import { useMemo } from 'react';

export const GRANULARITIES = {
  DAY: 'day',
  WEEK: 'week',
};

export const DEFAULT_GRANULARITY = GRANULARITIES.DAY;

export const useGranularityOptions = t => {
  return useMemo(() => {
    return [
      {
        label: t('common.time-granularities.by-day'),
        value: GRANULARITIES.DAY,
      },
      {
        label: t('common.time-granularities.by-week'),
        value: GRANULARITIES.WEEK,
      },
    ];
  }, [t]);
};
