import GridLayout from '@components/grid-layout';
import React from 'react';
import { css } from 'styled-components';

import EventCurrencyContainer from './event-extras/event-currency-container';
import EventOptionsContainer from './event-extras/event-options-container';
import EventTypeContainer from './event-extras/event-type-container';

export default function EventExtras() {
  return (
    <GridLayout colums={[1, { TABLET_MAX: 2 }]}>
      <EventCurrencyContainer />
      <EventTypeContainer />
      <EventOptionsContainer
        css={css`
          grid-column: 1 / -1;
        `}
      />
    </GridLayout>
  );
}
