import { rem } from 'polished';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Img = styled.img`
  width: ${rem(56)};
  border-radius: var(--border-radius-small);
`;

const Label = styled.span`
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-tight);
  margin-left: var(--spacing-16);
`;

export default function EventName({ name, imageUrl }) {
  return (
    <Wrapper>
      <Img src={imageUrl} data-testid="event-name-image" />
      <Label data-testid="event-name-label">{name}</Label>
    </Wrapper>
  );
}

EventName.propTypes = {
  name: string,
  imageUrl: string,
};
