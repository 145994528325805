import { Linkicon } from '@hopin-team/ui-linkicon';
import { Pill } from '@hopin-team/ui-pill';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';
const { t } = i18n;

import { i18n } from '@features/localization';

import { PillContainer, PillWrapper, StyledHr } from './styles';

function TicketsFilter({
  allTickets,
  selectedTickets,
  onClick,
  handleClearClick,
}) {
  return (
    <>
      <Text mb={2}>Tickets</Text>
      {allTickets.length === 0 && (
        <div>
          <Text>{t('roundtables.filtering.tickets-unavailable')}</Text>
        </div>
      )}

      {allTickets.length !== 0 && (
        <PillContainer
          justifyContent="left"
          alignItems="center"
          mb={2}
          flexWrap="wrap"
        >
          {allTickets.map(ticket => {
            return (
              <PillWrapper
                selected={selectedTickets.includes(String(ticket.id))}
                key={ticket.id}
                id={ticket.id}
                onClick={() => onClick(ticket)}
                mr={1}
                title={ticket.label}
              >
                <Pill label={ticket.label} />
              </PillWrapper>
            );
          })}
        </PillContainer>
      )}

      <StyledHr />

      <Linkicon
        label={t('roundtables.filtering.clear-tickets')}
        onClick={handleClearClick}
        leadingIcon="close"
        scale={3}
        iconScale={1}
      />
    </>
  );
}

TicketsFilter.propTypes = {
  allTickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedTickets: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  handleClearClick: PropTypes.func.isRequired,
};

export default TicketsFilter;
