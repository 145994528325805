import { Button } from '@hopin-team/ui-button';
import { TextField } from '@hopin-team/ui-text-field';
import { func, object } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ChallengeHtmlForm = styled.form`
  width: 100%;
`;

const ChallengeForm = ({ t, register, errors, handleSubmit }) => (
  <ChallengeHtmlForm onSubmit={handleSubmit} data-testid="confirmation-form">
    <TextField
      label={t(`.token.label`)}
      name="confirmationToken"
      ref={register}
      hasErrors={errors?.confirmationToken}
      errorMessage={errors?.confirmationToken?.message}
      placeholder={t('.token.placeholder')}
      size="large"
      data-testid="confirmation-token-input"
      mt={1}
      mb={3}
    />

    <Button data-testid="submit-button" mb={2}>
      {t(`.submitButtonText`)}
    </Button>
  </ChallengeHtmlForm>
);

ChallengeForm.propTypes = {
  t: func.isRequired,
  register: func.isRequired,
  errors: object.isRequired,
  handleSubmit: func.isRequired,
};

export default ChallengeForm;
