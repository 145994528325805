import * as Routes from '@routes';
import ky from 'ky-universal';

let clients = {};

function getNewToken(params) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return ky.create({ headers }).post(Routes.usersScopedSsoPath(params)).json();
}

function createClient({ token, endpoint }) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  return ky.create({ prefixUrl: endpoint, headers });
}

function getClient(params, forceCreate = false) {
  const key = JSON.stringify(params);

  if (clients[key] && !forceCreate) {
    return clients[key];
  }
  clients[key] = getNewToken(params).then(({ token, endpoint }) => {
    return createClient({ token, endpoint });
  });
  return clients[key];
}

function makeRequest(params, requestFunction, previousFailed = false) {
  return getClient(params, previousFailed)
    .then(requestFunction)
    .catch(e => {
      if (e.response.status == 419) {
        return makeRequest(params, requestFunction, true);
      } else {
        throw e;
      }
    });
}

// handles token regeneration for expired tokens automatically
// if the server returns a http status code of 419
export function makeScopedRequest(scope, scopeParams, requestFunction) {
  const params = { ...scopeParams, scope: scope };
  // this returns a a promise, don't forget to .then it to get the results.
  return makeRequest(params, requestFunction);
}
