import timezones from '@features/constants/timezones';
import { getTzFromValue } from '@features/event-creation-wizard/utils/time-zone-selectors';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import ChevronDown from 'icons/chevron-down.svg';
import { func, object, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Container,
  DateField,
  TZContainer,
  TZIcon,
  TZPicker,
} from './date-picker.styles';

const DatePickerComponents = ({
  t,
  watch,
  errors,
  register,
  trigger,
  setValue,
  dateLocale,
  baseTranslationNamespace,
}) => {
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const tz = watch('timezone');
  const timezone = useMemo(() => getTzFromValue(tz), [tz]);

  useEffect(() => {
    trigger(['startDate', 'endDate']);
  }, [timezone, trigger]);

  const {
    org: { isOnFreePlan },
    basicDetails: { maxEventLengthHours },
    template: { id: templateId },
  } = useSelector(({ org, basicDetails, template }) => ({
    org,
    basicDetails,
    template,
  }));

  const onStartDateChange = useCallback(
    date => {
      if (date && date.toString() !== watch('startDate').toString()) {
        setValue('startDate', date);
        if (templateId === 'virtual_lite') {
          setValue('endDate', new Date(date.getTime() + 3600000));
        }
      }
      trigger(['startDate', 'endDate']);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templateId],
  );

  const onEndDateChange = useCallback(date => {
    date &&
      date.toString() !== watch('endDate').toString() &&
      setValue('endDate', date);
    trigger(['endDate', 'endDate']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translationKey =
    baseTranslationNamespace || 'event-creation-wizard.steps.basic-details';

  return (
    <>
      <Container>
        <DateField
          data-testid="event-start-date-input"
          label={t(`${translationKey}.form.start-date.label`)}
          dateOptions={{ locale: dateLocale }}
          date={startDate}
          ref={register('startDate')}
          onDateChange={onStartDateChange}
          withTime
          hasErrors={Boolean(errors?.startDate)}
          errorMessage={errors?.startDate?.message ?? ''}
        />
        <DateField
          data-testid="event-end-date-input"
          label={t(`${translationKey}.form.end-date.label`)}
          dateOptions={{ locale: dateLocale }}
          date={endDate}
          onDateChange={onEndDateChange}
          ref={register('endDate')}
          withTime
          hasErrors={Boolean(errors?.endDate)}
          errorMessage={errors?.endDate?.message ?? ''}
        />
      </Container>

      <Flex alignItems="center" mb={2.5} flexWrap="wrap">
        <Text element="p" color="grey-600" pattern="labelThree" mr={1} mt={2}>
          {t(`${translationKey}.form.helper-text`, {
            plan: isOnFreePlan ? 'Free' : 'your',
            maxEventLengthHours,
            timezone,
          })}
        </Text>

        <TZContainer htmlFor="timezone">
          <TZPicker id="timezone" name="timezone" ref={register}>
            {/* Should really be passing the start_time into `timezones` as a reference date.
            But the current basics page uses the current time.
            Once we have rewritten the basics page, we can improve this behavior. */}
            {timezones(new Date()).map(timezone => (
              <option key={timezone.value} value={timezone.value}>
                {timezone.label}
              </option>
            ))}
          </TZPicker>
          <TZIcon>
            <ChevronDown height={16} width={16} />
          </TZIcon>
        </TZContainer>
      </Flex>
    </>
  );
};

DatePickerComponents.propTypes = {
  t: func.isRequired,
  watch: func.isRequired,
  setValue: func.isRequired,
  register: func.isRequired,
  errors: object.isRequired,
  dateLocale: object.isRequired,
  trigger: func.isRequired,
  baseTranslationNamespace: string,
};

export default DatePickerComponents;
