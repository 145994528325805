import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import * as Routes from '@routes';
import { bool, oneOf, shape, string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

const InputWrapper = styled(Box)`
  max-width: 360px;
`;

const EventPrivacy = ({ event, paidPlan }) => {
  const { t } = useLocalization('event-privacy');
  const [eventVisibility, setEventVisibility] = useState(event.event_type);

  const options = [
    {
      value: 'hidden_event',
      label: t('visibility.hidden.label'),
      helpText: t('visibility.hidden.help-text'),
    },
    {
      value: 'public_event',
      label: t('visibility.public.label'),
      helpText: t('visibility.public.help-text'),
    },
    {
      value: 'private_event',
      label: t('visibility.private.label'),
      helpText: t('visibility.private.help-text'),
    },
  ];

  return (
    <>
      <InputWrapper mt={2.5} mb={!paidPlan ? 1 : 0}>
        <input type="hidden" name="event[event_type]" value={eventVisibility} />

        <Select
          label={t('label')}
          helperText={options.find(o => o.value === eventVisibility).helpText}
          size="small"
          options={options}
          value={eventVisibility}
          setValue={setEventVisibility}
          disabled={!paidPlan}
        />

        {eventVisibility === 'private_event' && (
          <TextField
            name="event[password]"
            defaultValue={event.password}
            label={t('password')}
            size="small"
            minLength={6}
            pattern="^[^,/\\.%&#]*$"
            mt={1.5}
          />
        )}
      </InputWrapper>

      {!paidPlan && (
        <Text element="a" scale={3} href={Routes.organiserBillingPath()}>
          {t('upgrade')}
        </Text>
      )}
    </>
  );
};

EventPrivacy.propTypes = {
  event: shape({
    password: string,
    event_type: oneOf(['hidden_event', 'public_event', 'private_event'])
      .isRequired,
  }).isRequired,
  paidPlan: bool.isRequired,
};

export default withLocalizationProvider(EventPrivacy);
