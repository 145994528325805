import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;
`;

export const Header = styled(Flex)`
  text-align: center;
  white-space: pre-line;

  & [role='tooltip'] {
    max-width: 240px;
  }
`;
