import TicketsFilter from '@components/sessions-filters/tickets-filter';
import { MenuContent } from '@hopin-team/ui-menu';
import { isEqual } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import DateFilter from './date-filter';
import SearchInput from './search-input';
import {
  FilterBlock,
  FilterButton,
  FiltersWrapper,
  MainContainer,
  OverlayFilterContainer,
} from './styles';
import TagsFilter from './tags-filter';
const dateToString = datePickerDate => {
  if (!datePickerDate) {
    return '';
  }
  return moment(datePickerDate).format('yyyy-MM-DD');
};

const stringToDate = urlDate => {
  if (!urlDate) {
    return null;
  }
  return moment(urlDate).toDate();
};

function SessionsFilters({ tags, tickets }) {
  const urlParams = new URLSearchParams(window.location.search);
  const tagsFromUrl = urlParams.getAll('tag_ids[]');
  const ticketsFromUrl = urlParams.getAll('ticket_ids[]');
  const startDateFromUrl = urlParams.get('from_ts');
  const endDateFromUrl = urlParams.get('to_ts');
  const searchText = urlParams.get('q');
  const buttonRef = useRef(null);
  const [tagsValue, setTagsValue] = useState(tagsFromUrl || []);
  const [ticketsValue, setTicketsValue] = useState(ticketsFromUrl || []);
  const [isShowing, setIsShowing] = useState(false);
  const [searchValue, setSearchValue] = useState(searchText || '');
  const [startDate, setStartDate] = useState(stringToDate(startDateFromUrl));
  const [endDate, setEndDate] = useState(stringToDate(endDateFromUrl));

  const handleFiltersChange = useCallback(
    e => {
      const isNewTagFilter = !isEqual(tagsFromUrl.sort(), tagsValue.sort());
      const isNewTicketFilter = !isEqual(
        ticketsFromUrl.sort(),
        ticketsValue.sort(),
      );
      const isNewDateFilter = !isEqual(
        [startDateFromUrl, endDateFromUrl],
        [dateToString(startDate), dateToString(endDate)],
      );
      if (isNewTagFilter || isNewTicketFilter || isNewDateFilter) {
        applySearchAndFilters(); // hard redirect
      }
      if (e.target !== buttonRef.current) {
        // due to mouseDown on fe-library's Menu vs onClick on button
        setIsShowing(false);
      }
    },
    [
      tagsFromUrl,
      tagsValue,
      ticketsFromUrl,
      ticketsValue,
      startDateFromUrl,
      endDateFromUrl,
      startDate,
      endDate,
      applySearchAndFilters,
    ],
  );

  const onTicketsChange = ticket => {
    if (ticketsValue.includes(String(ticket.id))) {
      const newTickets = ticketsValue.filter(e => e !== String(ticket.id));
      setTicketsValue(newTickets);
    } else {
      const newTickets = ticketsValue.concat(String(ticket.id));
      setTicketsValue(newTickets);
    }
  };

  function clearTags() {
    setTagsValue([]);
  }

  function clearTickets() {
    setTicketsValue([]);
  }

  const dateFilterCounter = startDate || endDate ? 1 : 0;
  const appliedFiltersLength =
    tagsValue.length + ticketsValue.length + dateFilterCounter;

  const onTagsChange = tag => {
    if (tagsValue.includes(String(tag.id))) {
      const newTags = tagsValue.filter(e => e !== String(tag.id));
      setTagsValue(newTags);
    } else {
      const newTags = tagsValue.concat(String(tag.id));
      setTagsValue(newTags);
    }
  };

  const applySearchAndFilters = useCallback(() => {
    let params = [];
    params.push(
      ...(searchValue ? [['q', searchValue]] : []),
      ...(startDate ? [['from_ts', dateToString(startDate)]] : []),
      ...(endDate || startDate
        ? [['to_ts', dateToString(endDate) || dateToString(startDate)]] // fallback for selecting one date only
        : []),
    );
    if (tagsValue.length) {
      params = params.concat(tagsValue.map(tag_id => ['tag_ids[]', tag_id]));
    }
    if (ticketsValue.length) {
      params = params.concat(
        ticketsValue.map(ticket_id => ['ticket_ids[]', ticket_id]),
      );
    }
    const newUrlParams = new URLSearchParams(params);

    if (newUrlParams.toString() !== window.location.search) {
      window.location.search = newUrlParams.toString();
    }
  }, [endDate, searchValue, startDate, tagsValue, ticketsValue]);

  return (
    <MainContainer justifyContent={'flex-end'}>
      <SearchInput
        value={searchValue}
        onInputChange={setSearchValue}
        applySearch={applySearchAndFilters}
      />
      {isShowing && (
        <OverlayFilterContainer
          isShowing={isShowing}
          isDismissible
          onClose={handleFiltersChange}
        >
          <MenuContent>
            <FiltersWrapper>
              <FilterBlock>
                <DateFilter
                  endDate={endDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  setStartDate={setStartDate}
                />
              </FilterBlock>
              <FilterBlock>
                <TicketsFilter
                  allTickets={tickets}
                  selectedTickets={ticketsValue}
                  onClick={onTicketsChange}
                  handleClearClick={clearTickets}
                />
              </FilterBlock>
              <FilterBlock>
                <TagsFilter
                  allTags={tags}
                  selectedTags={tagsValue}
                  onClick={onTagsChange}
                  handleClearClick={clearTags}
                />
              </FilterBlock>
            </FiltersWrapper>
          </MenuContent>
        </OverlayFilterContainer>
      )}
      <FilterButton
        ref={buttonRef}
        isInline
        isOutlined
        id="filtersButton"
        onClick={() => setIsShowing(prevValue => !prevValue)}
        trailingIcon="filter"
        isAnyFilterActive={appliedFiltersLength > 0}
      >
        {`Filters ${appliedFiltersLength ? ` (${appliedFiltersLength})` : ''}`}
      </FilterButton>
    </MainContainer>
  );
}

SessionsFilters.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SessionsFilters;
