import analyticsApi from '@api/analytics';
import Ellipse from '@components/icons/ellipse';
import CardTitle from '@features/dashboard/components/card-title';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import { DeprecatedSelect as Select } from '@hopin-team/ui-select';
import Ticket from 'icons/ticket.svg';
import floor from 'lodash/floor';
import { string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import Card from '../common/overview-card';
import TopItemsListComponent from '../common/top-items-list-component';
import EmptyState from '../styles/empty-state-styles';
import Loading from '../styles/loading-styles';

const StyledTicketIcon = styled.span`
  color: var(--color-blue-400);

  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledSelect = styled(Select)`
  flex-basis: 35%;
  top: -0.4rem;

  [class$='control'] {
    min-height: 2rem;
    border-color: var(--color-gray-300);

    > div {
      padding: 0.1rem 0.5rem;
    }
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TicketDescriptionWrapper = styled.span`
  svg {
    padding: 0 6px 0 4px;
  }

  span:nth-child(3) {
    color: var(--color-gray-600);
  }
`;

const TicketDescription = ({ label, price }) => (
  <TicketDescriptionWrapper>
    <span>{label}</span>
    <Ellipse />
    <span>{price}</span>
  </TicketDescriptionWrapper>
);

TicketDescription.propTypes = {
  label: string.isRequired,
  price: string.isRequired,
};

const transformResult = (tickets, orderType) => {
  return tickets.map(ticket => {
    const ticketValue =
      orderType.value == 'number_sold'
        ? ticket.fraction_sold
        : `${floor(ticket.percentage_sold, 2)}%`;

    return {
      icon: (
        <StyledTicketIcon data-testid="ticket-icon">
          <Ticket />
        </StyledTicketIcon>
      ),
      text: (
        <TicketDescription
          label={ticket.label}
          price={ticket.price_with_currency_symbol}
        />
      ),
      value: ticketValue,
    };
  });
};

const RegistrationsByTicketType = ({ eventSlug }) => {
  const { t } = useLocalization('overview.registrations-by-ticket-type');
  const [tickets, setTickets] = useState([]);
  const [orderType, setOrderType] = useState({
    label: t('order-options.percentage'),
    value: 'percentage_sold',
  });
  const limit = 6;
  const withRegistrations = true;
  const loading = usePromise(
    signal =>
      analyticsApi.getTicketSalesByType(
        eventSlug,
        signal,
        limit,
        orderType.value,
        withRegistrations,
      ),
    result => setTickets(transformResult(result.data, orderType)),
    [orderType],
  );

  const iconAccessibilityProps = {
    clear: {
      description: t('clear.description'),
      title: t('clear.title'),
    },
    dropdown: {
      description: t('dropdown.description'),
      title: t('dropdown.title'),
    },
    multiValueRemove: {
      title: t('multi-value-remove.title'),
    },
  };

  const orderOptions = [
    {
      label: t('order-options.percentage'),
      value: 'percentage_sold',
    },
    {
      label: t('order-options.number'),
      value: 'number_sold',
    },
  ];

  return (
    <Card>
      <CardContentWrapper>
        <CardHeader>
          <CardTitle>{t('title')}</CardTitle>
          <StyledSelect
            options={orderOptions}
            value={orderType}
            onChange={setOrderType}
            placeholder={t('select-placeholder')}
            iconAccessibilityProps={iconAccessibilityProps}
          />
        </CardHeader>
        <Loading isLoading={loading}>
          {tickets.length > 0 ? (
            <TopItemsListComponent items={tickets} />
          ) : (
            <EmptyState text={t('empty')} icon={<Ticket />} />
          )}
        </Loading>
      </CardContentWrapper>
    </Card>
  );
};

RegistrationsByTicketType.propTypes = {
  eventSlug: string.isRequired,
};

export default RegistrationsByTicketType;
