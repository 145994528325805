import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { FullWidthFlex } from '../bulk-upload-modal-styles';
import { STEPS } from '../constants';

export const ModalHeader = ({ step, registrationEmailsDisabled }) => {
  const { t } = useLocalization('people.attendees.registrant-bulk-upload');

  const titlesByStep = {
    [STEPS.CSV_UPLOAD]: t('title'),
    [STEPS.CSV_MAPPING]: t('mapping'),
    [STEPS.ERROR_REPORT]: t('error-report.title'),
    [STEPS.EMAIL_MARKETING]: registrationEmailsDisabled
      ? t('email-marketing.disabled.title')
      : t('email-marketing.enabled.title'),
    [STEPS.SUCCESS_CONFIRMATION]: t('csv-processing-title'),
  };

  const titleText = titlesByStep[step];

  if (!titleText) {
    return null;
  }

  return (
    <FullWidthFlex justifyContent="flex-start">
      <Text pattern="bodyLargeBold">{titleText}</Text>
    </FullWidthFlex>
  );
};

ModalHeader.propTypes = {
  step: PropTypes.string.isRequired,
  registrationEmailsDisabled: PropTypes.bool,
};
