import { Container } from '@features/event-creation-wizard/components/date-pickers/date-picker.styles';
import { Butticon } from '@hopin-team/ui-butticon';
import { Button } from '@hopin-team/ui-button';
import { DateField as UIDateField } from '@hopin-team/ui-date-field';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { TextField } from '@hopin-team/ui-text-field';
import { rem } from 'polished';
import styled from 'styled-components';

export const FullScreenWrapper = styled.main`
  height: 100vh;
  width: 100vw;
  /* Prevents browser from adding horizontal scrollbar */
  max-width: 100%;
`;

export const CustomCloseButton = styled(Butticon).attrs({
  icon: 'close',
  size: 'medium',
  pattern: 'secondary',
  p: 1.75,
  m: 1.5,
})`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;

  &:focus,
  &:focus-visible {
    position: fixed;
  }
`;

export const FormWrapper = styled.form`
  width: 100%;
  max-width: ${rem(484)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    max-width: unset;
  }

  /* Date picker styles override */
  ${Container} {

    ${UIDateField} {
      margin: 0;
    }

    ${UIDateField} + ${UIDateField} {
      margin: 0;
      margin-left: ${rem(8)};
    }

    ${UIDateField} div:has(> input):first-child {
      padding-left: 0.5rem;
    }

    @media (max-width: ${rem(1500)}) {
    flex-direction: row;

    ${UIDateField} {
      margin: 0;
    }
  }

    @media (max-width: ${rem(1200)}) {
      
      flex-direction: column;

      ${UIDateField} + ${UIDateField} {
        margin-left: 0;
      }
    }
  }
`;

export const ContinueAction = styled(Button).attrs({
  pattern: 'primary',
})`
  border-radius: ${rem(10)};
  min-height: ${rem(40)};
`;

export const GridWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 5%;
  height: 100%;

  max-width: ${rem(1280)};
  margin: 0 auto;

  padding-right: 40px;
  padding-left: 120px;

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    padding-right: 100px;
    padding-left: 100px;
    grid-template-columns: 1fr 0;
    gap: unset;
  }

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MIN', theme)}) {
    padding-right: 40px;
    padding-left: 40px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;

  input {
    border-radius: ${rem(10)};
  }
`;
