import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  externalId: undefined,
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegistration: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setRegistration } = registrationSlice.actions;

export default registrationSlice.reducer;
