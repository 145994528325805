import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { connectViewData } from '@util/page-view-context';
import { string } from 'prop-types';
import React, { useContext } from 'react';

import { sendTestRedeemCodeEmail } from '../services';
import SingleInviteComponent from './single-invite-component';
import useSingleInviteForm from './use-single-invite-form';

const SingleInviteContainer = ({ eventSlug, ...props }) => {
  const { t } = useLocalization('magic-links.tabs.single-invite');
  const { addAlert } = useContext(AlertsContext);

  const handleSubmitSuccess = async () => {
    addAlert({
      active: true,
      text: t('invitation-sent'),
      pattern: 'success',
    });
    if (typeof window !== 'undefined') {
      window.location.hash = 'manage-invites';
      window.location.reload();
    }
  };

  const handleSubmitError = errors => {
    addAlert({
      active: true,
      text: errors.join(', '),
      pattern: 'error',
    });
  };

  const handleTestEmailClick = async () => {
    const data = await sendTestRedeemCodeEmail(eventSlug);
    const { success, errors } = data;
    if (success) {
      addAlert({
        active: true,
        text: t('test-email-sent'),
        pattern: 'success',
      });
    } else {
      handleSubmitError(errors);
    }
  };

  const { errors, isSubmitting, onSubmit, register } = useSingleInviteForm(
    eventSlug,
    handleSubmitSuccess,
    handleSubmitError,
  );

  return (
    <SingleInviteComponent
      errors={errors}
      inputRef={register}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      onTestEmailClick={handleTestEmailClick}
      {...props}
    />
  );
};

SingleInviteContainer.propTypes = {
  eventSlug: string.isRequired,
};

const mapViewDataToProps = ({ event }) => ({
  eventSlug: event.slug,
  tickets: event.personas.filter(({ onlyForOrganisers }) => !onlyForOrganisers),
});

export default connectViewData(mapViewDataToProps)(SingleInviteContainer);
