import React from 'react';

const HeartFill = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#2C3242"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3335 0C13.0699 0 13.6668 0.596954 13.6668 1.33333V9.33333C13.6668 10.0697 13.0699 10.6667 12.3335 10.6667H8.94016C8.76527 10.6674 8.59767 10.7368 8.4735 10.86L7.48016 11.8533C7.38649 11.9471 7.25939 11.9999 7.12683 12H6.8735C6.74093 11.9999 6.61384 11.9471 6.52016 11.8533L5.52683 10.86C5.40266 10.7368 5.23506 10.6674 5.06016 10.6667H1.66683C0.93045 10.6667 0.333496 10.0697 0.333496 9.33333V1.33333C0.333496 0.596954 0.93045 0 1.66683 0H12.3335ZM7.3335 8.54L8.00016 7.87333C8.06243 7.81107 8.13054 7.74384 8.20308 7.67223C9.00043 6.8852 10.3335 5.56937 10.3335 4.5C10.3335 4.01377 10.1403 3.54745 9.79653 3.20364C9.45271 2.85982 8.98639 2.66667 8.50016 2.66667C7.93126 2.67993 7.39123 2.91995 7.00016 3.33333C6.60997 2.9187 6.0694 2.67845 5.50016 2.66667C4.48764 2.66667 3.66683 3.48748 3.66683 4.5C3.66683 5.52254 4.88569 6.77042 5.66263 7.56585C5.77213 7.67797 5.87286 7.78109 5.96016 7.87333L6.62683 8.54C6.82204 8.73497 7.13828 8.73497 7.3335 8.54Z"
      />
    </svg>
  );
};

export default HeartFill;
