import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { SecondaryHeaderContainer } from './secondary-header.styled';

export const SecondaryHeader = ({ title, subtitle, moreText, moreLink }) => {
  const showMore = moreLink && moreText;

  return (
    <SecondaryHeaderContainer
      data-testid="secondaryHeader"
      as="hgroup"
      cornerRadius="lg"
      background="grey-100"
      px="4"
      py="3"
    >
      <Text element="h2" pattern="subHeadingBold" color="grey-900">
        {title}
      </Text>
      <Text element="h3" pattern="bodyLarge">
        {subtitle}{' '}
        {showMore && (
          <a target="_blank" rel="noreferrer" href={moreLink}>
            {moreText}
          </a>
        )}
      </Text>
    </SecondaryHeaderContainer>
  );
};

SecondaryHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  moreText: PropTypes.string,
  moreLink: PropTypes.string,
};
