import { AlertsContext } from '@features/alerts/alerts-provider';
import { Button } from '@hopin-team/ui-button';
import * as Routes from '@routes';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import TicketMatchingSideModalComponent from './ticket-matching-side-modal-component';

const errorAlert = t => ({
  active: true,
  text: t('networking.something-went-wrong'),
  pattern: 'error',
});

const iconAccessibilityProps = t => ({
  clear: {
    title: t('networking.ticket-matching-modal.remove'),
    description: t('networking.ticket-matching-modal.clear-options'),
  },
  dropdown: {
    title: t('networking.ticket-matching-modal.arrow-down'),
    description: t('networking.ticket-matching-modal.open-options'),
  },
  multiValueRemove: {
    title: t('networking.ticket-matching-modal.remove'),
    description: t('networking.ticket-matching-modal.clear-all-options'),
  },
});

const TicketMatchingModalContainer = ({
  authToken,
  eventId,
  personas,
  buttonText,
  t,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { addAlert } = useContext(AlertsContext);

  const ticketMatchingOptions = personas.map(persona => ({
    value: persona.id,
    label: persona.label,
  }));

  const [matchedOptions, setMatchedOptions] = useState(
    personas.reduce((acc, currentValue) => {
      acc[currentValue.externalId] = currentValue.matchedIds.map(id =>
        ticketMatchingOptions.find(option => option.value === id),
      );
      return acc;
    }, {}),
  );

  const handleUpdateTicketMatchingOption = async (externalId, selected) => {
    try {
      const url = Routes.updatePersonaMatchesOrganisersEventPersonaPath(
        eventId,
        externalId,
        { matched_ids: selected.map(option => option.value).join(',') },
      );

      // Optimistically update the select fields
      setMatchedOptions(prevState => ({
        ...prevState,
        [externalId]: selected,
      }));

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-CSRF-TOKEN': authToken,
        },
      });

      if (!response.ok) {
        addAlert(errorAlert(t));
      }
    } catch (e) {
      addAlert(errorAlert(t));
    }
  };

  return (
    <>
      <Button
        isOutlined
        size="small"
        isInline
        onClick={() => setModalOpen(true)}
        mb={0}
      >
        {buttonText || t('networking.ticket-matching-modal.ticket-matching')}
      </Button>
      <TicketMatchingSideModalComponent
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onUpdateTicketMatchingOption={handleUpdateTicketMatchingOption}
        matchedOptions={matchedOptions}
        personas={personas}
        ticketMatchingOptions={ticketMatchingOptions}
        iconAccessibilityProps={iconAccessibilityProps(t)}
        t={t}
      />
    </>
  );
};

export default TicketMatchingModalContainer;

TicketMatchingModalContainer.propTypes = {
  authToken: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  personas: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      matchedIds: PropTypes.arrayOf(PropTypes.number),
      label: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  buttonText: PropTypes.string,
  t: PropTypes.func.isRequired,
};
