import { Box } from '@hopin-team/ui-box';
import styled from 'styled-components';

export const ContentContainer = styled(Box)`
  display: inline-block;
`;

export const SelectableLabel = styled.label`
  display: block;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 100%;

    + ${ContentContainer} {
      border: 0.125rem solid transparent;
    }

    &:checked + ${ContentContainer} {
      border-color: var(--color-blue-400);
    }
  }
`;
