const eventLink = window.location.href;

const feedbackFormLinks = {
  Overview: {
    Draft: `https://docs.google.com/forms/d/e/1FAIpQLSdlkrFXRW8e0EOAuu4oZ-k4AoUFMRBOrtfWBTzMAOly3sckmA/viewform?usp=pp_url&entry.1407174919=${eventLink}`,
    Upcoming: `https://docs.google.com/forms/d/e/1FAIpQLSdlkrFXRW8e0EOAuu4oZ-k4AoUFMRBOrtfWBTzMAOly3sckmA/viewform?usp=pp_url&entry.1407174919=${eventLink}`,
    Live: `https://docs.google.com/forms/d/e/1FAIpQLScwT-CPF5wQ34JBudXIjCuAjmV0HEOzRo9lUiEsmjr0I2-EzQ/viewform?usp=pp_url&entry.1557351730=${eventLink}`,
    Ended: `https://docs.google.com/forms/d/e/1FAIpQLSdlrfpfaoCIR6ZKQGZebF_jrTMQeOnO6FJUrhL8WYJinzV_zA/viewform?usp=pp_url&entry.1800955452=${eventLink}`,
  },
  Connections: {
    Live: `https://docs.google.com/forms/d/e/1FAIpQLSduRJyAB9-4XmA--tWWtrlnhIci4MEBj1ehR2_Wnr_zbcftmw/viewform?usp=pp_url&entry.1557351730=${eventLink}`,
    Ended: `https://docs.google.com/forms/d/e/1FAIpQLSduRJyAB9-4XmA--tWWtrlnhIci4MEBj1ehR2_Wnr_zbcftmw/viewform?usp=pp_url&entry.1557351730=${eventLink}`,
  },
  Registrations: {
    Draft: `https://docs.google.com/forms/d/e/1FAIpQLScjI_HpU9JsUYTFvdIBQq8HK19uE37amrrG5M5DhDzisF5NtA/viewform?usp=pp_url&entry.1557351730=${eventLink}`,
    Upcoming: `https://docs.google.com/forms/d/e/1FAIpQLScjI_HpU9JsUYTFvdIBQq8HK19uE37amrrG5M5DhDzisF5NtA/viewform?usp=pp_url&entry.1557351730=${eventLink}`,
    Live: `https://docs.google.com/forms/d/e/1FAIpQLScjI_HpU9JsUYTFvdIBQq8HK19uE37amrrG5M5DhDzisF5NtA/viewform?usp=pp_url&entry.1557351730=${eventLink}`,
    Ended: `https://docs.google.com/forms/d/e/1FAIpQLScjI_HpU9JsUYTFvdIBQq8HK19uE37amrrG5M5DhDzisF5NtA/viewform?usp=pp_url&entry.1557351730=${eventLink}`,
  },
};

export default feedbackFormLinks;
