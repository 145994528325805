import { useModal } from '@components/modal/modal-context';
import ModalProvider from '@components/modal/modal-provider';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Heading = styled.h5`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: 1;
  color: var(--color-gray-800);
  text-align: center;
  margin-top: ${rem(24)};
  margin-bottom: ${rem(24)};
`;

const Button = styled.button`
  &:hover {
    color: var(--color-primary-400);
  }
  &.button.-secondary:active {
    background-color: transparent;
  }
  &.button.-secondary:focus {
    background-color: transparent;
  }
`;

const ModalContent = () => (
  <>
    <Heading>New Integration Request</Heading>
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSe0U0ZJXiWzDvu9S4Lm9behQpl4B4xfk1Blv6jiROn8X1GFzA/viewform?embedded=true"
      width="100%"
      height="600"
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      title="New Integration Request"
    >
      Loading…
    </iframe>
  </>
);
const IntegrationModal = () => {
  const { setContent, setIsModalOpen } = useModal();

  const onOpen = () => {
    setContent(<ModalContent />);
    setIsModalOpen(true);
  };

  return (
    <>
      <Button className="button -secondary" onClick={onOpen}>
        Request an integration
      </Button>
    </>
  );
};

const ModalContainer = () => (
  <ModalProvider>
    <IntegrationModal />
  </ModalProvider>
);
export default ModalContainer;
