import { useLocalization } from '@features/localization';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  revertTrimRecording as revertTrimRecordingApi,
  ValidationError,
} from '../api';
import { updateRecording as updateRecordingInStore } from '../redux/slices/recordings/recordings-slice';

export const useRevertTrimRecording = ({ slug, onSuccess, onError }) => {
  const { t } = useLocalization('recording-group');
  const dispatch = useDispatch();
  const [isRevertSaving, setIsRevertSaving] = useState(false);

  const revertTrimRecording = async recording => {
    const { id } = recording;
    const previousRecording = recording;
    setIsRevertSaving(true);

    try {
      const updatedRecording = await revertTrimRecordingApi(id, slug);
      dispatch(updateRecordingInStore(updatedRecording));
      setIsRevertSaving(false);
      if (onSuccess) onSuccess(updatedRecording);
    } catch (error) {
      setIsRevertSaving(false);

      let message = t('something-went-wrong-try-later');

      if (error && error instanceof ValidationError) {
        message = error.message;
      }

      if (onError) {
        onError({ error, message, previousRecording });
      }
    }
  };

  return { revertTrimRecording, isRevertSaving };
};
