import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import compose from 'lodash/fp/compose';
import { number, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import ConfirmUpdateEmailsModal from './modals/confirm-update-email-modal';
import {
  CustomizeEmailsPrimaryButton,
  CustomizeEmailsSecondaryButton,
  LeftPanel,
  RightPanel,
  StickyDiv,
} from './styles';

const CustomiseEmailsPreview = ({
  authenticityToken,
  eventSlug,
  customTemplate,
}) => {
  const { t } = useLocalization('communications.customise-emails');
  const { addAlert } = useContext(AlertsContext);
  const apiClient = initApiClient(authenticityToken);
  const logger = getLogger(LOGGER_NAMES.COMMUNICATIONS);
  const [
    isConfirmUpdateEmailsModalOpen,
    setConfirmUpdateEmailsModalOpen,
  ] = useState(false);

  const backToEdit = customTemplate => {
    window.location.href = Routes.editOrganisersEventCommunicationPath({
      event_id: eventSlug,
      id: customTemplate.kind,
      _options: true,
    });
  };

  const sendTestEmail = async () => {
    const selectedUser = document.getElementById('preview_user_id').value;
    const selectedPersona = document.getElementById('preview_persona_id').value;

    const endpoint = Routes.testOrganisersEventCommunicationPath({
      event_id: eventSlug,
      id: customTemplate.kind,
      preview: {
        user_id: selectedUser,
        persona_id: selectedPersona,
      },
      _options: true,
    });

    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );

      window.location.reload();
    } catch (err) {
      logger.error(err);
      // There was a network error
      addAlert({
        active: true,
        text: t('preview.send-test-error-message'),
        pattern: 'error',
      });
    }
  };

  const confirmUpdate = async () => {
    const endpoint = Routes.confirmOrganisersEventCommunicationPath({
      event_id: eventSlug,
      id: customTemplate.id,
      _options: true,
    });

    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );

      window.location.href = Routes.organisersEventCommunicationsPath({
        event_id: eventSlug,
        _options: true,
      });
    } catch (err) {
      logger.error(err);

      // There was a network error
      addAlert({
        active: true,
        text: t('preview.update-error-message'),
        pattern: 'error',
      });
    }
  };

  return (
    <>
      <StickyDiv>
        <Flex justifyContent="space-between">
          <LeftPanel>
            <CustomizeEmailsSecondaryButton
              isInline
              isOutlined
              pattern="secondary"
              data-testid="back-to-edit-button"
              onClick={() => backToEdit(customTemplate)}
            >
              <Text>{t('preview.back-to-edit.title')}</Text>
            </CustomizeEmailsSecondaryButton>
          </LeftPanel>
          <RightPanel>
            <CustomizeEmailsSecondaryButton
              isInline
              isOutlined
              pattern="secondary"
              data-testid="send-test-email-button"
              onClick={() => sendTestEmail(customTemplate)}
            >
              <Text>{t('preview.send-test-email.title')}</Text>
            </CustomizeEmailsSecondaryButton>
            <CustomizeEmailsPrimaryButton
              isInline
              data-testid="update-email-button-modal"
              onClick={() => setConfirmUpdateEmailsModalOpen(true)}
            >
              <Text>{t('preview.confirm-update.title')}</Text>
            </CustomizeEmailsPrimaryButton>
          </RightPanel>
        </Flex>
      </StickyDiv>
      <ConfirmUpdateEmailsModal
        isModalDisplayed={isConfirmUpdateEmailsModalOpen}
        handleClose={() => setConfirmUpdateEmailsModalOpen(false)}
        confirmUpdate={confirmUpdate}
        data-testid="update-email-confirmation-modal"
      />
    </>
  );
};

CustomiseEmailsPreview.propTypes = {
  authenticityToken: string.isRequired,
  eventSlug: string.isRequired,
  customTemplate: shape({
    id: number,
    kind: string,
  }).isRequired,
};

export default compose(
  withLocalizationProvider,
  withAlertsProvider,
)(CustomiseEmailsPreview);
