import MountSlice, { LATEST } from '@components/slices/MountSlice';
import { ReactiveMap } from '@slicesjs/reactive-map';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router';

/**
 * TODO: change the loader container currently we only use the
 * recording library slice for the cuts functionality. That's why
 * the explicit naming, CutsSliceLoaderContainer
 */
import { CutsSliceLoaderContainer } from './recording-library-slice.styled';

const PACKAGE_NAME = '@hopin-team/hopin-web-slices-recording-library';

const createReactiveMap = () =>
  new ReactiveMap({
    allowedKeys: ['externalRegistrationId'],
  });

const RecordingLibrarySliceBase = ({ externalRegistrationId }) => {
  const history = useHistory();
  const { recordingId } = useParams();

  const reactiveMap = useMemo(createReactiveMap, []);

  reactiveMap.item('externalRegistrationId').set(externalRegistrationId);

  const navigator = useRef({
    listen: callback =>
      history?.listen(({ location }) => {
        if (location) {
          callback(location);
        }
      }),
    push: location => history?.push(location),
  }).current;

  return (
    <CutsSliceLoaderContainer>
      <MountSlice
        packageName={PACKAGE_NAME}
        version={LATEST}
        // TOOD: change this one it's not the only route we'll use
        pathname={`${recordingId}/cuts`}
        navigator={navigator}
        reactiveMap={reactiveMap}
      />
    </CutsSliceLoaderContainer>
  );
};

RecordingLibrarySliceBase.propTypes = {
  navigator: PropTypes.object,
  pathname: PropTypes.string,
  externalRegistrationId: PropTypes.string,
};

export const RecordingLibrarySlice = withErrorHandling({
  loggerName: LOGGER_NAMES.REPLAY,
  errorPattern: 'page',
})(RecordingLibrarySliceBase);
