import { element, number, string } from 'prop-types';
import React from 'react';

import {
  BackgroundPattern,
  BackgroundPatternContainer,
  ImageContainer,
} from './styles';

const ImagePanel = ({
  horizontalAlign = 'center',
  verticalAlign = 'center',
  padding,
  children,
}) => {
  return (
    <>
      <BackgroundPatternContainer>
        <BackgroundPattern />
      </BackgroundPatternContainer>

      <ImageContainer
        horizontalAlign={horizontalAlign}
        verticalAlign={verticalAlign}
        padding={padding}
        data-testid="step-image"
      >
        {children}
      </ImageContainer>
    </>
  );
};

ImagePanel.propTypes = {
  horizontalAlign: string,
  verticalAlign: string,
  padding: number,
  children: element,
};

export default ImagePanel;
