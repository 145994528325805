import PropTypes from 'prop-types';
import React from 'react';

const StarTag = ({ className, color = 'currentColor' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M14.7225 1.66669H5.27832C3.42665 1.66669 2.50081 2.59253 2.50081 4.44419V12.7775C2.50081 14.6292 3.42665 15.555 5.27832 15.555H6.52832C6.96582 15.555 7.37749 15.7609 7.63916 16.1109L8.88916 17.7775C9.44499 18.5184 10.5558 18.5184 11.1116 17.7775L12.3616 16.1109C12.6241 15.7609 13.0358 15.555 13.4725 15.555H14.7225C16.5742 15.555 17.5 14.6292 17.5 12.7775V4.44419C17.5 2.59253 16.5742 1.66669 14.7225 1.66669Z"
        fill={color}
      />
      <path
        d="M10.4459 4.86081L11.345 6.68495C11.4125 6.82245 11.5433 6.91749 11.6941 6.93916L13.7691 7.24083C14.15 7.29583 14.3017 7.76583 14.0259 8.035L12.5258 9.49913C12.4158 9.6058 12.3658 9.76079 12.3917 9.91162L12.735 11.9158C12.805 12.3225 12.38 12.6325 12.0158 12.4408L10.2159 11.4933C10.0809 11.4225 9.91996 11.4225 9.78496 11.4933L7.98666 12.44C7.62166 12.6316 7.19667 12.3208 7.26584 11.9141L7.60835 9.91162C7.63418 9.75996 7.58417 9.6058 7.47417 9.49913L5.97413 8.035C5.6983 7.76583 5.85005 7.29666 6.23088 7.24083L8.30588 6.93916C8.45671 6.91749 8.5875 6.82162 8.655 6.68495L9.55415 4.86081C9.73665 4.49081 10.2634 4.49081 10.4459 4.86081Z"
        fill={color}
      />
    </svg>
  );
};

StarTag.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default StarTag;
