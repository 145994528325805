import Alerts from '@features/alerts/alerts';
import { LocalizationContext } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { array, bool, func, number, string } from 'prop-types';
import React, { useContext } from 'react';

import AnnouncementTable from '../announcements-table';
import CreateEmailButton from '../create-email-button';
import EmptyState from '../empty-state';
import { ContentWrapper, HeaderWrapper } from './styles';

const AnnouncementsListWrapper = ({
  eventId,
  eventTz,
  items,
  token,
  setItems,
  organizationId,
  loading,
  handleCreateEmail,
  emailTemplatesLimit,
  hasTicketTypes,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      {items.length ? (
        <ContentWrapper>
          <HeaderWrapper>
            <Text element="p" pattern="body" color="grey-800" pt={3} pb={4}>
              {t('email-dashboard.description')}
            </Text>
            <CreateEmailButton
              createAnnouncement={handleCreateEmail}
              loading={loading}
              disabled={items.length >= emailTemplatesLimit || loading}
            />
          </HeaderWrapper>
          <AnnouncementTable
            eventId={eventId}
            eventTz={eventTz}
            items={items}
            token={token}
            organizationId={organizationId}
            setItems={setItems}
            t={t}
            hasTicketTypes={hasTicketTypes}
          />
        </ContentWrapper>
      ) : (
        <EmptyState createAnnouncement={handleCreateEmail} disabled={loading} />
      )}
      <Alerts />
    </>
  );
};

AnnouncementsListWrapper.propTypes = {
  eventId: number.isRequired,
  eventSlug: string.isRequired,
  eventTz: string.isRequired,
  items: array.isRequired,
  token: string.isRequired,
  organizationId: number.isRequired,
  loading: bool.isRequired,
  handleCreateEmail: func.isRequired,
  setItems: func.isRequired,
  emailTemplatesLimit: number,
  hasTicketTypes: bool,
};

export default AnnouncementsListWrapper;
