import { TextField } from '@hopin-team/ui-text-field';
import styled from 'styled-components';

export const NameFieldsContainer = styled.div`
  display: flex;
  width: 100%;

  ${TextField} {
    width: 50%;
  }
`;
