import Footer from '@features/event-creation-wizard/components/footer';
import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { useLocalization } from '@features/localization';
import segmentTrack from '@util/analytics/segment-client';
import * as P from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { stepMap } from '../constants/step-map';
import { useEventCreationWizard } from '../event-creation-wizard-provider';
import { useStepsInfo } from './useStepsInfo';

const EventCreationWizardFooter = ({
  canSkip,
  canPreview,
  disabled = false,
  onSkip,
  onSave,
  ctaLabel,
  ctaPreviewLabel,
}) => {
  const isMounted = useRef(false);
  const state = useSelector(state => state);
  const orgId = state.org.id;
  const templateId = state.template.id;
  const eventFormat = state.eventLocation.type;
  const { essentialsWizardEventSimplification } = state.flags;
  const { t } = useLocalization('event-creation-wizard.common');
  const { push } = useHistory();
  const { nextStep, currentStep } = useStepsInfo();
  const { createEvent } = useEventCreationWizard();
  const [transitioning, setTransitioning] = React.useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const actionHandler = callback => async () => {
    setTransitioning(true);
    await callback?.();

    if (nextStep?.name) {
      push(stepMap[nextStep.name].path(templateId, eventFormat));
      return;
    }

    await createEvent();

    if (isMounted) {
      setTransitioning(false);
    }
  };

  const handleSkip = () => {
    segmentTrack(EVENT.STEP_SKIPPED, {
      organiser_id: orgId,
      screen_name: currentStep,
      event_type: templateId,
    });

    actionHandler(onSkip)();
  };
  const handleSave = actionHandler(onSave);

  const handleSaveAndPreview = async () => {
    setTransitioning(true);

    createEvent({ openReceptionTab: true });

    if (isMounted) {
      setTransitioning(false);
    }
  };

  return (
    <Footer
      handleCta={handleSave}
      handlePreviewCta={handleSaveAndPreview}
      handleSkip={handleSkip}
      canSkip={canSkip}
      canPreview={canPreview}
      disabled={disabled}
      transitioning={transitioning}
      ctaLabel={ctaLabel || t('save-continue')}
      ctaPreviewLabel={ctaPreviewLabel}
      skipLabel={t('skip')}
      rounded={essentialsWizardEventSimplification}
    />
  );
};

EventCreationWizardFooter.propTypes = {
  canSkip: P.bool.isRequired,
  canPreview: P.bool,
  disabled: P.bool,
  onSkip: P.func,
  onSave: P.func,
  ctaLabel: P.string,
  ctaPreviewLabel: P.string,
};

export default EventCreationWizardFooter;
