async function initDatepickers() {
  const datepickers = document.querySelectorAll('.datepicker');

  if (datepickers.length > 0) {
    await import('flatpickr/dist/flatpickr.min.css');
    const { default: flatpickr } = await import('flatpickr');
    const { getDefaultLanguage } = await import(
      '@features/localization/helpers'
    );
    const { default: locales } = await import(`flatpickr/dist/l10n`);
    const locale = locales[getDefaultLanguage()];

    datepickers.forEach(datepicker => {
      const useMinDate = !datepicker.hasAttribute('data-no-min-date');

      flatpickr(datepicker, {
        enableTime: true,
        altInput: true,
        maxDate: '9999/12/31',
        minDate: useMinDate && 'today',
        altFormat: 'M-j h:iK',
        dateFormat: 'Y-m-d\\TH:i',
        locale,
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', initDatepickers);
