import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Pill } from '@hopin-team/ui-pill';
import { Showcase } from '@hopin-team/ui-showcase';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { EVENT_PART } from '../constants';
import { localiseDate } from '../utils/utils';
import {
  Divider,
  FilterContainer,
  PillWrapper,
  SelectWrapper,
} from './filter.styled';

const EVENT_PART_OPTIONS = [EVENT_PART.STAGE, EVENT_PART.SESSION];

const FilterComponent = ({
  filters: { eventPart, date, videoAreaId },
  onFilterChange,
  dateOptions: dateOptionsProp,
  videoAreaOptions,
  showEventPartFilters,
}) => {
  const { t, language } = useLocalization('recording-filter');
  const showVideoAreaFilter = videoAreaOptions?.length > 0;
  const showDateFilter = dateOptionsProp?.length > 0;
  const dateOptions = useMemo(() => {
    return showDateFilter
      ? [...dateOptionsProp]
          ?.sort((a, b) => new Date(a) - new Date(b))
          .map(option => ({
            label: localiseDate(option, language),
            value: option,
          }))
      : [];
  }, [dateOptionsProp, language, showDateFilter]);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <FilterContainer alignItems="center" data-testid="recordings-filter">
        {showVideoAreaFilter && (
          <SelectWrapper
            hideOptionsLabel={t('areas-hide-options')}
            showOptionsLabel={t('areas-show-options')}
            placeholder={t('areas-placeholder')}
            size="small"
            label={t('areas-label')}
            mr="2"
            hideLabel="true"
            options={videoAreaOptions}
            value={videoAreaId}
            setValue={val => onFilterChange({ videoAreaId: val })}
          />
        )}
        {showEventPartFilters &&
          EVENT_PART_OPTIONS.map((part, index) => (
            <PillWrapper
              selected={eventPart === part}
              onClick={() =>
                onFilterChange({ eventPart: eventPart === part ? null : part })
              }
              key={part}
              data-testid={`filter-event-part-${part}`}
              mr={index === 0 ? 1 : 0}
            >
              <Pill label={t(`part_${part}`)} />
            </PillWrapper>
          ))}
        {showEventPartFilters && showDateFilter && (
          <Divider data-testid="filter-divider" />
        )}
        {showDateFilter && (
          <Showcase scrollIconSize="medium" distanceFromHorizontalEdges={0}>
            {dateOptions.map(({ label, value }) => (
              <PillWrapper
                selected={date === value}
                onClick={() =>
                  onFilterChange({
                    date: date === value ? null : value,
                  })
                }
                key={value}
                data-testid={`filter-date-option-${value}`}
              >
                <Pill label={label} />
              </PillWrapper>
            ))}
          </Showcase>
        )}
      </FilterContainer>
    </Flex>
  );
};

FilterComponent.propTypes = {
  filters: PropTypes.shape({
    eventPart: PropTypes.string,
    date: PropTypes.string,
    videoAreaId: PropTypes.string,
  }),
  onFilterChange: PropTypes.func,
  dateOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
  videoAreaOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  showEventPartFilters: PropTypes.bool,
};

export const Filter = withLocalizationProvider(FilterComponent);
