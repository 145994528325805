import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { calcSpace } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import { shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SocialMediaLink from './social-media-link';

const BoothHeaderCTA = ({ actionButtonText }) => {
  return (
    <>
      <Button>
        <Icon data-testid="heart-icon" color="grey-white" name="heart" />
        {actionButtonText}
      </Button>
    </>
  );
};

BoothHeaderCTA.propTypes = {
  actionButtonText: string,
};

export const Section = styled.div`
  margin-top: ${calcSpace(1)};
  text-align: center;
`;

const ContentWrapper = styled.div`
  padding: ${calcSpace(3)};
`;

const socialMediaTypes = [
  'instagram',
  'linkedin',
  'facebook',
  'twitter',
  'website',
];

const VendorTabPreview = ({ vendor, ...restProps }) => {
  const { logoUrl, name, headline, about, buttonText } = vendor;
  const links = socialMediaTypes.map(type => ({ type, url: vendor[type] }));
  return (
    <ContentWrapper data-testid={restProps['data-testid']}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Section>
          <Flex justifyContent="center">
            <img
              data-testid="logo"
              style={{ maxWidth: '20%' }}
              src={logoUrl}
              alt=""
            />
          </Flex>
        </Section>
        <Section>
          <Text pattern="strong" color="grey-black" scale={3}>
            {name}
          </Text>
        </Section>
        <Section>
          <Text pattern="body" color="grey-700" scale={2}>
            {headline}
          </Text>
        </Section>
        {links.some(item => item.url) && (
          <Section>
            <Flex justifyContent={'space-evenly'}>
              {links.map(({ type, url }) => (
                <SocialMediaLink
                  key={type}
                  data-testid={`${type}-icon`}
                  href={url}
                >
                  <Icon
                    scale={1.5}
                    m={1}
                    data-testid={`${type}-icon`}
                    color="grey-600"
                    name={type}
                  />
                </SocialMediaLink>
              ))}
            </Flex>
          </Section>
        )}
        {about && (
          <Section>
            <Text pattern="body" color="grey-700" scale={2}>
              {about}
            </Text>
          </Section>
        )}
      </Flex>
      <Section>
        <BoothHeaderCTA actionButtonText={buttonText} />
      </Section>
    </ContentWrapper>
  );
};

VendorTabPreview.propTypes = {
  vendor: shape({
    name: string,
    logoUrl: string,
    headline: string,
    about: string,
    buttonText: string,
    facebook: string,
    instagram: string,
    linkedin: string,
    twitter: string,
    website: string,
  }),
  ['data-testid']: string,
};

export default VendorTabPreview;
