import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { JobStatus } from '@features/expo/csv/consts';
import { safeInterval } from '@features/expo/csv/helpers';
import { useLocalization } from '@features/localization';
import { useParseCsvFile } from '@features/registrations/registrants-bulk-upload/use-parse-csv-file';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { Toast } from '@hopin-team/ui-toast';
import { REGISTRATION_EVENTS } from '@util/analytics/registration-events';
import trackSegmentEvent from '@util/analytics/segment-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import { isEmpty } from 'lodash';
import { bool, func, string } from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getCustomForms } from '../utils/services';
import {
  ERROR_MESSAGES,
  ErrorToast,
  GENERIC_ERROR_MESSAGE,
  ModalBodyV2,
  ModalFooter,
  ModalHeader,
} from './components';
import { StyledModal } from './components/modal-body-v2-styles';
import { STEPS } from './constants';
import { useModalStep } from './use-modal-step';
import {
  getFormattedErrors,
  serializeCsv,
  triggerCsvDownload,
} from './utils/csv';
import { applyMapping } from './utils/serialization';

const INITIAL_STEP = STEPS.CSV_UPLOAD;
const REQUIRED_FIELDS = ['first_name', 'last_name', 'email', 'persona_label'];
const DEFAULT_MAPPING = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  persona_label: 'Ticket Name',
  external_barcode: 'External Barcode',
};

const logger = getLogger(LOGGER_NAMES.REGISTRATIONS);

const BulkUploadModalV2 = ({
  eventSlug,
  eventExternalId,
  isOpen,
  onClose,
  registrationEmailsDisabled,
  userExternalId,
  registrantsBulkUploadReportStatus,
}) => {
  const timerForPageReload = useRef(null);
  const [errors, setErrors] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isProcessingCSV, setIsProcessingCSV] = useState(false);
  const [files, setFiles] = useState([]);
  const [questionsLoadedStatus, setQuestionsLoadedStatus] = useState('initial');
  const [fieldMapping, setFieldMapping] = useState();
  const [questionsMapping, setQuestionsMapping] = useState({});
  const [defaultMapping, setDefaultMapping] = useState(DEFAULT_MAPPING);
  // TODO: Currently, the `AuthenticationContext` doesn't work in staging (and potentially in prod too).
  //       As a workaround, we are fetching the token using the `hopinApi.getUserToken` method.
  //       Remove this logic once the `AuthenticationContext` issue is fixed
  //       (see the comment above the `onConfirmUpload` function).
  const [token, setToken] = useState();

  const { nextStep, prevStep, step, setStep } = useModalStep([
    STEPS.CSV_UPLOAD,
    STEPS.CSV_MAPPING,
    STEPS.EMAIL_MARKETING,
    STEPS.SUCCESS_CONFIRMATION,
  ]);

  const modalSizesByStep = {
    [STEPS.CSV_MAPPING]: 'huge',
  };
  const modalSize = modalSizesByStep[step];

  const { t } = useLocalization('people.attendees.registrant-bulk-upload');

  const { addAlert } = useContext(AlertsContext);
  const toastError = useCallback(
    (title, subtitle = '', timeout = undefined) => {
      addAlert({
        toast: () => <ErrorToast title={title} subtitle={subtitle} />,
        timeout,
      });
    },
    [addAlert],
  );

  const onParseError = useCallback(
    () => toastError(t('network-errors.invalid-csv')),
    [t, toastError],
  );
  const parsedCsv = useParseCsvFile(files?.[0], onParseError);

  useEffect(() => {
    setFieldMapping(undefined);
  }, [files]);

  useEffect(() => {
    if (step === STEPS.CSV_UPLOAD && progressPercentage !== 0) {
      setProgressPercentage(0);
    }
  }, [files, progressPercentage, step]);

  useEffect(() => {
    (async () => {
      if (questionsLoadedStatus !== 'initial') {
        return;
      }

      setQuestionsLoadedStatus('loading');

      const { token } = await hopinApi.getUserToken();

      const customForms = await getCustomForms(token, eventSlug);
      const questions = (customForms?.[0]?.questions || []).reduce(
        (acc, { external_id, name }) => {
          acc[external_id] = name;
          return acc;
        },
        {},
      );

      setToken(token);
      setQuestionsMapping(questions);
      setDefaultMapping(mapping => ({ ...mapping, ...questions }));
      setQuestionsLoadedStatus('complete');
    })();
  }, [
    eventSlug,
    setToken,
    setQuestionsMapping,
    setDefaultMapping,
    questionsLoadedStatus,
  ]);

  useEffect(() => {
    return () => {
      if (timerForPageReload.current) {
        clearInterval(timerForPageReload.current);
      }
    };
  }, []);

  const reset = useCallback(() => {
    // don't allow modal to close while processing
    if (registrantsBulkUploadReportStatus && isProcessingCSV) {
      return;
    }
    setProgressPercentage(0);
    setStep(INITIAL_STEP);
    onClose?.();
    setFiles([]);
  }, [isProcessingCSV, onClose, registrantsBulkUploadReportStatus, setStep]);

  const jobStatusCheck = useCallback(
    jobID => {
      safeInterval(async (_, intervalController) => {
        const response = await hopinApi.registrantsBulkUploadV2Job(
          eventSlug,
          jobID,
          token,
        );

        const { status, result, errors, pct_complete = 0 } = response;
        setProgressPercentage(pct_complete);

        if (status === JobStatus.FAILED) {
          intervalController.abort();
          setIsProcessingCSV(false);
          setProgressPercentage(0);

          if (errors && typeof errors === 'object' && !isEmpty(errors)) {
            Object.entries(errors).forEach(([field, err]) => {
              const message =
                ERROR_MESSAGES.find(
                  errorMessage => errorMessage.code === `${field}_${err}`,
                ) || GENERIC_ERROR_MESSAGE;

              addAlert({
                toast: () => (
                  <ErrorToast
                    subtitle={t(`network-errors.${message.subtitleKey}`)}
                  />
                ),
                timeout: 10_000,
              });
            });
          } else {
            addAlert({
              toast: () => (
                <ErrorToast
                  title={t(`network-errors.${GENERIC_ERROR_MESSAGE.titleKey}`)}
                  subtitle={t(
                    `network-errors.${GENERIC_ERROR_MESSAGE.subtitleKey}`,
                  )}
                />
              ),
              timeout: 10_000,
            });
          }

          return;
        }

        if (status === JobStatus.COMPLETE) {
          intervalController.abort();
          setIsProcessingCSV(false);
          setProgressPercentage(100);

          if (result.total.created > 0 || result.total.updated > 0) {
            addAlert({
              // eslint-disable-next-line react/prop-types
              toast: ({ onClose }) => (
                <Toast
                  icon="success"
                  colorPattern="success"
                  role="status"
                  iconColor="green-600"
                  isInverse
                  withCloseButton
                  onClose={onClose}
                  py={2}
                >
                  <Text pattern="strong" color="grey-800">
                    {t('success-toast', {
                      uploaded: result.total.created + result.total.updated,
                      created: result.total.created,
                      updated: result.total.updated,
                    })}
                  </Text>
                </Toast>
              ),
            });
          }
          const hasErrors = !!Object.entries(result.errors).length;

          if (hasErrors) {
            setErrors(getFormattedErrors(result.errors));
            setStep(STEPS.ERROR_REPORT);
          } else {
            setErrors(null);
            reset();

            timerForPageReload.current = setTimeout(() => {
              location.reload();
            }, 2_000);
          }
        }
      }, 2_000);
    },
    [addAlert, eventSlug, reset, setStep, t, token],
  );

  // Revert the line below once the issue with the `AuthenticationContext` is resolved.
  // const { authenticationToken } = useContext(AuthenticationContext);
  const canMapFields = !!parsedCsv;
  const onConfirmUpload = useCallback(async () => {
    try {
      if (registrantsBulkUploadReportStatus) {
        setIsProcessingCSV(true);
      }

      // Apply the mapping the user specified to records from the CSV.
      const registrants = parsedCsv.map(
        applyMapping(fieldMapping, new Set(Object.keys(questionsMapping))),
      );

      // Create an update job with the mapped result, extract it's ID.
      const request = await hopinApi.registrantsBulkUploadV2(
        eventSlug,
        { registrants },
        token,
      );

      if (!request.ok) {
        throw new Error('Request failed');
      }

      if (registrantsBulkUploadReportStatus) {
        const response = await request.json();

        const { background_job_id } = response;

        jobStatusCheck(background_job_id);
      } else {
        setStep(STEPS.SUCCESS_CONFIRMATION);
      }

      trackSegmentEvent(REGISTRATION_EVENTS.REGISTRANT_CSV_BULK_UPLOADED, {
        event_id: eventExternalId,
        user_id: userExternalId,
      });
    } catch (e) {
      setIsProcessingCSV(false);
      logger.error(e);

      toastError(
        t(`network-errors.${GENERIC_ERROR_MESSAGE.titleKey}`),
        t(`network-errors.${GENERIC_ERROR_MESSAGE.subtitleKey}`),
        10_000,
      );
    }
  }, [
    registrantsBulkUploadReportStatus,
    parsedCsv,
    fieldMapping,
    questionsMapping,
    eventSlug,
    token,
    eventExternalId,
    userExternalId,
    jobStatusCheck,
    setStep,
    toastError,
    t,
  ]);

  const isMappingValid =
    fieldMapping &&
    Object.entries(fieldMapping)
      .filter(([key]) => REQUIRED_FIELDS.includes(key))
      .every(([, value]) => !!value);

  const csvDownloadLinkRef = useRef();
  const createSampleDownload = useCallback(() => {
    const sampleData = serializeCsv({
      fields: ['First Name', 'Last Name', 'Email', 'Ticket Name'],
      data: [['John', 'Doe', 'john.doe@example.com', 'Free']],
    });
    triggerCsvDownload(
      new Blob([sampleData]),
      'registrants-sample-data.csv',
      csvDownloadLinkRef,
    );
  }, [csvDownloadLinkRef]);

  if (questionsLoadedStatus !== 'complete') {
    return null;
  }

  return (
    <StyledModal
      isDismissible={false}
      isShowing={isOpen}
      onClose={reset}
      size={modalSize ?? 'large'}
      withSeparator="footer"
      withCloseButton
      header={
        <ModalHeader
          step={step}
          registrationEmailsDisabled={registrationEmailsDisabled}
        />
      }
      footer={
        <ModalFooter
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          isMappingValid={isMappingValid}
          isProcessingCSV={isProcessingCSV}
          progressPercentage={progressPercentage}
          onModalClose={reset}
          onConfirmUpload={onConfirmUpload}
          canMapFields={canMapFields}
          registrationEmailsDisabled={registrationEmailsDisabled}
          registrantsBulkUploadReportStatus={registrantsBulkUploadReportStatus}
        />
      }
    >
      <Box mx={3} mb={3} ref={csvDownloadLinkRef}>
        <ModalBodyV2
          step={step}
          errors={errors}
          eventSlug={eventSlug}
          downloadSampleCsv={createSampleDownload}
          onFilesChange={setFiles}
          parsedCsv={parsedCsv}
          defaultMapping={defaultMapping}
          fieldMapping={fieldMapping}
          onMappingChange={setFieldMapping}
          registrationEmailsDisabled={registrationEmailsDisabled}
          registrantsBulkUploadReportStatus={registrantsBulkUploadReportStatus}
        />
      </Box>
    </StyledModal>
  );
};

BulkUploadModalV2.propTypes = {
  eventSlug: string.isRequired,
  eventExternalId: string.isRequired,
  userExternalId: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func,
  registrationEmailsDisabled: bool,
  registrantsBulkUploadReportStatus: bool,
};

export default BulkUploadModalV2;
