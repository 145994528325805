import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { connectViewData, withViewDataProvider } from '@util/page-view-context';
import compose from 'lodash/fp/compose';
import React from 'react';

import KeyDetailsComponent from './key-details-component';

const KeyDetailsContainer = props => {
  return <KeyDetailsComponent {...props} />;
};

const mapViewDataToProps = ({ event, timezones }) => ({
  event,
  timezones,
});

export default compose(
  withViewDataProvider,
  connectViewData(mapViewDataToProps),
  withAlertsProvider,
)(KeyDetailsContainer);
