import InfoCircleIcon from '@components/icons/info-circle-icon';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { func, string } from 'prop-types';
import React, { useContext } from 'react';

import {
  Container,
  Spacer,
  Title,
  TooltipContentWrapper,
  TooltipIconWrapper,
} from './preview-header.styled';

const TooltipContent = ({ t }) => {
  return (
    <TooltipContentWrapper>
      <Text
        pattern="headingThree"
        element="h3"
        color="grey-100"
        weight="medium"
        my={1}
      >
        {t('preview.tooltip.header')}
      </Text>
      <Text pattern="body" element="p" color="grey-100" weight="medium" my={1}>
        {t('preview.tooltip.body')}
      </Text>
    </TooltipContentWrapper>
  );
};

const PreviewHeader = ({ href, section }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Spacer>
      <Container>
        <Title>
          <Text color="grey-100" weight="medium">
            {section} {t('preview.title-suffix')}
          </Text>
          <ToolTip
            align="bottom"
            tip={<TooltipContent t={t} />}
            hideTip={false}
          >
            <TooltipIconWrapper>
              <InfoCircleIcon width={16} height={16} />
            </TooltipIconWrapper>
          </ToolTip>
        </Title>
        <Button
          theme={{ COLOR: { UTILITY: { ACTION: 'white' } } }}
          isOutlined
          isInline
          size="small"
          px={3}
          py={1}
          href={href}
        >
          {t('preview.edit-event')}
        </Button>
      </Container>
    </Spacer>
  );
};

TooltipContent.propTypes = {
  t: func,
};

PreviewHeader.propTypes = {
  href: string,
  section: string,
};

export default PreviewHeader;
