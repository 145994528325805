import { createSlice } from '@reduxjs/toolkit';

import { flagsActions } from './flags-actions';

export const flagsSlice = createSlice({
  name: 'flags',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      flagsActions.flagsProvider.flagsInitialised,
      (state, action) => ({
        ...state,
        ...action.payload,
      }),
    );
  },
});

export default flagsSlice.reducer;
