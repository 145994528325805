import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StepHead = styled.header`
  margin-bottom: var(--spacing-32);
  white-space: pre-line;
`;

const StepHeader = ({ title, subtitle }) => (
  <StepHead>
    <Text
      pattern="pageHeading"
      element="h1"
      color="grey-800"
      weight="regular"
      mb={1}
    >
      {title}
    </Text>
    <Text pattern="body" element="p" color="grey-700" weight="regular">
      {subtitle}
    </Text>
  </StepHead>
);

StepHeader.propTypes = { title: string, subtitle: string };

export default StepHeader;
