import { useLocalization } from '@features/localization';
import * as Routes from '@routes';
import { object, string } from 'prop-types';
import React from 'react';

import { STEPS } from '../../constants/steps';
import ChecklistItemsList from '../../styled/checklist-items-list';
import { isStepApplicable, isStepComplete } from '../../utils/steps';
import ChecklistItem from '../checklist-item';

const BuildVenueTabPanel = ({ checklistStatuses, eventSlug }) => {
  const { t } = useLocalization(
    'overview.event-setup-checklist.tab-panels.build-venue',
  );
  return (
    <ChecklistItemsList>
      {isStepApplicable(STEPS.RECEPTION, checklistStatuses) && (
        <ChecklistItem
          description={t('reception.description')}
          iconName="house"
          isChecked={isStepComplete(STEPS.RECEPTION, checklistStatuses)}
          link={Routes.receptionFormOrganisersEventPath(eventSlug)}
          text={t('reception.heading')}
        />
      )}
      {isStepApplicable(STEPS.SESSIONS, checklistStatuses) && (
        <ChecklistItem
          description={t('sessions.description')}
          iconName="sessions"
          isChecked={isStepComplete(STEPS.SESSIONS, checklistStatuses)}
          link={Routes.organisersEventRoundtablesPath(eventSlug)}
          text={t('sessions.heading')}
        />
      )}
      {isStepApplicable(STEPS.STAGE, checklistStatuses) && (
        <ChecklistItem
          description={t('stage.description')}
          iconName="camera"
          isChecked={isStepComplete(STEPS.STAGE, checklistStatuses)}
          link={Routes.organisersEventBackstagesPath(eventSlug)}
          text={t('stage.heading')}
        />
      )}
    </ChecklistItemsList>
  );
};

BuildVenueTabPanel.propTypes = {
  checklistStatuses: object.isRequired,
  eventSlug: string.isRequired,
};

export default BuildVenueTabPanel;
