import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
  border-bottom: 1px solid var(--color-gray-300);
  &:last-child {
    border-bottom: none;
  }
`;

const Color = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  position: relative;
  top: 0.3em;
`;

const Title = styled.div`
  text-align: left;
  padding-left: 0.5em;
  flex: 1;
`;

const Value = styled.div`
  text-align: left;
`;

const ColorLegendRow = ({ color, title, value }) => (
  <Container>
    <Color color={color} />
    <Title>{title}</Title>
    <Value>{value}</Value>
  </Container>
);

ColorLegendRow.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
};

export default ColorLegendRow;
