/** @typedef {{
    expo?: boolean;
    people?: boolean;
    networking?: boolean;
  }} Areas
 */

/**
 *
 * @param {boolean} extraSponsorTiers
 * @param {Areas} areas
 * @returns object like structure containing all the permitted fields user can edit
 */
export const getDefaultJson = (extraSponsorTiers, areas) => ({
  common: {
    booth: 'Booth',
    break: 'Break',
    chat: 'Chat',
    ...(areas.expo && { expo: 'Expo' }),
    'external-url': 'External URL',
    ...(areas.networking && { networking: 'Networking' }),
    ...(areas.people && {
      people: 'People',
      'people-area': 'People',
    }),
    polls: 'Polls',
    'proudly-supported-by': 'Proudly Supported By',
    'q-and-a': 'Q&A',
    reception: 'Reception',
    session: 'Session',
    sessions: 'Sessions',
    stage: 'Stage',
    stages: 'Stages',
    event: 'Event',
    replay: 'Replay',
  },
  chat: {
    'entered-the-booth': 'entered the booth',
    'entered-the-session': 'entered the session',
    'event-chat-disabled-title': 'Event chat is disabled',
    'stage-chat-disabled-title': 'Stage chat is disabled',
  },
  'chat-area': {
    organiser: 'Organiser',
  },
  'event-feedback': {
    'back-to-ringcentral-events': 'Back to RingCentral Events',
  },
  ...(areas.expo && {
    expo: {
      'claim-offer':
        'By registering interest you will claim the offer & your email will be forwarded to the vendor.',
      'direct-link':
        'Selecting the button below will open the vendor link in a new tab',
      'event-offer': 'Event Offer',
      home: 'Home',
      'search-vendors': 'Search vendors',
    },
  }),
  'general-announcement': {
    announcement: 'Announcement',
    'general-announcement': 'General Announcement',
  },
  notifications: {
    'polls-notifications': 'Polls notifications',
    'leave-poll-message':
      'The poll you want to view is in another area of this event, are you sure you want to be redirected?',
    'leave-q-and-a-message':
      'The Q&A you want to view is in another area of this event, are you sure you want to be redirected?',
  },
  'notification-preview': {
    'new-poll': 'New poll',
    'open-q&a': 'Open Q&A',
  },
  ...(areas.people && {
    people: {
      'my-connections': 'My Connections',
      organisers: 'Organisers',
      speakers: 'Speakers',
      'speed-networking': 'Speed Networking',
      'speed-networking-description':
        'Meet like-minded people in short one to one video conversations',
    },
  }),
  polls: {
    'polls-for-event-and-section': 'Polls for event and {{section}}',
    'no-polls-yet': 'No polls yet',
    'you-can-see-polls-here':
      'You can see polls here when your organiser adds one.',
    'result-hidden':
      'Thanks for voting, this poll result is only visible to the organiser.',
    'polls-disabled-title': 'Polls are disabled',
    'polls-disabled-message': 'The organizer has disabled the polls',
    'new-poll': 'New poll',
    'view-poll': 'View poll',
  },
  questions: {
    'questions-disabled': 'The organizer has disabled this Q&A',
    'ask-question': 'Ask a question',
    'delete-question': 'Delete question',
    'delete-answer': 'Delete answer',
    'failed-to-announce': 'Failed to announce Q&A. Please try again.',
    'remove-question': 'Remove this question?',
    'ask-anonymously': 'Ask anonymously',
    'type-your-question': 'Type your question',
    'no-questions-asked': 'Be the first to ask a question',
    'no-user-questions': 'No questions asked',
    'no-answered-questions': 'No answered questions',
    'no-unanswered-questions': 'No unanswered questions',
    anonymous: 'Anonymous',
    'all-questions': 'All Questions',
    answered: 'Answered',
    unanswered: 'Unanswered',
    'my-questions': 'My Questions',
    'question-deleted': 'Your question has been removed',
    'question-error': 'Your question cannot be deleted at this time',
    'new-question-error': 'No new questions can be added',
    'remove-answer': 'Remove this answer?',
    'answer-deleted': 'Answer deleted',
    'answer-deleted-error': 'Your answer cannot be deleted at this time',
    'question-remove-error': 'Your questions cannot be removed at this time',
    'delete-question-question-mark': 'Delete question?',
    'remove-cant-undo':
      'Remove this answer? Event organizers will still be able to see your answer after the event ends.',
    'are-you-sure-cant-undo':
      'Are you sure you want to remove this question? Event organizers will still be able to see your answer after the event ends.',
    'are-you-sure-cant-undo-plural':
      'Are you sure you want to remove these questions? Event organizers will still be able to see your answers after the event ends.',
    'new-questions': 'New questions',
  },
  'questions-notifications': {
    'event-open': 'Q&A is open for this event',
    'stage-open': 'Q&A is open for this stage',
    'session-open': 'Q&A is open for this session',
    'booth-open': 'Q&A is open for this booth',
    'ask-questions-upvote-existing': 'Ask questions and upvote existing ones',
    'q-and-a-open': 'Q&A open',
    'q-and-a-for-name-is-now-open': 'Q&A for {{qAndAName}} is now open',
    'q-and-a-open-for-event': 'Q&A for the event is now open',
    'q-and-a-open-for-stage': 'Q&A for the stage is now open',
    'q-and-a-open-for-session': 'Q&A for the session is now open',
    'q-and-a-open-for-booth': 'Q&A for the booth is now open',
    'open-q-and-a': 'Open Q&A',
  },
  reception: {
    description: 'Description',
    'explore-booths': 'Explore Booths',
    ...(areas.networking && { 'go-to-networking': 'Go to Networking' }),
    'go-to-stage': 'Go to Stage',
    'hosted-by': 'Hosted by',
    ...(areas.people && {
      'interact-with-people': 'Interact with people in one-on-one meetings',
    }),
    schedule: 'Schedule',
    speakers: 'Speakers',
    sponsors: 'Sponsors',
    'stage-is-live': 'Stage is live now!',
    ...(areas.expo && { 'visit-expo': 'Visit Expo' }),
    'visit-sessions': 'Visit Sessions',
    'visit-replay': 'Visit Replay',
    'visit-external-url': 'Enter',
    'visit-custom': 'Enter',
  },
  'schedule-meeting': {
    'schedule-meeting': 'Schedule Meeting',
  },
  sessions: {
    'screenshare-text': '[Screenshare]',
    'create-a-session': 'Create a session',
    'create-session': 'Create Session',
  },
  'side-panel': {
    'welcome-to-event': 'Welcome to the event!',
  },
  stage: {
    'nothing-is-happening': "Nothing's happening on the stage",
    'we-will-notify-you': "We'll notify you about a new scheduled segment.",
  },
  waiting: {
    'in-the-meantime': 'In the meantime you can',
  },
  registration: {
    description: 'Description',
    sponsors: 'Sponsors',
    schedule: 'Schedule',
    speakers: 'Speakers',
    booths: 'Booths',
    'hosted-by': 'Hosted by',
    'proudly-supported-by': 'Proudly Supported By',
  },
  sponsors: {
    gold: 'Gold',
    silver: 'Silver',
    bronze: 'Bronze',
    ...(extraSponsorTiers
      ? {
          'tier-4': 'Tier 4',
          'tier-5': 'Tier 5',
          'tier-6': 'Tier 6',
          'tier-7': 'Tier 7',
          'tier-8': 'Tier 8',
          'tier-9': 'Tier 9',
          'tier-10': 'Tier 10',
        }
      : {}),
  },
});
