import chunk from 'lodash/chunk';
import { array, func, number, object } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Carousel } from './carousel';
import { Body } from './field-mapper.style';
import { Matcher } from './matcher';
import { autoMatch } from './utils';

const FieldMapper = ({
  defaultMapping,
  initialMapping,
  exampleCount = 3,
  onMappingChange,
  records,
}) => {
  const userFields = useMemo(() => Object.keys(records[0] ?? {}), [records]);
  const chunkedUserFields = useMemo(() => chunk(userFields, 3), [userFields]);
  const autoMapping = useMemo(() => autoMatch(defaultMapping, userFields), [
    defaultMapping,
    userFields,
  ]);
  const [userMapping, setUserMapping] = useState(initialMapping ?? autoMapping);

  const examples = useMemo(
    () =>
      records.slice(0, exampleCount).reduce((examplesByField, record) => {
        for (const [field, value] of Object.entries(record)) {
          examplesByField[field] = examplesByField[field] || [];
          examplesByField[field].push(value);
        }

        return examplesByField;
      }, {}),
    [exampleCount, records],
  );

  const onMatcherChange = useCallback(
    (mappingKey, field) => {
      setUserMapping(currentMapping => {
        const updatedMapping = {
          ...currentMapping,
          [mappingKey]: field,
        };

        // Remove any other mapping for the changed key
        Object.keys(updatedMapping).forEach(prop => {
          if (prop !== mappingKey && updatedMapping[prop] === field) {
            updatedMapping[prop] = null;
          }
        });

        return updatedMapping;
      });
    },
    [setUserMapping],
  );

  useEffect(() => onMappingChange?.(userMapping), [
    onMappingChange,
    userMapping,
  ]);

  return (
    <Carousel>
      {chunkedUserFields.map((group, index) => (
        <Body key={index} p={4} allowOverflow>
          {group.map(fieldName => (
            <Matcher
              key={fieldName}
              autoMapping={autoMapping}
              defaultMapping={defaultMapping}
              examples={examples[fieldName]}
              fieldName={fieldName}
              onChange={onMatcherChange}
              userMapping={userMapping}
            />
          ))}
        </Body>
      ))}
    </Carousel>
  );
};

FieldMapper.propTypes = {
  /**
   * An object whose keys are attributes of the desired model
   * and whose values are header names.
   */
  defaultMapping: object.isRequired,
  /**
   * An (optional) mapping to use as the initial state for
   * the component.
   */
  initialMapping: object,
  /**
   * The number of sample rows shown for each field to be mapped.
   */
  exampleCount: number,
  /**
   * A handler to be called when the field mapping is updated.
   */
  onMappingChange: func,
  /**
   * An array of objects whose keys are the fields in the CSV file
   * and whose values constitute an individual row.
   */
  records: array.isRequired,
};

export default FieldMapper;
