import styled from 'styled-components';

export const DefaultVariantStyles = styled.div`
  position: relative; // allowing an overlay to be absolutely positioned above
`;

const TIMELINE_HEIGHT = 45;

const EditVariantStyles = styled.div`
  flex-direction: row;
  position: relative;

  // Force display some elements
  .vjs-time-divider {
    display: block !important;
  }
  .video-js .vjs-duration,
  .vjs-no-flex .vjs-duration,
  .vjs-current-time {
    display: block !important;
  }
  .vjs-remaining-time {
    display: none !important;
  }

  .video-js {
    margin-bottom: ${TIMELINE_HEIGHT}px;

    // Take timeline out of the control bar and put belowe the player
    .vjs-progress-control.vjs-control {
      background: none;
      position: absolute;
      bottom: -${TIMELINE_HEIGHT}px;
      width: 100%;
    }

    .vjs-duration > .vjs-duration-display {
      display: none !important;
    }

    .vjs-control-bar > .vjs-duration:after {
      content: "${({ duration }) => duration}";
    }

    // Current time / Recording duration eg. 1:09 / 2:52
    .vjs-current-time {
      padding-right: 0;
    }
    .vjs-duration {
      padding-left: 0;
    }

    // Always show control bar.
    .vjs-control-bar {
      display: flex !important;
      font-size: 1.1em; // scale whole control bar
    }

    // Grey timeline
    .vjs-play-progress,
    .vjs-progress-holder {
      background: none;
      height: ${TIMELINE_HEIGHT / 2}px;
      border-radius: 4px;
      pointer-events: none;
    }
    .vjs-load-progress {
      display: none !important;
    }

    .vjs-progress-holder {
      margin: 0 !important;
      font-size: 10px !important;
    }

    .vjs-mouse-display {
      // On hover position tooltip eg 0:33
      .vjs-time-tooltip {
        font-size: 10px !important;
      }
    }

    .vjs-play-progress {
      // Current position tooltip eg. 0:23
      .vjs-time-tooltip {
        display: none !important;
      }

      // Current position pointer
      &:before {
        content: '';
        width: 10px;
        height: 30px;
        top: -10px;
        z-index: 1;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='28' viewBox='0 0 10 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='-0.5' width='1.83624' height='23.6582' rx='0.918118' transform='matrix(-1 0 0 1 6.85352 5.46835)' fill='black' stroke='white'/%3E%3Cpath d='M4.63381 5.21571L1.19108 0.597688C0.699348 -0.0619068 1.17009 -1 1.9928 -1H8.87828C9.70099 -1 10.1717 -0.0619059 9.68001 0.597689L6.23727 5.21572C5.83737 5.75213 5.03371 5.75213 4.63381 5.21571Z' fill='black'/%3E%3C/svg%3E");
      }
    }
  }
`;

export const mapModeToStyles = {
  default: DefaultVariantStyles,
  edit: EditVariantStyles,
};
