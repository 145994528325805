import styled from 'styled-components';

export const CardContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  a {
    margin-top: auto;
  }
`;
