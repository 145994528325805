import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { Toggle } from '@hopin-team/ui-toggle';
import { bool, func, string } from 'prop-types';
import React from 'react';

const SettingComponent = ({ isToggled, onChange, title, description }) => {
  return (
    <>
      <Flex flexDirection="row" mb={2} data-testid={title}>
        <Toggle
          name={title}
          ml={-1}
          isToggled={isToggled}
          sizeOption="large"
          onChange={onChange}
        />
        <Flex flexDirection="column" mb={1} ml={0.5}>
          <Text element="p" pattern="body" color="grey-800" mb={0.5}>
            {title}
          </Text>
          <Text element="p" pattern="body" color="grey-600">
            {description}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

SettingComponent.propTypes = {
  isToggled: bool,
  onChange: func,
  title: string,
  description: string,
};

export default SettingComponent;
