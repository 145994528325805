import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { setFlow } from '@features/event-creation-wizard/redux/app/app-slice';
import { useStepsInfo } from '@features/event-creation-wizard/shared/useStepsInfo';
import segmentTrack from '@util/analytics/segment-client';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

function usePageViews() {
  const location = useLocation();
  const { id: orgId, subscriptionPlanTier } = useSelector(state => state.org);
  const { external_id: userExternalId } = useSelector(state => state.user);
  const templateId = useSelector(state => state.template.id);
  const stepInfo = useStepsInfo();
  const currentStep = stepInfo?.currentStep;
  const referrer = document.referrer;

  useEffect(() => {
    if (currentStep) {
      segmentTrack(EVENT.STEP_VISITED, {
        step: currentStep,
        user_id: userExternalId,
        organiser_id: orgId,
        path: location.pathname,
        template: templateId,
        organisation_subscription_tier: subscriptionPlanTier,
        referrer,
      });
    }
  }, [
    location,
    templateId,
    userExternalId,
    orgId,
    currentStep,
    subscriptionPlanTier,
    referrer,
  ]);
}

function useOnboardingCompleted() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const flow = searchParams.get('flow');
  const { id: orgId } = useSelector(state => state.org);
  const { id: userId } = useSelector(state => state.user);

  useEffect(() => {
    if (flow) {
      segmentTrack(EVENT.ONBOARDING_COMPLETED, {
        user_id: userId,
        organiser_id: orgId,
      });

      dispatch(setFlow(flow));

      searchParams.delete('flow');
      history.replace({
        search: searchParams.toString(),
      });
    }
  }, [flow, orgId, userId, history, searchParams, dispatch]);
}

export { usePageViews, useOnboardingCompleted };
