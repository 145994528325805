import { LoadSlice } from '@hopin-team/load-slice';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { FE_SLICES_CDN } from '@util/config';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import React from 'react';
import styled from 'styled-components';

import { dependencies } from '~/package.json';

const Wrapper = styled.div`
  width: 70vw;
  height: 60vh;
`;

const VerticalContainer = styled(Flex)`
  height: 100%;
`;

const slice = '@hopin-team/slice-expo-booth';

const LoadingComponent = () => (
  <VerticalContainer alignItems="center" justifyContent="center">
    <Spinner isShowing pattern="jumbo" data-testid="expo-booth-slice-spinner" />
  </VerticalContainer>
);

const BoothSliceComponent = () => {
  return (
    <Wrapper>
      <LoadSlice
        package={slice}
        assetUrl={FE_SLICES_CDN}
        loadingComponent={<LoadingComponent />}
        loadUsing="window"
        dependencies={dependencies}
      />
    </Wrapper>
  );
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.ADD_EXPO_BOOTH,
  }),
)(BoothSliceComponent);
