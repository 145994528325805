import CsrfInput from '@components/csrf-input';
import SaveBar from '@components/save-bar';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { shape, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { Wrapper } from './marketing-settings.styles';
import useMarketingSettingsForm from './use-marketing-settings-form';

const MarketingSettingsComponent = ({ id, attributes }) => {
  const { t } = useLocalization('marketing-settings');
  const { addAlert } = useContext(AlertsContext);

  const handleSuccess = () => {
    addAlert({
      active: true,
      text: t('success-alert-message'),
      pattern: 'success',
    });
  };

  const handleError = errors => {
    errors.forEach(error => {
      addAlert({
        active: true,
        text: error.detail,
        pattern: 'error',
      });
    });
  };

  const {
    errors: { marketing_settings: errors },
    onSubmit,
    register,
    reset,
  } = useMarketingSettingsForm({
    id,
    attributes,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { shortDescription, analyticsCode } = attributes;
  useEffect(() => {
    reset({
      marketing_settings: {
        analytics_code: analyticsCode,
        short_description: shortDescription,
      },
    });
  }, [reset, analyticsCode, shortDescription]);

  return (
    <Wrapper data-testid="marketing-settings-component">
      <Alerts />
      <form onSubmit={onSubmit}>
        <CsrfInput />
        <Text element="p" mt={3}>
          {t('summary')}
        </Text>
        <Text element="h2" pattern="headingFour" mt={3}>
          {t('sharing-snippet')}
        </Text>
        <TextField
          size="small"
          label={t('sharing-snippet-label')}
          mt={2}
          name="marketing_settings[short_description]"
          ref={register}
          hasErrors={errors?.short_description}
          errorMessage={errors?.short_description?.message}
          maxLength={120}
        />
        <Text element="h2" pattern="headingFour" mt={3}>
          {t('google-analytics')}
        </Text>
        <TextField
          size="small"
          label={t('google-analytics-label')}
          mt={2}
          name="marketing_settings[analytics_code]"
          ref={register}
          hasErrors={errors?.analytics_code}
          errorMessage={errors?.analytics_code?.message}
        />
        <SaveBar />
      </form>
    </Wrapper>
  );
};

MarketingSettingsComponent.propTypes = {
  id: string.isRequired,
  attributes: shape({
    analyticsCode: string,
    shortDescription: string,
  }),
};

export default withAlertsProvider(MarketingSettingsComponent);
