import '@rce-web/ui-upgrade-dialog/dist/styles.css';
import '../../style/upgrade-dialog-styles.css';

import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import { UpgradeDialog } from '@rce-web/ui-upgrade-dialog';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;
const PrimaryText = styled(Text)`
  color: #323439;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
`;

const SecondaryText = styled(Text)`
  color: #72757a;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
`;

const ChoosePlanButton = styled(Button)`
  width: 216px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  background-color: #1d6eaa;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

export default function UpgradeBanner() {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
  const { t } = useLocalization();
  const { organization, user } = useSelector(({ organization, user }) => ({
    organization,
    user,
  }));
  const { firstName, lastName, email } = user;
  const { name: organisationName, externalId: organisationId } = organization;

  const upgradeDialogLead = {
    firstName,
    lastName,
    email,
    organisationName,
  };
  const upgradeDialogOptions = {
    host: '',
    organisationId,
    source: 'event_dashboard',
  };

  return (
    <Container my={25}>
      <img
        src="/images/AttendeesUpgrade.png"
        alt=""
        width="160"
        height="160"
        draggable="false"
      />
      <Body>
        <PrimaryText>{t('people.attendees.upgrade-banner.title')}</PrimaryText>
        <SecondaryText>
          {t('people.attendees.upgrade-banner.description')}
        </SecondaryText>
      </Body>
      <ChoosePlanButton
        onClick={() => {
          setUpgradeDialogOpen(true);
        }}
        pattern="filled"
        kind="primary"
        size="medium"
        className="!w-64 rounded-lg"
      >
        {t('people.attendees.upgrade-banner.choosePlan')}
      </ChoosePlanButton>
      <UpgradeDialog
        isOpen={upgradeDialogOpen}
        lead={upgradeDialogLead}
        onClose={() => {
          setUpgradeDialogOpen(false);
        }}
        options={upgradeDialogOptions}
        translations={t('upgrade-dialog')}
      />
    </Container>
  );
}
