import { CUSTOM_DOMAIN_SETTINGS_PENDING_STATUSES } from './custom-domain-settings-constants';

export const isStatusPending = (status, errors) =>
  errors?.length === 0 &&
  (!status || CUSTOM_DOMAIN_SETTINGS_PENDING_STATUSES.includes(status));

export const isStatusError = errors => errors?.length !== 0;

export const isStatusSuccess = (status, errors) =>
  !isStatusPending(status, errors) && !isStatusError(errors);
