import Loading from '@features/event-creation-wizard/components/loading';
import TemplateCard from '@features/event-creation-wizard/components/template-card';
import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import {
  routes,
  stepMap,
} from '@features/event-creation-wizard/constants/step-map';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { setAddOns } from '@features/event-creation-wizard/redux/add-ons/add-ons-slice';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import { getTemplateSteps } from '@features/event-creation-wizard/shared/useStepsInfo';
import { useLocalization } from '@features/localization';
import { Butticon } from '@hopin-team/ui-butticon';
import { Flex } from '@hopin-team/ui-flex';
import { Grid } from '@hopin-team/ui-grid';
import { Text } from '@hopin-team/ui-text';
import segmentTrack from '@util/analytics/segment-client';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useEventCreationWizard } from '../../event-creation-wizard-provider';
import { setTemplate } from '../../redux/step-specific-state/template-slice';
import { Body, LoadingContainer, Logo, TopBar } from './styles';

const TemplatePickerComponent = () => {
  const {
    id: orgId,
    isOnAdvancedPlan,
    isOnFreePlan,
    subscriptionPlanTier,
    features,
  } = useSelector(state => state?.org);
  const { type: eventFormat } = useSelector(state => state.eventLocation);

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t } = useLocalization('event-creation-wizard');
  const { submitting } = useEventCreationWizard();
  const userEventPurposes =
    useSelector(state => state.user.event_purpose) || [];

  /**
   * When SKU "Events Lite" is available for organizer's license,
   * following templates are not available upon Event creation:
   * webinar, workshop, meetup
   */
  const reduceTemplates = template => {
    if (!features.eventsLiteEnabled) {
      return true;
    }

    if (['webinar', 'workshop', 'meetup'].includes(template)) {
      return false;
    }

    return true;
  };

  const noneExperienceTemplates = template =>
    !Object.values(EXPERIENCE_TEMPLATE).includes(template);

  const canShowTemplate = template => {
    const isTemplateVisible = !template.hidden;
    const isFormatHybrid = eventFormat === 'hybrid';
    const isTemplateAvailableInPerson =
      template.steps[0].options.canSetEventLocation;

    if (isTemplateVisible && !isFormatHybrid) {
      return true;
    } else if (
      isTemplateVisible &&
      isFormatHybrid &&
      isTemplateAvailableInPerson
    ) {
      return true;
    } else {
      return false;
    }
  };

  const templateList = Object.keys(templates)
    .filter(reduceTemplates)
    .filter(noneExperienceTemplates)
    .filter(template => canShowTemplate(templates[template]));

  const selectTemplate = async templateId => {
    segmentTrack(EVENT.EVENT_TEMPLATE_SELECTED, {
      organiser_id: orgId,
      event_type: templateId,
      organisation_subscription_tier: subscriptionPlanTier,
    });

    dispatch(setTemplate(templateId));

    const templateData = templates[templateId];
    dispatch(setVenue({ venue: templateData.defaultVenue, isOnFreePlan }));
    dispatch(setAddOns(templateData.defaultAddOns));

    const { name: firstStepName } = getTemplateSteps(templateId)[0];
    push(stepMap[firstStepName].path(templateId, eventFormat));
  };

  const previewTemplate = templateId => {
    segmentTrack(EVENT.TEMPLATE_PREVIEW, {
      organiser_id: orgId,
      event_type: templateId,
      organisation_subscription_tier: subscriptionPlanTier,
    });
    push(routes.preview(templateId, eventFormat));
  };

  const handleBack = async () => {
    push(routes.formatPicker());
  };

  if (submitting) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <main data-testid="event-cretion-wizard-template-picker">
      <TopBar>
        <Butticon
          onClick={handleBack}
          icon="arrow-back"
          size="medium"
          p={1.75}
          aria-label={t('common.close')}
          data-testid="template-picker-back-button"
        />
        <Logo src={'/images/logo-ringcentral.svg'} alt={'RingCentral'} />
      </TopBar>
      <Body>
        <Flex flexDirection="column">
          <Text element="p" pattern="headingTwo" color="grey-800" pt={4} pb={1}>
            {orgId ? t('templates.title') : t('templates.first-event-title')}
          </Text>
          <Text element="p" pattern="subHeading" color="grey-700">
            {t('templates.sub-title')}
          </Text>
        </Flex>
        <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="1.5rem 2rem" py={4}>
          {templateList.map(template => (
            <TemplateCard
              template={template}
              key={template}
              title={t(`templates.${template}.title`)}
              description={
                isOnAdvancedPlan
                  ? t(`templates.${template}.description-hybrid`)
                  : t(`templates.${template}.description`)
              }
              image={templates[template].image}
              selectTemplate={selectTemplate}
              previewTemplate={previewTemplate}
              recommended={userEventPurposes[0] === template}
            />
          ))}
        </Grid>
      </Body>
    </main>
  );
};

export default TemplatePickerComponent;
