import styled from 'styled-components';

export const Styles = {};

Styles.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--color-nav-background);
  color: var(--color-gray-800);
  word-break: break-word;
`;
