const sponsors = {
  i18nKey: 'add-ons.sponsors',
};

const speakers = {
  i18nKey: 'add-ons.speakers',
};

const replay = {
  i18nKey: 'add-ons.replay',
  premium: true,
};

const q_and_a = {
  i18nKey: 'add-ons.q_and_a',
};

const polls = {
  i18nKey: 'add-ons.polls',
};

const chat = {
  i18nKey: 'add-ons.chat',
};

const networking = {
  i18nKey: 'event-areas.areas.networking.title',
};

const sessions = {
  i18nKey: 'event-areas.areas.sessions.title',
};

const expo = {
  i18nKey: 'event-areas.areas.expo.title',
};

export const featuresConfig = {
  sponsors,
  speakers,
  networking,
  replay,
  q_and_a,
  polls,
  chat,
  sessions,
  expo,
};
