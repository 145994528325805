import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

import { MASK_PLACEHOLDER } from '../../utils';
import { StyledInput } from './timestamp-input.styled';
import { mapFormatToMask } from './utils';

export const TimestampInput = ({ format = 'HH:mm:ss', ...inputMaskProps }) => {
  const timestampMask = mapFormatToMask(format);

  return (
    <InputMask
      mask={timestampMask}
      maskPlaceholder={MASK_PLACEHOLDER}
      {...inputMaskProps}
    >
      <StyledInput />
    </InputMask>
  );
};

TimestampInput.propTypes = {
  format: PropTypes.string,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.string,
};
