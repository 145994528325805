import { useLocalization } from '@features/localization';
import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getTabs } from './constants/tabs';
import ChecklistTabpanels from './partials/checklist-tabpanels';
import ChecklistTabs from './partials/checklist-tabs';
import ChecklistWrapper from './styled/checklist-wrapper';
import ProgressBar from './styled/progress-bar';
import TabsColumn from './styled/tabs-column';
import { isTabApplicable, isTabComplete } from './utils/tabs';

const EventProgressComponent = ({
  canPublish,
  checklistStatuses,
  eventSlug,
  isLoading,
  isVisible,
  isPublishing,
  onPreview,
  onPublish,
  onSkipLandingPage,
  onSkipSpeakerProfiles,
  onToggleVisibility,
}) => {
  const firstIncompleteTabHasFocused = useRef(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { t } = useLocalization();
  const tabs = useMemo(() => getTabs(t), [t]);

  useEffect(() => {
    if (checklistStatuses && !firstIncompleteTabHasFocused.current) {
      const applicableTabs = tabs.filter(tab =>
        isTabApplicable(tab, checklistStatuses),
      );
      const firstIncompleteTabIndex =
        applicableTabs.reduce((index, tab, ix) => {
          if (index === null && !isTabComplete(tab, checklistStatuses, true)) {
            return ix;
          }
          return index;
        }, null) ?? applicableTabs.length - 1;

      firstIncompleteTabHasFocused.current = true;
      setSelectedTabIndex(firstIncompleteTabIndex);
    }
  }, [checklistStatuses, tabs]);

  const handleTabClick = tabIndex => {
    setSelectedTabIndex(tabIndex);
  };

  const applicableStepsCount =
    checklistStatuses &&
    Object.values(checklistStatuses).filter(
      status => status !== 'not_applicable',
    ).length;
  const completeStepsCount =
    checklistStatuses &&
    Object.values(checklistStatuses).filter(status => status === 'completed')
      .length;

  return (
    <ChecklistWrapper
      aria-labelledby="event-setup-checklist-title"
      role="complementary"
      data-testid="event-setup-checklist"
    >
      <TabsColumn isVisible={isVisible && !isLoading}>
        <Text
          pattern="headingFour"
          element="h2"
          id="event-setup-checklist-title"
        >
          {t('overview.event-setup-checklist.heading')}
        </Text>
        <Spinner isShowing={isLoading} mt={2} />
        {!isLoading && (
          <ProgressBar
            label={t('overview.event-setup-checklist.progress-bar-label')}
            min={0}
            max={applicableStepsCount}
            step={1}
            value={completeStepsCount}
          />
        )}
        {isVisible && !isLoading && (
          <>
            <ChecklistTabs
              checklistStatuses={checklistStatuses}
              onTabClick={handleTabClick}
              selectedTabIndex={selectedTabIndex}
              tabs={tabs}
            />
          </>
        )}
      </TabsColumn>
      {!isLoading && (
        <ChecklistTabpanels
          canPublish={canPublish}
          checklistStatuses={checklistStatuses}
          checklistIsVisible={isVisible}
          eventSlug={eventSlug}
          isPublishing={isPublishing}
          onPreview={onPreview}
          onPublish={onPublish}
          onSkipLandingPage={() => {
            setSelectedTabIndex(1);
            onSkipLandingPage();
          }}
          onSkipSpeakerProfiles={() => {
            setSelectedTabIndex(3);
            onSkipSpeakerProfiles();
          }}
          onToggleVisibility={onToggleVisibility}
          selectedTabIndex={selectedTabIndex}
          tabs={tabs}
        />
      )}
    </ChecklistWrapper>
  );
};

EventProgressComponent.propTypes = {
  canPublish: bool,
  checklistStatuses: object,
  eventSlug: string.isRequired,
  isVisible: bool,
  isLoading: bool,
  isPublishing: bool,
  onPreview: func.isRequired,
  onPublish: func.isRequired,
  onSkipLandingPage: func.isRequired,
  onSkipSpeakerProfiles: func.isRequired,
  onToggleVisibility: func.isRequired,
};

export default EventProgressComponent;
