import { Flex } from '@hopin-team/ui-flex';
import { TextField } from '@hopin-team/ui-text-field';
import styled, { css } from 'styled-components';

export const questionCardStyles = {
  pt: 3.5,
  pb: 6,
  px: 5,
  mt: 3,
  mx: 'auto',
  background: 'grey-white',
  border: 'grey-300',
  cornerRadius: 'lg',
};

export const QuestionCard = styled(Flex)`
  width: 50.5rem;

  @media (max-width: 51rem) {
    width: calc(100% - 3rem);
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
`;

export const QuestionWrapper = styled.div`
  width: 95%;
`;

export const iconStyles = css`
  padding: 0.4rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-width: 0.875rem;
    min-height: 0.875rem;
  }
  color: var(--color-white);
`;

export const StyledTextField = styled(TextField)`
  .hpn-Box .hpn-Icon {
    ${iconStyles}
    background: ${({ background }) => background};
  }
`;
