import React from 'react';

const Print = ({ fillColor = '#757A8A', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 16 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8333 2.16667V3.83333C14.7538 3.83333 15.5 4.57953 15.5 5.5V10.0833C15.5 10.3135 15.3135 10.5 15.0833 10.5H13.8333V13.8333C13.8333 14.7538 13.0871 15.5 12.1667 15.5H3.83333C2.91286 15.5 2.16667 14.7538 2.16667 13.8333V10.5H0.916667C0.686548 10.5 0.5 10.3135 0.5 10.0833V5.5C0.5 4.57953 1.24619 3.83333 2.16667 3.83333V2.16667C2.16667 1.24619 2.91286 0.5 3.83333 0.5H12.1667C13.0871 0.5 13.8333 1.24619 13.8333 2.16667ZM12.1667 2.16667H3.83333V3.83333H12.1667V2.16667ZM12.1667 13.8333H3.83333V8.83333H12.1667V13.8333ZM13.4167 7.16667C13.6468 7.16667 13.8333 6.98012 13.8333 6.75V5.91667C13.8333 5.68655 13.6468 5.5 13.4167 5.5H12.5833C12.3532 5.5 12.1667 5.68655 12.1667 5.91667V6.75C12.1667 6.98012 12.3532 7.16667 12.5833 7.16667H13.4167Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Print;
