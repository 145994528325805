import Warning from '@components/icons/warning';
import {
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import compose from 'lodash/fp/compose';
import { func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Anchor } from './post-signup-view';

const BannerStyles = styled.header`
  position: sticky;
  top: 0;
  height: var(--spacing-48);
  background-color: var(--color-blue-100);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-650);
`;

const PostSignupBanner = ({ t }) => {
  return (
    <>
      <BannerStyles>
        <Warning />
        <Text pattern="body" mx={1}>
          {t('signup.banner.copy')}
        </Text>
        <Anchor data-testid="resend-link" href="/users/confirmation/new">
          {t('signup.banner.link')}
        </Anchor>
      </BannerStyles>
    </>
  );
};

PostSignupBanner.propTypes = {
  t: func.isRequired,
};

export default compose(
  withLocalizationProvider,
  withLocalization,
)(PostSignupBanner);
