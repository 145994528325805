import { BANNER_EVENTS } from '@features/integration/banner/banner-events';
import track, { isSegmentClientReady } from '@util/analytics/segment-client';
import { useCallback, useEffect } from 'react';

export const useBannerEvents = ({ isVisible, userId, organizationId }) => {
  useEffect(() => {
    if (!isSegmentClientReady()) {
      console.warn('Segment client is not ready');
      return;
    }

    track(BANNER_EVENTS.GROUP_ASSIGNED, {
      user_id: userId,
      organization_id: organizationId,
      experiment_version: isVisible,
    });

    if (isVisible) {
      track(BANNER_EVENTS.BANNER_VIEWED, {
        user_id: userId,
        organization_id: organizationId,
      });
    }
  }, []);

  const clickBanner = useCallback(() => {
    if (!isSegmentClientReady()) {
      console.warn('Segment client is not ready');
      return;
    }

    track(BANNER_EVENTS.BANNER_CLICKED, {
      user_id: userId,
      organization_id: organizationId,
    });
  }, []);

  return {
    clickBanner,
  };
};
