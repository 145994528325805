import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DotWrapper = styled.div`
  padding: 0 var(--spacing-8);
`;

export const Dot = styled.div`
  width: var(--spacing-8);
  height: var(--spacing-8);
  background-color: ${props => `var(${props.color})`};
  border-radius: 50%;
`;

const StatusDot = ({ color }) => (
  <DotWrapper>
    <Dot color={color} data-testid="invites-status-dot" />
  </DotWrapper>
);

StatusDot.propTypes = {
  color: PropTypes.string.isRequired,
};

export default StatusDot;
