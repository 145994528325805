export const images = {
  create: {
    '1x': require(`post-registration/create-event@1x.png`),
    '2x': require(`post-registration/create-event@2x.png`),
  },
  learn: {
    '1x': require(`post-registration/learn-more@1x.png`),
    '2x': require(`post-registration/learn-more@2x.png`),
  },
  explore: {
    '1x': require(`post-registration/explore@1x.png`),
    '2x': require(`post-registration/explore@2x.png`),
  },
};
