import { Body, Footer, Header, Wrapper } from '@components/side-panel-form';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import { array, bool, func, object } from 'prop-types';
import React, { useContext, useState } from 'react';

import {
  CreateSponsorButton,
  SponsorRow,
  SponsorRowLogo,
  SponsorRowName,
  StyledIcon,
} from './add-sponsors-modal.styled';
// TODO: Use planetary icons once these are published
import IconCheckCircle from './icons/icon-check-circle.svg';
import IconPlusCircle from './icons/icon-plus-circle.svg';

function AddSponsorRow({
  sponsor,
  onSelectSponsor,
  onUnselectSponsor,
  isSponsorSelected,
}) {
  const { t } = useContext(LocalizationContext);

  return (
    <SponsorRow
      as="button"
      aria-label={
        isSponsorSelected
          ? t('sponsors.import-modal.deselect-item', { name: sponsor.name })
          : t('sponsors.import-modal.select-item', { name: sponsor.name })
      }
      onClick={isSponsorSelected ? onUnselectSponsor : onSelectSponsor}
    >
      <SponsorRowLogo>
        <img src={sponsor.logo} alt={sponsor.name} />
      </SponsorRowLogo>
      <SponsorRowName>{sponsor.name}</SponsorRowName>

      <StyledIcon as={isSponsorSelected ? IconCheckCircle : IconPlusCircle} />
    </SponsorRow>
  );
}

AddSponsorRow.propTypes = {
  sponsor: object.isRequired,
  onSelectSponsor: func.isRequired,
  onUnselectSponsor: func.isRequired,
  isSponsorSelected: bool.isRequired,
};

export default function AddSponsorsModal({
  availableSponsors,
  selectedSponsorIds: initialSelectedSponsorIds,
  onCreateNew,
  onSave,
}) {
  const { t } = useContext(LocalizationContext);
  const [selectedSponsorIds, setSelectedSponsorIds] = useState(
    initialSelectedSponsorIds,
  );

  const selectSponsor = sponsor => {
    setSelectedSponsorIds([...selectedSponsorIds, sponsor.id]);
  };

  const unselectSponsor = sponsor => {
    setSelectedSponsorIds(selectedSponsorIds.filter(s => s !== sponsor.id));
  };

  const isSponsorSelected = sponsor => {
    return selectedSponsorIds.includes(sponsor.id);
  };

  return (
    <Wrapper data-testid="import-sponsors-form">
      <Header>
        <Text color="grey-800" pattern="headingThree">
          {t('sponsors.import-modal.title')}
        </Text>
      </Header>
      <Body>
        {availableSponsors.map(sponsor => (
          <AddSponsorRow
            sponsor={sponsor}
            key={sponsor.id}
            onSelectSponsor={() => selectSponsor(sponsor)}
            onUnselectSponsor={() => unselectSponsor(sponsor)}
            isSponsorSelected={isSponsorSelected(sponsor)}
          />
        ))}
        <CreateSponsorButton
          onClick={() => {
            onCreateNew();
          }}
        >
          <StyledIcon as={IconPlusCircle} />
          <Text
            pattern="body"
            font="text"
            weight="bold"
            color="gray-700"
            mx={2}
          >
            {t('sponsors.import-modal.create-new-button')}
          </Text>
        </CreateSponsorButton>
      </Body>

      <Footer>
        <Button
          data-testid="submit-button"
          isInline
          onClick={() => onSave(selectedSponsorIds)}
        >
          {t('save')}
        </Button>
      </Footer>
    </Wrapper>
  );
}

AddSponsorsModal.propTypes = {
  availableSponsors: array.isRequired,
  selectedSponsorIds: array.isRequired,
  onCreateNew: func.isRequired,
  onSave: func.isRequired,
};
