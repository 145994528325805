import { bool, string } from 'prop-types';
import React from 'react';

const NoticesComponent = ({
  eventSlug,
  eventPhase,
  hasTickets,
  newRegistrationUrl,
}) => {
  const registrationUrl = newRegistrationUrl + '/tickets?slug=' + eventSlug;

  return (
    <>
      {eventPhase == 'Upcoming' && (
        <div className="alert alert-light text-secondary border mt-2 alert-dismissible fade show">
          <div className="media pt-2 pb-2">
            <i className="fa fa-bell-on mr-3"></i>
            <div className="media-body">
              <h6
                className="mt-0 mb-0 pt-2 text-secondary"
                style={{ fontSize: '14px' }}
              >
                Hopin automatically sends a reminder email 24 hours before and 1
                hour before the event begins to your guests.
              </h6>
            </div>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      {eventPhase == 'Draft' && (
        <div className="alert alert-warning text-secondary border mt-2 alert-dismissible fade show">
          <div className="media pt-2 pb-2">
            <i className="fa fa-pencil mr-3"></i>
            <div className="media-body">
              <h6
                className="mt-0 mb-0 pt-2 text-secondary"
                style={{ fontSize: '14px' }}
              >
                <span className="text-primary font-weight-bolder">
                  Draft Mode.
                </span>
                &nbsp; Only <b>organizers</b> can access a preview of the event,
                attendees will not be able to access the page until it is
                published.
              </h6>
            </div>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      {!hasTickets && (
        <div className="alert alert-light bg-white text-secondary border mt-2 alert-dismissible fade show">
          <div className="media pt-2 pb-2">
            <i className="fa fa-ticket mr-3"></i>
            <div className="media-body">
              <h6
                className="mt-0 mb-0 pt-2 text-secondary"
                style={{ fontSize: '14px' }}
              >
                <span className="text-danger">No tickets.</span>
                &nbsp; You have not added tickets to this event,
                <a href={registrationUrl}> add a ticket </a>
                for attendees to register with.
              </h6>
            </div>
          </div>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
    </>
  );
};

NoticesComponent.propTypes = {
  eventSlug: string.isRequired,
  eventPhase: string.isRequired,
  hasTickets: bool.isRequired,
  newRegistrationDashboardEnabled: bool.isRequired,
  newRegistrationUrl: string.isRequired,
};

export default NoticesComponent;
