import styled, { css } from 'styled-components';

import { excludeLegacyBrowsersSelector } from '../range-selector.styled';

export const RANGE_SELECTOR_WIDTH = '100%';
export const RANGE_SELECTOR_HEIGHT_PX = 20;
export const RANGE_SELECTOR_THUMB_WIDTH_PX = 8;
export const PRIMARY_BLUE = css`var(--color-primary-400)`;
export const GRAY_BACKGROUND = css`var(--color-gray-200)`;
export const LIGHT_BLUE_BACKGROUND = css`var(--color-blue-100)`;
export const THUMB_COLOR = PRIMARY_BLUE;
export const THUMB_COLOR_ON_FOCUS_VISIBLE = css`var(--color-gray-900)`;

const CSS_VAR_START = '--s';
const CSS_VAR_START_OFFSET = '--so';
const CSS_VAR_END = '--e';
const CSS_VAR_END_OFFSET = '--eo';

/**
 * @param {number} n a fraction between 0 and 1
 * @returns {string} the corresponding percentage, rounded to 3 decimal places
 */
const toRoundedPercent = n => `${Math.round(n * 100000) / 1000}%`;

/**
 * @param {{start: number, max: number}}
 * @returns {string} the start point of the range selection, as a percentage
 */
const startPercent = ({ start, max }) => toRoundedPercent(start / max);

/**
 * @param {{start: number, max: number}}
 * @returns {string} number of pixels by which the background gradients should
 *   be offset from the start point to avoid crashing out of the inputs’ thumbs!
 */
const startOffset = ({ start, max }) =>
  `${start / max < 0.5 ? RANGE_SELECTOR_THUMB_WIDTH_PX / 2 : 0}px`;

/**
 * @param {{end: number, max: number}}
 * @returns {string} the end point of the range selection, as a percentage
 */
const endPercent = ({ end, max }) => toRoundedPercent(end / max);

/**
 * @param {{end: number, max: number}}
 * @returns {string} negative number of pixels by which the background gradients
 *   should be offset from the end point to avoid crashing out of the inputs’ thumbs!
 */
const endOffset = ({ end, max }) =>
  `-${end / max > 0.5 ? RANGE_SELECTOR_THUMB_WIDTH_PX / 2 : 0}px`;

const startBackgroundPosition = css`
calc(var(${CSS_VAR_START}) + var(${CSS_VAR_START_OFFSET}))
`;

const endBackgroundPosition = css`
calc(var(${CSS_VAR_END}) + var(${CSS_VAR_END_OFFSET}))
`;

const background = css`
  background:
    /* On top: Hopin gray background overlaying the blue horizontal lines */ linear-gradient(
      to right,
      ${GRAY_BACKGROUND} ${startBackgroundPosition},
      transparent ${startBackgroundPosition},
      transparent ${endBackgroundPosition},
      ${GRAY_BACKGROUND} ${endBackgroundPosition}
    ),
    /* Next: blue horizontal lines */
      linear-gradient(to bottom, ${PRIMARY_BLUE} 2px, transparent 2px) no-repeat,
    linear-gradient(to top, ${PRIMARY_BLUE} 2px, transparent 2px) no-repeat,
    /* On the bottom: Hopin light blue background behind the lines */
      linear-gradient(
        to right,
        transparent ${startBackgroundPosition},
        ${LIGHT_BLUE_BACKGROUND} ${startBackgroundPosition},
        ${LIGHT_BLUE_BACKGROUND} ${endBackgroundPosition},
        transparent ${endBackgroundPosition}
      ),
    ${GRAY_BACKGROUND};
`;

export const InputContainer = styled.div.attrs(({ start, end, max }) => ({
  role: 'group',
  style: {
    [CSS_VAR_START]: startPercent({ start, max }),
    [CSS_VAR_START_OFFSET]: startOffset({ start, max }),
    [CSS_VAR_END]: endPercent({ end, max }),
    [CSS_VAR_END_OFFSET]: endOffset({ end, max }),
  },
}))`
  &${excludeLegacyBrowsersSelector} {
    display: grid;
    grid-template-rows: max-content ${RANGE_SELECTOR_HEIGHT_PX}px;
  }
  margin: ${RANGE_SELECTOR_HEIGHT_PX}px auto;
  width: ${RANGE_SELECTOR_WIDTH};
  border-radius: 4px;

  ${background}
`;
