import { Badge } from '@hopin-team/ui-badge';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SuccessSvg = ({ className }) => (
  <svg
    role="presentation"
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="16"
      height="16"
      rx="8"
      fill="white"
      stroke="#1d6eaa"
      strokeWidth="2"
    />
    <path
      d="M6.16992 9.08652L8.56992 11.4865L12.4271 6.68652"
      stroke="#1d6eaa"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SuccessSvg.propTypes = {
  className: string,
};

export const SuccessCheckmark = styled(SuccessSvg)`
  margin-right: ${rem(8)};
  position: relative;
  top: ${rem(1)};
`;

export const LiveBadge = styled(Badge)`
  color: var(--color-white);
  font-size: ${rem(12)};
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.6666666667;
  order: -1;
  align-self: center;
`;

export const EndedBadge = styled(Badge)`
  font-size: ${rem(12)};
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.6666666667;
  order: -1;
  align-self: center;
  background-color: var(--color-gray-800);
  min-width: 4rem;
`;

export const Title = styled(Text)`
  display: flex;
`;
