import { rcTheme } from '@hopin-team/ui-symbols';
import getDisplayName from '@util/get-display-name';
import React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

export const withThemeProvider = WrappedComponent => {
  const WithThemeProvider = props => {
    const colorBranding = {
      COLOR: {
        THEME: {
          DEFAULT: 'var(--color-primary-400)',
          PRIMARY: 'var(--color-primary-400)',
          SECONDARY: 'var(--color-primary-400)',
          TERTIARY: 'var(--color-primary-400)',
        },
        GREY: {
          100: 'var(--color-gray-100)',
          200: 'var(--color-gray-200)',
          300: 'var(--color-gray-300)',
          400: 'var(--color-gray-400)',
          500: 'var(--color-gray-500)',
          600: 'var(--color-gray-600)',
          700: 'var(--color-gray-700)',
          800: 'var(--color-gray-800)',
          900: 'var(--color-gray-900)',
          WHITE: 'var(--color-white)',
          BLACK: 'var(--color-black)',
          DARK: 'var(--color-gray-800)',
        },
        UTILITY: {
          ACTION: 'var(--color-primary-400)',
          DEFAULT: 'var(--color-primary-400)',
        },
        BLUE: {
          100: 'var(--color-primary-100)',
          200: 'var(--color-primary-200)',
          300: 'var(--color-primary-300)',
          400: 'var(--color-primary-400)',
          500: 'var(--color-primary-500)',
          // This is a sketchy mapping
          600: 'var(--color-primary-500)',
          700: 'var(--color-primary-500)',
        },
        GREEN: {
          100: 'var(--color-green-100)',
          200: 'var(--color-green-200)',
          300: 'var(--color-green-300)',
          400: 'var(--color-green-400)',
          500: 'var(--color-green-500)',
          600: 'var(--color-green-600)',
          700: 'var(--color-green-700)',
          800: 'var(--color-green-800)',
          900: 'var(--color-green-900)',
        },
        ORANGE: {
          100: 'var(--color-orange-100)',
          200: 'var(--color-orange-200)',
          300: 'var(--color-orange-300)',
          400: 'var(--color-orange-400)',
          500: 'var(--color-orange-500)',
          600: 'var(--color-orange-600)',
          700: 'var(--color-orange-700)',
          800: 'var(--color-orange-800)',
          900: 'var(--color-orange-900)',
        },
        PURPLE: {
          100: 'var(--color-purple-100)',
          200: 'var(--color-purple-200)',
          300: 'var(--color-purple-300)',
          400: 'var(--color-purple-400)',
          500: 'var(--color-purple-500)',
          600: 'var(--color-purple-600)',
          700: 'var(--color-purple-700)',
          800: 'var(--color-purple-800)',
          900: 'var(--color-purple-900)',
        },
        YELLOW: {
          100: 'var(--color-yellow-100)',
          200: 'var(--color-yellow-200)',
          300: 'var(--color-yellow-300)',
          400: 'var(--color-yellow-400)',
          500: 'var(--color-yellow-500)',
          600: 'var(--color-yellow-600)',
          700: 'var(--color-yellow-700)',
          800: 'var(--color-yellow-800)',
          900: 'var(--color-yellow-900)',
        },
        RED: {
          100: 'var(--color-red-100)',
          200: 'var(--color-red-200)',
          300: 'var(--color-red-300)',
          400: 'var(--color-red-400)',
          500: 'var(--color-red-500)',
          600: 'var(--color-red-600)',
          700: 'var(--color-red-700)',
          800: 'var(--color-red-800)',
          900: 'var(--color-red-900)',
        },
      },
      LINE: {
        ACCENT_BORDER: `0.125rem solid var(--color-primary-400)`,
        ACTIVE_BORDER: `${rcTheme.LINE.WIDTH} solid var(--color-primary-400)`,
        SELECTED_BORDER: `${rcTheme.LINE.WIDTH} solid var(--color-primary-400)`,
      },
    };

    return (
      <SCThemeProvider theme={colorBranding}>
        <WrappedComponent {...props} />
      </SCThemeProvider>
    );
  };
  WithThemeProvider.displayName = `WithThemeProvider(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithThemeProvider;
};
