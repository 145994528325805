import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { bool, func } from 'prop-types';
import React from 'react';

const Publish = ({ onPublish, canPublish, isPublishing }) => {
  const { t } = useLocalization('publishing');

  return (
    <Button
      data-testid="event-progress-publish-button"
      isInline
      px={3}
      onClick={onPublish}
      disabled={isPublishing || !canPublish}
      size="small"
    >
      {isPublishing ? t('publishing') : t('publish')}
    </Button>
  );
};

Publish.propTypes = {
  canPublish: bool,
  isPublishing: bool,
  onPublish: func.isRequired,
};

export default Publish;
