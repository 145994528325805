import { trackSegmentPageView } from '@util/analytics/segment-client';
import React from 'react';
import { useSelector } from 'react-redux';

let lastPageName = null;

// this is needed for product analytics on looker.
function withSegmentPageView(pageName) {
  return function (WrappedComponent) {
    return function (props) {
      try {
        // random double renders shouldn't send events back to back
        if (lastPageName != pageName) {
          const { event, registration } = useSelector(
            ({ event, registration }) => ({
              event,
              registration,
            }),
          );

          if (event && registration) {
            const {
              name: eventName,
              externalId: eventExternalId,
              organization: { externalId: organizationExternalId },
            } = event;
            const { externalId: registrationExternalId } = registration;

            trackSegmentPageView('Analytics', pageName, {
              event_id: eventExternalId,
              organisation_id: organizationExternalId,
              title: eventName,
              user_id: registrationExternalId, // yes, bad naming on the event schema :(
            });
            lastPageName = pageName;
          }
        }
      } catch (e) {
        console.log(e);
      }
      return <WrappedComponent {...props} />;
    };
  };
}

export { withSegmentPageView };
