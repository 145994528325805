import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: '',
};

export const welcomeMessageSlice = createSlice({
  name: 'event-creation-wizard-welcome-message',
  initialState,
  reducers: {
    setWelcomeMessage(state, action) {
      state.value = action.payload;
    },
  },
});

export const { setWelcomeMessage } = welcomeMessageSlice.actions;

export default welcomeMessageSlice.reducer;
