import { Button } from '@hopin-team/ui-button';
import { bool, element, func, object, oneOfType, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  width: auto;
  margin-left: var(--spacing-16);
  padding: var(--spacing-8) var(--spacing-16);
  font-weight: var(--font-weight-medium);
`;

const IconWrapper = styled.span`
  margin-right: var(--spacing-8);
  color: ${props => (props.cancel ? 'inherit' : 'var(--color-gray-600)')};
`;

const withConfirmation = (confirmMsg, callback) => {
  if (!callback) {
    return;
  }

  if (!confirmMsg || window.confirm(confirmMsg)) {
    callback();
  }
};

const ActionButton = ({
  icon,
  children,
  onClick,
  confirm = '',
  cancel = false,
  ...rest
}) => (
  <StyledButton
    onClick={() => withConfirmation(confirm, onClick)}
    size="medium"
    isOutlined
    pattern={cancel ? 'danger' : 'primary'}
    data-testid={`button-${children}`}
    {...rest}
  >
    {icon && <IconWrapper cancel={cancel}>{icon}</IconWrapper>}
    {children}
  </StyledButton>
);

ActionButton.propTypes = {
  icon: object,
  children: oneOfType([string, element]).isRequired,
  onClick: func,
  cancel: bool,
  confirm: string,
};

export default ActionButton;
