import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Select } from '@hopin-team/ui-select';
import { TextField } from '@hopin-team/ui-text-field';
import { noop, snakeCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import AddressPicker from './address-picker';

const InputWrapper = styled(Box)`
  max-width: ${({ fullWidth }) => (fullWidth ? 'none' : '360px')};
`;

const virtual = 'virtual';
const hybrid = 'hybrid';
const onsite = 'onsite';

const venueTypeOptionsMap = {
  [virtual]: [virtual, hybrid, onsite],
  [hybrid]: [hybrid, onsite],
  [onsite]: [onsite, hybrid],
};

export const venueTypeInputName = 'event[venue_type]';
const getVenueType = (type, t) => ({
  value: type,
  label: t(`${type}.label`),
  help: t(`${type}.help`),
});
const getVenueTypes = (t, initialVenueType) =>
  venueTypeOptionsMap[initialVenueType].map(type => getVenueType(type, t));

const EventVenueType = ({
  googleMapsApiKey,
  size,
  initialVenueType = virtual,
  initialAddress,
  venueTypeDisabled,
  fullWidth = true,
}) => {
  const { t } = useLocalization('event-venue-type');
  const [venueType, setVenueType] = useState(initialVenueType);
  const [address, setAddress] = useState(initialAddress);
  const venueTypes = useMemo(() => getVenueTypes(t, initialVenueType), [
    t,
    initialVenueType,
  ]);
  const hasPhysicalLocation = useMemo(
    () => [hybrid, onsite].includes(venueType),
    [venueType],
  );

  return (
    <Box>
      {address &&
        Object.keys(address).map(name => (
          <input
            key={name}
            type="hidden"
            name={`event[address_attributes][${snakeCase(name)}]`}
            value={address[name] || ''}
            onChange={noop}
          />
        ))}

      <input
        type="hidden"
        name={venueTypeInputName}
        value={venueType}
        onChange={noop}
      />

      <InputWrapper mb={hasPhysicalLocation ? 1.5 : 0} fullWidth={fullWidth}>
        <Select
          label={t('label')}
          options={venueTypes}
          value={venueType}
          setValue={setVenueType}
          helperText={
            venueTypes.find(option => option.value === venueType).help
          }
          size={size}
          disabled={venueTypeDisabled}
        />
      </InputWrapper>

      {hasPhysicalLocation && (
        <Box>
          <InputWrapper mb={1.5} fullWidth={fullWidth}>
            <TextField
              label={t('venue-name.label')}
              name="event[address_attributes][venue_name]"
              placeholder={t('venue-name.placeholder')}
              size={size}
              defaultValue={address?.venueName}
            />
          </InputWrapper>

          {googleMapsApiKey ? (
            <AddressPicker
              address={address}
              onAddressChange={setAddress}
              googleMapsApiKey={googleMapsApiKey}
              size={size}
            />
          ) : (
            <InputWrapper fullWidth={fullWidth}>
              <TextField
                label={t('address.label')}
                name="event[address_attributes][name]"
                placeholder={t('address.placeholder')}
                size={size}
                defaultValue={address?.name}
                required
              />
            </InputWrapper>
          )}
        </Box>
      )}
    </Box>
  );
};

EventVenueType.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
  size: PropTypes.string,
  initialAddress: PropTypes.object,
  initialVenueType: PropTypes.string,
  venueTypeDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default withLocalizationProvider(EventVenueType);
