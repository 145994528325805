import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  email: '',
  description: '',
};

const template = createSlice({
  name: 'expo',
  initialState,
  reducers: {
    setExpoDetails: (state, action) => action.payload,
  },
});

const {
  reducer,
  actions: { setExpoDetails },
} = template;

export { setExpoDetails };

export default reducer;
