import { Butticon } from '@hopin-team/ui-butticon';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { Flex } from '@hopin-team/ui-flex';
import { bool, func, number, string } from 'prop-types';
import React, { useState } from 'react';

const CategoryBlockControls = ({
  setIsModalDismissable,
  onConfirmDelete,
  index,
  isBlockOpen,
  setBlockOpen,
  reactHookFormId,
  lockProfileMatching,
  t,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const closeDialog = () => {
    setShowConfirmation(false);
    setIsModalDismissable(true);
  };

  return (
    <Flex>
      {!isBlockOpen && (
        <Butticon
          icon="edit"
          size="small"
          title={t('networking.profile-setup.edit-category')}
          type="button"
          onClick={() => setBlockOpen(reactHookFormId)}
        />
      )}
      <Butticon
        icon="delete"
        type="button"
        size="small"
        title={t('networking.profile-setup.delete-category')}
        onClick={() => {
          setShowConfirmation(true);
          setIsModalDismissable(false);
        }}
        disabled={lockProfileMatching}
      />
      <ConfirmationDialog
        isShowing={showConfirmation}
        title={t('networking.profile-setup.are-you-sure')}
        message={t('networking.profile-setup.are-you-sure-delete-category')}
        primaryButtonText={t('networking.profile-setup.delete')}
        secondaryButtonText={t('cancel')}
        colorPattern="danger"
        onConfirm={() => {
          onConfirmDelete(index);
          closeDialog();
        }}
        onCancel={closeDialog}
        withCloseButton
        onClose={closeDialog}
      />
    </Flex>
  );
};

export default CategoryBlockControls;

CategoryBlockControls.propTypes = {
  setIsModalDismissable: func.isRequired,
  onConfirmDelete: func.isRequired,
  index: number.isRequired,
  isBlockOpen: bool,
  lockProfileMatching: bool,
  setBlockOpen: func.isRequired,
  reactHookFormId: string.isRequired,
  t: func.isRequired,
};
