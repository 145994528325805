const getTicketsWithCountResponse = {
  personas: [
    {
      id: 1,
      label: 'Silver',
      attendees_status_data: {
        registered_count: 1,
        attended_count: 0,
        unattended_count: 1,
        magic_link_invited_count: 0,
      },
    },
    {
      id: 2,
      label: 'Business',
      attendees_status_data: {
        registered_count: 2,
        attended_count: 0,
        unattended_count: 2,
        magic_link_invited_count: 0,
      },
    },
    {
      id: 3,
      label: 'Student',
      attendees_status_data: {
        registered_count: 2,
        attended_count: 0,
        unattended_count: 2,
        magic_link_invited_count: 0,
      },
    },
  ],
};

const mockTicketLabels = ['Silver', 'Business', 'Student'];

export { getTicketsWithCountResponse, mockTicketLabels };
