import { motion } from 'framer-motion';
import { func, node, number } from 'prop-types';
import React, { useEffect } from 'react';

import { StyledAlertWrapper } from './alerts.styles';

const AlertWrapper = ({ onDismiss, timeout = 5000, children }) => {
  useEffect(() => {
    const timeoutId = setTimeout(onDismiss, timeout);
    return () => clearTimeout(timeoutId);
  }, [onDismiss, timeout]);

  return (
    <motion.div
      layout
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      initial={{ opacity: 0, y: -16 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -16 }}
    >
      <StyledAlertWrapper>{children}</StyledAlertWrapper>
    </motion.div>
  );
};

AlertWrapper.propTypes = {
  children: node,
  timeout: number,
  onDismiss: func,
};

export default AlertWrapper;
