import ArrowRight from 'icons/arrow-right.svg';
import { rem } from 'polished';
import { func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ScrollButton = styled.button`
  background: white;
  right: var(--spacing-4);
  cursor: pointer;
  border: ${rem(1)} solid var(--color-gray-300);
  border-radius: var(--border-radius);
  width: ${rem(32)};
  height: ${rem(34)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  box-shadow: var(--box-shadow-long);
`;

const ScrollLeftButton = styled(ScrollButton)`
  left: var(--spacing-4);
  svg {
    transform: rotate(180deg);
  }
`;

const LeftDirectionButton = ({ onClick }) => {
  return (
    <ScrollLeftButton onClick={onClick}>
      <ArrowRight />
    </ScrollLeftButton>
  );
};

const RightDirectionButton = ({ onClick }) => (
  <ScrollButton onClick={onClick}>
    <ArrowRight />
  </ScrollButton>
);

const propTypes = {
  onClick: func.isRequired,
};
LeftDirectionButton.propTypes = propTypes;
RightDirectionButton.propTypes = propTypes;

export { LeftDirectionButton, RightDirectionButton };
