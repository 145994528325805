import EmptyState from '@components/empty-state';
import { getPageConstants } from '@components/empty-state/constants/page';
import MarketoModal from '@components/marketo-modal/marketo-modal';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import compose from 'lodash/fp/compose';
import { string } from 'prop-types';
import React, { useState } from 'react';

import { StyledButton } from './styles';

const OnsiteAddOnEmptyState = ({ pageType }) => {
  const { t } = useLocalization();
  const pageConstants = getPageConstants(t)[pageType];
  const [isShowingModal, setIsShowingModal] = useState(false);

  const onButtonClick = () => {
    return setIsShowingModal(!isShowingModal);
  };

  return (
    <>
      <EmptyState pageType={pageType}>
        <StyledButton
          buttonText={pageConstants.buttonText}
          isInline
          isGhost
          size="medium"
          onClick={onButtonClick}
        >
          {pageConstants.buttonText}
        </StyledButton>
      </EmptyState>
      <MarketoModal
        isShowing={isShowingModal}
        setIsShowing={setIsShowingModal}
        campaign="in_product_contact_sales_onsite"
      />
    </>
  );
};

OnsiteAddOnEmptyState.propTypes = {
  pageType: string,
};

export default compose(withLocalizationProvider)(OnsiteAddOnEmptyState);
