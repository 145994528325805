import Card from '@components/card-with-loading';
import TabbedChart from '@components/tabbed-chart';
import Chart from '@components/tabbed-chart/chart';
import Header from '@components/tabbed-chart/header';
import { bool, number, string } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../util/hooks';
import { getMeetingsOverTime, getMessagesOverTime } from '../util/services';

const ConnectionsOverTime = ({ eventId, startTime, endTime, expanded }) => {
  const [data, setData] = useState(null);
  const [tab, setTab] = useState(0);

  const loading = usePromise(
    signal => [
      getMessagesOverTime(eventId, signal),
      getMeetingsOverTime(eventId, signal),
    ],
    setData,
  );

  if (expanded) {
    return (
      <>
        {data?.map((chart, i) => (
          <div
            className="row mb-3 no-page-break-inside"
            key={i}
            data-testid="connections-over-time"
          >
            <div className="col-12">
              <Card isLoading={loading}>
                <Header summary={chart.summary} />
                <Chart
                  chartData={chart.chartData}
                  chartType="line"
                  startTime={startTime}
                  endTime={endTime}
                />
              </Card>
            </div>
          </div>
        ))}
      </>
    );
  }
  return (
    <div
      className="row mb-3 no-page-break-inside"
      data-testid="connections-over-time"
    >
      <div className="col-12">
        <Card isLoading={loading}>
          <TabbedChart
            chartType="line"
            tab={tab}
            setTab={setTab}
            data={data}
            startTime={startTime}
            endTime={endTime}
          />
        </Card>
      </div>
    </div>
  );
};

ConnectionsOverTime.propTypes = {
  eventId: number.isRequired,
  startTime: string.isRequired,
  endTime: string.isRequired,
  expanded: bool,
};

export default ConnectionsOverTime;
