import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import { StyledOverlaidRangeInput } from './overlaid-range-input.styled';
import { adjustValue, setInputAriaValueAsTimeOffset } from './utils';

export const OverlaidRangeInput = props => {
  const inputRef = useRef(null);
  let {
    rangeMin,
    selectableMin = rangeMin,
    rangeMax,
    selectableMax = rangeMax,
    value = selectableMin,
    onChange,
    onInput,
    step = 1,
    ...restProps
  } = props;
  selectableMin = Math.max(rangeMin, selectableMin);
  selectableMax = Math.min(rangeMax, selectableMax);

  const createInputValidatorWithCallback = useCallback(
    callback => ({ nativeEvent }) => {
      const { target } = nativeEvent;
      if (!target) return;

      const value = adjustValue(+target.value, selectableMin, selectableMax);

      setInputAriaValueAsTimeOffset(inputRef.current, value);
      callback?.(value);
    },
    [selectableMax, selectableMin],
  );

  const onChangeHandler = createInputValidatorWithCallback(onChange);
  const onInputHandler = createInputValidatorWithCallback(onInput);

  useEffect(() => {
    setInputAriaValueAsTimeOffset(inputRef.current, value);
  }, [value]);

  return (
    <StyledOverlaidRangeInput
      ref={inputRef}
      min={rangeMin}
      step={step}
      max={rangeMax}
      onChange={onChangeHandler}
      onInput={onInputHandler}
      value={value}
      {...restProps}
    />
  );
};

OverlaidRangeInput.propTypes = {
  rangeMin: PropTypes.number.isRequired,
  selectableMin: PropTypes.number,
  rangeMax: PropTypes.number.isRequired,
  selectableMax: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  step: PropTypes.number,
};
