import { rem } from 'polished';
import styled from 'styled-components';

const ContentWrapper = styled.section`
  padding: 0;
  height: calc(100vh - var(--spacing-128));
  --parent-padding: ${rem(48)};
  --offset-padding: calc(var(--parent-padding) + var(--dash-nav-width-v2));
  max-width: calc(100vw - var(--offset-padding));
  overflow: auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { ContentWrapper, HeaderWrapper };
