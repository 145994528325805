import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { number, object } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import AttendeeActionsSetting from './attendee-actions-setting';

const AttendeeActions = ({ eventId, control }) => {
  const { t } = useLocalization('venue-controls.attendee-actions');
  const { addAlert } = useContext(AlertsContext);
  const [tickets, setTickets] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const { token } = await hopinApi.getUserToken();
        const { personas } = await hopinApi.getTicketsWithCount(token, eventId);

        setTickets(personas);
      } catch (e) {
        addAlert({
          active: true,
          text: t('something-went-wrong'),
          pattern: 'error',
        });
      }
    }
    if (!tickets) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, tickets]);

  return (
    <Box mb={3}>
      <Text
        element="h3"
        pattern="headingFour"
        color="grey-800"
        weight="medium"
        scale={4}
      >
        {t('title')}
      </Text>
      <StyledText pattern="body" color="grey-600" mt={1} mb={3} element="p">
        {t('subtitle')}
      </StyledText>

      <AttendeeActionsSetting
        control={control}
        tickets={tickets}
        translationKey="direct-message"
        formToggleName="venue_settings[send_messages]"
        formFieldName="venue_settings[send_direct_message_excluded_persona_ids]"
        formCheckboxName="venue_settings[send_direct_message_excluded_tickets_checkbox]"
      />
      <AttendeeActionsSetting
        control={control}
        tickets={tickets}
        translationKey="schedule-meeting"
        formToggleName="venue_settings[schedule_meetings]"
        formFieldName="venue_settings[schedule_meeting_excluded_persona_ids]"
        formCheckboxName="venue_settings[schedule_meeting_excluded_tickets_checkbox]"
      />
      <AttendeeActionsSetting
        control={control}
        tickets={tickets}
        translationKey="video-call"
        formToggleName="venue_settings[invite_to_video_call]"
        formFieldName="venue_settings[start_instant_video_call_excluded_persona_ids]"
        formCheckboxName="venue_settings[start_instant_video_call_excluded_tickets_checkbox]"
      />
    </Box>
  );
};

AttendeeActions.propTypes = {
  eventId: number.isRequired,
  control: object.isRequired,
};

const StyledText = styled(Text)`
  max-width: 47.5rem;
  width: 100%;
`;

export default withLocalizationProvider(AttendeeActions);
