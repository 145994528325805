import { string } from 'prop-types';
import React from 'react';

const MouseCursor = ({ color }) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0573 12.7933L8.36559 3.29995C8.21107 3.21126 8.03564 3.16524 7.85735 3.16661C7.68307 3.16358 7.51149 3.20981 7.36248 3.29995L7.20198 3.39328C6.88248 3.56754 6.67906 3.89708 6.66699 4.25995V23.3799C6.66711 23.6606 6.84357 23.9112 7.10836 24.0066C7.36212 24.0997 7.64714 24.0313 7.8306 23.8333L11.9233 19.3266L17.6477 29.2066C17.7842 29.4359 18.0048 29.6033 18.2629 29.6733L18.7043 29.8333H18.9584C19.1367 29.8347 19.3121 29.7886 19.4666 29.6999L24.0542 27.0333C24.2834 26.9025 24.4516 26.6869 24.5223 26.4333L24.6561 25.9399C24.7272 25.6843 24.6935 25.4109 24.5624 25.1799L18.838 15.2999L24.8032 13.9666C25.0716 13.9147 25.2796 13.7021 25.3248 13.4333C25.3659 13.1865 25.262 12.938 25.0573 12.7933Z"
      fill={color}
    />
  </svg>
);

MouseCursor.defaultProps = {
  color: 'var(--color-blue-400)',
};

MouseCursor.propTypes = {
  color: string,
};

export default MouseCursor;
