import CardTitle from '@features/dashboard/components/card-title';
import ChangeRate from '@features/dashboard/components/change-rate/change-rate-component';
import { object } from 'prop-types';
import React from 'react';

import { HeaderContainer } from './styles';

const Header = ({ summary }) => {
  return (
    <HeaderContainer>
      <CardTitle>{summary.title}</CardTitle>
      <h2 style={{ display: 'inline' }}>
        {summary.unit ? `${summary.unit}` : ''}
        {summary.number}
      </h2>
      {!summary.hideChangeRate && (
        <ChangeRate
          rate={summary.changeRate}
          days={summary.changeDays}
          showText={true}
        />
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  summary: object.isRequired,
};

export default Header;
