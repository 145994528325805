import { timezonesMap } from '@features/constants/timezones-map';
import { yupResolver } from '@hookform/resolvers';
import { differenceInMinutes, isAfter } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { date, object, string } from 'yup';

export const getEventValidations = (defaultValues, maxEventLengthHours, t) =>
  yupResolver(
    object().shape({
      name: string().required(t('event-name.required')),
      startDate: date().test(
        'min',
        t('start-date.validation-chronology'),
        function (timeStart) {
          const tz = this.parent.timezone;

          return timeStart
            ? isAfter(
                zonedTimeToUtc(timeStart, timezonesMap[tz] || 'etc/UTC'),
                new Date(),
              )
            : true;
        },
      ),
      endDate: date()
        .test(
          'after-start-date',
          t('end-date.validation-chronology'),
          function () {
            const {
              startDate = defaultValues.startDate,
              endDate = defaultValues.endDate,
            } = this.parent;

            return isAfter(endDate, startDate);
          },
        )
        .test(
          'date-range',
          t('end-date.validation-length', { maxEventLengthHours }),
          function () {
            const {
              startDate = defaultValues.startDate,
              endDate = defaultValues.endDate,
            } = this.parent;

            return (
              maxEventLengthHours * 60 >=
              differenceInMinutes(endDate, startDate)
            );
          },
        ),
      eventAccess: string().required(t('event-access.required')),
      eventType: string().required(),
      eventPassword: string().when('eventType', (eventType, schema) =>
        eventType === 'private_event'
          ? schema
              .required(t('password.required'))
              .matches(/^[^,/\\.%&#]*$/gm, t('password.validation'))
          : schema,
      ),
    }),
  );
