import Histogram from '@components/bar-chart/histogram';
import DataOverTimeLineChart from '@components/tabbed-chart/data-over-time-line-chart';
import { array, number, object, oneOfType, string } from 'prop-types';
import React from 'react';

const Chart = ({
  chartType,
  startTime,
  endTime,
  unit,
  maxBars,
  chartData,
  tooltipDescription,
  height,
}) => {
  switch (chartType) {
    case 'histogram':
      return (
        <div className="mr-2">
          <Histogram
            data={chartData}
            maxBars={maxBars}
            tooltipDescription={tooltipDescription}
            height={height}
          />
        </div>
      );
    case 'line':
      return (
        <DataOverTimeLineChart
          startTime={startTime}
          endTime={endTime}
          chartData={chartData}
          unit={unit}
          height={height}
        />
      );
    default:
      console.error('Invalid chart type');
  }
};

Chart.propTypes = {
  chartType: string.isRequired,
  chartData: oneOfType([object, array]).isRequired,
  startTime: string,
  endTime: string,
  unit: string,
  tooltipDescription: string,
  maxBars: number,
  height: number,
};

export default Chart;
