import { useLocalization } from '@features/localization';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import DuplicateButton from './duplicate-button';

const DuplicateButtonContainer = props => {
  const { t } = useLocalization('event-dashboard.sidenav.duplicate');

  const componentProps = useSelector(({ event }) => {
    const { validForDuplication } = event;

    return {
      ...props,
      align: 'right',
      disabled: !validForDuplication,
      hide: false,
      hideTip: validForDuplication,
      icon: 'copy',
      isInverse: true,
      text: t('duplicate'),
      tipText: t('tip-text'),
    };
  }, shallowEqual);

  return (
    <ToolTip
      align={componentProps.right}
      tip={componentProps.tipText}
      hideTip={componentProps.hideTip}
    >
      <DuplicateButton {...componentProps} />
    </ToolTip>
  );
};

export default DuplicateButtonContainer;
