import { componentsWithoutLayout } from '@components/nav/nav-config';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import * as Routes from '@routes';
import { compose } from 'lodash/fp';
import { bool, func, string } from 'prop-types';
import React from 'react';

import { getPageInfoText, getPageTitle } from './page-title';
import { Container, Wrapper } from './wrapper-container-sc';

const pathIndexUsedForTitle = 4;

const HeaderComponent = ({
  currentPath,
  slug,
  onPreview,
  registrationPage,
  showEngagementScore,
}) => {
  const { t } = useLocalization('header');

  const currentPathSplit = currentPath.split('/');

  const pathKey =
    currentPathSplit.length === pathIndexUsedForTitle + 1
      ? currentPathSplit[pathIndexUsedForTitle]
      : currentPathSplit.slice(pathIndexUsedForTitle).join('_');

  const pageTitle = getPageTitle(t, pathKey, { showEngagementScore });

  const pageInfoText = getPageInfoText(t, pathKey, {});

  const previewRegistrationUrl = eventPath => {
    return registrationPage ? registrationPage : eventPath;
  };

  return (
    <Wrapper data-testid="header-wrapper">
      <Container isFluid={componentsWithoutLayout.includes(pathKey)}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Text pattern="headingThree" element="h3" font="display">
              {pageTitle}
            </Text>
            {pageInfoText && (
              <ToolTip tip={pageInfoText} align="bottom">
                <Icon name="question-mark-fill" ml={1} color="grey-500" />
              </ToolTip>
            )}
          </Flex>
          <div>
            <a
              href={previewRegistrationUrl(Routes.eventPath(slug))}
              target="_blank"
              rel="noreferrer noopener"
              onClick={onPreview}
            >
              <Button
                leadingIcon="open-in-new"
                isOutlined
                isInline
                size="small"
                type="button"
                mr={1}
              >
                {t('previewRegistration')}
              </Button>
            </a>

            <a
              href={Routes.previewOrganisersEventPath(slug)}
              target="_blank"
              rel="noreferrer noopener"
              onClick={onPreview}
            >
              <Button
                leadingIcon="open-in-new"
                isOutlined
                isInline
                size="small"
                type="button"
              >
                {t('previewEvent')}
              </Button>
            </a>
          </div>
        </Flex>
      </Container>
    </Wrapper>
  );
};

HeaderComponent.propTypes = {
  currentPath: string.isRequired,
  slug: string.isRequired,
  onPreview: func.isRequired,
  registrationPage: string,
  showEngagementScore: bool,
  onButtonClick: func,
};

export default compose(withLocalizationProvider)(HeaderComponent);
