import { Flex } from '@hopin-team/ui-flex';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

export const TemplateList = styled(Flex)`
  margin-top: ${calcSpace(1)};
  margin-left: ${calcSpace(-2)};

  > ${Flex} {
    width: ${calcSpace(21)};
    margin-left: ${calcSpace(2)};
    margin-top: ${calcSpace(2)};
  }
`;
