export const ReportType = {
  SUMMARY: 'summary',
  ACTIVITY: 'activity',
  CONNECTIONS: 'connections',
  ENGAGEMENT: 'engagement',
  CHAT: 'chat',
  REGISTRATION: 'registration',
  REPLAY: 'replay',
  OTHER: 'other',
};

function createMapping(reportType, jobTypes) {
  return {
    jobTypes,
    reportType,
  };
}

const TYPE_MAPPINGS = [
  createMapping(ReportType.SUMMARY, [
    'EventSummaryReportJob',
    'EventAttendeesReportJob',
    'EventAttendeesOldReportJob',
    'EventNoShowsReportJob',
    'EventReferralsReportJob',
    'AllReportsBundleJob',
    'EventScheduleReportJob',
    'EventScheduleOldReportJob',
    'RegistrantAgendaReportJob',
  ]),
  createMapping(ReportType.ACTIVITY, [
    'EventActivitiesReportJob',
    'EventCountersReportJob',
    'EventAttendeeScoreReportJob',
    'EventPostSurveyReportJob',
    'EventPollsReportJob',
    'QuestionsAndAnswersReportJob',
  ]),
  createMapping(ReportType.CONNECTIONS, [
    'ScheduledMeetingsReportJob',
    'EventNetworkingMeetingsReportJob',
    'EventConnectionsReportJob',
  ]),
  createMapping(ReportType.ENGAGEMENT, [
    'EventEngagementReportJob',
    'EventParticipationReportJob',
    'VendorParticipationReportJob',
    'VendorInteractionsReportJob',
    'ScheduleParticipationReportJob',
  ]),
  createMapping(ReportType.CHAT, ['EventChatReportJob']),
  createMapping(ReportType.REGISTRATION, [
    'EventRegistrationsReportJob',
    'EventRegistrantsReportJob',
  ]),
  createMapping(ReportType.REPLAY, [
    'ReplayViewersReportJob',
    'ReplayRecordingsReportJob',
  ]),
];

export function getReportTypeForJobType(jobType) {
  const typeMapping = TYPE_MAPPINGS.find(mapping =>
    mapping.jobTypes.includes(jobType),
  );
  if (typeMapping == null) {
    return ReportType.OTHER;
  }
  return typeMapping.reportType;
}
