import { eventStatuses } from '@features/events/constants';
import { Spinner } from '@hopin-team/ui-spinner';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { string } from 'prop-types';
import React from 'react';

import { Dot, Label, Wrapper } from './styles';

const statusColors = {
  upcoming: '--color-blue-400',
  draft: '--color-orange-400',
  live: '--color-green-400',
};

export function getStatusColor(colors, status) {
  if (colors && status) {
    return colors[status.toLowerCase()];
  }
}

export default function EventStatus({ status, label }) {
  return (
    <Wrapper>
      {status === eventStatuses.BUILDING ? (
        <Spinner isShowing={true} data-testid="event-status-spinner" />
      ) : (
        <Dot
          data-testid="event-status-dot"
          color={getStatusColor(statusColors, status)}
        />
      )}
      <Label data-testid="event-status-label">
        {startCase(camelCase(label))}
      </Label>
    </Wrapper>
  );
}

EventStatus.propTypes = {
  status: string.isRequired,
  label: string.isRequired,
};
