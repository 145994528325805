import NotificationToast from '@components/notification-toast/notification-toast';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { number } from 'prop-types';
import React, { useContext } from 'react';

import AlertWrapper from './alert-wrapper';
import { AlertsContext } from './alerts-provider';
import { AlertsList } from './alerts.styles';

const Alerts = ({ timeout = 5000 }) => {
  const { alerts, dismissAlert } = useContext(AlertsContext);
  const defaultTimeout = timeout;

  return (
    <AlertsList>
      <AnimateSharedLayout>
        <AnimatePresence>
          {Object.entries(alerts).map(
            ([id, { text, pattern, timeout, toast }]) => {
              const Toast = toast;

              return (
                <AlertWrapper
                  onDismiss={() => dismissAlert(id)}
                  key={id}
                  timeout={timeout || defaultTimeout}
                >
                  {toast ? (
                    <Toast onClose={() => dismissAlert(id)} />
                  ) : (
                    <NotificationToast
                      pattern={pattern}
                      onClose={() => dismissAlert(id)}
                      text={text}
                    />
                  )}
                </AlertWrapper>
              );
            },
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </AlertsList>
  );
};

Alerts.propTypes = {
  timeout: number,
};

export default Alerts;
