import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Search } from '@hopin-team/ui-search';
import { Text } from '@hopin-team/ui-text';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import React from 'react';

import SelectFilterPlanetary from './filtering/select-filter-planetary';
import MagicLinksOperations from './magic-links-operations';
import SelectedCounter from './selected-counter';

const MagicLinksTableHeader = ({
  onChange,
  inputValue,
  filterState,
  selectedIds,
  selectAll,
  total,
  send,
  download,
  cancel,
  onClear,
}) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites');
  const operationsActive = (total && selectAll) || selectedIds.length;
  return (
    <Box mt={2}>
      <Text pattern="headingThree" element="h3">
        {t('users')}
      </Text>
      <Flex>
        {operationsActive ? (
          <>
            <SelectedCounter
              selectedIds={selectedIds}
              selectAll={selectAll}
              total={total}
            />
            <MagicLinksOperations
              selectedFilters={filterState.selectedFilters}
              send={send}
              download={download}
              cancel={cancel}
            />
          </>
        ) : (
          <>
            <Search
              onChange={onChange}
              value={inputValue}
              name="search_text"
              placeholder={t('search-placeholder')}
              testId="search-input"
              ariaLabel={t('search-aria-label')}
              sizeOption="medium"
              colorPattern="light"
              onClear={onClear}
            />
            <SelectFilterPlanetary
              {...filterState}
              noOptionsText={t('filter.all-added')}
            />
          </>
        )}
      </Flex>
    </Box>
  );
};

MagicLinksTableHeader.propTypes = {
  onChange: func.isRequired,
  onClear: func.isRequired,
  inputValue: string.isRequired,
  filterState: object.isRequired,
  selectedIds: arrayOf(number),
  selectAll: bool,
  send: func,
  total: number.isRequired,
  download: func,
  cancel: func,
};

export default withLocalizationProvider(MagicLinksTableHeader);
