import { createGroupId } from '../../../utils/utils';

export const getGroupByOptions = (state, options) => {
  const groupId = createGroupId(options);
  const group = state?.recordingGroups[groupId];

  let enhancedGroup = {
    ...group,
  };

  if (group) {
    enhancedGroup.hasRecordings = group.groupRecordings?.length > 0;
  }

  return enhancedGroup;
};
