import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import React, { useContext } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 2rem;
`;

const Content = styled.div`
  width: fit-content;
  max-width: 20rem;
  margin: 7.5rem auto;
  text-align: center;
`;

const IconContainer = styled.div`
  display: inline-flex;
  padding: 1rem;
  background-color: var(--color-gray-200);
  border-radius: 100%;
`;

const EmptyAnalytics = () => {
  const { t } = useContext(LocalizationContext);
  return (
    <Wrapper data-testid="empty-analytics">
      <Content>
        <IconContainer>
          <Icon name="chart-bar" color="grey-600" scale={3.5} />
        </IconContainer>
        <Text as="p" pattern="body" mt={2}>
          {t('overview.empty-analytics')}
        </Text>
      </Content>
    </Wrapper>
  );
};

export default withLocalizationProvider(EmptyAnalytics);
