import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { element, number, oneOfType, string } from 'prop-types';
import React from 'react';

import { StyledFlex, StyledTooltip } from './styles';

const MetricItem = ({
  iconName,
  displayValue,
  description,
  tooltipText,
  testId,
}) => {
  return (
    <StyledFlex pt={3} data-testid={'metric-card-' + testId} flex={1} mx={2}>
      <Flex alignItems="center">
        <Halon scale={4} icon={iconName} color="grey-200" iconScale={1.5} />
      </Flex>
      <Flex flexDirection="column" ml={2} flexGrow={1}>
        <Text pattern="subHeadingBold" color="grey-800" scale={6}>
          {displayValue}
        </Text>
        <Flex alignItems="center">
          <Text pattern="body">{description}</Text>
          {tooltipText && (
            <StyledTooltip align="top" tip={tooltipText} color="grey-600">
              <Icon name="info-outlined" scale={1.5} ml={1} color="grey-600" />
            </StyledTooltip>
          )}
        </Flex>
      </Flex>
    </StyledFlex>
  );
};

MetricItem.propTypes = {
  description: string.isRequired,
  displayValue: oneOfType([string, number, element]).isRequired,
  iconName: string,
  testId: string,
  tooltipText: string,
};

export default MetricItem;
