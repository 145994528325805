import { LITE_EVENT_STEPS } from '@components/event-progress/constants/steps';
import AlertIcon from '@components/icons/alert';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { withSegmentPageView } from '@util/analytics/with-segment-page-view';
import { connectViewData, withViewDataProvider } from '@util/page-view-context';
import compose from 'lodash/fp/compose';
import { bool, object } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateStepStatus } from '@/redux/reducers/setup-checklist';
import withReduxProvider from '@/redux/with-provider';

import LiveAnalyticsComponent from './live-analytics-component';

const LiveAnalyticsContainer = ({
  event,
  organization,
  renameInvitedToPending,
}) => {
  const { t } = useLocalization('live-analytics');
  const dispatch = useDispatch();

  useEffect(() => {
    if (event && event.isLite && event.phase === 'Ended') {
      dispatch(
        updateStepStatus({
          eventId: event.externalId,
          step: LITE_EVENT_STEPS.ANALYTICS,
          status: 'completed',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organization.analyticsEnabled) {
    return (
      <div className="mb-3">
        <p>
          <AlertIcon color="black" /> {t('disabled')}
        </p>
      </div>
    );
  }
  return (
    <LiveAnalyticsComponent
      event={event}
      renameInvitedToPending={renameInvitedToPending}
    />
  );
};

const mapViewDataToProps = data => data;

LiveAnalyticsContainer.propTypes = {
  event: object.isRequired,
  organization: object.isRequired,
  renameInvitedToPending: bool.isRequired,
};

export default compose(
  withLocalizationProvider,
  withViewDataProvider,
  withReduxProvider,
  connectViewData(mapViewDataToProps),
  withSegmentPageView('Live Analytics'),
)(LiveAnalyticsContainer);
