import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

const ChecklistItemsList = styled.ul`
  display: grid;
  grid-row-gap: ${calcSpace(4)};
  list-style-type: none;
  width: 100%;
  margin-top: ${calcSpace(2)};
  align-self: start;
`;

export default ChecklistItemsList;
