export const EXPERIENCE_TEMPLATE = {
  WEBINAR: 'webinar_experience',
  VIRTUAL: 'virtual_experience',
  HYBRID: 'hybrid_experience',
  ONSITE: 'onsite_experience',
};

export const EXPERIENCE_TEMPLATE_TO_EVENT_TEMPLATE = {
  [EXPERIENCE_TEMPLATE.WEBINAR]: 'lite_webcast',
  [EXPERIENCE_TEMPLATE.VIRTUAL]: 'blank',
  [EXPERIENCE_TEMPLATE.HYBRID]: 'blank',
  [EXPERIENCE_TEMPLATE.ONSITE]: 'onsite',
};

export const EXPERIENCE_TEMPLATE_TO_VENUE_TYPE = {
  [EXPERIENCE_TEMPLATE.WEBINAR]: 'virtual',
  [EXPERIENCE_TEMPLATE.VIRTUAL]: 'virtual',
  [EXPERIENCE_TEMPLATE.HYBRID]: 'hybrid',
  [EXPERIENCE_TEMPLATE.ONSITE]: 'onsite',
};
