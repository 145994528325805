import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import {
  CHANGE_TYPE,
  SECONDS_IN_ONE_HOUR,
  toTimestampString,
} from '../../utils';
import { Duration } from '../duration';
import { TimestampField } from '../timestamp-field';
import { Column } from './trimming-controls.styled';

export const TrimmingControls = withLocalizationProvider(
  ({ deltaUnit, duration, endAt, lengthOfVideo, onChange, startAt }) => {
    const { t } = useContext(LocalizationContext);

    const isHourNeededInTimeStamp = lengthOfVideo >= SECONDS_IN_ONE_HOUR;
    const timestampFormat = isHourNeededInTimeStamp ? 'HH:mm:ss' : 'mm:ss';

    const handleStartChange = newValue => {
      onChange(newValue, endAt, CHANGE_TYPE.start);
    };

    const handleEndChange = newValue => {
      onChange(startAt, newValue, CHANGE_TYPE.end);
    };

    return (
      <Flex>
        <Column isWider={isHourNeededInTimeStamp}>
          <TimestampField
            deltaUnit={deltaUnit}
            format={timestampFormat}
            label={t('edit-recording-modal.start')}
            max={endAt - deltaUnit}
            min={0}
            onChange={handleStartChange}
            value={startAt}
          />
        </Column>

        <Column isWider={isHourNeededInTimeStamp}>
          <TimestampField
            deltaUnit={deltaUnit}
            format={timestampFormat}
            label={t('edit-recording-modal.end')}
            max={lengthOfVideo}
            min={startAt + deltaUnit}
            onChange={handleEndChange}
            value={endAt}
          />
        </Column>

        <Column>
          <Duration text={toTimestampString(duration, timestampFormat)} />
        </Column>
      </Flex>
    );
  },
);

TrimmingControls.propTypes = {
  deltaUnit: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  endAt: PropTypes.number.isRequired,
  lengthOfVideo: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  startAt: PropTypes.number.isRequired,
};
