import { IconWithBackground } from '@features/dashboard/components/empty-state/empty-state';
import styled from 'styled-components';

const TopAreasByCurrentAttendeesEmptyState = styled.div`
  padding: 5rem 0;

  h4 {
    text-align: center;
    font-weight: var(--font-weight-normal);
    margin-top: 1.5rem;
  }
`;

export { IconWithBackground, TopAreasByCurrentAttendeesEmptyState };
