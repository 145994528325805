import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Select } from '@hopin-team/ui-select';
import { array, func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FiltersList from './filters-list-planetary';

// Planetary Select component need to have a property called "value"
// to use on "setValue" handler
const shapeInPlanetaryFormat = filter => ({
  ...filter,
  value: filter.label,
});

const findFilterByValue = ({ filters, value }) =>
  filters.find(filter => filter.label === value);

const Container = styled(Flex)`
  flex-grow: 1;
  max-width: 75%;
`;

const SelectFilterPlanetary = ({
  ticketFilters,
  addFilter,
  statusFilters,
  removeFilter,
  selectedFilters,
}) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites.filter');

  const handleTicketFilterChange = value => {
    const filter = findFilterByValue({ filters: ticketFilters, value });
    addFilter(filter);
  };

  const handleStatusFilterChange = value => {
    const filter = findFilterByValue({ filters: statusFilters, value });
    addFilter(filter);
  };

  return (
    <Container ml={3}>
      <Select
        options={ticketFilters.map(shapeInPlanetaryFormat)}
        setValue={handleTicketFilterChange}
        placeholder={t('ticket-type')}
        hideLabel={true}
        mr={1}
        label={t('ticket-type')}
      />
      <Select
        options={statusFilters.map(shapeInPlanetaryFormat)}
        setValue={handleStatusFilterChange}
        placeholder={t('status')}
        hideLabel={true}
        mr={1}
        label={t('status')}
      />
      <FiltersList
        onDeleteChip={removeFilter}
        list={selectedFilters}
        noOptionsText={t('all-added')}
      />
    </Container>
  );
};

SelectFilterPlanetary.propTypes = {
  addFilter: func.isRequired,
  removeFilter: func.isRequired,
  ticketFilters: array.isRequired,
  statusFilters: array.isRequired,
  selectedFilters: array.isRequired,
};

export default withLocalizationProvider(SelectFilterPlanetary);
