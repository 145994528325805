import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';

import CircleCheck from './circle-check';
import {
  Card,
  Container,
  Description,
  HiddenCheckbox,
  Title,
  WhiteCircle,
} from './styles';

export const AreasSelector = ({ areasMap, onAreaHover, onAreaToggle }) => {
  const { t } = useLocalization();

  return (
    <Container>
      {Array.from(areasMap.keys()).map(areaId => {
        const { selected, titleKey, descriptionKey } = areasMap.get(areaId);

        return (
          <Card
            key={areaId}
            active={selected}
            onMouseEnter={() => onAreaHover(areaId)}
          >
            <Flex mr={1.5}>
              <Checkbox
                checked={selected}
                onChange={() => onAreaToggle(areaId)}
              />
            </Flex>

            <Flex flexGrow={1} flexDirection="column">
              <Title
                pattern="emphasis"
                element="h6"
                color="grey-black"
                mb={0.5}
              >
                {t(titleKey)}
              </Title>

              <Description pattern="captionMedium" element="p" color="grey-600">
                {t(descriptionKey)}
              </Description>
            </Flex>
          </Card>
        );
      })}
    </Container>
  );
};

AreasSelector.propTypes = {
  areasMap: instanceOf(Map),
  onAreaHover: func.isRequired,
  onAreaToggle: func.isRequired,
};

const Checkbox = ({ checked, onChange }) => {
  const Checkmark = checked ? CircleCheck : WhiteCircle;

  return (
    <>
      <Checkmark />
      <HiddenCheckbox checked={checked} onChange={onChange} />
    </>
  );
};

Checkbox.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
};
