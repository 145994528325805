import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { func, shape, string } from 'prop-types';
import React from 'react';

import { CenteredPic, StyledBox } from './styles';

const ComplexityFormatCard = ({
  images,
  title,
  subtitle,
  startText,
  onClick,
}) => {
  return (
    <StyledBox
      container={32}
      m={1.5}
      data-testid={`event-format-card-${title}`}
    >
      <Flex flexDirection="column" alignItems="center" p={4}>
        <CenteredPic
          isRound
          src={images['1x']}
          srcSets={[
            {
              srcSet: `${images['1x']} 1x, ${images['2x']} 2x`,
            },
          ]}
        />
        <Text align="center" pattern="subHeadingMedium">
          {title}
        </Text>
        <Text color="grey-medium" align="center" pattern="body">
          {subtitle}
        </Text>
        <Button onClick={onClick} isOutlined isInline pattern="primary" mt={2}>
          {startText}
        </Button>
      </Flex>
    </StyledBox>
  );
};

ComplexityFormatCard.propTypes = {
  images: shape({
    '1x': string.isRequired,
    '2x': string.isRequired,
  }),
  title: string.isRequired,
  subtitle: string.isRequired,
  startText: string.isRequired,
  onClick: func.isRequired,
};

export default ComplexityFormatCard;
