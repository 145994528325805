/* global Rails */

const execRequest = async (route, { body = {}, method = 'GET' } = {}) => {
  const res = await fetch(route, {
    ...(method !== 'GET' && { body: JSON.stringify(body) }),
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(method !== 'GET' && {
        'X-CSRF-TOKEN': Rails.csrfToken(),
      }),
    },
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  if (res?.headers?.get('content-type').includes('application/json')) {
    const json = await res.json();
    return json;
  }

  const text = await res.text();
  return text;
};

const request = {
  get: route => execRequest(route),
  post: (route, body) => execRequest(route, { method: 'POST', body }),
  delete: route => execRequest(route, { method: 'DELETE' }),
  put: (route, body) => execRequest(route, { method: 'PUT', body }),
};

export default request;
