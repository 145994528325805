import { images } from '@features/event-creation-wizard/constants/complexity-selector';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { func } from 'prop-types';
import React from 'react';

import ComplexityFormatCard from './complexity-format-card';

const ComplexityFormatSelector = ({ handleNext }) => {
  const { t } = useLocalization('event-creation-wizard.complexity-selector');

  return (
    <Flex flexDirection="row" justifyContent="center" flexWrap="wrap" m={1}>
      <ComplexityFormatCard
        title={t('essentials-title')}
        subtitle={t('essentials-subtitle')}
        startText={t('essentials-start')}
        images={images.essentials}
        onClick={() => handleNext('essentials')}
      />
      <ComplexityFormatCard
        title={t('complex-title')}
        subtitle={t('complex-subtitle')}
        startText={t('complex-start')}
        images={images.complex}
        onClick={() => handleNext('complex')}
      />
    </Flex>
  );
};

ComplexityFormatSelector.propTypes = {
  handleNext: func.isRequired,
};

export default ComplexityFormatSelector;
