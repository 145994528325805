import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Card } from '@hopin-team/ui-card';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';

import { CATEGORY_BLOCK, TYPE } from '../category-types';
import CategoryBlockControls from './category-block-controls';
import CategoryFormatField from './category-format-field';
import CategoryOptionsField from './category-options-field';
import { IconWrapper, StyledBox } from './styles';

const CategoryBlock = ({
  category,
  watchedCategoryName,
  watchedCategoryType,
  index,
  control,
  onDeleteBlock,
  setIsModalDismissable,
  errors,
  setOptions,
  fieldNamePrefix,
  isBlockOpen,
  setBlockOpen,
  getValues,
  lockProfileMatching,
  dragHandleProps,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Card level="base" cornerRadius="md" py={2} px={2}>
      {category.id && (
        <Controller
          control={control}
          name={`${fieldNamePrefix}.id`}
          defaultValue={category.id}
          render={() => <input type="hidden" />}
        />
      )}

      <Flex
        alignItems="center"
        flexDirection="row"
        data-testid="category-header"
      >
        <Flex alignItems="center" flexGrow={1} {...dragHandleProps}>
          <Icon name="drag" color="grey-600" aria-hidden="true" />
          <IconWrapper
            name={CATEGORY_BLOCK[watchedCategoryType].iconName}
            title={CATEGORY_BLOCK[watchedCategoryType].iconName}
            backgroundColor={CATEGORY_BLOCK[watchedCategoryType].iconBackground}
            color="grey-white"
            ml={1.5}
          />
          <Text as="h4" pattern="emphasis" color="grey-800" mb={0} ml={1}>
            {watchedCategoryName || t('networking.profile-setup.category-name')}
          </Text>
          {!isBlockOpen && errors && (
            <ToolTip
              tip={t('networking.profile-setup.category-incomplete-tooltip')}
              align="right"
            >
              <Icon
                name="report"
                color="utility-error"
                scale={1.5}
                ml={1}
                title={t('networking.profile-setup.category-incomplete-icon')}
              />
            </ToolTip>
          )}
        </Flex>
        <CategoryBlockControls
          setIsModalDismissable={setIsModalDismissable}
          onConfirmDelete={onDeleteBlock}
          index={index}
          isBlockOpen={isBlockOpen}
          setBlockOpen={setBlockOpen}
          reactHookFormId={category.reactHookFormId}
          lockProfileMatching={lockProfileMatching}
          t={t}
        />
      </Flex>
      <StyledBox mt={2} isBlockOpen={isBlockOpen}>
        <Controller
          control={control}
          name={`${fieldNamePrefix}.name`}
          defaultValue={category.name}
          rules={{
            required: t('networking.profile-setup.field-empty-error'),
            validate: value => {
              const form = getValues();
              if (
                form.categories.some(
                  (category, categoryIndex) =>
                    categoryIndex !== index &&
                    category.name.toUpperCase() === value.toUpperCase(),
                )
              ) {
                return t('networking.profile-setup.category-name-not-unique');
              }
            },
          }}
          render={({ value, onChange }) => (
            <TextField
              isDisabled={lockProfileMatching}
              label={t('networking.profile-setup.category-name')}
              placeholder={t(
                'networking.profile-setup.category-name-placeholder',
              )}
              value={value}
              errorMessage={errors?.name?.message}
              hasErrors={!!errors?.name}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name={`${fieldNamePrefix}.type`}
          defaultValue={category.type}
          render={({ value, onChange }) => (
            <CategoryFormatField
              lockProfileMatching={lockProfileMatching}
              categoryType={value}
              setCategoryType={selected => onChange(selected)}
              errorMessage={errors?.type?.message}
              hasErrors={!!errors?.type}
              t={t}
            />
          )}
        />

        <Controller
          control={control}
          name={`${fieldNamePrefix}.tags`}
          defaultValue={category.options}
          rules={{
            required: true,
            validate: value =>
              value?.length > 0 ||
              t('networking.profile-setup.field-empty-error'),
          }}
          render={({ value }) => (
            <CategoryOptionsField
              lockProfileMatching={lockProfileMatching}
              options={value}
              setOptions={setOptions}
              errorMessage={errors?.tags?.message}
              hasError={!!errors?.tags}
              t={t}
            />
          )}
        />
      </StyledBox>
    </Card>
  );
};

export default withLocalizationProvider(CategoryBlock);

CategoryBlock.propTypes = {
  category: shape({
    id: string,
    reactHookFormId: string.isRequired,
    type: oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
    name: string.isRequired,
    tags: arrayOf(shape({ label: string, value: string }).isRequired),
  }),
  watchedCategoryName: string,
  watchedCategoryType: oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
  index: number.isRequired,
  onDeleteBlock: func.isRequired,
  setOptions: func.isRequired,
  setIsModalDismissable: func.isRequired,
  errors: shape({
    name: shape({ message: string }),
    options: shape({ message: string }),
  }),
  control: object,
  getValues: func.isRequired,
  fieldNamePrefix: string.isRequired,
  isBlockOpen: bool,
  lockProfileMatching: bool,
  setBlockOpen: func.isRequired,
  dragHandleProps: object,
};
