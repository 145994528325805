import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const { getInitialState, getSelectors, addOne, setAll } = createEntityAdapter();

const {
  actions: { addSponsor, setSponsors },
  reducer,
} = createSlice({
  name: 'sponsors',
  initialState: getInitialState(),
  reducers: {
    addSponsor: addOne,
    setSponsors: setAll,
  },
});

export { addSponsor, setSponsors };

export const selectors = getSelectors(state => state.sponsors);

export default reducer;
