import hopinApi from '@api/hopin';
import * as Routes from '@routes';
import { string } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../../util/hooks';
import LatestConnectionsComponent from './latest-connections-component';

const LatestConnectionsContainer = ({ eventSlug }) => {
  const [connections, setConnections] = useState([]);

  const loading = usePromise(
    signal => hopinApi.getLatestConnections(eventSlug, signal),
    result => setConnections(result),
  );

  return (
    <LatestConnectionsComponent
      loading={loading}
      connections={connections}
      connectionsUrl={Routes.organisersEventConnectionsPath(eventSlug)}
    />
  );
};

LatestConnectionsContainer.propTypes = {
  eventSlug: string.isRequired,
};

export default LatestConnectionsContainer;
