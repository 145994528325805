import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CardTitleWrapper = styled.h6`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: var(--color-gray-800);
`;

const CardTitle = ({ children }) => {
  return <CardTitleWrapper>{children}</CardTitleWrapper>;
};

CardTitle.propTypes = {
  children: string.isRequired,
};

export default CardTitle;
