import api from '@api/hopin';
import EventFormatSelector from '@features/event-creation-wizard/components/event-format-selector';
import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { routes } from '@features/event-creation-wizard/constants/step-map';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { setEventLocationType } from '@features/event-creation-wizard/redux/event-location/event-location-slice';
import { hasComplexityOptions } from '@features/event-creation-wizard/redux/org/org-slice';
import { setTemplate } from '@features/event-creation-wizard/redux/step-specific-state/template-slice';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import { redirectOnClose } from '@features/event-creation-wizard/utils/redirection';
import { useLocalization } from '@features/localization';
import { Butticon } from '@hopin-team/ui-butticon';
import { Text } from '@hopin-team/ui-text';
import segmentTrack from '@util/analytics/segment-client';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useEventCreationWizard } from '../../event-creation-wizard-provider';
import { CloseButton, Content, FullScreenWrapper } from './styles';

const FormatPicker = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t } = useLocalization('event-creation-wizard');
  const { dashboardUrl } = useEventCreationWizard();
  const { dashboardOptedIn, appEnvironment } = useSelector(
    state => state.flags,
  );
  const { id: orgId, isOnFreePlan, isOnStarterPlan } = useSelector(
    state => state?.org,
  );
  const [isLoading, setIsLoading] = useState(false);
  const isComplexitySelectorEnabled = useSelector(hasComplexityOptions);
  const auth = useSelector(state => state?.auth);

  const handleClose = async () => {
    redirectOnClose({ dashboardOptedIn, dashboardUrl, appEnvironment, orgId });
  };

  const handleBack = () => {
    if (isComplexitySelectorEnabled) {
      push(routes.complexityPicker());
    } else {
      handleClose();
    }
  };

  const selectEventFormat = async format => {
    if (isLoading) return;

    setIsLoading(true);

    segmentTrack(EVENT.EVENT_FORMAT_SELECTED, {
      organiser_id: orgId,
      event_format: format,
    });

    if (format !== 'virtual' && (isOnFreePlan || isOnStarterPlan)) {
      if (!orgId) {
        await api.createDefaultOrg(auth.token);
      }
      push(routes.billing());
    } else {
      dispatch(setEventLocationType(format));
      if (format === 'onsite') {
        dispatch(
          setVenue({
            venue: templates['onsite'].defaultVenue,
            isOnFreePlan,
          }),
        );
        dispatch(setTemplate('onsite'));
        return push(routes.onsiteForm());
      } else {
        push(routes.templatePicker(format));
      }
    }
  };

  return (
    <FullScreenWrapper>
      {isComplexitySelectorEnabled && (
        <Butticon
          icon="arrow-back"
          size="medium"
          pattern="secondary"
          p={1.75}
          m={1.5}
          onClick={handleBack}
        />
      )}
      <CloseButton
        onClick={handleClose}
        aria-label={t('common.close')}
        data-testid="format-picker-close-button"
      />
      <Content>
        <Text pattern="pageHeading" as="h1" color="grey-800" mb={1.5}>
          {t('format-selector.title')}
        </Text>
        <Text pattern="body" as="p" color="grey-700" mb={3}>
          {t('format-selector.subtitle')}
        </Text>
        <EventFormatSelector
          handleNext={selectEventFormat}
          isLoading={isLoading}
        />
      </Content>
    </FullScreenWrapper>
  );
};

export default FormatPicker;
