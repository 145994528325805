import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: 'virtual',
  address: '{}',
};

const eventLocationSlice = createSlice({
  name: 'eventLocation',
  initialState,
  reducers: {
    setEventLocationType: (state, { payload }) => ({
      ...state,
      type: payload,
    }),
    setEventLocationAddress: (state, { payload }) => ({
      ...state,
      address: payload,
    }),
    setEventLocationName: (state, { payload }) => ({
      ...state,
      name: payload,
    }),
  },
});

const {
  reducer,
  actions: {
    setEventLocationType,
    setEventLocationAddress,
    setEventLocationName,
  },
} = eventLocationSlice;

export { setEventLocationType, setEventLocationAddress, setEventLocationName };

export default reducer;
