import NotificationsStore from '@util/notifications/store';
import Swal from 'sweetalert2';

export function showNotifications() {
  const notifications = NotificationsStore.get();
  notifications.forEach(notification => Swal.fire(notification));
  NotificationsStore.save([]);
}

export function addNotification(notification) {
  const notifications = NotificationsStore.get();
  notifications.push(notification);
  NotificationsStore.save(notifications);
}
