import analyticsApi from '@api/analytics';
import Chart from '@components/icons/chart';
import CardTitle from '@features/dashboard/components/card-title';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import cleanCountryCountData from '@features/dashboard/util/clean-country-count-data';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import { Icon } from '@hopin-team/ui-icon';
import floor from 'lodash/floor';
import { string } from 'prop-types';
import React, { useState } from 'react';

import Card from '../common/overview-card';
import TopItemsListComponent from '../common/top-items-list-component';
import EmptyState from '../styles/empty-state-styles';
import Loading from '../styles/loading-styles';

const getCountryName = (t, regionNames, countryCode) => {
  if (!countryCode) {
    return t('other');
  }
  const countryName = regionNames.of(countryCode);
  return countryName ? countryName : countryCode;
};

const transformResult = (t, countryData, language) => {
  const regionNames = new Intl.DisplayNames([language], { type: 'region' });
  const registrationCount = countryData.registrations_count;

  countryData = countryData.registrations_countries
    .sort((a, b) => b.registrations_count - a.registrations_count)
    .map(item => [
      getCountryName(t, regionNames, item.country_code),
      item.registrations_count,
    ]);

  countryData = cleanCountryCountData(countryData);

  let transformedData = [];
  let countryDataTally = { count: 0, percentage: 0 };
  const countryDataLength = countryData.length;

  for (let i = 0; i < Math.min(countryDataLength, 6); i++) {
    //If countries list is more than 6, truncate and sum the rest after the 5th item
    if (i == 5 && countryDataLength > 6) {
      const attendeeCount = registrationCount - countryDataTally.count;
      const attendeePercentage = floor(100 - countryDataTally.percentage, 2);

      transformedData.push({
        icon: <Icon name="location-pin" color="grey-400" mr={1} />,
        text: t('other'),
        value: `${attendeeCount.toLocaleString()} (${attendeePercentage}%)`,
      });

      break;
    }

    const [countryName, attendeeCount] = countryData[i];
    const attendeePercentage = floor(
      (attendeeCount / registrationCount) * 100,
      2,
    );
    countryDataTally.count += attendeeCount;
    countryDataTally.percentage += attendeePercentage;

    transformedData.push({
      icon: <Icon name="location-pin" color="grey-400" mr={1} />,
      text: countryName,
      value: `${attendeeCount.toLocaleString()} (${attendeePercentage}%)`,
    });
  }

  return transformedData;
};

const TopCountriesByRegistrations = ({ eventExternalId }) => {
  const { t, language } = useLocalization(
    'overview.top-countries-by-registrations',
  );
  const [countryData, setCountryData] = useState([]);

  const loading = usePromise(
    signal =>
      analyticsApi.getAnalyticsRegistrationsByCountry(eventExternalId, signal),
    result => setCountryData(transformResult(t, result, language)),
  );

  return (
    <Card testId="top-countries-by-registrations">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <Loading isLoading={loading}>
          {countryData.length > 0 ? (
            <TopItemsListComponent items={countryData} />
          ) : (
            <EmptyState text={t('empty')} icon={<Chart />} />
          )}
        </Loading>
      </CardContentWrapper>
    </Card>
  );
};

TopCountriesByRegistrations.propTypes = {
  eventExternalId: string.isRequired,
};

export default TopCountriesByRegistrations;
