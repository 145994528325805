import Loading from '@components/loading';
import { useLocalization } from '@features/localization';
import { EVENT_PART, RANK, WHEN } from '@features/recordings-backup/constants';
import { Button } from '@hopin-team/ui-button';
import { ImageUpload } from '@hopin-team/ui-image-upload';
import { SidePanelModal } from '@hopin-team/ui-side-panel-modal';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectEvent } from '../redux/slices/event/event-selectors';
import { useThumbnails } from './hooks';
import { Selectable } from './partials';
import {
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  Thumbnail,
} from './thumbnail-side-panel-modal.styled';

export const ThumbnailSidePanelModal = ({ isShowing, onClose, recording }) => {
  const { t } = useLocalization('recording-library.thumbnail-side-panel');
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState();
  const handleImageChange = images => setImage(images[0]);
  const { slug: eventSlug } = useSelector(selectEvent);
  const { isLoading, thumbnails } = useThumbnails(recording.id, eventSlug);

  const onThumbnailSelect = thumbnailId => {
    console.log('THUMBNAIL SELECTED', thumbnailId);
  };

  return (
    <SidePanelModal
      background="grey-100"
      describedById="modal-header"
      isShowing={isShowing}
      isDismissible={true}
      closeLabel={t('close-side-panel-modal')}
      onClose={onClose}
      withCloseButton
      label={t('change-thumbnail')}
      size="large"
    >
      <HeaderContainer>
        <Text id="modal-header" element="h3" pattern="headingThree" mb={0}>
          {t('change-thumbnail')}
        </Text>
      </HeaderContainer>
      <ContentContainer>
        <Text element="p" pattern="caption" color="grey-600">
          {t('a-good-thumbnail-will-help-viewers')}
        </Text>
        <Text element="p" pattern="emphasis" mt={3}>
          {t('recording-title')}
        </Text>
        <Text element="p" pattern="body">
          {recording.title}
        </Text>
        <Text element="p" pattern="emphasis" mt={3} mb={2}>
          Use your own image
        </Text>
        <ImageUpload
          onImagesChange={handleImageChange}
          width={430}
          height={430 / (16 / 9)}
          messages={{
            orDropFile: t('upload-thumbnail.or-drop-file'),
            dropFile: t('upload-thumbnail.drop-file'),
            uploadImage: t('upload-thumbnail.upload-image'),
            fileHints: t('upload-thumbnail.file-hints'),
            maximumUploadSize: t('upload-thumbnail.maximum-upload-size'),
            generalError: t('upload-thumbnail.general-error'),
            maxSizeError: t('upload-thumbnail.max-size-error'),
            minSizeError: t('upload-thumbnail.min-size-error'),
            tooManyFilesError: t('upload-thumbnail.too-many-files-error'),
            fileTypeError: t('upload-thumbnail.file-type-error'),
          }}
        />

        <Loading isLoading={isLoading}>
          <Text element="p" pattern="emphasis" mt={3} mb={2}>
            {t('select-from-existing-thumbnails')}
          </Text>

          {thumbnails.length
            ? thumbnails.map(thumbnail => (
                <Selectable
                  key={thumbnail.id}
                  isSelected={thumbnail.selected}
                  onSelect={() => onThumbnailSelect(thumbnail.id)}
                >
                  <Thumbnail src={thumbnail.url} />
                </Selectable>
              ))
            : null}
        </Loading>
      </ContentContainer>

      <FooterContainer>
        <Button isInline size="medium" mr={2}>
          {t('save')}
        </Button>
        <Button
          isInline
          isOutlined
          size="medium"
          type="reset"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </FooterContainer>
    </SidePanelModal>
  );
};

ThumbnailSidePanelModal.propTypes = {
  isShowing: PropTypes.bool,
  onClose: PropTypes.func,
  recording: PropTypes.shape({
    id: PropTypes.string,
    rank: PropTypes.oneOf([RANK.MAIN, RANK.BACKUP]),
    whenInTime: PropTypes.oneOf([WHEN.PRE_EVENT, WHEN.IN_EVENT]),
    startedAt: PropTypes.string,
    createdAt: PropTypes.string,
    endedAt: PropTypes.string,
    title: PropTypes.string,
    playableUrl: PropTypes.string,
    description: PropTypes.string,
    eventPart: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf([EVENT_PART.STAGE, EVENT_PART.SESSION]),
    }),
  }).isRequired,
};
