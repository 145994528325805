import eventDashboardApi from '@api/event-dashboard';
import { mapResponseToFormFields } from '@components/people/profile-categories-form/profile-form-helpers';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Link } from '@hopin-team/ui-link';
import { Text } from '@hopin-team/ui-text';
import { isEmpty } from 'lodash';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { setProfileCategories } from '@/redux/reducers/networking';

import NetworkingImage from '../../../../assets/images/speed-networking-hero.svg';

const InterestsSettingsBlock = ({
  event,
  setShowSettingsModal,
  setIsLoading,
  addAlert,
  lockProfileMatching,
}) => {
  const { t } = useLocalization();
  const categoriesData = useSelector(({ networking }) => networking.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    if (categoriesData) return;

    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await eventDashboardApi.getProfileCategories(
          event.external_id,
        );

        const formData = mapResponseToFormFields(
          response.data,
          response.included,
        );
        dispatch(setProfileCategories(formData));
      } catch (e) {
        addAlert({
          active: true,
          text: t('people.people-area.something-went-wrong'),
          pattern: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.external_id]);

  return (
    <Flex
      background="blue-100"
      cornerRadius="md"
      alignItems="center"
      p={6}
      mb={2}
    >
      <Box mr={2}>
        <Text pattern="subHeadingBold" color="grey-800" element="h4">
          {t('people.people-area.collect-interests-heading')}
        </Text>
        <Text pattern="body" color="grey-700" element="p" mt={1}>
          {t('people.people-area.collect-interests-text')}
        </Text>
        <Link
          label={t('people.people-area.learn-more')}
          href="https://events-support.ringcentral.com/hc/en-us/articles/5016822508948--The-People-area"
          leadingIcon="open-in-new"
          scale={4}
          mt={1}
          target="_blank"
          rel="noopener noreferrer"
        />
        <Flex flexDirection="row" alignItems="center" mt={3}>
          <Button
            size="small"
            isInline
            onClick={setShowSettingsModal}
            disabled={isEmpty(categoriesData) && lockProfileMatching}
          >
            {isEmpty(categoriesData) || !categoriesData
              ? t('people.people-area.setup-interests')
              : lockProfileMatching
              ? t('people.people-area.preview-settings')
              : t('people.people-area.change-settings')}
          </Button>
          {!isEmpty(categoriesData) && (
            <StyledButton
              isOutlined
              pattern="primary"
              size="small"
              isInline
              ml={1}
              px={2.5}
              disabled
            >
              <Icon name="checkmark" mr={0.75} color="grey-500" />
              {t('people.people-area.enabled')}
            </StyledButton>
          )}
        </Flex>
      </Box>
      <Flex>
        <NetworkingImage data-testid="networking-image" />
      </Flex>
    </Flex>
  );
};

export default InterestsSettingsBlock;

InterestsSettingsBlock.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string,
    network_type: PropTypes.string,
    external_id: PropTypes.string.isRequired,
  }).isRequired,
  lockProfileMatching: PropTypes.bool,
  setShowSettingsModal: PropTypes.func,
  setIsLoading: PropTypes.func,
  addAlert: PropTypes.func,
};

export const StyledButton = styled(Button)`
  height: 2.1rem;
  background: var(--color-white) !important;
  color: var(--color-gray-500);
  cursor: none;

  &:hover {
    background: var(--color-white);
    color: var(--color-gray-500);
  }
  .hpn-Icon {
    margin-left: 0;
    margin-right: ${rem(6)};
  }
`;
