import Booth from '@components/icons/booth';
import Network from '@components/icons/network';
import Sessions from '@components/icons/sessions';
import Stage from '@components/icons/stage';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { array } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import EventPartsCard from './event-parts/event-parts-card';

const EventPartsRow = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const EventPartsDetailsComponent = ({ eventParts }) => {
  const hasEventPart = eventPart => {
    return (
      eventParts.length > 0 &&
      eventParts.some(({ eventPartType }) => eventPart === eventPartType)
    );
  };

  return (
    <div className="my-4">
      <label className="flex align-items-center">
        <span className="mr-1">What would you like the event to have?</span>
        <ToolTip
          align="top"
          tip="To learn more about Hopin Segments head to our FAQ or Support."
        >
          <i className="fas fa-info-circle" aria-hidden />
        </ToolTip>
      </label>
      <EventPartsRow>
        <EventPartsCard
          subtitle="Your event stage"
          title="Stage"
          selected={hasEventPart('stage')}
          icon={<Stage />}
        />
        <EventPartsCard
          subtitle="Interactive Roundtables"
          title="Sessions"
          selected={hasEventPart('sessions')}
          icon={<Sessions />}
        />
        <EventPartsCard
          subtitle="One on Ones"
          title="Networking"
          selected={hasEventPart('networking')}
          icon={<Network />}
        />
        <EventPartsCard
          subtitle="Virtual Booths"
          title="Expo"
          selected={hasEventPart('expo')}
          icon={<Booth />}
        />
      </EventPartsRow>
    </div>
  );
};

EventPartsDetailsComponent.propTypes = {
  eventParts: array.isRequired,
};

export default EventPartsDetailsComponent;
