import countryNameToCode from '@features/dashboard/util/country-name-to-code';

const cleanCountryCountData = data => {
  const countDataByCountryCode = data.reduce(
    (countData, [countryName, count]) => {
      // If more than one countryName for the same country exists,
      // return the larger count to maintain consistency with TopCountriesByRegistrations.
      // We won't need to do this once country names are normalized in the DB
      const countryCode = countryNameToCode[countryName] || countryName;
      const prevCountryData = countData[countryCode];
      const prevCount = (!!prevCountryData && prevCountryData[1]) || 0;

      countData[countryCode] = [countryName, count + prevCount];

      return countData;
    },
    {},
  );

  return Object.values(countDataByCountryCode);
};

export default cleanCountryCountData;
