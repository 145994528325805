import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

const CustomDomainSettingsSection = ({ heading, description, children }) => {
  return (
    <Box mb={3}>
      <Box mb={2}>
        <Text
          element="h2"
          font="text"
          weight="medium"
          mb={0.5}
          pattern="body"
          scale={4}
        >
          {heading}
        </Text>

        <Text element="p" pattern="body" scale={3} color="grey-700">
          {description}
        </Text>
      </Box>

      {children}
    </Box>
  );
};

CustomDomainSettingsSection.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomDomainSettingsSection;
