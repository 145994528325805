import { Toggle } from '@hopin-team/ui-toggle';
import { bool } from 'prop-types';
import React, { useState } from 'react';

const DoorsOpenToggle = ({ isToggled }) => {
  let displayStyle;
  const [_isToggled, setToggle] = useState(isToggled);

  return (
    <Toggle
      role="checkbox"
      isToggled={_isToggled}
      checked={_isToggled}
      id="preEventEnabled"
      name="event[pre_event_enabled]"
      onChange={() => {
        displayStyle = _isToggled ? 'none' : 'block';
        document.getElementById(
          'pre_event_inputs',
        ).style.display = displayStyle;
        document
          .getElementById('event_pre_event_enabled')
          .setAttribute('checked', !_isToggled);
        document
          .getElementById('event_pre_event_enabled')
          .setAttribute('value', !_isToggled);
        setToggle(!_isToggled);
      }}
    />
  );
};

DoorsOpenToggle.propTypes = {
  isToggled: bool.isRequired,
};

export default DoorsOpenToggle;
