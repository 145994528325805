export function safeInterval(callback, step = 1000) {
  const intervalController = new AbortController();
  const start = document.timeline?.currentTime ?? performance.now();

  function frame(time) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / step) * step;
    const targetNext = start + roundedElapsed + step;
    const delay = targetNext - performance.now();

    setTimeout(
      () =>
        requestAnimationFrame(time => {
          if (intervalController.signal.aborted) return;

          callback(roundedElapsed, intervalController);
          frame(time);
        }),
      delay,
    );
  }

  frame(start);
}
