import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const ControlsContainerWrapped = styled(Flex).attrs({
  flexDirection: 'column',
  flex: 1,
  mb: 2,
  mr: 4,
})`
  justify-content: 'unset';
  width: 'inherit';
  flex-wrap: 'unset';
`;

export const LastTrimmedText = styled(Text)`
  line-height: 1.5rem;
`;

export const StyledDescription = styled(Text)`
  blockquote {
    overflow: hidden;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 0.3125rem solid #ccc;
  }

  blockquote[dir='rtl'] {
    border-right: 0.3125rem solid #ccc;
  }

  ul,
  ol {
    padding-left: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  ul[dir='rtl'],
  ol[dir='rtl'] {
    padding-right: 1rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }
`;
