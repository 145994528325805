import { createSlice } from '@reduxjs/toolkit';

const venueInitialState = {
  stage: { selected: true, hidden: false },
  sessions: { selected: false },
  expo: { selected: false },
  networking: { selected: false },
  replay: { selected: false },
  reception: { selected: false },
};

export const venueSlice = createSlice({
  name: 'event-creation-wizard-venue',
  initialState: venueInitialState,
  reducers: {
    setVenue(state, action) {
      const { venue, isOnFreePlan } = action.payload;
      const updated = Object.entries(venue).reduce((acc, [key, feature]) => {
        if (feature.isPaidOnly && isOnFreePlan) {
          acc[key] = {
            ...feature,
            selected: false,
            hidden: true,
          };
        } else {
          acc[key] = feature;
        }
        return acc;
      }, {});
      return updated;
    },
  },
});

export const { setVenue } = venueSlice.actions;

export default venueSlice.reducer;
