const backFilters = {
  statuses: 'statuses[]',
  ticketTypes: 'ticket_types[]',
  searchText: 'search_text',
};

const buildQueryObject = s =>
  s.map(filter => [backFilters[filter.key], filter.query]);

export default buildQueryObject;
