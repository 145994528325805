import { LocalizationContext } from '@features/localization';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Setting from './setting-component';

const AttendeeActions = ({ eventSettings, toggleSetting }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <Setting
        isToggled={eventSettings.enable_schedule_meetings}
        onChange={() => toggleSetting('enable_schedule_meetings')}
        title={t('people.people-area.schedule-meetings')}
        description={t('people.people-area.schedule-meetings-description')}
      />

      <Setting
        isToggled={eventSettings.enable_instant_video_call}
        onChange={() => toggleSetting('enable_instant_video_call')}
        title={t('people.people-area.start-instant-call')}
        description={t('people.people-area.start-instant-call-description')}
      />
    </>
  );
};

export default AttendeeActions;

AttendeeActions.propTypes = {
  eventSettings: PropTypes.shape({
    enable_schedule_meetings: PropTypes.bool,
    enable_instant_video_call: PropTypes.bool,
  }).isRequired,
  toggleSetting: PropTypes.func.isRequired,
};
