import { Label, Select } from '@components/inputs';
import { array, object, string } from 'prop-types';
import React from 'react';

import ErrorLabel from '../error-label';

const SelectTimezones = ({ className, event, timezones }) => {
  const errors = event.errors.timezone;
  return (
    <div className={className}>
      <Label htmlFor="timezone">Timezone</Label>
      <Select
        id="timezone"
        name="event[timezone]"
        className="select"
        defaultValue={event.timezone}
        error={errors}
      >
        {timezones.map(([name, value]) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </Select>
      {errors && <ErrorLabel htmlFor="timezone">{errors[0]}</ErrorLabel>}
    </div>
  );
};

SelectTimezones.propTypes = {
  className: string,
  event: object.isRequired,
  timezones: array.isRequired,
};
export default SelectTimezones;
