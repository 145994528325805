/* global Rails */
import isClientside from '@util/is-clientside';
import ky from 'ky-universal';

let client = {};

if (isClientside()) {
  client = ky.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/vnd.api+json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
    timeout: 30000,
  });
}

const BASE_PATH = '/api/v1/event_wizard';

const eventWizardApi = {
  updateNavigationView(token, eventId, value) {
    return client
      .put(`${BASE_PATH}/${eventId}/dashboard_overview`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          navigation_view: value,
        },
      })
      .json();
  },
};

export default eventWizardApi;
