import AlertsProvider from '@features/alerts/alerts-provider';
import { withLocalizationProvider } from '@features/localization';
import { ReactIDProvider } from '@hopin-team/react-id';
import { EVENT_DASHBOARD_EVENTS } from '@util/analytics/event-dashboard-events';
import trackSegmentEvent from '@util/analytics/segment-client';
import compose from 'lodash/fp/compose';
import * as P from 'prop-types';
import React, { createContext, useEffect } from 'react';

import withReduxProvider from '@/redux/with-provider';

import OverviewComponent from './component';

export const RegistrationContext = createContext({
  newRegistrationsEnabled: false,
  newRegistrationsUrl: '',
});

export const OverviewContainer = ({
  newRegistrationsEnabled,
  newRegistrationsUrl,
  user,
  event,
  eventActionCards = [],
  stripeUrl,
}) => {
  useEffect(() => {
    trackSegmentEvent(EVENT_DASHBOARD_EVENTS.DASHBOARD_LOADED, {
      user_id: user.external_id,
      organiser_id: event.organization.external_id,
      event_id: event.external_id,
      event_type: event.event_wizard_template,
      organization_subscription_tier:
        event.organization.current_payment_plan.name,
      task_cards_shown: eventActionCards.map(({ card_type }) => card_type),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registrationValue = {
    newRegistrationsEnabled,
    newRegistrationsUrl,
  };

  return (
    <ReactIDProvider>
      <AlertsProvider>
        <RegistrationContext.Provider value={registrationValue}>
          <OverviewComponent
            user={user}
            event={event}
            eventActionCards={eventActionCards}
            stripeUrl={stripeUrl}
            newRegistrationsUrl={newRegistrationsUrl}
            newRegistrationsEnabled={newRegistrationsEnabled}
          />
        </RegistrationContext.Provider>
      </AlertsProvider>
    </ReactIDProvider>
  );
};

export const OverviewPropTypes = {
  newRegistrationsUrl: P.string.isRequired,
  newRegistrationsEnabled: P.bool.isRequired,
  user: P.shape({
    first_name: P.string.isRequired,
    external_id: P.string.isRequired,
  }).isRequired,
  event: P.shape({
    external_id: P.string.isRequired,
    finished: P.bool.isRequired,
    slug: P.string.isRequired,
    event_wizard_template: P.string,
    organization: P.shape({
      external_id: P.string.isRequired,
      current_payment_plan: P.shape({
        name: P.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  eventActionCards: P.arrayOf(
    P.shape({
      card_type: P.string.isRequired,
      required: P.bool.isRequired,
      completed: P.bool.isRequired,
    }),
  ).isRequired,
  stripeUrl: P.string.isRequired,
};

OverviewContainer.propTypes = OverviewPropTypes;

export default compose(
  withReduxProvider,
  withLocalizationProvider,
)(OverviewContainer);
