import styled, { css } from 'styled-components';

/**
 * Parent container, whose hover/focus
 * state causes the other elements to be
 * hidden/visible
 */
export const HoverContainer = styled.div`
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`;

/**
 * Content will be in the DOM so that it is
 * accessible by keyboard and screen readers.
 *
 * Once the container is hovered over or focus is on a
 * child DOM node (focus-within), the content is visible.
 */
export const ShowOnHover = styled.div`
  ${HoverContainer}:not(:hover):not(:focus-within) & {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

/**
 * Content will be hidden when the container is hovered
 * or focus is on a child DOM node (focus-within).
 *
 * This should not include any actionable content (button, link).
 */
export const HideOnHover = styled.span`
  ${HoverContainer}:hover & {
    display: none;
  }

  ${HoverContainer}:focus-within & {
    display: none;
  }
`;
