import { element, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const IconWithBackground = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  background-color: var(--color-gray-200);
  margin: 0 auto 16px;
  color: var(--color-gray-600);

  svg {
    display: block;
    margin: auto;
  }
`;

const ButtonWrapper = styled.div`
  a {
    padding: 0 1rem;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  margin-top: -1rem;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  margin: 1rem 0;

  h6 {
    text-align: center;
    font-size: 0.875rem;
    font-weight: var(--font-weight-normal);
  }
`;

const H6 = styled.h6`
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const EmptyState = ({ text, icon, button, className }) => {
  return (
    <Wrapper className={className}>
      <IconWithBackground>{icon}</IconWithBackground>
      <H6 data-testid="empty-state-message">{text}</H6>
      {button && <ButtonWrapper>{button}</ButtonWrapper>}
    </Wrapper>
  );
};

EmptyState.propTypes = {
  text: string.isRequired,
  icon: element.isRequired,
  button: element,
  className: string,
};

export default EmptyState;
