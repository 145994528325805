import hopinApi from '@api/hopin';
import SaveBar from '@components/save-bar';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { AddTag } from '@hopin-team/ui-add-tag';
import { Box } from '@hopin-team/ui-box';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import { includes } from 'lodash';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import OnsiteBanner from './onsite-banner';
import ProgressModal from './progress-modal';
import { schema } from './schema';
import {
  DoubleBlock,
  NumberInput,
  SingleBlock,
  StyledCombobox,
  StyledSelect,
  StyledTagsGroup,
} from './styles';

const dateToString = datePickerDate => {
  if (!datePickerDate) {
    return '';
  }
  return moment(datePickerDate).format('yyyy-MM-DD');
};

const BulkEdit = ({
  organizationId,
  roundtables,
  eventId,
  eventSlug,
  authenticityToken,
  availableTags,
  availableTickets,
  roundtablesHaveSegments,
  videoSettingsEnabled,
  rtmpEnabled,
  availableRtmpStages,
  availableRtmpSessions,
  videoSessionsEnableLayoutV2,
  defaultData: {
    sessionsSize: defaultSessionsSize,
    sessionsSchedulability: defaultSessionsSchedulability,
    record: defaultRecord,
    youtube: defaultYoutube,
    rtmp: defaultRtmp,
    rtmpTarget: defaultRtmpTarget,
    rtmpStage: defaultRtmpStage,
    rtmpSession: defaultRtmpSession,
    priority: defaultPriority,
    maxParticipants: defaultMaxParticipants,
    videoSetting: defaultVideoSetting,
  },
  eventType,
  groupedSessionType,
  availableVideoSettings,
  editAll,
  batchId,
}) => {
  const { t } = useLocalization();

  // form-related code
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    errors,
  } = useForm({
    defaultValues: {
      tags: [],
      watchers: [],
      tickets: [],
      participants: [],
      moderators: [],
      sessionsSize: defaultSessionsSize,
      sessionsSchedulability: defaultSessionsSchedulability,
      record: defaultRecord,
      youtube: defaultYoutube,
      rtmp: defaultRtmp,
      rtmpTarget: defaultRtmpTarget,
      rtmpStage: defaultRtmpStage,
      rtmpSession: defaultRtmpSession,
      videoSetting: defaultVideoSetting,
    },
    resolver: yupResolver(schema),
  });
  const tagsActionValue = watch('tagsAction');
  const tagsValue = watch('tags');
  const whoCanWatchValue = watch('whoCanWatch');
  const whoCanWatchActionValue = watch('whoCanWatchAction');
  const watchersValue = watch('watchers');
  const ticketsValue = watch('tickets');
  const whoCanParticipateValue = watch('whoCanParticipate');
  const whoCanParticipateActionValue = watch('whoCanParticipateAction');
  const participantsValue = watch('participants');
  const moderatorsValue = watch('moderators');
  const sessionsSizeValue = watch('sessionsSize');
  const sessionsSchedulabilityValue = watch('sessionsSchedulability');
  const rtmpValue = watch('rtmp');
  const rtmpTargetValue = watch('rtmpTarget');
  const rtmpSessionValue = watch('rtmpSession');
  const rtmpStageValue = watch('rtmpStage');
  const recordValue = watch('record');
  const youtubeValue = watch('youtube');
  const videoSettingValue = watch('videoSetting');

  useEffect(() => {
    clearErrors(['whoCanWatchAction']);
  }, [whoCanWatchValue, clearErrors]);

  useEffect(() => {
    clearErrors(['watchers']);
  }, [whoCanWatchValue, whoCanWatchActionValue, watchersValue, clearErrors]);

  useEffect(() => {
    clearErrors(['tickets']);
  }, [whoCanWatchValue, whoCanWatchActionValue, ticketsValue, clearErrors]);

  useEffect(() => {
    clearErrors(['whoCanParticipateAction']);
  }, [whoCanParticipateValue, clearErrors]);

  useEffect(() => {
    clearErrors(['participants']);
  }, [
    whoCanParticipateValue,
    whoCanParticipateActionValue,
    participantsValue,
    clearErrors,
  ]);

  useEffect(() => {
    clearErrors(['moderators']);
  }, [
    whoCanParticipateValue,
    whoCanParticipateActionValue,
    moderatorsValue,
    clearErrors,
  ]);

  useEffect(() => {
    clearErrors(['rtmpSession', 'rtmpStage']);
  }, [rtmpValue, rtmpTargetValue, clearErrors]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [availableWatchers, setAvailableWatchers] = useState({});
  const [isLoadingWatchers, setIsLoadingWatchers] = useState(false);
  const [
    enoughCharactersEnteredForWatchersSearch,
    setEnoughCharactersEnteredForWatchersSearch,
  ] = useState(false);

  const { addAlert } = useContext(AlertsContext);

  // state
  const [availableParticipants, setAvailableParticipants] = useState({});
  const [
    enoughCharactersEnteredForParticipantsSearch,
    setEnoughCharactersEnteredForParticipantsSearch,
  ] = useState(false);
  const [isLoadingParticipants, setIsLoadingParticipants] = useState(false);
  const [availableModerators, setAvailableModerators] = useState({});
  const [
    enoughCharactersEnteredForModeratorsSearch,
    setEnoughCharactersEnteredForModeratorsSearch,
  ] = useState(false);
  const [isLoadingModerators, setIsLoadingModerators] = useState(false);
  const [bid, setBid] = useState(batchId);

  const apiClient = initApiClient(authenticityToken);
  const logger = getLogger(LOGGER_NAMES.SESSIONS);
  const iconAccessibilityProps = t => ({
    clear: {
      title: t('common.select-icon-accessibility-props.clear.title'),
      description: t(
        'common.select-icon-accessibility-props.clear.description',
      ),
    },
    dropdown: {
      title: t('common.select-icon-accessibility-props.dropdown.title'),
      description: t(
        'common.select-icon-accessibility-props.dropdown.description',
      ),
    },
    multiValueRemove: t(
      'common.select-icon-accessibility-props.multi-value-remove.title',
    ),
  });

  const selectOptions = [
    { value: 'add', label: t('sessions.bulk-edit.add') },
    { value: 'remove', label: t('sessions.bulk-edit.remove') },
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
  ];

  const whoCanWatchOptions = [
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
    { value: 'anyone', label: t('sessions.bulk-edit.anyone') },
    { value: 'private_', label: t('sessions.bulk-edit.private') },
    {
      value: 'specific_ticket_holders',
      label: t('sessions.bulk-edit.specific-ticket'),
    },
    { value: 'unlisted', label: t('sessions.bulk-edit.unlisted') },
  ];

  const whoCanParticipateOptions = [
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
    { value: 'anyone_', label: t('sessions.bulk-edit.anyone') },
    { value: 'invite_only', label: t('sessions.bulk-edit.invite') },
    { value: 'moderated', label: t('sessions.bulk-edit.moderated') },
  ];

  const sessionsSizeOptions = [
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
    { value: 'medium', label: t('sessions.bulk-edit.medium') },
    { value: 'large', label: t('sessions.bulk-edit.large') },
  ];

  const sessionsSchedulabilityOptions = [
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
    { value: 'schedulable', label: t('sessions.bulk-edit.schedulable') },
    { value: 'always_on', label: t('sessions.bulk-edit.always-on') },
  ];

  const recordOptions = [
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
    { value: true, label: t('sessions.bulk-edit.record-all') },
    { value: false, label: t('sessions.bulk-edit.dont-record-all') },
  ];

  const youtubeOptions = [
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
    { value: true, label: t('sessions.bulk-edit.enabled') },
    { value: false, label: t('sessions.bulk-edit.disabled') },
  ];

  const rtmpOptions = [
    { value: 'keep', label: t('sessions.bulk-edit.keep') },
    { value: 'true', label: t('sessions.bulk-edit.enabled') },
    { value: 'false', label: t('sessions.bulk-edit.disabled') },
  ];

  const rtmpTargetOptions = [
    { value: 'self', label: t('sessions.bulk-edit.dont-mirror') },
    { value: 'Stage', label: t('sessions.bulk-edit.stage') },
    { value: 'Roundtable', label: t('sessions.bulk-edit.session') },
  ];

  // tags
  const handleTagAdd = tag => {
    setValue('tags', [...new Set([...tagsValue, tag])]);
  };

  const handleTagTogle = tag => {
    let newTags;
    if (includes(tagsValue, tag)) {
      newTags = tagsValue.filter(e => e !== tag);
    } else {
      newTags = [...tagsValue, tag];
    }
    setValue('tags', newTags);
  };

  const handleTagRemove = event => {
    const tag = event.target.closest('div > span').children[1].textContent;
    setValue(
      'tags',
      tagsValue.filter(e => e !== tag),
    );
  };

  // who can watch
  const handleSearchAttendees = async searchText => {
    const route = `${Routes.organisersEventAttendeesPath(
      eventId,
      'json',
    )}?search_text=${encodeURIComponent(searchText)}`;

    try {
      const response = await apiClient['get'](route);
      return response?.items ?? [];
    } catch (error) {
      logger.error(error);
    }
  };

  const handleWatcherSelectFocus = () => {
    setAvailableWatchers([]);
  };

  // who can participate
  const handleParticipantSelectFocus = () => {
    setAvailableParticipants([]);
  };

  const handleModeratorSelectFocus = () => {
    setAvailableModerators([]);
  };

  const handleWatcherSearchInputChange = newValue => {
    if (newValue.length > 1) {
      setEnoughCharactersEnteredForWatchersSearch(true);
      setIsLoadingWatchers(true);

      handleSearchAttendees(newValue)
        .then(matchingAttendees => {
          setAvailableWatchers(
            matchingAttendees.map(attendee => ({
              label: attendee.name,
              value: attendee.id,
              customIcon: attendee.image_url,
            })),
          );
        })
        .finally(() => setIsLoadingWatchers(false));
    } else {
      setEnoughCharactersEnteredForWatchersSearch(false);
      setAvailableWatchers([]);
    }
  };

  const handleParticipantSearchInputChange = newValue => {
    if (newValue.length > 1) {
      setEnoughCharactersEnteredForParticipantsSearch(true);
      setIsLoadingParticipants(true);

      handleSearchAttendees(newValue)
        .then(matchingAttendees => {
          setAvailableParticipants(
            matchingAttendees.map(attendee => ({
              label: attendee.name,
              value: attendee.id,
              customIcon: attendee.image_url,
            })),
          );
        })
        .finally(() => setIsLoadingParticipants(false));
    } else {
      setEnoughCharactersEnteredForParticipantsSearch(false);
      setAvailableParticipants([]);
    }
  };

  const handleModeratorSearchInputChange = newValue => {
    if (newValue.length > 1) {
      setEnoughCharactersEnteredForModeratorsSearch(true);
      setIsLoadingModerators(true);

      handleSearchAttendees(newValue)
        .then(matchingAttendees => {
          setAvailableModerators(
            matchingAttendees.map(attendee => ({
              label: attendee.name,
              value: attendee.id,
              customIcon: attendee.image_url,
            })),
          );
        })
        .finally(() => setIsLoadingModerators(false));
    } else {
      setEnoughCharactersEnteredForModeratorsSearch(false);
      setAvailableModerators([]);
    }
  };

  const onSubmit = async (
    {
      tagsAction,
      tags = [],
      whoCanWatch,
      whoCanWatchAction,
      tickets = [],
      watchers = [],
      whoCanParticipate,
      whoCanParticipateAction,
      participants = [],
      moderators = [],
      sessionsSize,
      sessionsSchedulability,
      record,
      youtube,
      rtmp,
      rtmpTarget,
      rtmpSession,
      rtmpStage,
      maxParticipants,
      priority,
      videoSetting,
    },
    e,
  ) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);

      const { token } = await hopinApi.getUserToken();

      let options = {};

      if (tagsAction) {
        options = {
          ...options,
          associated_tags_action: tagsAction,
          tags: tags.join(','),
        };
      }

      if (whoCanWatch && whoCanWatch !== 'keep') {
        options = {
          ...options,
          associated_can_watch_action: whoCanWatchAction,
          can_watch: whoCanWatch,
          ticket_ids: tickets.map(e => e.value),
          watchers_ids: watchers.map(e => e.value),
        };
      }

      if (whoCanParticipate && whoCanParticipate !== 'keep') {
        options = {
          ...options,
          associated_can_sit_action: whoCanParticipateAction,
          can_sit: whoCanParticipate,
          participate_ids: participants.map(e => e.value),
          moderator_ids: moderators.map(e => e.value),
        };
      }

      if (
        sessionsSize &&
        sessionsSize !== 'keep' &&
        sessionsSize !== defaultSessionsSize
      ) {
        options = {
          ...options,
          booth_size: sessionsSize,
        };
      }

      if (
        sessionsSchedulability &&
        sessionsSchedulability !== 'keep' &&
        sessionsSchedulability !== defaultSessionsSchedulability
      ) {
        options = {
          ...options,
          schedulability: sessionsSchedulability,
        };
      }

      if (record !== 'keep') {
        options = {
          ...options,
          record_status: record,
        };
      }

      if (youtube !== 'keep') {
        options = {
          ...options,
          youtube_sharing_enabled: youtube,
        };
      }

      if (rtmp !== 'keep') {
        options = {
          ...options,
          rtmp_enabled: rtmp,
        };

        if (rtmp) {
          options = {
            ...options,
            mirrorable_type: rtmpTarget,
            mirrorable_id: rtmpTarget == 'Stage' ? rtmpStage : rtmpSession,
          };
        } else {
          options = {
            ...options,
            mirrorable_type: '',
            mirrorable_id: '',
          };
        }
      }

      if (maxParticipants) {
        options = {
          ...options,
          max_participants: maxParticipants,
        };
      }

      if (priority) {
        options = {
          ...options,
          priority,
        };
      }

      if (videoSetting) {
        options = {
          ...options,
          video_setting: videoSetting,
        };
      }

      if (editAll == false) {
        await hopinApi.bulkUpdateRoundtables(
          organizationId,
          eventId,
          token,
          roundtables.map(r => r.id),
          options,
        );
        window.location.assign(
          Routes.organisersEventRoundtablesPath(eventSlug),
        );
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const tagsFromUrl = urlParams.getAll('tag_ids[]');
        const ticketsFromUrl = urlParams.getAll('ticket_ids[]');
        const startDateFromUrl = urlParams.get('from_ts');
        const endDateFromUrl = urlParams.get('to_ts');
        const searchText = urlParams.get('q');
        const { bid } = await hopinApi.bulkUpdateAllRoundtables(
          organizationId,
          eventId,
          token,
          options,
          {
            query_string: searchText,
            tag_ids: tagsFromUrl,
            persona_ids: ticketsFromUrl,
            from_ts: dateToString(startDateFromUrl),
            to_ts: dateToString(endDateFromUrl),
          },
        );
        setBid(bid);
      }
    } catch (error) {
      logger.error(error);
      if (error.response && error.response.status == 409) {
        addAlert({
          active: true,
          text: t('sessions.bulk-edit.update-started-by-another-user'),
          pattern: 'warning',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkKeyDown = e => {
    // prevent form submit on 'Enter' key down
    // because 'Enter' is used to add new tags in AddTag component
    if (e.key === 'Enter') e.preventDefault();
  };

  let maxParticipantsLimit;
  if (videoSessionsEnableLayoutV2) {
    maxParticipantsLimit = 50;
  } else {
    maxParticipantsLimit = recordValue ? 9 : 20;
  }

  return (
    <>
      {/* show alerts for 409 response code */}
      <Alerts />

      <ProgressModal initialBid={bid} />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => checkKeyDown(e)}>
        <>
          <OnsiteBanner
            eventType={eventType}
            sessionType={groupedSessionType}
          />

          <SingleBlock flexDirection="column" mt={3}>
            <StyledSelect
              label={t('sessions.bulk-edit.tags')}
              placeholder={t('sessions.bulk-edit.multiple-selections')}
              options={selectOptions}
              value={tagsActionValue}
              setValue={value => setValue('tagsAction', value)}
              visibleOptionsCount={3}
              size="small"
              {...register('tagsAction')}
            />
            {includes(['add', 'remove'], tagsActionValue) && (
              <AddTag
                addText={t('sessions.bulk-edit.update')}
                tags={availableTags}
                selectedTags={tagsValue}
                onAddTag={handleTagAdd}
                onSelectTag={handleTagTogle}
                canCreateNew
                hideLabel
                mt={0.5}
                size="small"
                sizeOption="small"
                {...register('tags')}
              />
            )}
            {tagsValue.length > 0 && (
              <StyledTagsGroup
                tags={tagsValue}
                p={1}
                onRemove={handleTagRemove}
              />
            )}
          </SingleBlock>

          <hr />

          <Text
            pattern="subHeadingMedium"
            scale={5}
            element={'h4'}
            color={'grey-dark'}
          >
            {t('sessions.bulk-edit.access')}
          </Text>

          <DoubleBlock justifyContent="space-between" mt={3}>
            {/* who can watch */}
            <Flex flexDirection="column">
              <StyledSelect
                label={t('sessions.bulk-edit.who-can-watch')}
                placeholder={t('sessions.bulk-edit.multiple-selections')}
                options={whoCanWatchOptions}
                value={whoCanWatchValue}
                setValue={value => setValue('whoCanWatch', value)}
                visibleOptionsCount={5}
                size="small"
                {...register('whoCanWatch')}
              />
              {includes(
                ['private_', 'specific_ticket_holders'],
                whoCanWatchValue,
              ) && (
                <StyledSelect
                  placeholder={t('sessions.bulk-edit.select-option')}
                  options={selectOptions}
                  value={whoCanWatchActionValue}
                  setValue={value => setValue('whoCanWatchAction', value)}
                  visibleOptionsCount={3}
                  size="small"
                  mt={1}
                  hideLabel
                  hasErrors={errors['whoCanWatchAction']}
                  errorMessage={t('sessions.bulk-edit.required')}
                  {...register('whoCanWatchAction')}
                />
              )}
              {includes(['add', 'remove'], whoCanWatchActionValue) &&
                whoCanWatchValue === 'private_' && (
                  <>
                    <StyledCombobox
                      placeholder={t('sessions.bulk-edit.search-attendies')}
                      className="segment-select-container"
                      classNamePrefix="segment-select"
                      isSearchable
                      onInputChange={handleWatcherSearchInputChange}
                      inputId="attendee-list"
                      iconAccessibilityProps={iconAccessibilityProps(t)}
                      onChange={v => setValue('watchers', v)}
                      options={availableWatchers}
                      onFocus={handleWatcherSelectFocus}
                      noOptionsMessage={() =>
                        enoughCharactersEnteredForWatchersSearch
                          ? t('speaker-list.add-attendee-as-speaker.no-results')
                          : t(
                              'speaker-list.add-attendee-as-speaker.enter-characters',
                            )
                      }
                      isLoading={isLoadingWatchers}
                      value={watchersValue}
                      isMulti
                      aria-invalid={errors['watchers']}
                      aria-errormessage="watcher-list-error"
                      {...register('watchers')}
                    />
                    {errors['watchers'] && (
                      <FieldErrorMessage
                        mt={1}
                        errorMessage={t('sessions.bulk-edit.required')}
                        id="watcher-list-error"
                      />
                    )}
                  </>
                )}
              {includes(['add', 'remove'], whoCanWatchActionValue) &&
                whoCanWatchValue === 'specific_ticket_holders' && (
                  <>
                    <StyledCombobox
                      placeholder={t('sessions.bulk-edit.search-tickets')}
                      className="segment-select-container"
                      classNamePrefix="segment-select"
                      inputId="ticket-list"
                      iconAccessibilityProps={iconAccessibilityProps(t)}
                      onChange={v => setValue('tickets', v)}
                      options={availableTickets}
                      value={ticketsValue}
                      isMulti
                      aria-invalid={errors['tickets']}
                      aria-errormessage="ticket-list-error"
                      {...register('tickets')}
                    />
                    {errors['tickets'] && (
                      <FieldErrorMessage
                        mt={1}
                        errorMessage={t('sessions.bulk-edit.required')}
                        id="ticket-list-error"
                      />
                    )}
                  </>
                )}
            </Flex>

            {/* who can participate */}
            <Flex flexDirection="column">
              <StyledSelect
                label={t('sessions.bulk-edit.who-can-participate')}
                placeholder={t('sessions.bulk-edit.multiple-selections')}
                options={whoCanParticipateOptions}
                value={whoCanParticipateValue}
                setValue={value => setValue('whoCanParticipate', value)}
                visibleOptionsCount={5}
                size="small"
                {...register('whoCanParticipate')}
              />
              {includes(
                ['invite_only', 'moderated'],
                whoCanParticipateValue,
              ) && (
                <StyledSelect
                  placeholder={t('sessions.bulk-edit.select-option')}
                  options={selectOptions}
                  value={whoCanParticipateActionValue}
                  setValue={value => setValue('whoCanParticipateAction', value)}
                  visibleOptionsCount={3}
                  size="small"
                  mt={1}
                  hideLabel
                  hasErrors={errors['whoCanParticipateAction']}
                  errorMessage={t('sessions.bulk-edit.required')}
                  {...register('whoCanParticipateAction')}
                />
              )}
              {includes(['add', 'remove'], whoCanParticipateActionValue) &&
                whoCanParticipateValue === 'invite_only' && (
                  <>
                    <StyledCombobox
                      placeholder={t('sessions.bulk-edit.search-attendies')}
                      className="segment-select-container"
                      classNamePrefix="segment-select"
                      mt={8}
                      isSearchable
                      onInputChange={handleParticipantSearchInputChange}
                      inputId="attendee-list"
                      iconAccessibilityProps={iconAccessibilityProps(t)}
                      onChange={v => setValue('participants', v)}
                      options={availableParticipants}
                      onFocus={handleParticipantSelectFocus}
                      noOptionsMessage={() =>
                        enoughCharactersEnteredForParticipantsSearch
                          ? t('speaker-list.add-attendee-as-speaker.no-results')
                          : t(
                              'speaker-list.add-attendee-as-speaker.enter-characters',
                            )
                      }
                      isLoading={isLoadingParticipants}
                      value={participantsValue}
                      isMulti
                      aria-invalid={errors['participants']}
                      aria-errormessage="participants-list-error"
                      {...register('participants')}
                    />
                    {errors['participants'] && (
                      <FieldErrorMessage
                        mt={1}
                        errorMessage={t('sessions.bulk-edit.required')}
                        id="participants-list-error"
                      />
                    )}
                  </>
                )}
              {includes(['add', 'remove'], whoCanParticipateActionValue) &&
                whoCanParticipateValue === 'moderated' && (
                  <>
                    <StyledCombobox
                      placeholder={t('sessions.bulk-edit.search-attendies')}
                      className="segment-select-container"
                      classNamePrefix="segment-select"
                      mt={8}
                      isSearchable
                      onInputChange={handleModeratorSearchInputChange}
                      inputId="attendee-list"
                      iconAccessibilityProps={iconAccessibilityProps(t)}
                      onChange={v => setValue('moderators', v)}
                      options={availableModerators}
                      onFocus={handleModeratorSelectFocus}
                      noOptionsMessage={() =>
                        enoughCharactersEnteredForModeratorsSearch
                          ? t('speaker-list.add-attendee-as-speaker.no-results')
                          : t(
                              'speaker-list.add-attendee-as-speaker.enter-characters',
                            )
                      }
                      isLoading={isLoadingModerators}
                      value={moderatorsValue}
                      isMulti
                      aria-invalid={errors['moderators']}
                      aria-errormessage="moderators-list-error"
                      {...register('moderators')}
                    />
                    {errors['moderators'] && (
                      <FieldErrorMessage
                        mt={1}
                        errorMessage={t('sessions.bulk-edit.required')}
                        id="moderators-list-error"
                      />
                    )}
                  </>
                )}
            </Flex>
          </DoubleBlock>

          <hr />

          <Text
            pattern="subHeadingMedium"
            scale={5}
            element={'h4'}
            color={'grey-dark'}
          >
            {t('sessions.bulk-edit.display')}
          </Text>

          <DoubleBlock justifyContent="space-between" mt={3}>
            {/* sessions size */}
            <Flex flexDirection="column">
              <StyledSelect
                label={t('sessions.bulk-edit.size')}
                placeholder={t('sessions.bulk-edit.multiple-selections')}
                options={sessionsSizeOptions}
                value={sessionsSizeValue}
                setValue={value => setValue('sessionsSize', value)}
                visibleOptionsCount={3}
                size="small"
                {...register('sessionsSize')}
              />
            </Flex>

            <Flex flexDirection="column">
              <NumberInput
                label={t('sessions.bulk-edit.priority')}
                defaultValue={defaultPriority}
                onChange={e => setValue('priority', e.target.value)}
                type="number"
                size="medium"
                min={1}
                mb={1}
                {...register('priority')}
              />
            </Flex>
          </DoubleBlock>

          {eventType !== 'onsite' && (
            <>
              <hr />

              <div className={'mb-16'}>
                <Text
                  className={'mb-0'}
                  pattern="subHeadingMedium"
                  scale={5}
                  element={'h4'}
                  color={
                    eventType == 'hybrid' && groupedSessionType == 'onsite'
                      ? 'grey-400'
                      : 'grey-dark'
                  }
                >
                  {t('sessions.bulk-edit.scheduling')}
                </Text>

                {eventType == 'hybrid' && groupedSessionType == 'hybrid' && (
                  <Flex className={'mt-4'}>
                    <Icon name="warning" color="yellow-500" mt={0.5} mr={0.5} />
                    <Box>
                      <Text
                        pattern="body"
                        element="p"
                        color="yellow-500"
                        mt={0.25}
                      >
                        {t('sessions.bulk-edit.only-virtual-hybrid')}
                      </Text>
                    </Box>
                  </Flex>
                )}
              </div>

              <SingleBlock flexDirection="column" mt={3}>
                {/* sessions schedulability */}
                <StyledSelect
                  label={t('sessions.bulk-edit.schedulability')}
                  placeholder={t('sessions.bulk-edit.multiple-selections')}
                  options={sessionsSchedulabilityOptions}
                  value={sessionsSchedulabilityValue}
                  setValue={value => setValue('sessionsSchedulability', value)}
                  visibleOptionsCount={3}
                  size="small"
                  disabled={
                    eventType == 'hybrid' && groupedSessionType == 'onsite'
                  }
                  {...register('sessionsSchedulability')}
                />
                {sessionsSchedulabilityValue == 'always_on' &&
                  defaultSessionsSchedulability != 'always_on' &&
                  roundtablesHaveSegments && (
                    <Box mt={2}>
                      <Box background="yellow-100" cornerRadius="lg" p={2}>
                        <Flex>
                          <Icon
                            name="warning"
                            color="yellow-500"
                            mt={1}
                            mr={1}
                          />
                          <Box>
                            <Text pattern="headingFive" element="p">
                              {t('sessions.bulk-edit.cant-be-linked-warning')}
                            </Text>
                            <Text pattern="body" element="p" mt={0.25}>
                              {t(
                                'sessions.bulk-edit.wont-appear-in-schedule-warning',
                              )}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </Box>
                  )}
              </SingleBlock>
            </>
          )}

          {eventType !== 'onsite' && (
            <>
              <hr />

              <div className={'mb-16'}>
                <Text
                  className={'mb-0'}
                  pattern="subHeadingMedium"
                  scale={5}
                  element={'h4'}
                  color={
                    eventType == 'hybrid' && groupedSessionType == 'onsite'
                      ? 'grey-400'
                      : 'grey-dark'
                  }
                >
                  {t('sessions.bulk-edit.video-content')}
                </Text>

                {eventType == 'hybrid' && groupedSessionType == 'hybrid' && (
                  <Flex className={'mt-4'}>
                    <Icon name="warning" color="yellow-500" mt={0.5} mr={0.5} />
                    <Box>
                      <Text
                        pattern="body"
                        element="p"
                        color="yellow-500"
                        mt={0.25}
                      >
                        {t('sessions.bulk-edit.only-virtual-hybrid')}
                      </Text>
                    </Box>
                  </Flex>
                )}
              </div>

              <DoubleBlock justifyContent="space-between" mt={3}>
                {/* max participants */}
                <Flex flexDirection="column">
                  <NumberInput
                    label={t('sessions.bulk-edit.max-participants')}
                    defaultValue={defaultMaxParticipants}
                    onChange={e => setValue('maxParticipants', e.target.value)}
                    type="number"
                    size="medium"
                    min={1}
                    max={maxParticipantsLimit}
                    mb={1}
                    disabled={
                      eventType == 'hybrid' && groupedSessionType == 'onsite'
                    }
                    {...register('maxParticipants')}
                  />
                </Flex>

                <Flex flexDirection="column">
                  <StyledSelect
                    label={t('sessions.bulk-edit.youtube')}
                    placeholder={t('sessions.bulk-edit.select-option')}
                    options={youtubeOptions}
                    value={youtubeValue}
                    setValue={value => setValue('youtube', value)}
                    visibleOptionsCount={3}
                    size="small"
                    disabled={
                      eventType == 'hybrid' && groupedSessionType == 'onsite'
                    }
                    {...register('youtube')}
                  />
                </Flex>
              </DoubleBlock>

              <DoubleBlock justifyContent="space-between" mt={3}>
                <Flex flexDirection="column">
                  <StyledSelect
                    label={t('sessions.bulk-edit.record')}
                    placeholder={t('sessions.bulk-edit.select-option')}
                    options={recordOptions}
                    value={recordValue}
                    setValue={value => setValue('record', value)}
                    visibleOptionsCount={3}
                    size="small"
                    disabled={
                      eventType == 'hybrid' && groupedSessionType == 'onsite'
                    }
                    {...register('record')}
                  />
                </Flex>
                {rtmpEnabled && (
                  <Flex flexDirection="column">
                    <StyledSelect
                      label={t('sessions.bulk-edit.rtmp-streaming')}
                      placeholder={t('sessions.bulk-edit.select-option')}
                      options={rtmpOptions}
                      value={rtmpValue}
                      setValue={value => setValue('rtmp', value)}
                      visibleOptionsCount={3}
                      size="small"
                      disabled={
                        eventType == 'hybrid' && groupedSessionType == 'onsite'
                      }
                      {...register('rtmp')}
                    />

                    {rtmpValue === 'true' && (
                      <StyledSelect
                        label={t('sessions.bulk-edit.rtmp-target')}
                        placeholder={t('sessions.bulk-edit.select-option')}
                        options={rtmpTargetOptions}
                        value={rtmpTargetValue}
                        setValue={value => setValue('rtmpTarget', value)}
                        visibleOptionsCount={4}
                        size="small"
                        mt={1}
                        hideLabel
                        disabled={
                          eventType == 'hybrid' &&
                          groupedSessionType == 'onsite'
                        }
                        {...register('rtmpTarget')}
                      />
                    )}

                    {rtmpValue === 'true' &&
                      rtmpTargetValue == 'Roundtable' && (
                        <StyledSelect
                          placeholder={t('sessions.bulk-edit.search-session')}
                          options={availableRtmpSessions}
                          value={rtmpSessionValue}
                          setValue={value => setValue('rtmpSession', value)}
                          visibleOptionsCount={4}
                          size="small"
                          mt={1}
                          hideLabel
                          hasErrors={errors['rtmpSession']}
                          errorMessage={t('sessions.bulk-edit.required')}
                          disabled={
                            eventType == 'hybrid' &&
                            groupedSessionType == 'onsite'
                          }
                          {...register('rtmpSession')}
                        />
                      )}

                    {rtmpValue === 'true' && rtmpTargetValue == 'Stage' && (
                      <StyledSelect
                        placeholder={t('sessions.bulk-edit.search-stage')}
                        options={availableRtmpStages}
                        value={rtmpStageValue}
                        setValue={value => setValue('rtmpStage', value)}
                        visibleOptionsCount={4}
                        size="small"
                        mt={1}
                        hideLabel
                        hasErrors={errors['rtmpStage']}
                        errorMessage={t('sessions.bulk-edit.required')}
                        disabled={
                          eventType == 'hybrid' &&
                          groupedSessionType == 'onsite'
                        }
                        {...register('rtmpStage')}
                      />
                    )}
                  </Flex>
                )}
              </DoubleBlock>

              {videoSettingsEnabled && (
                <SingleBlock flexDirection="column" mt={3}>
                  <StyledSelect
                    label={t('sessions.bulk-edit.video-settings')}
                    placeholder={t('sessions.bulk-edit.select-option')}
                    options={availableVideoSettings}
                    value={videoSettingValue}
                    setValue={value => setValue('videoSetting', value)}
                    visibleOptionsCount={5}
                    size="small"
                    disabled={
                      eventType == 'hybrid' && groupedSessionType == 'onsite'
                    }
                    {...register('videoSetting')}
                  />
                </SingleBlock>
              )}
            </>
          )}

          <SaveBar
            buttonText={t('sessions.bulk-edit.save')}
            isLoading={isSubmitting}
            onButtonClick={handleSubmit}
          />
        </>
      </form>
    </>
  );
};

export default compose(withAlertsProvider, withLocalizationProvider)(BulkEdit);

BulkEdit.propTypes = {
  roundtables: arrayOf(shape({ id: number.isRequired })).isRequired,
  organizationId: number.isRequired,
  eventId: number.isRequired,
  eventSlug: string.isRequired,
  authenticityToken: string.isRequired,
  availableTags: arrayOf(string).isRequired,
  availableTickets: arrayOf(
    shape({
      label: string.isRequired,
      value: number.isRequired,
    }),
  ).isRequired,
  roundtablesHaveSegments: bool.isRequired,
  videoSettingsEnabled: bool.isRequired,
  rtmpEnabled: bool.isRequired,
  availableRtmpStages: arrayOf(
    shape({
      label: string.isRequired,
      value: number.isRequired,
    }),
  ).isRequired,
  availableRtmpSessions: arrayOf(
    shape({
      label: string.isRequired,
      value: number.isRequired,
    }),
  ).isRequired,
  videoSessionsEnableLayoutV2: bool.isRequired,
  defaultData: shape({
    sessions_size: string,
    sessions_schedulability: string,
    record: bool,
    youtube: bool,
    rtmp: string,
    rtmp_target: string,
    rtmp_stage: number,
    rtmp_session: number,
    priority: number,
    max_participants: number,
    video_setting: number,
  }),
  eventType: oneOf(['onsite', 'hybrid', 'virtual']),
  groupedSessionType: oneOf(['onsite', 'hybrid', 'virtual']),
  availableVideoSettings: arrayOf(
    shape({
      label: string.isRequired,
      value: number.isRequired,
    }),
  ).isRequired,
  editAll: bool.isRequired,
  batchId: string,
};
