// Support component names relative to this directory:
import 'focus-visible';
import '@reach/tabs/styles.css';
import '@reach/dialog/styles.css';
import '../datepickers';

var componentRequireContext = require.context('react', true);
var ReactRailsUJS = require('react_ujs');

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);
