import { DialogContent, DialogOverlay } from '@reach/dialog';
import styled, { css } from 'styled-components';

const Overlay = styled(DialogOverlay)`
  height: 100%;
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  z-index: 1000;

  &[data-reach-dialog-overlay] {
    background-color: hsla(224, 20%, 22%, 0.88);
  }
`;

const getSize = size => {
  const smallSize = '27rem';
  const mediumSize = '34.375rem';
  const largeSize = '43.75rem';
  const xLargeSize = '61.125rem';

  switch (size) {
    case 'small':
      return smallSize;
    case 'medium':
      return mediumSize;
    case 'large':
      return largeSize;
    case 'xLarge':
      return xLargeSize;
    default:
      return smallSize;
  }
};

const Content = styled(DialogContent)`
  max-height: 80vh;
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0px 1px 2px rgba(44, 50, 66, 0.08);
  position: relative;

  &[data-reach-dialog-content] {
    width: 90vw;
    max-width: ${({ size }) => getSize(size)};
    margin: 1.25rem auto;
    padding: 0;
    overflow: ${({ overflow }) => overflow};
  }
`;

export const iconButtonStyles = css`
  &:focus {
    outline-width: 0;
  }

  &:hover {
    path {
      fill: var(--color-gray-800);
    }
  }
`;

const CloseButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;

  path {
    fill: var(--color-gray-600);
  }

  &:hover {
    background: var(--color-gray-100);
  }

  ${iconButtonStyles}
`;

export { Overlay, Content, CloseButton };
