import React from 'react';

const Booth = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2664 1.69922C20.0239 1.69922 20.7164 2.12722 21.0552 2.80479L22.2913 5.27693C22.4302 5.55464 22.5024 5.86086 22.5024 6.17135V7.69922C22.5024 8.80379 21.607 9.69922 20.5024 9.69922C20.1381 9.69922 19.7966 9.60181 19.5024 9.43161L19.5024 15.6992H21.5024C22.0547 15.6992 22.5024 16.1469 22.5024 16.6992C22.5024 17.2515 22.0547 17.6992 21.5024 17.6992V22.6992C21.5024 23.2515 21.0547 23.6992 20.5024 23.6992H4.50244C3.95016 23.6992 3.50244 23.2515 3.50244 22.6992V17.6992C2.95016 17.6992 2.50244 17.2515 2.50244 16.6992C2.50244 16.1469 2.95016 15.6992 3.50244 15.6992H5.50244L5.50352 9.43103C5.24963 9.57811 4.96041 9.67096 4.6517 9.69373L4.50244 9.69922C3.44808 9.69922 2.58428 8.88334 2.50793 7.84848L2.50244 7.69922V6.17547C2.50244 5.86235 2.57596 5.5536 2.71709 5.27408L3.96731 2.79782C4.30746 2.12409 4.99792 1.69922 5.75266 1.69922H19.2664ZM14.5024 18.6992H10.5024C9.95016 18.6992 9.50244 19.1469 9.50244 19.6992C9.50244 20.2515 9.95016 20.6992 10.5024 20.6992H14.5024C15.0547 20.6992 15.5024 20.2515 15.5024 19.6992C15.5024 19.1469 15.0547 18.6992 14.5024 18.6992ZM14.5024 7.69922L14.497 7.84848C14.4206 8.88334 13.5568 9.69922 12.5024 9.69922C11.4481 9.69922 10.5843 8.88334 10.5079 7.84848L10.5024 7.69922L10.497 7.84848C10.4206 8.88334 9.5568 9.69922 8.50244 9.69922C8.13812 9.69922 7.79656 9.60181 7.50237 9.43161L7.50244 15.6992H17.5024L17.5035 9.43103C17.2496 9.57811 16.9604 9.67096 16.6517 9.69373L16.5024 9.69922C15.3979 9.69922 14.5024 8.80379 14.5024 7.69922Z"
      fill="currentColor"
    />
  </svg>
);

export default Booth;
