/**
 * Converts money value into localized currency.
 * @param {number} value - The money value (not in cents)
 * @param {string} currency - The currency abbreviation (ie USD)
 * @param {string} locale - The language code (ie zh-SG)
 */
export const formatCurrency = (value, currency, locale) => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    value,
  );
};
