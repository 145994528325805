import EmptyState from '@components/empty-state';
import { getAppStoreWebUrl } from '@features/integration/helpers/get-app-store-web-url';
import { getEventAreaParameter } from '@features/integration/helpers/get-event-area-parameter';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import P from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import BaseInput from './base-input';
import MenuIconInput from './menu-icon-input';
import PostConfigs from './post-configs';

const S = {
  Box: styled.div`
    display: flex;
    align-items: flex-end;
    gap: 1rem;
  `,
  PostConfigs: styled(PostConfigs)`
    flex-basis: 360px;
  `,
  Input: styled(BaseInput)`
    flex-basis: 360px;
  `,
  IconInput: styled(MenuIconInput)`
    flex-basis: 360px;
  `,
  Select: styled(Select)`
    flex-basis: 360px;
  `,
};

function convertPostConfig(postConfig) {
  return postConfig.map(config => {
    const [key] = Object.keys(config);

    if (key === 'tab_name') {
      return { field: key, label: 'Menu Label' };
    }

    return { field: key, ...config[key] };
  });
}

function getInputValues({ isSameApp, fields, currentConfigs, defaultTabName }) {
  if (isSameApp && currentConfigs) {
    return { ...currentConfigs };
  }

  // set all config value to empty string
  const fieldValues = fields.reduce((acc, val) => ({ ...acc, [val]: '' }), {});

  fieldValues['tab_name'] = defaultTabName;

  return fieldValues;
}

export const configField = (named, configs) =>
  configs.find(({ field }) => field === named);

const AppArea = ({
  installedAppAreaApps,
  selectedCustomApp,
  orgExternalId,
  venue,
  version = '1.0.0',
}) => {
  const { t } = useLocalization('app-area');
  const isVersion_1_1_0 = version === '1.1.0';
  const [selectedApp, setSelectedApp] = useState(
    installedAppAreaApps.find(app => app.id === selectedCustomApp?.id) || null,
  );
  const [configs, setConfigs] = useState(
    convertPostConfig(selectedApp?.post_configuration || []),
  );
  const [values, setValues] = useState(
    getInputValues({
      isSameApp: selectedApp?.id === selectedCustomApp?.id,
      fields: configs.map(config => config.field),
      currentConfigs: selectedCustomApp?.current_configuration,
      defaultTabName: selectedApp?.name || '',
    }),
  );

  const appsOptions = installedAppAreaApps.map(app => ({
    value: app.id,
    label: app.name,
  }));

  const noSelectedAppOption = {
    value: 'no-selected-app',
    label: t('select.placeholder'),
  };

  const allDropdownOptions = isVersion_1_1_0
    ? [noSelectedAppOption, ...appsOptions]
    : appsOptions;

  const appStoreUrl = getAppStoreWebUrl({
    organizationId: orgExternalId,
    eventAreaParameter: getEventAreaParameter({ venue }),
  });

  const onAppSelected = id => {
    if (id === noSelectedAppOption.value) {
      setSelectedApp(null);
      setConfigs([]);
      setValues(
        getInputValues({
          isSameApp: false,
          fields: [],
          currentConfigs: null,
          defaultTabName: '',
        }),
      );
    } else {
      setSelectedApp(installedAppAreaApps.find(app => app.id === id));
    }
  };

  const onValueUpdate = useCallback(
    (key, val) => {
      setValues(values => ({
        ...values,
        [key]: val,
      }));
    },
    [setValues],
  );

  useEffect(() => {
    if (!selectedApp?.id) return;

    const newConfigs = convertPostConfig(selectedApp?.post_configuration || []);

    setConfigs(newConfigs);
  }, [selectedApp]);

  useEffect(() => {
    if (!selectedApp?.id) return;

    setValues(
      getInputValues({
        isSameApp: selectedApp?.id === selectedCustomApp?.id,
        fields: configs.map(config => config.field),
        currentConfigs: selectedCustomApp?.current_configuration,
        defaultTabName: selectedApp?.name || '',
      }),
    );
  }, [selectedApp, configs, selectedCustomApp]);

  const showSetupArea = !!(
    isVersion_1_1_0 &&
    selectedApp &&
    configs.filter(config => !['tab_name', 'enabled'].includes(config.field))
      .length > 0
  );

  if (!installedAppAreaApps.length && isVersion_1_1_0) {
    return (
      <EmptyState pageType="app">
        <Button isInline size="small" href={appStoreUrl} target="_blank">
          {t('emptyStateButton')}
        </Button>
      </EmptyState>
    );
  }

  return (
    <div className="container -event-dash-form mt-3">
      <div className="row">
        <div className="col-12">
          <Text element="h2" mb={1} pattern="headingFour">
            {t('configuration.header')}
          </Text>
          <Text pattern="labelThree" color="grey-600">
            {t('configuration.helper-message')}
          </Text>
          <S.Box>
            <input
              type="hidden"
              name={'app_area[app_id]'}
              value={selectedApp?.id}
              readOnly
            />
            <input
              type="hidden"
              name={'app_area[destroy]'}
              value={!selectedApp?.id}
              data-testid="destroy"
              readOnly
            />
            <S.Select
              options={allDropdownOptions}
              label={t('select.label')}
              showOptionsLabel={t('select.show_options')}
              placeholder={t('select.placeholder')}
              mt={2.5}
              value={selectedApp?.id}
              setValue={id => onAppSelected(id)}
              size="small"
              disabled={installedAppAreaApps.length === 0}
            />
            <Button
              isInline
              isOutlined
              leadingIcon="grid-view"
              size="small"
              target="_blank"
              href={appStoreUrl}
              mb={0.5}
            >
              {t('button')}
            </Button>
          </S.Box>
          {showSetupArea && (
            <>
              <Text element="h2" mb={1} mt={5} pattern="headingFour">
                {t('setup.header')}
              </Text>
              <Text pattern="labelThree" color="grey-600">
                {t('setup.helper-message')}
              </Text>
              &nbsp;
              {selectedApp?.documentation && (
                <Text
                  pattern="labelThree"
                  color="grey-600"
                  dangerouslySetInnerHTML={{
                    __html: t('setup.usage-guide-message', {
                      name: selectedApp.name,
                      usageGuide: selectedApp.documentation,
                    }),
                  }}
                />
              )}
            </>
          )}
          <S.Box>
            <S.PostConfigs
              configs={configs}
              values={values}
              onValueUpdate={onValueUpdate}
            />
          </S.Box>
          {selectedApp && (
            <>
              <Text element="h2" mt={5} mb={1} pattern="headingFour">
                {t('customize.header')}
              </Text>
              <Text pattern="labelThree" color="grey-600">
                {t('customize.helper-message')}
              </Text>
              <S.Box>
                <S.Input
                  config={configField('tab_name', configs)}
                  value={values['tab_name']}
                  onValueUpdate={value => onValueUpdate('tab_name', value)}
                />
                {isVersion_1_1_0 && (
                  <S.IconInput
                    value={values['icon']}
                    onChange={value => onValueUpdate('icon', value)}
                  />
                )}
              </S.Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

AppArea.propTypes = {
  installedAppAreaApps: P.arrayOf(
    P.shape({
      id: P.string.isRequired,
      name: P.string.isRequired,
      post_configuration: P.arrayOf(P.object),
    }),
  ).isRequired,
  selectedCustomApp: P.shape({
    id: P.string,
    current_configuration: P.object,
  }),
  orgExternalId: P.string.isRequired,
  canonicalUrl: P.string,
  fallbackCanonicalUrl: P.string.isRequired,
  environment: P.string.isRequired,
  fallbackEnvironment: P.string.isRequired,
  venue: P.string.isRequired,
  version: P.string,
};

export default withLocalizationProvider(AppArea);
