import PropTypes from 'prop-types';
import React from 'react';

const ChevronRight = ({ size, color }) => {
  size = size || 12;
  color = color || 'currentColor';

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.366874 1.28668C0.311247 1.23191 0.279958 1.15736 0.279958 1.07959C0.279958 1.00182 0.311247 0.927275 0.366874 0.872509L0.989805 0.254176C1.04313 0.197782 1.11757 0.165791 1.19549 0.165791C1.27341 0.165791 1.34785 0.197782 1.40117 0.254176L6.80186 5.61501C6.88455 5.69698 6.93105 5.80818 6.93115 5.92418V6.07584C6.93105 6.19184 6.88455 6.30304 6.80186 6.38501L1.40117 11.7458C1.34785 11.8022 1.27341 11.8342 1.19549 11.8342C1.11757 11.8342 1.04313 11.8022 0.989805 11.7458L0.366874 11.1275C0.311247 11.0727 0.279958 10.9982 0.279958 10.9204C0.279958 10.8427 0.311247 10.7681 0.366874 10.7133L5.11525 6.00001L0.366874 1.28668Z"
      />
    </svg>
  );
};

ChevronRight.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ChevronRight;
