export const ORGANIZATION_DASHBOARD_EVENTS = {
  CREATE_EVENT_CLICKED: 'create_event_clicked',
  EVENT_CLICKED: 'event_clicked',
  OVERVIEW_PAGE_VISITED: 'overview_page_visited',
  UPGRADE_BUTTON_CLICKED: 'upgrade_button_clicked',
  VIEW_ALL_EVENTS_PRESSED: 'view_all_events_pressed',
  DELETE_EVENT_CLICKED: 'delete_event_clicked',
  DUPLICATE_EVENT_CLICKED: 'duplicate_event_clicked',
  EDIT_EVENT_CLICKED: 'edit_event_clicked',
};
