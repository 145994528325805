import eventDashboardApi from '@api/event-dashboard';
import Loading from '@components/loading';
import AlertsProvider from '@features/alerts/alerts-provider';
import { withLocalizationProvider } from '@features/localization';
import camelizeKeys from '@util/camelize-keys';
import { string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setHostInformation } from '@/redux/reducers/host-information';
import withReduxProvider from '@/redux/with-provider';

import HostInformationComponent from './host-information-component';

const HostInformationContainer = ({ eventId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const response = await eventDashboardApi.getHostInformation(eventId);

      dispatch(setHostInformation(camelizeKeys(response.data)));
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const { id, attributes } = useSelector(
    ({ hostInformation }) => hostInformation,
  );

  const isLoading = !id;

  return (
    <AlertsProvider>
      <Loading isLoading={isLoading}>
        {!isLoading && (
          <HostInformationComponent id={id} attributes={attributes} />
        )}
      </Loading>
    </AlertsProvider>
  );
};

HostInformationContainer.propTypes = {
  eventId: string.isRequired,
};

export default withReduxProvider(
  withLocalizationProvider(HostInformationContainer),
);
