import styled from 'styled-components';

const Chip = styled.div`
  background: var(--color-blue-200);
  border-radius: var(--border-radius-small);
  padding: var(--spacing-8) var(--spacing-12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-blue-400);
  font-weight: bold;
  white-space: nowrap;
  margin-right: var(--spacing-16);
`;

const ChipButton = styled.div`
  margin-left: var(--spacing-4);
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
`;

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  position: relative;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollTrack = styled.div`
  overflow-x: auto;
  display: flex;
  padding: 0;
  align-items: center;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
`;

export { Chip, ChipContainer, Container, ScrollTrack, ChipButton };
