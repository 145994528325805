import { useOnClickOutside } from '@hopin-team/ui-helper-hooks';
import { Text } from '@hopin-team/ui-text';
import PlusIcon from 'icons/plus.svg';
import SelectArrow from 'icons/select-arrow.svg';
import { array, func, string } from 'prop-types';
import React, { useRef, useState } from 'react';

import SelectStyles, {
  Option,
  OptionList,
  SelectContainer,
  SelectLabel,
} from './select-styles';

const Select = ({ optionsList, onSelect, label, noOptionsText }) => {
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef();
  useOnClickOutside(wrapperRef, () => setIsActive(false));

  return (
    <SelectContainer ref={wrapperRef}>
      <SelectStyles isActive={isActive} onClick={() => setIsActive(!isActive)}>
        <SelectLabel>{label}</SelectLabel>
        <SelectArrow />
      </SelectStyles>
      <OptionList data-testid={`option-list-${label}`} isActive={isActive}>
        {optionsList.length === 0 ? (
          <Text color="grey-500">{noOptionsText}</Text>
        ) : (
          optionsList.map(option => (
            <Option
              data-testid={`select-component-${option.label}`}
              onClick={() => onSelect(option)}
              key={`select-options-list-${option.label}`}
            >
              <span>{option.label}</span>
              <PlusIcon />
            </Option>
          ))
        )}
      </OptionList>
    </SelectContainer>
  );
};

Select.propTypes = {
  optionsList: array.isRequired,
  onSelect: func.isRequired,
  label: string.isRequired,
  noOptionsText: string,
};

export default Select;
