import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { DurationLabel, DurationText } from './duration.styled';

export const Duration = withLocalizationProvider(({ text }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <DurationLabel pattern="body" element="p">
        {t('edit-recording-modal.duration')}
      </DurationLabel>

      <DurationText pattern="body" element="p">
        {text}
      </DurationText>
    </>
  );
});

Duration.propTypes = {
  text: PropTypes.string.isRequired,
};
