import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import compose from 'lodash/fp/compose';
import { bool, func } from 'prop-types';
import React from 'react';

import { ConfirmModal } from '../../confirm-modal';

const StopAllEmailsModal = ({
  isModalDisplayed,
  handleClose,
  confirmStopAll,
}) => {
  const { t } = useLocalization('communications.customise-emails');

  return (
    <ConfirmModal
      label="Stop All Emails Modal"
      title={t(`index.stop-all-emails.confirmation`)}
      message={t(`index.stop-all-emails.description-without-youre-in`)}
      open={isModalDisplayed}
      onCancel={handleClose}
      onConfirm={confirmStopAll}
      cancelButtonProps={{
        'data-testid': 'cancel-button',
      }}
      confirmButtonProps={{
        'data-testid': 'stop-all-emails-button-confirm',
        label: t(`index.stop-all-emails.confirm`),
        pattern: 'primary',
      }}
    />
  );
};

StopAllEmailsModal.propTypes = {
  handleClose: func.isRequired,
  isModalDisplayed: bool.isRequired,
  confirmStopAll: func.isRequired,
};

export default compose(withLocalizationProvider)(StopAllEmailsModal);
