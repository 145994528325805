import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { RetryButton } from './error-banner.styled';

export const ErrorBanner = withLocalizationProvider(({ onRetry }) => {
  const { t } = useLocalization('recording-group');

  return (
    <Flex>
      <Text color="utility-error" pattern="emphasis" scale={5}>
        {t('something-went-wrong-and-we-couldnt-load-recordings')}
      </Text>
      <RetryButton onClick={onRetry}>{t('retry')}</RetryButton>
    </Flex>
  );
});

ErrorBanner.propTypes = {
  onRetry: PropTypes.func,
};
