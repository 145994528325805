import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import styled from 'styled-components';

export const MetricCardContainer = styled.div`
  display: flex;
`;

export const MetricCardValueWrapper = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
`;

export const MetricCardValue = styled.div`
  line-height: 1.5rem;
  font-family: var(--font-family-display);
  font-size: var(--font-size-heading-1);
  font-weight: var(--font-weight-medium);
  ${({ disabled }) => (disabled ? 'color: var(--color-gray-600);' : '')};
`;

export const MetricCardDescription = styled.div`
  color: var(--color-gray-600);
  font-weight: var(--font-weight-medium);
  line-height: 1;
`;

export const MetricCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
`;

export const CircleGraphicInnerContainer = styled(Flex)`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  justify-content: center;
  align-items: center;
`;

export const ColoredIcon = styled(Icon)`
  color: ${({ iconColor }) => iconColor};
`;

export const CircleGraphicSvg = styled.svg`
  position: absolute;
`;

export const TooltipWrapper = styled.div`
  margin-left: 0.375rem;
`;

export const Tooltip = styled(ToolTip)`
  max-width: 22rem;
`;
