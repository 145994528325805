import ImageDropzone from '@components/dropzone/image-dropzone';
import { Body, Footer, Header, Wrapper } from '@components/side-panel-form';
import { LocalizationContext } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { Button } from '@hopin-team/ui-button';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { func } from 'prop-types';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required(),
  logo: yup
    .mixed()
    .required()
    .test(
      'requiredFile',
      'Please attach a logo',
      value => value instanceof File,
    ),
  website: yup.string().url('Must be a valid URL').required(),
});

const InputGroup = styled.div`
  margin: var(--spacing-24, 0) 0;
  --color-text-label: var(--grey-600);
`;
export default function CreateNewSponsor({ onSubmit }) {
  const { t } = useContext(LocalizationContext);
  const {
    clearErrors,
    setError,
    control,
    errors,
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onDropError = fileRejections => {
    const fileSizeErrors = fileRejections.filter(({ errors }) =>
      errors.find(error => error.code === 'file-too-large'),
    );

    if (fileSizeErrors.length > 0) {
      setError('logo', {
        message: t('sponsors.create-modal.logo.error-file-size'),
      });
    }
  };

  return (
    <Wrapper
      as="form"
      data-testid="create-sponsor-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Header>
        <Text color="grey-800" pattern="headingThree" element="h4" font="text">
          {t('sponsors.create-modal.title')}
        </Text>
      </Header>
      <Body>
        <InputGroup>
          <TextField
            errorMessage={errors.name?.message}
            hasErrors={!!errors.name}
            label={t('sponsors.create-modal.name.label')}
            name="name"
            placeholder={t('sponsors.create-modal.name.placeholder')}
            ref={register()}
            size="small"
            // TODO!: Adjust to tiny once DFP-15 is merged
          ></TextField>
        </InputGroup>
        <InputGroup>
          <TextField
            errorMessage={errors.website?.message}
            hasErrors={!!errors.website}
            label={t('sponsors.create-modal.website.label')}
            name="website"
            placeholder={t('sponsors.create-modal.website.placeholder')}
            ref={register()}
            size="small"
            // TODO!: Adjust to tiny once DFP-15 is merged
          ></TextField>
        </InputGroup>
        <InputGroup>
          <FieldLabel
            htmlFor="logo"
            label={t('sponsors.create-modal.logo.label')}
            mb={1}
          ></FieldLabel>
          <Controller
            control={control}
            id="logo"
            defaultValue=""
            name="logo"
            render={field => (
              <ImageDropzone
                hasError={!!errors.logo}
                height={'200px'}
                accept=".png,.jpeg,.jpg,.gif"
                // 1 MB
                maxFileSize={1 * 1024 * 1024}
                onDrop={file => {
                  clearErrors('logo');
                  field.onChange(file);
                }}
                onDropError={onDropError}
              />
            )}
          />
          {errors.logo?.message && (
            <FieldErrorMessage mt={1} errorMessage={errors.logo?.message} />
          )}
        </InputGroup>
      </Body>
      <Footer>
        <Button
          data-testid="submit-button"
          disabled={isSubmitting}
          isInline
          type="submit"
        >
          Create Sponsor
        </Button>
      </Footer>
    </Wrapper>
  );
}

CreateNewSponsor.propTypes = {
  onSubmit: func.isRequired,
};
