import ConfigurationField from '@features/app-store/application-configuration/configuration-field';
import BooleanInput from '@features/app-store/boolean-input';
import FullUrlInput from '@features/app-store/full-url-input';
import TextInput from '@features/app-store/text-input';
import {
  Accordion,
  AccordionContent,
  AccordionHeading,
} from '@hopin-team/ui-accordion';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { arrayOf, bool, oneOf, shape, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

const ApplicationConfiguration = ({
  isLegacy = false,
  app,
  baseId,
  baseName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentConfiguration = app.current_configuration;
  const postConfiguration = app.post_configuration.filter(config => {
    return !['tab_name', 'enabled'].includes(Object.keys(config)[0]);
  });

  const openApplication = useCallback(
    evt => {
      evt.preventDefault();
      setIsOpen(!isOpen);
    },
    [setIsOpen, isOpen],
  );

  return (
    <Accordion isExpanded={isOpen}>
      <Text pattern="emphasis" color="grey-dark">
        <AccordionHeading
          onClick={openApplication}
          trailingIcon={isOpen ? 'arrow-up' : 'arrow-down'}
        >
          {app.name}
        </AccordionHeading>
      </Text>

      <AccordionContent px={2} pb={2}>
        <Text mt="2" pattern="captionBold">
          Configuration
        </Text>
        <Flex flexDirection="row" flexWrap="wrap">
          {!isLegacy && (
            <div className="pl-0 col-md-4">
              <TextInput
                label="Tab name"
                id={`${baseId}tab_name`}
                name={`${baseName}[tab_name]`}
                initialValue={currentConfiguration.tab_name}
              />
            </div>
          )}
          {postConfiguration.map(field => {
            const entries = Object.entries(field);

            if (entries.length === 0) {
              console.error('Malformed post-configuration: ', field);
              return null;
            }
            const [name, metadata = {}] = entries[0];

            return (
              <ConfigurationField
                key={`${baseId}${name}`}
                baseId={baseId}
                baseName={baseName}
                name={name}
                metadata={metadata}
                field={field}
                initialValue={currentConfiguration[name]}
              />
            );
          })}

          {!isLegacy && (
            <div className="pl-0 col-md-4">
              <BooleanInput
                key={`${baseId}enabled`}
                name={`${baseName}[enabled]`}
                label="Enabled"
                id={`${baseId}$enabled`}
                initialValue={currentConfiguration.enabled}
              />
            </div>
          )}

          {postConfiguration.map(field => {
            const entries = Object.entries(field);

            if (entries.length === 0) {
              console.error('Malformed post-configuration: ', field);
              return null;
            }
            const [name, metadata = {}] = entries[0];

            if (metadata?.appearance?.type === 'url') {
              return (
                <div key={`${baseId}${name}`} className="pl-0 col-md-12">
                  <FullUrlInput
                    initialValue={currentConfiguration[name]}
                    label={metadata.label}
                    usageGuide={app.usage_guide}
                    groupName={name}
                    appName={app.name}
                    urlRegex={metadata.appearance.regex}
                    name={`${baseName}[${name}]`}
                    id={`${baseId}${name}`}
                    errorMessage={metadata.appearance.error_message}
                  />
                </div>
              );
            }
          })}
        </Flex>
      </AccordionContent>
    </Accordion>
  );
};

ApplicationConfiguration.propTypes = {
  baseId: string.isRequired,
  baseName: string.isRequired,
  isLegacy: bool,
  app: shape({
    name: string.isRequired,
    usage_guide: string,
    post_configuration: arrayOf(
      shape({
        name: shape({
          type: oneOf(['string', 'boolean']).isRequired,
          label: string.isRequired,
          appearance: shape({
            type: oneOf(['url']),
          }),
        }),
      }).isRequired,
    ),
    current_configuration: shape({
      enabled: oneOf(['0', '1']).isRequired,
      tab_name: string,
    }),
  }).isRequired,
};

export default ApplicationConfiguration;
