import { timezonesMap } from '@features/constants/timezones-map';
import {
  setEventLocationAddress,
  setEventLocationName,
  setEventLocationType,
} from '@features/event-creation-wizard/redux/event-location/event-location-slice';
import { setEventType } from '@features/event-creation-wizard/redux/event-type/event-type-slice';
import { setBasicDetails } from '@features/event-creation-wizard/redux/step-specific-state/basic-details-slice';
import { getEventValidations } from '@features/event-creation-wizard/steps/basic-details/hooks/validations/event-setup-form-validations';
import { useLocalization } from '@features/localization';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import { getSelectedTimezone } from '../../../utils/time-zone-selectors';

const useEventSetupForm = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization(
    'event-creation-wizard.steps.basic-details.form',
  );
  const {
    name,
    startDate,
    endDate,
    maxEventLengthHours,
    eventPassword,
  } = useSelector(state => state.basicDetails);
  const eventType = useSelector(state => state.eventType);
  const {
    type: eventLocationType,
    name: eventLocationName,
    address: eventLocationAddress,
  } = useSelector(state => state.eventLocation);

  const defaultValues = {
    name,
    timezone: getSelectedTimezone().value,
    startDate,
    endDate,
    eventType,
    eventLocationType,
    eventLocationName,
    eventLocationAddress:
      typeof eventLocationAddress === 'string'
        ? eventLocationAddress
        : JSON.stringify(eventLocationAddress),
    eventPassword,
  };

  const {
    errors,
    register,
    setValue,
    formState,
    handleSubmit,
    ...rest
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: getEventValidations(defaultValues, maxEventLengthHours, t),
  });

  const onSubmit = async ({
    name,
    imageUrl,
    startDate,
    endDate,
    eventType,
    eventLocationType,
    eventLocationName,
    eventLocationAddress,
    eventPassword,
    timezone,
  }) => {
    const ianaTz = timezonesMap[timezone];
    const basicDetails = {
      name,
      imageUrl: imageUrl ?? '',
      timezone,
      startDate: zonedTimeToUtc(startDate, ianaTz),
      endDate: zonedTimeToUtc(endDate, ianaTz),
      eventPassword,
      maxEventLengthHours,
    };

    batch(() => {
      dispatch(setBasicDetails(basicDetails));
      dispatch(setEventType(eventType));
      eventLocationType && dispatch(setEventLocationType(eventLocationType));
      dispatch(setEventLocationName(eventLocationName));
      dispatch(
        setEventLocationAddress(
          typeof eventLocationAddress === 'string'
            ? JSON.parse(eventLocationAddress)
            : eventLocationAddress,
        ),
      );
    });
  };

  return {
    errors,
    register,
    setValue,
    formState,
    onClickNext: handleSubmit(onSubmit),
    ...rest,
  };
};

export default useEventSetupForm;
