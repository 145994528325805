import hopinApi from '@api/hopin';
import EventName from '@components/event-name';
import EventStatus from '@components/event-status';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { eventStatuses } from '@features/events/constants';
import { getPusherChannel, pusherEvents } from '@features/templates/constants';
import { getNewEventSetupAlert } from '@features/templates/util/get-new-event-setup-alert';
import { getBaseUrl } from '@hopin-team/dashboard-constants';
import { Icon } from '@hopin-team/ui-icon';
import * as Routes from '@routes';
import { ORGANIZATION_DASHBOARD_EVENTS } from '@util/analytics/organization-dashboard-events';
import { bindToPusher } from '@util/pusher';
import { bool, func, number, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { HideOnHover, HoverContainer, ShowOnHover } from './hover-manager';
import {
  EventRowActionButton,
  EventRowActionLink,
  EventRowActions,
  EventTableRow,
  Link,
  TableBodyCell,
  TableBodyCellWithMinWidth,
} from './styles';

const EventRow = ({
  t,
  name,
  picture,
  eventSlug,
  dateRange,
  registrationsCount,
  initialStatus,
  isFreePlan,
  freeRegistrationsLimit,
  trackAmplitudeEvent,
  eventExternalId,
  onEventNotDuplicatable,
}) => {
  const [status, setStatus] = useState(initialStatus);
  const { addAlert } = useContext(AlertsContext);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Event duplication.

  const handleEventDuplicate = async ({ eventExternalId, eventSlug }) => {
    trackAmplitudeEvent(ORGANIZATION_DASHBOARD_EVENTS.DUPLICATE_EVENT_CLICKED);

    const { data } = await hopinApi.checkEventDuplicatable(eventExternalId);
    const baseUrl = getBaseUrl();

    const eventCanBeDuplicated = data?.event?.canBeDuplicated;

    if (eventCanBeDuplicated) {
      window.location.assign(
        `${baseUrl}/organisers/events/${eventSlug}/duplications`,
      );
    } else onEventNotDuplicatable();
  };

  useEffect(() => {
    let unbind;

    async function bindToPusherAndAssignUnbind() {
      unbind = await bindToPusher({
        channelName: getPusherChannel(eventExternalId),
        eventName: pusherEvents.EVENT_DUPLICATION_FINISHED,
        handler: payload => {
          unbind();
          const alert = getNewEventSetupAlert(payload, name, t);
          setStatus(eventStatuses.DRAFT);
          addAlert(alert);
        },
      });
    }

    if (eventExternalId && status === eventStatuses.BUILDING) {
      bindToPusherAndAssignUnbind(eventExternalId);

      return () => {
        try {
          unbind();
        } catch (err) {
          console.error('Error unbinding pusher event', err);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventExternalId, status]);

  const translationKeyPrefix =
    'onboarding.organization-dashboard.event-row-actions';

  const disabled = status === eventStatuses.BUILDING;

  return (
    <HoverContainer
      data-testid="event-row"
      as={EventTableRow}
      $disabled={disabled}
    >
      <TableBodyCell $disabled={disabled}>
        <Link
          data-testid="event-dashboard-link"
          href={Routes.organisersEventOverviewPath(eventSlug)}
          onClick={() =>
            trackAmplitudeEvent(ORGANIZATION_DASHBOARD_EVENTS.EVENT_CLICKED)
          }
        >
          <EventName name={name} imageUrl={picture} />
        </Link>
      </TableBodyCell>
      <TableBodyCellWithMinWidth minWidth={'11rem'} $disabled={disabled}>
        {dateRange}
      </TableBodyCellWithMinWidth>
      <TableBodyCell $disabled={disabled}>
        {isFreePlan
          ? `${registrationsCount} / ${freeRegistrationsLimit}`
          : `${registrationsCount}`}
      </TableBodyCell>
      <TableBodyCellWithMinWidth minWidth={'11rem'}>
        <ShowOnHover>
          <EventRowActions>
            <EventRowActionLink
              m={8}
              data-testid="event-row-edit-link"
              onClick={() =>
                trackAmplitudeEvent(
                  ORGANIZATION_DASHBOARD_EVENTS.EDIT_EVENT_CLICKED,
                )
              }
              href={Routes.organisersEventOverviewPath(eventSlug)}
              className="link"
            >
              <Icon name="edit" title={t(`${translationKeyPrefix}.edit`)} />
            </EventRowActionLink>

            <EventRowActionButton
              data-testid="event-row-copy-link"
              onClick={e => {
                e.stopPropagation();
                handleEventDuplicate({ eventExternalId, eventSlug });
              }}
              className="link"
            >
              <Icon name="copy" title={t(`${translationKeyPrefix}.copy`)} />
            </EventRowActionButton>

            <EventRowActionLink className="link">
              {/* TODO: (money-pod) to be defined later */}
              {/* <Icon name="share" title={t(`${translationKeyPrefix}.share`)} /> */}
            </EventRowActionLink>
          </EventRowActions>
        </ShowOnHover>
        <HideOnHover>
          <EventStatus
            status={status}
            label={t(`common.event-statuses.${status}`)}
          />
        </HideOnHover>
      </TableBodyCellWithMinWidth>
    </HoverContainer>
  );
};

EventRow.propTypes = {
  t: func.isRequired,
  currentColor: string,
  eventId: number.isRequired,
  name: string.isRequired,
  picture: string.isRequired,
  eventSlug: string.isRequired,
  dateRange: string.isRequired,
  registrationsCount: number.isRequired,
  initialStatus: string.isRequired,
  isFreePlan: bool.isRequired,
  freeRegistrationsLimit: number.isRequired,
  trackAmplitudeEvent: func,
  eventExternalId: string.isRequired,
  onEventNotDuplicatable: func.isRequired,
};

export default EventRow;
