import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

const ChecklistItem = styled(Flex).attrs(({ href }) =>
  href ? { forwardedAs: 'a' } : {},
)`
  color: initial;

  &:hover {
    color: initial;
  }
`;

export default ChecklistItem;
