import { Button } from '@hopin-team/ui-button';
import { calcSpace, COLOR, LINE } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

export const Styles = {};

Styles.EventDetails = styled.div`
  padding-left: ${calcSpace(3)};
  padding-right: ${calcSpace(3)};
  margin-top: ${calcSpace(2)};
`;

Styles.EventImage = styled.img`
  background-color: #ffffff;
  width: 3.5rem;
  height: 2rem;
  border-radius: ${LINE.RADIUS_DEFAULT};
  object-fit: cover;
`;

Styles.DotsWrapper = styled.div`
  svg {
    display: block;
  }

  color: ${COLOR.GREY['800']};

  &:hover {
    color: ${COLOR.GREY['900']};
  }
`;

Styles.PublishRow = styled.div`
  padding-left: ${calcSpace(3)};
  padding-right: ${calcSpace(2)};
  margin-top: ${calcSpace(2)};
  margin-bottom: ${calcSpace(2)};
  display: flex;
  flex-wrap: wrap;
  gap: ${calcSpace(1.5)};
`;

Styles.PublishButton = styled(Button)`
  height: auto;
  min-height: 2rem;
  white-space: normal;

  &:focus:not([data-focus-visible-added]) {
    outline: 0;
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: ${calcSpace(1)};
  }
  ${props => {
    if (props.$canPublishEvent === false) {
      return `
          background-color : #FF7A00;
          color: #fff;
          border-color: transparent;

          &:hover {
            background-color: #DA5001;
          }
      `;
    }
  }}
`;

Styles.DuplicateButton = styled(Button)`
  height: auto;
  min-height: 2rem;
  white-space: normal;

  &:focus:not([data-focus-visible-added]) {
    outline: 0;
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: ${calcSpace(1)};
  }
`;
