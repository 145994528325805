import styled, { css } from 'styled-components';

const getActiveStyles = props => {
  if (!props.isActive) {
    return;
  }

  return css`
    background-color: var(--color-gray-100);
    border-left-color: var(--color-primary-400);

    ${Title} {
      font-weight: var(--font-weight-medium);
    }

    &:before {
      background-color: var(--color-white);
      border-bottom: 0.0625rem solid var(--color-gray-200);
      border-left: 0.0625rem solid var(--color-gray-200);
      content: '';
      height: 0.625rem;
      position: absolute;
      right: -0.3125rem;
      transform: rotate(45deg);
      width: 0.625rem;
      z-index: 1;
    }
  `;
};

export const StyledLink = styled.a`
  align-items: center;
  border-bottom-color: var(--color-gray-200);
  border-bottom: 0.0625rem solid var(--color-white);
  border-left: 0.125rem solid transparent;
  display: flex;
  padding: var(--spacing-12) 1.375rem;
  position: relative;
  transition: background-color var(--transition-duration);

  &:hover {
    background-color: var(--color-gray-100);
  }

  &.focus-visible {
    box-shadow: inset 0 0 0 0.125rem var(--color-primary-400);
    outline: none;
  }

  ${getActiveStyles}
`;

export const Content = styled.div`
  margin-left: var(--spacing-12);
  min-width: 0;
`;

export const Img = styled.img`
  border-radius: 50%;
  height: 2.5rem;
  object-fit: cover;
  width: 2.5rem;
`;

export const Title = styled.span`
  color: var(--color-text);
  display: block;
  font-size: var(--font-size-big);
  line-height: var(--line-height-tight);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Subtitle = styled.span`
  color: var(--color-text-light);
  display: block;
  font-size: var(--font-size-small);
  overflow: hidden;
  text-overflow: ellipsis;
`;
