import SearchInput from '@components/search-input';
import { string } from 'prop-types';
import React, { useState } from 'react';

export default function Static({ name, placeholder, ariaLabel, testId }) {
  const urlParams = new URLSearchParams(window.location.search);
  const searchText = urlParams.get(name);
  const [value, setValue] = useState(searchText || '');

  return (
    <SearchInput
      inputValue={value}
      onChange={e => setValue(e.target.value)}
      name={name}
      placeholder={placeholder}
      ariaLabel={ariaLabel}
      testId={testId}
      clearable={true}
    />
  );
}

Static.propTypes = {
  name: string.isRequired,
  placeholder: string.isRequired,
  ariaLabel: string.isRequired,
  testId: string,
};

Static.defaultProps = {
  name: 'search_text',
  placeholder: 'Search',
};
