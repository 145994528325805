import { useLocalization } from '@features/localization';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  padding: ${rem(24)} ${rem(16)};
`;

const Heading = styled.h3`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: 1;
  color: var(--color-gray-800);
  text-align: center;
  margin-top: ${rem(24)};
  margin-bottom: 0;
`;

const Body = styled.p`
  font-family: var(--font-family);
  font-size: ${rem(14)};
  line-height: 1.71;
  color: var(--color-gray-700);
  margin-top: ${rem(16)};
  text-align: center;
`;

const ErrorModal = () => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');
  return (
    <Section>
      <Heading>{t('error')}</Heading>
      <Body>{t('uploaded-invalid-file')}</Body>
    </Section>
  );
};

export default ErrorModal;
