import { createContext, useContext } from 'react';

const ModalContext = createContext({});
// cf. https://reactjs.org/docs/context.html#contextdisplayname
ModalContext.displayName = 'Modal';

/**
 *
 * Use this hook to open a modal.
 * `<ModalProvider />` must be above your component in the tree
 * @returns Object with `setModalState` function
 * @example
 * const { setIsModalOpen, setContent } = useModal()
 * const onClickHandler = () => {
 *    setContent(<Form />);
 *    setIsModalOpen(true);
 * }
 * return <button onClick={onClickHandler}>Open the modal</button>
 */
const useModal = () => useContext(ModalContext);

export { ModalContext, useModal };
