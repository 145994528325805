import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { number, object, setLocale, string } from 'yup';

import { createSingleInvite } from '../services';

const useSingleInviteForm = (eventSlug, onSuccess, onError) => {
  const { t } = useLocalization('magic-links.tabs.single-invite');
  setLocale({
    mixed: {
      required: t('cannot-be-blank'),
    },
  });
  const { handleSubmit, reset, ...rest } = useForm({
    resolver: yupResolver(
      object().shape({
        email: string().email().required(),
        first_name: string().trim().required(),
        last_name: string().trim().required(),
        headline: string().max(100),
        persona_id: number()
          .transform(val => (isNaN(val) ? undefined : val))
          .required(t('ticket-type-required')),
      }),
    ),
    reValidateMode: 'onChange',
  });

  const handleHookFormSubmit = async values => {
    try {
      const { errors, redeemCode, success } = await createSingleInvite(
        eventSlug,
        {
          redeem_code: values,
        },
      );
      if (success) {
        onSuccess(redeemCode);
        reset();
      } else {
        onError(errors);
      }
    } catch (err) {
      onError([t('something-went-wrong')]);
    }
  };

  return {
    onSubmit: handleSubmit(handleHookFormSubmit),
    ...rest,
  };
};

export default useSingleInviteForm;
