import camelToSnakeCase from '@util/camelcase-to-snakecase';

const snakecaseKeys = obj => {
  if (!(obj instanceof Object)) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(item => snakecaseKeys(item));
  }
  const o = {};
  Object.keys(obj).forEach(key => {
    const snakecased = camelToSnakeCase(key);
    o[snakecased] = snakecaseKeys(obj[key]);
  });
  return o;
};

export default snakecaseKeys;
