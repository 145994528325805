import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { stepMap } from '../constants/step-map';
import { templates } from '../constants/templates';

export const getTemplateSteps = templateId =>
  (templates[templateId] && templates[templateId].steps) || [];

export const useStepsInfo = () => {
  const templateId = useSelector(state => state.template.id);
  const { type: eventFormat } = useSelector(state => state.eventLocation);
  const { conferenceFullFlowEnabled } = useSelector(state => state.flags);
  const steps = getTemplateSteps(templateId, conferenceFullFlowEnabled);

  const venue = useSelector(state => state.venue);
  const filteredSteps = steps.filter(step => {
    if (step.name === 'expo') {
      return venue.expo;
    }

    if (step.name === 'sessions') {
      return venue.sessions;
    }

    return true;
  });

  const location = useLocation();
  const currentStepName = Object.keys(stepMap).find(
    stepId =>
      stepMap[stepId].path(templateId, eventFormat) === location.pathname,
  );

  const currentStepIndex = filteredSteps.findIndex(
    step => step.name === currentStepName,
  );
  const isLastStep = currentStepIndex + 1 === filteredSteps.length;
  const nextStepIndex = isLastStep ? null : currentStepIndex + 1;
  const nextStep = isLastStep ? null : filteredSteps[nextStepIndex];

  const isFirstStep = currentStepIndex === 0;
  const previousStepIndex = isFirstStep ? null : currentStepIndex - 1;
  const previousStep = isFirstStep ? null : filteredSteps[previousStepIndex];

  const stepsInfo = {
    steps: filteredSteps,
    currentStep: currentStepName,
    currentStepIndex,
    previousStep,
    previousStepIndex,
    nextStep,
    nextStepIndex,
  };

  return stepsInfo;
};
