import { Input } from '@components/inputs';
import styled from 'styled-components';

const WhiteInput = styled(Input)`
  background: var(--color-white);
  width: 100%;
`;

const InputContainer = styled.div`
  width: 14.5rem;
`;

const ErrorMessage = styled.span`
  color: var(--color-red-400);
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: var(--spacing-8);
  font-weight: var(--font-weight-medium);
`;

export { ErrorMessage, InputContainer, WhiteInput };
