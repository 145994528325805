import hopinApi from '@api/hopin';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Grid } from '@hopin-team/ui-grid';
import { Text } from '@hopin-team/ui-text';
import { EVENT_DASHBOARD_EVENTS } from '@util/analytics/event-dashboard-events';
import segmentTrack from '@util/analytics/segment-client';
import { chain, has, isEmpty } from 'lodash';
import * as P from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { EVENT_ACTION_CARD_MAP, INITIAL_CARDS_TO_SHOW } from './constants';
import EventActionCard from './event-action-card';

const StyledButton = styled(Button)`
  height: auto;
`;

export const getMappedEventActionCards = eventActionCards => {
  const mapCardsDetails = card => ({
    ...card,
    ...EVENT_ACTION_CARD_MAP[card.card_type],
  });

  const sortedCards = chain(eventActionCards)
    .filter(card => has(EVENT_ACTION_CARD_MAP, card['card_type']))
    .uniqBy('card_type')
    .orderBy(
      [
        card => card.completed,
        card => card.required,
        card => Object.keys(EVENT_ACTION_CARD_MAP).indexOf(card['card_type']),
      ],
      ['asc', 'desc', 'asc'],
    )
    .map(mapCardsDetails)
    .value();

  return sortedCards;
};

const EventActionCards = ({
  user,
  eventActionCards,
  event,
  stripeUrl,
  newRegistrationsUrl,
  newRegistrationsEnabled,
}) => {
  const { t } = useLocalization();
  const userToken = useAsync(hopinApi.getUserToken);
  const [showAll, setShowAll] = useState(false);

  const mappedEventActionCards = useMemo(() => {
    const cards = getMappedEventActionCards(eventActionCards).filter(
      card => !card.shouldHide?.({ newRegistrationsEnabled }),
    );

    return cards;
  }, [eventActionCards, newRegistrationsEnabled]);

  const initialCardsToShow = useMemo(
    () => mappedEventActionCards.slice(0, INITIAL_CARDS_TO_SHOW),
    [mappedEventActionCards],
  );

  const toggleActionCards = () => {
    setShowAll(prevShowAll => !prevShowAll);
  };

  if (isEmpty(mappedEventActionCards)) {
    return null;
  }

  const showToggle = mappedEventActionCards.length > INITIAL_CARDS_TO_SHOW;
  const actionCards = showAll ? mappedEventActionCards : initialCardsToShow;

  return (
    <Box data-testid="event-action-cards">
      <Flex alignItems="flex-end" justifyContent="space-between" mb={3}>
        <Box>
          <Text element="p" pattern="bodyLargeBold">
            {t('overview.event-actions.heading-1', { name: user.first_name })}
          </Text>
          <Text element="p" pattern="body">
            {t('overview.event-actions.heading-2')}
          </Text>
        </Box>

        {showToggle && (
          <StyledButton
            onClick={toggleActionCards}
            aria-controls="action-cards"
            aria-expanded={showAll ? 'true' : 'false'}
            aria-label={t('overview.event-actions.toggle-label')}
            isGhost
            size="small"
            isInline
          >
            {showAll
              ? t('overview.event-actions.show-less')
              : t('overview.event-actions.show-more')}
          </StyledButton>
        )}
      </Flex>

      <Grid
        id="action-cards"
        gridTemplateColumns="repeat(3, 1fr)"
        gridGap="1.5rem"
      >
        {actionCards.map(eventActionCard => (
          <EventActionCard
            key={eventActionCard.card_type}
            title={t(eventActionCard.title)}
            description={t(eventActionCard.description)}
            ctaLabel={t(eventActionCard.ctaLabel)}
            completedCtaLabel={t(eventActionCard.completedCtaLabel)}
            icon={eventActionCard.icon}
            required={eventActionCard.required}
            completed={eventActionCard.completed}
            onClick={() => {
              segmentTrack(EVENT_DASHBOARD_EVENTS.TASK_CARD_CLICKED, {
                event_id: event.external_id,
                organiser_id: event.organization.external_id,
                user_id: user.external_id,
                task_card_id: eventActionCard.card_type,
              });

              const context = {
                event,
                stripeUrl,
                newRegistrationsUrl,
                newRegistrationsEnabled,
                token: userToken.value?.token,
              };

              return eventActionCard.onClick(context);
            }}
          />
        ))}
      </Grid>
    </Box>
  );
};

EventActionCards.propTypes = {
  user: P.shape({
    first_name: P.string.isRequired,
    external_id: P.string.isRequired,
  }).isRequired,
  eventActionCards: P.arrayOf(
    P.shape({
      card_type: P.string.isRequired,
      required: P.bool.isRequired,
      completed: P.bool.isRequired,
    }),
  ).isRequired,
  event: P.shape({
    external_id: P.string.isRequired,
    finished: P.bool.isRequired,
    slug: P.string.isRequired,
    organization: P.shape({
      external_id: P.string.isRequired,
    }).isRequired,
  }).isRequired,
  stripeUrl: P.string.isRequired,
  newRegistrationsUrl: P.string.isRequired,
  newRegistrationsEnabled: P.bool.isRequired,
};

export default EventActionCards;
