import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { Text } from '@hopin-team/ui-text';
import { kebabCase } from 'lodash';
import { bool, func, number, string } from 'prop-types';
import React, { forwardRef } from 'react';

import ChecklistNumber from '../styled/checklist-number';
import StyledChecklistTab from '../styled/checklist-tab';

const ChecklistTab = forwardRef(
  ({ isActive, isChecked, number, onClick, text }, ref) => {
    const { t } = useLocalization('overview.event-setup-checklist');

    return (
      <StyledChecklistTab
        aria-controls={`${kebabCase(text)}-tab`}
        aria-label={`${
          isChecked ? t('completed-step') : t('incomplete-step')
        }: ${text}`}
        aria-selected={isActive}
        data-testid="event-progress-tab"
        isActive={isActive}
        onClick={onClick}
        ref={ref}
        role="tab"
        tabIndex={isActive ? '0' : '-1'}
      >
        <Flex alignItems="center">
          <ChecklistNumber
            data-testid="event-progress-tab-number"
            isActive={isActive}
            isChecked={isChecked}
          >
            <Halon
              color={
                isChecked ? 'green-100' : isActive ? 'blue-500' : 'blue-100'
              }
              iconScale={1.5}
              scale={2.5}
              {...(isChecked && { icon: 'checkmark', iconColor: 'green-400' })}
            />
            {!isChecked && <Text pattern="body">{number}</Text>}
          </ChecklistNumber>
          <Text ml={1} pattern="labelOne" weight="regular" color="grey-800">
            {text}
          </Text>
        </Flex>
      </StyledChecklistTab>
    );
  },
);

ChecklistTab.propTypes = {
  isActive: bool,
  isChecked: bool,
  number: number.isRequired,
  onClick: func,
  text: string.isRequired,
};

export default ChecklistTab;
