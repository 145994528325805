import { Tab, TabList, TabPanel, TabPanels } from '@hopin-team/ui-tabs';
import { Text } from '@hopin-team/ui-text';
import { getBrowserTimezone } from '@util/date-helpers';
import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { array, string } from 'prop-types';
import { groupBy } from 'ramda';
import React from 'react';

import ScheduleList from './schedule-list-component';
import { Styles } from './schedule-styles';

export const formatLocalDatetimeString = (
  utcDatetimeString,
  pattern = 'MMM d, h:mma z',
) => {
  return format(
    utcToZonedTime(toDate(utcDatetimeString), getBrowserTimezone()),
    pattern,
    {
      timeZone: getBrowserTimezone(),
    },
  );
};

const Schedule = ({ schedule, eventRoute }) => {
  const timetable = groupBy(item =>
    formatLocalDatetimeString(item.time_start, 'MMM d, yyyy'),
  )(schedule);

  return (
    <div>
      <Styles.ScheduleTabs pattern="schedule">
        <TabList style={{ width: 'auto' }}>
          {Object.keys(timetable).map((date, idx) => (
            <Tab key={idx}>
              <Text pattern="labelTwo" element="p">
                Day {idx + 1}
              </Text>
              <Text pattern="labelOne" element="p">
                {date}
              </Text>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {Object.entries(timetable).map(([key, data]) => (
            <TabPanel key={key}>
              <ScheduleList list={data} eventRoute={eventRoute} />
            </TabPanel>
          ))}
        </TabPanels>
      </Styles.ScheduleTabs>
    </div>
  );
};

Schedule.propTypes = {
  schedule: array,
  eventRoute: string,
};

export default Schedule;
