import 'react-tippy/dist/tippy.css';

import { LocalizationContext } from '@features/localization';
import {
  Accordion,
  AccordionContent,
  AccordionHeading,
} from '@hopin-team/ui-accordion';
import { Box } from '@hopin-team/ui-box';
import ArrowDown from 'icons/chevron-down.svg';
import ArrowUp from 'icons/chevron-up.svg';
import { string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { Tooltip } from 'react-tippy';

import { AnnouncementsText } from '../styles';
import {
  LearnMoreLink,
  TagsPanel,
  TagsPanelDescription,
  TagsPanelGroupHeader,
  TagsPanelHeader,
  TagWrapper,
} from './styles';

const copyToClipboard = (text, onSuccess) => {
  navigator.clipboard.writeText(text).then(() => {
    onSuccess();
  });
};

const PersonalisationTag = ({ name, description, ...rest }) => {
  const { t } = useContext(LocalizationContext);
  const formattedTag = `{{${name}}}`;
  const [copied, setIsCopied] = useState(false);

  const isCopyToClipboardSupported = !!navigator.clipboard;

  const copyTag = tag => {
    copyToClipboard(tag, () => {
      setIsCopied(true);
      setInterval(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  return (
    <TagWrapper mt={1} {...rest}>
      <Tooltip
        title={copied ? `${t('copied')}!` : t('copy')}
        disabled={!isCopyToClipboardSupported}
        size="small"
        hideOnClick="false"
        transitionFlip="false"
        sticky="true"
        theme="transparent"
        hideDelay="500"
        distance="3"
        multiple="true"
        popperOptions={{
          placement: 'top-start',
        }}
      >
        <Box
          aria-describedby={`tooltip-${name}`}
          as="span"
          className="padded-tag"
          onClick={() => copyTag(formattedTag)}
        >
          {formattedTag}
        </Box>
      </Tooltip>

      <span className="padded-tag">{description}</span>
    </TagWrapper>
  );
};

PersonalisationTag.propTypes = {
  name: string.isRequired,
  description: string.isRequired,
};

const tags = {
  basic: [
    'user.name',
    'user.first_name',
    'user.last_name',
    'event.name',
    'event.link',
    'event.calendar_link',
  ],
  registration: [
    'ticket.label',
    'ticket.price',
    'ticket.amount',
    'event.currency',
    'registration.price',
    'registration.refunded',
    'registration.amount_paid',
  ],
  media: [
    'event.original_picture',
    'event.medium_picture',
    'organiser.medium_picture',
  ],
};

const PersonalisationTagsPanel = () => {
  const { t } = useContext(LocalizationContext);
  const translationKeyPrefix = 'email-dashboard';
  const [allTagsOpen, setAllTagsOpen] = useState(false);

  return (
    <TagsPanel data-testid="personalisation-tags-panel">
      <TagsPanelHeader mb={2} element="p" pattern="body">
        {t(`${translationKeyPrefix}.edit.personalisation_tags.title`)}
      </TagsPanelHeader>
      <TagsPanelDescription as="p" pattern="p">
        {t(`${translationKeyPrefix}.edit.personalisation_tags.description`)}
      </TagsPanelDescription>
      <TagsPanelDescription as="p" pattern="p" pb={2}>
        {t(`${translationKeyPrefix}.edit.personalisation_tags.for_more`)}
        &nbsp;
        <LearnMoreLink href="https://events-support.ringcentral.com/hc/en-gb/articles/360057952492-Using-Liquid-Templates">
          {t(`${translationKeyPrefix}.edit.personalisation_tags.help_center`)}
        </LearnMoreLink>
        .
      </TagsPanelDescription>
      <TagsPanelGroupHeader>
        {t(`${translationKeyPrefix}.edit.personalisation_tags.basic`)}
      </TagsPanelGroupHeader>
      {tags.basic.map(tag => (
        <PersonalisationTag
          key={tag}
          name={tag}
          description={t(`${translationKeyPrefix}.edit.tags.${tag}`)}
          data-testid="basic-tag"
          mb={1.5}
        />
      ))}
      <Accordion isExpanded={allTagsOpen}>
        <AccordionHeading mb={3} onClick={() => setAllTagsOpen(!allTagsOpen)}>
          <AnnouncementsText pr="0.5">
            {t(`${translationKeyPrefix}.edit.personalisation_tags.show_all`)}
          </AnnouncementsText>
          {allTagsOpen ? <ArrowUp /> : <ArrowDown />}
        </AccordionHeading>
        <AccordionContent>
          <TagsPanelGroupHeader>
            {t(
              `${translationKeyPrefix}.edit.personalisation_tags.registration`,
            )}
          </TagsPanelGroupHeader>
          {tags.registration.map(tag => (
            <PersonalisationTag
              key={tag}
              name={tag}
              description={t(`${translationKeyPrefix}.edit.tags.${tag}`)}
              data-testid="registration-tag"
              mb={1.5}
            />
          ))}
          <TagsPanelGroupHeader>
            {t(`${translationKeyPrefix}.edit.personalisation_tags.media`)}
          </TagsPanelGroupHeader>
          {tags.media.map(tag => (
            <PersonalisationTag
              key={tag}
              name={tag}
              description={t(`${translationKeyPrefix}.edit.tags.${tag}`)}
              data-testid="media-tag"
              mb={1.5}
            />
          ))}
        </AccordionContent>
      </Accordion>
    </TagsPanel>
  );
};

PersonalisationTagsPanel.propTypes = {};

export default PersonalisationTagsPanel;
