import camelizeKeys from '@util/camelize-keys';
import { addDays } from 'date-fns';

export const RECORDING_DETAIL_TEST_ID = {
  // Detail page elements
  DESCRIPTION_TEXT: 'description-text',
  LAST_TRIMMED: 'last-trimmed',
  PUBLISH_CONTROLS: 'publish-controls',
  RECORDING_CONTROLS: 'recording-controls',
  RECORDING_BUTTONS: 'recording-buttons',
  RELATED_RECORDINGS: 'related-recordings',
  STATUS_MSG: 'status-message',
  SUBTITLE: 'recording-subtitle',

  // Detail page action elements
  BACKUP_LINK: 'backup-link',
  DELETE_BTN: 'delete-button',
  DESCRIPTION_BTN: 'description-icon-btn',
  DESCRIPTION_LINK: 'description-link-btn',
  DOWNLOAD_BTN: 'download-icon-btn',
  OPTIONS_BTN: 'options-icon-btn',
  EDIT_BTN: 'edit-icon-btn',
  CREATE_CUTS_BTN: 'create-cuts-icon-btn',
  CHANGE_THUMBNAIL_BTN: 'change-thumbnail-icon-btn',
  VIEW_TRANSCRIPT_BTN: 'view-transcript-icon-btn',
};

export const featureFlags = {
  customThumbnailsEnabled: false,
};

export const daysFromNow = days => addDays(new Date(), days).toISOString();

export const SLUG = 'slug';

export const event = {
  slug: SLUG,
  status: 'published',
  timeStart: '2021-03-07T19:09:58.000Z',
  timeEnd: '2029-03-07T19:09:58.000Z',
  areas: { stage: true, sessions: true },
  eventId: 'eventId',
};

export const TITLE = 'test title 222ee';

export const GENERATED_TITLE = 'January 27, 2022 at 5:36 PM';

export const recordings = [
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1MQ==',
    title: 'recording 1 title',
    playableUrl:
      'https://stream.mux.com/3QWt6N01L4TYGp6GTBAZSnlnt84cySHUv/high.mp4',
    downloadUrl:
      'https://this-is-a-download-url-TGl2ZXN0cmVhbVJlY29yZGluZzo1MQ==',
    eventPartId: 'gpwernasdfV1eELORKjLfWYx',
    eventPartType: 'stage',
    startedAt: '2021-03-07T19:00:15.000Z',
    endedAt: '2021-03-07T19:07:26.576Z',
    duration: 431,
    published: true,
    description: '',
    category: 'main',
    status: 'processing',
    statusChangedAt: null,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1Mg==',
    playableUrl:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    downloadUrl:
      'https://this-is-a-download-url-TGl2ZXN0cmVhbVJlY29yZGluZzo1Mg==',
    eventPartId: 'sdfgnliaweORKjLfWYx',
    eventPartType: 'stage',
    title: 'recording 2 title',
    startedAt: '2021-03-07T19:09:58.000Z',
    endedAt: '2021-03-07T19:18:01.259Z',
    duration: 483,
    published: true,
    description: '',
    category: 'main',
    status: 'processing',
    statusChangedAt: '2021-03-08T19:18:01.259Z',
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY33yZGluZzo1Mg==',
    playableUrl:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    downloadUrl:
      'https://this-is-a-download-url-TGl2ZXN0cmVhbVJlY33yZGluZzo1Mg==',
    eventPartId: 'laksdfANlkadbfAWE',
    eventPartType: 'Session',
    title: 'Session Title',
    startedAt: '2021-03-04T18:09:58.000Z',
    endedAt: '2021-03-04T18:18:01.259Z',
    duration: 483,
    published: true,
    description: '',
    category: 'main',
    status: 'processing',
    statusChangedAt: null,
  },
];

export const rawRelatedRecordings = [
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1Mg==',
    playable_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    download_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    event_part_name: 'Main Stage',
    evnet_part_type: 'stage',
    started_at: '2021-03-07T19:09:58.000Z',
    ended_at: '2021-03-07T19:18:01.259Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M2==',
    playable_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    download_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    event_part_name: 'Main Stage',
    event_part_type: 'stage',
    started_at: '2021-03-07T19:09:58.000Z',
    ended_at: '2021-03-07T19:18:01.259Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M3==',
    playable_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    download_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    event_part_name: 'Main Stage',
    event_part_type: 'stage',
    started_at: '2021-03-07T19:09:58.000Z',
    ended_at: '2021-03-07T19:18:01.259Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M4==',
    playable_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    download_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    event_part_name: 'Main Stage',
    event_part_type: 'stage',
    started_at: '2021-03-07T19:09:58.000Z',
    ended_at: '2021-03-07T19:18:01.259Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M5==',
    playable_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    download_url:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    event_part_name: 'Main Stage',
    event_part_type: 'stage',
    started_at: '2021-03-07T19:09:58.000Z',
    ended_at: '2021-03-07T19:18:01.259Z',
    duration: 483,
  },
];

export const rawRecording = {
  id: 'TGl2ZXN0cmVhbVJlY29yZGluZzoxMDE=',
  started_at: '2022-01-27T17:36:06.094Z',
  ended_at: '2022-01-27T17:39:06.094Z',
  playable_url: 'https://test-playable-url',
  download_url:
    '/organisers/events/emw-live-event-with-primary-and-backup/recordings/TGl2ZXN0cmVhbVJlY29yZGluZzoxMDE=/download',
  thumbnail: 'https://image.mux.com/xyz/thumbnail.jpg?width=640',
  duration: 180,
  event_part_id: 'kjmaW30627XV1eELORKjLfWYx',
  event_part_type: 'stage',
  event_part_name: 'Default Stage',
  published: false,
  title: TITLE,
  description: '',
  category: 'main',
  status: 'processing',
  status_changed_at: null,
  related: rawRelatedRecordings,
  parent_recording_id: null,
};

export const rawRecordingWithTrimSuccess = {
  ...rawRecording,
  status: 'success',
  statusChangedAt: '2022-01-27T19:39:06.094Z',
};

export const rawRecordingWithTrimProcessing = {
  ...rawRecording,
  status: 'trimming_requested',
  statusChangedAt: '2022-01-28T17:39:06.094Z',
  related_recordings: [],
  id: 'test1',
};

export const rawRecordingWithTrimFailed = {
  ...rawRecording,
  status: 'failure',
  statusChangedAt: '2022-01-28T17:39:06.094Z',
  related_recordings: [],
  id: 'test1',
};

export const recording = camelizeKeys(rawRecording);
export const trimmedRecording = camelizeKeys(rawRecordingWithTrimSuccess);
export const processingRecording = camelizeKeys(rawRecordingWithTrimProcessing);

export const defaultUser = {
  userId: 'userId',
};

export const defaultRegistration = {
  external_id: 'registrationExternalId',
};
