import eventDashboardApi from '@api/event-dashboard';
import FileInputButton from '@components/file-input-button/file-input-button';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { uploadFileToS3 } from '@util/upload-file-to-s3';
import PropTypes from 'prop-types';
import React from 'react';

import CustomDomainSettingsImagePreview from './custom-domain-settings-image-preview';
import { FaviconLabel } from './custom-domain-settings.styles';

const CustomDomainSettingsFavicon = ({
  imageUrl,
  t,
  ref,
  disabled,
  setFilename,
  entityId,
  setImagePreview,
  addAlert,
}) => {
  const validateFavicon = file => {
    return new Promise((resolve, reject) => {
      const faviconMaxSize = 150;
      if (file.size > faviconMaxSize * 1024) {
        reject(
          t('common.image-upload.file-limit', { size: `${faviconMaxSize}kb` }),
        );
      }

      const supportedDimensions = [
        [16, 16],
        [32, 32],
        [48, 48],
        [64, 64],
      ];
      const fileReader = new FileReader();

      fileReader.onload = e => {
        const image = new Image();

        image.onload = function () {
          const validDimension = supportedDimensions.find(
            ([width, height]) => width === this.width && height === this.height,
          );

          if (!validDimension) {
            reject(t('event-dashboard.custom-domains.favicon-dimensions'));
          }

          resolve();
          return true;
        };

        image.src = e.target.result;
      };
      fileReader.readAsDataURL(file);
    });
  };

  const onChange = async e => {
    e.persist();

    const file = e.target.files[0];

    if (!file) {
      return;
    }

    try {
      await validateFavicon(file);

      try {
        const {
          data: {
            attributes: { file_name, presigned_url, params },
          },
          errors,
        } = await eventDashboardApi.createPresignedUploadUrl({
          entity_name: 'event',
          entity_field: 'favicon',
          file_name: file.name,
          entity_id: entityId,
          public_read: true,
        });

        if (file_name) {
          await uploadFileToS3(presigned_url, file, params);
          setFilename(file_name);

          const reader = new FileReader();
          reader.onload = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(file);
        } else if (errors) {
          addAlert({
            active: true,
            text: errors,
            pattern: 'error',
          });
        }
      } catch (e) {
        addAlert({
          active: true,
          text: t('common.image-upload.file-fail'),
          pattern: 'error',
        });
        console.error(e);
      }
    } catch (faviconValidationError) {
      addAlert({
        active: true,
        pattern: 'error',
        text: faviconValidationError,
      });
    }
  };

  return (
    <>
      <Flex alignItems="flex-end">
        <CustomDomainSettingsImagePreview
          image={imageUrl}
          label="custom domain favicon preview"
        />
        <input type="hidden" ref={ref} />
        <Box ml={2}>
          <FaviconLabel
            as={Text}
            pattern="labelTwo"
            element="label"
            htmlFor="custom-domain-favicon"
            mb={1}
            id="custom-domain-favicon-label"
          >
            {t('event-dashboard.custom-domains.icon')}
          </FaviconLabel>
          <FileInputButton
            isInline
            isOutlined
            id="custom-domain-favicon"
            disabled={disabled}
            size="small"
            testId="favicon-input"
            py={1}
            px={2}
            aria-labelledby="custom-domain-favicon-label"
            accept=".ico"
            onChange={onChange}
          >
            {t('event-dashboard.custom-domains.choose-icon')}
          </FileInputButton>
        </Box>
      </Flex>
    </>
  );
};

CustomDomainSettingsFavicon.propTypes = {
  imageUrl: PropTypes.string,
  ref: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  disabled: PropTypes.bool.isRequired,
  setImagePreview: PropTypes.func.isRequired,
  setFilename: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  addAlert: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default CustomDomainSettingsFavicon;
