import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { AddTag, TagGroup } from '@hopin-team/ui-add-tag';
import { Box } from '@hopin-team/ui-box';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { Toggle } from '@hopin-team/ui-toggle';
import { arrayOf, number, object, shape, string } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

const AttendeeActionsSetting = ({
  tickets,
  control,
  formToggleName,
  formFieldName,
  translationKey,
  formCheckboxName,
}) => {
  const { t } = useLocalization(`venue-controls.attendee-actions`);
  const ticketLabels = tickets ? tickets.map(ticket => ticket.label) : [];

  const {
    field: { name: toggleName, onChange: onToggle, value: toggleValue },
  } = useController({
    name: formToggleName,
    control,
  });

  const {
    field: {
      name: excludedTicketsName,
      onChange: setExcludedTickets,
      value: excludedTicketsValue,
    },
  } = useController({
    name: formFieldName,
    control,
  });

  const {
    field: {
      name: excludedTicketsCheckboxName,
      onChange: setExcludedTicketsCheckbox,
      value: excludedTicketsChecked,
    },
  } = useController({
    name: formCheckboxName,
    control,
  });

  const excludedTicketsValueLabels =
    excludedTicketsValue?.length && tickets?.length
      ? excludedTicketsValue.map(
          id => tickets.find(ticket => ticket.id === id)?.label,
        )
      : [];

  const getTicketIdFromLabel = label =>
    tickets.find(ticket => ticket.label === label).id;

  const handleToggle = e => onToggle(e.target.checked);

  const handleCheck = e => {
    if (!e.target.checked) {
      setExcludedTickets([]);
    }

    setExcludedTicketsCheckbox(e.target.checked);
  };

  const handleOnSelect = ticket => {
    const selectedTicketId = getTicketIdFromLabel(ticket);

    const selectedTickets =
      excludedTicketsValue?.length &&
      excludedTicketsValue.includes(selectedTicketId)
        ? excludedTicketsValue.filter(
            excludedTicketId => excludedTicketId !== selectedTicketId,
          )
        : [...excludedTicketsValue, selectedTicketId];

    setExcludedTickets(selectedTickets);
  };

  const handleOnAdd = ticket =>
    setExcludedTickets([...excludedTicketsValue, getTicketIdFromLabel(ticket)]);

  const handleOnRemove = event => {
    const t = event.target;
    const name = t?.parentNode?.children[1]?.textContent;

    if (!name) return;
    setExcludedTickets(
      excludedTicketsValue.filter(
        selectedTicket => selectedTicket !== getTicketIdFromLabel(name),
      ),
    );
  };

  return (
    <Box mt={3}>
      <Toggle
        id={translationKey}
        sizeOption="large"
        label={t(`${translationKey}.label`)}
        name={toggleName}
        isToggled={toggleValue}
        onChange={handleToggle}
      />
      <StyledBox ml={8}>
        <Text
          element="label"
          htmlFor={translationKey}
          pattern="body"
          color="grey-500"
        >
          {t(`${translationKey}.helper`)}
        </Text>

        {!!tickets?.length && toggleValue && (
          <>
            <Checkbox
              name={excludedTicketsCheckboxName}
              checked={excludedTicketsChecked}
              label={t(`${translationKey}.exclude-tickets`)}
              onChange={handleCheck}
              mt={0.5}
            />
            {excludedTicketsChecked && (
              <Flex mt={1} flexDirection="column">
                <StyledAddTag
                  name={excludedTicketsName}
                  tags={ticketLabels}
                  selectedTags={excludedTicketsValueLabels}
                  onAddTag={handleOnAdd}
                  onSelectTag={handleOnSelect}
                  placeholder={t('excluded-tickets-placeholder')}
                  aria-label={t('add-tickets-to-exclude')}
                  hideLabel
                  isInline
                />
                <TagGroup
                  label=""
                  tags={excludedTicketsValueLabels}
                  onRemove={handleOnRemove}
                  isCapped
                  mt={1}
                />
              </Flex>
            )}
          </>
        )}
      </StyledBox>
    </Box>
  );
};

const StyledBox = styled(Box)`
  max-width: 37.875rem;
`;

const StyledAddTag = styled(AddTag)`
  min-width: 14rem;
`;

AttendeeActionsSetting.propTypes = {
  tickets: arrayOf(
    shape({
      id: number,
      label: string,
    }),
  ),
  control: object.isRequired,
  formToggleName: string.isRequired,
  formFieldName: string.isRequired,
  translationKey: string.isRequired,
  formCheckboxName: string.isRequired,
};

export default withLocalizationProvider(AttendeeActionsSetting);
