const transformResults = results => {
  const [
    npsResults,
    turnoutResults,
    { average_minutes },
    attendeeResults,
    attendeesByTicketTypeResults,
  ] = results;
  const turnoutData = transformTurnoutResults(turnoutResults);
  const attendeeNpsData = transformAttendeeNpsResults(npsResults);
  const peakAttendance = transformAttendeeResults(attendeeResults);
  const attendees = transformAttendeeByTicketTypeResults(
    attendeesByTicketTypeResults,
  );

  return [
    attendeeNpsData,
    turnoutData,
    average_minutes,
    peakAttendance,
    attendees,
  ];
};

const transformTurnoutResults = ({ ratio, registrations_count }) => {
  return registrations_count > 0
    ? Math.round(ratio * 100).toString() + '%'
    : '--';
};

const transformAttendeeResults = results => {
  if (!results) return null;

  return Math.max(...results.chart.data[0].data.map(d => d[1]).concat([0]));
};

const transformAttendeeNpsResults = npsResults => {
  return npsResults != null ? npsResults : '--';
};

const transformAttendeeByTicketTypeResults = attendeesByTicketTypeResults => {
  if (!attendeesByTicketTypeResults) return null;

  return attendeesByTicketTypeResults.event_attendees_count || '--';
};

export default transformResults;
