import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Search } from '@hopin-team/ui-search';
import { string } from 'prop-types';
import React, { useState } from 'react';

const SEARCH_URL_PARAM = 'q';

const SearchBar = ({ searchText: initSearchText }) => {
  const { t } = useLocalization('vendor-list-header-search-bar');

  const [searchText, setSearchText] = useState(initSearchText);

  const onClear = () => {
    setSearchText('');
    const search = new URLSearchParams(window.location.search);
    search.delete(SEARCH_URL_PARAM);
    window.location.search = search.toString();
  };

  return (
    <form method="GET">
      <Search
        aria-label={t('label')}
        placeholder={t('placeholder')}
        sizeOption="medium"
        colorPattern="light"
        name={SEARCH_URL_PARAM}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        onClear={onClear}
      />
    </form>
  );
};

SearchBar.propTypes = {
  searchText: string.isRequired,
};

export default withLocalizationProvider(SearchBar);
