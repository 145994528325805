import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { usePublishEventHandler } from '@features/publishing';
import { string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchData,
  togglePublishedStatus,
  toggleVisibility,
  updateStepStatus,
} from '@/redux/reducers/setup-checklist';

import { STEPS } from './constants/steps';
import EventProgressComponent from './event-progress-component';

const EventProgressContainer = ({ eventId }) => {
  const dispatch = useDispatch();
  const { addAlert } = useContext(AlertsContext);

  const addGenericCatchAlert = () => {
    addAlert({
      active: true,
      text: 'Something went wrong',
      pattern: 'error',
    });
  };

  useEffect(() => {
    try {
      dispatch(fetchData(eventId));
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const canPublish = useSelector(({ event }) => {
    const { maxPrice, organization } = event;
    const { merchantId } = organization;
    const noTickets = !event.hasTickets;
    const free = maxPrice === 0;
    return !(noTickets || (!free && !merchantId));
  });
  const checklistStatuses = useSelector(
    ({ setupChecklist }) => setupChecklist.attributes.steps,
  );
  const eventSlug = useSelector(({ event }) => event.slug);
  const isDraft = useSelector(({ event }) => event.draft);
  const isPublishing = useSelector(({ event }) => event.isPublishing);
  const isVisible = useSelector(
    ({ setupChecklist }) => setupChecklist.attributes.visible,
  );

  useEffect(() => {
    const publishedStatusPending = checklistStatuses?.published === 'pending';
    if (checklistStatuses && isDraft !== publishedStatusPending) {
      dispatch(togglePublishedStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraft]);

  const handlePublish = usePublishEventHandler();

  const handleToggleVisibility = async () => {
    dispatch(toggleVisibility(eventId));
  };

  const skipStep = step => {
    dispatch(updateStepStatus({ eventId, step, status: 'skipped' }));
  };

  const handleSkipLandingPage = async () => {
    skipStep(STEPS.LANDING_PAGE);
  };

  const handleSkipSpeakerProfiles = async () => {
    skipStep(STEPS.SPEAKER_PROFILES);
  };

  const handlePreview = async () => {
    try {
      dispatch(
        updateStepStatus({
          eventId,
          step: STEPS.PREVIEWED,
          status: 'completed',
        }),
      );
    } catch (err) {
      console.log(err);
      addGenericCatchAlert();
    }
  };

  const isLoading =
    !checklistStatuses || Object.keys(checklistStatuses).length === 0;

  return (
    <>
      <Alerts />
      <EventProgressComponent
        canPublish={canPublish}
        checklistStatuses={checklistStatuses}
        eventSlug={eventSlug}
        isLoading={isLoading}
        isVisible={isVisible}
        isPublishing={isPublishing}
        onPublish={handlePublish}
        onPreview={handlePreview}
        onSkipLandingPage={handleSkipLandingPage}
        onSkipSpeakerProfiles={handleSkipSpeakerProfiles}
        onToggleVisibility={handleToggleVisibility}
      />
    </>
  );
};

EventProgressContainer.propTypes = {
  eventId: string.isRequired,
};

export default withAlertsProvider(EventProgressContainer);
