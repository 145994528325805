import { LocalizationProvider } from '@features/localization';
import React from 'react';

import { FieldMapper } from '../index';

export default {
  title: 'Components/FieldMapper',
  component: FieldMapper,
  decorators: [
    Story => (
      <LocalizationProvider>
        <Story />
      </LocalizationProvider>
    ),
  ],
};

const Field = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  HOMETOWN: 'Hometown',
  INTERESTS: 'Interests',
};

const Template = args => <FieldMapper {...args} />;

export const Primary = Template.bind({});

Primary.args = {
  defaultMapping: {
    first_name: Field.FIRST_NAME,
    last_name: Field.LAST_NAME,
    email: Field.EMAIL,
    hometown: Field.HOMETOWN,
    interests: Field.INTERESTS,
  },
  exampleCount: 3,
  records: [
    {
      [Field.FIRST_NAME]: 'Walter',
      [Field.LAST_NAME]: 'White',
      'Digital Letterbox': 'heisenburg@breaking.bad',
      [Field.INTERESTS]: '',
      [Field.HOMETOWN]: 'Albequerque, NM',
    },
    {
      [Field.FIRST_NAME]: 'Jessie',
      [Field.LAST_NAME]: 'Pinkman',
      'Digital Letterbox': 'jessie@breaking.bad',
      [Field.INTERESTS]: '',
      [Field.HOMETOWN]: 'Albequerque, NM',
    },
    {
      [Field.FIRST_NAME]: 'Gustavo',
      [Field.LAST_NAME]: 'Fring',
      'Digital Letterbox': 'chicken.man@pollo.bros',
      [Field.INTERESTS]: '',
      [Field.HOMETOWN]: 'Albequerque, NM',
    },
    {
      [Field.FIRST_NAME]: 'Mike',
      [Field.LAST_NAME]: 'Ehrmantraut',
      'Digital Letterbox': 'mike@ex.cop',
      [Field.INTERESTS]: '',
      [Field.HOMETOWN]: 'Albequerque, NM',
    },
    {
      [Field.FIRST_NAME]: 'Hank',
      [Field.LAST_NAME]: 'Schrader',
      'Digital Letterbox': 'hank@the.dea',
      [Field.INTERESTS]: '',
      [Field.HOMETOWN]: 'Albequerque, NM',
    },
  ],
};
