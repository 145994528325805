import Card from '@components/card-with-loading';
import Chart from '@components/icons/chart';
import Connection from '@components/icons/connection';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import {
  ConnectionIconWrapper,
  ConnectionsContentWrapper,
  Headline,
  LeftConnection,
  ListItem,
  Logo,
  Name,
  RightConnection,
  SeeMore,
} from '@features/dashboard/styles/latest-connections-styles';
import { useLocalization } from '@features/localization';
import { arrayOf, bool, shape, string } from 'prop-types';
import React from 'react';

const LatestConnectionsContainer = ({
  loading,
  connections,
  connectionsUrl,
}) => {
  const { t } = useLocalization('connections-dashboard.latest-connections');
  return (
    <Card isLoading={loading} testId="latest-connections-component">
      <CardTitle>{t('title')}</CardTitle>

      <ConnectionsContentWrapper>
        {connections.length === 0 && (
          <EmptyState text={t('empty')} icon={<Chart />} />
        )}

        {connections.length > 0 && (
          <>
            <div>
              {connections.map((connection, index) => (
                <ListItem key={index}>
                  <LeftConnection>
                    <Logo
                      alt={connection.user.name}
                      src={connection.user.avatar_url}
                      rounded={true}
                    />
                    <Name>
                      <div>{connection.user.name}</div>
                      <Headline>{connection.user.headline}</Headline>
                    </Name>
                  </LeftConnection>
                  <ConnectionIconWrapper>
                    <Connection />
                  </ConnectionIconWrapper>
                  <RightConnection>
                    <Logo
                      alt={connection.liked.name}
                      src={connection.liked.avatar_url}
                      rounded={true}
                    />
                    <Name>
                      <div>{connection.liked.name}</div>
                      <Headline>{connection.liked.headline}</Headline>
                    </Name>
                  </RightConnection>
                </ListItem>
              ))}
            </div>

            <SeeMore href={connectionsUrl}>{t('see-more')} &gt;</SeeMore>
          </>
        )}
      </ConnectionsContentWrapper>
    </Card>
  );
};

const userPropTypes = shape({
  name: string,
  avatar_url: string,
  headline: string,
});

LatestConnectionsContainer.propTypes = {
  connectionsUrl: string.isRequired,
  connections: arrayOf(
    shape({
      user: userPropTypes,
      liked: userPropTypes,
    }),
  ),
  loading: bool.isRequired,
};

export default LatestConnectionsContainer;
