import Tooltip from '@components/chart-tooltip';
import {
  graphColor60,
  gray300,
  gray700,
} from '@features/dashboard/util/colors';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { getTicks, scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';
import { format as formatNumber } from 'd3-format';
import PropTypes from 'prop-types';
import React from 'react';

import { BarChartSvg, BarChartWrapper } from './styles.js';
import getMaxTickWidth from './util/get-max-tick-width';
import getUpperBound from './util/get-upper-bound';

/*
This component is used for displaying data organized into
subgroups with associated numerical values.
ie: data = [[groupName1, val], [groupName2, val]]
*/

// accessors
const getX = d => d[0];
const getY = d => d[1];

// formatter
const numberFormatter = formatNumber('~s');

function BarChart({
  height,
  width,
  data,
  xTickLabelRenderer,
  testId,
  formatTooltipName,
  tooltipDescription,
}) {
  let tooltipTimeout;
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  // y-axis config
  const maxYTicks = 4;
  const paddingBottom = 35;
  const yMax = height - paddingBottom;
  const maxValue = Math.max(...data.map(getY));
  const upperYBound = getUpperBound(maxValue, maxYTicks) || 1;
  const numYTicks = Math.min(upperYBound, maxYTicks);
  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [0, upperYBound],
  });
  const yTicks = getTicks(yScale, numYTicks);

  // x-axis config
  const paddingLeft = getMaxTickWidth(yTicks, numberFormatter) + 8;
  const xMax = width - paddingLeft;
  const xScale = scaleBand({
    range: [0, xMax],
    domain: data.map(getX),
    padding: 0.4,
  });

  // This number is approximate, visx will try to get as close to this as possible.
  // Because of this we need to air on the lower side
  const numXTicks = 6;

  const xTicks = getTicks(xScale, numXTicks);
  const optionalXAxisProps = {};
  if (xTickLabelRenderer) {
    optionalXAxisProps.tickComponent = props => {
      return xTickLabelRenderer({
        allTicks: xTicks,
        ...props,
      });
    };
  }

  return (
    <BarChartWrapper data-testid={testId}>
      <BarChartSvg width={width} height={height}>
        <Group>
          <GridRows
            numTicks={numYTicks}
            scale={yScale}
            left={paddingLeft}
            width={xMax}
            height={yMax}
            stroke={gray300}
          />

          {data.map((d, i) => {
            const xVal = getX(d);
            const yVal = getY(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getY(d)) ?? 0);
            const barX = xScale(xVal) + paddingLeft;
            const barY = yMax - barHeight;
            return (
              <Bar
                data-testid={'bar-chart-bar-' + i}
                key={i}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={graphColor60}
                onMouseLeave={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 300);
                }}
                onMouseMove={event => {
                  if (tooltipTimeout) clearTimeout(tooltipTimeout);
                  const { x, y } = localPoint(event);

                  showTooltip({
                    tooltipData: {
                      name: formatTooltipName ? formatTooltipName(xVal) : xVal,
                      value: yVal,
                    },
                    tooltipTop: y,
                    tooltipLeft: x,
                  });
                }}
              />
            );
          })}
        </Group>
        <AxisBottom
          scale={xScale}
          top={yMax}
          left={paddingLeft}
          numTicks={numXTicks}
          tickValues={xTicks}
          hideTicks
          stroke={gray300}
          tickLabelProps={() => ({
            fill: gray700,
            fontSize: 12,
            textAnchor: 'middle',
          })}
          {...optionalXAxisProps}
        />
        <AxisLeft
          scale={yScale}
          left={paddingLeft}
          numTicks={numYTicks}
          stroke={gray300}
          tickStroke={gray300}
          tickLength={4}
          tickFormat={numberFormatter}
          tickLabelProps={() => ({
            fill: gray700,
            fontSize: 12,
            textAnchor: 'end',
            dy: '0.33em',
            dx: '-.33em',
          })}
          labelOffset={10}
        />
      </BarChartSvg>
      {tooltipOpen && tooltipData && (
        <Tooltip
          top={tooltipTop}
          left={tooltipLeft}
          data={tooltipData}
          description={tooltipDescription}
          theme="dark"
        />
      )}
    </BarChartWrapper>
  );
}

BarChart.defaultProps = {
  data: [],
  testId: 'bar-chart',
};

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  xTickLabelRenderer: PropTypes.func,
  formatTooltipName: PropTypes.func,
  tooltipDescription: PropTypes.string,
  testId: PropTypes.string,
};

export default BarChart;
