import { useLocalization } from '@features/localization';
import { ValidationError } from '@features/recordings-backup/api';
import { EVENT_PART, RANK, WHEN } from '@features/recordings-backup/constants';
import { useUpdateRecording } from '@features/recordings-backup/hooks';
import { selectEvent } from '@features/recordings-backup/redux/slices/event/event-selectors';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { SidePanelModal } from '@hopin-team/ui-side-panel-modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { SidebarForm } from './sidebar-form';

export const SidebarContainer = ({
  isShowing,
  recording,
  onClose,
  onSuccess,
  onError,
}) => {
  const { t } = useLocalization('recording-library');
  const [isShowingCancelDialog, setIsShowingCancelDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { slug } = useSelector(selectEvent);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    reset,
    setError,
    formState,
  } = useForm({
    defaultValues: {
      title: recording.title,
      description: recording.description,
    },
  });

  useEffect(() => {
    reset({
      title: recording.title,
      description: recording.description,
    });
  }, [recording.title, recording.description, reset]);

  const { isDirty } = formState;

  const onSubmit = values => {
    if (isDirty) {
      setIsProcessing(true);
      updateRecording(recording, values);
    } else {
      stopEditing();
    }
  };

  const onUpdateSuccess = ({ updatedRecording }) => {
    setIsProcessing(false);
    if (onSuccess) {
      onSuccess(updatedRecording);
    }
  };

  const onUpdateError = ({ error, message }) => {
    setIsProcessing(false);

    if (error instanceof ValidationError && error.field === 'title') {
      setError('title', { type: 'manual', message });
    } else {
      if (onError) {
        onError(message);
      }
    }
  };

  const { updateRecording } = useUpdateRecording({
    slug,
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  });

  const handleClose = () => {
    if (isDirty) {
      setIsShowingCancelDialog(true);
    } else {
      stopEditing();
    }
  };

  const stopEditing = () => {
    setIsShowingCancelDialog(false);
    reset({
      title: recording.title,
      description: recording.description,
    });
    onClose();
  };

  return (
    <>
      {/* Cancel editing confirm */}
      <span className="confirm-dialog"></span>
      <ConfirmationDialog
        size="tiny"
        isShowing={isShowingCancelDialog}
        title={t('cancel-edit-confirmation-modal.title')}
        message={t('cancel-edit-confirmation-modal.message')}
        primaryButtonText={t('cancel-edit-confirmation-modal.confirm')}
        colorPattern="danger"
        secondaryButtonText={t('cancel-edit-confirmation-modal.cancel')}
        onConfirm={stopEditing}
        onCancel={() => setIsShowingCancelDialog(false)}
      />
      <span className="sidebar"></span>
      <SidePanelModal
        background="grey-100"
        describedById="modal-header"
        isShowing={isShowing}
        isDismissible={true}
        closeLabel={t('edit.close-sidebar')}
        onClose={handleClose}
        withCloseButton
        label={t('edit.label')}
        size="large"
      >
        <SidebarForm
          isProcessing={isProcessing}
          title={watch('title')}
          description={recording.description}
          errors={errors}
          onCancel={handleClose}
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          setValue={setValue}
        />
      </SidePanelModal>
    </>
  );
};

SidebarContainer.propTypes = {
  isShowing: PropTypes.bool,
  recording: PropTypes.shape({
    rank: PropTypes.oneOf([RANK.MAIN, RANK.BACKUP]),
    whenInTime: PropTypes.oneOf([WHEN.PRE_EVENT, WHEN.IN_EVENT]),
    startedAt: PropTypes.string,
    createdAt: PropTypes.string,
    endedAt: PropTypes.string,
    title: PropTypes.string,
    playableUrl: PropTypes.string,
    description: PropTypes.string,
    eventPart: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf([EVENT_PART.STAGE, EVENT_PART.SESSION]),
    }),
  }).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func,
};
