import '@hopin-team/ui-theme';

import { AlertsContext } from '@features/alerts/alerts-provider';
import { SidePanelModal } from '@hopin-team/ui-side-panel-modal';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import { string } from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { closeModal, openModal } from '@/redux/reducers/modal';
import {
  addSponsorArea,
  selectSponsorAreasForSection,
  setSponsorAreaLevel,
} from '@/redux/reducers/sponsor-areas';
import {
  addSponsor,
  selectors as sponsorSelectors,
} from '@/redux/reducers/sponsors';

import AddSponsorsModal from './add-sponsors-modal';
import { MODALS } from './constants';
import { postSponsor } from './create-sponsor-api';
import CreateNewSponsor from './create-sponsor-modal';

function SponsorModalContainer({ authToken, section, eventSlug }) {
  const { addAlert } = useContext(AlertsContext);
  const dispatch = useDispatch();
  const apiClient = initApiClient(authToken);
  const logger = getLogger(LOGGER_NAMES.SPONSORS_GRID);

  const { level: modalSponsorLevel, type: modalType } = useSelector(
    ({ modal }) => ({
      level: modal.context?.level,
      type: modal.type,
    }),
  );
  const sponsors = useSelector(sponsorSelectors.selectAll);
  const sponsorAreas = useSelector(state => {
    const sponsorEntities = sponsorSelectors.selectEntities(state);

    // Add sponsor object on sponsorAreas for UI elements
    return selectSponsorAreasForSection(section)(state)
      .filter(({ level }) => level === modalSponsorLevel)
      .map(i => ({
        ...i,
        sponsor: sponsorEntities[i.sponsorId],
      }));
  });

  const isSponsorModalOpen =
    MODALS.ADD_EXISTING_SPONSORS === modalType ||
    MODALS.CREATE_AND_ADD_SPONSOR === modalType;

  // Handle Submission of Add Sponsor Modal
  const handleSponsorAreasSave = level => sponsorIds => {
    dispatch(closeModal());

    dispatch(
      setSponsorAreaLevel({
        items: sponsorIds.map(id => {
          // Find existing sponsor area, if one is available
          const existingSponsorArea = sponsorAreas.find(
            sa =>
              sa.sponsorId === id &&
              sa.level === level &&
              sa.section === section,
          );

          return (
            existingSponsorArea || {
              id: uuid(),
              level,
              sponsorId: id,
            }
          );
        }),
        level,
        section,
      }),
    );
  };

  const handleSponsorCreation = level => sponsor => {
    logger.info('creating sponsor', { eventId: eventSlug });

    return postSponsor(apiClient, eventSlug, sponsor)
      .then(createdSponsor => {
        dispatch(closeModal());

        // Add to collection of sponsors
        dispatch(addSponsor(createdSponsor));

        // Associate Sponsor with Sponsorship Level
        dispatch(
          addSponsorArea({
            level: level,
            section,
            sponsorId: createdSponsor.id,
          }),
        );
        addAlert({
          active: true,
          text: 'Sponsor created',
          pattern: 'success',
        });
      })
      .catch(e => {
        dispatch(closeModal());
        logger.error(e);
        addAlert({
          active: true,
          text: 'Something went wrong!',
          pattern: 'error',
        });
      });
  };

  return (
    <SidePanelModal
      describedById="modal-header"
      isShowing={isSponsorModalOpen}
      onClose={() => {
        dispatch(closeModal());
      }}
      isDismissible
      withCloseButton
      label="Sponsors Modal"
      size="large"
    >
      {
        {
          [MODALS.ADD_EXISTING_SPONSORS]: (
            <AddSponsorsModal
              availableSponsors={sponsors}
              selectedSponsorIds={sponsorAreas.map(({ sponsor }) => sponsor.id)}
              onCreateNew={() =>
                dispatch(
                  openModal(MODALS.CREATE_AND_ADD_SPONSOR, {
                    level: modalSponsorLevel,
                  }),
                )
              }
              onSave={handleSponsorAreasSave(modalSponsorLevel)}
            />
          ),
          [MODALS.CREATE_AND_ADD_SPONSOR]: (
            <CreateNewSponsor
              onSubmit={handleSponsorCreation(modalSponsorLevel)}
            />
          ),
        }[modalType]
      }
    </SidePanelModal>
  );
}

export default SponsorModalContainer;

SponsorModalContainer.propTypes = {
  authToken: string.isRequired,
  section: string.isRequired,
  eventSlug: string.isRequired,
};
