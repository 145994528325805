import ChevronRight from '@components/icons/chevron-right';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  font-size: var(--font-size);
`;

const LinkComponent = ({ linkTo, linkText }) => {
  return (
    <Link href={linkTo}>
      {linkText}
      &nbsp;
      <ChevronRight />
    </Link>
  );
};

LinkComponent.propTypes = {
  linkTo: string.isRequired,
  linkText: string.isRequired,
};

export default LinkComponent;
