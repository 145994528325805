import { FOOTER_HEIGHT } from '@features/event-creation-wizard/components/footer/styles';
import { TOP_BAR_HEIGHT } from '@features/event-creation-wizard/components/top-bar/styles';
import { rem } from 'polished';
import styled from 'styled-components';

const SidePanel = styled.aside`
  position: fixed;
  width: 50%;
  right: 0;
  height: calc(100vh - ${rem(TOP_BAR_HEIGHT)} - ${rem(FOOTER_HEIGHT)});
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border: 4px solid var(--color-black);
  border-radius: 1rem;
  width: 85%;
  aspect-ratio: 7/5;
  overflow-y: hidden;
  padding: var(--spacing-24);
`;

const BannerPreview = styled.img`
  width: 100%;
  aspect-ratio: 13/5;
  background: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: var(--border-radius-big);
`;

const OrgLogo = styled.img`
  height: ${rem(52)};
  width: ${rem(52)};
  border: 0.8px solid var(--color-gray-300);
  border-radius: ${rem(9)};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-12);
  border: 1px solid var(--color-blue-200);
  border-radius: var(--border-radius);
`;

export { SidePanel, Wrapper, BannerPreview, OrgLogo, IconContainer };
