import { FORM_NAME } from './profile-categories-form';

export const getFieldNameFromError = pointer => {
  const errorPaths = pointer.split('/');

  const categoryId = errorPaths[2];
  const field = errorPaths[3];

  if (categoryId && field) {
    return `${FORM_NAME}[${categoryId}].${field}`;
  }
};

const errorPatterns = [
  {
    pattern: /name can't be blank/,
    message: 'networking.profile-setup.field-empty-error',
  },
  {
    pattern: /is not included in the list/,
    message: 'networking.profile-setup.invalid-category-format',
  },
  {
    pattern: /Categories name not unique/,
    message: 'networking.profile-setup.category-names-not-unique',
  },
  {
    pattern: /Categories tags name not unique/,
    message: 'networking.profile-setup.tag-names-not-unique',
  },
];

export const getErrorMessageFromDetail = detail =>
  errorPatterns.find(({ pattern }) => detail.match(pattern))?.message;
