import { useLocalization } from '@features/localization';
import { Illustration } from '@hopin-team/ui-illustration';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { TemplateModalErrorRoot, TryAgainButton } from './styled';

export const TemplateModalError = ({ onTryAgain }) => {
  const { t } = useLocalization('templates.error');

  return (
    <TemplateModalErrorRoot
      data-testid="template-modal-error"
      flexDirection="column"
      alignItems="center"
      py={9}
      px={2}
    >
      <Illustration name="error" />
      <Text
        align="center"
        element="h3"
        id="create-event-modal-heading"
        pattern="headingThree"
      >
        {t('oops')}
      </Text>
      <Text align="center" color="grey-700" element="p" my={2} pattern="body">
        {t('loggingTheError')}
      </Text>
      <TryAgainButton onClick={onTryAgain}>{t('tryAgain')}</TryAgainButton>
    </TemplateModalErrorRoot>
  );
};

TemplateModalError.propTypes = {
  onTryAgain: PropTypes.func.isRequired,
};
