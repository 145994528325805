import hopinApi from '@api/hopin';
import Loading from '@components/loading';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  LocalizationContext,
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import debounce from 'lodash/debounce';
import compose from 'lodash/fp/compose';
import { bool, number, shape, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import AnnouncementsTopBar from '../announcements-top-bar';
import { ContainerWrapper } from '../styles';
import Editor from './editor';
import { SyncingIcon } from './styles';

const AnnouncementsEdit = ({
  event,
  announcementEmailTemplateId,
  registrationsDashboardUrl,
  ckeditor5Enabled,
}) => {
  const { t } = useContext(LocalizationContext);
  const translationKeyPrefix = 'email-dashboard';
  const { addAlert } = useContext(AlertsContext);
  const { organiser_id: organiserId, id: eventId, slug: eventSlug } = event;
  const [token, setToken] = useState(null);
  const form = useForm({ mode: 'onChange' });
  const { setValue, handleSubmit, getValues, reset, errors } = form;
  const [isLoading, setIsLoading] = useState(true);
  const [announcement, setAnnouncement] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { token } = await hopinApi.getUserToken();
        setToken(token);

        const announcementData = await hopinApi.getAnnouncement(
          token,
          organiserId,
          eventId,
          announcementEmailTemplateId,
        );

        const { personas } = await hopinApi.getTicketsWithCount(token, eventId);

        setTickets(personas);
        setAnnouncement(
          (({
            id,
            body,
            footer,
            name,
            subject,
            preview_text: previewText,
            audience_selection_attendee_status: attendeeStatus,
            audience_selection_ticket_types: ticketTypes,
          }) => ({
            id,
            body,
            footer,
            name,
            subject,
            previewText,
            attendeeStatus,
            ticketTypes,
          }))(announcementData),
        );

        setIsLoading(false);
      } catch (e) {
        addAlert({
          active: true,
          text: t(`${translationKeyPrefix}.list.errors.default`),
          pattern: 'error',
        });
      }
    }
    fetchData();
  }, [
    addAlert,
    announcementEmailTemplateId,
    eventId,
    organiserId,
    setAnnouncement,
    setValue,
    setIsLoading,
    t,
  ]);

  useEffect(() => {
    if (!isLoading && announcement?.id) {
      reset({
        name: announcement.name,
        subject: announcement.subject,
        body: announcement.body,
        footer: announcement.footer,
        previewText: announcement.previewText,
        attendeeStatus: announcement.attendeeStatus,
        ticketTypes: announcement.ticketTypes,
      });
    }
  }, [announcement, isLoading, reset, getValues, setValue]);

  const hasInvalidTickets = useCallback(
    value => {
      const invalidTickets = value.filter(item => {
        return !tickets.find(ticket => ticket.id === item.id);
      });

      if (invalidTickets.length) {
        const invalidTicketLabels = invalidTickets
          .map(({ label }) => `'${label}'`)
          .join(', ');
        const message =
          invalidTickets.length === 1
            ? t(`${translationKeyPrefix}.edit.invalid_ticket`, {
                ticket: invalidTicketLabels,
              })
            : t(`${translationKeyPrefix}.edit.invalid_tickets`, {
                tickets: invalidTicketLabels,
              });
        return message;
      }
    },
    [t, tickets],
  );

  const handleError = useCallback(
    (event, attributes) => {
      const invalidTicketsMessage = hasInvalidTickets(
        attributes.audience_selection_ticket_types,
      );
      const errors = [
        ...(invalidTicketsMessage ? [invalidTicketsMessage] : []),
      ];
      const errorMessage = errors.join(', ');

      const errorMessageText = errorMessage
        ? errorMessage
        : t(`${translationKeyPrefix}.list.errors.default`);

      addAlert({
        active: true,
        text: errorMessageText,
        pattern: 'error',
      });
    },
    [addAlert, hasInvalidTickets, t],
  );

  const saveFormData = useCallback(
    async (data, options) => {
      if (!Object.keys(errors).length) {
        setIsSaving(true);

        const attr = (({
          name,
          subject,
          body,
          previewText,
          attendeeStatus = 'registered', // to be removed when data is included in the form
          ticketTypes = [], // to be removed when data is included in the form
        }) => ({
          name,
          subject,
          body,
          preview_text: previewText,
          audience_selection_attendee_status: attendeeStatus,
          audience_selection_ticket_types: ticketTypes,
        }))(data);

        try {
          await hopinApi.editAnnouncement(
            token,
            organiserId,
            eventId,
            announcementEmailTemplateId,
            attr,
          );

          if (options.redirect) {
            window.location.assign(
              `/organisers/events/${eventSlug}/announcements/${announcementEmailTemplateId}/preview`,
            );
          }
        } catch (e) {
          handleError(e, attr);
        } finally {
          // wait a bit before allowing to update and prolong the animation
          setTimeout(() => {
            setIsSaving(false);
          }, 1000);
        }
      }
    },
    [
      announcementEmailTemplateId,
      handleError,
      eventId,
      eventSlug,
      organiserId,
      token,
      errors,
    ],
  );

  const onSubmit = async formData => {
    await saveFormData(formData, { redirect: true });
  };

  const handleFieldChange = () => {
    const formData = getValues();
    debounceCallback(formData);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce(formData => {
      saveFormData(formData, {});
    }, 2000),
    [saveFormData],
  );

  return (
    <ContainerWrapper>
      <AnnouncementsTopBar
        event={event}
        pageBreadcrumb={t(`${translationKeyPrefix}.list.actions.edit`)}
        rightActions={() => (
          <>
            {isSaving && <SyncingIcon />}
            <Button
              data-testid="save-and-preview-button"
              isInline
              size="small"
              mr={2}
              onClick={handleSubmit(onSubmit)}
              disabled={isSaving}
            >
              {t(`${translationKeyPrefix}.edit.save_and_preview`)}
            </Button>
          </>
        )}
      />
      <Alerts />
      <Loading isLoading={isLoading}>
        {!isLoading && (
          <FormProvider {...form}>
            <Editor
              eventSlug={eventSlug}
              announcement={announcement}
              handleFieldChange={handleFieldChange}
              tickets={tickets}
              registrationsDashboardUrl={registrationsDashboardUrl}
              ckeditor5Enabled={ckeditor5Enabled}
            />
          </FormProvider>
        )}
      </Loading>
    </ContainerWrapper>
  );
};

AnnouncementsEdit.propTypes = {
  event: shape({
    id: number.isRequired,
    name: string.isRequired,
    picture: string.isRequired,
    slug: string.isRequired,
  }),
  announcementEmailTemplateId: string.isRequired,
  registrationsDashboardUrl: string.isRequired,
  ckeditor5Enabled: bool,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
  withLocalization,
  withThemeProvider,
)(AnnouncementsEdit);
