const eventPurposeOptions = [
  {
    value: 'lead_generation',
    icon: 'email-two-tone',
    color: 'green',
  },
  {
    value: 'recruitment',
    icon: 'people',
    color: 'violet',
  },
  {
    value: 'engagement',
    icon: 'chat',
    color: 'green',
  },
  {
    value: 'entertainment',
    icon: 'desktop',
    color: 'orange',
  },
  {
    value: 'employee_engagement',
    icon: 'team',
    color: 'blue',
  },
  {
    value: 'community_building',
    icon: 'house',
    color: 'orange',
  },
  {
    value: 'marketing',
    icon: 'star',
    color: 'violet',
  },
];

export { eventPurposeOptions };
