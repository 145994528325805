import PropTypes from 'prop-types';
import React from 'react';

const PenLine = ({ className, color = 'currentColor' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M2.5 13.3334V17.5H6.66667L14.8226 9.38173L10.6183 5.17834L2.5 13.3334Z"
        fill={color}
      />
      <path
        d="M17.0117 4.84493L15.155 2.98834C14.5034 2.33667 13.4458 2.33745 12.795 2.99078L10.6175 5.17828L14.8216 9.38248L17.0091 7.20495C17.6624 6.55412 17.6642 5.49659 17.0117 4.84493Z"
        fill={color}
      />
      <path
        d="M17.5 18.125H11.6667C11.3217 18.125 11.0417 17.845 11.0417 17.5C11.0417 17.155 11.3217 16.875 11.6667 16.875H17.5C17.845 16.875 18.125 17.155 18.125 17.5C18.125 17.845 17.845 18.125 17.5 18.125Z"
        fill={color}
      />
    </svg>
  );
};

PenLine.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default PenLine;
