import { TextField } from '@hopin-team/ui-text-field';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const getAddressField = (place, type) =>
  place.address_components.find(component => component.types.includes(type))
    ?.long_name;

const AddressPicker = ({
  address,
  onAddressChange,
  googleMapsApiKey,
  size,
  label,
  placeholder,
  required = true,
}) => {
  const coordinates = address?.lat && {
    lat: parseFloat(address.lat),
    lng: parseFloat(address.lng),
  };
  const autocompleteRef = useRef();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries: ['places'],
  });

  const handlePlaceChange = () => {
    const place = autocompleteRef.current.getPlace();

    // place.address_components does not include a street address (street + street number)
    // so we grab it from formatted_address if the place has a street component
    const street = getAddressField(place, 'route');
    const streetAddress = street && place.formatted_address.split(',')[0];

    onAddressChange({
      formatted: place.formatted_address,
      name: place.name,
      streetAddress,
      locality: getAddressField(place, 'locality'),
      administrativeArea: getAddressField(place, 'administrative_area_level_1'),
      country: getAddressField(place, 'country'),
      postalCode: getAddressField(place, 'postal_code'),
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const renderInput = () => (
    <TextField
      label={label || 'Address'}
      placeholder={placeholder || 'Enter a location'}
      size={size}
      defaultValue={address?.name}
      required={required}
    />
  );

  if (!isLoaded) {
    return renderInput();
  }

  return (
    <>
      <Autocomplete
        onLoad={autocomplete => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceChange}
      >
        {renderInput()}
      </Autocomplete>

      {coordinates && (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '180px',
            marginTop: 8,
            borderRadius: 4,
          }}
          center={coordinates}
          zoom={15}
        >
          <Marker position={coordinates} />
        </GoogleMap>
      )}
    </>
  );
};

AddressPicker.propTypes = {
  address: PropTypes.object,
  onAddressChange: PropTypes.func,
  googleMapsApiKey: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default AddressPicker;
