import { Button as UIButton } from '@hopin-team/ui-button';
import { CopyField as UICopyField } from '@hopin-team/ui-copy-field';
import { Flex } from '@hopin-team/ui-flex';
import { Halon as UIHalon } from '@hopin-team/ui-halon';
import { Image as UIImage } from '@hopin-team/ui-image';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const Container = styled.div`
  // To account for wrapper padding
  margin-left: calc(var(--spacing-24) * -1);
  margin-right: calc(var(--spacing-24) * -1);
`;

export const Banner = styled.div`
  max-width: 72rem;
  padding: var(--spacing-32);
  margin: auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-32);

  @media (min-width: 1000px) {
    grid-gap: var(--spacing-64);
  }

  background-color: var(--color-gray-100);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
`;

export const OnsiteBannerContainer = styled(Flex)`
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  margin: var(--spacing-16) 0;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-gray-300);
`;

export const CopyField = styled(UICopyField)`
  input {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-80);
  }
`;

export const WhiteButton = styled(UIButton)`
  background-color: var(--color-white);
`;

export const Image = styled(UIImage)`
  border-radius: var(--border-radius);
  aspect-ratio: 2.5 / 1;
  object-fit: cover;
`;

export const ImageLink = styled.a`
  position: relative;

  opacity: 1;
  transition: opacity var(--transition-duration);

  :hover {
    opacity: 0.6;
  }
`;

export const TextLink = styled(Text)`
  &: nth-child(1) {
    color: var(--color-blue-400);
  }
`;

export const Halon = styled(UIHalon)`
  position: absolute;
  top: 0;
  right: 0;
  margin: var(--spacing-16);
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 24rem;
`;

export const CopyLinkContainer = styled.div`
  display: flex;
  border-top: 1px solid var(--color-gray-300);
  width: 100%;
  padding: var(--spacing-16);
  align-items: center;
`;

export const OnlineCopyLinkOverride = styled(UICopyField)`
  input {
    background: white !important;
    width: 396px;
    color: var(--color-gray-600) !important;
    padding-right: 80px;
  }

  button {
    color: var(--color-blue-400) !important;
  }
`;

export const OnlineBannerTitle = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;
