import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import { useEventCreationWizard } from '@features/event-creation-wizard/event-creation-wizard-provider';
import { hasComplexityOptions } from '@features/event-creation-wizard/redux/org/org-slice';
import { useStepsInfo } from '@features/event-creation-wizard/shared/useStepsInfo';
import { redirectOnClose } from '@features/event-creation-wizard/utils/redirection';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import segmentTrack from '@util/analytics/segment-client';
import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes, stepMap } from '../../constants/step-map';
import Breadcrumbs from '../breadcrumbs';
import { EventCreationProgress, TopBarWrapper } from './styles';

const TopBar = ({ disabled = false }) => {
  const history = useHistory();
  const { name: eventName } = useSelector(state => state.basicDetails);
  const { id: orgId } = useSelector(state => state.org);
  const { dashboardOptedIn, appEnvironment } = useSelector(
    state => state.flags,
  );
  const { type: eventFormat } = useSelector(state => state.eventLocation);
  const templateId = useSelector(state => state.template.id);
  const isComplexitySelectorEnabled = useSelector(hasComplexityOptions);
  const { dashboardUrl } = useEventCreationWizard();
  const { t } = useLocalization('event-creation-wizard');
  const stepsInfo = useStepsInfo();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleBack = () => {
    segmentTrack(EVENT.BACK_BUTTON_PRESSED, {
      organiser_id: orgId,
      screen_name: stepsInfo.currentStep,
      event_type: templateId,
    });

    if (!stepsInfo.previousStep) {
      if (Object.values(EXPERIENCE_TEMPLATE).includes(templateId)) {
        return history.push(routes.experienceDetails());
      } else if (templateId === 'virtual_lite') {
        if (isComplexitySelectorEnabled) {
          return history.push(routes.complexityPicker());
        } else {
          handleClose();
        }
      } else if (eventFormat === 'onsite') {
        return history.push(routes.formatPicker());
      } else {
        return history.push(routes.templatePicker(eventFormat));
      }
    }

    history.push(
      stepMap[stepsInfo.previousStep.name].path(templateId, eventFormat),
    );
  };

  const handleClose = async () => {
    segmentTrack(EVENT.MODAL_CLOSED, {
      organiser_id: orgId,
      screen_name: stepsInfo.currentStep,
      event_type: templateId,
    });

    redirectOnClose({ dashboardOptedIn, dashboardUrl, appEnvironment, orgId });
  };

  const breadcrumbSteps = stepsInfo.steps.reduce((acc, step) => {
    const { path } = stepMap[step.name];
    const selected = step.name === stepsInfo.currentStep;
    acc.push({
      key: step.name,
      path: path(templateId, eventFormat),
      label: t(`steps.${step.name}.name`),
      selected,
    });

    return acc;
  }, []);

  // browser confirmation dialog on tab close
  useEffect(() => {
    function listener(e) {
      if (!showConfirmation) {
        e.preventDefault();
        const message = t('common.confirmation-dialog.title');
        e.returnValue = message;
        return message;
      }
    }

    if (!process.env.NODE_ENV === 'development') {
      window.addEventListener('beforeunload', listener);
      const removeListener = () => {
        window.removeEventListener('beforeunload', listener);
      };

      return removeListener;
    }
  }, [t, showConfirmation]);

  return (
    <TopBarWrapper>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={1.5}
        py={1.25}
      >
        <Flex alignItems="center">
          <Button
            onClick={handleBack}
            isGhost
            isInline
            disabled={disabled}
            aria-label={t('common.back')}
            mr={4}
            data-testid="event-creation-back-button"
          >
            <Icon color="grey-900" name="arrow-back" scale={1.5} />
          </Button>
          <Breadcrumbs
            items={breadcrumbSteps}
            disabled={!eventName || disabled}
          />
        </Flex>
        <Button
          onClick={() => setShowConfirmation(true)}
          isGhost
          isInline
          aria-label={t('common.close')}
          data-testid="event-creation-close-button"
        >
          <Icon color="grey-700" name="close" scale={1.5} />
        </Button>
      </Flex>

      <EventCreationProgress
        label={t('common.progress-bar-label')}
        value={stepsInfo.currentStepIndex + 1}
        min={0}
        max={stepsInfo.steps.length}
      />
      <ConfirmationDialog
        isShowing={showConfirmation}
        title={t('common.confirmation-dialog.title')}
        message={t('common.confirmation-dialog.message')}
        primaryButtonText={t('common.confirmation-dialog.leave')}
        secondaryButtonText={t('common.confirmation-dialog.cancel')}
        colorPattern="danger"
        onConfirm={() => {
          handleClose();
        }}
        onCancel={() => setShowConfirmation(false)}
        isDismissible
      />
    </TopBarWrapper>
  );
};

TopBar.propTypes = {
  disabled: bool.isRequired,
};

export default TopBar;
