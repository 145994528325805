import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { Text } from '@hopin-team/ui-text';
import HeartCircle from 'icons/heart-circle.svg';
import Megaphone from 'icons/megaphone-alt.svg';
import UsersAlt from 'icons/users-alt.svg';
import UsersGroupAlt from 'icons/users-group-alt.svg';
import UsersGroup from 'icons/users-group.svg';
import Video from 'icons/video.svg';
import { func, oneOf } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const WebinarStyleRadioButtons = ({ selectedOption, setSelectedOption }) => {
  const { t } = useLocalization('event-creation-wizard.steps.webinar-style');

  const handleRadioChange = event => {
    setSelectedOption(event.target.value);
  };

  const options = [
    {
      id: 'lite_webcast',
      header: t('stage.header'),
      text1: t('stage.line-one'),
      text2: t('stage.line-two'),
      text3: t('stage.line-three'),
      text4: t('stage.line-four'),
      headerIcon: <Video />,
      icon2: <Video />,
      icon3: <Megaphone />,
      icon4: <UsersAlt />,
    },
    {
      id: 'lite_meetup',
      header: t('session.header'),
      text1: t('session.line-one'),
      text2: t('session.line-two'),
      text3: t('session.line-three'),
      text4: t('session.line-four'),
      headerIcon: <UsersGroupAlt />,
      icon2: <HeartCircle />,
      icon3: <Video />,
      icon4: <UsersGroup />,
    },
  ];

  return (
    <RadioGroupContainer role="radiogroup">
      {options.map(option => (
        <RadioButtonCard
          key={option.id}
          isSelected={selectedOption === option.id}
        >
          <Flex alignItems="center" flexGrow={1}>
            <div>
              <Flex mb={1} alignItems="center">
                <IconContainer
                  mr={1}
                  p={1}
                  checked={selectedOption === option.id}
                >
                  {option.headerIcon}
                </IconContainer>
                <RadioHeader pattern="emphasis" element="h6" color="grey-800">
                  {option.header}
                </RadioHeader>
              </Flex>

              <Text pattern="captionMedium" element="p" color="grey-600" mb={1}>
                {option.text1}
              </Text>

              <Flex mb={1} alignItems="center">
                {option.icon2}
                <Text
                  pattern="captionMedium"
                  element="p"
                  color="grey-600"
                  ml={2}
                >
                  {option.text2}
                </Text>
              </Flex>

              <Flex mb={1} alignItems="center">
                {option.icon3}
                <Text
                  pattern="captionMedium"
                  element="p"
                  color="grey-600"
                  ml={2}
                >
                  {option.text3}
                </Text>
              </Flex>

              <Flex mb={1} alignItems="center">
                {option.icon4}
                <Text
                  pattern="captionMedium"
                  element="p"
                  color="grey-600"
                  ml={2}
                >
                  {option.text4}
                </Text>
              </Flex>
            </div>
          </Flex>
          <div>
            <RadioButtonInput
              type="radio"
              name="webinar-styles"
              value={option.id}
              checked={selectedOption === option.id}
              onChange={handleRadioChange}
            />
            <CustomRadio isSelected={selectedOption === option.id} />
          </div>
        </RadioButtonCard>
      ))}
    </RadioGroupContainer>
  );
};

export default WebinarStyleRadioButtons;

WebinarStyleRadioButtons.propTypes = {
  selectedOption: oneOf(['lite_meetup', 'lite_webcast']),
  setSelectedOption: func.isRequired,
};

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    margin-bottom: 0;
  }
`;

const RadioButtonCard = styled.label`
  display: flex;
  align-items: flex-start;
  border: 0.0625rem solid
    ${({ isSelected }) =>
      isSelected ? 'var(--color-blue-300)' : 'var(--color-gray-300)'};
  background-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-blue-100)' : 'var(--color-white)'};
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:has(:focus-visible) {
    outline-color: var(--color-blue-500);
    outline-style: solid;
  }
`;

const RadioButtonInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + div::before {
    content: '';
    position: absolute;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 100%;
    background-color: var(--color-white);
    left: 0.25rem;
    top: 0.25rem;
  }
`;

const CustomRadio = styled.div`
  position: relative;
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  border: 0.0625rem solid
    ${({ isSelected }) =>
      isSelected ? 'var(--color-blue-400)' : 'var(--color-gray-400)'};
  border-radius: 100%;
  margin-left: 1.25rem;
  background: ${({ isSelected }) =>
    isSelected ? 'var(--color-blue-400)' : 'var(--color-white)'};
`;

const RadioHeader = styled(Text)`
  line-height: 1rem;
`;

const IconContainer = styled(Flex)`
  border-radius: 50%;
  background-color: ${({ checked }) =>
    checked ? 'var(--color-blue-200)' : 'var(--color-gray-100)'};
`;
