export const TYPE = {
  MULTI: 'multiple_choice',
  SINGLE: 'single_choice',
};

export const CATEGORY_BLOCK = {
  [TYPE.SINGLE]: {
    value: TYPE.SINGLE,
    label: 'networking.profile-setup.single-select',
    iconName: 'single-select',
    iconBackground: 'var(--color-violet-400)',
  },
  [TYPE.MULTI]: {
    value: TYPE.MULTI,
    label: 'networking.profile-setup.multi-select',
    iconName: 'multi-select',
    iconBackground: '#FA598A',
  },
};
