import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { connectViewData, withViewDataProvider } from '@util/page-view-context';
import compose from 'lodash/fp/compose';
import { array, bool, number, object } from 'prop-types';
import React from 'react';

import StagesComponent from './component';
import { mapStageTypesToStages } from './utils';

const mapViewDataToProps = ({
  event,
  eventPart,
  eventStages,
  stageLimitReached,
  stages,
  stageTypesByBackstageExternalIds,
}) => ({
  event,
  eventPart,
  eventStages,
  stageLimitReached,
  stages,
  stageTypesByBackstageExternalIds,
});

const StagesContainer = props => {
  const componentProps = {
    ...props,
    stages: mapStageTypesToStages(
      props.stages,
      props.stageTypesByBackstageExternalIds,
    ),
  };

  return <StagesComponent {...componentProps} />;
};

StagesContainer.propTypes = {
  event: object.isRequired,
  eventPart: object.isRequired,
  eventStages: number.isRequired,
  stageLimitReached: bool.isRequired,
  stages: array.isRequired,
  stageTypesByBackstageExternalIds: object.isRequired,
};

export default compose(
  withAlertsProvider,
  withViewDataProvider,
  connectViewData(mapViewDataToProps),
)(StagesContainer);
