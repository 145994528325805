/* global Rails */
import React, { useEffect, useState } from 'react';

const CsrfInput = () => {
  const [csrf, setCsrf] = useState();

  useEffect(() => {
    const csrfParam = Rails.csrfParam();
    const csrfToken = Rails.csrfToken();

    if (csrfParam && csrfToken) {
      setCsrf({
        csrfParam,
        csrfToken,
      });
    }
  }, []);

  return csrf ? (
    <input type="hidden" name={csrf.csrfParam} value={csrf.csrfToken} />
  ) : null;
};

export default CsrfInput;
