import { useLocalization } from '@features/localization';
import { Tab, TabHeader, TabList, TabPanels, Tabs } from '@hopin-team/ui-tabs';
import { string } from 'prop-types';
import React, { useContext } from 'react';
import { css } from 'styled-components';

import { EventLinksContext } from '../event-links-container';
import { TabPanel } from '../event-links-styles';
import HopinStudioBackstageLinksTab from './tab-hopin-studio-backstage-links';
import RegistrationTab from './tab-registration';
import RTMPBackstageLinksTab from './tab-rtmp-backstage-links';

const EventLinksTabsComponent = () => {
  const {
    eventSlug,
    registrationUrl,
    backstageOptions,
    hopinStudioLinks,
    createRtmpLink,
    rtmpLinks,
    rtmpAllowed,
  } = useContext(EventLinksContext);
  const { t } = useLocalization('overview.event-links.modal.tabs');

  const eventHasStages = backstageOptions?.length > 0;

  return (
    <Tabs>
      <TabHeader
        css={css`
          display: block;
        `}
      >
        {eventHasStages && (
          <TabList data-testid="event-links-tablist">
            <Tab>{t('registration.label')}</Tab>
            <Tab>{t('hopin-studio.label')}</Tab>
            {rtmpAllowed && <Tab>{t('rtmp.label')}</Tab>}
          </TabList>
        )}
      </TabHeader>
      <TabPanels>
        <TabPanel>
          <RegistrationTab registrationUrl={registrationUrl} />
        </TabPanel>
        {eventHasStages && (
          <>
            <TabPanel>
              <HopinStudioBackstageLinksTab
                backstageOptions={backstageOptions}
                links={hopinStudioLinks}
              />
            </TabPanel>
            {rtmpAllowed && (
              <TabPanel>
                <RTMPBackstageLinksTab
                  eventSlug={eventSlug}
                  backstageOptions={backstageOptions}
                  links={rtmpLinks}
                  createRtmpLink={createRtmpLink}
                />
              </TabPanel>
            )}
          </>
        )}
      </TabPanels>
    </Tabs>
  );
};

EventLinksTabsComponent.propTypes = {
  eventId: string,
};

export default EventLinksTabsComponent;
