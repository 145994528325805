import analyticsApi from '@api/analytics';
import Loading from '@components/loading';
import { usePromise } from '@features/dashboard/util/hooks';
import EmptyAnalytics from '@features/overview/analytics/components/empty-analytics';
import { string } from 'prop-types';
import React, { useState } from 'react';

import LivePostEventComponent from './live-post-event-component';
import PreEventComponent from './pre-event-component';

const EventAnalyticsOverview = ({ eventSlug }) => {
  const [event, setEvent] = useState({});
  const isLoading = usePromise(
    signal => analyticsApi.getEventDetails(eventSlug, signal),
    result => setEvent(result),
  );

  const eventPhase = event?.phase;

  return (
    <Loading isLoading={isLoading}>
      {eventPhase == 'Draft' && <EmptyAnalytics />}
      {eventPhase == 'Upcoming' && (
        <PreEventComponent
          eventSlug={eventSlug}
          eventExternalId={event.external_id}
          registrationCount={event.registration_count}
          eventTimezone={event.standard_timezone}
          eventWizardTemplate={event.event_wizard_template}
          eventAreas={event.areas}
        />
      )}

      {(eventPhase == 'Live' || eventPhase == 'Ended') && (
        <LivePostEventComponent
          eventSlug={eventSlug}
          eventExternalId={event.external_id}
          registrationCount={event.registration_count}
          eventPhase={eventPhase}
          eventTimezone={event.standard_timezone}
        />
      )}
    </Loading>
  );
};

EventAnalyticsOverview.propTypes = {
  eventSlug: string.isRequired,
};

export default EventAnalyticsOverview;
