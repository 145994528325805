import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const MaybeHiddenFlex = styled(Flex)`
  display: ${props => (props.hidden ? 'none' : 'flex')};
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const UsageGuideLink = styled.a`
  text-decoration: none;
`;

const FullUrlInput = ({
  appName,
  name,
  id,
  label,
  initialValue = '',
  errorMessage,
  urlRegex,
  groupName,
  usageGuide = '#',
}) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [url, setUrl] = useState('');

  const changeUrl = useCallback(
    evt => {
      setUrl(evt.target.value);
    },
    [setUrl],
  );

  const changeValue = useCallback(
    evt => {
      setValue(evt.target.value);
    },
    [setValue],
  );

  const verifyURL = useCallback(
    evt => {
      evt.preventDefault();

      try {
        const validation = new RegExp(urlRegex);
        const match = validation.exec(url);

        setValue(match.groups[groupName]);
        setUrl('');
        setHasErrors(false);
      } catch {
        setHasErrors(true);
      }
    },
    [setHasErrors, setValue, groupName, urlRegex, url],
  );

  return (
    <>
      <Text element="h1" mt="2" pattern="captionBold">
        Set up with URL
      </Text>
      <Text mb="1" element="p" pattern="caption">
        Not sure where to find the URL? Check out&nbsp;
        <UsageGuideLink
          href={usageGuide}
          target="_blank"
          rel="noreferrer noopener"
        >
          {`${appName}'s usage guide`}
        </UsageGuideLink>
        .
      </Text>
      <Flex flexDirection="row" alignItems="flex-end">
        <div className="pl-0 col-md-6">
          <TextField
            name={`${name}[url]`}
            label="Configuration URL"
            id={`${id}_url`}
            value={url}
            onChange={changeUrl}
            size="tiny"
          />
        </div>
        <Button
          ml="1"
          size="small"
          disabled={!url}
          isInline
          onClick={verifyURL}
        >
          Use this URL
        </Button>
      </Flex>
      {hasErrors && (
        <Text pattern="caption" color="utility-error">
          {errorMessage}
        </Text>
      )}

      <MaybeHiddenFlex
        aria-hidden={!value}
        hidden={!value}
        mt={0.5}
        className="pl-0 col-md-4"
      >
        <StyledTextField
          name={name}
          label={label}
          id={id}
          value={value}
          onChange={changeValue}
          size="tiny"
        />
      </MaybeHiddenFlex>
    </>
  );
};

FullUrlInput.propTypes = {
  appName: string.isRequired,
  name: string.isRequired,
  usageGuide: string.isRequired,
  id: string.isRequired,
  label: string.isRequired,
  initialValue: string,
  errorMessage: string.isRequired,
  urlRegex: string.isRequired,
  groupName: string.isRequired,
};

export default FullUrlInput;
