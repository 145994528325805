const getClearChatJobResponse = {
  data: {
    id: 'UHYvtTO36bBtdLKkSNQ34zs6L',
    type: 'clear_chat_job',
    attributes: { status: 'succeeded', segment_types: ['event'] },
  },
};

const getClearChatJobErrorResponse = {
  data: {
    id: 'UHYvtTO36bBtdLKkSNQ34zs6L',
    type: 'clear_chat_job',
    attributes: { status: 'failed', segment_types: ['event'] },
  },
};

export { getClearChatJobResponse, getClearChatJobErrorResponse };
