import ConnectionsDashboardComponent from '@features/dashboard/connections-dashboard/connections-dashboard-component';
import { withSegmentPageView } from '@util/analytics/with-segment-page-view';
import { connectViewData, withViewDataProvider } from '@util/page-view-context';
import compose from 'lodash/fp/compose';
import { bool, object, string } from 'prop-types';
import React, { useState } from 'react';

import withReduxProvider from '@/redux/with-provider';

const ConnectionsDashboardContainer = ({ eventPhase, ...props }) => {
  const [currentPhase, setCurrentPhase] = useState(eventPhase);

  return (
    <ConnectionsDashboardComponent
      currentPhase={currentPhase}
      setCurrentPhase={setCurrentPhase}
      {...props}
    />
  );
};

const mapViewDataToProps = data => data;

ConnectionsDashboardContainer.propTypes = {
  event: object.isRequired,
  organization: object.isRequired,
  eventPhase: string.isRequired,
  topAttendeesByNetworkingMeetingsDisabled: bool,
};

export default compose(
  withViewDataProvider,
  connectViewData(mapViewDataToProps),
  withReduxProvider,
  withSegmentPageView('Connections Summary'),
)(ConnectionsDashboardContainer);
