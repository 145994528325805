import useDateFormatters from '@components/bar-chart/hooks/use-date-formatters';
import { array, number, string } from 'prop-types';
import React from 'react';

import BarChart from './bar-chart-container';
import getIntervalUnit from './util/get-interval-unit';
import XAxisTickTimeLabel from './x-axis-tick-time-label';

const sanitizeData = data => {
  if (!data) {
    return [];
  }
  if (Array.isArray(data)) {
    return data;
  }
  Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]));
};

/*
This component is used for displaying data that is organized over time in regular intervals.
ie: data = [[2000-01-01, n], [2000-01-02, n]]
Timestamp formatting logic is handled by XAxisTickTimeLabel.
*/

const xTickLabelRenderer = unit => props => {
  return <XAxisTickTimeLabel unit={unit} {...props} />;
};

const Histogram = ({ data, maxBars, tooltipDescription, height }) => {
  const { formatForTooltip } = useDateFormatters();

  data = sanitizeData(data);

  // unit of time between bars
  const unit = getIntervalUnit(data);

  return (
    <BarChart
      testId="histogram"
      data={data}
      tooltipDescription={tooltipDescription}
      xTickLabelRenderer={xTickLabelRenderer(unit)}
      maxBars={maxBars}
      formatTooltipName={dateString => formatForTooltip(dateString, unit)}
      height={height}
    />
  );
};

Histogram.propTypes = {
  data: array.isRequired,
  maxBars: number.isRequired,
  tooltipDescription: string,
  height: number,
};

export default Histogram;
