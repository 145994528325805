import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

import { timezonesMap } from './timezones-map';

const utcTimezone = { label: '(GMT+00:00) UTC', value: 'UTC' };

const tzList = [
  'International Date Line West',
  'American Samoa',
  'Midway Island',
  'Hawaii',
  'Alaska',
  'Pacific Time (US & Canada)',
  'Tijuana',
  'Arizona',
  'Chihuahua',
  'Mazatlan',
  'Mountain Time (US & Canada)',
  'Central America',
  'Central Time (US & Canada)',
  'Guadalajara',
  'Mexico City',
  'Monterrey',
  'Saskatchewan',
  'Bogota',
  'Eastern Time (US & Canada)',
  'Indiana (East)',
  'Lima',
  'Quito',
  'Atlantic Time (Canada)',
  'Caracas',
  'Georgetown',
  'La Paz',
  'Puerto Rico',
  'Santiago',
  'Newfoundland',
  'Brasilia',
  'Buenos Aires',
  'Greenland',
  'Montevideo',
  'Mid-Atlantic',
  'Azores',
  'Cape Verde Is.',
  'Casablanca',
  'Dublin',
  'Edinburgh',
  'Lisbon',
  'London',
  'Monrovia',
  'UTC',
  'Amsterdam',
  'Belgrade',
  'Berlin',
  'Bern',
  'Bratislava',
  'Brussels',
  'Budapest',
  'Copenhagen',
  'Ljubljana',
  'Madrid',
  'Paris',
  'Prague',
  'Rome',
  'Sarajevo',
  'Skopje',
  'Stockholm',
  'Vienna',
  'Warsaw',
  'West Central Africa',
  'Zagreb',
  'Zurich',
  'Athens',
  'Bucharest',
  'Cairo',
  'Harare',
  'Helsinki',
  'Jerusalem',
  'Kaliningrad',
  'Kyiv',
  'Pretoria',
  'Riga',
  'Sofia',
  'Tallinn',
  'Vilnius',
  'Baghdad',
  'Istanbul',
  'Kuwait',
  'Minsk',
  'Moscow',
  'Nairobi',
  'Riyadh',
  'St. Petersburg',
  'Tehran',
  'Abu Dhabi',
  'Baku',
  'Muscat',
  'Samara',
  'Tbilisi',
  'Volgograd',
  'Yerevan',
  'Kabul',
  'Ekaterinburg',
  'Islamabad',
  'Karachi',
  'Tashkent',
  'Chennai',
  'Kolkata',
  'Mumbai',
  'New Delhi',
  'Sri Jayawardenepura',
  'Kathmandu',
  'Almaty',
  'Astana',
  'Dhaka',
  'Urumqi',
  'Rangoon',
  'Bangkok',
  'Hanoi',
  'Jakarta',
  'Krasnoyarsk',
  'Novosibirsk',
  'Beijing',
  'Chongqing',
  'Hong Kong',
  'Irkutsk',
  'Kuala Lumpur',
  'Perth',
  'Singapore',
  'Taipei',
  'Ulaanbaatar',
  'Osaka',
  'Sapporo',
  'Seoul',
  'Tokyo',
  'Yakutsk',
  'Adelaide',
  'Darwin',
  'Brisbane',
  'Canberra',
  'Guam',
  'Hobart',
  'Melbourne',
  'Port Moresby',
  'Sydney',
  'Vladivostok',
  'Magadan',
  'New Caledonia',
  'Solomon Is.',
  'Srednekolymsk',
  'Auckland',
  'Fiji',
  'Kamchatka',
  'Marshall Is.',
  'Wellington',
  'Chatham Is.',
  "Nuku'alofa",
  'Samoa',
  'Tokelau Is.',
];

// values returned from browser Intl timezone (that reads OS configuration) for generic timezones (no specific city set)
const genericTimezonesMap = {
  Los_Angeles: 'Pacific Time (US & Canada)',
  Denver: 'Mountain Time (US & Canada)',
  Chicago: 'Central Time (US & Canada)',
  New_York: 'Eastern Time (US & Canada)',
  Halifax: 'Atlantic Time (Canada)',
  Lagos: 'West Central Africa',
  Katmandu: 'Kathmandu',
  St_Johns: 'Newfoundland',
  La_Paz: 'La Paz',
  Calcutta: 'Kolkata',
  'GMT-12': 'International Date Line West',
  'GMT-11': 'American Samoa',
  'GMT-10': 'Hawaii',
  'GMT-9': 'Alaska',
  'GMT-8': 'Pacific Time (US & Canada)',
  'GMT-7': 'Mountain Time (US & Canada)',
  'GMT-6': 'Central America',
  'GMT-5': 'Eastern Time (US & Canada)',
  'GMT-4': 'Atlantic Time (Canada)',
  'GMT-3': 'Greenland',
  'GMT-2': 'Mid-Atlantic',
  'GMT-1': 'Casablanca',
  GMT: 'UTC',
  'GMT+1': 'West Central Africa',
  'GMT+2': 'Jerusalem',
  'GMT+3': 'Istanbul',
  'GMT+4': 'Abu Dhabi',
  'GMT+5': 'Ekaterinburg',
  'GMT+6': 'Dhaka',
  'GMT+7': 'Jakarta',
  'GMT+8': 'Beijing',
  'GMT+9': 'Tokyo',
  'GMT+10': 'Brisbane',
  'GMT+11': 'Canberra',
  'GMT+12': 'Kamchatka',
  'GMT+13': 'Fiji',
  'GMT+14': 'Samoa',
};

const timezones = (referenceDate = new Date()) =>
  tzList
    .map(value => {
      const timezone = timezonesMap[value];
      const offset = getTimezoneOffset(timezone, referenceDate);
      const formattedOffset = formatInTimeZone(referenceDate, timezone, 'XXX');
      const label = `(GMT${formattedOffset}) ${value}`
        .trim()
        .replace('GMTZ', 'GMT+00:00');

      return { label, value, offset };
    })
    .sort((a, b) => a.offset - b.offset);

export { genericTimezonesMap, utcTimezone };
export default timezones;
