import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Image } from '@hopin-team/ui-image';
import { calcSpace } from '@hopin-team/ui-symbols';
import {
  Table,
  TableCell,
  TableHeading,
  TableRow,
  TableRowAction,
  TableRowActions,
} from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import compose from 'lodash/fp/compose';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  border-collapse: collapse;
  margin: ${calcSpace(4)} 0;
  width: 100%;
`;

const StyledTableHeading = styled(TableHeading)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
`;

const StyledTableCell = styled(TableCell)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
  word-break: break-word;
`;

const StyledTableRowActions = styled(TableRowActions)`
  padding-right: ${calcSpace(2)};
`;

const Logo = styled(Image)`
  max-height: 2rem;
`;

const WebsiteText = styled(Text)`
  word-break: break-all;
`;

const SponsorsTable = ({
  authenticityToken,
  eventSlug,
  sponsors,
  flags = {},
}) => {
  const { t } = useLocalization('sponsors.table');
  const { addAlert } = useContext(AlertsContext);
  const apiClient = initApiClient(authenticityToken);
  const logger = getLogger(LOGGER_NAMES.SPONSORS_TABLE);

  const handleEdit = sponsorId => {
    window.location.href = Routes.editOrganisersEventSponsorPath({
      event_id: eventSlug,
      id: sponsorId,
      _options: true,
    });
  };

  const handleRemove = async sponsorId => {
    const confirm = window.confirm(t('remove-prompt'));

    if (confirm) {
      const endpoint = Routes.organisersEventSponsorPath({
        event_id: eventSlug,
        id: sponsorId,
        _options: true,
      });

      try {
        await apiClient.del(endpoint, {
          headers: {
            Accept: 'application/json',
          },
        });

        // Refresh the page to request the updated sponsors list.
        // We don't throw an error if we encounter a HTTP error (`!res.ok`)
        // because this logic is handled by the controller action when
        // a request is unsuccessful (i.e. outside of the 2xx range)
        window.location.reload();
      } catch (err) {
        logger.error(err);

        // There was a network error
        addAlert({
          active: true,
          text: t('remove-error-message'),
          pattern: 'error',
        });
      }
    }
  };

  return (
    <>
      <Alerts />

      <StyledTable data-testid="sponsors-table" hasActions isAlternating>
        <thead>
          <TableRow isHeading>
            <StyledTableHeading scope="col">
              <Text pattern="emphasis">{t('logo')}</Text>
            </StyledTableHeading>
            <StyledTableHeading scope="col">
              <Text pattern="emphasis">{t('name')}</Text>
            </StyledTableHeading>
            <StyledTableHeading scope="col">
              <Text pattern="emphasis">{t('website')}</Text>
            </StyledTableHeading>
            {flags.showPriority ? (
              <StyledTableHeading scope="col">
                <Text pattern="emphasis">{t('priority')}</Text>
              </StyledTableHeading>
            ) : null}
          </TableRow>
        </thead>

        <tbody>
          {sponsors.map(({ id, logo, name, website, priority }) => (
            <TableRow key={id}>
              <StyledTableCell data-testid="sponsor-logo">
                <Logo src={logo} alt="" />
              </StyledTableCell>
              <StyledTableCell data-testid="sponsor-name">
                <Text pattern="body" color="grey-800">
                  {name}
                </Text>
              </StyledTableCell>
              <StyledTableCell data-testid="sponsor-website">
                <WebsiteText pattern="body" color="grey-800">
                  {website}
                </WebsiteText>
              </StyledTableCell>
              {flags.showPriority ? (
                <StyledTableCell>
                  <Text pattern="body" color="grey-800">
                    {priority}
                  </Text>
                </StyledTableCell>
              ) : null}
              <StyledTableRowActions>
                <TableRowAction
                  icon="edit"
                  title={t('edit')}
                  onClick={() => handleEdit(id)}
                />
                <TableRowAction
                  icon="delete"
                  title={t('remove')}
                  onClick={() => handleRemove(id)}
                />
              </StyledTableRowActions>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

SponsorsTable.propTypes = {
  authenticityToken: string.isRequired,
  eventSlug: string.isRequired,
  sponsors: arrayOf(
    shape({
      id: number.isRequired,
      logo: string.isRequired,
      name: string.isRequired,
      website: string.isRequired,
    }).isRequired,
  ).isRequired,
  flags: shape({
    showPriority: bool,
  }),
};

export default compose(
  withLocalizationProvider,
  withAlertsProvider,
)(SponsorsTable);
