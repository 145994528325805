export const VIDEO_EDIT_STATUSES = {
  success: 'success',
  failure: 'failure',
  processing: 'processing',
  trimmingRequested: 'trimming_requested',
};

export const EVENT_PART = {
  STAGE: 'stage',
  SESSION: 'session',
};

export const AREA_TYPE = {
  STAGE: 'stage',
  SESSION: 'session',
};

export const RECORDING_CATEGORY = {
  MAIN: 'main',
  BACKUP: 'backup',
  REHEARSAL: 'rehearsal',
  CUT: 'cut',
};

export const MAX_TITLE_LENGTH = 300;

export const HOURS_FOR_REPLAY_PROCESSING = 2;

export const SECONDS_IN_ONE_MINUTE = 60;

export const SECONDS_IN_ONE_HOUR = 60 * SECONDS_IN_ONE_MINUTE;

/**
 * the order of the links needs to correspond to the order of the tabs in
 * app/javascript/react/features/recordings/recording-type-tab-header/recording-type-tab-header.js
 */
export const TAB_NAVIGATION_LINKS = ['/', '/cuts'];
