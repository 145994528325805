import { bool, string } from 'prop-types';
import React from 'react';
import { Transition } from 'react-transition-group';

import { AbsolutePositionedText } from '../styles';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const FadeInOutText = ({ text, inProp }) => {
  return (
    <Transition in={inProp} timeout={duration}>
      {state => (
        <AbsolutePositionedText
          m={2}
          pattern="subHeading"
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {text}
        </AbsolutePositionedText>
      )}
    </Transition>
  );
};

FadeInOutText.propTypes = {
  text: string,
  inProp: bool,
};

export default FadeInOutText;
