import { LocalizationContext } from '@features/localization';
import { bool, node } from 'prop-types';
import React, { useContext } from 'react';

import HopinLogo from '../icons/hopin-logo';
import { Background, Container, PoweredBy } from './printable.styled';

const PrintableComponent = ({ printMode, children }) => {
  const { t } = useContext(LocalizationContext);
  const containerClass = printMode ? 'open' : 'closed';

  return (
    <Background className={containerClass}>
      <Container className={containerClass}>
        {children}
        {printMode && (
          <PoweredBy>
            {t('event-dashboard.data-powered-by')} <HopinLogo />
          </PoweredBy>
        )}
      </Container>
    </Background>
  );
};

PrintableComponent.propTypes = {
  printMode: bool.isRequired,
  children: node,
};

export default PrintableComponent;
