import { rootStyles as visuallyHiddenStyles } from '@hopin-team/ui-visually-hidden/dist/styled/root';
import styled, { css } from 'styled-components';

/* Exclude legacy browsers. Generally support for `:focus-within` overlaps with
 * support for `pointer-events` on inputs and inside range-inputs’ shadow DOM /
 * pseudo-elements, so we can use :focus-within as a proxy for pointer-events in
 * pseudos (it’s not possible to feature-test this for pseudos using @supports) */
export const excludeLegacyBrowsersSelector = css`&:focus-within, &:not(:focus-within)`;

export const VisuallyHiddenLabel = styled.label`
  margin: 0.5rem 0 0;
  &${excludeLegacyBrowsersSelector} {
    ${visuallyHiddenStyles}
  }
`;
