import { bool, func, node, string } from 'prop-types';
import React from 'react';

import PrintableComponent from './printable-component';

const PrintableContainer = React.forwardRef(
  ({ printMode, exitPrintMode, pageTitle, children }, ref) => {
    if (printMode) {
      const existingTitle = document.title;
      if (pageTitle) {
        document.title = pageTitle;
      }

      // let the initial render happen,
      // all the window dressings will be gone
      // and children will be only thing on screen
      // after then, initiate the print dialog
      setTimeout(() => {
        // move printable node to body
        const printableWrapper = ref.current;
        let printableElements = printableWrapper.firstChild;
        document.body.appendChild(printableElements);

        window.print();
        // print dialog will block the javascript main thead
        // any code following window.print() will fire
        // after the dialog is closed.
        // we use this to our advantage by returning the user back to
        // the regular dashboard after print dialog is closed.

        // move printable node to its original location
        printableWrapper.appendChild(printableElements);
        document.title = existingTitle;

        exitPrintMode();
      }, 1);
    }

    return (
      <div ref={ref}>
        <PrintableComponent printMode={printMode} exitPrintMode={exitPrintMode}>
          {children}
        </PrintableComponent>
      </div>
    );
  },
);

// Allow PrintableContainer to access DOM node so the printable
// node can be appended directly to the body.
// This allows printable content to render correctly irrespective of changes
// elsewhere in the DOM.
class PrintableContainerWithRef extends React.Component {
  childRef = React.createRef();
  render = () => <PrintableContainer ref={this.childRef} {...this.props} />;
}

PrintableContainer.propTypes = {
  printMode: bool.isRequired,
  exitPrintMode: func.isRequired,
  pageTitle: string,
  children: node,
};

export default PrintableContainerWithRef;
