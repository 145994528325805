import { routes } from '@features/event-creation-wizard/constants/step-map';
import { useLocalization } from '@features/localization';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

const EventType = ({
  eventType,
  register,
  onChange,
  isOnFreePlan,
  errors,
  localizationPrefix,
}) => {
  const { t } = useLocalization(
    localizationPrefix || 'event-creation-wizard.steps.details.form',
  );

  const options = [
    {
      value: 'hidden_event',
      label: t('event-type.options.hidden_event'),
      help: t('event-type.options.hidden_help'),
    },
    {
      value: 'public_event',
      label: t('event-type.options.public_event'),
      help: t('event-type.options.public_help'),
    },
    {
      value: 'private_event',
      label: t('event-type.options.private_event'),
      help: t('event-type.options.private_help'),
    },
  ];

  return (
    <>
      <Select
        label={t('event-type.label')}
        placeholder={t('event-type.placeholder')}
        options={options}
        value={eventType}
        setValue={onChange}
        disabled={isOnFreePlan}
        helperText={
          isOnFreePlan ? (
            <>
              {t('event-type.helper-text')}{' '}
              <a href={routes.billing()} target="_blank" rel="noreferrer">
                {t('event-type.upgrade-plan')}
              </a>
            </>
          ) : (
            options.find(({ value }) => value === eventType)?.help
          )
        }
        mt={3}
        mb={2}
      />
      <input hidden name="eventType" ref={register} />

      {eventType === 'private_event' ? (
        <>
          <TextField
            label={t('password.label')}
            name="eventPassword"
            ref={register}
            hasErrors={!!errors?.eventPassword}
            errorMessage={errors?.eventPassword?.message}
            autocomplete="new-password"
            size="small"
          />
          <Text
            pattern="labelThree"
            mt={1}
            style={{
              display: 'inline-block',
            }}
          >
            {t('password.invitedUsers')}
          </Text>
        </>
      ) : (
        <input hidden name="eventPassword" ref={register} />
      )}
    </>
  );
};

EventType.propTypes = {
  eventType: string,
  register: func,
  onChange: func,
  isOnFreePlan: bool,
  errors: object,
  localizationPrefix: string,
};

export default EventType;
