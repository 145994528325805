import {
  blue100,
  blue500,
  gray100,
  gray300alpha05,
  white,
} from '@features/dashboard/util/colors';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding-bottom: 2.5rem;
`;

export const TabContainer = styled.div`
  border: 1px solid ${({ selected }) => (selected ? blue100 : gray100)};
  background: ${({ selected }) => (selected ? gray300alpha05 : white)};
  border-radius: 4px;
  padding: 1em;
  outline: none;
  margin-top: 0.75rem;

  h2 {
    margin-bottom: 0;
  }
`;

export const TabsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 3rem;
`;

export const IconContainer = styled.div`
  background: ${blue500};
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  div {
    height: 1rem;
    width: 1rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  h6 {
    margin-bottom: 0;
    font-size: 1rem;
  }
`;
