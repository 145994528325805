import Loading from '@components/loading';
import { withSlicesProvider } from '@components/slices/withSlicesProvider';
import VideoPlayer from '@components/video-player';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import {
  deleteRecordingThunk,
  fetchRecording,
  updateRecording as updateRecordingAction,
  updateRecordingThunk,
} from '@features/recordings/redux/slices/recordings/recordings-slice';
import { selectTranscriptIsShowing } from '@features/recordings/redux/slices/transcript/transcript-selectors';
import { setIsShowing as setIsTranscriptShowing } from '@features/recordings/redux/slices/transcript/transcript-slice';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { Flex } from '@hopin-team/ui-flex';
import { Linkicon } from '@hopin-team/ui-linkicon';
import { IndeterminateProgressOverlay } from '@hopin-team/ui-recording-card';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectToken } from '../redux/slices/auth/auth-selectors';
import { withAuthProvider } from '../redux/slices/auth/with-auth';
import { selectEvent } from '../redux/slices/event/event-selectors';
import { withEventProvider } from '../redux/slices/event/with-event';
import { recordingsActions } from '../redux/slices/recordings/recordings-actions';
import {
  getOriginalRecordingById,
  getRecordingById,
  selectRecordingIsLoaded,
} from '../redux/slices/recordings/recordings-selectors';
import { withRegistrationProvider } from '../redux/slices/registration/with-registration';
import { withUserProvider } from '../redux/slices/user/with-user';
import { withReduxProvider } from '../redux/with-provider';
import { getRecording } from '../services/recordingService';
import { ThumbnailSidePanelModal } from '../thumbnail-side-panel-modal';
import TileView from '../tile-view';
import {
  getGoBackLink,
  hasRecordingFailed,
  hasRecordingSucceededOnTrimming,
} from '../utils/utils';
import {
  MainColumn,
  RelatedRecordingContainer,
  Skeleton,
} from './detailed-page.styled';
import { Message } from './partials/message';
import PublishControls from './partials/publish-controls';
import { RecordingControls } from './partials/recording-controls';
import { SidebarContainer } from './partials/sidebar/sidebar-container';
import { Transcript } from './partials/transcript';

const NUMBER_OF_RELATED_RECORDINGS_TO_SHOW = 4;

export const DetailedPageComponent = ({
  id,
  flags: { customThumbnailsEnabled, transcriptsEnabled },
}) => {
  const dispatch = useDispatch();
  const { t, language } = useContext(LocalizationContext);
  const { addAlert } = useContext(AlertsContext);
  const { slug } = useSelector(selectEvent);
  const token = useSelector(selectToken);
  const history = useHistory();
  // State
  const [isEditUiOpen, setIsEditUiOpen] = useState(false);
  const [isThumbnailUiOpen, setIsThumbnailUiOpen] = useState(false);
  const [isDeleteUiOpen, setIsDeleteUiOpen] = useState(false);

  const [
    isHiddenTrimmingSuccessMessage,
    setIsHiddenTrimmingSuccessMessage,
  ] = useState(false);

  // Selectors
  const recording = useSelector(state => getRecordingById(state, id, language));
  const originalRecording = useSelector(state =>
    getOriginalRecordingById(state, id),
  );
  const isTranscriptShowing = useSelector(selectTranscriptIsShowing);
  const recordingIsLoaded = useSelector(state =>
    selectRecordingIsLoaded(state, id, language),
  );

  useEffect(() => {
    if (!recordingIsLoaded) {
      dispatch(fetchRecording({ id }));
    }
  }, [dispatch, id, recordingIsLoaded]);

  const relatedRecordings = recording?.related?.length
    ? recording.related.slice(0, NUMBER_OF_RELATED_RECORDINGS_TO_SHOW)
    : [];

  const handleRecordingChange = attributes => {
    dispatch(
      updateRecordingThunk({
        attributes,
        recording,
        onError: () =>
          addAlert({
            active: true,
            text: t('recording-group.something-went-wrong-try-later'), // update
            pattern: 'error',
          }),
      }),
    );
  };

  const onRecordingClick = useCallback(
    recording =>
      (window.location.href = Routes.organisersEventRecordingPath(
        slug,
        recording.id,
      )),
    [slug],
  );

  const handleReportDownloadFromButton = useCallback(() => {
    dispatch(
      recordingsActions.detailedPage.recordingControls.recordingDownloaded({
        recording: originalRecording,
        method: 'Recording',
      }),
    );
  }, [dispatch, originalRecording]);

  const handleReportDownloadFromRelated = useCallback(
    recording => {
      dispatch(
        recordingsActions.detailedPage.relatedRecordings.recordingDownloaded({
          recording,
          method: 'Suggested',
        }),
      );
    },
    [dispatch],
  );

  const redirectToMain = useCallback(() => {
    window.location.href = Routes.organisersEventRecordingsPath(slug);
  }, [slug]);

  const showConfirmationPopup = useCallback(() => setIsDeleteUiOpen(true), [
    setIsDeleteUiOpen,
  ]);

  const deleteRecordingHandler = useCallback(async () => {
    setIsDeleteUiOpen(false);

    await dispatch(
      deleteRecordingThunk({
        id: recording.id,
        onSuccess: () => {
          addAlert({
            active: true,
            text: t('recording-group.recording-successfully-deleted'),
            pattern: 'success',
          });
          setTimeout(redirectToMain, 1000);
        },
        onError: () =>
          addAlert({
            active: true,
            text: t('recording-group.something-went-wrong-try-later'),
            pattern: 'error',
          }),
      }),
    );
  }, [dispatch, recording?.id, t, addAlert, redirectToMain]);

  const onSidebarUpdateSuccess = useCallback(() => {
    addAlert({
      active: true,
      text: t('recording-library.recording-details-were-updated-successfully'),
      pattern: 'success',
    });
    setIsEditUiOpen(false);
  }, [addAlert, t]);

  const onSidebarUpdateError = useCallback(() => {
    addAlert({
      active: true,
      text: t('recording-library.something-went-wrong-try-later'),
      pattern: 'error',
    });
    setIsEditUiOpen(false);
  }, [addAlert, t]);

  const goToCuts = useCallback(() => history.push(`${id}/cuts`), [history, id]);

  const onViewTranscript = useCallback(() => {
    dispatch(setIsTranscriptShowing(!isTranscriptShowing));
  }, [dispatch, isTranscriptShowing]);

  const onCloseTranscript = useCallback(() => {
    dispatch(setIsTranscriptShowing(false));
  }, [dispatch]);

  const reloadSource = async () => {
    const newRecording = await getRecording(recording.id, token);
    dispatch(updateRecordingAction({ playableUrl: newRecording.playableUrl }));
    return newRecording.playableUrl;
  };

  return (
    <Flex flexDirection="column">
      <Alerts />
      <Loading isLoading={!recordingIsLoaded}>
        {recordingIsLoaded ? (
          <>
            <Flex flexDirection="column">
              {hasRecordingSucceededOnTrimming(recording) && (
                <Message
                  type="success"
                  hidden={isHiddenTrimmingSuccessMessage}
                  onClose={() => setIsHiddenTrimmingSuccessMessage(true)}
                />
              )}

              {hasRecordingFailed(recording) && <Message type="error" />}

              <Flex flexDirection="row" mb={2}>
                <MainColumn>
                  <Linkicon
                    data-testid="backLink"
                    iconScale={1.5}
                    scale={4}
                    label={t('recording-library.back')}
                    leadingIcon="arrow-back"
                    href={getGoBackLink(slug, recording.category)}
                    mb="2"
                    mt="4"
                  />

                  <VideoPlayer
                    src={recording.playableUrl}
                    forcePause={recording.isProcessing}
                    reloadSource={reloadSource}
                  >
                    {recording.isProcessing ? (
                      <IndeterminateProgressOverlay
                        aria-valuetext={t('recording-library.processing')}
                      />
                    ) : null}
                  </VideoPlayer>
                </MainColumn>

                {transcriptsEnabled && isTranscriptShowing && (
                  <Transcript onClose={onCloseTranscript} />
                )}
              </Flex>
              <Flex
                flexGrow={1}
                my={2}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <RecordingControls
                  customThumbnailsEnabled={customThumbnailsEnabled}
                  onChangeThumbnail={() => setIsThumbnailUiOpen(true)}
                  onDelete={showConfirmationPopup}
                  onDownload={handleReportDownloadFromButton}
                  onEdit={() => setIsEditUiOpen(true)}
                  onRecordingChange={handleRecordingChange}
                  onCreateCuts={goToCuts}
                  recording={recording}
                  transcriptsEnabled={transcriptsEnabled}
                  onViewTranscript={onViewTranscript}
                />
                <PublishControls
                  recording={recording}
                  onChange={handleRecordingChange}
                />
              </Flex>

              <ConfirmationDialog
                size="tiny"
                isShowing={isDeleteUiOpen}
                title={t('delete-recording-confirmation-modal.title')}
                message={t('delete-recording-confirmation-modal.message')}
                primaryButtonText={t(
                  'delete-recording-confirmation-modal.confirm',
                )}
                colorPattern="danger"
                secondaryButtonText={t(
                  'delete-recording-confirmation-modal.cancel',
                )}
                onConfirm={deleteRecordingHandler}
                onCancel={() => setIsDeleteUiOpen(false)}
              />

              {relatedRecordings?.length > 0 && (
                <Flex flexDirection="column" data-testid="related-recordings">
                  <Text pattern="headingFour" element="h3" my={1}>
                    {recording.eventPartName
                      ? t('recording-library.other-videos-from-with-name', {
                          type: t(
                            `recording-library.event-parts.${recording.eventPartType}`,
                          ),
                          name: recording.eventPartName,
                        })
                      : t('recording-library.other-videos-from', {
                          type: t(
                            `recording-library.event-parts.${recording.eventPartType}`,
                          ),
                        })}
                  </Text>
                  <RelatedRecordingContainer>
                    <TileView
                      recordings={relatedRecordings}
                      onRecordingClick={onRecordingClick}
                      isShowingDetails={false}
                      onRecordingDownload={handleReportDownloadFromRelated}
                    />
                  </RelatedRecordingContainer>
                </Flex>
              )}
            </Flex>
            <SidebarContainer
              recording={recording}
              isShowing={isEditUiOpen}
              onSuccess={onSidebarUpdateSuccess}
              onError={onSidebarUpdateError}
              onClose={() => setIsEditUiOpen(false)}
            />
            {customThumbnailsEnabled && (
              <ThumbnailSidePanelModal
                isShowing={isThumbnailUiOpen}
                onClose={() => setIsThumbnailUiOpen(false)}
                recording={recording}
              />
            )}
          </>
        ) : (
          <Skeleton />
        )}
      </Loading>
    </Flex>
  );
};

DetailedPageComponent.propTypes = {
  id: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    customThumbnailsEnabled: PropTypes.bool.isRequired,
    transcriptsEnabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export const DetailedPage = compose(
  withAlertsProvider,
  withLocalizationProvider,
  withReduxProvider,
  withAuthProvider,
  withEventProvider,
  withUserProvider,
  withRegistrationProvider,
  withSlicesProvider,
)(DetailedPageComponent);
