import { jobStatuses } from '../constants';

export function getNewEventSetupAlert(payload, eventName, t) {
  const { job_status } = payload;
  let pattern;
  let text;
  if (job_status === jobStatuses.FAILED) {
    pattern = 'warning';
    text = t('templates.buildAlerts.warning', { eventName });
  } else {
    pattern = 'success';
    text = t('templates.buildAlerts.success', { eventName });
  }

  return {
    active: true,
    pattern,
    text,
  };
}
