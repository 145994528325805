import analyticsApi from '@api/analytics';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { reportRequested } from '@/redux/reducers/reports';

export function useAlertingReportRequest(eventId) {
  const dispatch = useDispatch();
  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization('reports.alert');

  return useCallback(
    async (apiRequest, report) => {
      try {
        const report = await apiRequest();
        dispatch(reportRequested(report));
      } catch (error) {
        if (error?.response?.status === 401) {
          window.location.assign(
            '/users/sign_in?redirect_url=' + window.location.pathname,
          );
        } else {
          addAlert({
            pattern: 'error',
            status: 'failed',
            text: (
              <span>
                <strong>{report.title}</strong> {t('failed')}{' '}
                <button
                  onClick={() =>
                    analyticsApi.regenerateReport(eventId, {
                      report_id: report.id,
                    })
                  }
                >
                  {t('click-here')} {t('retry')}
                </button>
              </span>
            ),
          });
        }
      }
    },
    [addAlert, dispatch, eventId, t],
  );
}
