import CKEditor5 from '@components/ckeditor5/classic-editor';
import { Label } from '@components/inputs';
import {
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { Grid } from '@hopin-team/ui-grid';
import { calcSpace } from '@hopin-team/ui-symbols';
import { TextField } from '@hopin-team/ui-text-field';
import compose from 'lodash/fp/compose';
import { func, shape, string } from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { EmailFormWrapper } from './styles';

const EmailForm = ({ t, template }) => {
  const { register, setValue, errors, setError } = useFormContext();

  const handleCKEditorChange = editor => {
    const data = editor.getData();
    setValue('body', data);
    !data.length &&
      setError('body', {
        message: `${t('edit.headers.body')} ${t(
          'edit.errors.minimum-character',
        )}`,
      });
  };

  const maxFileSize = 10485760;

  return (
    <>
      <EmailFormWrapper data-testid="editor-container">
        <>
          <h4>
            {t('edit.headers.edit-email')} - {template.name}
          </h4>
          <Label type="text">{t('edit.body.tooltip')}</Label>
          <Grid
            gridColumnGap={2}
            gridGap={[calcSpace(2), calcSpace(3)]}
            my={3}
            gridTemplateColumns="repeat(2, 1fr)"
            gridTemplateRows="auto"
          >
            <TextField
              errorMessage={errors.subject?.message}
              hasErrors={errors.subject?.message}
              data-testid="email-subject"
              label={t('edit.headers.subject')}
              name="subject"
              ref={register({
                required: `${t('edit.headers.subject')} ${t(
                  'edit.errors.minimum-character',
                )}`,
              })}
              type="text"
              placeholder={t('edit.subject-placeholder')}
            />
            <Grid gridColumn="1/3">
              <Label name="body" type="text">
                {t('edit.headers.body')}
              </Label>
              <CKEditor5
                name="email-template-body"
                initialValue={template.body}
                onChange={(_, editor) => handleCKEditorChange(editor)}
                height={35}
                maxFileSize={maxFileSize}
                uploadS3PolicyUrl={`${window.location.origin}/account/upload_s3_policy`}
              />
              {errors.body?.message && (
                <FieldErrorMessage py={1} errorMessage={errors.body.message} />
              )}

              <input
                hidden
                data-testid="email-body"
                name="body"
                ref={register({})}
              />
            </Grid>
          </Grid>
        </>
      </EmailFormWrapper>
    </>
  );
};

EmailForm.propTypes = {
  t: func,
  template: shape({
    body: string,
    footer: string,
    name: string,
  }),
};

export default compose(withLocalizationProvider, withLocalization)(EmailForm);
