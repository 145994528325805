import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@components/table';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

import { DuplicateModal } from './duplicate-modal';
import EventRow from './event-row';

const EventsList = ({
  t,
  currentColor,
  events = [],
  isFreePlan,
  freeRegistrationsLimit,
  trackAmplitudeEvent,
}) => {
  const translationKeyPrefix =
    'onboarding.organization-dashboard.event-table-headers';

  const [modalVisible, setModalVisible] = useState(false);

  const handleConfirmDuplicateModal = useCallback(
    () => setModalVisible(false),
    [],
  );

  const handleEventNotDuplicatable = useCallback(
    () => setModalVisible(true),
    [],
  );

  return (
    <div data-testid="events-list">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>
              {t(`${translationKeyPrefix}.name`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`${translationKeyPrefix}.dates`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`${translationKeyPrefix}.registrations`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`${translationKeyPrefix}.status`)}
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {events.map(event => {
            return (
              <EventRow
                t={t}
                currentColor={currentColor}
                key={event.id}
                eventId={event.id}
                eventExternalId={event.external_id}
                name={event.name}
                picture={event.picture}
                eventSlug={event.slug}
                dateRange={event.date_range}
                registrationsCount={event.registration_count}
                initialStatus={event.status_label.toLowerCase()}
                isFreePlan={isFreePlan}
                freeRegistrationsLimit={freeRegistrationsLimit}
                trackAmplitudeEvent={trackAmplitudeEvent}
                onEventNotDuplicatable={handleEventNotDuplicatable}
              />
            );
          })}
        </TableBody>
      </Table>
      <DuplicateModal
        isModalDisplayed={modalVisible}
        handleConfirm={handleConfirmDuplicateModal}
      />
    </div>
  );
};

EventsList.propTypes = {
  t: func.isRequired,
  currentColor: string,
  trackAmplitudeEvent: func,
  events: arrayOf(
    shape({
      id: number,
      external_id: string,
      name: string,
      picture: string,
      slug: string,
      date_range: string,
      registration_count: number,
      status: string,
    }),
  ),
  isFreePlan: bool.isRequired,
  freeRegistrationsLimit: number.isRequired,
};

export default EventsList;
