import { FOOTER_HEIGHT } from '@features/event-creation-wizard/components/footer/styles';
import { rem } from 'polished';
import styled from 'styled-components';

const TOP_BAR_HEIGHT = 68;

const TopBar = styled.div`
  position: fixed;
  width: 100%;
  height: ${rem(TOP_BAR_HEIGHT)};
  z-index: 10;
  display: flex;
  align-items: center;
  padding: var(--spacing-8) var(--spacing-24);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-300);
`;

const Container = styled.main`
  min-height: 100vh;
  display: flex;
  position: relative;
  padding: ${rem(TOP_BAR_HEIGHT)} 0 ${rem(FOOTER_HEIGHT)};
`;

const LeftPanel = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 ${rem(420)};
  padding: var(--spacing-40) ${rem(60)} var(--spacing-32) ${rem(60)};

  @media (max-width: 450px) {
    width: 100%;
    padding: var(--spacing-16) var(--spacing-32);
  }
`;

const RightPanel = styled.section`
  position: relative;
  flex: 2 1 auto;
  padding: var(--spacing-24);
`;

export { TopBar, Container, LeftPanel, RightPanel };
