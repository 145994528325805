import EventAreas from '@features/event-creation-wizard/components/event-areas';
import Footer from '@features/event-creation-wizard/components/footer';
import ImagePanel from '@features/event-creation-wizard/components/image-panel';
import MissingFeature from '@features/event-creation-wizard/components/missing-feature';
import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import {
  routes,
  stepMap,
} from '@features/event-creation-wizard/constants/step-map';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { setEventLocationType } from '@features/event-creation-wizard/redux/event-location/event-location-slice';
import { setTemplate } from '@features/event-creation-wizard/redux/step-specific-state/template-slice';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import { useLocalization } from '@features/localization';
import { Badge } from '@hopin-team/ui-badge';
import { Butticon } from '@hopin-team/ui-butticon';
import { Text } from '@hopin-team/ui-text';
import segmentTrack from '@util/analytics/segment-client';
import { string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, LeftPanel, RightPanel, TopBar } from './styles';

const PreviewComponent = ({ templateId, eventFormat }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t } = useLocalization('event-creation-wizard');
  const template = templates[templateId];

  const { id: orgId, subscriptionPlanTier, isOnFreePlan } = useSelector(
    state => state.org,
  );

  const selectTemplate = () => {
    segmentTrack(EVENT.SETUP_STARTED_FROM_PREVIEW, {
      organiser_id: orgId,
      event_type: templateId,
      organisation_subscription_tier: subscriptionPlanTier,
    });

    const template = templates[templateId];

    dispatch(setTemplate(templateId));
    dispatch(setVenue({ venue: template.defaultVenue, isOnFreePlan }));
    dispatch(setEventLocationType(eventFormat));

    const { name: firstStepName } = template.steps[0];
    push(stepMap[firstStepName].path(templateId, eventFormat));
  };

  const handleBack = () => {
    dispatch(setEventLocationType(eventFormat));
    push(routes.templatePicker(eventFormat));
  };

  const tags = template.tags;
  const previewImages = template.preview.images;

  const { image1x, image2x, image3x, image4x } = previewImages;
  const srcSet = `${image1x} 1x, ${image2x} 2x, ${image3x} 3x ${image4x} 4x`;

  return (
    <>
      <TopBar>
        <Butticon
          onClick={handleBack}
          icon="arrow-back"
          size="medium"
          p={1.75}
          aria-label={t('preview.back')}
          id="back-button"
        />
        <Text
          element="label"
          htmlFor="back-button"
          pattern="subHeadingMedium"
          color="grey-900"
          ml={1}
        >
          {t('preview.back')}
        </Text>
      </TopBar>
      <Container>
        <LeftPanel>
          <div>
            <Text
              element="h1"
              pattern="headingTwo"
              weight="medium"
              color="grey-800"
              mb={0.5}
            >
              {t(`templates.${templateId}.title`)}
            </Text>
            <Text element="p" pattern="body" color="grey-700" mb={3}>
              {t(`templates.${templateId}.preview`)}
            </Text>
            {tags.map(tag => (
              <Badge
                key={tag}
                label={t(`tags.${tag}`)}
                priority="low"
                colorPattern="informational"
                mr={1}
                mb={4}
              />
            ))}
            <EventAreas templateId={templateId} />
          </div>
          <MissingFeature templateId={templateId} />
        </LeftPanel>
        <RightPanel>
          <ImagePanel alt={t('preview.image-alt')} padding={24}>
            <img src={image1x} srcSet={srcSet} alt={t('preview.image-alt')} />
          </ImagePanel>
        </RightPanel>
      </Container>
      <Footer handleCta={selectTemplate} ctaLabel={t('preview.start')} />
    </>
  );
};

PreviewComponent.propTypes = {
  templateId: string,
  eventFormat: string,
};

export default PreviewComponent;
