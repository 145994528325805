import ImageDropzone from '@components/dropzone/image-dropzone';
import { useLocalization } from '@features/localization';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import * as P from 'prop-types';
import React from 'react';

const SessionsImageInput = ({
  onDrop,
  onDropError,
  onRemove,
  pictureUrl,
  errorMessage,
}) => {
  const { t } = useLocalization(
    'event-creation-wizard.steps.sessions.form.image',
  );
  const maxFileSize = 2 * 1024 ** 2;

  return (
    <div>
      <FieldLabel fieldId="image" label={t('label')} mb={1} />
      <ImageDropzone
        placeholderImage={pictureUrl}
        onDrop={onDrop}
        onDropError={onDropError}
        onRemove={onRemove}
        name="image"
        height="200px"
        maxFileSize={maxFileSize}
        imagePreviewFit="cover"
        inputId="image"
      >
        <Icon name="image" color="grey-800" scale={3} mb={1.5} />

        <div>
          <Text color="blue-500" pattern="labelTwo">
            {t('choose-file')}
          </Text>{' '}
          <Text color="grey-800" pattern="labelThree">
            {t('or-drag-here')}
          </Text>
        </div>

        <Text
          color="grey-600"
          element="p"
          mb={0.5}
          mt={1.25}
          pattern="labelThree"
        >
          {t('max-upload-size')}
        </Text>
        <Text color="grey-600" element="p" pattern="labelThree">
          {t('recommended-dimensions')}
        </Text>
      </ImageDropzone>

      {errorMessage && <FieldErrorMessage mt={1} errorMessage={errorMessage} />}
    </div>
  );
};

SessionsImageInput.propTypes = {
  onDrop: P.func.isRequired,
  onDropError: P.func.isRequired,
  onRemove: P.func.isRequired,
  pictureUrl: P.string,
  errorMessage: P.string,
};

export default SessionsImageInput;
