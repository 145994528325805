import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

export const StyledAlertWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${calcSpace(2)};
  width: max-content;
`;

export const AlertsList = styled.div`
  position: fixed;
  top: ${calcSpace(9)};
  left: 0;
  width: 100%;
  z-index: 1010;
`;
