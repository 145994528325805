import { Flex } from '@hopin-team/ui-flex';
import { rem } from 'polished';
import styled from 'styled-components';

export const EventDateWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: ${rem(24)};
  margin-bottom: ${rem(16)};

  @media (min-width: 680px) {
    flex-direction: row;
    margin-bottom: ${rem(24)};
  }
`;

export const LabelledDate = styled.p`
  flex-grow: 1;
  padding-right: ${rem(16)};
  color: ${({ isGrayedOut }) =>
    isGrayedOut ? 'var(--color-gray-500)' : 'var(--color-gray-900)'};
  span {
    display: block;
    text-transform: uppercase;
    font-size: ${rem(10)};
    line-height: 1.2;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    font-weight: 700;
  }

  & + & {
    border-left: 1px solid #f1f2f5;
    padding-left: ${rem(24)};
  }
`;

export const Linkicon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray-300);
  border-radius: ${rem(4)};
  padding: ${rem(8)};
  margin-top: ${rem(16)};

  svg {
    pointer-events: none;
  }

  .add-to-cal-link__label {
    margin-left: ${rem(6)};
    color: var(--color-gray-800);
    font-weight: 500;
  }

  @media (min-width: 680px) {
    margin-top: 0;
    width: ${rem(48)};
    height: ${rem(48)};
    align-self: center;

    .add-to-cal-link__icon {
      height: ${rem(20)};
      width: ${rem(20)};
    }

    .add-to-cal-link__label {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
`;
