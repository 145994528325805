import { DeprecatedSelect as Select } from '@hopin-team/ui-select';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  margin-top: ${rem(4)};

  .preview-select__control {
    border: 1px solid var(--color-gray-300);
    background-color: var(--color-white);
    box-shadow: none;
  }

  .preview-select__control--is-focused {
    box-shadow: 0 0 ${rem(1)} ${rem(1)} var(--color-primary-400) !important;
  }

  .preview-select__placeholder {
    color: var(--grey-800);
    font-weight: 400;
  }

  input.focus-visible {
    box-shadow: none;
  }
`;
