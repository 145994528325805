import PrintIcon from '@components/icons/print';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { COLOR, LINE } from '@hopin-team/ui-symbols';
import { bool, func, object, oneOfType, string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

const PrintHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const WhiteButton = styled(Button)`
  width: 100px;
  background-color: ${COLOR.GREY.WHITE};
  border: ${LINE.DEFAULT_BORDER};
  color: ${COLOR.GREY[700]};
  border-radius: 4px;
  margin-left: auto;
  &:hover {
    border: ${LINE.ACTIVE_BORDER};
    background-color: ${COLOR.GREY.WHITE};
    color: ${COLOR.GREY[700]};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const PrintHeaderComponent = ({ triggerExport, titleComponent, visible }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <PrintHeader>
      {titleComponent}
      {visible && (
        <WhiteButton data-testid="print-header-button" onClick={triggerExport}>
          <PrintIcon />
          {t('event-dashboard.print')}
        </WhiteButton>
      )}
    </PrintHeader>
  );
};

PrintHeaderComponent.propTypes = {
  triggerExport: func.isRequired,
  titleComponent: oneOfType([object, string]),
  visible: bool.isRequired,
};

export default PrintHeaderComponent;
