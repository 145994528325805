import { LocalizationContext } from '@features/localization';
import { bool, func } from 'prop-types';
import React, { useContext } from 'react';

import { ConfirmModal } from './confirm-modal';

const DeleteEmailConfirmationModal = ({
  isModalDisplayed,
  handleClose,
  confirmDelete,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ConfirmModal
      label="Delete Email Modal"
      title={t(`email-dashboard.delete.confirmation`)}
      message={t(`email-dashboard.delete.description`)}
      open={isModalDisplayed}
      onCancel={handleClose}
      onConfirm={confirmDelete}
      cancelButtonProps={{
        'data-testid': 'cancel-button',
      }}
      confirmButtonProps={{
        'data-testid': 'delete-email-button-confirm',
        label: t(`email-dashboard.delete.confirm`),
        pattern: 'primary',
      }}
    />
  );
};

DeleteEmailConfirmationModal.propTypes = {
  handleClose: func.isRequired,
  isModalDisplayed: bool.isRequired,
  confirmDelete: func.isRequired,
};

export default DeleteEmailConfirmationModal;
