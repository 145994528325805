import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getRecordingPlayableUrl } from '../redux/slices/recordings/recordings-selectors';
import { selectExternalRegistrationId } from '../redux/slices/registration/registration-selector';
import { reportTrimmingAction } from '../utils/utils';
import { EditRecording } from './edit-recording';
import { ConfirmationPrompt } from './partials/confirmation-prompt';
import { RevertModal } from './partials/revert-modal';
import { SavePanel } from './partials/save-panel';
import { estimateTrimmingTimeMins } from './utils';

const SEC_TO_MILI = 1000;
const MINUTE_TO_MILI = SEC_TO_MILI * 60;

export const EditRecordingModalComponent = ({
  isEditSaving,
  isOpen,
  isRevertingRecording,
  onClose,
  onEditSave,
  onRevert,
  originalRecording,
  reloadRecordingSource,
}) => {
  const { t } = useLocalization('edit-recording-modal');
  const { published, hasRecordingEdits, lastEdit } = originalRecording;

  const defaultStartTimeSec = lastEdit?.startTimeMs / 1000 || 0;
  const defaultEndTimeSec = lastEdit?.endTimeMs / 1000 || 0;
  const [lastOpenedAt, setLastOpenedAt] = useState(new Date());

  const [trimStartTimeSec, setTrimStartTimeSec] = useState(defaultStartTimeSec);
  const [trimEndTimeSec, setTrimEndTimeSec] = useState(defaultEndTimeSec);

  const [fullRecordingIsSelected, setFullRecordingIsSelected] = useState(
    trimStartTimeSec === 0 && trimEndTimeSec === 0,
  );

  const externalRegistrationId = useSelector(selectExternalRegistrationId);
  const playableUrl = useSelector(state =>
    getRecordingPlayableUrl(state, originalRecording.id),
  );

  useEffect(() => {
    if (isOpen) {
      setLastOpenedAt(new Date());
    }
  }, [isOpen]);

  const onTrimTimeUpdate = useCallback(
    (startTimeMs, endTimeMs, fullRecordingSelected) => {
      setTrimStartTimeSec(startTimeMs);
      setTrimEndTimeSec(endTimeMs);
      setFullRecordingIsSelected(fullRecordingSelected);
    },
    [setTrimStartTimeSec, setTrimEndTimeSec, setFullRecordingIsSelected],
  );

  const [isInConfirmationMode, setIsInConfirmationMode] = useState(false);

  const reportTrimmingActionStatus = useCallback(
    trimmingStatus => {
      const now = new Date();
      reportTrimmingAction(originalRecording, {
        userId: externalRegistrationId,
        trimmingStatus,
        trimmingStartTime: lastOpenedAt.toISOString(),
        trimmingEndTime: now.toISOString(),
        status: published ? 'published' : 'unpublished',
        userTrimmingDuration:
          (now.getTime() - lastOpenedAt.getTime()) / MINUTE_TO_MILI,
      });
    },
    [externalRegistrationId, lastOpenedAt, published, originalRecording],
  );

  const onSave = useCallback(async () => {
    if (isInConfirmationMode) {
      await onEditSave({
        recording: originalRecording,
        startTimeMs: trimStartTimeSec * SEC_TO_MILI,
        endTimeMs: trimEndTimeSec * SEC_TO_MILI,
      }).then(() => reportTrimmingActionStatus('finished'));

      onClose();
    } else {
      setIsInConfirmationMode(true);
    }
  }, [
    onEditSave,
    isInConfirmationMode,
    reportTrimmingActionStatus,
    setIsInConfirmationMode,
    onClose,
    trimStartTimeSec,
    trimEndTimeSec,
    originalRecording,
  ]);

  const onDismissModal = useCallback(() => {
    onClose();
    setIsInConfirmationMode(false);
    reportTrimmingActionStatus('abandoned');
  }, [reportTrimmingActionStatus, setIsInConfirmationMode, onClose]);

  const onCancel = useCallback(() => {
    if (!isInConfirmationMode) {
      onClose();
    }
    setIsInConfirmationMode(false);
    reportTrimmingActionStatus('cancelled');
  }, [
    isInConfirmationMode,
    reportTrimmingActionStatus,
    setIsInConfirmationMode,
    onClose,
  ]);

  const onRevertRecording = useCallback(() => {
    setIsInConfirmationMode(false);

    onRevert();
  }, [setIsInConfirmationMode, onRevert]);

  return (
    <Modal
      data-testid="edit-recording-modal"
      isShowing={isOpen}
      withCloseButton
      isDismissible
      onClose={onDismissModal}
      label="edit-recording-modal"
      size={isInConfirmationMode || hasRecordingEdits ? 'medium' : 'huge'}
    >
      {hasRecordingEdits ? (
        <RevertModal
          isPublished={published}
          onCancel={onClose}
          onRevert={onRevertRecording}
          isReverting={isRevertingRecording}
        />
      ) : (
        <Flex flexDirection="column" data-testid="edit-recording-modal">
          <Flex flexDirection="column" m={2} mb={0}>
            {isInConfirmationMode ? (
              <Text pattern="headingThree">
                {t('confirmation-prompt.title')}
              </Text>
            ) : (
              <>
                <Text pattern="headingThree">{t('trim-recording')}</Text>
                <Text pattern="body" color="grey-600">
                  {t('modify-start-end')}
                </Text>
              </>
            )}
          </Flex>
          {isInConfirmationMode ? (
            <ConfirmationPrompt
              isPublished={published}
              timeEstimate={estimateTrimmingTimeMins(
                trimStartTimeSec,
                trimEndTimeSec,
              )}
            />
          ) : (
            <EditRecording
              src={playableUrl}
              startTimeMs={trimStartTimeSec}
              endTimeMs={trimEndTimeSec}
              onTrimTimeUpdate={onTrimTimeUpdate}
              reloadRecordingSource={reloadRecordingSource}
            />
          )}
          <Flex m={2} mt={0} justifyContent="end">
            <SavePanel
              onCancelText={t('cancel')}
              onConfirmText={t('save')}
              onCancel={onCancel}
              onConfirm={onSave}
              isSaveDisabled={isEditSaving || fullRecordingIsSelected}
            />
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default compose(withLocalizationProvider)(EditRecordingModalComponent);

EditRecordingModalComponent.propTypes = {
  isEditSaving: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  isRevertingRecording: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onEditSave: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  originalRecording: PropTypes.shape({
    hasRecordingEdits: PropTypes.bool.isRequired,
    playableUrl: PropTypes.string,
    published: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    eventPart: PropTypes.shape({
      externalId: PropTypes.string,
    }),
    lastEdit: PropTypes.shape({
      startTimeMs: PropTypes.number,
      endTimeMs: PropTypes.number,
    }),
  }).isRequired,
  reloadRecordingSource: PropTypes.func,
};
