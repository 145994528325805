import React from 'react';

const Dashboard = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 3.33333V0.666667C5.33333 0.298477 5.03486 0 4.66667 0H0.666667C0.298477 0 0 0.298477 0 0.666667V3.33333C0 3.70152 0.298477 4 0.666667 4H4.66667C5.03486 4 5.33333 3.70152 5.33333 3.33333ZM0.666667 5.33333H4.66667C5.03486 5.33333 5.33333 5.63181 5.33333 6V11.3333C5.33333 11.7015 5.03486 12 4.66667 12H0.666667C0.298477 12 0 11.7015 0 11.3333V6C0 5.63181 0.298477 5.33333 0.666667 5.33333ZM11.3333 8H7.33333C6.96514 8 6.66667 8.29848 6.66667 8.66667V11.3333C6.66667 11.7015 6.96514 12 7.33333 12H11.3333C11.7015 12 12 11.7015 12 11.3333V8.66667C12 8.29848 11.7015 8 11.3333 8ZM7.33333 0H11.3333C11.7015 0 12 0.298477 12 0.666667V6C12 6.36819 11.7015 6.66667 11.3333 6.66667H7.33333C6.96514 6.66667 6.66667 6.36819 6.66667 6V0.666667C6.66667 0.298477 6.96514 0 7.33333 0Z"
      />
    </svg>
  );
};

export default Dashboard;
