import CsrfInput from '@components/csrf-input';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { TextField } from '@hopin-team/ui-text-field';
import { compose } from 'lodash/fp';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import CustomDomainSettingsFavicon from './custom-domain-settings-favicon';
import CustomDomainSettingsSavebar from './custom-domain-settings-savebar';
import CustomDomainsSettingsSection from './custom-domain-settings-section';
import CustomDomainSettingsStatuses from './custom-domain-settings-statuses';
import CustomDomainSettingsUserVisible from './custom-domain-settings-user-visible';
import { Input, Wrapper } from './custom-domain-settings.styles';
import useCustomDomainSettingsForm from './use-custom-domain-settings-form';

const CustomDomainSettingsComponent = ({
  id,
  attributes,
  deleteDomain,
  isDeleting,
  setCustomDomain,
  readOnly,
  hasCanvasPage,
  deletable,
  canvasPageSetupUrl,
}) => {
  const { addAlert } = useContext(AlertsContext);
  const { t } = useContext(LocalizationContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formSaveArgs, setFormSaveArgs] = useState([]);

  const [isSaveConfirmationShowing, setSaveConfirmationShowing] = useState(
    false,
  );

  const showSaveConfirmationPopup = (...args) => {
    setFormSaveArgs(args);
    setSaveConfirmationShowing(true);
  };

  const hideSaveConfirmationPopup = () => {
    setFormSaveArgs([]);
    setSaveConfirmationShowing(false);
  };

  const handleSuccess = async data => {
    setCustomDomain(data);
    setIsSubmitting(false);
    if (domain === '') {
      setValue('domain', null);
    }

    if (userVisible) {
      addAlert({
        active: true,
        text: t('event-dashboard.custom-domains.successfully-updated'),
        pattern: 'success',
      });
    } else {
      addAlert({
        active: true,
        pattern: 'warning',
        text: t('event-dashboard.custom-domains.toast-activation-description'),
      });
    }
  };

  const handleError = errors => {
    setIsSubmitting(false);
    errors.forEach(error => {
      addAlert({
        active: true,
        text: error.detail,
        pattern: 'error',
      });
    });
  };

  const confirmDomainDeletion = async () => {
    const result = await deleteDomain();
    if (!result.error) {
      setValue('domain', null);
      setValue('appDomain', null);
      setValue('userVisible', false);
      setImageSelected(null);
      if (faviconUrl) {
        setValue('faviconUrl', null);
      }
      addAlert({
        active: true,
        text: t('event-dashboard.custom-domains.successfully-deleted'),
        pattern: 'success',
      });
    } else {
      addAlert({
        active: true,
        text: t('event-dashboard.custom-domains.not-deleted'),
        pattern: 'error',
      });
    }
  };

  const {
    errors,
    onSubmit,
    register,
    setValue,
    watch,
  } = useCustomDomainSettingsForm({
    id,
    attributes,
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const domain = watch('domain');
  const appDomain = watch('appDomain');
  const userVisible = watch('userVisible');

  const [faviconUrl, setImageSelected] = useState(watch('faviconUrl'));

  const hasDomainChanged = domain !== attributes.domain;
  const hasAppDomainChanged = appDomain !== attributes.appDomain;
  const hasUserVisibleChanged = userVisible !== attributes.userVisible;

  const confirmDomainSave = () => {
    setIsSubmitting(true);

    onSubmit(...formSaveArgs);
  };

  const {
    appDomainHostnameStatus,
    appDomainHostnameStatusErrors,
    appDomainSslStatus,
    appDomainSslStatusErrors,
    domainHostnameStatus,
    domainHostnameStatusErrors,
    domainSslStatus,
    domainSslStatusErrors,
    statusesLastUpdatedAt,
    expiresAt,
  } = attributes;

  const isActionHappening = isDeleting || isSubmitting;

  const activeForUsersDescription = `${t(
    'event-dashboard.custom-domains.active-for-users-description',
  )} ${
    expiresAt
      ? t('event-dashboard.custom-domains.active-for-users-expires-after', {
          expiresAt,
        })
      : ''
  }`;

  return (
    <Wrapper data-testid="custom-domain-settings-component">
      <Alerts />
      <form
        data-testid="custom-domain-settings-form"
        onSubmit={(...args) => {
          args[0].preventDefault();
          const hasChanged =
            hasDomainChanged || hasAppDomainChanged || hasUserVisibleChanged;

          if (hasChanged) {
            showSaveConfirmationPopup(...args);
          } else {
            onSubmit(...args);
          }
        }}
      >
        <CsrfInput />
        <CustomDomainsSettingsSection
          heading={t('event-dashboard.custom-domains.settings')}
          description={t('event-dashboard.custom-domains.description')}
        >
          <Flex>
            <Input
              as={TextField}
              name="domain"
              onChange={event => {
                setValue('domain', event.target.value.toLowerCase());
              }}
              value={domain || ''}
              helperText={t('event-dashboard.custom-domains.domain-helper')}
              errors={errors?.domain}
              placeholder={t(
                'event-dashboard.custom-domains.domain-placeholder',
              )}
              label={t('event-dashboard.custom-domains.domain')}
              ref={register('domain')}
              hasErrors={!!errors?.domain}
              errorMessage={errors?.message}
              isDisabled={!hasCanvasPage || readOnly || isActionHappening}
              my={2}
              mr={2}
            />
            <Button
              href={canvasPageSetupUrl}
              target="_blank"
              rel="noreferrer noopener"
              mt={5.7}
              isInline
              isOutlined
              size="medium"
              type="button"
            >
              {t('event-dashboard.custom-domains.canvas-page-button', {
                action: hasCanvasPage
                  ? t('event-dashboard.custom-domains.edit')
                  : t('event-dashboard.custom-domains.create'),
              })}
            </Button>
          </Flex>

          <Input
            as={TextField}
            isRequired
            name="appDomain"
            onChange={event => {
              setValue('appDomain', event.target.value.toLowerCase());
            }}
            value={appDomain || ''}
            helperText={t('event-dashboard.custom-domains.event-domain-helper')}
            hasErrors={!!errors?.appDomain}
            errorMessage={errors?.message}
            placeholder={t(
              'event-dashboard.custom-domains.event-domain-placeholder',
            )}
            label={t('event-dashboard.custom-domains.event-domain')}
            ref={register('appDomain')}
            isDisabled={readOnly || isActionHappening}
          />
        </CustomDomainsSettingsSection>

        <CustomDomainsSettingsSection
          heading={t('event-dashboard.custom-domains.active-for-users-heading')}
          description={activeForUsersDescription}
        >
          <CustomDomainSettingsUserVisible
            onChange={() => {
              setValue('userVisible', !userVisible);
            }}
            t={t}
            userVisible={userVisible || false}
            disabled={readOnly || isActionHappening}
            ref={register('userVisible')}
            mt={2}
          />
        </CustomDomainsSettingsSection>

        <hr className="my-3" />

        <CustomDomainsSettingsSection
          heading={t('event-dashboard.custom-domains.icon-heading')}
          description={t('event-dashboard.custom-domains.icon-description')}
        >
          <CustomDomainSettingsFavicon
            setImagePreview={setImageSelected}
            setFilename={fileName => {
              setValue('faviconFileName', fileName);
            }}
            entityId={id}
            addAlert={addAlert}
            t={t}
            ref={register('faviconFileName')}
            disabled={readOnly || isActionHappening}
            imageUrl={faviconUrl}
          />
        </CustomDomainsSettingsSection>

        <CustomDomainsSettingsSection
          heading={t('event-dashboard.custom-domains.connection-status')}
          description={t(
            'event-dashboard.custom-domains.connection-status-description',
          )}
        >
          <CustomDomainSettingsStatuses
            t={t}
            lastUpdated={statusesLastUpdatedAt}
            persistedAppDomain={attributes.appDomain}
            persistedDomain={attributes.domain}
            appDomainHostnameStatus={appDomainHostnameStatus}
            appDomainSslStatus={appDomainSslStatus}
            domainHostnameStatus={domainHostnameStatus}
            domainSslStatus={domainSslStatus}
            appDomainHostnameStatusErrors={appDomainHostnameStatusErrors}
            appDomainSslStatusErrors={appDomainSslStatusErrors}
            domainHostnameStatusErrors={domainHostnameStatusErrors}
            domainSslStatusErrors={domainSslStatusErrors}
          />
        </CustomDomainsSettingsSection>

        <CustomDomainSettingsSavebar
          t={t}
          disabled={isActionHappening}
          saveDisabled={!appDomain}
          isDeleteShown={deletable}
          isSaveShown={!readOnly}
          isSaveConfirmationShowing={isSaveConfirmationShowing}
          hideSaveConfirmationPopup={hideSaveConfirmationPopup}
          isSubmitting={isSubmitting}
          onConfirmDelete={confirmDomainDeletion}
          onConfirmSave={confirmDomainSave}
          isLoading={isSubmitting}
          isDeleting={isDeleting}
        />
      </form>
    </Wrapper>
  );
};

CustomDomainSettingsComponent.propTypes = {
  id: string.isRequired,
  attributes: shape({
    domain: string,
    appDomain: string,
    appDomainHostnameStatus: string,
    appDomainHostnameStatusErrors: arrayOf(string),
    appDomainSslStatus: string,
    appDomainSslStatusErrors: arrayOf(string),
    domainHostnameStatus: string,
    domainHostnameStatusErrors: arrayOf(string),
    domainSslStatus: string,
    domainSslStatusErrors: arrayOf(string),
    userVisible: bool,
    faviconUrl: string,
  }).isRequired,
  deleteDomain: func.isRequired,
  isDeleting: bool.isRequired,
  setCustomDomain: func.isRequired,
  readOnly: bool.isRequired,
  canvasPageSetupUrl: string.isRequired,
  hasCanvasPage: bool,
  deletable: bool.isRequired,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
)(CustomDomainSettingsComponent);
