import amplitudeClient from '@util/analytics/amplitude-client';
import { number } from 'prop-types';
import React, { useEffect } from 'react';

import NavigationItemComponent from './navigation-item-component';

const NavigationItemAccountContainer = ({ userId, ...rest }) => {
  useEffect(() => {
    amplitudeClient.setUserId(userId);
  }, [userId]);

  const handleClick = () => amplitudeClient.logEvent('account_clicked');

  return <NavigationItemComponent onClick={handleClick} {...rest} />;
};

export default NavigationItemAccountContainer;

NavigationItemAccountContainer.propTypes = {
  userId: number.isRequired,
};
