import '@hopin-team/ui-theme';

import hopinApi from '@api/hopin';
import { isTestUser } from '@util/filter-test-users';
import React, { useEffect, useState } from 'react';

import PostRegistrationApp from './post-registration-app';

const PostRegistrationRoot = () => {
  const [App, setApp] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const auth = await hopinApi.getUserToken();
        const user = await hopinApi.getUser(auth.token);
        const externalId = user.external_id;

        const testUser = isTestUser(user);

        setApp(
          <PostRegistrationApp
            userExternalId={externalId}
            isTestUser={testUser}
          />,
        );
      } catch (error) {
        window.location.pathname = '/users/sign_in';
      }
    }
    fetchData();
  }, []);

  return App;
};

export default PostRegistrationRoot;
