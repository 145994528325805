import { Box } from '@hopin-team/ui-box';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

export const TemplateCard = styled(Box).attrs(({ $isBlank, href }) => ({
  background: $isBlank ? 'grey-white' : 'blue-100',
  border: $isBlank ? 'grey-300' : null,
  cornerRadius: 'lg',
  as: href && 'a',
  p: 2,
}))`
  aspect-ratio: 1.42 / 1;
  cursor: ${({ $isBlank }) => $isBlank && 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ $isBlank, theme }) =>
    $isBlank ? getFromTheme('LINE.DEFAULT_BORDER', theme) : 'none'};
  border-width: ${calcSpace(0.25)};
  position: relative;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @supports not (aspect-ratio: 1.42 / 1) {
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-bottom: 70%;
    }

    img {
      position: absolute;
      width: calc(100% - ${calcSpace(4)});
      height: calc(100% - ${calcSpace(4)});
    }
  }
`;
