const getMaxTickWidth = (ticks, formatter) => {
  if (!ticks.length) return 0;

  const tickLengths = ticks.map(
    // return length of string excluding periods
    tick => formatter(tick).replace(/\./g, '').length,
  );
  const longestTickLength = Math.max(...tickLengths);

  // assumes that on average chars are 7 pixels wide
  return longestTickLength * 7;
};

export default getMaxTickWidth;
