import { numberFormatter } from '@features/email/shared';
import { useLocalization } from '@features/localization';
import { number } from 'prop-types';
import React from 'react';

export const AudienceCount = ({ recipientsCount, eventAudienceCount }) => {
  const { t } = useLocalization('email-dashboard.previewAndReviewPage');

  return (
    <span>
      {t('this_email')} <b>{numberFormatter(recipientsCount)}</b> {t('of')}{' '}
      <b>{numberFormatter(eventAudienceCount)}</b> {t('attendees')}.
    </span>
  );
};

AudienceCount.propTypes = {
  eventAudienceCount: number,
  recipientsCount: number,
};
