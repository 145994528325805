import CopyLink from '@features/overview/banner/onsite/components/copy-link';
import CountDown from '@features/overview/banner/onsite/components/countdown';
import Header from '@features/overview/banner/onsite/components/header';
import ImageEdit from '@features/overview/banner/onsite/components/image-edit';
import Location from '@features/overview/banner/onsite/components/location';
import { Flex } from '@hopin-team/ui-flex';
import propTypes from 'prop-types';
import React, { useMemo } from 'react';

import { OnsiteBannerContainer } from '../index.styled';

export const OnsiteBanner = ({ event, isLive, isEnded }) => {
  const startDate = useMemo(() => new Date(event.timeStartLocal), [
    event.timeStartLocal,
  ]);

  const location = useMemo(() => (event.address ? event.address : {}), [
    event.address,
  ]);

  return (
    <OnsiteBannerContainer flexDirection="column">
      <Flex p={3}>
        <Flex flexDirection="column" mr="auto">
          <Header
            event={event}
            isEnded={isEnded}
            isLive={isLive}
            isDraft={event.draft}
            startDate={startDate}
          />
          <Flex alignItems="center">
            {!isEnded && !isLive && <CountDown startDate={startDate} />}
            {location && <Location location={location} slug={event.slug} />}
          </Flex>
        </Flex>

        <ImageEdit event={event} />
      </Flex>
      <CopyLink eventId={event.externalId} />
    </OnsiteBannerContainer>
  );
};

OnsiteBanner.propTypes = {
  event: propTypes.object,
  isLive: propTypes.bool,
  isEnded: propTypes.bool,
};
