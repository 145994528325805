import DatePickerComponents from '@features/event-creation-wizard/components/date-pickers/date-picker-components';
import StepHeader from '@features/event-creation-wizard/components/step-header';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import useEventSetupForm from '@features/event-creation-wizard/steps/basic-details/hooks/use-event-setup-form';
import { LocalizationContext } from '@features/localization';
import { TextField } from '@hopin-team/ui-text-field';
import { bool } from 'prop-types';
import React, { useContext } from 'react';

const WebinarBasicDetailsComponent = ({ canSkip }) => {
  const {
    watch,
    errors,
    trigger,
    setValue,
    register,
    formState,
    onClickNext,
  } = useEventSetupForm();

  const { t, dateLocale } = useContext(LocalizationContext);

  const childProps = {
    t,
    watch,
    errors,
    setValue,
    register,
    trigger,
    dateLocale,
    baseTranslationNamespace:
      'event-creation-wizard.steps.webinar-basic-details',
  };

  return (
    <EventCreationWizardStep
      canSkip={canSkip}
      onSave={onClickNext}
      breadcrumbsDisabled={!canSkip && !formState.isValid}
      continueDisabled={!formState.isValid}
      ctaLabel={t('event-creation-wizard.common.continue')}
      showPreviewImage={false}
      verticallyCentered
    >
      <form onSubmit={onClickNext} data-testid="create-event-form">
        <StepHeader
          title={t(`event-creation-wizard.steps.webinar-basic-details.title`)}
          subtitle={t(
            `event-creation-wizard.steps.webinar-basic-details.subtitle`,
          )}
        />

        <TextField
          mb={3.5}
          name="name"
          ref={register}
          data-testid="event-name"
          hasErrors={Boolean(errors.name)}
          errorMessage={errors?.name?.message}
          label={t(
            `event-creation-wizard.steps.webinar-basic-details.form.event-name.label`,
          )}
          placeholder={t(
            `event-creation-wizard.steps.webinar-basic-details.form.event-name.placeholder`,
          )}
          isRequired
        />

        <DatePickerComponents
          {...childProps}
          setValue={(...rest) => {
            setValue(...rest);
          }}
        />

        {/* Issue with react-hook-form v6 -> if you don't register a field the value doesnt exist */}
        <input type="hidden" ref={register} name="eventType" />
      </form>
    </EventCreationWizardStep>
  );
};

WebinarBasicDetailsComponent.propTypes = {
  canSkip: bool,
};

export default WebinarBasicDetailsComponent;
