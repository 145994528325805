import analyticsApi from '@api/analytics';

import { useAlertingReportRequest } from './use-alerting-report-request';

export const useAlertingCreateReportRequest = eventId => {
  const reportRequest = useAlertingReportRequest();

  return (report, parameters, initiatedFrom) =>
    reportRequest(
      () =>
        analyticsApi.createReport(eventId, {
          report_type: report.reportType,
          initiated_from: initiatedFrom,
          ...parameters,
        }),
      report,
    );
};
