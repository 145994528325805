import { Pill } from '@hopin-team/ui-pill';
import styled from 'styled-components';

const StyledPill = styled(Pill)`
  height: auto;
  padding: var(--spacing-4) var(--spacing-16) var(--spacing-4) var(--spacing-4);
  border-radius: var(--spacing-48);
  border: 1px solid
    ${props =>
      props.selected ? 'var(--color-blue-300)' : 'var(--color-gray-300)'};
  background-color: ${props =>
    props.selected ? 'var(--color-blue-transparent)' : 'var(--color-white)'};
`;

const IconWrapper = styled.div`
  height: var(--spacing-32);
  width: var(--spacing-32);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-16);
  background-color: ${props => `var(--color-${props.color}-100)`};
  border: 1px solid transparent;
  border-radius: 100%;
`;

export { StyledPill, IconWrapper };
