import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const ConnectionsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  padding: 0.75rem 0;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-300);
  }
`;

const LogoWrap = styled.div`
  flex: none;
  margin-right: 0.5rem;
`;

const LogoImg = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 100%;
`;

export const LeftConnection = styled.div`
  display: flex;
  width: 38%;
`;

export const RightConnection = styled.div`
  display: flex;
  width: 42%;
`;

export const Headline = styled.div`
  color: var(--color-gray-600);
  font-size: var(--font-size-small);
`;

export const ConnectionIconWrapper = styled.div`
  display: flex;
  width: 12%;
  margin-right: 8%;

  svg {
    margin: auto;
  }
`;

export const Logo = ({ alt, src }) => (
  <LogoWrap>
    <LogoImg alt={alt} src={src} />
  </LogoWrap>
);

Logo.propTypes = {
  alt: string.isRequired,
  src: string.isRequired,
};

Logo.defaultProps = {
  rounded: false,
};

export const Name = styled.div`
  margin-right: 0.5rem;
  word-break: break-word;
`;

export const SeeMore = styled.a`
  display: block;
  margin-top: 1rem;

  @media print {
    display: none;
  }
`;
