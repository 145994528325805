import CheckIcon from '@components/icons/check';
import CloseIcon from 'icons/close.svg';
import { bool, node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: var(
    ${props => (props.valid ? '--color-green-500' : '--color-red-500')}
  );

  display: flex;
  align-items: center;
`;

const Message = styled.span`
  margin-left: 10px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin: 2px;
`;

export default function Rule({ valid, children }) {
  return (
    <Wrapper valid={valid} data-testid="password-strength-rule">
      {valid ? <StyledCheckIcon /> : <CloseIcon />}
      <Message data-testid={valid ? 'password-valid' : 'password-invalid'}>
        {children}
      </Message>
    </Wrapper>
  );
}

Rule.propTypes = {
  valid: bool,
  children: node,
};
