import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

const useExpoForm = (defaultValues, onSubmitHandler) => {
  const { t } = useLocalization('event-creation-wizard.steps.expo.form');

  const { errors, register, setValue, handleSubmit, formState } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(
      object().shape({
        name: string().trim().required(t('name.required')),
        email: string()
          .trim()
          .email(t('email.invalid'))
          .required(t('email.required')),
        description: string().trim().required(t('description.required')),
      }),
    ),
  });

  return {
    errors,
    register,
    setValue,
    formState,
    onSubmit: handleSubmit(onSubmitHandler),
  };
};

export default useExpoForm;
