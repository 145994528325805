import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import React from 'react';

import { ImageWrapper, LeftHalf, LeftStyledText } from './style';

const MarketoModalLeftComponent = () => {
  const { t } = useLocalization('marketo-modals.default');
  const hopinPlus = require('marketo-modal/hopin-plus.png');

  return (
    <LeftHalf>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        px={12}
      >
        <ImageWrapper>
          <img src={`${hopinPlus}`} alt="hopin plus logo" />
        </ImageWrapper>
        <LeftStyledText element="h6" pattern="headingSix" color="grey-white">
          {t('epic-onsite-event')}
        </LeftStyledText>
      </Flex>
    </LeftHalf>
  );
};

MarketoModalLeftComponent.propTypes = {};

export default MarketoModalLeftComponent;
