import DatePickerComponents from '@features/event-creation-wizard/components/date-pickers/date-picker-components';
import EventLocation from '@features/event-creation-wizard/components/event-location';
import EventType from '@features/event-creation-wizard/components/event-type';
import StepHeader from '@features/event-creation-wizard/components/step-header';
import { useEventCreationWizard } from '@features/event-creation-wizard/event-creation-wizard-provider';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import EventAccess from '@features/event-creation-wizard/steps/details/components/event-access';
import useDetailsForm from '@features/event-creation-wizard/steps/details/hooks/use-details-form';
import { LocalizationContext } from '@features/localization';
import { TextField } from '@hopin-team/ui-text-field';
import { bool } from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

const DetailsComponent = ({ canSkip, canSetEventLocation }) => {
  const {
    watch,
    errors,
    trigger,
    setValue,
    register,
    formState,
    onClickNext,
    reset,
    getValues,
  } = useDetailsForm();

  const { t, dateLocale } = useContext(LocalizationContext);
  const isOnFreePlan = useSelector(state => state.org.isOnFreePlan);
  const isOnAdvancedPlan = useSelector(state => state.org.isOnAdvancedPlan);
  const currentEventAccess = useSelector(state => state.eventAccess);
  const currentEventType = useSelector(state => state.eventType);
  const {
    type: currentEventLocationType,
    address: currentLocationAddress,
    name: currentEventLocationName,
  } = useSelector(state => state.eventLocation);

  const { googleMapsApiKey } = useEventCreationWizard();

  const eventType = watch('eventType', currentEventType);
  const eventLocationType = watch(
    'eventLocationType',
    currentEventLocationType,
  );

  const eventLocationName = watch(
    'eventLocationName',
    currentEventLocationName,
  );

  const eventLocationAddress = watch(
    'eventLocationAddress',
    typeof currentLocationAddress === 'string'
      ? JSON.parse(currentLocationAddress)
      : currentLocationAddress,
  );

  const childProps = {
    t,
    watch,
    errors,
    setValue,
    register,
    trigger,
    dateLocale,
  };

  const onEventLocationTypeChange = type => setValue('eventLocationType', type);
  const onAddressChange = address =>
    setValue('eventLocationAddress', JSON.stringify(address)); // This version of react-form-hook does not support object values
  const onNameChange = name => setValue('eventLocationName', name);

  return (
    <EventCreationWizardStep
      canSkip={canSkip}
      onSave={onClickNext}
      breadcrumbsDisabled={!canSkip && !formState.isValid}
      continueDisabled={!formState.isValid}
    >
      <form onSubmit={onClickNext} data-testid="create-event-form">
        <StepHeader
          title={t('event-creation-wizard.steps.details.title')}
          subtitle={t('event-creation-wizard.steps.details.subtitle')}
        />
        <TextField
          mb={3}
          name="name"
          ref={register}
          data-testid="event-name"
          hasErrors={Boolean(errors.name)}
          errorMessage={errors?.name?.message}
          label={t('event-creation-wizard.steps.details.form.event-name.label')}
          placeholder={t(
            'event-creation-wizard.steps.details.form.event-name.placeholder',
          )}
          isRequired
        />
        <DatePickerComponents
          {...childProps}
          setValue={(...rest) => {
            setValue(...rest);
          }}
        />
        <EventAccess
          currentEventAccess={currentEventAccess}
          register={register}
          errorMessage={errors?.eventAccess?.message}
          onChange={ticketType => {
            setValue('eventAccess', ticketType);
            trigger('eventAccess');
          }}
        />
        {canSetEventLocation && (
          <EventLocation
            type={eventLocationType}
            name={eventLocationName}
            address={
              eventLocationAddress && typeof eventLocationAddress === 'string'
                ? JSON.parse(eventLocationAddress)
                : eventLocationAddress
            }
            onTypeChange={onEventLocationTypeChange}
            onAddressChange={onAddressChange}
            onNameChange={onNameChange}
            register={register}
            isOnAdvancedPlan={isOnAdvancedPlan}
            googleMapsApiKey={googleMapsApiKey}
            errors={errors}
          />
        )}
        <EventType
          eventType={eventType}
          onChange={type => {
            setValue('eventType', type);
            reset({
              ...getValues(),
              eventPassword: '',
            });
          }}
          register={register}
          isOnFreePlan={isOnFreePlan}
          errors={errors}
        />
      </form>
    </EventCreationWizardStep>
  );
};

DetailsComponent.propTypes = {
  canSkip: bool,
  canSetEventLocation: bool,
};

export default DetailsComponent;
