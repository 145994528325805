import MountSlice, { LATEST } from '@components/slices/MountSlice';
import { createReactiveMap } from '@hopin-team/onsite-shared';
import { FE_SLICES_CDN } from '@util/config';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import React from 'react';

const logger = getLogger(LOGGER_NAMES.ONSITE);

const PACKAGE_NAME = '@hopin-team/slice-onsite-checkin-area';

const OnsiteCheckinArea = () => {
  const reactiveMap = createReactiveMap({
    clientPackageDetails: `${PACKAGE_NAME}/${LATEST}`,
    onAsyncError: error => logger.error(error),
  });

  return (
    <MountSlice
      packageName={PACKAGE_NAME}
      version={LATEST}
      reactiveMap={reactiveMap}
      urlOverride={`${FE_SLICES_CDN}/slices/slice-onsite-checkin-area/${LATEST}/remoteEntry.js`}
    />
  );
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.ONSITE,
    errorPattern: 'page',
  }),
)(OnsiteCheckinArea);
