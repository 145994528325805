import { LocalizationContext } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import SettingComponent from './setting-component';

const SpeedNetworking = ({ isToggled, onChange }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Box mt={5} mb={4}>
      <Text pattern="headingFour" color="grey-800" as="h4">
        {t('people.people-area.speed-networking')}
      </Text>
      <SettingComponent
        title={t('people.people-area.enable-speed-networking')}
        description={t('people.people-area.speed-networking-description')}
        isToggled={isToggled}
        onChange={onChange}
      />
    </Box>
  );
};

export default SpeedNetworking;

SpeedNetworking.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
