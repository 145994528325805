import { without } from 'ramda';
import { useReducer } from 'react';

export const FILTER_ACTIONS = {
  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  UPDATE_SEARCH_TEXT: 'UPDATE_SEARCH_TEXT',
};

export const statuses = [
  { key: 'statuses', query: 'pending' },
  { key: 'statuses', query: 'registered' },
  { key: 'statuses', query: 'unsent' },
  { key: 'statuses', query: 'declined' },
];

const filtersReducer = (state, action) => {
  switch (action.type) {
    case FILTER_ACTIONS.ADD_FILTER:
      return {
        ...state,
        [action.meta.key]: without([action.payload], state[action.meta.key]),
        filters: [...state.filters, action.payload],
      };
    case FILTER_ACTIONS.REMOVE_FILTER:
      return {
        ...state,
        [action.meta.key]: [...state[action.meta.key], action.payload],
        filters: without([action.payload], state.filters),
      };
    case FILTER_ACTIONS.UPDATE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    default:
      return state;
  }
};

const initFilters = (filters, initialState, t) => {
  if (filters.length === 0) return initialState;
  Object.entries({
    statuses: filters.statuses,
    ticketTypes: filters.ticketTypes,
  }).map(([key, value]) => {
    if (value) {
      value.split(',').forEach(label => {
        const filterSelected = { label, key, query: label };
        if (key === 'statuses')
          filterSelected.label = t(`backend-filters-to-front.${label}`);
        initialState[filterSelected.key] = without(
          [filterSelected],
          initialState[filterSelected.key],
        );
        initialState.filters.push(filterSelected);
      });
    }
  });
  return initialState;
};

const useFilterReducer = (tickets, filters, initialSearchTextValue, t) => {
  const translatedStatuses = statuses.map(({ key, query }) => ({
    label: t(`statuses.${query}`),
    key,
    query,
  }));

  const initialState = {
    statuses: translatedStatuses,
    ticketTypes: tickets.map(ticket => ({
      label: ticket.label,
      key: 'ticketTypes',
      query: ticket.label,
    })),
    filters: [],
    searchText: initialSearchTextValue,
  };
  const stateFromServer = initFilters(filters, initialState, t);
  const [state, dispatch] = useReducer(filtersReducer, stateFromServer);

  const addFilter = filter => {
    dispatch({
      type: FILTER_ACTIONS.ADD_FILTER,
      payload: filter,
      meta: { key: filter.key },
    });
  };

  const removeFilter = filter => {
    dispatch({
      type: FILTER_ACTIONS.REMOVE_FILTER,
      payload: filter,
      meta: { key: filter.key },
    });
  };

  const updateFieldValue = text => {
    dispatch({
      type: FILTER_ACTIONS.UPDATE_SEARCH_TEXT,
      payload: text,
    });
  };

  return {
    addFilter,
    removeFilter,
    updateFieldValue,
    statusFilters: state.statuses,
    ticketFilters: state.ticketTypes,
    selectedFilters: state.filters,
    searchText: state.searchText,
  };
};

export default useFilterReducer;
