import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { navigationViews } from '@features/nav/constants';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { unwrapResult } from '@reduxjs/toolkit';
import { EVENT_DASHBOARD_EVENTS } from '@util/analytics/event-dashboard-events';
import trackSegmentEvent from '@util/analytics/segment-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import P from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleNavigationView } from '@/redux/reducers/navigation-view';

import { Container, Toggle } from './navigation-view-toggle-styles';

const NavigationViewToggle = ({ navigationView: initialNavigationView }) => {
  const dispatch = useDispatch();
  const logger = getLogger(LOGGER_NAMES.EVENT_DASH_NAV);
  const { t: commonTranslations } = useLocalization();
  const { t } = useLocalization(
    'event-dashboard.sidenav.footer.navigation-view-toggle',
  );
  const { addAlert } = useContext(AlertsContext);

  const [view, setView] = useState(
    initialNavigationView ?? navigationViews.SIMPLIFIED,
  );

  const {
    event: {
      externalId: eventId,
      eventWizardTemplate,
      organization: {
        currentPaymentPlan: { name: organisationSubscriptionTier },
      },
    },
    user: { externalId: userId },
  } = useSelector(({ navigationView, event, user }) => ({
    navigationView,
    event,
    user,
  }));

  // Set the initial navigation view in the Redux store
  useEffect(() => {
    const setInitialNavigationView = async () => {
      try {
        const response = await dispatch(
          toggleNavigationView({ eventId, value: initialNavigationView }),
        );

        unwrapResult(response);
      } catch (error) {
        logger.error(error);

        addAlert({
          active: true,
          text: error.message,
          pattern: 'error',
        });
      }
    };

    setInitialNavigationView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleView = async () => {
    const initialView = view;

    const value =
      initialView === navigationViews.SIMPLIFIED
        ? navigationViews.ADVANCED
        : navigationViews.SIMPLIFIED;

    try {
      setView(value);

      trackSegmentEvent(EVENT_DASHBOARD_EVENTS.ADVANCED_SETTINGS_TOGGLED, {
        organiser_id: userId,
        event_type: eventWizardTemplate,
        organisation_subscription_tier: organisationSubscriptionTier,
        advanced_toggle_setting: value,
      });

      const response = await dispatch(
        toggleNavigationView({
          eventId,
          value,
        }),
      );

      unwrapResult(response);
    } catch (err) {
      setView(initialView);
      logger.error(err);
      addAlert({
        active: true,
        text: err.message,
        pattern: 'error',
      });
    }
  };

  return (
    <Container data-testid="navigation-view-toggle-container">
      <Box pb={1}>
        <Text element="h1" pattern="captionBold" color="grey-800">
          {t('dashboard-settings', {
            templateName: commonTranslations(
              `event-creation-wizard.templates.${eventWizardTemplate}.title`,
            ),
          })}
        </Text>
      </Box>
      <Box pb={1.5}>
        <Text element="p" pattern="labelFive" color="grey-800">
          {t('description')}
        </Text>
      </Box>
      <Toggle
        isToggled={view === navigationViews.ADVANCED}
        label={t('advanced-mode')}
        sizeOption="large"
        onChange={toggleView}
        data-testid="navigation-view-toggle"
      />
    </Container>
  );
};

NavigationViewToggle.propTypes = {
  navigationView: P.string,
};

export default NavigationViewToggle;
