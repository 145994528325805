import styled from 'styled-components';

const Label = styled.div`
  padding: var(--spacing-8);
  font-family: var(--font-family);
  font-size: var(--font-size-bigger);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-size-bigger);
  color: var(--color-gray-800);
`;

export default Label;
