import { buildRecordingTitle } from '@hopin-team/ui-recording-card';

import { VIDEO_EDIT_STATUSES } from '../../../constants';
import {
  filterObject,
  filterRecordings,
  hasRecordingSucceededOnTrimming,
  sortRecordings,
} from '../../../utils/utils';
import { defaultRecordingState } from './recordings-slice';

/**
 * @returns recording object or empty object if no ID matched. This is to handle loading state where the recording may subsequently get added to state
 */
export const getRecordingById = (state, id, language) => {
  const originalRecording = state?.recordings[id];
  if (!originalRecording) return {};

  let recording = { ...originalRecording };

  /**
   * We want to check the recording is loaded, but lastEdit is not
   * always present so cannot be used to validate
   */
  if (recording?.createdAt) {
    const { lastEdit, createdAt, title, startedAt } = recording;
    recording.isProcessing =
      lastEdit?.status === VIDEO_EDIT_STATUSES.processing ?? false;

    recording.lastTrimmedTime =
      lastEdit?.status === VIDEO_EDIT_STATUSES.success
        ? lastEdit.completedGenerationAt
        : undefined;

    recording.hasRecordingEdits = !!recording?.lastEdit;

    if (language) {
      recording.title = buildRecordingTitle({
        title,
        createdAt,
        startedAt,
        locale: language,
      });
    }
    return recording;
  }
};

/**
 * Get a clean version of the object sending to analytics
 * Without state or derived values
 */
export const getOriginalRecordingById = (state, id) => {
  const recording = state?.recordings[id];
  if (!recording) return undefined;

  const stateKeys = Object.keys(defaultRecordingState);
  const recordingWithState = { ...state?.recordings[id] };
  return filterObject({
    original: recordingWithState,
    disallowedKeys: stateKeys,
  });
};

export const getRecordingsByGroup = (state, group, filters) => {
  const recordingIds =
    state.recordingGroups[group.groupId]?.groupRecordings || [];

  let results = [];
  recordingIds.forEach(id => {
    if (state.recordings[id]) results.push(state.recordings[id]);
  });

  if (filters && results.length) {
    results = sortRecordings(filterRecordings(results, filters));
  }

  return results;
};

export const getRecordingPlayableUrl = (state, id) => {
  const recording = state?.recordings[id];
  if (!recording) {
    return null;
  } else if (hasRecordingSucceededOnTrimming(recording)) {
    return recording.playableUrl;
  }

  return recording.mar?.playable?.url || recording.playableUrl;
};

export const getRecordingDownloadUrl = (state, id) => {
  const recording = state?.recordings[id];
  if (!recording) {
    return null;
  } else if (hasRecordingSucceededOnTrimming(recording)) {
    return recording.downloadUrl;
  }

  return recording.mar?.download?.url || recording.downloadUrl;
};

export const selectRecordingIsLoaded = (state, id, language) => {
  const recording = getRecordingById(state, id, language);

  return !!(recording && !recording.isLoading && recording.title);
};
