import ContentWrapper from '@components/wrappers/content';
import TextWrapper from '@components/wrappers/text';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { bool, node, object, string } from 'prop-types';
import React from 'react';

import { getPageConstants } from './constants/page';
import AttendeeEmptyState from './partials/attendees';

const EmptyState = ({
  children,
  eventSlug,
  pageType,
  event,
  eventPart,
  status,
  hasMagicLinks,
  videoSessionsEnableLayoutV2,
}) => {
  const png = require(`empty-states/${pageType}.png`);
  const png2x = require(`empty-states/${pageType}-2x.png`);
  const { t } = useLocalization();
  const pageConstants = getPageConstants(t, videoSessionsEnableLayoutV2);
  const route = pageConstants[pageType].route;
  const routeMagicLinks = pageConstants[pageType].routeMagicLinks;
  const slug = eventSlug ? eventSlug : event;

  const EmptyStateButton = () => {
    return (
      <Button
        isInline
        data-testid="empty-button"
        data-toggle={pageType === 'referrals' ? 'modal' : undefined}
        data-target={pageType === 'referrals' ? '.new_affiliate' : undefined}
        {...(!['referrals', 'error'].includes(pageType) && {
          href: route(slug, eventPart),
        })}
      >
        {pageConstants[pageType].buttonText}
      </Button>
    );
  };

  return (
    <ContentWrapper>
      <Flex
        justifyContent="center"
        alignItems="center"
        mt={9}
        mr={pageType === 'sponsors' ? 6 : 0}
      >
        <img src={png} srcSet={`${png2x} 2x`} alt="" />
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <Text
          data-testid="empty-heading"
          element="h2"
          pattern="headingTwo"
          color="grey-800"
          display="font"
          mt={2}
        >
          {pageConstants[pageType].heading}
        </Text>
      </Flex>
      {pageType === 'attendees' ? (
        <AttendeeEmptyState
          descriptionText={pageConstants[pageType].descriptionText}
          eventSlug={eventSlug}
          hasMagicLinks={hasMagicLinks}
          notLiveDescriptionText={
            pageConstants[pageType].notLiveDescriptionText
          }
          route={route}
          routeMagicLinks={routeMagicLinks}
          status={status}
        />
      ) : (
        <>
          <Flex justifyContent="center" alignItems="center">
            <TextWrapper>
              <Text
                data-testid="empty-text"
                element="p"
                mt={2}
                mb={4}
                color="grey-700"
                pattern="body"
                dangerouslySetInnerHTML={{
                  __html:
                    status === 'draft'
                      ? pageConstants[pageType].notLiveDescriptionText
                      : pageConstants[pageType].descriptionText,
                }}
              />
            </TextWrapper>
          </Flex>
          {pageConstants[pageType].buttonText && status !== 'draft' && (
            <Flex justifyContent="center" alignItems="center">
              {children ?? <EmptyStateButton />}
            </Flex>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

EmptyState.propTypes = {
  children: node,
  event: object,
  eventPart: object,
  eventSlug: string,
  hasMagicLinks: bool,
  pageType: string.isRequired,
  status: string,
  videoSessionsEnableLayoutV2: bool,
};

export default withLocalizationProvider(EmptyState);
