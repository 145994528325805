import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { calcSpace } from '@hopin-team/ui-symbols';
import {
  Table,
  TableCell,
  TableHeading,
  TableRow,
  TableRowAction,
  TableRowActions,
} from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import compose from 'lodash/fp/compose';
import { rem } from 'polished';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import ResetEmailModal from './modals/reset-email-modal';
import ResumeEmailModal from './modals/resume-email-modal';
import StopEmailModal from './modals/stop-email-modal';
import Status from './status';

const StyledTable = styled(Table)`
  border-collapse: collapse;
  margin-bottom: ${calcSpace(4)};
  width: 100%;
`;

const StyledTableHeading = styled(TableHeading)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
  text-transform: uppercase;

  ${Text} {
    font-weight: 500;
  }
`;

const StyledTableCell = styled(TableCell)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
`;

const StyledStatusTableCell = styled(StyledTableCell)`
  white-space: nowrap;
`;

const StyledTableRowActions = styled(TableRowActions)`
  padding-right: ${calcSpace(2)};
`;

export const Thead = styled.thead`
  box-shadow: inset 0px -${rem(1)} 0px var(--color-gray-200);
`;

const CustomiseEmailsTable = ({
  authenticityToken,
  eventSlug,
  emailTemplates,
  emailsSuppressed,
}) => {
  const { t } = useLocalization('communications.customise-emails');
  const { addAlert } = useContext(AlertsContext);
  const apiClient = initApiClient(authenticityToken);
  const logger = getLogger(LOGGER_NAMES.COMMUNICATIONS);
  const [templateToStop, setTemplateToStop] = useState(null);
  const [isStopEmailModalOpen, setStopEmailModalOpen] = useState(false);
  const [templateToResume, setTemplateToResume] = useState(null);
  const [isResumeEmailModalOpen, setResumeEmailModalOpen] = useState(false);
  const [templateToReset, setTemplateToReset] = useState(null);
  const [isResetEmailModalOpen, setResetEmailModalOpen] = useState(false);

  const templateStatus = customTemplate => {
    if (emailsSuppressed) {
      return 'disabled';
    } else {
      if (!customTemplate) {
        return 'default';
      }

      if (customTemplate.stopped) {
        return 'disabled';
      }

      if (customTemplate.draft) {
        return 'draft';
      }

      return 'custom';
    }
  };

  const disabledTemplate = customTemplate => {
    return templateStatus(customTemplate) == 'disabled';
  };

  // When a user edits an email template a `customTemplate` object is created which is associated
  // to the email template in question. Customised templates (i.e. those with the `customTemplate` field)
  // can be previewed and reset. Once a customised email template is reset, the `customTemplate`
  // object associated with it is destroyed and the `customTemplate` field is no longer present.
  const handleEdit = templateCode => {
    window.location.href = Routes.editOrganisersEventCommunicationPath({
      event_id: eventSlug,
      id: templateCode,
      _options: true,
    });
  };

  // When a user stops an email from being sent, a `customTemplate` object is created which is associated
  // to the email template in question. Stopped emails can be resumed, although doing so does not reset the
  // template back to default.
  const handleStop = async templateCode => {
    setTemplateToStop(templateCode);
    setStopEmailModalOpen(true);
  };

  const confirmStop = async () => {
    const endpoint = Routes.stopOrganisersEventCommunicationPath({
      event_id: eventSlug,
      id: templateToStop,
      _options: true,
    });

    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );

      // Refresh the page to request the updated email template list.
      // We don't throw an error if we encounter a HTTP error (`!res.ok`)
      // because this logic is handled by the controller action when
      // a request is unsuccessful (i.e. outside of the 2xx range)
      window.location.reload();
    } catch (err) {
      logger.error(err);

      // There was a network error
      addAlert({
        active: true,
        text: t('table.stop-error-message'),
        pattern: 'error',
      });
    }
  };

  const handleResume = async templateCode => {
    setTemplateToResume(templateCode);
    setResumeEmailModalOpen(true);
  };

  const confirmResume = async () => {
    const endpoint = Routes.resumeOrganisersEventCommunicationPath({
      event_id: eventSlug,
      id: templateToResume,
      _options: true,
    });

    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );

      // Refresh the page to request the updated email template list.
      // We don't throw an error if we encounter a HTTP error (`!res.ok`)
      // because this logic is handled by the controller action when
      // a request is unsuccessful (i.e. outside of the 2xx range)
      window.location.reload();
    } catch (err) {
      logger.error(err);

      // There was a network error
      addAlert({
        active: true,
        text: t('table.resume-error-message'),
        pattern: 'error',
      });
    }
  };

  // When a user resets a previously customised email template, the `customTemplate` object
  // associated with it is destroyed and the `customTemplate` field is no longer present.
  // The user will no longer have the option to preview or reset the template.
  const handleReset = async templateCode => {
    setTemplateToReset(templateCode);
    setResetEmailModalOpen(true);
  };

  const confirmReset = async () => {
    const endpoint = Routes.organisersEventCommunicationPath({
      event_id: eventSlug,
      id: templateToReset,
      _options: true,
    });

    try {
      await apiClient.del(endpoint, {
        headers: {
          Accept: 'application/json',
        },
      });

      // Refresh the page to request the updated email template list.
      // We don't throw an error if we encounter a HTTP error (`!res.ok`)
      // because this logic is handled by the controller action when
      // a request is unsuccessful (i.e. outside of the 2xx range)
      window.location.reload();
    } catch (err) {
      logger.error(err);

      // There was a network error
      addAlert({
        active: true,
        text: t('table.reset-error-message'),
        pattern: 'error',
      });
    }
  };

  const handlePreview = customTemplateId => {
    window.location.href = Routes.previewOrganisersEventCommunicationPath({
      event_id: eventSlug,
      id: customTemplateId,
      _options: true,
    });
  };

  return (
    <>
      <Alerts />

      <StyledTable hasActions hasHighlights>
        <Thead>
          <TableRow isHeading>
            <StyledTableHeading scope="col">
              <Text pattern="caption" color="grey-600">
                {t('table.email-name')}
              </Text>
            </StyledTableHeading>
            <StyledTableHeading scope="col">
              <Text pattern="caption" color="grey-600">
                {t('table.description')}
              </Text>
            </StyledTableHeading>
            <StyledTableHeading scope="col">
              <Text pattern="caption" color="grey-600">
                {t('table.subject')}
              </Text>
            </StyledTableHeading>
            <StyledTableHeading scope="col">
              <Text pattern="caption" color="grey-600">
                {t('table.status')}
              </Text>
            </StyledTableHeading>
          </TableRow>
        </Thead>

        <tbody>
          {emailTemplates.map(
            ({ code, name, description, subject, customTemplate }) => (
              <TableRow key={code} id={`template-${code}`}>
                <StyledTableCell>
                  <Text
                    pattern="body"
                    color={
                      disabledTemplate(customTemplate) ? 'grey-600' : 'grey-800'
                    }
                  >
                    {name}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text
                    pattern="body"
                    color={
                      disabledTemplate(customTemplate) ? 'grey-600' : 'grey-800'
                    }
                  >
                    {description}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text
                    pattern="body"
                    color={
                      disabledTemplate(customTemplate) ? 'grey-600' : 'grey-800'
                    }
                  >
                    {customTemplate?.subject || subject}
                  </Text>
                </StyledTableCell>
                <StyledStatusTableCell>
                  <Status statusKey={templateStatus(customTemplate)} />
                </StyledStatusTableCell>
                <StyledTableRowActions>
                  <TableRowAction
                    icon="edit"
                    title={t('table.edit')}
                    onClick={() => handleEdit(code)}
                  />
                  {customTemplate?.stopped ? (
                    <TableRowAction
                      icon="arrow-forward-circle"
                      title={t('table.resume')}
                      onClick={() => handleResume(customTemplate.id)}
                    />
                  ) : (
                    <TableRowAction
                      icon="disable"
                      title={t('table.stop')}
                      onClick={() => handleStop(code)}
                    />
                  )}
                  {customTemplate && (
                    <>
                      <TableRowAction
                        icon="reload"
                        title={t('table.reset')}
                        onClick={() => handleReset(customTemplate.id)}
                      />
                      <TableRowAction
                        icon="open-in-new"
                        title={t('table.preview')}
                        onClick={() => handlePreview(customTemplate.id)}
                      />
                    </>
                  )}
                </StyledTableRowActions>
              </TableRow>
            ),
          )}
        </tbody>
      </StyledTable>
      <ResetEmailModal
        isModalDisplayed={isResetEmailModalOpen}
        handleClose={() => setResetEmailModalOpen(false)}
        confirmReset={confirmReset}
      />
      <StopEmailModal
        isModalDisplayed={isStopEmailModalOpen}
        handleClose={() => setStopEmailModalOpen(false)}
        confirmStop={confirmStop}
      />
      <ResumeEmailModal
        isModalDisplayed={isResumeEmailModalOpen}
        handleClose={() => setResumeEmailModalOpen(false)}
        confirmResume={confirmResume}
      />
    </>
  );
};

CustomiseEmailsTable.propTypes = {
  authenticityToken: string.isRequired,
  eventSlug: string.isRequired,
  emailsSuppressed: bool.isRequired,
  emailTemplates: arrayOf(
    shape({
      code: string.isRequired,
      description: string.isRequired,
      name: string.isRequired,
      subject: string.isRequired,
      customTemplate: shape({
        id: number,
        subject: string,
        stopped: bool,
      }),
    }),
  ).isRequired,
};

export default compose(
  withLocalizationProvider,
  withAlertsProvider,
)(CustomiseEmailsTable);
