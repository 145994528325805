import ChevronLeft from 'icons/chevron-left.svg';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledAnchor = styled.a`
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  height: var(--spacing-24);
  width: var(--spacing-24);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey-600);
`;

const Label = styled.span`
  margin-left: var(--spacing-8);
  font-size: var(--font-size-smaller);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = ({ label, url }) => (
  <Wrapper>
    <StyledAnchor href={url}>
      <ChevronLeft />
    </StyledAnchor>
    <Label>{label}</Label>
  </Wrapper>
);

BackButton.propTypes = {
  label: string,
  url: string,
};

export default BackButton;
