import { PaymentPlanCard, PlanName } from '@components/payment-plan/v2/styles';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { withSpace } from '@hopin-team/ui-helper-with-space';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import Clock from 'icons/clock.svg';
import Star from 'icons/star.svg';
import Ticket from 'icons/ticket.svg';
import { bool, func, number, shape, string } from 'prop-types';
import React, { useContext } from 'react';

import { UpgradeLink } from './billing-container.styles';
import BillingItem from './billing-item';
import ProgressBar from './progress-bar';
import RegistrationWrapper from './registration-wrapper';

export const translationKeyPrefix =
  'onboarding.organization-dashboard.payment-plan';

const currentColor = 'var(--color-gray-650)';
const DivWithSpace = withSpace('div');

const BillingContainer = ({
  organization,
  paymentPlan,
  onUpgradePlanClick,
}) => {
  const { t } = useContext(LocalizationContext);

  const {
    isFreePlan,
    isAdvancedPlan,
    isAppsumoPlan,
    name,
    maxEventLengthHours,
  } = paymentPlan;

  const {
    registrationsUsagePercentage,
    totalFreeRegistrationsAppsumo,
    totalPaidRegistrationsAppsumo,
    totalRegistrationsAvailable,
    totalRegistrationsUsed,
  } = organization;
  const isUpgradeable = !isAdvancedPlan && !isAppsumoPlan;

  return (
    <PaymentPlanCard p="3" data-testid="payment-plan">
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text scale={5} my={1}>
          {t(`${translationKeyPrefix}.title`)}
        </Text>
        {isUpgradeable && (
          <UpgradeLink
            data-testid="upgrade-plan"
            href={Routes.organiserBillingPath()}
            onClick={onUpgradePlanClick}
          >
            <Star color="current" />
            {t(`${translationKeyPrefix}.upgrade`)}
          </UpgradeLink>
        )}
      </Flex>

      <PlanName
        element="div"
        color="grey-900"
        mb={3}
        scale={7}
        font="text"
        weight="bold"
        data-testid="current-plan"
      >
        {name}
      </PlanName>

      {isFreePlan ? (
        <Flex
          justifyContent="space-between"
          data-testid="subscription-wrapper"
          mb={2}
        >
          <BillingItem
            icon={<Clock color={currentColor} />}
            label={t(
              `${translationKeyPrefix}.subscription-wrapper-v2.eventLength.title`,
            )}
            content={t(
              `${translationKeyPrefix}.subscription-wrapper-v2.eventLength.length`,
              { eventLength: maxEventLengthHours },
            )}
          />

          <BillingItem
            icon={<Ticket color={currentColor} />}
            label={t(
              `${translationKeyPrefix}.subscription-wrapper-v2.registrations.label`,
            )}
            content={totalRegistrationsAvailable}
          />
        </Flex>
      ) : (
        <DivWithSpace mt={5}>
          <RegistrationWrapper
            isAppsumoPlan={isAppsumoPlan}
            isAdvancedPlan={isAdvancedPlan}
            totalFreeRegistrationsAppsumo={totalFreeRegistrationsAppsumo}
            totalPaidRegistrationsAppsumo={totalPaidRegistrationsAppsumo}
            totalRegistrationsUsed={totalRegistrationsUsed}
            totalRegistrationsAvailable={totalRegistrationsAvailable}
            t={t}
            registrationsUsagePercentage={registrationsUsagePercentage}
          />
          <div data-testid="progress-bar">
            <ProgressBar percentage={registrationsUsagePercentage || 0} />
          </div>
        </DivWithSpace>
      )}
    </PaymentPlanCard>
  );
};

BillingContainer.propTypes = {
  organization: shape({
    name: string.isRequired,
    totalFreeRegistrationsAppsumo: number,
    totalPaidRegistrationsAppsumo: number,
    totalRegistrationsAvailable: number.isRequired,
    totalRegistrationsUsed: number.isRequired,
    registrationsUsagePercentage: number.isRequired,
  }),
  paymentPlan: shape({
    isFreePlan: bool.isRequired,
    isAdvancedPlan: bool.isRequired,
    isAppsumoPlan: bool.isRequired,
    maxEventLengthHours: number.isRequired,
  }),
  onUpgradePlanClick: func,
};

export default withLocalizationProvider(BillingContainer);
