import SummaryMetricItem from '@features/dashboard/common/summary-metric-item';
import { useLocalization } from '@features/localization';
import ChatMessagesSVG from 'icons/metrics/chat-messages.svg';
import ConnectionRequestsSVG from 'icons/metrics/connection-requests.svg';
import CountriesRepresentedSVG from 'icons/metrics/countries-represented.svg';
import NetworkingMeetingsSVG from 'icons/metrics/networking-meetings.svg';
import RegistrationsSVG from 'icons/metrics/registrations.svg';
import ScheduledMeetingsSVG from 'icons/metrics/scheduled-meetings.svg';
import TicketSalesSVG from 'icons/metrics/ticket-sales.svg';
import TicketsSoldSVG from 'icons/metrics/tickets-sold.svg';
import { bool, number, oneOfType, string } from 'prop-types';
import React from 'react';

export const TicketsSold = ({
  value,
  isLoading,
  show,
  newRegistrationsDashboardEnabled,
  newRegistrationsDefinitionsEnabled,
}) => {
  const { t } = useLocalization(
    'registrations-dashboard.tickets-sold-component',
  );
  const text = newRegistrationsDashboardEnabled ? t('title-v1') : t('title-v0');

  let tooltipText = '';
  if (newRegistrationsDefinitionsEnabled) {
    tooltipText = t('tooltip-v1');
  } else if (newRegistrationsDashboardEnabled) {
    tooltipText = t('tooltip-v0');
  }

  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<TicketsSoldSVG />}
      value={value}
      text={text}
      show={show}
      tooltipText={tooltipText}
    />
  );
};

TicketsSold.propTypes = {
  value: oneOfType([string, number]).isRequired,
  newRegistrationsDashboardEnabled: bool.isRequired,
  newRegistrationsDefinitionsEnabled: bool.isRequired,
  isLoading: bool,
  show: bool,
};

export const TicketSales = ({ value, isLoading, show }) => {
  const { t } = useLocalization(
    'registrations-dashboard.ticket-sales-component',
  );
  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<TicketSalesSVG />}
      value={value}
      text={t('title')}
      show={show}
    />
  );
};

TicketSales.propTypes = {
  value: string.isRequired,
  isLoading: bool,
  show: bool,
};

export const RegistrationsCount = ({
  value,
  isLoading,
  color,
  show,
  newRegistrationsDashboardEnabled,
}) => {
  const { t } = useLocalization(
    'registrations-dashboard.registrations-count-component',
  );
  const text = newRegistrationsDashboardEnabled ? t('title-v1') : t('title-v0');
  const tooltipText = newRegistrationsDashboardEnabled ? t('tooltip') : '';

  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<RegistrationsSVG />}
      value={value}
      text={text}
      color={color}
      show={show}
      tooltipText={tooltipText}
    />
  );
};

RegistrationsCount.propTypes = {
  value: number.isRequired,
  isLoading: bool,
  color: string,
  show: bool,
  newRegistrationsDashboardEnabled: bool,
};

export const CountriesRepresented = ({ value, isLoading, color, show }) => {
  const { t } = useLocalization(
    'registrations-dashboard.countries-represented-component',
  );

  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<CountriesRepresentedSVG />}
      value={value}
      text={t('title')}
      color={color}
      show={show}
    />
  );
};

CountriesRepresented.propTypes = {
  value: number.isRequired,
  isLoading: bool,
  color: string,
  show: bool,
};

export const ChatMessages = ({ value, isLoading, color }) => {
  const { t } = useLocalization('connections-dashboard');

  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<ChatMessagesSVG />}
      value={value}
      text={t('chat-messages')}
      color={color}
    />
  );
};

ChatMessages.propTypes = {
  value: number.isRequired,
  isLoading: bool,
  color: string,
};

export const NetworkingMeetings = ({ value, isLoading, color, show }) => {
  const { t } = useLocalization('connections-dashboard');

  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<NetworkingMeetingsSVG />}
      value={value}
      text={t('networking-meetings')}
      color={color}
      show={show}
    />
  );
};

NetworkingMeetings.propTypes = {
  value: number.isRequired,
  isLoading: bool,
  color: string,
  show: bool,
};

export const ConnectionRequests = ({ value, isLoading }) => {
  const { t } = useLocalization('connections-dashboard');

  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<ConnectionRequestsSVG />}
      value={value}
      text={t('connection-requests')}
    />
  );
};

ConnectionRequests.propTypes = {
  value: number.isRequired,
  isLoading: bool,
};

export const ScheduledMeetings = ({ value, isLoading }) => {
  const { t } = useLocalization('connections-dashboard');

  return (
    <SummaryMetricItem
      isLoading={isLoading}
      icon={<ScheduledMeetingsSVG />}
      value={value}
      text={t('scheduled-meetings')}
    />
  );
};

ScheduledMeetings.propTypes = {
  value: number.isRequired,
  isLoading: bool,
};
