import { timezonesMap } from '@features/constants/timezones-map';
import {
  setEventLocationAddress,
  setEventLocationName,
  setEventLocationType,
} from '@features/event-creation-wizard/redux/event-location/event-location-slice';
import { setEventType } from '@features/event-creation-wizard/redux/event-type/event-type-slice';
import { setBasicDetails } from '@features/event-creation-wizard/redux/step-specific-state/basic-details-slice';
import { setEventAccess } from '@features/event-creation-wizard/redux/step-specific-state/event-access-slice';
import { getEventValidations } from '@features/event-creation-wizard/steps/details/hooks/validations/event-setup-form-validations';
import { useLocalization } from '@features/localization';
import { differenceInHours } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import { getSelectedTimezone } from '../../../utils/time-zone-selectors';

const useEventSetupForm = () => {
  const dispatch = useDispatch();
  const {
    name,
    endDate,
    startDate,
    maxEventLengthHours,
    eventPassword,
  } = useSelector(state => state.basicDetails);
  const eventAccess = useSelector(state => state.eventAccess);
  const eventType = useSelector(state => state.eventType);
  const {
    type: eventLocationType,
    name: eventLocationName,
    address: eventLocationAddress,
  } = useSelector(state => state.eventLocation);

  const defaultEndDate = () => {
    const newEndDate = new Date(endDate);
    const newStartDate = new Date(startDate);
    const hours = differenceInHours(newEndDate, newStartDate);

    if (hours > 72) {
      return newEndDate.setDate(newStartDate.getDate() + 3);
    } else {
      return newEndDate;
    }
  };

  const defaultValues = {
    name,
    startDate: new Date(startDate),
    endDate: defaultEndDate(),
    eventAccess,
    eventType,
    eventLocationType,
    eventLocationName,
    eventLocationAddress:
      typeof eventLocationAddress === 'string'
        ? eventLocationAddress
        : JSON.stringify(eventLocationAddress),
    eventPassword,
    timezone: getSelectedTimezone().value,
  };

  const { t } = useLocalization('event-creation-wizard.steps.details.form');

  const {
    errors,
    register,
    setValue,
    formState,
    handleSubmit,
    ...rest
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: getEventValidations(defaultValues, maxEventLengthHours, t),
  });

  const onSubmit = async values => {
    const ianaTz = timezonesMap[values.timezone];
    const basicDetails = {
      name: values.name,
      timezone: values.timezone,
      startDate: zonedTimeToUtc(values.startDate, ianaTz),
      endDate: zonedTimeToUtc(values.endDate, ianaTz),
      maxEventLengthHours,
      eventPassword: values.eventPassword,
    };

    batch(() => {
      dispatch(setBasicDetails(basicDetails));
      dispatch(setEventAccess(values.eventAccess));
      dispatch(setEventType(values.eventType));
      values.eventLocationType &&
        dispatch(setEventLocationType(values.eventLocationType));
      dispatch(setEventLocationName(values.eventLocationName));
      values.eventLocationAddress &&
        dispatch(
          setEventLocationAddress(
            typeof values.eventLocationAddress === 'string'
              ? JSON.parse(values.eventLocationAddress)
              : values.eventLocationAddress,
          ),
        );
    });
  };

  return {
    errors,
    register,
    setValue,
    formState,
    onClickNext: handleSubmit(onSubmit),
    ...rest,
  };
};

export default useEventSetupForm;
