import hopinApi from '@api/hopin';
import Loading from '@components/loading';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import compose from 'lodash/fp/compose';
import { number, shape, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import EditForm from './edit-form';
import {
  ContainerWrapper,
  CustomizeEmailsPrimaryButton,
  CustomizeEmailsSecondaryButton,
  RightPanel,
  StickyDiv,
} from './styles';

const CustomiseEmailsEdit = ({ event, emailTemplateId }) => {
  const { t } = useLocalization('communications.customise-emails');
  const { addAlert } = useContext(AlertsContext);
  const { organiser_id: organiserId, id: eventId, slug: eventSlug } = event;
  const [token, setToken] = useState(null);
  const form = useForm({ mode: 'onChange' });
  const { setValue, handleSubmit, getValues, reset, errors } = form;
  const [isLoading, setIsLoading] = useState(true);
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { token } = await hopinApi.getUserToken();
        setToken(token);

        const templateData = await hopinApi.getEmailTemplate(
          token,
          organiserId,
          eventId,
          emailTemplateId,
        );

        setTemplate(
          (({ id, name, body, subject }) => ({
            id,
            name,
            body,
            subject,
          }))(templateData),
        );
        setIsLoading(false);
      } catch (e) {
        addAlert({
          active: true,
          text: t('edit.errors.default'),
          pattern: 'error',
        });
      }
    }
    fetchData();
  }, [
    addAlert,
    emailTemplateId,
    eventId,
    organiserId,
    setTemplate,
    setValue,
    setIsLoading,
    t,
  ]);

  useEffect(() => {
    if (!isLoading && template?.id) {
      reset({
        subject: template.subject,
        body: template.body,
      });
    }
  }, [template, isLoading, reset, getValues, setValue]);

  const saveFormData = useCallback(
    async data => {
      if (!Object.keys(errors).length) {
        const attr = (({ subject, body }) => ({
          subject,
          body,
        }))(data);

        try {
          await hopinApi.saveDraftEmailTemplate(
            token,
            organiserId,
            eventId,
            emailTemplateId,
            attr,
          );

          window.location.assign(
            `/organisers/events/${eventSlug}/communications/${emailTemplateId}/preview`,
          );
        } catch (e) {
          addAlert({
            active: true,
            text: t('edit.errors.default'),
            pattern: 'error',
          });
        }
      }
    },
    [
      emailTemplateId,
      eventId,
      eventSlug,
      organiserId,
      token,
      errors,
      t,
      addAlert,
    ],
  );

  const onSubmit = async formData => {
    await saveFormData(formData);
  };

  const onCancel = () => {
    window.location.assign(`/organisers/events/${eventSlug}/communications`);
  };

  return (
    <ContainerWrapper>
      <Alerts />
      <Loading isLoading={isLoading}>
        {!isLoading && (
          <FormProvider {...form}>
            <EditForm template={template} t={t} />
          </FormProvider>
        )}
      </Loading>

      <StickyDiv>
        <Flex justifyContent="space-between">
          <RightPanel>
            <CustomizeEmailsSecondaryButton
              isInline
              isOutlined
              pattern="secondary"
              data-testid="cancel-button"
              onClick={onCancel}
            >
              <Text>{t('edit.cancel.title')}</Text>
            </CustomizeEmailsSecondaryButton>
            <CustomizeEmailsPrimaryButton
              isInline
              data-testid="save-draft-button"
              onClick={handleSubmit(onSubmit)}
            >
              <Text>{t('edit.continue-to-preview.title')}</Text>
            </CustomizeEmailsPrimaryButton>
          </RightPanel>
        </Flex>
      </StickyDiv>
    </ContainerWrapper>
  );
};

CustomiseEmailsEdit.propTypes = {
  event: shape({
    id: number.isRequired,
    name: string.isRequired,
    picture: string.isRequired,
    slug: string.isRequired,
  }),
  emailTemplateId: string.isRequired,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
  withThemeProvider,
)(CustomiseEmailsEdit);
