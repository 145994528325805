import {
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import compose from 'lodash/fp/compose';
import { func } from 'prop-types';
import React from 'react';

import PostSignupView from './post-signup-view';

const translationKeyPrefix = 'signup.email-verification-failure';

const PostSignupEmailFailureComponent = ({ t }) => (
  <PostSignupView
    image={
      <img
        alt="email verification failure"
        src="/images/email-verification-failure.png"
      />
    }
    t={t}
    translationKeyPrefix={translationKeyPrefix}
  />
);

PostSignupEmailFailureComponent.propTypes = {
  t: func.isRequired,
};

export default compose(
  withLocalizationProvider,
  withLocalization,
)(PostSignupEmailFailureComponent);
