import { Button } from '@hopin-team/ui-button';
import { Grid } from '@hopin-team/ui-grid';
import { SpeakerCard } from '@hopin-team/ui-speaker-card';
import { array, bool } from 'prop-types';
import React, { useState } from 'react';

import { Styles } from './speakers-styles';

const NR_OF_VISIBLE_ITEMS = 6;

const Speakers = ({ speakers, viewAll }) => {
  const [viewAllSpeakers, setViewAllSpeakers] = useState(false);
  const scheduleList =
    viewAll || viewAllSpeakers
      ? speakers
      : speakers.slice(0, NR_OF_VISIBLE_ITEMS);
  const showCompleteScheduleButton =
    !viewAll && speakers.length > NR_OF_VISIBLE_ITEMS;
  const title = viewAllSpeakers ? 'Hide speakers' : 'View all speakers';

  const handleToggleViewAllSpeakers = () => {
    setViewAllSpeakers(!viewAllSpeakers);
  };

  return (
    <Styles.Grid>
      <Grid gridColumnGap="24px" gridRowGap="24px">
        {scheduleList.map((speaker, idx) => (
          <SpeakerCard
            key={idx}
            className={'event-page_speaker-card'}
            name={speaker.name}
            headline={speaker.headline}
            pictureUrl={speaker.picture_url}
            linkedin={speaker.linkedin}
            bio={speaker.headline}
            website={speaker.website}
            twitter={speaker.twitter}
            institution={speaker.institution}
          />
        ))}
      </Grid>
      {showCompleteScheduleButton && (
        <Button
          isInline
          isOutlined
          size="medium"
          mt={3}
          onClick={handleToggleViewAllSpeakers}
        >
          {title}
        </Button>
      )}
    </Styles.Grid>
  );
};

Speakers.propTypes = {
  speakers: array,
  viewAll: bool,
};

export default Speakers;
