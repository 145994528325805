import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { TextField } from '@hopin-team/ui-text-field';
import { bool, object, shape, string } from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import QuestionVisibilityButton from './question-visibility-button';
import {
  QuestionCard,
  questionCardStyles,
  QuestionWrapper,
  StyledTextField,
} from './styles';
import SurveyFieldWrapper from './survey-field-wrapper';

const TextQuestion = ({ fieldName, control, canEditSurvey, error }) => {
  const { t } = useLocalization();

  return (
    <QuestionCard {...questionCardStyles} data-testid="text-question">
      <Flex flex={2} flexDirection="column">
        <QuestionWrapper>
          <Controller
            name={`${fieldName}.question`}
            control={control}
            rules={{ required: t('survey-builder.edit.field-required') }}
            render={({ onChange, value, ref }) => (
              <SurveyFieldWrapper canEditSurvey={canEditSurvey}>
                <StyledTextField
                  label={t('survey-builder.edit.text-question')}
                  name={fieldName}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  currentCount={value.length}
                  maxCount={200}
                  mb={3}
                  leadingIcon="paragraph-text"
                  background="var(--color-orange-600)"
                  disabled={!canEditSurvey}
                  hasErrors={!!error}
                  errorMessage={error?.question.message}
                />
              </SurveyFieldWrapper>
            )}
          />
        </QuestionWrapper>
        <Box ml={4.5}>
          <ReadonlyField
            placeholder={t('survey-builder.edit.type-answer')}
            isDisabled
            multiLine
          />
        </Box>
      </Flex>
      <StyledBox>
        <QuestionVisibilityButton
          control={control}
          fieldName={fieldName}
          canEditSurvey={canEditSurvey}
        />
      </StyledBox>
    </QuestionCard>
  );
};

TextQuestion.propTypes = {
  fieldName: string.isRequired,
  control: object.isRequired,
  canEditSurvey: bool.isRequired,
  error: shape({ question: shape({ type: string, message: string }) }),
};

export default TextQuestion;

const ReadonlyField = styled(TextField)`
  width: 95%;

  .hpn-FieldLabel {
    display: none; // TODO(EN-566) - replace with hideLabel prop once TextField version has been updated
  }

  .hpn-TextField__Input:disabled {
    background: var(--color-white);
  }
`;

const StyledBox = styled(Box)`
  height: 100%;
`;
