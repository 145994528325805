import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { calcSpace, LINE } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import { shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ButtonCTA from './button-cta';
import EventOffer from './event-offer';
import SocialMediaLink from './social-media-link';

export const Section = styled(Flex)`
  margin-top: ${calcSpace(1)};
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: ${calcSpace(2)};
  border-bottom ${LINE.DEFAULT_BORDER};
`;

const socialMediaTypes = [
  'instagram',
  'linkedin',
  'facebook',
  'twitter',
  'website',
];

const VendorTabPreview = ({ vendor, ...restProps }) => {
  const {
    logoUrl,
    name,
    headline,
    about,
    buttonText,
    buttonAction,
    offer,
    website,
  } = vendor;
  const links = socialMediaTypes.map(type => ({ type, url: vendor[type] }));
  return (
    <>
      <ContentWrapper data-testid={restProps['data-testid']}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Section>
            <img
              data-testid="logo"
              style={{ maxWidth: '50%' }}
              src={logoUrl}
              alt={`${name} logo`}
            />
          </Section>
          <Section>
            <Text pattern="strong" color="grey-black" scale={3} align="center">
              {name}
            </Text>
          </Section>
          <Section>
            <Text pattern="body" color="grey-700" scale={3} align="center">
              {headline}
            </Text>
          </Section>
          {links.some(item => item.url) && (
            <Section justifyContent={'space-evenly'}>
              {links.map(({ type, url }) => (
                <SocialMediaLink
                  key={type}
                  data-testid={`${type}-icon`}
                  href={url}
                >
                  <Icon
                    scale={1.5}
                    m={1}
                    data-testid={`${type}-icon`}
                    color="grey-600"
                    name={type}
                  />
                </SocialMediaLink>
              ))}
            </Section>
          )}
          {about && (
            <Section mt={2}>
              <Text pattern="body" color="grey-700" scale={3} align="center">
                {about}
              </Text>
            </Section>
          )}
          {!offer && (
            <Section>
              <ButtonCTA
                data-testid="no-offer-cta-button"
                website={website}
                buttonAction={buttonAction}
                actionButtonText={buttonText}
              />
            </Section>
          )}
        </Flex>
      </ContentWrapper>
      {offer && (
        <EventOffer
          data-testid="event-offer-section"
          offer={offer}
          website={website}
          buttonAction={buttonAction}
          buttonText={buttonText}
        />
      )}
    </>
  );
};

VendorTabPreview.propTypes = {
  vendor: shape({
    name: string,
    logoUrl: string,
    headline: string,
    about: string,
    buttonText: string,
    facebook: string,
    instagram: string,
    linkedin: string,
    twitter: string,
    website: string,
  }),
  ['data-testid']: string,
};

export default VendorTabPreview;
