import OnsiteBadgeDesigner from '@features/onsite/badge-designer';
import OnsiteCheckInArea from '@features/onsite/check-in-area';
import OnsiteFloorPlan from '@features/onsite/floor-plan';
import OnsiteKioskMode from '@features/onsite/kiosk-mode';
import OnsiteSignatures from '@features/onsite/signatures';
import SchedulesSliceComponent from '@features/schedules/schedules-slice-component';
import * as Routes from '@routes';
import get from 'lodash/get';
import { matchPath } from 'react-router';

import {
  eventHasSimplifiedNav,
  getNavTreeByEventWizardTemplate,
} from './constants';

export const componentsWithoutLayout = ['schedules'];

export const componentRoutes = [
  {
    id: 'schedules',
    path: Routes.organisersEventSchedulesPath(':slug'),
    Component: SchedulesSliceComponent,
    title: 'Manage Schedules | RingCentral Events',
  },
  {
    id: 'check-in-area',
    path: Routes.organisersEventCheckInAreaPath(':slug'),
    Component: OnsiteCheckInArea,
    title: 'Check-in Areas | RingCentral Events',
  },
  {
    id: 'kiosk-mode',
    path: Routes.organisersEventKioskModePath(':slug'),
    Component: OnsiteKioskMode,
    title: 'Kiosk Mode | RingCentral Events',
  },
  {
    id: 'badge-designer',
    path: Routes.organisersEventBadgeDesignerPath(':slug'),
    Component: OnsiteBadgeDesigner,
    title: 'Your Badges | RingCentral Events',
  },
  {
    id: 'floor-plan',
    path: Routes.organisersEventFloorPlanPath(':slug'),
    Component: OnsiteFloorPlan,
    title: 'Floor Plans | RingCentral Events',
  },
  {
    id: 'signatures',
    path: Routes.organisersEventSignaturesPath(':slug'),
    Component: OnsiteSignatures,
    title: 'Signatures | RingCentral Events',
  },
];

function castToBoolean(object) {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if (['true', 'false'].includes(value)) {
        value = value === 'true';
      }

      return [key, value];
    }),
  );
}

export const componentExists = location =>
  !!componentRoutes.find(({ path }) => matchPath(location, { path }));

export const paidPlanFeatures = ['registrants', 'speakers'];

function getConfig({
  newRegistrationsAttendeesEnabled,
  creationEnableGlobalTags,
  event: {
    isAnonymised,
    areas,
    slug,
    organization,
    primaryBackstageId,
    session,
    isLite,
  },
  newRegistrationsUrl,
  newRegistrationsDashboardEnabled,
  hopinCanvasEnabled,
  ticketGroupsDeprecated,
  onsiteUpgradeFlowEnabled,
  customDomainsShowPage,
  venueType,
  isAppAreaVisible,
  surveyBuilderEnabled,
  showEngagementScore,
  reactPagesVariation,
  onsiteEventDashboardStreamline,
}) {
  const { currentPaymentPlan, maxStages, hasAnalytics } = organization;
  const features = castToBoolean(currentPaymentPlan.features);
  const attendeesPath = newRegistrationsAttendeesEnabled
    ? Routes.organisersEventNewAttendeesPath(slug)
    : Routes.organisersEventAttendeesPath(slug);
  const engagementPageMigrated = reactPagesVariation?.includes(
    'engagement_summary',
  );
  const engagementPath = engagementPageMigrated
    ? `/organisers/events/${slug}/engagement_summary`
    : Routes.organisersEventConnectionsSummaryPath(slug);
  const announcementsMigrated = reactPagesVariation?.includes('announcements');
  const communicationsMigrated = reactPagesVariation?.includes(
    'communications',
  );
  const customDomainsPath = `/organisers/events/${slug}/custom_domains`;
  const isOnsiteEvent = venueType === 'onsite';

  const config = {
    overview: {
      route: Routes.organisersEventOverviewPath(slug),
      active: true,
    },
    /**
     * When Essentials was developed, we designed a separate Basics page for it,
     * which is hosted on hopin-web (we don't have a valid rails route) and thus we ended up
     * with two different Basics pages.
     * The plan for the future is to have one Basics page to serve Virtual Events and Lite Events
     */
    basics: {
      route: isLite
        ? Routes.organisersEventBasicsPath(slug).replace('basics', 'setup')
        : Routes.organisersEventBasicsPath(slug),
      active: true,
    },
    theme: {
      route: Routes.organisersEventDesignPath(slug),
      active: true,
    },
    registration: {
      route: Routes.registrationOrganisersEventPath(slug),
      active: !newRegistrationsDashboardEnabled,
    },
    // registrations
    details: {
      route: newRegistrationsUrl + '/details?slug=' + slug,
      active: newRegistrationsDashboardEnabled,
    },
    about: {
      route: Routes.registrationOrganisersEventPath(slug),
      active: newRegistrationsDashboardEnabled && !hopinCanvasEnabled,
    },
    'ticket-groups': {
      route: newRegistrationsUrl + '/event-tracks?slug=' + slug,
      active: newRegistrationsDashboardEnabled && !ticketGroupsDeprecated,
    },
    'registrations-tickets': {
      route: newRegistrationsUrl + '/tickets?slug=' + slug,
      active: newRegistrationsDashboardEnabled,
    },
    'registration-form': {
      route: newRegistrationsUrl + '/form-builder?slug=' + slug,
      active: newRegistrationsDashboardEnabled,
    },
    'registration-event-landing-page': {
      route: newRegistrationsUrl + '/registration-page?slug=' + slug,
      active: newRegistrationsDashboardEnabled && hopinCanvasEnabled,
    },
    // venue
    reception: {
      route: Routes.receptionFormOrganisersEventPath(slug),
      active: areas.reception,
    },
    schedule: {
      route: Routes.organisersEventSchedulesPath(slug),
      active: true,
    },
    stage: {
      route: Routes.editOrganisersEventBackstagePath(slug, primaryBackstageId),
      active: areas.stage && maxStages <= 1,
    },
    stages: {
      route: Routes.organisersEventBackstagesPath(slug),
      active:
        onsiteEventDashboardStreamline && isOnsiteEvent
          ? false
          : areas.stage && maxStages > 1,
    },
    session: {
      route: Routes.editOrganisersEventTimetableRoundtablePath(
        slug,
        session?.eventPartId || 0,
        session?.id || 0,
      ),
      active: areas.sessions && isLite && session !== null,
    },
    sessions: {
      route: Routes.organisersEventRoundtablesPath(slug),
      active:
        onsiteEventDashboardStreamline && isOnsiteEvent
          ? false
          : areas.sessions && !isLite,
    },
    'venue-controls-people': {
      route: Routes.peopleOrganisersEventPath(slug),
      active: areas.people,
    },
    surveys: {
      route: Routes.organisersEventSurveysPath(slug),
      active: surveyBuilderEnabled,
    },
    networking: {
      route: Routes.networkingOrganisersEventPath(slug),
      active: !areas.people && areas.networking,
    },
    expo: {
      route: Routes.organisersEventVendorsPath(slug),
      active: areas.expo,
    },
    sponsors: {
      route: Routes.organisersEventSponsorsPath(slug),
      active: true,
    },
    tags: {
      route: Routes.organisersEventEventTagsPath(slug),
      active: creationEnableGlobalTags,
    },
    'venue-controls': {
      route: Routes.organisersEventVenueControlsPath(slug),
      active: true,
    },
    'app-area': {
      route: Routes.organisersEventAppAreaPath(slug),
      active: areas.app && isAppAreaVisible,
    },
    // onsite
    ...onsiteMenu(
      slug,
      venueType,
      {
        onsiteUpgradeFlowEnabled,
        customDomainsShowPage,
      },
      features,
    ),
    // people
    registrants: {
      route: attendeesPath,
      active: true,
    },
    speakers: {
      route: Routes.organisersEventSpeakersPath(slug),
      active: true,
    },
    'magic-link-invites': {
      route: Routes.organisersEventRedeemCodesPath(slug),
      active: features?.magicLinks,
    },
    // marketing
    'email-attendees': {
      route: Routes.organisersEventAnnouncementsPath(slug),
      active: !announcementsMigrated && features?.sendAttendeesEmails,
    },
    'customize-emails': {
      route: Routes.organisersEventCommunicationsPath(slug),
      active: !communicationsMigrated && features?.customEmails,
    },
    emails: {
      route: Routes.organisersEventEmailsPath(slug),
      active:
        (announcementsMigrated && features?.sendAttendeesEmails) ||
        (communicationsMigrated && features?.customEmails),
    },
    'sharing-and-tracking': {
      route: Routes.organisersEventMarketingPath(slug),
      active: true,
    },
    'utm-codes': {
      route: Routes.organisersEventUtmCodesPath(slug),
      active: true,
    },
    // advanced
    'customize-text': {
      route: Routes.textOrganisersEventPath(slug),
      active: features?.customText,
    },
    'custom-registration-fields': {
      route: Routes.organisersEventRegistrationFieldsPath(slug),
      active:
        !newRegistrationsDashboardEnabled && features?.customRegistrationFields,
    },
    'host-information': {
      route: Routes.organisersEventHostInformationPath(slug),
      active: features?.hostDefinitions,
    },
    'custom-domain': {
      route: Routes.organisersEventCustomDomainPath(slug),
      active:
        features?.customDomains &&
        ['SHOW_CLASSIC', 'SHOW_BOTH'].includes(customDomainsShowPage),
    },
    'custom-domains': {
      route: customDomainsPath,
      active:
        features?.customDomains &&
        ['SHOW_NEW', 'SHOW_BOTH'].includes(customDomainsShowPage),
    },
    recordings: {
      route: Routes.organisersEventRecordingsPath(slug),
      active: true,
    },
    // analytics
    engagement: {
      route: engagementPath,
      active: !isAnonymised && (showEngagementScore || engagementPageMigrated),
    },
    'live-analytics': {
      route: Routes.organisersEventLiveAnalyticsPath(slug),
      active: !isAnonymised && hasAnalytics,
    },
    reports: {
      route: Routes.organisersEventReportsPath(slug),
      active: !isAnonymised,
    },
    registrations: {
      route: Routes.organisersEventRegistrationsSummaryPath(slug),
      active: !isAnonymised,
    },
    connections: {
      route: Routes.organisersEventConnectionsSummaryPath(slug),
      active: !isAnonymised && !(showEngagementScore || engagementPageMigrated),
    },
    'view-polls': {
      route: Routes.organisersEventEventPollsPath(slug),
      active: !isAnonymised,
    },
    'expo-summary': {
      route: Routes.organisersEventExpoSummaryPath(slug),
      active: !isAnonymised && areas.expo,
    },
  };

  return config;
}

const hasSubItems = navItem => {
  const supportingFields = ['__title'];

  const numberOfSupportingFields = Object.keys(navItem).filter(key =>
    supportingFields.includes(key),
  ).length;

  return Object.keys(navItem).length - numberOfSupportingFields > 0;
};

function link(tree, config) {
  return Object.keys(tree).reduce((acc, item) => {
    const children = tree[item];
    const navItem = config[item];

    const hasNestedChildren = hasSubItems(children);

    if (hasNestedChildren) {
      const itemChildren = link(children, config);
      if (Object.keys(itemChildren).length) {
        acc[item] = {
          children: itemChildren,
          parent: acc,
          title: item,
        };
      }
    } else if (navItem?.active) {
      acc[children.__title || item] = navItem.route;
    }

    return acc;
  }, {});
}

function makeReverseMap(obj, parentKey = []) {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (k === 'parent') {
      return acc;
    }
    if (v instanceof Object) {
      return { ...acc, ...makeReverseMap(v, [...parentKey, k]) };
    }
    if (typeof v === 'string') {
      const parentNode = [...parentKey, k];
      return { ...acc, [v]: parentNode };
    }
  }, {});
}

function resolveException(path, reverseMap, config) {
  const exceptions = [
    [
      /organisers\/events\/[\w\d-]+\/schedule\/[\d]+\/roundtables/,
      config.sessions,
    ],
    [/organisers\/events\/[\w\d-]+\/reports/, config.reports],
    [/organisers\/events\/[\d]+\/backstages/, config.stages],
    [/organisers\/events\/[\d]+\/speakers/, config.speakers],
  ];

  return exceptions.reduce((acc, [regExp, match]) => {
    if (regExp.test(path)) {
      return reverseMap[match];
    }
    return acc;
  }, null);
}

function resolveContext(locationObject) {
  const { pathname, search } = locationObject;
  const params = new URLSearchParams(search);
  const context = params.get('context');
  if (context) {
    return `${pathname}/${context}`;
  }
  return pathname;
}

const getTemplateModifier = event => {
  let modifier = 'before';
  if (event.phase === 'Live') {
    modifier = 'during';
  }
  if (event.phase === 'Ended') {
    modifier = 'after';
  }
  return modifier;
};

const getTreeByTemplate = (
  eventWizardTemplate,
  advancedToggle,
  event,
  reorderTabs,
) => {
  const modifier = getTemplateModifier(event);

  const defaultTree = getNavTreeByEventWizardTemplate({
    template: 'default',
    modifier,
    reorderTabs,
  });

  const templateTree = getNavTreeByEventWizardTemplate({
    template: eventWizardTemplate,
    modifier,
    reorderTabs,
  });

  if (eventHasSimplifiedNav(eventWizardTemplate)) {
    return advancedToggle ? defaultTree : templateTree;
  }

  return templateTree;
};

function makeNavTree({
  event,
  newRegistrationsAttendeesEnabled,
  creationEnableGlobalTags,
  newRegistrationsUrl,
  newRegistrationsDashboardEnabled,
  hopinCanvasEnabled,
  ticketGroupsDeprecated,
  onsiteUpgradeFlowEnabled,
  customDomainsShowPage,
  venueType,
  advancedToggle,
  isAppAreaVisible,
  surveyBuilderEnabled,
  reorderTabs = false,
  showEngagementScore,
  reactPagesVariation,
  onsiteEventDashboardStreamline = false,
}) {
  if (!event.slug) {
    return { tree: null, resolveTree: () => null };
  }
  const config = getConfig({
    event,
    newRegistrationsAttendeesEnabled,
    creationEnableGlobalTags,
    newRegistrationsUrl,
    newRegistrationsDashboardEnabled,
    hopinCanvasEnabled,
    ticketGroupsDeprecated,
    onsiteUpgradeFlowEnabled,
    customDomainsShowPage,
    venueType,
    isAppAreaVisible,
    surveyBuilderEnabled,
    showEngagementScore,
    reactPagesVariation,
    onsiteEventDashboardStreamline,
  });
  const { eventWizardTemplate } = event;

  const navTree = getTreeByTemplate(
    eventWizardTemplate,
    advancedToggle,
    event,
    reorderTabs,
  );

  const tree = {
    parent: null,
    children: link(navTree, config),
  };

  const reverseMap = makeReverseMap(tree);

  const resolveTree = locationObject => {
    const currentPath = resolveContext(locationObject);
    let reverse = reverseMap[currentPath];
    if (!reverse) {
      const splitPath = currentPath.split('/');
      for (let i = splitPath.length - 1; i > 0 && !reverse; i--) {
        const subPath = splitPath.slice(0, i).join('/');
        reverse = reverseMap[subPath];
      }
      if (!reverse) {
        reverse = resolveException(currentPath, reverseMap, config);
      }
    }
    const treePath = reverse?.slice(0, reverse.length - 2);
    return treePath
      ? {
          tree: get(tree, treePath, tree),
          key: [...reverse].pop(),
          rootKey: reverse[1],
        }
      : { tree };
  };

  return { tree, resolveTree };
}

/**
 * @typedef OnsiteMenuFlags
 * @type {object}
 * @prop {boolean} onsiteUpgradeFlowEnabled - Onsite's add on upgrade flow enabled
 */

/**
 * Onsite's menu
 * @param {string} slug
 * @param {string} venueType - virtual | hybrid | onsite
 * @param {OnsiteMenuFlags} flags - Onsite's flags
 * @param {object} feature - Current plan features
 */
function onsiteMenu(slug, venueType, flags, features) {
  let menu = {};
  const isVirtualEvent = venueType === 'virtual';
  const isOnsiteEnabled =
    features?.onsiteHybridEvents ||
    features?.onsiteAdvancedAddOn ||
    flags.onsiteUpgradeFlowEnabled;

  if (isVirtualEvent || !isOnsiteEnabled) return menu;

  return {
    'check-in-areas': {
      route: Routes.organisersEventCheckInAreaPath(slug),
      active: features?.onsiteHybridEvents,
    },
    'kiosk-mode': {
      route: Routes.organisersEventKioskModePath(slug),
      active: features?.onsiteAdvancedAddOn || flags.onsiteUpgradeFlowEnabled,
    },
    badges: {
      route: Routes.organisersEventBadgeDesignerPath(slug),
      active: features?.onsiteAdvancedAddOn || flags.onsiteUpgradeFlowEnabled,
    },
    'floor-plan': {
      route: Routes.organisersEventFloorPlanPath(slug),
      active: features?.onsiteHybridEvents,
    },
    signatures: {
      route: Routes.organisersEventSignaturesPath(slug),
      active: features?.onsiteAdvancedAddOn || flags.onsiteUpgradeFlowEnabled,
    },
  };
}

export default makeNavTree;
