import { withSpace } from '@hopin-team/ui-helper-with-space';
import { calcSpace } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import ArrowDownIcon from 'icons/arrow-down.svg';
import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

const errorStyle = css`
  border-color: var(--color-red-400);
`;

const inputStyle = css`
  width: 100%;
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  line-height: 1.28;
  color: var(--color-gray-800);
  border: 0.0625rem solid var(--color-gray-300);
  border-radius: var(--border-radius-small);
  outline: 0;
  transition: box-shadow 200ms ease-out, border-color 200ms ease-out;

  ${({ error }) => (error ? errorStyle : '')}

  ${({ tiny }) =>
    tiny
      ? `
  padding: ${rem(7)} ${rem(10)};
  font-size: var(--font-size-small);
  `
      : `
  padding: ${rem(15)} ${rem(10)};
  font-size: var(--font-size);
  `}

  &:hover {
    box-shadow: 0 0.0625rem 0.125rem rgba(46, 63, 119, 0.08);
  }

  &:focus {
    border-color: var(--color-blue-400);
    box-shadow: 0 0 0.125rem 0.125rem rgba(23, 95, 255, 0.15);
  }

  &:placeholder {
    color: var(--color-gray-600);
  }
`;

export const Input = styled.input`
  ${inputStyle}
`;

const StyledSelect = styled.select`
  ${inputStyle}
  appearance: none;
`;

const SelectWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: ${calcSpace(2)};
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const Select = withSpace(
  // eslint-disable-next-line react/prop-types
  React.forwardRef(({ className, ...props }, ref) => (
    <SelectWrapper className={className}>
      <StyledSelect {...props} ref={ref} />
      <ArrowDownIcon />
    </SelectWrapper>
  )),
);

export const Textarea = styled.textarea`
  ${inputStyle}
`;

export const Label = styled(Text).attrs({
  element: 'label',
  pattern: 'labelTwo',
  mb: 1,
})`
  display: block;
`;
