import {
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import Image from 'icons/post-signup/verification.svg';
import compose from 'lodash/fp/compose';
import { func, string } from 'prop-types';
import React from 'react';

import PostSignupView from './post-signup-view';

const translationKeyPrefix = 'signup.post-signup';

const PostSignupComponent = ({ t, email }) => (
  <PostSignupView
    email={email}
    image={<Image />}
    t={t}
    translationKeyPrefix={translationKeyPrefix}
  />
);

PostSignupComponent.propTypes = {
  t: func.isRequired,
  email: string.isRequired,
};

export default compose(
  withLocalizationProvider,
  withLocalization,
)(PostSignupComponent);
