/* global Rails */
import * as Routes from '@routes';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import Pusher from 'pusher-js';

const APP_KEY = process.env.PUSHER_KEY;
const CLUSTER = process.env.PUSHER_CLUSTER;
export let pusher;
export const logger = getLogger(LOGGER_NAMES.DEMO);

export const pusherInitialize = channel => {
  pusher = new Pusher(APP_KEY, {
    auth: { headers: { 'X-CSRF-Token': Rails.csrfToken() } },
    authEndpoint: Routes.createDemoEventPusherAuthPath(channel),
    cluster: CLUSTER,
    encrypted: true,
  });
};

export const pusherBindDemoEvent = ({
  channelName,
  sectionFinishHandler,
  successHandler,
  errorHandler,
}) => {
  const channel = pusher.subscribe(channelName);
  channel.bind('event_duplication_finished', successHandler);
  channel.bind(
    'event_duplication_event_section_finished',
    sectionFinishHandler,
  );
  channel.bind('event_duplication_failed', errorHandler);
  return () => {
    pusher.unsubscribe(channel);
  };
};
