import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { bool, number, shape, string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import DoorsOpenModal from './doors-open-modal';

const DoorsOpenDetails = ({ eventId, organization, supportUrl }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useLocalization();
  const { advancedPlan, growthPlan } = organization;
  const showEmailLink = advancedPlan || growthPlan;

  return (
    <>
      <Text
        pattern="labelThree"
        color="grey-600"
        mt={1}
        mb={2}
        style={{ display: 'inline-block' }}
      >
        {t('basic-settings.pre-event.details', { duration: '2 weeks' })}{' '}
      </Text>
      <StyledText
        pattern="labelThree"
        color="blue-500"
        onClick={() => setModalOpen(true)}
        data-testid="learn-more-modal-link"
      >
        {t('basic-settings.pre-event.learn-more')}
      </StyledText>
      <DoorsOpenModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        eventId={eventId}
        showEmailLink={showEmailLink}
        supportUrl={supportUrl}
      />
    </>
  );
};

export default withLocalizationProvider(DoorsOpenDetails);

DoorsOpenDetails.propTypes = {
  supportUrl: string,
  eventId: number,
  organization: shape({
    advancedPlan: bool,
    growthPlan: bool,
  }),
};

const StyledText = styled(Text)`
  cursor: pointer;
`;
