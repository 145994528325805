import { useEffect, useState } from 'react';

export const usePromise = (generator, consumer, dependencies = []) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const abortController = new AbortController();
    const promises = generator(abortController.signal);
    setLoading(true);
    if (promises.length) {
      Promise.all(promises).then(results => {
        consumer(results);
        setLoading(false);
      });
    } else {
      promises.then(result => {
        consumer(result);
        setLoading(false);
      });
      promises.catch(() => {
        setLoading(false);
      });
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return loading;
};
