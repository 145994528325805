import analyticsApi from '@api/analytics';
import Card from '@components/card-with-loading';
import LinkComponent from '@features/dashboard/common/link-component';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import TicketIcon from 'icons/ticket.svg';
import { bool, string } from 'prop-types';
import React, { useState } from 'react';

import {
  PercentageSold,
  SingleTicket,
  TicketDetails,
} from '../styles/ticket-sales-by-type.styles';
import { usePromise } from '../util/hooks';

// If withRegistrations is true, ticket claimed data is matched with registrations data
// so we only return tickets claimed by people that have a registrations record.
// If it is false, we get tickets claimed data from the registrations component on the backend
// which can include ticket purchased by guest users
const TicketSalesByTypeComponent = ({
  eventSlug,
  newRegistrationsDashboardEnabled,
  newRegistrationsDashboardUrl,
}) => {
  const { t } = useLocalization(
    'registrations-dashboard.ticket-sales-by-type-component',
  );
  const [tickets, setTickets] = useState([]);
  const limit = 6;
  const sortBy = 'percentage_sold';
  const withRegistrations = !newRegistrationsDashboardEnabled;
  const loading = usePromise(
    signal =>
      analyticsApi.getTicketSalesByType(
        eventSlug,
        signal,
        limit,
        sortBy,
        withRegistrations,
      ),
    result => setTickets(result.data),
  );

  let ticketsInfo;
  const ticketManagementUrl = `${newRegistrationsDashboardUrl}/tickets?slug=${eventSlug}`;

  if (tickets.length < 1) {
    ticketsInfo = (
      <EmptyState
        text={t('empty-state')}
        icon={<TicketIcon />}
        button={
          <Button type="button" size="small" href={ticketManagementUrl}>
            {t('add-tickets')}
          </Button>
        }
      />
    );
  } else {
    ticketsInfo = (
      <>
        <div>
          {tickets.map((ticket, i) => (
            <SingleTicket key={i}>
              <TicketDetails>
                <span className="label">{ticket.label}</span>
                <span className="price">
                  {ticket.price_with_currency_symbol}
                </span>
                <span className="fraction">{ticket.fraction_sold}</span>
              </TicketDetails>
              <PercentageSold width={ticket.percentage_sold}>
                <div></div>
              </PercentageSold>
            </SingleTicket>
          ))}
        </div>
        <LinkComponent
          linkTo={ticketManagementUrl}
          linkText={t('ticket-link')}
        />
      </>
    );
  }

  return (
    <Card isLoading={loading} testId="ticket-sales-by-type">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        {ticketsInfo}
      </CardContentWrapper>
    </Card>
  );
};

TicketSalesByTypeComponent.propTypes = {
  eventSlug: string.isRequired,
  newRegistrationsDashboardEnabled: bool.isRequired,
  newRegistrationsDashboardUrl: string.isRequired,
};

export default TicketSalesByTypeComponent;
