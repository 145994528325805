import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { bool, func, node, string } from 'prop-types';
import React from 'react';

import StyledChecklistItem from '../styled/checklist-item';
import ChecklistItemIcon from './checklist-item-icon';

const ChecklistItem = ({
  children,
  description,
  iconName,
  isChecked,
  link,
  onClick,
  openInNewTab,
  text,
}) => {
  const { t } = useLocalization('overview.event-setup-checklist');
  return (
    <StyledChecklistItem
      {...(link && {
        href: link,
        ...(openInNewTab && { rel: 'noopener', target: '_blank' }),
      })}
      data-testid="event-progress-checklist-item"
      aria-label={`${
        isChecked ? t('completed-step') : t('incomplete-step')
      }: ${text}. ${description}`.trim()}
      onClick={onClick}
    >
      <ChecklistItemIcon name={iconName} isChecked={isChecked} />
      <Box
        css={`
          width: 100%;
        `}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          css={`
            width: 100%;
          `}
        >
          <Box ml={2}>
            <Text pattern="labelOne" element="p" weight="bold">
              {text}
            </Text>
            <Text pattern="body" element="p" color="grey-600">
              {description}
            </Text>
          </Box>
          {link && (
            <Icon name="arrow-forward" color="grey-600" scale={2} ml={3} />
          )}
        </Flex>
        {children && (
          <Box ml={2} mt={2}>
            {children}
          </Box>
        )}
      </Box>
    </StyledChecklistItem>
  );
};

ChecklistItem.propTypes = {
  children: node,
  description: string.isRequired,
  iconName: string.isRequired,
  isChecked: bool,
  link: string,
  onClick: func,
  openInNewTab: bool,
  text: string.isRequired,
};

export default ChecklistItem;
