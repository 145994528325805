import { Halon } from '@hopin-team/ui-halon';
import { Icon } from '@hopin-team/ui-icon';
import { bool, string } from 'prop-types';
import React from 'react';

import StyledChecklistItemIcon from '../styled/checklist-item-icon';

const ChecklistItemIcon = ({ isChecked, name }) => (
  <StyledChecklistItemIcon>
    {isChecked && (
      <Halon
        data-testid="event-progress-checklist-item-checkmark"
        scale={2}
        iconScale={1.5}
        icon="checkmark"
        color="blue-500"
        iconColor="grey-white"
      />
    )}
    <Icon name={name} color="blue-300" scale={3} />
  </StyledChecklistItemIcon>
);

ChecklistItemIcon.propTypes = {
  isChecked: bool,
  name: string.isRequired,
};

export default ChecklistItemIcon;
