/* global Rails */
import isClientside from '@util/is-clientside';
import ky from 'ky-universal';

let client = {};

if (isClientside()) {
  client = ky.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/vnd.api+json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
    throwHttpErrors: false,
  });
}

const BASE_PATH = '/people/api';

const peopleApi = {
  getPeopleSettings(eventId, token) {
    return client
      .get(`${BASE_PATH}/organiser/events/${eventId}/event_setting`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  updatePeopleSettings(eventId, token, data) {
    return client
      .put(`${BASE_PATH}/organiser/events/${eventId}/event_setting`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          event_setting: data,
        },
      })
      .json();
  },
};

export default peopleApi;
