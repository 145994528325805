import { useLocalization } from '@features/localization';
import { useDispatch } from 'react-redux';

import { updateRecording as updateRecordingApi, ValidationError } from '../api';
import { updateRecording as updateRecordingInStore } from '../redux/slices/recordings/recordings-slice';

export const useUpdateRecording = ({ slug, onSuccess, onError }) => {
  const { t } = useLocalization('recording-group');
  const dispatch = useDispatch();

  const updateRecording = async (originalRecording, attributes) => {
    const { id } = originalRecording;

    try {
      dispatch(updateRecordingInStore({ id, ...attributes }));

      const updatedRecording = await updateRecordingApi(id, attributes, slug);
      dispatch(updateRecordingInStore({ id, ...updatedRecording }));
      if (onSuccess) onSuccess({ originalRecording, updatedRecording });
    } catch (error) {
      dispatch(updateRecordingInStore({ id, ...originalRecording }));

      let message = t('something-went-wrong-try-later');

      if (error && error instanceof ValidationError) {
        message = error.message;
      }

      if (onError) {
        onError({ error, message, originalRecording });
      }
    }
  };

  return { updateRecording };
};
