import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > * + * {
    margin-top: ${rem(16)};
  }

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    margin-bottom: 0;
  }
`;

export const Card = styled.label`
  display: flex;
  align-items: center;
  border: 0.0625rem solid
    ${({ active }) => (active ? '#CAE7FF' : 'transparent')};
  background-color: ${({ active }) => (active ? '#E8F1F7' : '#F6F5F1')};
  border-radius: ${rem(14)};
  padding: ${rem(12)};
  cursor: pointer;

  &:has(:focus-visible) {
    outline-color: var(--color-blue-500);
    outline-style: solid;
  }

  &:hover {
    ${({ active }) =>
      active
        ? css`
            box-shadow: 0px 10px 20px -5px rgba(0, 64, 221, 0.1);
          `
        : css`
            background-color: #f0efe8;
          `}
  }
`;

export const Title = styled(Text)`
  line-height: ${rem(14)};
`;

export const Description = styled(Text)`
  line-height: ${rem(20)};
`;

export const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const WhiteCircle = styled.div`
  width: 20px;
  height: 20px;
  background-color: #f6f5f1;
  border-radius: 50%;
  border: 1px solid #c5c7cd;
`;
