import { useReducer } from 'react';

const BULK_ACTIONS = {
  CANCEL: 'CANCEL',
  SELECT_ALL: 'SELECT_ALL',
  SET_MAGIC_LINKS: 'SET_MAGIC_LINKS',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_SELECTED_IDS: 'SET_SELECTED_IDS',
};

const reducer = (state, action) => {
  switch (action.type) {
    case BULK_ACTIONS.CANCEL:
      return {};
    case BULK_ACTIONS.SELECT_ALL:
      return {
        ...state,
        selectedAll: action.payload,
      };
    case BULK_ACTIONS.SET_MAGIC_LINKS:
      return {
        ...state,
        magicLinks: action.payload,
      };
    case BULK_ACTIONS.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };

    case BULK_ACTIONS.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case BULK_ACTIONS.SET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: action.payload,
      };

    default:
      return state;
  }
};

const useBulkActionReducer = (magicLinks, pagination, currentPage) => {
  const [state, dispatch] = useReducer(reducer, {
    selectedIds: [],
    selectedAll: false,
    magicLinks: magicLinks,
    pagination: pagination,
    currentPage: currentPage,
  });

  const setSelectedAll = isSelected => {
    dispatch({ type: BULK_ACTIONS.SELECT_ALL, payload: isSelected });
  };

  const setPagination = pagination => {
    dispatch({ type: BULK_ACTIONS.SET_PAGINATION, payload: pagination });
  };

  const setMagicLinks = magicLinks => {
    dispatch({ type: BULK_ACTIONS.SET_MAGIC_LINKS, payload: magicLinks });
  };

  const setCurrentPage = page => {
    dispatch({ type: BULK_ACTIONS.SET_CURRENT_PAGE, payload: page });
  };

  const setSelectedIds = ids => {
    dispatch({ type: BULK_ACTIONS.SET_SELECTED_IDS, payload: ids });
  };

  return {
    magicLinks: state.magicLinks,
    pagination: state.pagination,
    selectedAll: state.selectedAll,
    currentPage: state.currentPage,
    selectedIds: state.selectedIds,
    setSelectedAll,
    setSelectedIds,
    setPagination,
    setMagicLinks,
    setCurrentPage,
  };
};

export default useBulkActionReducer;
