import { useLocalization } from '@features/localization';
import { RegistrationContext } from '@features/overview/container';
import { Button } from '@hopin-team/ui-button';
import * as Routes from '@routes';
import { func, object, string } from 'prop-types';
import React, { useContext } from 'react';

import { STEPS } from '../../constants/steps';
import ChecklistItemsList from '../../styled/checklist-items-list';
import {
  registrationRegistrationPagePath,
  registrationTicketsRedirect,
} from '../../utils/registration-routes';
import { isStepComplete } from '../../utils/steps';
import ChecklistItem from '../checklist-item';

const SetupEventTabPanel = ({
  checklistStatuses,
  eventSlug,
  onSkipLandingPage,
}) => {
  const regContext = useContext(RegistrationContext);
  const { t } = useLocalization('overview.event-setup-checklist.tab-panels');
  return (
    <ChecklistItemsList>
      <ChecklistItem
        description={t('setup-event.event-details.description')}
        iconName="wand"
        isChecked={isStepComplete(STEPS.DETAILS, checklistStatuses)}
        link={Routes.organisersEventBasicsPath(eventSlug)}
        text={t('setup-event.event-details.heading')}
      />
      <ChecklistItem
        description={t('setup-event.tickets-and-registration.description')}
        iconName="browser-content"
        isChecked={isStepComplete(STEPS.TICKETS, checklistStatuses)}
        link={registrationTicketsRedirect(
          regContext.newRegistrationsUrl,
          eventSlug,
        )}
        text={t('setup-event.tickets-and-registration.heading')}
      />
      <ChecklistItem
        description={t('setup-event.landing-page.description')}
        iconName="desktop"
        isChecked={isStepComplete(STEPS.LANDING_PAGE, checklistStatuses)}
        link={
          regContext.newRegistrationsEnabled
            ? registrationRegistrationPagePath(
                regContext.newRegistrationsUrl,
                eventSlug,
              )
            : Routes.registrationOrganisersEventPath(eventSlug)
        }
        text={t('setup-event.landing-page.heading')}
      >
        {!isStepComplete(STEPS.LANDING_PAGE, checklistStatuses) && (
          <Button
            data-testid="event-progress-skip-landing-page"
            isInline
            isOutlined
            onClick={e => {
              e.preventDefault();
              onSkipLandingPage();
            }}
            size="small"
            type="button"
          >
            {t('skip-step')}
          </Button>
        )}
      </ChecklistItem>
    </ChecklistItemsList>
  );
};

SetupEventTabPanel.propTypes = {
  checklistStatuses: object.isRequired,
  eventSlug: string.isRequired,
  onSkipLandingPage: func.isRequired,
};

export default SetupEventTabPanel;
