import { rem } from 'polished';
import styled from 'styled-components';

export const FOOTER_HEIGHT = 68;

const Container = styled.footer`
  position: fixed;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: ${rem(FOOTER_HEIGHT)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: var(--spacing-40);
  padding-right: var(--spacing-40);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-gray-300);

  button + button {
    margin-left: ${rem(16)};
  }
`;

export { Container };
