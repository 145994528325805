import AlertsProvider from '@features/alerts/alerts-provider';
import { LocalizationProvider } from '@features/localization';
import PathSelection from '@features/post-registration/components/path-selection/path-selection';
import { ReactIDProvider } from '@hopin-team/react-id';
import { bool, string } from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

const PostRegistrationApp = ({ userExternalId, isTestUser }) => (
  <>
    <Helmet>
      <title>Onboarding | RingCentral Events</title>
    </Helmet>

    <BrowserRouter>
      <LocalizationProvider>
        <AlertsProvider>
          <ReactIDProvider>
            <PathSelection
              userExternalId={userExternalId}
              isTestUser={isTestUser}
            />
          </ReactIDProvider>
        </AlertsProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </>
);

PostRegistrationApp.propTypes = {
  userExternalId: string.isRequired,
  isTestUser: bool.isRequired,
};

export default PostRegistrationApp;
