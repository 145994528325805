import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import StatusCountTile from './status-count-tile';

const TileWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: var(--spacing-32);
  margin-bottom: var(--spacing-32);
`;

const StatusCount = ({ redeemCodesStatus, renameInvitedToPending }) => {
  const [statusCount, setStatusCount] = useState(redeemCodesStatus);
  const handleSend = useCallback(
    ({ detail = {} }) => {
      const quantity = detail.quantity ? detail.quantity : 1;
      setStatusCount({
        ...statusCount,
        invited: statusCount.invited + quantity,
        notYetInvited: statusCount.notYetInvited - quantity,
        declined: statusCount.declined,
      });
    },
    [statusCount, setStatusCount],
  );

  const handleRemove = useCallback(
    ({ detail = {} }) => {
      const newStatusCount = Object.keys(statusCount).reduce((acc, type) => {
        const deleteCount = detail[type] || 0;
        acc[type] = statusCount[type] - deleteCount;
        return acc;
      }, {});

      setStatusCount(newStatusCount);
    },
    [statusCount, setStatusCount],
  );

  useEffect(() => {
    window.addEventListener('inviteSent', handleSend);
    window.addEventListener('inviteRemoved', handleRemove);

    return () => {
      window.removeEventListener('inviteSent', handleSend);
      window.removeEventListener('inviteRemoved', handleRemove);
    };
  }, [handleSend, handleRemove]);

  return (
    <TileWrapper>
      {Object.keys(statusCount).map(type => (
        <StatusCountTile
          key={type}
          type={type}
          count={statusCount[type]}
          renameInvitedToPending={renameInvitedToPending}
        />
      ))}
    </TileWrapper>
  );
};

StatusCount.propTypes = {
  redeemCodesStatus: PropTypes.objectOf(PropTypes.number),
  renameInvitedToPending: PropTypes.bool,
};

export default StatusCount;
