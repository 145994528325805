import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { object } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

const ModeratedQuestions = ({ control }) => {
  const { t } = useLocalization('venue-controls.q-and-a-moderation');

  const {
    field: { onChange, value, name },
  } = useController({
    name: 'venue_settings[moderated_questions]',
    control,
  });

  return (
    <>
      <Text
        mt={3}
        element="h3"
        pattern="headingThree"
        color="grey-800"
        weight="medium"
        scale={4}
      >
        {t('title-redesign')}
      </Text>
      <Text element="p" mt={1} pattern="body" scale={4} color="grey-600">
        {t('heading-redesign')}
      </Text>
      <Flex alignItems="center" mt={1}>
        <Checkbox
          name={name}
          data-testid="limit-connections-checkbox"
          id="venue_settings_moderated_questions"
          onChange={event => onChange(event.target.checked)}
          checked={!!value}
          value={value}
          label={t('enable-redesign')}
        />
      </Flex>
    </>
  );
};

ModeratedQuestions.propTypes = {
  control: object.isRequired,
};

export default withLocalizationProvider(ModeratedQuestions);
