import AddOnSelector from '@features/event-creation-wizard/components/add-on-selector';
import StepHeader from '@features/event-creation-wizard/components/step-header';
import { templates } from '@features/event-creation-wizard/constants/templates';
import {
  selectAddonConfig,
  setAddOns,
} from '@features/event-creation-wizard/redux/add-ons/add-ons-slice';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import { bool } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const FeaturesComponent = ({ canSkip }) => {
  const { t } = useLocalization('event-creation-wizard.steps.add-ons');
  const dispatch = useDispatch();

  const featuresData = useSelector(selectAddonConfig);
  const venue = useSelector(state => state.venue);
  const addOns = useSelector(state => state.addOns);
  const { id: templateId } = useSelector(state => state.template);
  const { isOnFreePlan } = useSelector(state => state.org);

  const getSortedFeatures = (template, recommended) => {
    return Object.entries({
      ...template.defaultAddOns,
      ...template.defaultVenue,
    })
      .filter(feat => feat[1].selected === recommended && featuresData[feat[0]])
      .map(feat => featuresData[feat[0]]);
  };
  const recommendedFeatures = getSortedFeatures(templates[templateId], true);
  const youMightAlsoLikeFeatures = getSortedFeatures(
    templates[templateId],
    false,
  );

  const showHeaders =
    recommendedFeatures.length > 0 && youMightAlsoLikeFeatures.length > 0;

  const updateFeature = feature => {
    if (feature.slice === 'venue') {
      const payload = {
        ...venue,
        [feature.id]: { ...venue[feature.id], selected: !feature.selected },
      };
      dispatch(setVenue({ venue: payload, isOnFreePlan }));
    } else {
      const payload = {
        ...addOns,
        [feature.id]: { ...addOns[feature.id], selected: !feature.selected },
      };
      dispatch(setAddOns(payload));
    }
  };

  return (
    <EventCreationWizardStep canSkip={canSkip} onSave={() => {}}>
      <StepHeader title={t('title')} subtitle={t('subtitle')} />
      <AddOnSelector
        titleKey={showHeaders && t('recommended-features-heading')}
        features={recommendedFeatures}
        onChange={feature => updateFeature(feature)}
      />
      <AddOnSelector
        titleKey={showHeaders && t('you-may-also-like-heading')}
        features={youMightAlsoLikeFeatures}
        onChange={feature => updateFeature(feature)}
      />
    </EventCreationWizardStep>
  );
};

FeaturesComponent.propTypes = {
  canSkip: bool,
};

export default FeaturesComponent;
