import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import TicketMatchingModalComponent from '@features/people/components/ticket-matching-modal-container';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Card } from '@hopin-team/ui-card';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { TYPE } from './category-types';

const MatchmakingCard = ({
  authToken,
  event,
  title,
  type,
  id,
  isDefault,
  details,
  footnote,
  matchmakingOption,
  onUseOption,
  hasSettings,
  image,
  personas,
  categoriesData,
  lockProfileMatching,
  setShowSettingsModal,
}) => {
  const { t } = useLocalization();
  const isSelected = matchmakingOption === id;
  const ticketsNeeded = type === 'ticket_holder' && personas?.length < 2;

  const handleChangeSettings = () => {
    setShowSettingsModal(true);
  };

  return (
    <>
      <StyledCard
        cornerRadius="sm"
        level="base"
        p={3}
        data-testid={isSelected ? 'selected' : 'not-selected'}
        isSelected={isSelected}
        mr={3}
      >
        <StyledBox>
          <div>
            <Title id="card-header" element="h4" pattern="headingFour" mb={0.5}>
              {title}{' '}
              {isDefault && (
                <span>{t('networking.matchmaking.card-common.default')}</span>
              )}
            </Title>
            <Details element="p" pattern="body" mb={3}>
              {details}
            </Details>
          </div>
          {image}
        </StyledBox>
        <Box>
          <Text element="p" pattern="body" scale={3} color="grey-600" mb={4.5}>
            {ticketsNeeded
              ? t('networking.matchmaking.ticket.missing-tickets')
              : footnote}
          </Text>
        </Box>
        <Box data-testid={`${type}-actions`}>
          {isSelected ? (
            <FakeButton
              isInline
              onClick={() => {}}
              size="small"
              mr={1.5}
              isOutlined
            >
              <Icon name="checkmark" isParentColor scale={2} mr={0.75} />{' '}
              {t('networking.matchmaking.card-common.cta-two')}
            </FakeButton>
          ) : (
            <Button
              isInline
              onClick={() => onUseOption(id, type)}
              size="small"
              mr={1.5}
              disabled={ticketsNeeded}
            >
              {t('networking.matchmaking.card-common.cta-one')}
            </Button>
          )}
          {hasSettings && (
            <StyledButton
              isInline
              onClick={handleChangeSettings}
              size="small"
              isOutlined
              disabled={
                lockProfileMatching &&
                (isEmpty(categoriesData) || !categoriesData)
              }
            >
              {isEmpty(categoriesData) || !categoriesData
                ? t('networking.matchmaking.card-common.cta-three')
                : lockProfileMatching
                ? t('people.people-area.preview-settings')
                : t('networking.matchmaking.card-common.cta-three')}
            </StyledButton>
          )}
          {type === 'ticket_holder' && !ticketsNeeded && (
            <TicketMatchingModalComponent
              authToken={authToken}
              eventId={event.id}
              personas={personas}
              useNewModal={true}
              buttonText={t('networking.matchmaking.card-common.cta-three')}
              t={t}
            />
          )}
        </Box>
      </StyledCard>
    </>
  );
};

export default withLocalizationProvider(MatchmakingCard);

MatchmakingCard.propTypes = {
  authToken: PropTypes.string.isRequired,
  event: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string.isRequired,
    network_type: PropTypes.string.isRequired,
    external_id: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  isDefault: PropTypes.bool,
  matchmakingOption: PropTypes.number,
  hasSettings: PropTypes.bool,
  details: PropTypes.string.isRequired,
  footnote: PropTypes.string.isRequired,
  onUseOption: PropTypes.func,
  setShowSettingsModal: PropTypes.func,
  image: PropTypes.object,
  id: PropTypes.number,
  personas: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      matchedIds: PropTypes.arrayOf(PropTypes.number),
      label: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  lockProfileMatching: PropTypes.bool,
  categoriesData: PropTypes.arrayOf(
    PropTypes.shape({
      reactHookFormId: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
      name: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
          .isRequired,
      ),
    }),
  ),
};

const StyledCard = styled(Card)`
  position: relative;
  width: 23rem;
  background-color: ${({ isSelected }) =>
    isSelected && 'var(--color-blue-100)'};
  border: ${({ isSelected }) =>
    isSelected && '1px solid var(--color-blue-300)'};
`;

const StyledBox = styled(Box)`
  display: flex;
`;

const Title = styled(Text)`
  span {
    color: var(--color-gray-600);
  }
`;

const Details = styled(Text)`
  height: 4.5rem;
`;

const FakeButton = styled(Button)`
  pointer-events: none;
  background-color: var(--color-white);
`;

const StyledButton = styled(Button)`
  background-color: var(--color-white);
`;
