/* global Rails */
import isClientside from '@util/is-clientside';
import ky from 'ky-universal';

let client = {};

if (isClientside()) {
  client = ky.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/vnd.api+json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
    throwHttpErrors: false,
    timeout: 30000,
  });
}

const BASE_PATH = '/event_templates/api';

const eventTemplatesApi = {
  createBlankEvent(attributes) {
    return client
      .post(`${BASE_PATH}/events`, {
        json: {
          data: {
            type: 'event',
            attributes,
          },
        },
      })
      .json();
  },
  createEventFromTemplate(templateId, attributes) {
    return client
      .post(`${BASE_PATH}/templates/${templateId}/events`, {
        json: {
          data: {
            id: templateId,
            type: 'pipeline_jobs',
            attributes,
          },
        },
      })
      .json();
  },
};

export default eventTemplatesApi;
