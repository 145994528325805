import PropTypes from 'prop-types';
import React from 'react';

import ColorLegendRow from './color-legend-row-component';

const ColorLegend = ({ values, titles, colorArray }) => (
  <>
    {values.map((value, i) => {
      var title = titles[i];
      var color = colorArray[i];
      return (
        <ColorLegendRow key={i} color={color} title={title} value={value} />
      );
    })}
  </>
);

ColorLegend.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ColorLegend;
