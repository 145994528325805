import StepHeader from '@features/event-creation-wizard/components/step-header';
import { setSpeakerDetails } from '@features/event-creation-wizard/redux/step-specific-state/speaker-slice';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import useSpeakerForm from '@features/event-creation-wizard/steps/speakers/hooks/use-speaker-form';
import SpeakerFormComponent from '@features/event-creation-wizard/steps/speakers/speaker-form-component';
import { useLocalization } from '@features/localization';
import { bool } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SpeakersComponent = ({ canSkip }) => {
  const dispatch = useDispatch();
  const defaultValues = useSelector(state => state.speakers);
  const { t } = useLocalization('event-creation-wizard.steps.speakers');

  const onSubmitHandler = useCallback(
    values => dispatch(setSpeakerDetails(values)),
    [dispatch],
  );

  const { register, errors, onSubmit, formState } = useSpeakerForm(
    defaultValues,
    onSubmitHandler,
  );

  return (
    <EventCreationWizardStep
      canSkip={canSkip}
      onSave={onSubmit}
      breadcrumbsDisabled={!canSkip && !formState.isValid}
      continueDisabled={!formState.isValid}
      info={t('info')}
    >
      <StepHeader title={t('title')} subtitle={t('subtitle')} />

      <SpeakerFormComponent register={register} errors={errors} />
    </EventCreationWizardStep>
  );
};

SpeakersComponent.propTypes = {
  canSkip: bool,
};

export default SpeakersComponent;
