import { INTERACTIVE } from '@hopin-team/ui-blocks';
import { Box } from '@hopin-team/ui-box';
import styled, { css } from 'styled-components';

const ChecklistTab = styled(Box).attrs({
  as: 'button',
  cornerRadius: 'lg',
  p: 1,
})`
  ${INTERACTIVE.isClickable}
  width: 100%;
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: rgba(236, 242, 255, 0.5);
    `};
`;

export default ChecklistTab;
