export const FULL_DICTIONARY = {
  exhibitor: 'Exhibitor',
  exhibitor_email: 'Exhibitor Email',
  booth_size: 'Booth Size',
  priority: 'Priority',
  tags: 'Tags',
};

export const MANDATORY_FIELDS = ['exhibitor', 'exhibitor_email', 'booth_size'];

export const BOOTH_SIZE = {
  mini: 100,
  small: 200,
  medium: 300,
  large: 400,
};

export const DEFAULT_BOOTH_SIZE = BOOTH_SIZE.medium;
export const DEFAULT_PRIORITY = 100;

export const View = {
  FILE_SELECTION: 'FileSelection',
  MAP_HEADERS: 'MapHeaders',
  ERROR_REPORT: 'ErrorReport',
};

export const ViewSize = {
  [View.FILE_SELECTION]: 'medium',
  [View.MAP_HEADERS]: 'huge',
  [View.ERROR_REPORT]: 'large',
};

export const JobStatus = {
  QUEUED: 'queued',
  WORKING: 'working',
  RETRYING: 'retrying',
  COMPLETE: 'complete',
  STOPPED: 'stopped',
  FAILED: 'failed',
  INTERRUPTED: 'interrupted',
};
