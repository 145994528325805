import { Box } from '@hopin-team/ui-box';
import { Image } from '@hopin-team/ui-image';
import styled from 'styled-components';

export const TemplateImageBox = styled(Box).attrs({
  cornerRadius: 'lg',
})`
  overflow: hidden;

  ${Image} {
    display: block;
  }
`;
