import { useCallback, useEffect, useRef, useState } from 'react';

import { fetchWorkspaces } from '../fetch-workspaces';

const MAX_NUMBER_OF_TRIES = 3;

export const useWorkspaces = (currentWorkspaceId, eventSlug) => {
  const [isDone, setIsDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const numberOfTriesRef = useRef(0);

  const getWorkspaces = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    numberOfTriesRef.current++;

    fetchWorkspaces(eventSlug)
      .then(async response => {
        const { workspaces } = await response.json();

        if (workspaces) {
          setWorkspaces(workspaces);
        }
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsDone(true);
        setIsLoading(false);
      });
  }, [eventSlug]);

  useEffect(() => {
    if (!currentWorkspaceId) {
      getWorkspaces();
    }
  }, [currentWorkspaceId, getWorkspaces]);

  const shouldAllowRetries = numberOfTriesRef.current < MAX_NUMBER_OF_TRIES;

  return {
    isDone,
    isError,
    isLoading,
    workspaces,
    getWorkspaces,
    shouldAllowRetries,
  };
};
