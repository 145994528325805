import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { object } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

const WidthLimitedText = styled(Text)`
  max-width: 760px;
  width: 100%;
`;

const DeviceAccess = ({ control }) => {
  const { t } = useLocalization('venue-controls.device-access');

  const {
    field: {
      onChange: enabledOnChange,
      name: enabledName,
      value: enabledValue,
    },
  } = useController({
    name: 'venue_settings[limit_multiple_event_connections]',
    control,
  });

  return (
    <>
      <Text element="h3" pattern="headingSix" mb={0.5}>
        {t('heading')}
      </Text>

      <WidthLimitedText element="p" pattern="body" color="grey-600" mb={1.5}>
        {t('summary')}
      </WidthLimitedText>

      <Flex alignItems="center">
        <input
          type="hidden"
          name="venue_settings[limit_multiple_event_connections]"
          value="0"
        />

        <Checkbox
          onChange={event => enabledOnChange(event.target.checked)}
          value={enabledValue}
          name={enabledName}
          checked={!!enabledValue}
          label={t('label')}
        />
      </Flex>
    </>
  );
};

DeviceAccess.propTypes = {
  control: object.isRequired,
};

export default withLocalizationProvider(DeviceAccess);
