import React from 'react';

const RoundedCheck = ({ fill = '#696E7C' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C9.76803 1.33325 11.4637 2.03563 12.714 3.28587C13.9642 4.53612 14.6666 6.23181 14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992ZM7.15325 10.2333L10.8999 6.48659C11.0227 6.35782 11.0227 6.15535 10.8999 6.02659L10.5466 5.67325C10.417 5.5462 10.2095 5.5462 10.0799 5.67325L6.91992 8.83325L5.91992 7.83992C5.85943 7.77547 5.77498 7.73891 5.68658 7.73891C5.59819 7.73891 5.51374 7.77547 5.45325 7.83992L5.09992 8.19325C5.03681 8.25584 5.00132 8.34104 5.00132 8.42992C5.00132 8.5188 5.03681 8.604 5.09992 8.66659L6.68659 10.2333C6.74707 10.2977 6.83153 10.3343 6.91992 10.3343C7.00831 10.3343 7.09276 10.2977 7.15325 10.2333Z"
      fill={fill}
    />
  </svg>
);

export default RoundedCheck;
