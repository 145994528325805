import { useLocalization } from '@features/localization';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import { bool, func, string } from 'prop-types';
import React from 'react';

import { HeaderWrapper } from './event-links-styles';
import EventLinksTabsComponent from './tabs/tabs-component';

const EventLinksModal = ({ eventId, isModalDisplayed, handleClose }) => {
  const { t } = useLocalization('overview.event-links');
  return (
    <Modal
      describedById="modal-header"
      isShowing={isModalDisplayed}
      onClose={handleClose}
      isDismissible
      withCloseButton
      label={t('modal.label')}
      size="large"
    >
      <HeaderWrapper data-testid="event-links-modal">
        <Text
          id="modal-header"
          element="h3"
          pattern="headingThree"
          color="grey-800"
          weight="medium"
          mt={4}
          mb={2}
        >
          {t('heading')}
        </Text>
      </HeaderWrapper>
      <EventLinksTabsComponent eventId={eventId} />
    </Modal>
  );
};

EventLinksModal.propTypes = {
  eventId: string,
  isModalDisplayed: bool,
  handleClose: func,
};

export default EventLinksModal;
