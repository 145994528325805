import { FieldMapper } from '@components/field-mapper';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { getHeaderValidationMessages } from '../../helpers';
import { ModalFooter } from '../modal-footer';
import { ModalHeader } from '../modal-header';
import { CustomToast } from './error-report.style';
import { Container } from './map-headers.style';

const defaultMapping = {
  exhibitor: 'Exhibitor',
  exhibitor_email: 'Exhibitor Email',
  booth_size: 'Booth Size',
  priority: 'Priority',
  tags: 'Tags',
};

const MapHeaders = ({ canInvite, loading, cancel, processedFile, onNext }) => {
  const { t } = useLocalization('expo.bulk-upload-modal.map-headers');

  const [fieldMapping, setFieldMapping] = useState({});

  const headerErrors = getHeaderValidationMessages({
    headerDictionary: fieldMapping,
    processedFile,
  });

  const hasError = !!headerErrors.length;

  const descriptionComponent = (
    <>
      <Text pattern="body">{t('description')}</Text>
      <Text pattern="strong" color="grey-600">
        {t('description-strong')}
      </Text>
      {hasError && (
        <CustomToast
          icon="alert"
          colorPattern="danger"
          role="status"
          iconColor="red-600"
          isInverse
          py={2}
          mt={2}
        >
          <Box>
            <Text pattern="strong" color="grey-800">
              {t('match-error', {
                unmatchedColumns: headerErrors.length,
              })}{' '}
            </Text>

            <Text pattern="body" color="grey-600">
              {t('error-check')}
            </Text>
          </Box>
        </CustomToast>
      )}
    </>
  );

  return (
    <Container>
      <ModalHeader title={t('title')} description={descriptionComponent} />

      <FieldMapper
        defaultMapping={defaultMapping}
        onMappingChange={setFieldMapping}
        records={processedFile}
      />

      <ModalFooter
        cancelClick={cancel}
        nextClick={() => onNext(fieldMapping)}
        nextText={canInvite ? t('invite') : t('confirm')}
        loading={loading}
        disabled={hasError}
      />
    </Container>
  );
};

MapHeaders.propTypes = {
  canInvite: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  cancel: PropTypes.func,
  onNext: PropTypes.func,
  processedFile: PropTypes.array,
};

export default withLocalizationProvider(MapHeaders);
