import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

const RecordingContainer = styled(Flex).attrs({
  flexWrap: 'wrap',
  mb: 2,
  mx: -1,
})`
  & > .recording-library-tile {
    margin-bottom: 1rem;
  }
`;

export { RecordingContainer };
