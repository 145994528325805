import GenericBadge from '@components/generic-badge/generic-badge';
import Eye from '@components/icons/eye';
import EyeSlash from '@components/icons/eye-slash';
import MoreHorizontal from '@components/icons/more-horizontal';
import PenLine from '@components/icons/pen-line';
import StarTag from '@components/icons/star-tag';
import { Subtitle } from '@components/navigation-item/styles';
import {
  EventAreaHalon,
  EventAreaLabel,
  EventPartCheckbox,
  GapFillingSpace,
  MediaWrapper,
  MenuItem,
  MenuItems,
  StyledMenu,
} from '@features/events/event-parts/event-part-styles';
import { useLocalization } from '@features/localization';
import { any, bool, func, string } from 'prop-types';
import React, { useState } from 'react';

const EventPartMenu = ({
  checked,
  handleSetAsLandingArea,
  handleShowHide,
  handleRename,
}) => {
  const renameDisabled = true;
  const colorOfRenameIcon = renameDisabled
    ? 'var(--color-text-disabled)'
    : 'var(--color-text)';
  const { t } = useLocalization();
  const tPrefix = 'basic-settings.event-parts.menu';

  /*
   This state is used as a workaround, to pass information between MenuItems
   (which are injected here into a MenuContainer) and their parent menu object.
   MenuContainer consists of a single button that opens/closes the main container.
   When that button is clicked to open the menu, the current value of usedAt
   is stored in the menu's internal state variable, expandedAt. When the menu
   is created/refreshed, the function will compare the externally-sourced value
   (usedAt) with the menu's internal state (expandedAt) to determine whether any
   of the menu items were clicked after the menu was opened - which means the menu
   should auto-close. So, the intended behaviour is that the menu should open
   after clicking the 3-dots button, and then close after any interaction.
   */
  const [menuUsedCounter, setMenuUsedCounter] = useState(0);

  return (
    <StyledMenu
      buttonContents={<MoreHorizontal />}
      position="left"
      usedAt={menuUsedCounter}
    >
      <MenuItems flexDirection="column">
        <MenuItem
          data-testid="menu-item-set-as-la"
          as="button"
          type="button"
          onClick={() => {
            setMenuUsedCounter(value => value + 1);
            handleSetAsLandingArea();
          }}
        >
          <StarTag />
          {t(`${tPrefix}.set-as-landing-area`)}
        </MenuItem>
        <MenuItem
          data-testid="menu-item-show-hide"
          as="button"
          type="button"
          onClick={() => {
            setMenuUsedCounter(value => value + 1);
            handleShowHide();
          }}
        >
          {checked ? <EyeSlash /> : <Eye />}
          {checked ? t(`${tPrefix}.hide`) : t(`${tPrefix}.show`)}
        </MenuItem>
        <MenuItem
          data-testid="menu-item-rename"
          as="button"
          type="button"
          onClick={() => {
            setMenuUsedCounter(value => value + 1);
            handleRename();
          }}
          disabled={renameDisabled}
        >
          <PenLine color={colorOfRenameIcon} />
          {t(`${tPrefix}.rename`)}
          <GapFillingSpace />
          {renameDisabled && (
            <GenericBadge
              label={t(`${tPrefix}.coming-soon`)}
              styling={{
                height: '2rem',
                textColor: 'COLOR.YELLOW.500',
                backgroundColor: 'COLOR.YELLOW.100',
              }}
            />
          )}
        </MenuItem>
      </MenuItems>
    </StyledMenu>
  );
};

const EventPart = ({
  type,
  name,
  value,
  icon,
  iconColor,
  iconBackgroundColor,
  checked,
  isLandingArea,
  handleSetAsLandingArea,
  handleShowHide,
  handleRename,
  isLite,
}) => {
  const { t } = useLocalization();
  try {
    const halon = (
      <EventAreaHalon
        icon={icon}
        color={iconBackgroundColor}
        iconColor={iconColor}
      />
    );
    const landingAreaBadge = isLandingArea ? (
      <GenericBadge
        label={t('basic-settings.event-parts.landing-area')}
        styling={{
          height: '2rem',
          textColor: 'COLOR.BLUE.500',
          backgroundColor: 'COLOR.BLUE.200',
        }}
      />
    ) : null;
    return (
      <EventPartCheckbox
        className={`js-event-part-${type}`}
        name={name}
        value={value}
        withSubtitle={true}
        hasErrors={false}
        isDisabled={false}
        isInverse={false}
        hasMedia={false}
        checked={checked}
        onChange={handleShowHide}
      >
        <MediaWrapper>
          {halon}
          <EventAreaLabel flexDirection="column">
            {type === 'sessions' && isLite
              ? t(`basic-settings.event-parts.session.name`)
              : t(`basic-settings.event-parts.${type}.name`)}
            <Subtitle
              className="subtitle-class"
              color="grey-medium"
              pattern="caption"
            >
              {type === 'sessions' && isLite
                ? t(`basic-settings.event-parts.session.description`)
                : t(`basic-settings.event-parts.${type}.description`)}
            </Subtitle>
          </EventAreaLabel>
          <GapFillingSpace />
          {landingAreaBadge}
          {
            <EventPartMenu
              checked={checked}
              handleSetAsLandingArea={() => handleSetAsLandingArea(type)}
              handleShowHide={handleShowHide}
              handleRename={handleRename}
            />
          }
        </MediaWrapper>
      </EventPartCheckbox>
    );
  } catch (_e) {
    return null;
  }
};

EventPartMenu.propTypes = {
  checked: bool.isRequired,
  handleSetAsLandingArea: func.isRequired,
  handleShowHide: func.isRequired,
  handleRename: func.isRequired,
};

const noOp = () => {};

EventPart.defaultProps = {
  handleRename: noOp,
};

EventPart.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  value: any.isRequired,
  icon: string.isRequired,
  iconColor: string.isRequired,
  iconBackgroundColor: string.isRequired,
  checked: bool.isRequired,
  isLandingArea: bool.isRequired,
  handleSetAsLandingArea: func.isRequired,
  handleShowHide: func.isRequired,
  handleRename: func,
  isLite: bool,
};

export default EventPart;
