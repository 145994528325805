import { bool, string } from 'prop-types';
import React, { forwardRef } from 'react';

import {
  StaticText,
  StyledInput,
  StyledInputWrapper,
} from './static-text-input.styles';

const StaticTextInput = forwardRef(
  (
    { className, hasErrors, leadingText, sizeOption, trailingText, ...props },
    ref,
  ) => (
    <StyledInputWrapper
      className={className}
      hasErrors={hasErrors}
      sizeOption={sizeOption}
    >
      {leadingText && <StaticText>{leadingText}</StaticText>}
      <StyledInput
        {...props}
        hasErrors={hasErrors}
        leadingText={leadingText}
        ref={ref}
        trailingText={trailingText}
      />
      {trailingText && <StaticText>{trailingText}</StaticText>}
    </StyledInputWrapper>
  ),
);

StaticTextInput.propTypes = {
  className: string,
  hasErrors: bool,
  leadingText: string,
  sizeOption: string,
  trailingText: string,
};

export default StaticTextInput;
