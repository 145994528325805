import { useLocalization } from '@features/localization';
import { Badge } from '@hopin-team/ui-badge';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

const badgeProps = {
  session: {
    colorPattern: 'informational',
    leadingIcon: 'sessions',
  },
  stage: {
    colorPattern: 'success',
    leadingIcon: 'camera',
  },
};

export const RecordingGroupTitle = ({ videoAreaType, title }) => {
  const { t } = useLocalization('recording-group');
  return (
    <>
      {videoAreaType && (
        <Badge
          data-testid="badge"
          mb={1.5}
          ph={1}
          pv={0.5}
          priority="low"
          {...badgeProps[videoAreaType]}
        >
          {t(videoAreaType)}
        </Badge>
      )}

      <Text data-testid="title" pattern="headingThree" as="h3" mb={2}>
        {title}
      </Text>
    </>
  );
};

RecordingGroupTitle.propTypes = {
  videoAreaType: PropTypes.oneOf(['stage', 'session']),
  title: PropTypes.string.isRequired,
};
