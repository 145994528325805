import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { Link } from '@hopin-team/ui-link';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import PropTypes from 'prop-types';
import React from 'react';

const BannerLocation = ({ location, slug }) => {
  const { t } = useLocalization('overview.onsite-banner');

  return (
    <Flex alignItems="center" pr={2}>
      <Halon
        mr={2}
        icon="location-pin"
        color="grey-200"
        iconColor="grey-900"
        scale={4}
        iconScale={2}
      />
      <Flex flexDirection="column">
        <Text pattern="bodyLargeBold">
          {location.venue_name || location.name || t('venue')}
        </Text>
        <Text color="grey-600" pattern="body">
          {location.formatted ? (
            location.formatted
          ) : (
            <Link
              href={Routes.organisersEventBasicsPath(slug)}
              label={t('add-address')}
              scale={4}
            />
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

BannerLocation.propTypes = {
  location: PropTypes.shape({
    formatted: PropTypes.string,
    name: PropTypes.string,
    venue_name: PropTypes.string,
  }),
  slug: PropTypes.string,
};

export default BannerLocation;
