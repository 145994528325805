import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import * as Routes from '@routes';
import { object } from 'prop-types';
import React from 'react';

const AddStage = ({ event, eventPart }) => {
  const { t } = useLocalization('stages');

  return (
    <div className="card card-lg text-center mt-2 border">
      <div className="card-body px-5">
        <h1 className="display-4">
          <i className="fas fa-chalkboard-teacher opacity-20"></i>
        </h1>
        <h1 className="h5">{t('no-stages')}</h1>
        <p
          className=" mb-3 "
          style="margin: 0 auto; max-width: 700px !important;"
        >
          {t('add-stage-description')}
        </p>
        <a
          href={Routes.newOrganisersEventBackstagePath(event, eventPart)}
          className="btn btn-lg btn-primary-def"
        >
          {t('add-stage')}
        </a>
      </div>
    </div>
  );
};

AddStage.propTypes = {
  event: object.isRequired,
  eventPart: object.isRequired,
};

export default withLocalizationProvider(AddStage);
