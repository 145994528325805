import { i18n } from '@features/localization';
import { Search } from '@hopin-team/ui-search';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const { t } = i18n;

function SearchInput({ value, onInputChange, applySearch }) {
  const [isCleared, setIsCleared] = useState(false);

  useEffect(() => {
    if (isCleared && !value) {
      applySearch();
    }
  }, [applySearch, isCleared, value]);

  function handleClear() {
    onInputChange('');
    setIsCleared(true);
  }

  function handleChange(event) {
    onInputChange(event.target.value);
  }

  return (
    <Search
      aria-label={t('roundtables.filtering.search-aria-label')}
      placeholder={t('roundtables.filtering.search-placeholder')}
      colorPattern="light"
      sizeOption="medium"
      value={value}
      onClear={handleClear}
      onSubmit={applySearch}
      onChange={handleChange}
      mr={1}
    />
  );
}

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  applySearch: PropTypes.func.isRequired,
};

export default SearchInput;
