import { useLocalization } from '@features/localization';
import { Butticon } from '@hopin-team/ui-butticon';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { bool, object, string } from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';

const QuestionVisibilityButton = ({
  control,
  fieldName,
  canEditSurvey,
  isLastEnabledQuestion,
}) => {
  const { t } = useLocalization();

  const getTipText = value => {
    if (!canEditSurvey) return t('survey-builder.edit.survey-is-live');
    if (isLastEnabledQuestion)
      return t('survey-builder.edit.cannot-disable-last-question');

    return value
      ? t('survey-builder.edit.disable-this-question')
      : t('survey-builder.edit.enable-this-question');
  };

  return (
    <Controller
      name={`${fieldName}.enabled`}
      control={control}
      render={({ onChange, value, ref }) => (
        <ToolTip align="bottom" tip={getTipText(value)}>
          <StyledButticon
            type="button"
            icon={value ? 'watching' : 'hidden'}
            size="small"
            ref={ref}
            title={
              value
                ? t('survey-builder.edit.question-enabled')
                : t('survey-builder.edit.question-disabled')
            }
            onClick={() => onChange(!value)}
            disabled={!canEditSurvey || isLastEnabledQuestion}
          />
        </ToolTip>
      )}
    />
  );
};

QuestionVisibilityButton.propTypes = {
  fieldName: string.isRequired,
  control: object.isRequired,
  canEditSurvey: bool.isRequired,
  isLastEnabledQuestion: bool.isRequired,
};

export default QuestionVisibilityButton;

const StyledButticon = styled(Butticon)`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
