import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { SavePanel } from '../save-panel';

export const RevertModal = ({
  isPublished,
  isReverting,
  onCancel,
  onRevert,
}) => {
  const { t } = useLocalization('edit-recording-modal');

  return (
    <Flex flexDirection="column" m={3} my={2}>
      <Flex mb={2} flexDirection="column">
        <Text pattern="headingThree" mb={1} align="center" element="h2">
          {t('revert-modal.title')}
        </Text>
        <Text color="grey-600" scale={4} mb={1}>
          {t('revert-modal.description')}
        </Text>
        {isPublished && (
          <Flex>
            <Icon name="warning" color="orange-500" mt={1.5} mr={1} />
            <Text color="orange-500" scale={4}>
              {t('revert-modal.published-status-disclaimer')}
            </Text>
          </Flex>
        )}
      </Flex>
      <SavePanel
        onCancelText={t('revert-modal.cancel')}
        onConfirmText={t('revert-modal.revert')}
        onCancel={onCancel}
        onConfirm={onRevert}
        isInLine={false}
        isSaveDisabled={isReverting}
      />
    </Flex>
  );
};

RevertModal.propTypes = {
  isPublished: PropTypes.bool.isRequired,
  isReverting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
};
