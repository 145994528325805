import { MenuHeading, MenuItem } from '@components/menu/menu-component';
import { useLocalization } from '@features/localization';
import { LANGUAGE_PICKER_MODAL_TYPE } from '@features/localization/constants';
import { LanguagePicker } from '@features/localization/language-picker';
import { Avatar } from '@hopin-team/ui-avatar';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import Settings from 'icons/settings.svg';
import SignOut from 'icons/sign-out.svg';
import { bool, string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal, openModal } from '@/redux/reducers/modal';

import { Styles } from './footer-styles';
import { Styles as NavItemStyles } from './nav-item-styles';
import NavigationViewToggle from './navigation-view-toggle';

const Footer = ({
  navigationView,
  userImage,
  userName,
  advancedToggleVisible,
}) => {
  const { t } = useLocalization('event-dashboard.sidenav.footer');
  const dispatch = useDispatch();
  const { type: modalType } = useSelector(({ modal }) => modal);
  const langPickerIsShowing = modalType === LANGUAGE_PICKER_MODAL_TYPE;

  const openLanguagePicker = () => {
    dispatch(openModal(LANGUAGE_PICKER_MODAL_TYPE));
  };

  const handleLangPickerClose = () => {
    dispatch(closeModal());
  };

  return (
    <Styles.Footer>
      {advancedToggleVisible && (
        <NavigationViewToggle navigationView={navigationView} />
      )}
      <Styles.ProfileMenu
        buttonContents={
          <NavItemStyles.RootItem as="div">
            <Avatar src={userImage} size="small" />
            <Text font="display">{userName}</Text>
          </NavItemStyles.RootItem>
        }
        position="top"
      >
        <MenuHeading>Account</MenuHeading>
        <LanguagePicker
          isShowing={langPickerIsShowing}
          onClose={handleLangPickerClose}
        />

        <MenuItem>
          <button onClick={openLanguagePicker}>
            <Icon scale={3.5} p={0.5} name="website" mr={1} color="grey-600" />
            {t('language')}
          </button>
        </MenuItem>
        <MenuItem>
          <a href={Routes.profileAccountPath()}>
            <Settings />
            {t('profile')}
          </a>
        </MenuItem>
        <MenuItem>
          <a href={Routes.signOutPath()}>
            <SignOut />
            {t('logout')}
          </a>
        </MenuItem>
      </Styles.ProfileMenu>
    </Styles.Footer>
  );
};

Footer.propTypes = {
  navigationView: string,
  userImage: string,
  userName: string.isRequired,
  advancedToggleVisible: bool,
};

export default Footer;
