import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { object } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

const MeetingScheduling = ({ control }) => {
  const { t } = useLocalization('venue-controls.my-agenda');

  const {
    field: {
      onChange: enableScheduleMeetings,
      value: areScheduleMeetingsEnabled,
      name: enableScheduleMeeting,
    },
  } = useController({
    name: 'venue_settings[schedule_meetings]',
    control,
  });

  const {
    field: {
      onChange: enableScheduleMeetingsPreEvent,
      value: areScheduleMeetingsPreEventEnabled,
      name: enableScheduleMeetingsPreEventName,
    },
  } = useController({
    name: 'venue_settings[schedule_meetings_pre_event]',
    control,
  });

  return (
    <Box mb={3}>
      <Text
        element="h3"
        pattern="headingThree"
        color="grey-800"
        weight="medium"
        scale={4}
        mb={1}
      >
        {t('title-schedule-meetings-redesign')}
      </Text>
      <Text scale={4} pattern="body" element="p" color="grey-600">
        {t('heading-schedule-meetings-redesign')}
      </Text>
      <Flex alignItems="center" mt={1}>
        <Checkbox
          data-testid="schedule-meetings-checkbox"
          id="venue_settings_schedule_meetings"
          value={areScheduleMeetingsEnabled}
          onChange={event => enableScheduleMeetings(event.target.checked)}
          name={enableScheduleMeeting}
          checked={!!areScheduleMeetingsEnabled}
          label={t('enable-during')}
        />
      </Flex>

      <Flex alignItems="center" mt={2}>
        <Checkbox
          data-testid="pre-event-meeting-scheduling-checkbox"
          id="venue_settings_schedule_meetings_pre_event"
          value={areScheduleMeetingsPreEventEnabled}
          onChange={event =>
            enableScheduleMeetingsPreEvent(event.target.checked)
          }
          name={enableScheduleMeetingsPreEventName}
          checked={!!areScheduleMeetingsPreEventEnabled}
          label={t('enable-before')}
          mr={1.5}
        />
        <ToolTipWrapper>
          <ToolTip tip={t('tip')} align="bottom">
            <Icon name="alert" color="grey-600" scale={2} />
          </ToolTip>
        </ToolTipWrapper>
      </Flex>
    </Box>
  );
};

MeetingScheduling.propTypes = {
  control: object.isRequired,
};

const ToolTipWrapper = styled.div`
  div {
    line-height: normal;
  }

  .bottom {
    span {
      width: 60%;
    }
  }
`;

export default withLocalizationProvider(MeetingScheduling);
