import { useLocalization } from '@features/localization';
import { intervalToDuration } from 'date-fns';
import { number } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const TimeSpentUnit = styled.span`
  font-size: var(--font-size-bigger);
  display: inline-block;
  margin-right: 0.5rem;
`;

const TimeSpent = ({ minutes }) => {
  const { t } = useLocalization('overview.time-spent');

  const milliseconds = minutes * 60 * 1000;
  const duration = intervalToDuration({ start: 0, end: milliseconds });

  let parts = [];
  if (duration.days) {
    parts.push(
      <span key="day">{duration.days}</span>,
      <TimeSpentUnit key="day-unit">{t('day-unit')}</TimeSpentUnit>,
    );
  }

  if (duration.hours) {
    parts.push(
      <span key="hour">{duration.hours}</span>,
      <TimeSpentUnit key="hour-unit">{t('hour-unit')}</TimeSpentUnit>,
    );
  }

  // show minutes if they are present or the entire duration is 0
  if (minutes === 0 || duration.minutes) {
    parts.push(
      <span key="min">{duration.minutes}</span>,
      <TimeSpentUnit key="min-unit">{t('min-unit')}</TimeSpentUnit>,
    );
  }

  return <div data-testid="top-metrics-time-spent">{parts}</div>;
};

TimeSpent.propTypes = {
  minutes: number.isRequired,
};

export default TimeSpent;
