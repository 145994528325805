import { ProgressBar } from '@hopin-team/ui-progress-bar';
import { rem } from 'polished';
import styled from 'styled-components';

const EventCreationProgress = styled(ProgressBar)`
  & > div {
    border-radius: 0;
  }
`;

const TOP_BAR_HEIGHT = 74;

const TopBarWrapper = styled.div`
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: ${rem(TOP_BAR_HEIGHT)};
  background-color: var(--color-white);
  z-index: 1;
`;

export { EventCreationProgress, TOP_BAR_HEIGHT, TopBarWrapper };
