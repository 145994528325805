import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  /**
   * Context is used to store any identifying
   * information about the entity being edited
   **/
  context: null,
  type: null,
  errors: [],
};

const { actions, reducer } = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: {
      reducer: (state, action) => ({
        ...state,
        type: action.payload.type,
        context: action.payload.context,
      }),
      prepare: (type, context) => ({ payload: { type, context } }),
    },
    closeModal: state => ({ ...state, context: null, type: null, errors: [] }),
    setModalErrors: (state, action) => ({ ...state, errors: action.payload }),
    setModalContext: (state, action) => ({ ...state, context: action.payload }),
  },
});

export const {
  openModal,
  closeModal,
  setModalErrors,
  setModalContext,
} = actions;

export default reducer;
