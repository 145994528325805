import { Button } from '@hopin-team/ui-button';
import { bool, func, node, string } from 'prop-types';
import React, { forwardRef } from 'react';

import { Wrapper } from './file-input-button.styles';

const FileInputButton = forwardRef(
  (
    { accept, children, name, id, disabled, onChange, testId, ...props },
    ref,
  ) => (
    <Wrapper>
      <Button forwardedAs="label" {...props} disabled={disabled} role="button">
        <input
          accept={accept}
          disabled={disabled}
          onChange={onChange}
          aria-labelledby={props['aria-labelledby']}
          id={id}
          data-testid={testId}
          name={name}
          ref={ref}
          type="file"
        />
        {children}
      </Button>
    </Wrapper>
  ),
);

FileInputButton.propTypes = {
  accept: string,
  'aria-labelledby': string,
  onChange: func,
  disabled: bool,
  children: node,
  name: string,
  testId: string,
  id: string,
};

export default FileInputButton;
