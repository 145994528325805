export const selectVideoAreas = ({ recordingGroups }) => {
  const videoAreas = Object.values(recordingGroups?.videoAreas);

  return videoAreas;
};

export const selectVideoAreasFetchingState = ({
  recordingGroups: { isDone, isError, isLoading },
}) => ({
  isDone,
  isError,
  isLoading,
});

export const selectVideoAreaById = ({ recordingGroups }, videoAreaId) => {
  const videoArea = recordingGroups.videoAreas[videoAreaId];
  if (!videoArea) {
    return null;
  }

  return videoArea;
};
