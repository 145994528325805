import Arrow from '@components/icons/arrow';
import { LocalizationContext } from '@features/localization';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

const trendStyles = {
  neutral: {
    background: '#F1F2F5',
    textColor: '#555863',
    arrowRotation: 90,
  },
  upwards: {
    background: '#E2F8ED',
    textColor: '#0B663A',
    arrowRotation: 0,
  },
  downwards: {
    background: '#FFF2F2',
    textColor: '#AE1D1D',
    arrowRotation: 180,
  },
};

const ChangeRateElement = styled.div`
  border-radius: 49px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 10px;
  font-size: 0.8125rem;
  font-weight: var(--font-weight-medium);
  margin: 0 5px;
  line-height: 24px;
  position: relative;
  bottom: 0.3em;
  ${({ color, background }) =>
    css`
      color: ${color};
      background: ${background};
    `}
`;

const DaysText = styled.span`
  position: relative;
  top: -4px;
  font-size: 0.8125rem;
`;

const RateText = styled.span`
  padding-left: 3px;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const ChangeRate = ({ rate, days, showText }) => {
  const { t } = useContext(LocalizationContext);

  const trend = rate > 0 ? 'upwards' : rate < 0 ? 'downwards' : 'neutral';
  const { background, textColor, arrowRotation } = trendStyles[trend];
  const text = rate != null ? `${(rate * 100).toFixed(0)}%` : 'n/a';

  // default to showing 7 days in tooltip when change rate + days aren't applicable
  const tipDays = days || 7;
  const tipText = t('event-dashboard.change-rate.tooltip', {
    period:
      tipDays > 1
        ? `${tipDays} ${t('event-dashboard.change-rate.days')}`
        : t('event-dashboard.change-rate.day'),
  });

  return (
    <>
      <ChangeRateElement
        data-testid="change-rate"
        color={textColor}
        background={background}
      >
        <ToolTip tip={tipText} align="top">
          <NoWrap>
            <Arrow color={textColor} size={12} rotation={arrowRotation} />
            <RateText data-testid="rate">{text}</RateText>
          </NoWrap>
        </ToolTip>
      </ChangeRateElement>
      {showText && days && (
        <DaysText data-testid="days">
          {t('event-dashboard.change-rate.duration', {
            period:
              days > 1
                ? `${days} ${t('event-dashboard.change-rate.days')}`
                : `${days} ${t('event-dashboard.change-rate.day')}`,
          })}
        </DaysText>
      )}
    </>
  );
};

ChangeRate.defaultProps = {
  rate: null,
  days: null,
  showText: false,
};

ChangeRate.propTypes = {
  rate: PropTypes.number,
  days: PropTypes.number,
  showText: PropTypes.bool,
};

export default ChangeRate;
