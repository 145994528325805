import { INTERACTIVE } from '@hopin-team/ui-blocks';
import { COLOR, LINE } from '@hopin-team/ui-symbols';
import Checkmark from 'icons/checkmark.svg';
import Minus from 'icons/minus.svg';
import { bool, func } from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Icon = ({ minus }) => (minus ? <Minus /> : <Checkmark />);

const StyledCheckbox = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${COLOR.GREY.WHITE};
  background-color: ${({ checked }) =>
    checked ? COLOR.BLUE['400'] : COLOR.GREY.WHITE};
  border: ${({ checked }) => (checked ? 'none' : LINE.DEFAULT_BORDER)};
  border-radius: ${LINE.RADIUS_MD};
  transition: all var(--transition-duration);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  svg {
    width: 0.75rem;
    display: none;
  }
`;

const CheckboxElement = styled.input.attrs({ type: 'checkbox' })`
  width: 100%;
  height: 100%;
  appearance: none;
  border: 0;
  outline: 0;

  ${INTERACTIVE.area}

  &:checked + ${StyledCheckbox} {
    background-color: ${COLOR.BLUE['400']};
    border: none;

    svg {
      display: unset;
    }
  }
`;

const CheckboxContainer = styled.div`
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
`;

const Checkbox = forwardRef(({ minus = false, ...props }, ref) => {
  return (
    <CheckboxContainer>
      <CheckboxElement ref={ref} {...props} />
      <StyledCheckbox>
        <Icon minus={minus} />
      </StyledCheckbox>
    </CheckboxContainer>
  );
});

Icon.propTypes = {
  checked: bool,
  minus: bool,
};

Checkbox.propTypes = {
  checked: bool,
  onChange: func,
  minus: bool,
};

export default Checkbox;
