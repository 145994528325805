const formatTagsData = (category, included) => {
  const tagsData = category?.relationships?.tags?.data;

  if (tagsData && included) {
    return tagsData.reduce((result, tag) => {
      const tagValue = included.find(item => item.id === tag.id);
      if (tagValue?.attributes?.name) {
        result.push({
          id: tag.id,
          label: tagValue.attributes.name,
          value: tagValue.attributes.name,
        });
      }
      return result;
    }, []);
  }
  return [];
};

export const mapResponseToFormFields = (data, included) => {
  if (data) {
    return data.reduce((result, category) => {
      if (category.attributes?.name && category.attributes?.type) {
        result.push({
          id: category.id,
          name: category.attributes.name,
          type: category.attributes.type,
          tags: formatTagsData(category, included),
        });
      }
      return result;
    }, []);
  }

  return [];
};
