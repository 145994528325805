import StepHeader from '@features/event-creation-wizard/components/step-header';
import { setEventAccess } from '@features/event-creation-wizard/redux/step-specific-state/event-access-slice';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import {
  RadioButtonContainer,
  RadioGroupContainer,
} from '@features/event-creation-wizard/steps/event-access/event-access-styles';
import { useLocalization } from '@features/localization';
import { Grid } from '@hopin-team/ui-grid';
import { RadioButton, RadioGroup } from '@hopin-team/ui-radio-buttons';
import { bool } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const accessTypes = ['free', 'paid', 'both'];

const EventAccessComponent = ({ canSkip }) => {
  const dispatch = useDispatch();

  const {
    eventAccess: defaultValue,
    org: { numRegistrationsIncluded },
  } = useSelector(({ eventAccess, org }) => ({
    eventAccess,
    org,
  }));

  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const { t } = useLocalization('event-creation-wizard.steps.event-access');

  const onSave = () => dispatch(setEventAccess(selectedValue));

  const onChange = event => setSelectedValue(event.target.value);

  return (
    <EventCreationWizardStep
      canSkip={canSkip}
      onSave={onSave}
      info={t('info', { registrationCount: numRegistrationsIncluded })}
    >
      <StepHeader title={t('title')} subtitle={t('subtitle')} />

      <RadioGroupContainer>
        <RadioGroup selectedValue={selectedValue} onChange={onChange}>
          <Grid gridGap="1rem">
            {accessTypes.map(accessType => (
              <RadioButtonContainer
                key={accessType}
                isSelected={selectedValue === accessType}
              >
                <RadioButton
                  value={accessType}
                  id={`event-access-${accessType}`}
                  label={t(`form.${accessType}.label`)}
                />
              </RadioButtonContainer>
            ))}
          </Grid>
        </RadioGroup>
      </RadioGroupContainer>
    </EventCreationWizardStep>
  );
};

EventAccessComponent.propTypes = {
  canSkip: bool,
};

export default EventAccessComponent;
