import Card from '@components/card-with-loading';
import InfoCircleIcon from '@components/icons/info-circle-icon';
import { metricItemColors } from '@features/dashboard/util/colors';
import { Flex } from '@hopin-team/ui-flex';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { bool, element, number, oneOfType, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SummaryMetricItemWrapper = styled.div`
  text-align: center;
  color: var(--color-gray-800);
  ${props => !props.show && `display: none`}
`;

const IconWrapper = styled.div`
  circle {
    ${props => props.backgroundColor && `fill: ${props.backgroundColor}`};
  }

  path {
    ${props => props.iconColor && `fill: ${props.iconColor}`};
  }
`;

const MetricValue = styled.h2`
  font-size: 1.9rem;
  margin: 1rem 0 0;
  font-family: var(--font-family-display);
  font-variant-numeric: tabular-nums;

  > span > span {
    padding: 0 0.5rem;
    font-size: 1.3rem;
  }
`;

const MetricText = styled.p`
  font-size: 1rem;
  margin-bottom: 0;
`;

const TooltipWrapper = styled.div`
  margin-left: 0.375rem;
  top: 2px;
  position: relative;
`;

const SummaryMetricItem = ({
  isLoading,
  icon,
  value,
  text,
  color,
  show,
  tooltipText,
}) => {
  const iconColor = color && metricItemColors[color].icon;
  const backgroundColor = color && metricItemColors[color].background;

  const formattedValue =
    typeof value == 'object' ? value : value.toLocaleString();

  return (
    <SummaryMetricItemWrapper
      show={show}
      className="metric-item"
      data-testid="summary-metric-item"
    >
      <Card isLoading={isLoading}>
        <IconWrapper iconColor={iconColor} backgroundColor={backgroundColor}>
          {icon}
        </IconWrapper>
        <MetricValue data-testid="metric-value">{formattedValue}</MetricValue>

        <Flex justifyContent="center">
          <MetricText data-testid="summary-metric-item-text">{text}</MetricText>
          {tooltipText && (
            <TooltipWrapper data-testid="summary-metric-item-tooltip">
              <ToolTip align="right" tip={tooltipText}>
                <InfoCircleIcon width={16} height={16} />
              </ToolTip>
            </TooltipWrapper>
          )}
        </Flex>
      </Card>
    </SummaryMetricItemWrapper>
  );
};

SummaryMetricItem.propTypes = {
  icon: element.isRequired,
  value: oneOfType([string, number, element]).isRequired,
  text: string.isRequired,
  tooltipText: string,
  color: string,
  isLoading: bool,
  show: bool,
};

SummaryMetricItem.defaultProps = {
  isLoading: false,
  show: true,
};

export default SummaryMetricItem;
