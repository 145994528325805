import { useReportsNotificationPusherSubscription } from '@components/reports-notification/use-reports-notification-pusher-subscription';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Icon } from '@hopin-team/ui-icon';
import { Spinner } from '@hopin-team/ui-spinner';
import compose from 'lodash/fp/compose';
import { bool, node, object, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reportsFetched } from '@/redux/reducers/reports';
import withReduxProvider from '@/redux/with-provider';

import { useAlertingCreateReportRequest } from '../hooks';

function GenerateButton({
  reportType,
  initiatedFrom,
  buttonProps,
  iconProps,
  children,
  isParentColor = false,
}) {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportsFetched([]));
  }, [dispatch]);
  useReportsNotificationPusherSubscription();

  const eventId = useSelector(({ event }) => event.id);
  const report = { reportType };
  const reportRequest = useAlertingCreateReportRequest(eventId);
  const status = useSelector(({ reports }) => reports.generatingStatus?.status);
  const isGenerating = ['running', 'started'].includes(status);

  return (
    <Button
      onClick={() => {
        reportRequest(report, null, initiatedFrom);
      }}
      {...buttonProps}
      disabled={buttonProps.disabled || isGenerating}
    >
      {isGenerating ? (
        <>
          <Spinner theme="secondary" mr={1} isShowing />
          {t('reports.report.status.generating')}
        </>
      ) : (
        <>
          <Icon
            isParentColor={isParentColor}
            name="download"
            mr={1}
            {...iconProps}
          />
          {children}
        </>
      )}
    </Button>
  );
}

GenerateButton.propTypes = {
  reportType: string.isRequired,
  initiatedFrom: string.isRequired,
  buttonProps: object.isRequired,
  iconProps: object.isRequired,
  children: node.isRequired,
  isParentColor: bool,
};

export default compose(withReduxProvider)(GenerateButton);
