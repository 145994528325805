import isAfter from 'date-fns/isAfter';
import sub from 'date-fns/sub';
import { isEmpty } from 'lodash/fp';

function isInLastDay(date) {
  const reportDate = new Date(date);
  const yesterday = sub(new Date(), {
    days: 1,
  });
  return isAfter(reportDate, yesterday);
}

export default class Report {
  constructor(reportDTO) {
    this.title = reportDTO.title;
    this.reportType = reportDTO.report_type;
    this.description = reportDTO.description;
    this.enabled = reportDTO.enabled;
    this.options = reportDTO.options?.[0];
    this.ongoingSince = reportDTO.ongoing_since;
    this.lastDownloadLink = reportDTO.last_completed.download_link;
    this.lastCompletedAt = reportDTO.last_completed.completed_at;
    this.lastDownloadedAreaLabel = reportDTO.last_completed.area_label;
    this.documentation = reportDTO.documentation;
  }

  get status() {
    if (!this.enabled) return 'disabled';
    if (this.isMissingRequiredInfo) return 'unavailable';
    if (this.ongoingSince) return 'generating';
    if (this.lastDownloadLink) {
      if (isInLastDay(this.lastCompletedAt)) {
        return 'available';
      } else {
        return 'generated';
      }
    }
    return null;
  }

  get isMissingRequiredInfo() {
    // TODO: This status should be more explicit in the DTO
    return Boolean(this.options && isEmpty(this.options.options));
  }
}
