import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import useInterval from '@util/hooks/useInterval';
import VerifyEmailImg from 'icons/post-signup/verification.svg';
import { func, string } from 'prop-types';
import React from 'react';

import { Anchor, ButtonWrapper } from './styles';

const ConfirmEmailModal = ({ t, email, poller, closeModal, resendEmail }) => {
  useInterval(poller, 3000);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      px={6}
      py={6}
      data-testid="confirm-email-modal-content"
    >
      <VerifyEmailImg />
      <Text
        pattern="headingTwo"
        weight="medium"
        color="grey-800"
        element="h2"
        mt={3}
      >
        {t('signup.post-signup.title')}
      </Text>
      <Text pattern="body" color="grey-800" element="p" mt={1}>
        {t('signup.post-signup.subtitle', { email })}
      </Text>
      <ButtonWrapper>
        <Button onClick={resendEmail} disabled={false} my={3} px={4} py={2}>
          {t('signup.post-signup.button')}
        </Button>
      </ButtonWrapper>
      <Flex alignItems="center">
        <Text mr={1}>{t('signup.post-signup.helper')}</Text>
        <Anchor onClick={closeModal}>{t('signup.post-signup.link')}</Anchor>
      </Flex>
    </Flex>
  );
};

ConfirmEmailModal.propTypes = {
  t: func,
  email: string,
  poller: func,
  closeModal: func,
  resendEmail: func,
};

export default ConfirmEmailModal;
