import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { rem } from 'polished';
import styled from 'styled-components';

export const PreviewContainer = styled.div`
  width: 100%;
  max-width: ${rem(660)};
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    max-height: ${rem(550)};
  }

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    display: none;
  }
`;
