import { Box } from '@hopin-team/ui-box';
import { LAYOUT } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

import graphic from './assets/backup-recordings-graphic.png';

export const SecondaryHeaderContainer = styled(Box)`
  background-image: url('${graphic}');
  background-position: bottom right;
  background-size: auto 100%;
  background-repeat: no-repeat;

  @media (max-width: ${LAYOUT.MOBILE_MAX}) {
    background-size: 100% auto;
  }

  @media (min-width: ${LAYOUT.MOBILE_MAX}) {
    padding-right: 18rem;
  }
`;
