import { Text } from '@hopin-team/ui-text';
import { bool, node } from 'prop-types';
import React from 'react';

import { LeftCell, RightCell } from './styles';

const Cell = ({ children, right, ...props }) => {
  const Container = right ? RightCell : LeftCell;

  return (
    <Container {...props}>
      <Text pattern="labelOne" color="grey-600">
        {children}
      </Text>
    </Container>
  );
};

Cell.propTypes = {
  children: node,
  right: bool,
};

export default Cell;
