import { Flex } from '@hopin-team/ui-flex';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled(Flex)`
  width: min-content;
  white-space: nowrap;
  border-radius: ${({ theme }) => getFromTheme('LINE.RADIUS_DEFAULT', theme)};
  font-size: ${rem(12)};
  font-weight: 500;
  padding: 0 0.5rem;

  ${({ textColor, theme }) =>
    textColor &&
    css`
      color: ${getFromTheme(textColor, theme)};
    `}

  ${({ backgroundColor, theme }) =>
    backgroundColor &&
    css`
      background-color: ${getFromTheme(backgroundColor, theme)};
    `}
`;
