import { Box } from '@hopin-team/ui-box';
import { Picture } from '@hopin-team/ui-picture';
import styled from 'styled-components';

export const CenteredPic = styled(Picture)`
  align-self: center;
`;

export const StyledBox = styled(Box)`
  border-radius: var(--border-radius-small);
  border: 1px solid var(--color-gray-300);
  width: 382px;
`;
