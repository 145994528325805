import Check from '@components/icons/check';
import { Text } from '@hopin-team/ui-text';
import { bool, node, string } from 'prop-types';
import React, { useState } from 'react';

import {
  Checkbox,
  CheckboxDisplay,
  Icon,
  LabelText,
  Subtitle,
  Wrapper,
} from './event-parts-card.styles';

const EventPartsCard = ({ disabled, icon, selected, subtitle, title }) => {
  const [selectedPart, setSelectedPart] = useState(selected);
  const handleSelectedPart = e => setSelectedPart(e.target.checked);
  return (
    <Wrapper
      background={selectedPart ? 'blue-100' : 'grey-100'}
      selected={selectedPart}
      disabled={disabled}
      data-testid="event-part-card"
    >
      <CheckboxDisplay checked={selectedPart}>
        <Checkbox
          disabled={disabled}
          value={selectedPart ? title.toLocaleLowerCase() : ''}
          name="schedule_name[]"
          checked={selectedPart}
          onChange={handleSelectedPart}
        />
        {selectedPart && <Check />}
      </CheckboxDisplay>
      <LabelText>
        <Text element="p" pattern="heading4" color="grey-800">
          {title}
        </Text>
        <Subtitle element="p" pattern="labelTwo" color="grey-700" mt={1}>
          {subtitle}
        </Subtitle>
      </LabelText>
      <Icon>{icon}</Icon>
    </Wrapper>
  );
};

export default EventPartsCard;

EventPartsCard.propTypes = {
  disabled: bool,
  icon: node,
  selected: bool,
  subtitle: string,
  title: string,
};
