/* global Rails */
import { getGraphQLUrl } from '@hopin-team/dashboard-constants';
import * as Routes from '@routes';
import isClientside from '@util/is-clientside';
import ky from 'ky-universal';

import { version } from '~/package.json';

import { thumbnailsMockData } from '../features/recordings/mock-data/thumbnails-mock-data';

const buildQueryParams = params => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    queryParams.set(key, value);
  });
  return queryParams;
};

let client = {};

if (isClientside()) {
  client = ky.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
  });
}

const isSuccess = status => status >= 200 && status < 300;
const NEW_RECORDINGS_COMPONENT_URL = '/v1/organizers/recordings/';

const hopinApi = {
  deleteEvent(eventId) {
    return client.delete(Routes.organisersEventPath(eventId));
  },
  deleteOrganizationMember({ userId }) {
    return client.delete(Routes.organizationsUserPath(userId));
  },
  deleteSchedule(eventId, scheduleId) {
    return client.delete(
      Routes.organisersEventSchedulePath(eventId, scheduleId),
    );
  },
  deleteSuggestedProfile(eventId, speakerId) {
    return client.delete(
      Routes.organizationsEventSuggestedProfilePath({
        event_id: eventId,
        id: speakerId,
        _options: true,
      }),
    );
  },
  getPresignedUrl({ entityName, entityField, fileName, entityId }) {
    const queryParams = buildQueryParams({
      entity_name: entityName,
      entity_field: entityField,
      file_name: fileName,
      ...(entityId ? { entity_id: entityId } : {}),
    });
    return client.get(`${Routes.imagesPresignedUrlPath()}?${queryParams}`);
  },
  inviteOrganizationMember({ firstName, lastName, email, role }) {
    return client
      .post(Routes.organizationsInvitationsPath(), {
        json: {
          invitation: {
            first_name: firstName,
            last_name: lastName,
            email,
            role,
          },
        },
      })
      .json();
  },
  publishEvent(eventId) {
    return client.post(Routes.publishOrganisersEventPath(eventId)).json();
  },
  updateVenueControls(eventId, data) {
    return client.put(Routes.organisersEventVenueControlsPath(eventId), {
      json: data,
    });
  },
  getUserToken() {
    return client
      .post('/users/sso', {
        headers: {
          credentials: 'include',
        },
      })
      .json();
  },
  setUserOnboardingPreferences(token, body) {
    return client
      .put('/api/v1/registrations/onboard/self', {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        body,
      })
      .json();
  },
  getUser(token) {
    return client
      .get('/api/v2/public/users/introspect', {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  getSimpleFlow(token, eventSlug, queryString = '') {
    return client
      .get(
        `/api/v1/public/registrations_component/simple_flow/${eventSlug}${queryString}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  getScheduleItems(token, eventSlug) {
    return client
      .get(`/api/v2/schedules/organiser/${eventSlug}/items`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  getEventDetails(token, eventSlug) {
    return client
      .get(`/api/v2/schedules/public/${eventSlug}/event`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  getEventSummaryStats(eventId, signal) {
    return client
      .get(Routes.organisersEventEventSummaryStatsPath(eventId), {
        signal,
      })
      .json();
  },
  getConnectionsKeyMetrics(eventId, signal) {
    return client
      .get(Routes.organisersEventConnectionsKeyMetricsPath(eventId), {
        signal,
      })
      .json();
  },
  getEventKeyMetrics(eventId, signal) {
    return client
      .get(Routes.organisersEventConnectionsKeyMetricsPath(eventId), {
        signal,
      })
      .json();
  },
  getEngagementScoreWithPagination(eventId, pageSize, page, signal) {
    return client
      .get(
        `${Routes.organisersEventEngagementScoresPath(
          eventId,
        )}?page_size=${pageSize}&page=${page}`,
        {
          signal,
        },
      )
      .json();
  },
  getAudienceResponseStats(eventId, signal) {
    return client
      .get(Routes.organisersEventAudienceResponseStatsPath(eventId), {
        signal,
      })
      .json();
  },
  getLatestConnections(eventSlug, signal) {
    return client
      .get(Routes.organisersEventLatestConnectionsPath(eventSlug), {
        signal,
      })
      .json();
  },
  getTopSponsorsByNetworkingMeetings(eventId, signal) {
    return client
      .get(Routes.organisersEventTopSponsorsByNetworkingMeetingsPath(eventId), {
        signal,
      })
      .json();
  },
  resendEmailConfirmation() {
    return client.post(Routes.usersResendConfirmationPath());
  },
  submitEmailConfirmation({ confirmationToken }) {
    return client.get(
      Routes.userConfirmationPath() +
        `?confirmation_token=${confirmationToken}`,
    );
  },
  getTicketNetSalesData(eventId, signal) {
    return client
      .get(Routes.organisersEventTicketNetSalesPath(eventId), {
        signal,
      })
      .json();
  },
  getRegistrationMetrics(eventId, signal) {
    return client
      .get(Routes.organisersEventRegistrationMetricsPath(eventId), {
        signal,
      })
      .json();
  },
  getTopAttendeesByNetworkingMeetings(eventId, signal) {
    return client
      .get(
        Routes.organisersEventTopAttendeesByNetworkingMeetingsPath(eventId),
        {
          signal,
        },
      )
      .json();
  },
  getConnectionsMade(eventId, signal) {
    return client
      .get(Routes.organisersEventConnectionsMadePath(eventId), {
        signal,
      })
      .json();
  },
  getRegistrants(organizationExternalId, token) {
    return client
      .get(`/api/v1/organisers/${organizationExternalId}/registrants`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  createRegistrant(organizationExternalId, email, token) {
    return client
      .post(`/api/v1/organisers/${organizationExternalId}/registrants`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: { email: email },
      })
      .json();
  },
  deleteRegistrant(organizationExternalId, registrantId, token) {
    return client
      .delete(
        `/api/v1/organisers/${organizationExternalId}/registrants/${registrantId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  uploadRegistrantsStatus(organizationExternalId, jobId, token) {
    return client
      .get(
        `/api/v1/organisers/${organizationExternalId}/registrants/upload-status?job_id=${jobId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  uploadRegistrants(organizationExternalId, csvFile, token) {
    const data = new FormData();
    data.append('csv_file', csvFile);
    // using fetch because I can't figure out how to set correct content type with ky for uploads
    return fetch(
      `/api/v1/organisers/${organizationExternalId}/registrants/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      },
    ).then(response => response.json());
  },
  deleteBatchRegistrants(organizationExternalId, registrantIds, token) {
    return client
      .post(
        `/api/v1/organisers/${organizationExternalId}/registrants/batch-delete`,
        {
          headers: {
            creadentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: { ids: registrantIds },
        },
      )
      .json();
  },
  getAnnouncementsList(organizationId, eventId, token) {
    return client
      .get(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  createAnnouncement(token, organizationId, eventId) {
    return client
      .post(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  editAnnouncement(token, organizationId, eventId, templateId, values) {
    return client
      .put(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: values,
        },
      )
      .json();
  },
  getTicketsWithCount(token, eventId) {
    return client
      .get(
        `/api/v1/organisers/events/${eventId}/personas/with_attendance_status`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  duplicateAnnouncement(token, organizationId, eventId, templateId) {
    return client
      .post(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}/duplicate`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  deleteAnnouncement(token, organizationId, eventId, templateId) {
    return client
      .delete(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  getAnnouncement(token, organizationId, eventId, templateId) {
    return client
      .get(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  sendAnnouncementEmail(token, organizationId, eventId, templateId) {
    return client
      .post(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}/send`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  sendTestAnnouncementEmail(token, organizationId, eventId, templateId, data) {
    return client
      .post(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}/send_test`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: data,
        },
      )
      .json();
  },
  previewAnnouncement(
    token,
    organizationId,
    eventId,
    templateId,
    registrationId,
  ) {
    return client
      .post(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}/preview`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: {
            registration_id: registrationId,
          },
        },
      )
      .json();
  },
  scheduleAnnouncement(
    token,
    organizationId,
    eventId,
    templateId,
    scheduledTime,
    timezone,
  ) {
    return client
      .post(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}/email_template_schedule`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: { scheduled_time: scheduledTime, timezone },
        },
      )
      .json();
  },
  getTemplateSchedule(token, organizationId, eventId, templateId) {
    return client
      .get(
        `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}/email_template_schedule`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  cancelScheduledEmail(token, organizationId, eventId, templateId) {
    return client.delete(
      `/api/v1/organisers/${organizationId}/events/${eventId}/announcement_email_templates/${templateId}/email_template_schedule`,
      {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  getEmailTemplate(token, organizationId, eventId, templateId) {
    return client
      .get(
        `/api/v1/organisers/${organizationId}/events/${eventId}/email_templates/${templateId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  saveDraftEmailTemplate(token, organizationId, eventId, templateId, values) {
    return client
      .put(
        `/api/v1/organisers/${organizationId}/events/${eventId}/email_templates/${templateId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: values,
        },
      )
      .json();
  },
  getRecordingAreas(eventSlug) {
    return client.get(Routes.organisersEventVideoAreasPath(eventSlug)).json();
  },
  backupGetRecordings(eventSlug, options = {}) {
    return client
      .get(
        Routes.organisersEventRecordingsPath(eventSlug, {
          format: 'json',
          ...options,
        }),
        { timeout: false },
      )
      .json();
  },
  getRecordings(eventId, token, options) {
    const queryParams = new URLSearchParams({
      event_id: eventId,
      ...options,
    }).toString();

    return client
      .get(
        `${Routes.recordingsComponentRecordingsComponentRootAPIPath()}/v1/organizers/recordings?${queryParams}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          timeout: false,
        },
      )
      .json();
  },
  async updateRecording(id, attributes, eventSlug) {
    const response = await client.patch(
      Routes.organisersEventRecordingPath(eventSlug, id, { format: 'json' }),
      {
        body: JSON.stringify({ attributes }),
      },
    );

    return {
      recording: (await response.json()).recording,
      success: isSuccess(response.status),
    };
  },
  getRecording(id, eventSlug) {
    return client
      .get(
        Routes.organisersEventRecordingPath(eventSlug, id, { format: 'json' }),
        { timeout: false },
      )
      .json();
  },
  backupDeleteRecording(id, eventSlug) {
    return client
      .delete(
        Routes.organisersEventRecordingPath(eventSlug, id, { format: 'json' }),
      )
      .json();
  },
  async deleteRecording(id, token) {
    const response = await client.delete(
      `${Routes.recordingsComponentRecordingsComponentRootAPIPath()}${NEW_RECORDINGS_COMPONENT_URL}${id}`,
      {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        timeout: false,
      },
    );
    return response;
  },
  async trimRecording(id, eventSlug, options) {
    const response = await client.post(
      Routes.trimOrganisersEventRecordingPath(eventSlug, id, {
        format: 'json',
      }),
      {
        body: JSON.stringify({ ...options }),
      },
    );

    return {
      recording: (await response.json()).recording,
      success: isSuccess(response.status),
    };
  },
  async revertTrimRecording(id, eventSlug) {
    const response = await client.delete(
      Routes.trimOrganisersEventRecordingPath(eventSlug, id, {
        format: 'json',
      }),
    );

    return {
      recording: (await response.json()).recording,
      success: isSuccess(response.status),
    };
  },
  getThumbnails(recordingId, eventSlug) {
    console.log('getThumbnails', recordingId, eventSlug);
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(thumbnailsMockData);
      }, [2000]);
    });
  },
  async getPlayableUrl(token, recordingId) {
    return client
      .get(
        `${Routes.recordingsComponentRecordingsComponentRootAPIPath()}${NEW_RECORDINGS_COMPONENT_URL}${recordingId}/playable_url`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          timeout: false,
        },
      )
      .json();
  },
  async getDownloadUrl(token, recordingId) {
    return client
      .get(
        `${Routes.recordingsComponentRecordingsComponentRootAPIPath()}${NEW_RECORDINGS_COMPONENT_URL}${recordingId}/download_url`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          timeout: false,
        },
      )
      .json();
  },
  unblockAttendeeFromEvent(id, eventId) {
    return client
      .post(Routes.unblockUserOrganisersEventPath(eventId, id))
      .json();
  },
  getAttendees(eventId, searchText = '') {
    const searchParams =
      searchText?.length > 0 ? { search_text: searchText } : {};

    return client
      .get(
        Routes.organisersEventAttendeesPath(eventId, {
          format: 'json',
          ...searchParams,
        }),
      )
      .json();
  },
  moneyComponentCheckout(token, organizationId, planId, successUrl, cancelUrl) {
    return client
      .post(Routes.moneyComponentSelfServeCheckoutPath(), {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          organization_external_id: organizationId,
          price_id: planId,
          success_url: successUrl,
          cancel_url: cancelUrl,
        },
      })
      .json();
  },
  moneyComponentCustomerPortal(token, organizationExternalId, returnUrl) {
    return client
      .post(Routes.moneyComponentSelfServeCustomerPortalPath(), {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          organization_external_id: organizationExternalId,
          return_url: returnUrl,
        },
      })
      .json();
  },
  createBlankEvent(token, payload = {}) {
    return client
      .post(Routes.eventTemplatesComponentAPIEventsPath(), {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: payload,
      })
      .json();
  },
  moneyComponentActiveSubscription(token, organizationExternalId) {
    return client.post(
      Routes.moneyComponentSubscriptionActiveSubscriptionPath(),
      {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          organization_external_id: organizationExternalId,
        },
      },
    );
  },
  createEventFromWizard(token, payload = {}) {
    return client
      .extend({
        timeout: 30000,
      })
      .put('/api/v1/creation_wizard/complete', {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: payload,
      })
      .json();
  },
  createDefaultOrg(token) {
    return client
      .extend({
        timeout: 30000,
      })
      .put('/api/v1/creation_wizard/create_default_org', {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  async markEventActionComplete(eventId, cardType, maybeToken) {
    const token = maybeToken ?? (await hopinApi.getUserToken());
    return client
      .put(
        `/api/v1/event_wizard/${eventId}/action_card/${cardType}/user_complete`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  async optInForHopinDashboard() {
    const { token } = await hopinApi.getUserToken();

    return client
      .post(`/api/v1/public/users/self/hopin_dashboard_participation`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  async optOutFromHopinDashboard() {
    const { token } = await hopinApi.getUserToken();

    return client
      .delete(`/api/v1/public/users/self/hopin_dashboard_participation`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  async getMagicLink({ eventSlug, redeemCodeExternalId }) {
    const { token } = await hopinApi.getUserToken();

    return client
      .get(
        `/organisers/events/${eventSlug}/redeem_codes/${redeemCodeExternalId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  bulkDeleteRoundtables(organizationExternalId, eventId, roundtableIds, token) {
    return client
      .delete(
        `/api/v1/organisers/${organizationExternalId}/events/${eventId}/roundtables/bulk_delete`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: {
            roundtable_ids: roundtableIds,
          },
        },
      )
      .json();
  },
  bulkDeleteAllRoundtables(
    organizationExternalId,
    eventId,
    token,
    searchAndFilters,
  ) {
    return client
      .delete(
        `/api/v1/organisers/${organizationExternalId}/events/${eventId}/roundtables/bulk_delete_all`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: {
            search_and_filters: searchAndFilters,
          },
        },
      )
      .json();
  },
  duplicateSession(
    organizationExternalId,
    eventId,
    roundtableId,
    copies,
    token,
  ) {
    return client
      .post(
        `/api/v1/organisers/${organizationExternalId}/events/${eventId}/roundtables/duplicate`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: {
            roundtable_id: roundtableId,
            copies: copies,
          },
        },
      )
      .json();
  },
  bulkUpdateRoundtables(
    organizationExternalId,
    eventId,
    token,
    roundtableIds,
    options,
  ) {
    return client
      .patch(
        `/api/v1/organisers/${organizationExternalId}/events/${eventId}/roundtables/bulk_update`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: {
            options,
            roundtable_ids: roundtableIds,
          },
        },
      )
      .json();
  },
  bulkUpdateAllRoundtables(
    organizationExternalId,
    eventId,
    token,
    options,
    searchAndFilters,
  ) {
    return client
      .patch(
        `/api/v1/organisers/${organizationExternalId}/events/${eventId}/roundtables/bulk_update_all`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: {
            options: options,
            search_and_filters: searchAndFilters,
          },
        },
      )
      .json();
  },
  async checkEventDuplicatable(eventId) {
    const { token } = await hopinApi.getUserToken();
    return client
      .post(getGraphQLUrl(), {
        headers: {
          'X-HOPIN-CLIENT-NAME': 'hopin-js',
          'X-HOPIN-CLIENT-VERSION': version,
          'x-hopin': '1',
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          query: `
          query EventDuplicatable($eventId: ID!) {
            event(id: $eventId) {
              canBeDuplicated
            }
          }
        `,
          variables: {
            eventId,
          },
        },
      })
      .json();
  },
  async getAvailableTicketsInEvent(slug, after = null, pageSize = 100) {
    const { token } = await hopinApi.getUserToken();
    return client
      .post(getGraphQLUrl(), {
        headers: {
          'X-HOPIN-CLIENT-NAME': 'hopin-js',
          'X-HOPIN-CLIENT-VERSION': version,
          'x-hopin': '1',
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          query: `
          query getAvailableTicketsInEvent($slug: String!, $pageSize: Int = 10, $after: String = null) {
            eventBySlug(slug: $slug) {
              name
              tickets(after: $after, first: $pageSize) {
                edges {
                  cursor
                  node {
                    id
                    name
                    remainingCapacity
                    availability
                  }
                }
                pageInfo {
                  hasNextPage
                  endCursor
                }
              }
            }
          }
        `,
          variables: {
            slug,
            pageSize,
            after,
          },
        },
      })
      .json();
  },
  async eventRegistrantUpdateTicket({ eventRegistrantId, ticketId, confirm }) {
    const { token } = await hopinApi.getUserToken();
    return client
      .post(getGraphQLUrl(), {
        headers: {
          'X-HOPIN-CLIENT-NAME': 'hopin-js',
          'X-HOPIN-CLIENT-VERSION': version,
          'x-hopin': '1',
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        json: {
          query: `
          mutation eventRegistrantUpdateTicket($input: EventRegistrantUpdateTicketInput!) {
            eventRegistrantUpdateTicket(input: $input) {
              userErrors {
                code
                message
              }
            }
          }
        `,
          variables: {
            input: {
              eventRegistrantId,
              ticketId,
              confirm,
            },
          },
        },
      })
      .json();
  },
  async batchStatus(batchId) {
    return client.get(`/batch_status/${batchId}.json`).json();
  },
  getSampleCsv(token, eventSlug) {
    return client
      .get(
        `/api/v1/registrations_component/${eventSlug}/registrant_bulk_upload/sample_csv`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .blob();
  },
  registrantsBulkUpload(eventSlug, formData, token) {
    return fetch(
      `/api/v1/registrations_component/${eventSlug}/registrant_bulk_upload/import_csv`,
      {
        method: 'POST',
        body: formData,
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  registrantsBulkUploadV2(eventSlug, body, token) {
    return fetch(
      `/api/v1/registrations_component/${eventSlug}/registrant_bulk_upload/import`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
  },
  registrantsBulkUploadV2Job(eventSlug, jobId, token) {
    return client
      .get(
        `/api/v1/registrations_component/${eventSlug}/registrant_bulk_upload/import/${jobId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .json();
  },
  createRegistrationCustomFormAnswers(eventSlug, answers, token) {
    return client
      .post(
        `/api/v1/registrations_component/custom_form_answers/${eventSlug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': Rails.csrfToken(),
          },
          json: {
            answers,
          },
        },
      )
      .json();
  },
  updateRegistrationCustomAnswers(eventSlug, registrationId, answers, token) {
    return client
      .put(
        `/api/v1/registrations_component/custom_form_answers_organiser/${eventSlug}/update_answers/${registrationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': Rails.csrfToken(),
          },
          json: {
            answers,
          },
        },
      )
      .json();
  },
  async generateDemoEvent(sourceEventId) {
    const { token } = await hopinApi.getUserToken();
    return client
      .post('/create-demo-event', {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        json: {
          source_event_id: sourceEventId,
        },
      })
      .json();
  },
  async seedDemoEvent(id, sourceEventId) {
    const { token } = await hopinApi.getUserToken();
    return client
      .post(`/create-demo-event/${id}/seed-data`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        json: {
          source_event_id: sourceEventId,
        },
      })
      .json();
  },
  async marketoLead(payload) {
    return client.post(`/marketo_lead`, {
      json: payload,
    });
  },
  getSurveys(token, organizationId, eventId) {
    return client
      .get(`/api/v1/organisers/${organizationId}/events/${eventId}/surveys`, {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
  },
  updateSurvey(token, organizationId, eventId, surveyId, data) {
    return client
      .put(
        `/api/v1/organisers/${organizationId}/events/${eventId}/surveys/${surveyId}`,
        {
          headers: {
            credentials: 'include',
            Authorization: `Bearer ${token}`,
          },
          json: {
            survey: data,
          },
        },
      )
      .json();
  },
};

export default hopinApi;
