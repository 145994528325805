import { rem } from 'polished';
import styled from 'styled-components';

export const RoleTile = styled.div`
  height: ${rem(150)};
  width: ${rem(250)};
  display: flex;
  padding: var(--spacing-24) var(--spacing-12);
  margin-right: var(--spacing-16);
  background-color: ${props =>
    props.selected ? 'var(--color-blue-100)' : 'var(--color-gray-100)'};
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  cursor: pointer;
`;

export const Radio = styled.div`
  height: ${rem(18)};
  width: ${rem(18)};
  margin-right: ${rem(10)};
  background-color: var(--color-white);
  border: ${props =>
    props.selected
      ? '6px solid var(--color-blue-400)'
      : '1px solid var(--color-gray-400)'};
  border-radius: var(--border-radius-big);
`;

export const Description = styled.p`
  font-size: ${rem(11)};
  margin-top: var(--spacing-8);
`;

export const RolesContainer = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  margin: var(--spacing-16) 0;
`;
