import ArrowIcon from '@components/icons/arrow.js';
import { LocalizationContext } from '@features/localization';
import { dateFormats } from '@features/localization/date';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  DateRangePicker,
  END_DATE,
  START_DATE,
  useDateInput,
} from 'react-nice-dates';
import styled from 'styled-components';

const IconWrapper = styled.div`
  height: calc(var(--input-height) - 0.125rem);
  padding: 0.8rem 0.4rem 0.4rem;
`;

const ContainerWrapper = styled.div`
  padding: 0 0.4rem;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  min-width: 15rem;

  input {
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    height: calc(var(--input-height) - 0.125rem);
    text-align: center;
  }

  .date-input {
    width: 4em;
  }

  .time-input {
    width: 3em;
  }
`;

const Container = ({ children, focused, error }) => (
  <ContainerWrapper
    className={classNames('input', { '-focus': focused, '-error': error })}
  >
    {children}
  </ContainerWrapper>
);
Container.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  focused: PropTypes.bool,
};

const DateInput = ({ dateProps, id, placeholder }) => (
  <input
    {...dateProps}
    id={id}
    className="date-input"
    placeholder={placeholder}
    autoComplete="off"
  />
);
DateInput.propTypes = {
  dateProps: PropTypes.object,
  id: PropTypes.string,
  placeholder: PropTypes.string,
};

const TimeInput = ({ timeProps, label }) => (
  <input {...timeProps} className="time-input" aria-label={label} />
);
TimeInput.propTypes = {
  timeProps: PropTypes.object,
  label: PropTypes.string,
};

const DateTimeRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minimumDate,
  maximumDate,
  maximumDays,
}) => {
  const { t, dateLocale } = useContext(LocalizationContext);

  const startTimeInputProps = useDateInput({
    date: startDate,
    format: dateFormats.hourAndMinutes,
    locale: dateLocale,
    onDateChange: date => setStartDate(date || startDate),
  });

  const endTimeInputProps = useDateInput({
    date: endDate,
    format: dateFormats.hourAndMinutes,
    locale: dateLocale,
    onDateChange: date => setEndDate(date || endDate),
  });

  // react-nice-dates has a weird API, wants a component fn as it's child.
  const childrenComponent = ({
    startDateInputProps,
    endDateInputProps,
    focus,
  }) => {
    const error = !startDate || !endDate || startDate > endDate;
    const focused = focus == START_DATE || focus == END_DATE;
    return (
      <Container focused={focused} error={error}>
        <DateInput
          dateProps={startDateInputProps}
          id="start"
          placeholder={t('common.select-a-date')}
        />
        <TimeInput
          timeProps={startTimeInputProps}
          label={t('common.enter-a-time')}
        />
        <IconWrapper>
          <ArrowIcon rotation={90} />
        </IconWrapper>
        <DateInput
          dateProps={endDateInputProps}
          id="end"
          placeholder={t('common.select-a-date')}
        />
        <TimeInput
          timeProps={endTimeInputProps}
          label={t('common.enter-a-time')}
        />
      </Container>
    );
  };

  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={setStartDate}
      onEndDateChange={setEndDate}
      minimumDate={minimumDate}
      maximumDate={maximumDate}
      maximumLength={maximumDays}
      locale={dateLocale}
      format="dd MMM"
    >
      {childrenComponent}
    </DateRangePicker>
  );
};

DateTimeRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  minimumDate: PropTypes.instanceOf(Date),
  maximumDate: PropTypes.instanceOf(Date),
  maximumDays: PropTypes.number,
};

DateTimeRangePicker.defaultProps = {
  minimumDate: null,
  maximumDate: null,
  maximumDays: null,
};

export default DateTimeRangePicker;
