import styled from 'styled-components';

const TableHeader = styled.thead``;

const TableHeaderCell = styled.th`
  padding: var(--spacing-12);
  color: var(--color-gray-800);
  font-weight: var(--font-weight-semi-bold);
  text-align: left;
`;

const Table = styled.table`
  margin-top: var(--spacing-32);
  margin-bottom: var(--spacing-32);
  width: 100%;
  border-collapse: collapse;
`;

const TableBodyCell = styled.td.attrs({
  'data-testid': 'table-body-cell',
})`
  padding: var(--spacing-12);
  color: var(--color-gray-600);
  --border-radius-cell: calc(var(--border-radius-big) / 2);

  &:first-child {
    color: var(--color-gray-800);
    border-top-left-radius: var(--border-radius-cell);
    border-bottom-left-radius: var(--border-radius-cell);
  }

  &:last-child {
    border-bottom-right-radius: var(--border-radius-cell);
    border-top-right-radius: var(--border-radius-cell);
  }
`;
const TableBody = styled.tbody``;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: var(--color-gray-100);
  }
`;

export {
  Table,
  TableBody,
  TableBodyCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
};
