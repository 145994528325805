import getDisplayName from '@util/get-display-name';
import { node } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setUser } from './user-slice';

const UserProvider = ({ children }) => {
  const { user } = children.props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [user, dispatch]);

  return children;
};

UserProvider.propTypes = {
  children: node,
};

export const withUserProvider = WrappedComponent => {
  const WithRootPropsProvider = props => {
    return (
      <UserProvider>
        <WrappedComponent {...props} />
      </UserProvider>
    );
  };

  WithRootPropsProvider.displayName = `WithRootPropsProvider(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithRootPropsProvider;
};

export default UserProvider;
