import { createAction } from '@reduxjs/toolkit';

export const recordingsActions = {
  recordingGroupContainer: {
    recordingDownloaded: createAction(
      '[recording group container] recording downloaded',
    ),
  },
  detailedPage: {
    recordingControls: {
      recordingDownloaded: createAction(
        '[detailed page recording controls] recording downloaded',
      ),
    },
    relatedRecordings: {
      recordingDownloaded: createAction(
        '[detailed page related recordings] recording downloaded',
      ),
    },
  },
};
