import { createSlice } from '@reduxjs/toolkit';

export const orgInitialState = {
  id: '',
  name: '',
  logo: '',
  isOnFreePlan: true,
  isOnAdvancedPlan: false,
  isOnStarterPlan: false,
  numRegistrationsIncluded: 0,
  features: {},
};

const orgSlice = createSlice({
  name: 'org',
  initialState: orgInitialState,
});

export const hasComplexityOptions = state =>
  state.org.features?.eventsLiteEnabled && state.org.features?.eventsProEnabled;

const { reducer } = orgSlice;
export default reducer;
