/* global Rails */
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Toggle } from '@hopin-team/ui-toggle';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import { bool, func, number, string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledToggle = styled(Toggle)`
  display: flex;

  & > label {
    font-weight: 400;
  }
`;

const LeadRetrievalLicenseToggle = ({
  eventSlug,
  vendorId,
  status: initStatus,
  hasLicenses,
  onToggle,
  onError,
}) => {
  const { t } = useLocalization('lead-retrieval-license-toggle');
  const apiClient = initApiClient(Rails.csrfToken(), { json: true });
  const logger = getLogger(LOGGER_NAMES.VENDORS);

  const [status, setStatus] = useState(initStatus);
  const [isLoading, setLoading] = useState(false);

  const isDisabled = () =>
    status == 'used' || (status == 'not_issued' && !hasLicenses);

  const handleOnChange = async () => {
    if (isLoading) {
      return;
    }

    const url = Routes.assignLeadRetrievalLicenseOrganisersEventVendorPath({
      event_id: eventSlug,
      id: vendorId,
      _options: true,
    });
    const [toggle, newStatus] =
      status == 'not_issued' ? [true, 'issued'] : [false, 'not_issued'];

    setLoading(true);

    try {
      const response = await apiClient.post(url, { toggle }, { raw: true });
      const data = await response.json();

      if (!response.ok) {
        onError?.(data);
      } else {
        setStatus(newStatus);
        onToggle?.(data);
      }
    } catch (err) {
      logger.error(err);
      // There was a network error
      onError?.({ errors: err });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex alignItems="center">
      <StyledToggle
        sizeOption="large"
        label={t(`status.${status}`)}
        isToggled={status != 'not_issued'}
        isDisabled={isDisabled()}
        onChange={handleOnChange}
      />
      <Spinner
        ml={1}
        label="Loading..."
        isPolite
        isShowing={isLoading}
        data-testid="lead-retrieval-license-toggle-spinner"
      />
    </Flex>
  );
};

LeadRetrievalLicenseToggle.propTypes = {
  eventSlug: string.isRequired,
  vendorId: number.isRequired,
  status: string.isRequired,
  hasLicenses: bool.isRequired,
  onToggle: func,
  onError: func,
};

export default withLocalizationProvider(LeadRetrievalLicenseToggle);
