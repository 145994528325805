import { withModalProvider } from '@components/modal/modal-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import * as Routes from '@routes';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { compose } from 'redux';

const CreateEventButton = ({
  isDisabled,
  onClick = () => {},
  size = 'tiny',
}) => {
  const { t } = useLocalization('organizations-dashboard');

  return (
    <Button
      onClick={onClick}
      href={Routes.createEventWizardPath()}
      size={size}
      disabled={isDisabled}
      data-testid="create-event-button"
      aria-label={t('create_event_btn')}
    >
      {t('create_event_btn')}
    </Button>
  );
};

CreateEventButton.propTypes = {
  isDisabled: bool,
  onClick: func,
  organization: shape({
    externalId: string.isRequired,
  }),
  size: string,
  user: shape({
    externalId: string.isRequired,
  }),
};

export default compose(
  withModalProvider,
  withLocalizationProvider,
)(CreateEventButton);
