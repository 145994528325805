import hopinApi from '@api/hopin';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import {
  EVENT_CREATE,
  EVENT_DESTROY,
  EVENT_SCHEDULE_DRAFT_UPDATE,
  EVENT_UPDATE,
  EVENT_UPDATE_ALL,
  pusherBindSchedule,
  pusherInitialize,
} from '@util/pusher/schedule';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import {
  createItem,
  destroyItem,
  setSchedule,
  setScheduleDraft,
  updateItem,
} from '@/redux/reducers/schedule';

const logger = getLogger(LOGGER_NAMES.SCHEDULES_PANEL);

function useSchedulesPusherSubscription() {
  const dispatch = useDispatch();
  const eventId = useSelector(({ event }) => event.id);
  const eventSlug = useSelector(({ event }) => event.slug);
  const userToken = useAsync(async () => {
    const { token } = await hopinApi.getUserToken();
    return token;
  });

  const fetchSchedule = useCallback(async () => {
    try {
      const { items } = await hopinApi.getScheduleItems(
        userToken.value,
        eventSlug,
      );

      dispatch(setSchedule(items));
    } catch (err) {
      logger.error(err);
    }
  }, [dispatch, eventSlug, userToken.value]);

  const fetchEventScheduleDraft = useCallback(async () => {
    try {
      const { event } = await hopinApi.getEventDetails(
        userToken.value,
        eventSlug,
      );

      dispatch(setScheduleDraft(event.schedule_draft));
    } catch (err) {
      logger.error(err);
    }
  }, [dispatch, eventSlug, userToken.value]);

  useEffect(() => {
    let unbind;

    if (eventId && userToken.value) {
      fetchSchedule();
      fetchEventScheduleDraft();
      pusherInitialize({ eventId });

      try {
        unbind = pusherBindSchedule({
          eventId,
          handler: (key, payload) => {
            if (key === EVENT_UPDATE_ALL) {
              fetchSchedule();
            }

            if (key === EVENT_UPDATE) {
              dispatch(updateItem(payload));
            }

            if (key === EVENT_CREATE) {
              dispatch(createItem(payload));
            }

            if (key === EVENT_DESTROY) {
              dispatch(destroyItem(payload));
            }

            if (key === EVENT_SCHEDULE_DRAFT_UPDATE) {
              dispatch(setScheduleDraft(payload.schedule_draft));
            }
          },
        });
      } catch (err) {
        logger.error(err);
      }

      return () => {
        if (unbind) {
          unbind();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, userToken.value]);
}

export { useSchedulesPusherSubscription };
