import { Text } from '@hopin-team/ui-text';
import { bool, func, node, number } from 'prop-types';
import React from 'react';

export const translationKeyPrefix =
  'onboarding.organization-dashboard.payment-plan.registrations-wrapper-v2';

const Bold = ({ children }) => (
  <Text element="span" weight="bold" pattern="emphasis" font="text">
    {children}
  </Text>
);

Bold.propTypes = {
  children: node.isRequired,
};

const RegistrationWrapper = ({
  isAppsumoPlan,
  isAdvancedPlan,
  totalFreeRegistrationsAppsumo,
  totalPaidRegistrationsAppsumo,
  totalRegistrationsUsed,
  totalRegistrationsAvailable,
  t,
}) => {
  /** Special text for users in the Appsumo promotional plans */
  const AppsumoPlanText = () => (
    <>
      <Bold>{totalFreeRegistrationsAppsumo.toLocaleString()}</Bold>
      {totalFreeRegistrationsAppsumo === 1
        ? ` ${t(`${translationKeyPrefix}.freeRegistrations.singular`)} `
        : ` ${t(`${translationKeyPrefix}.freeRegistrations.plural`)} `}

      {` ${t(`${translationKeyPrefix}.and`)} `}
      <Bold>{totalPaidRegistrationsAppsumo.toLocaleString()}</Bold>
      {totalPaidRegistrationsAppsumo === 1
        ? ` ${t(`${translationKeyPrefix}.paidRegistrations.singular`)} `
        : ` ${t(`${translationKeyPrefix}.paidRegistrations.plural`)} `}
    </>
  );

  return (
    <Text
      element="div"
      color="grey-700"
      scale={3}
      mb={1}
      data-testid="registrations-wrapper"
    >
      {` ${t(`${translationKeyPrefix}.title`)} `}

      {isAppsumoPlan ? (
        <AppsumoPlanText />
      ) : (
        <Bold>{totalRegistrationsUsed.toLocaleString()}</Bold>
      )}

      {` ${t(`${translationKeyPrefix}.of`)} `}
      <Bold>{totalRegistrationsAvailable.toLocaleString()}</Bold>

      {isAdvancedPlan
        ? ` ${t(`${translationKeyPrefix}.registrations`)}. `
        : ` ${t(`${translationKeyPrefix}.registrations`)}
            ${t(`${translationKeyPrefix}.thisPeriodTitle`)} `}
    </Text>
  );
};

RegistrationWrapper.propTypes = {
  isAppsumoPlan: bool,
  isAdvancedPlan: bool,
  totalFreeRegistrationsAppsumo: number,
  totalPaidRegistrationsAppsumo: number,
  totalRegistrationsAvailable: number.isRequired,
  totalRegistrationsUsed: number.isRequired,
  t: func.isRequired,
};
export default RegistrationWrapper;
