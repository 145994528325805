import { useLocalization } from '@features/localization';
import { CheckboxGroup } from '@hopin-team/ui-checkbox';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { Flex } from '@hopin-team/ui-flex';
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Item } from './styles';

const TICKET_TYPES = ['free', 'paid'];
const BOTH = 'both';

const EventAccess = ({
  currentEventAccess,
  register,
  onChange,
  errorMessage,
}) => {
  const { t } = useLocalization(
    'event-creation-wizard.steps.details.form.event-access',
  );

  const [ticketTypes, setTicketTypes] = useState(
    currentEventAccess === BOTH ? TICKET_TYPES : [currentEventAccess],
  );

  const onClick = value => {
    setTicketTypes(prevTicketTypes => {
      if (prevTicketTypes.includes(value)) {
        // The user has deselected the checkbox, so we remove
        // the value from the `ticketTypes` array
        return prevTicketTypes.filter(t => t !== value);
      }

      return [...prevTicketTypes, value];
    });
  };

  useEffect(() => {
    const eventAccess = ticketTypes.length > 1 ? BOTH : ticketTypes[0];
    onChange(eventAccess);
  }, [onChange, ticketTypes]);

  return (
    <CheckboxGroup legend={t('label')} id="ticketType" name="ticketType" mt={3}>
      <Flex>
        {TICKET_TYPES.map(type => (
          <Item
            key={type}
            label={t(`options.${type}`)}
            value={type}
            checked={ticketTypes.includes(type)}
            onChange={() => onClick(type)}
            required={ticketTypes.length === 0}
            data-testid={`event-access-option-${type}`}
          />
        ))}
      </Flex>
      <input hidden name="eventAccess" ref={register} />

      {errorMessage && <FieldErrorMessage mt={1} errorMessage={errorMessage} />}
    </CheckboxGroup>
  );
};

EventAccess.propTypes = {
  currentEventAccess: string,
  register: func,
  onChange: func,
  errorMessage: string,
};

export default EventAccess;
