import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { Text } from '@hopin-team/ui-text';
import React from 'react';
import styled from 'styled-components';

const FullHeightContainer = styled(Flex)`
  height: 100%;
`;

const SliceErrorBoundaryBase = () => {
  const { t } = useLocalization('recording-library.slice');

  return (
    <FullHeightContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={2}
      px={6}
      mt={-5}
    >
      <Halon
        color="red-100"
        icon="warning"
        iconColor="red-300"
        iconScale={2}
        scale={5}
      />
      <Text pattern="headingFive" element="p" align="center" mt={3}>
        {t('something-went-wrong')}
      </Text>
      <Text pattern="body" element="p" align="center">
        {t('try-refresh-and-contact-support')}
      </Text>
    </FullHeightContainer>
  );
};

export const SliceErrorBoundary = withLocalizationProvider(
  SliceErrorBoundaryBase,
);
