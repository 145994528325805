import { Box } from '@hopin-team/ui-box';
import { Halon } from '@hopin-team/ui-halon';
import { Text } from '@hopin-team/ui-text';
import React from 'react';

const GenericErrorBoundaryFallback = () => (
  <Box p={2} data-testid="generic-error-boundary">
    <Box background="grey-white" cornerRadius="lg" p={2}>
      <Halon
        color="red-100"
        css={`
          flex-shrink: 0;
        `}
        icon="warning"
        iconColor="red-300"
        iconScale={2}
        scale={5}
      />
      <Box mt={1}>
        <Text pattern="headingThree" element="p">
          Something went wrong
        </Text>
        <Text pattern="body" element="p">
          Try refreshing the page, and contact support if the problem persists.
        </Text>
      </Box>
    </Box>
  </Box>
);

export default GenericErrorBoundaryFallback;
