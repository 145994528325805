import { useCallback, useMemo } from 'react';

import { numberFormatter } from '../helpers';

export const useAudienceCount = ({
  ticketTypes,
  status,
  selectedTicketTypes,
}) => {
  const defaultStatusData = useMemo(
    () => ({
      attended_count: 0,
      unattended_count: 0,
      magic_link_invited_count: 0,
      registered_count: 0,
    }),
    [],
  );

  const total = useMemo(() => {
    return ticketTypes.reduce(
      (total, { attendees_status_data: data }) =>
        total +
        (data?.magic_link_invited_count || 0) +
        (data?.registered_count || 0),
      0,
    );
  }, [ticketTypes]);

  const selectedTicketsData = useMemo(() => {
    return selectedTicketTypes?.length
      ? selectedTicketTypes.map(item => {
          return {
            ...defaultStatusData,
            ...ticketTypes.find(({ id }) => item.id === id)
              ?.attendees_status_data,
          };
        })
      : ticketTypes.map(item => ({
          ...defaultStatusData,
          ...item.attendees_status_data,
        }));
  }, [selectedTicketTypes, ticketTypes, defaultStatusData]);

  const getTotalCount = useCallback(
    type => {
      return ticketTypes.reduce(
        (total, { attendees_status_data: data }) => total + (data?.[type] || 0),
        0,
      );
    },
    [ticketTypes],
  );

  const totalPendingInvites = useMemo(() => {
    return getTotalCount('magic_link_invited_count');
  }, [getTotalCount]);

  const count = useMemo(() => {
    return selectedTicketsData.reduce((all, current) => {
      if (status === 'registered') {
        return all + current.registered_count;
      } else if (status === 'attended') {
        return all + current.attended_count;
      } else if (status === 'unattended') {
        return all + current.unattended_count;
      } else {
        return all + current.magic_link_invited_count;
      }
    }, 0);
  }, [status, selectedTicketsData]);

  return {
    count,
    total,
    totalPendingInvites,
    formattedCount: numberFormatter(count),
    formattedTotal: numberFormatter(total),
  };
};
