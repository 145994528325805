import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const StyledFlex = styled(Flex)`
  border: 0.0625rem solid var(--color-gray-300);
  border-radius: 0.5rem;
`;

export const StyledBox = styled(Box)`
  min-width: 8.4375rem;
`;
