const DIGIT = /[0-9]/;
const ONLY_UNTIL_FIVE = /[0-5]/;
const SEPARATOR = ':';

// this array should be updated when mapFormatToMask is updated
export const SUPPORTED_FORMATS = ['HH:mm:ss', 'HH:mm', 'mm:ss'];

export const mapFormatToMask = format => {
  if (!SUPPORTED_FORMATS.includes(format)) {
    throw `Currently the only supported formats are the following: ${SUPPORTED_FORMATS.join(
      ', ',
    )}`;
  }

  const timestampMask = [];

  for (let i = 0; i < format.length; i++) {
    if (format[i] === 'm' || format[i] === 's') {
      if (i > 0 && format[i - 1] === SEPARATOR) {
        timestampMask.push(ONLY_UNTIL_FIVE);
      } else {
        timestampMask.push(DIGIT);
      }
    } else if (format[i] === SEPARATOR) {
      timestampMask.push(SEPARATOR);
    } else {
      timestampMask.push(DIGIT);
    }
  }

  return timestampMask;
};
