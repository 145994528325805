import { Pill } from '@hopin-team/ui-pill';
import { Text } from '@hopin-team/ui-text';
import { array, string } from 'prop-types';
import React from 'react';

import { Styles } from './pills-list-styles';

const PillsList = ({ list, title }) => {
  return (
    <>
      <Text pattern="labelFour" element="p" my={3}>
        {title}
      </Text>
      <Styles.PillsFlex>
        {list.map((tag, idx) => (
          <Pill label={tag} key={idx} mr={2} />
        ))}
      </Styles.PillsFlex>
    </>
  );
};

PillsList.propTypes = {
  list: array,
  title: string,
};

export default PillsList;
