import { i18n } from '@features/localization';
import { prop, sortBy } from 'lodash/fp';

const EN = 'en';
const DE = 'de';
const ES = 'es';
const JA = 'ja';
const FR = 'fr';
const PL = 'pl';
const IT = 'it';
const PT = 'pt';
const NL = 'nl';
const KO = 'ko';

const { t } = i18n;

export const getLanguageOptions = () =>
  sortBy(prop('title'), [
    { option: EN, title: `${t('common.english')} - English (US)` },
    { option: DE, title: `${t('common.german')} - Deutsch` },
    { option: ES, title: `${t('common.spanish')} - Español` },
    { option: JA, title: `${t('common.japanese')} - 日本語` },
    { option: FR, title: `${t('common.french')} - Français` },
    { option: PL, title: `${t('common.polish')} - Polski` },
    { option: IT, title: `${t('common.italian')} - Italiano` },
    {
      option: PT,
      title: `${t('common.portuguese-brazil')} - Português (Brazil)`,
    },
    { option: NL, title: `${t('common.dutch')} - Nederlands` },
    { option: KO, title: `${t('common.korean')} - 한국어` },
  ]);
