const { useSelector } = require('react-redux');

const useIsFreeTrial = () => {
  const { event, organization } = useSelector(({ event, organization }) => ({
    event,
    organization,
  }));
  if (!event) return false;

  const isFreePlan = organization?.currentPaymentPlan.name === 'Free';
  const isPublishAllowed =
    organization?.currentPaymentPlan.features.publishEvent;

  return {
    isFreeTrial: Boolean(isFreePlan && !isPublishAllowed),
    isDraft: event?.phase === 'Draft',
    isLive: event?.phase === 'Live',
  };
};

export default useIsFreeTrial;
