import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { getPluralForm } from '@features/localization/helpers';
import { Flex } from '@hopin-team/ui-flex';
import PropTypes from 'prop-types';
import React from 'react';

import { ConfirmationItemText } from './confirmation-prompt.styled';
import { ClockIcon } from './icons/clock';
import { PlayIcon } from './icons/play';
import { RevertIcon } from './icons/revert';

const ConfirmationItem = ({ children, Icon }) => (
  <Flex mb={3}>
    <Flex mr={2}>
      <Icon />
    </Flex>
    <ConfirmationItemText>{children}</ConfirmationItemText>
  </Flex>
);

ConfirmationItem.propTypes = {
  children: PropTypes.node.isRequired,
  Icon: PropTypes.func.isRequired,
};

export const ConfirmationPromptComponent = ({ isPublished, timeEstimate }) => {
  const { t, language } = useLocalization(
    'edit-recording-modal.confirmation-prompt',
  );
  const pluralForm = getPluralForm(language, timeEstimate);
  return (
    <Flex flexDirection="column" m={2} mt={4}>
      <ConfirmationItem Icon={ClockIcon}>
        {`${t(`will-take-n-minutes.plurals.${pluralForm}`, {
          timeEstimate,
        })} ${t('can-leave-this-page')}`}
      </ConfirmationItem>
      {isPublished && (
        <ConfirmationItem Icon={PlayIcon}>
          {t('what-happen-with-published-recordings')}
        </ConfirmationItem>
      )}
      <ConfirmationItem Icon={RevertIcon}>
        {t('can-i-revert-my-changes')}
      </ConfirmationItem>
    </Flex>
  );
};

ConfirmationPromptComponent.propTypes = {
  isPublished: PropTypes.bool.isRequired,
  timeEstimate: PropTypes.number.isRequired,
};

export const ConfirmationPrompt = withLocalizationProvider(
  ConfirmationPromptComponent,
);
