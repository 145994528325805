import { createReactBindings } from '@slicesjs/react-core';
import { FE_SLICES_CDN } from '@util/config';
import getDisplayName from '@util/get-display-name';
import React from 'react';

const { SlicesProvider } = createReactBindings();

export const withSlicesProvider = Component => {
  const WithSlicesProvider = props => (
    <SlicesProvider origin={FE_SLICES_CDN}>
      <Component {...props} />
    </SlicesProvider>
  );

  WithSlicesProvider.displayName = `WithSlicesProvider(${getDisplayName(
    Component,
  )})`;

  return WithSlicesProvider;
};
