import { PageButtons } from '@hopin-team/ui-pagination';
import { rem } from 'polished';
import styled from 'styled-components';

const PaginationNumbers = styled(PageButtons)`
  margin: auto;
`;

const ButtonPlaceholder = styled.div`
  width: ${rem(30)};
`;

export { PaginationNumbers, ButtonPlaceholder };
