import moment from 'moment';

export const normalizeStartEnd = (startTime, endTime, chartData) => {
  startTime = moment(startTime);
  endTime = moment(endTime);
  const currentTime = moment();

  // Registrations and Ticket Sales data are Objects
  // and they need to be shown before Event Start Time
  if (chartData && !Array.isArray(chartData)) {
    let firstDataAt = startTime;

    for (const date in chartData) {
      const dataAt = moment(date);

      if (dataAt.isBefore(firstDataAt)) {
        firstDataAt = dataAt;
      }
    }

    if (firstDataAt.isBefore(startTime)) {
      startTime = firstDataAt;
    }
  }

  if (currentTime.isBefore(startTime)) {
    startTime = currentTime;
  }

  if (currentTime.isBefore(endTime)) {
    endTime = currentTime;
  }

  return {
    startMoment: startTime,
    endMoment: endTime,
  };
};

export const getTickBounds = (startMoment, endMoment, timeOptions) => {
  startMoment = startMoment.clone();
  endMoment = endMoment.clone();

  const { stepSize, unit } = timeOptions;
  const searchUnit = unit == 'minute' ? 'hour' : 'day';
  let closestStart = startMoment.clone().startOf(searchUnit);
  let closestEnd = endMoment.clone();
  closestEnd.add(1, searchUnit);
  closestEnd.startOf(searchUnit);

  startMoment.subtract(stepSize, unit);

  while (closestStart.isSameOrBefore(startMoment)) {
    closestStart.add(stepSize, unit);
  }

  endMoment.add(stepSize, unit);

  while (closestEnd.isSameOrAfter(endMoment)) {
    closestEnd.subtract(stepSize, unit);
  }

  return {
    minTick: closestStart,
    maxTick: closestEnd,
  };
};

export const getTimeOptions = (startMoment, endMoment, unit, stepSize) => {
  if (!unit) {
    let duration = endMoment.diff(startMoment);

    if (duration < moment.duration(10, 'minutes')) {
      stepSize = 1;
      unit = 'minute';
    } else if (duration < moment.duration(20, 'minutes')) {
      stepSize = 5;
      unit = 'minute';
    } else if (duration < moment.duration(1, 'hour')) {
      stepSize = 10;
      unit = 'minute';
    } else if (duration < moment.duration(3, 'hours')) {
      stepSize = 20;
      unit = 'minute';
    } else if (duration < moment.duration(5, 'hours')) {
      stepSize = 30;
      unit = 'minute';
    } else if (duration < moment.duration(10, 'hours')) {
      stepSize = 1;
      unit = 'hour';
    } else if (duration < moment.duration(15, 'hours')) {
      stepSize = 2;
      unit = 'hour';
    } else if (duration < moment.duration(24, 'hours')) {
      stepSize = 4;
      unit = 'hour';
    } else if (duration < moment.duration(2, 'days')) {
      stepSize = 6;
      unit = 'hour';
    } else {
      stepSize = 1;
      unit = 'day';
    }
  }

  return {
    unit: unit,
    stepSize: stepSize,
    displayFormats: {
      minute: 'h:mm a',
      hour: 'll ha',
      day: 'll',
    },
  };
};
