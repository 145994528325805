import { TabList, TabPanels } from '@hopin-team/ui-tabs';
import { Text } from '@hopin-team/ui-text';
import { array, object, string } from 'prop-types';
import React from 'react';

import RenderRichText from '../../components/ckeditor5/render-rich-text';
import { Styles } from './event-info-tabs.styles';
import Schedule from './schedule/schedule-component';
import Speakers from './speakers/speakers-component';
import Sponsors from './sponsors/sponsors-component';
import Vendors from './vendors/vendors-component';

const EventInfoTabs = ({
  event,
  speakers,
  schedule,
  sponsors,
  vendors,
  eventRoute,
  customisations,
  locales,
}) => {
  const customText = customisations || {};
  const speakersAvailable = Boolean(speakers?.length);
  const scheduleAvailable = Boolean(schedule?.length);
  const sponsorsAvailable = Boolean(sponsors?.length);
  const vendorsAvailable = Boolean(vendors?.length);

  return (
    <Styles.Tabs defaultIndex={0}>
      <TabList>
        <Styles.Tab>{customText['description'] || 'Overview'}</Styles.Tab>
        {speakersAvailable && (
          <Styles.Tab>{customText['speakers'] || 'Speakers'}</Styles.Tab>
        )}
        {scheduleAvailable && (
          <Styles.Tab>{customText['schedule'] || 'Schedule'}</Styles.Tab>
        )}
        {sponsorsAvailable && (
          <Styles.Tab>{customText['sponsors'] || 'Sponsors'}</Styles.Tab>
        )}
        {vendorsAvailable && (
          <Styles.Tab>{customText['booths'] || 'Booths'}</Styles.Tab>
        )}
      </TabList>
      <TabPanels>
        <Styles.TabPanel>
          <Styles.Grid>
            <div>
              <Text pattern="headingTwo" mb={3} as="h2">
                {event.name}
              </Text>
              <RenderRichText value={event.description} />
            </div>
            {speakersAvailable && (
              <div data-testid="speaker-tab">
                <Text pattern="headingTwo" as="h3" mb={3}>
                  {customText['speakers'] || 'Speakers'}
                </Text>
                <Speakers speakers={speakers} />{' '}
              </div>
            )}
            {scheduleAvailable && (
              <div data-testid="schedule-tab">
                <Text pattern="headingTwo" as="h3" mb={3}>
                  {customText['schedule'] || 'Schedule'}
                </Text>
                <Schedule schedule={schedule} eventRoute={eventRoute} />
              </div>
            )}
            {sponsorsAvailable && (
              <div data-testid="sponsors-tab">
                <Sponsors
                  sponsors={sponsors}
                  title={
                    customText['proudly-supported-by'] || 'Proudly Supported By'
                  }
                  tierNames={locales['sponsors']}
                />
              </div>
            )}
            {vendorsAvailable && (
              <div data-testid="vendors-tab">
                <Text pattern="headingTwo" as="h3" mb={3}>
                  {customText['booths'] || 'Booths'}
                </Text>
                <Vendors vendors={vendors} />
              </div>
            )}
          </Styles.Grid>
        </Styles.TabPanel>
        {speakersAvailable && (
          <Styles.TabPanel>
            <Speakers speakers={speakers} viewAll />
          </Styles.TabPanel>
        )}
        {scheduleAvailable && (
          <Styles.TabPanel>
            <Schedule
              schedule={schedule}
              event={event}
              eventRoute={eventRoute}
            />
          </Styles.TabPanel>
        )}
        {sponsorsAvailable && (
          <Styles.TabPanel>
            <Sponsors
              event={event}
              sponsors={sponsors}
              title={
                customText['proudly-supported-by'] || 'Proudly Supported By'
              }
              tierNames={locales['sponsors']}
            />
          </Styles.TabPanel>
        )}
        {vendorsAvailable && (
          <Styles.TabPanel>
            <Vendors vendors={vendors} viewAll />
          </Styles.TabPanel>
        )}
      </TabPanels>
    </Styles.Tabs>
  );
};

EventInfoTabs.propTypes = {
  event: object,
  speakers: array,
  schedule: array,
  sponsors: array,
  vendors: array,
  eventRoute: string,
  customisations: object,
  locales: object,
};

export default EventInfoTabs;
export { EventInfoTabs as Events };
