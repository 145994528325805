export const images = {
  essentials: {
    '1x': require(`event-creation-wizard/complexity-selector/essentials@1x.png`),
    '2x': require(`event-creation-wizard/complexity-selector/essentials@2x.png`),
  },
  complex: {
    '1x': require(`event-creation-wizard/complexity-selector/complex@1x.png`),
    '2x': require(`event-creation-wizard/complexity-selector/complex@2x.png`),
  },
};
