import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { TabPanel } from '@hopin-team/ui-tabs';
import styled from 'styled-components';

const ViewLinksButton = styled(Button).attrs({
  isInline: true,
  isOutlined: true,
  size: 'small',
})`
  border: 0;

  &:hover {
    border: 0;
  }
`;

const TabWrapper = styled(Flex).attrs({
  flexDirection: 'column',
  background: 'grey-100',
  p: 4,
})`
  overflow-y: auto;
`;

const StyledTabPanel = styled(TabPanel)`
  &:focus:not([data-focus-visible-added]) {
    outline: none;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export {
  ViewLinksButton,
  TabWrapper,
  StyledTabPanel as TabPanel,
  HeaderWrapper,
};
