import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Modal } from '@hopin-team/ui-modal';
import { calcSpace } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import { string } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

export const ContainerWrapper = styled.div`
  background-color: var(--color-gray-100);
  height: 100%;
  min-height: 100vh;
`;

export const CenteredContainer = styled(Box)`
  margin: ${calcSpace(3)} auto;
  max-width: ${rem(1000)};
  padding-bottom: 20px;
`;

export const LeftPanel = styled.div`
  grid-column: 1/2;
  display: flex;
  flex-direction: row-reverse;
`;

export const CenterPanel = styled.div`
  grid-column: 2/4;
`;

export const RightPanel = styled.div`
  grid-column: 4/5;
`;

export const AnnouncementModal = styled(Modal)`
  & > div:first-child {
    width: 520px;
  }

  ${({ withCloseButton }) =>
    withCloseButton &&
    css`
      button[aria-label='Close modal'] {
        top: ${rem(20)};
      }
    `}
`;

export const AnnouncementModalBox = styled(Flex)`
  padding: ${rem(24)};

  ${({ backgroundColor }) => {
    if (!backgroundColor) return;

    const color = `var(--color-${backgroundColor})`;

    return css`
      background-color: ${color};
    `;
  }}
`;

export const AnnouncementsText = styled(({ weight, children, ...rest }) => (
  <Text
    element="p"
    pattern="body"
    style={{ fontWeight: weight || 500 }}
    {...rest}
  >
    {children}
  </Text>
))``;

export const AnnouncementModalHeader = styled(AnnouncementModalBox)`
  ${AnnouncementsText} {
    font-size: ${rem(18)};
    line-height: ${rem(16)};
    color: var(--color-black);
  }
`;

export const AnnouncementModalContent = styled(AnnouncementModalBox)`
  flex-direction: column;
  justify-content: left;
  padding-top: 0;
  padding-bottom: 0;
  ${AnnouncementsText} {
    color: var(--color-gray-600);
  }

  img {
    margin: 0 auto;
    width: ${rem(272)};
  }
`;

export const AnnouncementModalFooter = styled(AnnouncementModalBox)`
  flex-direction: row;
  justify-content: flex-end;
  display: flex;

  ${({ withDivider }) =>
    withDivider &&
    css`
      border-top: ${rem(1)} solid var(--color-gray-300);
    `}
`;

export const SecondaryActionButton = styled.a`
  height: ${rem(32)};
  margin-left: ${rem(16)};
  margin-right: ${rem(16)};
  padding: 0 ${rem(8)};
  display: inline-flex;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
  outline: none;
  font-weight: 500;
  color: var(--color-blue-400);
  line-height: ${rem(24)};
  size: ${rem(14)};
`;

export const LinkIcon = ({
  href,
  'data-testid': dataTestId,
  className,
  ...rest
}) => (
  <a href={href} data-testid={dataTestId} className={className}>
    <Icon {...rest} />
  </a>
);

LinkIcon.propTypes = {
  href: string.isRequired,
  'data-testid': string,
  className: string,
};
