import { Spinner } from '@hopin-team/ui-spinner';
import { array, bool } from 'prop-types';
import React from 'react';

import MetricItem from '../../common/metric-item/metric-item';
import { StyledBox, StyledFlex } from './styles';

const TopMetrics = ({ data, isLoading }) => {
  return (
    <StyledFlex
      pb={3}
      px={3}
      pt={0}
      mb={3}
      flexWrap="wrap"
      justifyContent="space-between"
      data-testid="top-metrics"
    >
      {data.map((props, i) =>
        isLoading ? (
          <StyledBox key={i}>
            <Spinner isShowing mt={4} mb={0} mx="auto" />
          </StyledBox>
        ) : (
          <MetricItem key={i} {...props} />
        ),
      )}
    </StyledFlex>
  );
};

TopMetrics.propTypes = {
  data: array.isRequired,
  isLoading: bool.isRequired,
};

export default TopMetrics;
