import { Flex } from '@hopin-team/ui-flex';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { Text } from '@hopin-team/ui-text';
import { Toggle } from '@hopin-team/ui-toggle';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > * + * {
    margin-top: ${rem(16)};
  }

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    margin-bottom: 0;
  }
`;

export const RadioButtonCard = styled.label`
  display: flex;
  align-items: flex-start;
  border: 0.0625rem solid
    ${({ isSelected }) => (isSelected ? '#CAE7FF' : 'transparent')};
  background-color: ${({ isSelected }) => (isSelected ? '#E8F1F7' : '#F6F5F1')};
  border-radius: ${rem(14)};
  padding: ${rem(11)};
  cursor: pointer;

  &:has(:focus-visible) {
    outline-color: var(--color-blue-500);
    outline-style: solid;
  }

  &:hover {
    ${({ isSelected }) =>
      isSelected
        ? css`
            box-shadow: 0px 10px 20px -5px rgba(0, 64, 221, 0.1);
          `
        : css`
            background-color: #f8f7f4;
          `}
  }
`;

export const RadioButtonInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + div::before {
    content: '';
    position: absolute;
    width: ${rem(10)};
    height: ${rem(10)};
    border-radius: 100%;
    background-color: var(--color-blue-400);
    left: calc(25% - 1px);
    top: calc(25% - 1px);
  }
`;

export const CustomRadio = styled.div`
  position: relative;
  min-width: ${rem(18)};
  width: ${rem(18)};
  height: ${rem(18)};
  border: ${rem(1)} solid
    ${({ isSelected }) =>
      isSelected ? 'var(--color-blue-400)' : 'var(--color-gray-400)'};
  border-radius: 100%;
  background-color: ${({ isSelected }) => (isSelected ? 'white' : '#f6f5f1')};
`;

export const RadioLabel = styled(Text)`
  line-height: ${rem(14)};
`;

export const Pill = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 6px;
  max-height: ${rem(24)};
  background-color: ${({ isActive }) => (isActive ? '#D9E6EF' : '#EAE7E2')};
  color: ${({ isActive }) => (isActive ? '#0072A4' : '#323439')};
`;

export const RadioDescription = styled(Text)`
  line-height: ${rem(20)};
`;

export const OnsiteLabel = styled(Text)`
  line-height: ${rem(20)};
`;

export const OnsiteDetailsWrapper = styled(Flex)`
  display: grid;
  grid-template-areas:
    'venue address'
    'map map';
  width: 100%;
  gap: ${rem(20)};

  & > div:nth-child(1) {
    grid-area: venue;
  }

  & > div:nth-child(2) {
    grid-area: address;
  }

  & > div:nth-child(3) {
    grid-area: map;
  }

  input {
    border-radius: ${rem(10)};
  }

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MIN', theme)}) {
    grid-template-areas:
      'venue'
      'address'
      'map';
  }
`;

export const CustomToggle = styled(Toggle)`
  & > input:not(:checked) ~ span {
    &::before {
      background-color: white;
      border: 1px solid var(--color-gray-400);
    }

    &::after {
      background-color: var(--color-gray-400);
    }
  }
`;
