export const VIDEO_EDIT_STATUSES = {
  success: 'success',
  failure: 'failure',
  processing: 'processing',
};

export const VIEW = {
  TILE: 'tile',
  GROUPED: 'grouped',
};

export const EVENT_PART = {
  STAGE: 'stage',
  SESSION: 'session',
};

export const AREA_TYPE = {
  STAGE: 'stage',
  SESSION: 'session',
};

export const RECORDING_TYPE = {
  PRIMARY: 'primary',
  BACKUP: 'backup',
  REHEARSAL: 'rehearsal',
};

export const RANK = {
  BACKUP: 'backup',
  MAIN: 'main',
};

export const WHEN = {
  PRE_EVENT: 'pre_event',
  IN_EVENT: 'in_event',
};

export const MAX_TITLE_LENGTH = 300;

export const HOURS_FOR_REPLAY_PROCESSING = 2;
