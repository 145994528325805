import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

Styles.Image = styled.img`
  border-radius: var(--border-radius);
  width: ${rem(64)};
  height: ${rem(64)};
  object-fit: cover;
`;

Styles.Dot = styled.span`
  width: 2px;
  height: 2px;
  background: var(--color-gray-800);
  display: inline-block;
  margin: 0 8px;
`;

Styles.PillsFlex = styled(Flex)`
  flex-wrap: wrap;
  & > * {
    margin-bottom: ${rem(16)};
  }
`;

Styles.Link = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

Styles.HostedBy = styled(Text)`
  font-weight: 600;
`;
