import MouseCursor from '@components/icons/mouse-cursor';
import { Box as CircleGraphicOuterContainer } from '@hopin-team/ui-box';
import { number, string } from 'prop-types';
import React from 'react';

import {
  CircleGraphicInnerContainer,
  CircleGraphicSvg,
  ColoredIcon,
} from './styles';

const CircleGraphicIcon = ({ name, color }) => {
  if (name === 'mouse-cursor') {
    // ui-icon package doesn't export this icon
    return <MouseCursor color={color} />;
  } else {
    // ui-icon Icon does accept color but only in a color-number format.
    // This color prop is an actual color value (or var)
    // so here we just color the icon with css instead
    return <ColoredIcon name={name} iconColor={color} scale={3.5} />;
  }
};

CircleGraphicIcon.propTypes = {
  color: string.isRequired,
  name: string.isRequired,
};

const CircleGraphic = ({ height, width, color, iconName }) => {
  const circleRadius = width / 2;

  return (
    <CircleGraphicOuterContainer>
      <CircleGraphicInnerContainer width={width} height={height}>
        <CircleGraphicSvg width={width} height={height}>
          <circle
            r={circleRadius}
            cx={width / 2}
            cy={height / 2}
            fill={color}
            opacity={0.2}
          />
        </CircleGraphicSvg>
        <CircleGraphicIcon name={iconName} color={color} scale={3.5} />
      </CircleGraphicInnerContainer>
    </CircleGraphicOuterContainer>
  );
};

CircleGraphic.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
  color: string.isRequired,
  iconName: string.isRequired,
};

export default CircleGraphic;
