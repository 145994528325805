import hopinApi from '@api/hopin';
import { getCategoryFromPathname } from '@features/recordings/utils/utils';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import Pusher from 'pusher-js';
import { eventChannel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';

import { selectEvent } from '../../event/event-selectors';
import { selectVideoAreaById } from '../../recording-groups/recording-groups-selectors';
import {
  refetchRecordingAreasThunk,
  refetchVideoAreadRecordingsThunk,
  removeRecordingFromVideoAreas,
} from '../../recording-groups/recording-groups-slice';
import { selectRecordingExists } from '../recordings-selectors';
import { removeRecording } from '../recordings-slice';
import {
  getLiveUpdatesChannel,
  RECORDING_ADDED_EVENT,
  RECORDING_REMOVED_EVENT,
  RECORDING_UPDATED_EVENT,
} from './recording-live-updates-config';

const CLUSTER = process.env.PUSHER_CLUSTER;
const APP_KEY = process.env.PUSHER_KEY;

export function* recordingsLiveUpdatesSaga() {
  const { token } = yield hopinApi.getUserToken();
  const { eventId, slug } = yield select(selectEvent);

  const channel = eventChannel(emitter => {
    const pusher = new Pusher(APP_KEY, {
      cluster: CLUSTER,
      authEndpoint: '/api/v2/pusher/auth',
      auth: { headers: { Authorization: `Bearer ${token}` } },
      enabledTransports: ['ws'],
      logger: getLogger(LOGGER_NAMES.REPLAY),
    });

    const channelName = getLiveUpdatesChannel(eventId);
    const pusherChannel = pusher.subscribe(channelName);

    const handleEvent = eventType => ({ event_part_type, event_part_id, id }) =>
      emitter({
        type: eventType,
        payload: {
          eventPartId: event_part_id,
          eventPartType: event_part_type,
          id,
        },
      });

    const handleRecordingAdded = handleEvent(RECORDING_ADDED_EVENT);
    const handleRecordingRemoved = handleEvent(RECORDING_REMOVED_EVENT);
    const handleRecordingUpdated = handleEvent(RECORDING_UPDATED_EVENT);

    pusherChannel.bind(RECORDING_ADDED_EVENT, handleRecordingAdded);
    pusherChannel.bind(RECORDING_REMOVED_EVENT, handleRecordingRemoved);
    pusherChannel.bind(RECORDING_UPDATED_EVENT, handleRecordingUpdated);

    return () => {
      pusherChannel.unbind(RECORDING_ADDED_EVENT, handleRecordingAdded);
      pusherChannel.unbind(RECORDING_REMOVED_EVENT, handleRecordingRemoved);
      pusherChannel.unbind(RECORDING_UPDATED_EVENT, handleRecordingUpdated);

      pusher.unsubscribe(channelName);
    };
  });

  try {
    while (true) {
      let event = yield take(channel);

      switch (event.type) {
        case RECORDING_REMOVED_EVENT: {
          const { id } = event.payload;
          const recordingExists = yield select(state =>
            selectRecordingExists(state, id),
          );

          if (recordingExists) {
            yield put(removeRecording({ id }));
            yield put(removeRecordingFromVideoAreas({ recordingId: id }));
          }
          break;
        }

        case RECORDING_UPDATED_EVENT:
        case RECORDING_ADDED_EVENT: {
          const { eventPartId, eventPartType } = event.payload;

          const isExistingVideoArea = yield select(state =>
            selectVideoAreaById(state, eventPartId),
          );

          if (isExistingVideoArea) {
            yield put(
              refetchVideoAreadRecordingsThunk({
                videoAreaId: eventPartId,
                videoAreaType: eventPartType,
                category: getCategoryFromPathname(window.location.pathname),
              }),
            );
          } else {
            yield put(refetchRecordingAreasThunk({ slug }));
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } finally {
    channel.close();
  }
}
