import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { AREA_TYPE, RANK, RECORDING_TYPE, WHEN } from '../constants';
import {
  useDeleteRecording,
  useRecordings,
  useUpdateRecording,
} from '../hooks';
import { NoResults } from '../no-results';
import { selectEvent } from '../redux/slices/event/event-selectors';
import { getGroupByOptions } from '../redux/slices/recording-groups/selectors';
import { getRecordingsByGroup } from '../redux/slices/recordings/recordings-selectors';
import { selectExternalRegistrationId } from '../redux/slices/registration/registration-selector';
import {
  getRecordingById,
  getSecondaryLinks,
  reportRecordingPublishStatusUpdated,
} from '../utils/utils';
import { RecordingGroup } from './recording-group';
import { RecordingGroupSection } from './recording-group-container.styled';

const RecordingGroupContainer = ({
  videoArea,
  rank,
  when,
  showTitle = true,
  showLinks = true,
  filters,
  recordingType,
  noResultsStyle = 'small',
  onRecordingSelect,
  customThumbnailsEnabled,
}) => {
  const { addAlert } = useContext(AlertsContext);
  const { slug, timeStart, timeEnd, eventId } = useSelector(selectEvent);
  const registrationExternalId = useSelector(selectExternalRegistrationId);
  const { t } = useLocalization();

  const groupOptions = { videoArea, rank, when };
  const { isError, isLoading, refetchRecordings } = useRecordings(
    slug,
    groupOptions,
  );

  const group = useSelector(state => getGroupByOptions(state, groupOptions));
  const recordings = useSelector(state =>
    getRecordingsByGroup(state, group, filters),
  );

  const showRecordingGroup = group?.hasRecordings || noResultsStyle === 'small';
  const recordingTypeEndTime =
    recordingType === RECORDING_TYPE.REHEARSAL ? timeStart : timeEnd;

  const [recordingIdToDelete, setRecordingIdToDelete] = useState(null);

  const { updateRecording } = useUpdateRecording({
    slug,
    onSuccess: ({ originalRecording, updatedRecording }) => {
      if (originalRecording.published !== updatedRecording.published) {
        reportRecordingPublishStatusUpdated(originalRecording, {
          userId: registrationExternalId,
          eventId,
          isPublished: updatedRecording.published,
        });
      }
    },
    onError: ({ message: text }) => {
      addAlert({ active: true, text, pattern: 'error' });
    },
  });

  const { deleteRecording } = useDeleteRecording({
    slug,
    onError: ({ message: text }) =>
      addAlert({ active: true, text, pattern: 'error' }),
  });

  const onRecordingClick = useCallback(
    recording =>
      (window.location.href = Routes.organisersEventRecordingPath(
        slug,
        recording.id,
      )),
    [slug],
  );

  const handleRecordingChange = useCallback(
    (id, attributes) => {
      const recording = getRecordingById(recordings, id);
      if (recording) {
        updateRecording(recording, attributes);
      }
    },
    [recordings, updateRecording],
  );

  const handleRecordingDelete = useCallback(id => setRecordingIdToDelete(id), [
    setRecordingIdToDelete,
  ]);

  const cleanUpRecordingDeletion = useCallback(
    () => setRecordingIdToDelete(null),
    [setRecordingIdToDelete],
  );

  const finishRecordingDeletion = useCallback(() => {
    deleteRecording(recordingIdToDelete);
    cleanUpRecordingDeletion();
  }, [cleanUpRecordingDeletion, deleteRecording, recordingIdToDelete]);

  const { rehearsalHref, backupHref } = getSecondaryLinks(slug, videoArea);

  return (
    <RecordingGroupSection data-testid="recordingGroup">
      <Alerts />

      {showRecordingGroup ? (
        <RecordingGroup
          title={showTitle ? videoArea.name : null}
          rehearsalHref={showLinks ? rehearsalHref : null}
          backupHref={showLinks ? backupHref : null}
          videoAreaType={videoArea.type}
          recordings={recordings}
          isError={isError}
          isLoading={isLoading}
          onRecordingClick={onRecordingClick}
          onRecordingDelete={handleRecordingDelete}
          onRecordingUpdate={handleRecordingChange}
          refetchRecordings={refetchRecordings}
          endTime={recordingTypeEndTime}
          onRecordingSelect={onRecordingSelect}
          customThumbnailsEnabled={customThumbnailsEnabled}
        />
      ) : (
        <NoResults
          title={videoArea.name}
          recordingType={recordingType}
          endTime={recordingTypeEndTime}
        />
      )}

      <ConfirmationDialog
        isShowing={recordingIdToDelete && !isLoading}
        title={t('delete-recording-confirmation-modal.title')}
        message={t('delete-recording-confirmation-modal.message')}
        primaryButtonText={t('delete-recording-confirmation-modal.confirm')}
        colorPattern="danger"
        secondaryButtonText={t('delete-recording-confirmation-modal.cancel')}
        isDismissible
        withCloseButton
        onClose={cleanUpRecordingDeletion}
        onConfirm={finishRecordingDeletion}
        onCancel={cleanUpRecordingDeletion}
      />
    </RecordingGroupSection>
  );
};

RecordingGroupContainer.propTypes = {
  videoArea: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf([AREA_TYPE.STAGE, AREA_TYPE.SESSION]),
    name: PropTypes.string,
  }).isRequired,
  rank: PropTypes.oneOf([RANK.MAIN, RANK.BACKUP]),
  when: PropTypes.oneOf([WHEN.IN_EVENT, WHEN.PRE_EVENT]),
  showTitle: PropTypes.bool,
  showLinks: PropTypes.bool,
  filters: PropTypes.shape({
    eventPart: PropTypes.string,
    date: PropTypes.string,
  }),
  recordingType: PropTypes.oneOf([
    RECORDING_TYPE.BACKUP,
    RECORDING_TYPE.REHEARSAL,
    RECORDING_TYPE.PRIMARY,
  ]),
  noResultsStyle: PropTypes.oneOf(['small', 'large']),
  onRecordingSelect: PropTypes.func.isRequired,
  customThumbnailsEnabled: PropTypes.bool,
};

const RecordingGroupContainerWithProviders = compose(
  withLocalizationProvider,
  withAlertsProvider,
)(RecordingGroupContainer);

export { RecordingGroupContainerWithProviders as RecordingGroupContainer };
