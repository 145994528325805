import { Label, Select } from '@components/inputs';
import { array, string } from 'prop-types';
import React from 'react';

const EventCurrency = ({ currencies, eventCurrency }) => (
  <div>
    <Label htmlFor="currency">
      In which currency will you be charging attendees?
    </Label>
    <Select
      id="currency"
      name="event[currency]"
      defaultValue={eventCurrency}
      readOnly
    >
      {currencies.map(([currencyName, currencyCode]) => (
        <option key={currencyCode} value={currencyCode}>
          {currencyName}
        </option>
      ))}
    </Select>
  </div>
);

export default EventCurrency;

EventCurrency.propTypes = {
  currencies: array,
  eventCurrency: string,
};
