import BooleanInput from '@features/app-store/boolean-input';
import SelectInput from '@features/app-store/select-input';
import TextInput from '@features/app-store/text-input';
import { arrayOf, oneOf, shape, string } from 'prop-types';
import React, { useMemo } from 'react';

const ConfigurationField = ({
  name,
  metadata = {},
  baseId,
  baseName,
  initialValue,
}) => {
  const { label, type, appearance, options, placeholder } = metadata;

  const InputComponent = useMemo(() => {
    if (type === 'boolean') return BooleanInput;
    if (type === 'select') return SelectInput;
    if (type === 'string' && appearance?.type !== 'url') return TextInput;

    return null;
  }, [type, appearance]);

  const props = {
    key: `${baseId}${name}`,
    id: `${baseId}${name}`,
    name: `${baseName}[${name}]`,
    label,
    initialValue,
  };

  if (type === 'select') {
    Object.assign(props, { options, placeholder });
  }

  if (InputComponent === null) return null;

  return (
    <div className="pl-0 col-md-4">
      <InputComponent {...props} />
    </div>
  );
};

ConfigurationField.propTypes = {
  baseId: string.isRequired,
  baseName: string.isRequired,
  initialValue: string,
  name: string.isRequired,
  metadata: shape({
    label: string,
    appearance: shape({
      type: oneOf(['url']).isRequired,
    }),
    type: oneOf(['select', 'string', 'boolean']),
    options: arrayOf(
      shape({
        label: string,
        value: string,
      }),
    ),
    placeholder: string,
  }).isRequired,
};

export default ConfigurationField;
