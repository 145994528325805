import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { withSpace } from '@hopin-team/ui-helper-with-space';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button).attrs({
  isInline: true,
  isOutlined: true,
  size: 'medium',
  pattern: 'secondary',
  leadingIcon: 'send-filled',
})`
  color: var(--color-primary-400);
  font-weight: var(--font-weight-semi-bold);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-primary-100);
    color: var(--color-primary-400);
  }
`;

const InviteVendors = ({ canInvite, eventSlug, ...spacingProps }) => {
  const { t } = useLocalization('invite-vendors');

  if (canInvite) {
    return (
      <StyledButton
        href={Routes.vendorsOrganisersEventRedeemCodesPath(eventSlug)}
        data-testid="invite-vendors-link"
        {...spacingProps}
      >
        {t('label')}
      </StyledButton>
    );
  } else {
    return (
      <ToolTip tip={t('tooltip')} align="top" {...spacingProps}>
        <StyledButton {...spacingProps}>{t('label')}</StyledButton>
      </ToolTip>
    );
  }
};

InviteVendors.propTypes = {
  canInvite: bool.isRequired,
  eventSlug: string.isRequired,
};

export default compose(withLocalizationProvider, withSpace)(InviteVendors);
