import React from 'react';

const HopinLogoIcon = () => (
  <svg
    width="5.1875rem"
    height="1.5rem"
    viewBox="0 0 83 24"
    fill="none"
    focusable="false"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.925 4.04415C67.9972 4.04415 68.8287 3.1851 68.8287 2.10579C68.8287 1.0485 67.9972 0.189453 66.925 0.189453C65.8747 0.189453 64.9994 1.0485 64.9994 2.10579C64.9775 3.1851 65.8747 4.04415 66.925 4.04415Z"
      fill="#1D1E1F"
    />
    <path
      d="M50.5137 5.38737H53.3801V7.14952C54.1022 6.09223 55.6996 5.12305 57.5814 5.12305C61.148 5.12305 63.8613 8.18478 63.8613 11.9514C63.8613 15.6959 61.148 18.7797 57.5814 18.7797C55.6996 18.7797 54.1241 17.8105 53.3801 16.7532V24.0001H50.5137V5.38737ZM57.0562 7.74424C54.7368 7.74424 53.1394 9.57247 53.1394 11.9514C53.1394 14.3082 54.7368 16.1585 57.0562 16.1585C59.3538 16.1585 60.9511 14.3303 60.9511 11.9514C60.9511 9.5945 59.3538 7.74424 57.0562 7.74424Z"
      fill="#1D1E1F"
    />
    <path
      d="M70.4697 5.38737H73.3362V7.17154C74.1239 5.84993 75.6119 5.12305 77.2967 5.12305C80.3164 5.12305 82.2857 7.28168 82.2857 10.6077V18.5154H79.4192V11.0923C79.4192 9.0218 78.3689 7.76627 76.7059 7.76627C74.8022 7.76627 73.3362 9.26409 73.3362 12.0615V18.5154H70.4697V5.38737Z"
      fill="#1D1E1F"
    />
    <path
      d="M28.6766 5.12347C26.9917 5.12347 25.5038 5.85035 24.7161 7.17196V0.189453H21.8496V18.5158H24.7161V12.0619C24.7161 9.26451 26.1821 7.76669 28.0858 7.76669C29.7488 7.76669 30.7991 9.02222 30.7991 11.0927V18.5158H33.6656V10.6082C33.6437 7.2821 31.6962 5.12347 28.6766 5.12347Z"
      fill="#1D1E1F"
    />
    <path d="M68.3255 5.3877H65.459V18.5157H68.3255V5.3877Z" fill="#1D1E1F" />
    <path
      d="M42.0897 18.7797C38.2386 18.7797 35.2627 15.762 35.2627 11.9514C35.2627 8.14073 38.2386 5.12305 42.0897 5.12305C45.9408 5.12305 48.9167 8.14073 48.9167 11.9514C48.9167 15.762 45.9408 18.7797 42.0897 18.7797ZM42.0897 16.0924C44.3873 16.0924 46.0065 14.2862 46.0065 11.9514C46.0065 9.61653 44.3873 7.81032 42.0897 7.81032C39.7703 7.81032 38.151 9.61653 38.151 11.9514C38.151 14.2862 39.7703 16.0924 42.0897 16.0924Z"
      fill="#1D1E1F"
    />
    <path
      d="M11.433 3.71331C11.4111 4.59439 10.7984 5.84992 9.77002 5.84992C9.13546 5.84992 8.61031 5.32127 8.61031 4.6825V4.66047C8.58842 3.62521 8.12892 2.72211 7.40683 2.08333C6.85979 1.62076 6.18147 1.31239 5.4375 1.22428C6.07206 0.563475 6.9692 0.166992 7.95386 0.166992C9.2011 0.166992 10.3389 0.893878 10.9954 1.95117C11.3017 2.47981 11.4549 3.11859 11.433 3.71331Z"
      fill="#1d6eaa"
    />
    <path
      d="M7.36295 3.80161C7.90998 4.50647 8.2382 5.8501 7.45047 6.51091C6.9472 6.92942 6.22511 6.86334 5.80936 6.37875C5.13104 5.60781 4.19014 5.21133 3.24924 5.1893C2.52715 5.16727 1.80506 5.38754 1.19238 5.80605C1.25803 4.90295 1.67377 3.99985 2.43962 3.36107C3.42429 2.54608 4.80282 2.30378 5.98442 2.78837C6.53145 2.98661 7.01284 3.33904 7.36295 3.80161Z"
      fill="#1d6eaa"
    />
    <path
      d="M4.30003 6.48876C5.1534 6.66497 6.29124 7.50199 6.11619 8.51523C6.00678 9.15401 5.3941 9.57252 4.75954 9.46238H4.73766C3.73111 9.30819 2.74645 9.61657 2.02436 10.2113C1.47732 10.6518 1.0397 11.2906 0.842763 12.0175C0.317609 11.2686 0.0769137 10.3214 0.230084 9.33022C0.470779 8.07469 1.36792 7.0174 2.59328 6.59889C3.14031 6.42267 3.75299 6.37862 4.30003 6.48876Z"
      fill="#1d6eaa"
    />
    <path
      d="M3.66527 10.5415C4.43113 10.123 5.83154 10.0349 6.33481 10.916C6.66303 11.4666 6.4661 12.1935 5.91906 12.5239C5.04381 13.0526 4.49677 13.9116 4.29984 14.8588C4.16855 15.5636 4.23419 16.3125 4.56241 16.9954C3.68716 16.7751 2.87754 16.2024 2.39615 15.3433C1.76159 14.22 1.78347 12.8323 2.43991 11.7309C2.74625 11.2243 3.162 10.8058 3.66527 10.5415Z"
      fill="#1d6eaa"
    />
    <path
      d="M5.76647 14.0443C6.09469 13.2293 7.10124 12.2601 8.04214 12.6125C8.65482 12.8328 8.96116 13.5156 8.74235 14.1104C8.41413 15.0795 8.54541 16.0928 9.00493 16.9298C9.35503 17.5686 9.88018 18.0752 10.5585 18.4056C9.72701 18.8021 8.76423 18.8902 7.84521 18.5378C6.64173 18.0972 5.76647 17.0179 5.56954 15.7403C5.50389 15.1676 5.56954 14.5729 5.76647 14.0443Z"
      fill="#1d6eaa"
    />
    <path
      d="M9.61725 15.3659C9.33279 14.5289 9.48596 13.1412 10.4487 12.7887C11.0614 12.5685 11.7179 12.8768 11.9367 13.4936C12.3087 14.4408 13.0526 15.1456 13.9498 15.498C14.6062 15.7624 15.3721 15.8064 16.0942 15.6302C15.7222 16.4672 15.022 17.1501 14.1029 17.5025C12.8995 17.943 11.5209 17.6787 10.58 16.8197C10.1424 16.4011 9.7923 15.9166 9.61725 15.3659Z"
      fill="#1d6eaa"
    />
    <path
      d="M13.4242 13.8897C12.6802 13.4272 11.8925 12.2598 12.4176 11.3787C12.7458 10.828 13.446 10.6298 14.015 10.9381C14.9121 11.4227 15.9186 11.4888 16.8377 11.1804C17.516 10.9381 18.1287 10.4976 18.5663 9.88086C18.807 10.7619 18.7195 11.7311 18.2162 12.5902C17.5816 13.7135 16.3563 14.3964 15.0871 14.3523C14.4745 14.3523 13.8837 14.1981 13.4242 13.8897Z"
      fill="#1d6eaa"
    />
    <path
      d="M15.3938 10.2998C14.5185 10.432 13.1837 10.0355 13.0087 9.02223C12.8774 8.40548 13.315 7.78873 13.9496 7.67859H13.9715C14.978 7.48035 15.7876 6.8636 16.269 6.04861C16.641 5.43185 16.816 4.70497 16.7504 3.95605C17.4944 4.46267 18.0633 5.27767 18.2165 6.24685C18.4353 7.50238 17.932 8.82399 16.9473 9.61696C16.4878 9.96939 15.9408 10.2337 15.3938 10.2998Z"
      fill="#1d6eaa"
    />
    <path
      d="M14.606 6.29044C14.0152 6.95125 12.7461 7.52395 11.9583 6.86314C11.455 6.44463 11.3894 5.71774 11.8051 5.21113C12.4397 4.41816 12.6804 3.40493 12.5272 2.45777C12.4178 1.75291 12.0896 1.07008 11.5645 0.519407C12.4616 0.4313 13.4025 0.695622 14.1683 1.3344C15.153 2.14939 15.6125 3.49303 15.3499 4.74856C15.2405 5.32126 14.978 5.87193 14.606 6.29044Z"
      fill="#1d6eaa"
    />
  </svg>
);

export default HopinLogoIcon;
