import { Icon } from '@hopin-team/ui-icon';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './event-details-styles';

const DuplicateButton = ({
  disabled,
  hide,
  icon,
  isInverse,
  onClick,
  text,
  tipText,
}) => {
  const inverse = isInverse || (disabled && tipText);
  return hide ? null : (
    <Styles.DuplicateButton
      size="medium"
      disabled={disabled && tipText}
      isOutlined={inverse}
      onClick={onClick}
      data-testid="new-nav-duplicate-button"
    >
      <Icon name={icon} color="blue-500" scale={2.5} mt={0.5} />
      {text}
    </Styles.DuplicateButton>
  );
};

DuplicateButton.propTypes = {
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  icon: PropTypes.string,
  isInverse: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  tipText: PropTypes.string,
};

export default DuplicateButton;
