import { Butticon } from '@hopin-team/ui-butticon';
import {
  Table,
  TableCell,
  TableHeading,
  TableRow,
  TableRowAction,
} from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled from 'styled-components';

export const FluidTable = styled(Table)`
  width: 100%;
  overflow: hidden;

  @media (min-width: 1280px) {
    word-break: break-word;
  }

  ${TableCell}:first-child, ${TableHeading}:first-child {
    padding-left: ${rem(56)};
  }

  ${TableCell}:last-child, ${TableHeading}:last-child {
    white-space: nowrap;
  }
`;

export const RightHeading = styled(TableHeading)`
  text-align: right;
`;

export const RightCell = styled(TableCell)`
  position: relative;
  text-align: right;
`;

export const LeftCell = styled(TableCell)`
  position: relative;
`;

export const ActionsContainer = styled.ul`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  list-style: none;
  background: var(--color-gray-100);
  opacity: 0;
  transition: opacity var(--transition-duration) ease-in-out;

  > li {
    margin-right: var(--spacing-12);
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: var(--spacing-40);
    left: calc(-1 * var(--spacing-40));
    background: linear-gradient(90deg, transparent, var(--color-gray-100));
  }

  &:focus-within,
  ${TableRow}:hover & {
    opacity: 1;
  }

  ${TableRow}:not(:hover) & {
    &:focus-within {
      background-color: var(--color-white);

      &:before {
        display: none;
      }
    }
  }
`;

export const TableAction = styled(TableRowAction)`
  &:hover {
    background-color: var(--color-gray-300);
  }
`;

export const Thead = styled.thead`
  box-shadow: inset 0px -${rem(1)} 0px var(--color-gray-200);
`;

export const DotWrapper = styled.div`
  padding: var(--spacing-8) var(--spacing-8) 0;
`;

export const Dot = styled.div`
  width: var(--spacing-8);
  height: var(--spacing-8);
  background-color: ${props => props.color};
  border-radius: 50%;
`;

export const Capitalized = styled.span`
  text-transform: capitalize;
`;

export const UppercaseText = styled(Text)`
  text-transform: uppercase;
`;

export const TagItem = styled.span`
  display: inline-flex;
  color: var(--color-gray-600);
  border: 1px solid var(--color-gray-400);
  line-height: ${rem(24)};
  padding: 0 ${rem(12)};
  margin-right: ${rem(12)};
  border-radius: ${rem(12)};
`;

export const CancelScheduleButtonContainer = styled.li`
  position: relative;

  ${Butticon} {
    width: ${rem(32)};
    height: ${rem(32)};

    &:hover {
      background-color: var(--color-gray-300);
    }
  }

  svg {
    display: none;
  }

  > svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`;
