import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { mapStyles } from '@hopin-team/ui-helper-props-to-styles';
import { Signal } from '@hopin-team/ui-signal';
import { Spinner } from '@hopin-team/ui-spinner';
import styled, { css } from 'styled-components';

export const ActionButton = styled('button')`
  color: currentColor;
  text-decoration: underline;

  &:hover {
    color: currentColor;
    text-decoration: underline;
  }
`;

/**
 * "Started" status
 */

const startedPrimary = css`
  border-color: ${({ theme }) => getFromTheme('COLOR.BLUE["200"]', theme)};
  border-top-color: ${({ theme }) => getFromTheme('COLOR.BLUE["500"]', theme)};
`;
const startedSecondary = css`
  border-color: ${({ theme }) => getFromTheme('COLOR.GREY["600"]', theme)};
  border-top-color: ${({ theme }) => getFromTheme('COLOR.GREY["300"]', theme)};
`;

const startedTheme = {
  primary: startedPrimary,
  secondary: startedSecondary,
};

export const StartedStatus = styled(Spinner)`
  ${props => mapStyles(props, 'theme', startedTheme)}
`;

/**
 * "Succeeded" status
 */

const succeededPrimary = css`
  background: ${({ theme }) => getFromTheme('COLOR.GREEN["500"]', theme)};
`;
const succeededSecondary = css`
  background: ${({ theme }) => getFromTheme('COLOR.GREEN["300"]', theme)};
`;

const succeededTheme = {
  primary: succeededPrimary,
  secondary: succeededSecondary,
};

export const SucceededStatus = styled(Signal)`
  ${props => mapStyles(props, 'theme', succeededTheme)}
`;
