import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Grid } from '@hopin-team/ui-grid';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import { bool, func } from 'prop-types';
import React from 'react';

export const AttendeeFormCloseModal = ({
  onDiscardChangesClick,
  onKeepEditingClick,
  isShowing,
}) => {
  const { t } = useLocalization('people.attendees.attendees-tab.details');

  return (
    <Modal
      isShowing={isShowing}
      withCloseButton
      onClose={onKeepEditingClick}
      withSeparator="footer"
      footer={
        <Grid gridColumnGap={2} pt={0} mt={0} gridTemplateColumns="auto auto">
          <Button
            key="primary"
            pattern="primary"
            isOutlined
            size="medium"
            onClick={onKeepEditingClick}
          >
            {t('modal.actions.keep-on-editing')}
          </Button>
          <Button
            key="secondary"
            pattern="danger"
            size="medium"
            onClick={onDiscardChangesClick}
          >
            {t('modal.actions.discard-changes')}
          </Button>
        </Grid>
      }
    >
      <Box px={3} py={1.75}>
        <Text element="h1" pattern="bodyLargeBold">
          {t('modal.header.title')}
        </Text>

        <Text pattern="body">{t('modal.header.description')}</Text>
      </Box>
    </Modal>
  );
};

AttendeeFormCloseModal.propTypes = {
  onDiscardChangesClick: func.isRequired,
  onKeepEditingClick: func.isRequired,
  isShowing: bool.isRequired,
};
