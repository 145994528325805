import { configureStore } from '@reduxjs/toolkit';

import auth from './slices/auth/auth-slice';
import editThumbnail from './slices/edit-thumbnail/edit-thumbnail-slice';
import event from './slices/event/event-slice';
import recordingGroups from './slices/recording-groups/recording-groups-slice';
import recordings from './slices/recordings/recordings-slice';
import registration from './slices/registration/registration-slice';
import transcript from './slices/transcript/transcript-slice';
import user from './slices/user/user-slice';

export const rootReducer = {
  auth,
  editThumbnail,
  event,
  recordingGroups,
  recordings,
  registration,
  transcript,
  user,
};

export default configureStore({
  reducer: rootReducer,
});
