import Card from '@components/card-with-loading';
import { metricItemColors } from '@features/dashboard/util/colors';
import { Flex } from '@hopin-team/ui-flex';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import InfoCircleIcon from 'icons/metrics/info-circle.svg';
import { bool, element, number, oneOfType, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SummaryMetricItemWrapper = styled.div`
  text-align: center;
  color: var(--color-gray-800);
  ${props => !props.show && `display: none`}
`;

const IconWrapper = styled.div`
  circle {
    ${props => props.backgroundColor && `fill: ${props.backgroundColor}`};
  }

  path {
    ${props => props.iconColor && `fill: ${props.iconColor}`};
  }
`;

const MetricValue = styled.h2`
  font-size: 1.3rem;
  margin: 0.5rem 0 0;
  font-family: var(--font-family-display);
  font-variant-numeric: tabular-nums;

  > span > span {
    padding: 0 0.5rem;
    font-size: 1.3rem;
  }
`;

const MetricText = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0.25rem;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -8px;
`;

const IconCircle = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4rem;
  margin: auto;
  background-color: rgb(239, 246, 250);
`;

const NewSummaryMetricItem = ({
  isLoading,
  icon,
  value,
  text,
  color,
  show,
  tooltipText,
}) => {
  const iconColor = color && metricItemColors[color].icon;
  const backgroundColor = color && metricItemColors[color].background;

  const formattedValue =
    typeof value == 'object' ? value : value.toLocaleString();

  return (
    <SummaryMetricItemWrapper
      show={show}
      className="metric-item"
      data-testid="summary-metric-item"
    >
      <Card isLoading={isLoading} px={2.5} py={2} cornerRadius="xl">
        <IconCircle>
          <IconWrapper iconColor={iconColor} backgroundColor={backgroundColor}>
            {icon}
          </IconWrapper>
        </IconCircle>
        <MetricValue data-testid="metric-value">{formattedValue}</MetricValue>

        <Flex justifyContent="center">
          <MetricText data-testid="summary-metric-item-text">{text}</MetricText>
        </Flex>
        {tooltipText && (
          <TooltipWrapper data-testid="summary-metric-item-tooltip">
            <ToolTip align="right" tip={tooltipText}>
              <InfoCircleIcon width={16} height={16} />
            </ToolTip>
          </TooltipWrapper>
        )}
      </Card>
    </SummaryMetricItemWrapper>
  );
};

NewSummaryMetricItem.propTypes = {
  icon: element.isRequired,
  value: oneOfType([string, number, element]).isRequired,
  text: string.isRequired,
  tooltipText: string,
  color: string,
  isLoading: bool,
  show: bool,
};

NewSummaryMetricItem.defaultProps = {
  isLoading: false,
  show: true,
};

export default NewSummaryMetricItem;
