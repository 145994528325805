import { Box } from '@hopin-team/ui-box';
import { DateField as UIDateField } from '@hopin-team/ui-date-field';
import { Select as UISelect } from '@hopin-team/ui-select';
import { TextField as UITextField } from '@hopin-team/ui-text-field';
import styled from 'styled-components';

const spaceAttrs = () => ({
  mb: 3,
});

export const DateField = styled(UIDateField).attrs(spaceAttrs)`
  table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
  }
`;
export const Select = styled(UISelect).attrs(spaceAttrs)``;
export const TextField = styled(UITextField).attrs(spaceAttrs)``;
export const MultiSelect = styled(Box).attrs(spaceAttrs)``;
