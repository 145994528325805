import { useLocalization } from '@features/localization';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { bool, node } from 'prop-types';
import React, { Fragment } from 'react';

const SurveyFieldWrapper = ({ canEditSurvey, children }) => {
  const { t } = useLocalization();

  return canEditSurvey ? (
    <Fragment>{children}</Fragment>
  ) : (
    <ToolTip
      tip={t('survey-builder.edit.survey-is-live')}
      align="bottom"
      hidePointer
    >
      {children}
    </ToolTip>
  );
};

SurveyFieldWrapper.propTypes = {
  canEditSurvey: bool.isRequired,
  children: node.isRequired,
};

export default SurveyFieldWrapper;
