import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const CustomizeEmailsSecondaryButton = styled(Button)`
  margin-right: 1rem;
  padding: 0.5rem 1.25rem;
  line-height: 1.5rem;
  color: var(--color-blue-400);

  ${Text} {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const CustomizeEmailsPrimaryButton = styled(Button)`
  margin-left: 1rem;
  padding: 0.5rem 1.25rem;
  line-height: 1.5rem;

  ${Text} {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const StickyDiv = styled.div`
  height: 4.5rem;
  padding: 1rem 1.5rem;
  border-top: 1px solid var(--color-gray-300);
  background-color: var(--color-white);
`;

export const LeftPanel = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const RightPanel = styled.div`
  margin: auto 0 auto auto;
  display: flex;
  justify-content: flex-end;
`;
