import CKEditor5 from '@components/ckeditor5/ckeditor5';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  ContentContainer,
  FooterContainer,
  HeaderContainer,
} from '../../../thumbnail-side-panel-modal/thumbnail-side-panel-modal.styled';

export const SidebarForm = ({
  isProcessing,
  title,
  description,
  errors = {},
  onCancel,
  onSubmit,
  register,
  setValue,
}) => {
  const { t } = useLocalization('recording-library');

  useEffect(() => {
    register('description');
  }, [register]);

  const onDescriptionChange = (_event, editor) => {
    setValue('description', editor.getData(), { shouldDirty: true });
  };

  return (
    <form onSubmit={onSubmit}>
      <HeaderContainer>
        <Text id="modal-header" element="h3" pattern="headingThree" mb={0}>
          {t('edit.header')}
        </Text>
      </HeaderContainer>
      <ContentContainer>
        <TextField
          label={t('edit.title-label')}
          type="text"
          name="title"
          ref={register}
          hasErrors={!!errors.title}
          errorMessage={errors.title?.message}
          helperText={t('edit.title-help')}
          requiredText={t('edit.required')}
          maxCount={300}
          currentCount={title?.length || 0}
          defaultValue={title}
          disabled={isProcessing}
          isRequired
        />
        <FieldLabel
          fieldId="description"
          label={t('edit.description-label')}
          mt={3}
          mb={1}
        />
        <CKEditor5
          toolbar="min"
          initialValue={description || ''}
          onChange={onDescriptionChange}
          name="description"
          disabled={isProcessing}
        />
      </ContentContainer>
      <FooterContainer>
        <Button
          isInline
          size="medium"
          type="submit"
          mr={2}
          disabled={isProcessing}
        >
          {t(isProcessing ? 'edit.saving' : 'edit.save')}
        </Button>
        <Button
          isInline
          isOutlined
          size="medium"
          type="reset"
          onClick={onCancel}
          disabled={isProcessing}
        >
          {t('edit.cancel')}
        </Button>
      </FooterContainer>
    </form>
  );
};

SidebarForm.propTypes = {
  isProcessing: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  errors: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
};
