import { LocalizationContext } from '@features/localization';
import { DeprecatedSelect as Select } from '@hopin-team/ui-select';
import React, { useContext, useMemo } from 'react';

const iconAccessibilityProps = t => {
  return {
    clear: {
      title: t('common.select-icon-accessibility-props.clear.title'),
      description: t(
        'common.select-icon-accessibility-props.clear.description',
      ),
    },
    dropdown: {
      title: t('common.select-icon-accessibility-props.dropdown.title'),
      description: t(
        'common.select-icon-accessibility-props.dropdown.description',
      ),
    },
    multiValueRemove: t(
      'common.select-icon-accessibility-props.multi-value-remove.title',
    ),
  };
};

const AccessibleSelect = props => {
  const { t } = useContext(LocalizationContext);
  const propValue = useMemo(() => iconAccessibilityProps(t), [t]);
  return <Select iconAccessibilityProps={propValue} {...props} />;
};

export default AccessibleSelect;
