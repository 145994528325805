import analyticsApi from '@api/analytics';
import Booth from '@components/icons/booth';
import Loading from '@components/loading';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import PersonListComponent from '@features/dashboard/components/person-list-component';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import { string } from 'prop-types';
import React, { useState } from 'react';

import Card from '../common/overview-card';

const TopAttendeesByExpoBoothInteraction = ({ eventSlug }) => {
  const { t } = useLocalization(
    'overview.top-attendees-by-expo-booth-interaction',
  );
  const [topAttendees, setTopAttendees] = useState([]);

  const loading = usePromise(
    signal =>
      analyticsApi.getTopAttendeesByExpoBoothInteraction(eventSlug, signal),
    setTopAttendees,
  );

  return (
    <Card testId="top-attendees-by-expo-booth-interaction">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <Loading isLoading={loading}>
          {topAttendees.length > 0 ? (
            <PersonListComponent people={topAttendees} />
          ) : (
            <EmptyState text={t('empty')} icon={<Booth />} />
          )}
        </Loading>
      </CardContentWrapper>
    </Card>
  );
};

TopAttendeesByExpoBoothInteraction.propTypes = {
  eventSlug: string.isRequired,
};

export default TopAttendeesByExpoBoothInteraction;
