import AddOns from '@features/event-creation-wizard/steps/add-ons/add-ons-component';
import BasicDetails from '@features/event-creation-wizard/steps/basic-details/basic-details-container';
import Description from '@features/event-creation-wizard/steps/description/description-component';
import Details from '@features/event-creation-wizard/steps/details/details-component';
import EventAccess from '@features/event-creation-wizard/steps/event-access/event-access-component';
import EventAreas from '@features/event-creation-wizard/steps/event-areas/event-areas-component';
import ExperienceDetails from '@features/event-creation-wizard/steps/experience-details/experience-details-component';
import Expo from '@features/event-creation-wizard/steps/expo/expo-component';
import FormatPicker from '@features/event-creation-wizard/steps/format-picker/format-picker-component';
import OnsiteEventCreationForm from '@features/event-creation-wizard/steps/onsite-event-creation/onsite-event-creation-component';
import Preview from '@features/event-creation-wizard/steps/preview/preview-component';
import Purpose from '@features/event-creation-wizard/steps/purpose/purpose-component';
import Sessions from '@features/event-creation-wizard/steps/sessions/sessions-component';
import Speakers from '@features/event-creation-wizard/steps/speakers/speakers-component';
import TemplatePicker from '@features/event-creation-wizard/steps/template-picker/template-picker-component';
import Venue from '@features/event-creation-wizard/steps/venue/venue-component';
import WebinarStyle from '@features/event-creation-wizard/steps/webinar-style/webinar-style-component';
import WelcomeMessage from '@features/event-creation-wizard/steps/welcome-message/welcome-message-component';

import ComplexityPicker from '../steps/complexity-picker/complexity-picker-component';

const eventcreationWizardPath = '/create-event-wizard';

const routes = {
  account: () => '/account',
  overview: () => '/organizations/overview',
  complexityPicker: () => `${eventcreationWizardPath}/complexity`,
  formatPicker: () => `${eventcreationWizardPath}/event-format`,
  templatePicker: format => `${eventcreationWizardPath}/${format}/template`,
  preview: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/preview`,
  basicDetails: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/basic-details`,
  eventAccess: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/event-access`,
  welcomeMessage: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/registration`,
  venue: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/venue`,
  speakers: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/speakers`,
  sessions: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/sessions`,
  expo: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/expo`,
  done: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/done`,
  eventDashboard: eventSlug => `/organisers/events/${eventSlug}/overview`,
  eventPreview: eventSlug => `/organisers/events/${eventSlug}/preview`,
  details: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/details`,
  billing: () => '/organizations/billing',
  purpose: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/purpose`,
  addOns: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/add-ons`,
  onsiteForm: () => `${eventcreationWizardPath}/onsite`,
  webinarStyle: (template, format) =>
    `${eventcreationWizardPath}/${format}/${template}/webinar-style`,
  experienceDetails: () => `${eventcreationWizardPath}/experience-details`,
  eventAreas: () => `${eventcreationWizardPath}/event-areas`,
  description: () => `${eventcreationWizardPath}/description`,
};

const stepMap = {
  'experience-details': {
    path: () => routes.experienceDetails(),
    View: ExperienceDetails,
  },
  'event-areas': {
    path: () => routes.eventAreas(),
    View: EventAreas,
  },
  description: {
    path: () => routes.description(),
    View: Description,
  },
  'complexity-picker': {
    path: () => routes.complexityPicker(),
    View: ComplexityPicker,
  },
  'format-picker': {
    path: () => routes.formatPicker(),
    View: FormatPicker,
  },
  'template-picker': {
    path: format => routes.templatePicker(format),
    View: TemplatePicker,
  },
  preview: {
    path: (template, format) => routes.preview(template, format),
    View: Preview,
  },
  'basic-details': {
    path: (template, format) => routes.basicDetails(template, format),
    View: BasicDetails,
  },
  'event-access': {
    path: (template, format) => routes.eventAccess(template, format),
    View: EventAccess,
  },
  'welcome-message': {
    path: (template, format) => routes.welcomeMessage(template, format),
    View: WelcomeMessage,
  },
  venue: {
    path: (template, format) => routes.venue(template, format),
    View: Venue,
  },
  speakers: {
    path: (template, format) => routes.speakers(template, format),
    View: Speakers,
  },
  sessions: {
    path: (template, format) => routes.sessions(template, format),
    View: Sessions,
  },
  expo: {
    path: (template, format) => routes.expo(template, format),
    View: Expo,
  },
  details: {
    path: (template, format) => routes.details(template, format),
    View: Details,
  },
  purpose: {
    path: (template, format) => routes.purpose(template, format),
    View: Purpose,
  },
  'add-ons': {
    path: (template, format) => routes.addOns(template, format),
    View: AddOns,
  },
  'webinar-style': {
    path: (template, format) => routes.webinarStyle(template, format),
    View: WebinarStyle,
  },
  onsite: {
    path: () => routes.onsiteForm(),
    View: OnsiteEventCreationForm,
  },
};

export { stepMap, routes, eventcreationWizardPath };
