const getVenueSettingsResponse = {
  data: {
    id: 'abc-123',
    type: 'venue_settings',
    attributes: {
      attendees_visiblity: 'show_all',
      doors_close_after_minutes: 5,
      doors_open_before_minutes: 5,
      add_session: true,
      schedule_meetings: true,
      schedule_meetings_pre_event: true,
      my_agenda: true,
      invite_to_video_call: true,
      event_part_types: ['stage', 'networking', 'sessions', 'expo'],
      giphy_enabled: true,
      side_panel_config: {
        chat: {
          event: false,
          stage: false,
          sessions: false,
          expo: false,
        },
        polls: {
          event: false,
          stage: false,
          sessions: false,
          expo: false,
        },
        'q-and-a': {
          event: false,
          stage: false,
          sessions: false,
          expo: false,
        },
      },
      moderated_questions: false,
      limit_multiple_event_connections: true,
      pre_event_settings: {
        pre_event_enabled: true,
        pre_event_duration_amount: 3,
        pre_event_duration_unit: 'months',
      },
      event_id: 5,
      organization_payment_plans: {
        advanced_plan: false,
        growth_plan: true,
      },
      support_url: 'https://support.url/test',
      send_messages: true,
      send_direct_message_excluded_persona_ids: [],
      schedule_meeting_excluded_persona_ids: [],
      start_instant_video_call_excluded_persona_ids: [],
    },
    meta: {
      close_doors_limit_minutes: 5,
      open_doors_limit_minutes: 5,
      has_roundtables_area: true,
      show_open_close_doors_times: true,
      show_clear_chat: true,
    },
  },
};

export default getVenueSettingsResponse;
