import { SponsorsLevel as UISponsorsLevel } from '@hopin-team/ui-sponsors-grid';
import { array, bool, func, string } from 'prop-types';
import React, { useContext } from 'react';
import { ReactSortable } from 'react-sortablejs';

import LocalesContext from './locales-context';
import SponsorCard from './sponsor-card';

const ForwardedUISponsorsLevel = React.forwardRef((props, ref) => {
  const locales = useContext(LocalesContext);

  let label = locales[props.className];

  // the customized text translation requires a dash in key names
  // this only affects the tier 4 - tier 10s
  if (props.className.indexOf('tier') !== -1) {
    label = locales[props.className.replace(/ /g, '-')];
  }

  return (
    <UISponsorsLevel cardsRef={ref} label={label} level={props.className}>
      {props.children}
    </UISponsorsLevel>
  );
});

ForwardedUISponsorsLevel.propTypes = {
  className: string.isRequired,
  children: array.isRequired,
};

export default function SponsorsLevel({
  level,
  onAddSponsor,
  onDeleteSponsor,
  onReorder,
  sponsorAreas,
  editable,
}) {
  const listItems = sponsorAreas.concat(
    // Append placehodler if areas are editable
    editable ? [{ placeholder: true }] : [],
  );

  return (
    <div>
      <ReactSortable
        animation={200}
        group="sponsors"
        list={listItems}
        // Filter out placeholder items, before executing handler
        setList={items => onReorder(items.filter(i => !i.placeholder))}
        filter=".dummy-card"
        disabled={!editable}
        className={level}
        tag={ForwardedUISponsorsLevel}
      >
        {listItems.map(sponsorArea => {
          return (
            <SponsorCard
              sponsorArea={sponsorArea}
              key={sponsorArea.id || sponsorArea.placeholder}
              editable={editable}
              onCardAdd={onAddSponsor}
              onDelete={onDeleteSponsor}
            />
          );
        })}
      </ReactSortable>
    </div>
  );
}

SponsorsLevel.propTypes = {
  level: string.isRequired,
  onAddSponsor: func.isRequired,
  onDeleteSponsor: func.isRequired,
  onReorder: func.isRequired,
  sponsorAreas: array.isRequired,
  editable: bool.isRequired,
};
