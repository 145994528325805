import { ErrorReportTable } from '@components/error-report-table';
import { FieldMapper } from '@components/field-mapper';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { STEPS } from '../constants';
import { EmailMarketing } from './email-marketing';
import { FileUpload } from './file-upload';
import { CustomToast, StyledList } from './modal-body-v2-styles';

const ONE_MEGA_BYTE = 1024 * 1024;

export const ModalBodyV2 = ({
  step,
  eventSlug,
  onFilesChange,
  parsedCsv,
  defaultMapping,
  fieldMapping,
  onMappingChange,
  registrationEmailsDisabled,
  downloadSampleCsv,
  errors,
  registrantsBulkUploadReportStatus,
}) => {
  const { t } = useLocalization('people.attendees.registrant-bulk-upload');

  return (
    <>
      {step === STEPS.CSV_UPLOAD && (
        <>
          <Text pattern="body" as="p" mb={1} color="grey-medium">
            {t('description')}
          </Text>
          <Box mb={2}>
            <Button
              onClick={downloadSampleCsv}
              size="medium"
              isOutlined
              leadingIcon="download"
            >
              {t('download-template-link')}
            </Button>
          </Box>

          <Text pattern="strong" mb={1} color="grey-medium">
            {t('instructions.title')}
          </Text>
          <StyledList pl={2.4} data-testid="csv-file-requirements">
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.email-format')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.profile-info')}
              </Text>
            </li>
            {registrantsBulkUploadReportStatus && (
              <>
                <li>
                  <Text pattern="caption" color="grey-medium">
                    {t('instructions.ticket-names')}
                  </Text>
                </li>
                <li>
                  <Text pattern="caption" color="grey-medium">
                    {t('instructions.ticket-not-empty')}
                  </Text>
                </li>
              </>
            )}
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.filled-rows')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.sensitive-data')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.external-barcode')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.file-limits')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.multi-select-questions')}
              </Text>
            </li>
          </StyledList>

          <Text element="p" pattern="caption" mb={1}>
            {t('drop-area.title')}
          </Text>
        </>
      )}

      <FileUpload
        isVisible={step === STEPS.CSV_UPLOAD}
        maxSize={ONE_MEGA_BYTE}
        onChange={onFilesChange}
      />

      {step === STEPS.CSV_MAPPING && (
        <FieldMapper
          records={parsedCsv}
          defaultMapping={defaultMapping}
          initialMapping={fieldMapping}
          onMappingChange={onMappingChange}
        />
      )}

      {step === STEPS.EMAIL_MARKETING && (
        <EmailMarketing
          registrationEmailsDisabled={registrationEmailsDisabled}
          eventSlug={eventSlug}
        ></EmailMarketing>
      )}

      {step === STEPS.ERROR_REPORT && (
        <>
          <CustomToast
            icon="alert"
            colorPattern="danger"
            role="status"
            iconColor="red-600"
            isInverse
            py={2}
            my={3}
          >
            <Box>
              <Text pattern="strong" color="grey-800">
                {t('error-report.row-throwed-error')}{' '}
              </Text>

              <Text pattern="body" color="grey-600">
                {t('error-report.correct-and-reupload')}
              </Text>
            </Box>
          </CustomToast>

          <ErrorReportTable errors={errors} />
        </>
      )}

      {step === STEPS.SUCCESS_CONFIRMATION && (
        <>
          <Text pattern="body" color="gray-650">
            {t('file-queued-processing')}
          </Text>
          {' - '}
          <Text pattern="strong" color="gray-650">
            {t('email-upon-completion')}
          </Text>
          <Text pattern="body" element="p" color="gray-650">
            {t('refresh-page')}
          </Text>
        </>
      )}
    </>
  );
};

ModalBodyV2.propTypes = {
  eventSlug: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  onFilesChange: PropTypes.func.isRequired,
  parsedCsv: PropTypes.arrayOf(PropTypes.object),
  registrationEmailsDisabled: PropTypes.bool,
  downloadSampleCsv: PropTypes.func.isRequired,
  defaultMapping: PropTypes.object.isRequired,
  fieldMapping: PropTypes.object,
  onMappingChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  registrantsBulkUploadReportStatus: PropTypes.bool,
};
