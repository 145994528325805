import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import PropTypes from 'prop-types';
import React from 'react';

const SimpleBarChart = ({
  values,
  titles,
  minY,
  maxY,
  colorArray,
  paddingRatio,
  aspectRatio,
}) => {
  if (values == null || values.length === 0) {
    return null;
  }

  minY = minY || 0;
  maxY = maxY || Math.max(...values);
  if (maxY <= 0) {
    return null;
  }

  colorArray = colorArray || ['black'];
  paddingRatio = paddingRatio || 0.1;
  aspectRatio = aspectRatio || 2.0;
  titles = titles || values.map(() => '');

  const rangeY = maxY - minY;
  const rangeX = rangeY * aspectRatio;
  const barCount = values.length;
  const barAreaWidth = rangeX / barCount;
  const barPadding = paddingRatio * barAreaWidth;
  const barWidth = barAreaWidth - barPadding * 2;

  return (
    <svg
      viewBox={`0 ${minY} ${rangeX} ${rangeY}`}
      data-testid="simple-bar-chart"
    >
      {values.map((value, i) => {
        const height = (value / maxY) * maxY;
        const x = i * barAreaWidth + barPadding;
        const y = maxY - height;
        const fill = colorArray[i % colorArray.length];
        return (
          <Group key={titles[i] || i}>
            <Bar x={x} y={y} height={height} width={barWidth} fill={fill} />
          </Group>
        );
      })}
    </svg>
  );
};

SimpleBarChart.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string),
  minY: PropTypes.number,
  maxY: PropTypes.number,
  colorArray: PropTypes.arrayOf(PropTypes.string),
  paddingRatio: PropTypes.number,
  aspectRatio: PropTypes.number,
};

export default SimpleBarChart;
