import {
  BOOTH_SIZE,
  DEFAULT_BOOTH_SIZE,
  DEFAULT_PRIORITY,
  FULL_DICTIONARY,
  MANDATORY_FIELDS,
} from '../consts';

export const getHeaderValidationMessages = ({ headerDictionary }) => {
  const requiredFields = [...MANDATORY_FIELDS];

  return requiredFields
    .filter(field => !headerDictionary[field])
    .map(field => `"${FULL_DICTIONARY[field]}" is a required field`);
};

export const processTags = tags => {
  const newLineRegex = /(\r|\n)/;
  const newLineDelimited = newLineRegex.test((tags || '').trim());
  const tagSeparator = ', ';

  return Array.from(
    new Set(
      tags
        ?.split(newLineDelimited ? newLineRegex : ',')
        .map(tag => tag.trim())
        .filter(Boolean) || [],
    ),
  ).join(tagSeparator);
};

export const translateIntoItems = ({ headerDictionary, processedFile }) => {
  return processedFile.map(row => {
    const headerValidationMessage = getHeaderValidationMessages({
      headerDictionary,
    })[0];

    if (headerValidationMessage) {
      throw new Error(headerValidationMessage);
    }

    const name = row[headerDictionary.exhibitor || ''];
    const email = row[headerDictionary.exhibitor_email || ''];

    const booth_size =
      BOOTH_SIZE[row[headerDictionary.booth_size]?.toLowerCase()] ??
      DEFAULT_BOOTH_SIZE;

    const priority = parseInt(
      row[headerDictionary.priority] ?? DEFAULT_PRIORITY,
    );

    const tags = processTags(row[headerDictionary.tags || '']);

    return {
      name,
      email,
      booth_size,
      priority,
      tags,
    };
  });
};

export const toCSVRow = record => [
  record.exhibitor,
  record.exhibitor_email,
  record.booth_size,
  record.priority,
  record.tags,
];
