import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Table, TableHeading, TableRow } from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import camelizeKeys from '@util/camelize-keys';
import { number, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SurveyListRow from './survey-list-row';

const SurveyList = ({ event }) => {
  const { t } = useLocalization();
  const { addAlert } = useContext(AlertsContext);
  const eventSlug = event.slug;
  const eventId = event.id;
  const organizationId = useSelector(({ organization }) => organization.id);
  const [isLoadingSurveys, setIsLoadingSurveys] = useState(false);
  const [hasFetchedSurveys, setHasFetchedSurveys] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (isLoadingSurveys) return;

      setIsLoadingSurveys(true);
      try {
        const { token } = await hopinApi.getUserToken();

        setAuthToken(token);

        if (!hasFetchedSurveys && token && eventId && organizationId) {
          setHasFetchedSurveys(true);

          const response = await hopinApi.getSurveys(
            token,
            organizationId,
            eventId,
          );

          setSurveys(camelizeKeys(response));
        }
      } catch (e) {
        addAlert({
          active: true,
          text: t('survey-builder.list.something-went-wrong'),
          pattern: 'error',
        });
      } finally {
        setIsLoadingSurveys(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAlert, eventId, hasFetchedSurveys, organizationId, t]);

  const labels = [
    t('survey-builder.list.survey-name'),
    t('survey-builder.list.status'),
    t('survey-builder.list.launch-time'),
  ];

  return (
    <>
      <Text element="h2" pattern="captionMedium" color="grey-600" mt={3}>
        {t('survey-builder.list.customize-questions')}
      </Text>
      <Text
        element="h3"
        pattern="captionMedium"
        color="grey-600"
        data-testid="link-text"
      >
        {t('survey-builder.list.visit')}{' '}
        <a href={Routes.organisersEventReportsPath(eventSlug)}>
          {t('survey-builder.list.analytics')}
        </a>{' '}
        {t('survey-builder.list.check-results')}
      </Text>
      <Box mt={3}>
        {isLoadingSurveys && (
          <Flex justifyContent="center">
            <Spinner
              isShowing
              isPolite
              label={t('survey-builder.list.loading')}
            />
          </Flex>
        )}

        {!!surveys.length && (
          <StyledTable>
            <THead>
              <TableRow isHeading>
                {labels.map(label => (
                  <TableHeading scope="col" key={label} py={1.5} px={3}>
                    <HeadingText pattern="caption" color="grey-600">
                      {label}
                    </HeadingText>
                  </TableHeading>
                ))}
              </TableRow>
            </THead>
            <tbody>
              {surveys.map(survey => (
                <SurveyListRow
                  survey={survey}
                  event={event}
                  key={survey.id}
                  eventSlug={eventSlug}
                  eventId={eventId}
                  organizationId={organizationId}
                  authToken={authToken}
                />
              ))}
            </tbody>
          </StyledTable>
        )}
      </Box>
    </>
  );
};

SurveyList.propTypes = {
  event: shape({
    id: number.isRequired,
    slug: string.isRequired,
  }),
};

export default SurveyList;

const StyledTable = styled(Table)`
  width: 100%;
`;

const THead = styled.thead`
  box-shadow: inset 0rem -0.0625rem 0rem var(--color-gray-300);
`;

const HeadingText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;
