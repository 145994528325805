import { Box } from '@hopin-team/ui-box';
import styled from 'styled-components';

const ChecklistWrapper = styled(Box).attrs({
  border: 'grey-200',
  cornerRadius: 'lg',
})`
  align-self: start;
  display: flex;
  width: 100%;
  padding: 0;
`;

export default ChecklistWrapper;
