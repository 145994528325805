import styled from 'styled-components';

export const IconBadge = styled.span`
  background-color: var(--color-gray-200);
  border-radius: 50%;
  height: var(--spacing-64);
  justify-content: center;
  width: var(--spacing-64);
  align-items: center;
  display: inline-flex;
`;

export const LinkWrapper = styled.div`
  width: var(--spacing-180);
`;
