import { useCallback, useEffect, useState } from 'react';

import { getRecordingAreas } from '../api';

export const useRecordingAreas = (slug, eventStarted) => {
  const [videoAreas, setVideoAreas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const loadAreas = useCallback(async () => {
    setIsError(false);

    try {
      const areas = await getRecordingAreas(slug);
      setVideoAreas(areas);
      setIsLoading(false);
    } catch (error) {
      setVideoAreas(null);
      setIsLoading(false);
      setIsError(true);
    }
  }, [slug]);

  useEffect(() => {
    if (slug && eventStarted) loadAreas();
  }, [slug, eventStarted, loadAreas]);

  return { videoAreas, isLoading, isError };
};
