import 'chart.js';

import analyticsApi from '@api/analytics';
import Card from '@components/card-with-loading';
import { useLocalization } from '@features/localization';
import { number } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../util/hooks';
import TopItemsComponent from './top-items-component';

const TopAreasByChatMessages = ({ eventId }) => {
  const { t } = useLocalization(
    'connections-dashboard.top-areas-by-chat-messages',
  );
  const [topAreas, setTopAreas] = useState([]);

  const loading = usePromise(
    signal => analyticsApi.getTopAreasByChatMessages(eventId, signal),
    result => setTopAreas(result),
  );

  return (
    <Card isLoading={loading}>
      <TopItemsComponent
        items={topAreas}
        title={t('title')}
        numberLabel={t('number-label')}
        emptyText={t('empty')}
      />
    </Card>
  );
};

TopAreasByChatMessages.propTypes = {
  eventId: number.isRequired,
};

export default TopAreasByChatMessages;
