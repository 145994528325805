/* global Rails */
import SaveBar from '@components/save-bar';
import Alerts from '@features/alerts/alerts';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { array, object } from 'prop-types';
import React from 'react';

import BaseDetails from './base-details';
import EventExtras from './event-extras';
import EventPartsDetails from './event-parts-details';
import TimeDetails from './time-details';

export default function KeyDetails({ event, timezones }) {
  const handleClick = () => {
    const csrf = Rails.csrfToken();
    document.getElementById('auth_token').value = csrf;
  };

  return (
    <div className="my-3">
      <Alerts />
      <Text element="p" pattern="body" mb={3}>
        Let{"'"}s get started creating your event.
      </Text>
      <div className="stack -gap-23">
        <form action={Routes.organisersEventPath(event.slug)} method="POST">
          <input type="hidden" name="_method" value="patch" />
          <input id="auth_token" type="hidden" name="authenticity_token" />
          <BaseDetails event={event} />
          <hr className="my-3" />
          <TimeDetails event={event} timezones={timezones} />
          <EventPartsDetails eventParts={event.eventParts} />
          <EventExtras />
          <SaveBar onButtonClick={handleClick} />
        </form>
      </div>
    </div>
  );
}

KeyDetails.propTypes = {
  event: object,
  timezones: array,
};
