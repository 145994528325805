import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  position: relative;
  margin-bottom: var(--spacing-64);
  display: flex;
  height: var(--spacing-16);
  justify-content: center;
  width: 100%;
`;

export const AbsolutePositionedText = styled(Text)`
  position: absolute;
  top: 0;
  text-align: center;
`;

export const BigSpinner = styled(Spinner)`
  height: 48px;
  width: 48px;
`;

export const ImageContainer = styled.div`
  height: 400px;
  width: 400px;
`;
