import moment from 'moment';

export default function formatData(
  data,
  startMoment,
  endMoment,
  timeOptions,
  tickBounds,
  aggregation,
  maxBars,
) {
  let barData = createEmptyBars(tickBounds, timeOptions, maxBars);

  data = normalizeData(data);

  addDataToBars(data, barData, aggregation, timeOptions.unit);

  barData = barsToArrays(barData);

  return barData;
}

function normalizeData(data) {
  if (!Array.isArray(data)) {
    // Registrations and Ticket Sales data come
    // in Object form, all other data in Array form
    data = Object.entries(data);
  }
  return data;
}

function createEmptyBars(tickBounds, timeOptions, maxBars) {
  let bars = [];
  let start = tickBounds.minTick;
  let end = start.clone().add(timeOptions.stepSize, timeOptions.unit);

  while (start < tickBounds.maxTick) {
    bars.push({
      start,
      end,
      yVal: 0,
      xVal: start.format(),
    });

    start = end;
    end = start.clone().add(timeOptions.stepSize, timeOptions.unit);
  }

  // limit number of bars
  return bars.slice(-maxBars);
}

function addDataToBars(data, bars, aggregation, unit) {
  data.forEach(d => {
    const [time, frequency] = d;

    // if data is scoped to days, don't convert to UTC
    const dataTime = unit === 'day' ? moment(time) : moment.utc(time);

    bars.forEach(bar => {
      if (moment(dataTime).isBetween(bar.start, bar.end, null, '[)')) {
        switch (aggregation) {
          case 'sum':
            bar.yVal += Number(frequency);
            break;
          case 'max':
            bar.yVal = Math.max(bar.yVal, frequency);
            break;
          default:
            console.error('Invalid aggregation:', aggregation);
            return;
        }
      }
    });
  });
}

function barsToArrays(bars) {
  return bars.map(bar => [bar.xVal, bar.yVal]);
}
