import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React from 'react';

import { TabWrapper } from '../event-links-styles';
import LinkField from '../partials/link-field';

const RegistrationTab = ({ registrationUrl }) => {
  const { t } = useLocalization('overview.event-links.modal.tabs.registration');
  return (
    <TabWrapper data-testid="event-links-tabpanel">
      <Text element="p" pattern="body" color="grey-600" weight="regular">
        {t('description')}
      </Text>
      <LinkField label="registrationUrl" link={registrationUrl} />
    </TabWrapper>
  );
};

RegistrationTab.propTypes = {
  registrationUrl: string,
};

export default RegistrationTab;
