import { createSlice } from '@reduxjs/toolkit';

import { createGroupId } from '../../../utils/utils';

export const recordingGroupsSlice = createSlice({
  name: 'recordings',
  initialState: {},
  reducers: {
    removeRecordingFromGroups: (state, action) => {
      const { recordingId } = action.payload;
      const allGroups = Object.keys(state);

      let newState = {
        ...state,
      };

      allGroups.forEach(groupId => {
        state[groupId];

        const filteredRecordings = state[groupId].groupRecordings.filter(
          id => id != recordingId,
        );

        newState[groupId] = {
          ...state[groupId],
          groupRecordings: filteredRecordings,
        };
      });

      return newState;
    },
    addGroup: (state, action) => {
      const { options, recordings } = action.payload;
      const groupId = createGroupId(options);
      const groupRecordings = recordings.map(obj => obj.id);
      return {
        ...state,
        [groupId]: {
          groupId,
          ...options,
          groupRecordings,
        },
      };
    },
  },
});

export const {
  removeRecordingFromGroups,
  addGroup,
} = recordingGroupsSlice.actions;

export default recordingGroupsSlice.reducer;
