import Loading from '@components/loading';
import { withFlagsProvider } from '@features/recordings/redux/slices/flags/with-flags';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { selectEvent } from '../redux/slices/event/event-selectors';
import { withEventProvider } from '../redux/slices/event/with-event';
import { selectExternalRegistrationId } from '../redux/slices/registration/registration-selector';
import { withRegistrationProvider } from '../redux/slices/registration/with-registration';
import { withReduxProvider } from '../redux/with-provider';
import { RecordingLibrarySlice } from '../slices/recording-library-slice/recording-library-slice';
import { DetailedPage } from './detailed-page';

const DetailedPageContainer = props => {
  const { slug } = useSelector(selectEvent);

  const externalRegistrationId = useSelector(selectExternalRegistrationId);

  return (
    <Loading isLoading={!slug}>
      {slug && (
        <Router basename={`/organisers/events/${slug}/recordings`}>
          <Switch>
            <Route path="/:recordingId" exact>
              <DetailedPage {...props} />
            </Route>
            <Route path="/:recordingId/cuts">
              <RecordingLibrarySlice
                externalRegistrationId={externalRegistrationId}
              />
            </Route>
          </Switch>
        </Router>
      )}
    </Loading>
  );
};

DetailedPageContainer.propTypes = {
  id: PropTypes.string.isRequired,
  customThumbnailsEnabled: PropTypes.bool.isRequired,
  transcriptsEnabled: PropTypes.bool.isRequired,
};

export default compose(
  withReduxProvider,
  withEventProvider,
  withRegistrationProvider,
  withFlagsProvider,
)(DetailedPageContainer);
