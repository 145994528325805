import { useLocalization } from '@features/localization';
import { EVENT_PART, RECORDING_CATEGORY } from '@features/recordings/constants';
import { updateRecordingThunk } from '@features/recordings/redux/slices/recordings/recordings-slice';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { SidePanelModal } from '@hopin-team/ui-side-panel-modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { SidebarForm } from './sidebar-form';

export const SidebarContainer = ({
  isShowing,
  recording,
  onClose,
  onSuccess,
  onError,
}) => {
  const { t } = useLocalization('recording-library');
  const [isShowingCancelDialog, setIsShowingCancelDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    reset,
    formState,
  } = useForm({
    defaultValues: {
      title: recording.title,
      description: recording.description,
    },
  });

  useEffect(() => {
    reset({
      title: recording.title,
      description: recording.description,
    });
  }, [recording.title, recording.description, reset]);

  const { isDirty } = formState;

  const onSubmit = attributes => {
    if (isDirty) {
      setIsProcessing(true);
      updateRecording(recording, attributes);
    } else {
      stopEditing();
    }
  };

  const onUpdateSuccess = () => {
    setIsProcessing(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  const onUpdateError = () => {
    setIsProcessing(false);

    if (onError) {
      onError();
    }
  };

  const updateRecording = (originalRecording, attributes) => {
    dispatch(
      updateRecordingThunk({
        attributes,
        recording: originalRecording,
        onSuccess: onUpdateSuccess,
        onError: onUpdateError,
      }),
    );
  };

  const handleClose = () => {
    if (isDirty) {
      setIsShowingCancelDialog(true);
    } else {
      stopEditing();
    }
  };

  const stopEditing = () => {
    setIsShowingCancelDialog(false);
    reset({
      title: recording.title,
      description: recording.description,
    });
    onClose();
  };

  return (
    <>
      {/* Cancel editing confirm */}
      <span className="confirm-dialog"></span>
      <ConfirmationDialog
        size="tiny"
        isShowing={isShowingCancelDialog}
        title={t('cancel-edit-confirmation-modal.title')}
        message={t('cancel-edit-confirmation-modal.message')}
        primaryButtonText={t('cancel-edit-confirmation-modal.confirm')}
        colorPattern="danger"
        secondaryButtonText={t('cancel-edit-confirmation-modal.cancel')}
        onConfirm={stopEditing}
        onCancel={() => setIsShowingCancelDialog(false)}
      />
      <span className="sidebar"></span>
      <SidePanelModal
        background="grey-100"
        describedById="modal-header"
        isShowing={isShowing}
        isDismissible={true}
        closeLabel={t('edit.close-sidebar')}
        onClose={handleClose}
        withCloseButton
        label={t('edit.label')}
        size="large"
      >
        <SidebarForm
          isProcessing={isProcessing}
          title={watch('title')}
          description={recording.description}
          errors={errors}
          onCancel={handleClose}
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          setValue={setValue}
        />
      </SidePanelModal>
    </>
  );
};

SidebarContainer.propTypes = {
  isShowing: PropTypes.bool,
  recording: PropTypes.shape({
    category: PropTypes.oneOf([
      RECORDING_CATEGORY.MAIN,
      RECORDING_CATEGORY.BACKUP,
      RECORDING_CATEGORY.REHEARSAL,
    ]),
    startedAt: PropTypes.string,
    endedAt: PropTypes.string,
    title: PropTypes.string,
    playableUrl: PropTypes.string,
    description: PropTypes.string,
    eventPartType: PropTypes.oneOf([EVENT_PART.STAGE, EVENT_PART.SESSION]),
    eventPartId: PropTypes.string,
  }).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func,
};
