import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { Flex } from '@hopin-team/ui-flex';
import { SidePanelModal } from '@hopin-team/ui-side-panel-modal';
import { Text } from '@hopin-team/ui-text';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import withReduxProvider from '@/redux/with-provider';

import MatchesExample from './assets/matches-example.svg';
import { TYPE } from './category-types';
import { ProfileCategoriesFormContainer } from './profile-categories-form/profile-categories-form-container';

const NetworkingModal = ({
  isOpen,
  setIsOpen,
  eventExternalId,
  lockProfileMatching,
  isLoading,
  categoriesData,
  showSettingsModal,
}) => {
  const { t } = useLocalization();

  const [isModalDismissable, setIsModalDismissable] = useState(true);
  const [savedChanges, setSavedChanges] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showLockedMessage, setShowLockedMessage] = useState(
    lockProfileMatching,
  );

  const closeModal = () => {
    savedChanges ? setIsOpen(false) : setShowConfirmation(true);
  };

  return (
    <>
      <StyledSidePanelModal
        describedById="modal-header"
        isShowing={isOpen}
        onClose={closeModal}
        isDismissible={isModalDismissable}
        withCloseButton
        label={t('networking.profile-setup.label')}
        size="large"
      >
        <StyledHeader p={3} background="grey-white">
          <Text id="modal-header" element="h3" pattern="headingThree" mb={0}>
            {t('networking.profile-setup.title')}
          </Text>
        </StyledHeader>
        <Box isRelative px={3} py={2}>
          <Flex mt={10}>
            <div>
              <h4>{t('networking.profile-setup.heading')}</h4>
              <Text color="grey-600" as="p">
                {t('networking.profile-setup.description')}
              </Text>
            </div>
            <MatchesExample />
          </Flex>
          <hr />
          <h4>{t('networking.profile-setup.heading-two')}</h4>
          <Text color="grey-600" as="p">
            {t('networking.profile-setup.description-two')}
          </Text>
        </Box>
        <ProfileCategoriesFormContainer
          setIsModalDismissable={setIsModalDismissable} // Required to prevent the confirmation dialog from closing the modal
          setSavedChanges={setSavedChanges}
          eventExternalId={eventExternalId}
          isLoading={isLoading}
          lockProfileMatching={lockProfileMatching}
          categoriesData={categoriesData}
          setIsOpen={setIsOpen}
        />
      </StyledSidePanelModal>
      <ConfirmationDialog
        isShowing={showConfirmation}
        title={t('networking.profile-setup.close-profile-setup')}
        message={t('networking.profile-setup.close-profile-setup-message')}
        primaryButtonText={t('networking.close')}
        secondaryButtonText={t('networking.profile-setup.continue-editing')}
        colorPattern="danger"
        onConfirm={() => {
          setShowConfirmation(false);
          setIsOpen(false);
        }}
        onCancel={() => setShowConfirmation(false)}
      />
      <ConfirmationDialog
        isShowing={showSettingsModal && showLockedMessage}
        title={t('networking.profile-setup.editing-interests-disabled')}
        message={t(
          'networking.profile-setup.editing-interests-disabled-message',
        )}
        primaryButtonText={t('networking.close')}
        colorPattern="primary"
        onConfirm={() => {
          setShowLockedMessage(false);
        }}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
};

export default compose(
  withErrorHandling({ loggerName: LOGGER_NAMES.NETWORKING }),
  withLocalizationProvider,
  withReduxProvider,
)(NetworkingModal);

NetworkingModal.propTypes = {
  isOpen: PropTypes.bool,
  showSettingsModal: PropTypes.bool,
  lockProfileMatching: PropTypes.bool,
  setIsOpen: PropTypes.func,
  eventExternalId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  categoriesData: PropTypes.arrayOf(
    PropTypes.shape({
      reactHookFormId: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
      name: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
          .isRequired,
      ),
    }),
  ),
};

const StyledSidePanelModal = styled(SidePanelModal)`
  grid-template-columns: 1fr minmax(37.5rem, 55%);

  div[role='dialog'] {
    > div {
      background-color: var(--color-gray-200);
    }

    > div > div {
      height: 100%;
    }
  }
`;

const StyledHeader = styled(Box)`
  width: 100%;
  box-shadow: 0px 0.188rem 0.5rem -0.125rem rgb(0 0 0 / 8%);
  position: fixed;
  z-index: 1;
`;
