import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as Y from 'yup';

export const useSessionForm = () => {
  const { t } = useLocalization('event-creation-wizard.steps.sessions.form');
  const sessionState = useSelector(state => state.sessions);

  const form = useForm({
    defaultValues: {
      title: sessionState.title,
      description: sessionState.description,
      image: sessionState.image,
    },
    resolver: yupResolver(
      Y.object().shape({
        title: Y.string().trim().required(t('title.required')),
        description: Y.string().trim().required(t('description.required')),
        image: Y.string().trim().nullable(),
      }),
    ),
    mode: 'onChange',
  });

  return form;
};
