import React from 'react';

const Crown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    data-testid="crown-icon"
  >
    <path
      d="M3.242 10.584 2.03 5.895c-.19-.698.588-1.258 1.19-.856l1.543 1.03a.652.652 0 0 0 .952-.266L7.29 2.45a.783.783 0 0 1 1.416 0l1.58 3.353c.17.361.62.486.951.265l1.544-1.03c.602-.4 1.38.16 1.19.857l-1.214 4.69a1 1 0 0 1-.968.748H4.21a1 1 0 0 1-.967-.749Zm9.591 2.75a.5.5 0 0 0-.5-.5H3.667a.5.5 0 0 0 0 1h8.666a.5.5 0 0 0 .5-.5Z"
      fill="#FBBC05"
    />
  </svg>
);

export default Crown;
