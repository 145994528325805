import { Box } from '@hopin-team/ui-box';
import { Modal } from '@hopin-team/ui-modal';
import { Scroll } from '@hopin-team/ui-modal/dist/styled/scroll';
import { Toast } from '@hopin-team/ui-toast';
import styled from 'styled-components';

export const CustomToast = styled(Toast)`
  box-shadow: none;
`;

export const StyledList = styled(Box).attrs({
  as: 'ul',
})`
  list-style: disc;
`;

export const StyledModal = styled(Modal)`
  ${Scroll} {
    overflow-x: hidden;
  }
`;
