import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import { CenteredPic, StyledBox } from './styles';

const EventFormatCard = ({
  enabled,
  images,
  title,
  subtitle,
  onClick,
  isLoading,
}) => {
  const { t } = useLocalization('event-creation-wizard.format-selector');
  return (
    <StyledBox
      container={32}
      m={1.5}
      data-testid={`event-format-card-${title}`}
    >
      <Flex flexDirection="column" alignItems="center" p={4}>
        <CenteredPic
          isRound
          src={images['1x']}
          srcSets={[
            {
              srcSet: `${images['1x']} 1x, ${images['2x']} 2x`,
            },
          ]}
        />
        <Text
          color={enabled ? 'grey-black' : 'grey-400'}
          align="center"
          pattern="subHeadingMedium"
        >
          {title}
        </Text>
        <Text
          color={enabled ? 'grey-black' : 'grey-400'}
          align="center"
          pattern="body"
        >
          {subtitle}
        </Text>
        <Button
          onClick={onClick}
          isOutlined
          isInline
          pattern="secondary"
          mt={2}
          disabled={isLoading}
        >
          {isLoading && <Spinner />}
          {enabled ? t('start') : t('upgrade-plan')}
        </Button>
      </Flex>
    </StyledBox>
  );
};

EventFormatCard.propTypes = {
  enabled: bool.isRequired,
  images: shape({
    '1x': string.isRequired,
    '2x': string.isRequired,
  }),
  title: string.isRequired,
  subtitle: string.isRequired,
  onClick: func.isRequired,
  isLoading: bool.isRequired,
};

export default EventFormatCard;
