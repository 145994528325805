import { useLocalization } from '@features/localization';
import {
  DEFAULT_NPS,
  SCHEDULED_KIND,
} from '@features/survey-builder/constants';
import {
  formatTimeInEventTimeZone,
  standardEventTimeZone,
  utcStringToTimeZone,
} from '@features/survey-builder/helpers';
import { Box } from '@hopin-team/ui-box';
import { DateField } from '@hopin-team/ui-date-field';
import { Flex } from '@hopin-team/ui-flex';
import { Grid } from '@hopin-team/ui-grid';
import { Icon } from '@hopin-team/ui-icon';
import { RadioButton, RadioGroup } from '@hopin-team/ui-radio-buttons';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import { addMinutes } from 'date-fns';
import { bool, func, object, shape, string } from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { iconStyles, QuestionCard, questionCardStyles } from './styles';
import SurveyFieldWrapper from './survey-field-wrapper';

const ScheduleTab = ({
  register,
  setValue,
  watch,
  reset,
  getValues,
  event,
  errors,
  control,
  survey,
  canEditSurvey,
}) => {
  const { t } = useLocalization();
  const selectedValue = watch('scheduled_kind');

  const eventDoorsCloseAt = addMinutes(
    new Date(event.time_end),
    event.doors_close_after_minutes || 0,
  );

  const formattedEventEndTime = utcStringToTimeZone(
    eventDoorsCloseAt.toISOString(),
    standardEventTimeZone(event),
  );

  const options =
    survey.kind === DEFAULT_NPS
      ? [
          {
            label: t('survey-builder.edit.middle'),
            value: SCHEDULED_KIND.MIDDLE,
          },
          {
            label: t('survey-builder.edit.specific'),
            value: SCHEDULED_KIND.SPECIFIC,
          },
        ]
      : [
          {
            label: t('survey-builder.edit.event-end'),
            value: SCHEDULED_KIND.END,
          },
          {
            label: t('survey-builder.edit.ten-minutes'),
            value: SCHEDULED_KIND.TEN_MINS,
          },
          {
            label: t('survey-builder.edit.specific'),
            value: SCHEDULED_KIND.SPECIFIC,
          },
        ];

  return (
    <>
      <QuestionCard mx="auto" mt={3}>
        <Text element="h2" pattern="bodyLargeBold" color="grey-800">
          {t('survey-builder.edit.distribute-survey')}
        </Text>
      </QuestionCard>

      <QuestionCard {...questionCardStyles} flexDirection="column">
        <Flex>
          <StyledIcon name="document" mr={1} />
          <Text element="h3" pattern="bodyLargeBold" color="grey-800">
            {t('survey-builder.edit.embed-in-event')}
          </Text>
        </Flex>
        <Text element="h4" pattern="caption" color="grey-600">
          {t('survey-builder.edit.attendees-inside-event')}
        </Text>

        <Box mt={3}>
          <SurveyFieldWrapper canEditSurvey={canEditSurvey}>
            <RadioGroup
              legend={t('survey-builder.edit.when-launch')}
              ref={register('scheduled_kind')}
              selectedValue={selectedValue}
              onChange={e => {
                const selected = e.target.value;
                setValue('scheduled_kind', selected, { shouldDirty: true });

                if (selected !== SCHEDULED_KIND.SPECIFIC) {
                  reset(
                    {
                      ...getValues(),
                      scheduled_at: survey.scheduled_at
                        ? new Date(survey.scheduled_at)
                        : null,
                    },
                    {
                      dirtyFields: true,
                      isDirty: true,
                    },
                  );
                } else {
                  reset(
                    {
                      ...getValues(),
                      scheduled_at: survey.scheduled_at
                        ? formatTimeInEventTimeZone(survey.scheduled_at, event)
                        : null,
                    },
                    {
                      dirtyFields: true,
                      isDirty: true,
                    },
                  );
                }
              }}
            >
              <Grid gridGap="1rem">
                {options.map(({ label, value }) => (
                  <RadioButton
                    id={`${label}-${value}`}
                    label={label}
                    value={value}
                    key={value}
                    disabled={!canEditSurvey}
                  />
                ))}
              </Grid>
            </RadioGroup>

            {selectedValue === SCHEDULED_KIND.SPECIFIC && (
              <Controller
                name="scheduled_at"
                control={control}
                rules={{ required: t('survey-builder.edit.field-required') }}
                render={({ onChange, value }) => (
                  <StyledDateField
                    mt={2}
                    ml={3.5}
                    label={t('survey-builder.edit.date-field')}
                    hasErrors={!!errors.scheduled_at?.message}
                    errorMessage={errors.scheduled_at?.message}
                    onDateChange={onChange}
                    date={value}
                    withTime
                    isDisabled={!canEditSurvey}
                  />
                )}
              />
            )}

            <Text mt={0.5} element="h4" pattern="caption" color="grey-600">
              {t('survey-builder.edit.event-ends-at', {
                datetime: formattedEventEndTime,
              })}
            </Text>
          </SurveyFieldWrapper>

          <Text pattern="emphasis" color="grey-800" element="h4" mt={3}>
            {t('survey-builder.edit.survey-results')}
          </Text>
          <Text
            pattern="captionMedium"
            color="grey-600"
            element="h5"
            data-testid="link-text"
          >
            {t('survey-builder.list.visit')}{' '}
            <a href={Routes.organisersEventReportsPath(event.slug)}>
              {t('survey-builder.list.analytics')}
            </a>{' '}
            {t('survey-builder.list.check-results')}
          </Text>
        </Box>
      </QuestionCard>
    </>
  );
};

ScheduleTab.propTypes = {
  survey: shape({ kind: string, scheduled_at: string }).isRequired,
  register: func.isRequired,
  watch: func.isRequired,
  errors: object,
  setValue: func.isRequired,
  getValues: func.isRequired,
  reset: func.isRequired,
  event: shape({
    slug: string,
  }),
  control: object.isRequired,
  canEditSurvey: bool.isRequired,
};

export default withErrorHandling({ loggerName: LOGGER_NAMES.SURVEY_BUILDER })(
  ScheduleTab,
);

const StyledIcon = styled(Icon)`
  ${iconStyles}
  background: var(--color-green-500);
`;

const StyledDateField = styled(DateField)`
  width: 60%;

  .hpn-FieldLabel {
    display: none; // TODO(EN-566) - replace with hideLabel prop once TextField version has been updated
  }

  table {
    width: 100%;
  }

  td,
  th {
    width: calc(100% / 7);
  }

  .hpn-QuantityInput {
    padding: 0 1rem;
`;
