import { Linkicon } from '@hopin-team/ui-linkicon';
import { Pill } from '@hopin-team/ui-pill';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';
const { t } = i18n;

import { i18n } from '@features/localization';

import { PillContainer, PillWrapper, StyledHr } from './styles';

function TagsFilter({ allTags, selectedTags, onClick, handleClearClick }) {
  return (
    <>
      <Text mb={2}>Tags</Text>
      {allTags.length === 0 && (
        <div>
          <Text>{t('roundtables.filtering.tags-unavailable')}</Text>
        </div>
      )}

      {allTags.length !== 0 && (
        <PillContainer
          justifyContent="left"
          alignItems="center"
          mb={2}
          flexWrap="wrap"
        >
          {allTags.map(tag => {
            return (
              <PillWrapper
                selected={selectedTags.includes(String(tag.id))}
                key={tag.id}
                id={tag.id}
                onClick={() => onClick(tag)}
                mr={1}
                title={tag.name}
              >
                <Pill label={tag.name} />
              </PillWrapper>
            );
          })}
        </PillContainer>
      )}

      <StyledHr />

      <Linkicon
        label={t('roundtables.filtering.clear-tags')}
        onClick={handleClearClick}
        leadingIcon="close"
        scale={3}
        iconScale={1}
      />
    </>
  );
}

TagsFilter.propTypes = {
  allTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  handleClearClick: PropTypes.func.isRequired,
};

export default TagsFilter;
