import GridLayout from '@components/grid-layout';
import { TextField } from '@hopin-team/ui-text-field';
import { object } from 'prop-types';
import React from 'react';

const BaseDetails = ({ event }) => {
  return (
    <GridLayout columns={[1, { TABLET_MAX: 2 }]}>
      <TextField
        id="event_name"
        autoComplete="off"
        name="event[name]"
        defaultValue={event.name}
        hasErrors={event.errors.name}
        errorMessage={event.errors.name?.[0]}
        error={event.errors.name}
        placeholder="XYZ Networking"
        isRequired={true}
        label="Name of the Event"
      />
    </GridLayout>
  );
};

BaseDetails.propTypes = {
  event: object.isRequired,
};

export default BaseDetails;
