import moment from 'moment';

export const SECONDS_IN_ONE_MINUTE = 60;
export const SECONDS_IN_ONE_HOUR = 3600;
export const CHANGE_TYPE = {
  start: 'START',
  end: 'END',
};
export const DEFAULT_DELTA_UNIT_S = 2; // 2 seconds
export const MASK_PLACEHOLDER = '-';

const RECORDING_DURATION_TRIMMING_TIME_MULTIPLIER = 0.3;

// HH:mm:ss or mm:ss
export const toSeconds = formattedTime => {
  const timePieces = formattedTime.split(':');
  let hours;
  let minutes;
  let seconds;

  if (timePieces.length === 2) {
    minutes = timePieces[0];
    seconds = timePieces[1];
  } else {
    hours = timePieces[0];
    minutes = timePieces[1];
    seconds = timePieces[2];
  }

  const hoursNumber = isNaN(hours) ? 0 : Number(hours) * SECONDS_IN_ONE_HOUR;
  const minutesNumber = isNaN(minutes)
    ? 0
    : Number(minutes) * SECONDS_IN_ONE_MINUTE;
  const secondsNumber = isNaN(seconds) ? 0 : Number(seconds);

  return hoursNumber + minutesNumber + secondsNumber;
};

export const toTimestampString = (timeInSeconds, format) =>
  moment.utc(timeInSeconds * 1000).format(format);

export const replaceAll = (myString, pattern, replacement) =>
  myString.replace(new RegExp(pattern, 'g'), replacement);

export const countAllOccurencies = (myString, character) =>
  myString.split(new RegExp(character, 'gi')).length - 1;

/**
 * @param {number} trimStartTimeSec trim start time in seconds
 * @param {number} trimEndTimeSec trim end time in seconds
 * @returns {number} estimated trimming time in minutes (integer)
 */
export const estimateTrimmingTimeMins = (trimStartTimeSec, trimEndTimeSec) => {
  return Math.max(
    1,
    Math.round(
      ((trimEndTimeSec - trimStartTimeSec) / SECONDS_IN_ONE_MINUTE) *
        RECORDING_DURATION_TRIMMING_TIME_MULTIPLIER,
    ),
  );
};
