import analyticsApi from '@api/analytics';
import Booth from '@components/icons/booth';
import CardTitle from '@features/dashboard/components/card-title';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import EventAreaIcon from '@features/overview/analytics/common/event-area-icon';
import Card from '@features/overview/analytics/common/overview-card';
import TopItemsListComponent from '@features/overview/analytics/common/top-items-list-component';
import { Button } from '@hopin-team/ui-button';
import * as Routes from '@routes';
import { string } from 'prop-types';
import React, { useState } from 'react';

import EmptyState from '../styles/empty-state-styles';
import Loading from '../styles/loading-styles';

const transformResult = topBooths => {
  return topBooths.map(booth => {
    return {
      icon: <EventAreaIcon eventArea="Expo" />,
      text: booth.name,
      value: booth.interaction_count.toLocaleString(),
    };
  });
};

const TopExpoBoothsByInteraction = ({ eventSlug }) => {
  const { t } = useLocalization('overview.top-expo-booths-by-interaction');
  const [topBooths, setTopBooths] = useState([]);

  const loading = usePromise(
    signal => analyticsApi.getTopExpoBoothsByInteraction(eventSlug, signal),
    result => setTopBooths(transformResult(result)),
  );

  return (
    <Card testId="top-expo-booths-by-interaction">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <Loading isLoading={loading}>
          {topBooths.length > 0 ? (
            <TopItemsListComponent items={topBooths} />
          ) : (
            <EmptyState
              button={
                <Button
                  type="button"
                  size="small"
                  href={Routes.organisersEventVendorsPath(eventSlug)}
                >
                  {t('create-booth-button')}
                </Button>
              }
              icon={<Booth />}
              text={t('empty')}
            />
          )}
        </Loading>
      </CardContentWrapper>
    </Card>
  );
};

TopExpoBoothsByInteraction.propTypes = {
  eventSlug: string.isRequired,
};

export default TopExpoBoothsByInteraction;
