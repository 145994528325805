import React from 'react';

const Chart = () => (
  <svg
    width="28"
    height="23"
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3333 17.2133H12.6667C11.9303 17.2133 11.3333 16.6051 11.3333 15.8548V2.27062C11.3333 1.52038 11.9303 0.912193 12.6667 0.912193H15.3333C16.0697 0.912193 16.6667 1.52038 16.6667 2.27062V15.8548C16.6667 16.6051 16.0697 17.2133 15.3333 17.2133ZM3.33332 13.138V15.8548C3.33332 16.6051 3.93028 17.2133 4.66666 17.2133H7.33332C8.0697 17.2133 8.66666 16.6051 8.66666 15.8548V13.138C8.66666 12.3878 8.0697 11.7796 7.33332 11.7796H4.66666C3.93028 11.7796 3.33332 12.3878 3.33332 13.138ZM20.6667 17.2133H23.3333C24.0697 17.2133 24.6667 16.6051 24.6667 15.8548V7.70431C24.6667 6.95407 24.0697 6.34589 23.3333 6.34589H20.6667C19.9303 6.34589 19.3333 6.95407 19.3333 7.70431V15.8548C19.3333 16.6051 19.9303 17.2133 20.6667 17.2133ZM1.33332 19.9301H26.6667C27.0348 19.9301 27.3333 20.2342 27.3333 20.6093V21.9677C27.3333 22.3429 27.0348 22.647 26.6667 22.647H1.33332C0.965133 22.647 0.666656 22.3429 0.666656 21.9677V20.6093C0.666656 20.2342 0.965133 19.9301 1.33332 19.9301Z"
      fill="#757A8A"
    />
  </svg>
);

export default Chart;
