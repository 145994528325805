import { Button } from '@hopin-team/ui-button';
import styled from 'styled-components';

export const TryAgainButton = styled(Button).attrs({
  isInline: true,
  isOutlined: true,
  size: 'small',
})`
  border: 0;
`;
