import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';

import { IconWrapper, StyledPill } from './styles';

const PillSelector = ({ selectedItem, options, onChange, label }) => (
  <>
    {label && (
      <Text pattern="body" element="p" color="grey-700" weight="regular" mb={2}>
        {label}
      </Text>
    )}
    {options.map(({ label, value, color = 'blue', icon }) => (
      <StyledPill
        key={value}
        as="button"
        onClick={() => onChange(value)}
        selected={selectedItem === value}
        mr={2}
        mb={2}
      >
        {icon && (
          <IconWrapper color={color}>
            <Icon name={icon} color={`${color}-400`} scale={1.5} />
          </IconWrapper>
        )}
        <Text pattern="body" color="grey-800" weight="regular" ml={1}>
          {label}
        </Text>
      </StyledPill>
    ))}
  </>
);

PillSelector.propTypes = {
  onChange: func,
  selectedItem: string,
  options: arrayOf(
    shape({
      value: string,
      icon: string,
      color: string,
      label: string,
    }),
  ),
  label: string,
};

export default PillSelector;
