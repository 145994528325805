import StepHeader from '@features/event-creation-wizard/components/step-header';
import { setAddOns } from '@features/event-creation-wizard/redux/add-ons/add-ons-slice';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import { setWebinarStyle } from '@features/event-creation-wizard/redux/step-specific-state/virtual-lite-slice';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WebinarPreview } from './preview/preview-component';
import { webinarTemplates } from './webinar-config';
import WebinarStyleRadioButtons from './webinar-style-radio-buttons/webinar-style-radio-buttons-component';

const WebinarStyleComponent = () => {
  const { t } = useLocalization('event-creation-wizard');
  const dispatch = useDispatch();
  const webinarStyle = useSelector(state => state.virtualLite.webinarStyle);

  const handleSelectOption = option => {
    dispatch(setWebinarStyle(option));

    const config = webinarTemplates[option];

    dispatch(
      setVenue({
        venue: config.venue,
      }),
    );
    dispatch(setAddOns(config.addons));
  };

  return (
    <EventCreationWizardStep
      canSkip={false}
      onSave={() => {}}
      ctaLabel={t('common.create')}
      preview={<WebinarPreview webinarStyle={webinarStyle} />}
      verticallyCentered
    >
      <StepHeader
        title={t('steps.webinar-style.title')}
        subtitle={t('steps.webinar-style.subtitle')}
      />
      <WebinarStyleRadioButtons
        selectedOption={webinarStyle}
        setSelectedOption={handleSelectOption}
      />
    </EventCreationWizardStep>
  );
};

export default WebinarStyleComponent;
