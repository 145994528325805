import * as dateFormatters from 'date-fns/locale';
import mapValues from 'lodash/fp/mapValues';
import merge from 'lodash/fp/merge';

import * as locales from './locales';

export const DE = 'de';
export const EN = 'en';
export const ES = 'es';
export const FR = 'fr';
export const IT = 'it';
export const JA = 'ja';
export const KO = 'ko';
export const PT = 'pt';
export const PL = 'pl';
export const NL = 'nl';

export const localeFileMap = mapValues(merge(locales.EN))({
  [DE]: locales.DE,
  [EN]: locales.EN,
  [ES]: locales.ES,
  [FR]: locales.FR,
  [IT]: locales.IT,
  [JA]: locales.JA,
  [KO]: locales.KO,
  [PT]: locales.PT,
  [PL]: locales.PL,
  [NL]: locales.NL,
});

export const dateFnsLanguageMap = {
  [DE]: dateFormatters.de,
  [EN]: dateFormatters.enGB, // British English
  [ES]: dateFormatters.es,
  [FR]: dateFormatters.fr,
  [IT]: dateFormatters.it,
  [JA]: dateFormatters.ja,
  [KO]: dateFormatters.ko,
  [PT]: dateFormatters.ptBR, // Brazilian Portuguese
  [PL]: dateFormatters.pl,
  [NL]: dateFormatters.nl,
};

export const COOKIE_LOOKUP_KEY_LANG = 'i18next';
export const LANGUAGE_PICKER_MODAL_TYPE = 'language-picker';
