import getDisplayName from '@util/get-display-name';
import { object } from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';

import store from './store';

const withReduxProvider = WrappedComponent => {
  const WithReduxProvider = props => {
    return (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    );
  };
  WithReduxProvider.propTypes = { viewData: object };
  WithReduxProvider.displayName = `WithViewDataProvider(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithReduxProvider;
};

export default withReduxProvider;
