import { rem } from 'polished';
import styled from 'styled-components';

const SelectStyles = styled.button`
  --current-color: ${({ isActive }) =>
    isActive ? 'var(--color-blue-400)' : 'var(--color-gray-600)'};
  border: none;
  border-bottom: ${rem(1)} solid var(--current-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  font-size: var(--font-size-small);
  margin-right: var(--spacing-12);
  padding: var(--spacing-8);
  user-select: none;
  min-width: 8rem;
  &:focus:not(.focus-visible) {
    outline: none;
  }
  svg {
    width: ${rem(12)};
    color: var(--current-color);
    transform: ${({ isActive }) => isActive && 'rotate(-180deg)'};
    transition: transform var(--transition-duration) ease;
  }
`;

export const SelectContainer = styled.div``;

export const SelectLabel = styled.span`
  color: var(--color-gray-650);
  margin-right: var(--spacing-8);
  font-size: var(--font-size-small);
`;

export const Option = styled.li`
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: var(--color-grey-800);
  user-select: none;
  &:not(:last-child) {
    margin-bottom: var(--spacing-24);
  }
`;

export const OptionList = styled.ul`
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};

  transition: opacity var(--transition-duration) linear;
  width: 11.5rem;
  padding: var(--spacing-24);
  box-shadow: var(--box-shadow-long);
  background-color: var(--color-white);
  border-bottom-right-radius: var(--border-radius-big);
  border-bottom-left-radius: var(--border-radius-big);
  list-style: none;
  margin-block-start: var(--spacing-4);
  margin-block-end: 0;
  position: absolute;
  z-index: 1;
`;

export default SelectStyles;
