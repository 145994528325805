import Select from '@components/select';
import { useLocalization } from '@features/localization';
import { array, func } from 'prop-types';
import React from 'react';

import { Container } from './filtering.styles';
import FiltersList from './filters-list';

const SelectFilter = ({
  ticketFilters,
  addFilter,
  statusFilters,
  removeFilter,
  selectedFilters,
}) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites.filter');

  return (
    <Container>
      <Select
        label={t('ticket-type')}
        optionsList={ticketFilters}
        onSelect={addFilter}
        noOptionsText={t('all-added')}
      />
      <Select
        label={t('status')}
        optionsList={statusFilters}
        onSelect={addFilter}
        noOptionsText={t('all-added')}
      />
      <FiltersList
        onDeleteChip={removeFilter}
        list={selectedFilters}
        noOptionsText={t('all-added')}
      />
    </Container>
  );
};

SelectFilter.propTypes = {
  addFilter: func.isRequired,
  removeFilter: func.isRequired,
  ticketFilters: array.isRequired,
  statusFilters: array.isRequired,
  selectedFilters: array.isRequired,
};

export default SelectFilter;
