import React from 'react';

export const CloseIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="close-icon"
      d="M11.85 10.44C11.8968 10.4865 11.934 10.5418 11.9594 10.6027C11.9848 10.6636 11.9979 10.729 11.9979 10.795C11.9979 10.861 11.9848 10.9263 11.9594 10.9873C11.934 11.0482 11.8968 11.1035 11.85 11.15L11.15 11.85C11.1035 11.8968 11.0482 11.934 10.9873 11.9594C10.9263 11.9848 10.861 11.9979 10.795 11.9979C10.729 11.9979 10.6636 11.9848 10.6027 11.9594C10.5418 11.934 10.4865 11.8968 10.44 11.85L5.99997 7.40997L1.55997 11.85C1.51349 11.8968 1.45819 11.934 1.39726 11.9594C1.33633 11.9848 1.27098 11.9979 1.20497 11.9979C1.13897 11.9979 1.07362 11.9848 1.01269 11.9594C0.951756 11.934 0.896456 11.8968 0.849974 11.85L0.149974 11.15C0.10311 11.1035 0.0659131 11.0482 0.0405288 10.9873C0.0151444 10.9263 0.0020752 10.861 0.0020752 10.795C0.0020752 10.729 0.0151444 10.6636 0.0405288 10.6027C0.0659131 10.5418 0.10311 10.4865 0.149974 10.44L4.58997 5.99997L0.149974 1.55997C0.10311 1.51349 0.0659131 1.45819 0.0405288 1.39726C0.0151444 1.33633 0.0020752 1.27098 0.0020752 1.20497C0.0020752 1.13897 0.0151444 1.07362 0.0405288 1.01269C0.0659131 0.951756 0.10311 0.896456 0.149974 0.849974L0.849974 0.149974C0.896456 0.10311 0.951756 0.0659131 1.01269 0.0405288C1.07362 0.0151444 1.13897 0.0020752 1.20497 0.0020752C1.27098 0.0020752 1.33633 0.0151444 1.39726 0.0405288C1.45819 0.0659131 1.51349 0.10311 1.55997 0.149974L5.99997 4.58997L10.44 0.149974C10.4865 0.10311 10.5418 0.0659131 10.6027 0.0405288C10.6636 0.0151444 10.729 0.0020752 10.795 0.0020752C10.861 0.0020752 10.9263 0.0151444 10.9873 0.0405288C11.0482 0.0659131 11.1035 0.10311 11.15 0.149974L11.85 0.849974C11.8968 0.896456 11.934 0.951756 11.9594 1.01269C11.9848 1.07362 11.9979 1.13897 11.9979 1.20497C11.9979 1.27098 11.9848 1.33633 11.9594 1.39726C11.934 1.45819 11.8968 1.51349 11.85 1.55997L7.40998 5.99997L11.85 10.44Z"
    />
  </svg>
);
