import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { HOURS_FOR_REPLAY_PROCESSING, RECORDING_CATEGORY } from '../constants';
import { hasDatePassed } from '../utils/utils';
import { Container, IconWrapper } from './no-results.styled';

/**
 * endTime is passed in, as this varies for rehearsals vs primary+backup
 * rehearsals endTime = when event starts
 * primary+backup endTime = when event ends
 */

const noResultsSubtitle = (eventStarted, endTime) => {
  try {
    if (!eventStarted) {
      return 'pre-event';
    } else if (endTime) {
      return hasDatePassed(endTime, HOURS_FOR_REPLAY_PROCESSING)
        ? 'post-event'
        : 'in-event';
    } else {
      return 'post-event';
    }
  } catch (error) {
    return 'post-event';
  }
};

export const NoResults = withLocalizationProvider(
  ({
    title,
    category = 'default',
    rehearsalHref,
    endTime,
    eventStarted = true,
  }) => {
    const { t } = useLocalization(`recording-no-results.${category}`);
    const subTitle = noResultsSubtitle(eventStarted, endTime);

    return (
      <Container data-testid="noResults">
        <IconWrapper
          data-testid="icon"
          backgroundColor="grey-black"
          justifyContent="center"
          alignItems="center"
        >
          <Icon aria-hidden="true" name="play" color="grey-white" p={1} />
        </IconWrapper>

        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Text as="h2">{t('title')}</Text>
          <Text
            element="p"
            color="grey-600"
            pattern="body"
            linkStyle="underline"
            align="center"
          >
            {t(subTitle, { title })}
          </Text>
          {rehearsalHref && (
            <Text
              mt={1}
              element="p"
              color="grey-600"
              pattern="body"
              linkStyle="underline"
              align="center"
              dangerouslySetInnerHTML={{
                __html: t('in-the-meantime', { rehearsalHref }),
              }}
            />
          )}
        </Flex>
      </Container>
    );
  },
);

NoResults.propTypes = {
  title: PropTypes.string,
  category: PropTypes.oneOf([
    RECORDING_CATEGORY.MAIN,
    RECORDING_CATEGORY.REHEARSAL,
    RECORDING_CATEGORY.BACKUP,
    'default',
  ]),
  eventStarted: PropTypes.bool,
  endTime: PropTypes.string,
  rehearsalHref: PropTypes.string,
};
