import { node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin: var(--spacing-16) var(--spacing-32) var(--spacing-16)
    var(--spacing-32);
`;

const FormHeader = styled.header`
  margin: var(--spacing-16) var(--spacing-32) var(--spacing-16)
    var(--spacing-32);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormBody = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: var(--spacing-32);
  padding-bottom: var(--spacing-32);
  background: var(--color-gray-200);
`;

const FormActionsWrapper = styled.div`
  position: sticky;
  background: var(--color-white);
  display: flex;
  justify-content: space-around;
  padding: var(--spacing-16) var(--spacing-32) var(--spacing-16)
    var(--spacing-32);
  width: 100%;
  bottom: 0;
`;

const FormActionsInner = styled.div`
  width: 80%;
`;

function FormActions({ children }) {
  return (
    <FormActionsWrapper>
      <FormActionsInner>{children}</FormActionsInner>
    </FormActionsWrapper>
  );
}

FormActions.propTypes = {
  children: node,
};

export { Form, FormRow, FormHeader, FormBody, FormActions };
