import { Grid } from '@hopin-team/ui-grid';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

const sponsorColorMap = {
  gold: '#E2A514',
  silver: '#979BA8',
  bronze: '#EC9D52',
};
const sponsorSizeMap = {
  gold: rem(72),
  silver: rem(48),
  bronze: rem(32),
};
const sponsorColumnsMap = {
  gold: 3,
  silver: 4,
  bronze: 5,
};

Styles.SponsorsGrid = styled(Grid)`
  grid-row-gap: ${rem(24)};
  grid-column-gap: ${rem(24)};
  grid-template-columns: repeat(
    ${props => sponsorColumnsMap[props.sponsorType]},
    1fr
  );
  padding-top: ${rem(24)};
`;

Styles.Sponsors = styled.div`
  display: grid;
  grid-row-gap: ${rem(56)};
`;

Styles.SponsorHeader = styled(Text)`
  height: ${rem(1)};
  background-color: ${props => sponsorColorMap[props.sponsorType]};
  position: relative;
  text-transform: capitalize;

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    content: '${props => props.sponsorTypeName}';
    padding: 0 ${rem(8)};
  }
`;

Styles.SponsorLink = styled.a`
  display: flex;
  height: ${props => sponsorSizeMap[props.sponsorType]};

  img {
    object-fit: contain;
  }
`;
