import Checkbox from '@components/checkbox';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@components/table';
import { useLocalization } from '@features/localization';
import EditIcon from 'icons/edit.svg';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useModal } from '../../../components/modal/modal-context';
import { iconButtonStyles } from '../../../components/modal/modal.styles';
import { COLOR_MAP } from '../invites-status/constants';
import { Dot } from '../invites-status/dot';
import EditMagiclinkForm from './edit-form/edit-magic-link-form';

export const getInvitationStatusProps = (
  isRegistered,
  isInvited,
  isDeclined,
  t,
  renameInvitedToPending,
) => {
  if (isDeclined) {
    return { text: t('status-props.declined'), color: COLOR_MAP.declined };
  }
  if (isRegistered) {
    return { text: t('status-props.registered'), color: COLOR_MAP.registered };
  }
  if (isInvited) {
    const text = renameInvitedToPending
      ? t('status-props.pending')
      : t('status-props.invited');
    return { text, color: COLOR_MAP.invited };
  }
  return {
    text: t('status-props.not-yet-invited'),
    color: COLOR_MAP.notYetInvited,
  };
};

const DotWrapper = styled.div`
  padding-right: var(--spacing-8);
`;

const InvitationStatusCell = styled.div`
  display: flex;
  align-items: center;
`;

const EditButton = styled.button`
  ${iconButtonStyles}
  transition: opacity var(--transition-duration) ease-in-out;
`;

const MagicLinkRow = styled(TableRow).attrs({
  'data-testid': 'magic-link-row',
})`
  ${EditButton} {
    opacity: 0;
  }
  &:hover {
    ${EditButton} {
      opacity: 1;
    }
  }
`;

export const headers = [
  'email',
  'first-name',
  'last-name',
  'headline',
  'ticket-type',
  'invitation-status',
  '',
];

const MagicLinksTable = ({
  magicLinks,
  eventSlug,
  apiClient,
  selectedIds,
  selectAll,
  handleSelectId,
  handleSelectAll,
  renameInvitedToPending,
}) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites.table');
  const { setContent, setIsModalOpen, setSize } = useModal();

  const handleClick = context => {
    setSize('medium');
    setContent(
      <EditMagiclinkForm
        eventSlug={eventSlug}
        initialValues={context}
        apiClient={apiClient}
      />,
    );
    setIsModalOpen(true);
  };

  const isChecked = id => {
    const isSelected = selectedIds.includes(id);
    return selectAll ? !isSelected : isSelected;
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell
            key="select-all"
            data-testid="magic-link-header-select-all"
          >
            <Checkbox
              onChange={handleSelectAll}
              checked={selectAll}
              minus={selectAll && !!selectedIds.length}
              data-testid="checkbox"
            />
          </TableHeaderCell>
          {headers.map(header => (
            <TableHeaderCell
              data-testid={`magic-link-header-${header}`}
              key={header}
            >
              {header ? t(header) : ''}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {magicLinks.map(
          ({
            email,
            firstName,
            lastName,
            headline,
            persona,
            isRegistered,
            isInvited,
            id,
            codeDeclinedAt,
          }) => {
            const handClickEdit = () =>
              handleClick({
                email,
                firstName,
                lastName,
                headline,
                id,
              });

            const invitationStatusProps = getInvitationStatusProps(
              isRegistered,
              isInvited,
              codeDeclinedAt,
              t,
              renameInvitedToPending,
            );

            return (
              <MagicLinkRow key={id} className="redeem-row">
                <TableBodyCell>
                  <Checkbox
                    onChange={() => handleSelectId(id)}
                    checked={isChecked(id)}
                    data-testid="checkbox"
                  />
                </TableBodyCell>
                <TableBodyCell>{email}</TableBodyCell>
                <TableBodyCell>{firstName}</TableBodyCell>
                <TableBodyCell>{lastName}</TableBodyCell>
                <TableBodyCell>{headline}</TableBodyCell>
                <TableBodyCell>{persona.label}</TableBodyCell>
                <TableBodyCell>
                  <InvitationStatusCell>
                    <DotWrapper>
                      <Dot
                        data-testid="magic-link-status-dot"
                        color={invitationStatusProps.color}
                      />
                    </DotWrapper>
                    <span data-testid="magic-link-status-text">
                      {invitationStatusProps.text}
                    </span>
                  </InvitationStatusCell>
                </TableBodyCell>
                <TableBodyCell>
                  {!isRegistered && (
                    <EditButton
                      data-testid="magic-link-edit-button"
                      onClick={handClickEdit}
                    >
                      <EditIcon />
                    </EditButton>
                  )}
                </TableBodyCell>
              </MagicLinkRow>
            );
          },
        )}
      </TableBody>
    </Table>
  );
};

MagicLinksTable.propTypes = {
  eventSlug: string.isRequired,
  apiClient: object.isRequired,
  magicLinks: arrayOf(
    shape({
      id: number,
      email: string,
      firstName: string,
      lastName: string,
      headline: string,
      persona: shape({
        id: number.isRequired,
      }),
      used: bool,
      sentEmailCount: number,
      isRegistered: bool,
      isInvited: bool,
      isCanceled: bool,
    }),
  ),
  selectedIds: arrayOf(number),
  selectAll: bool,
  handleSelectId: func,
  handleSelectAll: func,
  renameInvitedToPending: bool,
};

export default MagicLinksTable;
