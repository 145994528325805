import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import { AlertsContext } from '@features/alerts/alerts-provider';
import timezones from '@features/constants/timezones';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Icon } from '@hopin-team/ui-icon';
import { Spinner } from '@hopin-team/ui-spinner';
import { utcToZonedTime } from 'date-fns-tz';
import {
  array,
  bool,
  func,
  instanceOf,
  number,
  shape,
  string,
} from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

import { timezonesMap } from '../../constants/timezones-map';
import {
  AnnouncementModal,
  AnnouncementModalContent,
  AnnouncementModalFooter,
  AnnouncementModalHeader,
  AnnouncementsText,
  SecondaryActionButton,
} from '../styles';
import { Audience } from './audience';
import {
  ScheduleInfoItem,
  ScheduleInfoItemContent,
  ScheduleInfoItemTitle,
  ScheduleInfoValue,
} from './styles';

const ScheduleEmailModal = ({
  event,
  isModalDisplayed,
  handleClose,
  announcementEmailTemplateId,
  announcementStatus,
  token,
  selectedTicketTypes,
  status,
  recipientsCount,
  eventAudienceCount,
  subject,
  scheduleOptions,
}) => {
  const { t, formatDate } = useLocalization('email-dashboard');
  const {
    registration_count: registrationCount,
    organiser_id: organiserId,
    id: eventId,
    slug: eventSlug,
  } = event;
  const { addAlert } = useContext(AlertsContext);
  const [isLoading, setIsLoading] = useState(false);

  const formattedDate = useMemo(() => {
    return scheduleOptions?.date
      ? utcToZonedTime(
          scheduleOptions?.date,
          timezonesMap[scheduleOptions?.timezone],
        )
      : undefined;
  }, [scheduleOptions?.date, scheduleOptions?.timezone]);

  const timezone = useMemo(() => {
    return timezones().find(({ value }) => value === scheduleOptions?.timezone)
      ?.label;
  }, [scheduleOptions?.timezone]);

  const scheduleEmail = async () => {
    setIsLoading(true);
    try {
      if (announcementStatus === 'scheduled') {
        try {
          await hopinApi.cancelScheduledEmail(
            token,
            organiserId,
            eventId,
            announcementEmailTemplateId,
          );
        } catch (e) {
          addAlert({
            active: true,
            text: t(`list.errors.default`),
            pattern: 'error',
          });
        }
      }
      await hopinApi.scheduleAnnouncement(
        token,
        organiserId,
        eventId,
        announcementEmailTemplateId,
        scheduleOptions?.date,
        scheduleOptions?.timezone,
      );
      window.location.assign(
        `/organisers/events/${eventSlug}/announcements/?scheduled=${scheduleOptions?.date.toISOString()}&tz=${
          timezonesMap[scheduleOptions?.timezone]
        }`,
      );
    } catch (e) {
      addAlert({
        active: true,
        text: t(`schedule-email.error`),
        pattern: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Alerts />
      <AnnouncementModal
        describedById="modal-header"
        isShowing={isModalDisplayed}
        onClose={handleClose}
        isDismissible
        withCloseButton
        label="Schedule Email Modal"
        size="medium"
        data-testid="schedule-email-modal"
      >
        <AnnouncementModalHeader>
          <AnnouncementsText>
            {t(`schedule-email.email-ready`)}
          </AnnouncementsText>
        </AnnouncementModalHeader>
        <AnnouncementModalContent backgroundColor="gray-100">
          <img src="/images/send-email.png" alt="" />

          <Box mt={3} mb={1}>
            {t('schedule-email.subject')}: {subject}
          </Box>
          <Audience
            selectedTicketTypes={selectedTicketTypes}
            recipientsCount={recipientsCount}
            eventAudienceCount={eventAudienceCount}
            status={status}
            registrationCount={registrationCount}
          />
          <ScheduleInfoItem my={1} mb={3}>
            <Icon name="calendar-note" />
            <ScheduleInfoItemContent>
              <ScheduleInfoItemTitle>
                {t('schedule-email.scheduled-for')}
              </ScheduleInfoItemTitle>
              {formattedDate && (
                <>
                  <p data-testid="schedule-email-time">
                    <ScheduleInfoValue>
                      {formatDate(formattedDate, 'MMMM d')}
                    </ScheduleInfoValue>{' '}
                    {t('schedule-email.at')}{' '}
                    <ScheduleInfoValue>
                      {formatDate(formattedDate, 'hh:mm aa')}
                    </ScheduleInfoValue>
                  </p>
                  <p data-testid="schedule-email-timezone">{timezone}</p>
                </>
              )}
            </ScheduleInfoItemContent>
          </ScheduleInfoItem>
        </AnnouncementModalContent>
        <AnnouncementModalFooter>
          <SecondaryActionButton
            onClick={handleClose}
            data-testid="cancel-button"
          >
            {t(`schedule-email.cancel`)}
          </SecondaryActionButton>
          {isLoading ? (
            <Button
              isInline
              size="small"
              data-testid="sending-email-button-modal"
            >
              <AnnouncementsText color="grey-100" mr={1}>
                {t(`schedule-email.scheduling`)}
              </AnnouncementsText>
              <Spinner
                label={t(`schedule-email.scheduling`)}
                isAssertive
                isShowing={isLoading}
                color="grey-100"
              />
            </Button>
          ) : (
            <Button
              isInline
              size="small"
              onClick={scheduleEmail}
              data-testid="schedule-email-button-modal"
            >
              {t(`schedule-email.title`)}
            </Button>
          )}
        </AnnouncementModalFooter>
      </AnnouncementModal>
    </>
  );
};

ScheduleEmailModal.propTypes = {
  event: shape({
    registration_count: number.isRequired,
    organiser_id: number.isRequired,
    id: number.isRequired,
    slug: string.isRequired,
  }).isRequired,
  isModalDisplayed: bool.isRequired,
  handleClose: func.isRequired,
  announcementEmailTemplateId: string.isRequired,
  announcementStatus: string,
  token: string,
  status: string,
  selectedTicketTypes: array,
  hasTicketTypes: bool,
  recipientsCount: number,
  eventAudienceCount: number,
  subject: string,
  scheduleOptions: shape({
    date: instanceOf(Date),
    timezone: string,
  }),
};

export default ScheduleEmailModal;
