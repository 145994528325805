import { string } from 'prop-types';
import React from 'react';

const Alert = ({ color }) => (
  <svg
    data-testid="error-icon"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5234 0C5.00059 0 0.523438 4.47715 0.523438 10C0.523438 15.5228 5.00059 20 10.5234 20C16.0463 20 20.5234 15.5228 20.5234 10C20.5234 7.34784 19.4699 4.8043 17.5945 2.92893C15.7191 1.05357 13.1756 0 10.5234 0ZM11.5234 13.5C11.5234 13.7761 11.2996 14 11.0234 14H10.0234C9.7473 14 9.52344 13.7761 9.52344 13.5V12.5C9.52344 12.2239 9.7473 12 10.0234 12H11.0234C11.2996 12 11.5234 12.2239 11.5234 12.5V13.5ZM11.0534 10C11.1806 10.0009 11.2882 9.90624 11.3034 9.78L11.7034 6.56C11.7207 6.41735 11.6757 6.27417 11.58 6.16698C11.4843 6.05979 11.3471 5.99896 11.2034 6H9.84344C9.69975 5.99896 9.56256 6.05979 9.46685 6.16698C9.37115 6.27417 9.32619 6.41735 9.34344 6.56L9.74344 9.78C9.75869 9.90624 9.86628 10.0009 9.99344 10H11.0534Z"
      fill={color}
    />
  </svg>
);

Alert.propTypes = {
  color: string.isRequired,
};

export default Alert;
