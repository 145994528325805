import { COLOR } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

const Background = styled.div`
  &.open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: 9999;
    background: ${COLOR.GREY.WHITE};
  }
`;

const Container = styled.div`
  &.open {
    margin-left: auto;
    margin-right: auto;
    max-width: 1120px;
    width: 100%;
  }
`;

const PoweredBy = styled.div`
  text-align: center;
  color: ${COLOR.GREY['500']};
  margin-bottom: 1em;
`;

export { Background, Container, PoweredBy };
