import PagyPagination from '@components/pagination/pagy-pagination';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import * as Routes from '@routes';
import camelizeKeys from '@util/camelize-keys';
import { saveAs } from 'file-saver';
import { array, bool, number, object, objectOf, string } from 'prop-types';
import { equals, without } from 'ramda';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import InvitesStatus from '../invites-status';
import useBulkActionReducer from './bulk-actions/use-bulk-action-reducer';
import buildQueryObject from './filtering/build-query-object';
import useFilterReducer from './filtering/use-filter-reducer';
import MagicLinksPlanetaryTable from './magic-links-planetary-table';
import MagicLinksTable from './magic-links-table';
import MagicLinksTableHeader from './magic-links-table-header';
import MagicLinksTableHeaderPlanetary from './magic-links-table-header-planetary';
import { formatPayload } from './utils';

const ManageInvitesStyles = styled.div`
  margin-bottom: var(--spacing-32);
`;

const buildNewQueryParams = params => new URLSearchParams(params);

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const ManageInvitesComponent = ({
  eventSlug,
  pagyData,
  magicLinks: initialMagicLinks,
  searchText: initialSearchTextValue,
  redeemCodesStatus,
  tickets,
  filters,
  apiClient,
  renderPlanetaryComponents,
  renameInvitedToPending,
}) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites');
  const { addAlert } = useContext(AlertsContext);
  const {
    magicLinks,
    pagination,
    currentPage,
    selectedAll,
    setSelectedAll,
    setPagination,
    setMagicLinks,
    setCurrentPage,
    selectedIds,
    setSelectedIds,
  } = useBulkActionReducer(initialMagicLinks, pagyData, pagyData.page);
  const filterState = useFilterReducer(
    tickets,
    filters,
    initialSearchTextValue,
    t,
  );
  const firstRender = useRef(true);
  const previousSearchText = usePrevious(filterState.searchText);
  const previousSelectedFilters = usePrevious(filterState.selectedFilters);

  const queryParams = buildNewQueryParams(
    buildQueryObject(filterState.selectedFilters).concat([
      ['search_text', filterState.searchText],
      [
        'page',
        equals(filterState.selectedFilters, previousSelectedFilters) &&
        previousSearchText === filterState.searchText
          ? currentPage
          : 1,
      ],
    ]),
  );

  const getMagicLinksFiltered = async params => {
    try {
      const result = await apiClient.get(
        `${Routes.organisersEventRedeemCodesPath(eventSlug)}.json?${params}`,
      );

      const formattedResult = camelizeKeys(result);
      setPagination(formattedResult.pagy);
      setMagicLinks(formattedResult.paginatedRedeemCodes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    history.replaceState(null, null, `?${queryParams}#manage-invites`);
    getMagicLinksFiltered(queryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.selectedFilters, currentPage, filterState.searchText]);

  const handleChange = e => {
    filterState.updateFieldValue(e.target.value);
  };

  const handleClear = event => {
    event.preventDefault();
    filterState.updateFieldValue('');
  };

  const updateMagicLinks = ({ redeem_code }) => {
    const checkInclusion = id =>
      redeem_code.selected_ids?.includes(id) ||
      (redeem_code.excluded_ids && !redeem_code.excluded_ids.includes(id)) ||
      redeem_code.select_all;
    const newMagicLinks = magicLinks.map(ele => {
      const isIncluded = checkInclusion(ele.id);
      if (!isIncluded) {
        return ele;
      }
      return {
        ...ele,
        isInvited: true,
      };
    });
    setMagicLinks(newMagicLinks);
  };

  const handleSelectId = id => {
    const ids = selectedIds.includes(id)
      ? without([id], selectedIds)
      : [...selectedIds, id];

    setSelectedIds(ids);
  };

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);
    setSelectedIds([]);
  };

  const handleError = errors => {
    return addAlert({
      active: true,
      text: errors.join(', '),
      pattern: 'error',
    });
  };

  const sendInvites = async () => {
    const body = formatPayload(selectedIds, selectedAll, filterState);
    const headers = { 'Content-Type': 'application/json' };

    const result = await apiClient.post(
      Routes.sendRedeemCodesEmailOrganisersEventRedeemCodesPath(eventSlug),
      JSON.stringify(body),
      { headers },
    );
    if (result.success) {
      updateMagicLinks(body);
      return addAlert({
        active: true,
        text: selectedIds.length > 1 ? t('emails-sent') : t('email-sent'),
        pattern: 'success',
      });
    }
    if (result.errors) {
      handleError(result.errors);
    } else {
      const errors = [
        selectedIds.length > 1
          ? t('could-not-send-emails')
          : t('could-not-send-email'),
      ];
      handleError(errors);
    }
  };

  const downloadInvites = async () => {
    const body = formatPayload(selectedIds, selectedAll, filterState);
    const headers = { 'Content-Type': 'application/json' };

    const data = await apiClient.post(
      Routes.exportRedeemCodesCsvOrganisersEventRedeemCodesPath(eventSlug),
      JSON.stringify(body),
      { headers, type: 'text' },
    );
    var file = new File([data], 'invites.csv', {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(file);
  };

  const cancelInvites = async () => {
    const body = formatPayload(selectedIds, selectedAll, filterState);
    const headers = { 'Content-Type': 'application/json' };

    const result = await apiClient.post(
      `${Routes.cancelOrganisersEventRedeemCodesPath(
        eventSlug,
      )}?${queryParams}`,
      JSON.stringify(body),
      { headers },
    );

    if (result.success) {
      getMagicLinksFiltered(queryParams);
      setSelectedIds([]);
      setSelectedAll(false);

      return addAlert({
        active: true,
        text:
          selectedIds.length > 1
            ? t('invites-cancelled')
            : t('invite-cancelled'),
        pattern: 'success',
      });
    }

    return addAlert({
      active: true,
      text:
        selectedIds.length > 1
          ? t('could-not-cancel-invites')
          : t('could-not-cancel-invite'),
      pattern: 'error',
    });
  };

  return (
    <ManageInvitesStyles>
      <InvitesStatus
        redeemCodesStatus={redeemCodesStatus}
        renameInvitedToPending={renameInvitedToPending}
      />
      {renderPlanetaryComponents ? (
        <MagicLinksTableHeaderPlanetary
          onChange={handleChange}
          onClear={handleClear}
          inputValue={filterState.searchText}
          filters={filters}
          tickets={tickets}
          filterState={filterState}
          selectedIds={selectedIds}
          selectAll={selectedAll}
          total={pagination.count}
          send={sendInvites}
          download={downloadInvites}
          cancel={cancelInvites}
        />
      ) : (
        <MagicLinksTableHeader
          onChange={handleChange}
          inputValue={filterState.searchText}
          filters={filters}
          tickets={tickets}
          filterState={filterState}
          selectedIds={selectedIds}
          selectAll={selectedAll}
          total={pagination.count}
          send={sendInvites}
          download={downloadInvites}
          cancel={cancelInvites}
        />
      )}

      {renderPlanetaryComponents ? (
        <MagicLinksPlanetaryTable
          apiClient={apiClient}
          eventSlug={eventSlug}
          magicLinks={magicLinks}
          handleSelectId={handleSelectId}
          handleSelectAll={handleSelectAll}
          selectedIds={selectedIds}
          selectAll={selectedAll}
          renameInvitedToPending={renameInvitedToPending}
        />
      ) : (
        <MagicLinksTable
          apiClient={apiClient}
          eventSlug={eventSlug}
          magicLinks={magicLinks}
          handleSelectId={handleSelectId}
          handleSelectAll={handleSelectAll}
          selectedIds={selectedIds}
          selectAll={selectedAll}
          renameInvitedToPending={renameInvitedToPending}
        />
      )}

      {magicLinks.length > 0 && pagination.last > 1 && (
        <PagyPagination pagyData={pagination} setCurrentPage={setCurrentPage} />
      )}
    </ManageInvitesStyles>
  );
};

ManageInvitesComponent.propTypes = {
  eventSlug: string.isRequired,
  pagyData: object,
  magicLinks: array,
  searchText: string,
  totalRedeemCodes: number,
  redeemCodesStatus: objectOf(number),
  tickets: array.isRequired,
  filters: object,
  apiClient: object.isRequired,
  renderPlanetaryComponents: bool.isRequired,
  renameInvitedToPending: bool,
};

export default ManageInvitesComponent;
