import Flatpickr from '@components/flatpickr';
import { Label } from '@components/inputs';
import { LAYOUT } from '@hopin-team/ui-symbols';
import { isEqual, parseISO } from 'date-fns';
import { array, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ScheduleShift from './schedule-shift';
import SelectTimezones from './time-details/select-timezones-component';
const LayoutWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;

  @media (min-width: ${LAYOUT.TABLET_MAX}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StyledSelectTimezones = styled(SelectTimezones)`
  @media (min-width: ${LAYOUT.TABLET_MAX}) {
    grid-column: span 2;
  }
`;

const StyledScheduleShift = styled(ScheduleShift)`
  grid-column: 1 / -1;
`;

const stripISOTimezone = isoString =>
  isoString.replace(/((-|\+)\d\d:\d\d|Z)$/, '');

const TimeDetails = ({ event, timezones }) => {
  const [showScheduleShift, setShowScheduleShift] = useState(false);
  const [timeStart, setTimeStart] = useState(
    stripISOTimezone(event.timeStartLocal),
  );
  const [timeEnd, setTimeEnd] = useState(stripISOTimezone(event.timeEndLocal));

  useEffect(() => {
    if (event.schedules.length === 0) {
      return;
    }
    if (
      !isEqual(
        parseISO(timeStart),
        parseISO(stripISOTimezone(event.timeStartLocal)),
      ) ||
      !isEqual(
        parseISO(timeEnd),
        parseISO(stripISOTimezone(event.timeEndLocal)),
      )
    ) {
      setShowScheduleShift(true);
    } else if (showScheduleShift) {
      setShowScheduleShift(false);
    }
  }, [event, showScheduleShift, timeStart, timeEnd]);

  const flatpickrOptions = {
    altFormat: 'M-j h:iK',
    altInput: true,
    dateFormat: 'Y-m-d\\TH:i',
    enableTime: true,
  };

  return (
    <LayoutWrapper>
      <StyledSelectTimezones event={event} timezones={timezones} />
      <div>
        <Label htmlFor="event_time_start">Starts at</Label>
        <Flatpickr
          className="input"
          disabled={event.hasStarted}
          id="event_time_start"
          name="event[time_start]"
          onChange={([newTimeStart]) =>
            setTimeStart(newTimeStart.toISOString())
          }
          options={flatpickrOptions}
          required
          value={timeStart}
        />
      </div>
      <div>
        <Label htmlFor="event_time_end">Ends at</Label>
        <Flatpickr
          className="input"
          id="event_time_end"
          name="event[time_end]"
          onChange={([newTimeEnd]) => setTimeEnd(newTimeEnd.toISOString())}
          options={flatpickrOptions}
          required
          value={timeEnd}
        />
      </div>
      {showScheduleShift && (
        <StyledScheduleShift
          currentTimeStart={timeStart}
          currentTimeEnd={timeEnd}
          event={event}
        />
      )}
    </LayoutWrapper>
  );
};

TimeDetails.propTypes = {
  event: object.isRequired,
  timezones: array.isRequired,
};

export default TimeDetails;
