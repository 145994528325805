import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React from 'react';

const AddressCard = ({ address, googleMapsApiKey }) => {
  const coordinates = address?.lat && {
    lat: parseFloat(address.lat),
    lng: parseFloat(address.lng),
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });

  if (!isLoaded || !coordinates) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: 200,
        borderRadius: 8,
      }}
      center={coordinates}
      zoom={15}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
      }}
    >
      <div
        className="card p-8"
        style={{ position: 'absolute', bottom: 16, right: 16, left: 16 }}
      >
        <p>{address.name}</p>
        <p className="text -small -light">{address.formatted}</p>
      </div>
      <Marker position={coordinates} />
    </GoogleMap>
  );
};

AddressCard.propTypes = {
  address: PropTypes.object,
  googleMapsApiKey: PropTypes.string,
};

export default AddressCard;
