import { INTERACTIVE } from '@hopin-team/ui-blocks';
import { Card } from '@hopin-team/ui-card';
import { withSpace } from '@hopin-team/ui-helper-with-space';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const Icon = styled.div`
  width: 1.5rem;
  transition: color 200ms ease-out;
  margin-left: 1rem;
  flex-shrink: 0;
`;

export const Wrapper = styled(Card).attrs({ as: 'label', level: 'base' })`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  transition: background-color 200ms ease-out;
  opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};

  ${Icon} {
    color: ${({ selected }) =>
      selected ? 'var(--color-blue-400)' : 'var(--color-gray-600)'};
  }
`;

export const CheckboxDisplay = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid
    ${({ checked }) =>
      checked ? 'var(--color-blue-400)' : 'var(--color-gray-400)'};
  margin-right: 1rem;
  background-color: ${({ checked }) =>
    checked ? 'var(--color-blue-400)' : 'var(--color-white)'};
  color: var(--color-white);
  position: relative;
  flex-shrink: 0;

  svg {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 100%;
  height: 100%;
  ${INTERACTIVE.area}
`;

export const LabelText = styled.div`
  user-select: none;
  flex: auto;
`;

export const Subtitle = withSpace(Text);
