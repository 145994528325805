import initApiClient from '@util/api-client';
import camelizeKeys from '@util/camelize-keys';
import { connectViewData } from '@util/page-view-context';

import ManageInvitesComponent from './manage-invites-component';

const mapViewDataToProps = ({
  event,
  pagyData,
  redeemCodes,
  searchText,
  redeemCodesStatus,
  authenticityToken,
  filters,
  renderPlanetaryComponents,
  renameInvitedToPending,
}) => ({
  eventSlug: event.slug,
  magicLinks: camelizeKeys(redeemCodes),
  tickets: event.personas,
  pagyData,
  searchText,
  redeemCodesStatus,
  filters,
  apiClient: initApiClient(authenticityToken),
  renderPlanetaryComponents,
  renameInvitedToPending,
});

export default connectViewData(mapViewDataToProps)(ManageInvitesComponent);
