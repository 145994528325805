import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Toggle } from '@hopin-team/ui-toggle';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import AttachCalendarInviteConfirmationModal from './modals/attach-calendar-invite-confirmation-modal';
import ResumeAllEmailsModal from './modals/resume-all-emails-modal';
import StopAllEmailsModal from './modals/stop-all-emails-modal';

const StyledToggle = styled(Toggle)`
  color: var(--color-text);
  font-weight: 400;
`;

const CustomiseEmailsIndex = ({
  authenticityToken,
  eventSlug,
  emailsSuppressed,
  attachCalendarInvite,
}) => {
  const { t } = useLocalization('communications.customise-emails');
  const { addAlert } = useContext(AlertsContext);
  const apiClient = initApiClient(authenticityToken);
  const logger = getLogger(LOGGER_NAMES.COMMUNICATIONS);
  const [isStopAllEmailsModalOpen, setStopAllEmailsModalOpen] = useState(false);
  const [isResumeAllEmailsModalOpen, setResumeAllEmailsModalOpen] = useState(
    false,
  );

  const [
    isAttachCalendarInviteConfirmationModalOpen,
    setAttachCalendarInviteConfirmationModalOpen,
  ] = useState(false);

  const updateAttachCalendarInvite = async () => {
    const endpoint = Routes.attachCalendarInviteOrganisersEventCommunicationsPath(
      {
        event_id: eventSlug,
        _options: true,
      },
    );

    try {
      await apiClient.post(
        endpoint,
        JSON.stringify({
          attach_calendar_invite: !attachCalendarInvite,
        }),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );

      // Refresh the page to request the updated email template list.
      // We don't throw an error if we encounter a HTTP error (`!res.ok`)
      // because this logic is handled by the controller action when
      // a request is unsuccessful (i.e. outside of the 2xx range)
      window.location.reload();
    } catch (err) {
      logger.error(err);

      // There was a network error
      addAlert({
        active: true,
        text: t('index.stop-all-error-message'),
        pattern: 'error',
      });
    }
  };

  const confirmStopAll = async () => {
    const endpoint = Routes.suppressOrganisersEventCommunicationsPath({
      event_id: eventSlug,
      _options: true,
    });

    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );

      // Refresh the page to request the updated email template list.
      // We don't throw an error if we encounter a HTTP error (`!res.ok`)
      // because this logic is handled by the controller action when
      // a request is unsuccessful (i.e. outside of the 2xx range)
      window.location.reload();
    } catch (err) {
      logger.error(err);

      // There was a network error
      addAlert({
        active: true,
        text: t('index.stop-all-error-message'),
        pattern: 'error',
      });
    }
  };

  const confirmResumeAll = async () => {
    const endpoint = Routes.deliverOrganisersEventCommunicationsPath({
      event_id: eventSlug,
      _options: true,
    });

    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );

      // Refresh the page to request the updated email template list.
      // We don't throw an error if we encounter a HTTP error (`!res.ok`)
      // because this logic is handled by the controller action when
      // a request is unsuccessful (i.e. outside of the 2xx range)
      window.location.reload();
    } catch (err) {
      logger.error(err);

      // There was a network error
      addAlert({
        active: true,
        text: t('index.resume-all-error-message'),
        pattern: 'error',
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <StyledToggle
            isToggled={attachCalendarInvite}
            label={t('index.ics-toggle.title')}
            sizeOption="large"
            onChange={() => {
              setAttachCalendarInviteConfirmationModalOpen(true);
            }}
            data-testid="ics-toggle-toggle"
          />
          <AttachCalendarInviteConfirmationModal
            isModalDisplayed={isAttachCalendarInviteConfirmationModalOpen}
            handleClose={() =>
              setAttachCalendarInviteConfirmationModalOpen(false)
            }
            confirmResumeAll={updateAttachCalendarInvite}
            data-testid="ics-toggle-confirmation-modal"
            currentValue={attachCalendarInvite}
          />
        </div>
        <div className="col">
          <StyledToggle
            isToggled={!emailsSuppressed}
            label={t('index.stop-all-emails.title')}
            sizeOption="large"
            onChange={() => {
              emailsSuppressed
                ? setResumeAllEmailsModalOpen(true)
                : setStopAllEmailsModalOpen(true);
            }}
            data-testid="suppress-emails-toggle"
          />
          <StopAllEmailsModal
            isModalDisplayed={isStopAllEmailsModalOpen}
            handleClose={() => setStopAllEmailsModalOpen(false)}
            confirmStopAll={confirmStopAll}
            data-testid="stop-all-emails-confirmation-modal"
          />
          <ResumeAllEmailsModal
            isModalDisplayed={isResumeAllEmailsModalOpen}
            handleClose={() => setResumeAllEmailsModalOpen(false)}
            confirmResumeAll={confirmResumeAll}
            data-testid="resume-all-emails-confirmation-modal"
          />
        </div>
      </div>
    </>
  );
};

CustomiseEmailsIndex.propTypes = {
  authenticityToken: string.isRequired,
  eventSlug: string.isRequired,
  emailsSuppressed: bool.isRequired,
  attachCalendarInvite: bool.isRequired,
};

export default compose(
  withLocalizationProvider,
  withAlertsProvider,
)(CustomiseEmailsIndex);
