import { useLocalization } from '@features/localization';
import { Badge } from '@hopin-team/ui-badge';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import * as P from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled(Flex).attrs({
  border: 'grey-300',
  cornerRadius: 'lg',
  p: 1.5,
})`
  position: relative;
  padding-bottom: ${rem(4)};
  cursor: pointer;
  transition: box-shadow var(--transition-duration);
  &:hover {
    box-shadow: 0 0 5px -1px rgb(0, 0, 0, 0.2);
  }
`;

const StyledButton = styled(Button).attrs({
  isGhost: true,
  size: 'small',
  isInline: true,
})`
  margin-top: auto;
  padding-left: 0;
  padding-right: 3rem;
  &:hover {
    background: transparent;
  }
`;

const IconContainer = styled.div`
  width: ${rem(32)};
  min-width: ${rem(32)};
  height: ${rem(32)};
  overflow: hidden;
`;

const StatusContainer = styled.div`
  position: absolute;
  top: ${rem(12)};
  right: ${rem(12)};
`;

const EventActionCard = ({
  title,
  description,
  ctaLabel,
  completedCtaLabel,
  icon,
  completed,
  required,
  onClick,
}) => {
  const { t } = useLocalization('overview.event-actions.card');

  const [completedState, setCompletedState] = useState(completed);

  const handleClick = async () => {
    const res = await onClick();
    Boolean(res?.completed) && setCompletedState(res.completed);
  };

  return (
    <Container data-testid="event-action-card" onClick={handleClick}>
      <IconContainer>
        {React.isValidElement(icon) ? (
          icon
        ) : (
          <Halon
            aria-label={title}
            icon={icon.name}
            color={`${icon.color}-100`}
            iconColor={`${icon.color}-500`}
            scale={4}
            iconScale={2}
          />
        )}
      </IconContainer>

      <Flex flexDirection="column" alignItems="flex-start" ml={1.5}>
        <Text
          element="p"
          pattern="bodyLargeBold"
          style={{ lineHeight: '24px' }}
        >
          {title}
        </Text>
        <Text
          element="p"
          pattern="caption"
          color="grey-700"
          style={{ lineHeight: '24px' }}
        >
          {description}
        </Text>
        <StyledButton>
          {completedState ? completedCtaLabel : ctaLabel}
        </StyledButton>
      </Flex>

      <StatusContainer>
        {completedState ? (
          <Halon
            aria-label={t('completed')}
            icon="checkmark"
            color="green-500"
            iconColor="grey-white"
            scale={2.5}
            iconScale={1.25}
          />
        ) : (
          <Badge
            priority="low"
            colorPattern={required ? 'danger' : 'informational'}
          >
            {required ? t('required') : t('optional')}
          </Badge>
        )}
      </StatusContainer>
    </Container>
  );
};

EventActionCard.propTypes = {
  icon: P.oneOfType([
    P.shape({ name: P.string.isRequired, color: P.string.isRequired }),
    P.element,
  ]).isRequired,
  title: P.string.isRequired,
  description: P.string.isRequired,
  required: P.bool.isRequired,
  completed: P.bool.isRequired,
  ctaLabel: P.string.isRequired,
  completedCtaLabel: P.string.isRequired,
  onClick: P.func.isRequired,
};

export default EventActionCard;
