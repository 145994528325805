import { useOnClickOutside } from '@hopin-team/ui-helper-hooks';
import PropTypes from 'prop-types';
import React, { forwardRef, useRef } from 'react';

import {
  Button,
  Menu,
  MenuHeading,
  MenuItem,
  MenuSection,
  Wrapper,
} from './menu-styled-components';

const MenuComponent = forwardRef(
  (
    {
      buttonContents,
      children,
      className,
      closing,
      expanded,
      fixed,
      onButtonClick,
      onOutsideClick,
      menuPosition,
      position,
    },
    ref,
  ) => {
    const wrapperElem = useRef();
    useOnClickOutside(wrapperElem, () => onOutsideClick());

    return (
      <Wrapper
        ref={wrapperElem}
        data-testid="menu-wrapper"
        className={className}
      >
        <Button
          type="button"
          onClick={onButtonClick}
          aria-expanded={expanded}
          data-testid="menu-button"
        >
          {buttonContents}
        </Button>
        <Menu
          ref={ref}
          aria-hidden={!expanded}
          closing={closing}
          expanded={expanded}
          fixed={fixed}
          position={position}
          data-testid="menu-menu"
          boundingClientRect={menuPosition}
        >
          {children}
        </Menu>
      </Wrapper>
    );
  },
);

MenuComponent.propTypes = {
  buttonContents: PropTypes.element.isRequired,
  className: PropTypes.string,
  closing: PropTypes.bool,
  expanded: PropTypes.bool,
  fixed: PropTypes.bool,
  menuPosition: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }),
  onButtonClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  position: PropTypes.string,
  children: PropTypes.node,
};

MenuComponent.defaultProps = {
  expanded: false,
  onButtonClick: () => {},
  onOutsideClick: () => {},
};

export default MenuComponent;
export { MenuHeading, MenuItem, MenuSection };
