import PropTypes from 'prop-types';
import React from 'react';

import { Halon, Image, ImageContainer, ImageLink } from '../../index.styled';

const ImageEdit = ({ event }) => {
  return (
    <ImageContainer>
      <ImageLink
        data-testid="image-link"
        href={`/organisers/events/${event.slug}/design`}
      >
        <Halon
          isSquare
          icon="edit"
          iconColor="grey-600"
          color="grey-white"
          scale={3}
        />
        <Image src={event.picture} />
      </ImageLink>
    </ImageContainer>
  );
};

ImageEdit.propTypes = {
  event: PropTypes.object,
};

export default ImageEdit;
