import amplitudeClient from '@util/analytics/amplitude-client';
import { number } from 'prop-types';
import React, { useEffect } from 'react';

import DashboardTabsComponent from './dashboard-tabs-component';

const tabIdToAmplitudeTabName = tabId => {
  return tabId.charAt(0).toUpperCase() + tabId.slice(1);
};

const DashboardTabsContainer = ({ userId, ...rest }) => {
  // TODO use segment client for ID and track
  useEffect(() => {
    amplitudeClient.setUserId(userId);
  }, [userId]);

  const handleTabClick = tabId => {
    const tabName = tabIdToAmplitudeTabName(tabId);
    amplitudeClient.logEvent('tab_changed', {
      tabName,
    });
  };

  return <DashboardTabsComponent onTabClick={handleTabClick} {...rest} />;
};

DashboardTabsContainer.propTypes = {
  userId: number.isRequired,
};

export default DashboardTabsContainer;
