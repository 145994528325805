import { COLOR, LINE } from '@hopin-team/ui-symbols';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% - 3rem);
  max-width: 47.5rem;
  padding: ${rem(24)} 0;
`;

export const Input = styled.input`
  width: ${rem(270)};
`;

export const ToolBar = styled.div`
  width: 100%;
`;

export const Status = styled.div`
  margin: auto;
`;

const statusCellShadow = css`
  box-shadow: 0px 1px 0px ${COLOR.GREY['100']},
    inset 0px 1px 0px ${COLOR.GREY['100']};
`;

const statusCellSizing = css`
  width: ${rem(100)};
  padding: ${rem(12)} 0;
`;

export const StatusCell = styled.div`
  ${statusCellShadow}
  ${statusCellSizing}
`;

export const StatusNameCell = styled(StatusCell)`
  text-align: right;
`;

export const DomainNameHeading = styled.div`
  ${statusCellSizing}
  text-align: center !important;
`;

export const FaviconLabel = styled.div`
  max-width: ${rem(150)};
  color: ${COLOR.GREY['600']};
`;

export const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(100)};
  height: ${rem(100)};
  border-radius: ${LINE.RADIUS_MD};
  overflow: hidden;
  border: 1px dashed ${COLOR.GREY['300']};
`;
