import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { calcSpace } from '@hopin-team/ui-symbols';
import {
  Table,
  TableCell,
  TableHeading,
  TableRow,
  TableRowAction,
  TableRowActions,
} from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { arrayOf, boolean, number, shape, string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  border-collapse: collapse;
  margin: ${calcSpace(4)} 0;
  table-layout: auto;
  width: 100%;
`;

const StyledTableCell = styled(TableCell)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
  overflow-wrap: break-word;
`;

const StyledTableHeading = styled(TableHeading)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
`;

const SmallText = styled(Text)`
  font-size: 80%;
  overflow-wrap: break-word;
`;

const StyledTableRowActions = styled(TableRowActions)`
  padding-right: ${calcSpace(2)};
`;

const AttendeesListTable = ({
  registrations,
  eventSlug,
  authenticityToken,
  currency,
}) => {
  const { t } = useLocalization('people.attendees.attendees-list');
  const { addAlert } = useContext(AlertsContext);
  const apiClient = initApiClient(authenticityToken);

  const logger = getLogger(LOGGER_NAMES.REGISTRATIONS);

  const handleUnblock = async registrationId => {
    const confirm = window.confirm(t('are-you-sure'));

    if (confirm) {
      try {
        await hopinApi.unblockAttendeeFromEvent(registrationId, eventSlug);
        window.location.reload();
      } catch (err) {
        logger.error(err);
        addAlert({
          active: true,
          text: t('unblock-error-message'),
          pattern: 'error',
        });
      }
    }
  };

  const handleRemove = async (registrationId, chargeId) => {
    const confirm = window.confirm(chargeId ? t('refund') : t('are-you-sure'));

    if (confirm) {
      const url = Routes.organisersEventAttendeePath({
        event_id: eventSlug,
        id: registrationId,
        _options: true,
      });

      try {
        await apiClient.del(url, {
          headers: {
            Accept: 'application/json',
          },
        });
        window.location.reload();
      } catch (err) {
        logger.error(err);
        // There was a network error
        addAlert({
          active: true,
          text: t('remove-error-message'),
          pattern: 'error',
        });
      }
    }
  };

  if (registrations.length === 0) {
    return t('no-registrations');
  } else {
    return (
      <>
        <Alerts />
        <StyledTable
          hasActions
          hasHighlights
          data-testid="attendees-table-planetary"
        >
          <thead>
            <TableRow isHeading>
              <StyledTableHeading scope="col">
                <Text pattern="emphasis">{t('name')}</Text>
              </StyledTableHeading>
              <StyledTableHeading scope="col">
                <Text pattern="emphasis">{t('email')}</Text>
              </StyledTableHeading>
              <StyledTableHeading scope="col">
                <Text pattern="emphasis">{t('location')}</Text>
              </StyledTableHeading>
              <StyledTableHeading scope="col">
                <Text pattern="emphasis">{t('ticket')}</Text>
              </StyledTableHeading>
              <StyledTableHeading pattern="emphasis" scope="col">
                <SmallText>{t('purchase-price') + ` (${currency})`}</SmallText>
              </StyledTableHeading>
              <StyledTableHeading pattern="emphasis" scope="col">
                <SmallText>{t('registration-date')}</SmallText>
              </StyledTableHeading>
            </TableRow>
          </thead>
          <tbody>
            {registrations.map(registration => (
              <TableRow key={registration.id}>
                <StyledTableCell>
                  {registration.profile.first_name}{' '}
                  {registration.profile.last_name}
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-800">
                    {registration.profile.email}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-800">
                    {registration.profile.country}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-800">
                    {registration.persona}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-800">
                    {Number(registration.pricePaid / 100).toFixed(2)}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <SmallText pattern="body" color="grey-800">
                    {moment
                      .utc(registration.tzTime)
                      .local()
                      .format('DD MMM YYYY, LT')}
                  </SmallText>
                </StyledTableCell>
                <StyledTableRowActions>
                  {registration.blocked || registration.chatBanned ? (
                    <>
                      <TableRowAction
                        icon={
                          registration.chatBanned
                            ? 'microphone-muted'
                            : 'unlock'
                        }
                        title={
                          registration.blocked ? t('unblock') : t('unmute')
                        }
                        onClick={() => handleUnblock(registration.id)}
                      />
                    </>
                  ) : null}
                  {registration.refunded ? (
                    <StyledTableCell>
                      <Text>{t('removed')}</Text>
                    </StyledTableCell>
                  ) : (
                    <TableRowAction
                      icon="delete"
                      title={
                        registration.chargeId ? t('refund-remove') : t('remove')
                      }
                      onClick={() =>
                        handleRemove(registration.id, registration.chargeId)
                      }
                    />
                  )}
                </StyledTableRowActions>
              </TableRow>
            ))}
          </tbody>
        </StyledTable>
      </>
    );
  }
};

AttendeesListTable.propTypes = {
  authenticityToken: string.isRequired,
  eventSlug: string.isRequired,
  currency: string.isRequired,
  registrations: arrayOf(
    shape({
      persona: string.isRequired,
      pricePaid: number,
      chatBanned: boolean,
      chargeId: string,
      tzTime: string.isRequired,
      blocked: boolean,
      refunded: boolean,
      profile: shape({
        country: string,
        email: string.isRequired,
        first_name: string.isRequired,
        last_name: string.isRequired,
      }).isRequired,
    }),
  ),
};

export default compose(
  withLocalizationProvider,
  withAlertsProvider,
)(AttendeesListTable);
