import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const { actions, reducer } = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state, action) => action.payload,
  },
});

export const { setTickets } = actions;

export default reducer;
