import { useLocalization } from '@features/localization';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { trimRecording as trimRecordingApi, ValidationError } from '../api';
import { updateRecording as updateRecordingInStore } from '../redux/slices/recordings/recordings-slice';

export const useTrimRecording = ({ slug, onSuccess, onError }) => {
  const { t } = useLocalization('recording-group');
  const dispatch = useDispatch();
  const [isTrimSaving, setIsTrimSaving] = useState(false);

  const trimRecording = async ({ recording, startTimeMs, endTimeMs }) => {
    const { id } = recording;
    const previousRecording = recording;
    setIsTrimSaving(true);

    try {
      const updatedRecording = await trimRecordingApi(id, slug, {
        startTimeMs,
        endTimeMs,
      });

      dispatch(updateRecordingInStore(updatedRecording));
      setIsTrimSaving(false);
      if (onSuccess) onSuccess(updatedRecording);
    } catch (error) {
      setIsTrimSaving(false);

      let message = t('something-went-wrong-try-later');

      if (error && error instanceof ValidationError) {
        message = error.message;
      }

      if (onError) {
        onError({ error, message, previousRecording });
      }
    }
  };

  return { trimRecording, isTrimSaving };
};
