import hopinApi from '@api/hopin';
import { Image } from '@hopin-team/ui-image';
import React from 'react';

import goFundMeImg from './icons/gofundme-icon.png';
import kahootImg from './icons/kahoot-icon.png';
import mailchimpImg from './icons/mailchimp-icon.png';
import miroImg from './icons/miro-icon.png';
import slidoImg from './icons/slido-icon.png';
import StripeIcon from './icons/stripe-icon.js';
import TicketGroupIcon from './icons/ticket-group-icon.svg';

const CTA = {
  SETUP: 'overview.event-actions.cta.setup',
  CREATE: 'overview.event-actions.cta.create',
  ADD: 'overview.event-actions.cta.add',
  LEARN_MORE: 'overview.event-actions.cta.learn-more',
  UPGRADE: 'overview.event-actions.cta.upgrade',
  GET_STARTED: 'overview.event-actions.cta.get-started',
  CUSTOMISE: 'overview.event-actions.cta.customise',
  EDIT: 'overview.event-actions.cta.edit',
};

export const INITIAL_CARDS_TO_SHOW = 6;

export const EVENT_ACTION_CARD_MAP = {
  'connect-stripe': {
    title: 'overview.event-actions.connect-stripe.title',
    description: 'overview.event-actions.connect-stripe.desc',
    ctaLabel: CTA.SETUP,
    completedCtaLabel: CTA.SETUP,
    icon: <StripeIcon />,
    onClick({ stripeUrl }) {
      window.open(stripeUrl);
    },
  },

  'create-paid-ticket': {
    title: 'overview.event-actions.create-paid-ticket.title',
    description: 'overview.event-actions.create-paid-ticket.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'ticket', color: 'orange' },
    onClick({ event, newRegistrationsUrl }) {
      window.location = `${newRegistrationsUrl}/tickets?slug=${event.slug}`;
    },
  },

  'design-registration-page': {
    title: 'overview.event-actions.design-registration-page.title',
    description: 'overview.event-actions.design-registration-page.desc',
    ctaLabel: CTA.GET_STARTED,
    completedCtaLabel: CTA.EDIT,
    icon: { name: 'edit', color: 'purple' },
    onClick({ event }) {
      window.location.pathname = `/events/${event.slug}/registration/edit`;
    },
  },

  'create-custom-form': {
    title: 'overview.event-actions.create-custom-form.title',
    description: 'overview.event-actions.create-custom-form.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'edit', color: 'purple' },
    shouldHide: ctx => !ctx.newRegistrationsEnabled,
    onClick({ event, newRegistrationsUrl }) {
      window.location = `${newRegistrationsUrl}/form-builder?slug=${event.slug}`;
    },
  },

  'create-email-attendee': {
    title: 'overview.event-actions.create-email-attendee.title',
    description: 'overview.event-actions.create-email-attendee.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'email-two-tone', color: 'green' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/announcements`;
    },
  },

  'create-speaker': {
    title: 'overview.event-actions.create-speaker.title',
    description: 'overview.event-actions.create-speaker.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'microphone', color: 'purple' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/speakers`;
    },
  },

  'create-schedule': {
    title: 'overview.event-actions.create-schedule.title',
    description: 'overview.event-actions.create-schedule.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'calendar-month', color: 'red' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/schedules`;
    },
  },

  'create-session': {
    title: 'overview.event-actions.create-session.title',
    description: 'overview.event-actions.create-session.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'sessions', color: 'green' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/roundtables`;
    },
  },

  'create-expo-booth': {
    title: 'overview.event-actions.create-expo-booth.title',
    description: 'overview.event-actions.create-expo-booth.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'booth', color: 'yellow' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/vendors`;
    },
  },

  'create-sponsor': {
    title: 'overview.event-actions.create-sponsor.title',
    description: 'overview.event-actions.create-sponsor.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: { name: 'people', color: 'orange' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/sponsors`;
    },
  },

  'create-reception-area': {
    title: 'overview.event-actions.create-reception-area.title',
    description: 'overview.event-actions.create-reception-area.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.EDIT,
    icon: { name: 'house', color: 'orange' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/reception_form`;
    },
  },

  'create-ticket-group': {
    title: 'overview.event-actions.create-ticket-group.title',
    description: 'overview.event-actions.create-ticket-group.desc',
    ctaLabel: CTA.ADD,
    completedCtaLabel: CTA.ADD,
    icon: <TicketGroupIcon />,
    shouldHide: ctx => !ctx.newRegistrationsEnabled,
    onClick({ event, newRegistrationsUrl }) {
      window.location = `${newRegistrationsUrl}/event-tracks?slug=${event.slug}`;
    },
  },

  'setup-networking': {
    title: 'overview.event-actions.setup-networking.title',
    description: 'overview.event-actions.setup-networking.desc',
    ctaLabel: CTA.SETUP,
    completedCtaLabel: CTA.EDIT,
    icon: { name: 'handshake', color: 'purple' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/people`;
    },
  },

  'create-check-in-area': {
    title: 'overview.event-actions.create-check-in-area.title',
    description: 'overview.event-actions.create-check-in-area.desc',
    ctaLabel: CTA.CREATE,
    completedCtaLabel: CTA.EDIT,
    icon: { name: 'qr-code', color: 'blue' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/check_in_area`;
    },
  },

  'create-kiosk': {
    title: 'overview.event-actions.create-kiosk.title',
    description: 'overview.event-actions.create-kiosk.desc',
    ctaLabel: CTA.GET_STARTED,
    completedCtaLabel: CTA.EDIT,
    icon: { name: 'mobile', color: 'green' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/kiosk_mode`;
    },
  },

  'create-badge': {
    title: 'overview.event-actions.create-badge.title',
    description: 'overview.event-actions.create-badge.desc',
    ctaLabel: CTA.CUSTOMISE,
    completedCtaLabel: CTA.CUSTOMISE,
    icon: { name: 'edit', color: 'purple' },
    onClick({ event }) {
      window.location.pathname = `/organisers/events/${event.slug}/badge_designer`;
    },
  },

  'connect-mailchimp': {
    title: 'overview.event-actions.connect-mailchimp.title',
    description: 'overview.event-actions.connect-mailchimp.desc',
    ctaLabel: CTA.LEARN_MORE,
    completedCtaLabel: CTA.LEARN_MORE,
    icon: <Image src={mailchimpImg} alt="Mailchimp" />,
    async onClick({ event, token }) {
      window.open(
        `/organization/${event.organization.external_id}/integrations/mailchimp`,
      );

      try {
        const res = await hopinApi.markEventActionComplete(
          event.external_id,
          'connect-mailchimp',
          token,
        );
        return res;
      } catch (e) {
        console.error(e);
      }
    },
  },

  'connect-kahoot': {
    title: 'overview.event-actions.connect-kahoot.title',
    description: 'overview.event-actions.connect-kahoot.desc',
    ctaLabel: CTA.LEARN_MORE,
    completedCtaLabel: CTA.LEARN_MORE,
    icon: <Image src={kahootImg} alt="Kahoot" />,
    async onClick({ event, token }) {
      window.open(
        `/organization/${event.organization.external_id}/integrations/kahoot`,
      );

      try {
        const res = await hopinApi.markEventActionComplete(
          event.external_id,
          'connect-kahoot',
          token,
        );
        return res;
      } catch (e) {
        console.error(e);
      }
    },
  },

  'connect-miro': {
    title: 'overview.event-actions.connect-miro.title',
    description: 'overview.event-actions.connect-miro.desc',
    ctaLabel: CTA.LEARN_MORE,
    completedCtaLabel: CTA.LEARN_MORE,
    icon: <Image src={miroImg} alt="Miro" />,
    async onClick({ event, token }) {
      window.open(
        `/organization/${event.organization.external_id}/integrations/miro`,
      );

      try {
        const res = await hopinApi.markEventActionComplete(
          event.external_id,
          'connect-miro',
          token,
        );
        return res;
      } catch (e) {
        console.error(e);
      }
    },
  },

  'connect-gofundme': {
    title: 'overview.event-actions.connect-gofundme.title',
    description: 'overview.event-actions.connect-gofundme.desc',
    ctaLabel: CTA.LEARN_MORE,
    completedCtaLabel: CTA.LEARN_MORE,
    icon: <Image src={goFundMeImg} alt="GoFundMe" />,
    async onClick({ event, token }) {
      window.open(
        `/organization/${event.organization.external_id}/integrations/go_fund_me`,
      );

      try {
        const res = await hopinApi.markEventActionComplete(
          event.external_id,
          'connect-gofundme',
          token,
        );
        return res;
      } catch (e) {
        console.error(e);
      }
    },
  },

  'connect-slido': {
    title: 'overview.event-actions.connect-slido.title',
    description: 'overview.event-actions.connect-slido.desc',
    ctaLabel: CTA.LEARN_MORE,
    completedCtaLabel: CTA.LEARN_MORE,
    icon: <Image src={slidoImg} alt="Slido" />,
    async onClick({ event, token }) {
      window.open(
        `/organization/${event.organization.external_id}/integrations/slido`,
      );

      try {
        const res = await hopinApi.markEventActionComplete(
          event.external_id,
          'connect-slido',
          token,
        );
        return res;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
