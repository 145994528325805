import { Button } from '@hopin-team/ui-button';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  input[type='file'] {
    display: none;
  }

  ${Button} {
    margin-bottom: 0;
  }
`;
