// @ts-check
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';

const options = {
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
  localeMatcher: 'lookup',
  hourCycle: 'h12',
};

/**
 * Returns an event date formatted to the desired locale string.
 * @param {Date} date
 * @param {string | string[]} locales
 */
export function eventDateToLocaleString(date, locales) {
  return date.toLocaleString(locales, options);
}

/**
 * Formats date in UTC string to the desired timezone and format
 * @param {string} utcDatetimeString
 * @param {string} format
 * @param {string} timeZone
 * @returns formatted date in time zone from UTC string
 */
export function utcStringToTimeZone(utcDatetimeString, format, timeZone) {
  const date = utcToZonedTime(new Date(utcDatetimeString), timeZone);
  return formatDate(date, format);
}

/**
 * Returns the timezone of the browser in IANA format
 * @returns The timezone (e.g. "Europe/London")
 */
export function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
