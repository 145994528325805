export const iconAccessibilityProps = t => ({
  clear: {
    title: t('common.select-icon-accessibility-props.clear.title'),
    description: t('common.select-icon-accessibility-props.clear.description'),
  },
  dropdown: {
    title: t('common.select-icon-accessibility-props.dropdown.title'),
    description: t(
      'common.select-icon-accessibility-props.dropdown.description',
    ),
  },
  multiValueRemove: t(
    'common.select-icon-accessibility-props.multi-value-remove.title',
  ),
});
