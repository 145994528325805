import { useLocalization } from '@features/localization';
import { array, bool, string } from 'prop-types';
import React from 'react';

import { TagItem } from './styles';

export const AudienceSelection = ({ hasTicketTypes, status, ticketTypes }) => {
  const { t } = useLocalization('email-dashboard');

  const statusLabels = {
    registered: t('edit.all_registered'),
    attended: t('edit.all_attended'),
    unattended: t('edit.all_unattended'),
    ml_invited: t('edit.all_pending_registration'),
  };

  return (
    <>
      {hasTicketTypes ? (
        <>
          <TagItem>{statusLabels[status]}</TagItem>
          {ticketTypes.length ? (
            ticketTypes.map(item => (
              <TagItem key={item.id}>{item.label}</TagItem>
            ))
          ) : (
            <TagItem>{t('edit.all_ticket_types')}</TagItem>
          )}
        </>
      ) : (
        t('edit.no_audience_selected')
      )}
    </>
  );
};

AudienceSelection.propTypes = {
  hasTicketTypes: bool,
  status: string,
  ticketTypes: array,
};
