import { Butticon } from '@hopin-team/ui-butticon';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { string } from 'prop-types';
import React from 'react';

import { CancelScheduleButtonContainer } from './styles';

export const CancelScheduleIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0002 1.99991L2.00015 21.9999L0.585938 20.5857L20.5859 0.585694L22.0002 1.99991Z"
      fill="#696E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8232 4.17675C19.5722 4.06321 19.2935 4 19 4H18V2.5C18 2.22386 17.7761 2 17.5 2H16.5C16.2239 2 16 2.22386 16 2.5V4H8V2.5C8 2.22386 7.77614 2 7.5 2H6.5C6.22386 2 6 2.22386 6 2.5V4H5C3.89543 4 3 4.89543 3 6V19C3 19.5523 3.22386 20.0523 3.58579 20.4142L5 19V8H16L19.8232 4.17675ZM15.0712 11.7573L16.7573 10.0712C16.9027 10.1586 17 10.3179 17 10.5V11.5C17 11.7761 16.7761 12 16.5 12H15.5C15.3179 12 15.1586 11.9027 15.0712 11.7573ZM11.0712 15.7573L12.7573 14.0712C12.9027 14.1586 13 14.3179 13 14.5V15.5C13 15.7761 12.7761 16 12.5 16H11.5C11.3179 16 11.1586 15.9027 11.0712 15.7573ZM7.82843 19H19V8H18.8284L20.9933 5.83513C20.9977 5.8895 21 5.94448 21 6V19C21 20.1046 20.1046 21 19 21H5.82843L7.82843 19ZM13 11V10.5C13 10.2239 12.7761 10 12.5 10H11.5C11.2239 10 11 10.2239 11 10.5V11.5C11 11.7761 11.2239 12 11.5 12H12L13 11ZM9 15V14.5C9 14.2239 8.77614 14 8.5 14H7.5C7.22386 14 7 14.2239 7 14.5V15.5C7 15.7761 7.22386 16 7.5 16H8L9 15ZM8.5 12C8.77614 12 9 11.7761 9 11.5V10.5C9 10.2239 8.77614 10 8.5 10H7.5C7.22386 10 7 10.2239 7 10.5V11.5C7 11.7761 7.22386 12 7.5 12H8.5Z"
      fill="#696E7C"
    />
  </svg>
);

export const CancelScheduleButton = ({ title, ...props }) => {
  return (
    <CancelScheduleButtonContainer>
      <ToolTip align="bottom" tip={title} aria-hidden="true">
        <Butticon
          icon="calendar"
          {...props}
          title={title}
          onMouseUp={e => e.target.blur()}
        />
      </ToolTip>
      <CancelScheduleIcon />
    </CancelScheduleButtonContainer>
  );
};

CancelScheduleButton.propTypes = {
  title: string,
};
