export const iconAccessibilityProps = (t, type) => ({
  clear: {
    title: t('people.attendees.accessibility.dropdown.close'),
    description: t('people.attendees.accessibility.dropdown.clear', { type }),
  },
  dropdown: {
    title: t('people.attendees.accessibility.dropdown.icon'),
    description: t('people.attendees.accessibility.dropdown.open', { type }),
  },
  multiValueRemove: {
    title: t('people.attendees.accessibility.dropdown.close'),
  },
});

export const eventTracksIconAccessibilityProps = t =>
  iconAccessibilityProps(
    t,
    t('people.attendees.accessibility.event-tracks-dropdown'),
  );

export const ticketTypesIconAccessibilityProps = t =>
  iconAccessibilityProps(
    t,
    t('people.attendees.accessibility.ticket types-dropdown'),
  );
