import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

export const RecordingGroupRelatedLinks = ({
  title,
  backupHref,
  rehearsalHref,
}) => {
  const { t } = useLocalization('recording-group.view-alternative-videos');

  const relatedLinksLocalizedHtml = backupHref
    ? t('link-with-rehearsal-and-backups', {
        title,
        backupHref: backupHref,
        rehearsalHref: rehearsalHref,
      })
    : t('link-with-rehearsal', {
        title,
        rehearsalHref: rehearsalHref,
      });
  return (
    <Flex mt={1}>
      <Text pattern="caption" color="grey-600" linkStyle="underline">
        <span
          dangerouslySetInnerHTML={{
            __html: relatedLinksLocalizedHtml,
          }}
        />
      </Text>
    </Flex>
  );
};

RecordingGroupRelatedLinks.propTypes = {
  title: PropTypes.string.isRequired,
  rehearsalHref: PropTypes.string.isRequired,
  backupHref: PropTypes.string,
};
