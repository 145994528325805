import { Button } from '@hopin-team/ui-button';
import { Icon } from '@hopin-team/ui-icon';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = props => (
  <StyledButton data-testid={props['data-testid']}>
    <Icon mx={1} data-testid="heart-icon" color="grey-white" name="heart" />
    {props.text}
  </StyledButton>
);

ButtonContainer.propTypes = {
  text: string,
  ['data-testid']: string,
};

const ButtonCTA = ({
  website,
  buttonAction,
  actionButtonText,
  ...restProps
}) => {
  if (buttonAction === 'direct_link') {
    return (
      <a
        data-testid={restProps['data-testid']}
        href={website}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonContainer text={actionButtonText} />
      </a>
    );
  }
  return (
    <ButtonContainer
      data-testid={restProps['data-testid']}
      text={actionButtonText}
    />
  );
};

ButtonCTA.propTypes = {
  actionButtonText: string.isRequired,
  buttonAction: string,
  website: string,
  ['data-testid']: string,
};

export default ButtonCTA;
