import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const DurationLabel = styled(Text).attrs({
  scale: 3,
  font: 'text',
  weight: 'medium',
})`
  color: var(--color-gray-650);
  padding: var(--spacing-2) 0;
`;

export const DurationText = styled(Text)`
  padding: var(--spacing-4) 0;
`;
