import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import { withLocalizationProvider } from '@features/localization';
import { connectViewData, withViewDataProvider } from '@util/page-view-context';
import compose from 'lodash/fp/compose';
import React from 'react';

import MagicLinksComponent from './magic-links-component';

const MagicLinksContainer = props => {
  return <MagicLinksComponent {...props} />;
};

const mapViewDataToProps = ({
  enableSingleInvites,
  event,
  featureFlagInvites,
}) => ({
  enableSingleInvites,
  eventSlug: event.slug,
  featureFlagInvites,
});

export default compose(
  withThemeProvider,
  withAlertsProvider,
  withViewDataProvider,
  withLocalizationProvider,
  connectViewData(mapViewDataToProps),
)(MagicLinksContainer);
