import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { Toggle } from '@hopin-team/ui-toggle';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import { Wrapper } from './publish-controls.styled';

const PublishControls = ({ recording, onChange }) => {
  const { t } = useContext(LocalizationContext);

  const handleOnChange = useCallback(() => {
    onChange({ published: !recording.published });
  }, [recording.published, onChange]);

  return (
    <Wrapper flexDirection="column" data-testid="publish-controls">
      <Flex
        flexDirection="column"
        p="3"
        pr="12"
        background="grey-100"
        cornerRadius="lg"
      >
        <Text pattern="headingFour" mb="1" element="h3">
          {t('recording-library.publish-to-attendees')}
        </Text>
        <Text pattern="body" mb="2" color="grey-600" element="p">
          {t('recording-library.if-replay-enabled')}
        </Text>
        <Toggle
          sizeOption="large"
          label={t('recording-library.published')}
          ml={-1}
          isToggled={recording.published}
          onChange={handleOnChange}
        />
      </Flex>
      {recording.published && recording.isProcessing ? (
        <Flex
          mt="2"
          px="2"
          pt="1"
          pb="1.5"
          background="yellow-100"
          cornerRadius="lg"
          flexDirection="column"
        >
          <Text color="yellow-600" pattern="body" element="p">
            {t('recording-library.attendees-see-original')}
          </Text>
          <Text color="yellow-600" pattern="body" element="p">
            {t('recording-library.changes-published-once-processed')}
          </Text>
        </Flex>
      ) : null}
    </Wrapper>
  );
};

PublishControls.propTypes = {
  recording: PropTypes.shape({
    published: PropTypes.bool,
    isProcessing: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
};

export default withLocalizationProvider(PublishControls);
