import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { calcSpace } from '@hopin-team/ui-symbols';
import { TabHeader as UITabHeader, Tabs as UITabs } from '@hopin-team/ui-tabs';
import styled from 'styled-components';

import {
  AttendeeCustomAnswerForm,
  AttendeeRegistrationForm,
} from './attendee-details-forms';

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`;

export const Header = styled(Flex)`
  justify-content: space-between;
`;

export const Body = styled(Box)`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
`;

export const Tabs = styled(UITabs)`
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const TabHeader = styled(UITabHeader)`
  padding: 0;
`;

export const TabTitle = styled(Box)`
  padding-left: ${calcSpace(3)};
  padding-right: ${calcSpace(3)};
`;

export const RegistrationForm = styled(AttendeeRegistrationForm)`
  padding-bottom: ${calcSpace(3)};
  padding-top: ${calcSpace(3)};

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${calcSpace(3)};
`;

export const CustomAnswerForm = styled(AttendeeCustomAnswerForm)`
  padding-bottom: ${calcSpace(3)};
  padding-top: ${calcSpace(3)};

  > div {
    display: grid;
    column-gap: ${calcSpace(3)};
    grid-template-areas: 'label input' '. messages';
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: ${calcSpace(3)};

    > :first-child {
      grid-area: label;
    }

    > :nth-child(2) {
      grid-area: input;
    }

    > :nth-child(n + 3) {
      grid-area: messages;
    }
  }
`;

export const Footer = styled(Flex)`
  gap: 1rem;
`;
