import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Flex } from '@hopin-team/ui-flex';
import { calcSpace } from '@hopin-team/ui-symbols';
import {
  Table,
  TableCell,
  TableHeading,
  TableRow,
  TableRowAction,
  TableRowActions,
} from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import { VisuallyHidden } from '@hopin-team/ui-visually-hidden';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useModal } from '../../../components/modal/modal-context';
import { COLOR_MAP } from '../invites-status/constants';
import { Dot } from '../invites-status/dot';
import EditMagiclinkForm from './edit-form/edit-magic-link-form';

export const getInvitationStatusProps = (
  isRegistered,
  isInvited,
  isDeclined,
  t,
  renameInvitedToPending,
) => {
  if (isDeclined) {
    return { text: t('status-props.declined'), color: COLOR_MAP.declined };
  }
  if (isRegistered) {
    return { text: t('status-props.registered'), color: COLOR_MAP.registered };
  }
  if (isInvited) {
    const text = renameInvitedToPending
      ? t('status-props.pending')
      : t('status-props.invited');
    return { text, color: COLOR_MAP.invited };
  }
  return {
    text: t('status-props.not-yet-invited'),
    color: COLOR_MAP.notYetInvited,
  };
};

export const headers = [
  'email',
  'first-name',
  'last-name',
  'headline',
  'ticket-type',
  'invitation-status',
  '',
];

const StyledTableHeading = styled(TableHeading)`
  padding: ${calcSpace(2)};
  min-width: ${props => (props.wide ? '220px' : 'none')};
`;

const StyledTableCell = styled(TableCell)`
  padding: 0 ${calcSpace(2)};
  overflow-wrap: break-word;
`;

const StyledTable = styled(Table)`
  margin: ${calcSpace(4)} 0;
  width: 100%;
  border-collapse: collapse;
`;

const MagicLinksTable = ({
  magicLinks,
  eventSlug,
  apiClient,
  selectedIds,
  selectAll,
  handleSelectId,
  handleSelectAll,
  renameInvitedToPending,
}) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites.table');
  const { setContent, setIsModalOpen, setSize } = useModal();

  const handleClick = context => {
    setSize('medium');
    setContent(
      <EditMagiclinkForm
        eventSlug={eventSlug}
        initialValues={context}
        apiClient={apiClient}
      />,
    );
    setIsModalOpen(true);
  };

  const isChecked = id => {
    const isSelected = selectedIds.includes(id);
    return selectAll ? !isSelected : isSelected;
  };

  return (
    <StyledTable isAlternating>
      <thead>
        <TableRow isHeading>
          <StyledTableHeading key="select-all">
            <VisuallyHidden>
              <FieldLabel fieldId="field-select-all" label={t('select')} />
            </VisuallyHidden>
            <Checkbox
              onChange={handleSelectAll}
              checked={selectAll}
              value={selectAll && !!selectedIds.length}
              id="select-all"
            />
          </StyledTableHeading>
          {headers.map(header => (
            <StyledTableHeading
              wide={header === 'email'}
              data-testid={`magic-link-header-${header}`}
              key={header}
            >
              {header ? t(header) : ''}
            </StyledTableHeading>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {magicLinks.map(
          ({
            email,
            firstName,
            lastName,
            headline,
            persona,
            isRegistered,
            isInvited,
            id,
            codeDeclinedAt,
          }) => {
            const handClickEdit = () =>
              handleClick({
                email,
                firstName,
                lastName,
                headline,
                id,
              });

            const invitationStatusProps = getInvitationStatusProps(
              isRegistered,
              isInvited,
              codeDeclinedAt,
              t,
              renameInvitedToPending,
            );

            return (
              <TableRow key={id} className="redeem-row">
                <StyledTableCell>
                  <VisuallyHidden>
                    <FieldLabel fieldId={`field-${id}`} label={t('select')} />
                  </VisuallyHidden>
                  <Checkbox
                    onChange={() => handleSelectId(id)}
                    checked={isChecked(id)}
                    id={id}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-600">
                    {email}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-600">
                    {firstName}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-600">
                    {lastName}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-600">
                    {headline}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text pattern="body" color="grey-600">
                    {persona.label}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Flex alignItems="center">
                    <Box pr={1}>
                      <Dot
                        data-testid="magic-link-status-dot"
                        color={invitationStatusProps.color}
                      />
                    </Box>
                    <span>{invitationStatusProps.text}</span>
                  </Flex>
                </StyledTableCell>
                <TableRowActions>
                  {isRegistered || (
                    <TableRowAction
                      icon="edit"
                      title={t('edit')}
                      onClick={handClickEdit}
                    />
                  )}
                </TableRowActions>
              </TableRow>
            );
          },
        )}
      </tbody>
    </StyledTable>
  );
};

MagicLinksTable.propTypes = {
  eventSlug: string.isRequired,
  apiClient: object.isRequired,
  magicLinks: arrayOf(
    shape({
      email: string,
      firstName: string,
      headline: string,
      id: number,
      isInvited: bool,
      isRegistered: bool,
      lastName: string,
      persona: shape({
        id: number.isRequired,
      }),
      sentEmailCount: number,
    }),
  ),
  selectedIds: arrayOf(number),
  selectAll: bool,
  handleSelectId: func,
  handleSelectAll: func,
  renameInvitedToPending: bool,
};

export default withLocalizationProvider(MagicLinksTable);
