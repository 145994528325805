import { Flex } from '@hopin-team/ui-flex';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { rem } from 'polished';
import styled from 'styled-components';

const getBorder = ({ theme, matched }) =>
  getFromTheme(matched ? 'COLOR.BLUE["200"]' : 'COLOR.ORANGE["200"]', theme);
const getBackground = ({ theme, matched }) =>
  getFromTheme(matched ? 'COLOR.BLUE["100"]' : 'COLOR.ORANGE["100"]', theme);
const getColor = ({ theme, matched }) =>
  getFromTheme(matched ? 'COLOR.BLUE["500"]' : 'COLOR.ORANGE["600"]', theme);

export const MatchContainer = styled(Flex)`
  border-radius: ${({ theme }) => getFromTheme('LINE.RADIUS_DEFAULT', theme)};
  border: 1px solid ${getBorder};
  background-color: white;
  margin: 0 ${rem(8)};
  max-width: 33%;
  min-height: ${rem(280)};
  text-align: left;
`;

export const Top = styled(Flex)`
  padding: ${rem(4)};
  background-color: ${getBackground};
  color: ${getColor};
`;

export const List = styled.ul`
  margin: 0;
  border-top: 1px solid ${getBorder};
`;

export const ListItem = styled.li`
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${rem(8)} ${rem(16)};
  list-style: none;
  border-bottom: 1px solid ${getBorder};
  color: ${({ theme }) => getFromTheme('COLOR.GRAY["600"]', theme)};
`;

export const Stretch = styled(Flex)`
  & > div {
    flex: 1;
  }
`;
