import analyticsApi from '@api/analytics';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import { oneOf, string } from 'prop-types';
import React, { useState } from 'react';

import TimeSpent from './time-spent';
import TopMetrics from './top-metrics-component';
import transformResults from './util/transform-results';

const TopMetricsContainer = ({ eventPhase, eventSlug, eventExternalId }) => {
  const { t } = useLocalization('overview.top-metrics');
  const getPromises = (eventSlug, signal, eventExternalId) => {
    return [
      analyticsApi.getAverageAttendeeRating(eventSlug, signal),
      analyticsApi.getTurnout(eventSlug, signal),
      analyticsApi.getAverageMinutes(eventSlug, signal),
      analyticsApi.getLiveAnalyticsChartData(eventSlug, {}, signal),
      analyticsApi.getAttendeesByTicketType(eventExternalId, signal),
    ];
  };

  const [averageAttendeeRating, setAverageAttendeeRating] = useState('');
  const [turnout, setTurnout] = useState('');
  const [minutesSpent, setMinutesSpent] = useState(0);
  const [peakAttendance, setPeakAttendance] = useState(0);
  const [attendees, setAttendees] = useState(0);

  const loading = usePromise(
    signal => getPromises(eventSlug, signal, eventExternalId),
    results => {
      const [
        attendeeRatingData,
        turnout,
        minutesSpent,
        peakAttendance,
        attendees,
      ] = transformResults(results);

      setAverageAttendeeRating(attendeeRatingData);
      setTurnout(turnout);
      setMinutesSpent(minutesSpent);
      setPeakAttendance(peakAttendance);
      setAttendees(attendees);
    },
  );

  const data = [
    {
      description: t('attendees'),
      displayValue: attendees,
      iconName: 'attending',
      testId: 'attendees',
      tooltipText: t('attendees-tooltip'),
    },
    {
      description: t('attendees-score'),
      displayValue: averageAttendeeRating,
      iconName: 'star',
      testId: 'average-rating',
      tooltipText:
        eventPhase === 'Ended'
          ? t('attendees-score-post-event-tooltip')
          : t('attendees-score-live-event-tooltip'),
    },
    {
      description: t('average-time-spent'),
      displayValue: <TimeSpent minutes={minutesSpent} />,
      iconName: 'clock',
      testId: 'time-spent',
      tooltipText: t('average-time-spent-tooltip'),
    },
    {
      description: t('turnout'),
      displayValue: turnout,
      iconName: 'sessions',
      testId: 'turnout',
      tooltipText: t('turnout-tooltip'),
    },
    {
      description: t('peak-attendance'),
      displayValue: peakAttendance,
      iconName: 'chart-line-up',
      testId: 'max-attendance',
      tooltipText: t('peak-attendance-tooltip'),
    },
  ];

  return <TopMetrics data={data} isLoading={loading} />;
};

TopMetricsContainer.propTypes = {
  eventPhase: oneOf(['Ended', 'Live']).isRequired,
  eventSlug: string.isRequired,
  eventExternalId: string.isRequired,
};

export default TopMetricsContainer;
