import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import Download from 'icons/download.svg';
import EnvelopeSolid from 'icons/envelope-solid.svg';
// import Envelope from 'icons/envelope.svg';
import RemoveUser from 'icons/remove-user.svg';
import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ActionButton from './action-button';

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Operations = ({
  // preview,
  send,
  download,
  cancel,
  selectedFilters,
}) => {
  const { t } = useLocalization();

  return (
    <ActionsContainer data-testid="magic-invites-operations">
      {/* TO DO: add preview button (ESP-332)
      <ActionButton
        onClick={preview}
        icon={<Envelope fill="var(--color-gray-600)" />}
      >
        {t('magicLinks.manage.preview-button')}
      </ActionButton> */}
      <ActionButton
        disabled={selectedFilters.some(filter => filter.query === 'declined')}
        onClick={send}
        icon={<EnvelopeSolid />}
      >
        {t('magicLinks.manage.send-button')}
      </ActionButton>
      <ActionButton onClick={download} icon={<Download />}>
        {t('magicLinks.manage.download-button')}
      </ActionButton>
      <ActionButton
        onClick={cancel}
        icon={<RemoveUser />}
        confirm={t('magicLinks.manage.cancel-confirmation')}
        cancel
      >
        {t('magicLinks.manage.cancel-button')}
      </ActionButton>
    </ActionsContainer>
  );
};

Operations.propTypes = {
  // preview: func,
  send: func,
  download: func,
  cancel: func,
  selectedFilters: arrayOf(shape({ query: string })),
};

export default withLocalizationProvider(Operations);
