import CKEditor5 from '@components/ckeditor5/ckeditor5';
import StepHeader from '@features/event-creation-wizard/components/step-header';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { bool } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setWelcomeMessage } from '../../redux/step-specific-state/welcome-message-slice';

const WelcomeMessageComponent = ({ canSkip }) => {
  const dispatch = useDispatch();
  const storeWelcomeMessage = useSelector(state => state.welcomeMessage.value);
  const [message, setMessage] = React.useState(storeWelcomeMessage);
  const { t } = useLocalization('event-creation-wizard.steps.welcome-message');

  const onSave = () => {
    dispatch(setWelcomeMessage(message));
  };

  const maxFileSize = 10485760;

  return (
    <EventCreationWizardStep canSkip={canSkip} onSave={onSave} info={t('info')}>
      <StepHeader title={t('title')} subtitle={t('subtitle')} />

      <Box mb={3}>
        <CKEditor5
          name="welcome-message"
          initialValue={message}
          uploadS3PolicyUrl={`${window.location.origin}/account/upload_s3_policy`}
          maxFileSize={maxFileSize}
          onChange={(event, editor) => setMessage(editor.getData())}
        />
      </Box>
    </EventCreationWizardStep>
  );
};

WelcomeMessageComponent.propTypes = {
  canSkip: bool,
};

export default WelcomeMessageComponent;
