import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Grid } from '@hopin-team/ui-grid';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { array, object } from 'prop-types';
import React from 'react';

import { Styles } from './organizer-styles';

const Organizer = ({ organization, decoratedOrganization, customisations }) => {
  const customText = customisations || {};
  return (
    <>
      <Box cornerRadius="lg" border="grey-200" p={3}>
        <Styles.HostedBy pattern="labelTwo" mb={2} element="p" color="gray-900">
          {customText['hosted-by'] || 'Hosted by'}
        </Styles.HostedBy>
        <Grid gridTemplateColumns="auto 1fr" gridColumnGap="16px">
          <Styles.Image src={organization?.picture} alt="" />
          <div>
            <Styles.Link
              href={`/explore/organization/${organization?.externalId}`}
            >
              <Text pattern="headingTwo">{organization?.name}</Text>
            </Styles.Link>
            <Flex alignItems="center">
              <Text pattern="labelOne">
                <strong>{decoratedOrganization?.event_count}</strong>{' '}
                {decoratedOrganization?.event_count > 1 ? 'events' : 'event'}
              </Text>
              <Styles.Dot />
              <Text pattern="labelOne">
                <strong>{decoratedOrganization?.registration_count}</strong>{' '}
                {decoratedOrganization?.registration_count > 1
                  ? 'registrations'
                  : 'registration'}
              </Text>
            </Flex>
          </div>
        </Grid>
        <Text pattern="labelOne" element="p" mt={2}>
          {organization?.about}
        </Text>
        <Flex alignItems="center" mt={2}>
          {organization?.website && (
            <a
              href={organization.website}
              target="_blank"
              rel="noreferrer"
              aria-label={`${organization?.name} Website`}
            >
              <Icon
                name="website"
                color="grey-medium"
                title={`${organization?.name} Website`}
                mr={2}
                description={`A link to ${organization?.name}'s Website`}
              />
            </a>
          )}
          {organization?.facebook && (
            <a
              href={organization.facebook}
              target="_blank"
              rel="noreferrer"
              aria-label={`${organization?.name} Facebook`}
            >
              <Icon
                name="facebook"
                color="grey-medium"
                title={`${organization?.name} Facebook`}
                mr={2}
                description={`A link to ${organization?.name}'s Facebook`}
              />
            </a>
          )}
          {organization?.twitter && (
            <a
              href={organization.twitter}
              target="_blank"
              rel="noreferrer"
              aria-label={`${organization?.name} Twitter`}
            >
              <Icon
                name="twitter"
                color="grey-medium"
                title={`${organization?.name} Twitter`}
                mr={2}
                description={`A link to ${organization?.name}'s Twitter`}
              />
            </a>
          )}
          <a
            href={`mailto:${organization?.email}`}
            target="_blank"
            rel="noreferrer"
            aria-label={`${organization?.name} Email`}
          >
            <Icon
              name="email"
              color="grey-medium"
              title={`${organization?.name} Email`}
              mr={2}
              description={`Contact ${organization?.name} via email`}
            />
          </a>
        </Flex>
      </Box>
    </>
  );
};

Organizer.propTypes = {
  organization: object,
  decoratedOrganization: object,
  categories: array,
  tags: array,
  customisations: object,
};

export default Organizer;
