import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import React from 'react';

import { Section } from '../../section';

const UploadCSV = () => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');

  return (
    <Section title={t('upload-csv-header')}>
      <Text element="p" pattern="labelThree" color="grey-600" mb={2}>
        {t('upload-csv-summary')}{' '}
        <span role="img" aria-label={t('hand-pointing-up')}>
          ☝️
        </span>
      </Text>
      <button data-testid="bulk-invite-button" type="submit" className="button">
        {t('upload-csv-button')}
      </button>
    </Section>
  );
};

export default UploadCSV;
