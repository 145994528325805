import hopinApi from '@api/hopin';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

const initialState = {
  admins: [],
  attendeesVisiblity: null,
  currency: '',
  doorsOpenTime: null,
  doorsCloseTime: null,
  draft: true,
  errors: {},
  eventId: '',
  eventType: '',
  extra: {},
  hasStarted: true,
  hostAbout: null,
  hostName: null,
  hostFacebook: null,
  hostTwitter: null,
  hostWebsite: null,
  id: null,
  hasTickets: false,
  maxPrice: 0,
  started: false,
  finished: false,
  name: '',
  organization: {},
  password: '',
  picture: '',
  requireSsoLogin: false,
  shortDescription: null,
  slug: '',
  suppressEmails: false,
  timeEndLocal: '',
  timeStartLocal: '',
  timezone: '',
  validForDeletion: false,
};

export const publishEvent = createAsyncThunk(
  'event/publishStatus',
  async eventSlug => {
    const result = await hopinApi.publishEvent(eventSlug);
    return result;
  },
);

const { actions, reducer } = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, action) => ({ ...state, ...action.payload }),
    setSlug: (state, action) => {
      state.slug = action.payload;
    },
    toggleIsDraft: state => ({ ...state, draft: !state.draft }),
  },
  extraReducers: {
    [publishEvent.pending]: state => {
      state.isPublishing = true;
    },
    [publishEvent.fulfilled]: (state, action) => {
      state.isPublishing = false;
      if (action.payload.flash) {
        const [type] = Object.keys(action.payload.flash);
        const isSuccess = type === 'notice';
        if (isSuccess) {
          state.draft = !state.draft;
        }
      }
    },
  },
});

export const getOrganization = ({ event }) => event.organization.id;
export const getExternalEventId = createSelector(
  state => state.event,
  event => event.externalId,
);

export const { setEvent, setIsDraft, setSlug, toggleIsDraft } = actions;

export default reducer;
