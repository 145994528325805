import { timezonesMap } from '@features/constants/timezones-map';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';

const convertChartDataToTimezone = (data, timezone) => {
  data = data || [];
  if (!Array.isArray(data)) {
    data = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]));
  }
  return data.map(([timestamp, value]) => {
    const timezonedDate = utcToZonedTime(
      timestamp,
      timezonesMap[timezone] || timezone,
    );
    const formatted = formatDate(timezonedDate, 'yyyy-MM-dd HH:mm:ss');

    return [formatted, value];
  });
};

export default convertChartDataToTimezone;
