import { LocalizationContext } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Flex } from '@hopin-team/ui-flex';
import { NextButton, Pagination, PrevButton } from '@hopin-team/ui-pagination';
import { Table, TableCell, TableHeading, TableRow } from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import { array, string } from 'prop-types';
import React, { useContext } from 'react';

import {
  eventTracksIconAccessibilityProps,
  ticketTypesIconAccessibilityProps,
} from './constants';
import { useList } from './list-component';
import {
  ButtonPlaceholder,
  Container,
  FilterSelect,
  PaginationNumbers,
  SearchField,
} from './styles';

const WaitlistListComponent = ({ listUrl, ticketTypes, eventTracks }) => {
  const { t } = useContext(LocalizationContext);
  const {
    onPageChange,
    onQueryChange,
    onTicketTypeChange,
    onEventTrackChange,
    searchQuery,
    requestStatus,
    hasActiveFilters,
    totalPages,
    currentPage,
    attendeesCount,
    attendees,
    ticketType,
    eventTrack,
  } = useList(eventTracks, listUrl, 'waitlist');

  if (
    !hasActiveFilters &&
    attendeesCount === 0 &&
    requestStatus === 'COMPLETED'
  ) {
    return (
      <Flex mx="auto" py={6} flexDirection="column" alignItems="center">
        <Box mb={9}>
          <img src="/images/attendees.png" alt="" width="288" height="207" />
        </Box>
        <Text pattern="headingTwo">
          {t('people.attendees.waitlist-tab.list.empty.header')}
        </Text>
        <Text pattern="body" element="p" color="grey-700" mt={2} mb={4}>
          {t('people.attendees.waitlist-tab.list.empty.description')}
        </Text>
      </Flex>
    );
  }

  return (
    <Container py={4}>
      <h2>
        {t('people.attendees.waitlist-tab.list.header', {
          count: attendeesCount,
        })}
      </h2>

      <div className="row">
        <div className="col-md-3 form-group" data-testid="EventSelect">
          <FilterSelect
            placeholder={t(
              'people.attendees.waitlist-tab.list.filter.event-track',
            )}
            isClearable={true}
            iconAccessibilityProps={eventTracksIconAccessibilityProps(t)}
            value={eventTrack}
            onChange={onEventTrackChange}
            options={eventTracks}
          />
        </div>

        <div className="col-md-3 form-group" data-testid="TicketSelect">
          <FilterSelect
            placeholder={t(
              'people.attendees.waitlist-tab.list.filter.ticket-type',
            )}
            isClearable={true}
            iconAccessibilityProps={ticketTypesIconAccessibilityProps(t)}
            value={ticketType}
            onChange={onTicketTypeChange}
            options={ticketTypes}
          />
        </div>

        <div className="col-md-3">
          <FieldLabel fieldId="Search" className="sr-only" />
          <SearchField
            id="search"
            label={t('people.attendees.waitlist-tab.list.filter.search')}
            placeholder={t('people.attendees.waitlist-tab.list.filter.search')}
            type="text"
            value={searchQuery}
            onChange={onQueryChange}
          />
        </div>
      </div>

      <Table className="table" mt={4}>
        <thead>
          <TableRow>
            <TableHeading>
              {t('people.attendees.waitlist-tab.list.columns.name')}
            </TableHeading>
            <TableHeading>
              {t('people.attendees.waitlist-tab.list.columns.email')}
            </TableHeading>
            <TableHeading>
              {t('people.attendees.waitlist-tab.list.columns.event-track')}
            </TableHeading>
            <TableHeading>
              {t('people.attendees.waitlist-tab.list.columns.ticket')}
            </TableHeading>
            <TableHeading>
              {t('people.attendees.waitlist-tab.list.columns.waitlist-date')}
              <br />
              <Text pattern="labelTwo">
                (
                {t(
                  'people.attendees.waitlist-tab.list.columns.attempted-registration',
                )}
                )
              </Text>
            </TableHeading>
            <TableHeading>
              {t('people.attendees.waitlist-tab.list.columns.actions')}
            </TableHeading>
          </TableRow>
        </thead>
        <tbody>
          {attendees.map(attendee => (
            <TableRow key={attendee.id} hasActions={true}>
              <TableCell>
                <Text weight="bold" font="display">
                  {attendee.name}
                </Text>
              </TableCell>
              <TableCell>{attendee.email}</TableCell>
              <TableCell>{attendee.event_track}</TableCell>
              <TableCell>{attendee.ticket_type}</TableCell>
              <TableCell>
                {attendee.created_at_date}
                <br />
                {attendee.created_at_time}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        onChange={onPageChange}
        totalPages={totalPages}
        nextLabel={t('pagination.next')}
        pageLabel={t('pagination.goto-page')}
        prevLabel={t('pagination.previous')}
        titleLabel={t('pagination.title')}
        currentLabel={t('pagination.current')}
      >
        {currentPage > 1 ? <PrevButton mr={1} /> : <ButtonPlaceholder />}
        <PaginationNumbers />
        {currentPage < totalPages ? (
          <NextButton ml={1} />
        ) : (
          <ButtonPlaceholder />
        )}
      </Pagination>
    </Container>
  );
};

WaitlistListComponent.propTypes = {
  listUrl: string.isRequired,
  ticketTypes: array.isRequired,
  eventTracks: array.isRequired,
};

export default WaitlistListComponent;
