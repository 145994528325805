import Loading from '@components/loading';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { TYPE } from '../category-types';
import { ProfileCategoriesForm } from './profile-categories-form';

export const ProfileCategoriesFormContainer = ({
  eventExternalId,
  setIsModalDismissable,
  setSavedChanges,
  isLoading,
  lockProfileMatching,
  categoriesData,
  setIsOpen,
}) => {
  const { addAlert, alerts } = useContext(AlertsContext);
  const { t } = useLocalization();

  useEffect(() => {
    setIsModalDismissable(alerts.length === 0);
  }, [alerts.length, setIsModalDismissable]); // Prevent modal from closing when dismissing alerts

  return (
    <Loading isLoading={isLoading}>
      {!isLoading && categoriesData && (
        <ProfileCategoriesForm
          eventExternalId={eventExternalId}
          setIsModalDismissable={setIsModalDismissable}
          categoriesData={categoriesData}
          addAlert={addAlert}
          setSavedChanges={setSavedChanges}
          t={t}
          lockProfileMatching={lockProfileMatching}
          setIsOpen={setIsOpen}
        />
      )}
    </Loading>
  );
};

ProfileCategoriesFormContainer.propTypes = {
  eventExternalId: PropTypes.string,
  setIsModalDismissable: PropTypes.func,
  setSavedChanges: PropTypes.func,
  setIsOpen: PropTypes.func,
  isLoading: PropTypes.bool,
  lockProfileMatching: PropTypes.bool,
  categoriesData: PropTypes.arrayOf(
    PropTypes.shape({
      reactHookFormId: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
      name: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
          .isRequired,
      ),
    }),
  ),
};

export default withAlertsProvider(ProfileCategoriesFormContainer);
