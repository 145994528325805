import MountSlice, { LATEST } from '@components/slices/MountSlice';
import { createReactiveMap } from '@hopin-team/onsite-shared';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React from 'react';

import OnsiteAddOnEmptyState from './onsite-add-on-empty-state';

const logger = getLogger(LOGGER_NAMES.ONSITE);

const OnsiteSignatures = ({ onsiteAdvancedAddOnEnabled }) => {
  const PACKAGE_NAME = '@hopin-team/hopin-web-slices-onsite-signatures';

  const reactiveMap = createReactiveMap({
    clientPackageDetails: `${PACKAGE_NAME}/${LATEST}`,
    onAsyncError: error => logger.error(error),
  });

  if (onsiteAdvancedAddOnEnabled) {
    return (
      <MountSlice
        packageName={PACKAGE_NAME}
        version={LATEST}
        reactiveMap={reactiveMap}
      />
    );
  }
  return <OnsiteAddOnEmptyState pageType="signatures" />;
};

OnsiteSignatures.propTypes = {
  sliceVersion: string,
  onsiteAdvancedAddOnEnabled: bool,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.ONSITE,
    errorPattern: 'page',
  }),
)(OnsiteSignatures);
