import { rem } from 'polished';
import { func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ChallengeFooter from './challenge-footer';
import ChallengeFooterContext from './challenge-footer-context';
import ChallengeForm from './challenge-form';
import ChallengeFormContext from './challenge-form-context';
import ChallengeHeader from './challenge-header';

const Container = styled.div`
  max-width: ${rem(392)};
  margin: auto;
`;

const ChallengeModal = ({
  addAlert,
  email,
  image,
  t,
  oauthBrand,
  onConfirmation,
}) => {
  const tPrefix = oauthBrand ? '.oauth-branding-form' : '.default';

  const tt = (key, args) => t(`${tPrefix}${key}`, args);

  const challengeHeaderContext = {
    oauthBrand,
    image,
    email,
    t: tt,
  };

  const challengeFormContext = ChallengeFormContext({
    t: tt,
    addAlert,
    onSuccess: onConfirmation,
  });

  const challengeFooterContext = ChallengeFooterContext({
    t: tt,
    addAlert,
  });

  return (
    <Container data-testid="challenge-modal">
      <ChallengeHeader {...challengeHeaderContext} />
      <ChallengeForm {...challengeFormContext} />
      <ChallengeFooter {...challengeFooterContext} />
    </Container>
  );
};

ChallengeModal.propTypes = {
  addAlert: func.isRequired,
  email: string,
  image: node.isRequired,
  onConfirmation: func.isRequired,
  t: func.isRequired,
  oauthBrand: string,
};

export default ChallengeModal;
