import PillSelector from '@features/event-creation-wizard/components/pill-selector';
import StepHeader from '@features/event-creation-wizard/components/step-header';
import { setPurpose } from '@features/event-creation-wizard/redux/step-specific-state/purpose-slice';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import { bool } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { eventPurposeOptions } from './constants';

const EventPurposeComponent = ({ canSkip }) => {
  const dispatch = useDispatch();
  const { t } = useLocalization('event-creation-wizard.steps.purpose');

  const { purpose } = useSelector(({ purpose }) => ({
    purpose,
  }));
  const [selectedValue, setSelectedValue] = useState(purpose);
  const onSave = () => dispatch(setPurpose(selectedValue));

  return (
    <EventCreationWizardStep
      canSkip={canSkip}
      onSave={onSave}
      continueDisabled={!selectedValue}
    >
      <StepHeader title={t('title')} subtitle={t('subtitle')} />
      <PillSelector
        selectedItem={selectedValue}
        options={eventPurposeOptions.map(option => ({
          label: t(`form.options.${option.value}`),
          ...option,
        }))}
        onChange={purpose => setSelectedValue(purpose)}
        label={t('form.label')}
      />
    </EventCreationWizardStep>
  );
};

EventPurposeComponent.propTypes = {
  canSkip: bool,
};

export default EventPurposeComponent;
