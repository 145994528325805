import { FORM, INTERACTIVE } from '@hopin-team/ui-blocks';
import { COLOR } from '@hopin-team/ui-symbols';
import { Input } from '@hopin-team/ui-text-field/dist/styled/input';
import styled, { css } from 'styled-components';

export const StyledInputWrapper = styled(Input).attrs({ as: 'label' })`
  display: flex;
  align-items: center;
  margin: 0;

  &:focus-within {
    ${INTERACTIVE.focus}
  }

  ${({ hasErrors }) =>
    hasErrors
      ? css`
          ${FORM.hasErrors}
        `
      : ''}
`;

export const StyledInput = styled.input`
  border: 0;
  ${({ leadingText }) =>
    leadingText &&
    css`
      width: 100%;
    `}

  &:focus {
    outline: 0;
  }
`;

export const StaticText = styled.span`
  color: ${COLOR.GREY['600']};
  user-select: none;
  white-space: nowrap;
`;
