import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Header = styled.header`
  /* Allow the body to take all available area */
  flex-grow: 0;
  padding: var(--spacing-24);
  border-bottom: 1px solid var(--color-gray-200);
`;

export const Body = styled.div`
  flex-grow: 1;
  padding: 0 var(--spacing-24);
  overflow-y: auto;
`;

export const Footer = styled.div`
  /* Allow the body to take all available area */
  flex-grow: 0;
  padding: var(--spacing-16) var(--spacing-24);
  background: var(--color-white);
  border-top: 1px solid var(--color-gray-200);
`;
