import '@hopin-team/ckeditor5-build-hopin/dist/styles.css';

import RenderRichText from '@hopin-team/ckeditor5-build-hopin/dist/render-rich-text';
import PropTypes from 'prop-types';
import React from 'react';

const IFRAMELY_API_KEY_HASH = process.env.IFRAMELY_API_KEY_HASH;

function Render({ value }) {
  return (
    <RenderRichText value={value} iframelyApiKeyHash={IFRAMELY_API_KEY_HASH} />
  );
}

Render.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Render;
