import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import MenuComponent from './menu-component';

const MenuContainer = props => {
  const menuElem = useRef();
  const [menuPosition, setMenuPosition] = useState();
  const [closing, setClosing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedAt, setExpandedAt] = useState(0);

  const handleButtonClick = () => {
    if (menuElem.current && !expanded) {
      setMenuPosition(menuElem.current.getBoundingClientRect());
    }
    if (expanded) {
      setClosing(true);
    }
    if (!expanded) setExpandedAt(props.usedAt);
    setExpanded(currentExpanded => !currentExpanded);
  };

  if (expanded && props.usedAt > expandedAt) {
    setClosing(true);
    setExpanded(false);
  }

  const handleOutsideClick = () => setExpanded(false);

  useEffect(() => {
    const menu = menuElem.current;
    const handleTransitionEnd = () => {
      setClosing(false);
    };
    menu.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      menu.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [expanded]);

  return (
    <MenuComponent
      closing={closing}
      expanded={expanded}
      menuPosition={menuPosition}
      ref={menuElem}
      onButtonClick={handleButtonClick}
      onOutsideClick={handleOutsideClick}
      {...props}
    />
  );
};

MenuContainer.propTypes = {
  usedAt: PropTypes.number,
};

export default MenuContainer;
