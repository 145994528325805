import SaveBar from '@components/save-bar';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { bool, oneOf } from 'prop-types';
import React, { useState } from 'react';

import { venueTypeInputName } from '../event-venue-type';

const SubmitButtonEventDetails = ({
  initialVenueType,
  hasOnsiteOnlySegment,
}) => {
  const { t } = useLocalization();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  const submitEventDetailsForm = () =>
    document.querySelector('.edit_event').submit();

  const onClick = e => {
    e.preventDefault();
    const hybridInput = document.querySelector(
      `input[name="${venueTypeInputName}"]`,
    );

    if (
      initialVenueType !== 'virtual' &&
      hybridInput?.value === 'virtual' &&
      hasOnsiteOnlySegment
    ) {
      setIsDialogOpen(true);
    } else {
      submitEventDetailsForm();
    }
  };

  return (
    <>
      <ConfirmationDialog
        title={t('basic-settings.confirmation-dialog.title')}
        message={t('basic-settings.confirmation-dialog.message')}
        primaryButtonText={t(
          'basic-settings.confirmation-dialog.primaryButton',
        )}
        secondaryButtonText={t(
          'basic-settings.confirmation-dialog.secondaryButton',
        )}
        colorPattern="primary"
        onCancel={closeDialog}
        onClose={closeDialog}
        onConfirm={() => {
          submitEventDetailsForm();
          closeDialog();
        }}
        isShowing={isDialogOpen}
        withCloseButton
      />
      <SaveBar onButtonClick={onClick} />
    </>
  );
};

SubmitButtonEventDetails.propTypes = {
  initialVenueType: oneOf(['virtual', 'hybrid', 'onsite']),
  hasOnsiteOnlySegment: bool.isRequired,
};

export default withLocalizationProvider(SubmitButtonEventDetails);
