import analyticsApi from '@api/analytics';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import React, { useCallback, useContext } from 'react';

import { ActionButton } from './reports-notification.styles';

function useAlertingReportNotification(eventId) {
  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization('reports.alert');

  const handleRegenerateReport = useCallback(
    ({ id }) => {
      analyticsApi.regenerateReport(
        eventId,
        {
          report_id: id,
        },
        {
          initiated_from: 'notification_regenerate',
        },
      );
    },
    [eventId],
  );

  const handleAddAlert = useCallback(
    report => {
      switch (report.status) {
        case 'failed':
          addAlert({
            active: true,
            pattern: 'error',
            timeout: 10000,
            text: (
              <span>
                <strong>{report.report_name}</strong> {t('failed')}{' '}
                <ActionButton onClick={() => handleRegenerateReport(report)}>
                  {t('click-here')} {t('retry')}
                </ActionButton>
              </span>
            ),
          });
          break;
        case 'running':
          addAlert({
            active: true,
            pattern: 'success',
            timeout: 2000,
            text: (
              <span>
                <strong>{report.report_name}</strong> {t('slow')}
              </span>
            ),
          });
          break;
        case 'started':
          addAlert({
            active: true,
            pattern: 'success',
            timeout: 2000,
            text: (
              <span>
                <strong>{report.report_name}</strong> {t('started')}
              </span>
            ),
          });
          break;
        case 'succeeded':
          addAlert({
            active: true,
            pattern: 'success',
            timeout: 25000,
            text: (
              <span>
                <strong>{report.report_name}</strong> {t('succeeded')}{' '}
                <ActionButton
                  onClick={() =>
                    (location.href = `reports/${report.external_id}/download?initiated_from=notification`)
                  }
                >
                  {t('click-here')} {t('download')}
                </ActionButton>
              </span>
            ),
          });
          break;
      }
    },
    [addAlert, handleRegenerateReport, t],
  );

  return useCallback(
    report => {
      handleAddAlert(report);
    },
    [handleAddAlert],
  );
}

export { useAlertingReportNotification };
