import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { object } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

const MyAgenda = ({ control }) => {
  const { t } = useLocalization('venue-controls.my-agenda');

  const {
    field: { onChange, value, name },
  } = useController({
    name: 'venue_settings[my_agenda]',
    control,
  });

  return (
    <Box mb={3}>
      <Text
        element="h3"
        pattern="headingThree"
        color="grey-800"
        weight="medium"
        scale={4}
        mb={1}
      >
        {t('title-my-agenda-redesign')}
      </Text>
      <Text scale={4} pattern="body" element="p" color="grey-600">
        {t('heading-my-agenda-redesign')}
      </Text>

      <Flex alignItems="center">
        <Checkbox
          name={name}
          data-testid="my-agenda-checkbox"
          id="venue_settings_my_agenda"
          onChange={event => onChange(event.target.checked)}
          checked={!!value}
          value={value}
          label={t('enable-agenda')}
        />
      </Flex>
    </Box>
  );
};

MyAgenda.propTypes = {
  control: object.isRequired,
};

export default withLocalizationProvider(MyAgenda);
