import getDisplayName from '@util/get-display-name';
import { node } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setRegistration } from './registration-slice';

const RegistrationProvider = ({ children }) => {
  const { registration } = children.props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (registration) {
      const registrationValue = {
        externalId: registration.external_id,
      };

      dispatch(setRegistration(registrationValue));
    }
  }, [registration, dispatch]);

  return children;
};

RegistrationProvider.propTypes = {
  children: node,
};

export const withRegistrationProvider = WrappedComponent => {
  const WithRootPropsProvider = props => {
    return (
      <RegistrationProvider>
        <WrappedComponent {...props} />
      </RegistrationProvider>
    );
  };

  WithRootPropsProvider.displayName = `WithRootPropsProvider(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithRootPropsProvider;
};

export default RegistrationProvider;
