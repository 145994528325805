import EmptyStateComponent from '@features/dashboard/components/empty-state/empty-state';
import styled from 'styled-components';

const emptyStateVerticalMargin = '1rem';

const EmptyState = styled(EmptyStateComponent)`
  margin: ${emptyStateVerticalMargin} 0;
  height: calc(100% - ${emptyStateVerticalMargin});
`;

export default EmptyState;
