import { Flex } from '@hopin-team/ui-flex';
import { withSpace } from '@hopin-team/ui-helper-with-space';
import { Text } from '@hopin-team/ui-text';
import { number, object, oneOfType, string } from 'prop-types';
import React from 'react';

const SpanWithSpacing = withSpace('span');

const BillingItem = ({ icon, label, content }) => {
  return (
    <Flex justifyContent="space-around" alignItems="center">
      <SpanWithSpacing pr={2}>{icon}</SpanWithSpacing>

      <div>
        <Text element="span" color="grey-800" pattern="bodyOne" scale={4}>
          {content}
        </Text>
        <Text element="p" color="grey-700" pattern="labelTwo">
          {label}
        </Text>
      </div>
    </Flex>
  );
};

BillingItem.propTypes = {
  icon: object.isRequired,
  label: string.isRequired,
  content: oneOfType([string, number]).isRequired,
};

export default BillingItem;
