import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { bool, object, shape, string } from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import QuestionVisibilityButton from './question-visibility-button';
import Rating from './rating';
import {
  QuestionCard,
  questionCardStyles,
  QuestionWrapper,
  StyledTextField,
} from './styles';
import SurveyFieldWrapper from './survey-field-wrapper';

const RatingQuestion = ({
  fieldName,
  control,
  canEditSurvey,
  error,
  isLastEnabledQuestion,
}) => {
  const { t } = useLocalization();

  return (
    <QuestionCard {...questionCardStyles} data-testid="rating-question">
      <Flex flex={2} flexDirection="column">
        <QuestionWrapper>
          <Controller
            name={`${fieldName}.question`}
            control={control}
            rules={{ required: t('survey-builder.edit.field-required') }}
            render={({ onChange, value, ref }) => (
              <SurveyFieldWrapper canEditSurvey={canEditSurvey}>
                <StyledTextField
                  label={t('survey-builder.edit.nps-question')}
                  name={fieldName}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  currentCount={value.length}
                  maxCount={200}
                  mb={3}
                  leadingIcon="chart-bar-two-tone"
                  background="var(--color-green-500)"
                  disabled={!canEditSurvey}
                  hasErrors={!!error}
                  errorMessage={error?.question.message}
                />
              </SurveyFieldWrapper>
            )}
          />
        </QuestionWrapper>
        <Box ml={4.5}>
          <Rating />
        </Box>
      </Flex>
      <StyledBox>
        <QuestionVisibilityButton
          control={control}
          fieldName={fieldName}
          canEditSurvey={canEditSurvey}
          isLastEnabledQuestion={isLastEnabledQuestion}
        />
      </StyledBox>
    </QuestionCard>
  );
};

RatingQuestion.propTypes = {
  fieldName: string.isRequired,
  control: object.isRequired,
  canEditSurvey: bool.isRequired,
  error: shape({ question: shape({ type: string, message: string }) }),
  isLastEnabledQuestion: bool.isRequired,
};

export default RatingQuestion;

const StyledBox = styled(Box)`
  height: 100%;
`;
