import { AlertsContext } from '@features/alerts/alerts-provider';
import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { useLocalization } from '@features/localization';
import segmentTrack from '@util/analytics/segment-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import { isEmpty } from 'lodash';
import * as P from 'prop-types';
import React from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from './constants/step-map';
import { templates } from './constants/templates';
import { createEvent as createEventThunk } from './redux/app/create-event';

const trackEventCreation = state => {
  segmentTrack(EVENT.EVENT_SETUP_CREATE, {
    organiser_id: state.org.id,
    event_type: state.template.id,
    event_name: state.basicDetails.name,
    timezone: state.basicDetails.timezone,
    banner_uploaded: !isEmpty(state.basicDetails.imageUrl),
    ticket_type: state.eventAccess,
    welcome_message_added: !isEmpty(state.welcomeMessage.value),
    // Venue
    stage_enabled: state.venue.stage.selected,
    session_enabled: state.venue.sessions.selected,
    expo_enabled: state.venue.expo.selected,
    networking_enabled: state.venue.networking.selected,
    replay_enabled: state.venue.replay.selected,
    // Add-ons
    q_and_a_enabled: state.addOns.q_and_a.selected,
    speakers_enabled: state.addOns.speakers.selected,
    sponsors_enabled: state.addOns.sponsors.selected,
    polls_enabled: state.addOns.polls.selected,
    chat_enabled: state.addOns.chat.selected,

    session_title: state.sessions.title,
    session_image_uploaded: !isEmpty(state.sessions.image),
    headline_added: !isEmpty(state.speakers.headline),
    vendor_name: state.expo.name,
    auto_publish: templates[state.template.id]?.autoPublish ?? false,
    organisation_subscription_tier: state.org.subscriptionPlanTier,
    event_format: state.eventLocation.type,
  });
};

const EventCreationWizardContext = React.createContext({});

export const useEventCreationWizard = () =>
  React.useContext(EventCreationWizardContext);

const EventCreationWizardProvider = ({
  orgId,
  googleMapsApiKey,
  children,
  userExternalId,
  dashboardUrl,
}) => {
  const history = useHistory();
  const { t } = useLocalization('event-creation-wizard.common');
  const { addAlert } = React.useContext(AlertsContext);
  const store = useStore();
  const [submitting, setSubmitting] = React.useState(false);

  const createEvent = React.useCallback(
    /**
     * Creates a new event based on the selected template and redirects to event's dashboard
     * @param {Object} options Options
     * @param {boolean} options.openReceptionTab Opens event's reception in a separate tab
     */
    async (options = {}) => {
      const { openReceptionTab = false } = options;

      const state = store.getState();
      trackEventCreation(store.getState());
      setSubmitting(true);
      const { payload, error } = await store.dispatch(createEventThunk());

      const responseError = error || payload.error;

      if (responseError) {
        const logger = getLogger(LOGGER_NAMES.EVENT_CREATION_WIZARD);
        logger.error(responseError);
        segmentTrack(EVENT.EVENT_SETUP_UNSUCCESSFUL, {
          organiser_id: orgId,
          template_chosen: state.template.id,
          error: responseError.message,
          user_id: userExternalId,
        });
        addAlert({
          active: true,
          text: `${t('error')}${
            responseError.message ? `: ${responseError.message}` : ''
          }`,
          pattern: 'error',
        });

        setSubmitting(false);

        return;
      }

      segmentTrack(EVENT.EVENT_SETUP_SUCCESSFUL, {
        organiser_id: orgId,
        template_chosen: state.template.id,
        user_id: userExternalId,
        organisation_subscription_tier: state.org.subscriptionPlanTier,
        event_id: payload.event_external_id,
        event_intent: state.purpose,
        event_format: state.eventLocation.type,
      });

      if (payload.published) {
        segmentTrack(EVENT.EVENT_PUBLISHED, {
          organiser_id: orgId,
          template_chosen: state.template.id,
          user_id: userExternalId,
          organisation_subscription_tier: state.org.subscriptionPlanTier,
          event_id: payload.event_external_id,
          event_intent: state.purpose,
          event_format: state.eventLocation.type,

          // Venue
          stage_enabled: state.venue.stage.selected,
          session_enabled: state.venue.sessions.selected,
          expo_enabled: state.venue.expo.selected,
          networking_enabled: state.venue.networking.selected,
          replay_enabled: state.venue.replay.selected,
          // Add-ons
          q_and_a_enabled: state.addOns.q_and_a.selected,
          speakers_enabled: state.addOns.speakers.selected,
          sponsors_enabled: state.addOns.sponsors.selected,
          polls_enabled: state.addOns.polls.selected,
          chat_enabled: state.addOns.chat.selected,
        });
      }
      setSubmitting(false);

      const params = {
        ...(isEmpty(payload.failed_sections)
          ? {}
          : { failed_sections: payload.failed_sections.length }),
        ...(payload.published ? { published: 1 } : {}),
      };

      const redirectUrlParamQS = Object.entries(params)
        .map(pair => pair.map(encodeURIComponent).join('='))
        .join('&');

      if (openReceptionTab) {
        window.open(routes.eventPreview(payload.event_slug), '_blank');
      }

      history.push({
        pathname: routes.eventDashboard(payload.event_slug),
        search: redirectUrlParamQS,
      });
    },
    [addAlert, history, orgId, store, t, userExternalId],
  );

  const value = React.useMemo(
    () => ({
      submitting,
      setSubmitting,
      createEvent,
      googleMapsApiKey,
      dashboardUrl,
    }),
    [createEvent, submitting, googleMapsApiKey, dashboardUrl],
  );

  return (
    <EventCreationWizardContext.Provider value={value}>
      {children}
    </EventCreationWizardContext.Provider>
  );
};

EventCreationWizardProvider.propTypes = {
  children: P.node.isRequired,
  userId: P.number.isRequired,
  orgId: P.string,
  googleMapsApiKey: P.string,
  userExternalId: P.string.isRequired,
  dashboardUrl: P.string.isRequired,
};

export default EventCreationWizardProvider;
