import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { connectViewData, withViewDataProvider } from '@util/page-view-context';
import compose from 'lodash/fp/compose';
import React from 'react';

import AppArea from './component';

const mapViewDataToProps = ({
  installedAppAreaApps,
  selectedCustomApp,
  orgExternalId,
  canonicalUrl,
  fallbackCanonicalUrl,
  environment,
  fallbackEnvironment,
  venue,
  version,
}) => ({
  installedAppAreaApps,
  selectedCustomApp,
  orgExternalId,
  canonicalUrl,
  fallbackCanonicalUrl,
  environment,
  fallbackEnvironment,
  venue,
  version,
});

const AppAreaContainer = props => <AppArea {...props} />;

export default compose(
  withAlertsProvider,
  withViewDataProvider,
  connectViewData(mapViewDataToProps),
)(AppAreaContainer);
