import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  webinarStyle: 'lite_webcast',
};

export const virtualLiteSlice = createSlice({
  name: 'event-creation-wizard-virtual-lite',
  initialState,
  reducers: {
    setWebinarStyle(state, action) {
      state.webinarStyle = action.payload;
    },
  },
});

export const { setWebinarStyle } = virtualLiteSlice.actions;

export default virtualLiteSlice.reducer;
