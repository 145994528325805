export const gray100 = '#F1F2F5';
export const gray300alpha05 = 'rgba(236, 242, 255, 0.5)';
export const gray300 = '#E4E6EB';
export const gray700 = '#4F5461';
export const gray800 = '#2C3242';
export const blue100 = '#BDD2FF';
export const blue500 = '#1d6eaa';
export const white = 'white';

export const graphColor10 = '#ECF2FF';
export const graphColor20 = '#D6E3FF';
export const graphColor30 = '#BDD2FF';
export const graphColor40 = '#8DB5FF';
export const graphColor50 = '#6491FC';
export const graphColor60 = '#1d6eaa';
export const graphColor70 = '#1555E3';
export const graphColor80 = '#154CC4';
export const graphColor90 = '#1642A2';
export const graphColor100 = '#153780';

export const graphColorsArray1 = [
  graphColor20,
  graphColor40,
  graphColor60,
  graphColor80,
  graphColor100,
];

export const graphColorsArray2 = [
  graphColor10,
  graphColor20,
  graphColor30,
  graphColor40,
  graphColor50,
  graphColor60,
  graphColor70,
  graphColor80,
  graphColor90,
  graphColor100,
];

export function percentageToColor(percentage) {
  if (percentage <= 0) return graphColorsArray1[0];
  if (percentage >= 100) return graphColorsArray1.slice(-1)[0];

  return graphColorsArray1[Math.floor(percentage / 20)];
}

export const metricItemColors = {
  Green: { icon: '#25A968', background: '#EDF8F8' },
  Blue: { icon: 'var(--color-blue-400)', background: 'var(--color-blue-100)' },
  DarkOrange: {
    icon: 'var(--color-orange-600)',
    background: '#FFF5EE',
  },
  LightOrange: {
    icon: 'var(--color-orange-400)',
    background: '#FFF5EE',
  },
  Yellow: { icon: '#FFD07D', background: '#FFFBF1' },
  Violet: { icon: '#6F38DC', background: 'var(--color-violet-100)' },
};
