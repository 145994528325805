import NoticeItem from '@features/dashboard/common/notice-item';
import RegistrationsByCountryContainer from '@features/dashboard/registrations/registrations-by-country-container';
import TicketSalesByTypeComponent from '@features/dashboard/registrations/ticket-sales-by-type-component';
import { withLocalizationProvider } from '@features/localization';
import { Grid } from '@hopin-team/ui-grid';
import { bool, object, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RegistrationMetrics from './registration-metrics';
import TabbedChartsContainer from './tabbed-charts-container';
import TicketNetSales from './ticket-net-sales';

const Content = styled(Grid)`
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'metric metric'
    'graph graph'
    'ticket-sales-type ticket-sales-net'
    'reg-country reg-country';

  .metric {
    grid-area: metric;
  }
  .graph {
    grid-area: graph;
  }
  .ticket-sales-type {
    grid-area: ticket-sales-type;
  }
  .ticket-sales-net {
    grid-area: ticket-sales-net;
  }
  .reg-country {
    grid-area: reg-country;
  }
`;

const RegistrationsSummaryComponent = ({
  event,
  newRegistrationsDashboardEnabled,
  newRegistrationsDashboardUrl,
  newRegistrationsDefinitionsEnabled,
}) => {
  return (
    <div className="container-fluid">
      <NoticeItem
        pageType="Registrations"
        eventPhase={event.phase}
        showFeedbackLink
      />
      <Content gridGap="1rem" mb={6}>
        <div className="metric">
          <RegistrationMetrics
            eventId={event.id}
            hasPaidTickets={event.hasPaidTickets}
            newRegistrationsDashboardEnabled={newRegistrationsDashboardEnabled}
            newRegistrationsDefinitionsEnabled={
              newRegistrationsDefinitionsEnabled
            }
          />
        </div>
        <div className="graph">
          <TabbedChartsContainer
            eventId={event.id}
            startTime={event.startTime}
            endTime={event.endTime}
            newRegistrationsDashboardEnabled={newRegistrationsDashboardEnabled}
          />
        </div>
        {(event.hasPaidTickets || newRegistrationsDashboardEnabled) && (
          <>
            <div className="ticket-sales-type">
              <TicketSalesByTypeComponent
                eventSlug={event.slug}
                newRegistrationsDashboardEnabled={
                  newRegistrationsDashboardEnabled
                }
                newRegistrationsDashboardUrl={newRegistrationsDashboardUrl}
              />
            </div>
            <div className="ticket-sales-net">
              <TicketNetSales
                event={event}
                hasPaidTickets={event.hasPaidTickets}
              />
            </div>
          </>
        )}
        <div className="reg-country">
          <RegistrationsByCountryContainer eventId={event.id} />
        </div>
      </Content>
    </div>
  );
};

RegistrationsSummaryComponent.propTypes = {
  event: object.isRequired,
  newRegistrationsDefinitionsEnabled: bool.isRequired,
  newRegistrationsDashboardEnabled: bool.isRequired,
  newRegistrationsDashboardUrl: string.isRequired,
};

export default withLocalizationProvider(RegistrationsSummaryComponent);
