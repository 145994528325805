import styled from 'styled-components';

const Count = styled.div`
  padding: var(--spacing-4) var(--spacing-8);
  font-family: Larsseit, sans-serif;
  font-size: var(--font-size-heading-2);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-size-heading-2);
  color: var(--color-gray-800);
`;

export default Count;
