import { Pill } from '@hopin-team/ui-pill';
import { array, func } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { LeftDirectionButton, RightDirectionButton } from './scroll-buttons';

const ScrollTrack = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
`;

const PillsContainer = styled.div`
  align-items: center;
  display: flex;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledPill = styled(Pill)`
  border-color: var(--color-gray-300);
`;

const FiltersList = ({ list, onDeleteChip }) => {
  const scrollContainerRef = useRef();
  const pillsContainerRef = useRef();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const scroll = direction => {
    if (pillsContainerRef.current) {
      direction === 'right'
        ? (pillsContainerRef.current.scrollLeft += 200)
        : (pillsContainerRef.current.scrollLeft -= 200);
    }
  };

  const checkScrollPosition = useCallback(() => {
    if (scrollContainerRef.current && pillsContainerRef.current) {
      const parentOffsetWidth = scrollContainerRef.current.offsetWidth;
      const contentScrollWidth = pillsContainerRef.current.scrollWidth;
      const contentScrollLeft = pillsContainerRef.current.scrollLeft;

      const contentFitsWithinParent = contentScrollWidth <= parentOffsetWidth;
      const contentIsOnTheLeft = contentScrollLeft === 0;
      const contentIsOnTheRight =
        contentScrollWidth - contentScrollLeft <= parentOffsetWidth;

      setShowRightButton(!contentFitsWithinParent && !contentIsOnTheRight);
      setShowLeftButton(!contentIsOnTheLeft);
    }
  }, []);

  let throttleTick = useRef(false).current;

  const handleScroll = () => {
    if (!throttleTick) {
      requestAnimationFrame(() => {
        checkScrollPosition();
        throttleTick = false;
      });
      throttleTick = true;
    }
  };

  useEffect(() => {
    checkScrollPosition();
  }, [list, checkScrollPosition]);

  return (
    <ScrollTrack ref={scrollContainerRef}>
      {showLeftButton && <LeftDirectionButton onClick={() => scroll('left')} />}
      <PillsContainer onScroll={handleScroll} ref={pillsContainerRef}>
        {list.map(filter => (
          <StyledPill
            data-testid={`selected-${filter.label}`}
            isRemovable
            key={`selected-${filter.label}`}
            label={filter.label}
            mr={1}
            onRemove={() => onDeleteChip(filter)}
          />
        ))}
      </PillsContainer>
      {showRightButton && (
        <RightDirectionButton onClick={() => scroll('right')} />
      )}
    </ScrollTrack>
  );
};

FiltersList.propTypes = {
  list: array.isRequired,
  onDeleteChip: func.isRequired,
};

export default FiltersList;
