import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { bool } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setVenue } from '../../redux/step-specific-state/venue-slice';
import EventAreaInput from './event-area-input';

const VenueComponent = ({ canSkip }) => {
  const dispatch = useDispatch();
  const venueState = useSelector(state => state.venue);
  const { isOnFreePlan } = useSelector(state => state.org);
  const [spaces, setSpaces] = React.useState(venueState);
  const { t } = useLocalization('event-creation-wizard.steps.venue');

  const onSave = () => {
    dispatch(setVenue({ venue: spaces, isOnFreePlan }));
  };

  const eventAreas = ['stage', 'sessions', 'expo', 'networking', 'replay'];
  const onEventAreaChange = space => () => {
    const newState = { ...spaces, [space]: !spaces[space] };
    const totalSelected = Object.values(newState)
      .map(s => Number(s))
      .reduce((acc, x) => acc + x, 0);

    if (totalSelected < 1) {
      return;
    }

    setSpaces(newState);
  };

  return (
    <EventCreationWizardStep canSkip={canSkip} onSave={onSave}>
      <Text
        element="h1"
        pattern="headingTwo"
        color="grey-800"
        mb={0.5}
        style={{ fontWeight: 500 }}
      >
        {t('title')}
      </Text>

      <Text element="p" pattern="body" color="grey-700" mb={3}>
        {t('subtitle')}
      </Text>

      <form style={{ maxWidth: 368 }}>
        {eventAreas.map((space, idx) => (
          <EventAreaInput
            key={space}
            eventPartType={space}
            checked={spaces[space]}
            onChange={onEventAreaChange(space)}
            mb={idx < 4 ? 3 : 0}
          />
        ))}
      </form>
    </EventCreationWizardStep>
  );
};

VenueComponent.propTypes = {
  canSkip: bool,
};

export default VenueComponent;
