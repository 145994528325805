import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import React from 'react';
import { useSelector } from 'react-redux';

const Loading = () => {
  const { t } = useLocalization('event-creation-wizard.common');
  const {
    template: { id: templateId },
    flags: { essentialsWizardEventSimplification },
  } = useSelector(state => state);

  const loadingLabelKey = essentialsWizardEventSimplification
    ? EXPERIENCE_TEMPLATE.WEBINAR === templateId
      ? 'creating-webinar'
      : 'creating-event'
    : 'loading';

  return (
    <Flex
      flexDirection="column"
      flexGrow="1"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        pattern="jumbo"
        isShowing={true}
        data-testid="event-creation-wizard-spinner"
      />
      <Text
        pattern="caption"
        scale={6}
        font="text"
        weight="medium"
        color="grey-800"
        mt={4}
      >
        {t(loadingLabelKey)}
      </Text>
    </Flex>
  );
};

export default Loading;
