import StepHeader from '@features/event-creation-wizard/components/step-header';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { bool, string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AreaPreview } from './area-preview/area-preview-component';
import { AreasSelector } from './areas-selector/areas-selector-component';
import { InfoCircleIcon } from './assets/info-circle';

const EventAreasComponent = () => {
  const [previewArea, setPreviewArea] = useState('reception');
  const { t } = useLocalization('event-creation-wizard');
  const dispatch = useDispatch();

  const { id: templateId } = useSelector(state => state.template);
  const areas = useSelector(state => state.venue);

  const areasMap = useMemo(
    () =>
      new Map(
        Object.entries(areas)
          .filter(item => !item[1].hidden)
          .map(item => [
            item[0],
            { ...item[1], ...translationByArea[item[0]] },
          ]),
      ),
    [areas],
  );

  const hasZeroAreasSelected = useMemo(
    () =>
      Object.values(areas)
        .filter(a => !a.hidden)
        .filter(a => a.selected).length === 0,
    [areas],
  );
  const templateData = templates[templateId];

  const updateAreas = areaName => {
    const payload = {
      ...areas,
      [areaName]: { ...areas[areaName], selected: !areas[areaName].selected },
    };
    dispatch(setVenue({ venue: payload }));
  };

  const resetAreasToDefault = () => {
    dispatch(setVenue({ venue: templateData.defaultVenue }));
  };

  return (
    <EventCreationWizardStep
      canSkip
      continueDisabled={hasZeroAreasSelected}
      breadcrumbsDisabled={hasZeroAreasSelected}
      onSkip={resetAreasToDefault}
      ctaLabel={t('common.continue')}
      preview={<AreaPreview area={previewArea} />}
      verticallyCentered
    >
      <StepHeader
        title={t('steps.event-areas.title')}
        subtitle={t('steps.event-areas.subtitle')}
      />
      <AreasSelector
        areasMap={areasMap}
        onAreaHover={setPreviewArea}
        onAreaToggle={updateAreas}
      />
      <ErrorMessage
        label={t('steps.event-areas.errors.at-least-one-area')}
        isVisible={hasZeroAreasSelected}
      />
    </EventCreationWizardStep>
  );
};

export default EventAreasComponent;

const ErrorMessage = ({ isVisible, label }) => {
  return (
    <div style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
      <Flex alignItems="center" mt={2}>
        <InfoCircleIcon />
        <Text
          ml={1}
          pattern="body"
          element="p"
          color="utility-error"
          weight="regular"
        >
          {label}
        </Text>
      </Flex>
    </div>
  );
};

ErrorMessage.propTypes = {
  isVisible: bool.isRequired,
  label: string.isRequired,
};

const translationByArea = {
  reception: {
    titleKey: 'event-creation-wizard.steps.event-areas.reception.title',
    descriptionKey:
      'event-creation-wizard.steps.event-areas.reception.description',
  },
  stage: {
    titleKey: 'event-creation-wizard.steps.event-areas.stage.title',
    descriptionKey: 'event-creation-wizard.steps.event-areas.stage.description',
  },
  sessions: {
    titleKey: 'event-creation-wizard.steps.event-areas.sessions.title',
    descriptionKey:
      'event-creation-wizard.steps.event-areas.sessions.description',
  },
  networking: {
    titleKey: 'event-creation-wizard.steps.event-areas.networking.title',
    descriptionKey:
      'event-creation-wizard.steps.event-areas.networking.description',
  },
  expo: {
    titleKey: 'event-creation-wizard.steps.event-areas.expo.title',
    descriptionKey: 'event-creation-wizard.steps.event-areas.expo.description',
  },
};
