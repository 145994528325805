export const EVENT_STATUS = {
  DEFAULT: 'DEFAULT',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  POST_EVENT: 'POST_EVENT',
};

export const USER_STATUS = {
  DEFAULT: 'DEFAULT',
  ATTENDING: 'ATTENDING',
  ATTENDING_ORGANISER: 'ATTENDING_ORGANISER',
};

export function getUserStatus({
  isEventFinished,
  isUserAttending,
  isOrganiser,
}) {
  if (isEventFinished) return USER_STATUS.DEFAULT;

  if (isUserAttending && isOrganiser) return USER_STATUS.ATTENDING_ORGANISER;

  if (isUserAttending) return USER_STATUS.ATTENDING;

  return USER_STATUS.DEFAULT;
}

export const getEventStatus = ({
  isPostEventInProgress,
  isEventFinished,
  isEventLive,
}) => {
  if (isPostEventInProgress) {
    return EVENT_STATUS.POST_EVENT;
  }

  if (isEventFinished) {
    return EVENT_STATUS.ENDED;
  }

  if (isEventLive) {
    return EVENT_STATUS.LIVE;
  }

  return EVENT_STATUS.DEFAULT;
};

export const calculateIsPostEventInProgress = ({
  isPostEventEnabled,
  isEventFinished,
  postEventTimeEnd,
}) => {
  const isItBeforePostEventTimeEnd = new Date(postEventTimeEnd) > new Date();

  return isPostEventEnabled && isEventFinished && isItBeforePostEventTimeEnd;
};
