import { debounce, isFunction } from 'lodash';
import { arrayOf, func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import PreviewSelect from '../preview-select';

const AsyncSelect = ({
  onInputChange,
  loadOptions,
  minInputLength,
  options: initialOptions,
  ...rest
}) => {
  const [options, setOptions] = useState(initialOptions);
  const [loading, setLoading] = useState(false);
  const delayedFetchOptions = debounce(async value => {
    setLoading(true);
    setOptions(await loadOptions(value));
    setLoading(false);
  });

  const handleOnSelectInputChange = value => {
    isFunction(onInputChange) && onInputChange(value);

    if (!value || value.length === 0) {
      setOptions(initialOptions);
      return;
    }

    if (minInputLength && value.length < minInputLength) {
      setOptions([]);
      return;
    }

    delayedFetchOptions(value);
  };

  return (
    <PreviewSelect
      options={options}
      onInputChange={handleOnSelectInputChange}
      isLoading={loading}
      {...rest}
    />
  );
};

AsyncSelect.propTypes = {
  loadOptions: func,
  onChange: func,
  onInputChange: func,
  minInputLength: number,
  options: arrayOf(
    shape({
      label: string,
      value: number,
    }).isRequired,
  ),
};

AsyncSelect.defaultProps = {
  options: [],
  minInputLength: 0,
};

export default AsyncSelect;
