import MountSlice, { LATEST } from '@components/slices/MountSlice';
import { setEnvironment } from '@hopin-team/dashboard-constants';
import { createDashboardReactiveMap } from '@hopin-team/dashboard-shared';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { number, string } from 'prop-types';
import React, { useMemo } from 'react';

if (process.env.NODE_ENV === 'development') {
  setEnvironment('local');
}
const logger = getLogger(LOGGER_NAMES.EVENT_DUPLICATION);

const PACKAGE_NAME = '@hopin-team/slice-dashboard-event-duplication';

const DuplicationSlice = ({ orgId, eventId, eventSlug }) => {
  const reactiveMap = useMemo(
    () =>
      createDashboardReactiveMap({
        clientPackageDetails: `${PACKAGE_NAME}/${LATEST}`,
        onAsyncError: error => logger.error(error),
      }),
    [],
  );

  reactiveMap.item('org_id').set(orgId);
  reactiveMap.item('event_id').set(eventId);
  reactiveMap.item('event_slug').set(eventSlug);

  return (
    <MountSlice
      packageName={PACKAGE_NAME}
      version={LATEST}
      reactiveMap={reactiveMap}
    />
  );
};

DuplicationSlice.propTypes = {
  orgId: string.isRequired,
  eventId: number.isRequired,
  eventSlug: string.isRequired,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.EVENT_DUPLICATION,
    errorPattern: 'page',
  }),
)(DuplicationSlice);
