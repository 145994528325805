import { AlertsContext } from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { Toast } from '@hopin-team/ui-toast';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import compose from 'lodash/fp/compose';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { JobStatus, View, ViewSize } from '../consts';
import { safeInterval, translateIntoItems } from '../helpers';
import { useStorageErrors } from '../use-storage-errors';
// import { translateIntoItems } from '../helpers';
import { ErrorReport, FileSelection, MapHeaders } from './modal-views';
import { Modal } from './upload-modal.style';

const UploadModal = ({
  authenticityToken,
  eventSlug,
  canInvite,
  closeModal,
}) => {
  const { t } = useLocalization('expo.bulk-upload-modal');
  const { addAlert } = useContext(AlertsContext);

  const [view, setView] = useState();
  const [size, setViewSize] = useState(ViewSize[view]);
  const [processedFile, setProcessedFile] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dictionary, setDictionary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDismissible, setIsDismissible] = useState(true);
  const [errors, setErrors] = useState([]);

  const apiClient = initApiClient(authenticityToken, { json: true });
  const { storagedErrors, setErrorsToStorage } = useStorageErrors();

  useEffect(() => {
    setViewSize(ViewSize[view]);
  }, [view, setViewSize]);

  useEffect(() => {
    if (storagedErrors?.length) {
      setErrors(storagedErrors);
      setView(View.ERROR_REPORT);
    } else {
      setView(View.FILE_SELECTION);
    }
  }, [storagedErrors]);

  useEffect(() => {
    setErrorsToStorage(errors);
  }, [errors, setErrorsToStorage]);

  const handleFileSelect = useCallback(rows => {
    setProcessedFile(rows);
    setView(View.MAP_HEADERS);
  }, []);

  const getFormattedErros = errors => {
    const formattedErrors = [];

    for (const [line, validations] of Object.entries(errors)) {
      for (const [field, messages] of Object.entries(validations)) {
        formattedErrors.push({
          line: parseInt(line) + 2, // Data from the back-end starts at 0. if considering the table headers and the first line in *.csv file, we need to add +2 here
          field: startCase(toLower(field)),
          messages: messages.join(', '),
        });
      }
    }

    return formattedErrors;
  };

  const jobStatusCheck = useCallback(
    jobID => {
      // eslint-disable-next-line no-unused-vars
      safeInterval(async (_, intervalController) => {
        const endpoint = Routes.organisersEventVendorsBulkUpsertPath({
          event_id: eventSlug,
          id: jobID,
          _options: true,
        });

        const { status, result } = await apiClient.get(endpoint);

        if (status === JobStatus.COMPLETE) {
          intervalController.abort();

          setLoading(false);
          setIsDismissible(true);

          addAlert({
            // eslint-disable-next-line react/prop-types
            toast: ({ onClose }) => (
              <Toast
                icon="success"
                colorPattern="success"
                role="status"
                iconColor="green-600"
                isInverse
                withCloseButton
                onClose={onClose}
                py={2}
              >
                <Text pattern="strong" color="grey-800">
                  {t('messages.changedBooths', {
                    created: result.total.created,
                    updated: result.total.updated,
                  })}
                </Text>

                <Text pattern="body" color="grey-600">
                  {t('messages.successSecond')}
                </Text>
              </Toast>
            ),
          });

          const hasErrors = !!Object.entries(result.errors).length;

          if (hasErrors) {
            setErrors(getFormattedErros(result.errors));
            setView(View.ERROR_REPORT);
          } else {
            setErrors([]);
            closeModal();

            setTimeout(() => {
              location.reload();
            }, 2000);
          }
        }
      }, 2000);
    },
    [addAlert, apiClient, closeModal, eventSlug, t],
  );

  const handleHeadersSelect = useCallback(
    async matchedDictionary => {
      setDictionary(matchedDictionary);

      try {
        setLoading(true);
        setIsDismissible(false);

        const endpoint = Routes.organisersEventVendorsBulkUpsertIndexPath({
          event_id: eventSlug,
          _options: true,
        });

        const vendors = translateIntoItems({
          processedFile,
          headerDictionary: matchedDictionary,
        });

        const { background_job_id: jobID } = await apiClient.post(endpoint, {
          vendors,
          invite: canInvite,
        });

        jobStatusCheck(jobID);
      } catch (exception) {
        setLoading(false);
      }
    },
    [apiClient, canInvite, eventSlug, jobStatusCheck, processedFile],
  );

  const handleTryAgain = () => {
    setView(View.FILE_SELECTION);
  };

  const handleCloseModal = useCallback(() => {
    if (view === View.ERROR_REPORT) {
      location.reload();
      return;
    }

    closeModal();
  }, [closeModal, view]);

  return (
    <Modal
      isDismissible={isDismissible}
      withCloseButton
      onClose={handleCloseModal}
      isShowing
      size={size}
    >
      {view === View.FILE_SELECTION && (
        <FileSelection
          eventSlug={eventSlug}
          cancel={handleCloseModal}
          onFileSelect={handleFileSelect}
        />
      )}

      {view === View.MAP_HEADERS && (
        <MapHeaders
          processedFile={processedFile}
          loading={loading}
          canInvite={canInvite}
          cancel={handleCloseModal}
          onNext={handleHeadersSelect}
        />
      )}

      {view === View.ERROR_REPORT && (
        <ErrorReport
          errors={errors}
          cancel={handleCloseModal}
          onNext={handleTryAgain}
        />
      )}
    </Modal>
  );
};

UploadModal.propTypes = {
  eventSlug: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  canInvite: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
};

export default compose(withLocalizationProvider)(UploadModal);
