import eventDashboardApi from '@api/event-dashboard';
import { yupResolver } from '@hookform/resolvers';
import snakecaseKeys from '@util/snakecase-keys';
import { useForm } from 'react-hook-form';
import { bool, object, string } from 'yup';

const schema = object().shape({
  domain: string().trim().nullable(),
  appDomain: string().trim(),
  userVisible: bool(),
  faviconUrl: string().trim(),
});

const useCustomDomainSettingsForm = ({
  id,
  attributes: { domain, appDomain, userVisible, faviconUrl },
  onError,
  onSuccess,
}) => {
  const defaultValues = {
    domain,
    appDomain,
    userVisible,
    faviconUrl,
  };

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleHookFormSubmit = async values => {
    try {
      const { data, errors } = await eventDashboardApi.updateCustomDomain(
        id,
        snakecaseKeys(values),
      );

      if (data) {
        onSuccess(data);
      } else if (errors) {
        onError(errors);
      }
    } catch (err) {
      onError([{ detail: 'Something went wrong' }]);
    }
  };

  return {
    onSubmit: handleSubmit(handleHookFormSubmit),
    ...methods,
  };
};

export default useCustomDomainSettingsForm;
