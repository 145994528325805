import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { Header } from './modal-header.style';

export const ModalHeader = ({ title, description }) => {
  return (
    <Header flexDirection="column" alignItems="left" m={3}>
      <Text pattern="headingFour" element="h4" mb={1}>
        {title}
      </Text>

      {description}
    </Header>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
