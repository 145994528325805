import analyticsApi from '@api/analytics';
import Chart from '@components/icons/chart';
import CardTitle from '@features/dashboard/components/card-title';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import Card from '@features/overview/analytics/common/overview-card';
import { string } from 'prop-types';
import React, { useState } from 'react';

import EventAreaIcon from '../common/event-area-icon';
import TopItemsListComponent from '../common/top-items-list-component';
import EmptyState from '../styles/empty-state-styles';
import Loading from '../styles/loading-styles';

const transformResult = topAreas => {
  return topAreas.map(area => {
    return {
      icon: <EventAreaIcon eventArea={area.segment} />,
      text: area.name,
      value: area.count.toLocaleString(),
    };
  });
};

const TopAreasByAttendees = ({ eventSlug }) => {
  const { t } = useLocalization('overview.top-areas-by-attendees');
  const [topAreas, setTopAreas] = useState([]);
  const limit = 6;
  const loading = usePromise(
    signal => analyticsApi.getTopAreasByAttendees(eventSlug, signal, limit),
    result => setTopAreas(transformResult(result)),
  );

  return (
    <Card testId="top-areas-by-attendees">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <Loading isLoading={loading}>
          {topAreas.length > 0 ? (
            <TopItemsListComponent items={topAreas} />
          ) : (
            <EmptyState text={t('empty')} icon={<Chart />} />
          )}
        </Loading>
      </CardContentWrapper>
    </Card>
  );
};

TopAreasByAttendees.propTypes = {
  eventSlug: string.isRequired,
};

export default TopAreasByAttendees;
