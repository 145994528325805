import { Flex } from '@hopin-team/ui-flex';
import { rem } from 'polished';
import styled from 'styled-components';

const EmptyStateContainer = styled(Flex)`
  text-align: center;
`;

const SendEmailsIcon = styled.img`
  width: ${rem(320)};
`;

export { EmptyStateContainer, SendEmailsIcon };
