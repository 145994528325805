import { Table, TableCell, TableHeading, TableRow } from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import CustomDomainSettingsStatus from './custom-domain-settings-status';
import {
  DomainNameHeading,
  StatusCell,
  StatusNameCell,
} from './custom-domain-settings.styles';

const CustomDomainSettingsStatuses = ({
  persistedAppDomain,
  persistedDomain,
  appDomainHostnameStatus,
  appDomainSslStatus,
  domainHostnameStatus,
  domainSslStatus,
  appDomainHostnameStatusErrors,
  appDomainSslStatusErrors,
  domainHostnameStatusErrors,
  domainSslStatusErrors,
  lastUpdated,
  t,
}) => (
  <>
    <Table>
      <thead>
        <TableRow isHeading>
          <TableHeading scope="col" />
          <DomainNameHeading as={TableHeading} scope="col">
            <Text pattern="emphasis" align="center">
              {t('event-dashboard.custom-domains.event')}
            </Text>
          </DomainNameHeading>
          <DomainNameHeading as={TableHeading} scope="col">
            <Text pattern="emphasis" align="center">
              {t('event-dashboard.custom-domains.canvas')}
            </Text>
          </DomainNameHeading>
        </TableRow>
      </thead>

      <tbody>
        <TableRow>
          <StatusNameCell as={TableCell}>
            <Text pattern="emphasis">
              {t('event-dashboard.custom-domains.hostname')}
            </Text>
          </StatusNameCell>
          <StatusCell as={TableCell}>
            <CustomDomainSettingsStatus
              isPersisted={!!persistedAppDomain}
              status={appDomainHostnameStatus}
              errors={appDomainHostnameStatusErrors}
              t={t}
            />
          </StatusCell>
          <StatusCell as={TableCell}>
            <CustomDomainSettingsStatus
              isPersisted={!!persistedDomain}
              status={domainHostnameStatus}
              errors={domainHostnameStatusErrors}
              t={t}
            />
          </StatusCell>
        </TableRow>
        <TableRow>
          <StatusNameCell as={TableCell}>
            <Text pattern="emphasis">
              {t('event-dashboard.custom-domains.ssl')}
            </Text>
          </StatusNameCell>
          <StatusCell as={TableCell}>
            <CustomDomainSettingsStatus
              isPersisted={!!persistedAppDomain}
              status={appDomainSslStatus}
              errors={appDomainSslStatusErrors}
              t={t}
            />
          </StatusCell>
          <StatusCell as={TableCell}>
            <CustomDomainSettingsStatus
              isPersisted={!!persistedDomain}
              status={domainSslStatus}
              errors={domainSslStatusErrors}
              t={t}
            />
          </StatusCell>
        </TableRow>
      </tbody>
    </Table>
    {lastUpdated && (
      <Text element="p" color="grey-600" scale={3} mt={2}>
        {t('event-dashboard.custom-domains.updated')}: {lastUpdated}
      </Text>
    )}
  </>
);

CustomDomainSettingsStatuses.propTypes = {
  persistedDomain: PropTypes.string,
  persistedAppDomain: PropTypes.string,
  appDomainHostnameStatus: PropTypes.string,
  appDomainSslStatus: PropTypes.string,
  domainHostnameStatus: PropTypes.string,
  domainSslStatus: PropTypes.string,
  appDomainHostnameStatusErrors: PropTypes.arrayOf(PropTypes.string),
  appDomainSslStatusErrors: PropTypes.arrayOf(PropTypes.string),
  domainHostnameStatusErrors: PropTypes.arrayOf(PropTypes.string),
  domainSslStatusErrors: PropTypes.arrayOf(PropTypes.string),
  lastUpdated: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default CustomDomainSettingsStatuses;
