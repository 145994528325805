import eventDashboardApi from '@api/event-dashboard';
import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { object, setLocale, string } from 'yup';

const useHostInformationForm = ({
  id,
  attributes: { about, name, facebook, twitter, website },
  onError,
  onSuccess,
}) => {
  const { t } = useLocalization('host-information');

  setLocale({
    mixed: {
      required: t('cannot-be-empty'),
    },
    string: {
      url: t('must-be-valid-url'),
    },
  });

  const schema = object().shape({
    about: string(),
    name: string(),
    picture_file_name: string().trim(),
    facebook: string()
      .url(t('must-be-valid-url'))
      .test(
        'facebook-domain',
        t('must-be-a-facebook-url'),
        val => val.length === 0 || /^https:\/\/facebook.com/.test(val),
      ),
    twitter: string()
      .url()
      .test(
        'twitter-domain',
        t('must-be-a-twitter-url'),
        val => val.length === 0 || /^https:\/\/twitter.com/.test(val),
      ),
    website: string().url(),
  });

  const defaultValues = {
    about,
    picture_file_name: '',
    name,
    facebook,
    twitter,
    website,
  };

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleHookFormSubmit = async values => {
    try {
      const { data, errors } = await eventDashboardApi.updateHostInformation(
        id,
        values,
      );

      if (data) {
        onSuccess();
      } else if (errors) {
        onError(errors);
      }
    } catch (err) {
      onError([{ detail: t('something-went-wrong') }]);
    }
  };

  return {
    onSubmit: handleSubmit(handleHookFormSubmit),
    ...methods,
  };
};

export default useHostInformationForm;
