import { useLocalization } from '@features/localization';
import { arrayOf, bool, number } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledCounter = styled.div`
  font-size: var(--font-size);
  font-weight: var(--font-weight-normal);
  line-height: var(--button-size-medium);
  color: var(--color-blue-500);
  margin-right: var(--spacing-16);
`;

const SelectedCounter = ({ selectedIds, selectAll, total }) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites');
  const count = selectAll ? total - selectedIds.length : selectedIds.length;
  return <StyledCounter>{t('selected', { count })}</StyledCounter>;
};

SelectedCounter.propTypes = {
  selectedIds: arrayOf(number),
  selectAll: bool,
  total: number.isRequired,
};

export default SelectedCounter;
