import { Flex } from '@hopin-team/ui-flex';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

export const ItemOptions = styled(Flex).attrs(({ isCondensed }) => ({
  ph: isCondensed ? 1 : 0,
  pv: isCondensed ? 1 : 3,
  role: 'group',
  flexDirection: 'column',
}))`
  height: ${({ isCondensed }) =>
    !isCondensed && `calc(100% - ${calcSpace(4)})`};
  width: calc(100% - ${calcSpace(4)});
`;
