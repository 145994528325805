const eventAreas = {
  stage: {
    iconKey: 'camera',
    color: 'blue',
  },
  sessions: {
    iconKey: 'sessions',
    color: 'green',
  },
  expo: {
    iconKey: 'booth',
    color: 'orange',
  },
  networking: {
    iconKey: 'handshake',
    color: 'purple',
  },
  reception: {
    iconKey: 'house',
    color: 'yellow',
  },
};

export { eventAreas };
