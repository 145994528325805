import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const FileUploadArea = styled.button`
  display: block;
  width: 100%;
  height: ${props => props.height || 'auto'};
  min-height: ${rem(140)};
  padding: ${props => (props.hasImage ? 0 : rem(35))};
  background: ${props =>
    props.dragActive ? 'var(--color-gray-300)' : 'var(--color-gray-100)'};
  ${props =>
    props.aspectRatio &&
    css`
      aspect-ratio: ${props.aspectRatio};
    `}
  border: 1px dashed var(--color-gray-400);
  border-radius: var(--border-radius-small);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: background var(--transition-duration) ease-out;

  ${props =>
    props.hasError &&
    css`
      border: 1px solid var(--color-red-500);
      background: var(--color-red-100);
    `}
`;
export const PlaceholderImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const RemoveImageButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-gray-100);
`;
