import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { arrayOf, bool, object, string } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

import ClearChat from './clear-chat';

const ControlledCheckbox = ({ section, segment, label, control }) => {
  const {
    field: { onChange, name, value },
  } = useController({
    name: `venue_settings[side_panel_config][${section}][${segment}]`,
    control,
  });

  return (
    <Flex key={segment} alignItems="center" mr={5}>
      <Checkbox
        onChange={e => onChange(e.target.checked)}
        value={value}
        name={name}
        checked={!!value}
        id={`side_panel_config_${segment}_${section}`}
        data-testid={`activity-panel-settings-checkbox-${section}-${segment}`}
        label={label}
      />
    </Flex>
  );
};

ControlledCheckbox.propTypes = {
  control: object.isRequired,
  segment: string.isRequired,
  section: string.isRequired,
  label: string.isRequired,
};

const ActivityPanelSettings = ({
  control,
  eventPartTypes = [],
  eventExternalId,
  showClearChat,
}) => {
  const availableSegments = ['event'].concat(eventPartTypes);
  const { t } = useLocalization('venue-controls.activity-panel-settings');

  const chatKey = 'chat';

  const FEATURE_MAP = {
    [chatKey]: t('chat'),
    polls: t('polls'),
    'q-and-a': t('q-and-a'),
  };

  const SEGMENT_MAP = {
    event: t('event'),
    stage: t('stage'),
    sessions: t('sessions'),
    expo: t('expo'),
  };

  return (
    <>
      {Object.keys(FEATURE_MAP).map(section => (
        <Box key={section} mb={3}>
          <Text
            element="h3"
            pattern="headingThree"
            color="grey-800"
            weight="medium"
            scale={4}
            mb={1}
          >
            {FEATURE_MAP[section]}
          </Text>
          <Text element="p" color="grey-600" pattern="body" scale={4}>
            {t('description-redesign', {
              segment: FEATURE_MAP[section],
            })}
          </Text>
          <Flex alignItems="center" mt={1}>
            {availableSegments
              .filter(segment => !!SEGMENT_MAP[segment])
              .map((segment, i) => (
                <ControlledCheckbox
                  control={control}
                  key={i}
                  segment={segment}
                  section={section}
                  label={SEGMENT_MAP[segment]}
                />
              ))}
          </Flex>
          {section === chatKey && showClearChat && (
            <ClearChat eventId={eventExternalId} />
          )}
        </Box>
      ))}
    </>
  );
};

ActivityPanelSettings.propTypes = {
  control: object.isRequired,
  eventPartTypes: arrayOf(string),
  eventExternalId: string,
  showClearChat: bool,
};

export default withLocalizationProvider(ActivityPanelSettings);
