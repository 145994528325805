import { BoothCard } from '@hopin-team/ui-booth-card';
import { Button } from '@hopin-team/ui-button';
import { array, bool } from 'prop-types';
import React, { useState } from 'react';

import { Styles } from './vendors-style';

const NR_OF_VISIBLE_ITEMS = 4;

const Vendors = ({ vendors, viewAll }) => {
  const [viewAllVendors, setViewAllVendors] = useState(false);
  const vendorsList =
    viewAll || viewAllVendors ? vendors : vendors.slice(0, NR_OF_VISIBLE_ITEMS);
  const showViewAllButton = !viewAll && vendors.length > NR_OF_VISIBLE_ITEMS;
  const title = viewAllVendors ? 'Hide booths' : 'View all booths';

  const handleToggleViewallSpeakers = () => {
    setViewAllVendors(!viewAllVendors);
  };

  return (
    <>
      <Styles.VendorsGrid>
        {vendorsList.map((vendor, idx) => (
          <BoothCard
            key={idx}
            logo={vendor.logo}
            about={vendor.headline}
            backgroundImage={vendor.background_image}
            name={vendor.name}
          />
        ))}
      </Styles.VendorsGrid>
      {showViewAllButton && (
        <Button
          isInline
          isOutlined
          size="medium"
          mt={3}
          onClick={handleToggleViewallSpeakers}
        >
          {title}
        </Button>
      )}
    </>
  );
};

Vendors.propTypes = {
  vendors: array,
  viewAll: bool,
};

export default Vendors;
