import { LocalizationContext } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { calcSpace, COLOR } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import ButtonCTA from './button-cta';

const Offer = styled(Flex)`
  position: relative;
  min-height: ${calcSpace(40)};
  background-color: ${COLOR.GREY[200]};
  border-radius: ${calcSpace(2)};
`;

const TicketElement = styled(Flex)`
  height: ${calcSpace(25)};
  width: 100%;
  position: relative;
  background: ${COLOR.GREY[100]};
  border-radius: ${calcSpace(2)};
  box-shadow: 0 ${calcSpace(0.125)} ${calcSpace(0.125)} rgba(44, 50, 66, 0.02),
    0 ${calcSpace(1)} ${calcSpace(2)} rgba(44, 50, 66, 0.02);

  &::after {
    content: '';
    border-bottom: ${calcSpace(1.75)} dotted ${COLOR.GREY[200]};
    z-index: 1;
    width: 100%;
    height: ${calcSpace(6)};
    position: absolute;
    bottom: ${calcSpace(-0.75)};
  }
`;

const TicketPart = styled(Flex)`
  height: 50%;
`;

const TicketOffer = styled(Text)`
  overflow-wrap: anywhere;
  word-break: ${props => props.break && 'break-all'};
`;

const TicketDivider = styled(Flex)`
  position: relative;
  width: calc(100% - ${calcSpace(8)});
  height: ${calcSpace(0.125)};
  border: ${calcSpace(0.125)} dashed ${COLOR.GREY[300]};

  &::before {
    content: '';
    position: absolute;
    height: ${calcSpace(3)};
    width: ${calcSpace(3)};
    background-color: ${COLOR.GREY.WHITE};
    border-radius: ${calcSpace(3)};
    left: ${calcSpace(-5.75)};
  }

  &::after {
    content: '';
    position: absolute;
    height: ${calcSpace(3)};
    width: ${calcSpace(3)};
    background-color: ${COLOR.GREY.WHITE};
    border-radius: ${calcSpace(3)};
    right: ${calcSpace(-5.75)};
  }
`;

const EventOffer = ({
  offer,
  website,
  buttonAction,
  buttonText,
  ...restProps
}) => {
  const { t } = useContext(LocalizationContext);
  const hasWhiteSpace = offer?.indexOf(' ') >= 0;
  return (
    <Flex flexDirection="column" p={2} data-testid={restProps['data-testid']}>
      <Text pattern="subHeadingMedium" mb={2} align="left" scale={5}>
        {t('expo.booth.preview.modal.event-offer')}
      </Text>
      <Offer flexDirection="column" justifyContent="flex-start">
        <TicketElement
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <TicketPart justifyContent="center" alignItems="center" px={3}>
            <TicketOffer
              color="grey-800"
              align="center"
              scale={5}
              break={!hasWhiteSpace ? true : undefined}
            >
              {offer}
            </TicketOffer>
          </TicketPart>
          <TicketDivider alignItems="center" />
          <TicketPart justifyContent="center" alignItems="center" px={3}>
            <Text color="grey-700" align="center" scale={2}>
              {t(
                `expo.booth.preview.modal.${
                  buttonAction === 'direct_link' ? 'direct-link' : 'claim-offer'
                }`,
              )}
            </Text>
          </TicketPart>
        </TicketElement>
        <Flex
          flexDirection="column"
          justifyContent="center"
          flex="1 1 auto"
          p={2}
        >
          <ButtonCTA
            data-testid="cta-button"
            website={website}
            buttonAction={buttonAction}
            actionButtonText={buttonText}
          />
        </Flex>
      </Offer>
    </Flex>
  );
};

EventOffer.propTypes = {
  offer: string.isRequired,
  buttonText: string.isRequired,
  buttonAction: string,
  website: string,
  ['data-testid']: string,
};

export default EventOffer;
