import trackSegmentEvent from '@util/analytics/segment-client';
import { node, object, string } from 'prop-types';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const DEBOUNCE_TIMEOUT = 1000;

const TrackSegmentClick = ({ eventName, children, properties }) => {
  const trackEvent = useDebouncedCallback(() => {
    trackSegmentEvent(eventName, properties);
  }, DEBOUNCE_TIMEOUT);

  return (
    <span
      role="button"
      tabIndex={0}
      onClick={trackEvent.callback}
      onTouchEnd={trackEvent.callback}
      onKeyDown={trackEvent.callback}
    >
      {children}
    </span>
  );
};

TrackSegmentClick.propTypes = {
  eventName: string.isRequired,
  children: node.isRequired,
  properties: object,
};

TrackSegmentClick.defaultProps = {
  properties: {},
};

export default TrackSegmentClick;
