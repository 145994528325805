import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types';
import React from 'react';

import { isHopinNpsQuestion } from './helpers';
import RatingQuestion from './rating-question';
import ReadOnlyRatingQuestion from './read-only-rating-question';
import { QuestionCard } from './styles';
import SurveyDetails from './survey-details';
import TextQuestion from './text-question';

export const QUESTION_KIND = {
  RATING: 'scale_10',
  TEXT: 'text',
};

const EditorTab = ({
  survey,
  register,
  watch,
  questionPrefix,
  control,
  canEditSurvey,
  errors,
}) => {
  const { t } = useLocalization();
  const { questions } = survey;

  const formQuestions = watch('questions');
  const enabledFormQuestions = Object.values(formQuestions).filter(
    question => question.enabled,
  );

  // This calculation is because some questions are not registered to react-hook-form (i.e. the <ReadOnlyRatingQuestion)
  const enabledQuestionsCount =
    enabledFormQuestions.length +
    questions.length -
    Object.values(formQuestions).length;

  return (
    <>
      <QuestionCard mx="auto" mt={3} flexDirection="column">
        <Text element="h2" pattern="bodyLargeBold" color="grey-800">
          {t('survey-builder.edit.customize-questions')}
        </Text>
        <Text element="h3" pattern="captionMedium" color="grey-600">
          {t('survey-builder.edit.gather-feedback')}
        </Text>
      </QuestionCard>

      <SurveyDetails
        surveyKind={survey.kind}
        register={register}
        watch={watch}
        canEditSurvey={canEditSurvey}
        errors={errors}
      />
      {!!questions?.length &&
        questions.map(question => {
          const isLastEnabledQuestion =
            enabledQuestionsCount <= 1 &&
            formQuestions[`${questionPrefix}${question.id}`]?.enabled;

          if (isHopinNpsQuestion(question))
            return (
              <ReadOnlyRatingQuestion key={question.id} question={question} />
            );
          if (question.kind === QUESTION_KIND.RATING)
            return (
              <RatingQuestion
                key={question.id}
                fieldName={`questions.${questionPrefix}${question.id}`}
                control={control}
                canEditSurvey={canEditSurvey}
                isLastEnabledQuestion={isLastEnabledQuestion}
                error={
                  errors.questions &&
                  errors.questions[`${questionPrefix}${question.id}`]
                }
              />
            );
          if (question.kind === QUESTION_KIND.TEXT)
            return (
              <TextQuestion
                key={question.id}
                fieldName={`questions.${questionPrefix}${question.id}`}
                control={control}
                canEditSurvey={canEditSurvey}
                isLastEnabledQuestion={isLastEnabledQuestion}
                error={
                  errors.questions &&
                  errors.questions[`${questionPrefix}${question.id}`]
                }
              />
            );
        })}
    </>
  );
};

EditorTab.propTypes = {
  survey: shape({
    description: string,
    description_i18n_key: string,
    enabled: bool,
    event_id: number,
    id: number,
    kind: string,
    name: string,
    published_at: string,
    questions: arrayOf(
      shape({
        id: number,
        question: string,
        question_i18n_key: string,
        kind: oneOf([QUESTION_KIND.TEXT, QUESTION_KIND.RATING]),
      }),
    ),
    scheduled_at: string,
    scheduled_kind: string,
    title: string,
    title_i18n_key: string,
  }),
  questionPrefix: string.isRequired,
  register: func.isRequired,
  watch: func.isRequired,
  control: object.isRequired,
  canEditSurvey: bool.isRequired,
  errors: object,
};

export default withErrorHandling({ loggerName: LOGGER_NAMES.SURVEY_BUILDER })(
  EditorTab,
);
