import { ParentSize } from '@visx/responsive';
import PropTypes from 'prop-types';
import React from 'react';

import BarChart from './bar-chart-component';

const defaultChartHeight = 230; // in pixels

const BarChartContainer = props => {
  return (
    <ParentSize>
      {({ width }) => (
        <BarChart
          width={width}
          height={props.height || defaultChartHeight}
          {...props}
        />
      )}
    </ParentSize>
  );
};

BarChartContainer.propTypes = {
  height: PropTypes.number,
};

export default BarChartContainer;
