import { useLocalization } from '@features/localization';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropsTypes from 'prop-types';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import PublishButton from './publish-button';

const PublishButtonContainer = props => {
  const { t } = useLocalization('event-dashboard.sidenav.publish');

  const componentProps = useSelector(({ event }) => {
    const { draft, isPublishing, maxPrice, validForDeletion } = event;
    const { merchantId } = event.organization;
    const noTickets = !event.hasTickets;
    const free = maxPrice === 0;
    let tipText;

    const newProps = {
      ...props,
      disabled: isPublishing,
      hide: false,
      hideTip: true,
      icon: 'publish',
      isInverse: !draft && validForDeletion,
      text: t(
        props.canPublishEvent === false ? 'upgrade-to-publish' : 'publish',
      ),
      tipText,
    };
    if (draft) {
      if (noTickets || !free & !merchantId) {
        newProps.disabled = true;
        newProps.hideTip = false;
        newProps.tipText = t('tip-text');
      }
    } else if (validForDeletion) {
      newProps.text = t('unpublish');
      newProps.icon = 'undo';
    } else {
      newProps.hide = true;
      newProps.hideTip = true;
    }
    return newProps;
  }, shallowEqual);

  return (
    <ToolTip
      align="bottom"
      tip={componentProps.tipText}
      hideTip={componentProps.hideTip}
    >
      <PublishButton {...componentProps} />
    </ToolTip>
  );
};

PublishButtonContainer.propTypes = {
  canPublishEvent: PropsTypes.bool,
};

export default PublishButtonContainer;
