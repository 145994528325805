import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { bool, func, string } from 'prop-types';
import React from 'react';

import { Content, Img, StyledLink, Subtitle, Title } from './styles';

const NavigationItemComponent = ({
  title,
  subtitle,
  imageUrl,
  isActive,
  onClick,
  href,
}) => {
  const { t } = useLocalization('organizations-dashboard.account');

  return (
    <StyledLink
      isActive={isActive}
      onClick={onClick}
      href={href}
      data-testid="nav-link"
    >
      <Img src={imageUrl} data-testid="avatar" alt={t('avatar_alt')} />
      <Content>
        <Title data-testid="name">{title}</Title>
        <Subtitle data-testid="email">{subtitle}</Subtitle>
      </Content>
    </StyledLink>
  );
};

NavigationItemComponent.propTypes = {
  href: string.isRequired,
  imageUrl: string.isRequired,
  isActive: bool,
  onClick: func,
  subtitle: string.isRequired,
  title: string.isRequired,
};

export default withLocalizationProvider(NavigationItemComponent);
