import { useLocalization } from '@features/localization';
import { getPluralForm } from '@features/localization/helpers';
import { Text } from '@hopin-team/ui-text';
import get from 'lodash/get';
import { array, object } from 'prop-types';
import React, { useState } from 'react';

import { TabWrapper } from '../event-links-styles';
import LinkField from '../partials/link-field';
import StageSelect from '../partials/stage-select-component';

const HopinStudioBackstageLinksTab = ({ backstageOptions, links }) => {
  const [currentBackstage, setCurrentBackstage] = useState(
    get(backstageOptions, '[0].value', ''),
  );
  const { t, language } = useLocalization('overview.event-links.modal.tabs');
  const pluralForm = getPluralForm(language, backstageOptions?.length ?? 0);

  const { hopinStudioUrl, stageUrl } = links[currentBackstage];
  const showDescription =
    backstageOptions.length > 1 ||
    (backstageOptions.length == 1 && hopinStudioUrl);

  return (
    <TabWrapper data-testid="event-links-tabpanel">
      {showDescription && (
        <Text element="p" pattern="body" color="grey-600" weight="regular">
          {t('hopin-studio.description')}
        </Text>
      )}
      {backstageOptions.length > 1 && (
        <StageSelect
          options={backstageOptions}
          setCurrentBackstage={setCurrentBackstage}
          currentBackstage={currentBackstage}
        />
      )}

      {hopinStudioUrl && (
        <LinkField
          link={hopinStudioUrl}
          label={'hopinStudioUrl'}
          key={'hopinStudioUrl'}
        />
      )}

      {!hopinStudioUrl && (
        <Text
          mt={showDescription ? 1 : 0}
          element="p"
          pattern="body"
          color="grey-600"
          weight="regular"
          dangerouslySetInnerHTML={{
            __html: t(
              `hopin-studio.studio-requires-setup.plurals.${pluralForm}`,
              { stageUrl },
            ),
          }}
        ></Text>
      )}
    </TabWrapper>
  );
};

HopinStudioBackstageLinksTab.propTypes = {
  backstageOptions: array.isRequired,
  links: object.isRequired,
};

export default HopinStudioBackstageLinksTab;
