import { timezonesMap } from '@features/constants/timezones-map';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { utcToZonedTime } from 'date-fns-tz';
import { string } from 'prop-types';
import React from 'react';

import { Capitalized, Dot, DotWrapper } from './styles';

const Status = ({ statusKey, date, timezone }) => {
  const { t, formatDate } = useLocalization('email-dashboard.list.status');
  const statusLabel = {
    draft: t('draft'),
    sending: t('sending'),
    sent: t('sent'),
    scheduled: t('scheduled'),
  }[statusKey];

  const color = {
    draft: 'var(--color-gray-400)',
    sending: 'var(--color-blue-400)',
    sent: 'var(--color-green-500)',
    scheduled: 'var(--color-yellow-300)',
  }[statusKey];

  return (
    <Flex>
      <DotWrapper>
        <Dot color={color} data-testid="announcment-status-dot" />
      </DotWrapper>
      <Flex flexDirection="column">
        <Capitalized>{statusLabel}</Capitalized>
        {statusKey !== 'draft' &&
          (timezone ? (
            <span>
              {formatDate(
                utcToZonedTime(date, timezonesMap[timezone]),
                'MMM d - hh:mm aa',
              )}{' '}
              ({timezone})
            </span>
          ) : (
            <span>{formatDate(new Date(date), 'MMM d - hh:mm aa')}</span>
          ))}
      </Flex>
    </Flex>
  );
};

Status.propTypes = {
  statusKey: string.isRequired,
  date: string,
  timezone: string,
};

export default Status;
