import eventDashboardApi from '@api/event-dashboard';
import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { object, setLocale, string } from 'yup';

const useMarketingSettingsForm = ({
  id,
  attributes: { analyticsCode, shortDescription },
  onError,
  onSuccess,
}) => {
  const { t } = useLocalization('marketing-settings');

  setLocale({
    mixed: {
      required: t('cannot-be-empty'),
    },
  });

  const maxLength = 120;

  const schema = object().shape({
    marketing_settings: object().shape({
      analytics_code: string(),
      short_description: string().max(
        maxLength,
        t('length-validation', {
          maxLength,
        }),
      ),
    }),
  });

  const defaultValues = {
    marketing_settings: {
      analytics_code: analyticsCode,
      short_description: shortDescription,
    },
  };

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleHookFormSubmit = async values => {
    try {
      const { data, errors } = await eventDashboardApi.updateMarketingSettings(
        id,
        values.marketing_settings,
      );

      if (data) {
        onSuccess(data);
      } else if (errors) {
        onError(errors);
      }
    } catch (err) {
      onError(
        new Array({
          detail: t('something-went-wrong'),
        }),
      );
    }
  };

  return {
    onSubmit: handleSubmit(handleHookFormSubmit),
    ...methods,
  };
};

export default useMarketingSettingsForm;
