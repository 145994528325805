import { addMonths, addSeconds, set } from 'date-fns';

/* :: () -> Date */
export const getDefaultStartDate = () => {
  const currentDate = new Date();
  const startDate = set(addMonths(currentDate, 1), {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return startDate;
};

/* :: number -> Date */
export const getDefaultEndDate = maximumDuration => {
  const endDate = addSeconds(getDefaultStartDate(), maximumDuration);

  return endDate;
};
