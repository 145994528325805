import Loading from '@components/loading';
import Alerts from '@features/alerts/alerts';
import { withLocalizationProvider } from '@features/localization';
import { RECORDING_CATEGORY } from '@features/recordings/constants';
import { NoResults } from '@features/recordings/no-results';
import { RecordingCategoryTabHeader } from '@features/recordings/recording-category-tab-header/recording-category-tab-header';
import { FullHeightFlex } from '@features/recordings/recording-group/partials/full-height-flex.styled';
import { withAuthProvider } from '@features/recordings/redux/slices/auth/with-auth';
import { selectEvent } from '@features/recordings/redux/slices/event/event-selectors';
import { withEventProvider } from '@features/recordings/redux/slices/event/with-event';
import { withFlagsProvider } from '@features/recordings/redux/slices/flags/with-flags';
import { fetchRecordingCutsThunk } from '@features/recordings/redux/slices/recording-cuts/recording-cuts-actions';
import {
  selectRecordingCutIds,
  selectRecordingCutsFetchingState,
} from '@features/recordings/redux/slices/recording-cuts/recording-cuts-selectors';
import { withReduxProvider } from '@features/recordings/redux/with-provider';
import { ReactIDProvider } from '@hopin-team/react-id';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { RecordingsPrimaryPageComponent } from './primary-page';

export const RecordingsPrimaryPage = props => {
  const { slug, started: eventStarted } = useSelector(selectEvent);
  const { isLoading } = useSelector(selectRecordingCutsFetchingState);
  const cutIds = useSelector(selectRecordingCutIds);
  const lengthOfCuts = cutIds.length;
  const dispatch = useDispatch();

  const rehearsalHref = Routes.rehearsalOrganisersEventRecordingsPath(slug);

  useEffect(() => {
    if (!eventStarted) {
      dispatch(fetchRecordingCutsThunk());
    }
  }, [dispatch, eventStarted]);

  return (
    <Router basename={`/organisers/events/${slug}/recordings`}>
      <ReactIDProvider>
        <FullHeightFlex flexDirection="column" alignItems="stretch">
          <Alerts />

          {!eventStarted && !lengthOfCuts ? (
            <Loading isLoading={isLoading}>
              <NoResults
                category={RECORDING_CATEGORY.MAIN}
                rehearsalHref={rehearsalHref}
                eventStarted={eventStarted}
              />
            </Loading>
          ) : null}

          {eventStarted || lengthOfCuts ? (
            <Route path="/:category?">
              <RecordingCategoryTabHeader />
              <RecordingsPrimaryPageComponent {...props} />
            </Route>
          ) : null}
        </FullHeightFlex>
      </ReactIDProvider>
    </Router>
  );
};

RecordingsPrimaryPage.propTypes = {
  flags: PropTypes.shape({
    customThumbnailsEnabled: PropTypes.bool.isRequired,
    contentEnableRecordingsTrimmingAnalytics: PropTypes.bool.isRequired,
  }).isRequired,
};

export default compose(
  withReduxProvider,
  withEventProvider,
  withLocalizationProvider,
  withAuthProvider,
  withFlagsProvider,
)(RecordingsPrimaryPage);
