import MoreHorizontal from '@components/icons/more-horizontal';
import { MenuItem } from '@components/menu/menu-component';
import Menu from '@components/menu/menu-container';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import Close from 'icons/close-large.svg';
import Copy from 'icons/copy.svg';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { css } from 'styled-components';

import DuplicateButtonContainer from './duplicate-button-container';
import { Styles } from './event-details-styles';
import PublishButtonContainer from './publish-button-container';

const EventDetails = ({
  event,
  onEventDelete,
  onPublish,
  onEventDuplicate,
  canPublishEvent,
}) => {
  const { canDelete, name, slug, startTime } = event;
  const { t, formatDate } = useLocalization('event-dashboard.sidenav.options');

  const startTimeFormatted =
    startTime && formatDate(new Date(startTime), `EEE',' LLL d',' h:mm aaa'`);

  return (
    <div>
      <Styles.EventDetails>
        <Text element="p" pattern="headingThree" font="display" mb={0.5}>
          {name}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          css={css`
            width: 100%;
          `}
        >
          <Text element="p" color="grey-800" mb={0.5} pattern="body">
            {startTimeFormatted}
          </Text>
          {canDelete && (
            <Menu
              data-testid="menu-wrapper"
              buttonContents={
                <Styles.DotsWrapper>
                  <MoreHorizontal />
                </Styles.DotsWrapper>
              }
              fixed
              position="right"
            >
              <MenuItem>
                <a href={Routes.organisersEventDuplicationsPath(slug)}>
                  <Copy />
                  {t(`duplicate-event`)}
                </a>
              </MenuItem>
              {canDelete && (
                <MenuItem onClick={onEventDelete}>
                  <button>
                    <Close />
                    {t(`delete-event`)}
                  </button>
                </MenuItem>
              )}
            </Menu>
          )}
        </Flex>
      </Styles.EventDetails>
      {!event.isLite || (event.isLite && canPublishEvent === false) ? (
        <>
          <Box px={3} mt={2} mb={1}>
            <PublishButtonContainer
              onClick={onPublish}
              canPublishEvent={canPublishEvent}
            />
          </Box>

          <Box px={3} mb={1}>
            <DuplicateButtonContainer onClick={onEventDuplicate} />
          </Box>
        </>
      ) : null}
    </div>
  );
};

EventDetails.propTypes = {
  event: shape({
    name: string.isRequired,
    image: string,
    startTime: string.isRequired,
    slug: string.isRequired,
    canDelete: bool.isRequired,
  }).isRequired,
  onEventDelete: func.isRequired,
  onPublish: func.isRequired,
  onEventDuplicate: func.isRequired,
  canPublishEvent: bool,
};

export default EventDetails;
