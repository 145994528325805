import { parse as _parse } from 'papaparse';

export const parse = file => {
  return new Promise((resolve, reject) => {
    _parse(file, {
      skipEmptyLines: true,
      transformHeader: header => header.trim(),
      complete: results => {
        if (!results.errors.length && results.data.length > 1) {
          const [headers, ...rows] = results.data;
          const items = rows.map(row =>
            row.reduce((a, b, index) => ({ ...a, [headers[index]]: b }), {}),
          );
          resolve(items);
        } else {
          reject(new Error('Error parsing file'));
        }
      },
      error: e => {
        reject(e);
      },
    });
  });
};
