import * as Routes from '@routes';

export const getPageConstants = (t, videoSessionsEnableLayoutV2) => ({
  error: {
    heading: t('empty-state-component.error.heading'),
    descriptionText: t('empty-state-component.error.description'),
  },
  expo: {
    heading: t('empty-state-component.expo.heading'),
    descriptionText: t('empty-state-component.expo.description'),
    buttonText: t('empty-state-component.expo.button'),
    bulkCreateButtonText: t('empty-state-component.expo.button-bulk-create'),
    route: Routes.newOrganisersEventVendorPath,
  },
  attendees: {
    heading: t('empty-state-component.attendees.heading'),
    notLiveDescriptionText: t(
      'empty-state-component.attendees.not-live-description',
    ),
    descriptionText: t('empty-state-component.attendees.description'),
    buttonText: t('empty-state-component.attendees.button'),
    route: Routes.eventPath,
    routeMagicLinks: Routes.organisersEventRedeemCodesPath,
  },
  sessions: {
    heading: t('empty-state-component.sessions.heading'),
    descriptionText: videoSessionsEnableLayoutV2
      ? t('empty-state-component.sessions.description_50_participants')
      : t('empty-state-component.sessions.description'),
    buttonText: t('empty-state-component.sessions.button'),
    route: Routes.newOrganisersEventTimetableRoundtablePath,
  },
  speakers: {
    heading: t('empty-state-component.speakers.heading'),
    notLiveDescriptionText: t('empty-state-component.speakers.heading'),
    descriptionText: t('empty-state-component.speakers.not-live-description'),
    buttonText: t('empty-state-component.speakers.button'),
    route: Routes.newOrganisersEventSpeakerPath,
  },
  sponsors: {
    heading: t('empty-state-component.sponsors.heading'),
    descriptionText: t('empty-state-component.sponsors.description'),
    buttonText: t('empty-state-component.sponsors.button'),
    route: Routes.newOrganisersEventSponsorPath,
  },
  registrationFields: {
    heading: t('empty-state-component.registration-fields.heading'),
    descriptionText: t('empty-state-component.registration-fields.description'),
    buttonText: t('empty-state-component.registration-fields.button'),
    route: Routes.newOrganisersEventRegistrationFieldPath,
  },
  tickets: {
    heading: t('empty-state-component.tickets.heading'),
    descriptionText: t('empty-state-component.tickets.description'),
    buttonText: t('empty-state-component.tickets.button'),
  },
  app: {
    heading: t('empty-state-component.app.heading'),
    descriptionText: t('empty-state-component.app.description'),
    buttonText: t('app-area.emptyStateButton'),
  },
  badges: {
    heading: t('empty-state-component.badges.heading'),
    descriptionText: t('empty-state-component.badges.description'),
    buttonText: t('empty-state-component.badges.button'),
  },
  'kiosk-mode': {
    heading: t('empty-state-component.kiosk-mode.heading'),
    descriptionText: t('empty-state-component.kiosk-mode.description'),
    buttonText: t('empty-state-component.kiosk-mode.button'),
  },
  signatures: {
    heading: t('empty-state-component.signatures.heading'),
    descriptionText: t('empty-state-component.signatures.description'),
    buttonText: t('empty-state-component.signatures.button'),
  },
});
