import { Butticon } from '@hopin-team/ui-butticon';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const StyledLabel = styled(FieldLabel)`
  & label {
    color: var(--color-gray-650);
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const IconButtonContainer = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
})`
  height: 100%;
  position: absolute;
  right: var(--spacing-4);
  top: 0;
`;

export const StyledButticon = styled(Butticon).attrs({ p: 0.2 })`
  height: var(--font-size-small);
  width: var(--font-size-small);
`;
