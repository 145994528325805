import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  type: '',
  attributes: {},
  relationships: {},
};

const { actions, reducer } = createSlice({
  name: 'eventLinks',
  initialState,
  reducers: {
    setEventLinks: (state, action) => action.payload.eventLinks,
  },
});

export const getRegistrationUrl = eventExternalId => ({ eventLinks }) =>
  eventLinks[eventExternalId]?.attributes.registrationUrl;

export const getRtmpAllowed = eventExternalId => ({ eventLinks }) =>
  eventLinks[eventExternalId]?.meta?.rtmpAllowed;

export const { setEventLinks } = actions;

export default reducer;
