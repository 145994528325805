import { object, string } from 'prop-types';
import React from 'react';

import { Container } from './generic-badge.styles';

const GenericBadge = ({ label, styling }) => (
  <Container
    height={styling?.height}
    textColor={styling?.textColor}
    backgroundColor={styling?.backgroundColor}
  >
    {label}
  </Container>
);

GenericBadge.propTypes = {
  testId: string,
  label: string,
  styling: object,
};

export default GenericBadge;
