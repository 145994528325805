import { useLocalization } from '@features/localization';
import { ToastMessage } from '@features/registrations/components';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Grid } from '@hopin-team/ui-grid';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import { SWITCH_TICKET_MESSAGES_MAP } from './switch-ticket-messages-map';

export const UpdateTicketModal = ({
  name,
  code,
  newTicket,
  oldTicket,
  onConfirm,
  onCancel,
  isShowing,
}) => {
  const { t } = useLocalization('people.attendees.attendees-tab.update-ticket');
  const codeMessage = SWITCH_TICKET_MESSAGES_MAP[code];

  return (
    <Modal
      size="large"
      isShowing={isShowing}
      onClose={onCancel}
      withSeparator
      footer={
        <Grid gridColumnGap={3} gridTemplateColumns="repeat(2, 1fr)">
          <Button
            data-testid="ticket-update-cancel"
            size="medium"
            pattern="secondary"
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid="ticket-update-confirm"
            size="medium"
            onClick={onConfirm}
          >
            {t('confirm')}
          </Button>
        </Grid>
      }
    >
      <Box p={3}>
        <Text as="h4" mb={1}>
          {t('title', { name })}
        </Text>
        <p
          dangerouslySetInnerHTML={{
            __html: t('subtitle', {
              oldTicket: oldTicket?.name,
              newTicket: newTicket?.name,
            }),
          }}
        ></p>
        {codeMessage && (
          <ToastMessage
            data-testid="ticket-update-info"
            px={3}
            py={2}
            mb={3}
            icon="info-circle"
            colorPattern="primary"
            iconColor="blue-400"
          >
            <Text pattern="strong">{t(codeMessage)}</Text>
          </ToastMessage>
        )}
        <ToastMessage
          data-testid="ticket-update-warning"
          px={3}
          py={2}
          icon="warning"
          colorPattern="warning"
          iconColor="yellow-600"
          content={t('warning')}
        ></ToastMessage>
      </Box>
    </Modal>
  );
};

UpdateTicketModal.propTypes = {
  name: string,
  code: string,
  newTicket: shape({ name: string }),
  oldTicket: shape({ name: string }),
  onConfirm: func,
  onCancel: func,
  isShowing: bool,
};
