import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Menu } from '@hopin-team/ui-menu';
import { Pill } from '@hopin-team/ui-pill';
import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 10px;
  grid-template-areas: '. . .';
`;

export const FilterBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  flex-wrap: 1;
`;

export const PillContainer = styled(Flex)`
  max-height: 310px;
  overflow: auto;
`;

export const PillWrapper = styled(Flex)`
  cursor: pointer;
  margin-bottom: 0.1rem;

  ${Pill} {
    justify-content: flex-start;

    span {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    border-color: ${props =>
      props.selected ? 'var(--color-blue-400)' : 'var(--color-gray-400)'};
    background-color: ${props =>
      props.selected ? 'var(--color-blue-400);' : 'var(--color-white)'};
    color: ${props =>
      props.selected ? 'var(--color-white);' : 'var(--color-gray-700)'};
    font-weight: var(--font-weight-medium);

    &:hover {
      background-color: ${props =>
        props.selected ? 'var(--color-blue-400);' : 'var(--color-gray-100)'};
    }
  }
`;

export const MainContainer = styled(Flex)`
  position: relative;
  height: var(--input-height-small);
`;

export const OverlayFilterContainer = styled(Menu)`
  position: absolute;
  right: 0;
  top: 105%;
  width: 100%;
`;

export const DatePickerContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .nice-dates-day.-selected::before {
    background-color: var(--color-blue-400);
  }
`;

export const StyledHr = styled('hr')`
  margin-top: auto;
  margin-bottom: 0.5rem;
`;

export const FilterButton = styled(Button)`
  height: 100%;
  color: ${({ isAnyFilterActive }) =>
    isAnyFilterActive ? 'var(--color-blue-400)' : 'var(--color-gray-900)'};

  &:hover {
    color: ${({ isAnyFilterActive }) =>
      isAnyFilterActive ? 'var(--color-blue-400)' : 'var(--color-gray-900)'};
  }

  svg {
    margin-left: -0.5rem;
    color: var(--color-gray-600);
  }
`;
