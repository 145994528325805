import Tooltip from '@components/chart-tooltip';
import { useLocalization } from '@features/localization';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { hierarchy, stratify, Treemap, treemapSquarify } from '@visx/hierarchy';
import { Text } from '@visx/text';
import { useTooltip } from '@visx/tooltip';
import { array, number } from 'prop-types';
import React from 'react';

import { getNodeColor, getNodeTextLineCount, textFitsNode } from './utils';

// 7 is assumed to be the width occupied by each letter.
const letterWidth = 7;

const TreemapComponent = ({ width, height, data }) => {
  const { t } = useLocalization('live-analytics');

  let tooltipTimeout;
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const filteredData = data.filter(d => d.size != null);
  const minValue = Math.min(...filteredData.map(d => d.size));
  const maxValue = Math.max(...filteredData.map(d => d.size));

  const stratifiedData = stratify()
    .id(d => d.area)
    .parentId(d => d.parent)(data)
    .sum(d => d.size || 0);

  const root = hierarchy(stratifiedData).sort(
    (a, b) => (b.value || 0) - (a.value || 0),
  );

  return width < 10 ? null : (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill={'#FFFFFF'} />
        <Treemap
          root={root}
          size={[width, height]}
          tile={treemapSquarify}
          round
        >
          {treemap => (
            <Group>
              {treemap
                .descendants()
                .reverse()
                .map((node, i) => {
                  const nodeWidth = node.x1 - node.x0;
                  const nodeHeight = node.y1 - node.y0;
                  const verticalPadding = 20;
                  const lineHeight = 20;
                  const textWidthOffset = 30;
                  const nodeLines = getNodeTextLineCount(
                    node.data.id,
                    nodeWidth - textWidthOffset,
                    letterWidth,
                  );
                  const nodeColor = getNodeColor(
                    minValue,
                    maxValue,
                    node.value || 0,
                  );
                  const nodeTextColor = [0, 1].includes(nodeColor.value)
                    ? '#2C3242'
                    : '#FFFFFF';

                  const shouldShowText = textFitsNode(
                    node,
                    nodeLines,
                    lineHeight,
                    nodeHeight,
                    verticalPadding,
                    letterWidth,
                    nodeWidth,
                    textWidthOffset,
                  );

                  return (
                    <Group key={`node-${i}`} top={node.y0} left={node.x0}>
                      {node.depth === 1 && (
                        <>
                          <rect
                            data-testid={'treemap-node-' + i}
                            width={nodeWidth}
                            height={nodeHeight}
                            stroke={'#FFFFFF'}
                            fill={nodeColor.color}
                            onMouseMove={event => {
                              const { x, y } = localPoint(event);
                              if (tooltipTimeout) clearTimeout(tooltipTimeout);

                              showTooltip({
                                tooltipData: {
                                  name: node.data.id,
                                  value: node.data.value,
                                },
                                tooltipTop: y,
                                tooltipLeft: x,
                              });
                            }}
                            onMouseLeave={() => {
                              tooltipTimeout = window.setTimeout(() => {
                                hideTooltip();
                              }, 300);
                            }}
                          />

                          {shouldShowText && (
                            <>
                              <Text
                                x={'10'}
                                y={verticalPadding}
                                fill={nodeTextColor}
                                width={nodeWidth - textWidthOffset}
                                verticalAnchor={'start'}
                                fontWeight={400}
                                fontFamily={'Inter'}
                                fontSize={'14px'}
                                lineHeight={lineHeight}
                                cursor={'default'}
                              >
                                {node.data.id}
                              </Text>

                              <Text
                                x={'10'}
                                y={verticalPadding + 20}
                                dy={nodeLines * lineHeight}
                                fill={nodeTextColor}
                                fontSize={'18px'}
                                fontWeight={700}
                                fontFamily={'Inter'}
                                cursor={'default'}
                              >
                                {node.data.value}
                              </Text>
                            </>
                          )}
                        </>
                      )}
                    </Group>
                  );
                })}
            </Group>
          )}
        </Treemap>
      </svg>

      {tooltipOpen && tooltipData && (
        <Tooltip
          top={tooltipTop}
          left={tooltipLeft}
          data={tooltipData}
          description={t('treemap.number-of-attendees')}
        />
      )}
    </div>
  );
};

TreemapComponent.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  data: array.isRequired,
};

export default TreemapComponent;
