import { all, take } from 'redux-saga/effects';

import { setEvent } from '../../event/event-slice';
import { recordingsLiveUpdatesSaga } from './recordings-live-updates-saga';
import {
  trackRecordingDownload,
  trackRecordingUpdate,
} from './track-recording-events-saga';

export function* recordingsRootSaga() {
  yield take(setEvent);

  yield all([
    trackRecordingUpdate(),
    trackRecordingDownload(),
    recordingsLiveUpdatesSaga(),
  ]);
}
