import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import * as P from 'prop-types';
import React from 'react';

import EventExpoIcon from './icons/event-expo-icon.svg';
import EventNetworkingIcon from './icons/event-networking-icon.svg';
import EventReplayIcon from './icons/event-replay-icon.svg';
import EventSessionsIcon from './icons/event-sessions-icon.svg';
import EventStageIcon from './icons/event-stage-icon.svg';

const EVENT_PARTS_INFO = {
  stage: {
    name: 'basic-settings.event-parts.stage.name',
    description: 'basic-settings.event-parts.stage.description',
    icon: EventStageIcon,
  },
  sessions: {
    name: 'basic-settings.event-parts.sessions.name',
    description: 'basic-settings.event-parts.sessions.description',
    icon: EventSessionsIcon,
  },
  expo: {
    name: 'basic-settings.event-parts.expo.name',
    description: 'basic-settings.event-parts.expo.description',
    icon: EventExpoIcon,
  },
  networking: {
    name: 'basic-settings.event-parts.networking.name',
    description: 'basic-settings.event-parts.networking.description',
    icon: EventNetworkingIcon,
  },
  replay: {
    name: 'basic-settings.event-parts.replay.name',
    description: 'basic-settings.event-parts.replay.description',
    icon: EventReplayIcon,
  },
};

const EventAreaInput = ({
  eventPartType,
  onChange,
  checked = false,
  ...props
}) => {
  const { t } = useLocalization();
  const Icon = EVENT_PARTS_INFO[eventPartType].icon;

  const toggleArea = () => {
    onChange(!checked);
  };

  return (
    <Flex
      background={checked ? 'blue-100' : 'grey-white'}
      border={checked ? 'blue-300' : 'grey-300'}
      cornerRadius="lg"
      p={2}
      onClick={toggleArea}
      style={{ cursor: 'pointer' }}
      {...props}
    >
      <Box mr={2}>
        <input
          type="checkbox"
          aria-label={eventPartType}
          checked={checked}
          onChange={toggleArea}
          className="event_parts_selected"
        />
      </Box>

      <Box>
        <Text
          element="p"
          pattern="headingSix"
          color="grey-800"
          mb={1}
          style={{ lineHeight: rem('18px') }}
        >
          {t(EVENT_PARTS_INFO[eventPartType].name)}
        </Text>

        <Text
          element="p"
          pattern="labelThree"
          color="grey-700"
          style={{ lineHeight: rem('16px') }}
        >
          {t(EVENT_PARTS_INFO[eventPartType].description)}
        </Text>
      </Box>

      <Box ml="auto">
        <Icon height={32} width={32} />
      </Box>
    </Flex>
  );
};

EventAreaInput.propTypes = {
  eventPartType: P.oneOf(Object.keys(EVENT_PARTS_INFO)).isRequired,
  onChange: P.func.isRequired,
  checked: P.bool,
};

export default EventAreaInput;
