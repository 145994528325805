import { rem } from 'polished';
import styled, { css } from 'styled-components';

const getTabsHeightStyle = props => {
  if (props.big) {
    return css`
      height: calc(var(--spacing-64) + var(--spacing-1));
    `;
  }

  return css`
    height: calc(var(--spacing-48) + var(--spacing-1));
  `;
};

export const getActiveStyles = props => {
  if (props.isActive) {
    return css`
      color: var(--color-text);
      box-shadow: inset 0 calc(-1 * var(--spacing-2)) 0 var(--color-primary-400);
    `;
  }
};

export const Wrapper = styled.div`
  box-shadow: inset 0 calc(-1 * var(--spacing-1)) 0 var(--color-gray-200);
  margin-bottom: var(--spacing-48);
`;

export const TabsContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rem(1120)};
  width: 100%;
  background-color: var(--color-white);
  box-shadow: inset 0 calc(-1 * var(--spacing-1)) 0 var(--color-line-light);
  display: flex;
  overflow-x: auto;
  ${getTabsHeightStyle}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabPanel = styled.a`
  align-items: center;
  box-shadow: inset 0 calc(-1 * var(--spacing-2)) 0 transparent;
  color: var(--color-text-light);
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-weight: var(--font-weight-medium);
  justify-content: center;
  padding: 0 var(--spacing-8);
  transition-property: box-shadow, color;

  &:hover {
    color: var(--color-text);
  }

  &.focus-visible {
    box-shadow: inset 0 0 0 var(--spacing-2) var(--color-primary-400) !important;
    outline: none;
  }

  ${getActiveStyles}
`;
