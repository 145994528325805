import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import Image from 'icons/post-signup/verification.svg';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import ChallengeModal from './challenge-modal';
import SuccessModal from './success-modal';

const translationKeyPrefix = 'confirmation';

const ChallengeComponent = ({
  email,
  confirmed,
  afterSuccessfulConfirmationPath,
  oauthBrand,
}) => {
  const { addAlert } = useContext(AlertsContext);
  const { t } = useContext(LocalizationContext);

  const [isConfirmed, setConfirmed] = useState(confirmed);

  const onConfirmation = () => setConfirmed(true);

  const image = <Image />;

  const challengeModal = {
    addAlert,
    email,
    image,
    onConfirmation,
    t: (k, args) => t(`${translationKeyPrefix}.form${k}`, args),
    oauthBrand,
  };

  const successModal = {
    image,
    t: (k, args) => t(`${translationKeyPrefix}.success${k}`, args),
    ctaPath: afterSuccessfulConfirmationPath,
  };

  return (
    <>
      <Alerts />
      {isConfirmed ? (
        <SuccessModal {...successModal} />
      ) : (
        <ChallengeModal {...challengeModal} />
      )}
    </>
  );
};

ChallengeComponent.propTypes = {
  email: string.isRequired,
  confirmed: bool.isRequired,
  oauthBrand: string,
  afterSuccessfulConfirmationPath: string.isRequired,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
)(ChallengeComponent);
