import TimeZoneMessage from '@components/time-zone-message/time-zone-message';
import { Grid } from '@hopin-team/ui-grid';
import { calcSpace } from '@hopin-team/ui-symbols';
import { object, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import PreEventTopMetrics from './components/pre-event-top-metrics';
import RecentRegistrations from './components/recent-registrations';
import RegistrationsByTicketType from './components/registrations-by-ticket-type';
import RegistrationsOverTime from './components/registrations-over-time';
import TopCountriesByRegistrations from './components/top-countries-by-registrations';

const PreEventGrid = styled(Grid)`
  > div {
    :nth-child(1) {
      grid-area: 1 / 1 / 2 / 7;
    }
    :nth-child(2) {
      grid-area: 2 / 1 / 3 / 7;
      margin-bottom: calc(-1 * var(--spacing-12));
    }
    :nth-child(3) {
      grid-area: 3 / 1 / 4 / 5;
    }
    :nth-child(4) {
      grid-area: 3 / 5 / 4 / 7;
    }
    :nth-child(5) {
      grid-area: 4 / 1 / 5 / 4;
    }
    :nth-child(6) {
      grid-area: 4 / 4 / 5 / 7;
    }
  }
`;

const PreEventComponent = ({
  eventSlug,
  eventExternalId,
  eventTimezone,
  eventWizardTemplate,
  eventAreas,
}) => {
  return (
    <PreEventGrid
      gridTemplateColumns="repeat(6, 1fr)"
      gridTemplateRows="repeat(2, auto)"
      gridGap={calcSpace(3)}
      my={3}
    >
      <PreEventTopMetrics
        eventSlug={eventSlug}
        eventWizardTemplate={eventWizardTemplate}
        eventAreas={eventAreas}
      />
      <TimeZoneMessage eventTimezone={eventTimezone} />
      <RegistrationsOverTime
        eventSlug={eventSlug}
        eventTimezone={eventTimezone}
      />
      <RecentRegistrations eventSlug={eventSlug} />
      <TopCountriesByRegistrations eventExternalId={eventExternalId} />
      <RegistrationsByTicketType eventSlug={eventSlug} />
    </PreEventGrid>
  );
};

PreEventComponent.propTypes = {
  eventSlug: string.isRequired,
  eventExternalId: string.isRequired,
  eventTimezone: string.isRequired,
  eventWizardTemplate: string,
  eventAreas: object,
};

export default PreEventComponent;
