import { rem } from 'polished';
import styled from 'styled-components';

const FilterAndOperationsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: ${rem(32)};
  margin: ${rem(16)} 0;
`;

export default FilterAndOperationsWrapper;
