import ImageFileInput from '@components/image-file-input';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import P from 'prop-types';
import React from 'react';

const getInputName = fieldName =>
  `event[custom_event_application_attributes][${fieldName}]`;

const EventCustomApp = ({ customEvent, iconUrl, showForm }) => {
  const { t } = useLocalization('event-custom-app');

  if (!showForm) {
    return (
      <>
        <input
          type="hidden"
          name={getInputName('id')}
          defaultValue={customEvent?.id}
        />
        <input
          type="hidden"
          name={getInputName('reset_custom_application')}
          defaultValue={true}
        />
        {/* @NOTE Reset values if custom area removed. This might not be needed if done in BE */}
        <input type="hidden" name={getInputName('title')} defaultValue="" />
        <input type="hidden" name={getInputName('url')} defaultValue="" />
      </>
    );
  }

  return (
    <>
      <input
        type="hidden"
        name={getInputName('id')}
        defaultValue={customEvent?.id}
      />
      <input
        type="hidden"
        name={getInputName('reset_segment')}
        defaultValue={false}
      />
      <Text element="h2" mt={5} mb={1} pattern="headingFour">
        {t('header')}
      </Text>
      <TextField
        name={getInputName('title')}
        defaultValue={customEvent?.title}
        label={t('title')}
        mb={2.5}
        size="small"
        minLength={3}
      />
      <ImageFileInput
        name={getInputName('icon')}
        placeholderImageSrc={iconUrl}
        label={t('icon')}
        mb={2.5}
      />
      <TextField
        name={getInputName('url')}
        defaultValue={customEvent?.url}
        label={t('url')}
        mb={2.5}
        size="small"
      />
    </>
  );
};

EventCustomApp.propTypes = {
  customEvent: P.shape({
    id: P.number,
    title: P.string,
    url: P.string,
  }),
  iconUrl: P.string,
  showForm: P.bool,
};

export default withLocalizationProvider(EventCustomApp);
