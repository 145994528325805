import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  headline: '',
  last_name: '',
  first_name: '',
};

const template = createSlice({
  name: 'speakers',
  initialState,
  reducers: {
    setSpeakerDetails: (state, action) => action.payload,
  },
});

const {
  reducer,
  actions: { setSpeakerDetails },
} = template;

export { setSpeakerDetails };

export default reducer;
