import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

/**
 * The margin bottom is to help with showing
 * the action menu that's opening on the last row.
 */
export const Container = styled(Flex).attrs({
  mt: 3,
  mb: 8,
  flexDirection: 'column',
})``;
