import { NameFieldsContainer } from '@features/event-creation-wizard/steps/speakers/speaker-form.styles';
import { useLocalization } from '@features/localization';
import { TextField } from '@hopin-team/ui-text-field';
import { func, object } from 'prop-types';
import React from 'react';

const SpeakerFormComponent = ({ register, errors = {} }) => {
  const { t } = useLocalization('event-creation-wizard.steps.speakers.form');

  return (
    <>
      <NameFieldsContainer>
        <TextField
          mb={3.5}
          ref={register}
          name="first_name"
          data-testid="first-name"
          label={t('first-name.label')}
          hasErrors={Boolean(errors['first_name'])}
          placeholder={t('first-name.placeholder')}
          errorMessage={errors['first_name']?.message}
        />

        <TextField
          mb={3.5}
          ml={3}
          ref={register}
          name="last_name"
          data-testid="last-name"
          label={t('last-name.label')}
          hasErrors={Boolean(errors['last_name'])}
          placeholder={t('last-name.placeholder')}
          errorMessage={errors['last_name']?.message}
        />
      </NameFieldsContainer>

      <TextField
        mb={3.5}
        name="email"
        ref={register}
        data-testid="email"
        label={t('email.label')}
        placeholder={t('email.placeholder')}
        hasErrors={Boolean(errors?.email)}
        errorMessage={errors?.email?.message}
      />

      <TextField
        mb={3.5}
        ref={register}
        name="headline"
        data-testid="headline"
        label={t('headline.label')}
        placeholder={t('headline.placeholder')}
      />
    </>
  );
};

SpeakerFormComponent.propTypes = {
  errors: object,
  register: func.isRequired,
};

export default SpeakerFormComponent;
