import { numberFormatter } from '@features/email/shared';
import { useLocalization } from '@features/localization';
import { DeprecatedSelect as Select } from '@hopin-team/ui-select';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { iconAccessibilityProps } from './icon-accessibility-props';
import {
  MultiValueWarningIcon,
  StyledCustomOption,
  StyledMultiValueContainer,
  StyledMultiValueContent,
  StyledSelectContainer,
  StyledTicketCount,
} from './styles';

const SelectedStatusContext = React.createContext();

const CustomOption = ({ children, data, innerProps, isDisabled }) => {
  const status = useContext(SelectedStatusContext);
  let total = 0;
  const getCount = () => {
    const statusData = {
      registered_count: 0,
      attended_count: 0,
      unattended_count: 0,
      magic_link_invited_count: 0,
      ...data.attendees_status_data,
    };
    if (status === 'registered') {
      total = statusData.registered_count;
    } else if (status === 'attended') {
      total = statusData.attended_count;
    } else if (status === 'unattended') {
      total = statusData.unattended_count;
    } else {
      total = statusData.magic_link_invited_count;
    }

    return numberFormatter(total);
  };

  return !isDisabled ? (
    <StyledCustomOption {...innerProps}>
      {children}
      <StyledTicketCount>{getCount()}</StyledTicketCount>
    </StyledCustomOption>
  ) : null;
};

CustomOption.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
};

const CustomMultiValueContainer = ({ children, data, innerProps }) => {
  const { t } = useLocalization('email-dashboard.edit');
  return (
    <StyledMultiValueContainer isInvalid={data.notAvailable}>
      <ToolTip
        align="top"
        hideTip={!data.notAvailable}
        tip={t('ticket_not_available')}
      >
        <StyledMultiValueContent {...innerProps}>
          {data.notAvailable && (
            <MultiValueWarningIcon data-testid="invalid-ticket-icon" />
          )}
          {children}
        </StyledMultiValueContent>
      </ToolTip>
    </StyledMultiValueContainer>
  );
};

CustomMultiValueContainer.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  innerProps: PropTypes.object,
};

export const TicketSelect = ({ tickets, status, value, onChange }) => {
  const { t } = useLocalization('email-dashboard.edit');

  const ticketOptions = useMemo(() => {
    return tickets.map(item => ({ ...item, value: item.id }));
  }, [tickets]);

  const selected = useMemo(() => {
    return value.map(item => {
      return (
        ticketOptions.find(ticket => ticket.value === item.id) || {
          ...item,
          value: item.id,
          notAvailable: true,
        }
      );
    });
  }, [ticketOptions, value]);

  return (
    <SelectedStatusContext.Provider value={status}>
      <StyledSelectContainer data-testid="audience-ticket-select">
        <Select
          name="ticketTypes"
          id="ticketTypes"
          classNamePrefix="audience-ticket"
          className={tickets.length ? '-has-tickets' : '-no-tickets'}
          isMulti
          isDisabled={!tickets.length}
          placeholder={
            !tickets.length ? t('no_tickets') : t('all_ticket_types')
          }
          iconAccessibilityProps={iconAccessibilityProps(t)}
          onChange={selectedOptions =>
            onChange(
              selectedOptions?.map(({ value, label }) => ({
                label,
                id: value,
              })),
            )
          }
          value={selected}
          options={ticketOptions}
          components={{
            Option: CustomOption,
            MultiValueContainer: CustomMultiValueContainer,
          }}
        ></Select>
      </StyledSelectContainer>
    </SelectedStatusContext.Provider>
  );
};

TicketSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  tickets: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  status: PropTypes.string,
  eventId: PropTypes.number,
};
