import 'chart.js';
import 'flatpickr/dist/themes/light.css';

import { useLocalization } from '@features/localization';
import { Select } from '@hopin-team/ui-select';
import useMomentLocale from '@util/hooks/use-moment-locale';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import PropTypes from 'prop-types';
import React from 'react';
import { LineChart } from 'react-chartkick';
import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';

import { graphColor60 } from '../dashboard/util/colors';

const dateFormat = 'M j h:i K';

const getFlatpickrTranslations = locale => {
  // en translation file doesnt exist
  if (locale.match(/^en/)) return;

  try {
    return require(`flatpickr/dist/l10n/${locale}.js`).default[locale];
  } catch (err) {
    // translations unavailable for locale
    getLogger(LOGGER_NAMES.ANALYTICS).error(err);
  }
};

const buildDatepickerOptions = locale => {
  const translations = getFlatpickrTranslations(locale);

  return {
    defaultDate: new Date(),
    dateFormat: dateFormat,
    ...(translations && { locale: translations }),
  };
};

const AreaSelect = styled(Select)`
  .hpn-Select__Label {
    display: none;
  }
`;

const HiddenLabel = styled.label`
  display: none;
`;

const ActiveUsersChartComponent = ({
  chartData,
  reloadChart,
  areaOptions,
  selectedArea,
  setSelectedArea,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  inProgress,
}) => {
  const { t, dateLocale } = useLocalization('live-analytics.chart');
  const datepickerOptions = buildDatepickerOptions(dateLocale.code);

  // load chart translations
  useMomentLocale(dateLocale.code);

  return (
    <div>
      <LineChart {...chartData} curve={false} colors={[graphColor60]} />
      <br />
      <form
        onSubmit={e => {
          e.preventDefault();
          reloadChart();
        }}
      >
        <div className="row mb-3">
          <div className="col-6">
            <AreaSelect
              data-testid="live-analytics-area-select"
              options={areaOptions}
              label={t('select.label')}
              hideLabel={true}
              placeholder={t('select.placeholder')}
              value={selectedArea}
              setValue={setSelectedArea}
              hideOptionsLabel={t('select.hide-options-label')}
              showOptionsLabel={t('select.show-options-label')}
            />
          </div>
          <div className="col-2">
            <HiddenLabel htmlFor="active-users-by-time-chart-date-from">
              {t('calendar.from.label')}
            </HiddenLabel>
            <Flatpickr
              id="active-users-by-time-chart-date-from"
              className="form-control input"
              data-enable-time
              value={startTime}
              onChange={time => setStartTime(time[0])}
              options={datepickerOptions}
            />
          </div>
          <div className="col-2">
            <HiddenLabel htmlFor="active-users-by-time-chart-date-to">
              {t('calendar.to.label')}
            </HiddenLabel>
            <Flatpickr
              id="active-users-by-time-chart-date-to"
              className="form-control input"
              data-enable-time
              value={endTime}
              onChange={time => setEndTime(time[0])}
              options={datepickerOptions}
            />
          </div>
          <div className="col-2">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={inProgress}
            >
              {inProgress ? t('loading') : t('show-chart')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

ActiveUsersChartComponent.propTypes = {
  chartData: PropTypes.object.isRequired,
  reloadChart: PropTypes.func.isRequired,
  areaOptions: PropTypes.array.isRequired,
  selectedArea: PropTypes.string,
  setSelectedArea: PropTypes.func.isRequired,
  startTime: PropTypes.instanceOf(Date),
  setStartTime: PropTypes.func.isRequired,
  endTime: PropTypes.instanceOf(Date),
  setEndTime: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default ActiveUsersChartComponent;
