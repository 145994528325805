/* global Rails */
import getLogger, { LOGGER_NAMES } from '@util/logger';
import Pusher from 'pusher-js';

export const EVENT_CREATE = 'create';
export const EVENT_UPDATE = 'update';
export const EVENT_UPDATE_ALL = 'update_all';
export const EVENT_DESTROY = 'destroy';
export const EVENT_SCHEDULE_DRAFT_UPDATE = 'event_update';

// Store the active channels
const channels = new Map();
export let pusher;
export const logger = getLogger(LOGGER_NAMES.SCHEDULES_PANEL);

const APP_KEY = process.env.PUSHER_KEY;
const CLUSTER = process.env.PUSHER_CLUSTER;

export const pusherInitialize = () => {
  pusher = new Pusher(APP_KEY, {
    cluster: CLUSTER,
    auth: { headers: { 'X-CSRF-Token': Rails.csrfToken() } },
    encrypted: true,
  });
};

/**
 * Binds the schedule handler to pusher
 * @param {string} eventId - Internal event id
 * @param {function} handler - Event handler function
 * @returns {function} - Function to delete the channel and unsubscribe from Pusher
 */
export const pusherBindSchedule = ({ eventId, handler }) => {
  if (!eventId || !handler) {
    logger.error('Incorrect pusher configuration');
    return;
  }
  try {
    const channelName = `${eventId}_schedule`;
    if (!channels.has(channelName)) {
      channels.set(channelName, pusher.subscribe(channelName));
    }
    const channel = channels.get(channelName);

    [
      EVENT_CREATE,
      EVENT_UPDATE,
      EVENT_UPDATE_ALL,
      EVENT_DESTROY,
      EVENT_SCHEDULE_DRAFT_UPDATE,
    ].forEach(event => channel.bind(event, payload => handler(event, payload)));

    return () => {
      pusher.unsubscribe(channelName);
      channels.delete(channelName);
    };
  } catch (err) {
    logger.error(err);
  }
};

export const _testing_ = {
  channels,
  logger,
  pusher,
  pusherBindSchedule,
  pusherInitialize,
};
