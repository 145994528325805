import Alerts from '@features/alerts/alerts';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Linkicon } from '@hopin-team/ui-linkicon';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AREA_TYPE, RANK, RECORDING_TYPE, WHEN } from '../constants';
import Filter from '../filter';
import { useRecordingsFilters } from '../hooks';
import { NoResults } from '../no-results';
import { RecordingGroupContainer } from '../recording-group/recording-group-container';
import { withAuthProvider } from '../redux/slices/auth/with-auth';
import {
  getEditThumbnailIsShowing,
  getEditThumbnailSelectedRecordingId,
} from '../redux/slices/edit-thumbnail/edit-thumbnail-selectors';
import {
  setIsShowing as setThumbnailSidePanelIsShowing,
  setRecordingId as setSelectedRecordingId,
} from '../redux/slices/edit-thumbnail/edit-thumbnail-slice';
import { selectEvent } from '../redux/slices/event/event-selectors';
import { withEventProvider } from '../redux/slices/event/with-event';
import { getRecordingById } from '../redux/slices/recordings/recordings-selectors';
import { withRegistrationProvider } from '../redux/slices/registration/with-registration';
import { withReduxProvider } from '../redux/with-provider';
import { ThumbnailSidePanelModal } from '../thumbnail-side-panel-modal';
import { generateUid, getAreaByUid } from '../utils/utils';
import { SecondaryHeader } from './partials/secondary-header';

/**
 * Logic for display
 * -----------------
 * Backups > when=in-event && rank=backup
 * Rehearsals > when=pre-event && rank=null, so we load main+backup recordings
 */

const mapTypeValues = {
  [RECORDING_TYPE.BACKUP]: {
    title: 'backup-title',
    subtitle: 'backup-subtitle',
    rank: RANK.BACKUP,
    when: WHEN.IN_EVENT,
    learnMoreLink:
      'https://events-support.ringcentral.com/hc/en-us/articles/360056080232-How-to-access-recordings',
  },
  [RECORDING_TYPE.REHEARSAL]: {
    title: 'rehearsal-title',
    subtitle: 'rehearsal-subtitle',
    rank: null,
    when: WHEN.PRE_EVENT,
    learnMoreLink:
      'https://events-support.ringcentral.com/hc/en-us/articles/360056080232-How-to-access-recordings',
  },
};

export const RecordingsSecondaryPageComponent = ({
  customThumbnailsEnabled,
  recordingType,
  currentArea,
  areas = [],
}) => {
  const { language, t } = useLocalization('recording-pages.secondary');
  // TODO: create separate selectors for the computed values
  const { slug, started, days = [], timeEnd } = useSelector(selectEvent);

  const dispatch = useDispatch();
  const isThumbnailSidePanelShowing = useSelector(getEditThumbnailIsShowing);
  const selectedRecordingId = useSelector(getEditThumbnailSelectedRecordingId);
  const selectedRecording = useSelector(state =>
    getRecordingById(state, selectedRecordingId, language),
  );

  const { title, subtitle, rank, when, learnMoreLink } = mapTypeValues[
    recordingType
  ];

  const showListView =
    (recordingType === RECORDING_TYPE.BACKUP && started) ||
    recordingType === RECORDING_TYPE.REHEARSAL;

  const showBackupUnavailable =
    recordingType === RECORDING_TYPE.BACKUP && !started;

  const showDateFilters = recordingType === RECORDING_TYPE.BACKUP;

  const [area, setArea] = useState(null);
  const [filterAreaOptions, setFilterAreaOptions] = useState(null);
  const { filters, onFilterChange } = useRecordingsFilters();

  useEffect(() => {
    if (!area) return;

    onFilterChange({ videoAreaId: generateUid(area) });

    try {
      const url = new URL(window.location);
      url.searchParams.set('areaId', generateUid(area));
      window.history.pushState({}, '', url);
    } catch (error) {
      console.warn('Error updating the URL', { area, error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    if (!areas?.length) return;
    const options = areas.map(area => ({
      value: generateUid(area),
      label: `${
        area.type === AREA_TYPE.STAGE
          ? t('select-stage-label')
          : t('select-session-label')
      }: ${area.name}`,
    }));
    setFilterAreaOptions(options);

    const validCurrentArea = currentArea
      ? getAreaByUid(areas, currentArea)
      : false;
    setArea(validCurrentArea ? validCurrentArea : areas[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentArea, areas]);

  const handleFilterChange = changes => {
    onFilterChange(changes);
    const { videoAreaId } = changes;

    if (videoAreaId) {
      const newArea = getAreaByUid(areas, videoAreaId);
      setArea(newArea);
    } else {
      console.warn('handleFilterChange: change not handled', { changes });
    }
  };

  const onRecordingSelect = recordingId => {
    dispatch(setSelectedRecordingId(recordingId));
    dispatch(setThumbnailSidePanelIsShowing(true));
  };

  const onThumbnailSidePanelClose = () => {
    dispatch(setThumbnailSidePanelIsShowing(false));
  };

  return (
    <Flex mt={3} flexDirection="column">
      <Alerts />
      <Linkicon
        data-testid="backLink"
        iconScale={1.5}
        scale={4}
        label={t('back-to-primary')}
        leadingIcon="arrow-back"
        href={slug ? Routes.organisersEventRecordingsPath(slug) : ''}
        mb="3"
      />

      <SecondaryHeader
        title={t(title)}
        subtitle={t(subtitle)}
        moreText={t('learn-more')}
        moreLink={learnMoreLink}
      />

      {showBackupUnavailable && (
        <NoResults
          recordingType={RECORDING_TYPE.BACKUP}
          rehearsalHref={
            slug ? Routes.rehearsalOrganisersEventRecordingsPath(slug) : ''
          }
          endTime={timeEnd}
          eventStarted={started}
        />
      )}
      {showListView && (
        <>
          <Box mt="4" mb="2.5">
            <Filter
              filters={filters}
              onFilterChange={handleFilterChange}
              dateOptions={showDateFilters ? days : null}
              videoAreaOptions={filterAreaOptions}
              showEventPartFilters={false}
              showViewFilters={false}
            />
          </Box>
          {area && (
            <RecordingGroupContainer
              recordingType={recordingType}
              noResultsStyle="large"
              videoArea={area}
              rank={rank}
              when={when}
              showTitle={false}
              showLinks={false}
              filters={filters}
              onRecordingSelect={onRecordingSelect}
              customThumbnailsEnabled={customThumbnailsEnabled}
            />
          )}
        </>
      )}

      {customThumbnailsEnabled && (
        <ThumbnailSidePanelModal
          isShowing={isThumbnailSidePanelShowing}
          onClose={onThumbnailSidePanelClose}
          recording={selectedRecording}
        />
      )}
    </Flex>
  );
};

RecordingsSecondaryPageComponent.propTypes = {
  customThumbnailsEnabled: PropTypes.bool.isRequired,
  recordingType: PropTypes.oneOf([
    RECORDING_TYPE.BACKUP,
    RECORDING_TYPE.REHEARSAL,
  ]).isRequired,
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.oneOf([AREA_TYPE.STAGE, AREA_TYPE.SESSION]),
      name: PropTypes.string,
    }),
  ),
  currentArea: PropTypes.string,
};

export default compose(
  withLocalizationProvider,
  withReduxProvider,
  withEventProvider,
  withRegistrationProvider,
  withAuthProvider,
)(RecordingsSecondaryPageComponent);
