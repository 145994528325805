import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: fit-content;
`;

export const Anchor = styled.button`
  color: var(--color-blue-400);
  text-decoration: underline;
  font-weight: var(--font-weight-medium);
`;
