import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { ImageUpload } from '@hopin-team/ui-image-upload';
import { Text } from '@hopin-team/ui-text';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { parse } from '../../helpers';
import { useCSVExport } from '../../use-csv-template-export';
import { ModalFooter } from '../modal-footer';
import { ModalHeader } from '../modal-header';

const FIVE_MEGA_BYTE = 5 * 1024 * 1024;

const FileSelection = ({ eventSlug, onFileSelect, cancel }) => {
  const { t } = useLocalization('expo.bulk-upload-modal.file-selection');

  const [file, setFile] = useState(null);
  const [processedFile, setProcessedFile] = useState(null);
  const [isProcessingError, setProcessingError] = useState(false);

  const fileName = `${t('template-file-name', { eventSlug })}.csv`;
  const { url: templateURL } = useCSVExport(fileName);

  const onRemoveFile = () => {
    setFile(null);
    setProcessingError(false);
  };

  const onSelect = async ([file]) => {
    if (!file) {
      onRemoveFile();
      return;
    }

    setFile(file);

    try {
      const results = await parse(file);
      setProcessedFile(results);
    } catch (e) {
      setProcessingError(true);
    }
  };

  const onNextClick = () => {
    if (isProcessingError) {
      onRemoveFile();
    } else if (processedFile) {
      onFileSelect(processedFile);
    }
  };

  const triggerCSVDownload = useCallback(() => {
    const link = document.createElement('a');

    link.href = templateURL;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();
    link.remove();
  }, [fileName, templateURL]);

  const descriptionComponent = <Text pattern="body">{t('description')}</Text>;

  return (
    <>
      <ModalHeader title={t('title')} description={descriptionComponent} />

      <Box mx={3} mb={2}>
        <Button
          onClick={triggerCSVDownload}
          size="medium"
          pattern="primary"
          isOutlined
          leadingIcon="download"
        >
          {t('template')}
        </Button>
      </Box>

      <Text element="p" pattern="caption" mx={3} mb={1}>
        {t('drop-area.title')}
      </Text>

      <ImageUpload
        onImagesChange={onSelect}
        width={'100%'}
        mx={3}
        mb={3}
        renderPreview={files => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              px={4}
              css={`
                width: 100%;
                height: 100%;
              `}
            >
              {files[0].name}
            </Flex>
          );
        }}
        messages={{
          orDropFile: t('drop-area.on-drop-label'),
          uploadImage: t('drop-area.cta-label'),
          dropFile: t('drop-area.drop-file'),
          fileHints: t('drop-area.file-hints'),
          maximumUploadSize: t('drop-area.max-upload-size'),
          generalError: t('drop-area.errors.general'),
          maxSizeError: t('drop-area.errors.max-file-size'),
          tooManyFilesError: t('drop-area.errors.too-many-files'),
          fileTypeError: t('drop-area.errors.file-type'),
        }}
        options={{
          accept: {
            'text/*': ['.csv'],
          },
          maxSize: FIVE_MEGA_BYTE,
          maxFiles: 1,
        }}
      />

      <ModalFooter
        disabled={!file}
        cancelClick={cancel}
        nextText={t('next')}
        nextClick={onNextClick}
      />
    </>
  );
};

FileSelection.propTypes = {
  eventSlug: PropTypes.string,
  onFileSelect: PropTypes.func,
  cancel: PropTypes.func,
};

export default compose(withLocalizationProvider)(FileSelection);
