import analyticsApi from '@api/analytics';
import Card from '@components/card-with-loading';
import TabbedChart from '@components/tabbed-chart';
import { LocalizationContext } from '@features/localization';
import { bool, number, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import ChartGranularitySelect from '../components/chart-granularity-select';
import {
  DEFAULT_GRANULARITY,
  useGranularityOptions,
} from '../constants/time-granularities';
import { usePromise } from '../util/hooks';

const hasChartData = result =>
  result &&
  result.chartData &&
  (Array.isArray(result.chartData)
    ? result.chartData.find(pair => pair[1] > 0) != null
    : Object.values(result.chartData).find(value => value > 0) != null);

const transformResults = (t, results, newRegistrationsDashboardEnabled) => {
  const [registrations, sales, refunds] = results;
  const filtered = [
    registrations,
    (hasChartData(sales) || newRegistrationsDashboardEnabled) && sales,
    hasChartData(refunds) && refunds,
  ]
    .filter(result => !!result)
    .map(result => ({
      ...result,
      summary: {
        ...result.summary,
        title: t(`event-dashboard.chart-titles.${result.summary.title}`),
      },
    }));
  return filtered;
};

const TabbedChartsContainer = ({
  eventId,
  startTime,
  endTime,
  initialGranularity,
  newRegistrationsDashboardEnabled,
}) => {
  const { t } = useContext(LocalizationContext);

  const [data, setData] = useState(null);
  const [tab, setTab] = useState(0);
  const [granularity, setGranularity] = useState(
    initialGranularity || DEFAULT_GRANULARITY,
  );
  const granularityOptions = useGranularityOptions(t);

  const loading = usePromise(
    signal => {
      const chartParams = { chartType: 'line', granularity };
      return [
        analyticsApi.getRegistrationsOverTime(eventId, signal, chartParams),
        analyticsApi.getTicketSalesOverTime(eventId, signal, chartParams),
        analyticsApi.getRefundAmountsOverTime(eventId, signal, chartParams),
      ];
    },
    results =>
      setData(transformResults(t, results, newRegistrationsDashboardEnabled)),
    [granularity],
  );

  const chartHeight = data && data.length == 3 ? 340 : 240;

  return (
    <Card isLoading={loading} testId="registration-tabbed-charts">
      {!loading && (
        <ChartGranularitySelect
          options={granularityOptions}
          value={granularity}
          onChange={setGranularity}
        />
      )}
      <TabbedChart
        chartType="line"
        tab={tab}
        setTab={setTab}
        data={data}
        startTime={startTime}
        endTime={endTime}
        unit={granularity}
        height={chartHeight}
      />
    </Card>
  );
};

TabbedChartsContainer.propTypes = {
  eventId: number.isRequired,
  startTime: string.isRequired,
  endTime: string.isRequired,
  initialGranularity: string,
  newRegistrationsDashboardEnabled: bool,
};

export default TabbedChartsContainer;
