import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { NextButton, Pagination, PrevButton } from '@hopin-team/ui-pagination';
import { number } from 'prop-types';
import React from 'react';

import {
  ButtonPlaceholder,
  PaginationNumbers,
} from './location-pagination-styles';

const PAGE_URL_PARAM = 'page';

const LocationPagination = ({ currentPage, totalPages }) => {
  const { t } = useLocalization();

  const gotoPage = page => {
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set(PAGE_URL_PARAM, page);
    window.location.search = queryParams.toString();
  };

  return (
    <Pagination
      currentPage={currentPage}
      onChange={gotoPage}
      totalPages={totalPages}
      nextLabel={t('pagination.next')}
      pageLabel={t('pagination.goto-page')}
      prevLabel={t('pagination.previous')}
      titleLabel={t('pagination.title')}
      currentLabel={t('pagination.current')}
    >
      {currentPage > 1 ? <PrevButton mr={1} /> : <ButtonPlaceholder />}
      <PaginationNumbers />
      {currentPage < totalPages ? <NextButton ml={1} /> : <ButtonPlaceholder />}
    </Pagination>
  );
};

LocationPagination.propTypes = {
  currentPage: number.isRequired,
  totalPages: number.isRequired,
};

export default withLocalizationProvider(LocationPagination);
