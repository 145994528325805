import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { STEPS } from '../constants';
import { EmailMarketing } from './email-marketing';
import { FileUpload } from './file-upload';

const ONE_MEGA_BYTE = 1024 * 1024;

export const ModalBodyV1 = ({
  step,
  eventSlug,
  onFilesChange,
  registrationEmailsDisabled,
  downloadSampleCsv,
}) => {
  const { t } = useLocalization('people.attendees.registrant-bulk-upload');

  if (step === STEPS.SUCCESS_CONFIRMATION) {
    return (
      <>
        <Text pattern="body" color="gray-650">
          {t('file-queued-processing')}
        </Text>
        {' - '}
        <Text pattern="strong" color="gray-650">
          {t('email-upon-completion')}
        </Text>
        <Text pattern="body" element="p" color="gray-650">
          {t('refresh-page')}
        </Text>
      </>
    );
  }

  return (
    <>
      {step === STEPS.CSV_UPLOAD && (
        <>
          <Text pattern="body" as="p" mb={1} color="grey-medium">
            {t('description')}
          </Text>
          <Box mb={2}>
            <Button
              onClick={downloadSampleCsv}
              size="medium"
              isOutlined
              leadingIcon="download"
            >
              {t('download-template-link')}
            </Button>
          </Box>

          <Text pattern="strong" mb={1} color="grey-medium">
            {t('instructions.title')}
          </Text>
          <Box as="ul" pl={2.4} data-testid="csv-file-requirements">
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.column-names')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.email-format')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.filled-cells')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.filled-rows')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.sensitive-data')}
              </Text>
            </li>
            <li>
              <Text pattern="caption" color="grey-medium">
                {t('instructions.external-barcode')}
              </Text>
            </li>
          </Box>

          <Text element="p" pattern="caption" mb={1}>
            {t('drop-area.title')}
          </Text>
        </>
      )}

      <FileUpload
        isVisible={step === STEPS.CSV_UPLOAD}
        maxSize={ONE_MEGA_BYTE}
        onChange={onFilesChange}
      />

      {step === STEPS.EMAIL_MARKETING && (
        <EmailMarketing
          registrationEmailsDisabled={registrationEmailsDisabled}
          eventSlug={eventSlug}
        ></EmailMarketing>
      )}
    </>
  );
};

ModalBodyV1.propTypes = {
  eventSlug: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  onFilesChange: PropTypes.func.isRequired,
  registrationEmailsDisabled: PropTypes.bool,
  downloadSampleCsv: PropTypes.func.isRequired,
};
