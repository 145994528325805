import { Modal } from '@hopin-team/ui-modal';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

export const Root = styled(Modal).attrs({
  isDismissible: true,
  withCloseButton: true,
})`
  // prevents grid blowout since this component centers using a grid flow
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);

  [aria-modal='true'] {
    width: ${calcSpace(125.5)};
    height: ${calcSpace(98.5)};
    max-width: calc(100% - ${calcSpace(6)});
    max-height: calc(100% - ${calcSpace(6)});

    > div {
      height: 100%;
      overflow-y: auto;
    }
  }
`;
