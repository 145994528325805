import CopyToClipboard from '@components/copy-to-clipboard';
import { useLocalization } from '@features/localization';
import { Icon } from '@hopin-team/ui-icons';
import { bool, func, string } from 'prop-types';
import React, { forwardRef } from 'react';

import { TrailingButtonInput } from './marketing-settings.styles';
import StaticTextInput from './static-text-input';

const UrlField = forwardRef(
  ({ baseUrl, currentSlug, name, passedHasErrors, onCopy, ...props }, ref) => {
    const { t } = useLocalization('marketing-settings.url-field');

    return (
      <TrailingButtonInput>
        <StaticTextInput
          {...props}
          hasErrors={passedHasErrors}
          leadingText={`${baseUrl}/`}
          name={name}
          ref={ref}
          sizeOption="medium"
        />
        <CopyToClipboard text={`${baseUrl}/${currentSlug}`} onCopy={onCopy}>
          <Icon icon="copy" title={t('copy')} width="24" />
        </CopyToClipboard>
      </TrailingButtonInput>
    );
  },
);

UrlField.propTypes = {
  baseUrl: string.isRequired,
  currentSlug: string.isRequired,
  name: string.isRequired,
  passedHasErrors: bool,
  onCopy: func,
};

export default UrlField;
