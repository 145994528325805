import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  countAllOccurencies,
  MASK_PLACEHOLDER,
  replaceAll,
  toSeconds,
  toTimestampString,
} from '../../utils';
import { TimestampInput } from '../timestamp-input';
import {
  IconButtonContainer,
  InputContainer,
  StyledButticon,
  StyledLabel,
} from './timestamp-field.styled';

const KEYS = {
  ARROW_UP: 38,
  ARROW_DOWN: 40,
};

export const TimestampField = ({
  deltaUnit,
  format = 'HH:mm:ss',
  label,
  max,
  min,
  onChange,
  value,
}) => {
  const [originalValue] = useState(value);
  const [inputValue, setInputValue] = useState(
    toTimestampString(value, format),
  );
  const fieldId = kebabCase(label);

  useEffect(() => {
    setInputValue(toTimestampString(value, format));
  }, [format, value]);

  // Triggering change in parent component
  const update = useCallback(
    newValue => {
      // general validation
      if (max !== undefined && newValue > max) {
        // input's value is set to bigger than max, go to max value
        onChange(max);
        setInputValue(toTimestampString(max, format));
      } else if (min !== undefined && newValue < min) {
        // input's value is set to lower than min, go to min value
        onChange(min);
        setInputValue(toTimestampString(min, format));
      } else {
        onChange(newValue);
        setInputValue(toTimestampString(newValue, format));
      }
    },
    [format, max, min, onChange],
  );

  // Button event handlers
  const handleIncrement = useCallback(() => {
    update(value + deltaUnit);
  }, [deltaUnit, value, update]);

  const handleDecrement = useCallback(() => {
    update(value - deltaUnit);
  }, [deltaUnit, value, update]);

  // Input event handlers
  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === KEYS.ARROW_UP) {
        handleIncrement();
      } else if (e.keyCode === KEYS.ARROW_DOWN) {
        handleDecrement();
      }
    },
    [handleIncrement, handleDecrement],
  );

  const handleInputChange = useCallback(e => {
    setInputValue(e.currentTarget.value);
  }, []);

  const handleBlur = useCallback(() => {
    // input mask specific validation
    const numberOfPlaceholdersInValue = countAllOccurencies(
      inputValue,
      MASK_PLACEHOLDER,
    );
    const lengthOfFormatWithoutSeparator = replaceAll(format, ':', '').length;

    if (numberOfPlaceholdersInValue === lengthOfFormatWithoutSeparator) {
      // input is empty, go back to default value
      update(originalValue);
    } else {
      // replace every empty value with 0
      const newValue = replaceAll(inputValue, MASK_PLACEHOLDER, '0');
      update(toSeconds(newValue));
    }
  }, [format, inputValue, originalValue, update]);

  return (
    <>
      <StyledLabel fieldId={fieldId} label={label} />
      <InputContainer>
        <TimestampInput
          format={format}
          id={fieldId}
          onBlur={handleBlur}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={inputValue}
        />
        <IconButtonContainer>
          <StyledButticon
            icon="arrow-up"
            title="Increment"
            onClick={handleIncrement}
            data-testid="increment-button"
          />
          <StyledButticon
            icon="arrow-down"
            title="Decrement"
            onClick={handleDecrement}
            data-testid="decrement-button"
          />
        </IconButtonContainer>
      </InputContainer>
    </>
  );
};

TimestampField.propTypes = {
  deltaUnit: PropTypes.number.isRequired,
  format: PropTypes.string,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
