import TimeZoneMessage from '@components/time-zone-message/time-zone-message';
import TopAttendeesByExpoBoothInteraction from '@features/overview/analytics/components/top-attendees-by-expo-booth-interaction';
import { Grid } from '@hopin-team/ui-grid';
import { calcSpace } from '@hopin-team/ui-symbols';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ChatMessagesBySegment from './components/chat-messages-by-segment';
import ExpoBoothKeyMetrics from './components/expo-booth-key-metrics';
import MostActiveAttendeesByMinutesSpent from './components/most-active-attendees-by-minutes-spent';
import RegistrationsActiveAttendeesOverTime from './components/registrations-active-attendees-over-time';
import TopAreasByAttendees from './components/top-areas-by-attendees';
import TopAreasByChatMessages from './components/top-areas-by-chat-messages';
import TopCountriesByRegistrations from './components/top-countries-by-registrations';
import TopExpoBoothsByInteraction from './components/top-expo-booths-by-interaction';
import TopMetrics from './components/top-metrics';

const LivePostEventGrid = styled(Grid)`
  > div {
    :nth-child(1) {
      grid-area: 1 / 1 / 2 / 3;
    }
    :nth-child(2) {
      grid-area: 2 / 1 / 3 / 3;
      margin-top: calc(-1 * var(--spacing-12));
      margin-bottom: calc(-1 * var(--spacing-12));
    }
    :nth-child(3) {
      grid-area: 3 / 1 / 4 / 3;
    }
    :nth-child(4) {
      grid-area: 4 / 1 / 5 / 2;
    }
    :nth-child(5) {
      grid-area: 4 / 2 / 5 / 3;
    }
    :nth-child(6) {
      grid-area: 5 / 1 / 6 / 2;
    }
    :nth-child(7) {
      grid-area: 5 / 2 / 6 / 3;
    }
  }
`;

const LivePostEventComponent = ({
  eventSlug,
  eventExternalId,
  eventPhase,
  eventTimezone,
}) => {
  return (
    <LivePostEventGrid
      gridTemplateColumns="repeat(2, 1fr)"
      gridTemplateRows="repeat(4, auto)"
      gridGap={calcSpace(3)}
      my={3}
    >
      <TopMetrics
        eventPhase={eventPhase}
        eventSlug={eventSlug}
        eventExternalId={eventExternalId}
      />
      <TimeZoneMessage eventTimezone={eventTimezone} />
      <RegistrationsActiveAttendeesOverTime
        eventSlug={eventSlug}
        eventPhase={eventPhase}
        eventTimezone={eventTimezone}
      />
      <TopAreasByChatMessages eventSlug={eventSlug} />
      <TopAreasByAttendees eventSlug={eventSlug} />
      {eventPhase == 'Ended' && <ExpoBoothKeyMetrics eventSlug={eventSlug} />}
      <ChatMessagesBySegment eventSlug={eventSlug} />
      <TopExpoBoothsByInteraction eventSlug={eventSlug} />
      <TopCountriesByRegistrations eventExternalId={eventExternalId} />
      <MostActiveAttendeesByMinutesSpent eventSlug={eventSlug} />
      <TopAttendeesByExpoBoothInteraction eventSlug={eventSlug} />
    </LivePostEventGrid>
  );
};

LivePostEventComponent.propTypes = {
  eventSlug: string.isRequired,
  eventExternalId: string.isRequired,
  eventPhase: string.isRequired,
  eventTimezone: string.isRequired,
};

export default LivePostEventComponent;
