import Loading from '@components/loading';
import { LocalizationContext } from '@features/localization';
import { useRecordingsFilters } from '@features/recordings/hooks';
import { RecordingGroupContainer } from '@features/recordings/recording-group/recording-group-container';
import {
  getEditThumbnailIsShowing,
  getEditThumbnailSelectedRecordingId,
} from '@features/recordings/redux/slices/edit-thumbnail/edit-thumbnail-selectors';
import {
  setIsShowing as setThumbnailSidePanelIsShowing,
  setRecordingId as setSelectedRecordingId,
} from '@features/recordings/redux/slices/edit-thumbnail/edit-thumbnail-slice';
import { selectEvent } from '@features/recordings/redux/slices/event/event-selectors';
import {
  selectVideoAreas,
  selectVideoAreasFetchingState,
} from '@features/recordings/redux/slices/recording-groups/recording-groups-selectors';
import { fetchRecordingAreasThunk } from '@features/recordings/redux/slices/recording-groups/recording-groups-slice';
import { getRecordingById } from '@features/recordings/redux/slices/recordings/recordings-selectors';
import {
  filterVideoAreasByEventPartType,
  getCategoryFromPathname,
} from '@features/recordings/utils/utils';
import { Box } from '@hopin-team/ui-box';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Virtuoso } from 'react-virtuoso';

import { RECORDING_CATEGORY } from '../constants';
import { Filter } from '../filter';
import { ThumbnailSidePanelModal } from '../thumbnail-side-panel-modal';
import { Container } from './primary-page.styled';

// TODO: full impllimentation of this is covered in another ticket
const ErrorMessage = ({ ...props }) => {
  return (
    <div {...props}>
      <p>Some helpful error message</p>
    </div>
  );
};

export const RecordingsPrimaryPageComponent = ({
  flags: { customThumbnailsEnabled, virtualisedRecordingsEnabled },
}) => {
  const { language } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const category = getCategoryFromPathname(window.location.pathname);

  const { slug, days } = useSelector(selectEvent);
  const isThumbnailSidePanelShowing = useSelector(getEditThumbnailIsShowing);
  const selectedRecordingId = useSelector(getEditThumbnailSelectedRecordingId);
  const selectedRecording = useSelector(state =>
    getRecordingById(state, selectedRecordingId, language),
  );
  const videoAreas = useSelector(selectVideoAreas);
  const { isDone, isLoading, isError } = useSelector(
    selectVideoAreasFetchingState,
  );

  const { filters, onFilterChange } = useRecordingsFilters();

  useEffect(() => {
    if (slug) {
      dispatch(fetchRecordingAreasThunk({ slug }));
    }
  }, [dispatch, slug]);

  const filteredVideoAreas = filterVideoAreasByEventPartType(
    videoAreas,
    filters?.eventPart,
  );

  const onRecordingSelect = recordingId => {
    dispatch(setSelectedRecordingId(recordingId));
    dispatch(setThumbnailSidePanelIsShowing(true));
  };

  const onThumbnailSidePanelClose = () => {
    dispatch(setThumbnailSidePanelIsShowing(false));
  };

  useEffect(() => {
    if (isDone) {
      const videoAreaId = hash.substring(1);
      const videoArea = document.querySelector(
        `[data-video-area-id="${videoAreaId}"]`,
      );
      if (videoArea) {
        videoArea.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isDone, hash]);

  return (
    <Loading isLoading={isLoading}>
      <Container>
        {isError && <ErrorMessage data-testid="recordingsErrorMsg" />}

        <Box
          pb={2}
          mb={4}
          border="grey-300"
          removeBorders={['borderTop', 'borderRight', 'borderLeft']}
        >
          <Filter
            filters={filters}
            onFilterChange={onFilterChange}
            dateOptions={days}
            showEventPartFilters={true}
          />
        </Box>

        {virtualisedRecordingsEnabled ? (
          <div style={{ height: '75vh' }}>
            <Virtuoso
              totalCount={filteredVideoAreas.length}
              overscan={200}
              itemContent={index => {
                const videoArea = filteredVideoAreas[index];
                return (
                  <div>
                    <RecordingGroupContainer
                      key={videoArea.id}
                      videoAreaId={videoArea.id}
                      category={category}
                      filters={{ ...filters }}
                      onRecordingSelect={onRecordingSelect}
                      customThumbnailsEnabled={customThumbnailsEnabled}
                      showLinks={category === RECORDING_CATEGORY.MAIN}
                    />
                  </div>
                );
              }}
            />
          </div>
        ) : (
          filteredVideoAreas?.map(videoArea => (
            <RecordingGroupContainer
              key={videoArea.id}
              videoAreaId={videoArea.id}
              category={category}
              filters={{ ...filters }}
              onRecordingSelect={onRecordingSelect}
              customThumbnailsEnabled={customThumbnailsEnabled}
              showLinks={category === RECORDING_CATEGORY.MAIN}
            />
          ))
        )}

        {customThumbnailsEnabled && (
          <ThumbnailSidePanelModal
            isShowing={isThumbnailSidePanelShowing}
            onClose={onThumbnailSidePanelClose}
            recording={selectedRecording}
          />
        )}
      </Container>
    </Loading>
  );
};

RecordingsPrimaryPageComponent.propTypes = {
  flags: PropTypes.shape({
    customThumbnailsEnabled: PropTypes.bool.isRequired,
    contentEnableRecordingsTrimmingAnalytics: PropTypes.bool.isRequired,
    virtualisedRecordingsEnabled: PropTypes.bool.isRequired,
  }).isRequired,
};
