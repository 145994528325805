import { useLocalization } from '@features/localization';
import {
  BulkUploadModalV1,
  BulkUploadModalV2,
} from '@features/registrations/registrants-bulk-upload';
import { Button } from '@hopin-team/ui-button';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

export const UploadRegistrantsButton = ({
  eventPublished,
  eventSlug,
  eventExternalId,
  userExternalId,
  title,
  registrationEmailsDisabled,
  onsiteCustomFormBulkUploadEnabled,
  registrantsBulkUploadReportStatus,
}) => {
  const { t } = useLocalization('people.attendees.registrant-bulk-upload');
  const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [bulkUploadAccess, setBulkUploadAccess] = useState({
    denied: false,
    deniedReason: '',
  });

  useEffect(() => {
    if (!eventPublished) {
      setBulkUploadAccess({
        denied: true,
        deniedReason: t('upload-registrants-denied.publish-event'),
      });
      return;
    }
  }, [eventPublished, t]);

  return (
    <>
      <ToolTip
        tip={bulkUploadAccess.deniedReason}
        align="top"
        hideTip={!bulkUploadAccess.denied}
      >
        <Button
          isInline
          leadingIcon="publish"
          size="medium"
          onClick={() => setBulkUploadModalOpen(true)}
          disabled={bulkUploadAccess.denied}
        >
          {title}
        </Button>
      </ToolTip>
      {onsiteCustomFormBulkUploadEnabled ? (
        <BulkUploadModalV2
          isOpen={isBulkUploadModalOpen}
          onClose={() => setBulkUploadModalOpen(false)}
          eventSlug={eventSlug}
          eventExternalId={eventExternalId}
          userExternalId={userExternalId}
          registrationEmailsDisabled={registrationEmailsDisabled}
          registrantsBulkUploadReportStatus={registrantsBulkUploadReportStatus}
        />
      ) : (
        <BulkUploadModalV1
          isOpen={isBulkUploadModalOpen}
          onClose={() => {
            setBulkUploadModalOpen(false);
          }}
          eventSlug={eventSlug}
          eventExternalId={eventExternalId}
          userExternalId={userExternalId}
          registrationEmailsDisabled={registrationEmailsDisabled}
        />
      )}
    </>
  );
};

UploadRegistrantsButton.propTypes = {
  eventSlug: string.isRequired,
  eventPublished: bool.isRequired,
  title: string.isRequired,
  eventExternalId: string.isRequired,
  userExternalId: string.isRequired,
  registrationEmailsDisabled: bool,
  onsiteCustomFormBulkUploadEnabled: bool,
  registrantsBulkUploadReportStatus: bool,
};
