import ImageFileInput from '@components/image-file-input';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import P from 'prop-types';
import React from 'react';

const getInputName = fieldName =>
  `event[custom_event_part_attributes][${fieldName}]`;

const EventCustomArea = ({ customEvent, iconUrl, showForm }) => {
  const { t } = useLocalization('event-custom-area');

  if (!showForm) {
    return (
      <>
        <input
          type="hidden"
          name={getInputName('id')}
          defaultValue={customEvent?.id}
        />
        <input
          type="hidden"
          name={getInputName('reset_segment')}
          defaultValue={true}
        />
        {/* @NOTE Reset values if custom area removed. This might not be needed if done in BE */}
        <input type="hidden" name={getInputName('title')} defaultValue="" />
        <input type="hidden" name={getInputName('url')} defaultValue="" />
        <input
          type="hidden"
          name={getInputName('new_window')}
          defaultValue={false}
        />
      </>
    );
  }

  return (
    <>
      <input
        type="hidden"
        name={getInputName('id')}
        defaultValue={customEvent?.id}
      />
      <input
        type="hidden"
        name={getInputName('reset_segment')}
        defaultValue={false}
      />
      <Text element="h2" mt={5} mb={1} pattern="headingFour">
        {t('header')}
      </Text>
      <TextField
        name={getInputName('title')}
        defaultValue={customEvent?.title}
        label={t('title')}
        mb={2.5}
        size="small"
        minLength={3}
      />
      <ImageFileInput
        name={getInputName('icon')}
        placeholderImageSrc={iconUrl}
        label={t('icon')}
        mb={2.5}
      />
      <TextField
        name={getInputName('url')}
        defaultValue={customEvent?.url}
        label={t('url')}
        mb={2.5}
        size="small"
      />
      <Flex alignItems="center" mb={2.5}>
        <div className="form-check">
          <input type="hidden" name={getInputName('new_window')} value="0" />
          <input
            name={getInputName('new_window')}
            value="1"
            id="new_window"
            defaultChecked={!!customEvent?.new_window}
            type="checkbox"
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor="new_window">
            {t('new-window')}
          </label>
        </div>
      </Flex>
    </>
  );
};

EventCustomArea.propTypes = {
  customEvent: P.shape({
    id: P.number,
    title: P.string,
    url: P.string,
    new_window: P.bool,
  }),
  iconUrl: P.string,
  showForm: P.bool,
};

export default withLocalizationProvider(EventCustomArea);
