import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { TableRow } from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React from 'react';

import { ModalFooter } from '../modal-footer';
import { ModalHeader } from '../modal-header';
import {
  CustomTable,
  CustomTableCell,
  CustomTableHeading,
  CustomTableRow,
  CustomToast,
  TableScrollWrapper,
} from './error-report.style';

const ErrorReport = ({ errors, loading, cancel, onNext }) => {
  const { t } = useLocalization('expo.bulk-upload-modal.error-report');

  const descriptionComponent = (
    <>
      <Text pattern="body">{t('description')}</Text>

      <CustomToast
        icon="alert"
        colorPattern="danger"
        role="status"
        iconColor="red-600"
        isInverse
        py={2}
      >
        <Box>
          <Text pattern="strong" color="grey-800">
            {t('rowWithError')}{' '}
          </Text>

          <Text pattern="body" color="grey-600">
            {t('uploadAgain')}
          </Text>
        </Box>
      </CustomToast>
    </>
  );

  return (
    <>
      <ModalHeader title={t('title')} description={descriptionComponent} />
      <TableScrollWrapper>
        <CustomTable>
          <thead>
            <TableRow isHeading>
              <CustomTableHeading scope="col" width="60px">
                <Text pattern="emphasis">Row</Text>
              </CustomTableHeading>

              <CustomTableHeading scope="col">
                <Text pattern="emphasis">Error</Text>
              </CustomTableHeading>
            </TableRow>
          </thead>
          <tbody>
            {errors.map(({ line, field, messages }, index) => (
              <CustomTableRow key={index}>
                <CustomTableCell>
                  <Text pattern="body" color="red-500">
                    {line}
                  </Text>
                </CustomTableCell>

                <CustomTableRow>
                  <CustomTableCell>
                    <Text pattern="strong" color="grey-600">
                      {field}:{' '}
                    </Text>

                    <Text pattern="body" color="grey-600">
                      {messages}
                    </Text>
                  </CustomTableCell>
                </CustomTableRow>
              </CustomTableRow>
            ))}
          </tbody>
        </CustomTable>
      </TableScrollWrapper>

      <ModalFooter
        cancelClick={cancel}
        nextClick={onNext}
        nextText={t('next')}
        loading={loading}
        disabled={false}
      />
    </>
  );
};

ErrorReport.propTypes = {
  errors: PropTypes.array,
  loading: PropTypes.bool,
  cancel: PropTypes.func,
  onNext: PropTypes.func,
  processedFile: PropTypes.array,
};

export default compose(withLocalizationProvider)(ErrorReport);
