const getEventLinksResponse = {
  data: {
    id: '12345-event-id',
    type: 'event_links',
    attributes: {
      registration_url: 'http://hopin.com/events/12345-event-slug',
    },
    relationships: {
      backstage_links: {
        data: [
          {
            id: 'id-for-stage-1',
            type: 'backstage_links',
          },
          {
            id: 'id-for-stage-2',
            type: 'backstage_links',
          },
          {
            id: 'id-for-stage-3',
            type: 'backstage_links',
          },
          {
            id: 'id-for-stage-4',
            type: 'backstage_links',
          },
        ],
      },
    },
    meta: {
      rtmp_allowed: true,
    },
  },
  included: [
    {
      id: 'id-for-stage-1',
      type: 'backstage_links',
      attributes: {
        name: 'Stage #1',
        ringcentral_studio_url:
          'https://hopin.com/events/event-slug/backstages/id-for-stage-1',
        hopin_studio_url:
          'https://hopin.com/events/event-slug/backstages/id-for-stage-1',
        stage_url: 'https://hopin.com/organisers/events/43/backstages/01/edit',
        create_rtmp_video_channels_url:
          'https://hopin.com/organisers/events/43/backstages/01/create_rtmp_video_channels',
        rtmp_credentials: [
          {
            label: 'Preferred',
            stream_key: 'mux-stream-key-for-stage-1',
            delivery_type: 'mux',
            rtmp_supported: true,
            rtmp_server_url: 'rtmp://global-live.mux.com:123456/app',
            rtmps_supported: true,
            rtmps_server_url: 'rtmps://global-live.mux.com:123456/app',
            priority: 'preferred',
          },
          {
            label: 'Fallback',
            stream_key: 'stream-key-for-stage-1',
            delivery_type: 'ivs',
            rtmp_supported: false,
            rtmps_supported: true,
            rtmps_server_url:
              'rtmps://123456.global-contribute.live-video.net:443/app',
            priority: 'fallback',
          },
        ],
      },
    },
    {
      id: 'id-for-stage-2',
      type: 'backstage_links',
      attributes: {
        name: 'Stage #2',
        ringcentral_studio_url:
          'https://hopin.com/events/event-slug/backstages/id-for-stage-2',
        hopin_studio_url:
          'https://hopin.com/events/event-slug/backstages/id-for-stage-2',
        stage_url: 'https://hopin.com/organisers/events/43/backstages/02/edit',
        create_rtmp_video_channels_url:
          'https://hopin.com/organisers/events/43/backstages/02/create_rtmp_video_channels',
        rtmp_credentials: [
          {
            label: 'Preferred',
            stream_key: 'mux-stream-key-for-stage-2',
            delivery_type: 'mux',
            rtmp_supported: true,
            rtmp_server_url: 'rtmp://global-live.stage-2.hopin.com:123456/app',
            rtmps_supported: true,
            rtmps_server_url: 'rtmps://global-live.stage-2.mux.com:123456/app',
            priority: 'preferred',
          },
          {
            label: 'Fallback',
            stream_key: 'stream-key-for-stage-2',
            delivery_type: 'ivs',
            rtmp_supported: false,
            rtmps_supported: true,
            rtmps_server_url:
              'rtmps://123456.global-contribute.live-video.net:443/app',
            priority: 'fallback',
          },
        ],
      },
    },
    {
      id: 'id-for-stage-3',
      type: 'backstage_links',
      attributes: {
        name: 'Stage #3',
        ringcentral_studio_url:
          'https://hopin.com/events/event-slug/backstages/id-for-stage-3',
        hopin_studio_url:
          'https://hopin.com/events/event-slug/backstages/id-for-stage-3',
        stage_url:
          'http://localhost:3000/organisers/events/01/backstages/03/edit',
        create_rtmp_video_channels_url:
          'https://hopin.com/organisers/events/43/backstages/03/create_rtmp_video_channels',
        rtmp_credentials: [
          {
            label: 'Fallback',
            stream_key: 'stream-key-for-stage-3',
            delivery_type: 'ivs',
            rtmp_supported: true,
            rtmp_server_url:
              'rtmp://123456.global-contribute.live-video.net:443/app',
            rtmps_supported: true,
            rtmps_server_url:
              'rtmps://123456.global-contribute.live-video.net:443/app',
            priority: 'fallback',
          },
        ],
      },
    },
    {
      id: 'id-for-stage-4',
      type: 'backstage_links',
      attributes: {
        name: 'Stage #4',
        ringcentral_studio_url: null,
        hopin_studio_url: null,
        stage_url: 'https://hopin.com/organisers/events/43/backstages/04/edit',
        create_rtmp_video_channels_url:
          'https://hopin.com/organisers/events/43/backstages/04/create_rtmp_video_channels',
        rtmp_credentials: [
          {
            label: 'Fallback',
            stream_key: 'stream-key-for-stage-3',
            delivery_type: 'ivs',
            rtmp_supported: true,
            rtmp_server_url: 'rtmp://global-live.mux.com:123456/app',
            rtmps_supported: true,
            rtmps_server_url: 'rtmps://global-live.mux.com:123456/app',
            priority: 'fallback',
          },
        ],
      },
    },
  ],
};

const getEventLinksResponseNoStage = {
  data: {
    id: '12345-event-id',
    type: 'event_links',
    attributes: {
      registration_url: 'http://hopin.com/events/12345-event-slug',
    },
    relationships: {
      backstage_links: {
        data: [],
      },
    },
  },
  included: [],
};

export { getEventLinksResponse, getEventLinksResponseNoStage };
