import eventDashboardApi from '@api/event-dashboard';
import CsrfInput from '@components/csrf-input';
import FileInputButton from '@components/file-input-button/file-input-button';
import ImagePreview from '@components/image-preview';
import SaveBar from '@components/save-bar';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { ErrorMessage } from '@hopin-team/ui-field-error-message';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { uploadFileToS3 } from '@util/upload-file-to-s3';
import { shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { Wrapper } from './host-information.styles';
import useHostInformationForm from './use-host-information-form';

const HostInformationComponent = ({ id, attributes }) => {
  const { t } = useLocalization('host-information');
  const { addAlert } = useContext(AlertsContext);

  const { pictureUrl, name, about, website, facebook, twitter } = attributes;

  const handleSuccess = () => {
    addAlert({
      active: true,
      text: t('success-alert-message'),
      pattern: 'success',
    });
  };

  const handleError = errors => {
    errors.forEach(error => {
      addAlert({
        active: true,
        text: error.detail,
        pattern: 'error',
      });
    });
  };

  const {
    errors,
    onSubmit,
    register,
    reset,
    setValue,
  } = useHostInformationForm({
    id,
    attributes,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  useEffect(() => {
    const str = pictureUrl ? pictureUrl.split('/').pop() : null;
    const file = str ? str.split('?')[0] : null;

    reset({
      about,
      picture_file_name: file,
      name,
      facebook,
      twitter,
      website,
    });
  }, [about, pictureUrl, name, facebook, reset, twitter, website]);

  const [imageSelected, setImageSelected] = useState();

  const onChange = async e => {
    e.persist();

    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 10 * 1024 * 1024) {
      addAlert({
        active: true,
        text: t('file-size-error'),
        pattern: 'error',
      });

      return;
    }

    try {
      const {
        data: {
          attributes: { file_name, presigned_url, params },
        },
        errors,
      } = await eventDashboardApi.createPresignedUploadUrl({
        entity_name: 'event',
        entity_field: 'host_picture',
        file_name: file.name,
        entity_id: id,
      });

      if (file_name) {
        await uploadFileToS3(presigned_url, file, params);
        setValue('picture_file_name', file_name);

        const reader = new FileReader();
        reader.onload = () => {
          setImageSelected(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (errors) {
        addAlert({
          active: true,
          text: errors,
          pattern: 'error',
        });
      }
    } catch (e) {
      addAlert({
        active: true,
        text: t('failed-to-upload-image'),
        pattern: 'error',
      });
      console.error(e);
    }
  };

  return (
    <Wrapper data-testid="host-information-component">
      <Alerts />
      <form onSubmit={onSubmit}>
        <CsrfInput />
        <Text element="p" pattern="body" my={3}>
          {t('summary')}
        </Text>
        <Text
          pattern="labelTwo"
          element="label"
          htmlFor="sharing-snippet"
          mb={1}
          id="host-avatar-label"
        >
          {t('avatar.label')}
        </Text>
        <Flex alignItems="center">
          <ImagePreview
            image={imageSelected || pictureUrl}
            label={t('avatar.preview')}
          />
          <input type="hidden" ref={register} name="picture_file_name" />
          <FileInputButton
            ref={register}
            name="picture"
            isInline
            isOutlined
            size="small"
            ml={2}
            aria-labelledby="host-avatar-label"
            accept=".png,.jpeg,.jpg,.gif"
            onChange={onChange}
          >
            {t('avatar.choose')}
          </FileInputButton>
        </Flex>
        {errors?.pictureUrl && (
          <ErrorMessage errorMessage={errors.pictureUrl.message} />
        )}
        <TextField
          label={t('name.label')}
          placeholder={t('name.placeholder')}
          mt={3}
          mb={4}
          name="name"
          ref={register}
          hasErrors={errors?.name}
          errorMessage={errors?.name?.message}
        />
        <TextField
          label={t('about.label')}
          placeholder={t('about.placeholder')}
          my={4}
          name="about]"
          ref={register}
          hasErrors={errors?.about}
          errorMessage={errors?.about?.message}
        />
        <TextField
          label={t('website.label')}
          type="url"
          placeholder={t('website.placeholder')}
          my={4}
          name="website"
          ref={register}
          hasErrors={errors?.website}
          errorMessage={errors?.website?.message}
        />
        <Text element="h2" my={2} pattern="headingFour">
          {t('social')}
        </Text>
        <TextField
          label="Twitter"
          placeholder={`https://twitter.com/${t('slug')}`}
          mb={4}
          name="twitter"
          ref={register}
          hasErrors={errors?.twitter}
          errorMessage={errors?.twitter?.message}
        />
        <TextField
          label="Facebook"
          placeholder={`https://facebook.com/${t('slug')}`}
          my={4}
          name="facebook"
          ref={register}
          hasErrors={errors?.facebook}
          errorMessage={errors?.facebook?.message}
        />
        <SaveBar />
      </form>
    </Wrapper>
  );
};

HostInformationComponent.propTypes = {
  id: string.isRequired,
  attributes: shape({
    about: string,
    pictureFileName: string,
    name: string,
    facebook: string,
    twitter: string,
    website: string,
  }),
};

export default withAlertsProvider(HostInformationComponent);
