import { COLOR, getFontScale } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import styled, { css } from 'styled-components';

const ChecklistNumber = styled.div`
  position: relative;
  counter-increment: overview-checklist;
  font-size: ${getFontScale(3).size};
  line-height: ${getFontScale(3).lineHeight};
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${COLOR.GREY.WHITE};
    `};

  ${Text} {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
  }
`;

export default ChecklistNumber;
