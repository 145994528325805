import React from 'react';

const Network = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.23702 2.69922C7.48805 2.69922 7.69156 2.90277 7.69156 3.15381C7.69156 3.31604 7.60539 3.46372 7.46922 3.54459L7.39714 3.57922L7.11942 3.68355C6.53651 3.91169 5.83447 4.29039 5.83447 5.42649C5.83447 9.68285 9.29581 10.1065 10.5411 9.90467L15.0407 9.17467L20.2855 14.0125C20.6882 14.3459 20.9456 14.8554 20.9456 15.4265C20.9456 16.4306 20.1496 17.2447 19.1678 17.2447C18.8364 17.2447 18.5262 17.152 18.2607 16.9905L18.273 17.0956L18.2789 17.2447C18.2789 18.2488 17.483 19.0629 16.5011 19.0629C16.1695 19.0629 15.859 18.97 15.5933 18.8082L15.6064 18.9137L15.6123 19.0629C15.6123 20.067 14.8163 20.881 13.8345 20.881C13.5027 20.881 13.1921 20.7881 12.9264 20.6263L12.9397 20.7319L12.9456 20.881C12.9456 21.8852 12.1496 22.6992 11.1678 22.6992C10.5757 22.6992 10.0513 22.4032 9.72817 21.948L1.9287 13.6083H1.51406C0.962284 13.6083 0.51478 13.1614 0.514061 12.6096L0.502442 3.70052C0.501777 3.18769 0.887266 2.76451 1.38453 2.7061L1.50244 2.69922H7.23702ZM15.6123 2.69922C15.9781 2.69922 16.318 2.8097 16.6007 2.99911L20.9456 5.42649L23.2534 4.83642C23.3344 4.81572 23.4176 4.80525 23.5011 4.80525C24.014 4.80525 24.4366 5.19129 24.4944 5.68863L24.5011 5.80525V14.4265C24.5011 14.9393 24.1151 15.362 23.6178 15.4198L23.5011 15.4265H22.7109C22.6655 14.1851 22.2301 13.5323 21.6897 12.9525L21.5803 12.8374L20.8492 12.1042C20.5844 11.847 20.1951 11.4791 19.7463 11.0591L19.5505 10.8761L15.6123 7.24104L10.3087 8.10194C10.2313 8.11913 10.1524 8.1322 10.0722 8.14091L10.0594 8.14195C9.98463 8.14991 9.90999 8.15376 9.83447 8.15376C8.60692 8.15376 7.61225 7.13649 7.61225 5.88104V5.42649L14.8888 2.85261C15.1098 2.75402 15.3546 2.69922 15.6123 2.69922Z"
      fill="currentColor"
    />
  </svg>
);

export default Network;
