import { TextField } from '@hopin-team/ui-text-field';
import styled from 'styled-components';

export const TemplateTimeField = styled(TextField)`
  input {
    padding: 0 0.75rem;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
