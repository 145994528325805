import Alerts from '@features/alerts/alerts';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { routes, stepMap } from './constants/step-map';
import { getTemplateSteps } from './shared/useStepsInfo';
import { useOnboardingCompleted, usePageViews } from './utils/analytics';

const Routes = () => {
  const { id: templateId } = useSelector(state => state.template);
  const { name: eventName } = useSelector(state => state.basicDetails);
  const { type: eventFormat } = useSelector(state => state.eventLocation);
  const { isOnFreePlan } = useSelector(state => state.org);
  const { essentialsWizardEventSimplification } = useSelector(
    state => state.flags,
  );

  const steps = getTemplateSteps(templateId);

  const { pathname } = useLocation();

  useOnboardingCompleted();
  usePageViews();

  const renderDynamicRoutes = () => {
    const isGenericEventForm = steps[0]?.name === 'details';
    const isPreview = pathname.includes('/preview');

    if (
      !templateId &&
      pathname !== routes.templatePicker(eventFormat) &&
      !isPreview
    ) {
      return <Redirect to={routes.complexityPicker()} />;
    }

    if (
      !eventName &&
      pathname !== routes.templatePicker(eventFormat) &&
      !isPreview
    ) {
      if (
        !isGenericEventForm &&
        pathname !== routes.basicDetails(templateId, eventFormat)
      ) {
        return <Redirect to={routes.basicDetails(templateId, eventFormat)} />;
      }

      if (
        isGenericEventForm &&
        pathname !== routes.details(templateId, eventFormat)
      ) {
        return <Redirect to={routes.details(templateId, eventFormat)} />;
      }
    }

    return steps.map(({ name, options }) => (
      <Route
        exact
        key={`${name}-route`}
        path={stepMap[name].path(':templateId', ':format')}
        render={routeProps => {
          const { format } = routeProps.match.params;
          const StepComponent = stepMap[name].View;
          if (format !== eventFormat) {
            return <Redirect to={routes.formatPicker()} />;
          }

          return (
            <StepComponent
              {...routeProps}
              canSkip={options.canSkip}
              canSetEventLocation={options.canSetEventLocation}
            />
          );
        }}
      />
    ));
  };

  return (
    <>
      <Alerts />
      <Switch>
        <Route
          exact
          key="complexity"
          path={routes.complexityPicker()}
          render={() => {
            const ComplexityPicker = stepMap['complexity-picker'].View;
            return <ComplexityPicker />;
          }}
        />

        <Route
          exact
          key="experience-details"
          path={routes.experienceDetails()}
          render={() => {
            const BasicDetails = stepMap['experience-details'].View;
            return <BasicDetails />;
          }}
        />

        <Route
          exact
          key="event-areas"
          path={routes.eventAreas()}
          render={() => {
            if (!eventName) {
              return <Redirect to={routes.experienceDetails()} />;
            }

            const EventAreas = stepMap['event-areas'].View;
            return <EventAreas />;
          }}
        />

        <Route
          exact
          key="description"
          path={routes.description()}
          render={() => {
            if (!eventName) {
              return <Redirect to={routes.experienceDetails()} />;
            }

            const Description = stepMap['description'].View;
            return <Description />;
          }}
        />

        <Route
          exact
          key="format"
          path={routes.formatPicker()}
          render={() => {
            const FormatPicker = stepMap['format-picker'].View;
            return <FormatPicker />;
          }}
        />
        {!isOnFreePlan && (
          <Route
            exact
            key="onsite"
            path={routes.onsiteForm()}
            render={() => {
              const OnsiteForm = stepMap['onsite'].View;
              return <OnsiteForm />;
            }}
          />
        )}
        <Route
          exact
          key="template"
          path={routes.templatePicker(':format')}
          render={({ match }) => {
            const { format } = match.params;
            const TemplatePicker = stepMap['template-picker'].View;

            if (format !== eventFormat) {
              return <Redirect to={routes.formatPicker()} />;
            }

            return <TemplatePicker />;
          }}
        />
        <Route
          exact
          key="preview"
          path={routes.preview(':templateId', ':format')}
          render={({ match }) => {
            const { templateId, format } = match.params;
            const Preview = stepMap['preview'].View;
            return <Preview templateId={templateId} eventFormat={format} />;
          }}
        />
        <Route
          path={routes.eventDashboard(':slug')}
          render={({ match, location }) => {
            const { slug } = match.params;

            window.location.assign(
              `${routes.eventDashboard(slug)}${location.search}`,
            );
            return null;
          }}
        />
        <Route
          path={routes.eventPreview(':slug')}
          render={({ match, location }) => {
            window.location.assign(
              `${routes.eventPreview(match.params.slug)}${location.search}`,
            );
            return null;
          }}
        />
        <Route
          path={routes.billing()}
          render={() => {
            window.location.pathname = routes.billing();
            return null;
          }}
        />
        {renderDynamicRoutes()}
        <Redirect
          to={
            essentialsWizardEventSimplification
              ? routes.experienceDetails()
              : routes.complexityPicker()
          }
        />
      </Switch>
    </>
  );
};

export default Routes;
