import styled from 'styled-components';

export const BarChartSvg = styled.svg`
  svg:not(:root) {
    overflow: visible;
  }

  overflow: visible;
`;

export const BarChartWrapper = styled.div`
  position: relative;
`;
