export const getEventAreaParameter = ({ venue }) => {
  switch (venue) {
    case 'roundtables':
      return '/event_areas/sessions';
    case 'vendors':
      return '/event_areas/booths';
    case 'backstages':
      return '/event_areas/stages';
    case 'events':
      return '/event_areas/reception';
    case 'app_area':
      return '/event_areas/app_area';
    default:
      return '';
  }
};
