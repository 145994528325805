import hopinApi from '@api/hopin';
import Card from '@components/card-with-loading';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../../util/hooks';
import ConnectionsMadeChartComponent from './connections-made-chart-component';

const ConnectionsMadeChartContainer = ({ eventId }) => {
  const [requests, setRequests] = useState(0);
  const [connections, setConnections] = useState(0);

  const loading = usePromise(
    signal => hopinApi.getConnectionsMade(eventId, signal),
    result => {
      setRequests(result.total_connection_requests);
      setConnections(result.total_connections_made);
    },
  );
  return (
    <Card isLoading={loading}>
      <ConnectionsMadeChartComponent
        requests={requests}
        connections={connections}
      />
    </Card>
  );
};

ConnectionsMadeChartContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default ConnectionsMadeChartContainer;
