import Loading from '@components/loading';
import Alerts from '@features/alerts/alerts';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { withFlagsProvider } from '@features/recordings/redux/slices/flags/with-flags';
import { withRegistrationProvider } from '@features/recordings/redux/slices/registration/with-registration';
import { Box } from '@hopin-team/ui-box';
import { Linkicon } from '@hopin-team/ui-linkicon';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RECORDING_CATEGORY } from '../constants';
import { Filter } from '../filter';
import { useRecordingsFilters } from '../hooks';
import { NoResults } from '../no-results';
import { RecordingGroupContainer } from '../recording-group/recording-group-container';
import { withAuthProvider } from '../redux/slices/auth/with-auth';
import {
  getEditThumbnailIsShowing,
  getEditThumbnailSelectedRecordingId,
} from '../redux/slices/edit-thumbnail/edit-thumbnail-selectors';
import {
  setIsShowing as setThumbnailSidePanelIsShowing,
  setRecordingId as setSelectedRecordingId,
} from '../redux/slices/edit-thumbnail/edit-thumbnail-slice';
import { selectEvent } from '../redux/slices/event/event-selectors';
import { withEventProvider } from '../redux/slices/event/with-event';
import {
  selectVideoAreaById,
  selectVideoAreas,
  selectVideoAreasFetchingState,
} from '../redux/slices/recording-groups/recording-groups-selectors';
import { fetchRecordingAreasThunk } from '../redux/slices/recording-groups/recording-groups-slice';
import { getRecordingById } from '../redux/slices/recordings/recordings-selectors';
import { withReduxProvider } from '../redux/with-provider';
import { ThumbnailSidePanelModal } from '../thumbnail-side-panel-modal';
import { getVideoAreaById, mapVideoAreasToSelectOptions } from '../utils/utils';
import { SecondaryHeader } from './partials/secondary-header';
import { Container } from './secondary-page.styled';

const mapRecordingCategoryToConfiguration = {
  [RECORDING_CATEGORY.BACKUP]: {
    title: 'backup-title',
    subtitle: 'backup-subtitle',
    learnMoreLink:
      'https://events-support.ringcentral.com/hc/en-us/articles/360056080232-How-to-access-recordings',
  },
  [RECORDING_CATEGORY.REHEARSAL]: {
    title: 'rehearsal-title',
    subtitle: 'rehearsal-subtitle',
    learnMoreLink:
      'https://events-support.ringcentral.com/hc/en-us/articles/360056080232-How-to-access-recordings',
  },
};

export const RecordingsSecondaryPageComponent = ({
  category,
  currentArea: videoAreaIdFromQueryParams,
  flags: { customThumbnailsEnabled },
}) => {
  const { language, t } = useLocalization('recording-pages.secondary');
  const [currentVideoAreaId, setCurrentVideoAreaId] = useState(null);
  // TODO: create separate selectors for the computed values
  const { slug, started: eventStarted, days = [], timeEnd } = useSelector(
    selectEvent,
  );

  const dispatch = useDispatch();
  const isThumbnailSidePanelShowing = useSelector(getEditThumbnailIsShowing);
  const selectedRecordingId = useSelector(getEditThumbnailSelectedRecordingId);
  const selectedRecording = useSelector(state =>
    getRecordingById(state, selectedRecordingId, language),
  );
  const videoAreas = useSelector(selectVideoAreas);
  const { isLoading } = useSelector(selectVideoAreasFetchingState);
  const currentVideoArea = useSelector(state =>
    selectVideoAreaById(state, currentVideoAreaId),
  );

  const {
    title,
    subtitle,
    learnMoreLink,
  } = mapRecordingCategoryToConfiguration[category];

  const showListView =
    (category === RECORDING_CATEGORY.BACKUP && eventStarted) ||
    category === RECORDING_CATEGORY.REHEARSAL;

  const showBackupUnavailable =
    category === RECORDING_CATEGORY.BACKUP && !eventStarted;

  const showDateFilters = category === RECORDING_CATEGORY.BACKUP;

  const { filters, onFilterChange } = useRecordingsFilters();

  useEffect(() => {
    if (slug) {
      dispatch(fetchRecordingAreasThunk({ slug }));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (!videoAreas?.length) return;

    /**
     * On the initial render we check if the video area
     * that comes from the url query param valid or not
     * and based on the validity of it we either select it or
     * select the 1st video area from in the filter select
     */
    if (currentVideoAreaId === null) {
      const validCurrentArea = videoAreaIdFromQueryParams
        ? getVideoAreaById(videoAreas, videoAreaIdFromQueryParams)
        : false;
      const newVideoArea = validCurrentArea ? validCurrentArea : videoAreas[0];

      setCurrentVideoAreaId(newVideoArea.id);
      onFilterChange({ videoAreaId: newVideoArea.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVideoAreaId, videoAreaIdFromQueryParams, videoAreas]);

  useEffect(() => {
    if (!currentVideoArea) return;

    try {
      const url = new URL(window.location);
      url.searchParams.set('areaId', currentVideoArea.id);
      window.history.pushState({}, '', url);
    } catch (error) {
      console.warn('Error updating the URL', { currentVideoArea, error });
    }
  }, [currentVideoArea]);

  const handleFilterChange = changes => {
    onFilterChange(changes);
    const { videoAreaId } = changes;

    if (videoAreaId) {
      setCurrentVideoAreaId(videoAreaId);
    } else {
      console.warn('handleFilterChange: change not handled', { changes });
    }
  };

  const onRecordingSelect = recordingId => {
    dispatch(setSelectedRecordingId(recordingId));
    dispatch(setThumbnailSidePanelIsShowing(true));
  };

  const onThumbnailSidePanelClose = () => {
    dispatch(setThumbnailSidePanelIsShowing(false));
  };

  return (
    <Container>
      <Alerts />
      <Linkicon
        data-testid="backLink"
        iconScale={1.5}
        scale={4}
        label={t('back-to-primary')}
        leadingIcon="arrow-back"
        href={Routes.organisersEventRecordingsPath(slug)}
        mb="3"
      />

      <SecondaryHeader
        title={t(title)}
        subtitle={t(subtitle)}
        moreText={t('learn-more')}
        moreLink={learnMoreLink}
      />

      <Loading isLoading={isLoading}>
        <>
          {showBackupUnavailable && (
            <NoResults
              category={RECORDING_CATEGORY.BACKUP}
              rehearsalHref={Routes.rehearsalOrganisersEventRecordingsPath(
                slug,
              )}
              endTime={timeEnd}
              eventStarted={eventStarted}
            />
          )}
          {showListView && (
            <>
              <Box mt="4" mb="2.5">
                <Filter
                  filters={filters}
                  onFilterChange={handleFilterChange}
                  dateOptions={showDateFilters ? days : null}
                  videoAreaOptions={mapVideoAreasToSelectOptions(videoAreas, t)}
                  showEventPartFilters={false}
                />
              </Box>
              {currentVideoArea && (
                <RecordingGroupContainer
                  noResultsStyle="large"
                  videoAreaId={currentVideoArea.id}
                  category={category}
                  showTitle={false}
                  showLinks={false}
                  filters={filters}
                  onRecordingSelect={onRecordingSelect}
                  customThumbnailsEnabled={customThumbnailsEnabled}
                />
              )}
            </>
          )}

          {customThumbnailsEnabled && (
            <ThumbnailSidePanelModal
              isShowing={isThumbnailSidePanelShowing}
              onClose={onThumbnailSidePanelClose}
              recording={selectedRecording}
            />
          )}
        </>
      </Loading>
    </Container>
  );
};

RecordingsSecondaryPageComponent.propTypes = {
  category: PropTypes.oneOf([
    RECORDING_CATEGORY.BACKUP,
    RECORDING_CATEGORY.REHEARSAL,
  ]).isRequired,
  currentArea: PropTypes.string,
  flags: PropTypes.shape({
    customThumbnailsEnabled: PropTypes.bool.isRequired,
    contentEnableRecordingsTrimmingAnalytics: PropTypes.bool.isRequired,
  }).isRequired,
};

export default compose(
  withLocalizationProvider,
  withReduxProvider,
  withEventProvider,
  withAuthProvider,
  withFlagsProvider,
  withRegistrationProvider,
)(RecordingsSecondaryPageComponent);
