import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import auth from './auth';
import backstageLinks from './backstage-links';
import customDomain from './custom-domain';
import event from './event';
import eventLinks from './event-links';
import hostInformation from './host-information';
import marketingSettings from './marketing-settings';
import modal from './modal';
import navigationView from './navigation-view';
import networking from './networking';
import organization from './organization';
import registration from './registration';
import reports from './reports';
import schedule from './schedule';
import setupChecklist from './setup-checklist';
import sponsorAreas from './sponsor-areas';
import sponsors from './sponsors';
import tickets from './tickets';
import user from './user';
import venueSettings from './venue-settings';

const rootReducer = history =>
  combineReducers({
    navigationView,
    auth,
    event,
    modal,
    organization,
    setupChecklist,
    sponsorAreas,
    sponsors,
    tickets,
    user,
    venueSettings,
    marketingSettings,
    eventLinks,
    backstageLinks,
    hostInformation,
    registration,
    schedule,
    reports,
    customDomain,
    networking,
    router: connectRouter(history),
  });

export default rootReducer;
