import hopinApi from '@api/hopin';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import Pusher from 'pusher-js';
import { v4 as uuid } from 'uuid';

const APP_KEY = process.env.PUSHER_KEY;
const CLUSTER = process.env.PUSHER_CLUSTER;

// Setup main pusher client
let pusher;

// Keep track of subscriptions and bindings
const subscribedChannels = new Map();
const boundEvents = new Map();

// Assists with testing
export const _testing_ = {
  subscribedChannels,
  boundEvents,
};

const logger = getLogger(LOGGER_NAMES.EVENT_DUPLICATION);

// Control binding/unbinding
export const bindToPusher = async ({ channelName, eventName, handler }) => {
  if (!pusher) {
    try {
      const { token } = await hopinApi.getUserToken();
      pusher = new Pusher(APP_KEY, {
        cluster: CLUSTER,
        authEndpoint: '/api/v2/pusher/auth',
        auth: { headers: { Authorization: `Bearer ${token}` } },
      });
    } catch (error) {
      logger.error(error);
    }
  }
  // Retrieve from or add to subscribed channels map
  if (!subscribedChannels.has(channelName)) {
    subscribedChannels.set(channelName, pusher.subscribe(channelName));
    boundEvents.set(channelName, []);
  }
  const channel = subscribedChannels.get(channelName);

  // Bind the event to the channel and update the tracker
  const bindingId = uuid();
  channel.bind(eventName, handler);
  boundEvents.get(channelName).push(bindingId);

  // Pass back the unbind function
  return () => {
    // Unbind the handler
    channel.unbind(eventName, handler);

    // Update tracker and/or unsubscribe if necessary
    const currentBoundEvents = boundEvents.get(channelName);
    if (currentBoundEvents) {
      const updatedBoundEvents = currentBoundEvents.filter(
        eventId => eventId !== bindingId,
      );
      if (updatedBoundEvents.length === 0) {
        boundEvents.delete(channelName);
        pusher.unsubscribe(channelName);
        subscribedChannels.delete(channelName);
      } else {
        boundEvents.set(updatedBoundEvents);
      }
    }
  };
};
