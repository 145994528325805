import hopinApi from '@api/hopin';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const OnSubmitHandler = (
  addAlert,
  t,
  callbackFn,
  setError,
) => async formData => {
  const { confirmationToken = 'invalid' } = formData || {};

  try {
    return await hopinApi
      .submitEmailConfirmation({ confirmationToken })
      .then(() => {
        addAlert({
          active: true,
          text: t(`.confirmation.success`),
          pattern: 'success',
        });
      })
      .then(callbackFn);
  } catch (err) {
    return Promise.resolve(err)
      .then(() => {
        const responseStatus = err.response?.status;
        const errorText = responseStatus
          ? t(`.confirmation.errors.status-${responseStatus}`)
          : t(`.confirmation.errors.generic`);

        return errorText;
      })
      .then(message => {
        addAlert({
          active: true,
          text: message,
          pattern: 'error',
        });

        return message;
      })
      .then(message =>
        setError('confirmationToken', { type: 'manual', message }),
      );
  }
};

const FormSchema = t =>
  yup.object().shape({
    confirmationToken: yup
      .string()
      .matches(/^[a-z0-9-_]{1,32}$/, t(`.token.invalid`))
      .required(),
  });

const ChallengeFormContext = ({ t, addAlert, onSuccess }) => {
  const { handleSubmit, setError, ...rest } = useForm({
    resolver: yupResolver(FormSchema(t)),
  });

  const submitHandlerFn = OnSubmitHandler(addAlert, t, onSuccess, setError);

  return {
    ...rest,
    handleSubmit: handleSubmit(submitHandlerFn),
    t,
  };
};

export default ChallengeFormContext;
