import { EventLinksContext } from '@components/event-links/event-links-container';
import EventLinksModal from '@components/event-links/event-links-modal';
import Alerts from '@features/alerts/alerts';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { EVENT_DASHBOARD_EVENTS } from '@util/analytics/event-dashboard-events';
import trackSegmentEvent from '@util/analytics/segment-client';
import compose from 'lodash/fp/compose';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getAttributeLinks,
  getBackstageOptions,
  getRtmpLinksByPriority,
} from '@/redux/reducers/backstage-links.js';
import {
  getRegistrationUrl,
  getRtmpAllowed,
} from '@/redux/reducers/event-links';
import withReduxProvider from '@/redux/with-provider';

import {
  Banner,
  Container,
  CopyField,
  Halon,
  Image,
  ImageLink,
  TextLink,
  WhiteButton,
} from '../index.styled';

export const OnlineSharingBanner = () => {
  const { t } = useLocalization('overview.banner');

  const [eventLinksModalOpen, setEventLinksModalOpen] = useState(false);
  const handleOpenEventLinksModal = useCallback(
    () => setEventLinksModalOpen(true),
    [],
  );
  const handleCloseEventLinksModal = useCallback(
    () => setEventLinksModalOpen(false),
    [],
  );

  const {
    event: {
      eventWizardTemplate,
      organization: {
        currentPaymentPlan: { name: organisationSubscriptionTier },
      },
      ...event
    },
    user: { externalId: userId },
  } = useSelector(({ event, user }) => ({
    event,
    user,
  }));

  const { externalId } = event;

  const registrationUrl = useSelector(getRegistrationUrl(externalId));

  const backstageOptions = useSelector(getBackstageOptions);
  const hopinStudioLinks = useSelector(getAttributeLinks('hopinStudioUrl'));

  const createRtmpLink = useSelector(
    getAttributeLinks('createRtmpVideoChannelsUrl'),
  );

  const rtmpAllowed = useSelector(getRtmpAllowed(externalId));

  const rtmpLinks = useSelector(getRtmpLinksByPriority());

  const eventLinksContextProps = {
    backstageOptions,
    createRtmpLink,
    eventSlug: event.slug,
    hopinStudioLinks,
    registrationUrl,
    rtmpAllowed,
    rtmpLinks,
  };

  const isPublished = !event.draft;
  const eventHasStages = backstageOptions?.length > 0;

  const onPreviewClick = () => {
    trackSegmentEvent(EVENT_DASHBOARD_EVENTS.PREVIEW_CLICKED, {
      organiser_id: userId,
      event_type: eventWizardTemplate,
      organisation_subscription_tier: organisationSubscriptionTier,
    });
  };

  const trackCopyFieldClick = ({ target }) => {
    if (target.tagName.toLowerCase() === 'button') {
      trackSegmentEvent(EVENT_DASHBOARD_EVENTS.EVENT_URL_COPIED, {
        organiser_id: userId,
        event_type: eventWizardTemplate,
        organisation_subscription_tier: organisationSubscriptionTier,
      });
    }
  };

  const backstageURL =
    hopinStudioLinks[backstageOptions[backstageOptions.length - 1]?.value]
      ?.hopinStudioUrl;

  if (!eventWizardTemplate || event.finished) {
    return null;
  }

  return (
    <EventLinksContext.Provider value={eventLinksContextProps}>
      <Alerts />
      <EventLinksModal
        eventId={event.externalId}
        isModalDisplayed={eventLinksModalOpen}
        handleClose={handleCloseEventLinksModal}
      />
      <Container>
        <Banner>
          <div>
            <Box mb={2}>
              <Text element="h2" pattern="headingTwo">
                {isPublished ? event.name : t('unpublished-title')}
              </Text>
            </Box>

            {isPublished ? (
              <Flex flexDirection="column">
                <Box mr={1} mb={3} onClick={trackCopyFieldClick}>
                  <CopyField
                    label={t('copy-field-label')}
                    value={registrationUrl}
                  />
                </Box>
                {eventHasStages && (
                  <Box mr={1} mb={2}>
                    <CopyField
                      label={t('copy-backstage-label')}
                      value={backstageURL}
                    />
                  </Box>
                )}
                <a>
                  <TextLink
                    pattern="emphasis"
                    onClick={handleOpenEventLinksModal}
                  >
                    {t('view-all-event-links')}
                  </TextLink>
                </a>
              </Flex>
            ) : (
              <>
                <Box mb={2.5}>
                  <Text element="p" pattern="body">
                    {t('unpublished-description')}
                  </Text>
                </Box>
                <Flex>
                  <Box mr={2}>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={Routes.eventPath(event.slug)}
                    >
                      <WhiteButton
                        isInline
                        isOutlined
                        pattern="primary"
                        size="small"
                        leadingIcon="open-in-new"
                        onClick={onPreviewClick}
                      >
                        {t('preview-event')}
                      </WhiteButton>
                    </a>
                  </Box>
                  <WhiteButton
                    isInline
                    isOutlined
                    pattern="primary"
                    size="small"
                    leadingIcon="link"
                    onClick={handleOpenEventLinksModal}
                  >
                    {t('view-event-links')}
                  </WhiteButton>
                </Flex>
              </>
            )}
          </div>

          <Flex alignItems="center" justifyContent="flex-end">
            <ImageLink
              data-testid="image-link"
              href={`/organisers/events/${event.slug}/design`}
            >
              <Halon
                isSquare
                icon="edit"
                iconColor="grey-600"
                color="grey-white"
                scale={3}
              />
              <Image src={event.picture} />
            </ImageLink>
          </Flex>
        </Banner>
      </Container>
    </EventLinksContext.Provider>
  );
};

export default compose(
  withReduxProvider,
  withAlertsProvider,
)(OnlineSharingBanner);
