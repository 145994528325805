import { Avatar } from '@hopin-team/ui-avatar';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { node, string } from 'prop-types';
import React from 'react';

import { FirstLine, Name, Role, Wrapper } from './styles';

export default function UserTile({
  name,
  lastName,
  userRole,
  avatarUrl,
  children,
}) {
  return (
    <Wrapper>
      <FirstLine>
        <Name data-testid="user-tile-name">
          {name} {lastName}
        </Name>
        <Avatar src={avatarUrl} />
      </FirstLine>
      <Role data-testid="user-tile-role">{startCase(camelCase(userRole))}</Role>
      <div>{children}</div>
    </Wrapper>
  );
}

UserTile.propTypes = {
  name: string.isRequired,
  lastName: string.isRequired,
  userRole: string.isRequired,
  avatarUrl: string,
  children: node,
};
