import { rem } from 'polished';
import styled from 'styled-components';

const paddingPx = 16;
const maxWidthPx = 480 + paddingPx; // base from design + padding

export const TranscriptContainer = styled.div`
  border-left: 1px solid var(--color-gray-300);
  border-bottom: 1px solid var(--color-gray-300);
  max-width: ${rem(maxWidthPx + 'px')};
  padding: ${rem(paddingPx + 'px')};
  width: 100%;
`;
