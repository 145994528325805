import { useLocalization } from '@features/localization';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { EVENT_DASHBOARD_EVENTS } from '@util/analytics/event-dashboard-events';
import trackSegmentEvent from '@util/analytics/segment-client';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getRegistrationUrl } from '@/redux/reducers/event-links';

import { CopyLinkContainer, OnlineCopyLinkOverride } from '../../index.styled';

const CopyLink = ({ eventId }) => {
  const { t } = useLocalization('overview.onsite-banner');
  const registrationUrl = useSelector(getRegistrationUrl(eventId));
  const {
    event: {
      eventWizardTemplate,
      organization: {
        currentPaymentPlan: { name: organisationSubscriptionTier },
      },
    },
    user: { externalId: userId },
  } = useSelector(({ event, user }) => ({
    event,
    user,
  }));

  const trackCopyFieldClick = ({ target }) => {
    if (target.tagName.toLowerCase() === 'button') {
      trackSegmentEvent(EVENT_DASHBOARD_EVENTS.EVENT_URL_COPIED, {
        organiser_id: userId,
        event_type: eventWizardTemplate,
        organisation_subscription_tier: organisationSubscriptionTier,
      });
    }
  };
  return (
    <CopyLinkContainer onClick={trackCopyFieldClick}>
      <Icon mr={1} ml={1} name="link" color="grey-800" />
      <Text mr={1} pattern="emphasis" color="grey-800">
        {t('registration-link')}
      </Text>
      <OnlineCopyLinkOverride
        data-testid="copy-link-input"
        hideLabel={true}
        size="medium"
        value={registrationUrl}
        type="button"
      />
    </CopyLinkContainer>
  );
};

CopyLink.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default CopyLink;
