import { loadWithWindow } from '@components/slices/loadWithWindow';
import { LATEST } from '@components/slices/MountSlice';
import { withSlicesProvider } from '@components/slices/withSlicesProvider';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Mount, useHostSlice } from '@slicesjs/react-core';
import { ReactiveMap } from '@slicesjs/reactive-map';
import { withSegmentPageView } from '@util/analytics/with-segment-page-view';
import { FE_SLICES_CDN } from '@util/config';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import { pusher, pusherInitialize } from '@util/pusher/reports';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { bool, number, shape, string } from 'prop-types';
import React from 'react';

import withReduxProvider from '@/redux/with-provider';

const logger = getLogger(LOGGER_NAMES.ANALYTICS);
const PACKAGE_NAME = '@hopin-team/slice-dashboard-event-reports';

const initializeFn = (scope, module) => {
  return loadWithWindow(scope, module)();
};

const ReportsSlice = ({
  organization,
  eventId,
  eventExternalId,
  enableReportsNotifications,
  salesEmail,
}) => {
  pusherInitialize({ eventId: eventId.toString() });

  const createReactiveMap = () => {
    return new ReactiveMap({
      allowedKeys: [
        'eventId',
        'eventExternalId',
        'organization',
        'salesEmail',
        'enableReportsNotifications',
      ],
      allowedContextKeys: ['pusher'],
    }).addMapContext('pusher', pusher);
  };

  const reactiveMap = createReactiveMap({
    clientPackageDetails: `${PACKAGE_NAME}/${LATEST}`,
    onAsyncError: error => logger.error(error),
  });

  reactiveMap.item('eventId').set(eventId);
  reactiveMap.item('eventExternalId').set(eventExternalId);
  reactiveMap.item('organization').set(organization);
  reactiveMap.item('salesEmail').set(salesEmail);
  reactiveMap
    .item('enableReportsNotifications')
    .set(enableReportsNotifications);

  const { mount, error } = useHostSlice({
    packageName: PACKAGE_NAME,
    UNSAFE_customScriptInitializer: initializeFn,
    url: { origin: FE_SLICES_CDN },
  });

  if (mount) {
    return <Mount mount={mount} reactiveMap={reactiveMap} />;
  }

  if (error) {
    throw new Error(error);
  }

  return (
    <Flex justifyContent="center" my={2}>
      <Spinner isShowing pattern="jumbo" />
    </Flex>
  );
};
ReportsSlice.propTypes = {
  enableReportsNotifications: bool.isRequired,
  eventId: number.isRequired,
  eventExternalId: string.isRequired,
  organization: shape({
    externalId: string.isRequired,
    name: string.isRequired,
  }),
  salesEmail: string.isRequired,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.ANALYTICS,
    errorPattern: 'page',
  }),
  withAlertsProvider,
  withSlicesProvider,
  withReduxProvider,
  withSegmentPageView('Reports'),
)(ReportsSlice);
