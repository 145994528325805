import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  getRecordingAreas,
  getRecordings,
} from '../../../services/recordingService';
import { FETCHING_STATE } from '../../initial-state';
import { selectToken } from '../auth/auth-selectors';
import { selectEvent } from '../event/event-selectors';
import { addRecordings } from '../recordings/recordings-slice';

export const fetchRecordingAreas = async (args, { rejectWithValue }) => {
  const { slug } = args;

  try {
    return await getRecordingAreas(slug);
  } catch (err) {
    return rejectWithValue({ slug });
  }
};

export const fetchRecordingAreasThunk = createAsyncThunk(
  'recordingAreas/fetchRecordingAreas',
  fetchRecordingAreas,
);

export const refetchRecordingAreasThunk = createAsyncThunk(
  'recordingAreas/refetchRecordingAreas',
  fetchRecordingAreas,
);

const fetchVideoAreaRecordings = async (
  args,
  { rejectWithValue, dispatch, getState },
) => {
  const { videoAreaId, videoAreaType, category } = args;

  const authToken = selectToken(getState());
  const { eventId } = selectEvent(getState());

  try {
    const recordings = await getRecordings(eventId, authToken, {
      videoAreaId,
      videoAreaType,
      category,
    });

    dispatch(addRecordings({ recordings }));
    return recordings;
  } catch (err) {
    return rejectWithValue({});
  }
};

export const fetchVideoAreaRecordingsThunk = createAsyncThunk(
  'recordingAreas/fetchRecordings',
  fetchVideoAreaRecordings,
);

export const refetchVideoAreadRecordingsThunk = createAsyncThunk(
  'recordingAreas/refetchRecordings',
  fetchVideoAreaRecordings,
);

export const recordingGroupsSlice = createSlice({
  name: 'recordingAreas',
  initialState: {
    ...FETCHING_STATE.default,
    videoAreas: {},
  },
  reducers: {
    removeRecordingFromVideoAreas: (state, action) => {
      const { recordingId } = action.payload;
      const videoAreaIds = Object.keys(state.videoAreas);

      videoAreaIds.forEach(videoAreaId => {
        const filteredRecordingIds = state.videoAreas[
          videoAreaId
        ].recordingIds?.filter(id => id !== recordingId);

        state.videoAreas[videoAreaId].recordingIds = filteredRecordingIds;
      });
    },
  },
  extraReducers: builder => {
    // fetchRecordingAreas
    builder.addCase(fetchRecordingAreasThunk.pending, state => {
      return {
        ...state,
        ...FETCHING_STATE.pending,
      };
    });

    builder.addCase(fetchRecordingAreasThunk.rejected, state => {
      return {
        ...state,
        ...FETCHING_STATE.rejected,
      };
    });

    builder.addCase(fetchVideoAreaRecordingsThunk.pending, (state, action) => {
      const { videoAreaId } = action.meta.arg;
      state.videoAreas[videoAreaId] = {
        ...state.videoAreas[videoAreaId],
        ...FETCHING_STATE.pending,
      };
    });

    builder.addCase(fetchVideoAreaRecordingsThunk.rejected, (state, action) => {
      const { videoAreaId } = action.meta.arg;
      state.videoAreas[videoAreaId] = {
        ...state.videoAreas[videoAreaId],
        ...FETCHING_STATE.rejected,
      };
    });

    builder.addMatcher(
      isAnyOf(
        fetchVideoAreaRecordingsThunk.fulfilled,
        refetchVideoAreadRecordingsThunk.fulfilled,
      ),

      (state, action) => {
        const { videoAreaId } = action.meta.arg;
        const recordings = action.payload;
        const recordingIds = recordings.map(({ id }) => id);

        state.videoAreas[videoAreaId] = {
          ...state.videoAreas[videoAreaId],
          ...FETCHING_STATE.fulfilled,
          recordingIds,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(
        fetchRecordingAreasThunk.fulfilled,
        refetchRecordingAreasThunk.fulfilled,
      ),
      (state, action) => {
        const videoAreas = {};
        action.payload.forEach(videoArea => {
          videoAreas[videoArea.id] = {
            ...videoAreas[videoArea.id],
            ...videoArea,
            ...FETCHING_STATE.default,
          };
        });
        return {
          ...state,
          ...FETCHING_STATE.fulfilled,
          videoAreas,
        };
      },
    );
  },
});

export const { removeRecordingFromVideoAreas } = recordingGroupsSlice.actions;

export default recordingGroupsSlice.reducer;
