import { Spinner } from '@hopin-team/ui-spinner';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const ChildrenContainer = styled.div`
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
`;

const LoadingComponent = ({ isLoading, children, className }) => {
  return (
    <Container className={className}>
      {isLoading && (
        <SpinnerContainer>
          <Spinner pattern="mini" isShowing={isLoading} />
        </SpinnerContainer>
      )}
      <ChildrenContainer isLoading={isLoading}>{children}</ChildrenContainer>
    </Container>
  );
};

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default LoadingComponent;
