import { LoadSlice } from '@hopin-team/load-slice';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { FE_SLICES_CDN } from '@util/config';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React from 'react';

import { dependencies } from '~/package.json';

import OnsiteAddOnEmptyState from './onsite-add-on-empty-state';

const slice = '@hopin-team/slice-onsite-kiosk-mode';

const LoadingComponent = () => (
  <Flex justifyContent="center" my={2}>
    <Spinner isShowing pattern="jumbo" />
  </Flex>
);

const OnsiteKioskMode = ({ sliceVersion, onsiteAdvancedAddOnEnabled }) => {
  if (onsiteAdvancedAddOnEnabled) {
    return (
      <LoadSlice
        package={sliceVersion ? `${slice}/${sliceVersion}` : slice}
        assetUrl={FE_SLICES_CDN}
        loadingComponent={<LoadingComponent />}
        loadUsing="window"
        dependencies={dependencies}
      />
    );
  }
  return <OnsiteAddOnEmptyState pageType="kiosk-mode" />;
};

OnsiteKioskMode.propTypes = {
  sliceVersion: string,
  onsiteAdvancedAddOnEnabled: bool,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.ONSITE,
    errorPattern: 'page',
  }),
)(OnsiteKioskMode);
