import { Select } from '@hopin-team/ui-select';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { CATEGORY_BLOCK, TYPE } from '../category-types';
import { iconStyles } from './styles';

const CategoryFormatField = ({
  categoryType,
  setCategoryType,
  hasErrors,
  errorMessage,
  lockProfileMatching,
  t,
}) => {
  const categoryOptions = [
    {
      value: CATEGORY_BLOCK[TYPE.MULTI].value,
      label: t(CATEGORY_BLOCK[TYPE.MULTI].label),
    },
    {
      value: CATEGORY_BLOCK[TYPE.SINGLE].value,
      label: t(CATEGORY_BLOCK[TYPE.SINGLE].label),
    },
  ];

  return (
    <StyledSelect
      mt={2}
      value={categoryType}
      setValue={setCategoryType}
      label={t('networking.profile-setup.format')}
      options={categoryOptions}
      leadingIcon={CATEGORY_BLOCK[categoryType].iconName}
      iconBackground={CATEGORY_BLOCK[categoryType].iconBackground}
      hasErrors={hasErrors}
      errorMessage={errorMessage}
      disabled={lockProfileMatching}
    />
  );
};

export default CategoryFormatField;

CategoryFormatField.propTypes = {
  categoryType: PropTypes.oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
  setCategoryType: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool,
  lockProfileMatching: PropTypes.bool,
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};

const StyledSelect = styled(Select)`
  width: 50%;

  .hpn-Select__CustomInput {
    display: flex;
    align-items: center;

    .hpn-Icon:first-of-type {
      ${iconStyles};
      color: white;
      background: ${({ iconBackground }) => iconBackground};
    }
  }
`;
