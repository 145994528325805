import MountSlice from '@components/slices/MountSlice';
import { string } from 'prop-types';
import React from 'react';

const packageName = '@hopin-team/slice-schedules-registration';

const RegistrationsSliceComponent = ({ publicSliceVersion }) => (
  <MountSlice packageName={packageName} version={publicSliceVersion} />
);

RegistrationsSliceComponent.propTypes = {
  publicSliceVersion: string,
};

export default RegistrationsSliceComponent;
