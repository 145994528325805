import { selectEvent } from '@features/recordings/redux/slices/event/event-selectors';
import { selectContentEnableRecordingsTrimmingAnalytics } from '@features/recordings/redux/slices/flags/flags-selectors';
import { selectExternalRegistrationId } from '@features/recordings/redux/slices/registration/registration-selector';
import {
  reportRecordingDownloadToAnalytics,
  reportRecordingPublishStatusUpdated,
} from '@features/recordings/utils/utils';
import { select, takeEvery } from 'redux-saga/effects';

import { recordingsActions } from '../recordings-actions';
import { updateRecordingThunk } from '../recordings-slice';

export function* trackRecordingPublishedStatusChanged(action) {
  const {
    meta: {
      arg: { attributes, recording },
    },
  } = action;

  if (
    typeof attributes.published !== 'undefined' &&
    attributes.published !== recording.published
  ) {
    const registrationExternalId = yield select(selectExternalRegistrationId);
    const { eventId } = yield select(selectEvent);
    const contentEnableRecordingsTrimmingAnalytics = yield select(
      selectContentEnableRecordingsTrimmingAnalytics,
    );

    reportRecordingPublishStatusUpdated(recording, {
      userId: registrationExternalId,
      eventId,
      isPublished: attributes.published,
      contentEnableRecordingsTrimmingAnalytics,
    });
  }
}

export function* trackRecordingDownloaded(action) {
  const {
    payload: { recording, method },
  } = action;
  const registrationExternalId = yield select(selectExternalRegistrationId);
  const { eventId } = yield select(selectEvent);
  const contentEnableRecordingsTrimmingAnalytics = yield select(
    selectContentEnableRecordingsTrimmingAnalytics,
  );

  reportRecordingDownloadToAnalytics(recording, {
    userId: registrationExternalId,
    eventId,
    method,
    contentEnableRecordingsTrimmingAnalytics,
  });
}

export function* trackRecordingUpdate() {
  yield takeEvery(
    updateRecordingThunk.fulfilled().type,
    trackRecordingPublishedStatusChanged,
  );
}

export function* trackRecordingDownload() {
  yield takeEvery(
    [
      recordingsActions.recordingGroupContainer.recordingDownloaded().type,
      recordingsActions.detailedPage.recordingControls.recordingDownloaded()
        .type,
      recordingsActions.detailedPage.relatedRecordings.recordingDownloaded()
        .type,
    ],
    trackRecordingDownloaded,
  );
}
