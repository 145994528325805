import { useState } from 'react';

const ML_WARNING_REGISTRY_KEY = '_hopin_warning_registry';

/**
 * Upon copying magic link from registrants page, a disclaimer is shown in form of a tooltip.
 * This hook registers in local storage user's external ID and event slug with
 * the goal of showing the disclaimer only once.
 */
export const useMLDisclaimerLogger = ({ eventSlug, userExternalId }) => {
  const [disclaimerViewed, setDisclaimerViewed] = useState(() => {
    const serializedData = localStorage.getItem(ML_WARNING_REGISTRY_KEY);

    if (serializedData === null) {
      return false;
    }

    const data = JSON.parse(window.atob(serializedData));
    const value = `${eventSlug}${userExternalId}`;
    return (Array.isArray(data) && data.includes(value)) ?? false;
  });

  const logDisclaimerAsViewed = () => {
    const serializedData = localStorage.getItem(ML_WARNING_REGISTRY_KEY);
    const data = JSON.parse(
      serializedData ? window.atob(serializedData) : '[]',
    );

    const value = `${eventSlug}${userExternalId}`;
    const newData = [...(Array.isArray(data) ? data : []), value];

    window.localStorage.setItem(
      ML_WARNING_REGISTRY_KEY,
      window.btoa(JSON.stringify(newData)),
    );

    setDisclaimerViewed(true);
  };

  return {
    disclaimerViewed,
    logDisclaimerAsViewed,
  };
};
