import Background from '@features/dashboard/assets/connections-empty-state.png';
import { useLocalization } from '@features/localization';
import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-top: 90px;
    margin-bottom: 60px;
    width: 370px;
  }
`;

const ConnectionsDashboardEmptyComponent = () => {
  const { t } = useLocalization('connections-dashboard.empty-state');

  return (
    <Wrapper>
      <img src={Background} alt="Connections empty state" />
      <h2 className="heading -level-2">{t('header')}</h2>
      <p className="text -light mt-16">{t('message')}</p>
    </Wrapper>
  );
};

export default ConnectionsDashboardEmptyComponent;
