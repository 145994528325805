import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import segmentTrack from '@util/analytics/segment-client';
import { string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const MissingFeature = ({ templateId }) => {
  const { id: orgId, subscriptionPlanTier } = useSelector(state => state?.org);

  const trackSubmit = () => {
    segmentTrack(EVENT.MISSING_FEATURE_SUBMITTED, {
      organiser_id: orgId,
      event_type: templateId,
      organisation_subscription_tier: subscriptionPlanTier,
    });
  };
  const { t } = useLocalization();
  return (
    <Flex flexDirection="column" flexWrap="wrap">
      <Text mt={1} mb={1} element="span" pattern="caption" color="grey-700">
        {t('event-creation-wizard.templates.missing-feature-text')}{' '}
        <a
          onClick={trackSubmit}
          href={`mailto:qstemplatesfeedback@hopin.to?subject=${t(
            'event-creation-wizard.templates.missing-feature-email-subject',
          )}`}
        >
          {t('event-creation-wizard.templates.missing-feature-text-link')}
        </a>
      </Text>
    </Flex>
  );
};

MissingFeature.propTypes = {
  templateId: string,
};

export default MissingFeature;
