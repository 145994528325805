import { ErrorListContainer } from '@features/event-creation-wizard/steps/basic-details/components/error-list/error-list.styles';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { shape, string } from 'prop-types';
import React from 'react';

const ErrorListComponent = ({ errors }) => {
  const errorList = [
    errors?.startDate?.message,
    errors?.endDate?.message,
  ].filter(Boolean);

  return errorList.length ? (
    <ErrorListContainer>
      {errorList.map(msg => (
        <FieldErrorMessage errorMessage={msg} key={msg} />
      ))}
    </ErrorListContainer>
  ) : null;
};

ErrorListComponent.propTypes = {
  errors: shape({
    startDate: shape({ message: string }),
    endDate: shape({ message: string }),
  }),
};

export default ErrorListComponent;
