import { timezonesMap } from '@features/constants/timezones-map';
import {
  format as formatDate,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz';

const DATE_TIME_FORMAT_STRING = 'do LLL, h:mm aaa (zzz)';
export const SCHEDULED_KIND_I18N_KEY_MAP = {
  event_end: 'survey-builder.list.event-end',
  ten_mins_before_event_end: 'survey-builder.list.10m-before-event-end',
  event_middle: 'survey-builder.list.event-middle',
};

export const surveyLaunchTime = (survey, event, isEnabled, t) => {
  if (!isEnabled) {
    return '-';
  }

  if (survey.publishedAt) {
    const datetime = utcStringToTimeZone(
      survey.publishedAt,
      standardEventTimeZone(event),
    );

    return t('survey-builder.list.displayed-at', { datetime });
  }

  if (survey.scheduledAt) {
    const datetime = utcStringToTimeZone(
      survey.scheduledAt,
      standardEventTimeZone(event),
    );
    return t('survey-builder.list.scheduled-for', { datetime });
  }

  return '';
};

export const standardEventTimeZone = event => timezonesMap[event.timezone];

// We need to display the scheduled_at/published_at time in the timezone of the event itself
// This is consistent with how the basic details (start/end time) and also the
// schedule selector works.
export const formatTimeInEventTimeZone = (scheduledAt, event) =>
  utcToZonedTime(scheduledAt, standardEventTimeZone(event));

export const utcStringToTimeZone = (utcDatetimeString, timeZone) => {
  const date = utcToZonedTime(new Date(utcDatetimeString), timeZone);
  return formatDate(date, DATE_TIME_FORMAT_STRING, { timeZone });
};

// When presenting the scheduled_at time to the organizer we convert from UTC to the timezone of
// the event itself. Refer to formatTimeInEventTimeZone. So on form submission we need to convert
// the scheduled_at back into UTC ISO8601 format (what the server wants).
// When we go to submit, formData.scheduled_at is muddled due to the way JavaScript handles dates and timezones.
// The hours/mins will be relative to the event timezone, but if we output formData.scheduled_at it will
// say the timezone is whatever the system timezone is :shrug:
// zonedTimeToUtc allows us to ignore the timezone of the date object, tell it what the real timezone is (second arg)
// and converts to UTC.
export const utcScheduledAt = (scheduledAt, event) =>
  zonedTimeToUtc(scheduledAt, standardEventTimeZone(event)).toISOString();

export const getErrorMessage = (message, t) => {
  if (
    message ===
    'scheduled_at must be within the event start time and doors closed'
  ) {
    return t('survey-builder.edit.errors.date-outside-event');
  }

  return t('survey-builder.edit.errors.invalid-field');
};

export const surveyLaunchText = (survey, isEnabled, t) => {
  if (!survey?.scheduledKind) return null;
  if (!isEnabled) return null;

  const text = SCHEDULED_KIND_I18N_KEY_MAP[survey.scheduledKind];
  if (text) return t(text);

  return null;
};
