import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { arrayOf, func, number, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Section } from '../../section';

const FieldContainer = styled.div.attrs({
  className: 'field',
})`
  width: 50%;
`;

const TicketType = ({ error, event, inputRef }) => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');

  return (
    <Section title={t('ticket-type-header')}>
      <Text element="p" pattern="labelThree" color="grey-600" mb={2}>
        {t('ticket-type-summary')}
      </Text>
      <FieldContainer>
        <label htmlFor="persona_id">{t('ticket-type')}</label>
        <select
          data-testid="ticket-type-select"
          name="persona_id"
          className="select -tiny"
          id="persona_id"
          ref={inputRef}
        >
          {event.personas
            .filter(({ onlyForOrganisers }) => !onlyForOrganisers)
            .map(({ id, label }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
        </select>
        {error && (
          <span
            data-testid="ticket-type-error"
            className="text-danger labelError"
          >
            {error.message}
          </span>
        )}
      </FieldContainer>
    </Section>
  );
};

TicketType.propTypes = {
  error: shape({
    message: string,
  }),
  event: shape({
    personas: arrayOf(
      shape({
        id: number.isRequired,
        label: string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  inputRef: func,
};

export default TicketType;
