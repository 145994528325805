import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { SelectGroup, SelectItem } from '@hopin-team/ui-select-items';
import { Text } from '@hopin-team/ui-text';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React from 'react';

const FeatureSelector = ({ features, onChange, titleKey }) => {
  const { t } = useLocalization('event-creation-wizard');

  return (
    <SelectGroup name="add-ons" aria-labelledby="select-add-ons">
      <Flex flexDirection="column" mr={16}>
        {titleKey && (
          <Text pattern="emphasis" element="p" color="grey-700" mb={2}>
            {titleKey}
          </Text>
        )}
        {features.map((feature, idx) => (
          <SelectItem
            key={idx}
            onChange={() => onChange(feature)}
            checked={feature.selected}
            label={t(feature.i18nKey)}
            value={t(feature.i18nKey)}
            mb={2}
            color={feature.selected ? 'blue-100' : 'grey-white'}
          />
        ))}
      </Flex>
    </SelectGroup>
  );
};

FeatureSelector.propTypes = {
  titleKey: string,
  features: arrayOf(
    shape({
      i18nKey: string.isRequired,
      selected: bool.isRequired,
      hidden: bool,
      isPaidOnly: bool,
    }),
  ).isRequired,
  onChange: func,
};

export default FeatureSelector;
