import { Avatar } from '@hopin-team/ui-avatar';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ListWrapper = styled.ul`
  margin-bottom: 0;
`;

const AttendeeName = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeight};
  font-size: ${props => props.theme.fontSize};
  color: ${props => props.theme.color};
  margin-bottom: 0.3rem;
`;

const AttendeeHeadline = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeight};
  font-size: ${props => props.theme.fontSize};
  color: ${props => props.theme.color};
`;

const ListItem = styled.li`
  border-top: 1px solid var(--color-gray-300);
  padding: 0.5rem 0;
  display: flex;
  word-break: break-word;
  align-items: center;

  &:first-child {
    border-top: none;
  }

  > span {
    padding: 0 1rem;
  }
`;

const Number = styled.span`
  color: var(--color-gray-700);
  margin-left: auto;
  white-space: nowrap;
`;

const PersonListComponent = ({ people, theme }) => {
  const themes = {
    default: {
      avatarSize: 'medium',
      name: {
        fontSize: '0.875rem',
        fontWeight: '500',
        color: 'var(--color-gray-700)',
      },
      headline: {
        fontSize: '0.875rem',
        fontWeight: '500',
        color: 'var(--color-gray-600)',
      },
    },
    concise: {
      avatarSize: 'small',
      name: {
        FontSize: '0.875rem',
        fontWeight: '500',
        marginBottom: '0',
      },
      headline: {
        fontSize: '0.75rem',
        fontWeight: '400',
        marginBottom: '0',
      },
    },
  };

  return (
    <div>
      <ListWrapper>
        {people.map(person => {
          return (
            <ListItem key={person.id || person.external_id}>
              <Avatar
                alt={themes[theme].avatarSize}
                size={themes[theme].avatarSize}
                src={person.avatar_url}
              />
              <span>
                <AttendeeName theme={themes[theme].name}>
                  {person.name}
                </AttendeeName>
                <AttendeeHeadline theme={themes[theme].headline}>
                  {person.headline}
                </AttendeeHeadline>
              </span>
              {person.interaction_count && (
                <Number>{person.interaction_count}</Number>
              )}
            </ListItem>
          );
        })}
      </ListWrapper>
    </div>
  );
};

PersonListComponent.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      external_id: PropTypes.string,
      name: PropTypes.string,
      headline: PropTypes.string,
      avatar_url: PropTypes.string,
      interaction_count: PropTypes.integer,
    }),
  ),
  theme: PropTypes.string,
};

PersonListComponent.defaultProps = {
  theme: 'default',
};

export default PersonListComponent;
