import { withSpace } from '@hopin-team/ui-helper-with-space';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useUIDSeed } from 'react-uid';

import { CHANGE_TYPE } from '../../utils';
import { InputContainer } from './partials/container.styled';
import { OverlaidRangeInput } from './partials/overlaid-range-input';
import { VisuallyHiddenLabel } from './range-selector.styled';

// needs to be 1 so we could select the end of a video that has an odd number of seconds in it, i.e. 00:41
const END_SELECTOR_STEP = 1;

const RangeSelector = ({
  min,
  step = 1,
  max,
  start = min,
  startLabel = 'Trim Range Start Thumb',
  end = max,
  endLabel = 'Trim Range End Thumb',
  onRangeChange,
  label,
  labelledById,
}) => {
  const a11yProps = {};
  if (label) {
    a11yProps['aria-label'] = label;
  }
  if (labelledById) {
    a11yProps['aria-labelledby'] = labelledById;
  }

  const uid = useUIDSeed();

  const handleStartChange = useCallback(
    newStart => {
      onRangeChange?.(newStart, end, CHANGE_TYPE.start);
    },
    [end, onRangeChange],
  );

  const handleEndChange = useCallback(
    newEnd => {
      onRangeChange?.(start, newEnd, CHANGE_TYPE.end);
    },
    [start, onRangeChange],
  );

  return (
    <InputContainer
      aria-label={label}
      min={min}
      max={max}
      start={start}
      end={end}
      {...a11yProps}
    >
      <VisuallyHiddenLabel htmlFor={uid('start')}>
        {startLabel}
      </VisuallyHiddenLabel>
      <OverlaidRangeInput
        id={uid('start')}
        rangeMin={min}
        selectableMin={min}
        value={start}
        onChange={handleStartChange}
        step={step}
        rangeMax={max}
        selectableMax={end - step}
      ></OverlaidRangeInput>
      <VisuallyHiddenLabel htmlFor={uid('end')}>{endLabel}</VisuallyHiddenLabel>
      <OverlaidRangeInput
        id={uid('end')}
        rangeMin={min}
        selectableMin={start + step}
        value={end}
        onChange={handleEndChange}
        step={END_SELECTOR_STEP}
        rangeMax={max}
        selectableMax={max}
      ></OverlaidRangeInput>
    </InputContainer>
  );
};

RangeSelector.propTypes = {
  min: PropTypes.number.isRequired,
  step: PropTypes.number,
  max: PropTypes.number.isRequired,
  start: PropTypes.number,
  startLabel: PropTypes.string,
  end: PropTypes.number,
  endLabel: PropTypes.string,
  onRangeChange: PropTypes.func,
  onDrag: PropTypes.func,
  label: PropTypes.string,
  labelledById: PropTypes.string,
};

const RangeSelectorWithSpace = withSpace(RangeSelector);

export { RangeSelectorWithSpace as RangeSelector };
