import DateTimeRangePicker from '@components/date-time-range-picker/date-time-range-picker-component';
import Loading from '@components/loading';
import { LocalizationContext } from '@features/localization';
import { bool, func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import {
  RangePickerDisplayText,
  RangePickerIcon,
  RangePickerWrapper,
  RangeSelect,
  SelectWrapper,
} from './styles';

const formatRange = (rangeStart, rangeEnd, format) => {
  const formattedRangeStart = format(rangeStart, 'd MMM y');
  const formattedRangeEnd = format(rangeEnd, 'd MMM y');
  const isSameDate = rangeStart.toString() === rangeEnd.toString();

  return isSameDate
    ? formattedRangeStart
    : `${formattedRangeStart} - ${formattedRangeEnd}`;
};

const onRangeSelected = (rangeStart, rangeEnd, onChange, formatDate) => {
  if (rangeStart && rangeEnd) {
    const displayText = formatRange(rangeStart, rangeEnd, formatDate);
    const keyForQuery = `${rangeStart.toISOString()}-${rangeEnd.toISOString()}`;

    onChange({
      key: keyForQuery,
      displayText,
      rangeStart,
      rangeEnd,
    });
  }
};

const onCancelClick = (setRangeStart, setRangeEnd, onCancel) => e => {
  // prevent cancel click from re-opening DateTimeRangePicker
  e.preventDefault();
  setRangeStart(null);
  setRangeEnd(null);
  onCancel(e);
};

const RangePicker = ({
  displayText,
  loading,
  rangeSelected,
  onChange,
  onCancel,
}) => {
  const { formatDate } = useContext(LocalizationContext);
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);

  useEffect(() => onRangeSelected(rangeStart, rangeEnd, onChange, formatDate), [
    rangeStart,
    rangeEnd,
    onChange,
    formatDate,
  ]);

  const icon = rangeSelected ? (
    <RangePickerIcon
      name="close"
      onClick={onCancelClick(setRangeStart, setRangeEnd, onCancel)}
      scale={1.25}
      color="grey-600"
    />
  ) : (
    <RangePickerIcon name="arrow-down" scale={2} color="grey-600" />
  );

  return (
    <SelectWrapper>
      <RangePickerWrapper data-testid="range-picker">
        <RangeSelect>
          <Loading isLoading={loading}>
            <RangePickerDisplayText>
              {displayText}
              {icon}
            </RangePickerDisplayText>
          </Loading>
        </RangeSelect>
        <DateTimeRangePicker
          startDate={rangeStart}
          endDate={rangeEnd}
          setStartDate={setRangeStart}
          setEndDate={setRangeEnd}
        />
      </RangePickerWrapper>
    </SelectWrapper>
  );
};

RangePicker.propTypes = {
  displayText: string.isRequired,
  loading: bool.isRequired,
  rangeSelected: bool.isRequired,
  onChange: func.isRequired,
  onCancel: func.isRequired,
};

export default RangePicker;
