import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import compose from 'lodash/fp/compose';
import { bool, func } from 'prop-types';
import React from 'react';

import { ConfirmModal } from '../../confirm-modal';

const ConfirmUpdateEmailModal = ({
  isModalDisplayed,
  handleClose,
  confirmUpdate,
}) => {
  const { t } = useLocalization('communications.customise-emails');

  return (
    <ConfirmModal
      label="Save Template Emails Modal"
      message={t(`preview.confirm-update.description`)}
      open={isModalDisplayed}
      onCancel={handleClose}
      onConfirm={confirmUpdate}
      cancelButtonProps={{
        'data-testid': 'confirm-update-button-cancel',
      }}
      confirmButtonProps={{
        'data-testid': 'confirm-update-button',
        label: t(`preview.confirm-update.confirm`),
        pattern: 'primary',
      }}
    />
  );
};

ConfirmUpdateEmailModal.propTypes = {
  handleClose: func.isRequired,
  isModalDisplayed: bool.isRequired,
  confirmUpdate: func.isRequired,
};

export default compose(withLocalizationProvider)(ConfirmUpdateEmailModal);
