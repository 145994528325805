import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Badge } from '@hopin-team/ui-badge';
import { Flex } from '@hopin-team/ui-flex';
import { Link } from '@hopin-team/ui-link';
import { PhoneField } from '@hopin-team/ui-phone-field';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import segmentTrack from '@util/analytics/segment-client';
import { bool, func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { EVENTS } from './constants/events';
import LeftComponent from './left-component';
import {
  Form,
  RightHalf,
  StyledButton,
  StyledModal,
  StyledText,
} from './style';

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  region: '',
  phone: {},
};

const MarketoModal = ({
  isShowing,
  setIsShowing,
  campaign,
  dictionary = 'default',
}) => {
  const { t, dateLocale } = useLocalization(`marketo-modals.${dictionary}`);
  const { addAlert } = useContext(AlertsContext);
  const { code } = dateLocale;

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isShowing) {
      segmentTrack(EVENTS.shown, {
        campaign,
      });
    }
  }, [isShowing]);

  const handleField = (value, field) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const handleOnClose = () => {
    setIsShowing(false);
    setFormValues(defaultFormValues);
  };

  const leadForm = {
    leadFormFields: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone.phone,
      Your_Region__c: formValues.region,
      utm_campaign__c: campaign,
    },
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await hopinApi.marketoLead({
        formId: 1571,
        input: [leadForm],
      });
      segmentTrack(EVENTS.submitted, {
        campaign,
      });

      setLoading(false);

      segmentTrack(EVENTS.chilipiperShown, { campaign });
      if (window.ChiliPiper) {
        window.ChiliPiper.submit('hopin', 'onsite-upgrade-flow', {
          lead: leadForm.leadFormFields,
          onError: () => {
            addAlert({
              id: 'chilipiper-failure',
              active: true,
              pattern: 'error',
              text: t('error'),
            });
            segmentTrack(EVENTS.chilipiperFailed, { campaign });
          },
          onClose: handleOnClose(),
          locale: code,
          onSuccess: () => {
            segmentTrack(EVENTS.chilipiperMeetingBooked, { campaign });
          },
        });
      } else {
        throw Error('Chilipiper is enabled only in PROD environment!');
      }
    } catch (e) {
      setLoading(false);
      setFormValues(defaultFormValues);
      addAlert({
        id: 'marketo-form-failure',
        active: true,
        pattern: 'error',
        text: t('error'),
      });
      segmentTrack(EVENTS.failed, {
        campaign,
      });
    }
  };

  const disabled = !Object.values(formValues).every(
    val => val && (val.isValidNumber || val.length >= 2),
  );
  return (
    <>
      <Alerts />
      <StyledModal
        isDismissible
        isShowing={isShowing}
        onClose={handleOnClose}
        label="marketo-upgrade-modal"
        withCloseButton
        size="large"
      >
        <Flex>
          <LeftComponent />
          <RightHalf>
            <Form onSubmit={handleSubmit}>
              <Flex alignItems="center" mt={2} mb={1}>
                <StyledText mr={0.5}>Onsite</StyledText>
                <Badge label="Plus" colorPattern="inverse" />
              </Flex>
              <Text pattern="headingOne" mb={1}>
                {t('title')}
              </Text>
              <Text pattern="body" mb={3}>
                {t('subtitle')}
              </Text>
              <TextField
                mb={0.5}
                requiredText={t('required')}
                value={formValues.firstName}
                onChange={e => handleField(e.target.value, 'firstName')}
                isRequired
                label={t('first-name-label')}
                placeholder={t('first-name-placeholder')}
                name="firstName"
                size="small"
              />
              <TextField
                mb={0.5}
                requiredText={t('required')}
                value={formValues.lastName}
                onChange={e => handleField(e.target.value, 'lastName')}
                isRequired
                label={t('last-name-label')}
                placeholder={t('last-name-placeholder')}
                name="lastName"
                size="small"
              />
              <TextField
                mb={0.5}
                requiredText={t('required')}
                value={formValues.email}
                onChange={e => handleField(e.target.value, 'email')}
                isRequired
                type="email"
                label={t('email-label')}
                placeholder={t('email-placeholder')}
                name="email"
                size="small"
              />
              <Select
                requiredText={t('required')}
                name="region"
                value={formValues.region}
                setValue={val => handleField(val, 'region')}
                label={t('region-label')}
                placeholder={t('region-placeholder')}
                isRequired
                options={[
                  {
                    value: 'North America',
                    label: t('region-north-america'),
                  },
                  {
                    value: 'Europe, Middle East, Africa',
                    label: t('region-emea'),
                  },
                  {
                    value: 'Asia-Pacific & Japan',
                    label: t('region-asia'),
                  },
                  {
                    value: 'Latin America & Caribbean',
                    label: t('region-latin-america'),
                  },
                ]}
                leadingIcon="location-pin"
                visibleOptionsCount={4}
              />
              <PhoneField
                requiredText={t('required')}
                isRequired
                value={formValues.phone}
                onChange={value => handleField(value, 'phone')}
                label={t('telephone-label')}
                name={t('telephone-placeholder')}
                sizeOption="small"
              />
              <Text pattern="caption" mt={3} mb={2}>
                {t('privacy-text')}
                <Link
                  scale={3}
                  label={t('privacy-link')}
                  href="/legal/privacy"
                />
              </Text>
              <StyledButton
                isLoading={loading}
                disabled={disabled}
                onClick={() => !disabled && handleSubmit()}
                isInline
                type="submit"
              >
                {t('schedule-a-call')}
              </StyledButton>
            </Form>
          </RightHalf>
        </Flex>
      </StyledModal>
    </>
  );
};

MarketoModal.propTypes = {
  isShowing: bool,
  setIsShowing: func,
  campaign: string.isRequired,
  dictionary: string,
};

export default withAlertsProvider(MarketoModal);
