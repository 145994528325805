import { useLocalization } from '@features/localization';
import { EVENTS } from '@util/analytics/event-templates';
import trackSegmentEvent from '@util/analytics/segment-client';
import { bindToPusher } from '@util/pusher';
import { stubTrue } from 'lodash';
import { arrayOf, bool, func, number, oneOf, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getPusherChannel, pusherEvents } from '../constants';
import { steps } from './constants';
import { Root } from './styled';
import { TemplateModalError } from './template-modal-error';
import { TemplateModalForm } from './template-modal-form';
import { TemplateModalInProgress } from './template-modal-in-progress';
import { TemplateModalPreview } from './template-modal-preview';
import { TemplateModalSelection } from './template-modal-selection';

const IS_SUBMITTING_DELAY_MS = 250;

let isSubmittingTimeout = 0;

export const TemplateModal = ({
  onClose: parentOnClose,
  initialStep = steps.SELECTION,
  initialSelectedTemplate = {},
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasRemoteError, setHasRemoteError] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [step, setStep] = useState(initialStep);
  const [selectedTemplate, setSelectedTemplate] = useState(
    initialSelectedTemplate,
  );
  const [history, setHistory] = useState([initialStep]);
  const { t } = useLocalization('templates');
  const [eventExternalId, setEventExternalId] = useState();
  const [eventSlug, setEventSlug] = useState();
  const [currentStep, setCurrentStep] = useState();

  useEffect(() => {
    trackSegmentEvent(EVENTS.modal_show);
  }, []);

  const handleSubmit = isSubmitting => {
    if (isSubmitting) {
      // Give time for validation errors to occur and also avoid flash for
      // very fast submissions
      isSubmittingTimeout = setTimeout(() => {
        setIsSubmitting(stubTrue);
      }, IS_SUBMITTING_DELAY_MS);
    } else {
      clearTimeout(isSubmittingTimeout);
      setIsSubmitting(false);
    }
  };

  const goToStep = step => {
    setHistory(history => [...history, step]);
    setStep(step);
  };

  const handleSuccess = ({
    id: eventExternalId,
    attributes: { slug: eventSlug },
  } = {}) => {
    setEventExternalId(eventExternalId);
    setEventSlug(eventSlug);
  };

  const handleBack = () => {
    const previousStep = history.length > 1 && history[history.length - 2];
    if (previousStep) {
      setHistory(history => history.slice(0, -1));
      setStep(previousStep);
    }
  };

  const handleError = errors => {
    console.error('Error creating event:', errors);
    setHasRemoteError(true);
  };

  const handleTryAgain = () => {
    setHasRemoteError(false);
  };

  const handleTemplateSelection = (template, mode) => {
    setSelectedTemplate(template);
    goToStep(mode);
  };

  const renderModalStep = () => {
    switch (step) {
      case steps.SELECTION:
        return (
          <TemplateModalSelection
            onPreviewClick={template =>
              handleTemplateSelection(template, steps.PREVIEW)
            }
            onUseClick={template =>
              handleTemplateSelection(template, steps.FORM)
            }
          />
        );
      case steps.PREVIEW:
        trackSegmentEvent(EVENTS.template_previewed, {
          template_id: selectedTemplate.id,
        });
        return (
          <TemplateModalPreview
            goToStep={goToStep}
            template={selectedTemplate}
            {...rest}
          />
        );
      case steps.FORM:
        // TODO: If there is no `selectedTemplate`, it's a blank event. Do we want to track?
        if (selectedTemplate) {
          trackSegmentEvent(EVENTS.template_selected, {
            template_id: selectedTemplate.id,
          });
        }
        return (
          /* NOTE: Don't want this to re-mount/re-render so don't put into ternary */
          <TemplateModalForm
            visible={!isSubmitting && !hasRemoteError && !hasSucceeded}
            onSubmitting={handleSubmit}
            onSuccess={handleSuccess}
            onError={handleError}
            template={selectedTemplate}
            {...rest}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    let unbind;
    async function bindToPusherAndAssignUnbind() {
      unbind = await bindToPusher({
        channelName: getPusherChannel(eventExternalId),
        eventName: pusherEvents.EVENT_DUPLICATION_FINISHED,
        handler: () => {
          if (eventSlug) {
            setHasSucceeded(true);
            setCurrentStep();
            unbind();
            window.location.assign(`/organisers/events/${eventSlug}/overview`);
          }
        },
      });
    }

    if (eventExternalId) {
      if (selectedTemplate) {
        bindToPusherAndAssignUnbind();

        return () => {
          try {
            unbind();
          } catch (err) {
            console.error('Error unbding pusher event', err);
          }
        };
      } else {
        if (eventSlug) {
          setHasSucceeded(true);
          setCurrentStep();
          window.location.assign(`/organisers/events/${eventSlug}/overview`);
        }
      }
    }
  }, [eventSlug, eventExternalId, selectedTemplate]);

  useEffect(() => {
    let unbind;
    async function bindToPusherAndAssignUnbind() {
      unbind = await bindToPusher({
        channelName: getPusherChannel(eventExternalId),
        eventName: pusherEvents.EVENT_DUPLICATION_EVENT_SECTION_FINISHED,
        handler: ({ event_section }) => {
          setCurrentStep(event_section);
        },
      });
    }

    if (eventExternalId && selectedTemplate) {
      bindToPusherAndAssignUnbind();

      return () => {
        try {
          unbind();
        } catch (err) {
          console.error('Error unbinding pusher event', err);
        }
      };
    }
  }, [eventExternalId, selectedTemplate]);

  return (
    <Root
      backLabel={
        history.length > 1 &&
        t('modal.accessibleLabels.back', {
          step: t(
            `modal.accessibleLabels.steps.${history[history.length - 2]}`,
          ),
        })
      }
      closeLabel={t('modal.accessibleLabels.close')}
      describedById="create-event-modal-heading"
      onClose={parentOnClose}
      onBack={handleBack}
      withBackButton={history.length > 1}
      {...rest}
    >
      {hasRemoteError ? (
        <TemplateModalError onTryAgain={handleTryAgain} />
      ) : isSubmitting || hasSucceeded ? (
        <TemplateModalInProgress
          hasSucceeded={hasSucceeded}
          currentStep={currentStep}
        />
      ) : (
        renderModalStep()
      )}
    </Root>
  );
};

TemplateModal.propTypes = {
  initialStep: string,
  initialSelectedTemplate: shape({
    id: string.isRequired,
    image: string.isRequired,
    name: string.isRequired,
    attendees: string.isRequired,
    description: string.isRequired,
    enabledEventParts: arrayOf(
      oneOf(['stage', 'sessions', 'schedule', 'expo', 'networking']),
    ).isRequired,
  }),
  onClose: func.isRequired,
  isOnFreePlan: bool.isRequired,
  maxEventDurationSecs: number.isRequired,
  organizationExternalId: string.isRequired,
};
