/* global Rails */
import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import * as Routes from '@routes';
import { useForm } from 'react-hook-form';
import { mixed, number, object } from 'yup';

const noop = () => {};

const useBulkInviteForm = ({ event, onSuccess = noop, onError = noop }) => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');
  const { handleSubmit, ...rest } = useForm({
    resolver: yupResolver(
      object().shape({
        csv_file: mixed()
          .transform(val => (val.length === 0 ? undefined : val))
          .required(t('file-required'))
          .test('file-type', t('file-type-extension'), () => true),
        persona_id: number()
          .transform(val => (isNaN(val) ? undefined : val))
          .required(t('choose-ticket-type')),
        event_id: number().required(),
      }),
    ),
  });

  const handleHookFormSubmit = async (values, e) => {
    const url = Routes.importCsvOrganisersEventRedeemCodesPath(event.slug);
    const formData = new FormData(e.target);
    formData.set('csv_file', values['csv_file'][0]);
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-TOKEN': Rails.csrfToken(),
        },
      });
      if (res.ok) {
        onSuccess();
      } else {
        onError();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSubmit: handleSubmit(handleHookFormSubmit),
    ...rest,
  };
};

export default useBulkInviteForm;
