import { MetricList } from '@features/dashboard/styles/metric-list.styles';
import AttendeesOnScreenSVG from 'icons/metrics/attendees-on-screen.svg';
import ChatMessagesNewSVG from 'icons/metrics/chat-messages-new.svg';
import ChatReactionsSVG from 'icons/metrics/chat-reactions.svg';
import ConfettiSentSVG from 'icons/metrics/confetti-sent.svg';
import ConnectionRequestsNewSVG from 'icons/metrics/connection-requests-new.svg';
import NetworkingMeetingsNewSVG from 'icons/metrics/networking-meetings-new.svg';
import QuestionsAskedSVG from 'icons/metrics/questions-asked.svg';
import ScheduledMeetingsNewSVG from 'icons/metrics/scheduled-meetings-new.svg';
import VideoReactionsSVG from 'icons/metrics/video-reactions.svg';
import { number } from 'prop-types';
import React from 'react';

import EngagementTiles from './engagement-tiles';

const engagementMetricsRow1 = [
  {
    icon: ChatMessagesNewSVG,
    text: 'chat-messages',
    key: 'total_chat_messages',
  },
  {
    icon: ChatReactionsSVG,
    text: 'chat-reactions',
    key: 'chat_reactions',
    isAnalytics: true,
  },
  {
    icon: VideoReactionsSVG,
    text: 'video-reactions',
    key: 'video_reactions',
    isAnalytics: true,
  },
  {
    icon: AttendeesOnScreenSVG,
    text: 'attendees-on-screen',
    key: 'sessions_joined',
    isAnalytics: true,
  },
  {
    icon: ConfettiSentSVG,
    text: 'confetti-sent',
    key: 'confetti',
    isAnalytics: true,
  },
];
const engagementMetricsRow2 = [
  {
    icon: NetworkingMeetingsNewSVG,
    text: 'networking-meetings',
    key: 'total_networking_meetings',
  },
  {
    icon: ConnectionRequestsNewSVG,
    text: 'connection-requests',
    key: 'total_connection_requests',
  },
  {
    icon: ScheduledMeetingsNewSVG,
    text: 'scheduled-meetings',
    key: 'total_scheduled_meetings',
  },
  {
    icon: QuestionsAskedSVG,
    text: 'questions-asked',
    key: 'total_questions_asked',
  },
  { icon: ChatMessagesNewSVG, text: 'poll-votes', key: 'total_poll_votes' },
];

const EngagementMetrics = (values, isLoading, isAnalyticsLoading) => {
  const engagementMetricsRow = [engagementMetricsRow1, engagementMetricsRow2];
  return engagementMetricsRow.map((metrics, index) => {
    return (
      <MetricList key={index}>
        {EngagementTiles(metrics, values, isLoading, isAnalyticsLoading)}
      </MetricList>
    );
  });
};

EngagementMetrics.propTypes = {
  eventId: number.isRequired,
};

export default EngagementMetrics;
