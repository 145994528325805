import getDisplayName from '@util/get-display-name';
import { node } from 'prop-types';
import React, { useState } from 'react';

import { Modal } from './modal';
import { ModalContext } from './modal-context';

const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState();
  const [size, setSize] = useState('small');
  const [overflow, setOverflow] = useState('auto');

  const closeModal = () => setIsModalOpen(false);

  return (
    <ModalContext.Provider
      value={{
        setContent,
        setIsModalOpen,
        setSize,
        isModalOpen,
        setOverflow,
      }}
    >
      <Modal
        size={size}
        closeModal={closeModal}
        isOpen={isModalOpen}
        overflow={overflow}
      >
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: node,
};

export const withModalProvider = WrappedComponent => {
  const WithModalProvider = props => {
    return (
      <ModalProvider>
        <WrappedComponent {...props} />
      </ModalProvider>
    );
  };
  WithModalProvider.displayName = `withModalProvider(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithModalProvider;
};

export default ModalProvider;
