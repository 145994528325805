import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Halon } from '@hopin-team/ui-halon';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { uploadFileToS3 } from '@util/upload-file-to-s3';
import * as P from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { LINKS } from '../../constants/step-links';
import SessionsImageInput from './sessions-image-input';

const StyledBox = styled(Box)`
  display: grid;
  grid-template-columns: 2rem 1fr;
  padding-top: 1rem;
`;

const SessionsForm = ({ form, setImageUploading }) => {
  const sessionImage = useSelector(state => state.sessions.image);
  const { id: userId } = useSelector(state => state?.user);
  const { t } = useLocalization('event-creation-wizard.steps.sessions.form');
  const { addAlert } = React.useContext(AlertsContext);
  const [imageErrorMessage, setImageErrorMessage] = useState('');

  React.useEffect(() => {
    form.register('image');
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const onImageDrop = async imageFile => {
    try {
      setImageErrorMessage('');
      setImageUploading(true);

      const {
        data: { image_url, presigned_url, params },
      } = await hopinApi
        .getPresignedUrl({
          fileName: imageFile.name,
          entityId: Number(userId),
          entityField: 'wizard_session_image',
          entityName: 'user',
        })
        .json();

      form.setValue('image', image_url);

      await uploadFileToS3(presigned_url, imageFile, params);
    } catch (e) {
      addAlert({
        active: true,
        pattern: 'error',
        text: t('image.error-message-generic'),
      });
    } finally {
      // Reset the uploading flag if we've successfully uploaded
      // the image or if we've encountered an error
      setImageUploading(false);
    }
  };

  const onImageDropError = fileRejections => {
    // We only respond to file-size errors because the `image-dropzone`
    // component renders an inline message for file-type errors.
    const fileSizeErrors = fileRejections.filter(({ errors }) =>
      errors.find(error => error.code === 'file-too-large'),
    );

    if (fileSizeErrors.length > 0) {
      setImageErrorMessage(t('image.error-message-file-size'));
    }
  };

  const onImageRemove = () => {
    form.setValue('image', '');
  };

  return (
    <form>
      <TextField
        ref={form.register}
        mb={3.5}
        name="title"
        label={t('title.label')}
        placeholder={t('title.placeholder')}
        hasErrors={Boolean(form.errors.title)}
        errorMessage={form.errors.title?.message}
        data-testid="title"
        isRequired
      />

      <TextField
        ref={form.register}
        mb={3.5}
        name="description"
        label={t('description.label')}
        placeholder={t('description.placeholder')}
        hasErrors={Boolean(form.errors.description)}
        errorMessage={form.errors.description?.message}
        data-testid="description"
        isRequired
      />

      <SessionsImageInput
        onDrop={onImageDrop}
        onDropError={onImageDropError}
        onRemove={onImageRemove}
        pictureUrl={sessionImage}
        errorMessage={imageErrorMessage}
      />

      <StyledBox>
        <Halon
          icon="info-circle"
          color="grey-dark"
          iconColor="grey-300"
          iconScale={3}
          scale={1.5}
          ml={1}
          mt={1}
        />
        <Text
          element="span"
          pattern="caption"
          color="grey-700"
          ml={1}
          dangerouslySetInnerHTML={{
            __html: t(`info-box`, { link: LINKS.VIEWER_LIMITS }),
          }}
        />
      </StyledBox>
    </form>
  );
};

SessionsForm.propTypes = {
  form: P.shape({
    register: P.func.isRequired,
    errors: P.object.isRequired,
    setValue: P.func.isRequired,
    getValues: P.func.isRequired,
  }).isRequired,
  setImageUploading: P.func.isRequired,
};

export default SessionsForm;
