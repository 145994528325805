import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { Toast } from '@hopin-team/ui-toast';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const ERROR_MESSAGES = [
  {
    code: 'PAYMENT_PLAN_NOT_ALLOWED',
    titleKey: 'not-available-on-plan',
    subtitleKey: 'upgrade-plan',
  },
  {
    code: 'INVALID_CSV_FILE',
    titleKey: 'invalid-file',
    subtitleKey: 'verify-csv-structure',
  },
  {
    code: 'CSV_LIMIT_REACHED',
    titleKey: 'csv-rows-excess',
    subtitleKey: 'csv-rows-limit',
  },
  {
    code: 'TICKET_NOT_FREE',
    titleKey: 'paid-ticket',
    subtitleKey: 'select-free-ticket',
  },
  {
    code: 'INVALID_PERSONAS',
    titleKey: 'invalid-tickets',
    subtitleKey: 'invalid-tickets-subtitle',
  },
  {
    code: 'EMPTY',
    titleKey: 'invalid-file',
    subtitleKey: 'follow-requirements',
  },
  {
    code: 'DUPLICATED_EMAILS',
    titleKey: 'duplicated-emails',
    subtitleKey: 'duplicated-emails-subtitle',
  },
  {
    code: 'DUPLICATED_EXTERNAL_BARCODES',
    titleKey: 'duplicated-external-barcodes',
    subtitleKey: 'duplicated-external-barcodes-subtitle',
  },
  {
    code: 'NO_COLUMN_SEPARATOR_FOUND',
    titleKey: 'column-separator-not-supported',
    subtitleKey: 'column-separator-not-supported-subtitle',
  },
  {
    code: 'registrants_limit_reached',
    subtitleKey: 'registrants-limit-reached',
  },
  {
    code: 'event_invalid',
    subtitleKey: 'event-invalid',
  },
  {
    code: 'event_not_live',
    subtitleKey: 'event-not-live',
  },
  {
    code: 'payment_plan_not_allowed',
    subtitleKey: 'payment-plan-not-allowed',
  },
  {
    code: 'email_duplicate',
    subtitleKey: 'email-duplicate',
  },
  {
    code: 'external_barcode_duplicate',
    subtitleKey: 'external-barcode-duplicate',
  },
];

export const GENERIC_ERROR_MESSAGE = {
  titleKey: 'upload-failed',
  subtitleKey: 'refresh-page',
};

const StyledToast = styled(Toast)`
  background-color: #fff2f2;
  height: ${rem(56)};
`;

export const ErrorToast = ({ title, subtitle }) => {
  return (
    <StyledToast icon="alert" role="status" iconColor="red-600">
      <Box mr={2}>
        {title && (
          <Text pattern="strong" color="gray-400">
            {title}
          </Text>
        )}{' '}
        {subtitle && (
          <Text pattern="body" color="gray-400">
            {subtitle}
          </Text>
        )}
      </Box>
    </StyledToast>
  );
};

ErrorToast.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
