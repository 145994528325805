import { LocalizationContext } from '@features/localization';
import {
  BlankCard as UIBlankCard,
  SponsorCard as UISponsorCard,
} from '@hopin-team/ui-sponsors-grid';
import React, { useContext, useState } from 'react';

import IconActions from './icons/icon-actions.svg';

export default function SponsorCard({
  sponsorArea,
  onDelete,
  onCardAdd,
  editable,
}) {
  const { t } = useContext(LocalizationContext);
  const [dropdownIconVisible, setDropdownIconVisible] = useState(false);
  const [dropdownMenuOpened, setDropdownMenuOpened] = useState(false);

  const handleDropdownIconClick = event => {
    // do not open a sponsors page
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDelete = event => {
    onDelete(sponsorArea);

    event.preventDefault();
    event.stopPropagation();
  };

  if (sponsorArea.placeholder) {
    return (
      <UIBlankCard
        onClick={e => {
          e.preventDefault();
          onCardAdd(e);
        }}
      />
    );
  } else {
    return (
      <UISponsorCard
        name={sponsorArea.sponsor.name}
        imageUrl={sponsorArea.sponsor.logo}
        url={sponsorArea.sponsor.website}
        onMouseEnter={() => setDropdownIconVisible(true)}
        onMouseLeave={() => setDropdownIconVisible(false)}
        style={{
          // Explicitly set styles as an organizer panel do not implement
          // all custom branding variables. We will remove it as soon as
          // reception and registration pages styles are unified.
          backgroundColor: 'var(--color-white)',
          borderRadius: 'var(--border-radius)',
          border: '1px solid var(--color-gray-200)',
          boxShadow: 'var(--box-shadow)',
        }}
      >
        {editable && (
          <div
            className="position-absolute text -right pr-8 mt-2 mr-2"
            style={{ top: 0, right: 0, width: '2rem' }}
            onMouseEnter={() => setDropdownMenuOpened(true)}
            onMouseLeave={() => setDropdownMenuOpened(false)}
          >
            {dropdownIconVisible && (
              <>
                <button className="icon" onClick={handleDropdownIconClick}>
                  <IconActions className="actions" />
                </button>

                <div
                  className={`menu -top-right ${
                    dropdownMenuOpened ? '-open' : ''
                  }`}
                >
                  <span
                    className="menu_item"
                    role="button"
                    tabIndex={0}
                    onClick={handleDelete}
                    onKeyDown={handleDelete}
                  >
                    {t('common.remove')}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </UISponsorCard>
    );
  }
}
