import PrintableContainer from '@components/printable/printable-container';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import NoticeItem from '@features/dashboard/common/notice-item';
import EventPhaseToggleComponent from '@features/dashboard/components/event-phase-toggle-component';
import LatestConnectionsContainer from '@features/dashboard/components/latest-connections/latest-connections-container';
import PrintHeaderComponent from '@features/dashboard/components/print-header-component';
import TopAreasByChatMessages from '@features/dashboard/components/top-areas-by-chat-messages';
import ConnectionsMadeChartContainer from '@features/dashboard/connections-dashboard/connections-made-chart/connections-made-chart-container';
import ConnectionsOverTime from '@features/dashboard/connections-dashboard/connections-over-time';
import TopSponsorsByNetworkingMeetings from '@features/dashboard/connections-dashboard/top-sponsors-by-networking-meetings-component';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { bool, func, number, object, shape, string } from 'prop-types';
import compose from 'ramda/src/compose';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import TippyTooltipStyleOverride from '../../../style/TippyTooltipStyleOverride';
import ConnectionsDashboardEmptyComponent from './connections-dashboard-empty-component';
import EngagementKeyMetrics from './key-metrics/engagement-key-metrics';
import KeyMetrics from './key-metrics/key-metrics';
import MostEngagedAttendees from './most-engaged-attendees';
import TopAttendeesByNetworkingMeetings from './top-attendees-by-networking-meetings';

const Header = styled.h5`
  margin: auto 0;
`;

const DashboardContainer = styled.div`
  @media print {
    .dashboard-button,
    .flash {
      display: none;
    }
    canvas.chartjs-render-monitor {
      width: 100% !important;
    }
  }
`;

const ConnectionsDashboardComponent = ({
  event,
  currentPhase,
  setCurrentPhase,
  showEventPhaseToggle,
  showEngagementScore,
  topAttendeesByNetworkingMeetingsDisabled,
  organization,
}) => {
  const { t } = useContext(LocalizationContext);
  const [printMode, setPrintMode] = useState(false);

  const eventToggle = !showEngagementScore && showEventPhaseToggle && (
    <EventPhaseToggleComponent
      currentPhase={currentPhase}
      setCurrentPhase={setCurrentPhase}
    />
  );

  const header = (
    <Header>
      {t(
        `connections-dashboard.notice-text.${
          currentPhase === 'Ended' ? 'ended' : 'live'
        }`,
      )}
    </Header>
  );

  const headerWithExport = !showEngagementScore && (
    <PrintHeaderComponent
      titleComponent={printMode ? header : ''}
      triggerExport={() => setPrintMode(true)}
      visible={!printMode && currentPhase === 'Ended'}
    />
  );

  if (['Draft', 'Upcoming'].includes(currentPhase)) {
    return (
      <div className="container event-dashboard p-3">
        {eventToggle}
        <ConnectionsDashboardEmptyComponent />
      </div>
    );
  }

  // do not cause the PrintableContainer to rerender,
  // it will break printing!
  return (
    <DashboardContainer className="container event-dashboard p-3">
      <TippyTooltipStyleOverride />

      {eventToggle}
      {headerWithExport}

      {!showEngagementScore && (
        <NoticeItem
          pageType="Connections"
          eventPhase={currentPhase}
          showFeedbackLink
        />
      )}

      <PrintableContainer
        printMode={printMode}
        exitPrintMode={() => setPrintMode(false)}
        pageTitle={t('connections-dashboard.print-mode-title')}
      >
        {printMode && header}

        <div key="charts-container" data-event-id={event.id}>
          {showEngagementScore ? (
            <EngagementKeyMetrics
              eventId={event.id}
              externalId={event.externalId}
            />
          ) : (
            <KeyMetrics eventId={event.id} />
          )}

          <ConnectionsOverTime
            eventId={event.id}
            startTime={event.startTime}
            endTime={event.endTime}
            expanded={printMode}
          />
          {showEngagementScore && (
            <MostEngagedAttendees eventId={event.id} isDisabled={false} />
          )}

          <div className="row mb-3 no-page-break-inside">
            <div className={organization.advancedPlan ? 'col-4' : 'col-6'}>
              <ConnectionsMadeChartContainer eventId={event.id} />
            </div>
            {organization.advancedPlan && (
              <div className="col-4">
                <TopSponsorsByNetworkingMeetings
                  eventId={event.id}
                  theme="concise"
                />
              </div>
            )}
            <div className={organization.advancedPlan ? 'col-4' : 'col-6'}>
              <TopAttendeesByNetworkingMeetings
                eventId={event.id}
                theme="concise"
                isDisabled={topAttendeesByNetworkingMeetingsDisabled}
              />
            </div>
          </div>

          <div className="row mb-3 no-page-break-inside">
            <div className="col-6">
              <TopAreasByChatMessages eventId={event.id} />
            </div>
            <div className="col-6">
              <LatestConnectionsContainer eventSlug={event.slug} />
            </div>
          </div>
        </div>
      </PrintableContainer>
    </DashboardContainer>
  );
};

ConnectionsDashboardComponent.propTypes = {
  event: shape({
    id: number.isRequired,
    slug: string.isRequired,
    startTime: string.isRequired,
    endTime: string.isRequired,
    externalId: string.isRequired,
  }).isRequired,
  currentPhase: string.isRequired,
  showEngagementScore: bool.isRequired,
  setCurrentPhase: func.isRequired,
  showEventPhaseToggle: bool.isRequired,
  topAttendeesByNetworkingMeetingsDisabled: bool,
  organization: object.isRequired,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
)(ConnectionsDashboardComponent);
