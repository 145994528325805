import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import LeadingTextInput from '@features/marketing-settings/static-text-input';
import { Box } from '@hopin-team/ui-box';
import { Grid } from '@hopin-team/ui-grid';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { func, number, shape, string } from 'prop-types';
import React from 'react';
const EventAccess = ({
  closeDoorsLimitMinutes,
  currentDoorsCloseTime,
  currentDoorsOpenTime,
  errors,
  openDoorsLimitMinutes,
  register,
}) => {
  const { t } = useLocalization('venue-controls.event-access');
  return (
    <Box mb={3}>
      <Text
        element={'h3'}
        pattern="headingFour"
        scale={4}
        weight="medium"
        mb={0.5}
      >
        {t('title')}
      </Text>
      <Text element="p" pattern="body" color="grey-600" mb={1.5}>
        {t('heading')}
      </Text>
      <Grid gridGap={3} gridTemplateColumns="repeat(2, 1fr)">
        <TextField
          errorMessage={errors?.doors_open_before_minutes?.message}
          forwardedAs={LeadingTextInput}
          hasErrors={Boolean(errors?.doors_open_before_minutes)}
          helperText={
            openDoorsLimitMinutes
              ? t('max-minutes-helper', { maxMinutes: openDoorsLimitMinutes })
              : ''
          }
          trailingText={t('early-access-trailing-text', {
            minuteSuffix: currentDoorsOpenTime == 1 ? '' : 's',
          })}
          label={t('early-access-label')}
          name="venue_settings[doors_open_before_minutes]"
          passedHasErrors={Boolean(errors?.doors_open_before_minutes)}
          type="number"
          min="0"
          max={openDoorsLimitMinutes}
          ref={register}
        />
        <TextField
          errorMessage={errors?.doors_close_after_minutes?.message}
          forwardedAs={LeadingTextInput}
          hasErrors={Boolean(errors?.doors_close_after_minutes)}
          helperText={
            closeDoorsLimitMinutes
              ? t('max-minutes-helper', { maxMinutes: closeDoorsLimitMinutes })
              : ''
          }
          trailingText={t('doors-close-trailing-text', {
            minuteSuffix: currentDoorsCloseTime == 1 ? '' : 's',
          })}
          label={t('doors-close-label')}
          name="venue_settings[doors_close_after_minutes]"
          passedHasErrors={Boolean(errors?.doors_close_after_minutes)}
          type="number"
          min="0"
          max={closeDoorsLimitMinutes}
          ref={register}
        />
      </Grid>
    </Box>
  );
};

EventAccess.propTypes = {
  closeDoorsLimitMinutes: number,
  currentDoorsCloseTime: string,
  currentDoorsOpenTime: string,
  errors: shape({
    doors_close_after_minutes: shape({
      message: string.isRequired,
    }),
    doors_open_before_minutes: shape({
      message: string.isRequired,
    }),
  }),
  openDoorsLimitMinutes: number,
  register: func.isRequired,
};

export default withLocalizationProvider(EventAccess);
