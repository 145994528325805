import { useLocalization } from '@features/localization';
import { Butticon } from '@hopin-team/ui-butticon';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import { TranscriptSlice } from './transcript-slice/transcript-slice';
import { TranscriptContainer } from './transcript.styled';

export const Transcript = ({ onClose }) => {
  const { t } = useLocalization('recording-library.transcript');

  return (
    <TranscriptContainer>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mt={1.5}
      >
        <Text element="h3" pattern="headingFour" data-testid="transcript-title">
          {t('transcript')}
        </Text>
        <Butticon
          title={t('close-transcript')}
          icon="close"
          onClick={onClose}
          size="tiny"
        />
      </Flex>
      <TranscriptSlice />
    </TranscriptContainer>
  );
};

Transcript.propTypes = {
  onClose: PropTypes.func,
};
