import { useLocalization } from '@features/localization';
import * as Routes from '@routes';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import { STEPS } from '../../constants/steps';
import ChecklistItemsList from '../../styled/checklist-items-list';
import { isStepComplete } from '../../utils/steps';
import ChecklistItem from '../checklist-item';
import Publish from '../publish';

const PreviewAndPublishTabPanel = ({
  checklistStatuses,
  eventSlug,
  isPublishing,
  onPreview,
  onPublish,
  canPublish,
}) => {
  const { t } = useLocalization(
    'overview.event-setup-checklist.tab-panels.preview-and-publish',
  );

  return (
    <ChecklistItemsList>
      <ChecklistItem
        data-testid="event-progress-preview-link"
        description={t('preview.description')}
        iconName="browser-content"
        isChecked={isStepComplete(STEPS.PREVIEWED, checklistStatuses)}
        link={Routes.eventPath(eventSlug)}
        onClick={onPreview}
        openInNewTab
        text={t('preview.heading')}
      />

      <ChecklistItem
        description={t('publish.description')}
        iconName="publish"
        isChecked={isStepComplete(STEPS.PUBLISHED, checklistStatuses)}
        text={t('publish.heading')}
      >
        {!isStepComplete(STEPS.PUBLISHED, checklistStatuses) && (
          <Publish
            data-testid="event-progress-publish-button"
            onPublish={onPublish}
            canPublish={canPublish}
            isPublishing={isPublishing}
          />
        )}
      </ChecklistItem>
    </ChecklistItemsList>
  );
};

PreviewAndPublishTabPanel.propTypes = {
  canPublish: bool,
  checklistStatuses: object.isRequired,
  eventSlug: string.isRequired,
  isPublishing: bool,
  onPreview: func.isRequired,
  onPublish: func.isRequired,
};

export default PreviewAndPublishTabPanel;
