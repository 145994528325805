import { LAYOUT } from '@hopin-team/ui-symbols';
import { node } from 'prop-types';
import React from 'react';

import { CenteredContainer } from '../styles';

const AnnouncementsContainer = ({ children }) => (
  <CenteredContainer
    container={LAYOUT.BOUNDARY}
    containerPadding={10}
    data-testid="announcements-container"
  >
    {children}
  </CenteredContainer>
);

AnnouncementsContainer.propTypes = {
  children: node.isRequired,
};

export default AnnouncementsContainer;
