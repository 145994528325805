import { rem } from 'polished';
import styled, { css } from 'styled-components';

const setBorderAndBackgroundStyles = (hasError, isSuccess, dragActive) => {
  if (isSuccess) {
    return `
          border: 1px solid var(--color-green-500);
          background: var(--color-green-100);
        `;
  }
  if (hasError) {
    return `
          border: 1px solid var(--color-red-500);
          background: var(--color-red-100);
        `;
  }

  return `
        border: 1px dashed var(--color-gray-400);
        background: ${
          dragActive ? 'var(--color-gray-300)' : 'var(--color-gray-200)'
        };
      `;
};

const FileContainerZone = styled.button`
  ${({ dragActive, hasError, isSuccess }) => {
    return css`
      display: block;
      width: 100%;
      min-height: 6rem;
      padding: 1.5rem;
      border-radius: var(--border-radius-small);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      transition: background var(--transition-duration) ease-out;

      ${setBorderAndBackgroundStyles(hasError, isSuccess, dragActive)}
    `;
  }}
`;

export const Filename = styled.span`
  text-decoration: underline;
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: 1.14;
  color: var(--color-gray-800);
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default FileContainerZone;
