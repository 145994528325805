import DatePickerComponents from '@features/event-creation-wizard/components/date-pickers/date-picker-components';
import EventType from '@features/event-creation-wizard/components/event-type';
import StepHeader from '@features/event-creation-wizard/components/step-header';
import { useEventCreationWizard } from '@features/event-creation-wizard/event-creation-wizard-provider';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import useEventSetupForm from '@features/event-creation-wizard/steps/onsite-event-creation/hooks/use-onsite-event-form-hook';
import AddressPicker from '@features/events/address-picker';
import { LocalizationContext } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { TextField } from '@hopin-team/ui-text-field';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

const OnsiteEventCreationForm = () => {
  const {
    watch,
    errors,
    trigger,
    setValue,
    register,
    formState,
    onClickNext,
    reset,
    getValues,
  } = useEventSetupForm();

  const { t, dateLocale } = useContext(LocalizationContext);
  const { googleMapsApiKey } = useEventCreationWizard();
  const { address: currentLocationAddress } = useSelector(
    state => state.eventLocation,
  );
  const currentEventType = useSelector(state => state.eventType);

  const eventType = watch('eventType', currentEventType);
  const eventLocationAddress = watch(
    'eventLocationAddress',
    typeof currentLocationAddress === 'string'
      ? JSON.parse(currentLocationAddress)
      : currentLocationAddress,
  );

  const onNameChange = name => setValue('eventLocationName', name);
  const onAddressChange = address =>
    setValue('eventLocationAddress', JSON.stringify(address)); // This version of react-form-hook does not support object values

  const childProps = {
    t,
    watch,
    errors,
    setValue,
    register,
    trigger,
    dateLocale,
  };

  return (
    <EventCreationWizardStep
      canSkip={false}
      onSave={onClickNext}
      breadcrumbsDisabled={!formState.isValid}
      continueDisabled={!formState.isValid}
    >
      <Flex flexDirection="column">
        <StepHeader
          title={t('event-creation-wizard.steps.onsite.title')}
          subtitle={t('event-creation-wizard.steps.onsite.subtitle')}
        />
        <form onSubmit={onClickNext} data-testid="create-event-form">
          <TextField
            mb={3}
            name="name"
            ref={register}
            data-testid="event-name"
            hasErrors={Boolean(errors.name)}
            errorMessage={errors?.name?.message}
            label={t(
              'event-creation-wizard.steps.onsite.form.event-name.label',
            )}
            placeholder={t(
              'event-creation-wizard.steps.onsite.form.event-name.placeholder',
            )}
            isRequired
          />
          <DatePickerComponents
            {...childProps}
            setValue={(...rest) => {
              setValue(...rest);
            }}
          />
          <TextField
            mb={3.5}
            name="eventLocationName"
            ref={register}
            data-testid="venue-name"
            hasErrors={Boolean(errors?.name)}
            errorMessage={errors?.name?.message}
            onChange={e => onNameChange(e.target.value)}
            label={t(
              'event-creation-wizard.steps.onsite.form.event-location-name.label',
            )}
            placeholder={t(
              'event-creation-wizard.steps.onsite.form.event-location-name.placeholder',
            )}
          />
          <AddressPicker
            address={
              eventLocationAddress && typeof eventLocationAddress === 'string'
                ? JSON.parse(eventLocationAddress)
                : eventLocationAddress
            }
            onAddressChange={onAddressChange}
            googleMapsApiKey={googleMapsApiKey}
            label={t(
              'vent-creation-wizard.steps.onsite.form.event-location-address.label',
            )}
            placeholder={t(
              'event-creation-wizard.steps.onsite.form.event-location-address.placeholder',
            )}
          />
          <input hidden name="eventLocationAddress" ref={register} />
          <input hidden name="eventLocationType" ref={register} />
          <EventType
            eventType={eventType}
            onChange={type => {
              setValue('eventType', type);
              reset({
                ...getValues(),
                eventPassword: '',
              });
            }}
            register={register}
            isOnFreePlan={false}
            errors={errors}
          />
        </form>
      </Flex>
    </EventCreationWizardStep>
  );
};

export default OnsiteEventCreationForm;
