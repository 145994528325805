import { useLocalization } from '@features/localization';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { bool, func, node, number, oneOf, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  FileUploadArea,
  PlaceholderImage,
  RelativeDiv,
  RemoveImageButton,
} from './image-dropzone.styles';

const getFriendlyNameForSize = fileSize => {
  const KB = 1024;
  const MB = 1024 * 1024;

  if (fileSize >= MB) {
    return `${Math.round(fileSize / MB)}MB`;
  }

  if (fileSize >= KB) {
    return `${Math.round(fileSize / KB)}KB`;
  }

  return `${fileSize}Bytes`;
};

const DefaultEmptyState = ({ maxFileSize, t }) => (
  <>
    <Icon name="image" color="grey-800" scale={3} mb={1} />
    <Text color="grey-800" element="p" m={0} pattern="labelTwo">
      <Text color="blue-400" pattern="labelTwo" weight="bold">
        {t('common.image-upload.placeholder-bold')}
      </Text>{' '}
      {t('common.image-upload.placeholder-text')}
    </Text>
    {!!maxFileSize && (
      <Text color="grey-600" element="p" mb={0} mt={1.25} pattern="labelTwo">
        {t('common.image-upload.file-limit', {
          size: getFriendlyNameForSize(maxFileSize),
        })}
      </Text>
    )}
  </>
);

DefaultEmptyState.propTypes = {
  maxFileSize: number,
  t: func.isRequired,
};

const ImageDropzone = ({
  accept,
  children,
  hasError,
  height,
  aspectRatio,
  maxFileSize,
  name,
  onDrop,
  onDropError,
  onRemove,
  imagePreviewFit = 'contain',
  placeholderImage,
  inputId,
  ...dropzoneProps
}) => {
  const { t } = useLocalization();
  const [file, setFile] = useState();
  const [imagePreviewObjectURL, setImagePreviewObjectURL] = useState();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    inputRef,
    isDragReject,
  } = useDropzone({
    // Allow user to filter down on filetype more
    accept: accept || 'image/*',
    maxSize: maxFileSize || Infinity,
    multiple: false,
    // Only respond to accepted file sizes and types
    onDropAccepted: ([firstFile]) => {
      if (typeof DataTransfer !== 'undefined') {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(firstFile);
        inputRef.current.files = dataTransfer.files;
      }
      onDrop?.(firstFile);
      setFile(firstFile);
    },
    onDropRejected: fileRejections => {
      /** @NOTE Input files not cleared on rejection by default */
      inputRef.current.value = '';
      onDropError?.(fileRejections);
    },
    ...dropzoneProps,
  });

  useEffect(() => {
    if (file) {
      setImagePreviewObjectURL(URL.createObjectURL(file));
    } else {
      // Clear if no file is selected.
      setImagePreviewObjectURL();
    }
    // We need to manually revoke the Object URL to prevent a memory leak
    return () => {
      if (file) URL.revokeObjectURL(file);
    };
  }, [file]);

  let display = null;
  if (isDragReject) {
    /** @NOTE Shown if user is dragging an unsupported file */
    display = (
      <Text color="grey-600" element="p" pattern="labelThree">
        {t('common.image-upload.file-reject')}
      </Text>
    );
  } else if (imagePreviewObjectURL) {
    /** @NOTE User has selected an image file */
    display = (
      <PlaceholderImage
        src={imagePreviewObjectURL}
        alt={t('common.preview')}
        style={{ objectFit: imagePreviewFit }}
      ></PlaceholderImage>
    );
  } else if (placeholderImage) {
    /**
     @NOTE If placeholder image is provided then it will take precedence over
     other placeholder views. It will be shown when no image file is selected
     */
    display = (
      <PlaceholderImage
        src={placeholderImage}
        alt={t('common.preview')}
        style={{ objectFit: imagePreviewFit }}
      ></PlaceholderImage>
    );
  } else if (children) {
    /** @NOTE Custom placeholder content */
    display = children;
  } else {
    display = <DefaultEmptyState maxFileSize={maxFileSize} t={t} />;
  }

  return (
    <RelativeDiv>
      <input data-testid="file-input" {...getInputProps()} name={name} />

      {!!file && (
        <RemoveImageButton
          data-testid="remove-image"
          title={t('common.remove')}
          type="button"
          onClick={() => {
            onRemove?.();
            setFile();
            /** @NOTE Clear files in input */
            inputRef.current.value = '';
          }}
        >
          <Icon name="close-circle" scale={3} color="grey-900" />
        </RemoveImageButton>
      )}

      <FileUploadArea
        type="button"
        hasError={hasError || isDragReject}
        hasImage={Boolean(imagePreviewObjectURL || placeholderImage)}
        height={height}
        aspectRatio={aspectRatio}
        dragActive={isDragActive}
        id={inputId}
        {...getRootProps()}
      >
        {display}
      </FileUploadArea>
    </RelativeDiv>
  );
};

ImageDropzone.propTypes = {
  accept: string,
  children: node,
  hasError: bool,
  height: string,
  aspectRatio: number,
  maxFileSize: number,
  name: string,
  onDrop: func,
  onDropError: func,
  onRemove: func,
  imagePreviewFit: oneOf(['contain', 'cover']),
  placeholderImage: string,
  inputId: string,
};

export default ImageDropzone;
