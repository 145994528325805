const getSimpleEventWizardTemplates = ({ reorderTabs, withSponsors }) => {
  // Tabs
  const setup = {
    basics: {},
    theme: {},
    registration: {},
  };

  let registration = {
    'registrations-tickets': {},
    'ticket-groups': {},
    'registration-form': {},
    'registration-event-landing-page': {},
    details: {},
  };

  let venue = {
    reception: {},
    schedule: {},
    stage: {},
    stages: {},
    session: {},
    sessions: {},
    'venue-controls-people': {},
    networking: {},
    partners: {},
    tags: {},
    surveys: {},
    'venue-controls': {},
    'app-area': {},
    ...(withSponsors ? { sponsors: {} } : {}),
  };

  let people = {
    registrants: {},
    speakers: {},
    'magic-link-invites': {},
  };

  const marketing = {
    emails: {},
    'email-attendees': {},
    'customize-emails': {},
  };

  const analytics = {
    engagement: {},
    reports: {},
    registrations: {},
    connections: {},
    'view-polls': {},
    'expo-summary': {},
  };

  // reorder
  if (reorderTabs) {
    registration = {
      'registration-event-landing-page': {},
      'registration-form': {},
      'registrations-tickets': {},
      'ticket-groups': {},
      registrants: {},
      'magic-link-invites': {},
    };

    people = {};

    venue = {
      ...venue,
      speakers: {},
    };
  }

  // Templates
  const simpleEventConfig_during_event = {
    overview: {},
    setup,
    registration,
    venue,
    people,
    marketing,
    analytics,
  };

  const simpleEventConfig_after_event = {
    overview: {},
    analytics,
    people,
    more: {
      setup,
      registration,
      venue,
      marketing,
    },
  };

  return {
    before: simpleEventConfig_during_event,
    during: simpleEventConfig_during_event,
    after: simpleEventConfig_after_event,
  };
};

export const getSimpleEventWizardTemplatesWithSponsors = ({ reorderTabs }) => {
  return getSimpleEventWizardTemplates({
    reorderTabs,
    withSponsors: true,
  });
};

export const getSimpleEventWizardTemplatesWithNoSponsors = ({
  reorderTabs,
}) => {
  return getSimpleEventWizardTemplates({
    reorderTabs,
    withSponsors: false,
  });
};
