/* global Rails */
import * as Routes from '@routes';
import isClientside from '@util/is-clientside';
import ky from 'ky-universal';

import { makeScopedRequest } from './scoped';

let monolithClient = {};

if (isClientside()) {
  monolithClient = ky.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
  });
}

const makeEventAnalyticsRequest = (eventExternalId, requestFunction) =>
  makeScopedRequest(
    'event_analytics',
    { event_external_id: eventExternalId },
    requestFunction,
  );

const analyticsApi = {
  getAnalyticsRegistrationsOverTime(eventExternalId, granularity, signal) {
    const data = {
      event_external_id: eventExternalId,
      granularity: granularity,
    };
    return makeEventAnalyticsRequest(eventExternalId, client =>
      client.post('external/registrations_over_time', {
        json: data,
        signal,
      }),
    );
  },
  getAnalyticsRegistrationsByCountry(eventExternalId, signal) {
    const data = {
      event_external_id: eventExternalId,
    };
    return makeEventAnalyticsRequest(eventExternalId, client =>
      client.post('external/countries_by_registrations', {
        json: data,
        signal,
      }),
    ).then(response => response.json());
  },
  getAnalyticsKeyMetrics(eventExternalId, signal) {
    const data = {
      event_external_id: eventExternalId,
    };
    return makeEventAnalyticsRequest(eventExternalId, client =>
      client.post('external/event_key_metrics', {
        json: data,
        signal,
      }),
    ).then(response => response.json());
  },
  getAnalyticsTicketSalesOverTime(eventExternalId, granularity, signal) {
    const data = {
      event_external_id: eventExternalId,
      granularity: granularity,
    };
    return makeEventAnalyticsRequest(eventExternalId, client =>
      client.post('external/ticket_sales_over_time', {
        json: data,
        signal,
      }),
    );
  },
  getAttendeesByTicketType(eventExternalId, signal) {
    const data = {
      event_external_id: eventExternalId,
    };
    return makeEventAnalyticsRequest(eventExternalId, client =>
      client.post('external/attendees_by_ticket_type', {
        json: data,
        signal,
      }),
    ).then(response => response.json());
  },
  getEventDetails(eventSlug, signal) {
    return monolithClient
      .get(Routes.organisersEventEventDetailsPath(eventSlug), {
        signal,
      })
      .json();
  },
  getPreEventSummaryStats(eventSlug, signal) {
    return monolithClient
      .get(Routes.organisersEventPreEventSummaryStatsPath(eventSlug), {
        signal,
      })
      .json();
  },
  getRegistrationsByCountry(eventId, signal, limit = null) {
    return monolithClient
      .get(
        Routes.organisersEventRegistrationsByCountryPath(eventId, { limit }),
        {
          signal,
        },
      )
      .json();
  },
  getRecentlyRegisteredAttendees(eventId, signal) {
    return monolithClient
      .get(Routes.organisersEventRecentlyRegisteredAttendeesPath(eventId), {
        signal,
      })
      .json();
  },
  getMostActiveAttendeesByMinutesSpent(eventId, signal, limit) {
    return monolithClient
      .get(
        Routes.organisersEventMostActiveAttendeesByMinutesSpentPath(eventId, {
          limit,
        }),
        {
          signal,
        },
      )
      .json();
  },
  getRegistrationsOverTime(
    eventId,
    signal,
    { startTime, endTime, limit, chartType, granularity },
  ) {
    return monolithClient
      .get(
        Routes.organisersEventRegistrationsOverTimePath(eventId, {
          start_time: startTime,
          end_time: endTime,
          limit: limit,
          chart_type: chartType,
          granularity,
        }),
        {
          signal,
        },
      )
      .json();
  },
  getTicketSalesOverTime(eventId, signal, { chartType, granularity }) {
    return monolithClient
      .get(
        Routes.organisersEventTicketSalesOverTimePath(eventId, {
          chart_type: chartType,
          granularity,
        }),
        {
          signal,
        },
      )
      .json();
  },
  getRefundAmountsOverTime(eventId, signal, { chartType, granularity }) {
    return monolithClient
      .get(
        Routes.organisersEventRefundAmountOverTimePath(eventId, {
          chart_type: chartType,
          granularity,
        }),
        {
          signal,
        },
      )
      .json();
  },
  getLiveAnalyticsChartData(
    eventId,
    { selectedArea, startTime, endTime, defaultEndTime, chartType, limit },
    signal,
  ) {
    return monolithClient
      .get(
        Routes.organisersEventAnalyticsAttendeesLivePath(eventId, {
          area: selectedArea ? selectedArea.value : null,
          start_time: startTime,
          end_time: endTime,
          default_end_time: defaultEndTime,
          chart_type: chartType,
          limit,
        }),
        {
          signal,
        },
      )
      .json();
  },
  getTicketSalesByType(
    eventId,
    signal,
    limit,
    sortBy,
    withRegistrations = true,
  ) {
    return monolithClient
      .get(
        Routes.organisersEventTicketSalesByTypePath(eventId, {
          limit,
          sortBy,
          withRegistrations,
        }),
        {
          signal,
        },
      )
      .json();
  },
  getAvailableReports(eventId, signal) {
    return monolithClient
      .get(Routes.availableReportsOrganisersEventReportsPath(eventId), {
        signal,
      })
      .json();
  },
  createReport(eventId, data, signal) {
    return monolithClient
      .post(Routes.createReportOrganisersEventReportsPath(eventId), {
        signal,
        json: data,
      })
      .json();
  },
  regenerateReport(eventId, reportId, data, signal) {
    return monolithClient
      .post(Routes.organisersEventReportRegeneratePath(eventId, reportId), {
        signal,
        json: data,
      })
      .json();
  },
  getReportHistory(eventId, signal) {
    return monolithClient
      .get(Routes.reportHistoryOrganisersEventReportsPath(eventId), {
        signal,
      })
      .json();
  },
  getAverageMinutes(eventId, signal) {
    return monolithClient
      .get(Routes.organisersEventAverageMinutesPath(eventId), {
        signal,
      })
      .json();
  },
  getAverageAttendeeRating(eventId, signal) {
    return monolithClient
      .get(Routes.organisersEventAverageAttendeeRatingPath(eventId), {
        signal,
      })
      .json();
  },
  getTopAreasByAttendees(eventId, signal, limit) {
    return monolithClient
      .get(Routes.organisersEventTopAreasByAttendeesPath(eventId, { limit }), {
        signal,
      })
      .json();
  },
  getTopAreasByChatMessages(eventId, signal, limit) {
    return monolithClient
      .get(
        Routes.organisersEventTopAreasByChatMessagesPath(eventId, { limit }),
        {
          signal,
        },
      )
      .json();
  },
  getChatMessagesBySegment(eventId, signal) {
    return monolithClient
      .get(Routes.organisersEventChatMessagesBySegmentPath(eventId), {
        signal,
      })
      .json();
  },
  getTopExpoBoothsByInteraction(eventId, signal) {
    return monolithClient
      .get(Routes.organisersEventTopExpoBoothsByInteractionPath(eventId), {
        signal,
      })
      .json();
  },
  getTopAttendeesByExpoBoothInteraction(eventId, signal) {
    return monolithClient
      .get(
        Routes.organisersEventTopAttendeesByExpoBoothInteractionPath(eventId),
        {
          signal,
        },
      )
      .json();
  },
  getTurnout(eventId, signal) {
    return monolithClient
      .get(Routes.organisersEventTurnoutPath(eventId), {
        signal,
      })
      .json();
  },
  getExpoBoothKeyMetrics(eventId, signal) {
    return monolithClient
      .get(Routes.organisersEventExpoBoothKeyMetricsPath(eventId), {
        signal,
      })
      .json();
  },
  getOrganisersEventReportsStatus(eventId, signal) {
    return monolithClient
      .get(Routes.statusOrganisersEventReportsPath(eventId), {
        signal,
      })
      .json();
  },
};

export default analyticsApi;
