import hopinApi from '@api/hopin';
import { AuthenticationContext } from '@hopin-team/provider-authentication';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getAttendeeDetail, getCustomForms } from '../utils/services';

export const useRegistrationDetails = (eventSlug, attendeeId) => {
  const { authenticationToken } = useContext(AuthenticationContext);
  const canLoad = Boolean(authenticationToken && eventSlug && attendeeId);
  const [attendee, setAttendee] = useState(undefined);
  const [availableTickets, setAvailableTickets] = useState();

  const [customForm, setCustomForm] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(canLoad);

  const [invalidationTimestamp, setInvalidationTimestamp] = useState(
    Date.now(),
  );
  const invalidate = useCallback(() => setInvalidationTimestamp(Date.now()), [
    setInvalidationTimestamp,
  ]);

  useEffect(() => {
    async function fetchAttendeeDetail() {
      if (!canLoad) {
        return;
      }

      setError(undefined);
      setIsLoading(true);

      try {
        const [attendee, customForm] = await Promise.all([
          getAttendeeDetail(eventSlug, attendeeId),
          getCustomForms(authenticationToken, eventSlug),
        ]);

        let availableTicketsResponse;
        let cursor;
        let availableTickets = [];

        do {
          availableTicketsResponse = await hopinApi.getAvailableTicketsInEvent(
            eventSlug,
            cursor,
          );
          availableTickets = availableTickets.concat(
            availableTicketsResponse?.data?.eventBySlug?.tickets?.edges?.map(
              ({ node }) => node,
            ),
          );
          cursor =
            availableTicketsResponse?.data?.eventBySlug?.tickets?.pageInfo
              ?.endCursor;
        } while (
          availableTicketsResponse?.data?.eventBySlug?.tickets?.pageInfo
            ?.hasNextPage
        );

        setAvailableTickets(availableTickets);
        setAttendee(attendee);
        setCustomForm(customForm?.[0]);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }

    fetchAttendeeDetail();
  }, [
    attendeeId,
    authenticationToken,
    canLoad,
    eventSlug,
    invalidationTimestamp,
  ]);

  return {
    attendee,
    availableTickets,
    customForm,
    error,
    invalidate,
    isLoading,
  };
};
