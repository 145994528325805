import ArrowRight from 'icons/arrow-right.svg';
import React from 'react';
import styled from 'styled-components';

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  color: var(--color-gray-600);

  &:focus:not(.focus-visible) {
    outline: none;
  }
`;

const Arrow = styled(ArrowRight)`
  transform: rotate(180deg);
  width: 12px;
  height: 12px;
  display: block;
`;

const ArrowButton = props => (
  <Button {...props}>
    <Arrow />
  </Button>
);

export default ArrowButton;
