import { LOGGER_NAMES, OWNERSHIP_TAGS } from './constants';

const ANALYTICS_LOGGER_NAMES = new Set([LOGGER_NAMES.ANALYTICS]);

const CONNECTIONS_LOGGER_NAMES = new Set([LOGGER_NAMES.NETWORKING]);

const PARTICIPANTS_EXPERIENCE_LOGGER_NAMES = new Set([
  LOGGER_NAMES.REGISTRATIONS,
]);

const EVENT_CREATION_LOGGER_NAMES = new Set([
  LOGGER_NAMES.EVENT_DASH_HEADER,
  LOGGER_NAMES.EVENT_DASH_NAV,
  LOGGER_NAMES.EVENT_DUPLICATION,
  LOGGER_NAMES.EVENT_TEMPLATES,
  LOGGER_NAMES.MARKETING_SETTINGS,
  LOGGER_NAMES.SPONSORS_GRID, // Temporarily assigned to Event Creation
  LOGGER_NAMES.ONBOARDING_FLOW,
  LOGGER_NAMES.REPLAY,
]);

const AUDIENCE_ENGAGEMENT_LOGGER_NAMES = new Set([
  LOGGER_NAMES.EVENT_BRANDING,
  LOGGER_NAMES.VENUE_SETTINGS,
  LOGGER_NAMES.SURVEY_BUILDER,
]);

const MONEY_LOGGER_NAMES = new Set([LOGGER_NAMES.CUSTOMER_PORTAL]);

const PARTNERS_LOGGER_NAMES = new Set([
  LOGGER_NAMES.ADD_EXPO_BOOTH,
  LOGGER_NAMES.BOOTH_PREVIEW,
]);

const SELF_SERVE_LOGGER_NAMES = new Set([LOGGER_NAMES.EVENT_CREATION_WIZARD]);

const PRODUCT_GROWTH_LOGGER_NAMES = new Set([
  LOGGER_NAMES.DEMO,
  LOGGER_NAMES.HELP_CENTER,
]);

export const getLoggerNameOwnership = loggerName => {
  if (ANALYTICS_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.ANALYTICS;
  } else if (EVENT_CREATION_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.EVENT_CREATION;
  } else if (AUDIENCE_ENGAGEMENT_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.AUDIENCE_ENGAGEMENT;
  } else if (MONEY_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.MONEY;
  } else if (PARTNERS_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.PARTNERS;
  } else if (CONNECTIONS_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.CONNECTIONS;
  } else if (SELF_SERVE_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.SELF_SERVE;
  } else if (PARTICIPANTS_EXPERIENCE_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.PARTICIPANTS_EXPERIENCE;
  } else if (PRODUCT_GROWTH_LOGGER_NAMES.has(loggerName)) {
    return OWNERSHIP_TAGS.PRODUCT_GROWTH;
  }

  return 'unassigned';
};
