import P from 'prop-types';
import React from 'react';

import BaseInput from './base-input';

const PostConfigs = ({
  className,
  configs: configsProp,
  values,
  onValueUpdate,
}) => {
  const configs = configsProp.filter(
    config => !['enabled', 'tab_name'].includes(config.field),
  );

  if (configs.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      {configs.map(config => (
        <BaseInput
          key={config.field}
          config={config}
          value={values[config.field] || ''}
          onValueUpdate={val => onValueUpdate(config.field, val)}
          size="tiny"
        />
      ))}
    </div>
  );
};

PostConfigs.propTypes = {
  className: P.string,
  configs: P.arrayOf(
    P.shape({
      field: P.string.isRequired,
      label: P.string.isRequired,
    }),
  ).isRequired,
  values: P.objectOf(P.string).isRequired,
  onValueUpdate: P.func.isRequired,
};

export default PostConfigs;
