import { Avatar } from '@hopin-team/ui-avatar';
import { Grid } from '@hopin-team/ui-grid';
import { Text } from '@hopin-team/ui-text';
import { array } from 'prop-types';
import React, { useState } from 'react';

import { Styles } from './schedule-styles';

const NR_OF_VISIBLE_SPEAKERS = 4;

const ScheduleSpeaker = ({ speakers }) => {
  const [seeAllSpeakers, setSeeAllSpeakers] = useState(false);
  const speakersList = seeAllSpeakers
    ? speakers
    : speakers.slice(0, NR_OF_VISIBLE_SPEAKERS);
  const title = seeAllSpeakers ? 'Hide speakers' : 'See all speakers';
  const showAllSpeakersButton = speakers.length > NR_OF_VISIBLE_SPEAKERS;

  const handleToggleAllSpeakers = () => {
    setSeeAllSpeakers(!seeAllSpeakers);
  };

  return (
    <>
      <Styles.ScheduleSpeakersGrid>
        {speakersList.map(({ name, headline, picture_url }, idx) => (
          <Grid gridTemplateColumns="auto 1fr" gridColumnGap="16px" key={idx}>
            <Avatar alt="tiny" size="small" src={picture_url} />
            <div>
              <Text pattern="labelFour" element="p">
                {name}
              </Text>
              <Text pattern="body" element="p" scale={2}>
                {headline}
              </Text>
            </div>
          </Grid>
        ))}
      </Styles.ScheduleSpeakersGrid>
      {showAllSpeakersButton && (
        <Styles.SeeAllSpeakers
          pattern="headingFive"
          mt={3}
          element="p"
          onClick={handleToggleAllSpeakers}
        >
          {title}
        </Styles.SeeAllSpeakers>
      )}
    </>
  );
};

ScheduleSpeaker.propTypes = {
  speakers: array,
};

export default ScheduleSpeaker;
