import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import DoorsOpen from './assets/pre-event-banner.png';

const DoorsOpenModal = ({
  modalOpen,
  setModalOpen,
  eventId,
  showEmailLink,
  supportUrl,
}) => {
  const { t } = useLocalization();
  const handleClick = () => {
    window.open(supportUrl);
  };

  return (
    <Modal
      describedById="doors-open-modal"
      isShowing={modalOpen}
      onClose={() => setModalOpen(false)}
      isDismissible
      label="doors-open"
      withCloseButton
    >
      <Header>
        <Text pattern="title" element="h1">
          {t('basic-settings.pre-event.modal.during')}
          <br />
          {t('basic-settings.pre-event.modal.early-access')}
        </Text>
      </Header>
      <Content>
        <List>
          <ListItem>
            <Text>
              {t('basic-settings.pre-event.modal.features.event-parts')}
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              {t('basic-settings.pre-event.modal.features.polls-chat-qa')}
              <br />
            </Text>
            <a href={Routes.organisersEventVenueControlsPath(eventId)}>
              {t('basic-settings.pre-event.modal.features.polls-link')}
            </a>
          </ListItem>
          <ListItem>
            <Text>
              {t('basic-settings.pre-event.modal.features.email')}
              <br />
            </Text>
            {showEmailLink && (
              <a href={Routes.organisersEventCommunicationsPath(eventId)}>
                {t('basic-settings.pre-event.modal.features.email-link')}
              </a>
            )}
          </ListItem>
        </List>
        <Button
          isOutlined
          onClick={handleClick}
          pattern="primary"
          size="medium"
        >
          {t('basic-settings.pre-event.modal.features.cta')}
        </Button>
      </Content>
    </Modal>
  );
};

export default withLocalizationProvider(DoorsOpenModal);

DoorsOpenModal.propTypes = {
  setModalOpen: func,
  modalOpen: bool,
  showEmailLink: bool,
  eventId: number,
  supportUrl: string,
};

const Header = styled.div`
  padding: 1rem;
  background-color: var(--color-blue-100);
  padding: 2.375rem 2.125rem 2rem 2.125rem;
  background-image: url(${DoorsOpen});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`;

const Content = styled.div`
  margin: 2rem 3rem 2rem;
`;

const List = styled.ul`
  margin-bottom: 3.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;
