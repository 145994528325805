import styled from 'styled-components';

export const UpgradeLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 90px;
  padding: var(--spacing-8);
  background-color: #fffaf8;
  color: #f18549;
`;
