import hopinApi from '@api/hopin';
import { LITE_EVENT_STEPS } from '@components/event-progress/constants/steps';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import useInterval from '@util/hooks/useInterval';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import compose from 'lodash/fp/compose';
import { bool, number, shape, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateStepStatus } from '@/redux/reducers/setup-checklist';
import withReduxProvider from '@/redux/with-provider';

import AnnouncementsListWrapper from './announcements-list-wrapper';

// 30 seconds
const POLLING_INTERVAL = 30000;

const AnnouncementsList = ({ event, userId, emailTemplatesLimit, isLite }) => {
  const { t } = useLocalization('email-dashboard');
  const dispatch = useDispatch();
  const { addAlert } = useContext(AlertsContext);
  const [token, setToken] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL);
  const [hasTicketTypes, setHasTicketTypes] = useState();
  const queryParams = window.location.search;
  const hasSentParams = queryParams.includes('sent=true');
  const hasScheduledParams = queryParams.includes('scheduled=');
  const {
    id: eventId,
    slug: eventSlug,
    organiser_id: organizationId,
    timezone: eventTz,
  } = event;

  const handleCreateEmail = async () => {
    setLoading(true);
    try {
      const { id } = await hopinApi.createAnnouncement(
        token,
        organizationId,
        eventId,
      );
      window.location.assign(
        `/organisers/events/${eventSlug}/announcements/${id}/edit`,
      );
    } catch (e) {
      addAlert({
        active: true,
        text:
          e.key === 'limit_reached'
            ? t('list.errors.maximum-announcements-reached')
            : t('list.errors.default'),
        pattern: 'error',
      });
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const { token } = await hopinApi.getUserToken();
      setToken(token);

      const announcementsList = await hopinApi.getAnnouncementsList(
        organizationId,
        eventId,
        token,
      );
      setItems(announcementsList);

      const { personas } = await hopinApi.getTicketsWithCount(token, eventId);
      setHasTicketTypes(!!personas.length);
    } catch (e) {
      addAlert({
        active: true,
        text: t('list.errors.default'),
        pattern: 'error',
      });
      setPollingInterval(null);
    }
    setLoading(false);
  }, [addAlert, eventId, organizationId, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useInterval(() => {
    fetchData();
  }, pollingInterval);

  useEffect(() => {
    if (hasSentParams) {
      addAlert({
        active: true,
        text: t('send-email.success'),
        pattern: 'success',
      });
    }
  }, [hasSentParams, addAlert, t]);

  useEffect(() => {
    if (hasScheduledParams) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const scheduledDate = new Date(params.scheduled);
      const scheduledTimezone = params.tz;
      const time = utcToZonedTime(scheduledDate, scheduledTimezone);

      const date = `${format(time, 'MMMM dd')} ${t(
        'schedule-email.at',
      )} ${format(time, 'HH:mm')}`;
      addAlert({
        active: true,
        text: t('schedule-email.success', { date }),
        pattern: 'success',
      });
    }
  }, [hasScheduledParams, addAlert, t]);

  useEffect(() => {
    if (event && isLite && event.status === 'ended') {
      dispatch(
        updateStepStatus({
          eventId: event.external_id,
          step: LITE_EVENT_STEPS.EMAILS,
          status: 'completed',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Flex justifyContent="center" my={2} data-testid="spinner">
          <Spinner pattern="jumbo" isShowing={loading} />
        </Flex>
      ) : (
        <AnnouncementsListWrapper
          eventId={eventId}
          eventSlug={eventSlug}
          eventTz={eventTz}
          items={items}
          token={token}
          organizationId={organizationId}
          userId={userId}
          setItems={setItems}
          loading={loading}
          handleCreateEmail={handleCreateEmail}
          emailTemplatesLimit={emailTemplatesLimit}
          hasTicketTypes={hasTicketTypes}
        />
      )}
    </>
  );
};

AnnouncementsList.propTypes = {
  event: shape({
    attendees_email_templates_v2_enabled: bool,
    slug: string.isRequired,
    id: number.isRequired,
    organiser_id: number.isRequired,
  }),
  userId: number.isRequired,
  emailTemplatesLimit: number,
  isLite: bool,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
  withReduxProvider,
)(AnnouncementsList);

export { AnnouncementsList };
