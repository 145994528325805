import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const HidePeoplePrompt = ({
  showPeoplePrompt,
  setShowPeoplePrompt,
  onConfirm,
  source,
}) => {
  const { t } = useLocalization();
  const handleControls = () => {
    onConfirm();
    setShowPeoplePrompt(false);
  };

  return (
    <Modal
      label={t('people.people-area.hide-people-prompt.modal-label')}
      onClose={() => setShowPeoplePrompt(false)}
      role="alertdialog"
      isShowing={showPeoplePrompt}
    >
      <StyledFlex justifyContent="center" p={3}>
        <Text weight="bold">
          {t('people.people-area.hide-people-prompt.title')}
        </Text>
      </StyledFlex>
      <Box px={3}>
        <Text align="center" element="p" mb={1}>
          {t('people.people-area.hide-people-prompt.description', { source })}
        </Text>
      </Box>
      <StyledFlex justifyContent="space-evenly" px={3} pb={3}>
        <Button
          size="medium"
          pattern="primary"
          isOutlined
          mr={3}
          onClick={() => setShowPeoplePrompt(false)}
        >
          {t('people.people-area.hide-people-prompt.no')}
        </Button>
        <Button size="medium" pattern="danger" onClick={() => handleControls()}>
          {t('people.people-area.hide-people-prompt.yes')}
        </Button>
      </StyledFlex>
    </Modal>
  );
};

export default withLocalizationProvider(HidePeoplePrompt);

const StyledFlex = styled(Flex)`
  width: 100%;
`;

HidePeoplePrompt.propTypes = {
  showPeoplePrompt: PropTypes.bool.isRequired,
  setShowPeoplePrompt: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  source: PropTypes.string,
};
