import { object } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import AttendeeVisibilityToggles from './attendee-visibility-toggles';

const AttendeeVisibilityTogglesContainer = ({ control }) => {
  const eventAreas = useSelector(({ event }) => event.areas);
  const eventHasPeopleArea = eventAreas?.people;

  if (!eventHasPeopleArea) return null;

  return <AttendeeVisibilityToggles control={control} />;
};

AttendeeVisibilityTogglesContainer.propTypes = {
  control: object.isRequired,
};

export default AttendeeVisibilityTogglesContainer;
