import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const MeetingDurationFields = ({
  minDurationMm,
  minDurationSs,
  durationMm,
  durationSs,
  setMinDurationMm,
  setMinDurationSs,
  setDurationMm,
  setDurationSs,
}) => {
  const { t } = useLocalization();
  return (
    <>
      <Text pattern="body" mb={2} color="grey-800" element="p">
        {t('networking.set-meeting-time')}
      </Text>

      <Text pattern="caption" color="grey-700" mb={1}>
        {t('networking.min-meeting-length')}
      </Text>
      <Flex mb={3}>
        <Wrapper>
          <TextField
            mr={3}
            onChange={event => setMinDurationMm(Number(event.target.value))}
            label={t('networking.min')}
            defaultValue={minDurationMm}
            type="number"
            size="medium"
            min={0}
            max={1000}
            required
            placeholder="0"
            data-testid="min-meeting-minutes"
          />
        </Wrapper>
        <Wrapper>
          <TextField
            onChange={event => setMinDurationSs(Number(event.target.value))}
            label={t('networking.sec')}
            defaultValue={minDurationSs}
            type="number"
            size="medium"
            min={0}
            max={59}
            required
            placeholder="0"
            data-testid="min-meeting-seconds"
          />
        </Wrapper>
      </Flex>
      <Flex alignItems="center">
        <Text pattern="caption" color="grey-700" mr={1}>
          {t('networking.max-meeting-length')}
        </Text>
        <ToolTip
          align="bottom"
          id="tooltip"
          tip={t('networking.extend-meeting-time')}
        >
          <Icon
            title={t('networking.meeting-duration-tooltip')}
            name="info-outlined"
            color="grey-medium"
          />
        </ToolTip>
      </Flex>
      <Flex>
        <Wrapper>
          <TextField
            mr={3}
            onChange={event => setDurationMm(Number(event.target.value))}
            defaultValue={durationMm}
            label={t('networking.min')}
            type="number"
            size="medium"
            min={0}
            max={1000}
            required
            placeholder="0"
            data-testid="max-meeting-minutes"
          />
        </Wrapper>
        <Wrapper>
          <TextField
            onChange={event => setDurationSs(Number(event.target.value))}
            defaultValue={durationSs}
            label={t('networking.sec')}
            type="number"
            size="medium"
            min={0}
            max={59}
            required
            placeholder="0"
            data-testid="max-meeting-seconds"
          />
        </Wrapper>
      </Flex>
    </>
  );
};

export default MeetingDurationFields;

MeetingDurationFields.propTypes = {
  minDurationMm: PropTypes.number,
  minDurationSs: PropTypes.number,
  durationMm: PropTypes.number,
  durationSs: PropTypes.number,
  setMinDurationMm: PropTypes.func,
  setMinDurationSs: PropTypes.func,
  setDurationMm: PropTypes.func,
  setDurationSs: PropTypes.func,
};

const Wrapper = styled(Box)`
  width: 50%;
`;
