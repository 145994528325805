import MountSlice from '@components/slices/MountSlice';
import { setEnvironment } from '@hopin-team/dashboard-constants';
import { createDashboardReactiveMap } from '@hopin-team/dashboard-shared';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { number, string } from 'prop-types';
import React, { useMemo } from 'react';

const PACKAGE_NAME = '@hopin-team/slice-schedules-organiser';

if (process.env.NODE_ENV === 'development') {
  setEnvironment('local');
}

const logger = getLogger(LOGGER_NAMES.SCHEDULES_PANEL);

const SchedulesSliceComponent = ({
  organiserScheduleVersion,
  orgId,
  eventId,
  eventSlug,
}) => {
  const reactiveMap = useMemo(() => {
    const dashboardMap = createDashboardReactiveMap({
      clientPackageDetails: `${PACKAGE_NAME}/${organiserScheduleVersion}`,
      onAsyncError: error => logger.error(error),
    });
    dashboardMap.item('org_id').set(orgId);
    dashboardMap.item('event_id').set(eventId);
    dashboardMap.item('event_slug').set(eventSlug);

    return dashboardMap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MountSlice
      packageName={PACKAGE_NAME}
      version={organiserScheduleVersion}
      reactiveMap={reactiveMap}
    />
  );
};

SchedulesSliceComponent.propTypes = {
  organiserScheduleVersion: string,
  orgId: string.isRequired,
  eventId: number.isRequired,
  eventSlug: string.isRequired,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.SCHEDULES_PANEL,
    errorPattern: 'page',
  }),
)(SchedulesSliceComponent);
