import ImageDropzone from '@components/dropzone/image-dropzone';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import P from 'prop-types';
import React from 'react';

const EventBannerUpload = ({
  enforceMaxSize,
  onDrop,
  onDropError,
  onRemove,
  pictureUrl,
  helpText,
  v2HeaderEnabled,
}) => {
  const { t } = useLocalization('event-banner-upload');

  const maxFileSize = 2 * 1024 ** 2;

  const bestResultsI18Tokens = v2HeaderEnabled
    ? {
        minImageSize: '1120x230',
        aspectRatio: '7:2',
      }
    : {
        minImageSize: '1500x600',
        aspectRatio: '5:2',
      };

  return (
    <div>
      <Text element="p" color="grey-600" pattern="labelThree" mb={1}>
        {helpText ?? t('label')}
      </Text>

      <ImageDropzone
        onDrop={onDrop}
        onDropError={onDropError}
        onRemove={onRemove}
        name="event[picture]"
        height="auto"
        aspectRatio={v2HeaderEnabled ? 3.5 : 2.5}
        maxFileSize={enforceMaxSize ? maxFileSize : Infinity}
        imagePreviewFit="cover"
        placeholderImage={pictureUrl}
        inputId="event-banner-dropzone"
      >
        <Icon
          name="image"
          color="grey-800"
          scale={3}
          mb={1.5}
          aria-hidden="true"
        />

        <div>
          <Text color="blue-500" pattern="labelTwo">
            {t('choose-file')}
          </Text>{' '}
          <Text color="grey-800" pattern="labelThree">
            {t('or-drag-here')}
          </Text>
        </div>

        <Text
          color="grey-600"
          element="p"
          mb={0.5}
          mt={1.25}
          pattern="labelThree"
        >
          {t('max-upload')}
        </Text>
        <Text color="grey-600" element="p" pattern="labelThree">
          {t('for-best-results', bestResultsI18Tokens)}
        </Text>
      </ImageDropzone>
    </div>
  );
};

EventBannerUpload.propTypes = {
  enforceMaxSize: P.bool.isRequired,
  pictureUrl: P.string,
  onDrop: P.func,
  onDropError: P.func,
  onRemove: P.func,
  helpText: P.string,
  v2HeaderEnabled: P.bool,
};

export default withLocalizationProvider(EventBannerUpload);
