import { Label } from '@components/inputs';
import { useAudienceCount } from '@features/email/shared';
import { useLocalization } from '@features/localization';
import { node, number, string } from 'prop-types';
import React, { useContext } from 'react';

import { EditorContext } from './editor';
import {
  AudienceTargetingContainer,
  AudienceTargetingCount,
  AudienceTargetingLegendItemContainer,
  AudienceTargetingLegendItemIndicator,
  AudienceTargetingProgressBarLine,
  AudienceTargetingProgressBarTrack,
} from './styles';

const AudienceTargetingProgressBar = ({ primary, secondary, total }) => {
  return (
    <AudienceTargetingProgressBarTrack>
      {primary ? (
        <AudienceTargetingProgressBarLine
          value={(100 * primary) / total}
          type="primary"
        />
      ) : null}
      {secondary ? (
        <AudienceTargetingProgressBarLine
          value={(100 * secondary) / total}
          type="secondary"
        />
      ) : null}
    </AudienceTargetingProgressBarTrack>
  );
};

AudienceTargetingProgressBar.propTypes = {
  primary: number,
  secondary: number,
  total: number,
};

const AudienceTargetingLegendItem = ({ type, children }) => {
  return (
    <AudienceTargetingLegendItemContainer>
      <AudienceTargetingLegendItemIndicator type={type} />
      {children}
    </AudienceTargetingLegendItemContainer>
  );
};

AudienceTargetingLegendItem.propTypes = {
  type: string,
  children: node,
};

export const AudienceTargeting = () => {
  const { t } = useLocalization('email-dashboard.edit');
  const { tickets, selectedStatus, selectedTicketTypes } = useContext(
    EditorContext,
  );

  const {
    count,
    total,
    formattedCount,
    formattedTotal,
    totalPendingInvites,
  } = useAudienceCount({
    ticketTypes: tickets,
    status: selectedStatus,
    selectedTicketTypes,
  });

  return (
    <AudienceTargetingContainer data-testid="audience-targeting">
      <Label
        htmlFor="audience-targeting"
        data-testid="audience-targeting-content"
      >
        {t('email-will-be-received')} {formattedCount} {t('of')}{' '}
        {formattedTotal} {t('attendees')}.
      </Label>
      <AudienceTargetingProgressBar
        primary={count}
        secondary={selectedStatus === 'registered' ? totalPendingInvites : null}
        total={total}
      />
      <AudienceTargetingCount>
        <span data-testid="audience-targeting-count">{formattedCount}</span>
        <span data-testid="audience-targeting-total">{formattedTotal}</span>
      </AudienceTargetingCount>
      {selectedStatus === 'registered' && (
        <>
          <AudienceTargetingLegendItem type="primary">
            {t('successfully_registered')}
          </AudienceTargetingLegendItem>
          <AudienceTargetingLegendItem type="secondary">
            {t('pending_registration')}
          </AudienceTargetingLegendItem>
        </>
      )}
    </AudienceTargetingContainer>
  );
};
