import { LocalizationContext } from '@features/localization';
import { Select } from '@hopin-team/ui-select';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 10rem;
`;

const ChartGranularitySelect = ({ options, value, onChange }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <StyledSelect
      options={options}
      label={t('event-dashboard.granularity')}
      hideLabel={true}
      value={value}
      setValue={onChange}
    />
  );
};

ChartGranularitySelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ChartGranularitySelect;
