import { Icon } from '@hopin-team/ui-icon';
import { Image } from '@hopin-team/ui-image';
import PropTypes from 'prop-types';
import React from 'react';

import { ImagePreviewContainer } from './custom-domain-settings.styles';

const CustomDomainSettingsImagePreview = ({ image, label }) => (
  <ImagePreviewContainer aria-label={label} image={image}>
    {image ? (
      <Image src={image} />
    ) : (
      <Icon scale={4} color="grey-600" name="autosave-saving" />
    )}
  </ImagePreviewContainer>
);

CustomDomainSettingsImagePreview.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default CustomDomainSettingsImagePreview;
