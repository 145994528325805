import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  image: null,
  organizations: [],
};

const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
  },
});

const getUserExternalId = ({ user }) => user.externalId;
const getUserOrganisationExternalId = ({ user }) =>
  user.organizations?.[0]?.externalId;

export const { setUser } = actions;

export { getUserExternalId, getUserOrganisationExternalId };

export default reducer;
