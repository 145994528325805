import Card from '@components/card-with-loading';
import InfoCircleIcon from '@components/icons/info-circle-icon';
import { bool, element, number, oneOfType, string } from 'prop-types';
import React from 'react';

import CircleGraphic from './circle-graphic';
import {
  MetricCardContainer,
  MetricCardDescription,
  MetricCardText,
  MetricCardValue,
  MetricCardValueWrapper,
  Tooltip,
  TooltipWrapper,
} from './styles';

const MetricCard = ({
  testId,
  isLoading,
  description,
  displayValue,
  color,
  iconName,
  tooltipText,
}) => {
  return (
    <Card isLoading={isLoading}>
      <MetricCardContainer data-testid={'metric-card-' + testId}>
        {iconName && color && (
          <CircleGraphic
            width={75}
            height={75}
            color={color}
            iconName={iconName}
          />
        )}

        <MetricCardText>
          <MetricCardValueWrapper>
            <MetricCardValue disabled={displayValue == '--'}>
              {displayValue}
            </MetricCardValue>

            {tooltipText && (
              <TooltipWrapper>
                <Tooltip align="top" tip={tooltipText}>
                  <InfoCircleIcon width={16} height={16} />
                </Tooltip>
              </TooltipWrapper>
            )}
          </MetricCardValueWrapper>

          <MetricCardDescription>{description}</MetricCardDescription>
        </MetricCardText>
      </MetricCardContainer>
    </Card>
  );
};

MetricCard.defaultProps = {
  color: 'var(--color-blue-400)',
};

MetricCard.propTypes = {
  isLoading: bool.isRequired,
  description: string.isRequired,
  displayValue: oneOfType([string, number, element]).isRequired,
  color: string,
  iconName: string,
  testId: string,
  tooltipText: string,
};

export default MetricCard;
