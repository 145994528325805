import MountSlice from '@components/slices/MountSlice';
import { SliceErrorBoundary } from '@features/recordings-backup/slices/slice-error-boundary';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import React from 'react';

const PACKAGE_NAME = '@hopin-team/fe-event-slices-transcript';

const TranscriptSliceBase = () => {
  return <MountSlice packageName={PACKAGE_NAME} version="latest" />;
};

export const TranscriptSlice = withErrorHandling({
  loggerName: LOGGER_NAMES.REPLAY,
  errorPattern: 'custom',
  CustomErrorBoundaryComponent: SliceErrorBoundary,
})(TranscriptSliceBase);
