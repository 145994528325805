import * as Routes from '@routes';
import request from '@util/request';
import { bool, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import NoticesComponent from './notices-component';

export const getEventNotices = (eventId, signal) =>
  request.get(Routes.organisersEventNoticesPath(eventId), {
    signal: signal,
  });

const NoticesContainer = ({
  event,
  eventPhase,
  newRegistrationDashboardEnabled,
  newRegistrationUrl,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasTickets, setHasTickets] = useState(false);

  const abortController = new AbortController();

  useEffect(() => {
    getEventNotices(event.id, abortController.signal).then(data => {
      const { hasTickets } = data;
      setHasTickets(hasTickets);
      setLoading(false);
    });
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  return (
    <NoticesComponent
      eventSlug={event.slug}
      eventPhase={eventPhase}
      hasTickets={hasTickets}
      newRegistrationDashboardEnabled={newRegistrationDashboardEnabled}
      newRegistrationUrl={newRegistrationUrl}
    />
  );
};

NoticesContainer.propTypes = {
  event: object.isRequired,
  eventPhase: string.isRequired,
  newRegistrationDashboardEnabled: bool.isRequired,
  newRegistrationUrl: string.isRequired,
};

export default NoticesContainer;
