import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { arrayOf, bool, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearCreateEventError } from '../../redux/app/create-event';
import { useStepsInfo } from '../../shared/useStepsInfo';
import { BreadcrumbItem, BreadCrumbsList, BreadcrumbsNav } from './styles';

const Breadcrumbs = ({ items = [], disabled }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentStepIndex } = useStepsInfo();
  const itemCount = items.length;

  useEffect(() => {
    dispatch(clearCreateEventError());
  }, [dispatch]);

  return (
    <BreadcrumbsNav>
      <BreadCrumbsList>
        {items.map(({ key, path, label, selected }, index) => {
          const isLast = index + 1 === itemCount;
          const navigationDisabled = disabled && index >= currentStepIndex;

          const handleClick = e => {
            e.preventDefault();
            if (navigationDisabled) {
              return;
            }

            dispatch(clearCreateEventError());
            history.push(path);
          };

          return (
            <BreadcrumbItem key={key} disabled={navigationDisabled}>
              <a
                href={path}
                onClick={handleClick}
                data-testid={`breadcrumb-${key}`}
              >
                <Text
                  pattern="body"
                  font="text"
                  weight="medium"
                  color={selected ? 'grey-black' : 'grey-600'}
                  mx={1}
                >
                  {label}
                </Text>
              </a>
              {!isLast && (
                <Icon color="grey-400" name="arrow-forward" scale={1} mx={1} />
              )}
            </BreadcrumbItem>
          );
        })}
      </BreadCrumbsList>
    </BreadcrumbsNav>
  );
};

Breadcrumbs.propTypes = {
  items: arrayOf(
    shape({
      key: string.isRequired,
      path: string.isRequired,
      label: string.isRequired,
      selected: bool.isRequired,
    }).isRequired,
  ),
  disabled: bool,
};

export default Breadcrumbs;
