/* global Rails */
import * as Routes from '@routes';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import Pusher from 'pusher-js';

const APP_KEY = process.env.PUSHER_KEY;
const CLUSTER = process.env.PUSHER_CLUSTER;

// Store the active channels
const channels = new Map();
export let pusher;
export const logger = getLogger(LOGGER_NAMES.ANALYTICS);

/**
 * Creates a new instance of Pusher based on the event id
 * @param {string} eventId - Internal event id
 * @returns {void}
 */
export const pusherInitialize = ({ eventId }) => {
  pusher = new Pusher(APP_KEY, {
    auth: { headers: { 'X-CSRF-Token': Rails.csrfToken() } },
    authEndpoint: Routes.pusherAuthOrganisersEventReportsPath(eventId),
    cluster: CLUSTER,
    encrypted: true,
  });
};

/**
 * Binds the report status handler to the pusher
 * @param {string} eventId - Internal event id
 * @param {function} handler - Event handler function
 * @param {string} userId - Internal user id
 * @returns {function} - Function to delete the channel and unsubscribe from Pusher
 */
export const pusherBindReportsStatus = ({ eventId, handler, userId }) => {
  if (!eventId || !handler || !userId) {
    logger.error('Incorrect pusher configuration');
    return;
  }
  try {
    const channelName = `private-event-organiser_${eventId}_${userId}_reports`;
    if (!channels.has(channelName)) {
      channels.set(channelName, pusher.subscribe(channelName));
    }
    const channel = channels.get(channelName);
    channel.bind('status_change', handler);

    return () => {
      pusher.unsubscribe(channelName);
      channels.delete(channelName);
    };
  } catch (err) {
    logger.error(err);
  }
};

export const _testing_ = {
  channels,
  logger,
  pusher,
  pusherBindReportsStatus,
  pusherInitialize,
};
