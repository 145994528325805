import styled from 'styled-components';

export const SingleTicket = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  font-weight: var(--font-weight-medium);
  font-size: 1rem;
`;

export const TicketDetails = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .label {
    margin-right: 0.5rem;
    color: var(--color-gray-900);
  }

  .fraction {
    margin-left: auto;
    align-self: flex-end;
    overflow-wrap: break-word;
    color: var(--color-gray-700);
  }

  .price {
    margin-right: 0.5rem;
    align-self: flex-end;
    color: var(--color-gray-600);
  }
`;

export const PercentageSold = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 4px;
  background-color: var(--color-gray-300);
  div {
    height: 100%;
    background-color: var(--color-blue-400);
    width: ${props => props.width}%;
    border-radius: ${props => (props.width == 100 ? '4px' : '4px 0px 0px 4px')};
  }
`;

export const EmptyTicketsState = styled.div`
  padding-bottom: 3rem;
`;
