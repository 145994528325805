import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { func, node, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import { CloseLink, Header, ImageWrapper, RightActionsWrapper } from './styles';

const Breadcrumb = props => (
  <Text mx={1} pattern="body" color="grey-300" {...props} />
);

const AnnouncementsTopBar = ({
  event,
  pageBreadcrumb,
  rightActions: RightActionsPanel,
}) => (
  <Header data-testid="announcements-top-bar">
    <CloseLink
      href={Routes.organisersEventAnnouncementsPath(event.slug)}
      data-testid="close-button-link"
      aria-labelledby="Close"
      name="close"
      color="grey-300"
      title="Close"
      scale={1.3}
    />
    <Flex flexDirection="row" alignItems="center">
      <ImageWrapper picture={event.picture} data-testid="image-wrapper" />
      <Breadcrumb>{event.name}</Breadcrumb>
      <Breadcrumb>/</Breadcrumb>
      <Breadcrumb color="grey-white" weight="weight-medium">
        {pageBreadcrumb}
      </Breadcrumb>
    </Flex>
    {RightActionsPanel && (
      <RightActionsWrapper data-testid="topbar-right-panel">
        <Flex justifyContent="center">
          <RightActionsPanel />
        </Flex>
      </RightActionsWrapper>
    )}
  </Header>
);

AnnouncementsTopBar.propTypes = {
  event: shape({
    name: string.isRequired,
    picture: string.isRequired,
    slug: string.isRequired,
  }).isRequired,
  pageBreadcrumb: string.isRequired,
  rightActions: oneOfType([func, node]),
};

export default AnnouncementsTopBar;
