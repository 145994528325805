export const FETCHING_STATE = {
  default: {
    isDone: false,
    isLoading: false,
    isError: false,
  },
  pending: {
    isDone: false,
    isLoading: true,
    isError: false,
  },
  fulfilled: {
    isDone: true,
    isLoading: false,
    isError: false,
  },
  rejected: {
    isDone: true,
    isLoading: false,
    isError: true,
  },
};
