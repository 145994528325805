import { connectViewData } from '@util/page-view-context';

import EventTypeComponent from './event-type-component';

const mapViewDataToProps = ({ event, eventTypes, organiser }) => ({
  errors: event.errors,
  eventPassword: event.password,
  eventType: event.eventType,
  eventTypes,
  hasPaidPlan: organiser.onPaidPlan,
});

export default connectViewData(mapViewDataToProps)(EventTypeComponent);
