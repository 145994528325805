import ImageIcon from 'icons/image.svg';
import { string } from 'prop-types';
import React from 'react';

import { Image, StyledImagePreview } from './image-preview.styles';

const ImagePreview = ({ label, image }) => (
  <StyledImagePreview image={image} aria-label={label}>
    {image ? <Image src={image} /> : <ImageIcon />}
  </StyledImagePreview>
);

ImagePreview.propTypes = {
  label: string,
  image: string,
};

export default ImagePreview;
