import { arrayOf, node, oneOfType, string } from 'prop-types';
import React from 'react';

const SocialMediaLink = ({ href, children }) => {
  return href ? (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : null;
};

SocialMediaLink.propTypes = {
  href: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default SocialMediaLink;
