import { format } from 'date-fns';

const dateFormats = {
  hourAndMinutes: 'HH:mm',
  dayAndMonth: 'dd MMMM',
  shortenedDayAndMonth: 'dd MMM',
  dateAndHour: 'yyyy-MM-dd HH:mm',
};

const formatDate = (locale, date, formatStyle) =>
  format(date, formatStyle, {
    locale,
  });

export { formatDate, dateFormats };
