import VendorTabPreview from '@features/expo/vendor-tab/vendor-tab-preview';
import { LocalizationContext } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import {
  Tab,
  TabHeader,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@hopin-team/ui-tabs';
import { Text } from '@hopin-team/ui-text';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { object, shape, string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 30vw;
  height: 60vh;
`;

const TabMessage = ({ iconName, heading, caption, testId }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={10}
      px={1}
    >
      <Icon data-testid={testId} scale={2} name={iconName} />
      <Text pattern="headingThree">{heading}</Text>
      <Text align="center" pattern="caption">
        {caption}
      </Text>
    </Flex>
  );
};

TabMessage.propTypes = {
  testId: string,
  iconName: string,
  heading: string,
  caption: string,
};

const BoothChildrenTabs = ({ vendor, flags }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Tabs pattern="nested">
      <TabList>
        <Tab>{t('expo.booth.preview.modal.tabs.home')}</Tab>
        <Tab>{t('expo.booth.preview.modal.tabs.chat')}</Tab>
        <Tab>{t('expo.booth.preview.modal.tabs.polls')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <VendorTabPreview
            data-testid="vendor-tab-preview"
            vendor={vendor}
            flags={flags}
          />
        </TabPanel>
        <TabPanel>
          <TabMessage
            iconName="chat"
            heading={t('expo.booth.preview.modal.tabs.chat')}
            caption={t('expo.booth.preview.modal.tab-unavailable', {
              tab: t('expo.booth.preview.modal.tabs.chat'),
            })}
            testId="chat-icon"
          />
        </TabPanel>
        <TabPanel>
          <TabMessage
            iconName="chart-bar"
            heading={t('expo.booth.preview.modal.tabs.polls')}
            caption={t('expo.booth.preview.modal.tab-unavailable', {
              tab: t('expo.booth.preview.modal.tabs.polls'),
            })}
            testId="polls-icon"
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

BoothChildrenTabs.propTypes = {
  vendor: shape({
    name: string,
    logoUrl: string,
    headline: string,
    about: string,
    buttonText: string,
  }),
  flags: object,
};

const PreviewBoothTab = ({ vendor, flags }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Wrapper>
      <Tabs pattern="main">
        <TabHeader>
          <TabList>
            <Tab>{t('expo.booth.preview.modal.tabs.booth')}</Tab>
          </TabList>
        </TabHeader>
        <TabPanels>
          <TabPanel>
            <BoothChildrenTabs vendor={vendor} flags={flags} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Wrapper>
  );
};

PreviewBoothTab.propTypes = {
  vendor: shape({
    name: string,
    logoUrl: string,
    headline: string,
    about: string,
    buttonText: string,
  }),
  flags: object,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.ADD_EXPO_BOOTH,
  }),
)(PreviewBoothTab);
