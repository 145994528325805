const camelizeKeys = obj => {
  if (!(obj instanceof Object)) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(item => camelizeKeys(item));
  }
  const o = {};
  Object.keys(obj).forEach(key => {
    const camelized = key
      .split('_')
      .map((k, i) => (i === 0 ? k : `${k[0].toUpperCase()}${k.slice(1)}`))
      .join('');
    o[camelized] = camelizeKeys(obj[key]);
  });
  return o;
};

export default camelizeKeys;
