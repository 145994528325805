import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { func, string } from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import Rule from './rule';

const minimumCharacters = 10;
const specialCharacters = /[\W_]{1,}/;
const numericCharacters = /[0-9]{1,}/;
const alphabeticCharacters = /[A-Za-z]{1,}/;

const PasswordStrengthFeedback = styled.div`
  margin-top: 16px;
`;

const getRules = t => [
  {
    errorMessage: t(`password-strength.minimum-characters`, {
      minimumCharacters,
    }),
    validator: password =>
      password && password.trim().length >= minimumCharacters,
  },
  {
    errorMessage: t(`password-strength.special-characters`),
    validator: password => password && specialCharacters.test(password.trim()),
  },
  {
    errorMessage: t(`password-strength.numbers`),
    validator: password => password && numericCharacters.test(password),
  },
  {
    errorMessage: t(`password-strength.alphabetic-characters`),
    validator: password => password && alphabeticCharacters.test(password),
  },
];

function validateRules(t, password) {
  const rules = getRules(t);
  return rules.map(rule => ({
    errorMessage: rule.errorMessage,
    valid: rule.validator(password),
  }));
}

const PasswordStrength = withLocalizationProvider(
  ({ password, onSuccess, onFailure }) => {
    const { t } = useLocalization();
    const validationResults = validateRules(t, password);
    const allRulesAreValid = validationResults.every(rule => rule.valid);

    useEffect(() => {
      if (allRulesAreValid) {
        onSuccess();
      } else {
        onFailure();
      }
    }, [allRulesAreValid, onSuccess, onFailure]);

    if (!password) {
      return null;
    }

    return (
      <PasswordStrengthFeedback data-testid="password-strength">
        {validationResults.map(rule => (
          <Rule key={rule.errorMessage} valid={rule.valid}>
            {rule.errorMessage}
          </Rule>
        ))}
      </PasswordStrengthFeedback>
    );
  },
);

PasswordStrength.propTypes = {
  password: string,
  onSuccess: func.isRequired,
  onFailure: func.isRequired,
};

export default PasswordStrength;
export { PasswordStrength };
