import { useLocalization } from '@features/localization';
import { TextField } from '@hopin-team/ui-text-field';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import { DEFAULT_NPS } from '../../constants';
import { QuestionCard, questionCardStyles, QuestionWrapper } from './styles';
import SurveyFieldWrapper from './survey-field-wrapper';

const SurveyDetails = ({
  surveyKind,
  register,
  watch,
  canEditSurvey,
  errors,
}) => {
  const { t } = useLocalization();

  return (
    <QuestionCard {...questionCardStyles}>
      <QuestionWrapper>
        <SurveyFieldWrapper canEditSurvey={canEditSurvey}>
          <TextField
            name="name"
            ref={register({
              required: t('survey-builder.edit.field-required'),
              maxLength: 200,
            })}
            label={t('survey-builder.edit.survey-name')}
            isRequired
            requiredText={t('survey-builder.edit.visible-to-you')}
            currentCount={watch('name').length}
            maxCount={200}
            isDisabled={!canEditSurvey}
            hasErrors={!!errors.name?.message}
            errorMessage={errors.name?.message}
          />
        </SurveyFieldWrapper>
        {surveyKind !== DEFAULT_NPS && (
          <SurveyFieldWrapper canEditSurvey={canEditSurvey}>
            <TextField
              name="title"
              ref={register({
                required: t('survey-builder.edit.field-required'),
                maxLength: 100,
              })}
              label={t('survey-builder.edit.page-title')}
              isRequired
              requiredText={t('survey-builder.edit.visible-to-all')}
              currentCount={watch('title').length}
              maxCount={100}
              mt={3}
              isDisabled={!canEditSurvey}
              hasErrors={!!errors.title?.message}
              errorMessage={errors.title?.message}
            />
          </SurveyFieldWrapper>
        )}
        <SurveyFieldWrapper canEditSurvey={canEditSurvey}>
          <TextField
            name="description"
            ref={register({
              required: t('survey-builder.edit.field-required'),
              maxLength: 200,
            })}
            label={t('survey-builder.edit.page-description')}
            isRequired
            requiredText={t('survey-builder.edit.visible-to-all')}
            currentCount={watch('description').length}
            maxCount={200}
            mt={3}
            isDisabled={!canEditSurvey}
            hasErrors={!!errors.description?.message}
            errorMessage={errors.description?.message}
          />
        </SurveyFieldWrapper>
      </QuestionWrapper>
    </QuestionCard>
  );
};

SurveyDetails.propTypes = {
  surveyKind: string.isRequired,
  register: func.isRequired,
  watch: func.isRequired,
  canEditSurvey: bool.isRequired,
  errors: shape({
    name: shape({ message: string }),
    title: shape({ message: string }),
    description: shape({ message: string }),
  }),
};

export default SurveyDetails;
