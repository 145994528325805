export default class NotificationsStore {
  static key = 'notifications';

  static get() {
    try {
      return JSON.parse(localStorage.getItem(NotificationsStore.key)) || [];
    } catch (e) {
      console.error(e);
    }
  }

  static save(notifications) {
    try {
      if (Array.isArray(notifications)) {
        localStorage.setItem(
          NotificationsStore.key,
          JSON.stringify(notifications),
        );
      } else {
        console.warn('notifications have to be an array');
      }
    } catch (e) {
      console.error(e);
    }
  }
}
