import { EventDate } from '@components/event-info/event-date-component';
import { EventRegistrants } from '@components/event-info/event-registrants-component';
import { EventTickets } from '@components/event-info/event-tickets-component';
import { EventTitle } from '@components/event-info/event-title-component';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import {
  event_source,
  REGISTRATION_EVENTS,
} from '@util/analytics/registration-events';
import trackSegmentEvent from '@util/analytics/segment-client';
import { bool, number, object, string } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';

import * as Styled from './event-info-component.styles';
import {
  calculateIsPostEventInProgress,
  EVENT_STATUS,
  getEventStatus,
  getUserStatus,
  USER_STATUS,
} from './event-info-utils';

const createWidgetUrl = registrationWidgetUrl => {
  if (window.location.search) {
    return `${registrationWidgetUrl}${window.location.search}&widget_source=hopin`;
  }
  return `${registrationWidgetUrl}?widget_source=hopin`;
};

export const EventInfo = ({
  event,
  attendeesVisiblityShowAll,
  registrationsCount,
  calendarLink,
  isEventFinished,
  postEventTimeEnd,
  isUserAttending,
  doorsOpenTime,
  isOrganiser,
  eventReceptionHref,
  postEventHref,
  isUserLoggedIn,
  isEventLive,
  registrationWidgetUrl,
  joinEventFlowEnabled,
  isGuestEvent,
  registrationFormLayoutV2,
}) => {
  const widgetUrl = useMemo(() => {
    return createWidgetUrl(registrationWidgetUrl);
  }, [registrationWidgetUrl]);

  const {
    name,
    time_start: eventTimeStart,
    time_end: eventTimeEnd,
    post_event_enabled: isPostEventEnabled,
  } = event;

  const { t } = useContext(LocalizationContext);

  const isPostEventInProgress = calculateIsPostEventInProgress({
    isPostEventEnabled,
    isEventFinished,
    postEventTimeEnd,
  });

  const eventStatus = getEventStatus({
    isPostEventInProgress,
    isEventFinished,
    isEventLive,
  });

  const userStatus = getUserStatus({
    isEventFinished,
    isUserAttending,
    isOrganiser,
  });

  useEffect(() => {
    trackSegmentEvent(REGISTRATION_EVENTS.REGISTRATION_PAGE_VIEWED, {
      method: event_source,
      signed_id: !!isUserLoggedIn,
    });
  }, [isUserLoggedIn]);

  let buttonLabel;
  if (isEventFinished) {
    buttonLabel = t('registration-v1.event-over');
  } else {
    if (isEventLive) {
      buttonLabel = t('registration-v1.join-event');
    } else {
      buttonLabel = t('registration-v1.register');
    }
  }
  const canAddToCalendar = !isEventFinished;
  const isAttending = !isEventFinished && isUserAttending;
  const showPreviewLink =
    eventStatus === EVENT_STATUS.DEFAULT &&
    userStatus === USER_STATUS.ATTENDING_ORGANISER;
  const registrationAvailable = !isGuestEvent || isOrganiser;

  const renderButtonWithoutJoinEventFlow = () => {
    return (
      registrationAvailable && (
        <EventTickets
          eventSlug={event.slug}
          buttonLabel={buttonLabel}
          buttonProps={{ disabled: isEventFinished }}
          registrationWidgetUrl={widgetUrl}
          registrationFormLayoutV2={registrationFormLayoutV2}
        />
      )
    );
  };

  const renderJoinEventFlowButtons = () => {
    if (!isUserLoggedIn && isEventLive && registrationAvailable)
      return (
        <>
          <EventTickets
            eventSlug={event.slug}
            buttonLabel={t('registration-v1.register')}
            buttonProps={{ disabled: isEventFinished }}
            registrationWidgetUrl={widgetUrl}
            registrationFormLayoutV2={registrationFormLayoutV2}
          />
          <EventTickets
            eventSlug={event.slug}
            buttonLabel={t('registration-v1.already-registered-join-event')}
            buttonProps={{
              disabled: isEventFinished,
              isOutlined: true,
              mt: 2,
            }}
            registrationWidgetUrl={`${widgetUrl}&action=join_event`}
            registrationFormLayoutV2={registrationFormLayoutV2}
          />
        </>
      );

    return renderButtonWithoutJoinEventFlow();
  };

  const renderButtons = () => {
    if (isEventLive && isAttending)
      return (
        <Button element="a" href={eventReceptionHref} pattern="primary">
          {t('registration-v1.enter-event')}
        </Button>
      );

    if (!joinEventFlowEnabled) {
      return renderButtonWithoutJoinEventFlow();
    }

    return renderJoinEventFlowButtons();
  };

  const renderPostEventCallToAction = () => {
    if (isUserAttending) {
      return (
        <Button element="a" href={postEventHref} pattern="primary">
          {t('registration-v1.post-event.cta')}
        </Button>
      );
    }

    return (
      <EventTickets
        eventSlug={event.slug}
        buttonLabel={t(
          isUserLoggedIn
            ? 'registration-v1.post-event.join-and-watch-recordings'
            : 'registration-v1.login-cta',
        )}
        registrationWidgetUrl={widgetUrl}
        registrationFormLayoutV2={registrationFormLayoutV2}
      />
    );
  };

  return (
    <Styled.EventInfoBox>
      <EventTitle
        eventName={name}
        eventStatus={eventStatus}
        userStatus={userStatus}
      />
      {(eventStatus === EVENT_STATUS.ENDED ||
        eventStatus === EVENT_STATUS.POST_EVENT) && (
        <Text element="p" pattern="body">
          {t('registration-v1.event-status-ended')}
        </Text>
      )}
      {!isEventLive && isAttending ? (
        <Text element="p" pattern="body" color="grey-900">
          {t('registration-v1.pre-event-start', {
            minutes: doorsOpenTime,
            unit: doorsOpenTime === 1 ? 'minute' : 'minutes',
          })}
        </Text>
      ) : (
        !isPostEventInProgress && (
          <EventRegistrants
            attendeesVisiblityShowAll={attendeesVisiblityShowAll}
            registrationsCount={registrationsCount || 0}
            eventStatus={eventStatus}
          />
        )
      )}
      {isPostEventInProgress ? (
        <>
          <Styled.EventInfoFooter>
            <EventDate
              startTime={new Date(eventTimeStart)}
              endTime={new Date(eventTimeEnd)}
              calendarLink={calendarLink}
              canAddToCalendar={canAddToCalendar}
              postEventTimeEnd={postEventTimeEnd}
            />
            <Flex justifyContent="center" flexDirection="column">
              {renderPostEventCallToAction()}
            </Flex>
          </Styled.EventInfoFooter>
        </>
      ) : (
        <Styled.EventInfoFooter>
          <EventDate
            startTime={new Date(eventTimeStart)}
            endTime={new Date(eventTimeEnd)}
            calendarLink={calendarLink}
            canAddToCalendar={canAddToCalendar}
            postEventTimeEnd={postEventTimeEnd}
          />
          {renderButtons()}
          {showPreviewLink && (
            <Button
              element="a"
              href={eventReceptionHref}
              pattern="secondary"
              isOutlined
              mt={1}
            >
              {t('registration-v1.preview-event')}
            </Button>
          )}
        </Styled.EventInfoFooter>
      )}
    </Styled.EventInfoBox>
  );
};

EventInfo.propTypes = {
  event: object,
  registrationsCount: number,
  attendeesVisiblityShowAll: bool,
  calendarLink: string,
  isEventFinished: bool,
  postEventTimeEnd: string,
  isUserAttending: bool,
  doorsOpenTime: number,
  isOrganiser: bool,
  eventReceptionHref: string,
  postEventHref: string,
  isEventLive: bool,
  isUserLoggedIn: bool,
  registrationWidgetUrl: string,
  isGuestEvent: bool,
  joinEventFlowEnabled: bool,
  registrationFormLayoutV2: bool,
};

export default withThemeProvider(withLocalizationProvider(EventInfo));
