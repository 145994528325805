export const getAdvancedEventWizardTemplates = ({ reorderTabs }) => {
  // Tabs
  const setup = {
    basics: {},
    theme: {},
    registration: {},
  };

  let registration = {
    'registrations-tickets': {},
    'ticket-groups': {},
    'registration-form': {},
    'registration-event-landing-page': {},
    details: {},
  };

  let venue = {
    reception: {},
    schedule: {},
    stage: {},
    stages: {},
    session: {},
    sessions: {},
    'venue-controls-people': {},
    networking: {},
    partners: {},
    expo: {},
    sponsors: {},
    tags: {},
    surveys: {},
    'venue-controls': {},
    'app-area': {},
  };

  const onsite_features = {
    'floor-plan': {},
    'check-in-areas': {},
    'kiosk-mode': {},
    badges: {},
    signatures: {},
  };

  let people = {
    registrants: {},
    speakers: {},
    'magic-link-invites': {},
  };

  const marketing = {
    emails: {},
    'email-attendees': {},
    'customize-emails': {},
    'sharing-and-tracking': {},
    'utm-codes': {},
  };

  const analytics = {
    engagement: {},
    'live-analytics': {},
    reports: {},
    registrations: {},
    connections: {},
    'view-polls': {},
    'expo-summary': {},
  };

  // Reorder
  if (reorderTabs) {
    registration = {
      'registration-event-landing-page': {},
      'registration-form': {},
      'registrations-tickets': {},
      'ticket-groups': {},
      registrants: {},
      'magic-link-invites': {},
    };

    people = {};

    venue = {
      ...venue,
      speakers: {},
    };
  }

  // Templates
  const advanced_pre_event = {
    overview: {},
    setup,
    registration,
    venue,
    'onsite-features': onsite_features,
    people,
    marketing,
    more: {
      recordings: {},
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      'custom-domain': {},
      'custom-domains': {},
      analytics,
    },
  };

  const advanced_during_event = {
    overview: {},
    setup,
    registration,
    venue,
    'onsite-features': onsite_features,
    people,
    marketing,
    analytics,
    more: {
      recordings: {},
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      'custom-domain': {},
      'custom-domains': {},
    },
  };

  const advanced_after_event = {
    overview: {},
    recordings: {},
    analytics,
    people,
    more: {
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      'custom-domain': {},
      'custom-domains': {},
      setup,
      registration,
      marketing,
      venue,
      'onsite-features': onsite_features,
    },
  };

  return {
    before: advanced_pre_event,
    during: advanced_during_event,
    after: advanced_after_event,
  };
};
