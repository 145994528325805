import { LocalizationContext } from '@features/localization';
import { Spinner } from '@hopin-team/ui-spinner';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { bool, func } from 'prop-types';
import React, { useContext } from 'react';

import { CustomButton, LoadingLabel } from './styles';

const CreateEmailButton = ({ createAnnouncement, loading, disabled }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <CustomButton
      onClick={createAnnouncement}
      disabled={disabled}
      size="medium"
      leadingIcon={!loading && 'email'}
      isInline
      data-testid="create-announcement-button"
    >
      <ToolTip
        align="top"
        tip={t('email-dashboard.list.errors.maximum-announcements-reached')}
        hideTip={!disabled}
      >
        {loading ? (
          <LoadingLabel>
            <Spinner isShowing={true} />
            {t('email-dashboard.list.loading.creating-email')}
          </LoadingLabel>
        ) : (
          t('email-dashboard.list.actions.create')
        )}
      </ToolTip>
    </CustomButton>
  );
};

CreateEmailButton.propTypes = {
  createAnnouncement: func.isRequired,
  loading: bool,
  disabled: bool,
};

export default CreateEmailButton;
