import { Signal } from '@hopin-team/ui-signal';
import compose from 'lodash/fp/compose';
import React from 'react';
import { useSelector } from 'react-redux';

import { isDraftIndicatorShown } from '@/redux/reducers/schedule';
import withReduxProvider from '@/redux/with-provider';

import { useSchedulesPusherSubscription } from './use-subcribe-to-schedule';

function SchedulesDraftContainer() {
  useSchedulesPusherSubscription();

  const isDraft = useSelector(isDraftIndicatorShown);

  return isDraft ? (
    <Signal
      data-testid="schedules-draft-indicator"
      colorPattern="warning"
      ml={2}
      mb={0.5}
    />
  ) : null;
}

export default compose(withReduxProvider)(SchedulesDraftContainer);
