import { LocalizationContext } from '@features/localization';
import { func, string } from 'prop-types';
import React, { useContext } from 'react';

import { CancelScheduleButton } from './cancel-schedule-button';
import { ActionsContainer, TableAction } from './styles';

const Action = props => (
  <TableAction {...props} onMouseUp={e => e.target.blur()}></TableAction>
);

const Actions = ({
  status,
  onReview,
  onEdit,
  onSend,
  onDuplicate,
  onDelete,
  onCancelSchedule,
}) => {
  const translationKeyPrefix = 'email-dashboard.list.actions';
  const { t } = useContext(LocalizationContext);

  return (
    <ActionsContainer data-testid="announcement-actions">
      {status === 'sent' || status === 'sending' ? (
        <Action
          icon="watching"
          title={t(`${translationKeyPrefix}.review`)}
          onClick={onReview}
          data-testid="action-review"
        />
      ) : (
        <>
          <Action
            icon="edit"
            title={t(`${translationKeyPrefix}.edit`)}
            onClick={onEdit}
            data-testid="action-edit"
          />
          {status === 'scheduled' ? (
            <CancelScheduleButton
              title={t(`${translationKeyPrefix}.cancel-schedule`)}
              onClick={onCancelSchedule}
              data-testid="action-cancel-schedule"
            />
          ) : (
            <Action
              icon="send"
              title={t(`${translationKeyPrefix}.send`)}
              onClick={onSend}
              data-testid="action-send"
            />
          )}
        </>
      )}
      <Action
        icon="copy"
        title={t(`${translationKeyPrefix}.duplicate`)}
        onClick={onDuplicate}
        data-testid="action-duplicate"
      />
      {status != 'sending' && (
        <Action
          icon="delete"
          title={t(`${translationKeyPrefix}.delete`)}
          onClick={onDelete}
          data-testid="action-delete"
        />
      )}
    </ActionsContainer>
  );
};

Actions.propTypes = {
  status: string,
  onReview: func,
  onEdit: func,
  onSend: func,
  onDuplicate: func,
  onDelete: func,
  onCancelSchedule: func,
};

export default Actions;
