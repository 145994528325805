import { createSlice } from '@reduxjs/toolkit';

export const editThumbnailSlice = createSlice({
  name: 'editThumbnail',
  initialState: {
    isShowing: false,
    recordingId: null,
  },
  reducers: {
    setIsShowing: (state, action) => {
      state.isShowing = action.payload;
    },
    setRecordingId: (state, action) => {
      state.recordingId = action.payload;
    },
  },
});

export const { setIsShowing, setRecordingId } = editThumbnailSlice.actions;

export default editThumbnailSlice.reducer;
