import { Text } from '@hopin-team/ui-text';
import { Toast } from '@hopin-team/ui-toast';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const StyledToast = styled(Toast)`
  box-shadow: none;
`;

export const ToastMessage = ({ content, learnMore, children, ...props }) => (
  <StyledToast p={3} role="alert" isInverse={true} {...props}>
    {content ? (
      <div>
        <Text
          data-testid="toast-message-content"
          pattern="body"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></Text>{' '}
        {learnMore}
      </div>
    ) : (
      children
    )}
  </StyledToast>
);

ToastMessage.propTypes = {
  content: PropTypes.string,
  learnMore: PropTypes.node,
  children: PropTypes.node,
};
