export const thumbnailsMockData = {
  thumbnails: [
    {
      id: 'thumbnail-id-1',
      url:
        'https://images.unsplash.com/photo-1449247709967-d4461a6a6103?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80',
      selected: false,
      uploaded: true,
    },
    {
      id: 'thumbnail-id-2',
      url:
        'https://images.unsplash.com/photo-1494200483035-db7bc6aa5739?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
      selected: true,
      uploaded: false,
    },
    {
      id: 'thumbnail-id-3',
      url:
        'https://images.unsplash.com/photo-1485841890310-6a055c88698a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
      selected: false,
      uploaded: false,
    },
    {
      id: 'thumbnail-id-4',
      url:
        'https://images.unsplash.com/photo-1436262513933-a0b06755c784?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
      selected: false,
      uploaded: false,
    },
  ],
};
