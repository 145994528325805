import { useReportsNotificationPusherSubscription } from '@components/reports-notification/use-reports-notification-pusher-subscription';
import { loadWithWindow } from '@components/slices/loadWithWindow';
import { withSlicesProvider } from '@components/slices/withSlicesProvider';
import Alerts from '@features/alerts/alerts';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { withLocalizationProvider } from '@features/localization/';
import { useAlertingCreateReportRequest } from '@features/reports/hooks/use-alerting-create-report-request';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Mount, ReactiveMap, useHostSlice } from '@slicesjs/react-core';
import { withSegmentPageView } from '@util/analytics/with-segment-page-view';
import { FE_SLICES_CDN } from '@util/config';
import { LOGGER_NAMES } from '@util/logger';
import { pusher } from '@util/pusher/reports';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { bool, shape, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import withReduxProvider from '@/redux/with-provider';

const SLICE_NAME =
  '@hopin-team/hopin-web-slices-analytics-registrations-dashboard';

const LoadingComponent = () => (
  <Flex justifyContent="center" my={2}>
    <Spinner isShowing pattern="jumbo" />
  </Flex>
);

const initializeFn = (scope, module) => {
  return loadWithWindow(scope, module)();
};

const RegistrationDashboard = props => {
  let reactiveMap = useRef();
  const eventId = useSelector(({ event }) => event.id);
  useReportsNotificationPusherSubscription();
  const reportRequest = useAlertingCreateReportRequest(eventId);

  const { mount, error } = useHostSlice({
    packageName: SLICE_NAME,
    url: `${FE_SLICES_CDN}/slices/_hopin_team_hopin_web_slices_analytics_registrations_dashboard/${props.sliceVersion}/web/remoteEntry.js`,
    UNSAFE_customScriptInitializer: initializeFn,
  });

  const createReactiveMap = () => {
    return new ReactiveMap({
      allowedKeys: ['event', 'feature_flags', 'urls', 'hooks', 'organization'],
      allowedContextKeys: ['pusher'],
    }).addMapContext('pusher', pusher);
  };

  useEffect(() => {
    reactiveMap.current = createReactiveMap();
  }, []);

  const generateReport = () => async (report, parameters, initiatedFrom) => {
    await reportRequest(report, parameters, initiatedFrom);
  };

  if (error) {
    throw error;
  }

  if (mount) {
    reactiveMap.current.item('event').set({
      ...props?.event,
    });
    reactiveMap.current.item('hooks').set({
      useAlertingCreateReportRequest: generateReport,
    });
    reactiveMap.current.item('organization').set({
      ...props?.organization,
    });
    reactiveMap.current.item('urls').set({
      ...props?.urls,
    });

    return (
      <>
        <Alerts />
        <Mount mount={mount} reactiveMap={reactiveMap.current} />
      </>
    );
  }

  return <LoadingComponent />;
};

RegistrationDashboard.propTypes = {
  sliceVersion: string.isRequired,
  event: shape({
    currency: string,
    externalId: string.isRequired,
    type: string,
    hasMagicLinks: bool,
    slug: string.isRequired,
    status: string,
    timezone: string,
    onlyFreeTickets: bool,
    eventStarted: bool,
    eventEnded: bool,
    registrationsCloseTime: string,
    hasPromoCodes: bool,
  }),
  organization: shape({
    externalId: string.isRequired,
    name: string.isRequired,
  }),
  urls: shape({
    attendees: string,
    billing: string,
    magicLinks: string,
    tickets: string,
    promoCodes: string,
    registrationDetails: string,
  }),
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.ANALYTICS,
    errorPattern: 'page',
  }),
  withAlertsProvider,
  withLocalizationProvider,
  withReduxProvider,
  withSlicesProvider,
  withSegmentPageView('Registrations Summary'),
)(RegistrationDashboard);
