import { LocalizationContext } from '@features/localization';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import CardTitle from '../../components/card-title';
import { graphColorsArray1 } from '../../util/colors';
import ColorLegend from './components/color-legend-component';
import SimpleBarChart from './components/simple-bar-chart-component';

const ChartContainer = styled.div`
  border-bottom: 1px solid var(--color-gray-300);
  padding: 0 1em 0 1em;
  margin: 0 2em 2em 2em;
`;
const colorArray = graphColorsArray1.slice(2).reverse();

const ConnectionsMadeChartComponent = ({ requests, connections }) => {
  const { t } = useContext(LocalizationContext);
  const headerText = t('event-dashboard.connections');
  const titles = [
    t('event-dashboard.connection-requests'),
    t('event-dashboard.connections-made'),
  ];
  const values = [requests, connections];
  return (
    <>
      <CardTitle>{headerText}</CardTitle>
      <ChartContainer>
        <SimpleBarChart values={values} colorArray={colorArray} />
      </ChartContainer>
      <ColorLegend values={values} titles={titles} colorArray={colorArray} />
    </>
  );
};

ConnectionsMadeChartComponent.propTypes = {
  requests: PropTypes.number,
  connections: PropTypes.number,
};

export default ConnectionsMadeChartComponent;
