import { parse } from './csv-utils';

export const happyPathFile = new File(
  ['column1,column2\ndata1,data2\ndata3,data4'],
  'eventschedule.csv',
  {
    type: 'text/csv',
  },
);

const errorFile = new File(['column1!'], 'eventschedule.csv', {
  type: 'text/csv',
});

describe('CSV Utils', () => {
  it('Parses file successfully', async () => {
    const results = await parse(happyPathFile);

    expect(results).toEqual([
      { column1: 'data1', column2: 'data2' },
      { column1: 'data3', column2: 'data4' },
    ]);
  });

  it('Handles parsing failure', async () => {
    try {
      await parse(errorFile);
    } catch (e) {
      expect(e).toEqual(new Error('Error parsing file'));
    }
  });
});
