import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import PropTypes from 'prop-types';
import React from 'react';

export const SavePanel = ({
  onCancelText,
  onConfirmText,
  onCancel,
  onConfirm,
  isSaveDisabled,
  isInLine = true,
}) => (
  <Flex justifyContent="end">
    <Button isInline={isInLine} isOutlined size="medium" onClick={onCancel}>
      {onCancelText}
    </Button>
    <Button
      isInline={isInLine}
      size="medium"
      ml={1}
      onClick={onConfirm}
      disabled={isSaveDisabled}
    >
      {onConfirmText}
    </Button>
  </Flex>
);

SavePanel.propTypes = {
  onCancelText: PropTypes.string.isRequired,
  onConfirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  isInLine: PropTypes.bool,
};
