import eventDashboardApi from '@api/event-dashboard';
import Loading from '@components/loading';
import normalize from 'json-api-normalizer';
import { string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAttributeLinks,
  getBackstageOptions,
  getHopinStudioLinks,
  getRtmpLinksByPriority,
  setBackstageLinks,
} from '@/redux/reducers/backstage-links.js';
import {
  getRegistrationUrl,
  getRtmpAllowed,
  setEventLinks,
} from '@/redux/reducers/event-links';
import withReduxProvider from '@/redux/with-provider';

import EventLinksComponent from './event-links-component';

export const EventLinksContext = React.createContext();

const EventLinksContainer = ({ eventId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const response = await eventDashboardApi.getEventLinks(eventId);
      dispatch(setEventLinks(normalize(response)));
      dispatch(setBackstageLinks(normalize(response)));
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const eventSlug = useSelector(({ event }) => event.slug);

  const registrationUrl = useSelector(getRegistrationUrl(eventId));

  const backstageOptions = useSelector(getBackstageOptions);

  const hopinStudioLinks = useSelector(getHopinStudioLinks);

  const createRtmpLink = useSelector(
    getAttributeLinks('createRtmpVideoChannelsUrl'),
  );

  const rtmpLinks = useSelector(getRtmpLinksByPriority());

  const isLoading = !useSelector(({ eventLinks }) => eventLinks[eventId]);

  const rtmpAllowed = useSelector(getRtmpAllowed(eventId));

  return (
    <Loading isLoading={isLoading}>
      {!isLoading && (
        <EventLinksContext.Provider
          value={{
            backstageOptions,
            createRtmpLink,
            eventSlug,
            hopinStudioLinks,
            registrationUrl,
            rtmpAllowed,
            rtmpLinks,
          }}
        >
          <EventLinksComponent eventId={eventId} />
        </EventLinksContext.Provider>
      )}
    </Loading>
  );
};

EventLinksContainer.propTypes = {
  eventId: string,
};

export default withReduxProvider(EventLinksContainer);
