import { createSlice } from '@reduxjs/toolkit';

export const transcriptSlice = createSlice({
  name: 'transcript',
  initialState: {
    isShowing: false,
  },
  reducers: {
    setIsShowing: (state, action) => {
      state.isShowing = action.payload;
    },
  },
});

export const { setIsShowing } = transcriptSlice.actions;

export default transcriptSlice.reducer;
