import { Box } from '@hopin-team/ui-box';
import { Icon } from '@hopin-team/ui-icon';
import styled, { css } from 'styled-components';

export const iconStyles = css`
  padding: 0.4rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-width: 0.875rem;
    min-height: 0.875rem;
  }
`;

export const IconWrapper = styled(Icon)`
  ${iconStyles};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const StyledBox = styled(Box)`
  visibility: ${({ isBlockOpen }) => (isBlockOpen ? 'visible' : 'hidden')};
  display: ${({ isBlockOpen }) => (isBlockOpen ? 'block' : 'none')};
`;
