import {
  Form,
  FormActions,
  FormBody,
  FormHeader,
  FormRow,
} from '@components/modal/form';
import {
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { InputLabel } from '@hopin-team/ui-input-label';
import compose from 'lodash/fp/compose';
import { func, object, string } from 'prop-types';
import React from 'react';

import { Heading } from '../../bulk-invite/components/success-modal';
import {
  ErrorMessage,
  InputContainer,
  WhiteInput,
} from './edit-magic-link-styles';
import useEditMagiclinkForm from './use-edit-magic-link-form';

const getFormValidationPath = type => `forms.validation.${type}`;

const EditMagiclinkForm = ({ initialValues, eventSlug, apiClient, t }) => {
  const { onSubmit, isSubmitting, register, errors } = useEditMagiclinkForm(
    initialValues,
    eventSlug,
    apiClient,
  );

  const getTranslation = (type, params) =>
    t(getFormValidationPath(type), params);
  const inputRegister = register();

  return (
    <Form data-testid="edit-form" onSubmit={onSubmit}>
      <FormHeader>
        <Heading>{t('magicLinks.edit.title')}</Heading>
      </FormHeader>
      <FormBody>
        <FormRow>
          <InputContainer>
            <InputLabel htmlFor="redeem_code[first_name]">
              {t('firstname')}
            </InputLabel>
            <WhiteInput
              data-testid="firstName"
              hasError={errors?.first_name}
              name="redeem_code[first_name]"
              ref={inputRegister}
            />
            {errors?.first_name && (
              <ErrorMessage>
                {getTranslation(errors?.first_name.type)}
              </ErrorMessage>
            )}
          </InputContainer>
          <InputContainer>
            <InputLabel htmlFor="redeem_code[last_name]">
              {t('lastname')}
            </InputLabel>
            <WhiteInput
              data-testid="lastName"
              hasError={errors?.last_name}
              name="redeem_code[last_name]"
              ref={inputRegister}
            />
            {errors?.last_name && (
              <ErrorMessage>
                {getTranslation(errors?.last_name.type)}
              </ErrorMessage>
            )}
          </InputContainer>
        </FormRow>

        <FormRow>
          <InputContainer>
            <InputLabel htmlFor="redeem_code[headline]">
              {t('headline')}
            </InputLabel>
            <WhiteInput
              data-testid="headline"
              hasError={errors?.headline}
              name="redeem_code[headline]"
              ref={inputRegister}
            />
            {errors?.headline && (
              <ErrorMessage>
                {getTranslation(
                  errors?.headline.type,
                  errors?.headline.message.params,
                )}
              </ErrorMessage>
            )}
          </InputContainer>
          <InputContainer>
            <InputLabel htmlFor="redeem_code[email]">{t('email')}</InputLabel>
            <WhiteInput
              data-testid="email"
              hasError={errors?.email}
              name="redeem_code[email]"
              ref={inputRegister}
            />
            {errors?.email && (
              <ErrorMessage>{getTranslation(errors?.email.type)}</ErrorMessage>
            )}
          </InputContainer>
        </FormRow>
      </FormBody>
      <FormActions>
        <Button
          data-testid="submit-button"
          disabled={isSubmitting}
          type="submit"
        >
          {t('save')}
        </Button>
      </FormActions>
    </Form>
  );
};

EditMagiclinkForm.propTypes = {
  initialValues: object.isRequired,
  eventSlug: string.isRequired,
  apiClient: object.isRequired,
  t: func.isRequired,
};

export default compose(
  withLocalizationProvider,
  withLocalization,
)(EditMagiclinkForm);
