// Valid values for steps are 'pending', 'completed', or 'not_applicable'

export const response = {
  attributes: {
    event_id: 'test-event',
    visible: true,
    steps: {
      details: 'pending',
      tickets: 'pending',
      landing_page: 'pending',
      reception: 'pending',
      sessions: 'pending',
      stage: 'pending',
      speaker_profiles: 'pending',
      previewed: 'pending',
      published: 'pending',
    },
  },
};
