import { TYPE } from '@components/people/category-types.js';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import MultiSelectPreviewImage from '../../assets/multi-select.js';
import SingleSelectPreviewImage from '../../assets/single-select.js';

const SelectPreview = ({ dropdownPreview }) => {
  const { t } = useLocalization();

  return (
    <Box m={2}>
      <Wrapper mb={2}>
        {dropdownPreview === TYPE.SINGLE ? (
          <SingleSelectPreviewImage />
        ) : (
          <MultiSelectPreviewImage />
        )}
      </Wrapper>
      <Text pattern="emphasis">
        {dropdownPreview === TYPE.SINGLE
          ? t('networking.profile-setup.single-select')
          : t('networking.profile-setup.multi-select')}{' '}
        {t('networking.profile-setup.field')}
      </Text>
    </Box>
  );
};

export default withLocalizationProvider(SelectPreview);

SelectPreview.propTypes = {
  dropdownPreview: PropTypes.string,
};

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
