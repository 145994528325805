import Crown from '@components/icons/crown';
import ReportsNotificationContainer from '@components/reports-notification/reports-notification-container';
import { useLocalization } from '@features/localization';
import SchedulesDraftContainer from '@features/schedules/schedules-draft-container';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { componentExists, paidPlanFeatures } from '../nav-config';
import { Styles } from './nav-item-styles';

export const SubNavItem = ({
  childTitle,
  link,
  currentActiveItem,
  scrollToActiveItem,
  tabIndex = 0,
  gatePaidFeatures,
}) => {
  const { t } = useLocalization('event-dashboard.sidenav');
  const isActive = childTitle === currentActiveItem.key;
  const isTestEnv = process.env.NODE_ENV === 'test';
  const renderReactRoute = isTestEnv ? componentExists(link) : false;
  const title = t(`nav-items.${childTitle}`) || childTitle;
  const isGatedFeature =
    gatePaidFeatures && paidPlanFeatures.includes(childTitle);

  return (
    <Styles.SubItem
      // DASH-282: disable dynamic routing and force page reloads
      // This causes issues with page analytics: segment.page calls are not made when loading a slice component.
      // As a workaround here we force a page reload which will trigger a correct segment.page call.
      // A better approach would be to update our load-slice component to allow us to hook into the slice lifecycle
      // and call segment.page ourselves after the slice has been loaded, from our own router.
      // Another way would be to have each slice call segment.page, but would require each slice to be updated separately.
      {...(renderReactRoute ? { as: Link, to: link } : { as: 'a', href: link })}
      data-menu-action={title}
      isActive={isActive}
      isChild
      {...(isActive && {
        ref: scrollToActiveItem,
      })}
      tabIndex={tabIndex}
    >
      <Text font="display" pattern="headingFour">
        {title}
      </Text>
      {title === 'Reports' ? (
        <Styles.Notification>
          <ReportsNotificationContainer theme="secondary" />
        </Styles.Notification>
      ) : null}
      {isGatedFeature && (
        <Styles.Crown>
          <Crown />
        </Styles.Crown>
      )}

      {link.includes('/schedules') && <SchedulesDraftContainer />}
    </Styles.SubItem>
  );
};

SubNavItem.propTypes = {
  childTitle: PropTypes.string,
  link: PropTypes.string,
  currentActiveItem: PropTypes.object,
  scrollToActiveItem: PropTypes.func,
  tabIndex: PropTypes.number,
  gatePaidFeatures: PropTypes.bool,
};
