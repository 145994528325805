import { Button } from '@hopin-team/ui-button';
import styled from 'styled-components';

const HideChecklistButton = styled(Button).attrs({
  isInline: true,
  isOutlined: true,
  size: 'small',
})`
  border: 0;

  &:hover {
    border: 0;
  }
`;

export default HideChecklistButton;
