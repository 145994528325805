import { useLocalization } from '@features/localization';
import { TableRow } from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

import {
  CustomTable,
  CustomTableCell,
  CustomTableHeading,
  CustomTableRow,
  TableScrollWrapper,
} from './error-report-table-styles';

export const ErrorReportTable = ({ errors }) => {
  const { t } = useLocalization('common');

  return (
    <TableScrollWrapper>
      <CustomTable>
        <thead>
          <TableRow isHeading>
            <CustomTableHeading scope="col" width="60px">
              <Text pattern="emphasis">{t('row')}</Text>
            </CustomTableHeading>

            <CustomTableHeading scope="col">
              <Text pattern="emphasis">{t('error')}</Text>
            </CustomTableHeading>
          </TableRow>
        </thead>
        <tbody>
          {errors.map(({ lineIndex, messages }, index) => (
            <CustomTableRow key={index}>
              <CustomTableCell>
                <Text pattern="body" color="red-500">
                  {lineIndex}
                </Text>
              </CustomTableCell>

              <CustomTableCell>
                <Text pattern="body" color="grey-600">
                  {messages}
                </Text>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </tbody>
      </CustomTable>
    </TableScrollWrapper>
  );
};

ErrorReportTable.propTypes = {
  errors: PropTypes.array,
};
