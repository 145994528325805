import { Checkbox } from '@hopin-team/ui-checkbox';
import { string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const StyleLabel = styled.label`
  margin-bottom: 10px;
`;

const BooleanInput = ({ label, name, id, initialValue = '0' }) => {
  const [checked, setChecked] = useState(initialValue === '1');
  const value = checked ? '1' : '0';

  const changeChecked = useCallback(() => {
    setChecked(!checked);
  }, [setChecked, checked]);

  return (
    <>
      <StyleLabel htmlFor={`field-${id}`}>{label}</StyleLabel>
      <Checkbox
        name={name}
        id={id}
        checked={checked}
        onChange={changeChecked}
      />
      <input
        type="hidden"
        data-testid="boolean-input-hidden"
        value={value}
        readOnly
        name={name}
      />
    </>
  );
};

BooleanInput.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  id: string.isRequired,
  initialValue: string,
};

export default BooleanInput;
