import Menu from '@components/menu/menu-container';
import {
  Button as MenuButton,
  Menu as StyledMenu,
} from '@components/menu/menu-styled-components';
import { Avatar } from '@hopin-team/ui-avatar';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

import { Styles as NavItemStyles } from './nav-item-styles';

export const Styles = {};

Styles.Footer = styled.div`
  margin-top: auto;
  bottom: 0;
`;

Styles.ProfileMenu = styled(Menu)`
  height: 100%;
  width: 100%;

  ${Avatar} {
    margin-right: ${calcSpace(2)};
  }

  ${MenuButton} {
    width: 100%;
    padding: 0.75rem 0.5rem;
    border-top: 1px solid var(--color-gray-300);

    :hover {
      background-color: var(--color-nav-hover);

      ${NavItemStyles.RootItem} {
        background-color: var(--color-nav-hover);
      }
    }
  }

  ${StyledMenu} {
    margin-left: ${calcSpace(3)};
    width: calc(100% - ${calcSpace(6)});
  }
`;
