import { withThemeProvider } from '@features/branding/withThemeProvider';
import { Toggle } from '@hopin-team/ui-toggle';
import { bool } from 'prop-types';
import React, { useState } from 'react';

const DoorsCloseToggle = ({ isToggled }) => {
  let displayStyle;
  const [_isToggled, setToggle] = useState(isToggled);

  return (
    <Toggle
      isToggled={_isToggled}
      id="postEventEnabled"
      onChange={() => {
        displayStyle = _isToggled ? 'none' : 'block';
        document.querySelector(
          '#post_event_inputs',
        ).style.display = displayStyle;
        document
          .querySelector('#event_post_event_enabled')
          .setAttribute('value', !_isToggled);
        setToggle(!_isToggled);
      }}
    />
  );
};

DoorsCloseToggle.propTypes = {
  isToggled: bool.isRequired,
};

export default withThemeProvider(DoorsCloseToggle);
