import hopinApi from '@api/hopin';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@components/table';
import { AlertsContext } from '@features/alerts/alerts-provider';
import {
  differenceInMilliseconds,
  isAfter,
  isBefore,
  parseISO,
} from 'date-fns';
import { object, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import ScheduleRow from './schedule-shift/schedule-row';

const stripISOTimezone = isoString =>
  isoString.replace(/((-|\+)\d\d:\d\d|Z)$/, '');

const ScheduleShift = ({
  className,
  currentTimeEnd,
  currentTimeStart,
  event,
}) => {
  const [useShift, setUseShift] = useState(false);
  const handleScheduleShift = () => setUseShift(true);
  const handleRevertScheduleShift = () => setUseShift(false);
  const shiftAmount = differenceInMilliseconds(
    parseISO(currentTimeStart),
    parseISO(event.timeStartLocal),
  );
  const [schedules, setSchedules] = useState(event.schedules);
  const { addAlert } = useContext(AlertsContext);

  const handleScheduleRemove = async id => {
    try {
      const result = await hopinApi.deleteSchedule(event.slug, id);
      if (!result.ok) {
        throw new Error(result.statusText);
      }
      const newSchedules = [...schedules];
      const scheduleIndex = newSchedules.findIndex(s => s.id === id);
      newSchedules.splice(scheduleIndex, 1);
      setSchedules(newSchedules);
    } catch (err) {
      addAlert({
        active: true,
        text: 'Something went wrong',
        pattern: 'error',
      });
    }
  };

  return (
    <div className={className}>
      <div className="flex align-items-center justify-content-between">
        <label>
          Changing the time of the event might affect your schedule segments:
        </label>
        {useShift ? (
          <button
            type="button"
            className="button -small"
            onClick={handleRevertScheduleShift}
          >
            Shift schedule back
          </button>
        ) : (
          <button
            type="button"
            className="button -small"
            onClick={handleScheduleShift}
          >
            Shift whole schedule with start time
          </button>
        )}
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Start Time</TableHeaderCell>
            <TableHeaderCell>End Time</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {schedules.map((schedule, ix) => {
            const mismatched =
              isAfter(
                parseISO(currentTimeStart),
                parseISO(stripISOTimezone(schedule.timeStartLocal)),
              ) ||
              isBefore(
                parseISO(currentTimeEnd),
                parseISO(stripISOTimezone(schedule.timeEndLocal)),
              );
            return (
              <ScheduleRow
                key={schedule.id}
                eventSlug={event.slug}
                index={ix}
                mismatched={mismatched}
                onRemove={handleScheduleRemove}
                schedule={schedule}
                shiftAmount={shiftAmount}
                useShift={useShift}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

ScheduleShift.propTypes = {
  className: string,
  currentTimeEnd: string.isRequired,
  currentTimeStart: string.isRequired,
  event: object.isRequired,
};

export default ScheduleShift;
