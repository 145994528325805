import getDisplayName from '@util/get-display-name';
import { object } from 'prop-types';
import React, { useContext } from 'react';

const createViewContext = () => {
  const ViewContext = React.createContext();

  const withViewDataProvider = WrappedComponent => {
    const WithViewDataProvider = ({ viewData = {}, ...props }) => {
      return (
        <ViewContext.Provider value={viewData}>
          <WrappedComponent {...props} />
        </ViewContext.Provider>
      );
    };
    WithViewDataProvider.propTypes = { viewData: object };
    WithViewDataProvider.displayName = `WithViewDataProvider(${getDisplayName(
      WrappedComponent,
    )})`;
    return WithViewDataProvider;
  };

  const connectViewData = mapViewDataToProps => WrappedComponent => {
    const ConnectViewData = props => {
      const context = useContext(ViewContext);
      const viewDataProps = mapViewDataToProps(context);
      return <WrappedComponent {...viewDataProps} {...props} />;
    };
    ConnectViewData.displayName = `ConnectViewData(${getDisplayName(
      WrappedComponent,
    )})`;
    return ConnectViewData;
  };

  return { connectViewData, ViewContext, withViewDataProvider };
};

export default createViewContext;
