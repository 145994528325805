import { Halon } from '@hopin-team/ui-halon';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React from 'react';

import {
  isStatusError,
  isStatusPending,
  isStatusSuccess,
} from './custom-domain-settings-helpers';
import { Status } from './custom-domain-settings.styles';

const CustomDomainSettingsStatus = ({ t, isPersisted, status, errors }) => {
  const isStatusPendingOrError =
    isStatusPending(status, errors) || isStatusError(errors);

  return (
    <ToolTip
      tip={
        !isPersisted
          ? t('event-dashboard.custom-domains.not-set-up')
          : isStatusSuccess(status, errors)
          ? t('event-dashboard.custom-domains.successfully-enabled')
          : isStatusError(errors)
          ? errors.join(' - ')
          : t('event-dashboard.custom-domains.pending')
      }
      align="top"
    >
      <Status
        as={Halon}
        icon={isStatusPendingOrError ? 'close' : 'checkmark'}
        iconColor={isStatusPendingOrError ? 'grey-500' : 'green-500'}
        color={isStatusPendingOrError ? 'grey-100' : 'green-100'}
        scale={4}
        iconScale={isStatusPendingOrError ? 1.5 : 2}
      />
    </ToolTip>
  );
};

CustomDomainSettingsStatus.propTypes = {
  status: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  isPersisted: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default CustomDomainSettingsStatus;
