export const firstPage = {
  waitlist: [
    {
      id: 100,
      name: 'Xuan Wisozk',
      email: 'darnell.kuvalis@weissnat.co',
      event_track: 'Pannier-88501',
      ticket_type: 'Y-Solowarm',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 99,
      name: 'Clyde Johnson',
      email: 'junior@oreilly.co',
      event_track: 'Kanlam-15694',
      ticket_type: 'Sub-Ex',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 98,
      name: 'Alita Pfeffer',
      email: 'robin@kunde.com',
      event_track: 'Pannier-88501',
      ticket_type: 'Zamit',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 97,
      name: 'Rueben Fritsch',
      email: 'freddy@zemlak-conroy.co',
      event_track: 'Stim-5818',
      ticket_type: 'Cardify',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 96,
      name: 'Louie Donnelly',
      email: 'alma@price.name',
      event_track: 'Pannier-88501',
      ticket_type: 'Y-Solowarm',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 95,
      name: 'Douglas Gislason',
      email: 'dylan_braun@ziemann.co',
      event_track: 'Kanlam-15694',
      ticket_type: 'Sonsing',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 94,
      name: 'Trish Mohr',
      email: 'marcelo@weber-mcdermott.co',
      event_track: 'Kanlam-15694',
      ticket_type: 'Sonsing',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 93,
      name: 'Caleb Stroman',
      email: 'ed.dubuque@kessler-roberts.name',
      event_track: null,
      ticket_type: 'Kanlam',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 92,
      name: 'Fumiko Reinger',
      email: 'keena_thompson@deckow-wehner.io',
      event_track: 'Pannier-88501',
      ticket_type: 'Zamit',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 91,
      name: 'Yoko Ward',
      email: 'tom_legros@ruecker-johns.name',
      event_track: 'Kanlam-15694',
      ticket_type: 'Sub-Ex',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 90,
      name: 'Octavio Kirlin',
      email: 'eleanor.emmerich@trantow.info',
      event_track: 'Pannier-88501',
      ticket_type: 'Y-Solowarm',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 89,
      name: 'Adriana Kilback',
      email: 'maryjo_littel@anderson.biz',
      event_track: null,
      ticket_type: 'Pannier',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 88,
      name: 'Johnie Wyman',
      email: 'dudley@west.com',
      event_track: 'Pannier-88501',
      ticket_type: 'Zamit',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 87,
      name: 'Sidney Conroy',
      email: 'brain@armstrong-gleichner.info',
      event_track: 'Stim-5818',
      ticket_type: 'Cardify',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 86,
      name: 'Maxwell Ritchie',
      email: 'omar_luettgen@cummings-macejkovic.net',
      event_track: 'Kanlam-15694',
      ticket_type: 'Sonsing',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 85,
      name: 'Candice White',
      email: 'abe@mckenzie.co',
      event_track: 'Kanlam-15694',
      ticket_type: 'Sonsing',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 84,
      name: 'Lauri Auer',
      email: 'sandy@becker.io',
      event_track: null,
      ticket_type: 'Kanlam',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 83,
      name: 'Robin Cronin',
      email: 'dante.prosacco@wehner-kiehn.com',
      event_track: 'Kanlam-15694',
      ticket_type: 'Sonsing',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 82,
      name: 'Gayle Cremin',
      email: 'rose@sanford.net',
      event_track: null,
      ticket_type: 'Tempsoft',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
    {
      id: 81,
      name: 'Lorna Hand',
      email: 'delinda@padberg.co',
      event_track: null,
      ticket_type: 'Kanlam',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
  ],
  pagy: { current_page: 1, total_pages: 2, count: 26 },
};

export const secondPage = {
  waitlist: [
    {
      id: 151,
      name: 'Mark Sugar',
      email: 'delinda@padberg.co',
      event_track: null,
      ticket_type: 'Kanlam',
      created_at_date: '02 Mar 2021',
      created_at_time: '11:01 AM',
    },
  ],
  pagy: { current_page: 1, total_pages: 2, count: 26 },
};
