import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { array, object } from 'prop-types';
import React from 'react';

import StageRow from './row';

const StagesList = ({ event, stages }) => {
  const { t } = useLocalization('stages');

  return (
    <table className="table">
      <thead>
        <tr>
          <th>{t('index-title')}</th>
          <th>{t('description')}</th>
          <th>{t('stream-provider')}</th>
          <th colSpan="3"></th>
        </tr>
      </thead>
      <tbody>
        {stages.map(stage => (
          <StageRow event={event} key={stage.id} stage={stage} />
        ))}
      </tbody>
    </table>
  );
};

StagesList.propTypes = {
  event: object.isRequired,
  stages: array.isRequired,
};

export default withLocalizationProvider(StagesList);
