import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Anchor = styled.a`
  color: var(--color-blue-400);
  text-decoration: underline;
  font-weight: var(--font-weight-medium);
`;

const FormFooter = ({ t, handleClick }) => (
  <Flex alignItems="center" flexDirection="column">
    <Text scale={3} mt={1}>
      {t('.resend.help')}
    </Text>
    <Text scale={3} mt={1}>
      <Anchor data-testid="resend-button" onClick={handleClick}>
        {t('.resend.linkText')}
      </Anchor>
    </Text>
  </Flex>
);

FormFooter.propTypes = {
  t: func.isRequired,
  handleClick: func.isRequired,
};

export default FormFooter;
