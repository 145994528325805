const DEFAULT_STAGE_NAMES = ['Stage', 'Default Stage'];

const areaNameWithPrefix = area => {
  switch (area.segment) {
    case 'Stages':
      return DEFAULT_STAGE_NAMES.includes(area.name)
        ? area.name
        : `Stage: ${area.name}`;
    case 'Sessions':
      return `Session: ${area.name}`;
    case 'Expo':
      return `Booth: ${area.name}`;
    default:
      return `${area.name}`;
  }
};

export default areaNameWithPrefix;
