import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { withSpace } from '@hopin-team/ui-helper-with-space';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { bool, number, string } from 'prop-types';
import React, { useState } from 'react';

import { TEMPLATES } from '../constants';
import { steps, TemplateModal } from '../modal';
import TemplateMenuItem from '../partials/template-menu-item';
import { TemplateList } from './styled/template-list';

const StartMenu = ({
  className,
  isOnFreePlan,
  maxEventDurationSecs,
  organizationExternalId,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [initialStep, setInitialStep] = useState(undefined);
  const { t } = useLocalization('templates');
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const handleTemplateSelection = (template = {}, mode) => {
    setSelectedTemplate(template);
    setInitialStep(mode);
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
    setInitialStep(undefined);
  };

  return (
    <Box className={className} data-testid="overview-template-selection-menu">
      <Text element="h2" pattern="headingFive" scale={7}>
        {t('getStarted')}
      </Text>
      <TemplateList mt={1} flexWrap="wrap">
        <Flex>
          <TemplateMenuItem
            href={Routes.createEventPath()}
            isBlank
            onClick={() => handleTemplateSelection(null, steps.FORM)}
            template={{ name: 'Blank' }}
          />
        </Flex>
        {TEMPLATES.map((template, ix) => (
          <Flex key={ix}>
            <TemplateMenuItem
              isCondensed={true}
              onPreviewClick={() =>
                handleTemplateSelection(template, steps.PREVIEW)
              }
              onUseClick={() => handleTemplateSelection(template, steps.FORM)}
              template={template}
            />
          </Flex>
        ))}
      </TemplateList>
      {isModalVisible && (
        <TemplateModal
          isShowing={true}
          initialStep={initialStep}
          initialSelectedTemplate={selectedTemplate}
          isOnFreePlan={isOnFreePlan}
          maxEventDurationSecs={maxEventDurationSecs}
          onClose={handleClose}
          organizationExternalId={organizationExternalId}
        />
      )}
    </Box>
  );
};

StartMenu.propTypes = {
  className: string,
  isOnFreePlan: bool.isRequired,
  maxEventDurationSecs: number.isRequired,
  organizationExternalId: string.isRequired,
};

export default withSpace(StartMenu);
