import PropTypes from 'prop-types';
import React from 'react';

const Arrow = ({ color, rotation, size }) => {
  size = size || 12;
  color = color || 'black';
  rotation = rotation || 0;
  const style = rotation != 0 ? { transform: `rotate(${rotation}deg)` } : {};
  return (
    <svg
      data-testid="arrow-icon"
      width={size}
      height={size}
      viewBox={`-2 0 14 14`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33356 13.6667H4.66689C4.4828 13.6667 4.33356 13.5174 4.33356 13.3333V2.55334L1.37356 5.52001C1.31097 5.58311 1.22577 5.61861 1.13689 5.61861C1.04801 5.61861 0.962816 5.58311 0.900227 5.52001L0.43356 5.04668C0.370456 4.98409 0.334961 4.89889 0.334961 4.81001C0.334961 4.72113 0.370456 4.63593 0.43356 4.57334L4.52023 0.48001C4.61391 0.386215 4.741 0.33346 4.87356 0.333344H5.12689C5.25917 0.33488 5.38575 0.387421 5.48023 0.48001L9.56689 4.57334C9.63 4.63593 9.66549 4.72113 9.66549 4.81001C9.66549 4.89889 9.63 4.98409 9.56689 5.04668L9.09356 5.52001C9.03212 5.58272 8.94802 5.61806 8.86023 5.61806C8.77244 5.61806 8.68834 5.58272 8.62689 5.52001L5.66689 2.55334V13.3333C5.66689 13.5174 5.51766 13.6667 5.33356 13.6667Z"
      />
    </svg>
  );
};

Arrow.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
  size: PropTypes.number,
};

export default Arrow;
