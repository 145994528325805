import { Flex } from '@hopin-team/ui-flex';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import styled from 'styled-components';

export const Body = styled(Flex)`
  & > div {
    flex: 1;
  }

  ${({ allowOverflow }) =>
    allowOverflow
      ? ''
      : `
        max-height: 65vh;
        overflow-y: scroll;
  `}
  position: relative;
  background: ${({ theme }) => getFromTheme('COLOR.GREY["100"]', theme)};
`;
