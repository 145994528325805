import SaveBar from '@components/save-bar';
import BoothPreviewModal from '@features/expo/booth-preview-modal';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { object, shape, string } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const getPreviewRoute = onPreviewUrl => {
  const previewAfterSave = '?preview_after_save=true';
  return onPreviewUrl + previewAfterSave;
};

const LinkText = styled.div`
  color: var(--color-blue-400);
`;

const SaveAndPreviewBar = ({ showPreview, vendor, onPreviewUrl, flags }) => {
  const [isModalDisplayed, setModalDisplayed] = useState(false);
  const openBoothPreview = useCallback(() => setModalDisplayed(true), []);
  const onClose = useCallback(() => setModalDisplayed(false), []);
  useEffect(() => {
    if (showPreview === 'true') {
      openBoothPreview();
    }
  }, [openBoothPreview, showPreview]);
  const { t } = useLocalization('expo.booth.preview');

  return (
    <SaveBar>
      <Button
        isGhost
        isInline={true}
        size="medium"
        type="submit"
        ml={1}
        formAction={getPreviewRoute(onPreviewUrl)}
      >
        <LinkText>{t('action')}</LinkText>
      </Button>
      <BoothPreviewModal
        vendor={vendor}
        isModalDisplayed={isModalDisplayed}
        onCloseHandler={onClose}
        flags={flags}
      />
    </SaveBar>
  );
};

SaveAndPreviewBar.propTypes = {
  showPreview: string,
  vendor: shape({
    name: string,
    headline: string,
    about: string,
    buttonText: string,
    logoUrl: string,
    facebook: string,
    instagram: string,
    linkedin: string,
    twitter: string,
    website: string,
  }),
  onPreviewUrl: string,
  flags: object,
};

export default SaveAndPreviewBar;
