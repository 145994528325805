import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  BannerPreview,
  IconContainer,
  OrgLogo,
  SidePanel,
  Wrapper,
} from './styles';

const BasicDetailsPreview = ({ name, startDate, endDate, bannerUrl }) => {
  const { t } = useLocalization(
    'event-creation-wizard.steps.basic-details.preview',
  );
  const { name: orgName, logo: orgLogo } = useSelector(state => state.org);
  const { last_name } = useSelector(state => state.user);
  const { defaultEventImageUrl } = useSelector(state => state.basicDetails);
  const dateFormat = 'MMMM d';

  return (
    <SidePanel>
      <Wrapper data-testid="dynamic-graphic">
        <BannerPreview
          src={bannerUrl || defaultEventImageUrl}
          alt="event banner"
        />
        <Flex my={3}>
          <OrgLogo src={orgLogo} alt="organization logo" />
          <Flex flexDirection="column" ml={2}>
            <Text pattern="subHeadingBold">
              {name || t('event-name-placeholder')}
            </Text>
            <Text pattern="caption" color="grey-400">
              {t('hosted')}{' '}
              {orgName ? orgName : `${last_name} First Organization`}
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <IconContainer>
            <Icon name="calendar-note" color="blue-500" />
          </IconContainer>
          <Flex flexDirection="column" ml={1.5}>
            <Text pattern="body" color="grey-500">
              {t('date')}
            </Text>
            <Text pattern="strong" color="grey-700">
              {' '}
              {format(startDate, dateFormat)} -{' '}
              {format(
                endDate,
                startDate.getMonth() === endDate.getMonth() ? 'd' : dateFormat,
              )}
            </Text>
          </Flex>
        </Flex>
      </Wrapper>
    </SidePanel>
  );
};

BasicDetailsPreview.propTypes = {
  name: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  bannerUrl: PropTypes.string,
};

export default withLocalizationProvider(BasicDetailsPreview);
