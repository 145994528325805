import SearchInput from '@components/search-input';
import { useLocalization } from '@features/localization';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SelectFilter from './filtering/select-filter';
import MagicLinksOperations from './magic-links-operations';
import SelectedCounter from './selected-counter';

const MagicLinksTableHeaderStyles = styled.div`
  margin-top: var(--spacing-32);
`;

const TableActions = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: var(--spacing-40);
`;

const FilterContainer = styled.div`
  margin-left: var(--spacing-32);
  width: 75%;
`;

const MagicLinksTableHeader = ({
  onChange,
  inputValue,
  filterState,
  selectedIds,
  selectAll,
  total,
  send,
  download,
  cancel,
}) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites');
  const operationsActive = (total && selectAll) || selectedIds.length;
  return (
    <MagicLinksTableHeaderStyles>
      <h3>{t('users')}</h3>
      <TableActions>
        {operationsActive ? (
          <>
            <SelectedCounter
              selectedIds={selectedIds}
              selectAll={selectAll}
              total={total}
            />
            <MagicLinksOperations
              selectedFilters={filterState.selectedFilters}
              send={send}
              download={download}
              cancel={cancel}
            />
          </>
        ) : (
          <>
            <SearchInput
              onChange={onChange}
              inputValue={inputValue}
              name="search_text"
              placeholder={t('search-placeholder')}
              testId="search-input"
              ariaLabel={t('search-aria-label')}
            />
            <FilterContainer>
              <SelectFilter
                {...filterState}
                noOptionsText={t('filter.all-added')}
              />
            </FilterContainer>
          </>
        )}
      </TableActions>
    </MagicLinksTableHeaderStyles>
  );
};

MagicLinksTableHeader.propTypes = {
  onChange: func.isRequired,
  inputValue: string.isRequired,
  filterState: object.isRequired,
  selectedIds: arrayOf(number),
  selectAll: bool,
  send: func,
  total: number.isRequired,
  download: func,
  cancel: func,
};

export default MagicLinksTableHeader;
