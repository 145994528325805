import Loading from '@components/loading';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { HOURS_FOR_REPLAY_PROCESSING } from '../constants';
import RecordingGroupTileView from '../tile-view';
import { hasDatePassed } from '../utils/utils';
import { ErrorBanner } from './partials/error-banner';
import { RecordingGroupRelatedLinks } from './partials/recording-group-related-links';
import { RecordingGroupTitle } from './partials/recording-group-title';

export const RecordingGroup = ({
  title,
  backupHref,
  rehearsalHref,
  videoAreaType,
  recordings,
  isError,
  isLoading,
  refetchRecordings,
  onRecordingClick,
  onRecordingUpdate,
  onRecordingDelete,
  onRecordingDownload,
  onRecordingSelect,
  endTime,
  customThumbnailsEnabled,
}) => {
  const { t } = useLocalization('recording-group');

  const hasRelatedLinks = rehearsalHref || backupHref;

  const noResultsTxtString = useMemo(() => {
    const eventTimingString = hasDatePassed(
      endTime,
      HOURS_FOR_REPLAY_PROCESSING,
    )
      ? 'post-event'
      : 'in-event';

    switch (videoAreaType) {
      case 'stage':
        return `no-recordings-available-${eventTimingString}-stage`;
      case 'session':
        return `no-recordings-available-${eventTimingString}-session`;
      default:
        return `no-recordings-available-${eventTimingString}-generic`;
    }
  }, [endTime, videoAreaType]);

  return (
    <>
      {title && (
        <RecordingGroupTitle videoAreaType={videoAreaType} title={title} />
      )}

      {isError ? (
        <ErrorBanner onRetry={refetchRecordings} />
      ) : (
        <Flex flexDirection="column">
          <Loading isLoading={isLoading}>
            {recordings?.length ? (
              <RecordingGroupTileView
                recordings={recordings}
                onRecordingClick={onRecordingClick}
                onRecordingChange={onRecordingUpdate}
                onRecordingDelete={onRecordingDelete}
                onRecordingDownload={onRecordingDownload}
                isShowingDetails={true}
                onRecordingSelect={onRecordingSelect}
                customThumbnailsEnabled={customThumbnailsEnabled}
              />
            ) : (
              <Text mb={2} color="grey-600" scale={4}>
                {t(noResultsTxtString, { videoAreaType })}
              </Text>
            )}
          </Loading>
        </Flex>
      )}
      {hasRelatedLinks && (
        <RecordingGroupRelatedLinks
          title={title}
          backupHref={backupHref}
          rehearsalHref={rehearsalHref}
        />
      )}
    </>
  );
};

RecordingGroup.propTypes = {
  isError: PropTypes.bool,
  backupHref: PropTypes.string,
  rehearsalHref: PropTypes.string,
  isLoading: PropTypes.bool,
  onRecordingClick: PropTypes.func,
  onRecordingUpdate: PropTypes.func.isRequired,
  onRecordingDelete: PropTypes.func.isRequired,
  onRecordingDownload: PropTypes.func,
  recordings: PropTypes.arrayOf(PropTypes.shape).isRequired,
  refetchRecordings: PropTypes.func,
  title: PropTypes.string,
  videoAreaType: PropTypes.oneOf(['stage', 'session']),
  endTime: PropTypes.string,
  onRecordingSelect: PropTypes.func.isRequired,
  customThumbnailsEnabled: PropTypes.bool,
};
