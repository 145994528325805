import { Halon } from '@hopin-team/ui-halon';
import styled from 'styled-components';

const ChecklistItemIcon = styled.div`
  position: relative;

  ${Halon} {
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
  }
`;

export default ChecklistItemIcon;
