import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import { bool, number, shape, string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import DoorsOpenModal from './doors-open-modal';

const LearnMoreButton = styled(Button)`
  display: inline-block;
  padding: 0;
  height: auto;
  width: auto;
  line-height: 1rem;
  font-size: inherit;
  font-weight: inherit;
`;

const DoorsOpenDetails = ({ eventId, organization, supportUrl }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useLocalization();
  const { advancedPlan, growthPlan } = organization;
  const showEmailLink = advancedPlan || growthPlan;
  const duration = t('basic-settings.pre-event.months', { durationAmount: 5 });

  return (
    <Box mb={2}>
      <Text pattern="body" color="grey-600" mt={1} element="p">
        {t('basic-settings.pre-event.details', { duration })}{' '}
        <LearnMoreButton
          isGhost
          size="tiny"
          pattern="secondary"
          onClick={evt => {
            evt.preventDefault();
            setModalOpen(true);
          }}
          data-testid="learn-more-modal-link"
        >
          {t('basic-settings.pre-event.learn-more')}
        </LearnMoreButton>
      </Text>
      <DoorsOpenModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        eventId={eventId}
        showEmailLink={showEmailLink}
        supportUrl={supportUrl}
      />
    </Box>
  );
};

export default withLocalizationProvider(DoorsOpenDetails);

DoorsOpenDetails.propTypes = {
  supportUrl: string,
  eventId: number,
  organization: shape({
    advancedPlan: bool,
    growthPlan: bool,
  }),
};
