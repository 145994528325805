import Loading from '@components/loading';
import AlertsProvider from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteCustomDomain,
  fetchCustomDomain,
  setCustomDomain,
} from '@/redux/reducers/custom-domain';
import withReduxProvider from '@/redux/with-provider';

import CustomDomainSettingsComponent from './custom-domain-settings-component';

const CustomDomainSettingsContainer = ({
  eventId,
  hasEnabledCanvasPage,
  canvasEditorUrl,
}) => {
  const dispatch = useDispatch();

  async function deleteDomain() {
    return await dispatch(deleteCustomDomain(eventId));
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(fetchCustomDomain(eventId));
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, hasEnabledCanvasPage]);

  const { attributes, id, isFetching, isDeleting } = useSelector(
    ({ customDomain }) => customDomain,
  );

  const { started, finished } = useSelector(({ event }) => event);

  const initiallyLoaded = isFetching === false;

  const isPersisted = id !== null;

  const deletable = (!started || finished) && isPersisted;
  const readOnly = started || finished;

  return (
    <AlertsProvider>
      <Loading isLoading={!initiallyLoaded}>
        {initiallyLoaded && (
          <CustomDomainSettingsComponent
            canvasPageSetupUrl={canvasEditorUrl}
            readOnly={readOnly}
            deletable={deletable}
            isDeleting={isDeleting}
            deleteDomain={deleteDomain}
            setCustomDomain={data => {
              dispatch(setCustomDomain(data));
            }}
            hasCanvasPage={hasEnabledCanvasPage}
            id={eventId}
            attributes={attributes}
          />
        )}
      </Loading>
    </AlertsProvider>
  );
};

CustomDomainSettingsContainer.propTypes = {
  eventId: string.isRequired,
  hasEnabledCanvasPage: bool,
  canvasEditorUrl: string.isRequired,
};

export default compose(
  withThemeProvider,
  withReduxProvider,
)(CustomDomainSettingsContainer);
