import { LAYOUT } from '@hopin-team/ui-symbols';
import styled, { css } from 'styled-components';

function makeColumns({ columns }) {
  if (!columns) {
    return '';
  }
  if (typeof columns === 'number') {
    return css`
      grid-template-columns: repeat(${columns}, 1fr);
    `;
  }
  if (Array.isArray(columns)) {
    const [initial, breakpoints = {}] = columns;
    const entries = Object.entries(breakpoints)
      .sort(([bp1], [bp2]) => {
        const value1 = LAYOUT[bp1] || bp1;
        const value2 = LAYOUT[bp2] || bp2;
        return parseInt(value1, 10) - parseInt(value2, 10);
      })
      .map(([bp, bpColumns]) => [LAYOUT[bp] || bp, bpColumns]);

    return css`
      ${initial ? `grid-template-columns: repeat(${initial}, 1fr);` : ''}
      ${entries.reduce(
        (acc, [breakpoint, bpColumns]) =>
          /\d+px/.test(breakpoint)
            ? `${acc}
              @media (min-width: ${breakpoint}) {
                grid-template-columns: repeat(${bpColumns}, 1fr);
              }
            `
            : acc,
        '',
      )}
    `;
  }
}

const GridLayout = styled.div`
  display: grid;
  grid-gap: 1rem;
  ${makeColumns};
`;

export default GridLayout;
