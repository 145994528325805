import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import { MenuItem } from '../styled/menu-item';
import { TemplateCard } from '../styled/template-card';
import { TemplateItemOptions } from './template-item-options';

const TemplateMenuItem = ({
  isBlank,
  isCondensed,
  onClick,
  onPreviewClick,
  onUseClick,
  template = {},
}) => {
  const { image, name } = template;

  return (
    <MenuItem data-testid="template-menu-item">
      <TemplateCard
        $isBlank={isBlank}
        aria-label={`${name} template`}
        onClick={onClick}
        tabIndex={isBlank && '0'}
      >
        {isBlank ? (
          <Icon name="plus" color="blue-500" scale={2.5} />
        ) : (
          <>
            <img src={image} alt="" />
            <TemplateItemOptions
              isCondensed={isCondensed}
              onPreviewClick={onPreviewClick}
              onUseClick={onUseClick}
              template={template}
            />
          </>
        )}
      </TemplateCard>
      <Text element="p" pattern="body" font="text" weight="bold" mt={1}>
        {name}
      </Text>
    </MenuItem>
  );
};

TemplateMenuItem.propTypes = {
  isBlank: bool,
  isCondensed: bool,
  onClick: func,
  onPreviewClick: func,
  onUseClick: func,
  template: shape({
    image: string,
    name: string.isRequired,
    description: string,
  }),
};

export default TemplateMenuItem;
