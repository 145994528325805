import Dropzone from '@components/dropzone';
import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import { Section } from '../../section';

const CSVSelect = ({ error, onChange, linesLimitCsvUploadEnabled }) => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');

  return (
    <Section title={t('edit-and-select-header')}>
      <Text element="p" pattern="labelThree" color="grey-600" mb={1}>
        {t('edit-and-select-summary')}
      </Text>
      <div>
        <Dropzone name="csv_file" onDrop={onChange}>
          <div>
            <Text color="blue-500" pattern="labelTwo">
              {t('choose-file')}
            </Text>{' '}
            <Text color="grey-800" pattern="labelThree">
              {t('or-drag-here')}
            </Text>
          </div>
          <Text
            color="grey-600"
            element="p"
            mb={0.5}
            mt={1.25}
            pattern="labelThree"
          >
            {t('max-size')}
          </Text>
          {linesLimitCsvUploadEnabled && (
            <Text
              color="red-300"
              element="p"
              mb={0.5}
              mt={1.25}
              pattern="labelThree"
            >
              {t('max-lines')}
            </Text>
          )}
        </Dropzone>
        {error && (
          <span data-testid="csv-error" className="text-danger labelError">
            {error.message}
          </span>
        )}
      </div>
    </Section>
  );
};

CSVSelect.propTypes = {
  error: shape({
    message: string,
  }),
  onChange: func.isRequired,
  linesLimitCsvUploadEnabled: bool.isRequired,
};

export default CSVSelect;
