import { Icon } from '@hopin-team/ui-icon';
import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
`;

export const RangePickerWrapper = styled.label`
  .input {
    opacity: 0;
  }

  .nice-dates-popover {
    width: 25rem;
    position: absolute;
    right: 0;
    top: 3rem;
  }

  .input {
    position: absolute;
  }
`;

export const RangeSelect = styled.div`
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--color-gray-300);
  min-height: 3rem;
  padding: 0 1.5rem;
  cursor: default;
  font-size: var(--font-size);
`;

export const RangePickerDisplayText = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RangePickerIcon = styled(Icon)`
  margin-left: 1rem;
`;
