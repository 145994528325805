import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const BackgroundPatternContainer = styled.div`
  height: 100%;
  max-width: 66.67%;
  position: absolute;
  top: 0;
  right: 0;
`;

const BackgroundPattern = () => (
  <svg viewBox="0 0 491 826" style={{ height: '100%' }}>
    <path
      d="M223.655 -46.8866C376.811 -223.728 703.737 -370.012 898.649 -184.736C1021.95 -67.6689 1033.83 134.12 926.492 267.017L924.44 269.378C758.22 483.951 692.91 755.192 724.878 1013.83C749.114 1207.91 827.832 1395.25 959.776 1546.28C728.64 1565.13 491.083 1487.72 303.26 1309.3C59.1579 1078.16 -49.9918 712.189 22.8578 370.567C55.8163 213.459 125.375 65.9507 223.655 -46.8866Z"
      fill="#F1F2F5"
    />
  </svg>
);

const ImageContainer = styled.div`
  position: relative;
  right: 0;
  width: 100%;
  height: 100%;
  padding: ${props => (props.padding ? rem(props.padding) : 0)};
  display: flex;
  justify-content: ${props => props.horizontalAlign};
  align-items: ${props => props.verticalAlign};
`;

export { BackgroundPatternContainer, BackgroundPattern, ImageContainer };
