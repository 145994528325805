import 'chart.js';

import {
  getTickBounds,
  getTimeOptions,
  normalizeStartEnd,
} from '@features/dashboard/util/chartjs-time-helpers';
import { graphColor60 } from '@features/dashboard/util/colors';
import { useLocalization } from '@features/localization';
import useMomentLocale from '@util/hooks/use-moment-locale';
import PropTypes from 'prop-types';
import React from 'react';
import { LineChart } from 'react-chartkick';

const defaultChartHeight = 248;

const DataOverTimeLineChart = ({
  startTime,
  endTime,
  chartData,
  unit,
  stepSize,
  height,
}) => {
  const { dateLocale } = useLocalization();
  const { startMoment, endMoment } = normalizeStartEnd(
    startTime,
    endTime,
    chartData,
  );
  const timeOptions = getTimeOptions(startMoment, endMoment, unit, stepSize);
  const { minTick, maxTick } = getTickBounds(
    startMoment,
    endMoment,
    timeOptions,
  );

  // load chart translations
  useMomentLocale(dateLocale.code);

  // This is a workaround for the fact that Chart.js ignores `type: 'time'` on the Axes options
  // and assumes linear data when data is empty, so the ticks.min and ticks.max don't
  // get interpreted as timestamps.
  //
  // Adding a timestamp before ticks.min allows Chart.js to infer `type: 'time'` for the X Axis while
  // not showing the dummy data point.
  const dataWithWorkaroundForEmpty =
    chartData && Object.keys(chartData).length > 0
      ? chartData
      : [['1970-01-02 00:00', 0]];
  return (
    <LineChart
      library={{
        scales: {
          xAxes: [
            {
              time: timeOptions,
              ticks: {
                min: minTick,
                max: maxTick,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 1,
              },
            },
          ],
        },
      }}
      data={dataWithWorkaroundForEmpty}
      curve={false}
      colors={[graphColor60]}
      height={`${height || defaultChartHeight}px`}
    />
  );
};

DataOverTimeLineChart.defaultProps = {
  stepSize: 1,
};

DataOverTimeLineChart.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  chartData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  unit: PropTypes.string,
  stepSize: PropTypes.number,
  height: PropTypes.number,
};

export default DataOverTimeLineChart;
