import hopinApi from '@api/hopin';
import Loading from '@components/loading';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { numberFormatter } from '@features/email/shared';
import {
  useLocalization,
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import * as Routes from '@routes';
import { compose } from 'lodash/fp';
import { arrayOf, number, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import AnnouncementsContainer from '../announcements-container';
import { AudienceSelection } from '../announcements-preview/audience-selection';
import AnnouncementsTopBar from '../announcements-top-bar';
import { AnnouncementsText, ContainerWrapper, LeftPanel } from '../styles';
import { BackLink, CenterPanelGrid, EmailDetails, PreviewBody } from './styles';

const AnnouncementsReview = ({ event, announcementEmailTemplateId }) => {
  const { t } = useLocalization('email-dashboard');

  const { organiser_id: organiserId, id: eventId, slug: eventSlug } = event;
  const [announcement, setAnnouncement] = useState(null);
  const [selectedAttendeeStatus, setAttendeeStatus] = useState(null);
  const [selectedTicketTypes, setTicketTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { addAlert } = useContext(AlertsContext);
  const [availableTicketTypes, setAvailableTicketTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { token } = await hopinApi.getUserToken();

        const {
          metadata,
          ...announcementData
        } = await hopinApi.getAnnouncement(
          token,
          organiserId,
          eventId,
          announcementEmailTemplateId,
        );

        const { personas } = await hopinApi.getTicketsWithCount(token, eventId);
        setAvailableTicketTypes(personas);
        setAnnouncement({
          ...announcementData,
          recipientsCount: metadata?.audience_selection_data?.recipients_count,
          eventAudienceCount:
            metadata?.audience_selection_data?.event_audience_count,
        });
        setAttendeeStatus(announcementData.audience_selection_attendee_status);
        setTicketTypes(announcementData.audience_selection_ticket_types);

        setIsLoading(false);
      } catch (e) {
        addAlert({
          active: true,
          text: t(`list.errors.default`),
          pattern: 'error',
        });
      }
    };

    fetchData();
  }, [
    addAlert,
    announcementEmailTemplateId,
    eventId,
    organiserId,
    setAnnouncement,
    setIsLoading,
    t,
  ]);

  return (
    <ContainerWrapper data-testid="announcements-review">
      <AnnouncementsTopBar event={event} pageBreadcrumb={t('review')} />
      <Alerts />
      <Loading isLoading={isLoading}>
        {!isLoading && (
          <AnnouncementsContainer>
            <LeftPanel>
              <BackLink
                href={Routes.organisersEventAnnouncementsPath(eventSlug)}
                data-testid="back-to-list-link"
                aria-labelledby="Back"
                name="arrow-back"
                color="grey-600"
                scale={2}
              />
            </LeftPanel>
            <CenterPanelGrid>
              <AnnouncementsText>
                {t(`previewAndReviewPage.backToList`)}
              </AnnouncementsText>
              <EmailDetails>
                <AudienceSelection
                  status={selectedAttendeeStatus}
                  ticketTypes={selectedTicketTypes}
                  hasTicketTypes={!!availableTicketTypes.length}
                ></AudienceSelection>
                <AnnouncementsText
                  weight={400}
                  my={0.5}
                  data-testid="email-subject"
                >
                  {t(`previewAndReviewPage.emailSubject`)}
                  {`: ${announcement?.subject}`}
                </AnnouncementsText>
                <span
                  style={{
                    fontSize: '0.75rem',
                    color: 'var(--color-gray-700)',
                  }}
                  data-testid="email-audience-count"
                >
                  {t('reviewPage.this-email')}{' '}
                  <b>{numberFormatter(announcement.recipientsCount)}</b>{' '}
                  {t(`previewAndReviewPage.of`)}{' '}
                  <b>{numberFormatter(announcement.eventAudienceCount)}</b>{' '}
                  {t(`previewAndReviewPage.attendees`)}.
                </span>
              </EmailDetails>
              <PreviewBody
                data-testid="email-body"
                dangerouslySetInnerHTML={{
                  __html: announcement?.body,
                }}
              />
            </CenterPanelGrid>
          </AnnouncementsContainer>
        )}
      </Loading>
    </ContainerWrapper>
  );
};

AnnouncementsReview.propTypes = {
  event: shape({
    name: string.isRequired,
    picture: string.isRequired,
    slug: string.isRequired,
    registration_count: number.isRequired,
  }).isRequired,
  announcementEmailTemplateId: string.isRequired,
  initialAttendeesList: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    }).isRequired,
  ),
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
  withLocalization,
)(AnnouncementsReview);
