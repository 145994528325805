import analyticsApi from '@api/analytics';
import Card from '@components/card-with-loading';
import { number } from 'prop-types';
import React, { useState } from 'react';

import cleanCountryCountData from '../util/clean-country-count-data';
import { usePromise } from '../util/hooks';
import RegistrationsByCountryComponent from './registrations-by-country-component';

const RegistrationsByCountryContainer = ({ eventId }) => {
  const [countryData, setCountryData] = useState([]);

  const loading = usePromise(
    signal => analyticsApi.getRegistrationsByCountry(eventId, signal),
    result => setCountryData(cleanCountryCountData(result.data)),
  );

  return (
    <Card isLoading={loading}>
      <RegistrationsByCountryComponent countryData={countryData} />
    </Card>
  );
};

RegistrationsByCountryContainer.propTypes = {
  eventId: number.isRequired,
};

export default RegistrationsByCountryContainer;
