import { Card } from '@hopin-team/ui-card';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  border: 1px solid #f1f2f5;
  box-shadow: 0px 1px 2px rgba(44, 50, 66, 0.08);
  height: 100%;
`;

const OverviewCard = ({ testId, ...props }) => {
  return <StyledCard data-testid={testId} p={3} {...props} />;
};

OverviewCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default OverviewCard;
