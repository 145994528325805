import { Text } from '@hopin-team/ui-text';
import { node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Root = styled.section`
  padding: 0;
  margin-bottom: 1.5rem;
`;

export const Section = ({ title, children }) => (
  <Root>
    <header>
      <Text element="h2" mb={1} pattern="headingFour">
        {title}
      </Text>
    </header>
    {children}
  </Root>
);

Section.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
};
