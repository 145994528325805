import AppStoreBanner from '@features/integration/assets/app-store-banner.png';
import { useBannerEvents } from '@features/integration/banner/use-banner-events';
import { getAppStoreWebUrl } from '@features/integration/helpers/get-app-store-web-url';
import { getEventAreaParameter } from '@features/integration/helpers/get-event-area-parameter';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Image as HopinImage } from '@hopin-team/ui-image';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import { bool, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const S = {
  Title: styled(Text)`
    font-size: ${rem(20)};
  `,
  Container: styled(Flex)`
    height: ${rem(176)};
    overflow: hidden;
  `,
  LeftWrap: styled(Flex)`
    padding-left: ${rem(24)};
  `,
  Link: styled.a`
    font-weight: 600;
    font-size: 14px;
    color: #1d6eaa;
  `,
  Image: styled(HopinImage)`
    width: auto;
    height: 100%;
  `,
};

const Banner = ({ isVisible = false, organizationId, userId, venue }) => {
  const { clickBanner } = useBannerEvents({
    isVisible,
    userId,
    organizationId,
  });
  const { t } = useLocalization('integrations');

  const appStoreUrl = getAppStoreWebUrl({
    organizationId,
    eventAreaParameter: getEventAreaParameter({ venue }),
  });

  if (!isVisible) return null;

  return (
    <S.Container
      justifyContent="space-between"
      alignItems="center"
      data-testid="app-store-banner"
      cornerRadius="md"
      background="blue-100"
    >
      <S.LeftWrap flexDirection="column">
        <Flex mb={1} flexDirection="column">
          <S.Title
            pattern="pageHeading"
            color="grey-900"
            weight="regular"
            mb={1}
          >
            {t('banner.title')}
          </S.Title>
          <Text color="grey-600" pattern="body">
            {t('banner.description')}
          </Text>
        </Flex>
        <span>
          <S.Link
            data-testid="app-store-link"
            onClick={clickBanner}
            href={appStoreUrl}
            target="_blank"
            rel="noopener"
            scale={4}
          >
            {t('banner.link')}
          </S.Link>
        </span>
      </S.LeftWrap>
      <S.Image src={AppStoreBanner} alt="App Store Banner" />
    </S.Container>
  );
};

Banner.propTypes = {
  isVisible: bool,
  canonicalUrl: string,
  fallbackCanonicalUrl: string,
  environment: string,
  fallbackEnvironment: string,
  // Organization ID could be required, but there's the case where vendors configure venues
  // and this type of user does not belong to any organization, and hence have no ID
  organizationId: string,
  userId: string,
  venue: string,
};

export default withLocalizationProvider(Banner);
