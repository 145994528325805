import { LocalizationContext } from '@features/localization';
import { bool, func } from 'prop-types';
import React, { useContext } from 'react';

import { ConfirmModal } from './confirm-modal';

const CancelScheduleConfirmationModal = ({
  isModalDisplayed,
  handleClose,
  confirmCancel,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ConfirmModal
      label="Cancel Schedule Modal"
      title={t(`email-dashboard.cancel-schedule.confirmation`)}
      message={t(`email-dashboard.cancel-schedule.description`)}
      open={isModalDisplayed}
      onCancel={handleClose}
      onConfirm={confirmCancel}
      cancelButtonProps={{
        'data-testid': 'cancel-button',
      }}
      confirmButtonProps={{
        'data-testid': 'cancel-schedule-email-button-confirm',
        label: t(`email-dashboard.cancel-schedule.confirm`),
      }}
    />
  );
};

CancelScheduleConfirmationModal.propTypes = {
  handleClose: func.isRequired,
  isModalDisplayed: bool.isRequired,
  confirmCancel: func.isRequired,
};

export default CancelScheduleConfirmationModal;
