import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';

import { ItemOptions } from '../styled/item-options';

const TemplateItemOptions = ({
  isCondensed,
  onUseClick,
  onPreviewClick,
  template,
}) => {
  const { t } = useLocalization('templates');
  const { t: tRoot } = useLocalization();
  const { name, description } = template;

  return (
    <ItemOptions isCondensed={isCondensed}>
      {!isCondensed && (
        <>
          <Text element="h3" pattern="headingFour">
            {name}
          </Text>
          <Text element="p" scale={3} mt={1}>
            {description}
          </Text>
        </>
      )}
      <Flex
        alignItems="center"
        mt={isCondensed ? 0 : 'auto'}
        flexDirection={isCondensed ? 'column' : 'row'}
      >
        <Button
          aria-label={t('accessiblePreviewTemplate', { templateName: name })}
          isInline={!isCondensed}
          onClick={onPreviewClick}
          size="small"
          type="button"
        >
          {tRoot('common.preview')}
        </Button>
        <Button
          aria-label={t('accessibleUseTemplate', { templateName: name })}
          isInline={!isCondensed}
          isOutlined
          mt={isCondensed ? 1.5 : 0}
          ml={isCondensed ? 0 : 1}
          onClick={onUseClick}
          pattern="secondary"
          size="small"
          type="button"
        >
          {t('useTemplate')}
        </Button>
      </Flex>
    </ItemOptions>
  );
};

TemplateItemOptions.propTypes = {
  isCondensed: bool,
  onUseClick: func,
  onPreviewClick: func,
  template: shape({
    name: string.isRequired,
    description: string.isRequired,
  }),
};

export { TemplateItemOptions };
