import { TagGroup } from '@hopin-team/ui-add-tag';
import { Flex } from '@hopin-team/ui-flex';
import { Overlay } from '@hopin-team/ui-overlay';
import { ProgressBar } from '@hopin-team/ui-progress-bar';
import { DeprecatedSelect, Select } from '@hopin-team/ui-select';
import { calcSpace, COLOR } from '@hopin-team/ui-symbols';
import { TextField } from '@hopin-team/ui-text-field';
import { rem } from 'polished';
import styled from 'styled-components';

import { gray800 } from '../../dashboard/util/colors';

export const SingleBlock = styled(Flex)`
  width: 50%;
  padding-right: ${rem(12)};
`;

export const DoubleBlock = styled(Flex)`
  gap: ${rem(24)};
  ${Flex} {
    width: 100%;
  }
`;

export const NumberInput = styled(TextField)`
  div:first-of-type {
    margin-bottom: ${rem(4)};
  }
  input {
    height: ${rem(32)};
    padding-right: ${rem(8.5)};
  }
  label {
    color: ${props => (props.disabled ? '#bdbfc7' : gray800)};
    line-height: ${rem(16)};
  }
`;

export const StyledTagsGroup = styled(TagGroup)`
  padding: ${rem(8)} 0;
  span {
    height: ${rem(24)};
    line-height: ${rem(24)};
    background-color: #ecf2ff;
    border-radius: ${rem(3)};
    color: #1d6eaa;
    border: 0px;
  }
  button {
    color: #1d6eaa;
  }
  button:hover {
    background-color: #ecf2ff;
    color: #1048c5;
  }
`;

export const StyledCombobox = styled(DeprecatedSelect)`
  margin-top: ${rem(8)};

  flex: 1 1 auto;

  & .segment-select__control {
    min-height: ${calcSpace(4)};
  }

  & .segment-select__value-container {
    padding: ${calcSpace(0.5)} ${calcSpace(1)} ${calcSpace(0.5)} ${calcSpace(2)};

    > * {
      margin: 0;
      padding: 0;
    }
  }

  & .segment-select__indicators {
    padding-right: ${calcSpace(1.5)};
  }

  & .segment-select__indicator {
    padding: ${calcSpace(0.75)};
  }

  & .segment-select__multi-value {
    margin-right: ${rem(8)};
    margin-bottom: ${rem(1)};
    margin-top: ${rem(1)};
  }

  & .segment-select__placeholder {
    font-size: ${rem(12)};
  }
`;

export const StyledSelect = styled(Select)`
  label {
    color: ${props => (props.disabled ? '#bdbfc7' : gray800)};
  }
  svg {
    color: ${props => (props.disabled ? '#bdbfc7' : '#696e7c')};
  }
`;

export const StyledProgressBar = styled(ProgressBar)`
  --color-progress-bar: ${COLOR.BLUE['500']};
  --color-progress-track: ${COLOR.GREY['200']};
  margin-top: ${calcSpace(2)};
`;

export const StyledOverlay = styled(Overlay)`
  z-index: 3;
  /* render overlay for the entire page height */
  height: 100%;
  > div {
    /* postion modal in the middle of viewport */
    height: 100vh;
  }
`;
