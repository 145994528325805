import { useLocalization } from '@features/localization';
import { CalloutCard } from '@features/post-registration/components/path-selection/call-out-card';
import { Text } from '@hopin-team/ui-text';
import { bool, string } from 'prop-types';
import React from 'react';

import { Container } from '../../styles';

const PostRegistrationApp = ({ userExternalId, isTestUser }) => {
  const { t } = useLocalization('post-registration.path-selection');
  return (
    <Container p={8} flexDirection="column" m="auto">
      <Text pattern="subHeadingBold" color="grey-800">
        {t('title')}
      </Text>
      <CalloutCard
        title={t('create-event')}
        subtitle={t('create-event-subtitle')}
        cta={t('create-event-cta')}
        url="/create-event-wizard"
        image="create"
        type="create_event"
        userExternalId={userExternalId}
        isTestUser={isTestUser}
      />
      <CalloutCard
        title={t('learn-more')}
        subtitle={t('learn-more-subtitle')}
        cta={t('learn-more-cta')}
        url="https://education.hopin.com/"
        image="learn"
        type="learn_more"
        userExternalId={userExternalId}
        isTestUser={isTestUser}
      />
    </Container>
  );
};

PostRegistrationApp.propTypes = {
  userExternalId: string.isRequired,
  isTestUser: bool.isRequired,
};

export default PostRegistrationApp;
