import request from '@util/request';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useList = (eventTracks, listUrl, field) => {
  const [requestStatus, setRequestStatus] = useState('IDLE');
  const [eventTrack, setEventTrack] = useState(null);
  const [ticketType, setTicketType] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataUrl, setDataUrl] = useState(listUrl);
  const [currentPage, setCurrentPage] = useState(1);
  const [response, setResponse] = useState(null);

  const hasActiveFilters = Boolean(eventTrack || ticketType || searchQuery);
  const attendees = response ? response[field] : [];
  const totalPages = response?.pagy?.total_pages || 1;
  const attendeesCount = response?.pagy?.count || 0;

  const fetchData = useCallback(async () => {
    setRequestStatus('RUNNING');
    try {
      const data = await request.get(dataUrl);
      setResponse(data);
    } catch (e) {
      setResponse(null);
    }

    setRequestStatus('COMPLETED');
  }, [dataUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const reload = useCallback(
    (eventTrack, ticketType, searchQuery, currentPage) => {
      const searchParams = new URLSearchParams();

      if (eventTrack) {
        let ticketTypes = eventTracks.find(
          et => eventTrack === parseInt(et.value, 10),
        ).ticketTypeIds;
        if (ticketTypes.length) {
          ticketTypes.forEach(value =>
            searchParams.append('ticket_type_ids[]', value),
          );
        } else {
          searchParams.append('ticket_type_ids[]', '');
        }
      }

      if (ticketType) {
        searchParams.append('ticket_type_ids[]', ticketType);
      }

      if (searchQuery !== '') {
        searchParams.append('query_string', searchQuery);
      }

      searchParams.append('page', currentPage);

      setDataUrl(listUrl + '?' + searchParams.toString());
    },
    [eventTracks, listUrl],
  );
  const debouncedReload = useDebouncedCallback(reload, 500);

  const onEventTrackChange = selected => {
    setTicketType(null);
    setEventTrack(selected);
    setCurrentPage(1);
    const selectedValue = selected?.value;
    reload(selectedValue, 0, searchQuery, 1);
  };

  const onTicketTypeChange = selected => {
    setEventTrack(null);
    setTicketType(selected);
    setCurrentPage(1);
    const selectedValue = selected?.value;
    reload(0, selectedValue, searchQuery, 1);
  };

  const onQueryChange = e => {
    e.persist();
    setRequestStatus('IDLE');
    setSearchQuery(e.target.value);
    setCurrentPage(1);
    debouncedReload.callback(
      eventTrack?.value,
      ticketType?.value,
      e.target.value,
      1,
    );
  };

  const onPageChange = (page, forceReload) => {
    setCurrentPage(page);
    if (forceReload) {
      fetchData();
      return;
    }
    reload(eventTrack?.value, ticketType?.value, searchQuery, page);
  };

  return {
    onPageChange,
    onQueryChange,
    onTicketTypeChange,
    onEventTrackChange,
    searchQuery,
    requestStatus,
    totalPages,
    currentPage,
    hasActiveFilters,
    attendeesCount,
    attendees,
    ticketType,
    eventTrack,
  };
};
