import { Box } from '@hopin-team/ui-box';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

import { ItemOptions } from './item-options';
import { TemplateCard } from './template-card';

export const MenuItem = styled(Box)`
  width: 100%;

  ${TemplateCard} {
    &:hover,
    &:focus-within {
      border: ${({ theme }) => getFromTheme('LINE.DEFAULT_BORDER', theme)};
      border-width: ${calcSpace(0.25)};
      border-color: ${({ theme }) => getFromTheme('COLOR.BLUE[100]', theme)};
      background-color: ${({ theme }) =>
        getFromTheme('COLOR.GREY.WHITE', theme)};

      ${ItemOptions} {
        opacity: 1;
        pointer-events: all;
      }

      img {
        opacity: 0;
      }
    }

    ${ItemOptions} {
      position: absolute;
      left: ${calcSpace(2)};
      opacity: 0;
      pointer-events: none;
    }
  }
`;
