import hopinApi from '@api/hopin';
import {
  AuthenticationContext,
  AuthenticationProvider,
} from '@hopin-team/provider-authentication';
import getDisplayName from '@util/get-display-name';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectToken } from './auth-selectors';
import { setToken } from './auth-slice';

const AuthReduxProvider = ({ children }) => {
  const { isLoaded, hasError, authenticationToken } = useContext(
    AuthenticationContext,
  );
  const dispatch = useDispatch();
  const tokenFromStore = useSelector(selectToken);

  useEffect(() => {
    if (isLoaded && !hasError) {
      dispatch(setToken(authenticationToken));
    }
  }, [authenticationToken, isLoaded, hasError, dispatch]);

  return tokenFromStore ? children : null; // todo: show spinner before is loaded. maybe provider has more options?
};

AuthReduxProvider.propTypes = {
  children: PropTypes.element,
};

export const withAuthProvider = WrappedComponent => {
  const WithRootPropsProvider = props => {
    return (
      <AuthenticationProvider tokenProvider={hopinApi.getUserToken}>
        <AuthReduxProvider>
          <WrappedComponent {...props} />
        </AuthReduxProvider>
      </AuthenticationProvider>
    );
  };

  WithRootPropsProvider.displayName = `WithRootPropsProvider(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithRootPropsProvider;
};

export default AuthReduxProvider;
