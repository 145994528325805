import { Flex } from '@hopin-team/ui-flex';
import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

Styles.PillsFlex = styled(Flex)`
  flex-wrap: wrap;
  & > * {
    margin-bottom: ${rem(16)};
  }
`;
