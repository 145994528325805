import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import CheckCircle from 'icons/check-circle.svg';
import CopyAlt from 'icons/copy-alt.svg';
import { rem } from 'polished';
import { bool, func, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const CopyAltIcon = styled(CopyAlt)`
  margin-right: ${rem(4)};

  path {
    fill: var(--color-blue-400);
  }
`;

const CheckCircleIcon = styled(CheckCircle)`
  margin-right: ${rem(4)};

  path {
    fill: var(--color-green-600);
  }
`;

const CopyButton = styled.button`
  background-color: none;
  border: none;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  color: var(
    ${props => (props.success ? '--color-green-600' : '--color-blue-400')}
  );
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
  `};
`;

const redeemStates = {
  idle: {
    translationKey: 'people.attendees.attendees-tab.details.actions.copy-link',
    icon: CopyAltIcon,
  },
  copying: {
    translationKey:
      'people.attendees.attendees-tab.details.actions.copying-link',
    icon: CopyAltIcon,
  },
  copied: {
    translationKey:
      'people.attendees.attendees-tab.details.actions.link-copied',
    icon: CheckCircleIcon,
  },
};

const copyToClipboard = value => {
  // Safari <= 12.0
  if (!navigator?.clipboard) {
    const textArea = document.createElement('textarea');
    textArea.value = value;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);
  } else {
    navigator.clipboard.writeText(value);
  }
};

const logger = getLogger(LOGGER_NAMES.PEOPLE);

export const CopyMagicLink = ({
  eventSlug,
  redeemCode,
  t,
  showDisclaimer,
  onCopy,
}) => {
  const [state, setState] = useState('idle');
  const { addAlert } = useContext(AlertsContext);

  const fetchMagicLink = async () => {
    const response = await hopinApi.getMagicLink({
      eventSlug,
      redeemCodeExternalId: redeemCode,
    });

    copyToClipboard(response.magic_link);
  };

  const handleCopy = async () => {
    if (state !== 'idle') {
      return;
    }

    setState('copying');

    if (!showDisclaimer) {
      onCopy();
    }

    try {
      await fetchMagicLink();
      setState('copied');
    } catch (error) {
      logger.error(error);
      addAlert({
        active: true,
        text: t('people.attendees.attendees-tab.details.actions.generic-error'),
        pattern: 'error',
      });
    } finally {
      setTimeout(() => {
        setState('idle');
      }, 800);
    }
  };

  const Icon = redeemStates[state].icon;

  return (
    <span role="presentation" data-testid="copy-link-wrapper">
      <Flex>
        <ToolTip
          hideTip={showDisclaimer}
          tip={
            <TooltipContent
              text={t(
                'people.attendees.attendees-tab.details.magic-link-disclaimer',
              )}
            />
          }
          align="top"
        >
          <CopyButton
            onClick={handleCopy}
            success={state === 'copied'}
            disabled={state !== 'idle'}
          >
            <Icon />
            {t(redeemStates[state].translationKey)}
          </CopyButton>
        </ToolTip>
      </Flex>
    </span>
  );
};

CopyMagicLink.propTypes = {
  eventSlug: string.isRequired,
  onCopy: func.isRequired,
  redeemCode: string.isRequired,
  showDisclaimer: bool.isRequired,
  t: func.isRequired,
};

const StyledTip = styled.div`
  max-width: ${rem(212)};
  text-align: center;
`;

export const TooltipContent = ({ text }) => {
  return (
    <StyledTip>
      <Text pattern="caption" element="span" color="grey-white">
        {text}
      </Text>
    </StyledTip>
  );
};

TooltipContent.propTypes = {
  text: string.isRequired,
};
