import createViewContext from '@util/create-view-context';

// A context singleton for use in a single view

const {
  connectViewData,
  ViewContext,
  withViewDataProvider,
} = createViewContext();

ViewContext.displayName = 'ViewContext';

export { connectViewData, ViewContext, withViewDataProvider };
