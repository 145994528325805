import { useLocalization } from '@features/localization';
import { Badge } from '@hopin-team/ui-badge';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { OnlineBannerTitle } from '../../index.styled';

const AdditionalBadge = ({ isDraft, isLive, isEnded, t }) => {
  if (isDraft) {
    return <Badge mr={1} label="Draft" priority="low" colorPattern="warning" />;
  } else if (isLive) {
    return (
      <Badge mr={1} label={t('live')} priority="low" colorPattern="success" />
    );
  } else if (isEnded) {
    return (
      <Badge mr={1} label={t('ended')} priority="low" colorPattern="inactive" />
    );
  } else {
    return (
      <Badge
        mr={1}
        label={t('upcoming')}
        priority="low"
        colorPattern="informational"
      />
    );
  }
};

const Header = ({ event, isLive, isEnded, isDraft, startDate }) => {
  const { t } = useLocalization('overview.onsite-banner');

  return (
    <Flex flexDirection="column" mb={3}>
      <Flex mb={1} alignItems="center">
        <OnlineBannerTitle mr={2} pattern="subHeadingMedium">
          {event.name}
        </OnlineBannerTitle>
        <Badge
          mr={1}
          label={t('onsite')}
          colorPattern="informational"
          priority="low"
        />
        <AdditionalBadge
          isDraft={isDraft}
          isLive={isLive}
          isEnded={isEnded}
          t={t}
        />
      </Flex>
      <Text color="grey-600" pattern="body">
        {format(startDate, 'eee, MMM d, hh:mm a')}
      </Text>
    </Flex>
  );
};

Header.propTypes = {
  event: PropTypes.object,
  isUpcoming: PropTypes.bool,
  isEnded: PropTypes.bool,
  isLive: PropTypes.bool,
  isDraft: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
};

export default Header;
