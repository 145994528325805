import { connectViewData } from '@util/page-view-context';

import SelectTimezonesComponent from './select-timezones-component';

const mapViewDataToProps = ({ event, timezones }) => ({
  eventTimezone: event.timezone,
  errors: event.errors.timezone,
  timezones,
});

export default connectViewData(mapViewDataToProps)(SelectTimezonesComponent);
