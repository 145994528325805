import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { withSpace } from '@hopin-team/ui-helper-with-space';
import compose from 'lodash/fp/compose';
import { bool, func, oneOf, string } from 'prop-types';
import React from 'react';

import { UploadModal } from './upload-modal';

const CSVUpload = ({
  authenticityToken,
  eventSlug,
  buttonText,
  size = 'medium',
  isShowing,
  toggleModal,
  canInvite,
  ...spacingProps
}) => {
  const { t } = useLocalization('expo.bulk-upload-modal');

  return (
    <React.Fragment>
      <Button
        isInline
        isOutlined
        size={size}
        onClick={toggleModal}
        leadingIcon="publish"
        {...spacingProps}
      >
        {buttonText ?? t('import-via-csv')}
      </Button>

      {isShowing && (
        <UploadModal
          eventSlug={eventSlug}
          authenticityToken={authenticityToken}
          canInvite={canInvite}
          closeModal={toggleModal}
        />
      )}
    </React.Fragment>
  );
};

CSVUpload.propTypes = {
  eventSlug: string.isRequired,
  authenticityToken: string.isRequired,
  buttonText: string,
  size: oneOf(['small', 'medium']),
  isShowing: bool.isRequired,
  toggleModal: func.isRequired,
  canInvite: bool.isRequired,
};

export default compose(withLocalizationProvider, withSpace)(CSVUpload);
