import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { calcSpace } from '@hopin-team/ui-symbols';
import { Table, TableCell, TableHeading, TableRow } from '@hopin-team/ui-table';
import { Toast } from '@hopin-team/ui-toast';
import { rem } from 'polished';
import styled from 'styled-components';

export const CustomTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ theme }) => getFromTheme('COLOR.GREY["100"]', theme)};
`;

export const CustomTableHeading = styled(TableHeading)` 
  position: sticky;
  top: 0;

  ${props => `width: ${props.width};`}
  padding: ${calcSpace(2)} ${calcSpace(3)};

  background-color: ${({ theme }) => getFromTheme('COLOR.GREY["100"]', theme)};
`;

export const CustomTableRow = styled(TableRow)`
  &:last-child {
    padding-bottom: ${calcSpace(2)};
  }
`;

export const CustomTableCell = styled(TableCell)`
  padding: ${calcSpace(1)} ${calcSpace(3)};
`;

export const CustomToast = styled(Toast)`
  box-shadow: none;
`;

export const TableScrollWrapper = styled.div`
  overflow-y: auto;
  max-height: ${rem(368)};
`;
