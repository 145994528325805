import EventFormatCard from '@features/event-creation-wizard/components/event-format-selector/event-format-card';
import { images } from '@features/event-creation-wizard/constants/format-selector';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { bool, func } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const EventFormatSelector = ({ handleNext, isLoading }) => {
  const { isOnFreePlan, isOnStarterPlan } = useSelector(state => state.org);

  const { t } = useLocalization('event-creation-wizard.format-selector');

  return (
    <Flex flexDirection="row" justifyContent="center" flexWrap="wrap" m={1}>
      <EventFormatCard
        enabled={true}
        title={t('online-title')}
        subtitle={t('online-subtitle')}
        images={images.online}
        onClick={() => {
          handleNext('virtual');
        }}
        value="virtual"
        isLoading={isLoading}
      />
      <EventFormatCard
        enabled={!(isOnFreePlan || isOnStarterPlan)}
        title={t('onsite-title')}
        subtitle={t('onsite-subtitle')}
        images={images.onsite}
        onClick={() => handleNext('onsite')}
        value="onsite"
        isLoading={isLoading}
      />
      <EventFormatCard
        enabled={!(isOnFreePlan || isOnStarterPlan)}
        title={t('hybrid-title')}
        subtitle={t('hybrid-subtitle')}
        images={images.hybrid}
        onClick={() => handleNext('hybrid')}
        value="hybrid"
        isLoading={isLoading}
      />
    </Flex>
  );
};

EventFormatSelector.propTypes = {
  handleNext: func.isRequired,
  isLoading: bool.isRequired,
};

export default EventFormatSelector;
