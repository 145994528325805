import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Toggle } from '@hopin-team/ui-toggle';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React from 'react';

const CustomDomainSettingsUserVisible = ({
  disabled,
  userVisible,
  ref,
  onChange,
  t,
  ...restProps
}) => {
  return (
    <Flex alignItems="center" {...restProps}>
      <Toggle
        label={t('event-dashboard.custom-domains.active-for-users')}
        name="userVisible"
        ref={ref}
        mr={1}
        disabled={disabled}
        isToggled={userVisible}
        onChange={(...args) => {
          if (!disabled) {
            onChange(...args);
          }
        }}
        sizeOption="large"
      />
      <ToolTip
        tip={t('event-dashboard.custom-domains.active-for-users-tip')}
        align="bottom"
      >
        <Icon name="alert" color="grey-600" scale={2} />
      </ToolTip>
    </Flex>
  );
};

CustomDomainSettingsUserVisible.propTypes = {
  disabled: PropTypes.bool.isRequired,
  userVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  ref: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default CustomDomainSettingsUserVisible;
