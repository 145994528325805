import React from 'react';

export const PlayIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM7.38965 5.69666C7.62853 5.56579 7.91959 5.57472 8.15 5.72L14 9.36C14.2182 9.49972 14.3501 9.74094 14.3501 10C14.3501 10.2591 14.2182 10.5003 14 10.64L8.15 14.28C7.91959 14.4253 7.62853 14.4342 7.38965 14.3033C7.15076 14.1725 7.00161 13.9224 7 13.65V6.35C7.00161 6.07762 7.15076 5.82752 7.38965 5.69666Z"
      fill="#1d6eaa"
    />
  </svg>
);
