import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { bool, object, string } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

const Sessions = ({ eventSlug, hasRoundtablesArea, control }) => {
  const { t } = useLocalization('venue-controls.sessions');

  const {
    field: { onChange, value, name },
  } = useController({
    name: 'venue_settings[add_session]',
    control,
  });

  return (
    <>
      <Text
        element="h3"
        pattern="headingThree"
        color="grey-800"
        weight="medium"
        scale={4}
        mt={3}
      >
        {t('title')}
      </Text>
      {!hasRoundtablesArea && (
        <Text
          element="p"
          mt={1}
          color="grey-600"
          font="display"
          pattern="body"
          weight="bold"
          dangerouslySetInnerHTML={{
            __html: t('not-enabled-hint', {
              slug: eventSlug,
            }),
          }}
        />
      )}
      <Text element="p" mt={1} scale={4} pattern="body" color="grey-600">
        {t('heading')}
      </Text>
      <Flex alignItems="center" mt={1}>
        <Checkbox
          name={name}
          data-testid="limit-connections-checkbox"
          id="add_session"
          onChange={event => onChange(event.target.checked)}
          checked={!!value}
          value={value}
          label={t('enable')}
          disabled={hasRoundtablesArea ? '' : 'disabled'}
        />
      </Flex>
    </>
  );
};

Sessions.propTypes = {
  eventSlug: string,
  hasRoundtablesArea: bool,
  control: object.isRequired,
};

export default withLocalizationProvider(Sessions);
