const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const initApiClient = (
  token,
  { headers: initHeaders = {}, json = false } = {},
) => {
  const headers = {
    ...initHeaders,
    ...(json ? JSON_HEADERS : {}),
    'X-CSRF-TOKEN': token,
  };

  const put = async (url, body) => {
    const request = await fetch(url, {
      method: 'PUT',
      headers,
      body: body,
    });
    return request.json();
  };

  const get = async url => {
    const request = await fetch(url, {
      method: 'GET',
      headers,
    });
    return request.json();
  };

  const post = async (url, body, options) => {
    const request = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        ...(options?.headers ? options.headers : {}),
      },
      body: json && body ? JSON.stringify(body) : body,
    });

    if (options?.raw) {
      return request;
    }

    return request[options?.type || 'json']();
  };

  const del = async (url, options) => {
    return await fetch(url, {
      method: 'DELETE',
      headers: {
        ...headers,
        ...(options?.headers ? options.headers : {}),
      },
    });
  };

  return { put, get, post, del };
};

export default initApiClient;
