import { routes } from '@features/event-creation-wizard/constants/step-map';

export const redirectOnClose = ({
  dashboardOptedIn,
  dashboardUrl,
  appEnvironment,
  orgId,
}) => {
  if (dashboardOptedIn && appEnvironment !== 'integration') {
    window.location.assign(dashboardUrl);
    return;
  }

  if (orgId) {
    window.location.assign(routes.overview());
    return;
  }

  window.location.assign(routes.account());
};
