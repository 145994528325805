import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { Grid } from '@hopin-team/ui-grid';
import { Image } from '@hopin-team/ui-image';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { TemplateCard } from '../styled/template-card';
import {
  TemplateDateField,
  TemplateModalFormComponent,
  TemplateModalFormRoot,
  TemplateTimeField,
} from './styled';
import { useTemplateModalForm } from './use-template-modal-form';

const MIN_PASSWORD_LENGTH = 6;

export const TemplateModalForm = ({
  organizationExternalId,
  template,
  isOnFreePlan,
  maxEventDurationSecs,
  onSubmitting,
  onSuccess,
  onError,
  visible,
}) => {
  const { t } = useLocalization('templates');
  const { t: tRoot } = useLocalization('');
  const [areDateValuesWatched, setAreDateValuesWatched] = useState(false);

  const options = useMemo(
    () => [
      { value: 'hidden_event', label: t('form.eventType.options.hidden') },
      ...(isOnFreePlan
        ? []
        : [
            {
              value: 'public_event',
              label: t('form.eventType.options.public'),
            },
            {
              value: 'private_event',
              label: t('form.eventType.options.private'),
            },
          ]),
    ],
    [isOnFreePlan, t],
  );

  const {
    formState: { errors, isSubmitting },
    register,
    onSubmit,
    control,
    trigger,
  } = useTemplateModalForm({
    isOnFreePlan,
    maxEventDurationSecs,
    onSuccess,
    onError,
    templateId: template?.id,
    organizationExternalId,
    minPasswordLength: MIN_PASSWORD_LENGTH,
  });

  const {
    startDate: changedStartDate,
    startTime: changedStartTime,
    endDate: changedEndDate,
    endTime: changedEndTime,
  } = useWatch({
    control,
    name: ['startDate', 'startTime', 'endDate', 'endTime'],
    defaultValue: {},
  });

  const eventTypeValue = useWatch({ control, name: 'eventType' });

  useEffect(() => {
    onSubmitting(isSubmitting);
  }, [isSubmitting, onSubmitting]);

  useEffect(() => {
    // First value is undefined
    // Second value is the form default values
    // From third value (i.e. the first "change") we'll start triggering re-validation
    if (changedStartDate) {
      if (areDateValuesWatched) {
        trigger(['startDate', 'startTime', 'endDate', 'endTime']);
      } else {
        setAreDateValuesWatched(true);
      }
    }
  }, [
    changedStartDate,
    changedStartTime,
    changedEndDate,
    changedEndTime,
    trigger,
    areDateValuesWatched,
  ]);

  return (
    <TemplateModalFormRoot data-testid="template-modal-form" visible={visible}>
      <Text
        element="h2"
        font="text"
        id="create-event-modal-heading"
        pattern="headingTwo"
      >
        {t('createANewEvent')}
      </Text>
      <Text color="grey-700" element="p" mt={0.5} pattern="body">
        {t('addDetails')}
      </Text>
      {template?.image && (
        <>
          <TemplateCard mt={3.125} ml="auto" mr="auto">
            <Image src={template.image} />
          </TemplateCard>
        </>
      )}
      <TemplateModalFormComponent onSubmit={onSubmit}>
        <TextField
          label={t('form.eventName.label')}
          name="eventName"
          hasErrors={!!errors?.eventName}
          errorMessage={errors?.eventName?.message}
          ref={register}
          placeholder={t('form.eventName.placeholder')}
          mt={2}
          size="tiny"
        />
        <Grid alignItems="center" gridGap={2} gridTemplateColumns="2fr 1fr">
          <Controller
            control={control}
            name="startDate"
            render={({ onChange, value }) => (
              <TemplateDateField
                label={t('form.startDate.label')}
                date={value}
                setDate={onChange}
                mt={2}
                sizeOption="tiny"
              />
            )}
          />
          <TemplateTimeField
            label={t('form.startTime.label')}
            name="startTime"
            ref={register}
            placeholder={t('form.startTime.placeholder')}
            mt={2}
            size="tiny"
            type="time"
            required
          />
        </Grid>
        {errors?.startDate && (
          <FieldErrorMessage mt={1} errorMessage={errors?.startDate?.message} />
        )}
        {errors?.startTime && (
          <FieldErrorMessage mt={1} errorMessage={errors?.startTime?.message} />
        )}
        <Grid alignItems="center" gridGap={2} gridTemplateColumns="2fr 1fr">
          <Controller
            control={control}
            name="endDate"
            render={({ onChange, value }) => (
              <TemplateDateField
                label={t('form.endDate.label')}
                date={value}
                setDate={onChange}
                mt={2}
                sizeOption="tiny"
              />
            )}
          />
          <TemplateTimeField
            label={t('form.endTime.label')}
            name="endTime"
            ref={register}
            placeholder={t('form.endTime.placeholder')}
            mt={2}
            size="tiny"
            type="time"
            required
          />
        </Grid>
        {errors?.endDate && (
          <FieldErrorMessage mt={1} errorMessage={errors?.endDate?.message} />
        )}
        {errors?.endTime && (
          <FieldErrorMessage mt={1} errorMessage={errors?.endTime?.message} />
        )}
        <Controller
          control={control}
          name="eventType"
          render={({ onChange, value }) => (
            <Select
              label={t('form.eventType.label')}
              options={options}
              value={value}
              setValue={onChange}
              hasErrors={!!errors?.eventType}
              errorMessage={errors?.eventType?.message}
              mt={2}
              visibleOptionsCount={3}
              size="small"
              showOptionsLabel={tRoot('common.showOptionsLabel', {
                label: t('form.eventType.label'),
              })}
              hideOptionsLabel={tRoot('common.hideOptionsLabel', {
                label: t('form.eventType.label'),
              })}
            />
          )}
        />
        {eventTypeValue === 'private_event' && (
          <>
            <TextField
              label={t('form.eventPassword.label')}
              name="eventPassword"
              hasErrors={!!errors?.eventPassword}
              errorMessage={errors?.eventPassword?.message}
              ref={register}
              autocomplete="new-password"
              mt={2}
              size="small"
            />
            {/* Display password rules (see https://www.nngroup.com/articles/password-creation/) */}
            <Text
              pattern="labelThree"
              mt={1}
              // This isn't great, but without this, the line-height and margin is wrong ... it needs to be fixed "properly"
              style={{
                display: 'inline-block',
              }}
            >
              {t('form.eventPassword.invitedUsers', {
                min: MIN_PASSWORD_LENGTH,
              })}
            </Text>
          </>
        )}
        {isOnFreePlan && (
          <Text pattern="labelThree" mt={2}>
            {t('wantPublicPrivate')}{' '}
            <a href="/organiser-billing">{t('upgradeYourPlan')}</a>
          </Text>
        )}
        <Button mt={4}>Create event</Button>
      </TemplateModalFormComponent>
    </TemplateModalFormRoot>
  );
};

TemplateModalForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  template: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  isOnFreePlan: PropTypes.bool.isRequired,
  maxEventDurationSecs: PropTypes.number.isRequired,
  onSubmitting: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  organizationExternalId: PropTypes.string.isRequired,
};
