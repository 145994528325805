import styled from 'styled-components';

const Spacer = styled.div`
  height: 4rem;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 200;
  padding: 1rem;
  background-color: var(--color-gray-900);

  [role='tooltip'] {
    overflow: visible;
  }
`;

const TooltipIconWrapper = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-left: 0.125rem;
`;

const TooltipContentWrapper = styled.div`
  max-width: 18.5rem;
  padding: 0.75rem;
  text-align: start;
`;

const Title = styled.span`
  display: inline-flex;
`;

export { Spacer, Container, TooltipIconWrapper, TooltipContentWrapper, Title };
