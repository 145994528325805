import timezones from './timezones';

describe('Timezone constants', () => {
  it('should return an ordered list of timezones', () => {
    expect(timezones(new Date('2020-01-01'))).toEqual(
      expect.arrayContaining([
        {
          label: '(GMT+00:00) London',
          value: 'London',
          offset: 0,
        },
        {
          label: '(GMT+00:00) Monrovia',
          value: 'Monrovia',
          offset: 0,
        },
        {
          label: '(GMT+00:00) UTC',
          value: 'UTC',
          offset: 0,
        },
        {
          label: '(GMT+01:00) Casablanca',
          value: 'Casablanca',
          offset: 3600000,
        },
        {
          label: '(GMT+01:00) Amsterdam',
          value: 'Amsterdam',
          offset: 3600000,
        },
        {
          label: '(GMT+01:00) Belgrade',
          value: 'Belgrade',
          offset: 3600000,
        },
        {
          label: '(GMT+01:00) Berlin',
          value: 'Berlin',
          offset: 3600000,
        },
      ]),
    );
  });

  it('should adjust for DST', () => {
    expect(timezones(new Date('2020-06-01'))).toEqual(
      expect.arrayContaining([
        { label: '(GMT+01:00) London', value: 'London', offset: 3600000 },
        {
          label: '(GMT+01:00) West Central Africa',
          value: 'West Central Africa',
          offset: 3600000,
        },
        { label: '(GMT+02:00) Amsterdam', value: 'Amsterdam', offset: 7200000 },
        { label: '(GMT+02:00) Belgrade', value: 'Belgrade', offset: 7200000 },
        { label: '(GMT+02:00) Berlin', value: 'Berlin', offset: 7200000 },
      ]),
    );
  });
});
