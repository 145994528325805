import { intervalToDuration, parseISO } from 'date-fns';

const getIntervalUnit = data => {
  if (!data || data.length < 2) return 'minute';

  const duration = intervalToDuration({
    start: parseISO(data[0][0]),
    end: parseISO(data[1][0]),
  });

  if (duration.days) {
    return 'day';
  } else if (duration.hours) {
    return 'hour';
  } else if (duration.minutes) {
    return 'minute';
  } else {
    console.error('Not a valid duration');
  }
};

export default getIntervalUnit;
