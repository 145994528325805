import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const Column = styled(Flex).attrs(({ isWider }) => ({
  flexDirection: 'column',
  flexBasis: isWider ? 106 : 80,
}))`
  &:not(:last-child) {
    margin-right: var(--spacing-16);
  }
`;
