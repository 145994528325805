import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Image } from '@hopin-team/ui-image';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import HeaderBackground from './assets/post-event-banner.png';
import {
  FeatureItem,
  FeatureLink,
  FeatureList,
  LearnMoreLink,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ReadArticleButton,
} from './post-event-settings.styled';

const PostEventSettings = ({
  externalSupportArticleUrl,
  isCustomEmailsEnabled,
  slug,
}) => {
  const { t } = useContext(LocalizationContext);

  const [isModalShowing, setIsModalShowing] = useState(false);
  const openModal = useCallback(() => setIsModalShowing(true), [
    setIsModalShowing,
  ]);
  const closeModal = useCallback(() => setIsModalShowing(false), [
    setIsModalShowing,
  ]);

  return (
    <Flex mb={2}>
      <Text scale={4}>
        {t('basic-settings.post-event.description')}{' '}
        <LearnMoreLink onClick={openModal} data-testid="link-for-more-details">
          &#8594; {t('basic-settings.post-event.link')}
        </LearnMoreLink>
      </Text>
      <Modal isShowing={isModalShowing} onClose={closeModal} size="small">
        <ModalHeader>
          <Image src={HeaderBackground} />
          <ModalTitle>{t('basic-settings.post-event.modal.title')}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <FeatureList>
            <FeatureItem>
              <Text scale={4} color="gray-800">
                {t('basic-settings.post-event.modal.features.notifications')}
              </Text>
              {isCustomEmailsEnabled && (
                <FeatureLink
                  href={Routes.organisersEventCommunicationsPath(slug)}
                  data-testid="link-to-communications"
                >
                  &#8594;{' '}
                  {t(
                    'basic-settings.post-event.modal.features.change-email-settings',
                  )}
                </FeatureLink>
              )}
            </FeatureItem>
            <FeatureItem>
              <Text scale={4} color="gray-800">
                {t('basic-settings.post-event.modal.features.event-parts')}
              </Text>
            </FeatureItem>
          </FeatureList>
        </ModalBody>
        {externalSupportArticleUrl && (
          <ModalFooter>
            <ReadArticleButton
              isOutlined
              href={externalSupportArticleUrl}
              target="_blank"
              data-testid="link-to-external-article"
            >
              {t('basic-settings.post-event.modal.read-article')}
            </ReadArticleButton>
          </ModalFooter>
        )}
      </Modal>
    </Flex>
  );
};

PostEventSettings.propTypes = {
  externalSupportArticleUrl: PropTypes.string,
  isCustomEmailsEnabled: PropTypes.bool,
  slug: PropTypes.string,
};

export default withThemeProvider(withLocalizationProvider(PostEventSettings));
