import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { List, ListItem, MatchContainer, Stretch, Top } from './matcher.styles';

const getHeaderText = (t, matched, manually) => {
  if (matched) {
    return manually ? t('matched') : t('automatched');
  }
  return t('unmatched');
};

const Matcher = ({
  autoMapping,
  defaultMapping,
  examples,
  fieldName,
  onChange,
  userMapping,
}) => {
  const { t } = useLocalization('field-mapper.matcher');
  const selection =
    Object.keys(userMapping).find(
      keyFromHopin => userMapping[keyFromHopin] === fieldName,
    ) ?? '';

  const noMatch = useMemo(() => ({ value: '', label: t('no-match') }), [t]);

  const selectOptions = Object.entries(defaultMapping)
    .filter(([key]) => {
      const pickedByThisMatcher = key === selection;
      const notPickedYet = !userMapping[key];
      return pickedByThisMatcher || notPickedYet;
    })
    .map(([value, label]) => ({
      value,
      label: label ?? '',
    }));

  const isMatched = !!selection;
  const isAutoMatched = autoMapping[selection] || false;
  const isManuallyMatched = isMatched && isAutoMatched !== fieldName;
  const filledExamples = new Array(examples.length)
    .fill('-')
    .map((fallback, index) => examples[index] || fallback);

  return (
    <MatchContainer
      flexDirection="column"
      matched={isMatched}
      data-testid={`matcher_${selection}`}
    >
      <Top alignItems="center" matched={isMatched}>
        {isMatched && <Icon name="checkmark" color="blue-400" aria-hidden />}
        <Text pattern="headingSix" ml={1}>
          {getHeaderText(t, isMatched, isManuallyMatched)}
        </Text>
      </Top>
      <Flex p={1} ml={1}>
        <Text pattern="headingFour">{fieldName}</Text>
      </Flex>

      <List matched={isMatched}>
        {filledExamples.map((example, index) => (
          <ListItem key={index} matched={isMatched}>
            <Text pattern="labelThree">{example}</Text>
          </ListItem>
        ))}
      </List>

      <Stretch p={1}>
        <Select
          label=""
          options={[noMatch, ...selectOptions]}
          value={selection}
          setValue={keyFromHopin => onChange(keyFromHopin, fieldName)}
          visibleOptionsCount={3}
        />
      </Stretch>
    </MatchContainer>
  );
};

Matcher.propTypes = {
  autoMapping: PropTypes.object,
  defaultMapping: PropTypes.object,
  examples: PropTypes.array,
  exampleCount: PropTypes.number,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  userMapping: PropTypes.object,
};

export default Matcher;
