import styled, { css } from 'styled-components';

const Pagination = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const disabledNavLinkStyles = css`
  pointer-events: none;
  color: var(--color-gray-500);
`;

const NavLink = styled.button`
  ${({ disabled }) => css`
    margin-right: var(--spacing-8);
    ${disabled && disabledNavLinkStyles}
  `}
`;

const disabledPaginationNumberStyles = css`
  background: var(--color-white);
  color: var(--color-gray-500);
  border: 0.0625rem solid var(--color-gray-300);
`;

const PaginationPageNumber = styled(NavLink)`
  ${({ disabled }) => css`
    &:hover {
      color: var(--color-white);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-blue-400);
    color: var(--color-white);
    border-radius: var(--spacing-4);
    width: 4rem;
    height: var(--spacing-32);
    ${disabled && disabledPaginationNumberStyles}
  `}
`;

export { NavLink, Pagination, PaginationPageNumber };
