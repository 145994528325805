import useDateFormatters from '@components/bar-chart/hooks/use-date-formatters';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';

const XAxisTickTimeLabel = props => {
  const { formatForAxisLabel } = useDateFormatters();
  const { formattedValue, allTicks, unit } = props;
  const { text, subText } = formatForAxisLabel(formattedValue, allTicks, unit);

  const svgAttrs = omit(props, [
    'formattedValue',
    'allTicks',
    'unit',
    'format',
  ]);

  return (
    <svg>
      <text {...svgAttrs}>
        <tspan>{text}</tspan>
        <tspan x={svgAttrs.x} dy="1.2em">
          {subText}
        </tspan>
      </text>
    </svg>
  );
};

XAxisTickTimeLabel.propTypes = {
  formattedValue: PropTypes.string.isRequired,
  allTicks: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
};

export default XAxisTickTimeLabel;
