import { connectViewData } from '@util/page-view-context';

import EventOptionsComponent from './event-options-component';

const mapViewDataToProps = ({ hasHopinWebUrl, organiser }) => ({
  hasHopinWebUrl,
  hasSuppressEmails: organiser.suppressEmails,
});

export default connectViewData(mapViewDataToProps)(EventOptionsComponent);
