import { Icon } from '@hopin-team/ui-icon';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const DEFAULT_LEVELS_LENGTH = 3;
const MAX_SPONSOR_LEVELS = 10;

const sharedSponsorButtonStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary-400);
  margin-right: 1.5rem;
  font-size: 14px;

  & > div {
    height: 14px;
    width: 14px;
    margin-right: 10px;
    color: unset;
  }
`;

const SponsorButtonGroup = styled.div`
  margin-top: 1rem;
`;

const SponsorAreaLinkButton = styled.a`
  ${sharedSponsorButtonStyles}
`;

const SponsorAreaButton = styled.button`
  ${sharedSponsorButtonStyles}
`;

const ExtraSponsorTiersControls = ({
  hasCustomizedText,
  textCustomizationHref,
  currentSponsorAreaMax,
  handleAddSponsorArea,
  handleRemoveSponsorTier,
  defaultLevelsLength = DEFAULT_LEVELS_LENGTH,
}) => {
  return (
    <SponsorButtonGroup>
      {hasCustomizedText && (
        <SponsorAreaLinkButton
          href={textCustomizationHref}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon aria-hidden="true" name="edit" scale={1} />
          Edit tier names
        </SponsorAreaLinkButton>
      )}
      {currentSponsorAreaMax < MAX_SPONSOR_LEVELS && (
        <SponsorAreaButton onClick={handleAddSponsorArea}>
          <Icon aria-hidden="true" name="plus" scale={1} />
          Add a sponsor tier
        </SponsorAreaButton>
      )}
      {currentSponsorAreaMax > defaultLevelsLength && (
        <SponsorAreaButton onClick={handleRemoveSponsorTier}>
          <Icon aria-hidden="true" name="minus" scale={1} />
          Remove bottom tier
        </SponsorAreaButton>
      )}
    </SponsorButtonGroup>
  );
};

ExtraSponsorTiersControls.propTypes = {
  hasCustomizedText: bool.isRequired,
  textCustomizationHref: string.isRequired,
  currentSponsorAreaMax: number.isRequired,
  handleAddSponsorArea: func.isRequired,
  handleRemoveSponsorTier: func.isRequired,
  defaultLevelsLength: number,
};

export default ExtraSponsorTiersControls;
