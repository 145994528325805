import { Butticon } from '@hopin-team/ui-butticon';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

const StatusMessage = styled(Text).attrs({
  role: 'status',
  pattern: 'caption',
  color: 'grey-white',
  px: 2,
  py: 1.5,
  mt: 2,
})`
  box-shadow: 0 0.25rem 1rem -0.5rem rgba(0, 0, 0, 0.32);
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
`;

export const SuccessMessageWrapper = styled(StatusMessage).attrs({
  color: 'green-600',
  mb: 3,
})`
  background: ${({ theme }) => getFromTheme('COLOR.GREEN[100]', theme)};

  ${Butticon} {
    margin-left: auto;
  }
`;

export const ErrorMessageWrapper = styled(StatusMessage)`
  background: ${({ theme }) => getFromTheme('COLOR.RED[500]', theme)};
`;
