import * as Routes from '@routes';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectEvent } from './redux/slices/event/event-selectors';

const FullSpaceLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const RecordingLink = ({ recording, children }) => {
  const { slug } = useSelector(selectEvent);
  const href = Routes.organisersEventRecordingPath(slug, recording.id);

  return (
    <FullSpaceLink data-testid={`recording-link-${recording.id}`} href={href}>
      {children}
    </FullSpaceLink>
  );
};

RecordingLink.propTypes = {
  recording: PropTypes.shape({
    id: PropTypes.string,
  }),
  children: PropTypes.node,
};
