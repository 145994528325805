import api from '@api/hopin';
import InfoSquareIcon from '@components/icons/info-square';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { LocalizationContext } from '@features/localization';
import { UploadRegistrantsButton } from '@features/registrations/registrants-bulk-upload/components/upload-registrants-button';
import GenerateButton from '@features/reports/components/generate-button';
import { AuthenticationProvider } from '@hopin-team/provider-authentication';
import { Box } from '@hopin-team/ui-box';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Flex } from '@hopin-team/ui-flex';
import { NextButton, Pagination, PrevButton } from '@hopin-team/ui-pagination';
import {
  Table,
  TableCell,
  TableHeading,
  TableRow,
  TableRowActions,
} from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { array, arrayOf, bool, number, shape, string } from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import { AttendeeDetailsTabs } from './attendee-details/attendee-details-tabbed';
import { AttendeesListActionMenu } from './attendees-list-action-menu';
import {
  CopyMagicLink,
  TooltipContent,
  useMLDisclaimerLogger,
} from './components';
import {
  eventTracksIconAccessibilityProps,
  ticketTypesIconAccessibilityProps,
} from './constants';
import { EmptyStateContainer } from './empty-state-container';
import { useList } from './list-component';
import {
  BulkActionsContainer,
  ButtonPlaceholder,
  Container,
  FiltersContainer,
  FilterSelect,
  PaginationNumbers,
  SearchField,
  StyledHeading,
} from './styles';
import {
  deleteAttendee,
  refundTicket,
  resendEmail,
  unblockAttendee,
} from './utils/services';

const AttendeesListComponent = ({
  eventSlug,
  listUrl,
  ticketTypes,
  eventTracks,
  eventId,
  eventExternalId,
  userExternalId,
  eventPublished,
  registrantBulkUploadEnabled,
  registrationEmailsDisabled,
  registrantsBulkUploadReportStatus,
  registrantEditableTicketTypeEnabled,
  onsiteCustomFormBulkUploadEnabled,
  downloadMagicLinkEnabled,
  isOnAdvancedPlan,
}) => {
  const { t } = useContext(LocalizationContext);

  const {
    onPageChange,
    onQueryChange,
    onTicketTypeChange,
    onEventTrackChange,
    searchQuery,
    requestStatus,
    totalPages,
    currentPage,
    attendeesCount,
    hasActiveFilters,
    attendees,
    ticketType,
    eventTrack,
  } = useList(eventTracks, listUrl, 'attendees');
  const { addAlert } = useContext(AlertsContext);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const { disclaimerViewed, logDisclaimerAsViewed } = useMLDisclaimerLogger({
    eventSlug,
    userExternalId,
  });

  const closeAttendeeDetails = useCallback(
    reload => {
      setSelectedAttendee(undefined);
      if (reload) {
        onPageChange(currentPage, true);
      }
    },
    [setSelectedAttendee, onPageChange, currentPage],
  );

  const handleBlockAttendee = async attendee => {
    const response = confirm(
      t('people.attendees.attendees-tab.details.actions.block.confirmation'),
    );
    if (response) {
      try {
        const deleteResponse = await deleteAttendee(eventSlug, attendee.id);
        if (deleteResponse.error) {
          return addAlert({
            active: true,
            text: deleteResponse.error,
            pattern: 'error',
          });
        }
        const updatedCount = attendeesCount - 1;
        if (updatedCount % 20 !== 0) {
          onPageChange(currentPage, true);
        } else {
          onPageChange(currentPage - 1, true);
        }
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.block.success-message',
            {
              name: attendee.name,
            },
          ),
          pattern: 'success',
        });
        if (attendee) {
          setSelectedAttendee(null);
        }
      } catch (e) {
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.generic-error',
          ),
          pattern: 'error',
        });
      }
    }
  };

  const handleRefundTicket = async () => {
    const response = confirm(
      t('people.attendees.attendees-tab.details.actions.refund.confirmation'),
    );
    if (response) {
      try {
        const refundResponse = await refundTicket(
          eventSlug,
          selectedAttendee.id,
        );
        if (refundResponse.error) {
          return addAlert({
            active: true,
            text: refundResponse.error,
            pattern: 'error',
          });
        }
        onPageChange(currentPage, true);
        if (selectedAttendee) {
          setSelectedAttendee(null);
        }
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.refund.success-message',
            {
              name: selectedAttendee.name,
            },
          ),
          pattern: 'success',
        });
      } catch (e) {
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.generic-error',
          ),
          pattern: 'error',
        });
      }
    }
  };

  const handleResendEmail = async attendee => {
    const response = confirm(
      t(
        'people.attendees.attendees-tab.details.actions.resend-registration-email.confirmation',
      ),
    );
    if (response) {
      try {
        const resendResponse = await resendEmail(eventSlug, attendee.id);
        if (resendResponse.error) {
          return addAlert({
            active: true,
            text: resendResponse.error,
            pattern: 'error',
          });
        }
        onPageChange(currentPage, true);
        if (attendee) {
          setSelectedAttendee(null);
        }
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.resend-registration-email.success-message',
            {
              name: attendee.name,
            },
          ),
          pattern: 'success',
        });
      } catch (e) {
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.generic-error',
          ),
          pattern: 'error',
        });
      }
    }
  };

  const handleUnblockAttendee = async attendee => {
    const response = confirm(
      t('people.attendees.attendees-tab.details.actions.unblock.confirmation'),
    );
    if (response) {
      try {
        const unblockResponse = await unblockAttendee(eventId, attendee.id);
        if (unblockResponse.error) {
          return addAlert({
            active: true,
            text: unblockResponse.error,
            pattern: 'error',
          });
        }
        onPageChange(currentPage, true);
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.unblock.success-message',
            {
              name: attendee.name,
            },
          ),
          pattern: 'success',
        });
        setSelectedAttendee(null);
      } catch (e) {
        addAlert({
          active: true,
          text: t(
            'people.attendees.attendees-tab.details.actions.generic-error',
          ),
          pattern: 'error',
        });
      }
    }
  };

  if (
    !hasActiveFilters &&
    attendeesCount === 0 &&
    requestStatus === 'COMPLETED'
  ) {
    const emptyStateTranslatePrefix =
      'people.attendees.attendees-tab.list.empty.';
    let emptyStateHeader = 'header';
    let emptyStateBody = 'description';

    if (registrantBulkUploadEnabled) {
      emptyStateHeader = 'bulk-upload-header';
      emptyStateBody = 'bulk-upload-description';
    }
    return (
      <>
        <EmptyStateContainer
          mx="auto"
          py={6}
          flexDirection="column"
          alignItems="center"
        >
          <Box mb={9}>
            <img
              src="/images/attendees.png"
              alt=""
              width="288"
              height="207"
              draggable="false"
            />
          </Box>
          <Text pattern="headingTwo">
            {t(`${emptyStateTranslatePrefix}${emptyStateHeader}`)}
          </Text>
          <Text pattern="body" element="p" color="grey-700" mt={2} mb={4}>
            {t(`${emptyStateTranslatePrefix}${emptyStateBody}`)}
          </Text>
          {registrantBulkUploadEnabled && (
            <>
              <UploadRegistrantsButton
                eventPublished={eventPublished}
                eventSlug={eventSlug}
                eventExternalId={eventExternalId}
                userExternalId={userExternalId}
                title={t(`${emptyStateTranslatePrefix}upload-registrants`)}
                registrationEmailsDisabled={registrationEmailsDisabled}
                registrantsBulkUploadReportStatus={
                  registrantsBulkUploadReportStatus
                }
                onsiteCustomFormBulkUploadEnabled={
                  onsiteCustomFormBulkUploadEnabled
                }
              />
              <Box mt={3}>
                <Text color="grey-400" pattern="caption">
                  {t('people.attendees.registrant-bulk-upload.disclaimer')}
                </Text>
              </Box>
            </>
          )}
        </EmptyStateContainer>
      </>
    );
  }

  return (
    <AuthenticationProvider tokenProvider={api.getUserToken}>
      <Container py={4}>
        <h2>
          {t('people.attendees.attendees-tab.list.header', {
            count: attendeesCount,
          })}
        </h2>

        <FiltersContainer>
          <FilterSelect
            placeholder={t(
              'people.attendees.attendees-tab.list.filter.event-track',
            )}
            isClearable={true}
            iconAccessibilityProps={eventTracksIconAccessibilityProps(t)}
            value={eventTrack}
            onChange={onEventTrackChange}
            options={eventTracks}
          />

          <FilterSelect
            placeholder={t(
              'people.attendees.attendees-tab.list.filter.ticket-type',
            )}
            isClearable={true}
            iconAccessibilityProps={ticketTypesIconAccessibilityProps(t)}
            value={ticketType}
            onChange={onTicketTypeChange}
            options={ticketTypes}
          />

          <FieldLabel fieldId="Search" className="sr-only" />
          <SearchField
            id="search"
            label={t('people.attendees.attendees-tab.list.filter.search')}
            placeholder={t('people.attendees.attendees-tab.list.filter.search')}
            type="text"
            value={searchQuery}
            onInput={onQueryChange}
          />

          {(() => {
            let buttonText = 'people.attendees.tabs.registrants';
            let reportType = 'Analytics::Reports::EventRegistrantsReportJob';
            if (registrantBulkUploadEnabled) {
              return (
                <BulkActionsContainer>
                  <UploadRegistrantsButton
                    ticketTypes={ticketTypes}
                    eventExternalId={eventExternalId}
                    eventPublished={eventPublished}
                    eventSlug={eventSlug}
                    userExternalId={userExternalId}
                    title={t(
                      'people.attendees.attendees-tab.list.actions.upload',
                    )}
                    registrationEmailsDisabled={registrationEmailsDisabled}
                    registrantsBulkUploadReportStatus={
                      registrantsBulkUploadReportStatus
                    }
                    onsiteCustomFormBulkUploadEnabled={
                      onsiteCustomFormBulkUploadEnabled
                    }
                  />
                  <GenerateButton
                    reportType={reportType}
                    initiatedFrom="new_registrations"
                    isParentColor
                    buttonProps={{
                      isOutlined: true,
                      isInline: true,
                      disabled: attendeesCount <= 0,
                      size: 'medium',
                    }}
                  >
                    {t('people.attendees.attendees-tab.list.actions.download')}
                  </GenerateButton>
                </BulkActionsContainer>
              );
            }
            return (
              <GenerateButton
                reportType={reportType}
                initiatedFrom="new_registrations"
                buttonProps={{
                  isOutlined: true,
                  isInline: true,
                  disabled: attendeesCount <= 0,
                  size: 'medium',
                }}
              >
                {t(buttonText)}{' '}
                {t('people.attendees.attendees-tab.list.actions.csv')}
              </GenerateButton>
            );
          })()}
        </FiltersContainer>
        <Table className="table" mt={4}>
          <thead>
            <TableRow>
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.name')}
              </TableHeading>
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.event-track')}
              </TableHeading>
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.ticket')}
              </TableHeading>
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.price')}
              </TableHeading>
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.promo-code')}
              </TableHeading>
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.registration')}
              </TableHeading>
              {downloadMagicLinkEnabled && isOnAdvancedPlan && (
                <TableHeading>
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    data-testid="magic-link-heading"
                  >
                    <StyledHeading>
                      {t(
                        'people.attendees.attendees-tab.list.columns.magic-link',
                      )}
                    </StyledHeading>
                    <Box ml={0.625}>
                      <ToolTip
                        tip={
                          <TooltipContent
                            text={t(
                              'people.attendees.attendees-tab.details.magic-link-disclaimer',
                            )}
                          />
                        }
                        align="top"
                      >
                        <InfoSquareIcon />
                      </ToolTip>
                    </Box>
                  </Flex>
                </TableHeading>
              )}
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.refund')}
              </TableHeading>
              <TableHeading>
                {t('people.attendees.attendees-tab.list.columns.block')}
              </TableHeading>
            </TableRow>
          </thead>
          <tbody>
            {attendees.map(attendee => (
              <TableRow key={attendee.id}>
                <TableCell>
                  <Text weight="bold" font="display">
                    {attendee.name}
                  </Text>
                  <br />
                  {attendee.email}
                </TableCell>
                <TableCell>{attendee.event_track}</TableCell>
                <TableCell>{attendee.ticket_type}</TableCell>
                <TableCell>{attendee.price_paid}</TableCell>
                <TableCell>{attendee.promo_code}</TableCell>
                <TableCell>
                  {attendee.created_at_date}
                  <br />
                  {attendee.created_at_time}
                </TableCell>
                {downloadMagicLinkEnabled && isOnAdvancedPlan && (
                  <TableCell>
                    {attendee.redeem_code_external_id && (
                      <CopyMagicLink
                        redeemCode={attendee.redeem_code_external_id}
                        t={t}
                        eventSlug={eventSlug}
                        onCopy={logDisclaimerAsViewed}
                        showDisclaimer={disclaimerViewed}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {attendee.refunded
                    ? t('people.attendees.attendees-tab.list.status.refunded')
                    : ''}
                </TableCell>
                <TableCell>
                  {attendee.blocked
                    ? t('people.attendees.attendees-tab.list.status.blocked')
                    : ''}
                </TableCell>
                <TableRowActions>
                  {attendee.id && (
                    <AttendeesListActionMenu
                      id={attendee.id}
                      actions={[
                        {
                          label: t(
                            'people.attendees.attendees-tab.details.actions.show.label',
                          ),
                          leadingIcon: 'info-list',
                          onClick: () => setSelectedAttendee(attendee),
                        },
                        {
                          label: t(
                            'people.attendees.attendees-tab.details.actions.resend-registration-email.label',
                          ),
                          leadingIcon: 'send-filled',
                          onClick: () => handleResendEmail(attendee),
                        },
                        {
                          label: attendee.blocked
                            ? t(
                                'people.attendees.attendees-tab.details.actions.unblock.label',
                              )
                            : t(
                                'people.attendees.attendees-tab.details.actions.block.label',
                              ),
                          leadingIcon: 'disable',
                          onClick: () =>
                            attendee.blocked
                              ? handleUnblockAttendee(attendee)
                              : handleBlockAttendee(attendee),
                        },
                      ]}
                    />
                  )}
                </TableRowActions>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          onChange={onPageChange}
          totalPages={totalPages}
          nextLabel={t('pagination.next')}
          pageLabel={t('pagination.goto-page')}
          prevLabel={t('pagination.previous')}
          titleLabel={t('pagination.title')}
          currentLabel={t('pagination.current')}
        >
          {currentPage > 1 ? <PrevButton mr={1} /> : <ButtonPlaceholder />}
          <PaginationNumbers />
          {currentPage < totalPages ? (
            <NextButton ml={1} />
          ) : (
            <ButtonPlaceholder />
          )}
        </Pagination>

        {selectedAttendee && (
          <AttendeeDetailsTabs
            attendee={selectedAttendee}
            eventSlug={eventSlug}
            onClose={closeAttendeeDetails}
            onRefundTicket={handleRefundTicket}
            ticketTypes={ticketTypes}
            registrantEditableTicketTypeEnabled={
              registrantEditableTicketTypeEnabled
            }
          />
        )}
      </Container>
    </AuthenticationProvider>
  );
};

AttendeesListComponent.propTypes = {
  listUrl: string.isRequired,
  ticketTypes: arrayOf(
    shape({
      label: string,
      value: number,
      externalId: string,
      freeTicket: bool,
    }),
  ).isRequired,
  eventTracks: array.isRequired,
  eventSlug: string.isRequired,
  eventId: number.isRequired,
  eventExternalId: string.isRequired,
  userExternalId: string.isRequired,
  eventPublished: bool.isRequired,
  registrantEditableTicketTypeEnabled: bool.isRequired,
  registrantBulkUploadEnabled: bool,
  registrationEmailsDisabled: bool,
  registrantsBulkUploadReportStatus: bool,
  onsiteCustomFormBulkUploadEnabled: bool.isRequired,
  downloadMagicLinkEnabled: bool,
  isOnAdvancedPlan: bool,
};

export default AttendeesListComponent;
