import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { PasswordStrength } from '.';

const userTypingDebounceTimeMs = 300;

export default function PasswordStrengthStatic() {
  const [password, setPassword] = useState();
  const [debouncedPassword] = useDebounce(password, userTypingDebounceTimeMs);

  useEffect(() => {
    window.__updatePasswordComponent = password => setPassword(password);
  }, []);

  return (
    <PasswordStrength
      password={debouncedPassword}
      onSuccess={window.__onPasswordSuccess}
      onFailure={window.__onPasswordFailure}
    />
  );
}
