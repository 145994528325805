import hopinApi from '@api/hopin';
import {
  ChatMessages,
  ConnectionRequests,
  NetworkingMeetings,
  ScheduledMeetings,
} from '@features/dashboard/common/all-metrics';
import {
  MetricList,
  MetricListWrapper,
} from '@features/dashboard/styles/metric-list.styles';
import { number } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../../util/hooks';

const KeyMetrics = ({ eventId }) => {
  const [stats, setStats] = useState({
    total_chat_messages: 0,
    total_networking_meetings: 0,
    total_connection_requests: 0,
    total_scheduled_meetings: 0,
  });

  const loading = usePromise(
    signal => hopinApi.getConnectionsKeyMetrics(eventId, signal),
    result => {
      setStats({ ...stats, ...result });
    },
  );

  return (
    <MetricListWrapper>
      <MetricList>
        <ChatMessages
          isLoading={loading}
          value={stats.total_chat_messages}
          color={'DarkOrange'}
        />
        <NetworkingMeetings
          isLoading={loading}
          value={stats.total_networking_meetings}
          color={'Green'}
        />
        <>
          <ConnectionRequests
            isLoading={loading}
            value={stats.total_connection_requests}
          />
          <ScheduledMeetings
            isLoading={loading}
            value={stats.total_scheduled_meetings}
          />
        </>
      </MetricList>
    </MetricListWrapper>
  );
};

KeyMetrics.propTypes = {
  eventId: number.isRequired,
};

export default KeyMetrics;
