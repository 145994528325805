import analyticsApi from '@api/analytics';
import Chart from '@components/icons/chart';
import CardTitle from '@features/dashboard/components/card-title';
import PersonListComponent from '@features/dashboard/components/person-list-component';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import Loading from '@features/overview/analytics/styles/loading-styles';
import { string } from 'prop-types';
import React, { useState } from 'react';

import Card from '../common/overview-card';
import EmptyState from '../styles/empty-state-styles';

const MostActiveAttendeesByMinutesSpent = ({ eventSlug }) => {
  const { t } = useLocalization(
    'overview.most-active-attendees-by-minutes-spent',
  );
  const [attendees, setAttendees] = useState([]);
  const limit = 5;
  const loading = usePromise(
    signal =>
      analyticsApi.getMostActiveAttendeesByMinutesSpent(
        eventSlug,
        signal,
        limit,
      ),
    result => setAttendees(result.data),
  );

  return (
    <Card testId="most-active-attendees-by-minutes-spent">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <Loading isLoading={loading}>
          {attendees.length > 0 ? (
            <PersonListComponent people={attendees} />
          ) : (
            <EmptyState text={t('empty')} icon={<Chart />} />
          )}
        </Loading>
      </CardContentWrapper>
    </Card>
  );
};

MostActiveAttendeesByMinutesSpent.propTypes = {
  eventSlug: string.isRequired,
};

export default MostActiveAttendeesByMinutesSpent;
