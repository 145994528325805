import hopinApi from '@api/hopin';

const OnClickHandler = (addAlert, t) => async () => {
  try {
    await hopinApi.resendEmailConfirmation();
    addAlert({
      active: true,
      text: t(`.resend.success`),
      pattern: 'success',
    });
  } catch (err) {
    addAlert({
      active: true,
      text: t(`.resend.errors.generic`),
      pattern: 'error',
    });
  }
};

const ChallengeFooterContext = ({ addAlert, t }) => {
  return {
    t,
    handleClick: OnClickHandler(addAlert, t),
  };
};

export default ChallengeFooterContext;
