import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Grid } from '@hopin-team/ui-grid';
import { Modal } from '@hopin-team/ui-modal';
import { SelectGroup, SelectItem } from '@hopin-team/ui-select-items';
import { Text } from '@hopin-team/ui-text';
import { bool, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { closeModal } from '@/redux/reducers/modal';

import { useLocalization } from '.';
import { getLanguageOptions } from './language-options';

export const LanguagePicker = ({ isShowing, onClose }) => {
  const { language, setLanguage, t } = useLocalization();
  const [currentOption, setCurrentOption] = useState(language);
  const dispatch = useDispatch();

  const handleLanguageChange = e => {
    setCurrentOption(e.target.value);
  };

  const handleSave = () => {
    setLanguage(currentOption);
    dispatch(closeModal());
  };

  useEffect(() => {
    if (!isShowing && currentOption !== language) {
      setCurrentOption(language);
    }
  }, [currentOption, isShowing, language]);

  return (
    <Modal isShowing={isShowing} onClose={onClose}>
      <Box level="low" px={2} py={4}>
        <Text align="center" element="h2" pattern="headingThree">
          {t('language-picker.heading')}
        </Text>
        <Text align="center" element="p" pattern="body" color="grey-700">
          {t('language-picker.description')}
        </Text>
      </Box>
      <Box background="grey-100" p={2}>
        <SelectGroup
          isSingle
          onChange={handleLanguageChange}
          selectedValue={currentOption}
          name={name}
        >
          <Grid gridGap="0.5rem">
            {getLanguageOptions().map(({ title, option }) => (
              <SelectItem
                id={`${name}--${option}`}
                label={title}
                value={option}
                key={option}
                className="test-id-modal-option"
                p={2}
              />
            ))}
          </Grid>
        </SelectGroup>
      </Box>
      <Box p={2}>
        <Button onClick={handleSave}>{t('language-picker.save')}</Button>
      </Box>
    </Modal>
  );
};

LanguagePicker.propTypes = {
  isShowing: bool,
  onClose: func,
};
