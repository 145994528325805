import { Button } from '@hopin-team/ui-button';
import styled from 'styled-components';

const CustomButton = styled(Button)`
  height: var(--spacing-32);
`;

const LoadingLabel = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
`;

export { CustomButton, LoadingLabel };
