const localizationKeys = {
  Overview: {
    Live: 'overview.notice-text.live',
    Ended: 'overview.notice-text.ended',
  },
  Connections: {
    Live: 'connections-dashboard.notice-text.live',
    Ended: 'connections-dashboard.notice-text.ended',
  },
  Registrations: {
    Draft: 'registrations-dashboard.notice-text.draft',
    Upcoming: 'registrations-dashboard.notice-text.upcoming',
    Live: 'registrations-dashboard.notice-text.live',
    Ended: 'registrations-dashboard.notice-text.ended',
  },
};

const noticeTexts = (t, pageType, eventPhase) => {
  return t(localizationKeys[pageType][eventPhase]);
};

export default noticeTexts;
