import { Box } from '@hopin-team/ui-box';
import { PageButtons } from '@hopin-team/ui-pagination';
import { DeprecatedSelect as Select } from '@hopin-team/ui-select';
import { TextField } from '@hopin-team/ui-text-field';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled(Box)`
  width: 100%;
`;
export const SearchField = styled(TextField).attrs({
  size: 'small',
})`
  div:first-of-type {
    display: none !important;
  }
`;

export const FilterSelect = styled(Select).attrs({
  classNamePrefix: 'FilterSelect',
})`
  .FilterSelect__control {
    border-color: var(--color-gray-300);
  }
`;

export const PaginationNumbers = styled(PageButtons)`
  margin: auto;
`;

export const ButtonPlaceholder = styled.div`
  width: ${rem(30)};
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5rem;
`;

export const BulkActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1.5rem;
`;

export const StyledHeading = styled.span`
  white-space: nowrap;
`;
