import { Card } from '@hopin-team/ui-card';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled from 'styled-components';

export const PlanName = styled(Text)`
  text-transform: capitalize;
`;

export const PaymentPlanCard = styled(Card)`
  box-shadow: none;
  border: ${rem(1)} solid var(--color-gray-200);
  border-radius: var(--border-radius);
  background: var(--color-gray-100);
  min-width: 337px;
  min-height: 208px;
`;

export const StyledSpanIcon = styled.span`
  margin-right: var(${props => props.mr});
  align-items: center;
  border: none;
  display: inline-flex;
`;
