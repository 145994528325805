import styled, { css } from 'styled-components';

import { excludeLegacyBrowsersSelector } from '../../range-selector.styled';
import {
  RANGE_SELECTOR_HEIGHT_PX,
  RANGE_SELECTOR_THUMB_WIDTH_PX,
  THUMB_COLOR,
  THUMB_COLOR_ON_FOCUS_VISIBLE,
} from '../container.styled';

/* The following posts explain the research behind the CSS below. Well worth a read:
 * https://css-tricks.com/multi-thumb-sliders-particular-two-thumb-case/
 * https://css-tricks.com/multi-thumb-sliders-general-case/
 * https://css-tricks.com/sliding-nightmare-understanding-range-input/
 */

const cssTrack = css`
  background: none;
  box-sizing: border-box;
  height: ${RANGE_SELECTOR_HEIGHT_PX}px;
  width: 100%;
  border: none;
`;

const cssThumb = css`
  background: var(--color-white);
  border: 3px solid ${THUMB_COLOR};
  box-sizing: border-box;
  cursor: col-resize;
  pointer-events: auto; /* catch clicks */
  width: ${RANGE_SELECTOR_THUMB_WIDTH_PX}px;
  height: ${RANGE_SELECTOR_HEIGHT_PX}px;
`;

const cssThumbStart = css`
  border-radius: 4px 0px 0px 4px;
`;

const cssThumbEnd = css`
  border-radius: 0px 4px 4px 0px;
`;

export const StyledOverlaidRangeInput = styled.input.attrs({
  type: 'range',
})`
  &,
  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  height: auto;
  padding: 0;
  border: 0;
  z-index: 1;
  margin: 0;

  cursor: col-resize;

  &${excludeLegacyBrowsersSelector} {
    cursor: auto;
  }

  grid-column: 1;
  grid-row: 2;

  background: none; /* get rid of white Chrome background */
  color: #000;
  font: inherit; /* fix too small font-size in both Chrome & Firefox */
  pointer-events: none; /* let clicks pass through */

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    ${cssTrack};
  }
  &::-moz-range-track {
    ${cssTrack};
  }
  &::-ms-track {
    ${cssTrack};
    color: transparent;
  } /* 'transparent' removes tick marks */

  &::-ms-fill-lower {
    background: none;
  }

  &::-webkit-slider-thumb {
    ${cssThumb};
  }
  &::-moz-range-thumb {
    ${cssThumb};
  }
  &::-ms-thumb {
    ${cssThumb};
  }

  /* START: making the thumbs different colour when focusing with tab */
  /* Fallback for browser that do not support :focus-visible */
  &:focus::-webkit-slider-thumb,
  &:active::-webkit-slider-thumb {
    border-color: ${THUMB_COLOR_ON_FOCUS_VISIBLE};
  }
  &:focus::-moz-range-thumb,
  &:active::-moz-range-thumb {
    border-color: ${THUMB_COLOR_ON_FOCUS_VISIBLE};
  }
  &:focus::-ms-thumb,
  &:active::-ms-thumb {
    border-color: ${THUMB_COLOR_ON_FOCUS_VISIBLE};
  }

  /* For browsers that support :focus-visible, on :focus regular styles */
  &:focus:not(:focus-visible)::-webkit-slider-thumb {
    border-color: ${THUMB_COLOR};
  }
  &:focus:not(:focus-visible)::-moz-range-thumb {
    border-color: ${THUMB_COLOR};
  }
  &:focus:not(:focus-visible)::-ms-thumb {
    border-color: ${THUMB_COLOR};
  }

  /* For browsers that support :focus-visible, on :focus-visible different styles */
  &:focus-visible::-webkit-slider-thumb {
    border-color: ${THUMB_COLOR_ON_FOCUS_VISIBLE};
  }
  &:focus-visible::-moz-range-thumb {
    border-color: ${THUMB_COLOR_ON_FOCUS_VISIBLE};
  }
  &:focus-visible::-ms-thumb {
    border-color: ${THUMB_COLOR_ON_FOCUS_VISIBLE};
  }
  /* END: making the thumbs different colour when focusing with tab */

  &:nth-of-type(2n + 1) {
    &::-webkit-slider-thumb {
      ${cssThumbStart};
    }
    &::-moz-range-thumb {
      ${cssThumbStart};
    }
    &::-ms-thumb {
      ${cssThumbStart};
    }
  }

  &:nth-of-type(2n) {
    &::-webkit-slider-thumb {
      ${cssThumbEnd};
    }
    &::-moz-range-thumb {
      ${cssThumbEnd};
    }
    &::-ms-thumb {
      ${cssThumbEnd};
    }
  }
`;
