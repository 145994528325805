import { MenuSelectItem } from '@hopin-team/ui-menu';
import { SplitButton as PlanetarySplitButton } from '@hopin-team/ui-split-button';
import styled from 'styled-components';

export const SplitButton = styled(PlanetarySplitButton)`
  margin-right: 1rem;

  ${MenuSelectItem} {
    white-space: nowrap;
    min-width: 14rem;
    cursor: pointer;
  }
`;
