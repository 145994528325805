export const images = {
  online: {
    '1x': require(`event-creation-wizard/format-selector/online_event@1x.png`),
    '2x': require(`event-creation-wizard/format-selector/online_event@2x.png`),
  },
  hybrid: {
    '1x': require(`event-creation-wizard/format-selector/hybrid_event@1x.png`),
    '2x': require(`event-creation-wizard/format-selector/hybrid_event@2x.png`),
  },
  onsite: {
    '1x': require(`event-creation-wizard/format-selector/onsite_event@1x.png`),
    '2x': require(`event-creation-wizard/format-selector/onsite_event@2x.png`),
  },
};
