const registrationUrl = 'http://hopin.com/events/12345-event-slug';

const backstageOptions = [
  {
    label: 'Stage #1',
    value: '548fde53-34c9-4f3d-bd58-9cc73067571c',
  },
  {
    label: 'Stage #2',
    value: '4976b718-05f3-43af-bb1f-8f40214fd9d9',
  },
  {
    label: 'Stage #3 - IVS Links Not Yet Requested',
    value: '4721973b-c922-4d5a-aaa7-26a8cf9e9d5b',
  },
  {
    label: 'Stage #4 - RingCentral Events Studio not yet created',
    value: '4721973b-c922-4d5a-aaa7-26a8cf9e9d5b',
  },
];

const hopinStudioLinks = {
  '548fde53-34c9-4f3d-bd58-9cc73067571c': {
    hopinStudioUrl:
      'https://www.hopin.com/events/event-slug/backstages/548fde53-34c9-4f3d-bd58-9cc73067571c',
  },
  '4976b718-05f3-43af-bb1f-8f40214fd9d9': {
    hopinStudioUrl:
      'https://www.hopin.com/events/event-slug/backstages/4976b718-05f3-43af-bb1f-8f40214fd9d9',
  },
  '4721973b-c922-4d5a-aaa7-26a8cf9e9d5b': {
    hopinStudioUrl:
      'https://www.hopin.com/events/event-slug/backstages/4721973b-c922-4d5a-aaa7-26a8cf9e9d5b',
  },
  '4721973b-c922-4d5a-aaa7-26a8cf9e9d5c': {
    hopinStudioUrl: null,
  },
};

const createIvsVideoChannelLinks = {
  '548fde53-34c9-4f3d-bd58-9cc73067571c': {
    createIvsVideoChannelUrl:
      'https://www.hopin.com/organisers/events/49/backstages/70/create_ivs_video_channel',
  },
  '4976b718-05f3-43af-bb1f-8f40214fd9d9': {
    createIvsVideoChannelUrl:
      'https://www.hopin.com/organisers/events/49/backstages/74/create_ivs_video_channel',
  },
  '4721973b-c922-4d5a-aaa7-26a8cf9e9d5b': {
    createIvsVideoChannelUrl:
      'https://www.hopin.com/organisers/events/49/backstages/75/create_ivs_video_channel',
  },
};

const muxLinks = {
  '548fde53-34c9-4f3d-bd58-9cc73067571c': {
    streamKey: '06aecd02-c49b-63e9-a799-220f05362544',
    rtmpServerUrl: 'rtmp://global-live.mux.com:5222/app',
    rtmpsServerUrl: 'rtmps://global-live.mux.com:443/app',
  },
  '4976b718-05f3-43af-bb1f-8f40214fd9d9': {
    streamKey: '36f21960-1c78-6c28-50c7-66e22befe856',
    rtmpServerUrl: 'rtmp://global-live.mux.com:5222/app',
    rtmpsServerUrl: 'rtmps://global-live.mux.com:443/app',
  },
  '4721973b-c922-4d5a-aaa7-26a8cf9e9d5b': {
    streamKey: 'c0c5c736-fbec-e855-6384-73df86ec1c1c',
    rtmpServerUrl: 'rtmp://global-live.mux.com:5222/app',
    rtmpsServerUrl: 'rtmps://global-live.mux.com:443/app',
  },
};

const ivsLinks = {
  '548fde53-34c9-4f3d-bd58-9cc73067571c': {
    streamKey: 'sk_us-east-1_NePZAoKVwQFs_UsVX8OGPjqxNRjiQ6craECIQrXLZPx',
    rtmpsServerUrl:
      'rtmps://167b4551ff40.global-contribute.live-video.net:443/app',
  },
  '4976b718-05f3-43af-bb1f-8f40214fd9d9': {
    streamKey: 'sk_us-east-1_gOR1477VcAsL_5de1ODNKa9fweR5Lt5mQRag7alNkQH',
    rtmpsServerUrl:
      'rtmps://167b4551ff40.global-contribute.live-video.net:443/app',
  },
};

const stageUrls = {
  '548fde53-34c9-4f3d-bd58-9cc73067571c': {
    stageUrl: 'https://www.hopin.com/organisers/events/01/backstages/01/edit',
  },
  '4976b718-05f3-43af-bb1f-8f40214fd9d9': {
    stageUrl: 'https://www.hopin.com/organisers/events/01/backstages/02/edit',
  },
  '4721973b-c922-4d5a-aaa7-26a8cf9e9d5b': {
    stageUrl: 'https://www.hopin.com/organisers/events/01/backstages/03/edit',
  },
  '4721973b-c922-4d5a-aaa7-26a8cf9e9d5c': {
    stageUrl: 'https://www.hopin.com/organisers/events/01/backstages/04/edit',
  },
};

export {
  registrationUrl,
  backstageOptions,
  hopinStudioLinks,
  createIvsVideoChannelLinks,
  muxLinks,
  ivsLinks,
  stageUrls,
};
