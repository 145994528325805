import logger, { LOGGER_NAMES } from '@util/logger';
import { useEffect } from 'react';

const useMomentLocale = locale => {
  // Requiring the translation files to auto-localize charts that depend on moment
  useEffect(() => {
    // en translation file doesnt exist
    if (locale.match(/^en/)) return;

    try {
      require('moment/locale/' + locale);
    } catch (err) {
      logger(LOGGER_NAMES.ANALYTICS).error(err);
    }
  }, [locale]);
};

export default useMomentLocale;
