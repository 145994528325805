import CreateEventButton from '@components/create-event-button/create-event-button';
import { Card } from '@hopin-team/ui-card';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import Event from 'icons/event.svg';
import { func, string } from 'prop-types';
import React from 'react';

import { IconBadge, LinkWrapper } from './styles';

const translationKeyPrefix = 'onboarding.organization-dashboard.create-event';

const CreateEvent = ({ currentColor, onClick, t }) => {
  return (
    <Card p="3" data-testid="create-event">
      <Flex justifyContent="center" mb={2}>
        <IconBadge>
          <Event color={currentColor} />
        </IconBadge>
      </Flex>

      <Flex justifyContent="center">
        <Text
          element="h4"
          pattern="headingFour"
          color="grey-800"
          weight="medium"
        >
          {t(`${translationKeyPrefix}.title`)}
        </Text>
      </Flex>

      <Flex justifyContent="center" mb={2}>
        <Text element="span" color="grey-800" pattern="bodyOne" scale={4}>
          {t(`${translationKeyPrefix}.clickHere`)}
        </Text>
      </Flex>

      <Flex justifyContent="center" mb={1}>
        <LinkWrapper>
          <CreateEventButton onClick={onClick} />
        </LinkWrapper>
      </Flex>
    </Card>
  );
};

CreateEvent.propTypes = {
  currentColor: string,
  onClick: func,
  t: func.isRequired,
};

export default CreateEvent;
