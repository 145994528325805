import ImagePanel from '@features/event-creation-wizard/components/image-panel';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { useStepsInfo } from '@features/event-creation-wizard/shared/useStepsInfo';
import { useLocalization } from '@features/localization';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Image = styled.img`
  object-fit: contain;
  max-height: 100%;
`;

const EventCreationWizardImage = () => {
  const { t } = useLocalization('event-creation-wizard.common');
  const templateId = useSelector(state => state.template.id);
  const { currentStep } = useStepsInfo();

  const template = templates[templateId] || templates['conference'];

  const step =
    template.steps.find(step => step.name === currentStep) || template.steps[0];

  const stepImages = step.options.images;

  const {
    image1x,
    image2x,
    image3x,
    horizontalAlign = 'center',
    verticalAlign = 'center',
    noPadding = false,
  } = stepImages;

  const srcSet = `${image1x} 1x, ${image2x} 2x, ${image3x} 3x`;

  return (
    <ImagePanel
      horizontalAlign={horizontalAlign}
      verticalAlign={verticalAlign}
      padding={noPadding ? 0 : 32}
    >
      <Image src={image1x} srcSet={srcSet} alt={t('image-alt')} />
    </ImagePanel>
  );
};

export default EventCreationWizardImage;
