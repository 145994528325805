import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const ConfirmationItemText = styled(Text).attrs({
  color: 'grey-800',
  scale: 4,
})`
  position: relative;
  top: -4px;
`;
