export const firstPage = {
  attendees: [
    {
      id: 177,
      name: 'Barbar Wiza',
      email: 'lakita@frami.co',
      event_track: 'Toughjoyfax-35221',
      ticket_type: 'Mat Lam Tam',
      price_paid: '$677.60',
      promo_code: 'TODO',
      redeem_code_external_id: 'gf2fdas4',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 176,
      name: 'Wilma Von',
      email: 'danial.dare@huel.biz',
      event_track: 'Voltsillam-1141',
      ticket_type: 'Matsoft',
      price_paid: '$686.78',
      promo_code: 'TODO',
      redeem_code_external_id: 'fg2fdas2',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
      blocked: true,
    },
    {
      id: 175,
      name: 'Evangeline Jakubowski',
      email: 'maegan@runolfsdottir.info',
      event_track: null,
      ticket_type: 'Treeflex',
      price_paid: '$282.07',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 174,
      name: 'Romaine Stracke',
      email: 'miquel_homenick@mayert.io',
      event_track: 'Job-85809',
      ticket_type: 'Flowdesk',
      price_paid: '$261.72',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 173,
      name: 'Gregory Haley',
      email: 'lucius_herzog@hilpert-kilback.co',
      event_track: null,
      ticket_type: 'Home Ing',
      price_paid: '$648.67',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 172,
      name: 'Chadwick Reilly',
      email: 'tobi@bins.net',
      event_track: null,
      ticket_type: 'Home Ing',
      price_paid: '$509.64',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 171,
      name: 'Jeffrey Osinski',
      email: 'merlene_langosh@pacocha-heaney.io',
      event_track: 'Job-4477',
      ticket_type: 'Wrapsafe',
      price_paid: '$319.61',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 170,
      name: 'Devora Hoeger',
      email: 'gricelda@mante.info',
      event_track: null,
      ticket_type: 'Home Ing',
      price_paid: '$99.08',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 169,
      name: 'Emmett Hartmann',
      email: 'sondra@tromp.name',
      event_track: 'Toughjoyfax-35221',
      ticket_type: 'Zamit',
      price_paid: '$949.65',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 168,
      name: 'Rachelle Hegmann',
      email: 'buster.ryan@cummerata.org',
      event_track: null,
      ticket_type: 'Treeflex',
      price_paid: '$687.24',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 167,
      name: 'Emmaline Kub',
      email: 'tiffanie@stracke-legros.org',
      event_track: 'Voltsillam-1141',
      ticket_type: 'Voyatouch',
      price_paid: '$88.24',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 166,
      name: 'Winston Green',
      email: 'issac@macgyver-bernhard.co',
      event_track: 'Job-4477',
      ticket_type: 'Wrapsafe',
      price_paid: '$497.56',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 165,
      name: 'Calista Pfeffer',
      email: 'ghislaine@mccullough.net',
      event_track: null,
      ticket_type: 'Treeflex',
      price_paid: '$336.32',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 164,
      name: 'Carley Herman',
      email: 'issac@smitham.co',
      event_track: 'Voltsillam-1141',
      ticket_type: 'Matsoft',
      price_paid: '$716.09',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 163,
      name: 'Jackie Heathcote',
      email: 'tyler@bechtelar.org',
      event_track: 'Job-4477',
      ticket_type: 'Fixflex',
      price_paid: '$765.83',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 162,
      name: 'Blair Roob',
      email: 'ute_welch@durgan.biz',
      event_track: null,
      ticket_type: 'Cardify',
      price_paid: '$722.38',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 161,
      name: 'Eliz Green',
      email: 'simon@carter.info',
      event_track: 'Toughjoyfax-35221',
      ticket_type: 'Zamit',
      price_paid: '$469.69',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 160,
      name: 'Herman Stoltenberg',
      email: 'claude@fisher.net',
      event_track: null,
      ticket_type: 'Home Ing',
      price_paid: '$990.65',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 159,
      name: 'Therese Hintz',
      email: 'antionette.bashirian@zboncak.biz',
      event_track: null,
      ticket_type: 'Home Ing',
      price_paid: '$549.99',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
    {
      id: 158,
      name: 'Sterling Hills',
      email: 'claudio.kihn@ziemann.com',
      event_track: null,
      ticket_type: 'Treeflex',
      price_paid: '$595.25',
      promo_code: 'TODO',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
  ],
  pagy: { current_page: 1, total_pages: 6, count: 102 },
};

const firstAttendee = firstPage.attendees[0];

export const notBlockedAttendeeData = {
  id: firstAttendee.id,
  first_name: firstAttendee.name.split(' ')[0],
  last_name: firstAttendee.name.split(' ')[1],
  full_name: firstAttendee.name,
  email: firstAttendee.email,
  ticket_type: { label: firstAttendee.ticket_type, value: 50 },
  price_paid: firstAttendee.price_paid,
  promo_code: firstAttendee.promo_code,
  created_at_time: firstAttendee.created_at_time,
  event_track: { label: firstAttendee.event_track, value: 2 },
};

const secondAttendee = firstPage.attendees[1];

export const blockedAttendeeData = {
  id: secondAttendee.id,
  first_name: secondAttendee.name.split(' ')[0],
  last_name: secondAttendee.name.split(' ')[1],
  full_name: secondAttendee.name,
  email: secondAttendee.email,
  ticket_type: { label: secondAttendee.ticket_type, value: 50 },
  price_paid: secondAttendee.price_paid,
  promo_code: secondAttendee.promo_code,
  created_at_time: secondAttendee.created_at_time,
  event_track: { label: secondAttendee.event_track, value: 2 },
  blocked: true,
};

export const secondPage = {
  attendees: [
    {
      id: 387,
      name: 'Frank Einstein',
      email: 'fakit@frami.co',
      event_track: 'Toughjoyfax-35221',
      ticket_type: 'Mat Lam Tam',
      price_paid: '$677.60',
      promo_code: 'TODO',
      redeem_code_external_id: 'fg2fdas2',
      created_at_date: '25 Feb 2021',
      created_at_time: '04:05 PM',
    },
  ],
  pagy: { current_page: 1, total_pages: 6, count: 102 },
};
