import { useLocalization } from '@features/localization';
import { TextField } from '@hopin-team/ui-text-field';
import { func, object } from 'prop-types';
import React from 'react';

const ExpoFormComponent = ({ register, errors = {}, onSubmit }) => {
  const { t } = useLocalization('event-creation-wizard.steps.expo.form');

  return (
    <form onSubmit={onSubmit} data-testid="expo-form">
      <TextField
        mb={3.5}
        ref={register}
        name="name"
        data-testid="name"
        label={t('name.label')}
        hasErrors={Boolean(errors['name'])}
        placeholder={t('name.placeholder')}
        errorMessage={errors['name']?.message}
        isRequired
      />

      <TextField
        mb={3.5}
        name="email"
        ref={register}
        data-testid="email"
        label={t('email.label')}
        placeholder={t('email.placeholder')}
        hasErrors={Boolean(errors?.email)}
        errorMessage={errors?.email?.message}
        isRequired
      />

      <TextField
        mb={3.5}
        ref={register}
        name="description"
        data-testid="description"
        label={t('description.label')}
        hasErrors={Boolean(errors['description'])}
        placeholder={t('description.placeholder')}
        errorMessage={errors['description']?.message}
        isRequired
      />
    </form>
  );
};

ExpoFormComponent.propTypes = {
  errors: object,
  register: func.isRequired,
  onSubmit: func.isRequired,
};

export default ExpoFormComponent;
