import Card from '@components/card-with-loading';
import { useLocalization } from '@features/localization';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../dashboard/util/hooks';
import {
  getEventAreas,
  getLiveAnalyticsChartData,
} from '../dashboard/util/services';
import ActiveUsersChartComponent from './active-users-chart-component';

const transformAreaOptions = response =>
  response.areas.map(area => {
    return {
      label: area.name,
      value: `${area.type} ${area.id || ''}`.trim(),
    };
  });

const ActiveUsersChartContainer = ({ eventId, renameInvitedToPending }) => {
  const keyPrefix = renameInvitedToPending
    ? 'active-attendees-by-time'
    : 'active-users-by-time';

  const { t } = useLocalization(`live-analytics.${keyPrefix}`);

  const [areaOptions, setAreaOptions] = React.useState([]);
  const [selectedArea, setSelectedArea] = React.useState(null);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [chartData, setChartData] = React.useState({});
  const [defaultEndTime, setDefaultEndTime] = React.useState(null);
  const [chartFormVersion, setChartFormVersion] = useState(0);

  const loadingAreaOptions = usePromise(
    signal => getEventAreas(eventId, signal),
    result => setAreaOptions(transformAreaOptions(result)),
  );

  const loadingChart = usePromise(
    signal =>
      getLiveAnalyticsChartData(
        eventId,
        selectedArea,
        startTime ? startTime.toISOString() : null,
        endTime ? endTime.toISOString() : null,
        defaultEndTime ? defaultEndTime.toISOString() : null,
        signal,
      ),
    result => {
      const { chart, default_end_time, start_time, end_time } = result;
      setChartData(chart);
      setDefaultEndTime(new Date(default_end_time));
      setStartTime(new Date(start_time));
      setEndTime(new Date(end_time));
    },
    [chartFormVersion],
  );

  const loading = loadingAreaOptions || loadingChart;
  const firstLoading = loading && chartFormVersion == 0;

  return (
    <Card isLoading={firstLoading}>
      <h3 className="heading -level-3">{t('title')}</h3>

      <ActiveUsersChartComponent
        chartData={chartData}
        reloadChart={() => setChartFormVersion(chartFormVersion + 1)}
        areaOptions={areaOptions}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        inProgress={loading}
      />
    </Card>
  );
};

ActiveUsersChartContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  renameInvitedToPending: PropTypes.bool.isRequired,
};

export default ActiveUsersChartContainer;
