export const getGroupedLiquidTagConfig = ({ t }) => {
  const groupedLiquidTagConfig = {
    dropdown: {
      label: 'Custom Variables',
      class: 'ck-liquid-tag-dropdown',
    },
    groupedTags: [
      {
        key: 'event-data',
        label: t(`email-classic-editor.liquid-tags.group-labels.event-data`),
        tags: [
          {
            key: 'event-name',
            label: t(`email-classic-editor.liquid-tags.event-name.label`),
            content: '{{ event.name }}',
            tooltip: t(`email-classic-editor.liquid-tags.event-name.tooltip`),
          },
          {
            key: 'event-description',
            label: t(
              `email-classic-editor.liquid-tags.event-description.label`,
            ),
            content: '{{ event.description }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.event-description.tooltip`,
            ),
          },
          {
            key: 'event-start-time',
            label: t(`email-classic-editor.liquid-tags.event-start-time.label`),
            content: '{{ event.start_time }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.event-start-time.tooltip`,
            ),
          },
          {
            key: 'event-end-time',
            label: t(`email-classic-editor.liquid-tags.event-end-time.label`),
            content: '{{ event.end_time }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.event-end-time.tooltip`,
            ),
          },
          {
            key: 'event-post-event-end-time',
            label: t(
              `email-classic-editor.liquid-tags.event-post-event-end-time.label`,
            ),
            content: '{{ event.post_event_time_end }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.event-post-event-end-time.tooltip`,
            ),
          },
          {
            key: 'event-location',
            label: t(`email-classic-editor.liquid-tags.event-location.label`),
            content: '{{ event.location }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.event-location.tooltip`,
            ),
          },
          {
            key: 'event-logo',
            label: t(`email-classic-editor.liquid-tags.event-logo.label`),
            imageSrc: '{{ event.logo }}',
            imageAlt:
              '{{ event.name }} ' +
              t(`email-classic-editor.liquid-tags.event-logo.image-alt`),
            tooltip: t(`email-classic-editor.liquid-tags.event-logo.tooltip`),
          },
          {
            key: 'event-original-picture',
            label: t(
              `email-classic-editor.liquid-tags.event-original-picture.label`,
            ),
            imageSrc: '{{ event.original_picture }}',
            imageAlt:
              '{{ event.name }} ' +
              t(
                `email-classic-editor.liquid-tags.event-original-picture.image-alt`,
              ),
            tooltip: t(
              `email-classic-editor.liquid-tags.event-original-picture.tooltip`,
            ),
          },
          {
            key: 'event-medium-picture',
            label: t(
              `email-classic-editor.liquid-tags.event-medium-picture.label`,
            ),
            imageSrc: '{{ event.medium_picture }}',
            imageAlt:
              '{{ event.name }} ' +
              t(
                `email-classic-editor.liquid-tags.event-medium-picture.image-alt`,
              ),
            tooltip: t(
              `email-classic-editor.liquid-tags.event-medium-picture.tooltip`,
            ),
          },
          {
            key: 'event-link',
            label: t(`email-classic-editor.liquid-tags.event-link.label`),
            content: t(`email-classic-editor.liquid-tags.event-link.content`),
            linkHref: '{{ event.link }}',
            tooltip: t(`email-classic-editor.liquid-tags.event-link.tooltip`),
          },
          {
            key: 'event-calendar-link',
            label: t(
              `email-classic-editor.liquid-tags.event-calendar-link.label`,
            ),
            content: t(
              `email-classic-editor.liquid-tags.event-calendar-link.content`,
            ),
            tooltip: t(
              `email-classic-editor.liquid-tags.event-calendar-link.tooltip`,
            ),
            linkHref: '{{ event.calendar_link }}',
          },
          {
            key: 'event-replay-link',
            label: t(
              `email-classic-editor.liquid-tags.event-replay-link.label`,
            ),
            content: t(
              `email-classic-editor.liquid-tags.event-replay-link.content`,
            ),
            linkHref: '{{ event.replay_link }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.event-replay-link.tooltip`,
            ),
          },
        ],
      },
      {
        key: 'user-data',
        label: t(`email-classic-editor.liquid-tags.group-labels.user-data`),
        tags: [
          {
            key: 'user-name',
            label: t(`email-classic-editor.liquid-tags.user-name.label`),
            content: '{{ user.name }}',
            tooltip: t(`email-classic-editor.liquid-tags.user-name.tooltip`),
          },
          {
            key: 'user-first-name',
            label: t(`email-classic-editor.liquid-tags.user-first-name.label`),
            content: '{{ user.first_name }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.user-first-name.tooltip`,
            ),
          },
          {
            key: 'user-last-name',
            label: t(`email-classic-editor.liquid-tags.user-last-name.label`),
            content: '{{ user.last_name }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.user-last-name.tooltip`,
            ),
          },
        ],
      },
      {
        key: 'organiser-data',
        label: t(
          `email-classic-editor.liquid-tags.group-labels.organiser-data`,
        ),
        tags: [
          {
            key: 'organiser-name',
            label: t(`email-classic-editor.liquid-tags.organiser-name.label`),
            content: '{{ organiser.name }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.organiser-name.tooltip`,
            ),
          },
          {
            key: 'organiser-website',
            label: t(
              `email-classic-editor.liquid-tags.organiser-website.label`,
            ),
            content: '{{ organiser.website }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.organiser-website.tooltip`,
            ),
          },
          {
            key: 'organiser-medium-picture',
            label: t(
              `email-classic-editor.liquid-tags.organiser-medium-picture.label`,
            ),
            imageAlt:
              '{{ organiser.name }} ' +
              t(
                `email-classic-editor.liquid-tags.organiser-medium-picture.image-alt`,
              ),
            linkHref: '{{ organiser.website }}',
            imageSrc: '{{ organiser.medium_picture }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.organiser-medium-picture.tooltip`,
            ),
          },
        ],
      },
      {
        key: 'order-data',
        label: t(`email-classic-editor.liquid-tags.group-labels.order-data`),
        tags: [
          {
            key: 'order-summary',
            label: t(`email-classic-editor.liquid-tags.order-summary.label`),
            content: '{{ order.summary }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.order-summary.tooltip`,
            ),
          },
          {
            key: 'order-join-event-button',
            label: t(
              `email-classic-editor.liquid-tags.order-join-event-button.label`,
            ),
            content: '{{ order.join_event_button }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.order-join-event-button.tooltip`,
            ),
          },
          {
            key: 'registration-qr-code',
            label: t(
              `email-classic-editor.liquid-tags.registration-qr-code.label`,
            ),
            content: '{{ registration.qr_code_image_url }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.registration-qr-code.tooltip`,
            ),
          },
          {
            key: 'ticket-label',
            label: t(`email-classic-editor.liquid-tags.ticket-label.label`),
            content: '{{ ticket.label }}',
            tooltip: t(`email-classic-editor.liquid-tags.ticket-label.tooltip`),
          },
          {
            key: 'ticket-amount',
            label: t(`email-classic-editor.liquid-tags.ticket-amount.label`),
            content: '{{ ticket.amount }}',
            tooltip: t(
              `email-classic-editor.liquid-tags.ticket-amount.tooltip`,
            ),
          },
        ],
      },
    ],
  };

  return groupedLiquidTagConfig;
};
