import CKEditor5 from '@components/ckeditor5/ckeditor5';
import StepHeader from '@features/event-creation-wizard/components/step-header';
import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import { setWelcomeMessage } from '@features/event-creation-wizard/redux/step-specific-state/welcome-message-slice.js';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { bool } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DescriptionComponent = () => {
  const dispatch = useDispatch();
  const storeWelcomeMessage = useSelector(state => state.welcomeMessage.value);
  const templateId = useSelector(state => state.template.id);
  const [message, setMessage] = React.useState(storeWelcomeMessage);
  const { t } = useLocalization('event-creation-wizard');
  const isWebinarEvent = templateId === EXPERIENCE_TEMPLATE.WEBINAR;

  const onSave = () => {
    dispatch(setWelcomeMessage(message));
  };

  const maxFileSize = 10485760;

  return (
    <EventCreationWizardStep
      onSave={onSave}
      info={t('steps.description.info')}
      ctaLabel={t('common.continue')}
    >
      <StepHeader
        title={
          isWebinarEvent
            ? t('steps.description.whats-webinar-about')
            : t('steps.description.whats-event-about')
        }
        subtitle={t('steps.description.subtitle')}
      />

      <Box mb={3}>
        <Text
          pattern="captionBold"
          element="p"
          color="grey-black"
          weight="black"
          mb={0.5}
        >
          {t('steps.description.event-description')}
        </Text>
        <CKEditor5
          name="welcome-message"
          initialValue={message}
          uploadS3PolicyUrl={`${window.location.origin}/account/upload_s3_policy`}
          maxFileSize={maxFileSize}
          onChange={(event, editor) => setMessage(editor.getData())}
        />
      </Box>
    </EventCreationWizardStep>
  );
};

DescriptionComponent.propTypes = {
  canSkip: bool,
};

export default DescriptionComponent;
