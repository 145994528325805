import styled from 'styled-components';

const BreadcrumbsNav = styled.nav`
  display: flex;
`;

const BreadCrumbsList = styled.ol``;

const BreadcrumbItem = styled.li`
  display: inline;
  list-style: none;

  a {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`;

export { BreadcrumbsNav, BreadCrumbsList, BreadcrumbItem };
