import TopBar from '@features/event-creation-wizard/components/top-bar';
import * as P from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { useEventCreationWizard } from '../event-creation-wizard-provider';
import EventCreationWizardFooter from './event-creation-wizard-footer';
import EventCreationWizardMain from './event-creation-wizard-main';

const EventCreationWizardStep = ({
  children,
  canSkip = false,
  canPreview = false,
  breadcrumbsDisabled = false,
  continueDisabled = false,
  onSkip,
  onSave,
  info,
  preview,
  verticallyCentered,
  showPreviewImage,
  ctaLabel,
  ctaPreviewLabel,
}) => {
  const { submitting } = useEventCreationWizard();
  const hasCreateEventError = useSelector(
    state => !!state.createEvent.createEventError,
  );

  return (
    <>
      <TopBar disabled={breadcrumbsDisabled || submitting} />

      <EventCreationWizardMain
        info={info}
        submitting={submitting}
        hasCreateEventError={hasCreateEventError}
        preview={preview}
        verticallyCentered={verticallyCentered}
        showPreviewImage={showPreviewImage}
      >
        {children}
      </EventCreationWizardMain>

      <EventCreationWizardFooter
        canSkip={canSkip}
        canPreview={canPreview}
        disabled={continueDisabled || submitting}
        onSkip={onSkip}
        onSave={onSave}
        ctaLabel={ctaLabel}
        ctaPreviewLabel={ctaPreviewLabel}
      />
    </>
  );
};

EventCreationWizardStep.propTypes = {
  children: P.node.isRequired,
  canSkip: P.bool,
  canPreview: P.bool,
  breadcrumbsDisabled: P.bool,
  continueDisabled: P.bool,
  onSkip: P.func,
  onSave: P.func,
  info: P.string,
  preview: P.element,
  verticallyCentered: P.bool,
  showPreviewImage: P.bool,
  ctaLabel: P.string,
  ctaPreviewLabel: P.string,
};

export default EventCreationWizardStep;
