import { TableHeading } from '@hopin-team/ui-table';
import { bool, node } from 'prop-types';
import React from 'react';

import { RightHeading, UppercaseText } from './styles';

const Heading = ({ children, right, ...props }) => {
  const Container = right ? RightHeading : TableHeading;
  return (
    <Container {...props}>
      <UppercaseText pattern="labelTwo" color="grey-600">
        {children}
      </UppercaseText>
    </Container>
  );
};

Heading.propTypes = {
  children: node,
  right: bool,
};

export default Heading;
