const event = {
  name: 'Test Event',
  externalId: '123',
  id: 1,
  validForDeletion: true,
  slug: 'test-event',
  hasTickets: true,
  draft: false,
  maxPrice: 0,
  picture: 'https://placehold.it/1x1',
  timeStartLocal: 'Dec 12 2021',
  areas: {
    reception: true,
    stage: true,
  },
  organization: {
    currentPaymentPlan: {
      features: {
        hostDefinitions: true,
        name: 'Pro',
        eventDashboardPeopleMenu: true,
        publish_event: true,
      },
    },
    merchantId: 'abc123',
  },
  canDelete: true,
  validForDuplication: true,
  eventWizardTemplate: 'webinar',
  isLite: false,
  primaryBackstageId: 1,
  session: null,
  isAnonymised: false,
};

export default event;
