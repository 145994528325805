import { unparse } from 'papaparse';
import { useMemo } from 'react';

import { FULL_DICTIONARY } from './consts';
import { toCSVRow } from './helpers';

const CSV_HEADERS = [
  FULL_DICTIONARY['exhibitor'],
  FULL_DICTIONARY['exhibitor_email'],
  FULL_DICTIONARY['booth_size'],
  FULL_DICTIONARY['priority'],
  FULL_DICTIONARY['tags'],
];

export const useCSVExport = fileName => {
  const url = useMemo(() => {
    const sampleRow = {
      exhibitor: 'Exhibitor Name',
      exhibitor_email: 'exhibitor@domain.com',
      booth_size: 'medium',
      priority: '100',
      tags: 'tag1, tag2',
    };

    const contents = unparse({
      fields: CSV_HEADERS,
      data: [toCSVRow(sampleRow)],
    });

    return URL.createObjectURL(new File([contents], fileName));
  }, [fileName]);

  return { url };
};
