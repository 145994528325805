import { getColorPattern } from '@components/notification-toast/notification-toast-helpers';
import { Text } from '@hopin-team/ui-text';
import { Toast } from '@hopin-team/ui-toast';
import { func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledToast = styled(Toast)`
  min-width: 26.5rem;
`;

const NotificationToast = ({ text, pattern, onClose }) => (
  <StyledToast
    iconColor="grey-white"
    icon={pattern === 'success' ? 'checkmark' : 'alert'}
    colorPattern={getColorPattern(pattern)}
    withCloseButton
    onClose={onClose}
  >
    <Text scale={4} data-testid="notification-toast-text" color="grey-white">
      {text}
    </Text>
  </StyledToast>
);

NotificationToast.propTypes = {
  text: node.isRequired,
  onClose: func.isRequired,
  pattern: string,
};

export default NotificationToast;
