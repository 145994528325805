import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { Icon } from '@hopin-team/ui-icon';
import { Image } from '@hopin-team/ui-image';
import { NextButton, Pagination, PrevButton } from '@hopin-team/ui-pagination';
import { Text } from '@hopin-team/ui-text';
import { array, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { TEMPLATE_EVENT_PARTS } from '../constants';
import { steps } from './constants';

//placeholders until planetary component can be updated
export const StyledPrevButton = styled(PrevButton)`
  color: ${({ disabled }) => (disabled ? 'var(--color-gray-300);' : null)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
`;

export const StyledNextButton = styled(NextButton)`
  color: ${({ disabled }) => (disabled ? 'var(--color-gray-300);' : null)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
`;

export const TemplateModalPreview = ({ goToStep, template }) => {
  const { t } = useLocalization('templates');
  const [currentImageIndex, setImage] = useState(0);
  const isVenueImage = currentImageIndex === 0;

  const useTemplate = () => {
    goToStep(steps.FORM);
  };

  const {
    attendees,
    description,
    enabledEventParts,
    name,
    venueImage1x,
    venueImage2x,
    venueImage3x,
    registrationImage1x,
    registrationImage2x,
    registrationImage3x,
  } = template;

  const alt = isVenueImage
    ? 'preview of the venue screen'
    : 'preview of the event registration screen';

  const srcSet = isVenueImage
    ? `${venueImage1x} 1x, ${venueImage2x} 2x, ${venueImage3x} 3x`
    : `${registrationImage1x} 1x, ${registrationImage2x} 2x, ${registrationImage3x} 3x`;

  return (
    <Box pt={6} pr={6} pb={6} pl={4.5} data-testid="template-modal-preview">
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text
          element="h2"
          font="text"
          id="preview-event-modal-heading"
          pattern="headingOne"
        >
          {name}
        </Text>
        <Button isInline size="medium" type="button" onClick={useTemplate}>
          {t('useTemplate')}
        </Button>
      </Flex>
      <Flex justifyContent="space-between">
        <Box mr={2}>
          <Text weight="bold" font="text">
            {t('idealFor')}
          </Text>
          <Text element="p" pattern="body" mt={1} color="grey-700">
            {description}
          </Text>
          <Flex alignItems="center" my={4}>
            <Icon name="users" color="grey-700" />
            <Text element="p" pattern="body" mx={1}>
              {attendees}
            </Text>
          </Flex>
          <Text weight="bold" font="text">
            {t('whatsEnabled')}
          </Text>
          {enabledEventParts.map(eventPart => (
            <Flex key={eventPart} my={2}>
              <Halon
                color={`${TEMPLATE_EVENT_PARTS[eventPart].color}-100`}
                icon={TEMPLATE_EVENT_PARTS[eventPart].icon}
                iconColor={
                  `${TEMPLATE_EVENT_PARTS[eventPart].color}-400` ||
                  `${TEMPLATE_EVENT_PARTS[eventPart].color}-500`
                }
                iconScale={2}
                isSquare
                scale={5}
              />
              <Flex justifyContent="center" flexDirection="column">
                <Text color="grey-800" pattern="headingSix" mx={1}>
                  {TEMPLATE_EVENT_PARTS[eventPart].name}
                </Text>
                <Text color="grey-700" element="p" pattern="body" mx={1}>
                  {TEMPLATE_EVENT_PARTS[eventPart].tagline}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Flex flexBasis="72%" flexShrink="0">
          <Box background="grey-100" cornerRadius="sm" px={3} pb={2}>
            <Flex justifyContent="flex-start" my={2}>
              <Box background="grey-white">
                <Pagination currentPage={1} onChange={setImage} totalPages={2}>
                  <StyledPrevButton disabled={isVenueImage} />
                  <Text mx={1} pattern="labelFour">
                    {isVenueImage ? 'Venue' : 'Registration'}
                  </Text>
                  <StyledNextButton disabled={!isVenueImage} />
                </Pagination>
              </Box>
            </Flex>
            <Image alt={alt} srcSet={srcSet} />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

TemplateModalPreview.propTypes = {
  goToStep: func.isRequired,
  template: shape({
    name: string.isRequired,
    description: string.isRequired,
    attendees: string.isRequired,
    enabledEventParts: array.isRequired,
  }).isRequired,
};
