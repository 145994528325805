import { IconContainer } from '@components/tabbed-chart/styles';
import { Icon } from '@hopin-team/ui-icon';
import { string } from 'prop-types';
import React from 'react';

const TabIcon = ({ name }) => {
  return (
    <IconContainer>
      <Icon name={name} color="grey-white" scale={3} />
    </IconContainer>
  );
};

TabIcon.propTypes = {
  name: string.isRequired,
};

export default TabIcon;
