import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  reports: null,
  generatingStatus: {
    status: null,
  },
  generatingTimes: {},
};

const { actions, reducer } = createSlice({
  name: 'reports',
  initialState: INITIAL_STATE,
  reducers: {
    generatedStatus: (state, action) => ({
      ...state,
      generatingStatus: {
        ...action.payload,
      },
    }),
    generatedTimes: (state, action) => ({
      ...state,
      generatingTimes: {
        ...state.generatingTimes,
        ...action.payload,
      },
    }),
    reportsFetched: (state, action) => ({
      ...state,
      reports: action.payload,
    }),
    reportRequested: (state, action) => {
      const report = action.payload;
      state?.reports?.categories?.forEach(category => {
        const index = category.reports.findIndex(
          item => item.report_type === report.report_type,
        );
        // Skip substitution if the report_type was not found
        if (index > -1) category.reports.splice(index, 1, report);
      });
      return state;
    },
  },
});

export const {
  generatedStatus,
  generatedTimes,
  reportsFetched,
  reportRequested,
} = actions;

export default reducer;
