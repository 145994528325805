export const EVENT = {
  STEP_VISITED: 'event_wizard_step_visited',
  EVENT_TEMPLATE_SELECTED: 'event_template_selected',
  BACK_BUTTON_PRESSED: 'back_button_pressed',
  MODAL_CLOSED: 'modal_closed',
  STEP_SKIPPED: 'step_skipped',
  EVENT_SETUP_SUCCESSFUL: 'event_creation_wizard_event_created',
  EVENT_SETUP_UNSUCCESSFUL: 'event_setup_unsuccessful',
  EVENT_PUBLISHED: 'event_creation_wizard_event_published',
  EVENT_SETUP_CREATE: 'event_setup_create',
  TEMPLATE_OPT_IN: 'template_opt_in',
  ONBOARDING_COMPLETED: 'onboarding_completed',
  MISSING_FEATURE_SUBMITTED: 'missing_feature_submitted',
  SETUP_STARTED_FROM_PREVIEW: 'setup_started_from_preview',
  TEMPLATE_PREVIEW: 'template_preview',
  EXPERIMENT_VIEWED: 'experiment_viewed',
  EVENT_FORMAT_SELECTED: 'event_format_selected',
  COMPLEXITY_SELECTED: 'event_complexity_selected',
};
