import Loading from '@components/loading';
import Alerts from '@features/alerts/alerts';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { ReactIDProvider } from '@hopin-team/react-id';
import { Box } from '@hopin-team/ui-box';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RECORDING_TYPE } from '../constants';
import Filter from '../filter';
import { useRecordingAreas, useRecordingsFilters } from '../hooks';
import { NoResults } from '../no-results';
import { FullHeightFlex } from '../recording-group/partials/full-height-flex.styled';
import { RecordingGroupContainer } from '../recording-group/recording-group-container';
import { withAuthProvider } from '../redux/slices/auth/with-auth';
import {
  getEditThumbnailIsShowing,
  getEditThumbnailSelectedRecordingId,
} from '../redux/slices/edit-thumbnail/edit-thumbnail-selectors';
import {
  setIsShowing as setThumbnailSidePanelIsShowing,
  setRecordingId as setSelectedRecordingId,
} from '../redux/slices/edit-thumbnail/edit-thumbnail-slice';
import { selectEvent } from '../redux/slices/event/event-selectors';
import { withEventProvider } from '../redux/slices/event/with-event';
import { getRecordingById } from '../redux/slices/recordings/recordings-selectors';
import { withRegistrationProvider } from '../redux/slices/registration/with-registration';
import { withReduxProvider } from '../redux/with-provider';
import { ThumbnailSidePanelModal } from '../thumbnail-side-panel-modal';
import { filterVideoAreasByEventPartType } from '../utils/utils';

// TODO: full impllimentation of this is covered in another ticket
const ErrorMessage = ({ ...props }) => {
  return (
    <div {...props}>
      <p>Some helpful error message</p>
    </div>
  );
};

export const RecordingsPagePrimaryComponent = ({ customThumbnailsEnabled }) => {
  const { slug, started, days } = useSelector(selectEvent);
  const { language } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const isThumbnailSidePanelShowing = useSelector(getEditThumbnailIsShowing);
  const selectedRecordingId = useSelector(getEditThumbnailSelectedRecordingId);
  const selectedRecording = useSelector(state =>
    getRecordingById(state, selectedRecordingId, language),
  );

  const { videoAreas, isLoading, isError } = useRecordingAreas(slug, started);
  const { filters, onFilterChange } = useRecordingsFilters();

  const filteredVideoAreas = filterVideoAreasByEventPartType(
    videoAreas,
    filters?.eventPart,
  );

  const onRecordingSelect = recordingId => {
    dispatch(setSelectedRecordingId(recordingId));
    dispatch(setThumbnailSidePanelIsShowing(true));
  };

  const onThumbnailSidePanelClose = () => {
    dispatch(setThumbnailSidePanelIsShowing(false));
  };

  return (
    <ReactIDProvider>
      <FullHeightFlex flexDirection="column" alignItems="stretch">
        <Alerts />

        {!started && (
          <NoResults
            recordingType={RECORDING_TYPE.PRIMARY}
            rehearsalHref={
              slug ? Routes.rehearsalOrganisersEventRecordingsPath(slug) : ''
            }
            eventStarted={started}
          />
        )}

        {started && (
          <Loading isLoading={isLoading}>
            <Box mt={3}>
              {isError && <ErrorMessage data-testid="recordingsErrorMsg" />}

              <Box
                pb={2}
                mb={4}
                border="grey-300"
                removeBorders={['borderTop', 'borderRight', 'borderLeft']}
              >
                <Filter
                  filters={filters}
                  onFilterChange={onFilterChange}
                  dateOptions={days}
                  showEventPartFilters={true}
                  showViewFilters={false}
                />
              </Box>

              {filteredVideoAreas?.map(videoArea => (
                <RecordingGroupContainer
                  recordingType={RECORDING_TYPE.PRIMARY}
                  key={videoArea.id}
                  videoArea={videoArea}
                  rank="main"
                  when="in_event"
                  filters={filters}
                  onRecordingSelect={onRecordingSelect}
                  customThumbnailsEnabled={customThumbnailsEnabled}
                />
              ))}

              {customThumbnailsEnabled && (
                <ThumbnailSidePanelModal
                  isShowing={isThumbnailSidePanelShowing}
                  onClose={onThumbnailSidePanelClose}
                  recording={selectedRecording}
                />
              )}
            </Box>
          </Loading>
        )}
      </FullHeightFlex>
    </ReactIDProvider>
  );
};

RecordingsPagePrimaryComponent.propTypes = {
  customThumbnailsEnabled: PropTypes.bool.isRequired,
};

export default compose(
  withReduxProvider,
  withEventProvider,
  withRegistrationProvider,
  withLocalizationProvider,
  withAuthProvider,
)(RecordingsPagePrimaryComponent);
