// https://redux.js.org/usage/writing-tests
import { configureStore } from '@reduxjs/toolkit';
import { render as rtlRender } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';

import { rootReducer } from '../redux/store';

const defaultPreloadedState = {};

function renderWithStore(
  ui,
  {
    preloadedState = defaultPreloadedState,
    store = configureStore({ reducer: rootReducer, preloadedState }),
    ...renderOptions
  } = {},
) {
  // eslint-disable-next-line react/prop-types
  function Wrapper({ children }) {
    return <Provider store={store}>{children}</Provider>;
  }
  return rtlRender(ui, { wrapper: Wrapper, ...renderOptions });
}

const createStore = state => {
  const preloadedState = {
    ...defaultPreloadedState,
    ...state,
  };
  return configureStore({ reducer: rootReducer, preloadedState });
};

export * from '@testing-library/react';
export { renderWithStore, createStore };
