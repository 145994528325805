import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

export const TemplateModalInProgress = ({ hasSucceeded, currentStep }) => {
  const { t } = useLocalization('templates');
  const stepName = t(`steps.${currentStep}`);

  return (
    <Flex
      data-testid="template-modal-in-progress"
      flexDirection="column"
      alignItems="center"
      py={9}
      px={3}
    >
      <Spinner mt={25} mb={5} pattern="jumbo" isShowing={true} />
      <Text
        align="center"
        element="p"
        font="text"
        id="create-event-modal-in-progress"
      >
        {t(hasSucceeded ? 'eventCreated' : 'settingUp')}
      </Text>
      {currentStep && stepName && (
        <Text
          align="center"
          element="p"
          font="text"
          id="create-event-modal-in-progress-current-step"
        >
          {t('settingUpStep', { stepName: stepName })}
        </Text>
      )}
    </Flex>
  );
};

TemplateModalInProgress.propTypes = {
  hasSucceeded: PropTypes.bool.isRequired,
  eventSlug: PropTypes.string,
  eventExternalId: PropTypes.string,
  currentStep: PropTypes.string,
};
