import SaveBar from '@components/save-bar';
import Alerts from '@features/alerts/alerts';
import { AlertsContext } from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Halon } from '@hopin-team/ui-halon';
import { calcSpace } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import trackSegmentEvent from '@util/analytics/segment-client';
import { useUnloadPrompt } from '@util/hooks/use-unload-prompt';
import { arrayOf, bool, func, number, oneOf, shape, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import ActivityPanelSettings from './partials/activity-panel-settings';
import AttendeeActions from './partials/attendee-actions';
import AttendeeVisibilityTogglesContainer from './partials/attendee-visibility-toggles-container';
import DeviceAccess from './partials/device-access';
import EventAccess from './partials/event-access';
import InstantCommands from './partials/instant-commands';
import ModeratedQuestions from './partials/moderated-questions';
import MyAgenda from './partials/my-agenda';
import PreEventAccess from './partials/pre-event-access';
import Sessions from './partials/sessions';
import useVenueSettingsForm from './use-venue-settings-form';

const FormWrapper = styled.div`
  width: calc(100% - 3rem);
  padding-bottom: ${calcSpace(6)};
`;

const HalonWrapper = styled(Box)`
  display: inline-block;
`;

const VenueSettingsComponent = ({
  id,
  attributes,
  meta,
  onSubmitSuccess,
  eventSlug,
  eventExternalId,
  userExternalId,
  organizationExternalId,
  onsiteOnly,
  isLite,
}) => {
  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization('venue-controls');

  const {
    attendeesVisiblity,
    doorsCloseAfterMinutes,
    doorsOpenBeforeMinutes,
    inviteToVideoCall,
    addSession,
    scheduleMeetings,
    scheduleMeetingsPreEvent,
    myAgenda,
    moderatedQuestions,
    sidePanelConfig,
    eventPartTypes,
    giphyEnabled,
    limitMultipleEventConnections,
    preEventSettings,
    eventId,
    organizationPaymentPlans,
    supportUrl,
    sendMessages,
    scheduleMeetingExcludedPersonaIds,
    sendDirectMessageExcludedPersonaIds,
    startInstantVideoCallExcludedPersonaIds,
  } = attributes;

  const {
    hasRoundtablesArea,
    showOpenCloseDoorsTimes,
    openDoorsLimitMinutes,
    closeDoorsLimitMinutes,
    showClearChat,
  } = meta;

  const handleSuccess = data => {
    onSubmitSuccess(data);
    addAlert({
      active: true,
      text: 'Venue controls successfully updated',
      pattern: 'success',
    });

    trackSegmentEvent('Pre-event Meetings Toggled', {
      user_id: userExternalId,
      event_id: eventExternalId,
      state: data.attributes.schedule_meetings_pre_event ? 'On' : 'Off',
    });

    trackSegmentEvent('Scheduled Meetings Toggled', {
      user_id: userExternalId,
      event_id: eventExternalId,
      state: data.attributes.schedule_meetings ? 'On' : 'Off',
    });

    trackSegmentEvent(
      data.attributes.giphy_enabled ? 'Giphy Enabled' : 'Giphy Disabled',
      {
        user_id: userExternalId,
        event_id: eventExternalId,
        organization_id: organizationExternalId,
      },
    );
  };

  const handleError = errors => {
    errors.forEach(error => {
      addAlert({
        active: true,
        text: error.detail,
        pattern: 'error',
      });
    });
  };

  const {
    errors: { venue_settings: errors },
    formState,
    onSubmit,
    register,
    reset,
    control,
    watch,
  } = useVenueSettingsForm({
    id,
    attributes,
    meta,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  useUnloadPrompt({ when: formState.isDirty });

  useEffect(() => {
    reset({
      venue_settings: {
        attendees_visiblity: attendeesVisiblity,
        doors_close_after_minutes: doorsCloseAfterMinutes || '0',
        doors_open_before_minutes: doorsOpenBeforeMinutes || '0',
        schedule_meetings: scheduleMeetings,
        schedule_meetings_pre_event: scheduleMeetingsPreEvent,
        my_agenda: myAgenda,
        giphy_enabled: giphyEnabled,
        moderated_questions: moderatedQuestions,
        invite_to_video_call: inviteToVideoCall,
        add_session: addSession,
        side_panel_config: sidePanelConfig,
        limit_multiple_event_connections: limitMultipleEventConnections,
        pre_event_enabled: preEventSettings.preEventEnabled,
        pre_event_duration_amount: preEventSettings.preEventDurationAmount,
        pre_event_duration_unit:
          preEventSettings.preEventDurationUnit ?? 'days',
        send_messages: sendMessages,
        schedule_meeting_excluded_persona_ids: scheduleMeetingExcludedPersonaIds,
        schedule_meeting_excluded_tickets_checkbox: !!scheduleMeetingExcludedPersonaIds?.length,
        send_direct_message_excluded_persona_ids: sendDirectMessageExcludedPersonaIds,
        send_direct_message_excluded_tickets_checkbox: !!sendDirectMessageExcludedPersonaIds?.length,
        start_instant_video_call_excluded_persona_ids: startInstantVideoCallExcludedPersonaIds,
        start_instant_video_call_excluded_tickets_checkbox: !!startInstantVideoCallExcludedPersonaIds?.length,
      },
    });
  }, [
    attendeesVisiblity,
    doorsCloseAfterMinutes,
    doorsOpenBeforeMinutes,
    scheduleMeetings,
    scheduleMeetingsPreEvent,
    myAgenda,
    inviteToVideoCall,
    addSession,
    sidePanelConfig,
    limitMultipleEventConnections,
    preEventSettings,
    reset,
    giphyEnabled,
    moderatedQuestions,
    sendMessages,
    scheduleMeetingExcludedPersonaIds,
    sendDirectMessageExcludedPersonaIds,
    startInstantVideoCallExcludedPersonaIds,
  ]);

  const currentDoorsCloseTime = watch(
    'venue_settings[doors_close_after_minutes]',
  ).toString();
  const currentDoorsOpenTime = watch(
    'venue_settings[doors_open_before_minutes]',
  ).toString();

  return (
    <FormWrapper data-testid="venue-settings-component">
      <Alerts />
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <form onSubmit={onSubmit}>
        <Box
          as="section"
          mt={3.5}
          mb={4.5}
          p={0}
          border="grey-300"
          removeBorders={['borderTop', 'borderLeft', 'borderRight']}
        >
          <Box mb={4}>
            <Text
              element="h2"
              pattern="subHeading"
              color="grey-900"
              weight="medium"
              mb={2}
            >
              <HalonWrapper mr={1}>
                <Halon
                  color="purple-100"
                  icon="door-open"
                  iconColor="purple-600"
                  iconScale={2}
                  scale={4}
                  aria-hidden="true"
                />
              </HalonWrapper>
              {t('section-headings.access-to-the-event')}
            </Text>

            <PreEventAccess
              register={register}
              control={control}
              errors={errors}
              eventId={eventId}
              organizationPaymentPlans={organizationPaymentPlans}
              supportUrl={supportUrl}
            />

            {showOpenCloseDoorsTimes && (
              <EventAccess
                currentDoorsCloseTime={currentDoorsCloseTime}
                currentDoorsOpenTime={currentDoorsOpenTime}
                errors={errors}
                openDoorsLimitMinutes={openDoorsLimitMinutes}
                closeDoorsLimitMinutes={closeDoorsLimitMinutes}
                register={register}
              />
            )}

            <DeviceAccess control={control} />
          </Box>
        </Box>

        {!isLite && (
          <Box
            as="section"
            mb={4.5}
            p={0}
            border="grey-300"
            removeBorders={['borderTop', 'borderLeft', 'borderRight']}
          >
            <Box mb={4}>
              <Text
                element="h2"
                pattern="subHeading"
                color="grey-900"
                weight="medium"
                mb={2}
              >
                <HalonWrapper mr={1}>
                  <Halon
                    color="yellow-100"
                    icon="users"
                    iconColor="yellow-600"
                    iconScale={2}
                    scale={4}
                    aria-hidden="true"
                  />
                </HalonWrapper>
                {t('section-headings.attendee-experience')}
              </Text>

              <AttendeeActions eventId={eventId} control={control} />

              <AttendeeVisibilityTogglesContainer control={control} />

              <MyAgenda control={control} />

              <InstantCommands control={control} />
            </Box>
          </Box>
        )}

        <Box as="section" mb={2} p={0}>
          <Text
            element="h2"
            pattern="subHeading"
            color="grey-900"
            weight="medium"
            mb={2}
          >
            <HalonWrapper mr={1}>
              <Halon
                color="green-100"
                icon="activities"
                iconColor="green-600"
                iconScale={2}
                scale={4}
                aria-hidden="true"
              />
            </HalonWrapper>
            {t('section-headings.activity-panel')}
          </Text>

          <ActivityPanelSettings
            control={control}
            eventPartTypes={eventPartTypes}
            eventExternalId={eventExternalId}
            showClearChat={showClearChat}
          />

          <ModeratedQuestions control={control} />

          {!onsiteOnly && (
            <Sessions
              eventSlug={eventSlug}
              register={register}
              hasRoundtablesArea={hasRoundtablesArea}
              control={control}
            />
          )}
        </Box>

        <SaveBar buttonDisabled={formState.isSubmitting} />
      </form>
    </FormWrapper>
  );
};

const sidePanelConfigShape = shape({
  event: bool,
  stage: bool,
  sessions: bool,
  expo: bool,
});

VenueSettingsComponent.propTypes = {
  id: string.isRequired,
  attributes: shape({
    attendeesVisiblity: oneOf(['show_all', 'show_list', 'show_none'])
      .isRequired,
    doorsCloseAfterMinutes: number,
    doorsOpenBeforeMinutes: number,
    inviteToVideoCall: bool.isRequired,
    scheduleMeetings: bool.isRequired,
    moderatedQuestions: bool.isRequired,
    myAgenda: bool.isRequired,
    addSession: bool,
    giphyEnabled: bool,
    eventPartTypes: arrayOf(string),
    sidePanelConfig: shape({
      chat: sidePanelConfigShape,
      polls: sidePanelConfigShape,
      'q-and-a': sidePanelConfigShape,
    }),
    limitMultipleEventConnections: bool,
  }),
  meta: shape({
    closeDoorsLimitMinutes: number,
    openDoorsLimitMinutes: number,
    hasRoundtablesArea: bool.isRequired,
    showClearChat: bool,
  }),
  onSubmitSuccess: func,
  eventSlug: string,
  userExternalId: string,
  eventExternalId: string,
  organizationExternalId: string,
  onsiteOnly: bool,
  isLite: bool,
};

export default withLocalizationProvider(VenueSettingsComponent);
