import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { MeetupPreview } from './meetup-preview';
import { PreviewContainer, SidePanel } from './styles';
import { WebcastPreview } from './webcast-preview';

export const WebinarPreview = ({ webinarStyle }) => {
  const { logo } = useSelector(state => state.org);
  const { name } = useSelector(state => state.basicDetails);

  return (
    <SidePanel>
      <PreviewContainer>
        {webinarStyle === 'lite_webcast' && (
          <WebcastPreview eventName={name} logo={logo} />
        )}
        {webinarStyle === 'lite_meetup' && (
          <MeetupPreview eventName={name} logo={logo} />
        )}
      </PreviewContainer>
    </SidePanel>
  );
};

WebinarPreview.propTypes = {
  webinarStyle: PropTypes.oneOf(['lite_meetup', 'lite_webcast']).isRequired,
};
