import compose from 'lodash/fp/compose';
import { oneOf } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import withReduxProvider from '@/redux/with-provider';

import ReportsNotification from './reports-notification';

/**
 * Displays the status of report generation
 * @param {String} theme - styling theme to be used on child component
 * @returns {ReportsNotification}
 */
function ReportsNotificationContainer({ theme = 'secondary' }) {
  const status = useSelector(({ reports }) => reports.generatingStatus?.status);

  return (
    <span data-testid="reports-notification-container">
      <ReportsNotification status={status} theme={theme} />
    </span>
  );
}

ReportsNotificationContainer.propTypes = {
  theme: oneOf(['primary', 'secondary']),
};

export default compose(withReduxProvider)(ReportsNotificationContainer);
