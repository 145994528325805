import hopinApi from '@api/hopin';
import { SelectLabel } from '@components/select/select-styles';
import Alerts from '@features/alerts/alerts';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Icon } from '@hopin-team/ui-icon';
import { Spinner } from '@hopin-team/ui-spinner';
import { TextField } from '@hopin-team/ui-text-field';
import { any, array, bool, func, number, shape, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  AnnouncementModal,
  AnnouncementModalContent,
  AnnouncementModalFooter,
  AnnouncementModalHeader,
  AnnouncementsText,
  SecondaryActionButton,
} from '../styles';
import PreviewUsersSelect from './preview-users-select';
import { SelectWrapper, SendTestEmailFormWrapper } from './styles';

const SendTestEmailModal = ({
  event,
  isModalDisplayed,
  handleClose,
  selectedAttendeeData,
  defaultEmail,
  announcementEmailTemplateId,
  token,
  selectOptions,
}) => {
  const { t } = useLocalization('email-dashboard.send-test-email');
  const {
    organiser_id: organiserId,
    id: eventId,
    registration_count: registrationsCount,
  } = event;
  const { addAlert } = useContext(AlertsContext);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, register, getValues, setValue, reset } = useForm();

  useEffect(() => {
    if (!defaultEmail) return;

    reset({
      registration_id: selectedAttendeeData?.value,
      email: defaultEmail,
    });
  }, [defaultEmail, selectedAttendeeData, reset]);

  const handleClick = async () => {
    setIsLoading(true);
    const values = getValues();
    const registrationId = values.registration_id;
    const emailRecipient = values.email;

    try {
      await hopinApi.sendTestAnnouncementEmail(
        token,
        organiserId,
        eventId,
        announcementEmailTemplateId,
        { registration_id: registrationId },
      );

      addAlert({
        active: true,
        text: t('success-message') + ` ${emailRecipient}`,
        pattern: 'success',
      });

      handleClose();
    } catch (e) {
      addAlert({
        active: true,
        text: t('error-message'),
        pattern: 'error',
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  const handleAttendeeSelectChange = useCallback(
    async ({ value: newRegistrationId, email: newEmail }) => {
      setValue('registration_id', newRegistrationId);
      setValue('email', newEmail);
    },
    [setValue],
  );

  return (
    <>
      <Alerts />
      <AnnouncementModal
        describedById="modal-header"
        isShowing={isModalDisplayed}
        onClose={handleClose}
        isDismissible
        withCloseButton
        label="Send Test Email Modal"
        size="medium"
        data-testid="send-test-email-modal"
      >
        <AnnouncementModalHeader>
          <AnnouncementsText>{t('title')}</AnnouncementsText>
        </AnnouncementModalHeader>
        <SendTestEmailFormWrapper onSubmit={handleSubmit(handleClick)}>
          <AnnouncementModalContent backgroundColor="gray-100">
            <SelectWrapper mt={2}>
              <SelectLabel htmlFor="user-for-send-test">
                {t('select-organiser.label')}
              </SelectLabel>
              <PreviewUsersSelect
                eventId={eventId}
                registrationsCount={registrationsCount}
                onChange={handleAttendeeSelectChange}
                id="user-for-send-test"
                options={selectOptions}
                name="registration_id"
              />
              <input
                hidden
                data-testid="selected-attendee-id"
                name="registration_id"
                ref={register({})}
              />
            </SelectWrapper>
            <TextField
              data-testid="email-recipient"
              label={t('email-recipient.label')}
              name="email"
              isRequired
              readOnly
              ref={register()}
              type="text"
              style={{ color: 'var(--color-gray-800)', fontWeight: '400' }}
              mt={2}
              mb={2}
            />
          </AnnouncementModalContent>
          <AnnouncementModalFooter>
            <SecondaryActionButton
              onClick={handleClose}
              data-testid="cancel-button"
            >
              {t('cancel')}
            </SecondaryActionButton>
            {isLoading ? (
              <Button
                isInline
                size="small"
                data-testid="sending-test-email-button"
              >
                <AnnouncementsText color="grey-100" mr={1}>
                  {t('sending')}
                </AnnouncementsText>
                <Spinner
                  label={t('sending')}
                  isAssertive
                  isShowing={isLoading}
                  color="grey-100"
                />
              </Button>
            ) : (
              <Button
                isInline
                size="small"
                type="submit"
                data-testid="send-test-email-button-confirm"
              >
                {t('confirm')}
                <Icon
                  aria-labelledby="labelId"
                  name="send"
                  color="grey-100"
                  fill="grey-100"
                  scale={1.5}
                  ml={1}
                />
              </Button>
            )}
          </AnnouncementModalFooter>
        </SendTestEmailFormWrapper>
      </AnnouncementModal>
    </>
  );
};

SendTestEmailModal.propTypes = {
  announcementEmailTemplateId: string.isRequired,
  defaultEmail: string,
  event: shape({
    organiser_id: number.isRequired,
    id: number.isRequired,
  }).isRequired,
  handleClose: func.isRequired,
  isModalDisplayed: bool.isRequired,
  selectedAttendeeData: shape({
    value: any,
    label: any,
    email: any,
  }),
  token: string,
  selectOptions: array,
};

export default SendTestEmailModal;
