import HeartFillIcon from '@components/icons/heart-fill';
import InfoCircleIcon from '@components/icons/info-circle-icon';
import feedbackFormLinks from '@features/dashboard/constants/feedback-form-links';
import noticeTexts from '@features/dashboard/constants/notice-texts';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { bool, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const NoticeWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  padding: 0.75rem 1.25rem;
  justify-content: space-between;
  background-color: var(--color-blue-100);
`;

const LeaveFeedbackButton = styled(Button)`
  color: var(--color-gray-800);
  background-color: var(--color-white);
  padding: 0.25rem 0.875rem;
  box-shadow: 0px 1px 2px rgba(44, 50, 66, 0.08);

  &:hover {
    color: var(--color-gray-800);
    background-color: var(--color-gray-300);
  }

  &:focus {
    border: 1px solid var(--color-blue-400);
  }
`;

const NoticeIcon = styled.div`
  margin-left: 0.5rem;
  display: inline-block;
  color: var(--color-grey-600);
`;

const NoticeItem = ({
  pageType,
  eventPhase,
  customNoticeText,
  showFeedbackLink,
  showToolTip,
}) => {
  const { t } = useLocalization();
  const noticeText = customNoticeText
    ? customNoticeText
    : noticeTexts(t, pageType, eventPhase);
  const feedbackFormLink = feedbackFormLinks[pageType][eventPhase];

  return (
    <NoticeWrapper>
      <div>
        <Text pattern="headingSix" data-testid="event-notice">
          {noticeText}
        </Text>
        {showToolTip && (
          <NoticeIcon>
            <ToolTip
              tip={t('registrations-dashboard.notice-tooltip')}
              align="right"
            >
              <InfoCircleIcon />
            </ToolTip>
          </NoticeIcon>
        )}
      </div>
      <div>
        {showFeedbackLink && (
          <LeaveFeedbackButton
            isOutlined
            target="_blank"
            href={feedbackFormLink}
            size="small"
          >
            <HeartFillIcon /> &nbsp; &nbsp; {t('common.leave-feedback')}
          </LeaveFeedbackButton>
        )}
      </div>
    </NoticeWrapper>
  );
};

NoticeItem.defaultProps = {
  showToolTip: false,
};

NoticeItem.propTypes = {
  pageType: string.isRequired,
  eventPhase: string.isRequired,
  customNoticeText: string,
  showFeedbackLink: bool.isRequired,
  showToolTip: bool,
};

export default NoticeItem;
