/* global Rails */
import isClientside from '@util/is-clientside';
import ky from 'ky-universal';

let client = {};

if (isClientside()) {
  client = ky.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/vnd.api+json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
    throwHttpErrors: false,
  });
}

const BASE_PATH = '/event_dashboard/api';

const eventDashboardApi = {
  getVenueSettings(eventId) {
    return client.get(`${BASE_PATH}/events/${eventId}/venue_settings`).json();
  },
  updateVenueSettings(eventId, attributes) {
    return client
      .put(`${BASE_PATH}/events/${eventId}/venue_settings`, {
        json: {
          data: {
            id: eventId,
            type: 'venue_settings',
            attributes,
          },
        },
      })
      .json();
  },
  createClearChatJob(eventId, segment_types) {
    return client
      .post(`${BASE_PATH}/events/${eventId}/clear_chat_jobs`, {
        json: {
          data: {
            type: 'clear_chat_job',
            attributes: {
              segment_types,
            },
          },
        },
      })
      .json();
  },
  getClearChatJob(eventId, externalId) {
    return client
      .get(`${BASE_PATH}/events/${eventId}/clear_chat_jobs/${externalId}`)
      .json();
  },
  getMarketingSettings(eventId) {
    return client
      .get(`${BASE_PATH}/events/${eventId}/marketing_settings`)
      .json();
  },
  updateMarketingSettings(eventId, attributes) {
    return client
      .put(`${BASE_PATH}/events/${eventId}/marketing_settings`, {
        json: {
          data: {
            id: eventId,
            type: 'marketing_settings',
            attributes,
          },
        },
      })
      .json();
  },
  getEventLinks(eventId) {
    return client.get(`${BASE_PATH}/events/${eventId}/event_links`).json();
  },
  getHostInformation(eventId) {
    return client.get(`${BASE_PATH}/events/${eventId}/host_information`).json();
  },
  getCustomDomain(eventId) {
    return client.get(`${BASE_PATH}/events/${eventId}/custom_domain`).json();
  },
  deleteCustomDomain(eventId) {
    return client.delete(`${BASE_PATH}/events/${eventId}/custom_domain`).json();
  },
  createPresignedUploadUrl(attributes) {
    return client
      .post(`${BASE_PATH}/presigned_upload_urls`, {
        json: {
          data: {
            type: 'new_presigned_upload_url',
            attributes,
          },
        },
      })
      .json();
  },
  updateCustomDomain(eventId, attributes) {
    return client
      .put(`${BASE_PATH}/events/${eventId}/custom_domain`, {
        json: {
          data: {
            id: eventId,
            type: 'custom_domain',
            attributes,
          },
        },
      })
      .json();
  },
  updateHostInformation(eventId, attributes) {
    return client
      .put(`${BASE_PATH}/events/${eventId}/host_information`, {
        json: {
          data: {
            id: eventId,
            type: 'host_information',
            attributes,
          },
        },
      })
      .json();
  },
  uploadPresignedURLS(attributes) {
    return client
      .post(`${BASE_PATH}/presigned_upload_urls`, {
        json: {
          data: {
            type: 'new_presigned_upload_url',
            attributes,
          },
        },
      })
      .json();
  },
  getSetupChecklist(eventId) {
    return client.get(`${BASE_PATH}/events/${eventId}/setup_checklist`).json();
  },
  updateStepStatus(eventId, step, status) {
    return client
      .put(`${BASE_PATH}/events/${eventId}/setup_checklist/${step}/${status}`)
      .json();
  },
  updateSetupChecklistVisibility(eventId) {
    return client
      .put(`${BASE_PATH}/events/${eventId}/setup_checklist/visibility`)
      .json();
  },
  getPublishValidationStatus(eventId) {
    return client
      .get(`${BASE_PATH}/events/${eventId}/publish/validation_status`)
      .json();
  },
  createProfileCategories(eventId, categories) {
    return client
      .post(`${BASE_PATH}/events/${eventId}/profile_categories`, {
        json: {
          data: categories,
        },
      })
      .json();
  },
  getProfileCategories(eventId) {
    return client
      .get(`${BASE_PATH}/events/${eventId}/profile_categories`)
      .json();
  },
  updateProfileCategories(eventId, categories) {
    return client
      .put(`${BASE_PATH}/events/${eventId}/profile_categories`, {
        json: {
          data: categories,
        },
      })
      .json();
  },
};

export default eventDashboardApi;
