import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { unwrapResult } from '@reduxjs/toolkit';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { publishEvent } from '@/redux/reducers/event';
import { closeModal } from '@/redux/reducers/modal';

const FLASH_TYPES = {
  NOTICE: 'notice',
};

const getConfirmText = (t, isDraft) =>
  isDraft ? t('are-you-sure') : t('this-will-remove-your-event');

export const usePublishEventHandler = (
  { shouldConfirm } = { shouldConfirm: false },
) => {
  const { t } = useLocalization('publishing');
  const { addAlert } = useContext(AlertsContext);
  const logger = getLogger(LOGGER_NAMES.EVENT_DASH_NAV);

  const dispatch = useDispatch();
  const eventSlug = useSelector(({ event }) => event.slug);
  const isDraft = useSelector(({ event }) => event.draft);

  return async () => {
    const confirm = shouldConfirm
      ? window.confirm(getConfirmText(t, isDraft))
      : true;

    const isOnBasicsPage = window.location.pathname.endsWith('/basics');

    if (confirm) {
      try {
        const dispatchResult = await dispatch(publishEvent(eventSlug));
        const result = await unwrapResult(dispatchResult);

        if (result.flash) {
          const [type] = Object.keys(result.flash);
          const isSuccess = type === FLASH_TYPES.NOTICE;

          addAlert({
            active: true,
            text: result.flash[type],
            pattern: isSuccess ? 'success' : 'error',
          });

          if (isSuccess) {
            dispatch(closeModal());
          }
        }

        if (isOnBasicsPage) {
          window.location.reload();
        }
      } catch (err) {
        logger.error(err);

        addAlert({
          active: true,
          text: t('something-went-wrong'),
          pattern: 'error',
        });
      }
    }
  };
};
