import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { object } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

const InstantCommands = ({ control }) => {
  const { t } = useLocalization('venue-controls.instant-commands');

  const {
    field: { onChange, value, name },
  } = useController({
    name: 'venue_settings[giphy_enabled]',
    control,
  });

  return (
    <>
      <Text
        mt={3}
        element="h3"
        pattern="headingThree"
        color="grey-800"
        weight="medium"
        scale={4}
      >
        {t('title')}
      </Text>
      <Text element="p" mt={1} color="grey-600" pattern="body">
        {t('heading-redesign')}
      </Text>
      <Flex alignItems="center" mt={1}>
        <input type="hidden" name="venue_settings[giphy_enabled]" value="0" />
        <Checkbox
          name={name}
          data-testid="giphy-enabled-checkbox"
          id="giphy_enabled"
          onChange={event => onChange(event.target.checked)}
          checked={!!value}
          value={value}
          label={t('label')}
          mr={2}
        />
        <Text
          element="label"
          htmlFor="field-giphy_enabled"
          pattern="body"
          color="grey-500"
        >
          {t('helper-text')}
        </Text>
      </Flex>
    </>
  );
};

InstantCommands.propTypes = {
  control: object.isRequired,
};

export default withLocalizationProvider(InstantCommands);
