import getDisplayName from '@util/get-display-name';
import Cookies from 'js-cookie';
import { node } from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import rosetta from 'rosetta';

import {
  COOKIE_LOOKUP_KEY_LANG,
  dateFnsLanguageMap,
  localeFileMap,
} from './constants';
import { formatDate as format } from './date';
import { getDefaultLanguage, getLocalizationCookieDomain } from './helpers';

const i18n = rosetta(localeFileMap);

export const LocalizationContext = createContext();
const { t } = i18n;

const LocalizationProvider = ({ children }) => {
  const [language, setLanguage] = useState(getDefaultLanguage());
  const lastKnownLanguage = useRef(language);

  const formatDate = (date, formatStyle) =>
    format(dateFnsLanguageMap[language], date, formatStyle);
  const formatText = (text, params) => t(text, params, language);
  const dateLocale = dateFnsLanguageMap[language];

  useEffect(() => {
    i18n.locale(language);
    if (language !== lastKnownLanguage.current) {
      const domain = getLocalizationCookieDomain(window.location.hostname);
      Cookies.set(COOKIE_LOOKUP_KEY_LANG, language, { domain });
      window.location.reload();
    }
  }, [language]);

  return (
    <LocalizationContext.Provider
      value={{
        t: formatText,
        formatDate,
        setLanguage,
        dateLocale,
        language,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

LocalizationProvider.propTypes = {
  children: node,
};

const withLocalizationProvider = WrappedComponent => {
  const WithLocalizationProvider = props => {
    return (
      <LocalizationProvider>
        <WrappedComponent {...props} />
      </LocalizationProvider>
    );
  };
  WithLocalizationProvider.displayName = `WithLocalizationProvider(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithLocalizationProvider;
};

const withLocalization = WrappedComponent => {
  const WithLocalization = props => {
    const context = useContext(LocalizationContext);
    return <WrappedComponent {...context} {...props} />;
  };
  WithLocalization.displayName = `WithLocalization(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithLocalization;
};

// Hook to deprecate HOC
const useLocalization = (translationContext = '') => {
  const { t, ...rest } = useContext(LocalizationContext);
  const translate = useCallback(
    (keyPath, ...params) =>
      t(`${translationContext}.${keyPath}`.replace(/^\./, ''), ...params),
    [translationContext, t],
  );
  return {
    ...rest,
    t: translate,
  };
};

export {
  LocalizationProvider,
  withLocalization,
  withLocalizationProvider,
  i18n,
  useLocalization,
};
