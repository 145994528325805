import { getDate, getMonth, parseISO } from 'date-fns';

const getTimeLabelSubText = (date, tickIndex, allTicks, unit, formatDate) => {
  const isFirstTick = tickIndex === 0;
  const isDayUnit = unit === 'day';
  const dayOfMonth = getDate(date);
  const month = getMonth(date);
  const prevTickDate = parseISO(allTicks[tickIndex - 1]);
  const prevTickDayOfMonth = isFirstTick ? null : getDate(prevTickDate);
  const prevTickMonth = isFirstTick ? null : getMonth(prevTickDate);
  const isNextDay = prevTickDayOfMonth !== dayOfMonth;
  const isNextMonth = prevTickMonth !== month;
  const shouldShowMonth = isDayUnit && (isFirstTick || isNextMonth);
  const shouldShowMonthDay = !isDayUnit && (isFirstTick || isNextDay);

  let subText = '';
  if (shouldShowMonth) {
    subText = formatDate(date, 'MMM');
  } else if (shouldShowMonthDay) {
    subText = formatDate(date, 'MMM d');
  }

  return subText;
};

export default getTimeLabelSubText;
