const getPageDirectory = options => ({
  dashboard: 'title-directory.dashboard',
  overview: 'title-directory.overview',
  edit: 'title-directory.edit',
  basics: 'title-directory.basics',
  ticketing: 'title-directory.ticketing',
  registration: 'title-directory.registration',
  reception_form: 'title-directory.reception-form',
  backstages: 'title-directory.backstages',
  networking: 'title-directory.networking',
  roundtables: 'title-directory.roundtables',
  schedule: 'title-directory.schedule',
  speakers: 'title-directory.speakers',
  schedules: 'title-directory.schedules',
  sponsors: 'title-directory.sponsors.sponsors',
  vendors: 'title-directory.vendors.expo',
  affiliates: 'title-directory.affiliates',
  tags: 'title-directory.tags',
  advanced_settings: 'title-directory.advanced-settings',
  attendees: 'title-directory.registrants',
  new_attendees: 'title-directory.registrants',
  announcements: 'title-directory.announcements',
  analytics_beta: 'title-directory.analytics-beta',
  live_analytics: 'title-directory.live-analytics',
  marketing: 'title-directory.marketing',
  reports: 'title-directory.reports',
  registrations_summary: 'title-directory.registrations-summary',
  connections: 'title-directory.connections',
  connections_summary: options?.showEngagementScore
    ? 'title-directory.engagement'
    : 'title-directory.connections-summary',
  rooms_summary: 'title-directory.rooms-summary',
  expo_summary: 'title-directory.expo-summary',
  event_polls: 'title-directory.event-polls',
  design: 'title-directory.design',
  text: 'title-directory.text',
  communications: 'title-directory.communications',
  redeem_codes: 'title-directory.redeem-codes',
  redeem_codes_vendors: 'title-directory.invite-vendors',
  registration_fields: 'title-directory.registration-fields',
  duplications: 'title-directory.duplications',
  host_information: 'title-directory.host-information',
  security: 'title-directory.security',
  recordings: 'title-directory.recordings',
  recordings_backup: 'title-directory.recordings',
  recordings_rehearsal: 'title-directory.recordings',
  recordings_ALL: 'title-directory.recordings',
  venue_controls: 'title-directory.venue-controls',
  surveys: 'title-directory.surveys',
  custom_domain: 'title-directory.custom-domain',
  check_in_area: 'title-directory.check-in-area',
  kiosk_mode: 'title-directory.kiosk-mode',
  badge_designer: 'title-directory.badge-designer',
  floor_plan: 'title-directory.floor-plan',
  signatures: 'title-directory.signatures',
  people: 'title-directory.networking',
  app_area: 'title-directory.app-area',
  utm_codes: 'title-directory.utm-codes',
});

/**
 * Tries to fined an exact match (eg 'recordings' or 'recordings_backup')
 * If that fails and pathkey has an '_' the regex will create the key 'recordings_ALL' and look for that match
 * If all fail, returns undefined
 */
const getPageTitle = (t, pathKey, options) => {
  const title = getPageDirectory(options)[pathKey];
  if (title) return t(title);

  if (pathKey.includes('_')) {
    const fuzzyKey = pathKey.replace(/_(.*)/gi, '_ALL');
    return t(getPageDirectory(options)[fuzzyKey]);
  }

  return;
};

const getPageInfoText = (t, pathKey, options) =>
  t(getPageDirectory(options)[pathKey]?.concat('-info-text'));

export { getPageDirectory, getPageTitle, getPageInfoText };
