import { Butticon } from '@hopin-team/ui-butticon';
import PropTypes from 'prop-types';
import React from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';

import { CarouselContainer, LeftIcon, RightIcon } from './carousel.styles';

const ArrowPrev = (clickHandler, hasNext) =>
  hasNext ? (
    <LeftIcon>
      <Butticon
        onClick={clickHandler}
        icon="arrow-back"
        title="Previous"
        size="small"
      />
    </LeftIcon>
  ) : null;

const ArrowNext = (clickHandler, hasPrev) =>
  hasPrev ? (
    <RightIcon>
      <Butticon
        onClick={clickHandler}
        icon="arrow-forward"
        title="Next"
        size="small"
      />
    </RightIcon>
  ) : null;

export const Carousel = ({ children }) => (
  <CarouselContainer>
    <ReactCarousel
      showArrows
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      renderArrowNext={ArrowNext}
      renderArrowPrev={ArrowPrev}
    >
      {children}
    </ReactCarousel>
  </CarouselContainer>
);

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};
