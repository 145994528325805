import { Input, Label, Select } from '@components/inputs';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import * as Routes from '@routes';
import { humanize } from '@util/string-helpers';
import { array, bool, object, string } from 'prop-types';
import React, { useState } from 'react';
import { css } from 'styled-components';

const EventType = ({
  errors,
  eventPassword,
  eventType,
  hasPaidPlan,
  eventTypes,
}) => {
  const [currentEventType, setCurrentEventType] = useState(eventType);
  const eventTypesOptions = (acc, currentValue) => [
    ...acc,
    [humanize(currentValue), currentValue],
  ];
  const handleEventTypeChange = ({ target }) =>
    setCurrentEventType(target.value);

  return (
    <>
      {hasPaidPlan ? (
        <div>
          <Label htmlFor="event_type">Event Type?</Label>
          <Select
            id="event_type"
            name="event[event_type]"
            onChange={handleEventTypeChange}
            value={currentEventType}
          >
            {eventTypes
              .reduce(eventTypesOptions, [])
              .map(([eventTypeName, eventTypeCode]) => (
                <option key={eventTypeCode} value={eventTypeCode}>
                  {eventTypeName}
                </option>
              ))}
          </Select>
        </div>
      ) : (
        <div>
          <Select id="event_type" name="event[event_type]" disabled>
            <option>Hidden Event</option>
          </Select>
          <a href={Routes.organiserBillingPath()}>
            Upgrade to unlock private and public events
          </a>
        </div>
      )}
      <div hidden={currentEventType !== 'private_event'}>
        <Label
          htmlFor="event_password"
          css={css`
            display: flex;
          `}
        >
          Private Event Password
          <ToolTip
            align="top"
            tip="Invited users will need to enter the password to access the event page"
          >
            <i className="fas fa-info-circle ml-1" aria-hidden />
          </ToolTip>
        </Label>
        <Input
          error={errors?.password}
          defaultValue={eventPassword}
          id="event_password"
          name="event[password]"
          type="text"
        />
        {errors.password && (
          <label htmlFor="event_password" className="field_error">
            {errors?.password[0]}
          </label>
        )}
      </div>
    </>
  );
};

export default EventType;

EventType.propTypes = {
  errors: object,
  eventPassword: string,
  eventType: string,
  eventTypes: array,
  hasPaidPlan: bool,
};
