import React from 'react';
import { Provider } from 'react-redux';

import store from './store';

export const withReduxProvider = WrappedComponent => {
  const WithReduxProvider = props => {
    return (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    );
  };
  return WithReduxProvider;
};
