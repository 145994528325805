import InfoCircleIcon from '@components/icons/info-circle-icon';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React from 'react';

import { InfoPanelContainer } from './styles.js';

const InfoPanel = ({ text }) => (
  <InfoPanelContainer>
    <InfoCircleIcon />
    <Text element="span" pattern="caption" color="grey-700" ml={1}>
      {text}
    </Text>
  </InfoPanelContainer>
);

InfoPanel.propTypes = {
  text: string.isRequired,
};

export default InfoPanel;
