import { TabsContainer } from '@components/tabbed-chart/styles';
import { Grid } from '@hopin-team/ui-grid';
import {
  array,
  arrayOf,
  bool,
  element,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React from 'react';

import Chart from './chart';
import Header from './header';
import Tab from './tab';

const defaultChartHeight = 248;

const TabbedChart = props => {
  const { tab, setTab, data, rangePickerComponent, height } = props;

  if (!data || data.length == 0) {
    return null;
  }
  const activeData = data[tab];
  const { chartData, summary } = activeData;
  const showTabs = data.length > 1;

  return (
    <Grid
      gridTemplateColumns={showTabs ? 'minmax(0, 2fr) 1fr' : 'minmax(0, 1fr)'}
      gridGap={3}
      data-testid="tabbed-chart"
    >
      <div data-testid="tabbed-chart-graph">
        <Header summary={summary} />
        {rangePickerComponent}
        <Chart
          {...props}
          chartData={chartData}
          tooltipDescription={summary.resourceName}
          height={height || defaultChartHeight}
        />
      </div>
      {showTabs && (
        <div data-testid="tabbed-chart-legend">
          <TabsContainer>
            {data.map((tabData, i) => (
              <Tab
                key={i}
                tabIndex={i}
                selected={i == tab}
                summary={tabData.summary}
                iconName={tabData.iconName}
                onClick={() => setTab(i)}
              />
            ))}
          </TabsContainer>
        </div>
      )}
    </Grid>
  );
};

TabbedChart.propTypes = {
  data: arrayOf(
    shape({
      summary: shape({
        title: string.isRequired,
        number: oneOfType([string, number]).isRequired,
        unit: string,
        changeRate: number,
        hideChangeRate: bool,
      }).isRequired,
      chartData: oneOfType([object, array]).isRequired,
    }),
  ),
  tab: number.isRequired,
  setTab: func.isRequired,
  startTime: string,
  endTime: string,
  unit: string,
  chartType: oneOf(['histogram', 'line']).isRequired,
  maxBars: number,
  rangePickerComponent: element,
  height: number,
};

export default TabbedChart;
