import { Butticon } from '@hopin-team/ui-butticon';
import { Flex } from '@hopin-team/ui-flex';
import { MenuSelectItem } from '@hopin-team/ui-menu';
import styled, { css } from 'styled-components';

export const RecordingButtonsContainer = styled(Flex)`
  column-gap: 0.5rem;
`;

const disabledButticonStyles = css`
    /*
    This selector is to override a generic style that can be found in
    app/assets/stylesheets/bootstrap/_reboot.scss
    This generic style overrides the styles coming from fe-library
    */
    &:not([href]):not([tabindex]) {
      color: var(--color-gray-500);'
    }
`;

export const StyledButticon = styled(Butticon)`
  ${props => props.disabled && disabledButticonStyles}
`;

const disabledMenuStyles = css`
  color: var(--color-gray-600);
  pointer-events: none;

  svg {
    color: var(--color-gray-500);
  }
`;

export const StyledMenuSelectItem = styled(MenuSelectItem)`
  ${props => !props.enableRevert && disabledMenuStyles}
`;
