import ModalProvider from '@components/modal/modal-provider';
import Alerts from '@features/alerts/alerts';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import BulkInviteContainer from './bulk-invite/bulk-invite-container';
import ManageInvitesContainer from './manage-invites/manage-invites-container';
import SingleInviteContainer from './single-invite/single-invite-container';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from './tab-component';

const getLocationHash = () => {
  if (typeof window !== 'undefined') {
    return window.location.hash.replace('#', '');
  }
  return '';
};

const Summary = ({ children }) => (
  <Box
    border="grey-300"
    removeBorders={['borderTop', 'borderLeft', 'borderRight']}
    pb={3}
    my={3}
  >
    <Text element="p" pattern="body">
      {children}
    </Text>
  </Box>
);

Summary.propTypes = {
  children: string.isRequired,
};

const MagicLinksComponent = () => {
  const { t } = useLocalization('magic-links');

  const TABS = [
    { hash: '', text: t('tabs.upload-csv.title') },
    { hash: 'single-invite', text: t('tabs.single-invite.title') },
    { hash: 'manage-invites', text: t('tabs.manage-invites.title') },
  ];

  const handleTabChange = i => {
    if (typeof window !== 'undefined') {
      window.location.hash = TABS[i].hash;
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Alerts />
      <Summary>{t('summary')}</Summary>
      <ModalProvider>
        <Tabs
          defaultIndex={
            TABS.findIndex(tab => tab.hash === getLocationHash()) || 0
          }
          onChange={handleTabChange}
        >
          <TabList>
            {TABS.map(({ text }) => (
              <Tab key={text}>{text}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <BulkInviteContainer />
            </TabPanel>
            <TabPanel>
              <SingleInviteContainer />
            </TabPanel>
            <TabPanel>
              <ManageInvitesContainer />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalProvider>
    </>
  );
};

export default MagicLinksComponent;
