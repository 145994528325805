import getDisplayName from '@util/get-display-name';
import { ConnectedRouter } from 'connected-react-router';
import { object } from 'prop-types';
import React from 'react';

import { history } from '@/redux/store';

const withConnectedRouter = WrappedComponent => {
  const WithConnectedRouter = props => {
    return (
      <ConnectedRouter history={history}>
        <WrappedComponent {...props} />
      </ConnectedRouter>
    );
  };
  WithConnectedRouter.propTypes = { viewData: object };
  WithConnectedRouter.displayName = `WithViewDataProvider(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithConnectedRouter;
};

export default withConnectedRouter;
