import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

const HoverContent = styled.div`
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: all ease-in-out 0.2s;
`;

const DefaultContent = styled(Flex)`
  opacity: 1;
  transition: all ease-in-out 0.2s;
`;

const Card = styled(Box)`
  position: relative;
  display: flex;

  @media (min-width: 450px) {
    align-items: center;
  }

  &:hover {
    border: 1px solid var(--color-blue-300);
    ${props =>
      props.shouldShowStaticCards && `background-color: var(--color-gray-100)`};
  }

  ${props =>
    !props.shouldShowStaticCards &&
    `
    &:hover ${DefaultContent} {
      opacity: 0;
    }

    &:hover ${HoverContent} {
      opacity: 1;
    }
  `}
`;

const RecommendedLabel = styled(Text).attrs({
  pattern: 'labelTwo',
  color: 'grey-white',
  m: 1,
  px: 1.5,
  py: 0.1,
})`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0a8046;
  border-radius: var(--border-radius-small);
`;

export { Card, RecommendedLabel, HoverContent, DefaultContent };
