import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRecordings } from '../api';
import { addGroup } from '../redux/slices/recording-groups/recording-groups-slice';
import { getGroupByOptions } from '../redux/slices/recording-groups/selectors';
import { addRecordings } from '../redux/slices/recordings/recordings-slice';

export const useRecordings = (slug, options = { videoArea: {} }) => {
  const {
    videoArea: { id: videoAreaId, type },
    rank,
    when,
  } = options;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const group = useSelector(state => getGroupByOptions(state, options));

  const fetchRecordings = useCallback(() => {
    setIsLoading(true);
    setIsError(false);

    getRecordings(slug, {
      event_part: type,
      video_area_id: videoAreaId,
      rank,
      when_in_time: when,
    })
      .then(recordings => {
        dispatch(addGroup({ options, recordings }));
        dispatch(addRecordings({ recordings }));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, type, videoAreaId, rank, when]);

  useEffect(() => {
    if (group?.groupId || !slug) return;
    fetchRecordings();
  }, [fetchRecordings, group?.groupId, slug]);

  return {
    isError,
    isLoading,
    refetchRecordings: fetchRecordings,
  };
};
