import LocationPagination from '@components/pagination/location-pagination';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Image } from '@hopin-team/ui-image';
import { calcSpace } from '@hopin-team/ui-symbols';
import {
  Table,
  TableCell,
  TableHeading,
  TableRow,
  TableRowAction,
  TableRowActions,
} from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import * as Routes from '@routes';
import initApiClient from '@util/api-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import compose from 'lodash/fp/compose';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import LeadRetrievalLicenseToggle from './lead-retrieval-license-toggle';
import VendorListHeader from './vendor-list-header';

const StyledTable = styled(Table)`
  border-collapse: collapse;
  margin: ${calcSpace(4)} 0;
  table-layout: auto;
  width: 100%;
`;

const StyledTableCell = styled(TableCell)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
  overflow-wrap: break-word;
`;

const StyledTableCellUrl = styled(TableCell)`
  overflow-wrap: anywhere;
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
`;

const StyledTableHeading = styled(TableHeading)`
  padding-left: ${calcSpace(2)};
  padding-right: ${calcSpace(2)};
`;

const StyledTableRowActions = styled(TableRowActions)`
  padding-right: ${calcSpace(2)};
`;

const Logo = styled(Image)`
  max-width: 2.1rem;
  object-fit: cover;
`;

const ToolTipContainer = styled(Flex)`
  & > div {
    align-self: center;
  }
`;

const FlexIcon = styled(Icon)`
  display: flex;
`;

const VendorList = ({
  vendors,
  eventSlug,
  authenticityToken,
  guestOnlyConfigured,
  canInvite,
  showLeadRetrievalLicenses,
  leadRetrievalLicenses: initLeadRetrievalLicenses,
  currentPage,
  totalPages,
  searchText,
  flags = {},
}) => {
  const { t } = useLocalization('vendor-list-component');
  const { addAlert: _addAlert } = useContext(AlertsContext);
  const apiClient = initApiClient(authenticityToken, { json: true });

  const logger = getLogger(LOGGER_NAMES.VENDORS);

  const [leadRetrievalLicenses, setLeadRetrievalLicenses] = useState(
    initLeadRetrievalLicenses,
  );

  const addAlert = (key, { type = 'error', params = {} } = {}) =>
    _addAlert({
      active: true,
      text: t(`alerts.${key}`, params),
      pattern: type,
    });

  const handleEdit = vendorId => {
    window.location.href = Routes.editOrganisersEventVendorPath({
      event_id: eventSlug,
      id: vendorId,
      _options: true,
    });
  };

  const handleRemove = async vendorId => {
    const confirm = window.confirm(t('remove-vendor.are-you-sure'));

    if (confirm) {
      const url = Routes.organisersEventVendorPath({
        event_id: eventSlug,
        id: vendorId,
        _options: true,
      });

      try {
        await apiClient.del(url, { json: true });

        // Refresh the page to request the updated list.
        // We don't throw an error if we encounter a HTTP error (`!res.ok`)
        // because this logic is handled by the controller action when
        // a request is unsuccessful (i.e. outside of the 2xx range)
        window.location.reload();
      } catch (err) {
        logger.error(err);
        // There was a network error
        addAlert('remove-vendor-error');
      }
    }
  };

  const handleLeadRetrievalLicenseToggle = (vendor, { balance }) => {
    if (balance == null) {
      return;
    }

    const alertI18nKey =
      balance > leadRetrievalLicenses
        ? 'toggle-license-not-issued'
        : 'toggle-license-issued';

    setLeadRetrievalLicenses(balance);
    addAlert(alertI18nKey, {
      type: 'success',
      params: {
        vendor: vendor.name,
      },
    });
  };

  const handleLeadRetrievalLicenseError = ({ balance, errors }) => {
    if (balance != null) {
      setLeadRetrievalLicenses(balance);
    }

    let key;
    switch (errors?.lead_retrieval_license_assigned) {
      case 'no_licenses':
        key = 'toggle-license-no-licenses-error';
        break;
      case 'used':
        key = 'toggle-license-used-error';
        break;
      default:
        key = 'toggle-license-error';
    }

    addAlert(key);
  };

  return (
    <>
      <Alerts />
      <VendorListHeader
        eventSlug={eventSlug}
        authenticityToken={authenticityToken}
        guestOnlyConfigured={guestOnlyConfigured}
        canInvite={canInvite}
        showLeadRetrievalLicenses={showLeadRetrievalLicenses}
        leadRetrievalLicenses={leadRetrievalLicenses}
        searchText={searchText}
      />
      {vendors.length > 0 ? (
        <>
          <StyledTable
            hasActions
            hasHighlights
            data-testid="vendor-table-planetary"
          >
            <thead>
              <TableRow isHeading>
                <StyledTableHeading scope="col">
                  <Text pattern="emphasis">{t('table.headings.logo')}</Text>
                </StyledTableHeading>
                <StyledTableHeading scope="col">
                  <Text pattern="emphasis">{t('table.headings.name')}</Text>
                </StyledTableHeading>
                {showLeadRetrievalLicenses && (
                  <StyledTableHeading scope="col">
                    <ToolTipContainer>
                      <Text pattern="emphasis" mr={1}>
                        {t('table.headings.lead-retrieval-license-status.name')}
                      </Text>
                      <ToolTip
                        tip={t(
                          'table.headings.lead-retrieval-license-status.tooltip',
                        )}
                        align="top"
                        alignSelf="center"
                      >
                        <FlexIcon name="info-outlined" color="grey-600" />
                      </ToolTip>
                    </ToolTipContainer>
                  </StyledTableHeading>
                )}
                <StyledTableHeading scope="col">
                  <Text pattern="emphasis">{t('table.headings.website')}</Text>
                </StyledTableHeading>
                <StyledTableHeading scope="col">
                  <Text pattern="emphasis">{t('table.headings.provider')}</Text>
                </StyledTableHeading>
                <StyledTableHeading scope="col">
                  <Text pattern="emphasis">
                    {t('table.headings.booth-size')}
                  </Text>
                </StyledTableHeading>
                {flags.showPriority ? (
                  <StyledTableHeading scope="col">
                    <Text pattern="emphasis">
                      {t('table.headings.priority')}
                    </Text>
                  </StyledTableHeading>
                ) : null}
              </TableRow>
            </thead>
            <tbody>
              {vendors.map(vendor => (
                <TableRow key={vendor.id}>
                  <StyledTableCell>
                    <Logo src={vendor.logo} alt="" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Text pattern="body" color="grey-800">
                      {vendor.name}
                    </Text>
                  </StyledTableCell>
                  {showLeadRetrievalLicenses && (
                    <StyledTableCell>
                      <LeadRetrievalLicenseToggle
                        eventSlug={eventSlug}
                        vendorId={vendor.id}
                        status={vendor.leadRetrievalLicenseStatus}
                        hasLicenses={leadRetrievalLicenses > 0}
                        onToggle={data =>
                          handleLeadRetrievalLicenseToggle(vendor, data)
                        }
                        onError={handleLeadRetrievalLicenseError}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCellUrl>
                    <Text pattern="body" color="grey-800">
                      {vendor.website}
                    </Text>
                  </StyledTableCellUrl>
                  <StyledTableCell>
                    <Text pattern="body" color="grey-800">
                      {vendor.streamProvider
                        ? t(`stream_providers.${vendor.streamProvider}`)
                        : ''}
                    </Text>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Text pattern="body" color="grey-800">
                      {t(`booth_sizes.${vendor.boothSize}`)}
                    </Text>
                  </StyledTableCell>
                  {flags.showPriority ? (
                    <StyledTableCell>
                      <Text pattern="body" color="grey-800">
                        {vendor.priority}
                      </Text>
                    </StyledTableCell>
                  ) : null}
                  <StyledTableRowActions>
                    <TableRowAction
                      icon="edit"
                      title={t('table.headings.edit')}
                      onClick={() => handleEdit(vendor.id)}
                    />
                    <TableRowAction
                      icon="delete"
                      title={t('table.headings.remove')}
                      onClick={() => handleRemove(vendor.id)}
                    />
                  </StyledTableRowActions>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          <LocationPagination
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </>
      ) : (
        <p>{t('no-vendors')}</p>
      )}
    </>
  );
};

VendorList.propTypes = {
  authenticityToken: string.isRequired,
  eventSlug: string.isRequired,
  vendors: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      leadRetrievalLicenseStatus: string,
      website: string,
      streamProvider: string.isRequired,
      boothSize: string.isRequired,
      priority: number.isRequired,
      logo: string,
    }),
  ),
  guestOnlyConfigured: bool.isRequired,
  canInvite: bool.isRequired,
  showLeadRetrievalLicenses: bool.isRequired,
  leadRetrievalLicenses: number.isRequired,
  currentPage: number.isRequired,
  totalPages: number.isRequired,
  searchText: string.isRequired,
  flags: shape({
    showPriority: bool,
  }),
};

export default compose(
  withLocalizationProvider,
  withAlertsProvider,
  withThemeProvider,
)(VendorList);
