import { DateField } from '@hopin-team/ui-date-field';
import { func, number, string } from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

export const StyledDateField = styled(DateField)`
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: none;
  }
  tr {
    height: 2.5rem;
  }
  td,
  th {
    border: none;
    padding: 0 1rem;
  }
`;

export const TemplateDateField = props => {
  const wrappedSetDate = useCallback(
    date => {
      props.setDate(date);
    },
    [props],
  );
  return <StyledDateField {...props} setDate={wrappedSetDate} />;
};

TemplateDateField.propTypes = {
  label: string.isRequired,
  date: string.isRequired,
  setDate: func.isRequired,
  mt: number,
  sizeOption: string,
};
