import Alerts from '@features/alerts/alerts';
import { useLocalization } from '@features/localization';
import { Avatar } from '@hopin-team/ui-avatar';
import { Linkicon } from '@hopin-team/ui-linkicon';
import FaceGrinHeartsSVG from 'icons/metrics/face-grin-hearts.svg';
import FaceLaughRelaxedSVG from 'icons/metrics/face-laugh-relaxed.svg';
import FaceSmileSVG from 'icons/metrics/face-smile.svg';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ListWrapper = styled.ul`
  margin-bottom: 0;
  margin-top: 0.5rem;
`;

const AttendeeName = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeight};
  font-size: ${props => props.theme.fontSize};
  color: ${props => props.theme.color};
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListItem = styled.li`
  border-top: 1px solid var(--color-gray-300);
  padding: 0.75rem 0;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
  position: relative;
  line-height: 1.5rem;

  &:first-child {
    border-top: none;
  }

  > span {
    padding: 0 1rem;
  }
`;

const ListLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin-right: 76px;
`;
const ListRight = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  width: 56px;
`;

const Number = styled.div`
  color: var(--color-gray-700);
  white-space: nowrap;
  margin-left: 0.5rem;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-top: 1px solid var(--color-gray-300);
  padding: 1rem 0 0;

  button {
    margin-left: 0;
    max-width: 8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const themes = {
  default: {
    avatarSize: 'tiny',
    name: {
      fontSize: '0.875rem',
      fontWeight: '500',
      color: '#222222',
    },
    role: {
      fontSize: '0.875rem',
      fontWeight: '500',
      color: '#BDBFC7',
    },
  },
  concise: {
    avatarSize: 'tiny',
    name: {
      fontSize: '0.875rem',
      fontWeight: '500',
      marginBottom: '0',
    },
    headline: {
      fontSize: '0.75rem',
      fontWeight: '400',
      marginBottom: '0',
    },
  },
};

const AttendeeListComponent = ({ people, theme, hideShowMore, onShowMore }) => {
  const { t } = useLocalization('connections-dashboard.most-engaged-attendees');

  const getIconFromScore = score => {
    if (score < 50 || !score) {
      return <FaceSmileSVG />;
    } else if (score < 71) {
      return <FaceLaughRelaxedSVG />;
    } else {
      return <FaceGrinHeartsSVG />;
    }
  };

  return (
    <div>
      <Alerts />
      <ListWrapper>
        {people.map(attendee => {
          return (
            <ListItem key={attendee.user_id}>
              <ListLeft>
                <Avatar
                  alt={themes[theme].avatarSize}
                  size={themes[theme].avatarSize}
                  src={attendee.picture_url}
                />
                <span>
                  <AttendeeName theme={themes[theme].name}>
                    {attendee.first_name} {attendee.last_name}
                  </AttendeeName>
                </span>
                <span>
                  <AttendeeName theme={themes[theme].headline}>
                    {attendee.headline}
                  </AttendeeName>
                </span>
              </ListLeft>
              <ListRight>
                <FlexContainer>
                  {<div>{getIconFromScore(attendee.score)}</div>}
                  {<Number>{attendee.score || 0}</Number>}
                </FlexContainer>
              </ListRight>
            </ListItem>
          );
        })}
        {!hideShowMore && (
          <Grid>
            <Linkicon
              label={t('show-more')}
              onClick={onShowMore}
              leadingIcon="plus"
              scale={4}
              iconScale={3}
              ml={3}
            ></Linkicon>
          </Grid>
        )}
      </ListWrapper>
    </div>
  );
};

AttendeeListComponent.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number,
      external_id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      headline: PropTypes.string,
      picture_url: PropTypes.string,
      score: PropTypes.integer,
    }),
  ),
  theme: PropTypes.string,
  hideShowMore: PropTypes.bool,
  onShowMore: PropTypes.func,
};

AttendeeListComponent.defaultProps = {
  theme: 'default',
};

export default AttendeeListComponent;
