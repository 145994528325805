import { featuresConfig } from '@features/event-creation-wizard/constants/add-ons';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  chat: { selected: false },
  polls: { selected: false },
  q_and_a: { selected: false },
  sponsors: { selected: true },
  speakers: { selected: true },
};

const features = createSlice({
  name: 'addOns',
  initialState,
  reducers: {
    setAddOns: (state, action) => action.payload,
  },
});

const {
  reducer,
  actions: { setAddOns },
} = features;

const selectAddonConfig = createSelector(
  state => state.org,
  state => state.template,
  state => state.venue,
  state => state.addOns,
  (org, template, venue, addOns) => {
    const { isOnFreePlan } = org;
    const { id: templateId } = template;

    const selectItems = {};
    Object.entries(venue).map(([key, value]) => {
      const feature = {
        ...featuresConfig[key],
        ...templates[templateId].defaultVenue[key],
        id: key,
        slice: 'venue',
        selected: value.selected,
      };
      if (feature.hidden || (feature.premium && isOnFreePlan)) {
        return;
      }
      selectItems[key] = feature;
    });
    Object.entries(addOns).map(([key, value]) => {
      const feature = {
        ...featuresConfig[key],
        ...templates[templateId].defaultAddOns[key],
        id: key,
        slice: 'add-on',
        selected: value.selected,
      };
      if (feature.hidden || (feature.premium && isOnFreePlan)) {
        return;
      } else {
        selectItems[key] = feature;
      }
    });
    return selectItems;
  },
);

const flattenFeaturesForPayload = features => {
  return Object.entries(features).reduce((acc, [key, value]) => {
    acc[key] = value.selected;
    return acc;
  }, {});
};

export { setAddOns, selectAddonConfig, flattenFeaturesForPayload };

export default reducer;
