import { bool, func, node, object, string } from 'prop-types';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import FileContainerZone, { Filename } from './dropzone.styles';
import MessageStatus from './message-status';

const Dropzone = ({
  children,
  errorMessage,
  hasError,
  isSuccess,
  name,
  onDrop,
  successMessage,
}) => {
  const [files, setFiles] = useState();
  const handleDrop = f => {
    if (onDrop) {
      onDrop(f);
    }
    setFiles(f);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });
  const showFeedbackMessage = isSuccess || hasError;

  return (
    <FileContainerZone
      type="button"
      hasError={hasError}
      isSuccess={isSuccess}
      dragActive={isDragActive}
      {...getRootProps()}
    >
      <input data-testid="file-input" {...getInputProps()} name={name} />
      {files && <Filename data-testid="filename">{files[0].name}</Filename>}
      {!files && !isSuccess && <>{children}</>}
      {showFeedbackMessage && (
        <MessageStatus
          status={isSuccess ? 'success' : 'error'}
          text={isSuccess ? successMessage : errorMessage}
        />
      )}
    </FileContainerZone>
  );
};

Dropzone.defaultProps = {
  file: { name: '' },
  onChange: () => {},
};

Dropzone.propTypes = {
  children: node,
  errorMessage: string,
  file: object,
  hasError: bool,
  isSuccess: bool,
  multiple: bool,
  name: string,
  onDrop: func.isRequired,
  onChange: func,
  successMessage: string,
};

export default Dropzone;
