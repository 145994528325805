import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { oneOf } from 'prop-types';
import React from 'react';

const OnsiteBanner = ({ eventType, sessionType }) => {
  const { t } = useLocalization('sessions.onsite-banner');

  let title;
  let description;

  if (eventType == 'onsite') {
    title = t('onsite-onsite-title');
    description = t('onsite-onsite-description');
  }

  if (eventType == 'hybrid' && sessionType == 'onsite') {
    title = t('hybrid-onsite-title');
    description = t('hybrid-onsite-description');
  }

  if (eventType == 'hybrid' && sessionType == 'hybrid') {
    title = t('hybrid-hybrid-title');
    description = t('hybrid-hybrid-description');
  }

  if (title == null || description == null) {
    return null;
  }

  return (
    <Box mt={2} data-testid="onsite-banner">
      <Box background="blue-100" cornerRadius="lg" p={2}>
        <Flex>
          <Icon name="info-circle" color="blue-500" mt={1} mr={1} />
          <Box>
            <Text pattern="headingFive" element="p">
              {title}
            </Text>
            <Text pattern="body" element="p" mt={0.25}>
              {description}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

OnsiteBanner.propTypes = {
  eventType: oneOf(['virtual', 'hybrid', 'onsite']).isRequired,
  sessionType: oneOf(['virtual', 'hybrid', 'onsite']).isRequired,
};

export default withLocalizationProvider(OnsiteBanner);
