import { createSlice } from '@reduxjs/toolkit';

const flagsSlice = createSlice({
  name: 'flags',
  initialState: {
    dashboardOptIn: false,
    appEnvironment: '',
    basicDetailsDynamicGraphicEnabled: false,
    essentialsWizardEventSimplification: false,
  },
});

const { reducer } = flagsSlice;
export default reducer;
