import { Select } from '@hopin-team/ui-select';
import { arrayOf, shape, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

const SelectInput = ({
  name,
  id,
  label,
  placeholder,
  initialValue = null,
  options = [],
}) => {
  const [value, setValue] = useState(initialValue);

  const changeValue = useCallback(
    newValue => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <>
      <Select
        size="small"
        label={label}
        options={options}
        placeholder={placeholder}
        value={value}
        setValue={changeValue}
      />
      <input
        name={name}
        id={id}
        value={value}
        type="hidden"
        data-testid="select-input-hidden-input"
      />
    </>
  );
};

SelectInput.propTypes = {
  label: string.isRequired,
  placeholder: string,
  name: string.isRequired,
  id: string.isRequired,
  initialValue: string,
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
};

export default SelectInput;
