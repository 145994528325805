import { useLocalization } from '@features/localization';
import { RadioButton, RadioGroup } from '@hopin-team/ui-radio-buttons';
import { func, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const EventPhaseToggleComponent = ({ currentPhase, setCurrentPhase }) => {
  const { t } = useLocalization('connections-dashboard.event-phase');

  return (
    <Wrapper>
      <RadioGroup
        name="event-phase"
        selectedValue={currentPhase}
        onChange={e => setCurrentPhase(e.currentTarget.value)}
      >
        <RadioButton id="draft" label={t('draft')} value="Draft" />
        <RadioButton id="upcoming" label={t('upcoming')} value="Upcoming" />
        <RadioButton id="live" label={t('live')} value="Live" />
        <RadioButton id="ended" label={t('ended')} value="Ended" />
      </RadioGroup>
    </Wrapper>
  );
};

EventPhaseToggleComponent.propTypes = {
  currentPhase: string.isRequired,
  setCurrentPhase: func.isRequired,
};

export default EventPhaseToggleComponent;

const Wrapper = styled.div`
  fieldset {
    display: flex;
  }

  .hpn-RadioButton {
    margin-left: 1rem;
  }
`;
