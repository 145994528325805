import 'react-tippy/dist/tippy.css';

import Chart from '@components/icons/chart';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { percentageToColor } from '@features/dashboard/util/colors';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';

import areaNameWithPrefix from '../util/area-name-with-prefix';

const BarTd = styled.td`
  width: 25%;

  @media print {
    display: none;
  }
`;

const Bar = styled.div`
  width: ${props => props.percentage}%;
  max-width: 100%;
  height: 10px;
  background: ${props => percentageToColor(props.percentage)};
`;

const Table = styled.table`
  width: 100%;

  .number {
    text-align: right;
    padding-right: var(--spacing-8) !important;
  }

  .number-header {
    text-align: right;
  }

  .name {
    width: 65%;
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: var(--spacing-8);

    @media print {
      width: 90%;
    }
  }

  tr {
    height: 2.5rem;
    font-weight: var(--font-weight-medium);
  }
`;

const TooltipContentContainer = styled.div`
  text-align: left;
  padding: var(--spacing-8);
  font-size: var(--font-size);

  .name {
    margin-bottom: var(--spacing-8);
  }
  .number-label {
    padding-right: var(--spacing-16);
    display: inline-block;
  }
  .number {
    float: right;
    font-weight: bold;
  }
`;

function TooltipContent(props) {
  return (
    <TooltipContentContainer>
      <div className="name">{props.name}</div>
      <div className="number-label">{props.numberLabel}</div>
      <div className="number">{props.count}</div>
    </TooltipContentContainer>
  );
}

const TableBody = ({ items, numberLabel }) => (
  <tbody>
    {items.map((item, index) => {
      return (
        <Tooltip
          tag="tr"
          html={
            <TooltipContent
              name={areaNameWithPrefix(item)}
              count={item.count}
              numberLabel={numberLabel}
            />
          }
          style={{ display: 'table-row' }}
          position="right"
          theme="light"
          key={index}
          followCursor={true}
        >
          <td className="name">{areaNameWithPrefix(item)}</td>
          <td className="number">{item.count}</td>
          <BarTd>
            <Bar
              className="bar"
              data-testid="top-items-bar"
              percentage={item.ratio * 100}
            />
          </BarTd>
        </Tooltip>
      );
    })}
  </tbody>
);

const NoContent = ({ text }) => <EmptyState icon={<Chart />} text={text} />;

const Content = ({ items, numberLabel }) => (
  <div>
    <Table className="bordered">
      <TableBody items={items} numberLabel={numberLabel} />
    </Table>
  </div>
);

const TopItemsComponent = ({ items, title, numberLabel, emptyText }) => {
  return (
    <CardContentWrapper>
      <CardTitle>{title}</CardTitle>
      {items.length ? (
        <Content items={items} numberLabel={numberLabel} />
      ) : (
        <NoContent text={emptyText} />
      )}
    </CardContentWrapper>
  );
};

const itemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    segment: PropTypes.string,
    count: PropTypes.number,
    ratio: PropTypes.number,
  }),
);

const contentPropTypes = {
  items: itemsPropTypes,
  numberLabel: PropTypes.string,
  emptyText: PropTypes.string,
};

TopItemsComponent.propTypes = {
  title: PropTypes.string,
  ...contentPropTypes,
};

Content.propTypes = {
  ...contentPropTypes,
};

NoContent.propTypes = {
  text: PropTypes.string.isRequired,
};

TooltipContent.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
  numberLabel: PropTypes.string,
};

TableBody.propTypes = {
  items: itemsPropTypes,
  numberLabel: PropTypes.string,
};

export default TopItemsComponent;
