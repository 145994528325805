import { string } from 'prop-types';
import React from 'react';

const CircleCheck = () => (
  <svg
    data-testid="success-icon"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.2542C0 4.73139 4.47715 0.254242 10 0.254242C12.6522 0.254242 15.1957 1.30781 17.0711 3.18317C18.9464 5.05854 20 7.60208 20 10.2542C20 15.7771 15.5228 20.2542 10 20.2542C4.47715 20.2542 0 15.7771 0 10.2542ZM8.73 13.6042L14.35 7.98424C14.5341 7.79109 14.5341 7.48739 14.35 7.29424L13.82 6.76424C13.6256 6.57367 13.3144 6.57367 13.12 6.76424L8.38 11.5042L6.88 10.0142C6.78927 9.91757 6.66259 9.86272 6.53 9.86272C6.39741 9.86272 6.27073 9.91757 6.18 10.0142L5.65 10.5442C5.55534 10.6381 5.5021 10.7659 5.5021 10.8992C5.5021 11.0326 5.55534 11.1604 5.65 11.2542L8.03 13.6042C8.12073 13.7009 8.24741 13.7558 8.38 13.7558C8.51259 13.7558 8.63927 13.7009 8.73 13.6042Z"
      fill="currentColor"
    />
  </svg>
);

CircleCheck.propTypes = {
  color: string.isRequired,
};

export default CircleCheck;
