import { node, string } from 'prop-types';
import React from 'react';

import { AnnouncementsText } from '../styles';

export const EmailDetailText = ({ label, value, ...rest }) => (
  <AnnouncementsText weight={400} my={0.5} mb={1.5} {...rest}>
    <AnnouncementsText element="span" weight={400}>
      {label}
      {': '}
    </AnnouncementsText>
    {value}
  </AnnouncementsText>
);

EmailDetailText.propTypes = {
  label: string.isRequired,
  value: node.isRequired,
};
