import React from 'react';

const Warning = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2702 13.0868L8.54192 1.58016C8.4507 1.42764 8.28623 1.33404 8.10848 1.3335H7.90843C7.73069 1.33404 7.56621 1.42764 7.475 1.58016L0.733403 13.0868C0.646808 13.2446 0.646808 13.4357 0.733403 13.5935L0.826759 13.7468C0.913173 13.905 1.07994 14.0025 1.26019 14.0002H14.7434C14.9236 14.0025 15.0904 13.905 15.1768 13.7468L15.2702 13.5935C15.3568 13.4357 15.3568 13.2446 15.2702 13.0868ZM8.66862 11.0002C8.66862 11.1843 8.51934 11.3335 8.3352 11.3335H7.66838C7.48424 11.3335 7.33497 11.1843 7.33497 11.0002V10.3335C7.33497 10.1494 7.48424 10.0002 7.66838 10.0002H8.3352C8.51934 10.0002 8.66862 10.1494 8.66862 10.3335V11.0002ZM8.35521 8.66683C8.44 8.66744 8.51174 8.60432 8.52191 8.52016L8.78864 6.3735C8.80014 6.27839 8.77016 6.18294 8.70635 6.11148C8.64253 6.04002 8.55105 5.99947 8.45523 6.00016H7.54835C7.45253 5.99947 7.36105 6.04002 7.29723 6.11148C7.23342 6.18294 7.20344 6.27839 7.21494 6.3735L7.48167 8.52016C7.49184 8.60432 7.56358 8.66744 7.64837 8.66683H8.35521Z"
      fill="currentColor"
    />
  </svg>
);

export default Warning;
