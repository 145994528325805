import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { formatISO, isValid } from 'date-fns';
import { array, bool, number, object, oneOfType, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import {
  DateField,
  MultiSelect,
  Select,
  TextField,
} from './attendee-details-fields.styles';

export const FormTextField = ({
  disabled,
  label,
  multiLine,
  name,
  rules,
  size = 'small',
}) => {
  const { errors } = useFormContext();
  const {
    field,
    meta: { invalid },
  } = useController({ defaultValue: '', name, rules });

  return (
    <TextField
      {...field}
      errorMessage={errors[name]?.message}
      hasErrors={invalid}
      isDisabled={disabled}
      isRequired={rules?.required}
      label={label}
      multiLine={multiLine}
      name={name}
      size={size}
      mb={3}
    />
  );
};

FormTextField.propTypes = {
  disabled: bool.isRequired,
  label: string.isRequired,
  multiLine: bool,
  name: string.isRequired,
  rules: object,
  size: string,
};

export const FormSelectField = ({
  disabled,
  label,
  name,
  options,
  rules,
  leadingIcon,
}) => {
  const {
    // eslint-disable-next-line no-unused-vars
    field: { onChange, ref: _ref, ...field },
    meta: { invalid },
  } = useController({ name, rules });

  const setValue = useCallback(
    selectedValue =>
      onChange(options.find(({ value }) => value === selectedValue)?.value),
    [options, onChange],
  );

  return (
    <Select
      {...field}
      disabled={disabled}
      hasErrors={invalid}
      isRequired={rules?.required}
      label={label}
      leadingIcon={leadingIcon}
      options={options}
      setValue={setValue}
      size="medium"
      mb={3}
      visibleOptionsCount={5}
    />
  );
};

FormSelectField.propTypes = {
  disabled: bool.isRequired,
  label: string.isRequired,
  options: array.isRequired,
  name: string.isRequired,
  rules: object,
  size: string,
  leadingIcon: string,
};

export const FormMultiSelectField = ({ id, disabled, label, name, rules }) => {
  const fieldId = `attendee-detail-field-${id}`;
  const {
    // eslint-disable-next-line no-unused-vars
    field: { onChange, ref: _ref, ...field },
  } = useController({ name, rules });

  const toggle = option => () => {
    const idx = field.value.indexOf(option);
    const updatedValue = [...field.value];
    updatedValue.splice(idx, 1, {
      ...option,
      checked: !option.checked,
    });
    onChange(updatedValue);
  };

  return (
    <MultiSelect role="group" aria-labelledby={fieldId} mb={3}>
      <Text
        id={fieldId}
        color={disabled ? 'grey-600' : 'grey-800'}
        pattern="captionMedium"
      >
        {label}
      </Text>

      <Flex flexDirection="column">
        {field.value?.map(option => (
          <Checkbox
            {...field}
            disabled={disabled}
            key={option.label}
            label={option.label}
            value={option.value}
            checked={option.checked}
            onChange={toggle(option)}
          />
        ))}
      </Flex>
    </MultiSelect>
  );
};

FormMultiSelectField.propTypes = {
  disabled: bool.isRequired,
  id: oneOfType([string, number]).isRequired,
  label: string.isRequired,
  name: string.isRequired,
  rules: object,
};

export const FormDateField = ({ disabled, name, rules, label }) => {
  const { errors, register, setValue } = useFormContext();
  const value = useWatch({ name });

  const valueAsDate = useMemo(() => new Date(`${value}T00:00:00`), [value]);
  const date = useMemo(() => (isValid(valueAsDate) ? valueAsDate : undefined), [
    valueAsDate,
  ]);

  const setDate = useCallback(
    selectedDate => {
      setValue(
        name,
        formatISO(selectedDate, {
          representation: 'date',
        }),
      );
    },
    [name, setValue],
  );

  return (
    <DateField
      {...register(name, rules)}
      date={date}
      hasErrors={errors[name]}
      isDisabled={disabled}
      isRequired={rules?.required}
      label={label}
      onDateChange={setDate}
    />
  );
};

FormDateField.propTypes = {
  disabled: bool.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  rules: object,
};
