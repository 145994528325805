import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { eventStatuses } from '@features/events/constants';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { getPusherChannel, pusherEvents } from '@features/templates/constants';
import { getNewEventSetupAlert } from '@features/templates/util/get-new-event-setup-alert';
import { bindToPusher } from '@util/pusher';
import { string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import EventStatus from '.';

const ErbEventStatus = ({
  buildingClass,
  eventExternalId,
  eventName,
  initialStatus,
}) => {
  const [status, setStatus] = useState(initialStatus);
  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization();

  useEffect(() => {
    let unbind;
    async function bindToPusherAndAssignUnbind() {
      unbind = await bindToPusher({
        channelName: getPusherChannel(eventExternalId),
        eventName: pusherEvents.EVENT_DUPLICATION_FINISHED,
        handler: payload => {
          const eventElement = document.querySelector(
            `[data-event-external-id="${eventExternalId}"]`,
          );
          eventElement.classList.remove(buildingClass);
          setStatus(eventStatuses.DRAFT);
          const alert = getNewEventSetupAlert(payload, eventName, t);
          addAlert(alert);
        },
      });
    }

    if (status === eventStatuses.BUILDING) {
      bindToPusherAndAssignUnbind();

      return () => {
        try {
          unbind();
        } catch (err) {
          console.error('Error unbinding pusher event', err);
        }
      };
    }
  });

  return (
    <>
      <Alerts />
      <EventStatus
        label={t(`common.event-statuses.${status}`)}
        status={status}
      />
    </>
  );
};

ErbEventStatus.propTypes = {
  buildingClass: string.isRequired,
  eventExternalId: string.isRequired,
  eventName: string.isRequired,
  initialStatus: string.isRequired,
};

export default withLocalizationProvider(withAlertsProvider(ErbEventStatus));
