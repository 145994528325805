import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  description: '',
  image: null,
};

export const sessionsSlice = createSlice({
  name: 'event-creation-wizard-sessions',
  initialState,
  reducers: {
    setSession(state, action) {
      return action.payload;
    },
  },
});

export const { setSession } = sessionsSlice.actions;

export default sessionsSlice.reducer;
