import TextWrapper from '@components/wrappers/text';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

const EmptyState = ({
  eventSlug,
  descriptionText,
  notLiveDescriptionText,
  route,
  routeMagicLinks,
  status,
  hasMagicLinks,
}) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <TextWrapper>
        {status === 'draft' ? (
          <Text
            data-testid="empty-text"
            element="p"
            mt={2}
            mb={0}
            color="grey-700"
            pattern="body"
          >
            {notLiveDescriptionText}
          </Text>
        ) : hasMagicLinks ? (
          <>
            <Text
              data-testid="empty-text"
              element="p"
              mt={2}
              color="grey-700"
              pattern="body"
            >
              Invite attendees directly with a{' '}
              <a href={routeMagicLinks(eventSlug)}>Magic Link</a>
            </Text>
            <Text
              data-testid="empty-text"
              element="p"
              color="grey-700"
              pattern="body"
            >
              or send them a link to your{' '}
              <a
                href={route(eventSlug)}
                target="_blank"
                rel="noreferrer noopener"
              >
                Registration page
              </a>
            </Text>
          </>
        ) : (
          <>
            <Text
              data-testid="empty-text"
              element="p"
              mt={2}
              color="grey-700"
              pattern="body"
            >
              {descriptionText}
            </Text>
            <p>
              <a
                href={route(eventSlug)}
                target="_blank"
                rel="noreferrer noopener"
              >
                Registration Page
              </a>
            </p>
          </>
        )}
      </TextWrapper>
    </Flex>
  );
};

EmptyState.propTypes = {
  event: object,
  descriptionText: string,
  eventSlug: string,
  status: string,
  notLiveDescriptionText: string,
  hasMagicLinks: bool,
  route: func,
  routeMagicLinks: func,
};

export default EmptyState;
