import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import {
  logger,
  pusherBindDemoEvent,
  pusherInitialize,
} from '@util/pusher/demo-event';
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FadeInOutText } from './components/FadeInOutText';
import { images } from './constants';
import { BigSpinner, Container, ImageContainer, TextContainer } from './styles';

const DemoEventLoader = ({ sourceEventId }) => {
  const [eventId, setEventId] = useState();
  const [magicLink, setMagicLink] = useState();
  const [error, setError] = useState(false);
  const [eventDuplicated, setEventDuplicated] = useState(false);
  const [totalSectionsCount, setTotalSectionsCount] = useState(0);
  const [finishedSectionsCount, setFinishedSectionsCount] = useState(0);
  const { addAlert } = React.useContext(AlertsContext);
  const { t } = useLocalization('demo-event-loader');

  const handleError = payload => {
    logger.log(payload);
    addAlert({
      active: true,
      text: t('error'),
      pattern: 'error',
    });
    setError(true);
  };

  const handlePusherSuccess = event_external_id => {
    return async payload => {
      logger.log(payload);
      setEventDuplicated(true);
      const seedResp = await hopinApi.seedDemoEvent(
        event_external_id,
        sourceEventId,
      );
      // TODO: handle errors
      setMagicLink(seedResp.magic_link);
    };
  };

  const handleSectionFinish = () => {
    setFinishedSectionsCount(
      finishedSectionsCount => finishedSectionsCount + 1,
    );
  };

  useEffect(() => {
    let unbind;
    const handleFlow = async () => {
      const resp = await hopinApi.generateDemoEvent(sourceEventId);
      if (resp.magic_link) {
        // there is already demo event for this user
        setEventId(resp.event_external_id);
        setEventDuplicated(true);
        setMagicLink(resp.magic_link);
      } else {
        if (!resp.pusher_channel) {
          handleError('No Channel Provided');
          return;
        }

        pusherInitialize(resp.pusher_channel);
        unbind = pusherBindDemoEvent({
          channelName: resp.pusher_channel,
          sectionFinishHandler: handleSectionFinish,
          successHandler: handlePusherSuccess(resp.event_external_id),
          errorHandler: handleError,
        });
        setEventId(resp.event_external_id);
        setTotalSectionsCount(resp.duplicating_sections_count);
      }
    };

    if (!unbind && !error) handleFlow();
    return unbind ? unbind() : null;
  }, []);

  const { image1x, image2x, image3x } = images;
  const srcSet = `${image1x} 1x, ${image2x} 2x, ${image3x} 3x`;

  if (error) {
    return (
      <Container>
        <TextContainer>
          <FadeInOutText inProp={error} text={t('error')} />
        </TextContainer>
        <Button href="/create-demo-event" isInline>
          {t('error-cta')}
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      {!magicLink ? (
        <BigSpinner m={2} size="large" isShowing={true} />
      ) : (
        <ImageContainer>
          <img src={image1x} srcSet={srcSet} alt="demo event" />
        </ImageContainer>
      )}
      <TextContainer>
        <FadeInOutText
          inProp={!eventId && !magicLink}
          text={t('first-message')}
        />
        <FadeInOutText
          inProp={eventId && !eventDuplicated}
          text={t('duplicating-message', {
            finishedSectionsCount,
            totalSectionsCount,
          })}
        />
        <FadeInOutText
          inProp={eventId && eventDuplicated && !magicLink}
          text={t('second-message')}
        />
        <FadeInOutText
          inProp={magicLink !== undefined}
          text={t('third-message')}
        />
      </TextContainer>
      {magicLink && (
        <Button href={magicLink} isInline>
          {t('cta')}
        </Button>
      )}
    </Container>
  );
};

DemoEventLoader.propTypes = {
  sourceEventId: string,
};

export default DemoEventLoader;
