import analyticsApi from '@api/analytics';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import { object, string } from 'prop-types';
import React, { useState } from 'react';

import PreEventTopMetrics from './pre-event-top-metrics-component';

const PreEventTopMetricsContainer = ({
  eventSlug,
  eventWizardTemplate,
  eventAreas = {},
}) => {
  const { t } = useLocalization('overview.pre-event-top-metrics');
  const [preEventSummaryStats, setPreEventSummaryStats] = useState({
    session_count: 0,
    vendor_count: 0,
  });

  const loading = usePromise(
    signal => analyticsApi.getPreEventSummaryStats(eventSlug, signal),
    result => setPreEventSummaryStats(result),
  );

  const showSessionCount = !eventWizardTemplate || eventAreas.sessions;
  const showExpoCount = !eventWizardTemplate || eventAreas.expo;

  const metrics = [
    ...(showSessionCount
      ? [
          {
            id: 'sessions',
            description: t('sessions'),
            displayValue: preEventSummaryStats.session_count,
          },
        ]
      : []),
    ...(showExpoCount
      ? [
          {
            id: 'vendors',
            description: t('vendors'),
            displayValue: preEventSummaryStats.vendor_count,
          },
        ]
      : []),
  ];

  return <PreEventTopMetrics metrics={metrics} isLoading={loading} />;
};

PreEventTopMetricsContainer.propTypes = {
  eventSlug: string.isRequired,
  eventWizardTemplate: string,
  eventAreas: object,
};

export default PreEventTopMetricsContainer;
