import { bool, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const ListItem = styled.div`
  padding: 0.75rem 0;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-300);
  }

  & > :last-child {
    margin-left: auto;
  }
`;

const LogoWrap = styled.div`
  flex: none;
  margin-right: 0.5rem;
`;

const LogoImg = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: ${props => (props.rounded ? '100%' : '0')};
`;

export const Logo = ({ alt, src, rounded }) => (
  <LogoWrap>
    <LogoImg alt={alt} src={src} rounded={rounded} />
  </LogoWrap>
);

Logo.propTypes = {
  alt: string.isRequired,
  src: string.isRequired,
  rounded: bool,
};

Logo.defaultProps = {
  rounded: false,
};

export const Name = styled.div`
  margin-right: 0.5rem;
  word-break: break-word;
`;
