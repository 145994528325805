import PropTypes from 'prop-types';
import React from 'react';

import Alice from './assets/alice.png';
import BigMan from './assets/big-man.png';
import EmojiHalo from './assets/emoji-halo.png';
import EmojiStars from './assets/emoji-stars.png';
import EmojiThumb from './assets/emoji-thumb.png';
import EmojiWave from './assets/emoji-wave.png';
import Jaoa from './assets/jaoa.png';
import Kahoot from './assets/kahoot.png';
import Kate from './assets/kate.png';
import Miro from './assets/miro.png';
import Slido from './assets/slido.png';

export const MeetupPreview = ({ logo, eventName }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="none"
    viewBox="0 0 657 354"
  >
    <g filter="url(#a)">
      <rect
        width={568}
        height={293}
        x={25}
        y={26}
        fill="#fff"
        rx={16}
        shapeRendering="crispEdges"
      />
      <image
        width={25}
        height={25}
        x={36.5}
        y={37}
        clipPath="inset(0% round 4px)"
        href={logo}
      />
      <rect
        width={31.2}
        height={31.2}
        x={33.4}
        y={34.4}
        stroke="#222"
        strokeOpacity={0.1}
        strokeWidth={0.8}
        rx={7.6}
      />
      <text
        xmlSpace="preserve"
        fill="#222"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={77} y={54.4}>
          {eventName.length > 30 ? eventName.slice(0, 30) + '...' : eventName}
        </tspan>
      </text>
      <g clipPath="url(#b)">
        <rect
          width={391}
          height={237}
          x={33}
          y={74}
          fill="#0684BC"
          fillOpacity={0.1}
          rx={8}
        />
        <text
          xmlSpace="preserve"
          fill="#0684BC"
          fontFamily="Inter"
          fontSize={20}
          fontWeight="bold"
          letterSpacing={0}
          style={{
            whiteSpace: 'pre',
          }}
        >
          <tspan x={65} y={128.4}>
            {'Innovation\u2028'}
          </tspan>
          <tspan x={65} y={154.4}>
            {'Workshop'}
          </tspan>
        </text>
        <path
          fill="#0684BC"
          fillOpacity={0.1}
          d="M243.49 103.58C277.35 36.1 380.95-40.62 479.63-7.04c62.4 21.2 94.22 78.7 71.7 128.59l-.46.9c-33.68 79.82-21.6 165.6 25.5 237.54 35.44 53.96 90.53 100.27 160.8 130.45-84.7 29.63-184.87 31.9-279.94-.41-123.46-41.79-214.23-136.92-233-243.97-8.84-49.17-2.56-99.37 19.26-142.48Z"
        />
        <path fill="url(#c)" d="M196 121h214v191H196z" />
        <rect width={55} height={20} x={361} y={82} fill="#BCE0F0" rx={4} />
        <path
          fill="#0684BC"
          d="M382.16 93.23a2.4 2.4 0 0 0 0-2.46c-1.02-1.71-3.32-4.6-7.16-4.6-3.84 0-6.14 2.89-7.16 4.6a2.4 2.4 0 0 0 0 2.46c1.02 1.71 3.32 4.6 7.16 4.6 3.84 0 6.14-2.89 7.16-4.6Z"
          opacity={0.4}
        />
        <path
          fill="#0684BC"
          d="M375 89.08c-.3 0-.6.06-.87.15a1.36 1.36 0 0 1-1.9 1.9c-.09.28-.15.56-.15.87a2.92 2.92 0 1 0 2.92-2.92Z"
        />
        <text
          xmlSpace="preserve"
          fill="#0684BC"
          fontFamily="Inter"
          fontSize={9}
          fontWeight="bold"
          letterSpacing={0}
          style={{
            whiteSpace: 'pre',
          }}
        >
          <tspan x={389} y={95.3}>
            {'1,293'}
          </tspan>
        </text>
      </g>
      <rect width={153} height={73.67} x={432} y={74} fill="url(#d)" rx={8} />
      <rect width={34} height={16} x={436} y={128} fill="#D0E8F3" rx={6} />
      <text
        xmlSpace="preserve"
        fill="#0684BC"
        fontFamily="Inter"
        fontSize={10}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={441.4} y={139.7}>
          {'Alice'}
        </tspan>
      </text>
      <rect
        width={153}
        height={73.67}
        x={432}
        y={155.67}
        fill="url(#e)"
        rx={8}
      />
      <rect width={33} height={16} x={436} y={209} fill="#D0E8F3" rx={6} />
      <text
        xmlSpace="preserve"
        fill="#0684BC"
        fontFamily="Inter"
        fontSize={10}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
        transform="translate(441 209)"
      >
        <tspan x={0.24} y={11.7}>
          {'Joao'}
        </tspan>
      </text>
      <rect
        width={153}
        height={73.67}
        x={432}
        y={237.33}
        fill="url(#f)"
        rx={8}
      />
      <rect width={31} height={16} x={436} y={291} fill="#D0E8F3" rx={6} />
      <text
        xmlSpace="preserve"
        fill="#0684BC"
        fontFamily="Inter"
        fontSize={10}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
        transform="translate(441 291)"
      >
        <tspan x={0.07} y={11.7}>
          {'Kate'}
        </tspan>
      </text>
    </g>
    <path fill="url(#g)" d="M77 281h50v50H77z" />
    <path fill="url(#h)" d="M426 233h31v31h-31z" />
    <path fill="url(#i)" d="M44 259h24v24H44z" />
    <path fill="url(#j)" d="M86 240h23v23H86z" />
    <g filter="url(#k)">
      <rect
        width={99}
        height={40}
        x={309}
        y={295}
        fill="url(#l)"
        rx={16}
        shapeRendering="crispEdges"
      />
      <rect width={24} height={24} x={317} y={303} fill="url(#m)" rx={8} />
      <text
        xmlSpace="preserve"
        fill="#222"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={351} y={319.44}>
          {'Kahoot!'}
        </tspan>
      </text>
      <rect
        width={98}
        height={39}
        x={309.5}
        y={295.5}
        stroke="url(#n)"
        rx={15.5}
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#o)">
      <rect
        width={81}
        height={40}
        x={462}
        y={6}
        fill="url(#p)"
        rx={16}
        shapeRendering="crispEdges"
      />
      <rect width={24} height={24} x={470} y={14} fill="url(#q)" rx={8} />
      <text
        xmlSpace="preserve"
        fill="#222"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={504} y={30.44}>
          {'Miro'}
        </tspan>
      </text>
      <rect
        width={80}
        height={39}
        x={462.5}
        y={6.5}
        stroke="url(#r)"
        rx={15.5}
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#s)">
      <rect
        width={84}
        height={40}
        x={561}
        y={173}
        fill="url(#t)"
        rx={16}
        shapeRendering="crispEdges"
      />
      <rect width={24} height={24} x={569} y={181} fill="url(#u)" rx={8} />
      <rect
        width={23.5}
        height={23.5}
        x={569.25}
        y={181.25}
        stroke="#222"
        strokeOpacity={0.1}
        strokeWidth={0.5}
        rx={7.75}
      />
      <text
        xmlSpace="preserve"
        fill="#222"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={603} y={197.44}>
          {'Slido'}
        </tspan>
      </text>
      <rect
        width={83}
        height={39}
        x={561.5}
        y={173.5}
        stroke="url(#v)"
        rx={15.5}
        shapeRendering="crispEdges"
      />
    </g>
    <g clipPath="url(#w)">
      <mask
        id="x"
        width={174}
        height={230}
        x={407}
        y={79}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M580 79H407v230h173V79Z" />
      </mask>
      <g mask="url(#x)">
        <mask
          id="y"
          width={5796}
          height={5780}
          x={-2521}
          y={-2811}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill="#fff"
            d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
          />
        </mask>
        <g mask="url(#y)">
          <mask
            id="z"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#z)">
            <path
              fill="#0D0D80"
              d="m505.31 142.86 2-2.15-2.93-2.72-2 2.15 2.93 2.72Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="A"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#A)">
            <path
              fill="#FF9500"
              d="m557.95 132.89 2.34-1.65-2.36-3.33-2.34 1.64 2.36 3.34Z"
            />
          </g>
          <mask
            id="B"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#B)">
            <path
              fill="#FF9500"
              d="m539.8 123 .56-1.28-3.83-1.67-.56 1.27 3.83 1.67Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="C"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#C)">
            <path
              fill="#2AAC7E"
              d="m518.5 131.98 1.01-1.08-2.94-2.72-1 1.07 2.94 2.73Z"
            />
          </g>
          <mask
            id="D"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#D)">
            <path
              fill="#FF3B30"
              d="M497.89 108c1.15.06 2.13-.5 2.17-1.26.04-.76-.86-1.43-2.02-1.49-1.16-.06-2.13.5-2.17 1.26-.04.75.86 1.42 2.02 1.48Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="E"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#E)">
            <path
              fill="#FF9500"
              d="M546.52 166.6c-.94-.38-1.98 0-2.31.83-.34.83.15 1.82 1.09 2.2.94.38 1.98 0 2.32-.83.34-.84-.15-1.82-1.1-2.2Z"
            />
          </g>
          <mask
            id="F"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#F)">
            <path
              stroke="#FF9500"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2.06}
              d="M544.52 119.2a7.09 7.09 0 0 0-.64 3.89c.69 4.34 6.88 6.46 7.57 10.8.68 4.25-4.57 8.06-3.9 12.31.13.84.48 1.58.95 2.28"
            />
          </g>
          <mask
            id="G"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#G)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.89}
              d="M534.64 103.81c-1.55 2.8-4.3 5.37-4.13 8.46.22 4.3 6.16 6.94 6.39 11.24.12 2.34-1.49 4.37-2.94 6.42"
            />
          </g>
          <mask
            id="H"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#H)">
            <path
              fill="#2AAC7E"
              d="m518.48 128.27-.3-4.11-2.81.2.3 4.11 2.81-.2Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="I"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#I)">
            <path
              fill="#2AAC7E"
              d="m572.14 129.5-2.56-3.2-2.23 1.77 2.56 3.2 2.23-1.76Z"
            />
          </g>
          <mask
            id="J"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#J)">
            <path
              fill="#0D0D80"
              d="m553.27 121.51-.84-1.91-2.53 1.1.84 1.92 2.53-1.11Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="K"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#K)">
            <path
              fill="#FF9500"
              d="m528.65 128.37-1.3 1.53 2.27 1.89 1.28-1.54-2.25-1.88Z"
            />
          </g>
          <mask
            id="L"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#L)">
            <path
              fill="#0D0D80"
              d="M479.14 118.7c.4-.68-.06-1.7-1.04-2.26-.97-.56-2.09-.47-2.49.22-.4.68.07 1.7 1.05 2.26.97.56 2.09.47 2.48-.22Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="M"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#M)">
            <path
              fill="#FF9500"
              d="M551.27 142.62c.83-.1 1.38-1.06 1.25-2.13-.14-1.08-.93-1.86-1.75-1.75-.83.1-1.39 1.06-1.25 2.13.14 1.07.92 1.86 1.75 1.75Z"
            />
          </g>
          <mask
            id="N"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#N)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.92}
              d="M544.02 102.5c1.32 3.3 5.13 6.1 5.04 9.7-.11 4.3-5.98 7.1-6.09 11.4-.05 2.06 1.23 3.84 2.56 5.61"
            />
          </g>
          <mask
            id="O"
            width={5796}
            height={5780}
            x={-2289}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3506.8-2810.16h-5795.08v5778.32H3506.8v-5778.32Z"
            />
          </mask>
          <g mask="url(#O)">
            <path
              stroke="#0D0D80"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2.01}
              d="M548.57 83.64c.39.57.7 1.19.86 1.88 1.07 4.26-3.8 8.63-2.73 12.89 1.04 4.18 7.37 5.64 8.42 9.82.3 1.19.11 2.38-.28 3.57"
            />
          </g>
        </g>
        <mask
          id="P"
          width={5796}
          height={5780}
          x={-2521}
          y={-2811}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill="#fff"
            d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
          />
        </mask>
        <g mask="url(#P)">
          <mask
            id="Q"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#Q)">
            <path
              fill="#0D0D80"
              d="m481.4 142.87-2-2.16 2.94-2.72 2 2.15-2.93 2.73Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="R"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#R)">
            <path
              fill="#FF9500"
              d="m428.77 132.89-2.34-1.65 2.36-3.33 2.34 1.64-2.36 3.34Z"
            />
          </g>
          <mask
            id="S"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#S)">
            <path
              fill="#FF9500"
              d="m446.92 123-.56-1.28 3.83-1.67.56 1.27-3.83 1.67Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="T"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#T)">
            <path
              fill="#2AAC7E"
              d="m468.21 131.98-1-1.08 2.94-2.72 1 1.08-2.94 2.72Z"
            />
          </g>
          <mask
            id="U"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#U)">
            <path
              fill="#FF3B30"
              d="M488.83 108c-1.16.06-2.13-.5-2.17-1.26-.05-.76.86-1.42 2.01-1.49 1.16-.06 2.13.5 2.18 1.26.04.75-.87 1.42-2.02 1.48Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="V"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#V)">
            <path
              fill="#FF9500"
              d="M440.19 166.6c.94-.38 1.98 0 2.32.83.34.84-.15 1.82-1.1 2.2-.94.38-1.98 0-2.31-.83-.34-.84.15-1.82 1.09-2.2Z"
            />
          </g>
          <mask
            id="W"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#W)">
            <path
              stroke="#FF9500"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2.06}
              d="M442.2 119.2c.54 1.26.86 2.55.64 3.89-.69 4.34-6.88 6.46-7.57 10.8-.68 4.25 4.57 8.06 3.9 12.31a5.6 5.6 0 0 1-.95 2.28"
            />
          </g>
          <mask
            id="X"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#X)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.89}
              d="M452.07 103.81c1.56 2.8 4.3 5.37 4.14 8.46-.23 4.3-6.17 6.94-6.4 11.24-.12 2.34 1.5 4.37 2.95 6.42"
            />
          </g>
          <mask
            id="Y"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#Y)">
            <path
              fill="#2AAC7E"
              d="m468.24 128.27.3-4.11 2.8.2-.3 4.11-2.8-.2Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="Z"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#Z)">
            <path
              fill="#2AAC7E"
              d="m414.57 129.5 2.56-3.2 2.23 1.77-2.56 3.2-2.23-1.76Z"
            />
          </g>
          <mask
            id="aa"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#aa)">
            <path
              fill="#0D0D80"
              d="m433.44 121.51.85-1.9 2.53 1.1-.84 1.91-2.54-1.1Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="ab"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#ab)">
            <path
              fill="#FF9500"
              d="m458.07 128.37 1.3 1.53-2.27 1.89-1.28-1.54 2.25-1.88Z"
            />
          </g>
          <mask
            id="ac"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#ac)">
            <path
              fill="#0D0D80"
              d="M507.57 118.7c-.4-.68.07-1.7 1.05-2.26.97-.56 2.08-.46 2.48.22.4.68-.07 1.7-1.04 2.26-.97.56-2.09.47-2.49-.22Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="ad"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#ad)">
            <path
              fill="#FF9500"
              d="M435.45 142.62c-.83-.1-1.39-1.06-1.25-2.13.14-1.08.93-1.86 1.75-1.75.83.1 1.39 1.06 1.25 2.13-.14 1.07-.93 1.86-1.75 1.75Z"
            />
          </g>
          <mask
            id="ae"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#ae)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.92}
              d="M442.7 102.5c-1.32 3.3-5.13 6.1-5.04 9.7.11 4.3 5.98 7.1 6.09 11.4.05 2.06-1.23 3.84-2.56 5.61"
            />
          </g>
          <mask
            id="af"
            width={5796}
            height={5780}
            x={-2521}
            y={-2811}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2520.08-2810.16h5795.07v5778.32h-5795.07v-5778.32Z"
            />
          </mask>
          <g mask="url(#af)">
            <path
              stroke="#0D0D80"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2.01}
              d="M438.15 83.64c-.4.57-.7 1.19-.87 1.88-1.06 4.26 3.8 8.63 2.74 12.89-1.04 4.18-7.38 5.64-8.42 9.82a6.39 6.39 0 0 0 .27 3.58"
            />
          </g>
        </g>
        <mask
          id="ag"
          width={5682}
          height={5666}
          x={-2419}
          y={-2728}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill="#fff"
            d="M-2418.66-2727.45h5681.44v5665.02h-5681.44v-5665.02Z"
          />
        </mask>
        <g mask="url(#ag)">
          <mask
            id="ah"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ah)">
            <path
              fill="#0D0D80"
              d="m524.84 164.86-3.1 3.32-3.06-2.84 3.1-3.32 3.06 2.84Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="ai"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ai)">
            <path
              fill="#FF9500"
              d="m506.54 196.27.84-4.46 3.84.72-.85 4.46-3.83-.72Z"
            />
          </g>
          <mask
            id="aj"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aj)">
            <path
              fill="#FF9500"
              d="m471.53 180.16-1.51 1.7-3.1-2.77 1.51-1.69 3.1 2.76Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="ak"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ak)">
            <path
              fill="#2AAC7E"
              d="m497.48 156.01.3-1.9 4.5.74-.32 1.9-4.48-.74Z"
            />
          </g>
          <mask
            id="al"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#al)">
            <path
              fill="#FF3B30"
              d="M451.26 187.84c.95.47 1.28 1.76.72 2.88-.56 1.12-1.78 1.65-2.74 1.18-.96-.47-1.28-1.77-.73-2.89.56-1.12 1.8-1.65 2.75-1.17Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="am"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#am)">
            <path
              fill="#FF9500"
              d="M455.6 152.66c.94.64 1.13 2 .42 3.03-.7 1.03-2.05 1.35-2.99.71-.94-.64-1.13-2-.43-3.03.71-1.04 2.05-1.36 3-.72Z"
            />
          </g>
          <mask
            id="an"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#an)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.33}
              d="m489.3 169-.03-.28"
            />
          </g>
          <mask
            id="ao"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ao)">
            <path
              stroke="#FF9500"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.68}
              d="M531.66 175.96c1.12-1.97 2.37-3.92 2.3-6.05v-.16"
            />
          </g>
          <mask
            id="ap"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ap)">
            <path
              fill="#0D0D80"
              d="m479.2 142.57 2.65-3.12 3.46 2.93-2.66 3.12-3.46-2.93Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="aq"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aq)">
            <path
              fill="#2AAC7E"
              d="m471.33 125.23 1.73 3.91-4.16 1.83-1.73-3.91 4.16-1.83Z"
            />
          </g>
          <mask
            id="ar"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ar)">
            <path
              fill="#0D0D80"
              d="m489.2 153.61 1.35-1.52 3.42 2.99-1.35 1.53-3.41-3Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="as"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#as)">
            <path
              fill="#FF9500"
              d="m503.63 165.4 2-.73 1.54 4.27-2 .72-1.54-4.27Z"
            />
          </g>
          <mask
            id="at"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#at)">
            <path
              fill="#0D0D80"
              d="M479.24 205.68c-1.05-.68-1.46-1.91-.9-2.74.54-.83 1.83-.95 2.88-.27 1.05.69 1.46 1.92.91 2.75-.55.83-1.84.95-2.89.26Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="au"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#au)">
            <path
              fill="#FF9500"
              d="M489.25 171.14c-.76-1-.67-2.34.21-3.01.88-.67 2.2-.4 2.97.6.76 1 .66 2.34-.22 3-.88.67-2.2.4-2.96-.59Z"
            />
          </g>
          <mask
            id="av"
            width={5683}
            height={5666}
            x={-2459}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M-2458.43-2750.11h5681.44v5665.02h-5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#av)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.51}
              d="M533.52 234.8a20.1 20.1 0 0 0-1.47-3.16"
            />
          </g>
        </g>
        <mask
          id="aw"
          width={5683}
          height={5666}
          x={-2413}
          y={-2728}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill="#fff"
            d="M-2412.13-2727.45h5681.44v5665.02h-5681.44v-5665.02Z"
          />
        </mask>
        <g mask="url(#aw)">
          <mask
            id="ax"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ax)">
            <path
              fill="#0D0D80"
              d="m461.6 164.85 3.1 3.33 3.06-2.84-3.1-3.32-3.06 2.83Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="ay"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#ay)">
            <path
              fill="#FF9500"
              d="m479.9 196.27-.85-4.46-3.83.72.84 4.46 3.84-.72Z"
            />
          </g>
          <mask
            id="az"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#az)">
            <path
              fill="#FF9500"
              d="m514.9 180.16 1.52 1.69 3.1-2.76-1.51-1.69-3.1 2.76Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="aA"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aA)">
            <path
              fill="#2AAC7E"
              d="m488.96 156.01-.31-1.9-4.48.74.3 1.9 4.5-.74Z"
            />
          </g>
          <mask
            id="aB"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aB)">
            <path
              fill="#FF3B30"
              d="M535.18 187.84c-.95.47-1.28 1.76-.72 2.88.55 1.12 1.78 1.65 2.74 1.18.96-.47 1.28-1.77.72-2.89-.55-1.12-1.78-1.65-2.74-1.17Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="aC"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aC)">
            <path
              fill="#FF9500"
              d="M530.84 152.66c-.94.64-1.13 2-.42 3.03.7 1.03 2.04 1.35 2.99.71.94-.64 1.13-2 .42-3.03-.7-1.04-2.04-1.36-2.99-.72Z"
            />
          </g>
          <mask
            id="aD"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aD)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.33}
              d="m497.14 169 .03-.28"
            />
          </g>
          <mask
            id="aE"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aE)">
            <path
              stroke="#FF9500"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.68}
              d="M454.77 175.96c-1.12-1.97-2.37-3.93-2.3-6.05l.01-.16"
            />
          </g>
          <mask
            id="aF"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aF)">
            <path
              fill="#0D0D80"
              d="m507.25 142.57-2.67-3.12-3.46 2.93 2.67 3.12 3.46-2.93Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="aG"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aG)">
            <path
              fill="#2AAC7E"
              d="m515.11 125.23-1.73 3.91 4.16 1.83 1.73-3.91-4.16-1.83Z"
            />
          </g>
          <mask
            id="aH"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aH)">
            <path
              fill="#0D0D80"
              d="m497.23 153.61-1.34-1.52-3.42 2.99 1.35 1.53 3.41-3Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="aI"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aI)">
            <path
              fill="#FF9500"
              d="m482.81 165.39-2-.72-1.54 4.27 2 .72 1.54-4.27Z"
            />
          </g>
          <mask
            id="aJ"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aJ)">
            <path
              fill="#0D0D80"
              d="M507.2 205.68c1.05-.68 1.45-1.91.9-2.74-.54-.83-1.83-.95-2.88-.27-1.05.69-1.46 1.92-.91 2.75.54.83 1.84.95 2.89.26Z"
              opacity={0.5}
            />
          </g>
          <mask
            id="aK"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aK)">
            <path
              fill="#FF9500"
              d="M497.2 171.14c.75-1 .66-2.35-.22-3.01-.88-.67-2.21-.4-2.97.6-.76.99-.66 2.34.22 3 .87.67 2.2.4 2.96-.6Z"
            />
          </g>
          <mask
            id="aL"
            width={5682}
            height={5666}
            x={-2237}
            y={-2751}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M3444.87-2750.11h-5681.44v5665.02h5681.44v-5665.02Z"
            />
          </mask>
          <g mask="url(#aL)">
            <path
              stroke="#2AAC7E"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.51}
              d="M452.91 234.8c.39-1.08.9-2.15 1.47-3.16"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <pattern
        id="c"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aM" transform="scale(.0003 .00033)" />
      </pattern>
      <pattern
        id="d"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aN" transform="matrix(.00313 0 0 .00651 0 -1.06)" />
      </pattern>
      <pattern
        id="e"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aO" transform="matrix(.00208 0 0 .00433 0 -.2)" />
      </pattern>
      <pattern
        id="f"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aP" transform="matrix(.00313 0 0 .00649 0 -1.06)" />
      </pattern>
      <pattern
        id="g"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aQ" transform="scale(.00625)" />
      </pattern>
      <pattern
        id="h"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aR" transform="scale(.00625)" />
      </pattern>
      <pattern
        id="i"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aS" transform="scale(.00625)" />
      </pattern>
      <pattern
        id="j"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aT" transform="scale(.00625)" />
      </pattern>
      <pattern
        id="m"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aU" transform="scale(.0039)" />
      </pattern>
      <pattern
        id="q"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aV" transform="scale(.0039)" />
      </pattern>
      <pattern
        id="u"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#aW" transform="scale(.0039)" />
      </pattern>
      <image id="aM" width={3425} height={3077} href={BigMan} />
      <image id="aN" width={319} height={480} href={Alice} />
      <image id="aO" width={480} height={320} href={Jaoa} />
      <image id="aP" width={320} height={480} href={Kate} />
      <image id="aQ" width={160} height={160} href={EmojiStars} />
      <image id="aR" width={160} height={160} href={EmojiWave} />
      <image id="aS" width={160} height={160} href={EmojiHalo} />
      <image id="aT" width={160} height={160} href={EmojiThumb} />
      <image id="aU" width={256} height={256} href={Kahoot} />
      <image id="aV" width={256} height={256} href={Miro} />
      <image id="aW" width={256} height={256} href={Slido} />
      <linearGradient
        id="l"
        x1={309}
        x2={313.21}
        y1={295}
        y2={338.91}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.9} />
        <stop offset={1} stopColor="#fff" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="n"
        x1={309}
        x2={320.9}
        y1={295}
        y2={352.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.76} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="p"
        x1={462}
        x2={467.12}
        y1={6}
        y2={49.71}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.7} />
        <stop offset={1} stopColor="#fff" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="r"
        x1={462}
        x2={476.25}
        y1={6}
        y2={62.43}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.76} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="t"
        x1={561}
        x2={565.94}
        y1={173}
        y2={216.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.7} />
        <stop offset={1} stopColor="#fff" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="v"
        x1={561}
        x2={574.8}
        y1={173}
        y2={229.67}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.76} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <filter
        id="a"
        width={628}
        height={358}
        x={-5}
        y={-4}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={15} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_6_48713"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={12.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend
          in2="effect1_backgroundBlur_6_48713"
          result="effect2_dropShadow_6_48713"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_6_48713"
          result="shape"
        />
      </filter>
      <filter
        id="k"
        width={159}
        height={100}
        x={279}
        y={265}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={15} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_6_48713"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_backgroundBlur_6_48713"
          result="effect2_dropShadow_6_48713"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_6_48713"
          result="shape"
        />
      </filter>
      <filter
        id="o"
        width={141}
        height={100}
        x={432}
        y={-24}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={15} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_6_48713"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_backgroundBlur_6_48713"
          result="effect2_dropShadow_6_48713"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_6_48713"
          result="shape"
        />
      </filter>
      <filter
        id="s"
        width={144}
        height={100}
        x={531}
        y={143}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={15} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_6_48713"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_backgroundBlur_6_48713"
          result="effect2_dropShadow_6_48713"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_6_48713"
          result="shape"
        />
      </filter>
      <clipPath id="b">
        <rect width={391} height={237} x={33} y={74} fill="#fff" rx={8} />
      </clipPath>
      <clipPath id="w">
        <path fill="#fff" d="M407 79h173v230H407z" />
      </clipPath>
    </defs>
  </svg>
);

MeetupPreview.propTypes = {
  logo: PropTypes.string,
  eventName: PropTypes.string,
};
