import { processTags, translateIntoItems } from './field-processing';

describe('Field processing', () => {
  it('processes single tag', () => {
    expect(processTags('foo')).toEqual('foo');
  });

  it('processes list without space', () => {
    expect(processTags('foo,bar,baz')).toEqual('foo, bar, baz');
  });

  it('processes list of tags separated by new lines', () => {
    expect(processTags('foo\nbar\n baz ')).toEqual('foo, bar, baz');
  });

  it('supports \r line breaks', () => {
    expect(processTags('foo\nbar\r baz ')).toEqual('foo, bar, baz');
  });

  it('processes no tag', () => {
    expect(processTags('')).toEqual('');
    expect(processTags(null)).toEqual('');
    expect(processTags(undefined)).toEqual('');
  });

  it('dedupes tags', () => {
    expect(processTags('foo,foo,bar')).toEqual('foo, bar');
  });
});

describe('Translate into item', () => {
  it('Throws if missing exhibitor name', () => {
    try {
      translateIntoItems({
        processedFile: [{}],
        headerDictionary: {
          exhibitor_email: 'Exhibitor Email',
          booth_size: 'Booth Size',
          priority: 'Priority',
          tags: 'Tags',
        },
      });
    } catch (e) {
      expect(e.message).toMatch('"Exhibitor" is a required field');
    }
  });

  it('Throws if missing exhibitor email', () => {
    try {
      translateIntoItems({
        processedFile: [{}],
        headerDictionary: {
          exhibitor: 'Exhibitor',
          booth_size: 'Booth Size',
          priority: 'Priority',
          tags: 'Tags',
        },
      });
    } catch (e) {
      expect(e.message).toMatch('"Exhibitor Email" is a required field');
    }
  });

  it('Throws if missing name booth size', () => {
    try {
      translateIntoItems({
        processedFile: [{}],
        headerDictionary: {
          exhibitor: 'Exhibitor',
          exhibitor_email: 'Exhibitor Email',
          priority: 'Priority',
          tags: 'Tags',
        },
      });
    } catch (e) {
      expect(e.message).toMatch('"Booth Size" is a required field');
    }
  });
});
