import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
};

const template = createSlice({
  name: 'event-creation-wizard-template',
  initialState,
  reducers: {
    setTemplate: (state, action) => {
      state.id = action.payload;
      return state;
    },
  },
});

const {
  reducer,
  actions: { setTemplate },
} = template;

export { setTemplate };

export default reducer;
