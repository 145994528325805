import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import compose from 'lodash/fp/compose';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import withReduxProvider from '@/redux/with-provider';

import { componentExists } from './nav-config';
import withConnectedRouter from './with-connected-router';

export const BlankPageRedirector = () => {
  const location = useLocation().pathname;
  useEffect(() => {
    if (location && !componentExists(location)) {
      window.location.reload();
    }
  }, [location]);
  return (
    <Flex justifyContent="center" my={2}>
      <Spinner isShowing pattern="jumbo" />
    </Flex>
  );
};

export default compose(
  withReduxProvider,
  withConnectedRouter,
)(BlankPageRedirector);
