import React from 'react';

const DownloadTo = ({ fillColor = '#757A8A', size = 12 }) => {
  const width = size;
  const height = (width / 4) * 5;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00002 11.3346C5.88953 11.3346 5.78397 11.2889 5.70835 11.2083L0.883354 6.38333C0.804474 6.3051 0.760105 6.1986 0.760105 6.0875C0.760105 5.9764 0.804474 5.8699 0.883354 5.79167L1.05002 5.625C1.12842 5.54362 1.23703 5.49836 1.35002 5.5H3.50002V0.916667C3.50002 0.686548 3.68657 0.5 3.91669 0.5H8.08335C8.31347 0.5 8.50002 0.686548 8.50002 0.916667V5.5H10.65C10.763 5.49836 10.8716 5.54362 10.95 5.625L11.1167 5.79167C11.1956 5.8699 11.2399 5.9764 11.2399 6.0875C11.2399 6.1986 11.1956 6.3051 11.1167 6.38333L6.29169 11.2083C6.21608 11.2889 6.11051 11.3346 6.00002 11.3346ZM11.8334 14.25V13.4167C11.8334 13.1865 11.6468 13 11.4167 13H0.583354C0.353235 13 0.166687 13.1865 0.166687 13.4167V14.25C0.166687 14.4801 0.353235 14.6667 0.583354 14.6667H11.4167C11.6468 14.6667 11.8334 14.4801 11.8334 14.25Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default DownloadTo;
