import analyticsApi from '@api/analytics';
import Chart from '@components/icons/chart';
import Loading from '@components/loading';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import PersonListComponent from '@features/dashboard/components/person-list-component';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { string } from 'prop-types';
import React, { useState } from 'react';

const RecentRegistrations = ({ eventSlug }) => {
  const { t } = useLocalization('overview.recent-registrations');
  const [attendees, setAttendees] = useState([]);

  const loading = usePromise(
    signal => analyticsApi.getRecentlyRegisteredAttendees(eventSlug, signal),
    results => setAttendees(results),
  );

  return (
    <Box
      px={3}
      py={2.5}
      background="grey-white"
      border="grey-200"
      cornerRadius="lg"
    >
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <Loading isLoading={loading}>
          {attendees.length > 0 ? (
            <PersonListComponent people={attendees} />
          ) : (
            <EmptyState text={t('empty')} icon={<Chart />} />
          )}
        </Loading>
      </CardContentWrapper>
    </Box>
  );
};

RecentRegistrations.propTypes = {
  eventSlug: string.isRequired,
};

export default RecentRegistrations;
