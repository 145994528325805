import { number } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ProgressBarWrapper = styled.div`
  background-color: var(--color-green-200);
  height: 0.5rem;
  border-radius: var(--border-radius-small);
`;

const ProgressBarCompleted = styled.div`
  background-color: var(--color-green-400);
  border-radius: inherit;
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
  height: inherit;
  min-width: 1%;
  max-width: 100%;
  width: ${props => props.percentage || 0}%;
`;

const ProgressBarContainer = styled.div``;

const ProgressBar = ({ percentage }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarWrapper mt="8">
        <ProgressBarCompleted percentage={percentage} />
      </ProgressBarWrapper>
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  percentage: number.isRequired,
};

export default ProgressBar;
