import { timezonesMap } from '@features/constants/timezones-map';
import { yupResolver } from '@hookform/resolvers';
import { differenceInDays, differenceInMinutes, isAfter } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { date, object, string } from 'yup';

export const getExperienceStepFormValidations = ({
  defaultValues,
  maxEventLengthHours,
  t,
}) =>
  yupResolver(
    object().shape({
      name: string()
        .trim()
        .required(t('steps.event-experience.form.name.required')),
      startDate: date().test(
        'min',
        t('steps.event-experience.form.start-date.validation-chronology'),
        function (timeStart) {
          const tz = this.parent.timezone;

          return timeStart
            ? isAfter(
                zonedTimeToUtc(timeStart, timezonesMap[tz] || 'etc/UTC'),
                new Date(),
              )
            : true;
        },
      ),
      endDate: date()
        .test(
          'after-start-date',
          t('steps.event-experience.form.end-date.validation-chronology'),
          function () {
            const {
              startDate = defaultValues.startDate,
              endDate = defaultValues.endDate,
            } = this.parent;

            return isAfter(endDate, startDate);
          },
        )
        .test(
          'date-range',
          maxEventLengthHours > 24
            ? t('steps.event-experience.form.end-date.validation-length-days', {
                days: Math.abs(
                  differenceInDays(0, maxEventLengthHours * 60 * 60 * 1000),
                ),
              })
            : t(
                'steps.event-experience.form.end-date.validation-length-hours',
                {
                  hours: maxEventLengthHours,
                },
              ),
          function () {
            const {
              startDate = defaultValues.startDate,
              endDate = defaultValues.endDate,
            } = this.parent;

            return (
              maxEventLengthHours * 60 >=
              differenceInMinutes(endDate, startDate)
            );
          },
        ),
    }),
  );
