import { rem } from 'polished';
import styled, { css } from 'styled-components';

const TOP_BAR_HEIGHT = rem(68);

const Body = styled.div`
  max-width: 75rem;
  margin: 0 auto;
  padding: ${TOP_BAR_HEIGHT} var(--spacing-32) 0;
`;

const LoadingContainer = styled.div`
  height: 100vh;
`;

const TopBar = styled.div`
  position: fixed;
  width: 100%;
  height: ${TOP_BAR_HEIGHT};
  z-index: 10;
  display: flex;
  align-items: center;
  padding: var(--spacing-8) var(--spacing-24);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-300);
`;

const Logo = styled.img`
  height: ${rem(30)};
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-top: var(--spacing-8);
    `})}
`;

export { Body, LoadingContainer, TopBar, Logo };
