import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Image } from '@hopin-team/ui-image';
import styled from 'styled-components';

export const HeaderContainer = styled(Flex).attrs({
  p: 3,
  level: 'low',
  background: 'grey-white',
})`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const ContentContainer = styled(Box).attrs({
  p: 3,
  mb: 10,
})``;

export const FooterContainer = styled(Flex).attrs({
  p: 3,
  level: 'low',
  background: 'grey-white',
})`
  position: fixed;
  width: 100%;
  bottom: 0;
`;

export const Thumbnail = styled(Image)`
  border-radius: 0.25rem;
`;
