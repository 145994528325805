import RandomImg from '@components/people/assets/match-random.svg';
import TicketImg from '@components/people/assets/match-ticket.svg';
import React from 'react';

const matchmakingTypes = [
  {
    id: 0,
    type: 'everyone',
    title: 'networking.matchmaking.random.title',
    details: 'networking.matchmaking.random.details',
    footnote: 'networking.matchmaking.random.footnote',
    isDefault: true,
    hasSettings: false,
    image: <RandomImg />,
  },
  {
    id: 1,
    type: 'ticket_holder',
    title: 'networking.matchmaking.ticket.title',
    details: 'networking.matchmaking.ticket.details',
    footnote: 'networking.matchmaking.ticket.footnote',
    isDefault: false,
    hasSettings: false,
    image: <TicketImg />,
  },
];

export default matchmakingTypes;
