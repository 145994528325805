import { Icon } from '@hopin-team/ui-icon';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './event-details-styles';

const PublishButton = ({
  disabled,
  hide,
  icon,
  isInverse,
  onClick,
  text,
  tipText,
  canPublishEvent,
}) => {
  const inverse = isInverse || (disabled && tipText);
  return hide ? null : (
    <Styles.PublishButton
      size="medium"
      disabled={disabled && tipText}
      isOutlined={inverse}
      onClick={onClick}
      data-testid="new-nav-publish-button"
      $canPublishEvent={canPublishEvent}
    >
      <Icon
        name={icon}
        color={inverse ? 'blue-500' : 'grey-white'}
        scale={2.5}
        mt={0.5}
      />
      {text}
    </Styles.PublishButton>
  );
};

PublishButton.propTypes = {
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  icon: PropTypes.string,
  isInverse: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  tipText: PropTypes.string,
  canPublishEvent: PropTypes.bool,
};

export default PublishButton;
