import { getDaysBetween } from '@features/recordings/utils/utils';
import getDisplayName from '@util/get-display-name';
import { node } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setEvent } from './event-slice';

const EventProvider = ({ children }) => {
  const { event } = children.props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (event) {
      const eventValue = {
        // Passed in values
        slug: event.slug,
        status: event.status,
        days: getDaysBetween(
          new Date(event.timeStart),
          new Date(event.timeEnd),
        ),
        timeStart: event.timeStart,
        timeEnd: event.timeEnd,
        areas: event.areas,
        eventId: event.eventId,

        // TODO: move to the selector
        started: new Date(event.timeStart) < new Date(),
        finished: new Date(event.timeEnd) < new Date(),
      };

      dispatch(setEvent(eventValue));
    }
  }, [event, dispatch]);

  return children;
};

EventProvider.propTypes = {
  children: node,
};

export const withEventProvider = WrappedComponent => {
  const WithRootPropsProvider = props => {
    return (
      <EventProvider>
        <WrappedComponent {...props} />
      </EventProvider>
    );
  };

  WithRootPropsProvider.displayName = `WithRootPropsProvider(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithRootPropsProvider;
};

export default EventProvider;
