import eventTemplatesApi from '@api/event-templates';
import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { EVENTS } from '@util/analytics/event-templates';
import trackSegmentEvent from '@util/analytics/segment-client';
import { getBrowserTimezone } from '@util/date-helpers';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import {
  addMonths,
  addSeconds,
  differenceInSeconds,
  isAfter,
  set,
} from 'date-fns';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { date, object, string } from 'yup';

const ONE_HOUR_IN_SECS = 60 * 60;
const TIME_REGEX = /^[0-2][0-9]:[0-5][0-9]$/;

const logger = getLogger(LOGGER_NAMES.EVENT_TEMPLATES);

const combineDateAndTime = (date, time) => {
  if (!(date instanceof Date) || !TIME_REGEX.test(time)) {
    console.error('Error combining date and time. Invalid arguments:', {
      date,
      time,
    });
    return null;
  }
  const [hours, minutes] = time.split(':').map(str => parseInt(str, 10));
  const updatedDate = set(date, {
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  });
  return updatedDate;
};

const createSchema = ({
  t,
  tRoot,
  maxEventDurationSecs,
  minPasswordLength,
}) => {
  const maxDurationHrs =
    Math.round((maxEventDurationSecs / ONE_HOUR_IN_SECS) * 4) / 4;
  return object().shape({
    endDate: date()
      .label(t('endDate.label'))
      .required(t('endDate.required'))
      .test('in-future', t('endDate.mustBeInFuture'), (_value, context) => {
        const { endDate, endTime } = context.parent;
        const eventEndDate = combineDateAndTime(endDate, endTime);
        return isAfter(eventEndDate, new Date());
      })
      .test(
        'after-start-date',
        t('endDate.mustBeAfterStart'),
        (_value, context) => {
          const { endDate, endTime, startDate, startTime } = context.parent;
          const eventStartDate = combineDateAndTime(startDate, startTime);
          const eventEndDate = combineDateAndTime(endDate, endTime);
          return isAfter(eventEndDate, eventStartDate);
        },
      )
      .test(
        'duration-limit',
        t('endDate.cannotBeLonger', { maxDurationHrs }),
        (_value, context) => {
          const { endDate, endTime, startDate, startTime } = context.parent;
          const eventStartDate = combineDateAndTime(startDate, startTime);
          const eventEndDate = combineDateAndTime(endDate, endTime);
          return (
            maxEventDurationSecs >=
            differenceInSeconds(eventEndDate, eventStartDate)
          );
        },
      ),
    endTime: string().label(t('endTime.label')).required(t('endTime.required')),
    eventName: string()
      .label(t('eventName.label'))
      .required(tRoot('required'))
      .matches(/\S/, tRoot('required')) // any non-whitespace character
      .min(3),
    eventType: string()
      .label(t('eventType.label'))
      .required(tRoot('required'))
      .oneOf(['public_event', 'private_event', 'hidden_event']),
    eventPassword: string()
      .label(t('password.label'))
      .when('eventType', (eventType, schema) =>
        eventType === 'private_event'
          ? schema
              .matches(/\S/, tRoot('required')) // any non-whitespace character
              .min(
                minPasswordLength,
                t('eventPassword.min', { min: minPasswordLength }),
              )
          : schema,
      ),
    startDate: date()
      .label(t('startDate.label'))
      .required(t('startDate.required'))
      .test('in-future', t('startDate.mustBeInFuture'), (_value, context) => {
        const { startDate, startTime } = context.parent;
        const eventStartDate = combineDateAndTime(startDate, startTime);
        return isAfter(eventStartDate, new Date());
      }),
    startTime: string()
      .label(t('startTime.label'))
      .required(t('startTime.required')),
  });
};

const getDefaultStartDate = () =>
  set(addMonths(new Date(), 1), {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

const getDefaultEndDate = (startDate, maxEventDurationSecs) =>
  addSeconds(startDate, maxEventDurationSecs);

const getTimeFromDate = date => {
  return [date.getHours(), date.getMinutes()]
    .map(time => `${time}`.padStart(2, '0'))
    .join(':');
};

export const useTemplateModalForm = ({
  isOnFreePlan,
  maxEventDurationSecs,
  onError,
  onSuccess,
  templateId,
  organizationExternalId,
  minPasswordLength,
}) => {
  const { t } = useLocalization('templates.form');
  const { t: tRoot } = useLocalization();
  const schema = useMemo(
    () => createSchema({ t, tRoot, maxEventDurationSecs, minPasswordLength }),
    [t, tRoot, maxEventDurationSecs, minPasswordLength],
  );

  const defaultStartDate = useMemo(() => getDefaultStartDate(), []);
  const defaultStartTime = useMemo(() => getTimeFromDate(defaultStartDate), [
    defaultStartDate,
  ]);
  const defaultEndDate = useMemo(
    () => getDefaultEndDate(defaultStartDate, maxEventDurationSecs),
    [defaultStartDate, maxEventDurationSecs],
  );
  const defaultEndTime = useMemo(() => getTimeFromDate(defaultEndDate), [
    defaultEndDate,
  ]);

  const defaultValues = useMemo(
    () => ({
      endDate: defaultEndDate,
      endTime: defaultEndTime,
      eventName: null,
      eventPassword: null,
      eventType: isOnFreePlan ? 'hidden_event' : 'public_event',
      startDate: defaultStartDate,
      startTime: defaultStartTime,
    }),
    [
      defaultEndDate,
      defaultEndTime,
      defaultStartDate,
      defaultStartTime,
      isOnFreePlan,
    ],
  );

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleHookFormSubmit = async values => {
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      eventName,
      eventType,
      eventPassword,
    } = values;
    try {
      const eventStart = combineDateAndTime(startDate, startTime);
      const eventEnd = combineDateAndTime(endDate, endTime);

      const attributes = {
        organization_external_id: organizationExternalId,
        name: eventName,
        event_type: eventType,
        event_password: eventPassword,
        time_start: eventStart.toISOString(),
        time_end: eventEnd.toISOString(),
        timezone: getBrowserTimezone(),
      };

      const args = [attributes];
      if (templateId) {
        args.unshift(templateId);
      }

      const endpoint = templateId
        ? eventTemplatesApi.createEventFromTemplate
        : eventTemplatesApi.createBlankEvent;

      const { data, errors } = await endpoint(...args);
      if (errors) {
        logger.error(errors);
        onError(errors);
      } else {
        if (templateId)
          trackSegmentEvent(EVENTS.event_created_with_template, {
            template_id: templateId,
          });
        onSuccess(data);
      }
    } catch (err) {
      logger.error(err);
      onError('Something went wrong');
    }
  };

  return {
    onSubmit: handleSubmit(handleHookFormSubmit),
    ...methods,
  };
};
