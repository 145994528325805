import { buildRecordingTitle } from '@hopin-team/ui-recording-card';

import {
  enhanceRecording,
  filterObject,
  filterRecordings,
  sortRecordings,
} from '../../../utils/utils';
import { defaultRecordingState } from './recordings-slice';

/**
 * @returns recording object or empty object if no ID matched. This is to handle loading state where the recording may subsequently get added to state
 */
export const getRecordingById = (state, id, language) => {
  const originalRecording = state?.recordings[id];
  if (!originalRecording) return {};

  let recording = enhanceRecording(originalRecording);

  if (recording?.isDone && !recording?.isError) {
    if (language) {
      const { title, createdAt, startedAt } = recording;
      recording.title = buildRecordingTitle({
        title,
        createdAt,
        startedAt,
        locale: language,
      });
    }
    return recording;
  }
};

/**
 * Get a clean version of the object sending to analytics
 * Without state or derived values
 */
export const getOriginalRecordingById = (state, id) => {
  const recording = state?.recordings[id];
  if (!recording) return undefined;

  const stateKeys = Object.keys(defaultRecordingState);
  const recordingWithState = { ...state?.recordings[id] };
  return filterObject({
    original: recordingWithState,
    disallowedKeys: stateKeys,
  });
};

export const selectRecordingsByVideoArea = (state, videoArea, filters) => {
  let results = [];
  videoArea.recordingIds?.forEach(id => {
    if (state.recordings[id]) results.push(state.recordings[id]);
  });

  if (filters && results.length) {
    results = sortRecordings(filterRecordings(results, filters));
  }

  return results;
};

export const selectRecordingIsLoaded = (state, id, language) => {
  const recording = getRecordingById(state, id, language);

  return !!(recording && !recording.isLoading && recording.title);
};

export const selectRecordingExists = (state, id) => {
  return !!state?.recordings[id];
};
