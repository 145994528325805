export const fetchWorkspaces = eventSlug =>
  fetch(`/organisers/events/${eventSlug}/streamyard/workspaces`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }

    return response;
  });
