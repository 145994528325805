import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
// hide support launcher
  iframe#launcher {
    display: none;
  }

  iframe#webWidget {
    display: none;
  }
`;

export default GlobalStyle;
