export const getOnsiteEventWizardTemplates = ({ reorderTabs }) => {
  // Tabs
  let setup = {
    basics: {},
    theme: {},
    'venue-controls': {
      __title: 'access-and-settings',
    },
  };

  let registration = {
    'registrations-tickets': {},
    'ticket-groups': {},
    'registration-form': {},
    'registration-event-landing-page': {},
    details: {},
  };

  const content = {
    reception: {},
    schedule: {},
    stage: {},
    stages: {},
    sessions: {},
    'venue-controls-people': {},
    surveys: {},
    expo: {},
    sponsors: {},
    tags: {},
  };

  const features = {
    'floor-plan': {},
    'check-in-areas': {},
    'kiosk-mode': {},
    badges: {},
    signatures: {},
  };

  let people = {
    registrants: {},
    speakers: {},
    'magic-link-invites': {},
  };

  const marketing = {
    emails: {},
    'email-attendees': {},
    'customize-emails': {},
    'custom-domain': {},
    'custom-domains': {},
    'sharing-and-tracking': {},
    'utm-codes': {},
  };

  const analytics = {
    reports: {},
    'view-polls': {},
    'expo-summary': {},
  };

  // Reorder
  if (reorderTabs) {
    registration = {
      'registration-event-landing-page': {},
      'registration-form': {},
      'registrations-tickets': {},
      'ticket-groups': {},
      registrants: {},
      'magic-link-invites': {},
    };

    people = {};

    setup = {
      ...setup,
      speakers: {},
    };
  }

  // Templates
  const onsite_pre_event = {
    overview: {},
    setup,
    registration,
    content,
    'onsite-features': features,
    people,
    marketing,
    more: {
      'customize-text': {},
      'host-information': {},
      analytics,
    },
  };

  const onsite_during_event = {
    overview: {},
    setup,
    registration,
    content,
    'onsite-features': features,
    people,
    marketing,
    analytics,
    more: {
      'customize-text': {},
      'host-information': {},
    },
  };

  const onsite_after_event = {
    overview: {},
    people,
    analytics,
    more: {
      'customize-text': {},
      'host-information': {},
      setup,
      registration,
      content,
      'onsite-features': features,
      marketing,
    },
  };

  return {
    before: onsite_pre_event,
    during: onsite_during_event,
    after: onsite_after_event,
  };
};
