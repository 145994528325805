import { withThemeProvider } from '@features/branding/withThemeProvider';
import { getAppStoreWebUrl } from '@features/integration/helpers/get-app-store-web-url';
import { getEventAreaParameter } from '@features/integration/helpers/get-event-area-parameter';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { compose } from 'lodash/fp';
import { string } from 'prop-types';
import React from 'react';

const AddAnApp = ({ organizationId, venue }) => {
  const { t } = useLocalization('integrations');

  const appStoreUrl = getAppStoreWebUrl({
    organizationId,
    eventAreaParameter: getEventAreaParameter({ venue }),
  });

  return (
    <Button
      href={appStoreUrl}
      target="_blank"
      leadingIcon="grid-view"
      isOutlined
      isInline
    >
      {t('buttonText')}
    </Button>
  );
};

AddAnApp.propTypes = {
  canonicalUrl: string,
  fallbackCanonicalUrl: string,
  environment: string,
  fallbackEnvironment: string,
  // Organization ID could be required, but there's the case where vendors configure venues
  // and this type of user does not belong to any organization, and hence have no ID
  organizationId: string,
  venue: string,
};

export default compose(withThemeProvider, withLocalizationProvider)(AddAnApp);
