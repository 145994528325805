import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import {
  getDefaultEndDate,
  getDefaultStartDate,
} from '@features/event-creation-wizard/utils/dates';
import { createSlice } from '@reduxjs/toolkit';

import { setTemplate } from './template-slice';

const FREE_PLAN_MAX_EVENT_HOURS = 2;

const defaultEventLength = value => {
  if (value > 72) return 72;
  if (value <= 8) return 1;
  return value;
};

export const getInitialBasicDetailsState = (
  defaultMaxEventLengthHours,
  defaultEventImageUrl,
  defaultMaxWebinarLengthHours,
) => {
  const maxEventLengthHours = Number.isFinite(defaultMaxWebinarLengthHours)
    ? defaultMaxWebinarLengthHours
    : FREE_PLAN_MAX_EVENT_HOURS;

  return {
    name: '',
    timezone: '',
    imageUrl: '',
    maxEventLengthHours,
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(
      defaultEventLength(maxEventLengthHours) * 60 ** 2,
    ),
    eventPassword: '',
    defaultEventImageUrl,
    defaultMaxEventLengthHours,
    defaultMaxWebinarLengthHours,
  };
};

const basicDetails = createSlice({
  name: 'event-creation-wizard-basic-details',
  initialState: getInitialBasicDetailsState(),
  reducers: {
    setBasicDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setMaxEventLengthHours: (state, action) => {
      const { maxEventLengthHours } = action.payload;

      return {
        ...state,
        maxEventLengthHours: maxEventLengthHours,
      };
    },
    resetEndDate: state => {
      return {
        ...state,
        endDate: getDefaultEndDate(
          defaultEventLength(state.maxEventLengthHours) * 60 ** 2,
        ),
      };
    },
  },
  extraReducers: builder => {
    /**
     * None webinar events, have a maximum duration (in hours) which can
     * be customized via Retool. We need to reset maxEventLengthHours based
     * on the selected template in order to correctly validate event's length.
     */
    builder.addCase(setTemplate, (state, action) => {
      const maxLengthHours =
        action.payload === EXPERIENCE_TEMPLATE.WEBINAR
          ? state.defaultMaxWebinarLengthHours
          : state.defaultMaxEventLengthHours;

      return {
        ...state,
        maxEventLengthHours: Number.isFinite(maxLengthHours)
          ? maxLengthHours
          : FREE_PLAN_MAX_EVENT_HOURS,
      };
    });
  },
});

const {
  reducer,
  actions: {
    setBasicDetails,
    setMaxEventLengthHours,
    resetEndDate,
    setEndDate,
  },
} = basicDetails;

export { setBasicDetails, setMaxEventLengthHours, resetEndDate, setEndDate };

export default reducer;
