import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import eventCreationWizardReducer from './event-creation-wizard-reducer';

const setupEventCreationWizardStore = (apiClient, initialState) => {
  const store = configureStore({
    reducer: eventCreationWizardReducer,
    preloadedState: initialState,
    middleware: [
      ...getDefaultMiddleware({
        thunk: {
          extraArgument: {
            hopinApi: apiClient,
          },
        },
        serializableCheck: false,
        immutableCheck: false,
      }),
    ],
  });

  return { store };
};

export default setupEventCreationWizardStore;
