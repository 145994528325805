/* global Rails */
import hopinApi from '@api/hopin';
import request from '@util/request';

export const unblockAttendee = async (eventId, attendeeId) => {
  return hopinApi.unblockAttendeeFromEvent(attendeeId, eventId);
};

export const deleteAttendee = async (eventSlug, attendeeId) => {
  const response = await fetch(
    `/organisers/events/${eventSlug}/new_attendees/${attendeeId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': Rails.csrfToken(),
      },
    },
  );
  if (response.status === 204) return '';

  return await response.json();
};

export const getAttendeeDetail = (eventSlug, attendeeId) =>
  request.get(`/organisers/events/${eventSlug}/new_attendees/${attendeeId}`);

export const refundTicket = async (eventSlug, attendeeId) => {
  const response = await fetch(
    `/organisers/events/${eventSlug}/new_attendees/${attendeeId}/refund`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': Rails.csrfToken(),
      },
    },
  );
  if (response.status === 204) return '';

  return await response.json();
};

export const resendEmail = async (eventSlug, attendeeId) => {
  const response = await fetch(
    `/organisers/events/${eventSlug}/new_attendees/${attendeeId}/resend_registration_email`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': Rails.csrfToken(),
      },
    },
  );

  return await response.json();
};

export const exportAttendeesCsv = async url => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
  });
  if (response.status === 204) return '';

  return await response.json();
};

export const getCustomForms = async (token, eventSlug) =>
  fetch(`/api/v1/registrations_component/custom_forms/${eventSlug}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
