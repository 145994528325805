import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React from 'react';

import { TabPanel, TabsContainer, Wrapper } from './styles';

const DashboardTabsComponent = ({ onTabClick, tabs }) => {
  const { t } = useLocalization('organizations-dashboard.navigation-tabs');

  const setTabActiveStatus = tab => {
    if (['integrations', 'apps'].includes(tab.id)) {
      const isActive =
        tab.isActive || window.location.pathname.includes(tab.href);
      return { ...tab, isActive };
    } else {
      return tab;
    }
  };

  return (
    <Wrapper role="navigation" aria-label="Primary" data-testid="tabs-wrapper">
      <TabsContainer big>
        {tabs
          .filter(tab => tab.isVisible)
          .map(setTabActiveStatus)
          .map(tab => {
            if (['integrations', 'apps'].includes(tab.id)) {
              if (window.location.pathname.includes(tab.href)) {
                tab.isActive = true;
              }
            }

            return (
              <TabPanel
                isActive={tab.isActive}
                key={tab.href}
                href={tab.href}
                onClick={() => onTabClick(tab.id)}
                data-testid="tab-panel"
              >
                {t(tab.id)}
              </TabPanel>
            );
          })}
      </TabsContainer>
    </Wrapper>
  );
};

DashboardTabsComponent.propTypes = {
  onTabClick: func.isRequired,
  tabs: arrayOf(
    shape({
      id: string.isRequired,
      href: string.isRequired,
      isActive: bool.isRequired,
      isVisible: bool,
    }),
  ),
};

export default withLocalizationProvider(DashboardTabsComponent);
