import { func, node, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const CopyToClipboard = ({ children, onCopy = () => {}, text, ...props }) => {
  const textareaRef = useRef();
  const [copying, setCopying] = useState(false);

  const textarea = (
    <textarea
      ref={textareaRef}
      readOnly
      value={text}
      style={{ position: 'absolute', left: '-9999px' }}
    ></textarea>
  );

  useEffect(() => {
    if (copying) {
      textareaRef.current.select();
      document.execCommand('copy');
      onCopy();
      setCopying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copying]);

  const handleClick = () => {
    setCopying(true);
  };

  return (
    <button onClick={handleClick} type="button" {...props}>
      {copying && textarea}
      {children}
    </button>
  );
};

CopyToClipboard.propTypes = {
  children: node,
  onCopy: func,
  text: string,
};

export default CopyToClipboard;
