export function isStepApplicable(step, statuses) {
  return statuses[step] !== 'not_applicable';
}

export function isStepComplete(step, statuses) {
  return statuses[step] === 'completed';
}

export function isStepSkipped(step, statuses) {
  return statuses[step] === 'skipped';
}
