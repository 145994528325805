import { useEffect, useState } from 'react';

import { getThumbnails } from '../../api';

export const useThumbnails = (recordingId, eventSlug) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchThumbnails = async (recordingId, eventSlug) => {
    setIsLoading(true);

    try {
      setThumbnails(await getThumbnails(recordingId, eventSlug));
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchThumbnails(recordingId, eventSlug);
  }, [recordingId, eventSlug]);

  return {
    thumbnails,
    isLoading,
    isError,
  };
};
