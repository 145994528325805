const getMarketingSettingsResponse = {
  data: {
    id: 'abc-123',
    type: 'marketing_settings',
    attributes: {
      slug: 'abc-123',
      short_description: 'lorem ipsum',
      analytics_code: 'XYZ',
      event_url: 'https://example.com/events/abc-123',
    },
  },
};

export default getMarketingSettingsResponse;
