import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import { bool, func } from 'prop-types';
import React, { useContext } from 'react';

import { EmptyStateContainer, SendEmailsIcon } from './styles';

const EmptyState = ({ createAnnouncement, disabled }) => {
  const translationKeyPrefix = 'email-dashboard.empty-state';
  const { t } = useContext(LocalizationContext);

  return (
    <EmptyStateContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={12}
      mb={2}
      data-testid="empty-state"
    >
      <SendEmailsIcon src="/images/send-emails.png" />
      <Text mt={4}>{t(`${translationKeyPrefix}.title`)}</Text>
      <Text>{t(`${translationKeyPrefix}.subtitle`)}</Text>
      <Button
        onClick={createAnnouncement}
        disabled={disabled}
        isInline
        mt={3}
        size="medium"
      >
        {t(`${translationKeyPrefix}.link`)}
      </Button>
    </EmptyStateContainer>
  );
};

EmptyState.propTypes = {
  createAnnouncement: func,
  disabled: bool,
};

export default EmptyState;
