import { graphColorsArray1 } from '@features/dashboard/util/colors';
import { scaleLinear } from '@visx/scale';

const getNodeColor = (minValue, maxValue, nodeValue) => {
  const colorScaleValue = scaleLinear({
    domain: [minValue, maxValue],
    range: [0, 4],
    clamp: true,
  });

  const roundedValue = Math.ceil(colorScaleValue(nodeValue));

  return { color: graphColorsArray1[roundedValue], value: roundedValue };
};

export default getNodeColor;
