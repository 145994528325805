import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Flex } from '@hopin-team/ui-flex';
import { DeprecatedSelect } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

export const MAX_OPTIONS = 20;

const iconAccessibilityProps = t => ({
  clear: {
    title: t('networking.profile-setup.clear-all-created-options'),
  },
  dropdown: {
    title: t('networking.arrow-down'),
  },
  multiValueRemove: {
    title: t('networking.profile-setup.remove-option'),
  },
});

const CategoryOptionsField = ({
  options,
  setOptions,
  hasError,
  errorMessage,
  lockProfileMatching,
  t,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [hasDuplicatedValue, setHasDuplicatedValue] = useState(false);

  const handleKeyDown = event => {
    if (!inputValue || options.length >= MAX_OPTIONS) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();

        if (
          options.some(
            option => option.value.toUpperCase() === inputValue.toUpperCase(),
          )
        ) {
          setHasDuplicatedValue(true);
          return;
        }

        setOptions([
          ...options,
          {
            label: inputValue,
            value: inputValue,
          },
        ]);

        setInputValue('');
    }
  };

  const optionsLeft = MAX_OPTIONS - options.length;

  return (
    <>
      <FieldLabel
        fieldId={t('networking.profile-setup.category-options-label')}
        label={t('networking.profile-setup.category-options-label')}
        mb={1}
        mt={2}
      />

      <StyledSelect
        inputId={t('networking.profile-setup.category-options-label')}
        isDisabled={lockProfileMatching}
        inputValue={inputValue}
        onChange={value => {
          setHasDuplicatedValue(false);
          setOptions(value);
        }}
        onInputChange={value => {
          setHasDuplicatedValue(false);
          setInputValue(value);
        }}
        onKeyDown={handleKeyDown}
        value={options}
        isMulti
        isCreatable
        menuIsOpen={false}
        iconAccessibilityProps={iconAccessibilityProps(t)}
        components={{ DropdownIndicator: null }}
        placeholder={t('networking.profile-setup.category-options-placeholder')}
        hasError={hasError || hasDuplicatedValue}
      />

      {(hasError || hasDuplicatedValue) && (
        <FieldErrorMessage
          id={t('networking.profile-setup.category-options-label')}
          errorMessage={
            hasDuplicatedValue
              ? t('networking.profile-setup.duplicate-option')
              : errorMessage
          }
          mt={1}
          mb={2}
        />
      )}

      <Flex justifyContent="space-between" mt={1}>
        <Text pattern="labelFour" color="grey-600">{`${optionsLeft} ${
          optionsLeft === 1
            ? t('networking.profile-setup.option-left')
            : t('networking.profile-setup.options-left')
        }`}</Text>

        <Text pattern="labelFour" color="grey-600">
          {t('networking.profile-setup.category-options-helper-text')}
        </Text>
      </Flex>
    </>
  );
};

export default CategoryOptionsField;

const StyledSelect = styled(DeprecatedSelect)`
  > div {
    border-color: ${({ hasError }) => hasError && 'var(--color-red-400)'};
    box-shadow: ${({ hasError }) =>
      hasError && '0 0 0.125rem 0.125rem var(--color-red-200)'};
  }
`;

CategoryOptionsField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  setOptions: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  lockProfileMatching: PropTypes.bool,
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};
