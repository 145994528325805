import { EVENT_STATUS } from '@features/events/event-info-utils';
import { LocalizationContext } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { bool, number, string } from 'prop-types';
import React, { useContext } from 'react';
/**
 * @typedef {Object} EventAttendeesProps
 * @property {bool} attendeesVisiblityShowAll
 * @property {number} registrationsCount
 */

/**
 * Displays the number of resgistrants if `attendeesVisiblityShowAll` is enabled.
 * @param {EventAttendeesProps} props
 */
export const EventRegistrants = ({
  attendeesVisiblityShowAll,
  registrationsCount,
  eventStatus,
}) => {
  const { t } = useContext(LocalizationContext);
  const isEventLive = eventStatus === EVENT_STATUS.LIVE;
  const isRegistrantCountLow = registrationsCount <= 1;
  const eventLivePrefix = t('registration-v1.event-live');

  if (isEventLive && (!attendeesVisiblityShowAll || isRegistrantCountLow))
    return (
      <Text element="p" pattern="body">
        {eventLivePrefix}
      </Text>
    );

  if (!attendeesVisiblityShowAll) return null;

  if (isRegistrantCountLow) return null;

  const registrantsLabel =
    eventStatus === EVENT_STATUS.ENDED
      ? t('registration-v1.post-registration-count', {
          count: registrationsCount,
        })
      : t('registration-v1.registration-count', { count: registrationsCount });

  return (
    <Text element="p" pattern="body">
      {isEventLive && `${eventLivePrefix} `}
      {registrantsLabel}
    </Text>
  );
};

EventRegistrants.propTypes = {
  attendeesVisiblityShowAll: bool,
  registrationsCount: number.isRequired,
  eventStatus: string.isRequired,
};
