import { useCallback, useState } from 'react';

export const useRecordingsFilters = () => {
  const [filters, setFilters] = useState({});

  const onFilterChange = useCallback(
    filterChange => {
      setFilters({ ...filters, ...filterChange });
    },
    [filters, setFilters],
  );

  return { filters, onFilterChange };
};
