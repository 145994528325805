import { connectViewData, withViewDataProvider } from '@util/page-view-context';
import compose from 'lodash/fp/compose';
import React from 'react';

import RegistrationsSummaryComponent from './registrations-summary-component';

const RegistrationsSummaryContainer = props => {
  return <RegistrationsSummaryComponent {...props} />;
};

const mapViewDataToProps = data => data;

export default compose(
  withViewDataProvider,
  connectViewData(mapViewDataToProps),
)(RegistrationsSummaryContainer);
