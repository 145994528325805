import { Flex } from '@hopin-team/ui-flex';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

import { TemplateCard } from '../../styled/template-card';

export const TemplateModalFormRoot = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  py: 7,
  px: 3,
})`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  ${TemplateCard} {
    max-width: ${calcSpace(25)};
  }
`;
