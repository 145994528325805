import { COLOR } from '@hopin-team/ui-symbols';
import { TextField } from '@hopin-team/ui-text-field';
import { Input } from '@hopin-team/ui-text-field/dist/styled/input';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% - 3rem);
  max-width: 47.5rem;

  input {
    padding-left: 1rem;
  }
`;

export const TrailingButtonInput = styled.div`
  display: flex;

  label {
    height: var(--input-height-tiny);
  }

  button {
    height: var(--input-height-tiny);
    width: 3rem;
    background-color: ${COLOR.GREY['200']};
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border: 1px solid ${COLOR.GREY['300']};
    border-left: 0;
  }

  button:focus:not([data-focus-visible-added]) {
    outline: 0;
  }

  ${Input} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
`;

export const CustomURLTextField = styled(TextField)`
  div:last-child {
    input {
      padding-left: 0;
    }

    label {
      height: var(--input-height-tiny);
    }

    button {
      height: var(--input-height-tiny);
    }
  }
`;
