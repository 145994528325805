import getTimeLabelSubText from '@components/bar-chart/util/get-time-label-subtext';
import { LocalizationContext } from '@features/localization';
import { parseISO } from 'date-fns';
import { useContext } from 'react';

const axisLabelFormats = {
  minute: 'h:mm a',
  hour: 'h:mm a',
  day: 'dd',
};

const tooltipFormats = {
  minute: 'MMM dd, h:mm a',
  hour: 'MMM dd, h:mm a',
  day: 'MMM dd',
};

const useDateFormatters = () => {
  const { formatDate } = useContext(LocalizationContext);

  const formatForAxisLabel = (dateString, allTicks, unit) => {
    const tickIndex = allTicks.indexOf(dateString);
    const date = parseISO(allTicks[tickIndex]);
    const timeFormat = axisLabelFormats[unit];

    return {
      text: formatDate(date, timeFormat).replace(':00', ''),
      subText: getTimeLabelSubText(date, tickIndex, allTicks, unit, formatDate),
    };
  };

  const formatForTooltip = (dateString, unit) => {
    const date = parseISO(dateString);
    const timeFormat = tooltipFormats[unit];

    return formatDate(date, timeFormat);
  };

  return {
    formatForAxisLabel,
    formatForTooltip,
  };
};

export default useDateFormatters;
