import { createSlice } from '@reduxjs/toolkit';

export const authInitialState = { token: '' };

const onboardingAuthSlice = createSlice({
  name: 'event-creation-wizard-auth',
  initialState: authInitialState,
});

const { reducer } = onboardingAuthSlice;

export default reducer;
