import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Card } from '@hopin-team/ui-card';
import { Flex } from '@hopin-team/ui-flex';
import { Illustration } from '@hopin-team/ui-illustration';
import { Linkicon } from '@hopin-team/ui-linkicon';
import { Search } from '@hopin-team/ui-search';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import { camelCase, startCase } from 'lodash';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import withReduxProvider from '@/redux/with-provider';

import { getDefaultJson } from './custom-text-default';

export const formatKey = key => startCase(camelCase(key.split('-').join(' ')));

export function CustomTextForm({ customTextJSON = '{}', extraSponsorTiers }) {
  const { t } = useLocalization('custom-text');
  const isLiteEvent = useSelector(state => state.event.isLite);
  const parsedJSON = useMemo(() => {
    let json;
    try {
      json = JSON.parse(
        customTextJSON?.trim().length > 0 ? customTextJSON.trim() : '{}',
      );
    } catch (e) {
      const logger = getLogger(LOGGER_NAMES.CUSTOM_TEXT);
      logger.error('Custom text JSON is unparseable');
    }

    return json || {};
  }, [customTextJSON]);

  const [customText, setCustomText] = useState(parsedJSON);
  const [searchValue, setSearchValue] = useState('');
  const search = searchValue.trim().toLowerCase();
  const defaultJSON = useMemo(
    () =>
      getDefaultJson(extraSponsorTiers, {
        expo: !isLiteEvent,
        people: !isLiteEvent,
        networking: !isLiteEvent,
      }),
    [extraSponsorTiers, isLiteEvent],
  );

  useEffect(() => {
    // As there is no API exposed for updating custom text, we rely on the rails form to communicate.
    document
      .getElementById('custom-text-json')
      .setAttribute('value', JSON.stringify(customText));
  }, [customText]);

  const onFieldChange = useCallback(
    (value, groupKey, key) => {
      const newValues = { ...customText };
      const defaultVal = defaultJSON[groupKey][key];
      const lengthLimit = Math.max(defaultVal.length * 2, 20);

      if (!newValues[groupKey]) {
        newValues[groupKey] = {};
      }

      if (value === defaultVal) {
        delete newValues[groupKey][key];
      } else {
        newValues[groupKey][key] =
          value.length > lengthLimit ? value.substring(0, lengthLimit) : value;
      }

      if (Object.keys(newValues[groupKey]).length === 0) {
        delete newValues[groupKey];
      }

      setCustomText(newValues);
    },
    [customText, defaultJSON],
  );

  const reset = useCallback(
    event => {
      event.preventDefault();
      if (confirm(t('are-you-sure-reset'))) {
        setCustomText(defaultJSON);
        document.getElementById('custom-text-json').setAttribute('value', '{}');
        document.getElementsByClassName('w-100')?.[0]?.submit();
      }
    },
    [defaultJSON, t],
  );

  const groups = Object.keys(defaultJSON)
    .map(groupKey => {
      const groupLabel = formatKey(groupKey);

      const fields = Object.keys(defaultJSON[groupKey])
        .map(key => {
          const fieldLabel = formatKey(key);

          const value =
            customText?.[groupKey]?.[key] ?? defaultJSON[groupKey][key];

          if (
            !value.toLowerCase().includes(search) &&
            !fieldLabel.toLowerCase().includes(search) &&
            !groupLabel.toLowerCase().includes(search)
          ) {
            return null;
          }

          return (
            <TextField
              key={key}
              mb={2}
              px={2}
              label={fieldLabel}
              value={value}
              required
              onChange={e => onFieldChange(e.target.value, groupKey, key)}
            />
          );
        })
        .filter(Boolean);

      if (fields.length === 0) {
        return null;
      }

      return (
        <Card key={groupKey} id={groupKey} data-testid={groupKey} my={4} p={2}>
          <Text pattern="headingFour" element="h4" mb={2}>
            {groupLabel}
          </Text>

          {fields}
        </Card>
      );
    })
    .filter(Boolean);

  return (
    <>
      <Text mb={2} element="p" pattern="body">
        {t('main-advice')} {t('language-selector-advice')}{' '}
        {t('re-enable-advice')}{' '}
        <Linkicon
          id="reset-custom-text"
          pattern="primary"
          scale={4}
          label={t('reset-all')}
          onClick={reset}
        />
      </Text>

      <Text my={2} element="p" pattern="body">
        {t('curly-text-advice', { minutes: '{{ minutes }}' })}{' '}
        {t('leave-that-text-advice')} {t('will-be-swapped-advice')}
      </Text>

      <Flex justifyContent="space-between">
        <Button
          pattern="secondary"
          onClick={reset}
          isInline
          leadingIcon="refresh"
          size="medium"
        >
          {t('reset-button')}
        </Button>
        <Search
          aria-label={t('search')}
          placeholder={t('search')}
          colorPattern="light"
          sizeOption="medium"
          value={searchValue}
          onClear={() => setSearchValue('')}
          onChange={e => setSearchValue(e.target.value)}
          onSubmit={e => e.stopPropagation()}
          mr={1}
        />
      </Flex>

      {groups.length === 0 ? (
        <Flex flexDirection="column" alignItems="center" py={9} px={2}>
          <Illustration name="error" />
          <Text align="center" element="h3" pattern="headingThree">
            {t('no-results')}
          </Text>
        </Flex>
      ) : (
        groups
      )}
    </>
  );
}

export default compose(
  withThemeProvider,
  withAlertsProvider,
  withErrorHandling({ loggerName: LOGGER_NAMES.CUSTOM_TEXT }),
  withLocalizationProvider,
  withReduxProvider,
)(CustomTextForm);

CustomTextForm.propTypes = {
  extraSponsorTiers: bool,
  customTextJSON: string,
};
