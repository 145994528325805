import PropTypes from 'prop-types';
import React from 'react';

import BigIdeas from './assets/big-ideas.png';
import Field from './assets/field.png';

export const WebcastPreview = ({ logo, eventName }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="none"
    viewBox="0 0 639 344"
  >
    <g filter="url(#a)">
      <rect
        width={568}
        height={293}
        x={25}
        y={15}
        fill="#fff"
        rx={16}
        shapeRendering="crispEdges"
      />

      <image
        width={25}
        height={25}
        x={36.5}
        y={26.5}
        clipPath="inset(0% round 4px)"
        href={logo}
      />

      <rect
        width={31.2}
        height={31.2}
        x={33.4}
        y={23.4}
        stroke="#222"
        strokeOpacity={0.1}
        strokeWidth={0.8}
        rx={7.6}
      />
      <text
        xmlSpace="preserve"
        fill="#222"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={73} y={43.44}>
          {eventName.length > 30 ? eventName.slice(0, 30) + '...' : eventName}
        </tspan>
      </text>
      <image href={BigIdeas} x="35" y="61" />
      <rect width={36} height={20} x={36} y={277} fill="#F0F4FA" rx={6} />
      <path
        fill="#1D6EAA"
        d="M52 284.25a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm3 3h-2c-2.03 0-2.75 1.49-2.75 2.76 0 1.14.6 1.74 1.75 1.74h4c1.14 0 1.75-.6 1.75-1.74 0-1.27-.72-2.76-2.75-2.76Z"
      />
      <rect
        width={40.5}
        height={24.5}
        x={33.75}
        y={274.75}
        stroke="#1D6EAA"
        strokeWidth={1.5}
        rx={7.25}
      />
      <path
        fill="#F6F5F1"
        d="M82 283a6 6 0 0 1 6-6h9a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-9a6 6 0 0 1-6-6v-8Z"
      />
      <path
        fill="#8C8C8C"
        d="M88.84 284.7a1.67 1.67 0 1 1 3.33.01 1.67 1.67 0 0 1-3.33 0Zm2.5 2.5h-1.67c-1.7 0-2.3 1.25-2.3 2.3 0 .96.51 1.46 1.46 1.46h3.34c.95 0 1.45-.5 1.45-1.45 0-1.06-.6-2.3-2.29-2.3Z"
      />
      <path
        fill="#F6F5F1"
        d="M101 279c0-1.1.9-2 2-2h9a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6h-9a2 2 0 0 1-2-2v-16Z"
      />
      <path
        fill="#8C8C8C"
        d="M107.84 284.7a1.67 1.67 0 1 1 3.33.01 1.67 1.67 0 0 1-3.33 0Zm2.5 2.5h-1.67c-1.7 0-2.3 1.25-2.3 2.3 0 .96.51 1.46 1.46 1.46h3.34c.95 0 1.45-.5 1.45-1.45 0-1.06-.6-2.3-2.29-2.3Z"
      />
      <rect
        width={41.2}
        height={25.2}
        x={79.4}
        y={274.4}
        stroke="#222"
        strokeOpacity={0.1}
        strokeWidth={0.8}
        rx={7.6}
      />
      <path
        fill="#8C8C8C"
        d="M132.34 284.7a1.67 1.67 0 1 1 3.33.01 1.67 1.67 0 0 1-3.33 0Zm2.5 2.5h-1.67c-1.7 0-2.3 1.25-2.3 2.3 0 .96.51 1.46 1.47 1.46h3.32c.96 0 1.47-.5 1.47-1.45 0-1.06-.6-2.3-2.3-2.3Z"
      />
      <rect width={20} height={13} x={141} y={280.5} fill="#F6F5F1" rx={2} />
      <rect
        width={41.2}
        height={25.2}
        x={125.4}
        y={274.4}
        stroke="#222"
        strokeOpacity={0.1}
        strokeWidth={0.8}
        rx={7.6}
      />
      <path
        fill="#8C8C8C"
        d="M178.34 278.7a1.67 1.67 0 1 1 3.33.01 1.67 1.67 0 0 1-3.33 0Zm2.5 2.5h-1.67c-1.7 0-2.3 1.25-2.3 2.3 0 .96.51 1.46 1.47 1.46h3.32c.96 0 1.47-.5 1.47-1.45 0-1.06-.6-2.3-2.3-2.3Zm-2.5 9.5a1.67 1.67 0 1 1 3.33.01 1.67 1.67 0 0 1-3.33 0Zm2.5 2.5h-1.67c-1.7 0-2.3 1.25-2.3 2.3 0 .96.51 1.46 1.47 1.46h3.32c.96 0 1.47-.5 1.47-1.45 0-1.06-.6-2.3-2.3-2.3Z"
      />
      <rect width={20} height={13} x={187} y={280.5} fill="#F6F5F1" rx={2} />
      <rect
        width={41.2}
        height={25.2}
        x={171.4}
        y={274.4}
        stroke="#222"
        strokeOpacity={0.1}
        strokeWidth={0.8}
        rx={7.6}
      />
      <rect width={36} height={20} x={220} y={277} fill="#F6F5F1" rx={6} />
      <path
        fill="#8C8C8C"
        d="M225.34 287.7a1.67 1.67 0 1 1 3.33.01 1.67 1.67 0 0 1-3.33 0Zm2.5 2.5h-1.67c-1.7 0-2.3 1.25-2.3 2.3 0 .96.51 1.46 1.47 1.46h3.32c.96 0 1.47-.5 1.47-1.45 0-1.06-.6-2.3-2.3-2.3Z"
      />
      <rect
        width={41.2}
        height={25.2}
        x={217.4}
        y={274.4}
        stroke="#222"
        strokeOpacity={0.1}
        strokeWidth={0.8}
        rx={7.6}
      />
      <rect width={22} height={22} x={396} y={25.5} fill="#F6F5F1" rx={11} />
      <path
        fill="#3399F7"
        d="M404.1 41.75h-2.2v-6.63h2.2v6.63Zm.23-9.21a1.29 1.29 0 1 0-1.33 1.29 1.3 1.3 0 0 0 1.33-1.3Zm7.9 5.2c0-2.06-1.35-2.7-2.48-2.7a2.3 2.3 0 0 0-2 1.04v-.96h-2.13v6.64h2.21V38.2a1.3 1.3 0 0 1 1.2-1.37c.58 0 1.02.37 1.02 1.42v3.5h2.2l-.02-4.01Z"
      />
      <circle cx={414.5} cy={44} r={3} fill="#21BB6F" stroke="#F6F5F1" />
      <rect width={22} height={22} x={426} y={25.5} fill="#F6F5F1" rx={11} />
      <path
        fill="#1778F2"
        d="M442.83 36.5a5.84 5.84 0 0 1-4.92 5.76v-4.01h1.42l.27-1.75h-1.69v-1.1c0-.46.23-.9.95-.9h.74v-1.4s-.67-.12-1.31-.12c-1.33 0-2.2.76-2.2 2.22v1.28h-1.42v1.75h1.41v4.02a5.84 5.84 0 1 1 6.75-5.77"
      />
      <circle cx={444.5} cy={44} r={3} fill="#21BB6F" stroke="#F6F5F1" />
      <rect width={22} height={22} x={456} y={25.5} fill="#F6F5F1" rx={11} />
      <path
        fill="red"
        d="M472.59 33.7a1.5 1.5 0 0 0-1.03-1.04c-.91-.24-4.56-.24-4.56-.24s-3.65 0-4.56.24c-.5.14-.9.53-1.03 1.03-.24.91-.24 2.82-.24 2.82s0 1.9.24 2.8c.14.5.53.89 1.03 1.02.91.25 4.56.25 4.56.25s3.65 0 4.56-.25c.5-.13.9-.51 1.03-1.01.24-.91.24-2.81.24-2.81s0-1.9-.24-2.82Zm-6.76 4.55v-3.5l2.92 1.75-2.92 1.75Z"
      />
      <circle cx={474.5} cy={44} r={3} fill="#21BB6F" stroke="#F6F5F1" />
      <rect
        width={99}
        height={27}
        x={486}
        y={23}
        fill="#F54C3D"
        fillOpacity={0.1}
        rx={8}
      />
      <text
        xmlSpace="preserve"
        fill="#F54C3D"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={496} y={40.94}>
          {'End Broadcast'}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        fill="#8C8C8C"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={360} y={73.94}>
          {'Brand color'}
        </tspan>
      </text>
      <circle cx={380} cy={103} r={10} fill="#FF7A00" />
      <text
        xmlSpace="preserve"
        fill="#8C8C8C"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={398} y={107.44}>
          {'#FF7A00'}
        </tspan>
      </text>
      <rect
        width={224}
        height={39}
        x={360.5}
        y={83.5}
        stroke="#222"
        strokeOpacity={0.1}
        rx={7.5}
      />
      <text
        xmlSpace="preserve"
        fill="#8C8C8C"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={360} y={146.94}>
          {'Theme'}
        </tspan>
      </text>
      <path fill="#FF7A00" d="M360 165.5h77l-4.2 21H360v-21Z" />
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize={10}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={381} y={179.7}>
          {'Default'}
        </tspan>
      </text>
      <rect
        width={107}
        height={38.5}
        x={360.75}
        y={156.75}
        stroke="#1D6EAA"
        strokeWidth={1.5}
        rx={7.25}
      />
      <rect
        width={49}
        height={21}
        x={506.25}
        y={165.5}
        fill="#FF7A00"
        rx={10.5}
      />
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize={10}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={514.25} y={179.7}>
          {'Bubble'}
        </tspan>
      </text>
      <rect
        width={107.5}
        height={39}
        x={477}
        y={156.5}
        stroke="#222"
        strokeOpacity={0.1}
        rx={7.5}
      />
      <text
        xmlSpace="preserve"
        fill="#8C8C8C"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={360} y={219.94}>
          {'Background'}
        </tspan>
      </text>
      <g fill="#FF7A00" clipPath="url(#c)">
        <rect
          width={108.5}
          height={71}
          x={360}
          y={229}
          fillOpacity={0.2}
          rx={8}
        />
        <ellipse
          cx={449.5}
          cy={289}
          fillOpacity={0.3}
          opacity={0.6}
          rx={40.5}
          ry={41}
        />
      </g>
      <rect
        width={106.5}
        height={69}
        x={361}
        y={230}
        stroke="#0684BC"
        strokeWidth={2}
        rx={7}
      />
      <rect width={108.5} height={71} x={476.5} y={229} fill="url(#d)" rx={8} />
      <rect
        width={107.5}
        height={70}
        x={477}
        y={229.5}
        stroke="#222"
        strokeOpacity={0.1}
        rx={7.5}
      />
    </g>
    <g filter="url(#e)">
      <rect
        width={72}
        height={251}
        x={555}
        y={75}
        fill="url(#f)"
        rx={12}
        shapeRendering="crispEdges"
      />
      <rect width={56} height={51} x={563} y={83} fill="#F0F4FA" rx={5} />
      <path
        fill="#1D6EAA"
        d="M599.18 98.15c-.75-3.73-4.32-6.48-8.25-6.48a8.3 8.3 0 0 0-8.26 8.33c0 4.6 3.77 8.33 8.33 8.33 1.56 0 1.72-1.33.23-2.16-1.09-.62-1.05-2.47 1.63-2.47h1.86c1.85 0 5.38-.92 4.46-5.55Zm-12.33 4.56a1.04 1.04 0 0 1 0-2.08 1.04 1.04 0 0 1 0 2.08Zm0-3.33a1.04 1.04 0 0 1 0-2.09 1.04 1.04 0 0 1 0 2.08Zm2.5-2.5a1.04 1.04 0 0 1 0-2.09 1.04 1.04 0 0 1 0 2.08Zm3.33 0a1.04 1.04 0 0 1 0-2.09 1.04 1.04 0 0 1 0 2.08Zm2.5 2.5a1.04 1.04 0 0 1 0-2.09 1.04 1.04 0 0 1 0 2.08Z"
      />
      <text
        xmlSpace="preserve"
        fill="#1D6EAA"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={574.73} y={125.44}>
          {'Brand'}
        </tspan>
      </text>
      <path
        fill="#8C8C8C"
        d="M597.92 159.03c-.1-.31-.1-.64.04-.93a5.8 5.8 0 0 0-3.19-7.87 5.81 5.81 0 0 1-7.54 7.53 5.83 5.83 0 0 0 7.87 3.2c.3-.14.62-.14.92-.03l1.62.54c.51.16 1-.32.83-.83l-.55-1.61Z"
      />
      <path
        fill="#8C8C8C"
        d="M595.17 152.33a5.83 5.83 0 1 0-11.13 2.44c.14.29.14.62.04.92l-.54 1.62c-.17.5.31 1 .82.82l1.62-.54c.3-.1.63-.1.92.04l.33.13a5.84 5.84 0 0 0 7.93-5.43Z"
        opacity={0.4}
      />
      <path
        fill="#8C8C8C"
        d="M591.84 153.17a.84.84 0 0 1-.84-.84.83.83 0 1 1 .84.83Zm-1.66-.84a.83.83 0 1 0-1.67 0c0 .46.38.84.84.84.46 0 .83-.38.83-.84Zm-2.51 0a.83.83 0 0 0-.83-.83h-.01a.83.83 0 0 0-.83.83c0 .46.38.84.84.84.46 0 .83-.38.83-.84Z"
      />
      <text
        xmlSpace="preserve"
        fill="#8C8C8C"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={578.03} y={181.44}>
          {'Chat'}
        </tspan>
      </text>
      <path
        fill="#8C8C8C"
        d="M598.5 203.28v10.94a.77.77 0 0 1-1.25.61l-.86-.69a7.07 7.07 0 0 0-2.06-1.16v-8.45l.63-.27c.5-.24.99-.54 1.43-.9l.86-.7a.77.77 0 0 1 1.25.62Z"
      />
      <path
        fill="#8C8C8C"
        d="M593.7 204.72a7.4 7.4 0 0 1-2 .28h-4.45a3.75 3.75 0 0 0 0 7.5l2.04 4.08c.28.56.86.92 1.49.92h1.05l-.83-5h.7a7.4 7.4 0 0 1 2.63.47v-8.44l-.62.2Z"
        opacity={0.4}
      />
      <text
        xmlSpace="preserve"
        fill="#8C8C8C"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={568.44} y={237.44}>
          {'Banners'}
        </tspan>
      </text>
      <path
        fill="#8C8C8C"
        d="M583.5 266c0 1.12.25 2.18.7 3.13.17.37.17.8.04 1.19l-.7 2.08a.84.84 0 0 0 1.06 1.06l2.08-.7c.4-.13.82-.13 1.19.05a7.5 7.5 0 1 0-4.37-6.8Z"
        opacity={0.4}
      />
      <path
        fill="#8C8C8C"
        d="M593.08 265.33v-1.2a2.09 2.09 0 0 0-4.16 0v1.2c-.55.13-.84.52-.84 1.2v1.87c0 .83.42 1.25 1.25 1.25h3.34c.83 0 1.25-.42 1.25-1.25v-1.88c0-.67-.3-1.06-.84-1.19Zm-2.08-2.04c.46 0 .83.38.83.83v1.15h-1.66v-1.14c0-.46.37-.84.83-.84Z"
      />
      <text
        xmlSpace="preserve"
        fill="#8C8C8C"
        fontFamily="Inter"
        fontSize={12}
        fontWeight={500}
        letterSpacing="0em"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan x={570.91} y={294.44}>
          {'Private \u2028'}
        </tspan>
        <tspan x={578.03} y={310.44}>
          {'Chat'}
        </tspan>
      </text>
      <rect
        width={71}
        height={250}
        x={555.5}
        y={75.5}
        stroke="url(#g)"
        rx={11.5}
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <linearGradient
        id="f"
        x1={555}
        x2={691.54}
        y1={75}
        y2={240.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.9} />
        <stop offset={1} stopColor="#fff" stopOpacity={1} />
      </linearGradient>
      <linearGradient
        id="g"
        x1={555}
        x2={715.72}
        y1={75}
        y2={165.14}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.76} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <clipPath id="c">
        <rect width={108.5} height={71} x={360} y={229} fill="#fff" rx={8} />
      </clipPath>
      <filter
        id="a"
        width={628}
        height={358}
        x={-5}
        y={-15}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={15} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1_48712"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={12.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend
          in2="effect1_backgroundBlur_1_48712"
          result="effect2_dropShadow_1_48712"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_1_48712"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        width={132}
        height={311}
        x={525}
        y={45}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={15} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1_48712"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend
          in2="effect1_backgroundBlur_1_48712"
          result="effect2_dropShadow_1_48712"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_1_48712"
          result="shape"
        />
      </filter>
      <pattern
        id="d"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use xlinkHref="#h" transform="matrix(.00242 0 0 .0037 -.08 0)" />
      </pattern>
      <image id="h" width={480} height={270} href={Field} />
    </defs>
  </svg>
);

WebcastPreview.propTypes = {
  logo: PropTypes.string,
  eventName: PropTypes.string,
};
