import React from 'react';

export const RevertIcon = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 18C16.9704 18 20.9999 13.9706 20.9999 9C20.9999 4.02944 16.9704 0 11.9999 0C7.02929 0 2.99985 4.02944 2.99985 9H0.709852C0.574266 8.99803 0.443928 9.05234 0.349852 9.15L0.149852 9.35C0.055196 9.44388 0.00195312 9.57168 0.00195312 9.705C0.00195312 9.83832 0.055196 9.96612 0.149852 10.06L3.67985 13.6C3.87767 13.7918 4.19204 13.7918 4.38985 13.6L7.91985 10.06C8.01451 9.96612 8.06775 9.83832 8.06775 9.705C8.06775 9.57168 8.01451 9.44388 7.91985 9.35L7.71985 9.15C7.62578 9.05234 7.49544 8.99803 7.35985 9H4.99985C4.99985 5.13401 8.13386 2 11.9999 2C15.8658 2 18.9999 5.13401 18.9999 9C18.9999 12.866 15.8658 16 11.9999 16C10.6176 16.0036 9.26605 15.5925 8.11985 14.82C7.9223 14.6876 7.65887 14.7127 7.48985 14.88L6.76985 15.61C6.66898 15.7136 6.61792 15.8559 6.62985 16C6.64261 16.148 6.7193 16.2831 6.83985 16.37C8.34868 17.4363 10.1523 18.0061 11.9999 18ZM11.9999 7C13.1044 7 13.9999 7.89543 13.9999 9C13.9999 10.1046 13.1044 11 11.9999 11C10.8953 11 9.99985 10.1046 9.99985 9C9.99985 7.89543 10.8953 7 11.9999 7Z"
      fill="#1d6eaa"
    />
  </svg>
);
