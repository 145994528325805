import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { LocalizationContext } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Icon } from '@hopin-team/ui-icon';
import { Spinner } from '@hopin-team/ui-spinner';
import { array, bool, func, number, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import {
  AnnouncementModal,
  AnnouncementModalContent,
  AnnouncementModalFooter,
  AnnouncementModalHeader,
  AnnouncementsText,
  SecondaryActionButton,
} from '../styles';
import { Audience } from './audience';

const SendEmailModal = ({
  event,
  isModalDisplayed,
  handleClose,
  announcementEmailTemplateId,
  token,
  selectedTicketTypes,
  status,
  recipientsCount,
  eventAudienceCount,
  subject,
}) => {
  const { t } = useContext(LocalizationContext);
  const {
    registration_count: registrationCount,
    organiser_id: organiserId,
    id: eventId,
    slug: eventSlug,
  } = event;
  const { addAlert } = useContext(AlertsContext);
  const translationKeyPrefix = 'email-dashboard.send-email';
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await hopinApi.sendAnnouncementEmail(
        token,
        organiserId,
        eventId,
        announcementEmailTemplateId,
      );
      window.location.assign(
        `/organisers/events/${eventSlug}/announcements/?sent=true`,
      );
    } catch (e) {
      addAlert({
        active: true,
        text: t(`${translationKeyPrefix}.error`),
        pattern: 'error',
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  return (
    <>
      <Alerts />
      <AnnouncementModal
        describedById="modal-header"
        isShowing={isModalDisplayed}
        onClose={handleClose}
        isDismissible
        withCloseButton
        label="Send Email Modal"
        size="medium"
        data-testid="send-email-modal"
      >
        <AnnouncementModalHeader>
          <AnnouncementsText>
            {t(`${translationKeyPrefix}.ready-to-launch`)}
          </AnnouncementsText>
        </AnnouncementModalHeader>
        <AnnouncementModalContent backgroundColor="gray-100">
          <img src="/images/send-email.png" alt="" />

          <Box mt={3} mb={1}>
            {t(`email-dashboard.schedule-email.subject`)}: {subject}
          </Box>
          <Audience
            selectedTicketTypes={selectedTicketTypes}
            recipientsCount={recipientsCount}
            eventAudienceCount={eventAudienceCount}
            status={status}
            registrationCount={registrationCount}
          />
        </AnnouncementModalContent>
        <AnnouncementModalFooter>
          <SecondaryActionButton
            onClick={handleClose}
            data-testid="cancel-button"
          >
            {t(`cancel`)}
          </SecondaryActionButton>
          {isLoading ? (
            <Button
              isInline
              size="small"
              data-testid="sending-email-button-modal"
            >
              <AnnouncementsText color="grey-100" mr={1}>
                {t(`${translationKeyPrefix}.sending`)}
              </AnnouncementsText>
              <Spinner
                label={t(`${translationKeyPrefix}.sending`)}
                isAssertive
                isShowing={isLoading}
                color="grey-100"
              />
            </Button>
          ) : (
            <Button
              isInline
              size="small"
              onClick={handleClick}
              data-testid="send-email-button-modal"
            >
              {t(`${translationKeyPrefix}.title`)}
              <Icon
                aria-labelledby="send-email"
                name="send"
                color="grey-100"
                scale={1.5}
                ml={1}
              />
            </Button>
          )}
        </AnnouncementModalFooter>
      </AnnouncementModal>
    </>
  );
};

SendEmailModal.propTypes = {
  event: shape({
    registration_count: number.isRequired,
    organiser_id: number.isRequired,
    id: number.isRequired,
    slug: string.isRequired,
  }).isRequired,
  isModalDisplayed: bool.isRequired,
  handleClose: func.isRequired,
  announcementEmailTemplateId: string.isRequired,
  token: string,
  status: string,
  selectedTicketTypes: array,
  hasTicketTypes: bool,
  recipientsCount: number,
  eventAudienceCount: number,
  subject: string,
};

export default SendEmailModal;
