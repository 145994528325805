import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${rem(21)} ${rem(27)} ${rem(13)} ${rem(21)};
  border: 1px solid var(--color-gray-200);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  max-width: ${rem(408)};
`;

export const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rem(16)};
`;

export const Name = styled.div`
  color: var(--color-black);
  font-size: ${rem(24)};
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-medium);
`;

export const Role = styled.p`
  color: var(--color-gray-700);
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  font-weight: var(--font-weight-medium);
  margin-bottom: ${rem(15)};
`;
