import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { QuantityField, QuantityInput } from '@hopin-team/ui-quantity-field';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { bool, func, number, object, shape, string } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import DoorsOpenDetails from './doors-open-details';

const InlineBox = styled(Box)`
  display: inline-block;
  vertical-align: top;
`;

const PreEventAccess = ({
  register,
  control,
  eventId,
  organizationPaymentPlans,
  supportUrl,
}) => {
  const { t } = useLocalization('venue-controls.pre-event-access');
  const {
    field: {
      onChange: enabledOnChange,
      name: enabledName,
      value: enabledValue,
    },
  } = useController({
    name: 'venue_settings[pre_event_enabled]',
    control,
  });

  const {
    field: {
      onChange: durationUnitOnChange,
      name: durationUnitName,
      value: durationUnitValue,
    },
  } = useController({
    name: 'venue_settings[pre_event_duration_unit]',
    control,
  });

  const months = [{ value: 'months', label: t('months') }];

  const unitOptions = [
    { value: 'minutes', label: t('minutes') },
    { value: 'hours', label: t('hours') },
    {
      value: 'days',
      label: t('days'),
    },
    { value: 'weeks', label: t('weeks') },
    ...months,
  ];

  return (
    <Box mb={3}>
      <Text element={'h3'} pattern="headingFour" scale={4} weight="medium">
        {t('early-access')}
      </Text>
      <Box>
        <DoorsOpenDetails
          eventId={eventId}
          supportUrl={supportUrl}
          organization={organizationPaymentPlans}
        />
      </Box>
      <Box mb={2}>
        <Checkbox
          onChange={e => enabledOnChange(e.target.checked)}
          value={enabledValue}
          name={enabledName}
          checked={!!enabledValue}
          id="enable-early-access"
          label={t('enable')}
        />
      </Box>
      {enabledValue && (
        <Box>
          <Text pattern="body">{t('early-access')}</Text>
          <QuantityField sizeOption="small" px={1} isInline={true}>
            <QuantityInput
              name="venue_settings[pre_event_duration_amount]"
              ref={register}
              aria-label={t('early-access-amount')}
              px={1}
            />
          </QuantityField>
          <InlineBox mr={1}>
            <Select
              label={t('early-access-unit')}
              hideLabel={true}
              size="small"
              options={unitOptions}
              value={durationUnitValue}
              setValue={durationUnitOnChange}
              name={durationUnitName}
            />
          </InlineBox>
          <Text pattern="body">{t('before-event-is-live')}</Text>
        </Box>
      )}
    </Box>
  );
};

PreEventAccess.propTypes = {
  register: func.isRequired,
  control: object.isRequired,
  eventId: number.isRequired,
  organizationPaymentPlans: shape({
    advancedPlan: bool.isRequired,
    growthPlan: bool.isRequired,
  }),
  supportUrl: string.isRequired,
};

export default withLocalizationProvider(PreEventAccess);
