import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import {
  surveyLaunchText,
  surveyLaunchTime,
} from '@features/survey-builder/helpers';
import { Badge } from '@hopin-team/ui-badge';
import { Butticon } from '@hopin-team/ui-butticon';
import { Flex } from '@hopin-team/ui-flex';
import { Menu, MenuContent, MenuSelectItem } from '@hopin-team/ui-menu';
import { Spinner } from '@hopin-team/ui-spinner';
import { TableCell, TableRow } from '@hopin-team/ui-table';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { bool, number, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import { SCHEDULE_TAB } from '../edit-survey/edit-survey';

const SurveyListRow = ({
  survey,
  event,
  eventSlug,
  eventId,
  organizationId,
  authToken,
}) => {
  const { t } = useLocalization();
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const [isEnabled, setIsEnabled] = useState(survey.enabled);
  const [isLoading, setIsLoading] = useState(false);

  const { addAlert } = useContext(AlertsContext);

  const { name } = survey;
  const launchTime = surveyLaunchTime(survey, event, isEnabled, t);
  const launchText = surveyLaunchText(survey, isEnabled, t);

  const editSurveyUrl = Routes.editOrganisersEventSurveyPath(
    eventSlug,
    survey.id,
  );

  const onEditClick = () => {
    window.location.assign(editSurveyUrl);
  };

  const onScheduleClick = () => {
    window.location.assign(`${editSurveyUrl}?tab=${SCHEDULE_TAB}`);
  };

  const onToggleEnabled = async () => {
    setIsMenuShowing(false);
    setIsLoading(true);

    const data = {
      enabled: !isEnabled,
    };
    try {
      const response = await hopinApi.updateSurvey(
        authToken,
        organizationId,
        eventId,
        survey.id,
        data,
      );
      addAlert({
        active: true,
        text: response.enabled
          ? t('survey-builder.list.survey-enabled')
          : t('survey-builder.list.survey-disabled'),
        pattern: 'success',
      });
      setIsEnabled(response.enabled);
    } catch (e) {
      addAlert({
        active: true,
        text: t('survey-builder.list.something-went-wrong'),
        pattern: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledTableRow>
      <TableCell px={3}>
        <Text pattern="emphasis" color="grey-900">
          {name}
        </Text>
      </TableCell>
      <TableCell px={3}>
        {isLoading ? (
          <Spinner size="small" isShowing={isLoading} />
        ) : (
          <Badge
            label={
              isEnabled
                ? t('survey-builder.list.enabled')
                : t('survey-builder.list.disabled')
            }
            priority={isEnabled ? 'low' : 'high'}
            colorPattern={isEnabled ? 'success' : 'inactive'}
          />
        )}
      </TableCell>
      <TableCell px={3}>
        <Text pattern="emphasis" color="grey-600">
          {launchTime}
          {launchText ? ` ${launchText}` : null}
        </Text>
      </TableCell>
      <TableCell px={3}>
        <Flex justifyContent="flex-end">
          <Butticon
            icon="edit"
            size="small"
            mr={0.5}
            title={t('survey-builder.list.edit')}
            onClick={onEditClick}
          />
          <Menu isShowing={isMenuShowing}>
            <Butticon
              icon="option"
              size="small"
              title={
                isMenuShowing
                  ? t('survey-builder.list.close-menu')
                  : t('survey-builder.list.open-menu')
              }
              onClick={() => setIsMenuShowing(!isMenuShowing)}
            />
            <StyledMenuContent size="small">
              <MenuSection>
                <MenuSelectItem
                  label={t('survey-builder.list.customize')}
                  px={3}
                  py={1}
                  onClick={onEditClick}
                />
              </MenuSection>
              <MenuSection>
                <MenuSelectItem
                  label={t('survey-builder.list.schedule')}
                  px={3}
                  py={1}
                  onClick={onScheduleClick}
                />
              </MenuSection>
              {!survey.publishedAt && (
                <MenuSection withTopBorder>
                  <MenuSelectItem
                    label={
                      isEnabled
                        ? t('survey-builder.list.disable')
                        : t('survey-builder.list.enable')
                    }
                    px={3}
                    py={1}
                    onClick={onToggleEnabled}
                    disabled={isLoading}
                  />
                </MenuSection>
              )}
            </StyledMenuContent>
          </Menu>
        </Flex>
      </TableCell>
    </StyledTableRow>
  );
};

export default SurveyListRow;

SurveyListRow.propTypes = {
  survey: shape({
    name: string.isRequired,
    enabled: bool.isRequired,
    publishedAt: string,
    scheduledAt: string,
  }),
  eventSlug: string,
  eventId: number,
  organizationId: number,
  authToken: string,
  event: shape({
    id: number.isRequired,
    organiser_id: number.isRequired,
  }),
};

const StyledTableRow = styled(TableRow)`
  border-bottom: 0.0625rem solid var(--color-gray-300) !important; // important required to override planetary styles
`;

const StyledMenuContent = styled(MenuContent)`
  width: 12rem;
`;

const MenuSection = styled.div`
  padding: 0.25rem 0;

  ${({ withTopBorder }) =>
    withTopBorder &&
    css`
      border-top: 0.0625rem solid var(--color-gray-200);
    `}
`;
