import EmptyState from '@components/empty-state';
import { getPageConstants } from '@components/empty-state/constants/page';
import Alerts from '@features/alerts/alerts';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

import CsvUpload from './csv/csv-upload';

const ExpoEmptyState = ({ authenticityToken, eventSlug, canInvite }) => {
  const { t } = useLocalization();
  const pageType = 'expo';
  const pageConstants = getPageConstants(t)[pageType];

  const [isShowingModal, setIsShowingModal] = useState(false);
  const toggleModal = useCallback(
    () => setIsShowingModal(previous => !previous),
    [setIsShowingModal],
  );
  return (
    <>
      <Alerts />
      <EmptyState eventSlug={eventSlug} pageType={pageType}>
        <Button
          href={pageConstants.route(eventSlug)}
          isInline
          leadingIcon="plus"
          mr={2}
          size="medium"
        >
          {pageConstants.buttonText}
        </Button>

        <CsvUpload
          authenticityToken={authenticityToken}
          eventSlug={eventSlug}
          buttonText={pageConstants.bulkCreateButtonText}
          size="medium"
          isShowing={isShowingModal}
          toggleModal={toggleModal}
          canInvite={canInvite}
        />
      </EmptyState>
    </>
  );
};

ExpoEmptyState.propTypes = {
  authenticityToken: string.isRequired,
  eventSlug: string.isRequired,
  canInvite: bool.isRequired,
};

export default compose(
  withThemeProvider,
  withLocalizationProvider,
  withAlertsProvider,
)(ExpoEmptyState);
