import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const DotWrapper = styled.div`
  padding: var(--spacing-8) var(--spacing-8) 0;
`;

export const Dot = styled.div`
  width: var(--spacing-8);
  height: var(--spacing-8);
  background-color: ${props => props.color};
  border-radius: 50%;
`;

export const Capitalized = styled.span`
  text-transform: capitalize;
  color: ${props => props.color};
`;

const Status = ({ statusKey }) => {
  const { t } = useLocalization(
    'communications.customise-emails.table.statuses',
  );
  const statusLabel = {
    default: t('enabled'),
    disabled: t('disabled'),
    draft: t('enabled'),
    custom: t('enabled'),
  }[statusKey];

  const dotColor = {
    default: 'var(--color-blue-400)',
    disabled: 'var(--color-red-400)',
    draft: 'var(--color-blue-400)',
    custom: 'var(--color-blue-400)',
  }[statusKey];

  const labelColor = statusKey => {
    if (statusKey == 'disabled') {
      return 'var(--color-gray-600)';
    }
    return 'var(--color-gray-800)';
  };

  return (
    <Flex>
      <DotWrapper>
        <Dot color={dotColor} data-testid="announcment-status-dot" />
      </DotWrapper>
      <Flex flexDirection="column">
        <Capitalized color={labelColor(statusKey)}>{statusLabel}</Capitalized>
        {statusKey == 'draft' && (
          <Text
            pattern="caption"
            style={{ fontWeight: 400 }}
            color={labelColor(statusKey)}
          >
            {t('unsaved-changes')}
          </Text>
        )}
        {statusKey == 'custom' && (
          <Text
            pattern="caption"
            style={{ fontWeight: 400 }}
            color={labelColor(statusKey)}
          >
            {t('custom-email')}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

Status.propTypes = {
  statusKey: string.isRequired,
};

export default Status;
