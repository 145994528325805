import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Mount, useHostSlice } from '@slicesjs/react-core';
import { object, string } from 'prop-types';
import React, { useMemo } from 'react';

import { loadWithWindow } from '../../components/slices/loadWithWindow';
import { SLICE_PACKAGE } from './constants';
import { withSlicesProvider } from './withSlicesProvider';

export const LATEST = 'latest';

const initializeFn = (scope, module) => {
  return loadWithWindow(scope, module)();
};

const MountSlice = ({
  packageName,
  version = LATEST,
  reactiveMap,
  basename,
  pathname,
  navigator,
  urlOverride,
}) => {
  const url = useMemo(
    () =>
      urlOverride
        ? urlOverride
        : {
            version,
          },
    [urlOverride, version],
  );

  const { mount, error } = useHostSlice({
    packageName,
    url,
    UNSAFE_customScriptInitializer: initializeFn,
  });

  if (mount) {
    return (
      <Mount
        mount={mount}
        reactiveMap={reactiveMap}
        basename={basename}
        pathname={pathname}
        navigator={navigator}
      />
    );
  }

  if (error) {
    // temporary change while we're fixing the Help Center slice for reports page (@pod-product-growth)
    if (packageName === SLICE_PACKAGE.HELP_CENTER) {
      return null;
    }
    throw new Error(error);
  }

  // to remove the loading indicator for Help Center slice
  if (packageName === SLICE_PACKAGE.HELP_CENTER) {
    return null;
  }

  return (
    <Flex justifyContent="center" my={2}>
      <Spinner isShowing pattern="jumbo" />
    </Flex>
  );
};

MountSlice.propTypes = {
  packageName: string.isRequired,
  version: string,
  basename: string,
  pathname: string,
  navigator: object,
  reactiveMap: object,
  urlOverride: string,
};

export default withSlicesProvider(MountSlice);
