import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getDownloadUrl, getPlayableUrl, getRecording } from '../../../api';
import { FETCHING_STATE } from '../../initial-state';
import { selectToken } from '../auth/auth-selectors';

export const fetchRecording = createAsyncThunk(
  'recording/fetchRecording',
  async (args, { getState, rejectWithValue }) => {
    const { id, slug, force = false } = args;
    const { recordings } = getState();

    if (recordings[id]?.createdAt && !force) return recordings[id];

    try {
      const response = await getRecording(id, slug);
      return response;
    } catch (err) {
      return rejectWithValue({ id });
    }
  },
);

export const fetchPlayableUrl = createAsyncThunk(
  'recording/fetchPlayableUrl',
  async (id, { getState, rejectWithValue }) => {
    const token = selectToken(getState());

    try {
      const response = await getPlayableUrl(token, id);
      return response.playableUrl;
    } catch (err) {
      return rejectWithValue({ id });
    }
  },
);

export const fetchDownloadUrl = createAsyncThunk(
  'recording/fetchDownloadUrl',
  async (id, { getState, rejectWithValue }) => {
    const token = selectToken(getState());

    try {
      const response = await getDownloadUrl(token, id);
      return response.downloadUrl;
    } catch (err) {
      return rejectWithValue({ id });
    }
  },
);

export const defaultRecordingState = {
  ...FETCHING_STATE.default,
  isDeleting: false,
  isProcessing: false,
  mar: {
    playable: {
      ...FETCHING_STATE.default,
      url: null,
    },
    download: {
      ...FETCHING_STATE.default,
      url: null,
    },
  },
};

export const recordingsSlice = createSlice({
  name: 'recordings',
  initialState: {},
  reducers: {
    removeRecording_pending: (state, action) => {
      const id = action.payload?.id;
      if (!id) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          isDeleting: true,
        },
      };
    },
    removeRecording_rejected: (state, action) => {
      const id = action.payload?.id;
      if (!id) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          isDeleting: false,
        },
      };
    },
    removeRecording_fulfilled: (state, action) => {
      const id = action.payload;
      if (!id) return state;
      delete state[id];
      return state;
    },
    updateRecording: (state, action) => {
      const id = action.payload?.id;
      if (!id) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.payload,
        },
      };
    },
    addRecordings: (state, action) => {
      const { recordings } = action.payload;
      if (!recordings || !recordings.length) return state;

      let newState = {
        ...state,
      };

      recordings.forEach(recording => {
        newState[recording.id] = {
          ...defaultRecordingState,
          ...recording,
        };
      });

      return newState;
    },
    updateMarPlayableUrl: (state, action) => {
      const id = action.payload?.id;
      if (!id) return state;

      state[id].mar.playable.url = action.payload.playableUrl;
    },
  },
  extraReducers: builder => {
    // fetchRecording
    builder.addCase(fetchRecording.pending, (state, action) => {
      const { id } = action.meta.arg;
      state[id] = {
        ...FETCHING_STATE.pending,
        id,
      };
    });

    builder.addCase(fetchRecording.rejected, (state, action) => {
      const { id } = action.meta.arg;
      state[id] = FETCHING_STATE.rejected;
    });

    builder.addCase(fetchRecording.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      state[id] = {
        ...action.payload,
        ...defaultRecordingState,
        ...FETCHING_STATE.fulfilled,
      };
    });

    // fetchPlayableUrl
    builder.addCase(fetchPlayableUrl.pending, (state, action) => {
      const id = action.meta.arg;
      state[id].mar.playable = FETCHING_STATE.pending;
    });

    builder.addCase(fetchPlayableUrl.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state[id].mar.playable = {
        ...FETCHING_STATE.fulfilled,
        url: action.payload,
      };
    });

    builder.addCase(fetchPlayableUrl.rejected, (state, action) => {
      const id = action.meta.arg;
      state[id].mar.playable = FETCHING_STATE.rejected;
    });

    // fetchDownloadUrl
    builder.addCase(fetchDownloadUrl.pending, (state, action) => {
      const id = action.meta.arg;
      state[id].mar.download = FETCHING_STATE.pending;
    });

    builder.addCase(fetchDownloadUrl.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state[id].mar.download = {
        ...FETCHING_STATE.fulfilled,
        url: action.payload,
      };
    });

    builder.addCase(fetchDownloadUrl.rejected, (state, action) => {
      const id = action.meta.arg;
      state[id].mar.download = FETCHING_STATE.rejected;
    });
  },
});

export const {
  addRecordings,
  updateRecording,
  updateMarPlayableUrl,
  removeRecording_pending,
  removeRecording_rejected,
  removeRecording_fulfilled,
} = recordingsSlice.actions;

export default recordingsSlice.reducer;
