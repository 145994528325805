import { LocalizationContext } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { getBrowserTimezone, utcStringToTimeZone } from '@util/date-helpers';
import { bool, instanceOf, string } from 'prop-types';
import React, { useContext, useMemo } from 'react';

import * as Styled from './event-date-component.styles';

const YEAR_WITHOUT_TIME_DATE_FORMAT = 'dd LLL yyyy';
const DAY_AND_TIME_DATE_FORMAT = 'dd LLL, h:mm aaa';
const DAY_YEAR_AND_TIME_DATE_FORMAT = 'dd LLL yyyy, h:mm aaa';

export const EventDate = ({
  startTime,
  endTime,
  calendarLink,
  canAddToCalendar,
  postEventTimeEnd,
}) => {
  const isPastEventEndTime = postEventTimeEnd && new Date() > endTime;
  const isPastPostEventTime =
    postEventTimeEnd && new Date() > new Date(postEventTimeEnd);
  const dateFormat = isPastEventEndTime
    ? YEAR_WITHOUT_TIME_DATE_FORMAT
    : DAY_AND_TIME_DATE_FORMAT;
  const formattedStartTime = useMemo(
    () => utcStringToTimeZone(startTime, dateFormat, getBrowserTimezone()),
    [dateFormat, startTime],
  );
  const formattedEndTime = useMemo(
    () => utcStringToTimeZone(endTime, dateFormat, getBrowserTimezone()),
    [dateFormat, endTime],
  );
  const formattedPostEventEndTime = useMemo(
    () =>
      postEventTimeEnd &&
      utcStringToTimeZone(
        postEventTimeEnd,
        DAY_YEAR_AND_TIME_DATE_FORMAT,
        getBrowserTimezone(),
      ),
    [postEventTimeEnd],
  );
  const { t } = useContext(LocalizationContext);
  return (
    <Styled.EventDateWrapper justifyContent="space-between">
      <Flex flexGrow={1}>
        <Styled.LabelledDate isGrayedOut={isPastEventEndTime}>
          <Text element="span">{t('registration-v1.start-time')}</Text>
          <Text element="time" pattern="body" dateTime={formattedStartTime}>
            {formattedStartTime}
          </Text>
        </Styled.LabelledDate>
        <Styled.LabelledDate isGrayedOut={isPastEventEndTime}>
          <Text element="span">{t('registration-v1.end-time')}</Text>
          <Text element="time" pattern="body" dateTime={formattedEndTime}>
            {formattedEndTime}
          </Text>
        </Styled.LabelledDate>
        {isPastEventEndTime && (
          <Styled.LabelledDate isGrayedOut={isPastPostEventTime}>
            <Text element="span">
              {t('registration-v1.post-event.available-until')}
            </Text>
            <Text
              element="time"
              pattern="body"
              dateTime={formattedPostEventEndTime}
            >
              {formattedPostEventEndTime}
            </Text>
          </Styled.LabelledDate>
        )}
      </Flex>
      {canAddToCalendar && calendarLink && (
        <Styled.Linkicon href={calendarLink} target="_blank">
          <Icon
            className="add-to-cal-link__icon"
            name="calendar-add"
            aria-labelledby="event-page-header-add-to-calendar-link-label"
          />
          <span
            id="event-page-header-add-to-calendar-link-label"
            className="add-to-cal-link__label"
          >
            {t('registration-v1.add-to-calendar')}
          </span>
        </Styled.Linkicon>
      )}
    </Styled.EventDateWrapper>
  );
};

EventDate.propTypes = {
  startTime: instanceOf(Date).isRequired,
  endTime: instanceOf(Date).isRequired,
  canAddToCalendar: bool,
  calendarLink: string,
  postEventTimeEnd: instanceOf(Date).isRequired,
};
