import getDisplayName from '@util/get-display-name';
import { node } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

export const AlertsContext = React.createContext({
  alerts: {},
});

const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState({});

  const addAlert = useCallback(({ id, ...alert }) => {
    setAlerts(currentAlerts => ({
      ...currentAlerts,
      [id || uuid()]: { ...alert },
    }));
  }, []);

  const clearAlerts = useCallback(() => {
    setAlerts({});
  }, []);

  const dismissAlert = useCallback(id => {
    setAlerts(currentAlerts => {
      // eslint-disable-next-line no-unused-vars
      const { [id]: removedAlert, ...restAlerts } = currentAlerts;

      return restAlerts;
    });
  }, []);

  const contextValue = useMemo(
    () => ({ addAlert, alerts, dismissAlert, setAlerts, clearAlerts }),
    [addAlert, alerts, dismissAlert, setAlerts, clearAlerts],
  );
  return (
    <AlertsContext.Provider value={contextValue}>
      {children}
    </AlertsContext.Provider>
  );
};

export const withAlertsProvider = WrappedComponent => {
  const WithAlertsProvider = props => {
    return (
      <AlertsProvider>
        <WrappedComponent {...props} />
      </AlertsProvider>
    );
  };
  WithAlertsProvider.displayName = `WithAlertsProvider(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithAlertsProvider;
};

AlertsProvider.propTypes = {
  children: node,
};

export default AlertsProvider;
