import { FOOTER_HEIGHT } from '@features/event-creation-wizard/components/footer/styles';
import { TOP_BAR_HEIGHT } from '@features/event-creation-wizard/components/top-bar/styles';
import { rem } from 'polished';
import styled from 'styled-components';

const SidePanel = styled.aside`
  position: fixed;
  width: 50%;
  right: 0;
  height: calc(100vh - ${rem(TOP_BAR_HEIGHT)} - ${rem(FOOTER_HEIGHT)});
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    display: none;
  }
`;

const PreviewContainer = styled.div`
  width: 100%;
  max-width: 700px;
`;

export { SidePanel, PreviewContainer };
