import { includes } from 'lodash';
import * as yup from 'yup';

export const schema = yup.object({
  whoCanWatch: yup.string(),
  whoCanWatchAction: yup.string().when('whoCanWatch', {
    is: val => includes(['private_', 'specific_ticket_holders'], val),
    then: schema => schema.required(),
  }),
  watchers: yup.array(yup.object()).when(['whoCanWatch', 'whoCanWatchAction'], {
    is: (whoCanWatch, whoCanWatchAction) =>
      includes(['add', 'remove'], whoCanWatchAction) &&
      whoCanWatch === 'private_',
    then: schema => schema.min(1),
  }),
  tickets: yup.array(yup.object()).when(['whoCanWatch', 'whoCanWatchAction'], {
    is: (whoCanWatch, whoCanWatchAction) =>
      includes(['add', 'remove'], whoCanWatchAction) &&
      whoCanWatch === 'specific_ticket_holders',
    then: schema => schema.min(1),
  }),
  whoCanParticipate: yup.string(),
  whoCanParticipateAction: yup.string().when('whoCanParticipate', {
    is: val => includes(['invite_only', 'moderated'], val),
    then: schema => schema.required(),
  }),
  participants: yup
    .array(yup.object())
    .when(['whoCanParticipate', 'whoCanParticipateAction'], {
      is: (whoCanParticipate, whoCanParticipateAction) =>
        includes(['add', 'remove'], whoCanParticipateAction) &&
        whoCanParticipate === 'invite_only',
      then: schema => schema.min(1),
    }),
  moderators: yup
    .array(yup.object())
    .when(['whoCanParticipate', 'whoCanParticipateAction'], {
      is: (whoCanParticipate, whoCanParticipateAction) =>
        includes(['add', 'remove'], whoCanParticipateAction) &&
        whoCanParticipate === 'moderated',
      then: schema => schema.min(1),
    }),
  rtmp: yup.string().nullable(),
  rtmpTarget: yup.string(),
  rtmpSession: yup.string().when(['rtmp', 'rtmpTarget'], {
    is: (rtmp, rtmpTarget) => rtmp === 'true' && rtmpTarget == 'Roundtable',
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
  }),
  rtmpStage: yup.string().when(['rtmp', 'rtmpTarget'], {
    is: (rtmp, rtmpTarget) => rtmp === 'true' && rtmpTarget == 'Stage',
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
  }),
});
