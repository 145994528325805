import { Button } from '@hopin-team/ui-button';
import styled from 'styled-components';

export const RetryButton = styled(Button).attrs({
  isInline: true,
  isOutlined: true,
  p: 0,
  pattern: 'danger',
})`
  border: none;
  border-bottom: 2px solid currentColor;
  border-top: 2px solid transparent;
  border-radius: 0;
  height: auto;
  font-size: 1rem;
  margin-left: 0.15rem;

  &:hover {
    background: none;
    border: none;
    border-bottom: 2px solid currentColor;
    border-top: 2px solid transparent;
  }
`;
