import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { array, object, string } from 'prop-types';
import { filter, groupBy, merge, pipe, prop, propEq, sortBy } from 'ramda';
import React from 'react';

import { Styles } from './sponsors-style';

const Sponsors = ({ sponsors, title, tierNames }) => {
  const sponsorGroups = pipe(
    filter(propEq('section', 'registration')),
    sortBy(prop('position')),
    groupBy(prop('level')),
    merge({ gold: [], silver: [], bronze: [] }),
  )(sponsors);

  return (
    <>
      <Text data-testid="sponsors-title" pattern="headingTwo" as="h3" mb={3}>
        {title}
      </Text>
      <Styles.Sponsors>
        {Object.entries(sponsorGroups).map(
          ([sponsorType, sponsorsList], idx) => {
            return (
              <>
                {sponsorsList.length > 0 ? (
                  <div key={idx}>
                    <Styles.SponsorHeader
                      pattern="labelTwo"
                      element="p"
                      sponsorType={sponsorType}
                      sponsorTypeName={
                        (tierNames && tierNames[sponsorType]) || sponsorType
                      }
                      data-testid={`tier-${sponsorType}`}
                    ></Styles.SponsorHeader>
                    <Styles.SponsorsGrid
                      data-testid="sponsor-grid"
                      sponsorType={sponsorType}
                    >
                      {sponsorsList.map(({ sponsor }, i) => {
                        return (
                          <Box
                            key={`sponsor-${i}`}
                            cornerRadius="lg"
                            border="grey-200"
                            p={1}
                          >
                            <Styles.SponsorLink
                              sponsorType={sponsorType}
                              href={sponsor.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={sponsor.logo}
                                alt={sponsor.name}
                                title={sponsor.name}
                              />
                            </Styles.SponsorLink>
                          </Box>
                        );
                      })}
                    </Styles.SponsorsGrid>
                  </div>
                ) : null}
              </>
            );
          },
        )}
      </Styles.Sponsors>
    </>
  );
};

Sponsors.propTypes = {
  sponsors: array,
  title: string,
  tierNames: object,
};

export default Sponsors;
