import { Input, Select } from '@components/inputs';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { arrayOf, bool, func, object, string } from 'prop-types';
import React from 'react';

import { Section } from '../section';
import {
  Buttons,
  DetailsFields,
  Error,
  Field,
  Label,
} from './single-invite.styles';

const SingleInvite = ({
  errors,
  inputRef,
  isSubmitting,
  onSubmit,
  onTestEmailClick,
  tickets,
}) => {
  const { t } = useLocalization('magic-links.tabs.single-invite');

  return (
    <Box mx={1.5} py={3}>
      <form onSubmit={onSubmit} data-testid="single-invite-form">
        <Section title={t('enter-details-header')}>
          <DetailsFields>
            <Field>
              <Label htmlFor="email">{t('email')}</Label>
              <Input
                id="email"
                error={errors.email}
                name="email"
                autoComplete="none"
                placeholder={t('email-placeholder')}
                ref={inputRef}
                data-testid="single-invite-email"
              />
              {errors.email && (
                <Error data-testid="single-invite-email-error">
                  {errors.email.message}
                </Error>
              )}
            </Field>
            <Field>
              <Label htmlFor="firstName">{t('first-name')}</Label>
              <Input
                id="firstName"
                error={errors.first_name}
                name="first_name"
                autoComplete="none"
                placeholder={t('first-name-placeholder')}
                ref={inputRef}
                data-testid="single-invite-firstname"
              />
              {errors.first_name && (
                <Error data-testid="single-invite-firstname-error">
                  {errors.first_name.message}
                </Error>
              )}
            </Field>
            <Field>
              <Label htmlFor="lastName">{t('last-name')}</Label>
              <Input
                id="lastName"
                error={errors.last_name}
                name="last_name"
                autoComplete="none"
                placeholder={t('last-name-placeholder')}
                ref={inputRef}
                data-testid="single-invite-lastname"
              />
              {errors.last_name && (
                <Error data-testid="single-invite-lastname-error">
                  {errors.last_name.message}
                </Error>
              )}
            </Field>
            <Field>
              <Label htmlFor="headline">{t('headline')}</Label>
              <Input
                id="headline"
                error={errors.headline}
                name="headline"
                autoComplete="none"
                placeholder={t('headline-placeholder')}
                ref={inputRef}
                data-testid="single-invite-headline"
              />
              {errors.headline && (
                <Error data-testid="single-invite-headline-error">
                  {errors.headline.message}
                </Error>
              )}
            </Field>
          </DetailsFields>
        </Section>
        <Section title={t('select-ticket-type-header')}>
          <Field>
            <Label htmlFor="ticketType">{t('ticket-type')}</Label>
            <Select
              id="ticketType"
              error={errors.persona_id}
              name="persona_id"
              autoComplete="none"
              ref={inputRef}
              data-testid="single-invite-ticket-type"
            >
              <option value="">{t('ticket-type-blank-option')}</option>
              {tickets.map(({ label, id }) => (
                <option key={`${label}-${id}`} value={id}>
                  {label}
                </option>
              ))}
            </Select>
            {errors.persona_id && (
              <Error data-testid="single-invite-ticket-type-error">
                {errors.persona_id.message}
              </Error>
            )}
          </Field>
        </Section>
        <Buttons>
          <Button
            type="button"
            size="medium"
            isOutlined={true}
            onClick={onTestEmailClick}
          >
            {t('send-test-email')}
          </Button>
          <Button
            type="submit"
            size="medium"
            disabled={isSubmitting}
            data-testid="single-invite-submit"
          >
            {t('send-invitation')}
          </Button>
        </Buttons>
      </form>
    </Box>
  );
};

SingleInvite.propTypes = {
  inputRef: func.isRequired,
  isSubmitting: bool,
  onSubmit: func.isRequired,
  onTestEmailClick: func.isRequired,
  errors: object,
  privacyDisclaimer: string,
  tickets: arrayOf(object),
};

export default SingleInvite;
