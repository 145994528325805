import { Butticon } from '@hopin-team/ui-butticon';
import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

const FullScreenWrapper = styled.main`
  height: 100vh;
  width: 100vw:
`;

const Content = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})`
  height: 100%;
`;

const CloseButton = styled(Butticon).attrs({
  icon: 'close',
  size: 'medium',
  pattern: 'secondary',
  p: 1.75,
  m: 1.5,
})`
  position: absolute;
  top: 0;
  right: 0;

  &:focus {
    position: absolute;
  }
`;

export { FullScreenWrapper, Content, CloseButton };
