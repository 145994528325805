import NewSummaryMetricItem from '@features/dashboard/common/new-summary-metric-item';
import { useLocalization } from '@features/localization';
import { number } from 'prop-types';
import React from 'react';

const EngagementTiles = (metrics, values, isLoading, isAnalyticsLoading) => {
  const { t } = useLocalization('connections-dashboard');
  return metrics.map(metric => {
    return (
      <NewSummaryMetricItem
        key={metric.key}
        isLoading={metric.isAnalytics ? isAnalyticsLoading : isLoading}
        icon={<metric.icon />}
        value={values[metric.key]}
        text={t(metric.text)}
        color={metric.color}
        tooltipText={t(`${metric.text}-tooltip`)}
      />
    );
  });
};
EngagementTiles.propTypes = {
  eventId: number.isRequired,
};

export default EngagementTiles;
