import hopinApi from '@api/hopin';
import camelizeKeys from '@util/camelize-keys';
export class ValidationError extends Error {
  constructor(message, field) {
    super(message);
    this.name = 'ValidationError';
    this.field = field;
  }
}

const EMPTY_TITLE_ERROR_MESSAGE = 'The title of a recording cannot be empty';
const UNEXPECTED_ERROR_MESSAGE =
  'Something went wrong, please try again later.';

const validateRecordingInputs = attributes => {
  if (attributes.title === '') {
    throw new ValidationError(EMPTY_TITLE_ERROR_MESSAGE, 'title');
  }
};

export const getRecordingAreas = async eventSlug => {
  const response = await hopinApi.getRecordingAreas(eventSlug);
  if (response.video_areas) {
    return camelizeKeys(response.video_areas);
  } else {
    throw new ValidationError(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getRecording = async (id, eventSlug) => {
  const response = await hopinApi.getRecording(id, eventSlug);

  if (response.recording) {
    return camelizeKeys(response.recording);
  } else {
    throw new ValidationError(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getRecordings = async (eventSlug, filters) => {
  const response = await hopinApi.backupGetRecordings(eventSlug, filters);

  return camelizeKeys(response.recordings);
};

export const updateRecording = async (id, attributes, eventSlug) => {
  validateRecordingInputs(attributes);

  const response = await hopinApi.updateRecording(id, attributes, eventSlug);

  if (response.success) {
    return camelizeKeys(response.recording);
  } else {
    if (response?.errors?.attributes?.title) {
      if (response.errors.attributes.title.symbol === 'empty') {
        throw new ValidationError(EMPTY_TITLE_ERROR_MESSAGE, 'title');
      }
    }

    throw new ValidationError(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const trimRecording = async (
  id,
  eventSlug,
  { startTimeMs, endTimeMs },
) => {
  const response = await hopinApi.trimRecording(id, eventSlug, {
    start_time_ms: startTimeMs,
    end_time_ms: endTimeMs,
  });

  if (response.success) {
    return camelizeKeys(response.recording);
  } else {
    throw new ValidationError(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const revertTrimRecording = async (id, eventSlug) => {
  const response = await hopinApi.revertTrimRecording(id, eventSlug);

  if (response.success) {
    return camelizeKeys(response.recording);
  } else {
    throw new ValidationError(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getThumbnails = async (recordingId, eventSlug) => {
  const response = await hopinApi.getThumbnails(recordingId, eventSlug);

  return camelizeKeys(response.thumbnails);
};

export const getPlayableUrl = async (token, recordingId) => {
  const response = await hopinApi.getPlayableUrl(token, recordingId);

  return camelizeKeys(response);
};

export const getDownloadUrl = async (token, recordingId) => {
  const response = await hopinApi.getDownloadUrl(token, recordingId);

  return camelizeKeys(response);
};

const FAILED_TO_DELETE_ERROR_MESSAGE =
  'Something went wrong trying to delete the recording, please try again later.';
export const deleteRecording = async (id, eventSlug) => {
  const response = await hopinApi.backupDeleteRecording(id, eventSlug);

  if (!response.success) {
    throw new ValidationError(FAILED_TO_DELETE_ERROR_MESSAGE);
  }
};
