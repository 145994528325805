import { withLocalizationProvider } from '@features/localization';
import { ParentSize } from '@visx/responsive';
import { array } from 'prop-types';
import React from 'react';

import TreemapComponent from './treemap-component';

const TreemapContainer = ({ data }) => {
  return (
    <ParentSize>
      {({ width }) => (
        <TreemapComponent width={width} height={500} data={data} />
      )}
    </ParentSize>
  );
};

TreemapContainer.propTypes = {
  data: array.isRequired,
};

export default withLocalizationProvider(TreemapContainer);
