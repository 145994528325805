import { TextField } from '@hopin-team/ui-text-field';
import { string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  input {
    font-size: 0.75rem;
    font-weight: 400;
  }
`;

const TextInput = ({ label, name, id, initialValue = '' }) => {
  const [currentValue, setCurrentValue] = useState(initialValue);

  const changeValue = useCallback(
    evt => {
      setCurrentValue(evt.target.value);
    },
    [setCurrentValue],
  );

  return (
    <StyledTextField
      label={label}
      name={name}
      id={id}
      value={currentValue}
      onChange={changeValue}
      size="tiny"
    />
  );
};

TextInput.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  id: string.isRequired,
  initialValue: string,
};

export default TextInput;
