export const STEPS = {
  DETAILS: 'details',
  TICKETS: 'tickets',
  LANDING_PAGE: 'landing_page',
  RECEPTION: 'reception',
  SESSIONS: 'sessions',
  STAGE: 'stage',
  SPEAKER_PROFILES: 'speaker_profiles',
  PREVIEWED: 'previewed',
  PUBLISHED: 'published',
};

export const LITE_EVENT_STEPS = {
  RECORDINGS: 'publish_recordings',
  ANALYTICS: 'visit_analytics',
  EMAILS: 'check_emails',
  REPORTS: 'download_reports',
  DUPLICATE: 'duplicate_event',
};
