import { MenuHeading, MenuItem } from '@components/menu/menu-component';
import Menu from '@components/menu/menu-container';
import { useLocalization } from '@features/localization';
import { Logo } from '@hopin-team/ui-logo';
import * as Routes from '@routes';
import Book from 'icons/book.svg';
import Megaphone from 'icons/megaphone.svg';
import Play from 'icons/play.svg';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import { RELEASE_NOTES_URL } from '../constants';
import EventDetails from './event-details';
import { Styles } from './header-styles';

const Header = ({
  event,
  onEventDelete,
  onPublish,
  onEventDuplicate,
  supportUrl,
  canPublishEvent,
}) => {
  const { t } = useLocalization();

  return (
    <Styles.Header>
      <Styles.LogoBanner>
        <Styles.HeaderItem
          as={'a'}
          href={Routes.organizationsEventsPath({
            switch_to: event.organization.externalId,
          })}
        >
          <Styles.Chevron mr={1} />
          <Styles.ComboMark>
            <Logo type="ringcentral-default" scale={2.65} />
          </Styles.ComboMark>
        </Styles.HeaderItem>
        <Menu buttonContents={<Styles.LearnMenuIcon />} position="right">
          <MenuHeading>{t(`help.learn`)}</MenuHeading>
          <MenuItem>
            <a href={RELEASE_NOTES_URL} target="_blank" rel="noreferrer">
              <Megaphone />
              {t(`help.whats-new`)}
            </a>
          </MenuItem>
          <MenuItem>
            <a href={Routes.learnPath()} target="_blank" rel="noreferrer">
              <Play />
              {t(`help.video-tutorials`)}
            </a>
          </MenuItem>
          <MenuItem>
            <a href={supportUrl} target="_blank" rel="noreferrer">
              <Book />
              {t(`help.knowledge-base`)}
            </a>
          </MenuItem>
        </Menu>
      </Styles.LogoBanner>
      <EventDetails
        event={event}
        onEventDelete={onEventDelete}
        onPublish={onPublish}
        onEventDuplicate={onEventDuplicate}
        canPublishEvent={canPublishEvent}
      />
    </Styles.Header>
  );
};

Header.propTypes = {
  event: object,
  onEventDelete: func.isRequired,
  onPublish: func.isRequired,
  onEventDuplicate: func.isRequired,
  supportUrl: string.isRequired,
  canPublishEvent: bool,
};

export default Header;
