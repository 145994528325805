import { Tabs as UITabs } from '@hopin-team/ui-tabs';
import { Tab as UITab } from '@hopin-team/ui-tabs/dist/styled/tab';
import { rem } from 'polished';
import styled from 'styled-components';

export const Tab = styled(UITab)`
  font-weight: 500;
  padding: ${rem(8)} ${rem(37.5)};

  [data-selected] {
    font-weight: 500;
  }

  &:focus-visible {
    &:before {
      border: none;
      box-shadow: none;
    }
  }
`;

export const Tabs = styled(UITabs)`
  margin-top: ${rem(18)};
`;
