import { Accordion, AccordionHeading } from '@hopin-team/ui-accordion';
import { Box } from '@hopin-team/ui-box';
import { ProgressBar } from '@hopin-team/ui-progress-bar';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import Sync from 'icons/sync.svg';
import { rem } from 'polished';
import styled from 'styled-components';

import { AnnouncementsText, CenterPanel, RightPanel } from '../styles';

export const TagsPanel = styled(RightPanel)`
  height: calc(100vh - ${rem(44)});
  max-height: calc(100vh - ${rem(44)});
  font-size: ${rem(14)};
  margin-top: ${rem(32)};
  font-weight: 500;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${Accordion} {
    border: none;

    ${AccordionHeading} {
      color: var(--color-gray-650);
    }
  }
`;

export const TagsPanelHeader = styled(Text)`
  font-size: ${rem(16)};
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-700);
`;

export const TagsPanelDescription = styled(Text)`
  font-size: ${rem(14)};
  color: var(--color-gray-700);
  font-weight: 500;
`;

export const TagsPanelGroupHeader = styled(AnnouncementsText)`
  color: var(--color-gray-650);
`;

export const LearnMoreLink = styled.a`
  text-decoration: none;
`;

export const TagWrapper = styled(Box)`
  color: var(--color-gray-700);
  font-weight: 400;
  padding-left: ${rem(12)};

  .padded-tag {
    margin-left: ${rem(10)};
    line-height: ${rem(30)};

    &:first-child {
      background-color: var(--color-gray-300);
      margin-left: -${rem(12)};
      padding: ${rem(6)} ${rem(12)} ${rem(6)} ${rem(12)};
    }
  }
`;

export const AnnouncementEmailFormWrapper = styled(CenterPanel)`
  label {
    font-weight: 500;
    color: var(--color-gray-650);
  }

  ${TextField} {
    &:nth-child(3) {
      grid-column: 1/3;
    }
  }
`;

export const CKEditorWrapper = styled.div`
  margin-bottom: ${rem(64)};
`;

export const SyncingIcon = styled(Sync)`
  margin-right: var(--spacing-16);
  transition: all var(--transition-duration) ease-in-out;
  animation: rotation 1s infinite linear;
  animation-iteration-count: infinite;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const CreateTicketsContainer = styled.div`
  display: flex;
  background-color: var(--color-white);
  padding: var(--spacing-8);
  margin-bottom: ${rem(38)};
  border-radius: ${rem(8)};
`;

export const CreateTicketsBody = styled.div`
  padding-left: var(--spacing-16);
`;

export const CreateTicketsTitle = styled.h4`
  line-height: ${rem(28)};
  color: var(--color-gray-800);
`;

export const CreateTicketsContent = styled.div`
  font-size: ${rem(12)};
  color: var(--color-gray-700);
  margin-bottom: var(--spacing-8);
`;

export const AudienceTargetingContainer = styled.div`
  background-color: var(--color-white);
  padding: var(--spacing-16);
  margin-bottom: ${rem(24)};
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--color-gray-300);
`;

export const AudienceTargetingCount = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-500);
  margin-bottom: ${rem(8)};
`;

export const AudienceProgressBar = styled(ProgressBar)`
  --color-progress-bar: var(--color-green-400);
  --color-progress-track: var(--color-gray-200);
  margin: var(--spacing-8) 0 var(--spacing-4);
`;

export const AudienceTargetingProgressBarTrack = styled.div`
  display: flex;
  background: var(--color-gray-200);
  height: 0.375rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: ${rem(20)};
`;

export const AudienceTargetingProgressBarLine = styled.div`
  height: 100%;
  background-color: ${({ type }) =>
    type === 'primary' ? 'var(--color-green-400)' : 'var(--color-yellow-400)'};
  width: ${({ value }) => `${value}%`};
  &:last-child {
    border-radius: 0 ${rem(16)} ${rem(16)} 0;
  }
`;

export const AudienceTargetingLegendItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AudienceTargetingLegendItemIndicator = styled.div`
  background: ${({ type }) =>
    type === 'primary' ? 'var(--color-green-400)' : 'var(--color-yellow-400)'};
  border-radius: 100%;
  width: ${rem(8)};
  height: ${rem(8)};
  margin-right: ${rem(8)};
`;
