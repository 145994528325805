import { useLocalization } from '@features/localization';
import { TAB_NAVIGATION_LINKS } from '@features/recordings/constants';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { TabHeader, TabList } from '@hopin-team/ui-tabs';
import { Text } from '@hopin-team/ui-text';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Tab, Tabs } from './recording-category-tab-header.styled';

export const RecordingCategoryTabHeader = () => {
  const { t } = useLocalization('recording-library');
  const { category } = useParams();
  const history = useHistory();
  const defaultIndex = useMemo(() => {
    if (category) {
      const categoryWithoutHash = category.split('#')[0];

      const index = TAB_NAVIGATION_LINKS.findIndex(
        navigationLink => navigationLink === `/${categoryWithoutHash}`,
      );
      return index > -1 ? index : 0;
    }

    return 0;
  }, [category]);

  const onChange = useCallback(
    index => {
      history.push(TAB_NAVIGATION_LINKS[index]);
    },
    [history],
  );

  return (
    <Tabs defaultIndex={defaultIndex} onChange={onChange}>
      <TabHeader>
        <TabList>
          <Tab>
            <Flex alignItems="center" justifyContent="center">
              <Icon name="circle-filled-with-outer-ring" />
              <Text ml={1}>{t('original-recordings')}</Text>
            </Flex>
          </Tab>
          <Tab>
            <Flex alignItems="center" justifyContent="center">
              <Icon name="scissors" />
              <Text ml={1}>{t('cuts')}</Text>
            </Flex>
          </Tab>
        </TabList>
      </TabHeader>
    </Tabs>
  );
};
