import { ImageUpload } from '@hopin-team/ui-image-upload';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const FilenameTitle = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: underline;
`;
export const StyledImageUpload = styled(ImageUpload)`
  display: ${({ isVisible }) => (isVisible ? 'initial' : 'none')};
`;
