import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@hopin-team/ui-tabs';
import styled from 'styled-components';

const StyledTabList = styled(TabList)`
  &[data-reach-tab-list] {
    background-color: transparent;
    display: inline-flex;
  }
`;

const StyledTab = styled(Tab)`
  &[data-reach-tab] {
    min-width: 170px;
    padding: 0 16px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &:focus:not([data-focus-visible-added]) {
    outline: 0;
  }
`;

export {
  StyledTab as Tab,
  StyledTabList as TabList,
  StyledTabPanel as TabPanel,
  TabPanels,
  Tabs,
};
