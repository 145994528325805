import { getLiteEventWizardTemplates } from './partials/template-states/lite-constants';
import { getOnsiteEventWizardTemplates } from './partials/template-states/onsite-constants';
import { getAdvancedEventWizardTemplates } from './partials/template-states/pro-advanced-constants';
import {
  getSimpleEventWizardTemplatesWithNoSponsors,
  getSimpleEventWizardTemplatesWithSponsors,
} from './partials/template-states/pro-simple-constants';

const templatesWithSimplifiedNav = {
  webinar: true,
  meetup: true,
  all_hands: true,
  workshop: true,
};

const eventHasSimplifiedNav = eventWizardTemplate => {
  return templatesWithSimplifiedNav[eventWizardTemplate] || false;
};

const RELEASE_NOTES_URL =
  'https://support.ringcentral.com/release-notes/mvp/video/events.html';

const getNavTreeByEventWizardTemplate = ({
  template,
  modifier,
  reorderTabs = false,
}) => {
  const advancedTemplates = getAdvancedEventWizardTemplates({ reorderTabs });
  const onsiteTemplates = getOnsiteEventWizardTemplates({ reorderTabs });
  const liteTemplates = getLiteEventWizardTemplates({ reorderTabs });
  const simpleEventTemplatesWithSponsors = getSimpleEventWizardTemplatesWithSponsors(
    { reorderTabs },
  );

  const simpleEventTemplatesWithNoSponsors = getSimpleEventWizardTemplatesWithNoSponsors(
    { reorderTabs },
  );

  const navTreeByEventWizardTemplates = {
    default: {
      before: advancedTemplates.before,
      during: advancedTemplates.during,
      after: advancedTemplates.after,
    },

    onsite: {
      before: onsiteTemplates.before,
      during: onsiteTemplates.during,
      after: onsiteTemplates.after,
    },

    webinar: {
      before: simpleEventTemplatesWithSponsors.before,
      during: simpleEventTemplatesWithSponsors.during,
      after: simpleEventTemplatesWithSponsors.after,
    },

    meetup: {
      before: simpleEventTemplatesWithNoSponsors.before,
      during: simpleEventTemplatesWithNoSponsors.during,
      after: simpleEventTemplatesWithNoSponsors.after,
    },

    all_hands: {
      before: simpleEventTemplatesWithNoSponsors.before,
      during: simpleEventTemplatesWithNoSponsors.during,
      after: simpleEventTemplatesWithNoSponsors.after,
    },

    workshop: {
      before: simpleEventTemplatesWithNoSponsors.before,
      during: simpleEventTemplatesWithNoSponsors.during,
      after: simpleEventTemplatesWithNoSponsors.after,
    },

    lite_meetup: {
      before: liteTemplates.before,
      during: liteTemplates.during,
      after: liteTemplates.after,
    },

    lite_workshop: {
      before: liteTemplates.before,
      during: liteTemplates.during,
      after: liteTemplates.after,
    },

    lite_webcast: {
      before: liteTemplates.before,
      during: liteTemplates.during,
      after: liteTemplates.after,
    },
  };

  return navTreeByEventWizardTemplates[template]
    ? navTreeByEventWizardTemplates[template][modifier]
    : navTreeByEventWizardTemplates.default[modifier];
};

export {
  eventHasSimplifiedNav,
  RELEASE_NOTES_URL,
  getNavTreeByEventWizardTemplate,
};
