import EmptyState from '@components/empty-state';
import GenericErrorBoundaryFallback from '@components/generic-error-boundary-fallback';
import getDisplayName from '@util/get-display-name';
import logger from '@util/logger';
import { element, func, string } from 'prop-types';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.logger = logger(this.props.loggerName);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(err) {
    this.logger.error(err);
  }

  render() {
    const { children, errorPattern, CustomErrorBoundaryComponent } = this.props;
    if (this.state.hasError) {
      switch (errorPattern) {
        case 'page':
          return <EmptyState pageType="error" />;
        case 'custom':
          if (CustomErrorBoundaryComponent) {
            return <CustomErrorBoundaryComponent />;
          }

          console.warn(
            'withErrorHandling: You have not provided a CustomErrorBoundaryComponent, falling back to generic error boundary',
          );
          return <GenericErrorBoundaryFallback />;
        default:
          return <GenericErrorBoundaryFallback />;
      }
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: element,
  CustomErrorBoundaryComponent: func,
  errorPattern: string,
  loggerName: string,
};

const withErrorHandling = ({
  loggerName,
  errorPattern,
  CustomErrorBoundaryComponent,
} = {}) => WrappedComponent => {
  const WithErrorHandling = props => {
    return (
      <ErrorBoundary
        loggerName={loggerName}
        errorPattern={errorPattern}
        CustomErrorBoundaryComponent={CustomErrorBoundaryComponent}
      >
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
  WithErrorHandling.displayName = `WithErrorHandling(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithErrorHandling;
};

export default withErrorHandling;
