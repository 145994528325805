import EVENTS from '@features/post-registration/constants/analytics-events';
import { images } from '@features/post-registration/constants/images';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Picture } from '@hopin-team/ui-picture';
import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import { trackSegmentEventPromise } from '@util/analytics/segment-client';
import { bool, string } from 'prop-types';
import React, { useState } from 'react';

export const CalloutCard = ({
  title,
  subtitle,
  cta,
  url,
  image,
  type,
  userExternalId,
  isTestUser,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Flex
      mt={3}
      p={2}
      border="grey-300"
      cornerRadius="md"
      alignItems="flex-start"
      data-testid={`call-out-card-${image}`}
    >
      <Flex flexDirection="column" flex={1} alignItems="start">
        <Text pattern="strong" color="grey-800">
          {title}
        </Text>
        <Text pattern="body" color="grey-600">
          {subtitle}
        </Text>
        <Button
          disabled={loading}
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              if (!isTestUser) {
                await trackSegmentEventPromise(EVENTS.SELECT_INTENT, {
                  intent: type,
                  user_id: userExternalId,
                });
              }
              window.location.href = url;
            }
          }}
          mt={2}
          isInline
          outlined
          pattern="secondary"
          size="medium"
        >
          {loading ? <Spinner isShowing /> : cta}
        </Button>
      </Flex>
      <Picture
        isRound
        src={images['1x']}
        srcSets={[
          {
            srcSet: `${images[image]['1x']} 1x, ${images[image]['2x']} 2x`,
          },
        ]}
      />
    </Flex>
  );
};

CalloutCard.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  cta: string.isRequired,
  url: string.isRequired,
  image: string.isRequired,
  type: string.isRequired,
  userExternalId: string.isRequired,
  isTestUser: bool.isRequired,
};
