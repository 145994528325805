import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { bool, func, number } from 'prop-types';
import React from 'react';

import { FilenameTitle, StyledImageUpload } from './file-upload.styles';

export const FileUpload = ({ isVisible, onChange, maxFiles = 1, maxSize }) => {
  const { t } = useLocalization('people.attendees.registrant-bulk-upload');
  return (
    <StyledImageUpload
      isVisible={isVisible}
      inputAriaLabel={t('drop-area.label')}
      onImagesChange={onChange}
      width={'100%'}
      height={430 / (16 / 9)}
      renderPreview={files => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            px={4}
            css={`
              width: 100%;
              height: 100%;
            `}
          >
            <FilenameTitle
              pattern="emphasis"
              data-testid="uploaded-file-preview"
            >
              {files[0].name}
            </FilenameTitle>
          </Flex>
        );
      }}
      messages={{
        uploadImage: t('drop-area.cta-label'),
        orDropFile: t('drop-area.on-drop-label'),
        dropFile: t('drop-area.drop-file'),
        fileHints: t('drop-area.file-hints'),
        maximumUploadSize: t('drop-area.max-upload-size'),
        generalError: t('drop-area.errors.general'),
        maxSizeError: t('drop-area.errors.max-file-size'),
        tooManyFilesError: t('drop-area.errors.too-many-files'),
        fileTypeError: t('drop-area.errors.file-type'),
      }}
      options={{
        accept: {
          'text/*': ['.csv'],
        },
        maxSize,
        maxFiles,
      }}
    />
  );
};

FileUpload.propTypes = {
  isVisible: bool,
  maxFiles: number,
  maxSize: number.isRequired,
  onChange: func.isRequired,
};
