import { LocalizationContext } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Setting from './setting-component';

const AttendeeVisibility = ({ eventSettings, toggleSetting }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <div>
      <Text element="p" mb={3} pattern="headingFour" color="grey-800">
        {t('people.people-area.attendee-visibility')}
      </Text>

      <Setting
        isToggled={eventSettings.disable_people_count}
        onChange={() => toggleSetting('disable_people_count')}
        title={t('people.people-area.hide-people-count')}
        description={t('people.people-area.hide-people-count-description')}
      />
    </div>
  );
};

export default AttendeeVisibility;

AttendeeVisibility.propTypes = {
  eventSettings: PropTypes.shape({
    disable_people_count: PropTypes.bool,
  }).isRequired,
  toggleSetting: PropTypes.func.isRequired,
};
