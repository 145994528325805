import Loading from '@components/loading';
import VideoPlayer from '@components/video-player';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import { RangeSelectorContainer } from './edit-recording.styled';
import { RangeSelector } from './partials/range-selector';
import { TrimmingControls } from './partials/trimming-controls';
import { CHANGE_TYPE, DEFAULT_DELTA_UNIT_S } from './utils';

export const EditRecording = withLocalizationProvider(
  ({
    src,
    startTimeMs,
    endTimeMs,
    onTrimTimeUpdate,
    reloadRecordingSource,
  }) => {
    const [lengthOfRecordingSec, setLengthOfRecordingSec] = useState(0);
    const { t } = useContext(LocalizationContext);

    const trimRangeSec = endTimeMs - startTimeMs;

    const handleTrimStartAndEndChange = useCallback(
      (
        trimStartTimeSecArg,
        trimEndTimeSecArg,
        changeType = CHANGE_TYPE.start,
      ) => {
        let newTrimStartTimeSec = trimStartTimeSecArg;
        let newTrimEndTimeSec = trimEndTimeSecArg;

        // validate for the deltaUnit
        if (changeType === CHANGE_TYPE.start) {
          const leftOver = newTrimStartTimeSec % DEFAULT_DELTA_UNIT_S;
          if (leftOver !== 0) {
            newTrimStartTimeSec -= leftOver;
          }
        } else if (changeType === CHANGE_TYPE.end) {
          const leftOver = newTrimEndTimeSec % DEFAULT_DELTA_UNIT_S;
          if (leftOver !== 0) {
            newTrimEndTimeSec += leftOver;

            if (newTrimEndTimeSec > lengthOfRecordingSec) {
              newTrimEndTimeSec = lengthOfRecordingSec;
            }
          }
        }

        const fullRecordingSelected =
          newTrimStartTimeSec === 0 &&
          newTrimEndTimeSec === lengthOfRecordingSec;

        onTrimTimeUpdate(
          newTrimStartTimeSec,
          newTrimEndTimeSec,
          fullRecordingSelected,
        );
      },
      [lengthOfRecordingSec, onTrimTimeUpdate],
    );

    const handleDurationUpdate = useCallback(
      duratiomFromPlayer => {
        // rounding up for the inputs (i.e. 40.85 -> 41)
        const newDuration = Math.ceil(duratiomFromPlayer);
        setLengthOfRecordingSec(newDuration);

        if (endTimeMs === 0) {
          const fullRecordingSelected = startTimeMs === 0;
          onTrimTimeUpdate(startTimeMs, newDuration, fullRecordingSelected);
        }
      },
      [onTrimTimeUpdate, startTimeMs, endTimeMs],
    );

    const isLoading = lengthOfRecordingSec <= 0;

    return (
      <Loading isLoading={isLoading}>
        <Flex m="2" mb={0} flexDirection="column">
          <VideoPlayer
            mode="edit"
            src={src}
            trimStartTime={startTimeMs}
            trimEndTime={endTimeMs}
            onDurationChange={handleDurationUpdate}
            reloadSource={reloadRecordingSource}
          >
            {!isLoading && (
              <RangeSelectorContainer>
                <RangeSelector
                  min={0}
                  step={DEFAULT_DELTA_UNIT_S}
                  max={lengthOfRecordingSec}
                  start={startTimeMs}
                  end={endTimeMs}
                  onDrag={handleTrimStartAndEndChange}
                  onRangeChange={handleTrimStartAndEndChange}
                  startLabel={t(
                    'edit-recording-modal.range-selector.trim-start-time',
                  )}
                  endLabel={t(
                    'edit-recording-modal.range-selector.trim-end-time',
                  )}
                />
              </RangeSelectorContainer>
            )}
          </VideoPlayer>
          {!isLoading && (
            <TrimmingControls
              deltaUnit={DEFAULT_DELTA_UNIT_S}
              duration={trimRangeSec}
              lengthOfVideo={lengthOfRecordingSec}
              onChange={handleTrimStartAndEndChange}
              startAt={startTimeMs}
              endAt={endTimeMs}
            />
          )}
        </Flex>
      </Loading>
    );
  },
);

EditRecording.propTypes = {
  src: PropTypes.string.isRequired,
  startTimeMs: PropTypes.number.isRequired,
  endTimeMs: PropTypes.number.isRequired,
  onTrimTimeUpdate: PropTypes.func.isRequired,
  reloadRecordingSource: PropTypes.func,
};
