import CloseIcon from 'icons/close.svg';
import { array, func } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Chip,
  ChipButton,
  ChipContainer,
  ScrollTrack,
} from '../filtering/filtering.styles';
import {
  LeftDirectionButton,
  RightDirectionButton,
} from '../filtering/scroll-buttons';

const FiltersList = ({ list, onDeleteChip }) => {
  const trackRef = useRef();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const scroll = direction => {
    if (trackRef.current) {
      direction === 'right'
        ? (trackRef.current.scrollLeft += 200)
        : (trackRef.current.scrollLeft -= 200);
    }
  };

  const checkScrollPosition = useCallback(() => {
    if (trackRef.current) {
      const { offsetWidth, scrollLeft, scrollWidth } = trackRef.current;
      if (offsetWidth + scrollLeft === scrollWidth) {
        setShowRightButton(false);
      } else if (!showRightButton) {
        setShowRightButton(true);
      }
      if (scrollLeft === 0) {
        setShowLeftButton(false);
      } else if (!showLeftButton) {
        setShowLeftButton(true);
      }
    }
  }, [showLeftButton, showRightButton]);

  const handleScroll = () => {
    checkScrollPosition();
  };

  useEffect(() => {
    checkScrollPosition();
  }, [list, checkScrollPosition]);

  return (
    <ScrollTrack>
      {showLeftButton && <LeftDirectionButton onClick={() => scroll('left')} />}
      <ChipContainer onScroll={handleScroll} ref={trackRef}>
        {list.map(filter => (
          <Chip
            data-testid={`selected-${filter.label}`}
            key={`selected-${filter.label}`}
          >
            <span>{filter.label}</span>
            <ChipButton
              data-testid={`delete-button-${filter.label}`}
              onClick={() => onDeleteChip(filter)}
            >
              <CloseIcon />
            </ChipButton>
          </Chip>
        ))}
      </ChipContainer>
      {showRightButton && (
        <RightDirectionButton onClick={() => scroll('right')} />
      )}
    </ScrollTrack>
  );
};

FiltersList.propTypes = {
  list: array.isRequired,
  onDeleteChip: func.isRequired,
};

export default FiltersList;
