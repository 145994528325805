import 'react-responsive-carousel/lib/styles/carousel.min.css';

import styled from 'styled-components';

export const RightIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
`;

export const LeftIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
`;

export const CarouselContainer = styled.div`
  max-width: 100vw;
  position: relative;
  z-index: 1;

  .carousel.carousel-slider,
  .carousel .slider-wrapper {
    overflow: visible;
  }
`;
