import { rem } from 'polished';
import { oneOf, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Alert from '../icons/alert';
import CircleCheck from '../icons/circle-check';

const iconsStatuses = {
  success: <CircleCheck color="#3DD48A" />,
  error: <Alert color="#E64646" />,
};

const MessageStyles = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(12)};
`;

const Status = styled.span`
  margin-left: ${rem(6)};
`;

const MessageStatus = ({ text, status }) => {
  const icon = iconsStatuses[status];
  return (
    <MessageStyles>
      {icon}
      <Status data-testid="message-description">{text}</Status>
    </MessageStyles>
  );
};

MessageStatus.propTypes = {
  text: string.isRequired,
  status: oneOf(Object.keys(iconsStatuses)).isRequired,
};

export default MessageStatus;
