import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: null,
};

const { actions, reducer } = createSlice({
  name: 'networking',
  initialState,
  reducers: {
    setProfileCategories: (state, action) => ({
      ...state,
      categories: action.payload,
    }),
  },
});

export const { setProfileCategories } = actions;

export default reducer;
