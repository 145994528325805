import { useCallback, useEffect, useState } from 'react';

export const STORAGE_KEY_ERROR_REPORT = 'errorReport';

export const useStorageErrors = () => {
  const [storagedErrors, setStoragedErrors] = useState([]);

  const setErrorsToStorage = useCallback(errors => {
    localStorage.setItem(STORAGE_KEY_ERROR_REPORT, JSON.stringify(errors));
  }, []);

  useEffect(() => {
    setStoragedErrors(
      JSON.parse(localStorage.getItem(STORAGE_KEY_ERROR_REPORT)),
    );
  }, []);

  return { storagedErrors, setErrorsToStorage };
};
