/**
 *
 * @param defaultMapping A map from attribute name to known column name
 * @param columns The list of all columns present in the CSV file
 */
export const autoMatch = (defaultMapping, columns) => {
  return Object.entries(defaultMapping).reduce(
    (autoMapping, [attribute, column]) => {
      const caseInsensitiveMatch = columns.find(
        c => c.toLowerCase() === column.toLowerCase(),
      );

      autoMapping[attribute] = caseInsensitiveMatch ?? null;

      return autoMapping;
    },
    {},
  );
};
