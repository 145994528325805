import hopinApi from '@api/hopin';
import {
  CountriesRepresented,
  RegistrationsCount,
  TicketSales,
  TicketsSold,
} from '@features/dashboard/common/all-metrics.js';
import {
  MetricList,
  MetricListWrapper,
} from '@features/dashboard/styles/metric-list.styles.js';
import { bool, number } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../util/hooks';

// Events using the new registrations flow should display the data as is
// Events using the old reg should not show any value if the event has only free tickets
const displayValue = (
  value,
  hasPaidTickets,
  newRegistrationsDashboardEnabled,
) => {
  if (newRegistrationsDashboardEnabled) {
    return value;
  } else {
    return hasPaidTickets ? value : '-';
  }
};

const RegistrationMetrics = ({
  eventId,
  hasPaidTickets,
  newRegistrationsDashboardEnabled,
  newRegistrationsDefinitionsEnabled,
}) => {
  const [stats, setStats] = useState({
    tickets_sold: 0,
    ticket_sales: '',
    registrations_count: 0,
    countries_represented: 0,
  });

  const loading = usePromise(
    signal => hopinApi.getRegistrationMetrics(eventId, signal),
    result => {
      setStats({ ...stats, ...result });
    },
  );

  return (
    <MetricListWrapper>
      <MetricList>
        {hasPaidTickets && (
          <>
            <TicketsSold
              isLoading={loading}
              value={displayValue(
                stats.tickets_sold,
                hasPaidTickets,
                newRegistrationsDashboardEnabled,
              )}
              newRegistrationsDashboardEnabled={
                newRegistrationsDashboardEnabled
              }
              newRegistrationsDefinitionsEnabled={
                newRegistrationsDefinitionsEnabled
              }
            />
            <TicketSales
              isLoading={loading}
              value={displayValue(
                stats.ticket_sales,
                hasPaidTickets,
                newRegistrationsDashboardEnabled,
              )}
            />
          </>
        )}
        <RegistrationsCount
          isLoading={loading}
          value={stats.registrations_count}
          color="Blue"
          newRegistrationsDashboardEnabled={newRegistrationsDashboardEnabled}
        />
        <CountriesRepresented
          isLoading={loading}
          value={stats.countries_represented}
          color="Yellow"
        />
        {!hasPaidTickets && (
          <>
            <TicketsSold
              isLoading={loading}
              value={displayValue(
                stats.tickets_sold,
                hasPaidTickets,
                newRegistrationsDashboardEnabled,
              )}
              newRegistrationsDashboardEnabled={
                newRegistrationsDashboardEnabled
              }
              newRegistrationsDefinitionsEnabled={
                newRegistrationsDefinitionsEnabled
              }
            />
            <TicketSales
              isLoading={loading}
              value={displayValue(
                stats.ticket_sales,
                hasPaidTickets,
                newRegistrationsDashboardEnabled,
              )}
            />
          </>
        )}
      </MetricList>
    </MetricListWrapper>
  );
};

RegistrationMetrics.propTypes = {
  eventId: number.isRequired,
  hasPaidTickets: bool.isRequired,
  newRegistrationsDashboardEnabled: bool.isRequired,
  newRegistrationsDefinitionsEnabled: bool.isRequired,
};

export default RegistrationMetrics;
