/**
 * @param {Record<string, string>} mapping attribute name to record column name
 * @param {Set<string>} set of the question external IDs
 */
export const applyMapping = (mapping, questionExternalIds = new Set([])) => {
  // Invert the mapping so that we can easily lookup the correct attribute
  // for a given column name.
  const columnToAttribute = new Map(
    Object.entries(mapping).map(([attribute, columnName]) => [
      columnName,
      attribute,
    ]),
  );

  return record =>
    Object.entries(record).reduce((result, [columnName, value]) => {
      const attribute = columnToAttribute.get(columnName);
      const isQuestion = questionExternalIds.has(attribute);
      const isEmptyMapping = !(attribute && columnName);

      if (isQuestion) {
        result.answers = result.answers || {};
        result.answers[attribute] = value;
      }

      if (!isQuestion && !isEmptyMapping) {
        result[attribute] = value;
      }

      return result;
    }, {});
};
