import { useLocalization } from '@features/localization';
import { Grid } from '@hopin-team/ui-grid';
import { Text } from '@hopin-team/ui-text';
import { func } from 'prop-types';
import React from 'react';

import { TEMPLATES } from '../constants';
import TemplateMenuItem from '../partials/template-menu-item';

export const TemplateModalSelection = ({ onPreviewClick, onUseClick }) => {
  const { t } = useLocalization('templates');
  const customTemplateToursIds = ['xAsmg8YZuM8TwJ2fM6KeRuNVH'];

  const filteredTemplates = TEMPLATES.filter(template => {
    if (!customTemplateToursIds.includes(template.id)) {
      return template;
    }
  });

  return (
    <div data-testid="template-modal-selection">
      <Text
        align="center"
        color="grey-900"
        element="h2"
        mt={7}
        font="text"
        pattern="headingTwo"
      >
        {t('createANewEvent')}
      </Text>
      <Grid gridGap={3} gridTemplateColumns="repeat(3, 1fr)" px={7} py={3}>
        <TemplateMenuItem
          template={{ name: 'Blank' }}
          isBlank
          onClick={() => onUseClick()}
        />
        {filteredTemplates.map((template, ix) => (
          <TemplateMenuItem
            key={ix}
            template={template}
            onPreviewClick={() => onPreviewClick(template)}
            onUseClick={() => onUseClick(template)}
          />
        ))}
      </Grid>
    </div>
  );
};

TemplateModalSelection.propTypes = {
  onPreviewClick: func.isRequired,
  onUseClick: func.isRequired,
};
