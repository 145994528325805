import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Grid } from '@hopin-team/ui-grid';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { func, object, string } from 'prop-types';
import React from 'react';

import { STEPS } from '../../constants/steps';
import ChecklistItemsList from '../../styled/checklist-items-list';
import { isStepComplete } from '../../utils/steps';
import ChecklistItem from '../checklist-item';

const AddSpeakersTabPanel = ({
  checklistStatuses,
  eventSlug,
  onSkipSpeakerProfiles,
}) => {
  const { t } = useLocalization('overview.event-setup-checklist.tab-panels');
  const heading = t('add-speakers.create-speakers.heading');
  const description = t('add-speakers.create-speakers.description');

  return isStepComplete(STEPS.SPEAKER_PROFILES, checklistStatuses) ? (
    <ChecklistItemsList data-testid="event-progress-speaker-checklist">
      <ChecklistItem
        description={description}
        iconName="team"
        isChecked
        link={Routes.organisersEventSpeakersPath(eventSlug)}
        text={heading}
      />
    </ChecklistItemsList>
  ) : (
    <Grid
      gridTemplateRows="1fr auto"
      css={`
        height: 100%;
      `}
    >
      <Flex
        alignItems="center"
        css={`
          text-align: center;
        `}
        justifyContent="center"
      >
        <div>
          <Icon name="team" scale={3} color="blue-300" />
          <Text color="grey-800" element="p" pattern="labelOne">
            {heading}
          </Text>
          <Text color="grey-600" element="p" pattern="body">
            {description}
          </Text>
          <Button
            data-testid="event-progress-speakers-link"
            href={Routes.organisersEventSpeakersPath(eventSlug)}
            isInline
            mt={4}
            px={3}
            size="small"
          >
            {t('add-speakers.create-speakers.create')}
          </Button>
        </div>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button
          data-testid="event-progress-skip-speakers"
          isInline
          isOutlined
          onClick={onSkipSpeakerProfiles}
          size="small"
          type="button"
        >
          {t('skip-step')}
        </Button>
      </Flex>
    </Grid>
  );
};

AddSpeakersTabPanel.propTypes = {
  checklistStatuses: object.isRequired,
  eventSlug: string.isRequired,
  onSkipSpeakerProfiles: func.isRequired,
};

export default AddSpeakersTabPanel;
