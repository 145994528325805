import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import styled from 'styled-components';

const Container = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  max-width: 32rem;
  margin: 6rem auto;
`;

const IconWrapper = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  mb: 2,
  p: 4,
})`
  background-color: var(--color-gray-200);
  border-radius: 100%;
  width: 4rem;
  height: 4rem;

  ${Icon} {
    background-color: var(--color-gray-600);
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0.25rem 0.375rem;

    svg {
      height: 0.5rem;
    }
  }
`;

export { Container, IconWrapper };
