import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  backstageLinks: {},
};

const { actions, reducer } = createSlice({
  name: 'backstageLinks',
  initialState,
  reducers: {
    setBackstageLinks: (state, action) => action.payload.backstageLinks,
  },
});

const getBackstageLinks = ({ backstageLinks }) => backstageLinks;

export const getBackstageOptions = createSelector(
  getBackstageLinks,
  backstageLinks =>
    Object.keys(backstageLinks).reduce((acc, key) => {
      if (backstageLinks[key]?.attributes) {
        return [
          ...acc,
          {
            label: backstageLinks[key].attributes.name,
            value: key,
          },
        ];
      }
      return acc;
    }, []),
);

export const getAttributeLinks = linkTitle =>
  createSelector(getBackstageLinks, backstageLinks =>
    Object.keys(backstageLinks).reduce((acc, stageId) => {
      if (backstageLinks[stageId]?.attributes?.[linkTitle]) {
        return {
          ...acc,
          [stageId]: {
            [linkTitle]: backstageLinks[stageId].attributes[linkTitle],
          },
        };
      }
      return acc;
    }, {}),
  );

export const getHopinStudioLinks = createSelector(
  getBackstageLinks,
  backstageLinks =>
    Object.keys(backstageLinks).reduce((acc, stageId) => {
      if (backstageLinks[stageId]?.attributes) {
        return {
          ...acc,
          [stageId]: {
            hopinStudioUrl:
              backstageLinks[stageId].attributes['hopinStudioUrl'],
            stageUrl: backstageLinks[stageId].attributes['stageUrl'],
          },
        };
      }
      return acc;
    }, {}),
);

const getRTMPLinks = createSelector(getBackstageLinks, backstageLinks => {
  return Object.keys(backstageLinks).reduce((acc, stageId) => {
    if (backstageLinks[stageId]?.attributes?.rtmpCredentials?.length > 0) {
      const rtmpCredentials = backstageLinks[
        stageId
      ].attributes.rtmpCredentials.map(
        ({
          streamKey,
          rtmpServerUrl,
          rtmpsServerUrl,
          deliveryType,
          priority,
        }) => ({
          streamKey,
          rtmpServerUrl,
          rtmpsServerUrl,
          deliveryType,
          priority,
        }),
      );
      return {
        ...acc,
        [stageId]: rtmpCredentials,
      };
    }
    return acc;
  }, {});
});

export const getRtmpLinksByPriority = () =>
  createSelector(getRTMPLinks, rtmpLinks =>
    Object.keys(rtmpLinks).reduce((acc, stageId) => {
      const filteredLinks = rtmpLinks[stageId].filter(
        link => link.priority === 'preferred',
      );
      if (filteredLinks.length > 0) {
        const { streamKey, rtmpServerUrl, rtmpsServerUrl } = filteredLinks[0];
        return {
          ...acc,
          [stageId]: { streamKey, rtmpServerUrl, rtmpsServerUrl },
        };
      }
      return acc;
    }, {}),
  );

export const { setBackstageLinks } = actions;

export default reducer;
