import { Box } from '@hopin-team/ui-box';
import { calcSpace, COLOR, LINE } from '@hopin-team/ui-symbols';
import styled, { css } from 'styled-components';

const TabsColumn = styled(Box).attrs(({ isVisible }) => ({
  p: 3,
  pb: isVisible ? 7 : 4,
}))`
  width: ${calcSpace(28.5)};
  flex-shrink: 0;
  border-color: ${COLOR.GREY['200']};

  ${({ isVisible }) =>
    isVisible &&
    css`
      border-right: ${LINE.DEFAULT_BORDER};
    `}
`;

export default TabsColumn;
