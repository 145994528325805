import { TabContainer, TitleContainer } from '@components/tabbed-chart/styles';
import CardTitle from '@features/dashboard/components/card-title';
import ChangeRate from '@features/dashboard/components/change-rate/change-rate-component';
import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import TabIcon from './tab-icon';

const Tab = ({ summary, onClick, tabIndex, selected, iconName }) => {
  return (
    <TabContainer
      data-testid={'tabbed-chart-tab-' + tabIndex}
      onClick={onClick}
      onKeyUp={e => e.keycode == 13 && onClick()}
      role="button"
      tabIndex={tabIndex}
      selected={selected}
    >
      <div className="row">
        <div
          className={
            !summary.hideChangeRate && summary.changeRate != null
              ? 'col-8'
              : 'col-12'
          }
        >
          <TitleContainer>
            {iconName && <TabIcon name={iconName} className="mr-1" />}
            <CardTitle>{summary.title}</CardTitle>
          </TitleContainer>

          <h2>
            {summary.unit ? `${summary.unit}` : ''}
            {summary.number}
          </h2>
        </div>

        {!summary.hideChangeRate && summary.changeRate != null && (
          <div className="col-4 text-right">
            <ChangeRate rate={summary.changeRate} days={summary.changeDays} />
          </div>
        )}
      </div>
    </TabContainer>
  );
};

Tab.propTypes = {
  summary: shape({
    title: string.isRequired,
    number: oneOfType([string, number]).isRequired,
    unit: string,
    changeRate: number,
    hideChangeRate: bool,
  }).isRequired,
  onClick: func.isRequired,
  tabIndex: number.isRequired,
  selected: bool.isRequired,
  iconName: string,
};

export default Tab;
