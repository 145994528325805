import eventDashboardApi from '@api/event-dashboard';
import { AlertsContext } from '@features/alerts/alerts-provider';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import { Toast } from '@hopin-team/ui-toast';
import { string } from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

const Link = styled.a`
  text-decoration: none;
`;

const StyledInlineWarningMessage = styled(Toast)`
  max-width: 42rem;
  margin-bottom: 1rem;
  box-shadow: none;
  background: var(--color-yellow-100);
`;

const StyledLoading = styled.div`
  max-width: 42rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const StyledLoadingTable = styled.span`
  display: table;
  margin: 0 auto;
`;

const StyledLoadingColumn = styled.span`
  display: table-cell;
  padding-left: 0.5rem;
`;

const StyledSpinner = styled(Spinner)`
  display: table-cell;
`;

const clearChatQueryTimeoutValue = 1500;

const ClearChat = ({ eventId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [clearChatSelection, setClearChatSelection] = useState([]);
  const [clearChatData, setClearChatData] = useState(undefined);
  const [clearChatLoading, setClearChatLoading] = useState(false);
  const [pollExternalId, setPollExternalId] = useState(undefined);
  const [updateLoadingState, setUpdateLoadingState] = useState(true);
  const [disableClearChatButton, setDisableClearChatButton] = useState(false);
  const { addAlert } = useContext(AlertsContext);
  const availableSegments = ['event', 'stage', 'sessions', 'expo'];
  const { t } = useContext(LocalizationContext);
  const SEGMENT_MAP = {
    event: t('venue-controls.clear-chat.event'),
    stage: t('venue-controls.clear-chat.stage'),
    sessions: t('venue-controls.clear-chat.sessions'),
    expo: t('venue-controls.clear-chat.expo'),
  };

  const SEGMENT_LOOKUP = {
    expo: 'vendors',
    stage: 'stages',
    sessions: 'sessions',
    event: 'event',
  };

  const resetStateAndGiveError = useCallback(() => {
    setDisableClearChatButton(false);
    setClearChatSelection([]);
    setClearChatLoading(false);
    addAlert({
      active: true,
      text: t('common.general-error-message'),
      pattern: 'error',
    });
  }, [addAlert, t]);

  const onClearChatHandler = async (eventId, clearChatSelection, onSuccess) => {
    let newSelectList = clearChatSelection.map(each => SEGMENT_LOOKUP[each]);
    try {
      const { data, errors } = await eventDashboardApi.createClearChatJob(
        eventId,
        newSelectList,
      );

      if (data) {
        onSuccess(data);
      } else if (errors) {
        resetStateAndGiveError();
      }
    } catch (err) {
      resetStateAndGiveError();
    }
  };

  useEffect(() => {
    const CLEAR_CHAT_QUERY_STATES = {
      start: 'started',
      enqueue: 'enqueued',
      success: 'succeeded',
      fail: 'failed',
    };

    if (!updateLoadingState) {
      return;
    }

    let clearChatTimeout;

    const addErrorMessageAndResetFlow = () => {
      resetStateAndGiveError();
      setUpdateLoadingState(false);
      setClearChatData(undefined);
      clearTimeout(clearChatTimeout);
    };

    const onClearChatQueryHandler = async eventId => {
      try {
        const { data, errors } = await eventDashboardApi.getClearChatJob(
          eventId,
          pollExternalId,
        );
        if (errors && errors.length > 0) {
          addErrorMessageAndResetFlow();
        }
        setClearChatData(data);
      } catch (err) {
        addErrorMessageAndResetFlow();
      }
    };

    let stillLoading = false;
    if (clearChatData?.attributes?.status === CLEAR_CHAT_QUERY_STATES.enqueue) {
      stillLoading = true;
    } else if (
      clearChatData &&
      clearChatData?.attributes?.status === CLEAR_CHAT_QUERY_STATES.success &&
      clearChatSelection.length > 0
    ) {
      setDisableClearChatButton(false);
      setClearChatLoading(false);
      setClearChatSelection([]);
      setClearChatData(undefined);
      addAlert({
        active: true,
        text: t('venue-controls.clear-chat.success-message'),
        pattern: 'success',
      });
    } else if (
      clearChatData &&
      clearChatData?.attributes?.status === CLEAR_CHAT_QUERY_STATES.fail &&
      clearChatSelection.length > 0
    ) {
      stillLoading = false;
      setClearChatData(undefined);
      resetStateAndGiveError();
    } else if (
      clearChatData?.attributes?.status === CLEAR_CHAT_QUERY_STATES.start
    ) {
      stillLoading = true;
    }

    if (stillLoading) {
      clearChatTimeout = setTimeout(
        onClearChatQueryHandler,
        clearChatQueryTimeoutValue,
        eventId,
      );
    }
    return () => {
      clearTimeout(clearChatTimeout);
    };
  }, [
    addAlert,
    clearChatData,
    clearChatSelection,
    eventId,
    pollExternalId,
    resetStateAndGiveError,
    t,
    updateLoadingState,
  ]);

  const addAndToChatList = chatList => {
    let newChatList = chatList.slice();
    newChatList = newChatList.map(each =>
      t(`venue-controls.clear-chat.${each}`),
    );

    return newChatList.length > 1
      ? newChatList.slice(0, -1).join(', ') +
          ` ${t('common.and')} ` +
          newChatList.slice(-1)
      : newChatList[0];
  };

  const toggleSelected = segment => {
    let tempSelections = [...clearChatSelection];
    if (!tempSelections.includes(segment)) {
      tempSelections.push(segment);
    } else {
      tempSelections.splice(tempSelections.indexOf(segment), 1);
    }

    //This filtering is for right order, so it will get ordered according to default availableSegments list
    let latestSelection = [...availableSegments].filter(each =>
      tempSelections.includes(each),
    );

    setClearChatSelection(latestSelection);
    return latestSelection;
  };

  return (
    <Box my={3} data-testid="clear-chat">
      <Text
        element="h3"
        pattern="headingThree"
        color="grey-800"
        weight="medium"
        scale={4}
        mb={1}
      >
        {t('venue-controls.clear-chat.title')}
      </Text>
      <Fragment key={'clear-chat'}>
        <Text element="p" color="grey-600" pattern="body">
          {t('venue-controls.clear-chat.description')}
          <Link href={'reports'}>
            {' ' + t('venue-controls.clear-chat.description-report-link-label')}
          </Link>
        </Text>
        {!clearChatLoading && (
          <Flex alignItems="center" mt={1} mb={2.8}>
            {availableSegments.map(segment => (
              <Flex key={segment} alignItems="center" mr={5}>
                <Checkbox
                  name={`clear_chat[${segment}]`}
                  onChange={() => toggleSelected(segment)}
                  checked={clearChatSelection.includes(segment)}
                  label={SEGMENT_MAP[segment]}
                />
              </Flex>
            ))}
          </Flex>
        )}
        {clearChatSelection.includes('expo') && !clearChatLoading && (
          <StyledInlineWarningMessage
            colorPattern="warning"
            icon="alert"
            iconColor="yellow-600"
            isInverse="true"
          >
            <Text scale={4} data-testid="notification-toast-text">
              {t('venue-controls.clear-chat.vendor-warning-message')}
            </Text>
          </StyledInlineWarningMessage>
        )}
        {clearChatLoading && (
          <StyledLoading>
            <StyledLoadingTable>
              <StyledSpinner isShowing={true} />
              <StyledLoadingColumn>
                {t('venue-controls.clear-chat.loading-message')}
              </StyledLoadingColumn>
            </StyledLoadingTable>
          </StyledLoading>
        )}
        <Button
          isInline={true}
          size="medium"
          onClick={e => {
            setUpdateLoadingState(true);
            setIsDialogOpen(true);
            e.preventDefault();
          }}
          disabled={clearChatSelection.length === 0 || disableClearChatButton}
        >
          {t('venue-controls.clear-chat.title')}
        </Button>
      </Fragment>
      <ConfirmationDialog
        isShowing={isDialogOpen}
        title={t('venue-controls.clear-chat.confirmation-dialog-title')}
        message={t('venue-controls.clear-chat.confirmation-dialog-message', {
          selectedChats: addAndToChatList(clearChatSelection),
        })}
        primaryButtonText={t('venue-controls.clear-chat.title')}
        secondaryButtonText={t('cancel')}
        isDismissible={true}
        colorPattern="primary"
        withCloseButton={false}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={() => {
          setClearChatLoading(true);
          setDisableClearChatButton(true);
          clearChatSelection.length > 0 &&
            onClearChatHandler(eventId, clearChatSelection, data => {
              setClearChatData(data);
              setPollExternalId(data.id);
            });
          setIsDialogOpen(false);
        }}
      />
    </Box>
  );
};

ClearChat.propTypes = {
  eventId: string,
};

export default withLocalizationProvider(ClearChat);
