import { calcSpace, COLOR, LINE } from '@hopin-team/ui-symbols';
import styled, { css } from 'styled-components';

export const StyledImagePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR.GREY['650']};
  border-radius: ${LINE.RADIUS_LG};
  border: ${LINE.DEFAULT_BORDER};
  width: ${calcSpace(6)};
  height: ${calcSpace(6)};
  background-color: ${COLOR.GREY.WHITE};
  background-image: ${({ image }) =>
    image
      ? 'none'
      : css`linear-gradient(
      45deg,
      ${COLOR.GREY['300']} 25%,
      transparent 25%,
      transparent 75%,
      ${COLOR.GREY['300']} 75%,
      ${COLOR.GREY['300']}
    ),
    linear-gradient(
      45deg,
      ${COLOR.GREY['300']} 25%,
      transparent 25%,
      transparent 75%,
      ${COLOR.GREY['300']} 75%,
      ${COLOR.GREY['300']}
    )`};
  background-size: 1.0375rem 1.0375rem;
  background-position: 0 0, 0.51875rem 0.51875rem;
  overflow: hidden;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
