import hopinApi from '@api/hopin';
import Card from '@components/card-with-loading';
import Network from '@components/icons/network';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import PersonListComponent from '@features/dashboard/components/person-list-component';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { useLocalization } from '@features/localization';
import { bool, number, string } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../util/hooks';

const TopAttendeesByNetworkingMeetings = ({ eventId, theme, isDisabled }) => {
  const { t } = useLocalization(
    'connections-dashboard.top-attendees-by-networking-meetings',
  );
  const [attendees, setAttendees] = useState([]);

  const loading = usePromise(
    signal => [hopinApi.getTopAttendeesByNetworkingMeetings(eventId, signal)],
    results => setAttendees(results[0]),
  );

  return (
    <Card isLoading={loading}>
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        {isDisabled ? (
          <EmptyState text={t('disabled')} icon={<Network />} />
        ) : attendees.length > 0 ? (
          <PersonListComponent people={attendees} theme={theme} />
        ) : (
          <EmptyState text={t('empty')} icon={<Network />} />
        )}
      </CardContentWrapper>
    </Card>
  );
};

TopAttendeesByNetworkingMeetings.propTypes = {
  eventId: number.isRequired,
  theme: string,
  isDisabled: bool,
};

export default TopAttendeesByNetworkingMeetings;
