import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Section } from '../../section';

const Required = styled(Text).attrs({
  element: 'h3',
  pattern: 'heading4',
})`
  text-transform: uppercase;
`;

const Link = styled.a`
  text-decoration: none;
`;

const DownloadCSV = ({ event }) => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');

  return (
    <Section title={t('download-sample-header')}>
      <Text element="p">
        <Text pattern="labelThree" color="blue-500">
          <Link
            href={Routes.sampleCsvOrganisersEventRedeemCodesPath(event.slug)}
          >
            {t('download-sample-link')}
          </Link>
        </Text>{' '}
        <Text pattern="labelThree" color="grey-600">
          {t('download-sample-link-text')}
        </Text>
      </Text>
      <Required mt={2} mb={1}>
        {t('required')}
      </Required>
      <Box pl={3}>
        <ul>
          <li>
            <Text pattern="labelThree" color="grey-600">
              {t('required-list.email-name')}
            </Text>
          </li>
          <li>
            <Text pattern="labelThree" color="grey-600">
              {t('required-list.no-empty-rows')}
            </Text>
          </li>
          <li>
            <Text pattern="labelThree" color="grey-600">
              {t('required-list.valid-email')}
            </Text>
          </li>
          <li>
            <Text pattern="labelThree" color="grey-600">
              {t('required-list.no-empty-blocks')}
            </Text>
          </li>
        </ul>
      </Box>
    </Section>
  );
};

DownloadCSV.propTypes = {
  event: shape({
    slug: string.isRequired,
  }).isRequired,
};

export default DownloadCSV;
