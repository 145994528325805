import PropTypes from 'prop-types';
import React from 'react';

import Expo from '../assets/expo.png';
import Networking from '../assets/networking.png';
import Reception from '../assets/reception.png';
import Sessions from '../assets/sessions.png';
import Stage from '../assets/stage.png';
import { PreviewContainer, SidePanel } from './styles';

const previews = {
  reception: Reception,
  stage: Stage,
  sessions: Sessions,
  networking: Networking,
  expo: Expo,
};

export const AreaPreview = ({ area }) => {
  return (
    <SidePanel>
      <PreviewContainer>
        <img src={previews[area]} draggable="false" alt="" />
      </PreviewContainer>
    </SidePanel>
  );
};

AreaPreview.propTypes = {
  area: PropTypes.oneOf([
    'reception',
    'stage',
    'sessions',
    'networking',
    'expo',
  ]).isRequired,
};
