import ReportsNotificationWithFetchContainer from '@components/reports-notification/reports-notification-with-fetch-container';
import { useLocalization } from '@features/localization';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import {
  bool,
  elementType,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Styles } from './nav-item-styles';
import { SubNavItem } from './sub-nav-item-container';

const LinkList = styled.ul`
  overflow: hidden;
  margin: 0;
  transition: max-height 200ms ease-out;
  max-height: ${({ isExpanded }) => (isExpanded ? '100vh' : 0)};
`;

const NavItem = ({
  currentActiveItem,
  icon,
  isExpanded,
  navItemData,
  onClick,
  title,
  topLevelTranslationKey,
  isDeepLevel,
  tabIndex = 0,
  gatePaidFeatures,
}) => {
  const isLink = typeof navItemData === 'string';
  const hasChildren = Object.prototype.hasOwnProperty.call(
    navItemData,
    'children',
  );

  const subList = useRef();
  const activeItemRef = useRef();
  const [deepExpanded, setDeepExpanded] = useState(
    currentActiveItem?.tree.title ?? null,
  );

  useEffect(() => {
    if (!isExpanded) {
      setDeepExpanded(null);
    }
  }, [isExpanded]);

  const openTree = () => {
    onClick({ key: title, tree: navItemData });
  };

  const scrollToActiveItem = elem => {
    if (elem && !activeItemRef.current) {
      elem.scrollIntoView?.({ block: 'nearest' });
      activeItemRef.current = elem;
    }
  };

  const { t } = useLocalization('event-dashboard.sidenav');

  return (
    <Styles.ItemMargin $isDeepLevel={isDeepLevel}>
      <Styles.TopLevelItem
        isActive={
          topLevelTranslationKey === currentActiveItem.key ||
          topLevelTranslationKey === currentActiveItem.tree.title
        }
        {...(isLink && { as: 'a', href: navItemData })}
        $isDeepLevel={isDeepLevel}
        onClick={openTree}
        hasChildren={hasChildren}
        isExpanded={isExpanded}
        tabIndex={tabIndex}
        data-menu-action={title}
      >
        <Styles.ItemIcon name="users" as={icon} $isDeepLevel={isDeepLevel} />

        {hasChildren && (
          <Styles.Arrow>
            <Icon color="grey-800" name="caret-right" scale={2} />
          </Styles.Arrow>
        )}
        <Text font="display" pattern="headingFour">
          {title}
        </Text>
        {title === 'Analytics' ? (
          <Styles.Notification>
            <ReportsNotificationWithFetchContainer hideStatus={isExpanded} />
          </Styles.Notification>
        ) : null}
      </Styles.TopLevelItem>
      {hasChildren && (
        <LinkList ref={subList} isExpanded={isExpanded}>
          {Object.entries(navItemData.children).map(
            ([childTitle, navItemData]) => {
              const isLink = typeof navItemData === 'string';
              const hasChildren = !isLink && 'children' in navItemData;
              const childTabIndex = isExpanded ? 0 : -1;

              if (hasChildren) {
                return (
                  <NavItem
                    key={childTitle}
                    currentActiveItem={currentActiveItem}
                    isDeepLevel
                    icon={icon}
                    isExpanded={deepExpanded === childTitle}
                    navItemData={navItemData}
                    onClick={() => {
                      if (deepExpanded === childTitle) {
                        setDeepExpanded(null);
                      } else {
                        setDeepExpanded(childTitle);
                      }
                    }}
                    title={t(`${'nav-items'}.${childTitle}`)}
                    topLevelTranslationKey={childTitle}
                    tabIndex={childTabIndex}
                    gatePaidFeatures={gatePaidFeatures}
                  />
                );
              } else {
                return (
                  <SubNavItem
                    childTitle={childTitle}
                    key={childTitle}
                    link={navItemData}
                    currentActiveItem={currentActiveItem}
                    scrollToActiveItem={scrollToActiveItem}
                    tabIndex={childTabIndex}
                    gatePaidFeatures={gatePaidFeatures}
                  />
                );
              }
            },
          )}
        </LinkList>
      )}
    </Styles.ItemMargin>
  );
};

NavItem.propTypes = {
  currentActiveItem: object,
  icon: elementType,
  isExpanded: bool,
  isDeepLevel: bool,
  navItemData: oneOfType([object, string]).isRequired,
  onClick: func,
  title: string.isRequired,
  topLevelTranslationKey: string.isRequired,
  tabIndex: number,
  gatePaidFeatures: bool,
};

export default NavItem;
