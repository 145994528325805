import EventLinks from '@components/event-links/event-links-container';
import EventProgressContainer from '@components/event-progress/event-progress-container';
import ContentWrapper from '@components/wrappers/content';
import Alerts from '@features/alerts/alerts';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import TurnoutBannerComponent from '@features/overview/analytics/components/turnout-banner-component';
import EventAnalyticsOverview from '@features/overview/analytics/event-analytics-overview';
import { Grid } from '@hopin-team/ui-grid';
import { calcSpace } from '@hopin-team/ui-symbols';
import * as P from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import EventActionCards from './suggestions/event-action-cards';

const useFailedSectionsAlert = () => {
  const { t } = useLocalization('overview');
  const { addAlert } = React.useContext(AlertsContext);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const showErrorAlert = !!urlSearchParams.get('failed_sections');
    const showPublishedNotification = !!urlSearchParams.get('published');
    const url = new URL(window.location.href);

    if (showPublishedNotification) {
      addAlert({
        active: true,
        text: t('alerts.event-published'),
        pattern: 'success',
      });

      url.searchParams.delete('published');
    }

    if (showErrorAlert) {
      addAlert({
        active: true,
        text: t('alerts.event-creation-failed-sections'),
        pattern: 'error',
      });

      url.searchParams.delete('failed_sections');
    }

    if (showPublishedNotification || showErrorAlert) {
      history.pushState(null, document.title, url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GridComponent = ({ event }) => (
  <Grid
    theme={{ breakpoints: ['64rem'] }}
    gridTemplateColumns={['1fr', 'repeat(6, 1fr)']}
    gridGap={calcSpace(3)}
    my={event.event_wizard_template && !event.finished ? 0 : 3}
  >
    <Grid theme={{ breakpoints: ['64rem'] }} gridColumn={['1', '1 / -3']}>
      {event.finished && <TurnoutBannerComponent eventSlug={event.slug} />}
      {!event.finished && !event.event_wizard_template && (
        <EventProgressContainer eventId={event.external_id} />
      )}
    </Grid>
    <Grid theme={{ breakpoints: ['64rem'] }} gridColumn={['1', '-3 / -1']}>
      {(event.finished || !event.event_wizard_template) && (
        <EventLinks eventId={event.external_id} />
      )}
    </Grid>
  </Grid>
);

GridComponent.propTypes = {
  event: P.shape({
    event_wizard_template: P.string,
    external_id: P.string.isRequired,
    finished: P.bool.isRequired,
    slug: P.string.isRequired,
  }).isRequired,
};

const OverviewComponent = ({
  user,
  event,
  eventActionCards,
  stripeUrl,
  newRegistrationsUrl,
  newRegistrationsEnabled,
}) => {
  const { t } = useLocalization('overview');
  useFailedSectionsAlert();

  return (
    <>
      <Helmet>
        <title>{t('metaTitle')}</title>
      </Helmet>
      <ContentWrapper data-testid="overview-component">
        <Alerts />

        <GridComponent event={event} />

        {!event.finished && (
          <EventActionCards
            user={user}
            eventActionCards={eventActionCards}
            event={event}
            stripeUrl={stripeUrl}
            newRegistrationsUrl={newRegistrationsUrl}
            newRegistrationsEnabled={newRegistrationsEnabled}
          />
        )}

        <EventAnalyticsOverview eventSlug={event.slug} />
      </ContentWrapper>
    </>
  );
};

OverviewComponent.propTypes = {
  user: P.shape({
    first_name: P.string.isRequired,
  }).isRequired,
  event: P.shape({
    event_wizard_template: P.string,
    external_id: P.string.isRequired,
    finished: P.bool.isRequired,
    slug: P.string.isRequired,
  }).isRequired,
  eventActionCards: P.arrayOf(
    P.shape({
      card_type: P.string.isRequired,
      required: P.bool.isRequired,
      completed: P.bool.isRequired,
    }),
  ).isRequired,
  stripeUrl: P.string.isRequired,
  newRegistrationsUrl: P.string.isRequired,
  newRegistrationsEnabled: P.bool.isRequired,
};

export default OverviewComponent;
