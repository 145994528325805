import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Icon } from '@hopin-team/ui-icons';
import { string } from 'prop-types';
import React, { useContext } from 'react';

import { CopyButton, Link, LinkWrapper, Wrapper } from './link-field-styles';

const LinkField = ({ link, label }) => {
  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization('overview.event-links.modal');

  const labelText = t(`link-labels.${label}`);

  const handleCopy = () => {
    if (link) {
      navigator.clipboard.writeText(link);
      addAlert({
        active: true,
        text: t('link-copied', {
          linkType: labelText.replace('link', ''),
        }),
        pattern: 'success',
      });
    }
  };

  return (
    <>
      <FieldLabel
        fieldId="link-field"
        color="grey-800"
        mt={3}
        label={labelText}
      />
      <Wrapper>
        <LinkWrapper>
          <Link id="link-field" data-testid="link-field-text">
            {link}
          </Link>
        </LinkWrapper>
        <CopyButton onClick={handleCopy} data-testid="link-field-copy">
          <Icon
            icon="copy"
            title="Copy"
            color="grey-white"
            width="24"
            scale={2}
          />
        </CopyButton>
      </Wrapper>
    </>
  );
};

LinkField.propTypes = {
  label: string,
  link: string,
};
export default LinkField;
