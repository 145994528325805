import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import { TextField } from '@hopin-team/ui-text-field';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  height: 100%;
`;

export const CenterPanel = styled.div`
  grid-column: 2/4;
`;

export const EmailFormWrapper = styled(CenterPanel)`
  margin: 1.5rem auto;
  height: 100%;

  h4 {
    font-size: var(--font-size-heading-4);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-tight);
  }

  label {
    font-weight: 500;
    color: var(--color-gray-650);
  }

  ${TextField} {
    &:nth-child(3) {
      grid-column: 1/3;
    }
  }
`;

export const CustomizeEmailsPrimaryButton = styled(Button)`
  margin-left: 1rem;
  padding: 0.5rem 1.25rem;
  line-height: 1.5rem;

  ${Text} {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const CustomizeEmailsSecondaryButton = styled(Button)`
  margin-right: 1rem !important;
  padding: 0.5rem 1.25rem;
  line-height: 1.5rem;
  color: var(--color-blue-400);

  ${Text} {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const StickyDiv = styled.div`
  height: 4.5rem;
  padding: 1rem 1.5rem;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0 !important;
  border-top: 1px solid var(--color-gray-300);
  background-color: var(--color-white);

  button {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto 0 auto auto;
    height: auto;
  }
`;

export const LeftPanel = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const RightPanel = styled.div`
  margin: auto 0 auto auto;
  display: flex;
  justify-content: flex-end;
`;
