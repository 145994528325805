import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Spinner } from '@hopin-team/ui-spinner';
import PropTypes from 'prop-types';
import React from 'react';

import { Footer } from './modal-footer.style';

export const ModalFooter = ({
  cancelClick,
  nextClick,
  nextText,
  nextLeadingIcon,
  disabled,
  loading,
}) => {
  const { t } = useLocalization('expo.bulk-upload-modal');

  return (
    <Footer justifyContent="end" alignContent="center" px={2}>
      <Button
        isOutlined
        pattern="secondary"
        isInline
        mx={1}
        my={3}
        onClick={cancelClick}
        disabled={loading}
      >
        {t('cancel')}
      </Button>

      {loading ? (
        <Button isInline mx={1} my={3}>
          <Spinner isShowing />
        </Button>
      ) : (
        <Button
          isInline
          mx={1}
          my={3}
          onClick={nextClick}
          disabled={disabled || loading}
          {...(nextLeadingIcon && { leadingIcon: nextLeadingIcon })}
        >
          {nextText}
        </Button>
      )}
    </Footer>
  );
};

ModalFooter.propTypes = {
  cancelClick: PropTypes.func,
  nextClick: PropTypes.func.isRequired,
  nextText: PropTypes.string.isRequired,
  nextLeadingIcon: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
