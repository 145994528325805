import eventDashboardApi from '@api/event-dashboard';
import AlertsProvider from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import { withLocalizationProvider } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import camelizeKeys from '@util/camelize-keys';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import { compose } from 'lodash/fp';
import { string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMarketingSettings } from '@/redux/reducers/marketing-settings';
import withReduxProvider from '@/redux/with-provider';

import MarketingSettingsComponent from './marketing-settings-component';

const MarketingSettingsContainer = ({ eventId }) => {
  const logger = getLogger(LOGGER_NAMES.MARKETING_SETTINGS);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      logger.info(
        `Fetching marketing settings for event with external ID: ${eventId}`,
      );
      try {
        const response = await eventDashboardApi.getMarketingSettings(eventId);
        dispatch(setMarketingSettings(camelizeKeys(response.data)));
      } catch (err) {
        logger.error(err);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const { id, attributes } = useSelector(
    ({ marketingSettings }) => marketingSettings,
  );
  const isLoading = !id;

  return (
    <AlertsProvider>
      {isLoading ? (
        <Flex justifyContent="center" my={2}>
          <Spinner isShowing pattern="jumbo" />
        </Flex>
      ) : (
        <MarketingSettingsComponent id={id} attributes={attributes} />
      )}
    </AlertsProvider>
  );
};

MarketingSettingsContainer.propTypes = {
  eventId: string.isRequired,
};

export default compose(
  withThemeProvider,
  withErrorHandling({ errorPattern: 'page' }),
  withReduxProvider,
  withLocalizationProvider,
)(MarketingSettingsContainer);
