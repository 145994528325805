import { useLocalization } from '@features/localization';
import { bool, func, string } from 'prop-types';
import React from 'react';

import {
  Description,
  Radio,
  RolesContainer,
  RoleTile,
  Wrapper,
} from './styles';

const Role = ({ value, title, description, selected, onClick }) => (
  <RoleTile
    onClick={onClick}
    selected={selected}
    data-testid={'admin-role-option-' + value}
    className={selected && 'selected'}
  >
    <div>
      <Radio selected={selected} />
    </div>
    <div>
      <h4>{title}</h4>
      <Description>{description}</Description>
    </div>
  </RoleTile>
);

const AdminRoleSelector = ({ adminRole, onRoleClick }) => {
  const { t } = useLocalization('admin-role-selector');
  const roles = [
    {
      value: 'event_admin',
      title: t('event-admin.title'),
      description: t('event-admin.description'),
    },
    {
      value: 'organization_admin',
      title: t('organization-admin.title'),
      description: t('organization-admin.description'),
    },
    // Waiting for BE implementation
    // {
    //   value: 'global_admin',
    //   title: 'Global admin',
    //   description: `All organization permissions PLUS:
    //   Create and delete organizations.`,
    // },
  ];

  return (
    <Wrapper>
      <RolesContainer>
        {roles.map(({ value, title, description }) => (
          <Role
            key={value}
            value={value}
            title={title}
            description={description}
            selected={adminRole === value}
            onClick={() => onRoleClick(value)}
          />
        ))}
      </RolesContainer>
    </Wrapper>
  );
};

Role.propTypes = {
  value: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  selected: bool.isRequired,
  onClick: func,
};

AdminRoleSelector.propTypes = {
  adminRole: string.isRequired,
  onRoleClick: func.isRequired,
};

export default AdminRoleSelector;
