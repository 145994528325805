import Alerts from '@features/alerts/alerts';
import { node, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

export default function PageLayout({ title, children }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Alerts />
      {children}
    </>
  );
}

PageLayout.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
};
