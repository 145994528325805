import { Grid } from '@hopin-team/ui-grid';
import { calcSpace } from '@hopin-team/ui-symbols';
import { array, bool } from 'prop-types';
import React from 'react';

import MetricCard from '../../common/metric-card';

const PreEventTopMetrics = ({ metrics, isLoading }) => {
  return (
    <Grid
      gridTemplateColumns="repeat(2, 1fr)"
      gridTemplateRows="auto"
      gridGap={calcSpace(2)}
      my={2}
    >
      {metrics.map((props, i) => (
        <MetricCard key={i} {...props} isLoading={isLoading} />
      ))}
    </Grid>
  );
};

PreEventTopMetrics.propTypes = {
  metrics: array.isRequired,
  isLoading: bool.isRequired,
};

export default PreEventTopMetrics;
