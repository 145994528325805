import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { calcSpace, COLOR, LINE } from '@hopin-team/ui-symbols';
import { bool, func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: ${calcSpace(8)};
`;

const FixedBar = styled.div`
  padding: ${calcSpace(2)} ${calcSpace(3)};
  border-top: ${LINE.DEFAULT_BORDER};
  position: fixed;
  width: calc(100% - 15rem);
  left: 15rem;
  bottom: 0;
  background-color: ${COLOR.GREY.WHITE};
  z-index: 2;

  @media (max-width: 768px) {
    padding-left: ${calcSpace(2)};
    padding-right: ${calcSpace(2)};
  }
`;

const Container = styled.div`
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
`;

const SaveBar = ({
  buttonDisabled,
  buttonText,
  onButtonClick = () => {},
  isLoading = false,
  children,
  contentReplacedByChildren,
}) => {
  const { t } = useLocalization();

  return (
    <Wrapper>
      <FixedBar>
        <Container>
          <Flex alignItems="center">
            {!contentReplacedByChildren && (
              <Button
                data-testid="save-bar-button"
                isInline={true}
                onClick={onButtonClick}
                disabled={buttonDisabled}
              >
                <Spinner isShowing={isLoading} />
                {!isLoading && (buttonText || t('save'))}
              </Button>
            )}
            {children}
          </Flex>
        </Container>
      </FixedBar>
    </Wrapper>
  );
};

SaveBar.propTypes = {
  buttonDisabled: bool,
  buttonText: string,
  onButtonClick: func,
  children: node,
  isLoading: bool,
  contentReplacedByChildren: bool,
};

export default withLocalizationProvider(SaveBar);
