import { LocalizationContext } from '@features/localization';
import { Butticon } from '@hopin-team/ui-butticon';
import {
  Menu,
  MenuContent,
  MenuSelect,
  MenuSelectItem,
} from '@hopin-team/ui-menu';
import { arrayOf, func, number, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useLayer } from 'react-laag';
import styled from 'styled-components';

export const CustomMenu = styled(Menu)`
  z-index: 1;
`;

export const AttendeesListActionMenu = ({ id, actions }) => {
  const { t } = useContext(LocalizationContext);

  const [isVisible, setVisibility] = useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: isVisible,
    container: `menu-${id}`,
    onOutsideClick: () => setVisibility(false),
    onDisappear: () => setVisibility(false),
    overflowContainer: true,
    auto: true,
    placement: 'bottom-end',
    possiblePlacements: ['bottom-end'],
    preferX: 'left',
    triggerOffset: 5,
    containerOffset: -5,
  });

  return (
    <div id={`menu-${id}`}>
      <>
        <Butticon
          icon="option"
          title={t('people.attendees.attendees-tab.details.actions.menu.title')}
          data-testid={`edit-row-${id}`}
          onClick={() => setVisibility(isOpen => !isOpen)}
          {...triggerProps}
        />
        {isVisible &&
          renderLayer(
            <CustomMenu
              isInline={true}
              isShowing={isVisible}
              onClose={() => setVisibility(false)}
              {...layerProps}
            >
              <MenuContent px={1} py={0.5}>
                <MenuSelect labelledById="menu">
                  {actions.map(action => (
                    <MenuSelectItem
                      px={1.5}
                      py={0.5}
                      my={0.5}
                      key={action.label}
                      {...action}
                    />
                  ))}
                </MenuSelect>
              </MenuContent>
            </CustomMenu>,
          )}
      </>
    </div>
  );
};

AttendeesListActionMenu.propTypes = {
  id: number.isRequired,
  actions: arrayOf(
    shape({
      label: string,
      leadingIcon: string,
      onClick: func,
    }),
  ).isRequired,
};
