import DE from './de.json';
import EN from './en.json';
import ES from './es.json';
import FR from './fr.json';
import IT from './it.json';
import JA from './ja.json';
import KO from './ko.json';
import NL from './nl.json';
import PL from './pl.json';
import PT from './pt.json';

export { DE, EN, ES, FR, IT, JA, KO, PT, PL, NL };
