import DownloadToIcon from '@components/icons/download-to';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { COLOR, LINE } from '@hopin-team/ui-symbols';
import { arrayOf, func, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 10em;
`;
const MenuContainer = styled.div`
  position: absolute;
  z-index: 1;
  background: ${COLOR.GREY.WHITE};
  width: 100%;
  padding-bottom: 4px;
  border: ${LINE.DEFAULT_BORDER};
`;

const WhiteButton = styled(Button)`
  background-color: ${COLOR.GREY.WHITE};
  border: ${LINE.DEFAULT_BORDER};
  color: ${COLOR.GREY[700]};
  border-radius: 4px;
  &:hover,
  &.open {
    border: ${LINE.ACTIVE_BORDER};
    background-color: ${COLOR.GREY.WHITE};
    color: ${COLOR.GREY[700]};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  border-radius: 4px;
  margin: 4px 4px 0 4px;
  padding: 2px 6px;
  &:nth-child(2n + 1) { background-color: ${COLOR.GREY[300]}; }
  &:hover { var(--font-weight-medium); }
`;

const ExportButtonComponent = ({ supportedFormats, triggerExport }) => {
  const { t } = useContext(LocalizationContext);
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Container>
      <WhiteButton
        className={menuOpen ? 'open' : 'closed'}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <DownloadToIcon />
        {t('event-dashboard.export')}
      </WhiteButton>
      {menuOpen && (
        <MenuContainer>
          {supportedFormats.map(formatName => {
            return (
              <MenuItem
                key={formatName}
                onClick={() => {
                  setMenuOpen(false);
                  triggerExport(formatName);
                }}
              >
                {formatName}
              </MenuItem>
            );
          })}
        </MenuContainer>
      )}
    </Container>
  );
};

ExportButtonComponent.propTypes = {
  supportedFormats: arrayOf(string).isRequired,
  triggerExport: func.isRequired,
};

export default ExportButtonComponent;
