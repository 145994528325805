import { Select } from '@components/inputs';
import { SelectLabel } from '@components/select/select-styles';
import { AlertsContext } from '@features/alerts/alerts-provider';
import timezones from '@features/constants/timezones';
import { ConfirmModal } from '@features/email/announcements-list/announcements-table/confirm-modal';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { DateField } from '@hopin-team/ui-date-field';
import { addMinutes, isBefore, isValid } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { bool, func, instanceOf, shape, string } from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';

import { timezonesMap } from '../../constants/timezones-map';
import {
  AnnouncementModalContent,
  AnnouncementModalFooter,
  AnnouncementModalHeader,
  AnnouncementsText,
  SecondaryActionButton,
} from '../styles';
import {
  EditScheduleModalContainer,
  ScheduleEmailInputContainer,
} from './styles';

export const EditScheduleEmailModal = ({
  event,
  isModalDisplayed,
  onCancel,
  onSubmit,
  date,
  timezone,
  canSend,
  onSendNow,
}) => {
  const { t } = useContext(LocalizationContext);
  const { addAlert } = useContext(AlertsContext);
  const translationKeyPrefix = 'email-dashboard.schedule-email';
  const [selectedDate, setSelectedDate] = useState(
    date
      ? utcToZonedTime(date, timezonesMap[timezone])
      : addMinutes(new Date(), 30),
  );
  const [sendNowConfirm, setSendNowConfirm] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(
    timezones().find(({ value }) => (timezone || event.timezone) === value),
  );

  const handleClick = () => {
    const error = isInvalidDate();
    if (!error) {
      onSubmit(
        zonedTimeToUtc(selectedDate, timezonesMap[selectedTimezone.value]),
        selectedTimezone.value,
      );
    } else if (
      canSend &&
      error === t(`${translationKeyPrefix}.next-15min-error`)
    ) {
      setSendNowConfirm(true);
    } else {
      addAlert({
        active: true,
        text: error,
        pattern: 'error',
      });
    }
  };

  const isInvalidDate = () => {
    if (!selectedTimezone?.value) {
      return t(`${translationKeyPrefix}.invalid-timezone-error`);
    }
    if (!selectedDate || !isValid(new Date(selectedDate))) {
      return t(`${translationKeyPrefix}.invalid-date-error`);
    }

    // selectedDate is returned as a Date with the browser timezone
    // which needs to be replaced with the selected timezone
    // to produce the correct UTC time value
    const selectedTime = zonedTimeToUtc(
      selectedDate,
      timezonesMap[selectedTimezone.value],
    );
    const currentTime = new Date();

    const isPast = isBefore(selectedTime, currentTime);
    const isNext15Mins =
      !isPast && isBefore(selectedTime, addMinutes(currentTime, 15));

    if (isPast) {
      return t(`${translationKeyPrefix}.past-date-error`);
    } else if (isNext15Mins) {
      return t(`${translationKeyPrefix}.next-15min-error`);
    } else {
      return '';
    }
  };

  const onDateChange = useCallback(
    value => {
      value && setSelectedDate(value);
    },
    [setSelectedDate],
  );

  return (
    <>
      <EditScheduleModalContainer
        describedById="modal-header"
        isShowing={isModalDisplayed}
        onClose={onCancel}
        isDismissible={false}
        withCloseButton
        label={t('email-dashboard.schedule-email.title')}
        size="medium"
      >
        <AnnouncementModalHeader>
          <AnnouncementsText>
            {t('email-dashboard.schedule-email.title')}
          </AnnouncementsText>
        </AnnouncementModalHeader>
        <AnnouncementModalContent
          backgroundColor="gray-100"
          data-testid="edit-schedule-email-modal"
        >
          <ScheduleEmailInputContainer>
            <SelectLabel>
              {t('email-dashboard.schedule-email.pick-a-date')}
            </SelectLabel>
            <DateField
              name="date"
              id="date"
              withTime
              setDate={onDateChange}
              date={selectedDate}
              data-testid="datetime-select"
            />
          </ScheduleEmailInputContainer>
          <ScheduleEmailInputContainer>
            <SelectLabel>
              {t('email-dashboard.schedule-email.timezone')}
            </SelectLabel>
            <Select
              id="timezone"
              name="timezone"
              defaultValue={
                timezones().find(
                  ({ value }) => selectedTimezone.value === value,
                )?.value
              }
              onChange={({ target: { value } }) =>
                setSelectedTimezone(timezones().find(tz => tz.value === value))
              }
              data-testid="timezone-select"
            >
              {timezones().map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </ScheduleEmailInputContainer>
        </AnnouncementModalContent>
        <AnnouncementModalFooter>
          <SecondaryActionButton onClick={onCancel} data-testid="cancel-button">
            {t(`cancel`)}
          </SecondaryActionButton>
          <Button
            isInline
            size="small"
            onClick={handleClick}
            data-testid="schedule-email-button-modal"
          >
            {t('save')}
          </Button>
        </AnnouncementModalFooter>
      </EditScheduleModalContainer>
      <ConfirmModal
        label="Invalid schedule time modal"
        title={t('email-dashboard.schedule-email.invalid-schedule-time')}
        message={t('email-dashboard.schedule-email.change-or-send-now')}
        open={sendNowConfirm}
        onCancel={() => setSendNowConfirm(false)}
        onConfirm={() => onSendNow?.()}
        cancelButtonProps={{
          label: t('email-dashboard.schedule-email.change-date-time'),
        }}
        confirmButtonProps={{
          label: t('email-dashboard.schedule-email.send-now'),
        }}
      />
    </>
  );
};

EditScheduleEmailModal.propTypes = {
  event: shape({
    timezone: string.isRequired,
  }).isRequired,
  isModalDisplayed: bool.isRequired,
  onSubmit: func.isRequired,
  onCancel: func,
  onSendNow: func,
  date: instanceOf(Date),
  timezone: string,
  canSend: bool,
};
