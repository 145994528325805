import { Input } from '@components/inputs';
import { Button } from '@hopin-team/ui-button';
import styled from 'styled-components';

export const Label = styled.label`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--color-text);
`;

export const Field = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  ${Label} {
    display: block;
  }
`;

export const DetailsFields = styled.div`
  @media (min-width: 800px) {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  ${Field}, ${Input} {
    margin-bottom: 0;
  }
`;

export const Error = styled.span`
  color: var(--color-red-400);
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  ${Button} {
    display: inline-block;
    width: auto;
  }
  ${Button} + ${Button} {
    margin-left: 8px;
  }
`;
