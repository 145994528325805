import CKEditor5 from '@components/ckeditor5/classic-editor';
import { Label } from '@components/inputs';
import {
  withLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { Grid } from '@hopin-team/ui-grid';
import { calcSpace } from '@hopin-team/ui-symbols';
import { TextField } from '@hopin-team/ui-text-field';
import CKEditor4 from 'ckeditor4-react';
import compose from 'lodash/fp/compose';
import { array, bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import AnnouncementsContainer from '../announcements-container';
import { StatusSelect, TicketSelect } from './audience-select';
import { AudienceTargeting } from './audience-targeting';
import { CreateTickets } from './create-tickets';
import PersonalisationTagsPanel from './personalisation-tags-panel';
import { AnnouncementEmailFormWrapper, CKEditorWrapper } from './styles';

export const EditorContext = React.createContext({});

const Editor = ({
  eventSlug,
  t,
  announcement,
  handleFieldChange,
  tickets,
  registrationsDashboardUrl,
  ckeditor5Enabled,
}) => {
  const translationKeyPrefix = 'email-dashboard';
  const { register, setValue, watch, errors, setError } = useFormContext();

  const handleCKEditorChange = editor => {
    const data = editor.getData();
    setValue('body', data);
    !data.length &&
      setError('body', {
        message: `Body ${t(
          `${translationKeyPrefix}.list.errors.minimum-character`,
        )}`,
      });
    handleFieldChange();
  };

  const handleFileUpload = event => {
    // Workaround for drag & drop not firing change event in CKEditor
    // which is required to insert image HTML and bind data
    setTimeout(function () {
      event.editor.fire('change');
    }, 0);
  };

  const selectedStatus = watch('attendeeStatus');
  const selectedTicketTypes = watch('ticketTypes');
  const maxFileSize = 10485760;

  return (
    <EditorContext.Provider
      value={{
        tickets,
        selectedStatus,
        selectedTicketTypes,
      }}
    >
      <AnnouncementsContainer>
        <AnnouncementEmailFormWrapper data-testid="editor-container">
          <>
            <h4 data-testid="audience-selection-header">
              {t(`${translationKeyPrefix}.edit.audience_selection`)}
            </h4>
            <Grid
              gridColumnGap={2}
              gridGap={[calcSpace(2), calcSpace(3)]}
              my={3}
              gridTemplateColumns="1fr 2fr"
              gridTemplateRows="auto"
            >
              <div data-testid="audience-selection-attendee-status-select">
                <Label htmlFor="attendeeStatus">
                  {t(`${translationKeyPrefix}.edit.audience`)}
                </Label>
                <Controller
                  name="attendeeStatus"
                  defaultValue="registered"
                  render={({ value, onChange }) => (
                    <StatusSelect
                      isDisabled={!tickets.length}
                      value={value}
                      onChange={value => {
                        onChange(value);
                        handleFieldChange();
                      }}
                    />
                  )}
                ></Controller>
              </div>
              <div data-testid="audience-selection-ticket-types-select">
                <Label htmlFor="ticketTypes">
                  {t(`${translationKeyPrefix}.edit.ticket_type`)}
                </Label>
                <Controller
                  name="ticketTypes"
                  defaultValue={[]}
                  render={({ value, onChange }) => (
                    <TicketSelect
                      tickets={tickets}
                      status={selectedStatus}
                      onChange={selected => {
                        onChange(selected);
                        handleFieldChange();
                      }}
                      value={value}
                    />
                  )}
                />
              </div>
            </Grid>
            {!tickets.length ? (
              <CreateTickets
                eventSlug={eventSlug}
                registrationsDashboardUrl={registrationsDashboardUrl}
              ></CreateTickets>
            ) : (
              <AudienceTargeting />
            )}
          </>
          <h4>{t(`${translationKeyPrefix}.edit.email_details`)}</h4>
          <div>
            <TextField
              errorMessage={errors.name?.message}
              hasErrors={errors.name?.message}
              data-testid="template-name"
              label={t(`${translationKeyPrefix}.list.headers.email`)}
              name="name"
              ref={register({
                required: `${t(
                  `${translationKeyPrefix}.list.headers.email`,
                )} ${t(
                  `${translationKeyPrefix}.list.errors.minimum-character`,
                )}`,
              })}
              type="text"
              onChange={handleFieldChange}
              placeholder={t(
                `${translationKeyPrefix}.edit.template-name-placeholder`,
              )}
              my={3}
            />
            <TextField
              errorMessage={errors.subject?.message}
              hasErrors={errors.subject?.message}
              data-testid="email-subject"
              label={t(`${translationKeyPrefix}.list.headers.subject`)}
              name="subject"
              ref={register({
                required: `${t(
                  `${translationKeyPrefix}.list.headers.subject`,
                )} ${t(
                  `${translationKeyPrefix}.list.errors.minimum-character`,
                )}`,
              })}
              type="text"
              onChange={handleFieldChange}
              placeholder={t(
                `${translationKeyPrefix}.edit.subject-placeholder`,
              )}
              my={3}
            />
            <TextField
              data-testid="email-preheader"
              label={t(`${translationKeyPrefix}.template.preview-text`)}
              name="previewText"
              ref={register({})}
              type="text"
              onChange={handleFieldChange}
              my={3}
            />
            <div>
              <Label htmlFor="templateBody" my={3}>
                {t(`${translationKeyPrefix}.edit.body`)}
              </Label>
              {ckeditor5Enabled ? (
                <CKEditorWrapper>
                  <CKEditor5
                    name="template-body"
                    initialValue={announcement.body}
                    onChange={(_, editor) => handleCKEditorChange(editor)}
                    height={35}
                    maxFileSize={maxFileSize}
                    uploadS3PolicyUrl={`${window.location.origin}/account/upload_s3_policy`}
                  />
                </CKEditorWrapper>
              ) : (
                <CKEditor4
                  config={{
                    height: 550,
                    allowedContent: true,
                    filebrowserImageBrowseLinkUrl: '/ckeditor/pictures',
                    filebrowserImageUploadUrl: '/ckeditor/pictures?',
                    filebrowserImageBrowseUrl: '/ckeditor/pictures',
                    filebrowserUploadUrl: '/ckeditor/attachment_files',
                    filebrowserBrowseUrl: '/ckeditor/attachment_files',
                    filebrowserUploadMethod: 'form',
                    filebrowserImageUploadMethod: 'form',
                    htmlEncodeOutput: false,
                    entities: false,
                    versionCheck: false,
                  }}
                  data={announcement.body}
                  onChange={event => handleCKEditorChange(event.editor)}
                  onFileUploadResponse={handleFileUpload}
                />
              )}
              {errors.body?.message && (
                <FieldErrorMessage py={1} errorMessage={errors.body.message} />
              )}

              <input
                hidden
                data-testid="email-body"
                name="body"
                ref={register({})}
              />
            </div>
          </div>
        </AnnouncementEmailFormWrapper>
        {!ckeditor5Enabled && <PersonalisationTagsPanel />}
      </AnnouncementsContainer>
    </EditorContext.Provider>
  );
};

Editor.propTypes = {
  eventSlug: string,
  registrationsDashboardUrl: string,
  handleFieldChange: func,
  t: func,
  announcement: shape({
    body: string,
    footer: string,
  }),
  tickets: array,
  ckeditor5Enabled: bool,
};

export default compose(withLocalizationProvider, withLocalization)(Editor);
