import hopinApi from '@api/hopin';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useEngagementPagination = (
  eventId,
  itemsPerPage = 5,
  initialPage = 0,
) => {
  const [items, setItems] = useState([]);
  const totalRecordsRef = useRef(itemsPerPage);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const response = await hopinApi.getEngagementScoreWithPagination(
          eventId,
          itemsPerPage,
          page,
        );
        if (response.count) totalRecordsRef.current = response.count;
        setItems(prevItems => [...prevItems, ...response.items]);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [page, itemsPerPage, eventId]);

  const showMore = useCallback(() => {
    setPage(prevPage => prevPage + 1);
  }, []);

  return { error, items, loading, totalRecordsRef, showMore };
};
