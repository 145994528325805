import eventWizardApi from '@api/event-wizard';
import hopinApi from '@api/hopin';
import { navigationViews } from '@features/nav/constants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  previousView: navigationViews.SIMPLIFIED,
  view: navigationViews.SIMPLIFIED,
};

export const toggleNavigationView = createAsyncThunk(
  'navigationView/toggleValue',
  async ({ eventId, value }) => {
    const { token } = await hopinApi.getUserToken();

    const response = await eventWizardApi.updateNavigationView(
      token,
      eventId,
      value,
    );
    return response;
  },
);

const { reducer } = createSlice({
  name: 'navigationView',
  initialState,
  extraReducers: builder => {
    builder.addCase(toggleNavigationView.fulfilled, (state, action) => {
      const view = action.payload.navigation_view;
      state.previousView = view;
      state.view = view;
    });
    builder.addCase(toggleNavigationView.pending, (state, action) => {
      state.previousView = state.view;
      state.view = action.meta.arg.value;
    });
    builder.addCase(toggleNavigationView.rejected, (state, action) => {
      state.view = state.previousView;
      state.previousView = action.meta.arg.value;
    });
  },
});

export default reducer;
