import * as Routes from '@routes';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';

import { AREA_TYPE, EVENT_PART } from '../constants';
import track from './track';

const DOWNLOAD_EVENT_NAME = 'Recording Downloaded';
const PUBLISH_EVENT_NAME = 'Recording Publish Status Updated';
const RECORDING_TRIMMED_EVENT = 'Recording Trimmed';

const ID_KEY_BY_EVENT_TYPE = {
  session: 'session_id',
  stage: 'stage_id',
};

const VIDEO_EDIT_STATUSES = {
  success: 'success',
  failure: 'failure',
  processing: 'processing',
};

export const reportRecordingDownloadToAnalytics = (
  recording,
  { userId, eventId, method },
) => {
  const idKey = ID_KEY_BY_EVENT_TYPE[recording.eventPart.type];

  const payload = {
    user_id: userId,
    recording_type: recording.eventPart.type.toLowerCase(),
    recording_id: recording.id || '',
    [idKey]: recording.eventPart.externalId || '',
    event_id: eventId,
    method,
  };

  track(DOWNLOAD_EVENT_NAME, payload);
};

export const reportRecordingPublishStatusUpdated = (
  recording,
  { userId, eventId, isPublished },
) => {
  const idKey = ID_KEY_BY_EVENT_TYPE[recording.eventPart.type];

  const payload = {
    recording_id: recording.id,
    recording_type: recording.eventPart.type,
    recording_priority: getRecordingPriority(recording),
    [idKey]: recording.eventPart.externalId,
    is_published: isPublished,
    event_id: eventId,
    user_id: userId,
  };

  track(PUBLISH_EVENT_NAME, payload);
};

export const reportTrimmingAction = (
  recording,
  {
    userId,
    trimmingStatus,
    trimmingStartTime,
    trimmingEndTime,
    status,
    userTrimmingDuration,
  },
) => {
  const idKey = ID_KEY_BY_EVENT_TYPE[recording.eventPart.type];

  const payload = {
    recording_id: recording.id || '',
    recording_priority: getRecordingPriority(recording) || '',
    [idKey]: recording.eventPart.externalId || '',
    user_id: userId,
    trimming_status: trimmingStatus,
    trimming_start_time: trimmingStartTime,
    trimming_end_time: trimmingEndTime,
    status,
    user_trimming_duration: userTrimmingDuration,
  };

  track(RECORDING_TRIMMED_EVENT, payload);
};

export const hasRecordingEdits = recording => !!recording?.lastEdit;

export const hasRecordingSucceededOnTrimming = recording =>
  recording.lastEdit?.status === VIDEO_EDIT_STATUSES.success ?? false;

export const hasRecordingFailed = recording =>
  recording.lastEdit?.status === VIDEO_EDIT_STATUSES.failure ?? false;

export const sortRecordings = recordings => {
  if (!recordings || !recordings.length) {
    return [];
  }

  const SORT_A_BEFORE_B = -1;
  const SORT_B_BEFORE_A = 1;
  const KEEP_SORTING = 0;

  return recordings.sort((a, b) => {
    if (!a.startedAt && !b.startedAt) {
      return KEEP_SORTING;
    } else if (!a.startedAt && b.startedAt) {
      return SORT_B_BEFORE_A;
    } else if (a.startedAt && !b.startedAt) {
      return SORT_A_BEFORE_B;
    }

    const aStartedAt = new Date(a.startedAt);
    const bStartedAt = new Date(b.startedAt);
    return bStartedAt.getTime() - aStartedAt.getTime();
  });
};

export const filterRecordings = (recordings, filters) => {
  if (!filters) {
    return recordings;
  }

  return recordings
    .filter(
      recording =>
        !filters.eventPart || recording.eventPart.type === filters.eventPart,
    )
    .filter(
      recording =>
        !filters.date ||
        (recording.startedAt &&
          isSameDay(new Date(recording.startedAt), new Date(filters.date))),
    )
    .filter(recording =>
      isRecordingInVideoArea(recording, filters.videoAreaId),
    );
};

export const isRecordingInVideoArea = (recording, UID) => {
  if (!UID) {
    return true;
  }

  const { type, externalId } = recording.eventPart;
  return UID === generateUid({ id: externalId, type: type.toLowerCase() });
};

export const filterVideoAreasByEventPartType = (videoAreas, eventPartType) => {
  if (!videoAreas) {
    return null;
  }

  if (!eventPartType) {
    return videoAreas;
  }

  return videoAreas.filter(
    videoArea => videoArea.type === eventPartType.toLowerCase(),
  );
};

export const getRecordingById = (recordings, recordingId) =>
  recordings.filter(recording => recording.id === recordingId)[0] || null;

const TYPES_WITH_SECONDARY_LINKS = [
  EVENT_PART.STAGE,
  EVENT_PART.SESSION,
  AREA_TYPE.STAGE,
  AREA_TYPE.SESSION,
];

export const getSecondaryLinks = (slug, videoArea) => {
  const { type } = videoArea;
  const areaQueryParams = { areaId: generateUid(videoArea) };

  let backupHref = null;
  const rehearsalHref = Routes.rehearsalOrganisersEventRecordingsPath(
    slug,
    areaQueryParams,
  );

  if (TYPES_WITH_SECONDARY_LINKS.includes(type)) {
    backupHref = Routes.backupOrganisersEventRecordingsPath(
      slug,
      areaQueryParams,
    );
  }

  return { rehearsalHref, backupHref };
};

export const getRecordingPriority = recording => {
  switch (recording.whenInTime) {
    case 'pre_event':
      return 'rehearsal';

    case 'in_event':
      return recording.rank === 'main' ? 'primary' : 'backup';

    default:
      return null;
  }
};

export const getGoBackLink = (slug, { whenInTime, rank }) => {
  if (whenInTime === 'pre_event') {
    return Routes.rehearsalOrganisersEventRecordingsPath(slug);
  }

  if (rank === 'backup') {
    return Routes.backupOrganisersEventRecordingsPath(slug);
  }

  return Routes.organisersEventRecordingsPath(slug);
};

export const generateUid = area => {
  if (!area?.type || !area?.id) return '';
  return `${area.type}_${area.id}`;
};

export const getAreaByUid = (areas, UID) => {
  if (!areas?.length || !UID) return undefined;
  return areas.find(a => UID === `${a.type}_${a.id}`);
};

export const localiseDate = (
  dateToLocaliseStr,
  language,
  settings = { month: 'short', day: 'numeric' },
) => {
  try {
    const dateTimeFormat = new Intl.DateTimeFormat(language, settings);

    const dateToLocalise = new Date(dateToLocaliseStr);
    return dateTimeFormat.format(dateToLocalise.valueOf());
  } catch (e) {
    console.warn('localiseDate: Invalid Date given', dateToLocaliseStr);
    return dateToLocaliseStr;
  }
};

export const hasDatePassed = (endTime, hoursOffset = 0) => {
  const msOffset = 1000 * 60 * 60 * hoursOffset;
  const timeSinceEndTime = new Date(endTime) - new Date();
  return timeSinceEndTime < msOffset;
};

export const createGroupId = ({ videoArea: { id, type }, when, rank }) => {
  return `${type}-${id}-${when}-${rank}`;
};

export const filterObject = ({ original, allowedKeys, disallowedKeys }) => {
  const filteredObject = Object.keys(original)
    .filter(key => (disallowedKeys ? !disallowedKeys.includes(key) : true))
    .filter(key => (allowedKeys ? allowedKeys.includes(key) : true))
    .reduce((obj, key) => {
      obj[key] = original[key];
      return obj;
    }, {});

  return filteredObject;
};

export const getDaysBetween = (dateOne, dateTwo) => {
  if (!(dateOne instanceof Date) || !(dateTwo instanceof Date)) {
    console.error('getDaysBetween: Both arguments need to be Date objects');
    return [];
  }

  const [startDate, endDate] = [
    new Date(Math.min(dateOne, dateTwo)),
    new Date(Math.max(dateOne, dateTwo)),
  ];

  const dateArray = new Array();
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(currentDate.toISOString());
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};
