import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const matchesSectionLevel = ({ level, section }) => item =>
  item.level === level && section === item.section;

/**
 * Calculated the next open position within a level and section
 *
 * @param {Array[SponsorAreas]} sponsorAreas - current sponsorsAreas
 * @param {String} level - sponsor area level (Gold/Silver/Bronze)
 * @param {Number} section - section for the sponsor area (Registration/Reception)
 *
 * @returns {Number} next position
 */
const getNextPosition = ({ sponsorAreas, level, section }) =>
  sponsorAreas
    .filter(matchesSectionLevel({ level, section }))
    .reduce((acc, { position }) => Math.max(acc, position), 0) + 1;

const deduplicateSponsors = (acc, cur) => {
  // If sponsor ID is already in collection, return existing collection
  if (acc.some(({ sponsorId }) => sponsorId === cur.sponsorId)) {
    return acc;
  }

  // Otherwise append current sponsor area to accumulator
  return acc.concat(cur);
};

const {
  actions: {
    addSponsorArea,
    removeSponsorArea,
    removeSponsorLevel,
    setSponsorAreas,
    setSponsorAreaLevel,
  },
  reducer,
} = createSlice({
  name: 'sponsorArea',
  initialState: [],
  reducers: {
    addSponsorArea: {
      reducer: (state, action) => {
        const { id, position, level, section, sponsorId } =
          action.payload || {};

        return state.concat({
          id,
          level,
          position:
            position ||
            getNextPosition({ level, section, sponsorAreas: state }),
          section,
          sponsorId,
        });
      },
      prepare: payload => ({ payload: { id: uuid(), ...payload } }),
    },
    removeSponsorArea: (state, action) =>
      state.filter(({ id }) => id !== action.payload),
    removeSponsorLevel: (state, action) =>
      state.filter(item => item.level !== action.payload),
    setSponsorAreas: (_, action) => action.payload,
    setSponsorAreaLevel: (state, action) => {
      const { level, section, items } = action.payload;
      const itemsWithPosition =
        items
          ?.reduce(deduplicateSponsors, [])
          ?.map(({ id, sponsorId }, index) => ({
            id,
            level,
            position: index,
            section,
            sponsorId,
          })) ?? [];

      return (
        state
          // Remove items in the current section & level
          .filter(item => !(item.level === level && item.section === section))
          // Append new items for the seciton and level
          .concat(itemsWithPosition)
      );
    },
  },
});

export {
  addSponsorArea,
  removeSponsorArea,
  removeSponsorLevel,
  setSponsorAreas,
  setSponsorAreaLevel,
};

/** State selectors */
export const selectSponsorAreas = state => state.sponsorAreas;
export const selectSponsorAreasForSection = section => state =>
  selectSponsorAreas(state).filter(sa => sa.section === section);

export default reducer;
