import eventDashboardApi from '@api/event-dashboard';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import camelizeKeys from '@util/camelize-keys';

export const initialState = {
  id: null,
  type: null,
  attributes: {},
  isFetching: null,
  isDeleting: false,
};

export const fetchCustomDomain = createAsyncThunk(
  'customDomain/fetch',
  async eventId => {
    const result = await eventDashboardApi.getCustomDomain(eventId);

    return result;
  },
);

export const deleteCustomDomain = createAsyncThunk(
  'customDomain/delete',
  async eventId => {
    const result = await eventDashboardApi.deleteCustomDomain(eventId);

    return result;
  },
);

const { actions, reducer } = createSlice({
  name: 'customDomain',
  initialState,
  reducers: {
    setCustomDomain: (state, action) => ({
      ...state,
      ...camelizeKeys(action.payload),
    }),
  },
  extraReducers: {
    [fetchCustomDomain.pending]: state => {
      state.isFetching = true;
    },
    [fetchCustomDomain.fulfilled]: (state, action) => {
      state.isFetching = false;

      state.type = action.payload.data.type;
      state.id = action.payload.data.id;
      state.attributes = camelizeKeys(action.payload.data.attributes);
    },
    [deleteCustomDomain.pending]: state => {
      state.isDeleting = true;
    },
    [deleteCustomDomain.fulfilled]: state => {
      state.isDeleting = false;

      state.id = initialState.id;
      state.type = initialState.type;
      state.attributes = initialState.attributes;
    },
    [deleteCustomDomain.rejected]: state => {
      state.isDeleting = false;
    },
  },
});

export const { setCustomDomain } = actions;

export default reducer;
