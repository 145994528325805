import Booth from '@components/icons/booth';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import * as Routes from '@routes';
import { string } from 'prop-types';
import React from 'react';

const NoBoothEmptyState = ({ eventSlug }) => {
  const { t } = useLocalization('registrations-dashboard.no-booth');

  return (
    <EmptyState
      button={
        <Button
          type="button"
          size="small"
          className="dashboard-button"
          href={Routes.organisersEventVendorsPath(eventSlug)}
        >
          {t('create-booth')}
        </Button>
      }
      icon={<Booth />}
      text={t('empty')}
    />
  );
};

NoBoothEmptyState.propTypes = {
  eventSlug: string.isRequired,
};

export default NoBoothEmptyState;
