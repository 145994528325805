import packageJson from '~/package.json';

const stripCaret = version => version.replace('^', '');

export const loadWithWindow = (scope, module) => {
  const container = window?.[scope];
  if (!container?.init || !container?.get) return null;

  // We use the internal dependencies to avoid reloading them for performance
  // This should auto-bootstrap what it can but it doesn't hurt to call out the 3 biggest that will always be shared
  container.init({
    react: {
      [stripCaret(packageJson?.dependencies?.react)]: {
        get: () => Promise.resolve().then(() => () => require('react')),
      },
    },
    'react-dom': {
      [stripCaret(packageJson?.dependencies?.['react-dom'])]: {
        get: () => Promise.resolve().then(() => () => require('react-dom')),
      },
    },
    'styled-components': {
      [stripCaret(packageJson?.dependencies?.['styled-components'])]: {
        get: () =>
          Promise.resolve().then(() => () => require('styled-components')),
      },
    },
  });

  return async () => container.get(module).then(factory => factory());
};
