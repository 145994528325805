// https://redux.js.org/usage/writing-tests
import hopinApi from '@api/hopin';
import { rootSaga } from '@features/recordings/redux/root-saga';
import { rootReducer } from '@features/recordings/redux/store';
import { configureStore } from '@reduxjs/toolkit';
import { render as rtlRender } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

const defaultPreloadedState = {};
const sagaMiddleware = createSagaMiddleware();

jest.mock('@api/hopin');

function renderWithStore(
  ui,
  {
    preloadedState = defaultPreloadedState,
    store = configureStore({
      reducer: rootReducer,
      preloadedState,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(sagaMiddleware),
    }),
    ...renderOptions
  } = {},
) {
  hopinApi.getUserToken.mockResolvedValue({ token: 'token' });
  sagaMiddleware.run(rootSaga);

  // eslint-disable-next-line react/prop-types
  function Wrapper({ children }) {
    return <Provider store={store}>{children}</Provider>;
  }

  return rtlRender(ui, { wrapper: Wrapper, ...renderOptions });
}

const createStore = state => {
  const preloadedState = {
    ...defaultPreloadedState,
    ...state,
  };
  return configureStore({ reducer: rootReducer, preloadedState });
};

export * from '@testing-library/react';
export { renderWithStore, createStore };
