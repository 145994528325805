import SaveBar from '@components/save-bar';
import { Button } from '@hopin-team/ui-button';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ToolBar } from './custom-domain-settings.styles';

const CustomDomainSettingsSavebar = ({
  disabled,
  isDeleteShown,
  isSaveShown,
  isDeleting,
  onConfirmDelete,
  saveDisabled,
  onConfirmSave,
  isSaveConfirmationShowing,
  hideSaveConfirmationPopup,
  isSubmitting,
  t,
}) => {
  const [isDeleteConfirmationShowing, setDeleteConfirmationShowing] = useState(
    false,
  );

  const hideDeleteConfirmationPopup = () => {
    setDeleteConfirmationShowing(false);
  };

  const showDeleteConfirmationPopup = () => {
    setDeleteConfirmationShowing(true);
  };

  if (!isSaveShown && !isDeleteShown) {
    return null;
  }

  return (
    <SaveBar contentReplacedByChildren>
      <ToolBar as={Flex}>
        {isSaveShown && (
          <Button
            isInline={true}
            size="medium"
            aria-label={t('event-dashboard.custom-domains.save')}
            px={6}
            type="submit"
            disabled={disabled || saveDisabled}
          >
            <Spinner isShowing={isSubmitting} />
            {!isSubmitting && t('event-dashboard.custom-domains.save')}
          </Button>
        )}

        {isDeleteShown && (
          <Button
            aria-label={t('event-dashboard.custom-domains.delete')}
            leadingIcon="delete"
            size="medium"
            type="button"
            pattern="danger"
            onClick={showDeleteConfirmationPopup}
            isInline={true}
            disabled={disabled}
            ml="auto"
          >
            <Spinner isShowing={isDeleting} />
            {!isDeleting && t('event-dashboard.custom-domains.delete')}
          </Button>
        )}
      </ToolBar>

      <ConfirmationDialog
        title={t('event-dashboard.custom-domains.are-you-sure')}
        message={t(
          'event-dashboard.custom-domains.are-you-sure-to-delete-text',
        )}
        primaryButtonText={t('event-dashboard.custom-domains.sure-to-delete')}
        secondaryButtonText={t('event-dashboard.custom-domains.cancel')}
        isDismissible
        withCloseButton
        colorPattern="danger"
        onClose={hideDeleteConfirmationPopup}
        onCancel={hideDeleteConfirmationPopup}
        onConfirm={() => {
          hideDeleteConfirmationPopup();
          onConfirmDelete();
        }}
        isShowing={isDeleteConfirmationShowing}
      />
      <ConfirmationDialog
        title={t('event-dashboard.custom-domains.are-you-sure')}
        message={t('event-dashboard.custom-domains.are-you-sure-to-save-text')}
        primaryButtonText={t('event-dashboard.custom-domains.sure-to-save')}
        secondaryButtonText={t('event-dashboard.custom-domains.cancel')}
        isDismissible
        withCloseButton
        onConfirm={() => {
          hideSaveConfirmationPopup();
          onConfirmSave();
        }}
        onCancel={hideSaveConfirmationPopup}
        colorPattern="primary"
        onClose={hideSaveConfirmationPopup}
        isShowing={isSaveConfirmationShowing}
      />
    </SaveBar>
  );
};

CustomDomainSettingsSavebar.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isDeleteShown: PropTypes.bool.isRequired,
  isSaveShown: PropTypes.bool.isRequired,
  saveDisabled: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hideSaveConfirmationPopup: PropTypes.func.isRequired,
  isSaveConfirmationShowing: PropTypes.bool.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onConfirmSave: PropTypes.func.isRequired,
};

export default CustomDomainSettingsSavebar;
