import { TextField } from '@hopin-team/ui-text-field';
import P from 'prop-types';
import React from 'react';

const BaseInput = ({ className, config, value, onValueUpdate }) => (
  <TextField
    className={className}
    name={`app_area[config][${config?.field}]`}
    label={config?.label}
    value={value}
    onChange={e => onValueUpdate(e.target.value)}
    mt={2.5}
    mb={0.5}
    size="tiny"
    minLength={3}
    required
  />
);

BaseInput.propTypes = {
  className: P.string,
  config: P.shape({
    field: P.string.isRequired,
    label: P.string.isRequired,
  }).isRequired,
  value: P.string.isRequired,
  onValueUpdate: P.func.isRequired,
};

export default BaseInput;
