import camelToSnakeCase from '@util/camelcase-to-snakecase';

const formatPayload = (selectedIds, selectAll, activeFilters) => {
  const partialSelect = !selectAll && selectedIds.length > 0;
  const partialDeselect = selectAll && selectedIds.length > 0;
  const all = selectAll && !selectedIds.length;

  const { searchText, selectedFilters } = activeFilters;

  const groupedFilters = selectedFilters.reduce((acc, curr) => {
    acc[curr.key] = [...(acc[curr.key] || []), curr.query];
    return acc;
  }, {});

  const filters = Object.keys(groupedFilters).reduce((acc, curr) => {
    acc[camelToSnakeCase(curr)] = groupedFilters[curr];
    return acc;
  }, {});

  const redeem_code = {};

  switch (true) {
    case all:
      redeem_code.select_all = true;
      break;
    case partialSelect:
      redeem_code.selected_ids = selectedIds;
      break;
    case partialDeselect: {
      redeem_code.excluded_ids = selectedIds;
      break;
    }
  }

  return {
    redeem_code,
    ...filters,
    ...(searchText ? { search_text: searchText } : {}),
  };
};

export { formatPayload };
