import { array, object } from 'prop-types';
import React from 'react';

import AddStage from './add-stage';
import StagesList from './list';

const StagesTable = ({ event, eventPart, stages }) => {
  const { empty } = stages;

  if (eventPart) {
    if (empty) {
      return <AddStage event={event} eventPart={eventPart} />;
    }
    return <StagesList event={event} stages={stages} />;
  }
  return null;
};

StagesTable.propTypes = {
  event: object.isRequired,
  eventPart: object.isRequired,
  stages: array.isRequired,
};

export default StagesTable;
