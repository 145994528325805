import { useLocalization } from '@features/localization';
import { useDispatch } from 'react-redux';

import { deleteRecording as deleteRecordingApi, ValidationError } from '../api';
import { removeRecordingFromGroups } from '../redux/slices/recording-groups/recording-groups-slice';
import {
  removeRecording_fulfilled,
  removeRecording_pending,
  removeRecording_rejected,
} from '../redux/slices/recordings/recordings-slice';

export const useDeleteRecording = ({ slug, onSuccess, onError }) => {
  const { t } = useLocalization('recording-group');
  const dispatch = useDispatch();

  const deleteRecording = async id => {
    try {
      dispatch(removeRecording_pending(id));

      await deleteRecordingApi(id, slug);
      dispatch(removeRecording_fulfilled(id));
      dispatch(removeRecordingFromGroups(id));
      if (onSuccess) {
        onSuccess(id);
      }
    } catch (error) {
      dispatch(removeRecording_rejected(id));
      let message = t('something-went-wrong-try-later');

      if (error && error instanceof ValidationError) {
        message = error.message;
      }

      if (onError) {
        onError({ error, message, id });
      }
    }
  };

  return { deleteRecording };
};
