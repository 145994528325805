import PropTypes from 'prop-types';
import React from 'react';

const Eye = ({ className, color = 'currentColor' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.5921 13.4771C21.1361 12.5671 21.1361 11.4329 20.5921 10.5229C19.3651 8.46895 16.61 5 12 5C7.39004 5 4.635 8.46895 3.408 10.5229C2.864 11.4329 2.864 12.5671 3.408 13.4771C4.635 15.5311 7.39004 19 12 19C16.61 19 19.3651 15.5311 20.5921 13.4771Z"
        fill={color}
      />
      <path
        d="M12 8.5C11.634 8.5 11.2871 8.57203 10.9561 8.67603C11.1531 8.94603 11.272 9.27599 11.272 9.63599C11.272 10.54 10.54 11.272 9.63599 11.272C9.27599 11.272 8.94703 11.1521 8.67603 10.9561C8.57203 11.2881 8.5 11.634 8.5 12C8.5 13.933 10.067 15.5 12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5Z"
        fill={color}
      />
    </svg>
  );
};

Eye.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Eye;
