import eventDashboardApi from '@api/event-dashboard';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { CustomURLTextField } from '@features/marketing-settings/marketing-settings.styles';
import UrlField from '@features/marketing-settings/url-field';
import * as P from 'prop-types';
import React from 'react';
import { useAsync } from 'react-use';

const EventCustomAddressInput = ({ eventId }) => {
  const { t } = useLocalization();
  const { addAlert } = React.useContext(AlertsContext);
  const [eventSlug, setEventSlug] = React.useState('');

  const settingsData = useAsync(
    async () => eventDashboardApi.getMarketingSettings(eventId),
    [eventId],
  );
  React.useEffect(() => {
    if (settingsData.loading) {
      return;
    }

    setEventSlug(settingsData.value.data.attributes.slug);
  }, [settingsData.loading, settingsData.value?.data.attributes.slug]);

  const handleCopyToClipboard = () => {
    addAlert({
      active: true,
      text: t('marketing-settings.copied-to-clipboard'),
      pattern: 'success',
    });
  };

  const baseUrl =
    settingsData.value?.data.attributes.event_url.replace(/\/[^/]*$/, '') ?? '';

  return (
    <>
      <Alerts />
      <CustomURLTextField
        baseUrl={baseUrl}
        currentSlug={eventSlug}
        forwardedAs={UrlField}
        isRequired
        requiredText={t('forms.validation.required')}
        label={t('event-advanced-settings.event-url')}
        name="event[slug]"
        onCopy={handleCopyToClipboard}
        value={eventSlug}
        onChange={event => setEventSlug(event.target.value)}
        disabled={settingsData.loading || settingsData.error}
      />
    </>
  );
};

EventCustomAddressInput.propTypes = {
  eventId: P.string.isRequired,
};

export default withAlertsProvider(EventCustomAddressInput);
