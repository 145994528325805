import getDisplayName from '@util/get-display-name';
import { node } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { flagsActions } from './flags-actions';

const FlagsProvider = ({ children }) => {
  const { flags } = children.props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(flagsActions.flagsProvider.flagsInitialised(flags));
  }, [dispatch, flags]);

  return children;
};

FlagsProvider.propTypes = {
  children: node,
};

export const withFlagsProvider = WrappedComponent => {
  const WithRootPropsProvider = props => {
    return (
      <FlagsProvider>
        <WrappedComponent {...props} />
      </FlagsProvider>
    );
  };

  WithRootPropsProvider.displayName = `WithRootPropsProvider(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithRootPropsProvider;
};

export default FlagsProvider;
