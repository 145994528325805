import { Box } from '@hopin-team/ui-box';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { Grid } from '@hopin-team/ui-grid';
import { DeprecatedSelect } from '@hopin-team/ui-select';
import { SidePanelModal } from '@hopin-team/ui-side-panel-modal';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';

const TicketMatchingSideModalComponent = ({
  setModalOpen,
  modalOpen,
  personas,
  ticketMatchingOptions,
  matchedOptions,
  onUpdateTicketMatchingOption,
  iconAccessibilityProps,
  t,
}) => {
  return (
    <>
      <StyledSidePanelModal
        withCloseButton
        isShowing={modalOpen}
        size="large"
        onClose={() => setModalOpen(false)}
        isDismissible
      >
        <StyledBox>
          <Box background="grey-white" p={3}>
            <Text pattern="headingThree" m={3}>
              {t('networking.ticket-matching-modal.ticket-type-matching-rules')}
            </Text>
          </Box>
          <Grid px={4} pt={3} gridTemplateColumns="1fr 3fr" gridGap={3}>
            <Text pattern="headingSix" color="grey-800">
              {t('networking.ticket-matching-modal.ticket-name')}
            </Text>
            <ToolTip tip={t('networking.ticket-matching-modal.tooltip')}>
              <Text pattern="emphasis" color="grey-800">
                {t('networking.ticket-matching-modal.can-meet-with')}
              </Text>
            </ToolTip>
          </Grid>
          <StyledGrid p={4} pb={4} gridTemplateColumns="1fr 3fr" gridGap={3}>
            {personas.map(persona => (
              <Fragment key={persona.externalId}>
                <FieldLabel
                  fieldId={`matched_ids_${persona.externalId}`}
                  label={persona.label}
                  color="grey-700"
                />
                <div data-testid={`select-field-${persona.externalId}`}>
                  <DeprecatedSelect
                    inputId={`matched_ids_${persona.externalId}`}
                    isMulti
                    options={ticketMatchingOptions}
                    onChange={selected =>
                      onUpdateTicketMatchingOption(persona.externalId, selected)
                    }
                    value={matchedOptions[persona.externalId]}
                    iconAccessibilityProps={iconAccessibilityProps}
                  />
                </div>
              </Fragment>
            ))}
          </StyledGrid>
        </StyledBox>
      </StyledSidePanelModal>
    </>
  );
};

export default TicketMatchingSideModalComponent;

TicketMatchingSideModalComponent.propTypes = {
  personas: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      matchedIds: PropTypes.arrayOf(PropTypes.number),
      label: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  setModalOpen: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  ticketMatchingOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
  ),
  matchedOptions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
    ),
  ),
  onUpdateTicketMatchingOption: PropTypes.func.isRequired,
  iconAccessibilityProps: PropTypes.shape({
    clear: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    dropdown: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    multiValueRemove: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
};

const StyledSidePanelModal = styled(SidePanelModal)`
  div[role='dialog'] {
    > div {
      background-color: var(--color-gray-200);
    }
  }
`;

const StyledBox = styled(Box)`
  min-height: 100vh;
`;

const StyledGrid = styled(Grid)`
  align-items: center;
`;
