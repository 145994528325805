import { arrayOf, element, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;

  tr {
    height: 3rem;
    font-size: var(--font-size);
    font-weight: var(--font-weight-medium);
    :not(:last-child) {
      border-bottom: 1px solid var(--color-gray-200);
    }

    td:nth-child(1) {
      span:first-child {
        display: inline-flex;
        vertical-align: middle;
        padding-right: 0.5rem;
      }

      span:nth-child(2) {
        vertical-align: middle;
      }
    }

    td:nth-child(2) {
      text-align: right;
    }
  }
`;

const TopItemsListComponent = ({ items }) => {
  return (
    <Table>
      <tbody>
        {items.map((item, i) => {
          return (
            <tr key={i} data-testid={item.testId}>
              <td>
                <span>{item.icon}</span>
                <span>{item.text}</span>
              </td>
              <td>{item.value}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

TopItemsListComponent.propTypes = {
  items: arrayOf(
    shape({
      icon: element.isRequired,
      text: oneOfType([element, string]).isRequired,
      value: string.isRequired,
    }),
  ),
};

export default TopItemsListComponent;
