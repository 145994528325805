/* global Rails */
import * as Routes from '@routes';
import isClientside from '@util/is-clientside';
import ky from 'ky-universal';

let client = {};

const BASE_URL = `${Routes.recordingsComponentRecordingsComponentRootAPIPath()}/v1/organizers/recordings`;

if (isClientside()) {
  client = ky.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
    },
  });
}

const getOptions = token => ({
  headers: {
    credentials: 'include',
    Authorization: `Bearer ${token}`,
  },
  timeout: false,
});

export const recordingsApi = {
  async getRecording(id, token) {
    const response = await client
      .get(`${BASE_URL}/${id}`, getOptions(token))
      .json();
    return response;
  },
  async updateRecording(id, attributes, token) {
    const response = await client.put(
      `${Routes.recordingsComponentRecordingsComponentRootAPIPath()}/v1/organizers/recordings/${id}`,
      {
        headers: {
          credentials: 'include',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(attributes),
        timeout: false,
      },
    );

    return {
      recording: (await response.json()).recording,
    };
  },
};
