import { useLocalization } from '@features/localization';
import { navigationViews } from '@features/nav/constants';
import useIsFreeTrial from '@util/hooks/use-is-free-trial';
import ChartBarUnderline from 'icons/chart-bar-underline.svg';
import Coupon from 'icons/coupon.svg';
import Envelope3D from 'icons/envelope-3d.svg';
import GridSquare from 'icons/grid-square.svg';
import MoreHorizontal from 'icons/more-horizontal.svg';
import Onsite from 'icons/onsite.svg';
// TODO: Need to know what the default icon should be, placeholder for now, should not be hit with current map iconMap.
import defaultIcon from 'icons/options.svg';
import PenLine from 'icons/pen-line.svg';
import Recordings from 'icons/recordings.svg';
import UsersGroup from 'icons/users-group.svg';
import Venue from 'icons/venue.svg';
import { arrayOf, bool, number, object, shape, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import makeNavTree from '../nav-config';
import NavItem from './nav-item';
import { Styles } from './nav-tree-styles';

const iconMap = {
  advanced: MoreHorizontal,
  more: MoreHorizontal,
  analytics: ChartBarUnderline,
  marketing: Envelope3D,
  overview: GridSquare,
  people: UsersGroup,
  registrations: Coupon,
  registration: Coupon,
  setup: PenLine,
  basics: PenLine,
  venue: Venue,
  content: Venue,
  onsite: Onsite,
  'onsite-features': Onsite,
  recordings: Recordings,
};

const NavTree = ({
  event,
  newRegistrationsAttendeesEnabled,
  creationEnableGlobalTags,
  newRegistrationsUrl,
  newRegistrationsDashboardEnabled,
  hopinCanvasEnabled,
  ticketGroupsDeprecated,
  venueType,
  onsiteUpgradeFlowEnabled,
  customDomainsShowPage,
  isAppAreaVisible,
  surveyBuilderEnabled,
  reorderEventDashboardNavTabs,
  showEngagementScore,
  reactPagesVariation,
  onsiteEventDashboardStreamline,
}) => {
  const [navTree, setNavTree] = useState();
  const [expandedNavItem, setExpandedNavItem] = useState();
  const activeNavItem = useRef();
  const location = useLocation();
  const navigationView = useSelector(
    ({ navigationView }) => navigationView.view,
  );
  const { isFreeTrial, isDraft } = useIsFreeTrial();

  useEffect(() => {
    if (event.slug) {
      const { tree, resolveTree } = makeNavTree({
        newRegistrationsAttendeesEnabled,
        creationEnableGlobalTags,
        event,
        newRegistrationsUrl,
        newRegistrationsDashboardEnabled,
        hopinCanvasEnabled,
        ticketGroupsDeprecated,
        venueType,
        onsiteUpgradeFlowEnabled,
        customDomainsShowPage,
        advancedToggle: navigationView === navigationViews.ADVANCED,
        isAppAreaVisible,
        surveyBuilderEnabled,
        reorderTabs: reorderEventDashboardNavTabs,
        showEngagementScore,
        reactPagesVariation,
        onsiteEventDashboardStreamline,
      });
      setNavTree(tree);
      const resolvedTree = resolveTree(window.location); // TODO: We could refactor this to history.location - but requires modifying inner method
      setExpandedNavItem(resolvedTree);
      activeNavItem.current = resolvedTree;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, location, navigationView]);

  const handleNavItemClick = navItem => {
    if (
      expandedNavItem?.key === navItem.key ||
      expandedNavItem?.rootKey === navItem.tree.title ||
      expandedNavItem?.tree?.title === navItem.tree.title
    ) {
      setExpandedNavItem();
    } else {
      setExpandedNavItem(navItem);
    }
  };

  const { t } = useLocalization('event-dashboard.sidenav');

  return (
    <Styles.NavTree data-menu-version="v2">
      {navTree ? (
        <>
          {Object.entries(navTree.children).map(([title, value]) => (
            <NavItem
              currentActiveItem={activeNavItem.current}
              icon={iconMap[title] || defaultIcon}
              isExpanded={
                (title === expandedNavItem?.key ||
                  title === expandedNavItem?.tree?.title ||
                  title === expandedNavItem?.rootKey) &&
                !!value.children
              }
              key={title}
              navItemData={value}
              onClick={handleNavItemClick}
              title={t(`${title}`)}
              topLevelTranslationKey={title}
              gatePaidFeatures={isFreeTrial && isDraft}
            />
          ))}
        </>
      ) : null}
    </Styles.NavTree>
  );
};

NavTree.propTypes = {
  creationEnableGlobalTags: bool,
  event: shape({
    canDelete: bool.isRequired,
    slug: string.isRequired,
    hasTickets: bool.isRequired,
    isDraft: bool.isRequired,
    maxPrice: number.isRequired,
    organization: object.isRequired,
  }).isRequired,
  newRegistrationsAttendeesEnabled: bool,
  newRegistrationsUrl: string.isRequired,
  newRegistrationsDashboardEnabled: bool,
  hopinCanvasEnabled: bool,
  ticketGroupsDeprecated: bool,
  venueType: string.isRequired,
  onsiteUpgradeFlowEnabled: bool,
  customDomainsShowPage: string,
  eventId: string,
  isAppAreaVisible: bool,
  surveyBuilderEnabled: bool,
  reorderEventDashboardNavTabs: bool,
  showEngagementScore: bool,
  reactPagesVariation: arrayOf(string),
  onsiteEventDashboardStreamline: bool,
};

export default NavTree;
