export const getSubDomain = ({ environment, fallbackEnvironment }) => {
  switch (environment || fallbackEnvironment) {
    case 'production':
      return 'apps';
    case 'staging':
      return 'stg.apps';
    case 'development':
      return 'dev.apps';
    default:
      return 'apps';
  }
};
