import ActiveUsersChartContainer from '@features/live-analytics/active-users-chart-container';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { bool, object } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TopAreasByCurrentAttendees from './top-areas-by-current-attendees';

const BlockText = styled(Text)`
  display: block;
`;

const BulletedNotes = styled.ul`
  list-style-position: inside;
`;

const LiveAnalyticsComponent = ({ event, renameInvitedToPending }) => {
  const { t } = useLocalization('live-analytics');

  return (
    <>
      <div className="mb-3">
        <ActiveUsersChartContainer
          eventId={event.id}
          renameInvitedToPending={renameInvitedToPending}
        />
      </div>

      <TopAreasByCurrentAttendees
        event={event}
        renameInvitedToPending={renameInvitedToPending}
      />

      <Box mt={3}>
        <BlockText pattern="headingFour" mb={1.5}>
          {t('notes.title')}
        </BlockText>
        <BlockText pattern="labelThree" color="grey-600" mb={1}>
          <BulletedNotes>
            <li>
              <small>{t('notes.active-users')}</small>
            </li>
            <li>
              <small>{t('notes.no-refresh')}</small>
            </li>
          </BulletedNotes>
        </BlockText>
      </Box>
    </>
  );
};

LiveAnalyticsComponent.propTypes = {
  event: object.isRequired,
  renameInvitedToPending: bool.isRequired,
};

export default LiveAnalyticsComponent;
