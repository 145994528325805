import StepHeader from '@features/event-creation-wizard/components/step-header';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import { useLocalization } from '@features/localization';
import { bool } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSession } from '../../redux/step-specific-state/sessions-slice';
import SessionsForm from './sessions-form';
import { useSessionForm } from './use-session-form';

const SessionsComponent = ({ canSkip }) => {
  const [imageUploading, setImageUploading] = useState(false);
  const { t } = useLocalization('event-creation-wizard.steps.sessions');
  const form = useSessionForm();
  const dispatch = useDispatch();

  const onSave = () => {
    if (!form.formState.isValid) {
      throw new Error(t('form.generic-error'));
    }

    const { title, description, image } = form.getValues();
    dispatch(setSession({ title, description, image }));
  };

  return (
    <EventCreationWizardStep
      canSkip={canSkip}
      onSave={onSave}
      breadcrumbsDisabled={!canSkip && !form.formState.isValid}
      continueDisabled={!form.formState.isValid || imageUploading}
    >
      <StepHeader title={t('title')} subtitle={t('subtitle')} />

      <SessionsForm form={form} setImageUploading={setImageUploading} />
    </EventCreationWizardStep>
  );
};

SessionsComponent.propTypes = {
  canSkip: bool,
};

export default SessionsComponent;
