import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  type: null,
  attributes: {},
};

const { actions, reducer } = createSlice({
  name: 'marketingSettings',
  initialState,
  reducers: {
    setMarketingSettings: (state, action) => action.payload,
  },
});

export const { setMarketingSettings } = actions;

export default reducer;
