import analyticsApi from '@api/analytics';
import { DEFAULT_GRANULARITY } from '@features/dashboard/constants/time-granularities';
import { usePromise } from '@features/dashboard/util/hooks';
import { LocalizationContext } from '@features/localization';
import { bool, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import {
  BAR_LIMIT,
  CHART_TYPE,
} from '../registrations-active-attendees-over-time/constants';
import RegistrationsActiveAttendeesOverTime from './registrations-active-attendees-over-time';
import transformResults from './util/transform-results';

const fetchData = (query, eventSlug) => signal => {
  return [
    analyticsApi.getRegistrationsOverTime(eventSlug, signal, {
      startTime: query.rangeStart,
      endTime: query.rangeEnd,
      limit: BAR_LIMIT,
      chartType: CHART_TYPE,
      granularity: DEFAULT_GRANULARITY, // or 'week' to group by week
    }),

    analyticsApi.getLiveAnalyticsChartData(
      eventSlug,
      {
        chartType: CHART_TYPE,
        startTime: query.rangeStart,
        endTime: query.rangeEnd,
        limit: BAR_LIMIT,
      },
      signal,
    ),
  ];
};

const handleResults = (
  setData,
  setIsLoadingInitialData,
  query,
  eventPhase,
  eventTimezone,
  rangeSelected,
  t,
) => results => {
  const transformedData = transformResults(
    results,
    eventPhase,
    eventTimezone,
    rangeSelected,
    t,
  );

  setData(transformedData);
  setIsLoadingInitialData(false);
};

const initialQueryState = {
  key: 'default',
  rangeStart: null,
  rangeEnd: null,
};

const RegistrationsActiveAttendeesOverTimeContainer = ({
  eventSlug,
  eventTimezone,
  expanded,
  eventPhase,
}) => {
  const { t } = useContext(LocalizationContext);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(initialQueryState);
  const [loadingInitialData, setIsLoadingInitialData] = useState(true);
  const rangeSelected = query.key !== 'default';

  const loading = usePromise(
    fetchData(query, eventSlug),
    handleResults(
      setData,
      setIsLoadingInitialData,
      query,
      eventPhase,
      eventTimezone,
      rangeSelected,
      t,
    ),
    [query.key],
  );

  const loadingCustomRangeData = loading && !loadingInitialData;

  // range picker props
  const rangePickerProps = {
    displayText: rangeSelected
      ? query.displayText
      : t('overview.range-picker.all-times'),
    loading: loadingCustomRangeData,
    rangeSelected: rangeSelected,
    onCancel: () => setQuery(initialQueryState),
    onChange: setQuery,
  };

  return (
    <RegistrationsActiveAttendeesOverTime
      expanded={expanded}
      data={data}
      loadingInitialData={loadingInitialData}
      rangePickerProps={rangePickerProps}
      barLimit={BAR_LIMIT}
    />
  );
};

RegistrationsActiveAttendeesOverTimeContainer.defaultProps = {
  expanded: false,
};

RegistrationsActiveAttendeesOverTimeContainer.propTypes = {
  eventSlug: string.isRequired,
  eventPhase: string.isRequired,
  eventTimezone: string.isRequired,
  expanded: bool,
};

export default RegistrationsActiveAttendeesOverTimeContainer;
