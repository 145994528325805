import allHandsImg from '../assets/templates/all-hands.png';
import blankImg from '../assets/templates/blank.png';
import conferenceImg from '../assets/templates/conference.png';
import expoImg from '../assets/templates/expo.png';
import meetupImg from '../assets/templates/meetup.png';
import recruitmentImg from '../assets/templates/recruitment.png';
import webinarImg from '../assets/templates/webinar.png';
import workshopImg from '../assets/templates/workshop.png';

const templates = {
  blank: {
    steps: [
      {
        name: 'details',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/details/conference@1x.png`),
            image2x: require(`event-creation-wizard/details/conference@2x.png`),
            image3x: require(`event-creation-wizard/details/conference@3x.png`),
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: blankImg,
    autoPublish: false,
    defaultVenue: {
      stage: {
        selected: true,
        hidden: true,
      },
      sessions: {
        selected: true,
      },
      expo: {
        selected: true,
      },
      networking: {
        selected: true,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: true,
      },
      speakers: {
        selected: true,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
  },
  expo: {
    steps: [
      {
        name: 'details',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/details/expo@1x.png`),
            image2x: require(`event-creation-wizard/details/expo@2x.png`),
            image3x: require(`event-creation-wizard/details/expo@3x.png`),
          },
        },
      },
      {
        name: 'add-ons',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/add-ons/expo@1x.png`),
            image2x: require(`event-creation-wizard/add-ons/expo@2x.png`),
            image3x: require(`event-creation-wizard/add-ons/expo@3x.png`),
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: expoImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/expo@1x.png`),
        image2x: require(`event-creation-wizard/preview/expo@2x.png`),
        image3x: require(`event-creation-wizard/preview/expo@3x.png`),
        image4x: require(`event-creation-wizard/preview/expo@4x.png`),
      },
    },
    autoPublish: false,
    defaultVenue: {
      stage: {
        selected: true,
        hidden: true,
      },
      sessions: {
        selected: false,
      },
      expo: {
        selected: true,
      },
      networking: {
        selected: true,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: false,
      },
      speakers: {
        selected: false,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
    tags: ['virtual', 'hybrid'],
  },
  webinar: {
    steps: [
      {
        name: 'basic-details',
        options: {
          canSkip: false,
          canSetEventLocation: false,
          images: {
            image1x: require(`event-creation-wizard/basic-details/webinar@1x.png`),
            image2x: require(`event-creation-wizard/basic-details/webinar@2x.png`),
            image3x: require(`event-creation-wizard/basic-details/webinar@3x.png`),
          },
        },
      },
      {
        name: 'add-ons',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/add-ons/webinar@1x.png`),
            image2x: require(`event-creation-wizard/add-ons/webinar@2x.png`),
            image3x: require(`event-creation-wizard/add-ons/webinar@3x.png`),
          },
        },
      },
      {
        name: 'event-access',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/event-access/webinar@1x.png`),
            image2x: require(`event-creation-wizard/event-access/webinar@2x.png`),
            image3x: require(`event-creation-wizard/event-access/webinar@3x.png`),
          },
        },
      },
      {
        name: 'welcome-message',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/welcome-message/webinar@1x.png`),
            image2x: require(`event-creation-wizard/welcome-message/webinar@2x.png`),
            image3x: require(`event-creation-wizard/welcome-message/webinar@3x.png`),
            horizontalAlign: 'end',
            verticalAlign: 'end',
            noPadding: true,
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: webinarImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/webinar@1x.png`),
        image2x: require(`event-creation-wizard/preview/webinar@2x.png`),
        image3x: require(`event-creation-wizard/preview/webinar@3x.png`),
        image4x: require(`event-creation-wizard/preview/webinar@4x.png`),
      },
    },
    autoPublish: true,
    defaultVenue: {
      stage: {
        selected: true,
        hidden: true,
      },
      sessions: {
        selected: false,
      },
      expo: {
        selected: false,
      },
      networking: {
        selected: true,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: false,
      },
      speakers: {
        selected: false,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
    tags: ['virtual'],
  },
  virtual_lite: {
    hidden: true,
    steps: [
      {
        name: 'basic-details',
        options: {
          canSkip: false,
          canSetEventLocation: false,
          images: {
            image1x: require(`event-creation-wizard/basic-details/lite@1x.png`),
            image2x: require(`event-creation-wizard/basic-details/lite@2x.png`),
            image3x: require(`event-creation-wizard/basic-details/lite@3x.png`),
          },
        },
      },
      {
        name: 'webinar-style',
        options: {
          canSkip: false,
        },
      },
    ],
    image: webinarImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/webinar@1x.png`),
        image2x: require(`event-creation-wizard/preview/webinar@2x.png`),
        image3x: require(`event-creation-wizard/preview/webinar@3x.png`),
        image4x: require(`event-creation-wizard/preview/webinar@4x.png`),
      },
    },
    autoPublish: false,
    defaultVenue: {},
    defaultAddOns: {},
    tags: [],
  },
  workshop: {
    autoPublish: false,
    steps: [
      {
        name: 'details',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/details/workshop@1x.png`),
            image2x: require(`event-creation-wizard/details/workshop@2x.png`),
            image3x: require(`event-creation-wizard/details/workshop@3x.png`),
          },
        },
      },
      {
        name: 'add-ons',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/add-ons/workshop@1x.png`),
            image2x: require(`event-creation-wizard/add-ons/workshop@2x.png`),
            image3x: require(`event-creation-wizard/add-ons/workshop@3x.png`),
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: workshopImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/workshop@1x.png`),
        image2x: require(`event-creation-wizard/preview/workshop@2x.png`),
        image3x: require(`event-creation-wizard/preview/workshop@3x.png`),
        image4x: require(`event-creation-wizard/preview/workshop@4x.png`),
      },
    },
    defaultVenue: {
      stage: {
        selected: true,
        hidden: true,
      },
      sessions: {
        selected: false,
      },
      expo: {
        selected: false,
      },
      networking: {
        selected: true,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: false,
      },
      speakers: {
        selected: false,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
    tags: ['virtual', 'hybrid'],
  },
  conference: {
    autoPublish: false,
    steps: [
      {
        name: 'basic-details',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/basic-details/conference@1x.png`),
            image2x: require(`event-creation-wizard/basic-details/conference@2x.png`),
            image3x: require(`event-creation-wizard/basic-details/conference@3x.png`),
          },
        },
      },
      {
        name: 'add-ons',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/add-ons/conference@1x.png`),
            image2x: require(`event-creation-wizard/add-ons/conference@2x.png`),
            image3x: require(`event-creation-wizard/add-ons/conference@3x.png`),
          },
        },
      },
      {
        name: 'event-access',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/event-access/conference@1x.png`),
            image2x: require(`event-creation-wizard/event-access/conference@2x.png`),
            image3x: require(`event-creation-wizard/event-access/conference@3x.png`),
          },
        },
      },
      {
        name: 'welcome-message',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/welcome-message/conference@1x.png`),
            image2x: require(`event-creation-wizard/welcome-message/conference@2x.png`),
            image3x: require(`event-creation-wizard/welcome-message/conference@3x.png`),
          },
        },
      },
      {
        name: 'sessions',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/sessions/conference@1x.png`),
            image2x: require(`event-creation-wizard/sessions/conference@2x.png`),
            image3x: require(`event-creation-wizard/sessions/conference@3x.png`),
          },
        },
      },
      {
        name: 'speakers',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/speakers/conference@1x.png`),
            image2x: require(`event-creation-wizard/speakers/conference@2x.png`),
            image3x: require(`event-creation-wizard/speakers/conference@3x.png`),
          },
        },
      },
      {
        name: 'expo',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/expo/conference@1x.png`),
            image2x: require(`event-creation-wizard/expo/conference@2x.png`),
            image3x: require(`event-creation-wizard/expo/conference@3x.png`),
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: conferenceImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/conference@1x.png`),
        image2x: require(`event-creation-wizard/preview/conference@2x.png`),
        image3x: require(`event-creation-wizard/preview/conference@3x.png`),
        image4x: require(`event-creation-wizard/preview/conference@4x.png`),
      },
    },
    defaultVenue: {
      stage: {
        selected: true,
        hidden: true,
      },
      sessions: {
        selected: true,
      },
      expo: {
        selected: true,
      },
      networking: {
        selected: true,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: true,
      },
      speakers: {
        selected: true,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
    tags: ['virtual', 'hybrid'],
  },
  meetup: {
    steps: [
      {
        name: 'basic-details',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/basic-details/meetup@1x.png`),
            image2x: require(`event-creation-wizard/basic-details/meetup@2x.png`),
            image3x: require(`event-creation-wizard/basic-details/meetup@3x.png`),
          },
        },
      },
      {
        name: 'add-ons',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/add-ons/meetup@1x.png`),
            image2x: require(`event-creation-wizard/add-ons/meetup@2x.png`),
            image3x: require(`event-creation-wizard/add-ons/meetup@3x.png`),
          },
        },
      },
      {
        name: 'event-access',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/event-access/meetup@1x.png`),
            image2x: require(`event-creation-wizard/event-access/meetup@2x.png`),
            image3x: require(`event-creation-wizard/event-access/meetup@3x.png`),
          },
        },
      },
      {
        name: 'welcome-message',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/welcome-message/meetup@1x.png`),
            image2x: require(`event-creation-wizard/welcome-message/meetup@2x.png`),
            image3x: require(`event-creation-wizard/welcome-message/meetup@3x.png`),
          },
        },
      },
      {
        name: 'sessions',
        options: {
          canSkip: false,
          images: {
            image1x: require(`event-creation-wizard/sessions/meetup@1x.png`),
            image2x: require(`event-creation-wizard/sessions/meetup@2x.png`),
            image3x: require(`event-creation-wizard/sessions/meetup@3x.png`),
            horizontalAlign: 'end',
            verticalAlign: 'end',
            noPadding: true,
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: meetupImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/meetup@1x.png`),
        image2x: require(`event-creation-wizard/preview/meetup@2x.png`),
        image3x: require(`event-creation-wizard/preview/meetup@3x.png`),
        image4x: require(`event-creation-wizard/preview/meetup@4x.png`),
      },
    },
    autoPublish: true,
    defaultVenue: {
      stage: {
        selected: false,
        hidden: true,
      },
      sessions: {
        selected: true,
        hidden: true,
      },
      expo: {
        selected: false,
      },
      networking: {
        selected: true,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: false,
      },
      speakers: {
        selected: false,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
    tags: ['virtual'],
  },
  all_hands: {
    steps: [
      {
        name: 'basic-details',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/basic-details/all_hands@1x.png`),
            image2x: require(`event-creation-wizard/basic-details/all_hands@2x.png`),
            image3x: require(`event-creation-wizard/basic-details/all_hands@3x.png`),
          },
        },
      },
      {
        name: 'add-ons',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/add-ons/all_hands@1x.png`),
            image2x: require(`event-creation-wizard/add-ons/all_hands@2x.png`),
            image3x: require(`event-creation-wizard/add-ons/all_hands@3x.png`),
          },
        },
      },
      {
        name: 'welcome-message',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/welcome-message/all_hands@1x.png`),
            image2x: require(`event-creation-wizard/welcome-message/all_hands@2x.png`),
            image3x: require(`event-creation-wizard/welcome-message/all_hands@3x.png`),
          },
        },
      },
      {
        name: 'sessions',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/sessions/all_hands@1x.png`),
            image2x: require(`event-creation-wizard/sessions/all_hands@2x.png`),
            image3x: require(`event-creation-wizard/sessions/all_hands@3x.png`),
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: allHandsImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/all_hands@1x.png`),
        image2x: require(`event-creation-wizard/preview/all_hands@2x.png`),
        image3x: require(`event-creation-wizard/preview/all_hands@3x.png`),
        image4x: require(`event-creation-wizard/preview/all_hands@4x.png`),
      },
    },
    autoPublish: true,
    defaultVenue: {
      stage: {
        selected: false,
        hidden: true,
      },
      sessions: {
        selected: true,
        hidden: true,
      },
      expo: {
        selected: false,
      },
      networking: {
        selected: true,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: false,
      },
      speakers: {
        selected: false,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
    tags: ['virtual'],
  },
  recruitment: {
    autoPublish: false,
    steps: [
      {
        name: 'details',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/details/recruitment@1x.png`),
            image2x: require(`event-creation-wizard/details/recruitment@2x.png`),
            image3x: require(`event-creation-wizard/details/recruitment@3x.png`),
          },
        },
      },
      {
        name: 'add-ons',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/add-ons/recruitment@1x.png`),
            image2x: require(`event-creation-wizard/add-ons/recruitment@2x.png`),
            image3x: require(`event-creation-wizard/add-ons/recruitment@3x.png`),
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    image: recruitmentImg,
    preview: {
      images: {
        image1x: require(`event-creation-wizard/preview/recruitment@1x.png`),
        image2x: require(`event-creation-wizard/preview/recruitment@2x.png`),
        image3x: require(`event-creation-wizard/preview/recruitment@3x.png`),
        image4x: require(`event-creation-wizard/preview/recruitment@4x.png`),
      },
    },
    defaultVenue: {
      stage: {
        selected: true,
        hidden: true,
      },
      sessions: {
        selected: false,
      },
      expo: {
        selected: false,
      },
      networking: {
        selected: false,
      },
      replay: {
        selected: true,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: false,
      },
      speakers: {
        selected: false,
      },
      q_and_a: {
        selected: false,
      },
      polls: {
        selected: false,
      },
      chat: {
        selected: false,
      },
    },
    tags: ['virtual', 'hybrid'],
  },
  onsite: {
    hidden: true,
    steps: [
      {
        name: 'onsite',
        options: {
          canSkip: false,
          canSetEventLocation: true,
          images: {
            image1x: require(`event-creation-wizard/details/onsite@1x.png`),
            image2x: require(`event-creation-wizard/details/onsite@2x.png`),
            image3x: require(`event-creation-wizard/details/onsite@3x.png`),
          },
        },
      },
      {
        name: 'purpose',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/purpose/purpose@1x.png`),
            image2x: require(`event-creation-wizard/purpose/purpose@2x.png`),
            image3x: require(`event-creation-wizard/purpose/purpose@3x.png`),
          },
        },
      },
    ],
    defaultVenue: {
      stage: {
        selected: true,
        hidden: true,
      },
      sessions: {
        selected: true,
      },
      expo: {
        selected: true,
      },
      networking: {
        selected: false,
      },
      replay: {
        selected: false,
        isPaidOnly: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: true,
      },
      speakers: {
        selected: true,
      },
      q_and_a: {
        selected: false,
      },
      polls: {
        selected: false,
      },
      chat: {
        selected: false,
      },
    },
    autoPublish: false,
  },
  webinar_experience: {
    autoPublish: true,
    steps: [
      {
        name: 'description',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/description/description@1x.png`),
            image2x: require(`event-creation-wizard/description/description@2x.png`),
            image3x: require(`event-creation-wizard/description/description@3x.png`),
          },
        },
      },
    ],
    defaultVenue: {
      reception: { selected: false },
      stage: { selected: true },
      sessions: { selected: false },
      replay: { selected: false },
      networking: { selected: false },
      expo: { selected: false },
    },
    defaultAddOns: {
      chat: { selected: true },
      q_and_a: { selected: true },
      polls: { selected: false },
      speakers: { selected: false },
      sponsors: { selected: false },
    },
  },
  virtual_experience: {
    steps: [
      {
        name: 'event-areas',
        options: {
          canSkip: true,
        },
      },
      {
        name: 'description',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/description/description@1x.png`),
            image2x: require(`event-creation-wizard/description/description@2x.png`),
            image3x: require(`event-creation-wizard/description/description@3x.png`),
          },
        },
      },
    ],
    defaultVenue: {
      reception: {
        selected: true,
      },
      stage: {
        selected: true,
      },
      sessions: {
        selected: true,
      },
      networking: {
        selected: true,
      },
      expo: {
        selected: true,
      },
      replay: {
        selected: false,
        hidden: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: true,
      },
      speakers: {
        selected: true,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
  },
  hybrid_experience: {
    steps: [
      {
        name: 'event-areas',
        options: {
          canSkip: true,
        },
      },
      {
        name: 'description',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/description/description@1x.png`),
            image2x: require(`event-creation-wizard/description/description@2x.png`),
            image3x: require(`event-creation-wizard/description/description@3x.png`),
          },
        },
      },
    ],
    defaultVenue: {
      reception: {
        selected: true,
      },
      stage: {
        selected: true,
      },
      sessions: {
        selected: true,
      },
      networking: {
        selected: true,
      },
      expo: {
        selected: true,
      },
      replay: {
        selected: false,
        hidden: true,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: true,
      },
      speakers: {
        selected: true,
      },
      q_and_a: {
        selected: true,
      },
      polls: {
        selected: true,
      },
      chat: {
        selected: true,
      },
    },
  },
  onsite_experience: {
    steps: [
      {
        name: 'description',
        options: {
          canSkip: true,
          images: {
            image1x: require(`event-creation-wizard/description/description@1x.png`),
            image2x: require(`event-creation-wizard/description/description@2x.png`),
            image3x: require(`event-creation-wizard/description/description@3x.png`),
          },
        },
      },
    ],
    defaultVenue: {
      stage: {
        selected: false,
        hidden: true,
      },
      sessions: {
        selected: true,
      },
      expo: {
        selected: true,
      },
      networking: {
        selected: false,
      },
      replay: {
        selected: false,
      },
    },
    defaultAddOns: {
      sponsors: {
        selected: true,
      },
      speakers: {
        selected: true,
      },
      q_and_a: {
        selected: false,
      },
      polls: {
        selected: false,
      },
      chat: {
        selected: false,
      },
    },
  },
};

export { templates };
