import { arrayOf, func, number, object, shape, string } from 'prop-types';
import React from 'react';

import StyledChecklistTabs from '../styled/checklist-tabs';
import { isTabApplicable, isTabComplete } from '../utils/tabs';
import ChecklistTab from './checklist-tab';

const ChecklistTabs = ({
  checklistStatuses,
  onTabClick,
  selectedTabIndex,
  tabs,
}) => {
  const tabRefs = {};
  const handleKeydown = e => {
    if (e.keyCode === 40) {
      const newTabIndex = selectedTabIndex + 1;
      onTabClick(Math.min(tabs.length - 1, newTabIndex));
      tabRefs[newTabIndex].current.focus();
    }
    if (e.keyCode === 38) {
      const newTabIndex = selectedTabIndex - 1;
      onTabClick(Math.max(0, selectedTabIndex - 1));
      tabRefs[newTabIndex].current.focus();
    }
  };

  return (
    <StyledChecklistTabs
      aria-labelledby="event-setup-checklist-title"
      aria-orientation="vertical"
      mt={4}
      onKeyDown={handleKeydown}
      role="tablist"
    >
      {tabs
        .filter(tab => isTabApplicable(tab, checklistStatuses))
        .map((tab, ix) => (
          <ChecklistTab
            isActive={selectedTabIndex === ix}
            isChecked={isTabComplete(tab, checklistStatuses)}
            key={tab.title}
            number={ix + 1}
            onClick={() => onTabClick(ix)}
            ref={elem => {
              tabRefs[ix] = { current: elem };
            }}
            text={tab.title}
          />
        ))}
    </StyledChecklistTabs>
  );
};

ChecklistTabs.propTypes = {
  checklistStatuses: object,
  onTabClick: func,
  selectedTabIndex: number,
  tabs: arrayOf(
    shape({
      title: string.isRequired,
      requiredSteps: arrayOf(string).isRequired,
    }).isRequired,
  ),
};

export default ChecklistTabs;
