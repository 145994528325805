import { Icon } from '@hopin-team/ui-icon';
import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  display: inline-block;
`;

const getIconName = areaName => {
  switch (areaName) {
    case 'Stages':
      return 'camera';
    case 'Sessions':
      return 'sessions';
    case 'Expo':
      return 'booth';
    case 'Networking':
      return 'handshake';
    default:
      return 'house';
  }
};

const EventAreaIcon = ({ eventArea }) => {
  const iconName = getIconName(eventArea);

  return (
    <StyledIcon
      name={`${iconName}`}
      data-testid={`${iconName}-icon`}
      scale={2}
      color="blue-500"
    />
  );
};

EventAreaIcon.propTypes = {
  eventArea: string.isRequired,
};

export default EventAreaIcon;
