import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from './root-saga';
import auth from './slices/auth/auth-slice';
import editThumbnail from './slices/edit-thumbnail/edit-thumbnail-slice';
import event from './slices/event/event-slice';
import flags from './slices/flags/flags-slice';
import recordingCuts from './slices/recording-cuts/recording-cuts-slice';
import recordingGroups from './slices/recording-groups/recording-groups-slice';
import recordings from './slices/recordings/recordings-slice';
import registration from './slices/registration/registration-slice';
import transcript from './slices/transcript/transcript-slice';
import user from './slices/user/user-slice';

export const rootReducer = {
  auth,
  editThumbnail,
  event,
  flags,
  recordingCuts,
  recordingGroups,
  recordings,
  registration,
  transcript,
  user,
};

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
