import { createSlice } from '@reduxjs/toolkit';
import mergeDeepRight from 'ramda/src/mergeDeepRight';

const initialState = { items: [], schedule_draft: false };

const { actions, reducer } = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setScheduleDraft: (state, action) => ({
      ...state,
      schedule_draft: action.payload,
    }),
    setSchedule: (state, action) => ({ ...state, items: action.payload }),
    createItem: (state, action) => ({
      ...state,
      items: [...state.items, action.payload],
    }),
    destroyItem: (state, action) => ({
      ...state,
      items: state.items.filter(item => item.id !== action.payload.id),
    }),
    updateItem: (state, action) => {
      const oldElementIndex = state.items.findIndex(
        x => x.id === action.payload.id,
      );
      if (oldElementIndex === -1) return state;

      const newShedule = state.items.slice();
      const oldElement = state.items[oldElementIndex];
      newShedule[oldElementIndex] = mergeDeepRight(oldElement, action.payload);

      return { ...state, items: newShedule };
    },
  },
});

export const isDraftIndicatorShown = ({
  schedule: { items, schedule_draft },
}) => schedule_draft || items.some(schedule => schedule.draft);

export const {
  setSchedule,
  createItem,
  destroyItem,
  updateItem,
  setScheduleDraft,
} = actions;

export default reducer;
