import MenuContainer from '@components/menu/menu-container';
import {
  Menu,
  MenuItem as MenuItemBase,
} from '@components/menu/menu-styled-components';
import { INTERACTIVE } from '@hopin-team/ui-blocks';
import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { Icon } from '@hopin-team/ui-icon';
import { MediaWrapper as MediaWrapperBase } from '@hopin-team/ui-select-items/dist/styled/media';
import { StyledCheckbox } from '@hopin-team/ui-select-items/dist/styled/select-items.styled';
import styled from 'styled-components';

const { area } = INTERACTIVE;

export const EventAreaLabel = styled(Flex)`
  font-size: 0.875rem;
  font-weight: 500;

  span {
    color: var(--color-text-light);
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const EventPartCheckbox = styled(StyledCheckbox)`
  ${area}

  border: ${({ theme }) => getFromTheme('LINE.DEFAULT_BORDER', theme)};
  padding: 0.5rem 1rem;

  background-color: ${props =>
    props.checked
      ? ({ theme }) => getFromTheme('COLOR.BLUE["100"]', theme)
      : 'initial'};
  border-color: ${props =>
    props.checked
      ? ({ theme }) => getFromTheme('COLOR.BLUE["300"]', theme)
      : ({ theme }) => getFromTheme('COLOR.GREY["300"]', theme)}
    ;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  };

  &:focus-within {
    box-shadow: none;
  };

  div:not(${Halon}) {
    border-radius: 0.25rem;
  }
`;

export const EventAreaHalon = styled(Halon)`
  width: 2rem;
  height: 2rem;
`;

export const GapFillingSpace = styled.div`
  flex: auto;
`;

export const MediaWrapper = styled(MediaWrapperBase)`
  padding-left: 0.25rem;
  column-gap: 1rem;
`;

export const StyledMenu = styled(MenuContainer)`
  ${Menu} {
    padding: 0;
    width: 20rem;
  }
`;

export const MenuItems = styled(Flex)`
  padding: 0.5rem;
  row-gap: 0.25rem;

  svg:not(${Icon} svg) {
    // override style from menu-styled-components
    color: unset;
    margin-right: unset;
  }
`;

export const MenuItem = styled(MenuItemBase)`
  color: ${props =>
    props.disabled
      ? ({ theme }) => getFromTheme('COLOR.GREY["400"]', theme)
      : ({ theme }) => getFromTheme('COLOR.GREY["800"]', theme)};
  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  width: 100%;
  padding: 0.5rem 0.75rem;

  :hover {
    background-color: ${({ theme }) =>
      getFromTheme('COLOR.GREY["100"]', theme)};
  }

  &:not(:first-child) {
    // override margin definition from MenuItemBase
    margin-top: 0;
  }

  svg {
    align-self: center;
    height: 100%;
  }
`;
