import PropTypes from 'prop-types';
import React from 'react';

import { ContentContainer, SelectableLabel } from './selectable.styled.';

export const Selectable = ({ children, isSelected, onSelect }) => {
  const handleSelect = () => {
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <SelectableLabel>
      <input
        type="radio"
        name="thumbnail"
        defaultChecked={isSelected}
        onChange={handleSelect}
      />
      <ContentContainer cornerRadius="md">{children}</ContentContainer>
    </SelectableLabel>
  );
};

Selectable.propTypes = {
  children: PropTypes.element.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};
