import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { format as formatDate } from 'date-fns-tz';
import { string } from 'prop-types';
import compose from 'ramda/src/compose';
import React, { useContext } from 'react';

const TimeZoneMessage = ({ eventTimezone }) => {
  const { t } = useContext(LocalizationContext);
  let zone = 'GMT';

  if (eventTimezone) {
    zone = formatDate(new Date(), 'z', { timeZone: eventTimezone });
  }

  return (
    <div data-testid="time-zone-message">
      {t('overview.time-zone-message', { zone })}
    </div>
  );
};

TimeZoneMessage.propTypes = {
  eventTimezone: string,
};

export default compose(withLocalizationProvider)(TimeZoneMessage);
