import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  merchantId: null,
  externalId: null,
  hasAnalytics: false,
  id: null,
  maxStages: null,
  name: '',
  currentPaymentPlan: {
    features: {},
    maxAttendees: null,
    name: '',
    recordingsSize: null,
  },
  suppressEmails: false,
  v2: false,
};

const { actions, reducer } = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action) => action.payload,
  },
});

export const { setOrganization } = actions;

export default reducer;
