import { useLocalization } from '@features/localization';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Count from './count';
import StatusDot from './dot';
import Label from './label';
import { getColorByType } from './utils';

const Tile = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  width: -webkit-fill-available;
  max-width: ${rem(260)};
  padding: var(--spacing-16) var(--spacing-8) var(--spacing-48);
  box-sizing: border-box;
  border: 1px solid var(--color-gray-200);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  &:not(:last-child) {
    margin-right: var(--spacing-16);
  }
`;

const StatusCountTile = ({ type, count, renameInvitedToPending }) => {
  const { t } = useLocalization('magic-links.tabs.manage-invites.types');
  const typeKey =
    renameInvitedToPending && type == 'invited' ? 'pending' : type;
  return (
    <Tile data-testid="invites-status-tile">
      <StatusDot color={getColorByType(type)} />
      <div>
        <Label data-testid="invites-status-label">{t(typeKey)}</Label>
        <Count data-testid="invites-status-count">{count}</Count>
      </div>
    </Tile>
  );
};

StatusCountTile.propTypes = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  renameInvitedToPending: PropTypes.bool,
};

export default StatusCountTile;
