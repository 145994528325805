import Alerts from '@features/alerts/alerts';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { withLocalizationProvider } from '@features/localization';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { number, shape, string } from 'prop-types';
import React from 'react';

import withReduxProvider from '@/redux/with-provider';

import SurveyList from './components/survey-list/survey-list';

export const SurveyBuilderComponent = ({ event }) => {
  return (
    <>
      <Alerts />
      <SurveyList event={event} />
    </>
  );
};

SurveyBuilderComponent.propTypes = {
  event: shape({
    id: number.isRequired,
    slug: string.isRequired,
  }),
};

export default compose(
  withErrorHandling({ loggerName: LOGGER_NAMES.SURVEY_BUILDER }),
  withAlertsProvider,
  withLocalizationProvider,
  withReduxProvider,
)(SurveyBuilderComponent);
