import { TYPE } from '@components/people/category-types';
import MatchmakingCard from '@components/people/matchmaking-card';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { Flex } from '@hopin-team/ui-flex';
import * as Routes from '@routes';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import matchmakingTypes from './matchmaking-types';

const MatchmakingOptions = ({
  authToken,
  event,
  matchmakingOption,
  setMatchmakingOption,
  personas,
  hasMeetingsArea,
  categoriesData,
  lockProfileMatching,
  setShowSettingsModal,
  t,
}) => {
  const { addAlert } = useContext(AlertsContext);
  useEffect(() => {
    switch (event.network_type) {
      case 'everyone':
        setMatchmakingOption(0);
        break;
      case 'ticket_holder':
        if (personas?.length < 2) {
          onUseOption(0, 'everyone');
          return setMatchmakingOption(0);
        }
        setMatchmakingOption(1);
        break;
      default:
        setMatchmakingOption(0);
        break;
    }
  }, [event.network_type, personas?.length, setMatchmakingOption]);

  const onUseOption = async (id, matchmakingType) => {
    try {
      const url = Routes.organisersEventPath(event.slug, { format: 'js' });
      const formData = new FormData();
      formData.append('event[network_type]', matchmakingType);

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'X-CSRF-TOKEN': authToken,
        },
        body: formData,
      });

      if (response.ok) {
        setMatchmakingOption(id);

        addAlert({
          active: true,
          text: t('networking.setting-updated'),
          pattern: 'success',
        });
      }
    } catch (e) {
      addAlert({
        active: true,
        text: t('networking.something-went-wrong'),
        pattern: 'error',
      });
    }
  };

  return (
    <Flex mb={6} justifyContent="flex-start">
      {matchmakingTypes.map(type => (
        <MatchmakingCard
          authToken={authToken}
          event={event}
          key={t(type.title)}
          title={t(type.title)}
          type={type.type}
          id={type.id}
          matchmakingOption={matchmakingOption}
          onUseOption={onUseOption}
          isDefault={type.isDefault}
          hasSettings={type.hasSettings}
          details={t(type.details)}
          footnote={t(type.footnote)}
          image={type.image}
          personas={personas}
          hasMeetingsArea={hasMeetingsArea}
          categoriesData={categoriesData}
          lockProfileMatching={lockProfileMatching}
          setShowSettingsModal={setShowSettingsModal}
        />
      ))}
    </Flex>
  );
};

export default MatchmakingOptions;

MatchmakingOptions.propTypes = {
  authToken: PropTypes.string.isRequired,
  lockProfileMatching: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string.isRequired,
    network_type: PropTypes.string.isRequired,
  }).isRequired,
  personas: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      matchedIds: PropTypes.arrayOf(PropTypes.number),
      label: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  hasMeetingsArea: PropTypes.bool.isRequired,
  matchmakingOption: PropTypes.number,
  setMatchmakingOption: PropTypes.func,
  setShowSettingsModal: PropTypes.func,
  categoriesData: PropTypes.arrayOf(
    PropTypes.shape({
      reactHookFormId: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
      name: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
          .isRequired,
      ),
    }),
  ),
  t: PropTypes.func,
};
