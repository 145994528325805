import CsrfInput from '@components/csrf-input';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Text } from '@hopin-team/ui-text';
import get from 'lodash/get';
import { array, object, string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { TabWrapper } from '../event-links-styles';
import LinkField from '../partials/link-field';
import StageSelect from '../partials/stage-select-component';

const StyledButton = styled(Button)`
  width: fit-content;
`;

const RTMPBackstageLinksTab = ({ backstageOptions, links, createRtmpLink }) => {
  const [currentBackstage, setCurrentBackstage] = useState(
    get(backstageOptions, '[0].value', ''),
  );
  const { t } = useLocalization('overview.event-links.modal.tabs');

  const linksForStage = links[currentBackstage];

  const formAction =
    createRtmpLink[currentBackstage]?.createRtmpVideoChannelsUrl;

  return (
    <TabWrapper data-testid="event-links-tabpanel">
      <Text element="p" pattern="body" color="grey-600" weight="regular">
        {t('rtmp.description')}
      </Text>
      {backstageOptions?.length > 1 && (
        <StageSelect
          options={backstageOptions}
          setCurrentBackstage={setCurrentBackstage}
          currentBackstage={currentBackstage}
        />
      )}

      {!linksForStage && (
        <form
          method="post"
          action={formAction}
          data-testid="generate-rtmp-setup-form"
        >
          <input type="hidden" name="_method" value="post"></input>
          <CsrfInput />
          <StyledButton my={3}>{t('rtmp.generate-rtmp-setup')}</StyledButton>
        </form>
      )}

      {!linksForStage && (
        <Text
          element="p"
          pattern="body"
          color="grey-600"
          my={3}
          weight="regular"
        >
          {t('rtmp.rtmp-being-prepared')}
        </Text>
      )}

      {linksForStage &&
        Object.keys(linksForStage)?.map(
          linkKey =>
            linksForStage[linkKey] && (
              <LinkField
                link={linksForStage[linkKey]}
                label={linkKey}
                key={linkKey}
              />
            ),
        )}
    </TabWrapper>
  );
};

RTMPBackstageLinksTab.propTypes = {
  eventSlug: string,
  backstageOptions: array,
  links: object,
  createRtmpLink: object,
};

export default RTMPBackstageLinksTab;
