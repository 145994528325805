import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { LocalizationContext } from '@features/localization';
import { TableRow } from '@hopin-team/ui-table';
import { addMinutes, isBefore } from 'date-fns';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';

import Actions from './actions';
import { AudienceSelection } from './audience-selection';
import CancelScheduleConfirmationModal from './cancel-schedule-confirmation-modal';
import Cell from './cell';
import { ConfirmModal } from './confirm-modal';
import DeleteEmailConfirmationModal from './delete-email-confirmation-modal';
import Heading from './heading';
import Status from './status';
import { FluidTable, Thead } from './styles';

const AnnouncementsTable = ({
  eventId,
  eventTz,
  items,
  token,
  organizationId,
  setItems,
  hasTicketTypes,
}) => {
  const translationKeyPrefix = 'email-dashboard.list';
  const { addAlert } = useContext(AlertsContext);
  const { t } = useContext(LocalizationContext);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [
    isDeleteEmailConfirmationModalOpen,
    setDeleteEmailConfirmationModalOpen,
  ] = useState(false);
  const [
    isCancelScheduleConfirmationOpen,
    setCancelScheduleConfirmationOpen,
  ] = useState(false);
  const [isUnableToEditOpen, setUnableToEdit] = useState(false);

  const handleOnPreview = ({ id: templateId }) =>
    window.location.assign(
      `/organisers/events/${eventId}/announcements/${templateId}/preview`,
    );

  const handleOnReview = ({ id: templateId }) =>
    window.location.assign(
      `/organisers/events/${eventId}/announcements/${templateId}/review`,
    );

  const isScheduledSoon = ({ status }) => {
    const [statusKey] = Object.keys(status);

    return (
      statusKey === 'scheduled' &&
      isBefore(new Date(status[statusKey]), addMinutes(new Date(), 15))
    );
  };

  const handleOnEdit = async ({ id: templateId, status }) => {
    setSelectedTemplate(templateId);
    if (isScheduledSoon({ status })) {
      setUnableToEdit(true);
    } else {
      navigateToEdit(templateId);
    }
  };

  const navigateToEdit = templateId => {
    window.location.assign(
      `/organisers/events/${eventId}/announcements/${templateId}/edit`,
    );
  };

  const handleOnDuplicate = async ({ id: templateId }) => {
    try {
      const newEmail = await hopinApi.duplicateAnnouncement(
        token,
        organizationId,
        eventId,
        templateId,
      );

      addAlert({
        active: true,
        text: t(`email-dashboard.duplicate.success`),
        pattern: 'success',
      });

      setItems(items.concat(newEmail));
    } catch (e) {
      addAlert({
        active: true,
        text: t(`email-dashboard.duplicate.error`),
        pattern: 'error',
      });
    }
  };

  const handleOnDelete = async ({ id: templateId }) => {
    setSelectedTemplate(templateId);
    setDeleteEmailConfirmationModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await hopinApi.deleteAnnouncement(
        token,
        organizationId,
        eventId,
        selectedTemplate,
      );

      addAlert({
        active: true,
        text: t(`email-dashboard.delete.success`),
        pattern: 'warning',
      });

      setDeleteEmailConfirmationModalOpen(false);
      setItems(items.filter(item => item.id !== selectedTemplate));
    } catch (e) {
      addAlert({
        active: true,
        text: t(`email-dashboard.delete.error`),
        pattern: 'error',
      });
    }
  };

  const handleOnCancelSchedule = ({ id, status }) => {
    if (isScheduledSoon({ status })) {
      setUnableToEdit(true);
    } else {
      setCancelScheduleConfirmationOpen(true);
      setSelectedTemplate(id);
    }
  };

  const confirmCancelSchedule = async () => {
    try {
      await hopinApi.cancelScheduledEmail(
        token,
        organizationId,
        eventId,
        selectedTemplate,
      );

      const { status } = await hopinApi.getAnnouncement(
        token,
        organizationId,
        eventId,
        selectedTemplate,
      );

      addAlert({
        active: true,
        text: t(`email-dashboard.cancel-schedule.success`),
        pattern: 'warning',
      });

      setCancelScheduleConfirmationOpen(false);
      setItems(
        items.map(item =>
          item.id !== selectedTemplate ? item : { ...item, status },
        ),
      );
    } catch (e) {
      addAlert({
        active: true,
        text: t(`email-dashboard.cancel-schedule.error`),
        pattern: 'error',
      });
    }
  };

  return (
    <>
      <FluidTable hasHighlights>
        <Thead>
          <TableRow isHeading>
            <Heading>{t(`${translationKeyPrefix}.headers.email`)}</Heading>
            <Heading data-testid="announcements-list-audience-header">
              {t(`${translationKeyPrefix}.headers.audience`)}
            </Heading>
            <Heading>{t(`${translationKeyPrefix}.headers.status`)}</Heading>
          </TableRow>
        </Thead>
        <tbody>
          {items.map(item => {
            const statusKey = Object.keys(item.status)[0];
            const date = item.status[statusKey];
            const timezone = item.email_template_schedule?.timezone || eventTz;
            return (
              <TableRow key={item.id} data-testid="announcements-list-item">
                <Cell data-testid="template-name">{item.name}</Cell>
                <Cell>
                  <div data-testid="announcements-list-audience">
                    <AudienceSelection
                      hasTicketTypes={hasTicketTypes}
                      status={item.audience_selection_attendee_status}
                      ticketTypes={item.audience_selection_ticket_types}
                    ></AudienceSelection>
                  </div>
                </Cell>
                <Cell>
                  <Status
                    statusKey={statusKey}
                    date={date}
                    timezone={timezone}
                  />
                  <Actions
                    status={statusKey}
                    onReview={() => handleOnReview(item)}
                    onEdit={() => handleOnEdit(item)}
                    onSend={() => handleOnPreview(item)}
                    onDuplicate={() => handleOnDuplicate(item)}
                    onDelete={() => handleOnDelete(item)}
                    onCancelSchedule={() => handleOnCancelSchedule(item)}
                  />
                </Cell>
              </TableRow>
            );
          })}
        </tbody>
      </FluidTable>
      <DeleteEmailConfirmationModal
        isModalDisplayed={isDeleteEmailConfirmationModalOpen}
        handleClose={() => setDeleteEmailConfirmationModalOpen(false)}
        confirmDelete={confirmDelete}
        data-testid="delete-email-confirmation-modal"
      />
      <CancelScheduleConfirmationModal
        isModalDisplayed={isCancelScheduleConfirmationOpen}
        handleClose={() => setCancelScheduleConfirmationOpen(false)}
        confirmCancel={confirmCancelSchedule}
        data-testid="cancel-email-confirmation-modal"
      />
      <ConfirmModal
        label="Cancel Schedule Modal"
        title={t(`email-dashboard.cancel-schedule.scheduled-soon`)}
        message={t(`email-dashboard.cancel-schedule.editing-disabled`)}
        open={isUnableToEditOpen}
        onCancel={() => setUnableToEdit(false)}
        onConfirm={() => setUnableToEdit(false)}
        cancelButtonProps={{
          'data-testid': 'cancel-button',
        }}
        confirmButtonProps={{
          'data-testid': 'scheduled-soon-confirm',
          label: t(`common.okay`),
        }}
      />
      <Alerts />
    </>
  );
};

AnnouncementsTable.propTypes = {
  items: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      subject: string.isRequired,
      status: object.isRequired,
    }),
  ),
  setItems: func.isRequired,
  eventId: number.isRequired,
  eventTz: string.isRequired,
  token: string,
  organizationId: number,
  hasTicketTypes: bool,
};

export default AnnouncementsTable;
