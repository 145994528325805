import { connectViewData } from '@util/page-view-context';

import EventCurrencyComponent from './event-currency-component';

const mapViewDataToProps = ({ currencies, event }) => ({
  currencies,
  eventCurrency: event.currency,
});

export default connectViewData(mapViewDataToProps)(EventCurrencyComponent);
