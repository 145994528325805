import AddressPicker from '@features/events/address-picker';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { TextField } from '@hopin-team/ui-text-field';
import { func, number, shape, string } from 'prop-types';
import React from 'react';

const EventLocationType = ({
  type,
  address,
  register,
  onAddressChange,
  onNameChange,
  googleMapsApiKey,
  errors,
}) => {
  const { t } = useLocalization(
    'event-creation-wizard.steps.details.form.event-location',
  );

  return (
    <>
      <input hidden name="eventLocationType" ref={register} />
      {type === 'hybrid' && (
        <Flex mt={3} flexDirection="column">
          <TextField
            mb={3.5}
            name="eventLocationName"
            ref={register}
            data-testid="venue-name"
            hasErrors={Boolean(errors?.name)}
            errorMessage={errors?.name?.message}
            onChange={e => onNameChange(e.target.value)}
            label={t('name.label')}
            placeholder={t('name.placeholder')}
          />
          <AddressPicker
            address={address}
            onAddressChange={onAddressChange}
            googleMapsApiKey={googleMapsApiKey}
            label={t('address.label')}
            placeholder={t('address.placeholder')}
          />
          <input hidden name="eventLocationAddress" ref={register} />
        </Flex>
      )}
    </>
  );
};

EventLocationType.propTypes = {
  type: string,
  name: string,
  address: shape({
    name: string,
    format: string,
    lat: number,
    lng: number,
  }),
  register: func,
  onAddressChange: func,
  onNameChange: func,
  googleMapsApiKey: string,
  errors: shape({
    name: shape({
      message: string,
    }),
  }),
};

export default EventLocationType;
