import 'react-circular-progressbar/dist/styles.css';

import analyticsApi from '@api/analytics';
import Card from '@components/card-with-loading';
import { usePromise } from '@features/dashboard/util/hooks';
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import styled from 'styled-components';

import { graphColor60, gray300, gray800 } from '../../../dashboard/util/colors';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Progress = styled.div`
  width: 80px;
  font-weight: var(--font-weight-semi-bold);
`;

const TextContainer = styled.div`
  align-self: center;
  margin-left: 1.5rem;
  p {
    margin-bottom: 0.5rem;
  }
`;

const TurnoutCard = styled(Card)`
  padding: 1.25rem;
`;

const turnoutPercentageToText = (t, percentage, average) => {
  const texts = {
    muchLower: (
      <>
        <Text
          element="h5"
          pattern="headingFive"
          color="grey-800"
          weight="medium"
        >
          {t('overview.turnout-banner.muchLower.headline')}
        </Text>
        <Text element="p" pattern="body" color="grey-650" my={1}>
          {t('overview.turnout-banner.muchLower.message.preLinkText') + ' '}
          <a href="https://hopin.com/blog/13-tips-for-successful-virtual-events-from-team-hopin">
            {t('overview.turnout-banner.muchLower.message.linkText')}
          </a>{' '}
          {t('overview.turnout-banner.muchLower.message.postLinkText')}
        </Text>
      </>
    ),
    lower: (
      <>
        <Text
          element="h5"
          pattern="headingFive"
          color="grey-800"
          weight="medium"
        >
          {t('overview.turnout-banner.lower.headline')}
        </Text>
        <Text element="p" pattern="body" color="grey-650" my={1}>
          {t('overview.turnout-banner.lower.message.preLinkText') + ' '}
          <a href="https://hopin.com/blog/13-tips-for-successful-virtual-events-from-team-hopin">
            {t('overview.turnout-banner.lower.message.linkText')}
          </a>{' '}
          {t('overview.turnout-banner.lower.message.postLinkText')}
        </Text>
      </>
    ),
    average: (
      <>
        <Text
          element="h5"
          pattern="headingFive"
          color="grey-800"
          weight="medium"
        >
          {t('overview.turnout-banner.average.headline')}
        </Text>
        <Text element="p" pattern="body" color="grey-650" my={1}>
          {t('overview.turnout-banner.average.message')}
        </Text>
      </>
    ),
    higher: (
      <>
        <Text
          element="h5"
          pattern="headingFive"
          color="grey-800"
          weight="medium"
        >
          {t('overview.turnout-banner.higher.headline')}
        </Text>
        <Text element="p" pattern="body" color="grey-650" my={1}>
          {t('overview.turnout-banner.higher.message', {
            percentageDiff: percentage - average,
          })}
        </Text>
      </>
    ),
  };

  switch (true) {
    case percentage >= average + 1:
      return texts.higher;
    case percentage >= average - 10 && percentage < average + 1:
      return texts.average;
    case percentage >= average - 20 && percentage < average - 10:
      return texts.lower;
    default:
      return texts.muchLower;
  }
};

const TurnoutBannerComponent = ({ eventSlug }) => {
  const { t } = useContext(LocalizationContext);
  const [turnoutRatio, setTurnoutRatio] = useState(0);
  const [averageTurnoutRatio, setAverageTurnoutRatio] = useState(0);

  const loading = usePromise(
    signal => analyticsApi.getTurnout(eventSlug, signal),
    result => {
      setTurnoutRatio(result.ratio);
      setAverageTurnoutRatio(result.average_ratio);
    },
  );

  const turnoutPercentage = Math.round(turnoutRatio * 100);
  const averageTurnoutPercentage = Math.round(averageTurnoutRatio * 100);

  return (
    <TurnoutCard isLoading={loading}>
      <Wrapper>
        <Progress>
          <CircularProgressbar
            value={turnoutPercentage}
            text={`${turnoutPercentage}%`}
            strokeWidth={4}
            styles={buildStyles({
              textSize: '20px',
              pathColor: graphColor60,
              textColor: gray800,
              trailColor: gray300,
            })}
          />
        </Progress>
        <TextContainer>
          {turnoutPercentageToText(
            t,
            turnoutPercentage,
            averageTurnoutPercentage,
          )}
        </TextContainer>
      </Wrapper>
    </TurnoutCard>
  );
};

TurnoutBannerComponent.propTypes = {
  eventSlug: string.isRequired,
};

export default withLocalizationProvider(TurnoutBannerComponent);
