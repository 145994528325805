import 'chart.js';

import CardTitle from '@features/dashboard/components/card-title';
import { graphColorsArray2 } from '@features/dashboard/util/colors';
import { useLocalization } from '@features/localization';
import PropTypes from 'prop-types';
import React from 'react';
import { GeoChart } from 'react-chartkick';

const RegistrationsByCountryComponent = ({ countryData }) => {
  const { t } = useLocalization(
    'registrations-dashboard.registrations-by-country-component',
  );

  return (
    <div data-testid="registrations-by-country">
      <CardTitle>{t('title')}</CardTitle>
      <GeoChart
        data={countryData}
        colors={graphColorsArray2}
        label={t('label')}
        library={{ regioncoderVersion: 1 }}
      />
    </div>
  );
};

RegistrationsByCountryComponent.propTypes = {
  countryData: PropTypes.array.isRequired,
};

export default RegistrationsByCountryComponent;
