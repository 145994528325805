import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Modal } from '@hopin-team/ui-modal';
import { bool, func, node, object, string } from 'prop-types';
import React from 'react';

import {
  ConfirmModalContainer,
  ConfirmModalContent,
  ConfirmModalFooter,
  ConfirmModalHeader,
} from './confirm-modal-styles';

export const ConfirmModal = ({
  open,
  onCancel,
  onConfirm,
  title,
  message,
  actions,
  label,
  confirmButtonProps,
  cancelButtonProps,
}) => {
  const { t } = useLocalization();
  return (
    <Modal
      describedById="modal-header"
      isShowing={open}
      onClose={onCancel}
      isDismissible
      withCloseButton
      size="small"
      label={label}
    >
      <ConfirmModalContainer>
        <ConfirmModalHeader>{title}</ConfirmModalHeader>
        <ConfirmModalContent>{message}</ConfirmModalContent>
        <ConfirmModalFooter>
          {actions ? (
            actions
          ) : (
            <>
              <Button
                isOutlined
                type="submit"
                onClick={onCancel}
                mr={3}
                size="medium"
                {...cancelButtonProps}
              >
                {cancelButtonProps?.label || t('cancel')}
              </Button>
              <Button
                type="submit"
                pattern="primary"
                onClick={onConfirm}
                size="medium"
                {...confirmButtonProps}
              >
                {confirmButtonProps?.label || t(`common.okay`)}
              </Button>
            </>
          )}
        </ConfirmModalFooter>
      </ConfirmModalContainer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onCancel: func.isRequired,
  open: bool.isRequired,
  onConfirm: func.isRequired,
  title: string,
  message: node,
  actions: node,
  label: string,
  cancelButtonProps: object,
  confirmButtonProps: object,
};
