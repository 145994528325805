import eventDashboardApi from '@api/event-dashboard';
import { Footer } from '@components/side-panel-form';
import { withLocalizationProvider } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Spinner } from '@hopin-team/ui-spinner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { setProfileCategories } from '@/redux/reducers/networking';

import CategoryBlock from '../category-block/category-block';
import CategoryDropdownComponent from '../category-selection/category-dropdown-component';
import { TYPE } from '../category-types';
import {
  getErrorMessageFromDetail,
  getFieldNameFromError,
} from './profile-category-error-helpers';
import { mapResponseToFormFields } from './profile-form-helpers';

export const FORM_NAME = 'categories';

export const ProfileCategoriesForm = ({
  setIsModalDismissable,
  setSavedChanges,
  eventExternalId,
  categoriesData,
  addAlert,
  lockProfileMatching,
  setIsOpen,
  t,
}) => {
  const [openBlockId, setOpenBlockId] = useState();
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    errors,
    setValue,
    formState,
    clearErrors,
    watch,
    getValues,
    reset,
    setError,
  } = useForm({ defaultValues: { categories: categoriesData } });

  useEffect(() => {
    if (formState.isDirty) setSavedChanges(false);
  }, [formState, setSavedChanges]);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: FORM_NAME,
    keyName: 'reactHookFormId', // Required so that we can set our own 'id' key with values from the api
  });

  const { submitCount, isDirty, isSubmitting } = formState;
  const onDragEnd = result => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  };

  const handleSetOptions = (options, index) => {
    if (options.length) {
      clearErrors(`${FORM_NAME}[${index}].tags`);
    }

    setValue(`${FORM_NAME}[${index}].tags`, options, {
      shouldValidate: submitCount > 0,
      shouldDirty: submitCount > 0,
    });
  };

  const handleSetBlockOpen = id => setOpenBlockId(id);

  useEffect(() => {
    if (isAddingCategory) {
      handleSetBlockOpen(fields[fields.length - 1].reactHookFormId);
      setIsAddingCategory(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length]);

  const onSubmit = async values => {
    const formattedCategoryData = values.categories
      ? values.categories.map(category => {
          if (category.tags.length) {
            return {
              ...category,
              tags: category.tags.map(tag => {
                return { name: tag.value, ...(tag.id && { id: tag.id }) };
              }),
            };
          }
          return category;
        })
      : [];

    const isEditing = categoriesData?.length;

    try {
      const api = isEditing
        ? eventDashboardApi.updateProfileCategories
        : eventDashboardApi.createProfileCategories;

      const { data, included, errors } = await api(
        eventExternalId,
        formattedCategoryData,
      );

      if (data) {
        reset(values);
        setSavedChanges(true);
        addAlert({
          active: true,
          text: t('networking.profile-setup.categories-saved'),
          pattern: 'success',
        });

        const formData = mapResponseToFormFields(data, included);
        dispatch(setProfileCategories(formData));
        setIsOpen(false);
      } else if (errors) {
        errors.forEach(error => {
          const errorFieldName =
            error.source?.pointer &&
            getFieldNameFromError(error.source.pointer);
          const errorMessage = getErrorMessageFromDetail(error.detail);

          if (errorFieldName) {
            setError(errorFieldName, {
              message: errorMessage
                ? t(errorMessage)
                : t('networking.profile-setup.field-invalid'),
            });
          } else {
            addAlert({
              active: true,
              text: errorMessage
                ? t(errorMessage)
                : t('networking.something-went-wrong'),
              pattern: 'error',
            });
          }
        });
      }
    } catch (err) {
      addAlert({
        active: true,
        text: t('networking.something-went-wrong'),
        pattern: 'error',
      });
    }
  };

  return (
    <>
      <Box px={3} isRelative>
        <CategoryDropdownComponent
          lockProfileMatching={lockProfileMatching}
          isAtCategoryLimit={getValues()?.categories?.length >= 4}
          onDropdownSelection={categoryType => {
            setIsAddingCategory(true);
            append({
              name: '',
              type: categoryType,
              options: [],
            });
          }}
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={14} px={3} pt={3}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => {
                    return (
                      <Draggable
                        key={field.reactHookFormId}
                        draggableId={field.reactHookFormId}
                        index={index}
                        isDragDisabled={lockProfileMatching}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              marginBottom: `1rem`,
                              ...provided.draggableProps.style,
                            }}
                          >
                            <CategoryBlock
                              dragHandleProps={provided.dragHandleProps}
                              index={index}
                              watchedCategoryName={watch(
                                `${FORM_NAME}[${index}].name`,
                              )}
                              watchedCategoryType={
                                watch(`${FORM_NAME}[${index}].type`) ||
                                field.type
                              }
                              control={control}
                              category={field}
                              fieldNamePrefix={`${FORM_NAME}[${index}]`}
                              errors={
                                errors.categories && errors.categories[index]
                              }
                              setIsModalDismissable={setIsModalDismissable}
                              onDeleteBlock={index => remove(index)}
                              setOptions={options =>
                                handleSetOptions(options, index)
                              }
                              isBlockOpen={
                                field.reactHookFormId === openBlockId
                              }
                              setBlockOpen={handleSetBlockOpen}
                              getValues={getValues}
                              t={t}
                              lockProfileMatching={lockProfileMatching}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        <StyledFooter>
          <Button
            isInline
            size="small"
            disabled={
              (!isDirty && !fields.length) ||
              isSubmitting ||
              lockProfileMatching
            }
            type="submit"
          >
            {isSubmitting ? (
              <Spinner isShowing={true} />
            ) : (
              t('networking.profile-setup.cta-two')
            )}
          </Button>
        </StyledFooter>
      </form>
    </>
  );
};

export default withLocalizationProvider(ProfileCategoriesForm);

ProfileCategoriesForm.propTypes = {
  setIsModalDismissable: PropTypes.func,
  setSavedChanges: PropTypes.func,
  setIsOpen: PropTypes.func,
  eventExternalId: PropTypes.string.isRequired,
  categoriesData: PropTypes.arrayOf(
    PropTypes.shape({
      reactHookFormId: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.oneOf([TYPE.MULTI, TYPE.SINGLE]).isRequired,
      name: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
          .isRequired,
      ),
    }),
  ),
  addAlert: PropTypes.func,
  t: PropTypes.func.isRequired,
  lockProfileMatching: PropTypes.bool,
};

const StyledFooter = styled(Footer)`
  position: fixed;
  bottom: 0;
  width: 100%;
`;
