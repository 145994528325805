import hopinApi from '@api/hopin';
import {
  AuthenticationContext,
  AuthenticationProvider,
} from '@hopin-team/provider-authentication';
import getDisplayName from '@util/get-display-name';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setToken } from './auth-slice';

const AuthReduxProvider = ({ children }) => {
  const { isLoading, hasError, authenticationToken } = useContext(
    AuthenticationContext,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !hasError) {
      dispatch(setToken(authenticationToken));
    }
  }, [authenticationToken, isLoading, hasError, dispatch]);

  return children;
};

AuthReduxProvider.propTypes = {
  children: PropTypes.element,
};

export const withAuthProvider = WrappedComponent => {
  const WithRootPropsProvider = props => {
    return (
      <AuthenticationProvider tokenProvider={hopinApi.getUserToken}>
        <AuthReduxProvider>
          <WrappedComponent {...props} />
        </AuthReduxProvider>
      </AuthenticationProvider>
    );
  };

  WithRootPropsProvider.displayName = `WithRootPropsProvider(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithRootPropsProvider;
};

export default AuthReduxProvider;
