import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import {
  getRecordingById,
  getSecondaryLinks,
} from '@features/recordings/utils/utils';
import { ConfirmationDialog } from '@hopin-team/ui-confirmation-dialog';
import * as Routes from '@routes';
import compose from 'lodash/fp/compose';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RECORDING_CATEGORY } from '../constants';
import { NoResults } from '../no-results';
import { selectEvent } from '../redux/slices/event/event-selectors';
import { selectVideoAreaById } from '../redux/slices/recording-groups/recording-groups-selectors';
import { fetchVideoAreaRecordingsThunk } from '../redux/slices/recording-groups/recording-groups-slice';
import { recordingsActions } from '../redux/slices/recordings/recordings-actions';
import { selectRecordingsByVideoArea } from '../redux/slices/recordings/recordings-selectors';
import {
  deleteRecordingThunk,
  updateRecordingThunk,
} from '../redux/slices/recordings/recordings-slice';
import { RecordingGroup } from './recording-group';
import { RecordingGroupSection } from './recording-group-container.styled';

const RecordingGroupContainer = ({
  videoAreaId,
  category,
  showTitle = true,
  showLinks = true,
  filters,
  noResultsStyle = 'small',
  onRecordingSelect,
  customThumbnailsEnabled,
}) => {
  const { addAlert } = useContext(AlertsContext);
  const { slug, timeStart, timeEnd } = useSelector(selectEvent);
  const { t } = useLocalization();
  const dispatch = useDispatch();

  const videoArea = useSelector(state => {
    return selectVideoAreaById(state, videoAreaId);
  });

  const fetchRecordings = useCallback(
    () =>
      dispatch(
        fetchVideoAreaRecordingsThunk({
          videoAreaType: videoArea.type,
          videoAreaId: videoArea.id,
          category,
        }),
      ),
    [videoArea.type, videoArea.id, category, dispatch],
  );

  useEffect(() => {
    dispatch(
      fetchVideoAreaRecordingsThunk({
        videoAreaType: videoArea.type,
        videoAreaId: videoArea.id,
        category,
      }),
    );
  }, [category, videoArea.type, videoArea.id, dispatch]);

  const { isLoading, isError } = videoArea;

  const recordings = useSelector(state =>
    selectRecordingsByVideoArea(state, videoArea, filters),
  );

  const showRecordingGroup =
    videoArea?.recordingIds?.length > 0 || noResultsStyle === 'small';

  const recordingTypeEndTime =
    category === RECORDING_CATEGORY.REHEARSAL ? timeStart : timeEnd;

  const [recordingIdToDelete, setRecordingIdToDelete] = useState(null);

  const onRecordingClick = useCallback(
    recording =>
      (window.location.href = Routes.organisersEventRecordingPath(
        slug,
        recording.id,
      )),
    [slug],
  );

  const handleRecordingChange = useCallback(
    (id, attributes) => {
      const recording = getRecordingById(recordings, id);
      dispatch(
        updateRecordingThunk({
          attributes,
          recording,
          onSuccess: () =>
            addAlert({
              active: true,
              text: t('recording-group.recording-successfully-updated'),
              pattern: 'success',
            }),
          onError: () =>
            addAlert({
              active: true,
              text: t('recording-group.something-went-wrong-try-later'),
              pattern: 'error',
            }),
        }),
      );
    },
    [recordings, dispatch, addAlert, t],
  );

  const handleRecordingDelete = useCallback(id => setRecordingIdToDelete(id), [
    setRecordingIdToDelete,
  ]);

  const handleRecordingDownload = useCallback(
    recording => {
      dispatch(
        recordingsActions.recordingGroupContainer.recordingDownloaded({
          recording,
          method: 'Recording',
        }),
      );
    },
    [dispatch],
  );

  const cleanUpRecordingDeletion = useCallback(
    () => setRecordingIdToDelete(null),
    [setRecordingIdToDelete],
  );

  const finishRecordingDeletion = useCallback(async () => {
    await dispatch(
      deleteRecordingThunk({
        id: recordingIdToDelete,
        onSuccess: () =>
          addAlert({
            active: true,
            text: t('recording-group.recording-successfully-deleted'),
            pattern: 'success',
          }),
        onError: () =>
          addAlert({
            active: true,
            text: t('recording-group.something-went-wrong-try-later'),
            pattern: 'error',
          }),
      }),
    );

    cleanUpRecordingDeletion();
  }, [cleanUpRecordingDeletion, recordingIdToDelete, dispatch, addAlert, t]);

  const { rehearsalHref, backupHref } = getSecondaryLinks(slug, videoArea);

  return (
    <RecordingGroupSection
      data-testid="recordingGroup"
      data-video-area-id={videoArea.id}
    >
      <Alerts />

      {showRecordingGroup ? (
        <RecordingGroup
          title={showTitle ? videoArea.name : null}
          rehearsalHref={showLinks ? rehearsalHref : null}
          backupHref={showLinks ? backupHref : null}
          videoAreaType={videoArea.type}
          recordings={recordings}
          isError={isError}
          isLoading={isLoading}
          onRecordingClick={onRecordingClick}
          onRecordingDelete={handleRecordingDelete}
          onRecordingUpdate={handleRecordingChange}
          onRecordingDownload={handleRecordingDownload}
          refetchRecordings={fetchRecordings}
          endTime={recordingTypeEndTime}
          onRecordingSelect={onRecordingSelect}
          customThumbnailsEnabled={customThumbnailsEnabled}
          category={category}
          recordingsRootUrl={Routes.organisersEventRecordingsPath(slug)}
        />
      ) : (
        <NoResults
          title={videoArea.name}
          category={category}
          endTime={recordingTypeEndTime}
        />
      )}

      <ConfirmationDialog
        isShowing={recordingIdToDelete && !isLoading}
        title={t('delete-recording-confirmation-modal.title')}
        message={t('delete-recording-confirmation-modal.message')}
        primaryButtonText={t('delete-recording-confirmation-modal.confirm')}
        colorPattern="danger"
        secondaryButtonText={t('delete-recording-confirmation-modal.cancel')}
        isDismissible
        withCloseButton
        onClose={cleanUpRecordingDeletion}
        onConfirm={finishRecordingDeletion}
        onCancel={cleanUpRecordingDeletion}
      />
    </RecordingGroupSection>
  );
};

RecordingGroupContainer.propTypes = {
  videoAreaId: PropTypes.string.isRequired,
  category: PropTypes.oneOf([
    RECORDING_CATEGORY.BACKUP,
    RECORDING_CATEGORY.REHEARSAL,
    RECORDING_CATEGORY.MAIN,
    RECORDING_CATEGORY.CUT,
  ]),
  showTitle: PropTypes.bool,
  showLinks: PropTypes.bool,
  filters: PropTypes.shape({
    eventPart: PropTypes.string,
    date: PropTypes.string,
  }),
  noResultsStyle: PropTypes.oneOf(['small', 'large']),
  onRecordingSelect: PropTypes.func.isRequired,
  customThumbnailsEnabled: PropTypes.bool,
};

const RecordingGroupContainerWithProviders = compose(
  withLocalizationProvider,
  withAlertsProvider,
)(RecordingGroupContainer);

export { RecordingGroupContainerWithProviders as RecordingGroupContainer };
