import hopinApi from '@api/hopin';
import { recordingsApi } from '@features/recordings/api/recordingsApi';
import camelizeKeys from '@util/camelize-keys';

import { RECORDING_CATEGORY } from '../constants';
export class ValidationError extends Error {
  constructor(message, field) {
    super(message);
    this.name = 'ValidationError';
    this.field = field;
  }
}

const UNEXPECTED_ERROR_MESSAGE =
  'Something went wrong, please try again later.';

export const getRecordingAreas = async eventSlug => {
  const response = await hopinApi.getRecordingAreas(eventSlug);

  if (response.video_areas) {
    return camelizeKeys(response.video_areas);
  } else {
    throw new ValidationError(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getRecording = async (id, token) => {
  const recording = await recordingsApi.getRecording(id, token);

  return camelizeKeys(recording);
};

export const getRecordings = async (
  eventId,
  token,
  { videoAreaId, videoAreaType, category },
) => {
  const commonParams = {
    event_part_id: videoAreaId,
    event_part_type: videoAreaType,
  };
  let queryParams;

  switch (category) {
    case RECORDING_CATEGORY.CUT:
      queryParams = {
        category: RECORDING_CATEGORY.MAIN,
        originals: false,
        ...commonParams,
      };
      break;

    case RECORDING_CATEGORY.BACKUP:
    case RECORDING_CATEGORY.REHEARSAL:
      queryParams = {
        category,
        ...commonParams,
      };
      break;
    default:
      queryParams = {
        category,
        originals: true,
        ...commonParams,
      };
      break;
  }

  const response = await hopinApi.getRecordings(eventId, token, queryParams);

  return camelizeKeys(response.data);
};

export const getRecordingCuts = async (eventId, token) => {
  const response = await hopinApi.getRecordings(eventId, token, {
    originals: false,
  });

  return camelizeKeys(response.data);
};

export const updateRecording = async (id, attributes, token) => {
  const response = await recordingsApi.updateRecording(id, attributes, token);

  return camelizeKeys(response.recording);
};

export const trimRecording = async (
  id,
  eventSlug,
  { startTimeMs, endTimeMs },
) => {
  const response = await hopinApi.trimRecording(id, eventSlug, {
    start_time_ms: startTimeMs,
    end_time_ms: endTimeMs,
  });

  if (response.success) {
    return camelizeKeys(response.recording);
  } else {
    throw new ValidationError(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getThumbnails = async (recordingId, eventSlug) => {
  const response = await hopinApi.getThumbnails(recordingId, eventSlug);

  return camelizeKeys(response.thumbnails);
};

export const deleteRecording = async (id, authToken) => {
  const response = await hopinApi.deleteRecording(id, authToken);
  return response;
};
