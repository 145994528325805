import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Flex } from '@hopin-team/ui-flex';
import { Linkicon } from '@hopin-team/ui-linkicon';
import {
  QuantityButton,
  QuantityField,
  QuantityInput,
} from '@hopin-team/ui-quantity-field';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { compose } from 'lodash/fp';
import P from 'prop-types';
import React, { useReducer } from 'react';

import EventCustomAddressInput from './event-custom-address-input';

const EventAdvancedSettings = ({
  event,
  ssoConfigured,
  essentialsImprovements,
}) => {
  const { t } = useLocalization('event-advanced-settings');
  const [showAdvanced, setShowAdvanced] = React.useState(false);
  const MIN_MINUTES = 0;
  const MAX_MINUTES = 99999;

  const sessionDisplayActions = {
    INCREMENT_BEFORE: 'incrementBefore',
    DECREMENT_BEFORE: 'decrementBefore',
    SET_BEFORE: 'setBefore',
    INCREMENT_AFTER: 'incrementAfter',
    DECREMENT_AFTER: 'decrementAfter',
    SET_AFTER: 'setAfter',
    SET_MINIMUMS: 'setMinimums',
  };

  const [sessionDisplayMinutes, dispatch] = useReducer(
    (state, action) => {
      const keyedVal = parseInt(action.val);
      switch (action.type) {
        case sessionDisplayActions.INCREMENT_BEFORE:
          return { ...state, before: Math.min(state.before + 1, MAX_MINUTES) };
        case sessionDisplayActions.DECREMENT_BEFORE:
          return { ...state, before: Math.max(state.before - 1, MIN_MINUTES) };
        case sessionDisplayActions.SET_BEFORE:
          return { ...state, before: Math.min(keyedVal, MAX_MINUTES) };
        case sessionDisplayActions.INCREMENT_AFTER:
          return { ...state, after: Math.min(state.after + 1, MAX_MINUTES) };
        case sessionDisplayActions.DECREMENT_AFTER:
          return { ...state, after: Math.max(state.after - 1, MIN_MINUTES) };
        case sessionDisplayActions.SET_AFTER:
          return { ...state, after: Math.min(keyedVal, MAX_MINUTES) };
        case sessionDisplayActions.SET_MINIMUMS:
          return {
            before: Math.max(state.before, MIN_MINUTES),
            after: Math.max(state.after, MIN_MINUTES),
          };
        default:
          return state;
      }
    },
    {
      before: event.extra?.session_visible_before_minutes ?? 5,
      after: event.extra?.session_visible_after_minutes ?? 5,
    },
  );

  return (
    <Box mb={20}>
      <Linkicon
        pattern="primary"
        scale={3}
        label={t('toggle')}
        trailingIcon={showAdvanced ? 'arrow-up' : 'arrow-down'}
        my={5}
        onClick={e => {
          e.preventDefault();
          setShowAdvanced(state => !state);
        }}
      />
      <Box style={{ display: showAdvanced ? 'block' : 'none' }}>
        <Box mb={5}>
          {/* Custom URL */}
          <EventCustomAddressInput eventId={event.external_id} />
        </Box>

        {/* SESSION DISPLAY */}
        <Box mb={5}>
          <Text pattern="headingFour" mb={1} style={{ display: 'block' }}>
            {t('session-display.heading')}
          </Text>
          <Text
            pattern="labelThree"
            mt={1}
            mb={2}
            style={{ display: 'block', fontSize: '0.875rem' }}
          >
            {t('session-display.summary')}
          </Text>

          <Text
            pattern="labelThree"
            mb={1}
            style={{ display: 'block', fontWeight: 500 }}
          >
            {t('session-display.visible-from.label')}
          </Text>

          <Flex alignItems="center" mb={2.5}>
            <QuantityField>
              <QuantityButton
                isDecrement
                onClick={() =>
                  dispatch({ type: sessionDisplayActions.DECREMENT_BEFORE })
                }
                data-testid="button-dec-before"
              />
              <QuantityInput
                data-testid="session-mins-before"
                name="event[extra_attributes][session_visible_before_minutes]"
                value={sessionDisplayMinutes.before}
                onChange={e =>
                  dispatch({
                    type: sessionDisplayActions.SET_BEFORE,
                    val: e.target.value,
                  })
                }
                onBlur={() =>
                  dispatch({ type: sessionDisplayActions.SET_MINIMUMS })
                }
              />
              <QuantityButton
                isIncrement
                onClick={() =>
                  dispatch({ type: sessionDisplayActions.INCREMENT_BEFORE })
                }
                data-testid="button-inc-before"
              />
            </QuantityField>
            <Text pattern="body" ml={1}>
              {t('session-display.visible-from.text')}
            </Text>
          </Flex>

          <Text
            pattern="labelThree"
            mb={1}
            style={{ display: 'block', fontWeight: 500 }}
          >
            {t('session-display.visible-to.label')}
          </Text>

          <Flex alignItems="center">
            <QuantityField>
              <QuantityButton
                isDecrement
                onClick={() =>
                  dispatch({ type: sessionDisplayActions.DECREMENT_AFTER })
                }
                data-testid="button-dec-after"
              />
              <QuantityInput
                data-testid="session-mins-after"
                name="event[extra_attributes][session_visible_after_minutes]"
                value={sessionDisplayMinutes.after}
                onChange={e =>
                  dispatch({
                    type: sessionDisplayActions.SET_AFTER,
                    val: e.target.value,
                  })
                }
                onBlur={() =>
                  dispatch({ type: sessionDisplayActions.SET_MINIMUMS })
                }
              />
              <QuantityButton
                isIncrement
                onClick={() =>
                  dispatch({ type: sessionDisplayActions.INCREMENT_AFTER })
                }
                data-testid="button-inc-after"
              />
            </QuantityField>
            <Text pattern="body" ml={1}>
              {t('session-display.visible-to.text')}
            </Text>
          </Flex>
        </Box>

        {/* SSO */}
        {!essentialsImprovements && ssoConfigured && (
          <Box mb={5}>
            <Text pattern="headingFour" style={{ display: 'block' }}>
              {t('sso.heading')}
            </Text>
            <Text
              pattern="labelThree"
              mt={1}
              mb={2}
              style={{ display: 'block', fontSize: '0.875rem' }}
            >
              {t('sso.summary.pre')}
              <a
                href={Routes.organisersEventRedeemCodesPath(event.slug)}
                className="mt-2 btn-md link"
              >
                {t('sso.summary.link')}
              </a>
              {t('sso.summary.post')}
            </Text>
            <div className="form-check">
              <input type="hidden" name="event[require_sso_login]" value="0" />
              <input
                name="event[require_sso_login]"
                id="require_sso_login"
                defaultChecked={!!event.require_sso_login}
                value="1"
                type="checkbox"
                className="form-check-input"
              />
              <label className="form-check-label" htmlFor="require_sso_login">
                {t('sso.enforce')}
              </label>
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

EventAdvancedSettings.propTypes = {
  event: P.shape({
    event_type: P.oneOf(['hidden_event', 'public_event', 'private_event'])
      .isRequired,
    extra: P.shape({
      limit_multiple_event_connections: P.bool.isRequired,
      session_visible_before_minutes: P.number,
      session_visible_after_minutes: P.number,
    }).isRequired,
    slug: P.string.isRequired,
    password: P.string,
    require_sso_login: P.bool,
    external_id: P.string.isRequired,
  }).isRequired,
  ssoConfigured: P.bool.isRequired,
  essentialsImprovements: P.bool,
};

export default compose(
  withThemeProvider,
  withLocalizationProvider,
)(EventAdvancedSettings);
