import eventDashboardApi from '@api/event-dashboard';
import { yupResolver } from '@hookform/resolvers';
import omit from 'ramda/src/omit';
import { useForm } from 'react-hook-form';
import { boolean, mixed, number, object, setLocale, string } from 'yup';

setLocale({
  mixed: {
    required: `Can't be blank`,
  },
  string: {
    url: 'Must be a valid URL',
  },
});

const schema = object({
  venue_settings: object({
    attendees_visiblity: mixed().oneOf(['show_all', 'show_list', 'show_none']),
    doors_close_after_minutes: number()
      .transform(value => (Number.isNaN(value) ? 0 : value))
      .test('doors-close-org-limit', null, (value, context) => {
        const { closeDoorsLimitMinutes } = context.options.context;
        if (value > closeDoorsLimitMinutes) {
          return context.createError({
            message: `Doors can only close a maximum of ${closeDoorsLimitMinutes} minutes after the event end.`,
          });
        }
        return true;
      }),
    doors_open_before_minutes: number()
      .transform(value => (Number.isNaN(value) ? 0 : value))
      .test('doors-open-org-limit', null, (value, context) => {
        const { openDoorsLimitMinutes } = context.options.context;
        if (value > openDoorsLimitMinutes) {
          return context.createError({
            message: `Doors can only open a maximum of ${openDoorsLimitMinutes} minutes before the event start.`,
          });
        }
        return true;
      }),
    invite_to_video_call: boolean(),
    schedule_meetings: boolean(),
    schedule_meetings_pre_event: boolean(),
    my_agenda: boolean(),
    giphy_enabled: boolean(),
    moderated_questions: boolean().required(),
    add_session: boolean(),
    side_panel_config: object({
      chat: object({
        event: boolean(),
        stage: boolean(),
        sessions: boolean(),
        expo: boolean(),
      }),
      polls: object({
        event: boolean(),
        stage: boolean(),
        sessions: boolean(),
        expo: boolean(),
      }),
      'q-and-a': object({
        event: boolean(),
        stage: boolean(),
        sessions: boolean(),
        expo: boolean(),
      }),
    }),
    limit_multiple_event_connections: boolean(),
    pre_event_enabled: boolean(),
    pre_event_duration_amount: number()
      .nullable()
      .transform(val => (isNaN(val) ? null : val)),
    pre_event_duration_unit: string().nullable(),
  }),
});

const useVenueSettingsForm = ({
  id,
  attributes: {
    attendeesVisiblity,
    doorsCloseAfterMinutes,
    doorsOpenBeforeMinutes,
    inviteToVideoCall,
    addSession,
    sidePanelConfig,
    giphyEnabled,
    limitMultipleEventConnections,
    preEventSettings,
    scheduleMeetings,
    myAgenda,
    moderatedQuestions,
    scheduleMeetingsPreEvent,
    sendMessages,
    scheduleMeetingExcludedPersonaIds,
    sendDirectMessageExcludedPersonaIds,
    startInstantVideoCallExcludedPersonaIds,
  },
  meta: { closeDoorsLimitMinutes, openDoorsLimitMinutes, showClearChat },
  onError,
  onSuccess,
}) => {
  const defaultValues = {
    venue_settings: {
      attendees_visiblity: attendeesVisiblity,
      doors_close_after_minutes: doorsCloseAfterMinutes || '0',
      doors_open_before_minutes: doorsOpenBeforeMinutes || '0',
      schedule_meetings: scheduleMeetings,
      schedule_meetings_pre_event: scheduleMeetingsPreEvent,
      my_agenda: myAgenda,
      giphy_enabled: giphyEnabled,
      moderated_questions: moderatedQuestions,
      invite_to_video_call: inviteToVideoCall,
      add_session: addSession,
      side_panel_config: sidePanelConfig,
      limit_multiple_event_connections: limitMultipleEventConnections,
      pre_event_enabled: preEventSettings.preEventEnabled,
      pre_event_duration_amount: preEventSettings.preEventDurationAmount,
      pre_event_duration_unit: preEventSettings.preEventDurationUnit ?? 'days',
      send_messages: sendMessages,
      schedule_meeting_excluded_persona_ids: scheduleMeetingExcludedPersonaIds,
      schedule_meeting_excluded_tickets_checkbox: !!scheduleMeetingExcludedPersonaIds?.length,
      send_direct_message_excluded_persona_ids: sendDirectMessageExcludedPersonaIds,
      send_direct_message_excluded_tickets_checkbox: !!sendDirectMessageExcludedPersonaIds?.length,
      start_instant_video_call_excluded_persona_ids: startInstantVideoCallExcludedPersonaIds,
      start_instant_video_call_excluded_tickets_checkbox: !!startInstantVideoCallExcludedPersonaIds?.length,
    },
  };

  const { handleSubmit, ...api } = useForm({
    context: {
      closeDoorsLimitMinutes,
      openDoorsLimitMinutes,
      showClearChat,
    },
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleHookFormSubmit = async values => {
    try {
      // TODO(kacper): do we have to omit those fields?
      const formattedData = omit(
        [
          'schedule_meeting_excluded_tickets_checkbox',
          'send_direct_message_excluded_tickets_checkbox',
          'start_instant_video_call_excluded_tickets_checkbox',
        ],
        values.venue_settings,
      );

      const { data, errors } = await eventDashboardApi.updateVenueSettings(
        id,
        formattedData,
      );

      if (data) {
        onSuccess(data);
      } else if (errors) {
        onError(errors);
      }
    } catch (err) {
      onError('Something went wrong');
    }
  };

  return {
    onSubmit: handleSubmit(handleHookFormSubmit),
    ...api,
  };
};

export default useVenueSettingsForm;
