import Cookies from 'js-cookie';

import { COOKIE_LOOKUP_KEY_LANG, EN, localeFileMap } from './constants';

const isLanguageSupported = language =>
  Object.keys(localeFileMap).includes(language);

export const getDefaultLanguage = () => {
  const fallbackLanguage = EN;
  const i18nextPreference = Cookies.get(COOKIE_LOOKUP_KEY_LANG);
  const browserLanguage = navigator.language.split('-')[0];
  if (isLanguageSupported(i18nextPreference)) {
    return i18nextPreference;
  }
  if (isLanguageSupported(browserLanguage)) {
    return browserLanguage;
  }
  return fallbackLanguage;
};

/**
 * @param {string} language
 * @param {number} count
 * @returns {string} plural form
 */
export const getPluralForm = (language = 'en', count) => {
  const { Intl } = window;
  if (Intl?.PluralRules) {
    return new Intl.PluralRules(language).select(count);
  }

  // This basic fallback is designed only for cardinal numbers in en, de and ja
  // --these langs have only `one` or `other`-plural forms (ja has only 'other').
  const isJapanese = language.match(/^ja\b/i);
  if (!isJapanese && count === 1) {
    return 'one';
  }
  return 'other';
};

/**
 * @param {string} hostname
 * @returns {string} cookie domian
 */
export const getLocalizationCookieDomain = hostname => {
  const domain =
    (hostname && hostname?.match(/(hopin(?:internal)?\.com)$/)?.[0]) ||
    (hostname &&
      hostname.endsWith('events.ringcentral.com') &&
      'events.ringcentral.com') ||
    'hopin.com';

  return `.${domain}`;
};
