import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Centered = styled.span`
  display: block;
  text-align: center;
`;

const ChallengeHeader = ({ oauthBrand, image, email, t }) => (
  <Flex alignItems="center" flexDirection="column">
    {oauthBrand === undefined && image}

    <Text mt={1} pattern="headingTwo" element="p">
      <Centered>{t(`.title`, { oauthBrand: oauthBrand })}</Centered>
    </Text>
    <Text mb={2} element="p">
      <Centered>{t(`.subtitle`, { email: email })}</Centered>
    </Text>
  </Flex>
);

ChallengeHeader.propTypes = {
  oauthBrand: string,
  image: node.isRequired,
  email: string,
  t: func.isRequired,
};

export default ChallengeHeader;
