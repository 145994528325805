import { useLocalization } from '@features/localization';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

const useSpeakerForm = (defaultValues, onSubmitHandler) => {
  const { t } = useLocalization('event-creation-wizard.steps.speakers.form');

  const { errors, register, setValue, handleSubmit, formState } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(
      object().shape({
        headline: string().trim(),
        ['last_name']: string().trim().required(t('last-name.required')),
        ['first_name']: string().trim().required(t('first-name.required')),
        email: string()
          .trim()
          .email(t('email.invalid'))
          .required(t('email.required')),
      }),
    ),
  });

  return {
    errors,
    register,
    setValue,
    formState,
    onSubmit: handleSubmit(onSubmitHandler),
  };
};

export default useSpeakerForm;
