import { bool } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import BasicDetailsComponent from './basic-details-component';
import WebinarBasicDetailsComponent from './webinar-basic-details-component';

const BasicDetailsContainer = props => {
  const { id: templateId } = useSelector(state => state.template);
  const isLite = templateId === 'virtual_lite';

  return isLite ? (
    <WebinarBasicDetailsComponent {...props} />
  ) : (
    <BasicDetailsComponent {...props} />
  );
};

BasicDetailsContainer.propTypes = {
  canSkip: bool,
  canSetEventLocation: bool,
};

export default BasicDetailsContainer;
