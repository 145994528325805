import { Icon } from '@hopin-team/ui-icon';
import { oneOf } from 'prop-types';
import React from 'react';

import { StartedStatus, SucceededStatus } from './reports-notification.styles';

function ReportsNotification({ status = null, theme = 'secondary' }) {
  switch (status) {
    case 'failed':
      return (
        <Icon
          color={theme === 'secondary' ? 'grey-300' : 'red-500'}
          name="warning"
          scale={1.5}
          data-testid="reports-notification-failed"
        />
      );
    case 'running':
    case 'started':
      return (
        <StartedStatus
          isShowing={true}
          theme={theme}
          data-testid="reports-notification-started"
        />
      );
    case 'succeeded':
      return (
        <SucceededStatus
          role="status"
          size="large"
          theme={theme}
          data-testid="reports-notification-succeeded"
        />
      );
    default:
      return <span data-testid="reports-notification-null" />;
  }
}

ReportsNotification.propTypes = {
  status: oneOf(['failed', 'started', 'succeeded', 'running', null]),
  theme: oneOf(['primary', 'secondary']),
};

export default ReportsNotification;
