export const LOGGER_NAMES = {
  ANALYTICS: 'analytics',
  EVENT_BRANDING: 'event-branding',
  EVENT_DASH_HEADER: 'event-dash-header',
  EVENT_DASH_NAV: 'event-dash-nav',
  EVENT_DUPLICATION: 'event-duplication',
  EVENT_TEMPLATES: 'event-templates',
  VENUE_SETTINGS: 'venue-settings',
  ONSITE: 'onsite',
  MARKETING_SETTINGS: 'marketing-settings',
  INTEGRATIONS_PANEL: 'integrations-panel',
  SCHEDULES_PANEL: 'schedules-panel',
  ADD_EXPO_BOOTH: 'add-expo-booth',
  BOOTH_PREVIEW: 'booth-preview',
  SPONSORS_GRID: 'sponsor-grid',
  SPONSORS_TABLE: 'sponsor-table',
  ONBOARDING_FLOW: 'onboarding-flow',
  CUSTOMER_PORTAL: 'billing-panel',
  CUSTOM_TEXT: 'custom-text',
  NETWORKING: 'networking',
  EVENT_CREATION_WIZARD: 'event-creation-wizard',
  PEOPLE: 'people',
  VENDORS: 'vendors',
  COMMUNICATIONS: 'communications',
  REGISTRATIONS: 'registrations',
  SPEAKERS: 'speakers',
  SESSIONS: 'sessions',
  REPLAY: 'replay',
  DEMO: 'demo-event',
  HELP_CENTER: 'help-center',
  SURVEY_BUILDER: 'survey-builder',
};

export const OWNERSHIP_TAGS = {
  ANALYTICS: 'pod-analytics',
  AUDIENCE_ENGAGEMENT: 'pod-audience-engagement',
  CONNECTIONS: 'pod-connections',
  EVENT_CREATION: 'pod-event-creation',
  MONEY: 'pod-money',
  PARTNERS: 'pod-partners',
  SELF_SERVE: 'self-serve',
  PRODUCT_GROWTH: 'pod-product-growth',
};
