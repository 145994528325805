/**
 * Constants for sponsor grid modals
 */
export const MODALS = {
  ADD_EXISTING_SPONSORS: 'ADD_EXISTING_SPONSORS',
  CREATE_AND_ADD_SPONSOR: 'CREATE_AND_ADD_SPONSOR',
};

export const LEVELS = {
  GOLD: 'gold',
  SILVER: 'silver',
  BRONZE: 'bronze',
  TIER4: 'tier 4',
  TIER5: 'tier 5',
  TIER6: 'tier 6',
  TIER7: 'tier 7',
  TIER8: 'tier 8',
  TIER9: 'tier 9',
  TIER10: 'tier 10',
  NOTIER: 'no_tier',
};
