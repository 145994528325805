import ImagePanel from '@features/event-creation-wizard/components/image-panel';
import { element } from 'prop-types';
import React from 'react';

const EventCreationDynamicGraphic = ({ preview }) => (
  <ImagePanel data-testid="dynamic-graphic">{preview}</ImagePanel>
);

EventCreationDynamicGraphic.propTypes = {
  preview: element,
};

export default EventCreationDynamicGraphic;
