import { Toggle as UIToggle } from '@hopin-team/ui-toggle';
import styled from 'styled-components';

export const Container = styled.div`
  padding: var(--spacing-16) var(--spacing-24);
  border-top: 1px solid var(--color-gray-300);
`;

export const Toggle = styled(UIToggle)`
  user-select: none;
  color: var(--color-gray-800);

  span::before {
    background-color: var(--color-gray-700);
  }

  span::before,
  span::after {
    margin-left: 0;
  }
`;
