import compose from 'lodash/fp/compose';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import withReduxProvider from '@/redux/with-provider';

import { Layout } from './generic-component-loader-styles';
import { componentRoutes, componentsWithoutLayout } from './nav-config';
import withConnectedRouter from './with-connected-router';

const HIDDEN = 'none';
const SHOWN = '';

const setDisplay = (element, value) => {
  if (element) {
    element.style.display = value;
  }
};

const ComponentRoute = props => {
  const match = useRouteMatch(props.path);
  useEffect(() => {
    const mainContent = document.getElementById('event-dashboard-main-content');
    const genericComponentLoader = document.getElementById(
      'generic-component-loader',
    );

    setDisplay(mainContent, match ? HIDDEN : SHOWN);
    setDisplay(genericComponentLoader, match ? SHOWN : HIDDEN);

    return () => {
      setDisplay(mainContent, SHOWN);
      setDisplay(genericComponentLoader, HIDDEN);
    };
  }, [match]);

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Route {...props} />
    </>
  );
};

ComponentRoute.propTypes = {
  path: string,
  title: string,
};

export const GenericPageComponentLoader = ({
  routes = componentRoutes,
  organiserScheduleVersion,
  orgId,
  eventId,
  eventSlug,
  onsiteAdvancedAddOnEnabled,
}) => (
  <Switch>
    {routes.map(({ path, Component, id, ...props }) => {
      const withLayout = !componentsWithoutLayout.includes(id);
      return (
        <ComponentRoute path={path} key={path} {...props}>
          <Layout withLayout={withLayout}>
            <Component
              organiserScheduleVersion={organiserScheduleVersion}
              orgId={orgId}
              eventId={eventId}
              eventSlug={eventSlug}
              onsiteAdvancedAddOnEnabled={onsiteAdvancedAddOnEnabled}
            />
          </Layout>
        </ComponentRoute>
      );
    })}
  </Switch>
);

GenericPageComponentLoader.propTypes = {
  routes: arrayOf(
    shape({
      id: string,
      path: string,
      title: string,
      Component: func,
    }),
  ),
  organiserScheduleVersion: string,
  orgId: string.isRequired,
  eventId: number.isRequired,
  eventSlug: string.isRequired,
  onsiteAdvancedAddOnEnabled: bool,
};

export default compose(
  withReduxProvider,
  withConnectedRouter,
)(GenericPageComponentLoader);
