import { createSlice } from '@reduxjs/toolkit';

const initialState = 'hidden_event';

const template = createSlice({
  name: 'eventType',
  initialState,
  reducers: {
    setEventType: (state, action) => action.payload,
  },
});

const {
  reducer,
  actions: { setEventType },
} = template;

export { setEventType };

export default reducer;
