import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Checkbox } from '@hopin-team/ui-checkbox';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { object } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';

const VideoNetworking = ({ control }) => {
  const { t } = useLocalization('venue-controls.video-networking');

  const {
    field: { onChange, value, name },
  } = useController({
    name: 'venue_settings[invite_to_video_call]',
    control,
  });

  return (
    <Box mb={3}>
      <Text
        element="h3"
        pattern="headingThree"
        color="grey-800"
        weight="medium"
        scale={4}
        mb={1}
      >
        {t('title-redesign')}
      </Text>
      <Text element="p" color="grey-600" pattern="body">
        {t('heading-redesign')}
      </Text>
      <Flex alignItems="center" mt={1}>
        <Checkbox
          data-testid="limit-connections-checkbox"
          id="venue_settings_invite_to_video_call"
          name={name}
          onChange={event => onChange(event.target.checked)}
          value={value}
          checked={!!value}
          label={t('enable-redesign')}
        />
      </Flex>
    </Box>
  );
};

VideoNetworking.propTypes = {
  control: object.isRequired,
};

export default withLocalizationProvider(VideoNetworking);
