import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import { withLocalizationProvider } from '@features/localization';
import compose from 'ramda/src/compose';

import withReduxProvider from '@/redux/with-provider';

import AttendeesListComponent from './attendees-waitlist-component';

export default compose(
  withThemeProvider,
  withLocalizationProvider,
  withAlertsProvider,
  withReduxProvider,
)(AttendeesListComponent);
