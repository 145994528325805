import { useEffect, useRef, useState } from 'react';

const MAP_MODE_TO_PROPS = {
  default: {
    autoplay: true,
    controls: true,
    fluid: true,
  },
  edit: {
    autoplay: true,
    controls: true,
    fluid: true,
    inactivityTimeout: 0, // do not hide control bar
  },
};

export const getModeProps = (MODE = 'default') =>
  MAP_MODE_TO_PROPS[MODE] || MAP_MODE_TO_PROPS['default'];

export const usePlayerMode = ({
  mode,
  player,
  trimStartTime,
  trimEndTime,
  forcePause,
}) => {
  const isEditMode = mode === 'edit';
  const isPlaying = useRef(false);
  const interval = useRef(false);
  const userSeeking = useRef(false);
  const trimStartTimeRef = useRef(trimStartTime);
  const trimEndTimeRef = useRef(trimEndTime);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if (player) {
      player.on('durationchange', () => {
        setDuration(player.duration());
        trimEndTimeRef.current = Math.ceil(player.duration());
      });
    }
  }, [player]);

  // Adjust Control Bar // TODO: move to the separate hook useMinimalControl???
  useEffect(() => {
    if (isEditMode && player) {
      const controlBar = player.getChild('ControlBar');
      ['PictureInPictureToggle', 'FullscreenToggle'].forEach(child => {
        controlBar.removeChild(controlBar.getChild(child));
      });
    }
  }, [player, isEditMode]);

  useEffect(() => {
    if (isEditMode && player && trimStartTimeRef.current !== trimStartTime) {
      player.currentTime(trimStartTime);
      trimStartTimeRef.current = trimStartTime;
    }
  }, [isEditMode, player, trimStartTime]);

  useEffect(() => {
    if (isEditMode && player && trimEndTimeRef.current !== trimEndTime) {
      player.currentTime(trimEndTime);
      trimEndTimeRef.current = trimEndTime;
    }
  }, [isEditMode, player, trimEndTime]);

  useEffect(() => {
    if (player) {
      const forcePauseOnPlay = () => {
        if (forcePause) {
          player.pause();
        }
      };

      player.on('play', forcePauseOnPlay);

      return () => {
        player.off('play', forcePauseOnPlay);
      };
    }
  }, [player, forcePause]);

  useEffect(() => {
    if (isEditMode && player) {
      const getIsOutsideRange = () => {
        const currentTime = player.currentTime();
        return currentTime
          ? currentTime < trimStartTime || currentTime > trimEndTime
          : false;
      };

      const handleUserSeeked = () => {
        if (getIsOutsideRange() && userSeeking.current) {
          player.pause();
        }
        userSeeking.current = false;
      };

      interval.current = setInterval(() => {
        if (getIsOutsideRange() && !userSeeking.current && isPlaying.current) {
          player.currentTime(trimStartTime);
        }
      }, 100);

      const handlePlay = () => {
        isPlaying.current = true;
      };

      const handlePause = () => {
        isPlaying.current = false;
      };

      player.controlBar.progressControl.on('mousedown', () => {
        userSeeking.current = isPlaying.current;
      });

      player.on('play', handleUserSeeked);
      player.on('play', handlePlay);
      player.on('pause', handlePause);

      return () => {
        player.off('play', handleUserSeeked);
        player.off('play', handlePlay);
        player.off('pause', handlePause);
        if (interval.current) {
          clearInterval(interval.current);
        }
      };
    }
  }, [player, isPlaying, trimEndTime, trimStartTime, isEditMode, forcePause]);

  return { duration };
};
