import React from 'react';

const InfoSquareIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{ display: 'block' }}
    >
      <path
        opacity="0.4"
        d="M4.25 14H11.75C13.25 14 14 13.25 14 11.75V4.25C14 2.75 13.25 2 11.75 2H4.25C2.75 2 2 2.75 2 4.25V11.75C2 13.25 2.75 14 4.25 14Z"
        fill="#2C3242"
      />
      <path
        d="M8 11.5C7.724 11.5 7.5 11.276 7.5 11V7.95264C7.5 7.67664 7.724 7.45264 8 7.45264C8.276 7.45264 8.5 7.67664 8.5 7.95264V11C8.5 11.276 8.276 11.5 8 11.5ZM8.68001 5.66667C8.68001 5.29867 8.38201 5 8.01335 5H8.00667C7.63867 5 7.34326 5.29867 7.34326 5.66667C7.34326 6.03467 7.64535 6.33333 8.01335 6.33333C8.38135 6.33333 8.68001 6.03467 8.68001 5.66667Z"
        fill="#2C3242"
      />
    </svg>
  );
};

export default InfoSquareIcon;
