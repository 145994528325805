import WarningIcon from 'icons/warning-secondary.svg';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledCustomOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(12)};
  font-weight: 600;
  margin: ${rem(12)};
  border-radius: ${rem(8)};

  background-color: ${({ isSelected, isFocused }) =>
    isFocused || isSelected ? 'var(--color-gray-300)' : 'transparent'};

  &:hover,
  &:active {
    background-color: var(--color-gray-300);
  }
`;

export const StyledSelectContainer = styled.div`
  .-has-tickets {
    .audience-ticket__placeholder {
      color: var(--color-gray-900);
    }
  }
`;

export const StyledTicketCount = styled.span`
  font-weight: normal;
`;

export const StyledMultiValueContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMultiValueContainer = styled.div`
  ${({ isInvalid }) =>
    isInvalid &&
    `
    .audience-ticket__multi-value__label,
    .audience-ticket__multi-value__remove,
    ${StyledMultiValueContent} {
      color: var(--color-gray-600);
      background-color: var(--color-gray-100);
    }
  `}
`;

export const MultiValueWarningIcon = styled(WarningIcon)`
  margin-left: ${rem(5)};
`;
