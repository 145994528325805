import Card from '@components/card-with-loading';
import Sessions from '@components/icons/sessions';
import { useLocalization } from '@features/localization';
import { bool, object } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../dashboard/util/hooks';
import { getTopAreasByCurrentAttendees } from '../dashboard/util/services';
import {
  IconWithBackground,
  TopAreasByCurrentAttendeesEmptyState,
} from './live-analytics-styles';
import TreemapContainer from './treemap/treemap-container';

const TopAreasByCurrentAttendees = ({ event, renameInvitedToPending }) => {
  const keyPrefix = renameInvitedToPending
    ? 'active-attendees-by-area'
    : 'active-users-by-area';
  const { t } = useLocalization(`live-analytics.${keyPrefix}`);

  const [topAreas, setTopAreas] = useState({});
  const loading = usePromise(
    signal => getTopAreasByCurrentAttendees(event.id, signal),
    result => setTopAreas(result),
  );

  const hasAttendeesPresent = topAreas.length > 1;

  return (
    <Card isLoading={loading}>
      <h3 className="heading -level-3">{t('title')}</h3>

      {hasAttendeesPresent && <TreemapContainer data={topAreas} />}

      {!hasAttendeesPresent && (
        <TopAreasByCurrentAttendeesEmptyState>
          <IconWithBackground>
            <Sessions />
          </IconWithBackground>
          {(event.phase === 'Draft' || event.phase === 'Upcoming') && (
            <h4>{t('event-phase.draft-upcoming')}</h4>
          )}
          {event.phase === 'Live' && <h4>{t('event-phase.live')}</h4>}
          {event.phase === 'Ended' && <h4>{t('event-phase.ended')}</h4>}
        </TopAreasByCurrentAttendeesEmptyState>
      )}
    </Card>
  );
};

TopAreasByCurrentAttendees.propTypes = {
  event: object.isRequired,
  renameInvitedToPending: bool.isRequired,
};

export default TopAreasByCurrentAttendees;
