import analyticsApi from '@api/analytics';
import { Histogram } from '@components/bar-chart';
import Card from '@components/card-with-loading';
import CardTitle from '@features/dashboard/components/card-title';
import ChangeRate from '@features/dashboard/components/change-rate/change-rate-component';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import RangePicker from '@features/overview/analytics/common/range-picker/range-picker';
import { number, string } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import convertChartDataToTimezone from '../util/convert-chart-data-to-timezone';

const RegistrationsGraphHeader = styled.div`
  padding-bottom: 2.5rem;

  h2 {
    display: inline;
  }
`;

const barLimit = 75;
const defaultChartHeight = 248;

const fetchData = (query, eventSlug) => signal => {
  return analyticsApi.getRegistrationsOverTime(eventSlug, signal, {
    startTime: query.rangeStart,
    endTime: query.rangeEnd,
    limit: barLimit,
    chartType: 'bar',
  });
};

const handleResults = (
  setData,
  setIsLoadingInitialData,
  query,
  eventTimezone,
) => results => {
  const { chartData, summary } = results;

  const transformedChartData = convertChartDataToTimezone(
    chartData,
    eventTimezone,
  );

  setData({ chartData: transformedChartData, summary });
  setIsLoadingInitialData(false);
};

const initialQueryState = {
  key: 'default',
  rangeStart: null,
  rangeEnd: null,
};

const RegistrationsOverTime = ({ eventSlug, eventTimezone, height }) => {
  const { t } = useLocalization('overview');
  const [{ chartData, summary }, setData] = useState({
    chartData: [],
    summary: {},
  });
  const [query, setQuery] = useState(initialQueryState);
  const [loadingInitialData, setIsLoadingInitialData] = useState(true);

  const loading = usePromise(
    fetchData(query, eventSlug),
    handleResults(setData, setIsLoadingInitialData, query, eventTimezone),
    [query.key],
  );

  // range selector props
  const selectedRangeLoading = loading && !loadingInitialData;
  const rangeSelected = query.key !== 'default';
  const rangePickerProps = {
    displayText: rangeSelected
      ? query.displayText
      : t('range-picker.all-times'),
    loading: selectedRangeLoading,
    rangeSelected: rangeSelected,
    onCancel: () => setQuery(initialQueryState),
    onChange: setQuery,
  };

  return (
    <Card isLoading={loadingInitialData}>
      <CardContentWrapper>
        <RegistrationsGraphHeader>
          <CardTitle>{t('registrations-over-time.title')}</CardTitle>
          <h2>{summary.number}</h2>
          <ChangeRate rate={summary.changeRate} days={summary.changeDays} />

          <RangePicker {...rangePickerProps} />
        </RegistrationsGraphHeader>

        <Histogram
          data={chartData}
          maxBars={barLimit}
          tooltipDescription={t('registrations-over-time.histogram-tooltip')}
          height={height || defaultChartHeight}
        />
      </CardContentWrapper>
    </Card>
  );
};

RegistrationsOverTime.propTypes = {
  eventSlug: string.isRequired,
  eventTimezone: string.isRequired,
  height: number,
};

export default RegistrationsOverTime;
