import { useLocalization } from '@features/localization';
import { Grid } from '@hopin-team/ui-grid';
import { Text } from '@hopin-team/ui-text';
import React from 'react';
import styled from 'styled-components';

const Rating = () => {
  const { t } = useLocalization();
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <StyledGrid
      gridTemplateColumns="repeat(10, 1fr)"
      gridTemplateRows="2.5rem 1rem"
      gridRowGap="0.5rem"
      gridColumnGap="0"
    >
      {options.map(option => (
        <RatingOption key={option}>{option}</RatingOption>
      ))}
      <NotLikely element="p" pattern="captionMedium" color="grey-800">
        {t('survey-builder.edit.not-likely')}
      </NotLikely>
      <Likely element="p" pattern="captionMedium" color="grey-800">
        {t('survey-builder.edit.likely')}
      </Likely>
    </StyledGrid>
  );
};

export default Rating;

const StyledGrid = styled(Grid)`
  max-width: 31.25rem;
`;

const NotLikely = styled(Text)`
  grid-column-start: 1;
  grid-column-end: 3;
`;

const Likely = styled(Text)`
  grid-column-start: 9;
  grid-column-end: 11;
  justify-self: end;
`;

const RatingOption = styled.div`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  color: var(--color-gray-650);
  border: 0.0625rem solid var(--color-gray-300);
  border-left: none;

  &:first-child {
    border-left: 0.0625rem solid var(--color-gray-300);
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }

  &:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
`;
