import maxBy from 'lodash/maxBy';

import convertChartDataToTimezone from '../../../util/convert-chart-data-to-timezone';

const transformResults = (
  results,
  eventPhase,
  eventTimezone,
  rangeSelected,
  t,
) => {
  const [registrationResults, attendeeResults] = results;

  return [
    transformAttendeeResults(
      attendeeResults,
      eventPhase,
      eventTimezone,
      rangeSelected,
      t,
    ),
    transformRegistrationResults(
      registrationResults,
      eventPhase,
      eventTimezone,
      t,
    ),
  ];
};

const transformRegistrationResults = (
  results,
  eventPhase,
  eventTimezone,
  t,
) => {
  const timezonedChartData = convertChartDataToTimezone(
    results.chartData,
    eventTimezone,
  );

  return {
    summary: {
      number: results.summary.number,
      hideChangeRate: true,
      title: t('overview.registrations-active-attendees-over-time')
        .registrations[eventPhase.toLowerCase()]['title'],
      resourceName: t('overview.registrations-active-attendees-over-time')
        .registrations['resource-name'],
    },
    chartData: timezonedChartData,
    iconName: 'ticket',
  };
};

const transformAttendeeResults = (
  results,
  eventPhase,
  eventTimezone,
  rangeSelected,
  t,
) => {
  const chartData = results;
  let count = chartData.length > 0 ? chartData[chartData.length - 1][1] : 0;
  let attendeeTitleKey = eventPhase.toLowerCase();

  // should show maximum # of active attendees if event is over or range is selected
  if (eventPhase === 'Ended' || rangeSelected) {
    count = chartData.length > 0 ? maxBy(chartData, datum => datum[1])[1] : 0;
    attendeeTitleKey = 'rangeSelected';
  }

  const timezonedChartData = convertChartDataToTimezone(
    chartData,
    eventTimezone,
  );

  return {
    summary: {
      number: count,
      hideChangeRate: true,
      title: t('overview.registrations-active-attendees-over-time').attendees[
        attendeeTitleKey
      ].title,
      resourceName: t(
        'overview.registrations-active-attendees-over-time.attendees.resource-name',
      ),
    },
    chartData: timezonedChartData,
    iconName: 'sessions',
  };
};

export default transformResults;
