import { withLocalizationProvider } from '@features/localization';
import * as Routes from '@routes';
import { object } from 'prop-types';
import React from 'react';

import PreviewHeader from './preview-header-component.js';

const PreviewHeaderContainer = ({ event }) => {
  return (
    <PreviewHeader
      section={'Registration page'}
      href={Routes.registrationOrganisersEventPath(event.slug)}
    />
  );
};

PreviewHeaderContainer.propTypes = {
  event: object,
};

export default withLocalizationProvider(PreviewHeaderContainer);
