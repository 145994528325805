import { Button } from '@hopin-team/ui-button';
import { Grid } from '@hopin-team/ui-grid';
import { Tabs } from '@hopin-team/ui-tabs';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

Styles.ScheduleTabs = styled(Tabs)`
  [data-reach-tab-list] {
    width: auto;
  }
  [data-reach-tab-panels] {
    margin-top: ${rem(24)};
  }
`;

Styles.CalendarButton = styled(Button)`
  color: var(--color-gray-800) !important;
`;

Styles.SeeAllSpeakers = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

Styles.Stage = styled(Text)`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${rem(8)};
  }
`;

Styles.ScheduleGrid = styled(Grid)`
  grid-row-gap: ${rem(16)};

  @media (min-width: 481px) {
    grid-template-columns: auto 1fr;
    grid-column-gap: ${rem(48)};
    grid-row-gap: 0px;
  }
`;

Styles.ScheduleSpeakersGrid = styled(Grid)`
  grid-row-gap: ${rem(24)};

  @media (min-width: 481px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
