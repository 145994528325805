import { DateField as UIDateField } from '@hopin-team/ui-date-field';
import { Flex } from '@hopin-team/ui-flex';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: row;

  ${UIDateField} {
    margin-bottom: 0;
    margin-right: ${rem(16)};
  }

  @media (max-width: ${rem(1500)}) {
    flex-direction: column;

    ${UIDateField} {
      margin-bottom: ${rem(16)};
      margin-right: 0;
    }
  }
`;

export const DateField = styled(UIDateField)`
  /* @note: these styles is to solve a style bug in Planetary */
  /* Should be remove once is fixed */
  table {
    width: 100%;
    table-layout: fixed;
  }

  input[type='number'] {
    padding: ${rem(8)};
  }
`;

export const TZIcon = styled.div`
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
`;

export const TZContainer = styled.label`
  position: relative;
  width: 150px;
  cursor: pointer;
`;

export const TZPicker = styled.select`
  background-color: var(--color-white);
  font-size: 0.75rem;
  width: 100%;
  margin-top: 1rem;
  padding-right: 1rem;
  border: 0;
  white-space: nowrap;
  text-decoration: underline;
  text-overflow: ellipsis;
  appearance: none;
  cursor: pointer;
`;
