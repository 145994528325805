import { Box } from '@hopin-team/ui-box';
import { DateField } from '@hopin-team/ui-date-field';
import { Icon } from '@hopin-team/ui-icon';
import { calcSpace } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled from 'styled-components';

import {
  AnnouncementModal,
  AnnouncementsText,
  CenterPanel,
  LinkIcon,
} from '../styles';

export const CenterPanelGrid = styled(CenterPanel)`
  display: grid;
  gap: ${calcSpace(2)} ${calcSpace(3)};
  grid-template-columns: 1fr minmax(0, 1fr);
`;

export const BackLink = styled(LinkIcon)`
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  height: ${rem(24)};
  width: ${rem(24)};
`;

export const EmailDetails = styled(Box)`
  grid-column: 1/3;
`;

export const AttendeesAlert = styled(Box)`
  grid-column: 1/2;
  background-color: var(--color-yellow-100);
  color: var(--color-yellow-600);
  display: flex;
  align-items: center;
  height: ${rem(48)};

  ${AnnouncementsText} {
    margin-left: ${calcSpace(2)};
  }
`;

export const SelectWrapper = styled(Box)`
  grid-column: 1/2;
`;

export const PreviewBody = styled(Box)`
  background-color: var(--color-white);
  border: 1px solid var(--grey-300);
  border-radius: ${rem(4)};
  grid-column: 1/3;
`;

export const SendTestEmailFormWrapper = styled.form`
  label {
    font-weight: 400;
    color: var(--color-gray-600);

    span {
      font-weight: 400;
      color: var(--color-gray-600);
    }
  }
`;

export const TagItem = styled.span`
  display: inline-flex;
  color: var(--color-gray-600);
  border: 1px solid var(--color-gray-400);
  line-height: ${rem(24)};
  padding: 0 ${rem(12)};
  margin-right: ${rem(12)};
  border-radius: ${rem(12)};
`;

export const EditScheduleModalContainer = styled(AnnouncementModal)`
  overflow: auto;

  > div > .hpn-Box,
  > div > .hpn-Box > div {
    overflow: visible;
  }
`;

export const ScheduleEmailInputContainer = styled.div`
  margin: 1rem 0;

  ${DateField} {
    .hpn-DateField__Label {
      display: none;
    }

    table {
      width: 100%;
    }

    td,
    th {
      width: calc(100% / 7);
    }
  }
`;

export const ScheduleInfoItem = styled(Box)`
  display: flex;

  ${Icon} {
    margin: ${rem(4)} ${rem(10)} 0 0;
  }
`;

export const ScheduleInfoItemContent = styled.div``;

export const ScheduleInfoValue = styled(Text)`
  font-weight: 500;
`;

export const ScheduleInfoItemTitle = styled.div`
  font-weight: 600;
`;
