import { TableBodyCell as DefaultTableBodyCell } from '@components/table';
import styled, { css } from 'styled-components';

export const TableBodyCell = styled(DefaultTableBodyCell)`
  &:last-child {
    min-width: 200px;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const EventTableRow = styled.tr`
  height: 68px;
  background: #ffffff;

  img {
    height: 35px;
    width: 61px;
  }

  &:hover {
    background: #f7f8fa;
  }
`;

export const EventRowActions = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 38px;
`;

export const EventRowActionLink = styled.a`
  padding: var(--spacing-4);
  margin: var(--spacing-4);
  display: flex;
  align-items: center;
`;

export const EventRowActionButton = styled.button`
  margin: var(--spacing-8);
`;

export const TableBodyCellWithMinWidth = styled(TableBodyCell)`
  min-width: ${props => props.minWidth};
`;

export const Link = styled.a`
  display: inline-block; // Allows focus ring to surround image/text content

  span:hover,
  span:not(:hover) {
    color: var(--color-gray-600);
  }
`;
