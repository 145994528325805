import * as Routes from '@routes';

export const postSponsor = (apiClient, eventSlug, sponsor) => {
  // Submit data as multipart form (to handle file upload)
  var data = new FormData();

  for (const name in sponsor) {
    data.append(`sponsor[${name}]`, sponsor[name]);
  }

  return apiClient.post(
    `${Routes.organisersEventSponsorsPath(eventSlug)}`,
    data,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};
