import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  type: null,
  attributes: {},
  meta: {},
};

const { actions, reducer } = createSlice({
  name: 'venueSettings',
  initialState,
  reducers: {
    setVenueSettings: (state, action) => action.payload,
  },
});

export const { setVenueSettings } = actions;

export default reducer;
