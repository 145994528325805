import { registrationTicketsRedirect } from '@components/event-progress/utils/registration-routes';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { string } from 'prop-types';
import React, { useContext } from 'react';

import {
  CreateTicketsBody,
  CreateTicketsContainer,
  CreateTicketsContent,
  CreateTicketsTitle,
} from './styles';

export const CreateTickets = ({ eventSlug, registrationsDashboardUrl }) => {
  const { t } = useContext(LocalizationContext);

  const goToTickets = () => {
    window.location.assign(
      `${registrationTicketsRedirect(registrationsDashboardUrl, eventSlug)}`,
    );
  };

  return (
    <CreateTicketsContainer data-testid="create-tickets">
      <img
        alt={t('email-dashboard.edit.create_tickets')}
        src="/images/no-tickets.svg"
      />
      <CreateTicketsBody>
        <CreateTicketsTitle data-testid="create-tickets-title">
          {t('email-dashboard.edit.create_tickets')}
        </CreateTicketsTitle>
        <CreateTicketsContent>
          {t('email-dashboard.edit.you_need_to_add_tickets')}
        </CreateTicketsContent>
        <Button
          data-testid="create-tickets-button"
          size="small"
          isInline
          onClick={goToTickets}
        >
          {t('email-dashboard.edit.add_tickets')}
        </Button>
      </CreateTicketsBody>
    </CreateTicketsContainer>
  );
};

CreateTickets.propTypes = {
  eventSlug: string,
  registrationsDashboardUrl: string,
};
