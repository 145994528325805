import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { bool, func, string } from 'prop-types';
import React from 'react';

import { Card, DefaultContent, HoverContent, RecommendedLabel } from './styles';

const TemplateCard = ({
  title,
  description,
  image,
  recommended = false,
  template,
  selectTemplate,
  previewTemplate,
}) => {
  const { t } = useLocalization();
  const shouldShowStaticCards = template === 'blank';

  return (
    <Card
      as={shouldShowStaticCards ? 'button' : 'div'}
      border="grey-300"
      cornerRadius="lg"
      data-testid="event-template-card"
      shouldShowStaticCards={shouldShowStaticCards}
    >
      <DefaultContent flexWrap="wrap" onClick={() => selectTemplate(template)}>
        <Flex
          alignItems="center"
          justifyContent="center"
          p={2}
          flexBasis="40%"
          flexGrow="1"
        >
          <img src={image} alt={title} />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          p={2}
          flexBasis="60%"
          flexGrow="1"
        >
          <Text pattern="labelTwo" scale={6} font="display" color="grey-800">
            {title}
          </Text>
          <Text pattern="body" scale={4} color="grey-700" align="left">
            {description}
          </Text>
          {recommended && (
            <RecommendedLabel>
              {t('event-creation-wizard.templates.recommended')}
            </RecommendedLabel>
          )}
        </Flex>
      </DefaultContent>
      {!shouldShowStaticCards && (
        <HoverContent flexWrap="wrap">
          <Flex
            alignItems="center"
            justifyContent="space-around"
            p={2}
            flexBasis="40%"
            flexGrow="1"
            flexDirection="row"
            flexWrap="wrap"
          >
            <Button
              isInline
              m={0.5}
              onClick={() => previewTemplate(template)}
              isGhost
            >
              {t('event-creation-wizard.templates.previewTemplate', {
                templateShortName: t(
                  `event-creation-wizard.templates.${template}.shortName`,
                ),
              })}
            </Button>
            <Button isInline m={0.5} onClick={() => selectTemplate(template)}>
              {t('event-creation-wizard.templates.startWith', {
                templateShortName: t(
                  `event-creation-wizard.templates.${template}.shortName`,
                ),
              })}
            </Button>
          </Flex>
        </HoverContent>
      )}
    </Card>
  );
};

TemplateCard.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  image: string.isRequired,
  recommended: bool,
  template: string.isRequired,
  selectTemplate: func.isRequired,
  previewTemplate: func.isRequired,
};

export default TemplateCard;
