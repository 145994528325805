import { Grid } from '@hopin-team/ui-grid';
import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

Styles.VendorsGrid = styled(Grid)`
  row-gap: ${rem(24)};
  column-gap: ${rem(24)};

  @media (min-width: 481px) {
    grid-template-columns: repeat(2, 1fr);
  }

  [class*='BoothCard'] {
    & > div {
      height: 100%;
    }

    & > div > div {
      width: 100%;

      &:nth-child(2) {
        div:nth-child(2) {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    div[src] {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
`;
