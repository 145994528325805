import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import {
  RecordingCard,
  RecordingCardActionItem,
  RecordingCardActionLink,
} from '@hopin-team/ui-recording-card';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { MAX_TITLE_LENGTH } from '../constants';
import { RecordingLink } from '../recording-link';
import { RecordingContainer } from './tile-view.styled';

const TileView = ({
  recordings,
  onRecordingClick,
  onRecordingChange,
  onRecordingDelete,
  onRecordingDownload,
  isShowingDetails,
  onRecordingSelect,
  customThumbnailsEnabled,
}) => {
  const { t, language } = useContext(LocalizationContext);

  const onDownloadClick = recording => {
    if (onRecordingDownload && !recording.isProcessing) {
      onRecordingDownload(recording);
    }
  };

  const onChangeThumbnail = (closeActionMenu, recordingId) => {
    /*
     * The action menu needs to be closed so
     * the different modals would not fight
     * for focus
     */
    closeActionMenu();
    onRecordingSelect(recordingId);
  };

  const onDelete = (closeActionMenu, recordingId) => {
    /*
     * The action menu needs to be closed so
     * the different modals would not fight
     * for focus
     */
    closeActionMenu();
    onRecordingDelete(recordingId);
  };

  return (
    <RecordingContainer>
      {recordings.map(recording =>
        recording.isDeleting ? null : (
          <RecordingCard
            locale={language}
            recording={recording}
            key={recording.id}
            thumbnailOverlayWrapper={RecordingLink}
            onClick={() => onRecordingClick(recording)}
            onRecordingChange={onRecordingChange}
            isShowingDetails={isShowingDetails}
            isPublished={recording.published}
            processingStatusText={t('recording-card.processing-status')}
            processingStatusDetailsText={t(
              'recording-card.processing-status-details',
            )}
            publishedStatusText={t('recording-card.published-status')}
            my={1}
            mx={1.25}
            playRecordingTitle={t('recording-card.play')}
            recordingTrimmingFailedMessage={t('recording-card.trimming-failed')}
            recordingTrimmingProcessingMessage={t(
              'recording-card.trimming-processing',
            )}
            eventPartTypeName={t(
              `recording-card.event-part.${recording.eventPart.type}`,
            )}
            maxTitleLength={MAX_TITLE_LENGTH}
            maxLengthMessage={t('recording-card.reached-character-limit')}
            actionMenuTitle={t('recording-card.toggle-action-menu')}
            publishText={t('recording-card.publish')}
            publishToolTipText={t(
              'recording-card.if-replay-is-enabled-attendees-can-view-published-recordings',
            )}
            renderActionMenu={closeActionMenu => [
              customThumbnailsEnabled ? (
                <RecordingCardActionItem
                  key="change-thumbnail-button"
                  label={t('recording-card.change-thumbnail')}
                  leadingIcon="image-two-tone"
                  onClick={() =>
                    onChangeThumbnail(closeActionMenu, recording.id)
                  }
                />
              ) : null,
              <RecordingCardActionLink
                key="download-button"
                label={t('recording-card.download')}
                leadingIcon="download"
                target="_blank"
                itemUrl={recording.downloadUrl}
                onClick={() => onDownloadClick(recording)}
                disabled={recording.isProcessing}
                download
              />,
              <RecordingCardActionItem
                key="delete-button"
                label={t('recording-card.delete')}
                leadingIcon="delete"
                onClick={() => onDelete(closeActionMenu, recording.id)}
              />,
            ]}
          />
        ),
      )}
    </RecordingContainer>
  );
};

TileView.propTypes = {
  recordings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      playableUrl: PropTypes.string,
      downloadUrl: PropTypes.string.isRequired,
      startedAt: PropTypes.string,
      endedAt: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      title: PropTypes.string,
      duration: PropTypes.number,
      eventPart: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string.isRequired,
      }),
      isProcessing: PropTypes.bool,
    }),
  ),
  onRecordingClick: PropTypes.func.isRequired,
  onRecordingDownload: PropTypes.func,
  onRecordingChange: PropTypes.func,
  isShowingDetails: PropTypes.bool,
  onRecordingDelete: PropTypes.func,
  onRecordingSelect: PropTypes.func,
  customThumbnailsEnabled: PropTypes.bool,
};

TileView.defaultProps = {
  isShowingDetails: true,
};

export default withLocalizationProvider(TileView);
