import { SECONDS_IN_ONE_HOUR, toTimestampString } from '../../../../utils';

/**
 * updates the `aria-valuetext` on an input element with the `value` param
 * expressed as a formatted time offset in seconds, using `mm:ss` or `HH:mm:ss`
 * formats
 * @param {HTMLInputElement?} el
 * @param {number} value
 */
export const setInputAriaValueAsTimeOffset = (el, value) => {
  el?.setAttribute('aria-valuenow', value);
  el?.setAttribute(
    'aria-valuetext',
    toTimestampString(
      value,
      value < SECONDS_IN_ONE_HOUR ? 'mm:ss' : 'HH:mm:ss',
    ),
  );
};

export const adjustValue = (value, selectableMin, selectableMax) => {
  if (value < selectableMin) {
    return selectableMin;
  } else if (value > selectableMax) {
    return selectableMax;
  }

  return value;
};
