import BoothSliceComponent from '@features/expo/booth-slice-component';
import PreviewBoothTab from '@features/expo/vendor-tab/preview-booth-tab';
import { LocalizationContext } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Modal } from '@hopin-team/ui-modal';
import { calcSpace, LINE } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { bool, func, object, shape, string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

const Header = styled.div`
  padding: ${calcSpace(2)} ${calcSpace(3)};
  border-bottom: ${LINE.DEFAULT_BORDER};
`;

const Footer = styled.div`
  padding: ${calcSpace(2)} ${calcSpace(3)};
  border-top: ${LINE.DEFAULT_BORDER};
`;

const Wrapper = styled.div`
  overflow: auto;
`;

const BoothPreviewModal = ({
  vendor,
  isModalDisplayed,
  onCloseHandler,
  flags,
}) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Modal
      isShowing={isModalDisplayed}
      onClose={onCloseHandler}
      isDismissible
      withCloseButton
      label={t('expo.booth.preview.modal.label')}
      size="huge"
    >
      <Header>
        <Text pattern="subHeadingMedium">
          {t('expo.booth.preview.modal.header', {
            vendor: vendor.name,
          })}
        </Text>
      </Header>
      <Wrapper>
        <Flex alignItems="flex-start" justifyContent="center">
          <BoothSliceComponent />
          <PreviewBoothTab vendor={vendor} flags={flags} />
        </Flex>
      </Wrapper>
      <Footer>
        <Flex alignItems="center" justifyContent="flex-end">
          <Button isInline={true} size="small" onClick={onCloseHandler}>
            {t('expo.booth.preview.modal.action')}
          </Button>
        </Flex>
      </Footer>
    </Modal>
  );
};

BoothPreviewModal.propTypes = {
  onCloseHandler: func,
  vendor: shape({
    name: string,
    logoUrl: string,
    headline: string,
    about: string,
    buttonText: string,
    facebook: string,
    instagram: string,
    linkedin: string,
    twitter: string,
    website: string,
  }),
  isModalDisplayed: bool,
  flags: object,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.BOOTH_PREVIEW,
  }),
)(BoothPreviewModal);
