import * as Routes from '@routes';
import request from '@util/request';

export const getLiveAnalyticsChartData = (
  eventId,
  selectedArea,
  startTime,
  endTime,
  defaultEndTime,
  signal,
) =>
  request.get(
    Routes.organisersEventAnalyticsAttendeesLivePath(eventId, {
      area: selectedArea,
      start_time: startTime,
      end_time: endTime,
      default_end_time: defaultEndTime,
    }),
    { signal },
  );

export const getEventAreas = (eventId, signal) =>
  request.get(Routes.organisersEventAnalyticsEventAreasPath(eventId), {
    signal,
  });

export const getTicketSalesOverTime = (eventId, signal) =>
  request.get(Routes.organisersEventTicketSalesOverTimePath(eventId), {
    signal,
  });

export const getMessagesOverTime = (eventId, signal) =>
  request.get(Routes.organisersEventMessagesOverTimePath(eventId), {
    signal: signal,
  });

export const getMeetingsOverTime = (eventId, signal) =>
  request.get(Routes.organisersEventMeetingsOverTimePath(eventId), {
    signal: signal,
  });

export const getTopAreasByCurrentAttendees = (eventId, signal) =>
  request.get(Routes.organisersEventTopAreasByCurrentAttendeesPath(eventId), {
    signal: signal,
  });
