import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const RelatedRecordingContainer = styled(Flex)`
  margin-left: -2px; // Adjusting margin because the shadow is invisible in white background
`;

export const MainColumn = styled.div`
  flex-grow: 1;
`;

export const Skeleton = styled(Flex).attrs({ my: 4 })``;
