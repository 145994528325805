import { Card } from '@hopin-team/ui-card';
import { Modal } from '@hopin-team/ui-modal';
import { ContentsRoot } from '@hopin-team/ui-modal/dist/styled/contents';
import { Overlay } from '@hopin-team/ui-overlay';
import { rem } from 'polished';
import styled from 'styled-components';

export const TicketsModal = styled(Modal)`
  button {
    z-index: 100;
    right: ${rem(24)};
    top: ${rem(24)};
  }

  ${ContentsRoot} {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    background-color: transparent;
    width: 100vw;
    border-radius: 0;

    ${Card} {
      max-height: -webkit-fill-available;
    }
  }

  .widget-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  &.registration-form-layout-v2 {
    button {
      right: ${rem(20)};
      top: ${rem(16)};
    }

    ${ContentsRoot} {
      min-height: 720px;
      max-height: 720px;
      max-width: 984px;
      border-radius: 16px;

      @media (max-width: 770px) {
        min-height: 100vh;
        min-height: -webkit-fill-available;
        max-height: 100vh;
        max-height: -webkit-fill-available;
        border-radius: 0;
      }

      @media (max-height: 720px) {
        min-height: 100vh;
        min-height: -webkit-fill-available;
        max-height: 100vh;
        max-height: -webkit-fill-available;
        max-width: 100vw;
        max-width: -webkit-fill-available;
        border-radius: 0;
      }
    }
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TicketsModalOverlay = styled(Overlay)`
  height: -webkit-fill-available;
  z-index: calc(var(--z-index-events-popup) + 1);
`;
