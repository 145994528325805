const getUpperBound = (maxValue, maxTicks) => {
  // make upper yAxis upper bound easily divisible
  // so that yAxis ticks are easy to read.
  // If upper bound is less than max num ticks,
  // there will be a tick for each number so divisibility isn't a concern

  let upperBound = maxValue;
  if (upperBound > 200000) {
    return roundToNextMultiple(upperBound, 100000);
  } else if (upperBound > 100000) {
    return roundToNextMultiple(upperBound, 50000);
  } else if (upperBound > 50000) {
    return roundToNextMultiple(upperBound, 20000);
  } else if (upperBound > 20000) {
    return roundToNextMultiple(upperBound, 15000);
  } else if (upperBound > 10000) {
    return roundToNextMultiple(upperBound, 5000);
  } else if (upperBound > 4000) {
    return roundToNextMultiple(upperBound, 2500);
  } else if (upperBound > 2000) {
    return roundToNextMultiple(upperBound, 1000);
  } else if (upperBound > 1000) {
    return roundToNextMultiple(upperBound, 500);
  } else if (upperBound > 200) {
    return roundToNextMultiple(upperBound, 100);
  } else if (upperBound > 100) {
    return roundToNextMultiple(upperBound, 50);
  } else if (upperBound > 20) {
    return roundToNextMultiple(upperBound, 25);
  } else if (upperBound > maxTicks) {
    return roundToNextMultiple(upperBound, 5);
  } else {
    return upperBound;
  }
};

function roundToNextMultiple(x, multiple) {
  return Math.ceil(x / multiple) * multiple;
}

export default getUpperBound;
