import eventDashboardApi from '@api/event-dashboard';
import Loading from '@components/loading';
import AlertsProvider from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import camelizeKeys from '@util/camelize-keys';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setVenueSettings } from '@/redux/reducers/venue-settings';
import withReduxProvider from '@/redux/with-provider';

import VenueSettingsComponent from './venue-settings-component';

export const VenueSettingsContainer = ({
  eventSlug,
  eventExternalId,
  userExternalId,
  organizationExternalId,
  onsiteOnly,
  isLite,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const response = await eventDashboardApi.getVenueSettings(
        eventExternalId,
      );

      dispatch(setVenueSettings(camelizeKeys(response.data)));
    }

    fetchData();
  }, [eventExternalId, dispatch]);

  const handleSubmitSuccess = data => {
    dispatch(setVenueSettings(camelizeKeys(data)));
  };

  const { id, attributes, meta } = useSelector(
    ({ venueSettings }) => venueSettings,
  );
  const isLoading = !id;

  return (
    <AlertsProvider>
      <Loading isLoading={isLoading}>
        {!isLoading && (
          <VenueSettingsComponent
            id={id}
            attributes={attributes}
            meta={meta}
            onSubmitSuccess={handleSubmitSuccess}
            eventSlug={eventSlug}
            eventExternalId={eventExternalId}
            userExternalId={userExternalId}
            organizationExternalId={organizationExternalId}
            onsiteOnly={onsiteOnly}
            isLite={isLite}
          />
        )}
      </Loading>
    </AlertsProvider>
  );
};

VenueSettingsContainer.propTypes = {
  eventSlug: string,
  eventExternalId: string,
  userExternalId: string,
  organizationExternalId: string,
  onsiteOnly: bool,
  isLite: bool,
};

export default compose(
  withErrorHandling({
    errorPattern: 'page',
    loggerName: LOGGER_NAMES.VENUE_SETTINGS,
  }),
  withThemeProvider,
  withReduxProvider,
)(VenueSettingsContainer);
