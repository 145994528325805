import styled from 'styled-components';

export const SponsorRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-8);
  margin: var(--spacing-16) 0;

  &:hover,
  &:focus {
    background-color: var(--color-gray-100);
  }
`;

export const SponsorRowLogo = styled.div`
  display: inline-block;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);
  border-radius: var(--spacing-8);
  box-shadow: 0px 1px 2px rgba(44, 50, 66, 0.08);
  width: 70px;
  height: 32px;
  padding-top: var(--spacing-2);
  text-align: center;

  & > img {
    max-width: 60px;
    max-height: 26px;
  }
`;

export const SponsorRowName = styled.div`
  display: inline-block;
  flex-grow: 1;
  text-align: left;
  font-weight: bold;
  padding-left: var(--spacing-8);
`;

export const StyledIcon = styled.svg`
  color: var(--color-blue-400);
`;

export const CreateSponsorButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-8);
  margin: var(--spacing-16) 0;
`;
