import { useModal } from '@components/modal/modal-context';
import { yupResolver } from '@hookform/resolvers';
import * as Routes from '@routes';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

const schema = object().shape({
  redeem_code: object().shape({
    email: string().trim().email().required(),
    first_name: string().trim().required(),
    last_name: string().trim().required(),
    headline: string()
      .trim()
      .max(100, { params: { maxLength: 100 } }),
  }),
});

const useEditMagiclinkForm = (initialValues, eventSlug, apiClient) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      redeem_code: {
        first_name: initialValues.firstName,
        last_name: initialValues.lastName,
        headline: initialValues.headline,
        email: initialValues.email,
      },
    },
  });

  const { setIsModalOpen } = useModal();

  const onSubmit = async (values, e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const url = Routes.organisersEventRedeemCodePath(
      eventSlug,
      initialValues.id,
    );

    const response = await apiClient.put(url, formData);
    if (response.success) {
      window.location.reload();

      // wait a moment to refresh the page
      setTimeout(() => {
        setIsModalOpen(false);
      }, 2000);

      // this keeps the button disabled by with isSubmitting
      return new Promise(() => {});
    }
  };

  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
    errors: errors.redeem_code,
  };
};

export default useEditMagiclinkForm;
