import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { useModalStep } from '@features/registrations/registrants-bulk-upload/use-modal-step';
import { Box } from '@hopin-team/ui-box';
import { Modal } from '@hopin-team/ui-modal';
import { REGISTRATION_EVENTS } from '@util/analytics/registration-events';
import trackSegmentEvent from '@util/analytics/segment-client';
import getLogger, { LOGGER_NAMES } from '@util/logger';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';

import {
  ERROR_MESSAGES,
  ErrorToast,
  GENERIC_ERROR_MESSAGE,
  ModalBodyV1,
  ModalFooter,
  ModalHeader,
} from './components';
import { STEPS } from './constants';
import { triggerCsvDownload } from './utils/csv';

const logger = getLogger(LOGGER_NAMES.REGISTRATIONS);

const BulkUploadModalV1 = ({
  isOpen,
  onClose,
  eventSlug,
  eventExternalId,
  userExternalId,
  registrationEmailsDisabled,
}) => {
  const { nextStep, prevStep, step, setStep } = useModalStep([
    STEPS.CSV_UPLOAD,
    STEPS.EMAIL_MARKETING,
    STEPS.SUCCESS_CONFIRMATION,
  ]);

  const [files, setFiles] = useState([]);

  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization(
    'people.attendees.registrant-bulk-upload.network-errors',
  );

  const resetModalState = () => {
    setStep(STEPS.CSV_UPLOAD);
    setFiles([]);
  };

  const handleClose = () => {
    resetModalState();
    onClose();
  };

  const handleFilesChange = uploadedFiles => {
    setFiles(uploadedFiles);
  };

  const onConfirmUpload = async () => {
    try {
      const { token } = await hopinApi.getUserToken();

      if (!token) {
        return;
      }

      const formData = new FormData();
      formData.set('csv_file', files[0]);

      const response = await hopinApi.registrantsBulkUpload(
        eventSlug,
        formData,
        token,
      );

      if (response.status === 202) {
        trackSegmentEvent(REGISTRATION_EVENTS.REGISTRANT_CSV_BULK_UPLOADED, {
          event_id: eventExternalId,
          user_id: userExternalId,
        });

        setStep(STEPS.SUCCESS_CONFIRMATION);
        return;
      }

      const contentType = response.headers.get('content-type');

      if (contentType && !String(contentType).includes('application/json')) {
        addAlert({
          toast: () => (
            <ErrorToast
              title={t(GENERIC_ERROR_MESSAGE.titleKey)}
              subtitle={t(GENERIC_ERROR_MESSAGE.subtitleKey)}
            />
          ),
          timeout: 10_000,
        });

        return;
      }

      const errorResponse = await response.json();

      if (errorResponse?.errors && Array.isArray(errorResponse?.errors)) {
        errorResponse.errors.forEach(error => {
          const message =
            ERROR_MESSAGES.find(
              errorMessage => errorMessage.code === error.code,
            ) || GENERIC_ERROR_MESSAGE;

          addAlert({
            toast: () => (
              <ErrorToast
                title={t(message.titleKey)}
                subtitle={t(message.subtitleKey)}
              />
            ),
            timeout: 10_000,
          });
        });
      } else {
        addAlert({
          toast: () => (
            <ErrorToast
              title={t(GENERIC_ERROR_MESSAGE.titleKey)}
              subtitle={t(GENERIC_ERROR_MESSAGE.subtitleKey)}
            />
          ),
          timeout: 10_000,
        });
      }
    } catch (error) {
      logger.error(error);
      addAlert({
        toast: () => (
          <ErrorToast
            title={t(GENERIC_ERROR_MESSAGE.titleKey)}
            subtitle={t(GENERIC_ERROR_MESSAGE.subtitleKey)}
          />
        ),
        timeout: 10_000,
      });
    }
  };

  const downloadWrapper = useRef();
  const createSampleCsv = useCallback(async () => {
    try {
      const { token } = await hopinApi.getUserToken();
      const response = await hopinApi.getSampleCsv(token, eventSlug);
      const filename = `registrant-bulk-upload-sample-csv-for-${eventSlug}.csv`;
      triggerCsvDownload(response, filename, downloadWrapper);
    } catch (error) {
      logger.error(error);
    }
  }, [eventSlug]);

  return (
    <Modal
      label="bulk-registrants-upload-modal"
      withSeparator="footer"
      withCloseButton
      isDismissible={false}
      isShowing={isOpen}
      onClose={handleClose}
      header={
        <ModalHeader
          step={step}
          registrationEmailsDisabled={registrationEmailsDisabled}
        />
      }
      footer={
        <ModalFooter
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          onModalClose={handleClose}
          onConfirmUpload={onConfirmUpload}
          canMapFields={Boolean(files[0])}
          registrationEmailsDisabled={registrationEmailsDisabled}
        />
      }
    >
      <Box mx={3} mb={3} ref={downloadWrapper}>
        <ModalBodyV1
          step={step}
          eventSlug={eventSlug}
          onFilesChange={handleFilesChange}
          registrationEmailsDisabled={registrationEmailsDisabled}
          downloadSampleCsv={createSampleCsv}
        />
      </Box>
    </Modal>
  );
};

BulkUploadModalV1.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventSlug: PropTypes.string.isRequired,
  eventExternalId: PropTypes.string.isRequired,
  userExternalId: PropTypes.string.isRequired,
  registrationEmailsDisabled: PropTypes.bool,
};

export default BulkUploadModalV1;
