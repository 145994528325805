import {
  initLogger as initDatadogLogger,
  logger as getDataDogLogger,
} from '@hopin-team/browser-logger';
import { getLogger as getSentryLogger } from '@hopin-team/provider-logging';
import { Integrations } from '@sentry/browser';

import { getLoggerNameOwnership } from './get-logger-name-ownership';

export * from './constants';

initDatadogLogger({
  clientToken: window.DATADOG_CLIENT_TOKEN,
  env: window.DATADOG_ENV,
  service: 'hopin-fe',
  version: window.DATADOG_VERSION,
});

const sentryLogger = getSentryLogger({
  sentryDsn: window.SENTRY_DSN,
  sentryEnv: window.SENTRY_ENV,
  sentryRelease: window.SENTRY_RELEASE,
  consumerId: `@hopin-team/hopin/${window.SENTRY_RELEASE}`,
  customOptions: {
    integrations: [
      new Integrations.GlobalHandlers({
        onerror: false,
        onunhandledrejection: false,
      }),
    ],
  },
});

const getLogger = loggerName => {
  const dataDogLogger = getDataDogLogger(loggerName);
  const responsibleTeam = getLoggerNameOwnership(loggerName);

  if (dataDogLogger.addContext) {
    dataDogLogger.addContext('pod_name', responsibleTeam);
  }

  function proxyError(error, context) {
    dataDogLogger.error.call(dataDogLogger, error, context);
    const customException = {
      error,
      customTags: {
        hopin_tag: loggerName,
        responsible_team: responsibleTeam,
        ...context,
      },
    };
    if (error?.routeName) {
      customException.customFingerprint = [error.routeName];
    }
    sentryLogger.captureException(customException);
  }

  return new Proxy(dataDogLogger, {
    get: function (target, prop) {
      if (prop === 'error') {
        return proxyError;
      } else {
        return target[prop].bind(target);
      }
    },
  });
};

export default getLogger;
