import ErrorView from '@features/event-creation-wizard/components/error-view';
import { FOOTER_HEIGHT } from '@features/event-creation-wizard/components/footer/styles';
import InfoPanel from '@features/event-creation-wizard/components/info-panel';
import Loading from '@features/event-creation-wizard/components/loading';
import { TOP_BAR_HEIGHT } from '@features/event-creation-wizard/components/top-bar/styles';
import { getFromTheme } from '@hopin-team/ui-helper-deep-branding';
import { rem } from 'polished';
import * as P from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import EventCreationDynamicGraphic from './event-creation-wizard-dynamic-graphic';
import EventCreationWizardImage from './event-creation-wizard-image';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  position: relative;
  padding: ${rem(TOP_BAR_HEIGHT)} 0 ${rem(FOOTER_HEIGHT)};
`;

const ContentContainer = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 50%;
  padding: ${rem(48)} ${rem(104)};

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    width: 100%;
    padding: ${rem(48)} ${rem(32)};
  }

  ${({ showPreviewImage }) =>
    !showPreviewImage &&
    css`
      width: 100%;
      padding: ${rem(48)} ${rem(32)};
    `}

  ${({ verticallyCentered }) =>
    verticallyCentered &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const ContentWrapper = styled.div`
  max-width: ${rem(548)};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const SidePanel = styled.aside`
  position: fixed;
  width: 50%;
  right: 0;
  height: calc(100vh - ${rem(TOP_BAR_HEIGHT)} - ${rem(FOOTER_HEIGHT)});

  @media (max-width: ${theme => getFromTheme('LAYOUT.TABLET_MAX', theme)}) {
    display: none;
  }
`;

const EventCreationWizardMain = ({
  children,
  info,
  submitting,
  hasCreateEventError,
  preview,
  verticallyCentered,
  showPreviewImage = true,
}) => {
  if (submitting) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (hasCreateEventError) {
    return (
      <Container>
        <ErrorView />
      </Container>
    );
  }

  return (
    <Container>
      <ContentContainer
        data-testid="step-content"
        verticallyCentered={verticallyCentered}
        showPreviewImage={showPreviewImage}
      >
        <ContentWrapper>
          {children}
          {info && <InfoPanel text={info} />}
        </ContentWrapper>
      </ContentContainer>
      {showPreviewImage && (
        <SidePanel>
          {preview ? (
            <EventCreationDynamicGraphic preview={preview} />
          ) : (
            <EventCreationWizardImage />
          )}
        </SidePanel>
      )}
    </Container>
  );
};

EventCreationWizardMain.propTypes = {
  children: P.node.isRequired,
  info: P.string,
  submitting: P.bool,
  hasCreateEventError: P.bool,
  preview: P.element,
  verticallyCentered: P.bool,
  showPreviewImage: P.bool,
};

export default EventCreationWizardMain;
