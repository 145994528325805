import { isStepApplicable, isStepComplete, isStepSkipped } from './steps';

export function isTabApplicable(tab, statuses) {
  return tab.requiredSteps.some(requiredStep =>
    isStepApplicable(requiredStep, statuses),
  );
}

export function isTabComplete(tab, statuses, includeSkipped = false) {
  const applicableSteps = tab.requiredSteps.filter(step =>
    isStepApplicable(step, statuses),
  );
  return applicableSteps.every(
    applicableRequiredStep =>
      isStepApplicable &&
      (isStepComplete(applicableRequiredStep, statuses) ||
        (includeSkipped && isStepSkipped(applicableRequiredStep, statuses))),
  );
}
