import { TooltipWithBounds as Tooltip } from '@visx/tooltip';
import { number, oneOf, shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ChartTooltipContainer = styled(Tooltip)`
  width: 250px;
  z-index: 100;
  font-weight: 400;
  font-size: '14px';
  padding: 11px 16px;
  position: absolute;
  border-radius: 12px;
  font-family: 'Inter';
  border: ${({ theme }) => (theme === 'dark' ? 'none' : '1px solid #f1f2f5')};
  background: ${({ theme }) =>
    theme === 'dark' ? 'var(--color-gray-700);' : 'var(--color-white);'}
  color: ${({ theme }) =>
    theme === 'dark' ? 'var(--color-white);' : 'inherit;'}
  box-shadow: 0px 1px 2px rgba(44, 50, 66, 0.08);
`;

const ChartTooltipContent = styled.div`
  display: flex;
  justify-content: space-between;

  span:nth-child(2) {
    font-weight: 700;
  }
`;

const ChartTooltip = ({ top, left, data, description, theme }) => {
  return (
    <ChartTooltipContainer
      data-testid="chart-tooltip"
      top={top}
      left={left}
      unstyled={true}
      theme={theme}
    >
      <div>
        <p>{data.name}</p>
      </div>
      <ChartTooltipContent>
        {description && <span>{description} &nbsp;</span>}
        <span>{data.value}</span>
      </ChartTooltipContent>
    </ChartTooltipContainer>
  );
};

ChartTooltip.defaultProps = {
  theme: 'light',
};

ChartTooltip.propTypes = {
  top: number.isRequired,
  left: number.isRequired,
  description: string,
  data: shape({
    name: string.isRequired,
    value: number.isRequired,
  }).isRequired,
  theme: oneOf(['dark', 'light']),
};

export default ChartTooltip;
