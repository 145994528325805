import { FETCHING_STATE } from '@features/recordings/redux/initial-state';
import { createSlice } from '@reduxjs/toolkit';

import { fetchRecordingCutsThunk } from './recording-cuts-actions';

export const recordingCutsSlice = createSlice({
  name: 'recordingCuts',
  initialState: {
    cutIds: [],
    isCutsPageShowing: false,
    ...FETCHING_STATE.default,
  },
  reducers: {
    setIsCutsPageShowing: (state, action) => {
      state.isCutsPageShowing = action.payload;
    },
  },
  extraReducers: builder => {
    // fetchRecordingCuts
    builder.addCase(fetchRecordingCutsThunk.pending, state => ({
      ...state,
      ...FETCHING_STATE.pending,
    }));

    builder.addCase(fetchRecordingCutsThunk.rejected, state => ({
      ...state,
      ...FETCHING_STATE.rejected,
    }));

    builder.addCase(fetchRecordingCutsThunk.fulfilled, (state, action) => {
      const recordings = action.payload;

      return {
        ...state,
        cutIds: recordings.map(({ id }) => id),
        ...FETCHING_STATE.fulfilled,
      };
    });
  },
});

export const { setIsCutsPageShowing } = recordingCutsSlice.actions;

export default recordingCutsSlice.reducer;
