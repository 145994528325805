import Flatpickr from '@components/flatpickr';
import { TableBodyCell, TableRow } from '@components/table';
import cx from 'classnames';
import { add, parseISO } from 'date-fns';
import { bool, func, number, shape, string } from 'prop-types';
import React from 'react';

const ScheduleRow = ({
  index,
  mismatched,
  onRemove,
  schedule,
  shiftAmount,
  useShift,
}) => {
  const calculateTimeShift = timeString => {
    if (useShift) {
      return add(parseISO(timeString), { seconds: shiftAmount / 1000 });
    }
    return timeString;
  };

  const flatpickrOptions = {
    altFormat: 'M-j h:iK',
    altInput: true,
    enableTime: true,
  };

  const { id, name, timeStartLocal, timeEndLocal } = schedule;

  return (
    <TableRow key={id} className={cx({ 'table-danger': mismatched })}>
      <TableBodyCell>
        {name}
        <input
          type="hidden"
          name={`event[schedules_attributes][${index}][id]`}
          value={id}
        />
      </TableBodyCell>
      <TableBodyCell>
        <Flatpickr
          className="input"
          name={`event[schedules_attributes][${index}][time_start]`}
          options={{
            ...flatpickrOptions,
          }}
          required={true}
          value={calculateTimeShift(timeStartLocal)}
        />
      </TableBodyCell>
      <TableBodyCell>
        <Flatpickr
          className="input"
          name={`event[schedules_attributes][${index}][time_end]`}
          options={{
            ...flatpickrOptions,
          }}
          required={true}
          value={calculateTimeShift(timeEndLocal)}
        />
      </TableBodyCell>
      <TableBodyCell>
        <button
          type="button"
          className="text -red"
          data-confirm="Are you sure?"
          onClick={() => onRemove(id)}
        >
          Remove
        </button>
      </TableBodyCell>
    </TableRow>
  );
};

ScheduleRow.propTypes = {
  index: number.isRequired,
  mismatched: bool.isRequired,
  onRemove: func.isRequired,
  schedule: shape({
    id: number.isRequired,
    name: string.isRequired,
    timeEndLocal: string.isRequired,
    timeStartLocal: string.isRequired,
  }),
  shiftAmount: number.isRequired,
  useShift: bool,
};

export default ScheduleRow;
