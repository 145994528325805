import '@hopin-team/ui-theme';

import AlertsProvider from '@features/alerts/alerts-provider';
import { LocalizationProvider } from '@features/localization';
import { string } from 'prop-types';
import React from 'react';

import DemoEventLoaderApp from './demo-event-loader-app';

const DemoEventLoaderRoot = ({ sourceEventId }) => {
  return (
    <LocalizationProvider>
      <AlertsProvider>
        <DemoEventLoaderApp sourceEventId={sourceEventId} />
      </AlertsProvider>
    </LocalizationProvider>
  );
};

DemoEventLoaderRoot.propTypes = {
  sourceEventId: string,
};

export default DemoEventLoaderRoot;
