import hopinApi from '@api/hopin';
import Card from '@components/card-with-loading';
import CardTitle from '@features/dashboard/components/card-title';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import {
  graphColor40,
  graphColor60,
  graphColor100,
} from '@features/dashboard/util/colors';
import { formatCurrency } from '@features/dashboard/util/format-currency';
import { useLocalization } from '@features/localization';
import { number, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { PieChart } from 'react-chartkick';
import styled from 'styled-components';

import { usePromise } from '../util/hooks';
const PieChartWrapper = styled.div`
  width: 14rem;
  margin: auto;
`;

const NetSalesValue = styled.span`
  font-size: 1.9rem;
  font-family: var(--font-family-display);
  font-weight: var(--font-weight-medium);
`;

const TicketSalesTable = styled.ul`
  margin-bottom: 0;

  li {
    display: flex;
    padding: 0.75rem 0;
    margin-bottom: -0.0625rem;
    background-color: #fff;
    border: 0.0625rem solid #e9ecef;
    justify-content: space-between;
    border-left: none;
    border-right: none;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
      font-weight: var(--font-weight-bold);
    }
  }
`;

const pieChartData = (data = {}) => {
  data = { ...data };
  delete data.net_sales;

  return Object.values(data).map(({ name, value }) => [name, value]);
};

const netSalesValue = data => {
  return data?.net_sales.value;
};

const isDataEmpty = data => {
  return data && Object.values(data).every(({ value }) => !value);
};

const TicketNetSales = ({ event }) => {
  const { t, language } = useLocalization(
    'registrations-dashboard.ticket-net-sales-component',
  );
  const [ticketSalesData, setTicketSalesData] = useState();

  const loading = usePromise(
    signal => hopinApi.getTicketNetSalesData(event.id, signal),
    setTicketSalesData,
  );

  const renderListItem = key => {
    return (
      <li>
        <span>{ticketSalesData[key].name}</span>
        <span>
          {formatCurrency(ticketSalesData[key].value, event.currency, language)}
        </span>
      </li>
    );
  };

  return (
    <Card isLoading={loading} testId="ticket-net-sales">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <NetSalesValue>
          {formatCurrency(
            netSalesValue(ticketSalesData),
            event.currency,
            language,
          )}
        </NetSalesValue>
        {!isDataEmpty(ticketSalesData) && (
          <PieChartWrapper data-testid="ticket-sales-pie-chart">
            <PieChart
              data={pieChartData(ticketSalesData)}
              colors={[graphColor100, graphColor60, graphColor40]}
              legend={false}
              library={{
                rotation: -0.5 * Math.PI,
              }}
              height="10rem"
              width="14rem"
            />
          </PieChartWrapper>
        )}
        <TicketSalesTable>
          {ticketSalesData && (
            <>
              {renderListItem('gross_sales')}
              {renderListItem('merchant_fees')}
              {renderListItem('platform_fees')}
              {renderListItem('net_sales')}
            </>
          )}
        </TicketSalesTable>
      </CardContentWrapper>
    </Card>
  );
};

TicketNetSales.propTypes = {
  event: shape({
    id: number,
    currency: string,
  }),
};

export default TicketNetSales;
