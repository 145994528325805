import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

/*
 * This value comes from the height of the <header>
 * from app/javascript/react/components/header/wrapper-container-sc.js
 * It does not have a specific height, it comes from the content and
 * the padding around it
 */
const HEADER_HEIGHT = '4.1rem';

export const FullHeightFlex = styled(Flex)`
  min-height: calc(100vh - ${HEADER_HEIGHT});
`;
