import eventDashboardApi from '@api/event-dashboard';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import { withLocalizationProvider } from '@features/localization';
import normalize from 'json-api-normalizer';
import compose from 'lodash/fp/compose';
import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBackstageLinks } from '@/redux/reducers/backstage-links.js';
import { setEventLinks } from '@/redux/reducers/event-links';
import withReduxProvider from '@/redux/with-provider';

import { OnlineSharingBanner } from './online-hybrid/online-sharing-banner';
import { OnsiteBanner } from './onsite/onsite-banner';

export const SharingBanner = ({
  eventId,
  onsite,
  isEventLive,
  isEventFinished,
}) => {
  const { event } = useSelector(({ event }) => ({
    event,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const response = await eventDashboardApi.getEventLinks(eventId);
      dispatch(setEventLinks(normalize(response)));
      dispatch(setBackstageLinks(normalize(response)));
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  if (onsite) {
    return (
      <OnsiteBanner
        event={event}
        isLive={isEventLive}
        isEnded={isEventFinished}
      />
    );
  } else {
    return <OnlineSharingBanner />;
  }
};

SharingBanner.propTypes = {
  eventId: propTypes.string,
  creationEnablePublishModal: propTypes.bool,
  onsite: propTypes.bool,
};

export default compose(
  withReduxProvider,
  withThemeProvider,
  withLocalizationProvider,
)(SharingBanner);
