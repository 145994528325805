import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { useWorkspaces } from './hooks/use-workspaces';

const StreamYardWorkspaceSelect = ({
  backstageUrl,
  currentWorkspaceId,
  eventSlug,
}) => {
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    currentWorkspaceId,
  );
  const {
    isDone,
    isError,
    isLoading,
    workspaces,
    getWorkspaces: refetchWorkspaces,
    shouldAllowRetries,
  } = useWorkspaces(currentWorkspaceId, eventSlug);

  const { t } = useLocalization('stages');

  useEffect(() => {
    if (!currentWorkspaceId && isDone && workspaces) {
      setSelectedWorkspaceId(workspaces[0]?.id);
    }
  }, [currentWorkspaceId, isDone, workspaces]);

  const options = useMemo(() => {
    if (workspaces.length > 0) {
      return workspaces.map(({ id, name }) => ({ value: id, label: name }));
    }
    return workspaces;
  }, [workspaces]);

  const backstageUrlWithWorkspace = useMemo(() => {
    const url = new URL(backstageUrl);
    if (selectedWorkspaceId) {
      url.searchParams.append('workspaceId', selectedWorkspaceId);
    }
    return url;
  }, [backstageUrl, selectedWorkspaceId]);

  return (
    <>
      {options.length > 1 && (
        <Select
          label={t('streamyard-space')}
          options={options}
          name="workspaceId"
          value={selectedWorkspaceId}
          size="small"
          setValue={workspaceId => setSelectedWorkspaceId(workspaceId)}
        />
      )}
      {isError ? (
        <>
          {shouldAllowRetries ? (
            <>
              <Text color="utility-error" element="p" pattern="body" mt={2}>
                {t('failed-to-fetch-streamyard-spaces')}
              </Text>
              <Button
                isInline
                size="small"
                pattern="danger"
                onClick={refetchWorkspaces}
              >
                {t('retry')}
              </Button>
            </>
          ) : !isLoading ? (
            <Text color="utility-error" pattern="body" element="p" mt={2}>
              {t('currently-cannot-create-a-studio-with-workspace')}
            </Text>
          ) : null}
        </>
      ) : (
        <Button
          isInline
          size="small"
          mt={2}
          href={backstageUrlWithWorkspace}
          px={2}
          target="_blank"
          rel="noreferrer"
          disabled={isLoading}
        >
          {t(
            isLoading
              ? 'fetching-streamyard-spaces'
              : 'view-streamyard-backstage',
          )}
        </Button>
      )}
    </>
  );
};

StreamYardWorkspaceSelect.propTypes = {
  backstageUrl: string.isRequired,
  currentWorkspaceId: string,
  eventSlug: string.isRequired,
};

const StreamYardWorkspaceSelectWithProvider = withLocalizationProvider(
  StreamYardWorkspaceSelect,
);

export { StreamYardWorkspaceSelectWithProvider as StreamYardWorkspaceSelect };

export default StreamYardWorkspaceSelectWithProvider;
