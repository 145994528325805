import { Box } from '@hopin-team/ui-box';
import { calcSpace } from '@hopin-team/ui-symbols';
import { rem } from 'polished';
import styled from 'styled-components';

import { AnnouncementsText, CenterPanel, LinkIcon } from '../styles';

export const CenterPanelGrid = styled(CenterPanel)`
  display: grid;
  gap: ${calcSpace(2)} ${calcSpace(3)};
  grid-template-columns: 1fr;
`;

export const BackLink = styled(LinkIcon)`
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  height: ${rem(24)};
  width: ${rem(24)};
`;

export const EmailDetails = styled(Box)`
  grid-column: 1/2;
`;

export const AttendeesAlert = styled(Box)`
  grid-column: 1/2;
  background-color: var(--color-yellow-100);
  color: var(--color-yellow-600);
  display: flex;
  align-items: center;
  height: ${rem(48)};

  ${AnnouncementsText} {
    margin-left: ${calcSpace(2)};
  }
`;

export const PreviewBody = styled(Box)`
  background-color: var(--color-white);
  border: 1px solid var(--grey-300);
  border-radius: $rem(4);
  grid-column: 1/3;
`;

export const SendTestEmailFormWrapper = styled.form`
  label {
    font-weight: 400;
    color: var(--color-gray-600);

    span {
      font-weight: 400;
      color: var(--color-gray-600);
    }
  }
`;
