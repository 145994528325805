import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Image } from '@hopin-team/ui-image';
import { Text } from '@hopin-team/ui-text';
import { isEmpty } from 'lodash';
import P from 'prop-types';
import React from 'react';

const NoImagePlaceholder = ({ style, ...props }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    border="grey-300"
    background="grey-100"
    style={{ width: 76, height: 76, borderRadius: 8, ...style }}
    {...props}
  >
    <Icon aria-hidden="true" name="image" color="grey-600" scale={3} />
  </Flex>
);

NoImagePlaceholder.propTypes = {
  style: P.object,
};

const ImageFileInput = ({
  label,
  buttonText = 'Choose file',
  name,
  acceptImages,
  onChange,
  placeholderImageSrc,
  ...props
}) => {
  const [fileURL, setFileURL] = React.useState(null);
  const fileInputRef = React.useRef(null);

  const inputAccept = isEmpty(acceptImages)
    ? 'image/*'
    : acceptImages.map(fileType => `.${fileType}`).join(',');

  const onFileChoose = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        name={name}
        accept={inputAccept}
        onChange={event => {
          const [imageFile] = event.target.files;
          setFileURL(URL.createObjectURL(imageFile));
          onChange?.(event);
        }}
        style={{ display: 'none' }}
      />

      <Box {...props}>
        {!isEmpty(label) && (
          <Text scale={3} mb={1} style={{ fontWeight: 500 }}>
            {label}
          </Text>
        )}

        <Flex alignItems="center">
          {/* Image preview */}
          {isEmpty(fileURL) && isEmpty(placeholderImageSrc) ? (
            <NoImagePlaceholder
              style={{ cursor: 'pointer' }}
              onClick={onFileChoose}
            />
          ) : (
            <div style={{ width: 76 }}>
              <Image alt="Picked image" src={fileURL ?? placeholderImageSrc} />
            </div>
          )}

          <Button
            type="button"
            isOutlined
            isInline
            size="small"
            pattern="secondary"
            ml={2}
            onClick={onFileChoose}
          >
            {buttonText}
          </Button>
        </Flex>
      </Box>
    </>
  );
};

ImageFileInput.propTypes = {
  label: P.string,
  buttonText: P.string,
  name: P.string,
  onChange: P.func,
  acceptImages: P.arrayOf(P.string),
  placeholderImageSrc: P.string,
};

export default ImageFileInput;
