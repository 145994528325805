import { Flex } from '@hopin-team/ui-flex';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import styled from 'styled-components';

export const StyledFlex = styled(Flex)`
  width: 9.625rem;
  min-width: 9.625rem;
`;

export const StyledTooltip = styled(ToolTip)`
  max-width: 22rem;
`;
