import { bool, string } from 'prop-types';
import React from 'react';

const EventOptions = ({ className, emailsSuppressed, hasSuppressEmails }) => (
  <div className={className}>
    {hasSuppressEmails && (
      <div className="field my-4">
        <label htmlFor="suppress_emails" className="option">
          <input
            defaultChecked={emailsSuppressed}
            type="checkbox"
            id="suppress_emails"
            name="event[suppress_emails]"
          />
          <span className="option_content">
            Enable experimental email suppression
          </span>
        </label>
      </div>
    )}
  </div>
);

export default EventOptions;

EventOptions.propTypes = {
  className: string,
  emailsSuppressed: bool,
  hasHopinWebUrl: bool,
  hasSuppressEmails: bool,
  organizerV2: bool,
};
