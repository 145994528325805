import { Flex } from '@hopin-team/ui-flex';
import { COLOR } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  min-height: 3rem;
  margin-top: 0.5rem;
  border: 1px solid ${COLOR.GREY['300']};
  border-radius: 0.1875rem;
`;

const LinkWrapper = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
})`
  background-color: ${COLOR.GREY['WHITE']};
  width: calc(100% - 3rem);
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  padding: 0 1.25rem;
`;

const Link = styled.p`
  color: ${COLOR.GREY['800']};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
`;

const CopyButton = styled.button`
  width: 3rem;
  background-color: ${COLOR.GREY['200']};
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  border-left: 0;
`;

export { Wrapper, LinkWrapper, Link, CopyButton };
