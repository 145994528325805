import Alerts from '@features/alerts/alerts';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import * as Routes from '@routes';
import { array, bool, number, object } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import StagesTable from './table/index.js';

const StagesComponent = ({
  event,
  eventPart,
  eventStages,
  stageLimitReached,
  stages,
}) => {
  const { t } = useLocalization('stages');
  const { slug } = event;

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Alerts />
      <div className="mt-3">
        <div className="mb-3">{t('use-stages-to-broadcast')}</div>
        <div className="avatar-group mb-32">
          {stageLimitReached ? (
            <span
              data-toggle="tooltip"
              title={t('plan-limit', { eventStages })}
            >
              <a
                href={Routes.newOrganisersEventBackstagePath(slug)}
                className="button -small"
                data-testid="add-stage-action"
              >
                {t('add-stage')}
              </a>
            </span>
          ) : (
            <a
              href={Routes.newOrganisersEventBackstagePath(slug)}
              className="button -small"
              data-testid="add-stage-action"
            >
              {t('add-stage')}
            </a>
          )}
        </div>
        <StagesTable event={event} eventPart={eventPart} stages={stages} />
      </div>
    </>
  );
};

StagesComponent.propTypes = {
  event: object.isRequired,
  eventPart: object.isRequired,
  eventStages: number.isRequired,
  stageLimitReached: bool.isRequired,
  stages: array.isRequired,
};

export default withLocalizationProvider(StagesComponent);
