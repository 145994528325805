import analyticsApi from '@api/analytics';
import hopinApi from '@api/hopin';
import { MetricListWrapper } from '@features/dashboard/styles/metric-list.styles';
import { number, string } from 'prop-types';
import React, { useState } from 'react';

import { usePromise } from '../../util/hooks';
import EngagementMetrics from './engagement-metrics';

const EngagementKeyMetrics = ({ eventId, externalId }) => {
  const [stats, setStats] = useState({
    total_chat_messages: 0,
    total_networking_meetings: 0,
    total_connection_requests: 0,
    total_scheduled_meetings: 0,
    total_questions_asked: 0,
    total_poll_votes: 0,
    sessions_joined: 0,
    chat_reactions: 0,
    video_reactions: 0,
    confetti: 0,
  });

  const loading = usePromise(
    signal => hopinApi.getConnectionsKeyMetrics(eventId, signal),
    result => {
      setStats(previousState => ({
        ...previousState,
        ...result,
      }));
    },
  );
  const analyticsLoading = usePromise(
    signal => analyticsApi.getAnalyticsKeyMetrics(externalId, signal),
    result => {
      setStats(previousState => ({
        ...previousState,
        ...result,
      }));
    },
  );

  const metrics = EngagementMetrics(stats, loading, analyticsLoading);

  return <MetricListWrapper>{metrics}</MetricListWrapper>;
};

EngagementKeyMetrics.propTypes = {
  eventId: number.isRequired,
  externalId: string.isRequired,
};

export default EngagementKeyMetrics;
