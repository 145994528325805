import { CloseIcon } from '@components/icons/close';
import { bool, func, node, oneOf } from 'prop-types';
import React from 'react';

import { CloseButton, Content, Overlay } from './modal.styles';

export const Modal = ({ isOpen, children, closeModal, size, overflow }) => (
  <Overlay data-testid="overlay" isOpen={isOpen} onDismiss={closeModal}>
    <Content size={size} overflow={overflow} aria-labelledby="modal">
      <CloseButton
        data-testid="close-button"
        onClick={closeModal}
        aria-label="close modal"
      >
        <CloseIcon />
      </CloseButton>
      {children}
    </Content>
  </Overlay>
);

Modal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  children: node,
  size: oneOf(['small', 'medium', 'large', 'xLarge']),
  overflow: oneOf(['auto', 'visible', 'hidden', 'scroll']),
};
