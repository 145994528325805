import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Spinner } from '@hopin-team/ui-spinner';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React from 'react';

import { STEPS } from '../constants';

export const ModalFooter = ({
  step,
  nextStep,
  prevStep,
  isMappingValid,
  onModalClose,
  onConfirmUpload,
  canMapFields,
  registrationEmailsDisabled,
  isProcessingCSV,
  progressPercentage,
  registrantsBulkUploadReportStatus,
}) => {
  const { t } = useLocalization('people.attendees.registrant-bulk-upload');

  if (step === STEPS.CSV_UPLOAD) {
    return (
      <Flex justifyContent="flex-end">
        <Button
          pattern="secondary"
          size="medium"
          isInline
          isOutlined
          data-testid="close-modal-action"
          onClick={onModalClose}
        >
          {t('cancel')}
        </Button>
        <ToolTip
          tip={t('upload-registrants-denied.file-not-selected')}
          align="top"
          hidePointer
          hideTip={canMapFields}
        >
          <Button
            pattern="primary"
            size="medium"
            isInline
            ml={2}
            disabled={!canMapFields}
            data-testid="next-action"
            onClick={nextStep}
          >
            {t('confirm')}
          </Button>
        </ToolTip>
      </Flex>
    );
  }

  if (step === STEPS.CSV_MAPPING) {
    if (registrantsBulkUploadReportStatus && isProcessingCSV) {
      return (
        <Flex justifyContent="flex-end">
          <ToolTip
            tip={t('csv-validation-processing')}
            align="top"
            hidePointer
            hideTip={false}
          >
            <Button disabled isInline>
              <Text pattern="body" element="span">
                {t('processing')} {`${progressPercentage}%`}
              </Text>

              <Spinner ml={1} isShowing />
            </Button>
          </ToolTip>
        </Flex>
      );
    }

    return (
      <Flex justifyContent="flex-end">
        <Button
          pattern="secondary"
          size="medium"
          isInline
          isOutlined
          data-testid="close-modal-action"
          onClick={prevStep}
        >
          {t('go-back')}
        </Button>
        <ToolTip
          tip={t('field-mapping.incomplete')}
          align="top"
          hidePointer
          hideTip={isMappingValid}
        >
          <Button
            pattern="primary"
            size="medium"
            isInline
            ml={2}
            disabled={!isMappingValid}
            data-testid="next-action"
            onClick={
              registrantsBulkUploadReportStatus ? onConfirmUpload : nextStep
            }
          >
            {t(registrantsBulkUploadReportStatus ? 'upload' : 'upload')}
          </Button>
        </ToolTip>
      </Flex>
    );
  }

  if (step === STEPS.EMAIL_MARKETING) {
    return (
      <Flex>
        <Button
          pattern="secondary"
          size="medium"
          isOutlined
          data-testid="go-back-action"
          onClick={prevStep}
        >
          {t('go-back')}
        </Button>
        <Button
          pattern="primary"
          size="medium"
          leadingIcon={registrationEmailsDisabled ? 'publish' : 'send-filled'}
          ml={2}
          data-testid="upload-action"
          onClick={onConfirmUpload}
        >
          {registrationEmailsDisabled
            ? t('email-marketing.disabled.upload-registrants')
            : t('email-marketing.enabled.upload-registrants')}
        </Button>
      </Flex>
    );
  }

  if (step === STEPS.ERROR_REPORT) {
    return (
      <Flex justifyContent="flex-end">
        <Button
          pattern="secondary"
          size="medium"
          isInline
          isOutlined
          data-testid="close-modal-action"
          onClick={onModalClose}
        >
          {t('close')}
        </Button>
        <Button
          pattern="primary"
          size="medium"
          isInline
          ml={2}
          data-testid="next-action"
          onClick={nextStep}
        >
          {t('restart-upload')}
        </Button>
      </Flex>
    );
  }

  if (step === STEPS.SUCCESS_CONFIRMATION) {
    return (
      <Flex>
        <Button
          data-testid="got-it-action"
          pattern="primary"
          size="medium"
          onClick={onModalClose}
        >
          {t('got-it')}
        </Button>
      </Flex>
    );
  }

  return null;
};

ModalFooter.propTypes = {
  onConfirmUpload: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  canMapFields: PropTypes.bool,
  isMappingValid: PropTypes.bool,
  registrationEmailsDisabled: PropTypes.bool,
  isProcessingCSV: PropTypes.bool,
  registrantsBulkUploadReportStatus: PropTypes.bool,
  progressPercentage: PropTypes.number,
};
