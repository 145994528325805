import StepHeader from '@features/event-creation-wizard/components/step-header';
import { setExpoDetails } from '@features/event-creation-wizard/redux/step-specific-state/expo-slice';
import EventCreationWizardStep from '@features/event-creation-wizard/shared/event-creation-wizard-step';
import ExpoFormComponent from '@features/event-creation-wizard/steps/expo/expo-form-component';
import useExpoForm from '@features/event-creation-wizard/steps/expo/hooks/use-expo-form';
import { useLocalization } from '@features/localization';
import { bool } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ExpoComponent = ({ canSkip }) => {
  const dispatch = useDispatch();
  const defaultValues = useSelector(state => state.expo);
  const { t } = useLocalization('event-creation-wizard.steps.expo');

  const onSubmitHandler = useCallback(
    values => {
      dispatch(setExpoDetails(values));
    },
    [dispatch],
  );

  const { register, errors, onSubmit, formState } = useExpoForm(
    defaultValues,
    onSubmitHandler,
  );

  const onSkip = () => {
    // TO DO: create event!
  };

  return (
    <EventCreationWizardStep
      canSkip={canSkip}
      info={t('info')}
      onSave={onSubmit}
      onSkip={onSkip}
      breadcrumbsDisabled={!formState.isValid}
      continueDisabled={!formState.isValid}
    >
      <StepHeader title={t('title')} subtitle={t('subtitle')} />

      <ExpoFormComponent
        register={register}
        errors={errors}
        onSubmit={onSubmit}
      />
    </EventCreationWizardStep>
  );
};

ExpoComponent.propTypes = {
  canSkip: bool,
};

export default ExpoComponent;
