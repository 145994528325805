import amplitude from 'amplitude-js';

const setupAmplitudeClient = (apiKey = '') => {
  const client = amplitude.getInstance();
  client.init(apiKey);
  return client;
};

const amplitudeClient = setupAmplitudeClient(process.env.AMPLITUDE_API_KEY);

export default amplitudeClient;
