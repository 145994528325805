import {
  Button as MenuButton,
  Wrapper as MenuWrapper,
} from '@components/menu/menu-styled-components';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { calcSpace, COLOR, LINE } from '@hopin-team/ui-symbols';
import Megaphone from 'icons/megaphone.svg';
import React from 'react';
import styled, { css } from 'styled-components';

export const Styles = {};

Styles.Header = styled.div`
  background-color: var(--color-nav-background);
`;

Styles.Chevron = styled(Icon).attrs({
  name: 'arrow-back',
  color: 'grey-500',
  scale: 1,
})``;

const headerItemStyles = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  flex: auto;
  color: ${COLOR.GREY['800']};

  &:hover {
    color: ${COLOR.GREY['900']};
    background-color: var(--color-nav-hover);

    ${Styles.Chevron} {
      color: ${COLOR.GREY['900']};
    }
  }
`;

Styles.HeaderItem = styled.button`
  ${headerItemStyles}
`;

Styles.LogoBanner = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  border-bottom: ${LINE.WIDTH} solid ${COLOR.GREY['300']};
  position: sticky;
  top: 0;
  z-index: 4;
  height: ${calcSpace(8)};

  ${MenuWrapper} {
    ${headerItemStyles}
    flex: initial;
    padding: 0;
  }

  ${MenuButton} {
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const StyledLearnMenuIcon = styled.div`
  color: ${COLOR.GREY['600']};
`;

Styles.LearnMenuIcon = props => (
  <StyledLearnMenuIcon {...props}>
    <Megaphone />
  </StyledLearnMenuIcon>
);

Styles.ComboMark = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
`;
