import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Butticon } from '@hopin-team/ui-butticon';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import { shape, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Rating from './rating';
import {
  iconStyles,
  QuestionCard,
  questionCardStyles,
  QuestionWrapper,
} from './styles';

const ReadOnlyRatingQuestion = ({ question }) => {
  const { t } = useLocalization();

  return (
    <QuestionCard {...questionCardStyles} data-testid="readonly-question">
      <Flex flex={2} flexDirection="column">
        <QuestionWrapper>
          <Flex mb={3}>
            <StyledIcon name="chart-bar-two-tone" />
            <Text pattern="emphasis" ml={2}>
              {question.question}
            </Text>
          </Flex>
        </QuestionWrapper>
        <Box ml={4.5}>
          <Rating />
        </Box>
      </Flex>
      <StyledBox>
        <ToolTip
          align="bottom"
          tip={t('survey-builder.edit.question-cant-be-changed')}
        >
          <StyledButticon
            type="button"
            icon={'watching'}
            size="small"
            title={t('survey-builder.edit.question-enabled')}
            disabled
          />
        </ToolTip>
      </StyledBox>
    </QuestionCard>
  );
};

ReadOnlyRatingQuestion.propTypes = {
  question: shape({ question: string }),
};

export default ReadOnlyRatingQuestion;

const StyledBox = styled(Box)`
  height: 100%;
`;

const StyledIcon = styled(Icon)`
  ${iconStyles}
  background: var(--color-green-500)
`;

const StyledButticon = styled(Butticon)`
  pointer-events: none;
`;
