import Card from '@components/card-with-loading';
import TabbedChart from '@components/tabbed-chart';
import cx from 'classnames';
import { array, bool, number, object } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import RangePicker from '../../common/range-picker/range-picker';

const LoadingCard = styled(Card)`
  // allow loading height to equal loaded height
  min-height: 24rem;
`;

const RegistrationsActiveAttendeesOverTime = ({
  expanded,
  data,
  rangePickerProps,
  loadingInitialData,
  barLimit,
}) => {
  const [tab, setTab] = useState(0);

  // print-related logic
  let cardsToRender;
  if (expanded && data) {
    cardsToRender = data.map(tabData => {
      return { data: [tabData], tab: 0 };
    });
  } else {
    cardsToRender = [{ data: data, tab: tab }];
  }

  return (
    <div data-testid="registrations-active-attendees-over-time">
      {cardsToRender.map(card => (
        <div
          className={cx('row', {
            'no-page-break-inside': expanded,
          })}
          key={card.tab}
        >
          <div className="col-12">
            <LoadingCard isLoading={loadingInitialData}>
              <TabbedChart
                chartType="histogram"
                tab={card.tab}
                setTab={setTab}
                data={card.data}
                maxBars={barLimit}
                rangePickerComponent={<RangePicker {...rangePickerProps} />}
              />
            </LoadingCard>
          </div>
        </div>
      ))}
    </div>
  );
};

RegistrationsActiveAttendeesOverTime.defaultProps = {
  expanded: false,
};

RegistrationsActiveAttendeesOverTime.propTypes = {
  rangePickerProps: object.isRequired,
  loadingInitialData: bool.isRequired,
  data: array.isRequired,
  barLimit: number.isRequired,
  expanded: bool,
};

export default RegistrationsActiveAttendeesOverTime;
