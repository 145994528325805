import { Avatar } from '@hopin-team/ui-avatar';
import { Icon } from '@hopin-team/ui-icon';
import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Button = styled.button`
  &:focus:not(.focus-visible) {
    outline: none;
  }
`;

const scaleUp = keyframes`
from {
  transform: scale(0.9);
}
to {
  transform: scale(1);
}
`;

const scaleDown = keyframes`
from {
  transform: scale(1);
}
to {
  transform: scale(0.9);
}
`;

const getPositionStyles = ({ position }) => {
  switch (position) {
    case 'right':
      return css`
        top: 100%;
        left: 0;
        transform-origin: top left;
        margin-top: var(--spacing-8);
      `;
    case 'top':
      return css`
        bottom: 100%;
        left: 0;
        transform-origin: bottom center;
        margin-bottom: var(--spacing-8);
      `;
    default:
      return css`
        top: 100%;
        right: 0;
        transform-origin: top right;
        margin-top: var(--spacing-8);
      `;
  }
};

export const Menu = styled.div`
  background-color: var(--color-white);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-long);
  padding: var(--spacing-8);
  transition-property: opacity, visibility;
  transition-duration: var(--transition-duration-long), 0s;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.2), linear;
  transition-delay: 0s,
    ${({ expanded }) => (expanded ? '0s' : 'var(--transition-duration-long)')};
  animation: ${({ expanded }) =>
    expanded
      ? css`
          ${scaleUp} cubic-bezier(0.4, 0.2, 0, 1.2) normal
        `
      : css`
          ${scaleDown} cubic-bezier(0.4, 0.2, 0, 1.2) normal
        `};
  animation-duration: var(--transition-duration-long);
  z-index: 3;
  width: auto;
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  visibility: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
  pointer-events: ${({ expanded }) => (expanded ? 'all' : 'none')};
  position: absolute;
  ${getPositionStyles}
  ${({ boundingClientRect, closing, expanded, fixed }) =>
    fixed && (closing || expanded) && boundingClientRect
      ? css`
          position: fixed;
          top: ${(Math.floor(boundingClientRect.top) / 16).toFixed(2)}rem;
          left: ${(Math.floor(boundingClientRect.left) / 16).toFixed(2)}rem;
          bottom: auto;
          right: auto;
          margin: 0;
        `
      : ''}
`;

export const MenuHeading = styled.h2`
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);
  color: var(--color-text-light);
  text-transform: uppercase;
  margin: 0;
  line-height: 1.6;
`;

export const MenuItem = styled.div`
  font-size: var(--font-size);
  font-weight: var(--font-weight-medium);
  list-style-type: none;
  color: var(--color-text);
  width: 100%;
  padding: var(--spacing-16);
  min-width: fit-content;
  white-space: nowrap;
  word-break: keep-all;
  border-radius: 0.5rem;

  :hover {
    background-color: var(--color-gray-200);
  }

  a,
  button {
    display: flex;
    align-items: center;
  }

  a {
    font-weight: var(--font-weight-medium);
    color: var(--color-text);
    text-decoration: none;

    &:hover {
      color: var(--color-text);
    }

    &:focus:not(.focus-visible) {
      outline: none;
    }
  }

  svg:not(${Icon} svg) {
    margin-right: var(--spacing-8);
    color: var(--color-gray-600);
  }

  ${Avatar} {
    margin-right: var(--spacing-8);
    border-radius: var(
      --border-radius
    ); // Todo we need to update the avatar component for this
  }
`;

export const MenuSection = styled.section`
  padding-top: var(--spacing-12);
  padding-bottom: var(--spacing-12);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & + & {
    border-top: solid var(--color-gray-200);
  }
`;
