import hopinApi from '@api/hopin';
import { AlertsContext } from '@features/alerts/alerts-provider';
import EventBannerUpload from '@features/events/event-banner-upload';
import { useLocalization } from '@features/localization';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { FieldLabel } from '@hopin-team/ui-field-label';
import { uploadFileToS3 } from '@util/upload-file-to-s3';
import { func } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const EventBannerComponent = ({ register, setValue, setBannerUploading }) => {
  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization(
    'event-creation-wizard.steps.basic-details.form.file-upload',
  );
  const { id: userId } = useSelector(state => state?.user);
  const imageUrl = useSelector(state => state.basicDetails.imageUrl);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = async file => {
    try {
      setErrorMessage('');
      setBannerUploading(true);

      const {
        data: { presigned_url, params, image_url },
      } = await hopinApi
        .getPresignedUrl({
          fileName: file.name,
          entityField: 'wizard_banner',
          entityName: 'user',
          entityId: Number(userId),
        })
        .json();

      await uploadFileToS3(presigned_url, file, params);
      setValue('imageUrl', image_url);
    } catch (e) {
      addAlert({
        active: true,
        pattern: 'error',
        text: t('error-message-generic'),
      });
    } finally {
      // Reset the uploading flag if we've successfully uploaded
      // the banner or if we've encountered an error
      setBannerUploading(false);
    }
  };

  const onDropError = fileRejections => {
    // We only respond to file-size errors because the `image-dropzone`
    // component renders an inline message for file-type errors.
    const fileSizeErrors = fileRejections.filter(({ errors }) =>
      errors.find(error => error.code === 'file-too-large'),
    );

    if (fileSizeErrors.length > 0) {
      setErrorMessage(t('error-message-file-size'));
    }
  };

  const onRemove = () => {
    setValue('imageUrl', '');
  };

  return (
    <>
      <FieldLabel
        fieldId="event-banner-dropzone"
        label={t('event-banner-upload.label')}
      />
      <EventBannerUpload
        onDrop={onDrop}
        onDropError={onDropError}
        onRemove={onRemove}
        enforceMaxSize={true}
        pictureUrl={imageUrl}
        helpText=""
      />

      {errorMessage && <FieldErrorMessage mt={1} errorMessage={errorMessage} />}

      <input hidden name="imageUrl" ref={register('imageUrl')} />
    </>
  );
};

EventBannerComponent.propTypes = {
  setValue: func.isRequired,
  register: func.isRequired,
  setBannerUploading: func.isRequired,
};

export default EventBannerComponent;
