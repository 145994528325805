import { Flex } from '@hopin-team/ui-flex';
import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

Styles.Icon = styled.div`
  width: ${rem(48)};
  height: ${rem(48)};
  border-radius: 50%;
  border: 1px solid var(--color-gray-400);
  display: flex;
  justify-content: center;
  align-items: center;
`;

Styles.Flex = styled(Flex)`
  margin-bottom: ${rem(12)};

  & > a {
    margin-right: ${rem(16)};
  }
`;
