import { useEventCreationWizard } from '@features/event-creation-wizard/event-creation-wizard-provider';
import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import React from 'react';

import ErrorImage from './error-image.svg';

const ErrorView = () => {
  const { t } = useLocalization('event-creation-wizard.error-view');
  const { createEvent } = useEventCreationWizard();

  const handleRetry = () => {
    /** @NOTE Might be worth track user retry */
    createEvent();
  };

  return (
    <Flex justifyContent="center" alignItems="center" style={{ width: '100%' }}>
      <Flex
        flexDirection="column"
        alignItems="center"
        style={{ maxWidth: rem(368) }}
      >
        <ErrorImage width={320} />
        <Text element="p" align="center" style={{ fontWeight: 500 }} mb={2}>
          {t('info')}
        </Text>

        <Button
          size="small"
          isInline
          isOutlined
          px={3}
          style={{ border: 'none' }}
          onClick={handleRetry}
        >
          {t('retry')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ErrorView;
