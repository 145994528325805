import { compose } from 'lodash/fp';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal, openModal } from '@/redux/reducers/modal';
import withReduxProvider from '@/redux/with-provider';

import { useLocalization, withLocalizationProvider } from '.';
import { LANGUAGE_PICKER_MODAL_TYPE } from './constants';
import { LanguagePicker } from './language-picker';

const DashboardHeaderLangPicker = () => {
  const { t } = useLocalization();
  const dispatch = useDispatch();
  const modalType = useSelector(({ modal }) => modal.type);
  const modalIsShowing = modalType === LANGUAGE_PICKER_MODAL_TYPE;

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleButtonClick = e => {
    if (e.target === e.currentTarget) {
      dispatch(openModal(LANGUAGE_PICKER_MODAL_TYPE));
    }
  };

  return (
    <>
      <button onClick={handleButtonClick}>{t('common.language')}</button>
      <LanguagePicker isShowing={modalIsShowing} onClose={handleClose} />
    </>
  );
};

export default compose(
  withReduxProvider,
  withLocalizationProvider,
)(DashboardHeaderLangPicker);
