import 'react-nice-dates/build/style.css';

import {
  DatePickerContainer,
  StyledHr,
} from '@components/sessions-filters/styles';
import { i18n } from '@features/localization';
import { Linkicon } from '@hopin-team/ui-linkicon';
import { enGB } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates';
const { t } = i18n;

const DateFilter = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = newFocus => {
    setFocus(newFocus || START_DATE);
  };
  return (
    <DatePickerContainer>
      <DateRangePickerCalendar
        startDate={startDate}
        endDate={endDate}
        focus={focus}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onFocusChange={handleFocusChange}
        locale={enGB}
      />
      <StyledHr />
      <Linkicon
        label={t('roundtables.filtering.clear-dates')}
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
        }}
        leadingIcon="close"
        scale={3}
        iconScale={1}
        ml={2}
      ></Linkicon>
    </DatePickerContainer>
  );
};

DateFilter.propTypes = {
  startDate: PropTypes.object, // JS Date
  setStartDate: PropTypes.func,
  endDate: PropTypes.object, // JS Date
  setEndDate: PropTypes.func,
};

export default DateFilter;
