import { timezonesMap } from '@features/constants/timezones-map';
import { EXPERIENCE_TEMPLATE_TO_VENUE_TYPE } from '@features/event-creation-wizard/constants/event-experience';
import { stepMap } from '@features/event-creation-wizard/constants/step-map';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { setAddOns } from '@features/event-creation-wizard/redux/add-ons/add-ons-slice';
import {
  setEventLocationAddress,
  setEventLocationName,
  setEventLocationType,
} from '@features/event-creation-wizard/redux/event-location/event-location-slice';
import { setEventType } from '@features/event-creation-wizard/redux/event-type/event-type-slice';
import { setBasicDetails } from '@features/event-creation-wizard/redux/step-specific-state/basic-details-slice';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import { useStepsInfo } from '@features/event-creation-wizard/shared/useStepsInfo';
import { getSelectedTimezone } from '@features/event-creation-wizard/utils/time-zone-selectors';
import { useLocalization } from '@features/localization';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getExperienceStepFormValidations } from '../validations/experience-step-form-validations';

export const useExperienceStepForm = () => {
  const { t } = useLocalization('event-creation-wizard');
  const dispatch = useDispatch();
  const stepsInfo = useStepsInfo();
  const { push } = useHistory();

  const {
    basicDetails: { name, startDate, endDate, maxEventLengthHours },
    eventLocation: { name: eventLocationName, address: eventLocationAddress },
    template: { id: templateId },
  } = useSelector(state => state);

  const defaultValues = {
    name,
    startDate,
    endDate,
    timezone: getSelectedTimezone().value,
    eventLocationName,
    eventLocationAddress:
      typeof eventLocationAddress === 'string'
        ? eventLocationAddress
        : JSON.stringify(eventLocationAddress),
  };

  const {
    errors,
    register,
    setValue,
    formState,
    handleSubmit,
    trigger,
    watch,
    ...rest
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: getExperienceStepFormValidations({
      defaultValues,
      maxEventLengthHours,
      t,
    }),
  });

  useEffect(() => {
    trigger(['startDate', 'endDate']);
  }, [maxEventLengthHours, trigger]);

  const onSubmit = async ({
    name,
    startDate,
    endDate,
    timezone,
    eventLocationName,
    eventLocationAddress,
  }) => {
    if (!formState.isValid) {
      return;
    }

    const ianaTz = timezonesMap[timezone];
    const basicDetails = {
      name,
      startDate: zonedTimeToUtc(startDate, ianaTz),
      endDate: zonedTimeToUtc(endDate, ianaTz),
      timezone,
      maxEventLengthHours,
    };

    batch(() => {
      const templateData = templates[templateId];
      dispatch(setVenue({ venue: templateData.defaultVenue }));
      dispatch(setAddOns(templateData.defaultAddOns));
      dispatch(setBasicDetails(basicDetails));
      dispatch(setEventType('hidden_event'));
      dispatch(
        setEventLocationType(EXPERIENCE_TEMPLATE_TO_VENUE_TYPE[templateId]),
      );

      dispatch(setEventLocationName(eventLocationName));
      eventLocationAddress &&
        dispatch(
          setEventLocationAddress(
            typeof eventLocationAddress === 'string'
              ? JSON.parse(eventLocationAddress)
              : eventLocationAddress,
          ),
        );
    });

    push(stepMap[stepsInfo.nextStep.name].path());
  };

  return {
    errors,
    register,
    setValue,
    trigger,
    watch,
    formState,
    onClickNext: handleSubmit(onSubmit),
    ...rest,
  };
};
