import { EVENT_STATUS, USER_STATUS } from '@features/events/event-info-utils';
import { LocalizationContext } from '@features/localization';
import { string } from 'prop-types';
import React, { useContext } from 'react';

import * as Styled from './event-title-component.styles';

export const EventTitle = ({ eventName, eventStatus, userStatus }) => {
  const { t } = useContext(LocalizationContext);
  const isLiveEvent = eventStatus === EVENT_STATUS.LIVE;
  const isEndedEvent =
    eventStatus === EVENT_STATUS.ENDED ||
    eventStatus === EVENT_STATUS.POST_EVENT;

  const showCheckmark =
    eventStatus === EVENT_STATUS.DEFAULT &&
    (userStatus === USER_STATUS.ATTENDING ||
      userStatus === USER_STATUS.ATTENDING_ORGANISER);

  const label = isLiveEvent
    ? t('registration-v1.event-status-live', { eventName })
    : eventName;

  return (
    <Styled.Title
      element="h1"
      pattern="headingThree"
      color="grey-900"
      scale={7}
      aria-label={label}
    >
      {showCheckmark && <Styled.SuccessCheckmark />}
      {isEndedEvent && (
        <Styled.EndedBadge
          element="small"
          shape="simple"
          colorPattern="inverse"
          aria-hidden="true"
          mr={1}
        >
          {t('registration-v1.ended')}
        </Styled.EndedBadge>
      )}
      {eventName}
      {isLiveEvent && (
        <Styled.LiveBadge
          element="small"
          shape="rounded"
          colorPattern="danger"
          aria-hidden="true"
          mr={1}
        >
          {t('registration-v1.live')}
        </Styled.LiveBadge>
      )}
    </Styled.Title>
  );
};

EventTitle.propTypes = {
  eventName: string.isRequired,
  userStatus: string.isRequired,
  eventStatus: string.isRequired,
};
