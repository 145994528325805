import { FETCHING_STATE } from '@features/recordings/redux/initial-state';
import {
  deleteRecording as deleteRecordingService,
  getRecording as getRecordingService,
  updateRecording as updateRecordingService,
} from '@features/recordings/services/recordingService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { selectToken } from '../auth/auth-selectors';

export const updateRecordingThunk = createAsyncThunk(
  'recording/updateRecordingThunk',
  async (args, { getState, rejectWithValue }) => {
    const { recording, attributes, onSuccess, onError } = args;

    const token = selectToken(getState());
    try {
      const result = await updateRecordingService(
        recording.id,
        attributes,
        token,
      );

      if (onSuccess) {
        onSuccess();
      }

      return result;
    } catch (err) {
      if (onError) {
        onError();
      }
      return rejectWithValue();
    }
  },
);

export const deleteRecordingThunk = createAsyncThunk(
  'recording/deleteRecordingThunk',
  async ({ id, onSuccess, onError }, { getState, rejectWithValue }) => {
    const token = selectToken(getState());
    try {
      const response = await deleteRecordingService(id, token);

      if (onSuccess) {
        onSuccess();
      }

      return response;
    } catch (e) {
      if (onError) {
        onError();
      }
      return rejectWithValue(e);
    }
  },
);

export const fetchRecording = createAsyncThunk(
  'recording/fetchRecording',
  async (args, { getState, rejectWithValue }) => {
    const { id, force = false } = args;
    const { recordings } = getState();
    const token = selectToken(getState());

    if (recordings[id]?.createdAt && !force) return recordings[id];

    try {
      const recording = await getRecordingService(id, token);
      return recording;
    } catch (err) {
      return rejectWithValue({ id });
    }
  },
);

export const defaultRecordingState = {
  ...FETCHING_STATE.default,
  isDeleting: false,
  isProcessing: false,
};

export const recordingsSlice = createSlice({
  name: 'recordings',
  initialState: {},
  reducers: {
    updateRecording: (state, action) => {
      const id = action.payload?.id;
      if (!id) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.payload,
        },
      };
    },
    addRecordings: (state, action) => {
      const { recordings } = action.payload;
      if (!recordings || !recordings.length) return state;

      let newState = {
        ...state,
      };

      recordings.forEach(recording => {
        newState[recording.id] = {
          ...defaultRecordingState,
          isDone: true,
          ...recording,
        };
      });

      return newState;
    },
    updateMarPlayableUrl: (state, action) => {
      const id = action.payload?.id;
      if (!id) return state;

      state[id].mar.playable.url = action.payload.playableUrl;
    },
    removeRecording: (state, action) => {
      const id = action.payload.id;
      if (!id) return state;
      delete state[id];
    },
  },
  extraReducers: builder => {
    // updateRecordingThunk
    builder.addCase(updateRecordingThunk.pending, (state, action) => {
      const { recording, attributes } = action.meta.arg;
      state[recording.id] = {
        ...state[recording.id],
        ...attributes,
      };
    });

    builder.addCase(updateRecordingThunk.rejected, (state, action) => {
      const { recording } = action.meta.arg;
      state[recording.id] = recording;
    });

    // deleteRecordingThunk
    builder.addCase(deleteRecordingThunk.pending, (state, action) => {
      const { id } = action.meta.arg;
      state[id].isDeleting = true;
    });

    builder.addCase(deleteRecordingThunk.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      delete state[id];
    });

    builder.addCase(deleteRecordingThunk.rejected, (state, action) => {
      const { id } = action.meta.arg;
      state[id].isDeleting = false;
    });

    // fetchRecording
    builder.addCase(fetchRecording.pending, (state, action) => {
      const { id } = action.meta.arg;
      state[id] = {
        ...FETCHING_STATE.pending,
        id,
      };
    });

    builder.addCase(fetchRecording.rejected, (state, action) => {
      const { id } = action.meta.arg;
      state[id] = FETCHING_STATE.rejected;
    });

    builder.addCase(fetchRecording.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      state[id] = {
        ...action.payload,
        ...defaultRecordingState,
        ...FETCHING_STATE.fulfilled,
      };
    });
  },
});

export const {
  addRecordings,
  updateRecording,
  updateMarPlayableUrl,
  removeRecording,
} = recordingsSlice.actions;

export default recordingsSlice.reducer;
