import eventDashboardApi from '@api/event-dashboard';
import hopinApi from '@api/hopin';
import peopleApi from '@api/people';
import Loading from '@components/loading';
import NetworkingModal from '@components/people/networking-modal';
import SaveBar from '@components/save-bar';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import HidePeoplePrompt from '@features/people/components/hide-people-prompt';
import MatchmakingOptions from '@features/people/components/matchmaking-options-component';
import MeetingDurationFields from '@features/people/components/meeting-duration-fields';
import {
  calculateMinutes,
  calculateSeconds,
  mmSsToSeconds,
} from '@features/people/components/meeting-duration-helpers';
import { Box } from '@hopin-team/ui-box';
import { FieldErrorMessage } from '@hopin-team/ui-field-error-message';
import { Text } from '@hopin-team/ui-text';
import { Toast } from '@hopin-team/ui-toast';
import * as Routes from '@routes';
import { isAfter, sub } from 'date-fns';
import compose from 'lodash/fp/compose';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import withReduxProvider from '@/redux/with-provider';

import SpeedNetworking from './components/speed-networking';
import InterestsSettingsBlock from './interests-settings-block';

const PeopleComponent = ({
  authToken,
  hasMeetingsArea,
  personas,
  event,
  isDraft,
}) => {
  const { t } = useLocalization();
  const { addAlert } = useContext(AlertsContext);
  const dispatch = useDispatch();

  const categoriesData = useSelector(({ networking }) => networking.categories);
  const [showWarning, setShowWarning] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [matchmakingOption, setMatchmakingOption] = useState(null);
  const [eventSettings, setEventSettings] = useState(null);
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [durationError, setDurationError] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPeopleList, setShowPeopleList] = useState(true);
  const [showPeoplePrompt, setShowPeoplePrompt] = useState(false);

  const mapResponseToState = settings => {
    return {
      ...omit(settings, 'min_meeting_length', 'max_meeting_length'),
      minDurationMm: calculateMinutes(settings.min_meeting_length),
      minDurationSs: calculateSeconds(settings.min_meeting_length),
      maxDurationMm: calculateMinutes(settings.max_meeting_length),
      maxDurationSs: calculateSeconds(settings.max_meeting_length),
    };
  };

  const eventStartTime = sub(new Date(event.time_start), {
    ...(event.pre_event_enabled && {
      [event.pre_event_duration_unit]: event.pre_event_duration_amount,
    }),
    ...(!event.pre_event_enabled && {
      minutes: 5,
    }),
  });

  const lockProfileMatching = isAfter(Date.now(), eventStartTime) && !isDraft;

  useEffect(() => {
    const warningTime = sub(new Date(eventStartTime), {
      days: 7,
    });
    if (isAfter(Date.now(), warningTime)) setShowWarning(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingSettings(true);
      try {
        const { token } = await hopinApi.getUserToken();
        const response = await peopleApi.getPeopleSettings(
          event.external_id,
          token,
        );

        setEventSettings(mapResponseToState(response.event_setting));
        setToken(token);
      } catch (e) {
        addAlert({
          active: true,
          text: t('people.people-area.something-went-wrong'),
          pattern: 'error',
        });
      } finally {
        setIsLoadingSettings(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, event.external_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await eventDashboardApi.getVenueSettings(
          event.external_id,
        );

        response.data.attributes.attendees_visiblity === 'show_none' &&
          setShowPeopleList(false);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, [event.external_id]);

  const toggleSetting = key => {
    setEventSettings(currentSettings => ({
      ...currentSettings,
      [key]: !currentSettings[key],
    }));
  };

  const handleSpeedNetworkingToggle = () => {
    if (!showPeopleList && eventSettings.enable_speed_networking) {
      setShowPeoplePrompt(true);
    } else {
      toggleSetting('enable_speed_networking');
    }
  };

  const updateDuration = (key, value) => {
    setEventSettings(currentSettings => ({
      ...currentSettings,
      [key]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setDurationError();
      const max_meeting_length = mmSsToSeconds(
        eventSettings.maxDurationMm,
        eventSettings.maxDurationSs,
      );
      const min_meeting_length = mmSsToSeconds(
        eventSettings.minDurationMm,
        eventSettings.minDurationSs,
      );

      if (max_meeting_length < 30) {
        setDurationError(t('networking.meeting-duration-error'));
        return;
      }

      if (max_meeting_length <= min_meeting_length) {
        setDurationError(t('networking.min-meeting-duration-error'));
        return;
      }

      setIsSubmitting(true);

      const data = {
        max_meeting_length,
        min_meeting_length,
        ...omit(
          eventSettings,
          'maxDurationMm',
          'maxDurationSs',
          'minDurationMm',
          'minDurationSs',
        ),
      };

      const response = await peopleApi.updatePeopleSettings(
        event.external_id,
        token,
        data,
      );

      if (response.event_setting) {
        addAlert({
          active: true,
          text: t('people.people-area.settings-saved'),
          pattern: 'success',
        });
        setEventSettings(mapResponseToState(response.event_setting));
      }
    } catch (e) {
      addAlert({
        active: true,
        text: t('people.people-area.something-went-wrong'),
        pattern: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box pt={2} pb={3}>
      <Alerts />
      <InterestsSettingsBlock
        event={event}
        setShowSettingsModal={() => setShowSettingsModal(true)}
        setIsLoading={setIsLoading}
        addAlert={addAlert}
        lockProfileMatching={lockProfileMatching}
      />

      {showWarning && (
        <StyledToast
          isInverse
          colorPattern="warning"
          icon="warning"
          iconColor="yellow-600"
          withCloseButton="true"
          onClose={() => setShowWarning(false)}
        >
          <Text pattern="body" color="grey-800">
            <Text pattern="body" color="blue-500">
              {t('people.people-area.interests-link')}{' '}
            </Text>
            {lockProfileMatching
              ? t('people.people-area.interests-warning-expired')
              : t('people.people-area.interests-warning')}
          </Text>
        </StyledToast>
      )}
      <Loading isLoading={isLoadingSettings}>
        {!isLoadingSettings && eventSettings && (
          <>
            <SpeedNetworking
              isToggled={eventSettings.enable_speed_networking}
              onChange={() => handleSpeedNetworkingToggle()}
            />

            {eventSettings.enable_speed_networking && (
              <>
                <Text pattern="body" color="grey-800" mt={4} mb={2} element="p">
                  {t('people.people-area.pair-people-up')}
                </Text>
                <MatchmakingOptions
                  setMatchmakingOption={setMatchmakingOption}
                  matchmakingOption={matchmakingOption}
                  authToken={authToken}
                  event={event}
                  hasMeetingsArea={hasMeetingsArea}
                  personas={personas}
                  t={t}
                  categoriesData={categoriesData}
                  lockProfileMatching={lockProfileMatching}
                  setShowSettingsModal={setShowSettingsModal}
                />
              </>
            )}

            <form onSubmit={handleSubmit}>
              {eventSettings.enable_speed_networking && (
                <StyledBox>
                  <MeetingDurationFields
                    minDurationSs={eventSettings.minDurationSs}
                    minDurationMm={eventSettings.minDurationMm}
                    durationMm={eventSettings.maxDurationMm}
                    durationSs={eventSettings.maxDurationSs}
                    setDurationSs={value =>
                      updateDuration('maxDurationSs', value)
                    }
                    setDurationMm={value =>
                      updateDuration('maxDurationMm', value)
                    }
                    setMinDurationMm={value =>
                      updateDuration('minDurationMm', value)
                    }
                    setMinDurationSs={value =>
                      updateDuration('minDurationSs', value)
                    }
                  />
                  {durationError && (
                    <FieldErrorMessage mt={3} errorMessage={durationError} />
                  )}
                </StyledBox>
              )}
              <Box mt={5} mb={4}>
                <Text element="p" mb={3} pattern="headingFour" color="grey-800">
                  {t('people.people-area.attendee-actions')}
                </Text>

                <Text
                  pattern="body"
                  element="p"
                  scale={4}
                  linkStyle="auto"
                  color="grey-600"
                  mb={3}
                  data-testid="venue-controls-link"
                >
                  {t('people.people-area.control-privacy-settings')}{' '}
                  <a href={Routes.organisersEventVenueControlsPath(event.slug)}>
                    {t('people.people-area.control-privacy-settings-link')}
                  </a>
                </Text>
              </Box>
              <SaveBar buttonText={t('save')} isLoading={isSubmitting} />
            </form>
          </>
        )}
      </Loading>

      <NetworkingModal
        categoriesData={categoriesData}
        eventExternalId={event.external_id}
        isOpen={showSettingsModal}
        setIsOpen={setShowSettingsModal}
        isSubmitDisabled
        isLoading={isLoading}
        lockProfileMatching={lockProfileMatching}
        showSettingsModal={showSettingsModal}
      />
      <HidePeoplePrompt
        showPeoplePrompt={showPeoplePrompt}
        setShowPeoplePrompt={setShowPeoplePrompt}
        onConfirm={() => toggleSetting('enable_speed_networking')}
        source={t('venue-controls.attendee-visibility.show-list-label')}
      />
    </Box>
  );
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
  withThemeProvider,
  withReduxProvider,
)(PeopleComponent);

PeopleComponent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string,
    network_type: PropTypes.string,
    external_id: PropTypes.string.isRequired,
    time_start: PropTypes.string,
    pre_event_duration_amount: PropTypes.number,
    pre_event_duration_unit: PropTypes.string,
    pre_event_enabled: PropTypes.bool,
  }).isRequired,
  eventExternalId: PropTypes.string,
  authToken: PropTypes.string.isRequired,
  hasMeetingsArea: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool.isRequired,
  personas: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      matchedIds: PropTypes.arrayOf(PropTypes.number),
      label: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
};

const StyledBox = styled(Box)`
  width: 23rem;
`;

const StyledToast = styled(Toast)`
  box-shadow: none;
`;
