import { Flex } from '@hopin-team/ui-flex';
import { rem } from 'polished';
import styled from 'styled-components';

export const ConfirmModalContainer = styled.div`
  padding: ${rem(16)};
`;

export const ConfirmModalHeader = styled(Flex)`
  padding-top: ${rem(20)};
  font-weight: 500;
  color: var(--color-black);
  justify-content: center;
`;

export const ConfirmModalContent = styled(Flex)`
  padding-top: ${rem(10)};
  font-weight: 400;
  justify-content: center;
`;

export const ConfirmModalFooter = styled(Flex)`
  padding-top: ${rem(20)};
  justify-content: center;
  flex-direction: row;
`;
