import ComplexityFormatSelector from '@features/event-creation-wizard/components/complexity-selector';
import { EVENT } from '@features/event-creation-wizard/constants/analytics';
import { routes } from '@features/event-creation-wizard/constants/step-map';
import { setAddOns } from '@features/event-creation-wizard/redux/add-ons/add-ons-slice';
import { setEventLocationType } from '@features/event-creation-wizard/redux/event-location/event-location-slice';
import { setEventType } from '@features/event-creation-wizard/redux/event-type/event-type-slice';
import {
  getInitialBasicDetailsState,
  resetEndDate,
  setBasicDetails,
  setMaxEventLengthHours,
} from '@features/event-creation-wizard/redux/step-specific-state/basic-details-slice';
import { setTemplate } from '@features/event-creation-wizard/redux/step-specific-state/template-slice';
import { setVenue } from '@features/event-creation-wizard/redux/step-specific-state/venue-slice';
import { redirectOnClose } from '@features/event-creation-wizard/utils/redirection';
import { useLocalization } from '@features/localization';
import { Text } from '@hopin-team/ui-text';
import segmentTrack from '@util/analytics/segment-client';
import React, { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useEventCreationWizard } from '../../event-creation-wizard-provider';
import {
  CloseButton,
  Content,
  FullScreenWrapper,
} from '../format-picker/styles';
import { webinarTemplates } from '../webinar-style/webinar-config';

const ComplexityPicker = () => {
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const { t } = useLocalization('event-creation-wizard');
  const { dashboardUrl } = useEventCreationWizard();
  const { dashboardOptedIn, appEnvironment } = useSelector(
    state => state.flags,
  );
  const { id: orgId, isOnFreePlan, features } = useSelector(
    state => state?.org,
  );
  const { defaultMaxEventLengthHours, defaultEventImageUrl } = useSelector(
    state => state.basicDetails,
  );

  const hasPro = features.eventsProEnabled;
  const hasLite = features.eventsLiteEnabled;

  const handleClose = useCallback(() => {
    redirectOnClose({ dashboardOptedIn, dashboardUrl, appEnvironment, orgId });
  }, [appEnvironment, dashboardOptedIn, dashboardUrl, orgId]);

  const startWebinarJourney = useCallback(
    ({ withReplace } = {}) => {
      batch(() => {
        dispatch(
          setBasicDetails(
            getInitialBasicDetailsState(
              defaultMaxEventLengthHours,
              defaultEventImageUrl,
            ),
          ),
        );
        dispatch(setEventLocationType('virtual'));
        dispatch(
          setVenue({
            venue: webinarTemplates['lite_webcast'].venue,
            isOnFreePlan,
          }),
        );
        dispatch(setEventType('hidden_event'));
        dispatch(setTemplate('virtual_lite'));
        dispatch(setAddOns(webinarTemplates['lite_webcast'].addons));
        dispatch(setMaxEventLengthHours({ maxEventLengthHours: 8 }));
        dispatch(resetEndDate());
      });

      const route = routes.basicDetails('virtual', 'virtual_lite');
      if (withReplace) {
        replace(route);
      } else {
        push(route);
      }
    },
    [
      defaultEventImageUrl,
      defaultMaxEventLengthHours,
      dispatch,
      isOnFreePlan,
      push,
      replace,
    ],
  );

  const startComplexJourney = useCallback(
    ({ withReplace } = {}) => {
      dispatch(
        setBasicDetails(
          getInitialBasicDetailsState(
            defaultMaxEventLengthHours,
            defaultEventImageUrl,
          ),
        ),
      );

      const route = routes.formatPicker();
      if (withReplace) {
        replace(route);
      } else {
        push(route);
      }
    },
    [defaultEventImageUrl, defaultMaxEventLengthHours, dispatch, push, replace],
  );

  const selectComplexity = useCallback(
    complexity => {
      segmentTrack(EVENT.COMPLEXITY_SELECTED, {
        organiser_id: orgId,
        complexity,
      });

      if (complexity === 'complex') {
        startComplexJourney();
      }

      if (complexity === 'essentials') {
        startWebinarJourney();
      }
    },
    [orgId, startComplexJourney, startWebinarJourney],
  );

  useEffect(() => {
    if (hasLite && !hasPro) {
      startWebinarJourney({ withReplace: true });
    } else if (!hasLite && hasPro) {
      startComplexJourney({ withReplace: true });
    } else if (!hasLite && !hasPro) {
      console.error(
        'Organisation does not have ability to create Essentials OR Complex events.',
      );
      handleClose();
    }
  }, [handleClose, hasLite, hasPro, startComplexJourney, startWebinarJourney]);

  return (
    <FullScreenWrapper>
      <CloseButton
        onClick={handleClose}
        aria-label={t('common.close')}
        data-testid="complexity-picker-close-button"
      />
      <Content>
        <Text pattern="pageHeading" as="h1" color="grey-800" mb={1.5}>
          {t('format-selector.title')}
        </Text>
        <Text pattern="body" as="p" color="grey-700" mb={3}>
          {t('format-selector.subtitle')}
        </Text>
        <ComplexityFormatSelector handleNext={selectComplexity} />
      </Content>
    </FullScreenWrapper>
  );
};

export default ComplexityPicker;
