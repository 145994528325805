import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import compose from 'lodash/fp/compose';
import { string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { StyledOverlay, StyledProgressBar } from './styles';

const ProgressModal = ({ initialBid }) => {
  const { addAlert } = useContext(AlertsContext);
  const { t } = useLocalization('sessions.bulk-edit.progress-modal');

  const [bid, setBid] = useState(initialBid);
  const [isComplete, setIsComplete] = useState(false);
  const [total, setTotal] = useState(1);
  const [pending, setPending] = useState(1);
  const [intervalId, setIntervalId] = useState();

  const poller = useCallback(async () => {
    if (bid) {
      const {
        is_complete: newIsComplete,
        total,
        pending,
        failures,
      } = await hopinApi.batchStatus(bid);

      if (isComplete == false && newIsComplete == true) {
        if (failures > 0) {
          addAlert({
            active: true,
            text: t('update-failed', { count: failures }),
            pattern: 'warning',
          });
        } else {
          addAlert({
            active: true,
            text: t('update-complete'),
            pattern: 'success',
          });
        }
        // batch is complete, reset the state to default
        setBid(null);
        setIsComplete(newIsComplete);
        setTotal(1);
        setPending(1);
        return;
      }

      setIsComplete(newIsComplete);
      setTotal(total);
      setPending(pending);
    }
  }, [bid, addAlert, isComplete, t]);

  useEffect(() => {
    if (initialBid) {
      // set isComplete to false before we call the hopinApi.batchStatus
      setIsComplete(false);
      setBid(initialBid);
    }
  }, [initialBid]);

  useEffect(() => {
    if (bid && intervalId == null) {
      const id = setInterval(poller, 3000);
      setIntervalId(id);
    }

    if (bid == null && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    // TODO: clear interval
  }, [bid, intervalId, poller]);

  const estimatedDurationMinutes = count => Math.ceil((0.45 * count) / 60);

  const renderOverlay = props => {
    return <StyledOverlay {...props} {...{ isFullScreen: false }} />;
  };

  return (
    <>
      <Alerts />

      {bid ? (
        <Modal
          isShowing
          isDismissible={false}
          size="small"
          renderOverlay={renderOverlay}
        >
          <Box mt={2} mb={2} ml={3} mr={3}>
            <Text pattern="bodyLargeBold" element={'p'} pb={0.75}>
              {t('saving-changes')}
            </Text>
            <Text pattern={'body'} color={'grey-600'}>
              {t('edits-are-processed', {
                count: estimatedDurationMinutes(total),
              })}
            </Text>
            <StyledProgressBar
              min={0}
              max={total}
              value={total - pending}
              label={'Sessions update progress'}
            />
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

ProgressModal.propTypes = {
  initialBid: string,
};

export default compose(
  withAlertsProvider,
  withLocalizationProvider,
)(ProgressModal);
