import { selectToken } from '@features/recordings/redux/slices/auth/auth-selectors';
import { selectEvent } from '@features/recordings/redux/slices/event/event-selectors';
import { getRecordingCuts } from '@features/recordings/services/recordingService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchRecordingCuts = async (_, { getState, rejectWithValue }) => {
  const authToken = selectToken(getState());
  const { eventId } = selectEvent(getState());

  try {
    return await getRecordingCuts(eventId, authToken);
  } catch (err) {
    return rejectWithValue({ eventId });
  }
};

export const fetchRecordingCutsThunk = createAsyncThunk(
  'recordingCuts/fetchRecordingCuts',
  fetchRecordingCuts,
);
