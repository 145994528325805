import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import { func, node, string } from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

export const Anchor = styled.a`
  color: var(--color-blue-400);
  text-decoration: underline;
  font-weight: var(--font-weight-medium);
`;

const Container = styled.div`
  max-width: ${rem(392)};
  margin: auto;
  text-align: center;
`;

const PostSignupView = ({ email, image, t, translationKeyPrefix }) => {
  const { addAlert } = useContext(AlertsContext);

  const onClick = async () => {
    try {
      await hopinApi.resendEmailConfirmation();
      addAlert({
        active: true,
        text: 'Verification email sent!',
        pattern: 'success',
      });
    } catch (err) {
      addAlert({
        active: true,
        text: err.message || 'Something went wrong!',
        pattern: 'error',
      });
    }
  };
  return (
    <Container>
      <Alerts />
      <Flex alignItems="center" flexDirection="column">
        {image}
        <Text mt={1} pattern="headingTwo" element="p">
          {t(`${translationKeyPrefix}.title`)}
        </Text>
        <Text mb={2} element="p">
          {t(`${translationKeyPrefix}.subtitle`, { email })}
        </Text>
        <Text mb={2} element="p">
          {t(`${translationKeyPrefix}.subtitleNote`)}
        </Text>
        <Button data-testid="resend-mail-button" onClick={onClick} mb={2}>
          {t('signup.post-signup.button')}
        </Button>
        <Flex alignItems="center">
          <Text scale={4} mr={1}>
            {t('signup.post-signup.helper')}
          </Text>
          <Anchor href="/registration">{t('signup.post-signup.link')}</Anchor>
        </Flex>
      </Flex>
    </Container>
  );
};

PostSignupView.propTypes = {
  email: string,
  image: node.isRequired,
  t: func.isRequired,
  translationKeyPrefix: string.isRequired,
};

export default withAlertsProvider(PostSignupView);
