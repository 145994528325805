import styled from 'styled-components';

export const EventInfoBox = styled.div.attrs(() => ({
  className: 'card event-page__header-event-info',
}))`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EventInfoFooter = styled.div`
  margin-top: auto;
`;
