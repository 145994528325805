import { STEPS } from '@components/event-progress/constants/steps';
import { componentExists } from '@components/nav/nav-config';
import withConnectedRouter from '@components/nav/with-connected-router';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import { ReactIDProvider } from '@hopin-team/react-id';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { bool, string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { updateStepStatus } from '@/redux/reducers/setup-checklist';
import withReduxProvider from '@/redux/with-provider';

import HeaderComponent from './component';

const HeaderContainer = ({
  currentPath,
  eventId,
  registrationPage,
  showEngagementScore,
}) => {
  const dispatch = useDispatch();
  const slug = useSelector(({ event }) => event.slug);
  const location = useLocation().pathname;
  const handlePreview = async () => {
    dispatch(
      updateStepStatus({
        eventId: eventId,
        step: STEPS.PREVIEWED,
        status: 'completed',
      }),
    );
  };
  // TODO: This avoids always using the location on currentLocation to
  // Prevent breaking something that may be relying on the provider (rails currently)
  // provides the currentPath as of this commit
  currentPath = componentExists(location) ? location : currentPath;
  return (
    <ReactIDProvider>
      <HeaderComponent
        slug={slug}
        currentPath={currentPath}
        onPreview={handlePreview}
        registrationPage={registrationPage}
        showEngagementScore={showEngagementScore}
      />
    </ReactIDProvider>
  );
};

HeaderContainer.propTypes = {
  currentPath: string.isRequired,
  eventId: string.isRequired,
  registrationPage: string,
  showEngagementScore: bool,
};

export default compose(
  withErrorHandling({ tag: LOGGER_NAMES.EVENT_DASH_HEADER }),
  withThemeProvider,
  withReduxProvider,
  withConnectedRouter,
)(HeaderContainer);
