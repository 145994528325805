export const TEMPLATE_EVENT_PARTS = {
  stage: {
    name: 'Stage',
    color: 'green',
    icon: 'camera',
    tagline: 'The main stage for keynotes',
  },
  sessions: {
    name: 'Sessions',
    color: 'blue',
    icon: 'team',
    tagline: 'Interactive roundtables',
  },
  schedule: {
    name: 'Schedule',
    color: 'red',
    icon: 'calendar-note',
    tagline: 'Agenda for your attendees',
  },
  expo: {
    name: 'Expo',
    color: 'orange',
    icon: 'booth',
    tagline: 'Virtual booths for sponsors',
  },
  networking: {
    name: 'Networking',
    color: 'purple',
    icon: 'handshake',
    tagline: 'Attendee one-on-ones',
  },
};

export const TEMPLATES = [
  {
    id: 'GDusstmhHma7yNI9v9CdQD5gE',
    name: 'Webinars',
    attendees: '100 - 2,500 attendees',
    description:
      'Events that focus on a single topic and that have one or multiple sessions and speakers.',
    enabledEventParts: ['stage', 'sessions', 'schedule'],
    image: require('templates/use-form/webinar@2x.png'),
    venueImage1x: require('templates/venue/webinars@1x.png'),
    venueImage2x: require('templates/venue/webinars@2x.png'),
    venueImage3x: require('templates/venue/webinars@3x.png'),
    registrationImage1x: require('templates/registration/webinars@1x.png'),
    registrationImage2x: require('templates/registration/webinars@2x.png'),
    registrationImage3x: require('templates/registration/webinars@3x.png'),
  },
  {
    id: 'Jad0WqtkOhVBZeuO02ojbgdjn',
    name: 'Conferences & Summits',
    attendees: '100 - 20,000 attendees',
    enabledEventParts: ['stage', 'sessions', 'schedule', 'expo', 'networking'],
    description:
      'Single or multi-day conferences that include expo booths, keynote speakers and multiple sessions.',
    image: require('templates/use-form/conferences-summits@2x.png'),
    venueImage1x: require('templates/venue/conferences@1x.png'),
    venueImage2x: require('templates/venue/conferences@2x.png'),
    venueImage3x: require('templates/venue/conferences@3x.png'),
    registrationImage1x: require('templates/registration/conferences@1x.png'),
    registrationImage2x: require('templates/registration/conferences@2x.png'),
    registrationImage3x: require('templates/registration/conferences@3x.png'),
  },
  {
    id: 'rEClrJgoI0kt4ofxi54UuApWe',
    name: 'Meetups',
    attendees: '10 - 50 attendees',
    enabledEventParts: ['sessions', 'schedule', 'networking'],
    description:
      'Collaborative events that encourage attendee participation through the use of sessions and networking.',
    image: require('templates/use-form/meetups@2x.png'),
    venueImage1x: require('templates/venue/meetups@1x.png'),
    venueImage2x: require('templates/venue/meetups@2x.png'),
    venueImage3x: require('templates/venue/meetups@3x.png'),
    registrationImage1x: require('templates/registration/meetups@1x.png'),
    registrationImage2x: require('templates/registration/meetups@2x.png'),
    registrationImage3x: require('templates/registration/meetups@3x.png'),
  },
  {
    id: 'zZTYAs2NraiVngQgE4pDXn6Q0',
    name: 'Workshops & Courses',
    attendees: '10 - 100 attendees',
    enabledEventParts: ['stage', 'sessions', 'schedule'],
    description:
      'Educational workshops or online courses with interactive sessions and attendee participation',
    image: require('templates/use-form/workshops-courses@2x.png'),
    venueImage1x: require('templates/venue/workshops@1x.png'),
    venueImage2x: require('templates/venue/workshops@2x.png'),
    venueImage3x: require('templates/venue/workshops@3x.png'),
    registrationImage1x: require('templates/registration/workshops@1x.png'),
    registrationImage2x: require('templates/registration/workshops@2x.png'),
    registrationImage3x: require('templates/registration/workshops@3x.png'),
  },
  {
    id: 'xAsmg8YZuM8TwJ2fM6KeRuNVH',
    name: 'Amazon FC Tours Template',
    attendees: '100 - 2,500 attendees',
    description: 'Amazon Fulfillment Center Tours.',
    enabledEventParts: ['stage'],
    image: require('templates/use-form/webinar@2x.png'),
    venueImage1x: require('templates/venue/webinars@1x.png'),
    venueImage2x: require('templates/venue/webinars@2x.png'),
    venueImage3x: require('templates/venue/webinars@3x.png'),
    registrationImage1x: require('templates/registration/webinars@1x.png'),
    registrationImage2x: require('templates/registration/webinars@2x.png'),
    registrationImage3x: require('templates/registration/webinars@3x.png'),
  },
];

export const jobStatuses = {
  FAILED: 'failed',
  COMPLETED: 'completed',
};

export const getPusherChannel = eventExternalId =>
  `private-notifications-event-duplication-progress-${eventExternalId}`;

export const pusherEvents = {
  EVENT_DUPLICATION_EVENT_SECTION_FINISHED:
    'event_duplication_event_section_finished',
  EVENT_DUPLICATION_FINISHED: 'event_duplication_finished',
};
