import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Avatar } from '@hopin-team/ui-avatar';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Grid } from '@hopin-team/ui-grid';
import { Image } from '@hopin-team/ui-image';
import { Logo } from '@hopin-team/ui-logo';
import { SelectGroup, SelectItem } from '@hopin-team/ui-select-items';
import { LAYOUT } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import * as P from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ButtonText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserText = styled(Text)`
  font-size: 15px;
  line-height: 32px;
`;

const BannerText = styled(Text).attrs({
  ml: 'auto',
  mr: 'auto',
})`
  font-size: 18px;
  line-height: 32px;

  strong {
    font-weight: 500;
  }
`;

const AppImage = styled(Image)`
  // fixes image blurriness
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0); /* IE 9 */
  -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
  transform: translateZ(0);
`;

const ExternalInstallIntentComponent = ({
  user,
  organizations,
  oauthApplication,
  installIntentionRedirectUrl,
}) => {
  const { t } = useLocalization('external-install-intent');

  // assume we always have at least one org here
  // otherwise the controller would've redirected us away
  const [selectedOrg, setSelectedOrg] = useState(organizations[0]);
  const [installUrl, setInstallUrl] = useState();

  const showOrgSelector = organizations.length > 1;

  useEffect(() => {
    setInstallUrl(
      `${installIntentionRedirectUrl}?org_external_id=${selectedOrg.externalId}`,
    );
  }, [installIntentionRedirectUrl, selectedOrg]);

  return (
    <>
      <Box pt={3}>
        <Box border="grey-500" level="medium" p={3}>
          <Flex justifyContent={['space-between']} p={1}>
            <Logo mr="auto" type="hopin-two-tone" />
            <Flex ml="auto">
              <Avatar mr="auto" src={user.picture} size="small" />
              <UserText ml={1}>
                {user.firstName} {user.lastName}
              </UserText>
            </Flex>
          </Flex>
          <Box container={LAYOUT.CONTAINER} py={3}>
            <Flex justifyContent={'center'}>
              <div style={{ width: '250px' }}>
                <AppImage
                  src={oauthApplication.logoUrl}
                  ratio="1:1"
                  fit="scale-down"
                />
              </div>
            </Flex>
            {showOrgSelector && (
              <>
                <Flex justifyContent={'center'} mt={3}>
                  <BannerText
                    dangerouslySetInnerHTML={{
                      __html: t('install-prompt', {
                        app: oauthApplication.name,
                      }),
                    }}
                  />
                </Flex>
                <SelectGroup
                  data-testid="org-selector"
                  isSingle
                  onChange={event =>
                    setSelectedOrg(
                      organizations.find(
                        org => org.externalId == event.target.value,
                      ),
                    )
                  }
                  selectedValue={selectedOrg.externalId}
                  mt={3}
                >
                  <Grid gridGap="0.5rem">
                    {organizations.map(({ externalId, name }) => (
                      <SelectItem
                        id={externalId}
                        label={name}
                        key={name.toLowerCase()}
                        value={externalId}
                      />
                    ))}
                  </Grid>
                </SelectGroup>
              </>
            )}
            <Button
              leadingIcon="open-in-new"
              href={installUrl}
              mt={3}
              data-testid="install-button"
            >
              <ButtonText
                dangerouslySetInnerHTML={{
                  __html: t('install-button', {
                    app: oauthApplication.name,
                    org: selectedOrg.name,
                  }),
                }}
              />
            </Button>
            <Flex justifyContent={'center'} mt={3}>
              <Text
                align="center"
                pattern="body"
                dangerouslySetInnerHTML={{
                  __html: t('share-warning', {
                    app: oauthApplication.name,
                  }),
                }}
              />
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ExternalInstallIntentComponent.propTypes = {
  user: P.shape({
    firstName: P.string,
    lastName: P.string,
    picture: P.string,
  }),
  organizations: P.arrayOf(
    P.shape({
      externalId: P.string,
      name: P.string,
      imageUrl: P.string,
    }),
  ),
  oauthApplication: P.shape({
    externalId: P.string,
    name: P.string,
    logoUrl: P.string,
  }),
  installIntentionRedirectUrl: P.string,
};

export default withLocalizationProvider(ExternalInstallIntentComponent);
