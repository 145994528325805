import { Label, Select } from '@components/inputs';
import { useLocalization } from '@features/localization';
import { array, func, string } from 'prop-types';
import React from 'react';

const StageSelect = ({ options, currentBackstage, setCurrentBackstage }) => {
  const handleOptionSelect = ({ target }) => {
    setCurrentBackstage(target.value);
  };
  const { t } = useLocalization('overview.event-links.modal');

  return (
    <>
      <Label id="stage-label" htmlFor="stage-select" mt={3}>
        {t('select-stage')}
      </Label>
      <Select
        id="stage-select"
        name="stageSelect"
        aria-labelledby="stage-label"
        value={currentBackstage}
        onChange={handleOptionSelect}
        readOnly
      >
        {options.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
    </>
  );
};

StageSelect.propTypes = {
  options: array,
  currentBackstage: string,
  setCurrentBackstage: func,
};

export default StageSelect;
