import cx from 'classnames';
import { number, shape, string } from 'prop-types';
import React from 'react';

const PagyPagination = ({ pagyData, baseUrl }) => {
  const { page, pages, last, next, prev } = pagyData;

  const getPageUrl = number => {
    const [pathname, qr] = baseUrl.split('?');
    const queryParams = new URLSearchParams(qr);
    queryParams.set('page', number);
    return `${pathname}?${queryParams}#manage-invites`;
  };

  const getPaginationLinks = () => {
    let pageLinks = [];
    for (let i = 1; i <= pages; i++) {
      const isCurrentPage = page === i;
      const link = isCurrentPage ? i : <a href={getPageUrl(i)}>{i}</a>;
      const color = isCurrentPage && 'black';
      pageLinks.push(
        <span
          data-testid="page-link"
          key={`page-${i}`}
          style={{
            color,
          }}
          className="page"
        >
          {link}
        </span>,
      );
    }
    return pageLinks;
  };

  return (
    <nav className="pagy-nav pagination" role="navigation" aria-label="pager">
      <span
        data-testid="previous-button"
        className={cx('page prev', {
          disabled: page === 1,
        })}
      >
        {page === 1 ? '‹ Prev' : <a href={getPageUrl(prev)}>‹&nbsp;Prev</a>}
      </span>
      {getPaginationLinks()}
      <span
        data-testid="next-button"
        className={cx('page next', {
          disabled: page === prev,
        })}
      >
        {page === last ? 'Next ›' : <a href={getPageUrl(next)}>Next&nbsp;›</a>}
      </span>
    </nav>
  );
};

PagyPagination.propTypes = {
  baseUrl: string.isRequired,
  pagyData: shape({
    page: number.isRequired,
    pages: number.isRequired,
    last: number.isRequired,
    next: number,
    prev: number,
  }),
};

export default PagyPagination;
