import { useCallback, useState } from 'react';

export const useModalStep = (orderedSteps = [], selectedStep = undefined) => {
  const [step, setStep] = useState(selectedStep ?? orderedSteps[0]);

  const prevStep = useCallback(
    () =>
      setStep(currentStep => {
        const currentIdx = orderedSteps.indexOf(currentStep);
        const prev = orderedSteps[currentIdx - 1];
        if (prev) {
          setStep(prev);
        }
      }),
    [orderedSteps],
  );

  const nextStep = useCallback(
    () =>
      setStep(currentStep => {
        const currentIdx = orderedSteps.indexOf(currentStep);
        const nextStep = orderedSteps[currentIdx + 1];
        if (nextStep) {
          setStep(nextStep);
        }
      }),
    [orderedSteps],
  );

  return {
    nextStep,
    prevStep,
    step,
    setStep,
  };
};
