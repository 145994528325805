import eventDashboardApi from '@api/event-dashboard';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  type: null,
  attributes: {},
};

export const fetchData = createAsyncThunk(
  'setupChecklist/fetchData',
  async eventId => {
    const response = await eventDashboardApi.getSetupChecklist(eventId);
    return response;
  },
);

export const toggleVisibility = createAsyncThunk(
  'setupChecklist/toggleVisibility',
  async eventId => {
    const response = await eventDashboardApi.updateSetupChecklistVisibility(
      eventId,
    );
    return response;
  },
);

export const updateStepStatus = createAsyncThunk(
  'setupChecklist/updateStepStatus',
  async ({ eventId, step, status }) => {
    const response = await eventDashboardApi.updateStepStatus(
      eventId,
      step,
      status,
    );
    return response;
  },
);

const isFulfilledAction = action =>
  action.type.match(/^setupChecklist.*fulfilled$/);

const { actions, reducer } = createSlice({
  name: 'setupChecklist',
  initialState,
  reducers: {
    togglePublishedStatus: state => {
      const { published } = state.attributes.steps;
      const newStatus = published === 'completed' ? 'pending' : 'completed';
      state.attributes.steps.published = newStatus;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(toggleVisibility.pending, state => {
        state.attributes.visible = !state.attributes.visible;
      })
      .addMatcher(isFulfilledAction, (state, action) => action.payload.data);
  },
});

export const { togglePublishedStatus } = actions;

export default reducer;
