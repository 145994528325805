import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const MetricList = styled(Flex)`
  div.metric-item {
    flex: 1;
    margin: 0.5rem;
  }

  div.metric-item:first-child {
    margin-left: 0;
  }

  div.metric-item:last-child {
    margin-right: 0;
  }
`;

export const MetricListWrapper = styled.div`
  margin-bottom: 1rem;
  break-inside: avoid-page;
  overflow: hidden;
`;

export const MetricListHeader = styled.h6`
  margin-bottom: 0.5rem;
`;
