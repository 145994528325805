import MountSlice, { LATEST } from '@components/slices/MountSlice';
import { ReactiveMap } from '@slicesjs/reactive-map';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { string } from 'prop-types';
import React, { useMemo } from 'react';

const PACKAGE_NAME = '@hopin-team/hopin-web-slices-help-center-organizer';

const createReactiveMap = () => {
  return new ReactiveMap({
    allowedKeys: ['user_auth_token', 'event_id', 'org_id', 'snap'],
  });
};

const HelpCenterSlice = ({ eventId, orgId }) => {
  const reactiveMap = useMemo(createReactiveMap, []);
  const version = LATEST;

  reactiveMap.item('org_id').set(orgId);
  reactiveMap.item('event_id').set(eventId);

  const isOnEventWizardPage = window.location.pathname.includes(
    'create-event-wizard',
  );
  if (isOnEventWizardPage) {
    reactiveMap.item('snap').set('left');
  }

  return (
    <MountSlice
      packageName={PACKAGE_NAME}
      version={version}
      reactiveMap={reactiveMap}
    />
  );
};

HelpCenterSlice.propTypes = {
  eventId: string,
  orgId: string,
};

export default compose(
  withErrorHandling({
    loggerName: LOGGER_NAMES.HELP_CENTER,
    errorPattern: 'page',
  }),
)(HelpCenterSlice);
