import hopinApi from '@api/hopin';
import Alerts from '@features/alerts/alerts';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { SCHEDULED_KIND } from '@features/survey-builder/constants';
import {
  formatTimeInEventTimeZone,
  getErrorMessage,
  utcScheduledAt,
} from '@features/survey-builder/helpers';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { TabPanel, TabPanels, Tabs } from '@hopin-team/ui-tabs';
import { TopBar } from '@hopin-team/ui-top-bar';
import * as Routes from '@routes';
import { useUnloadPrompt } from '@util/hooks/use-unload-prompt';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { arrayOf, number, shape, string } from 'prop-types';
import mergeRight from 'ramda/src/mergeRight';
import omit from 'ramda/src/omit';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import EditorTab from './editor-tab';
import { isHopinNpsQuestion } from './helpers';
import ScheduleTab from './schedule-tab';

export const SCHEDULE_TAB = 'schedule';

const tabs = {
  EDITOR_TAB: 0,
  SCHEDULE_TAB: 1,
};

export const HOPIN_NPS_QUESTION_KEY = 'recommend-friend-colleague-rating';

const EditSurvey = ({ survey, event }) => {
  const [activeTab, setActiveTab] = useState(tabs.EDITOR_TAB);
  const { t } = useLocalization();
  const { addAlert } = useContext(AlertsContext);

  const isEditorTabActive = activeTab === tabs.EDITOR_TAB;
  const isScheduleTabActive = activeTab === tabs.SCHEDULE_TAB;
  const formId = `form_${survey.id}_${activeTab}`;
  const canEditSurvey = !survey.published_at;
  const surveysUrl = Routes.organisersEventSurveysPath(event.slug);

  const goToSurveyList = () => {
    window.location.assign(surveysUrl);
  };

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params.tab === SCHEDULE_TAB) {
      setActiveTab(tabs.SCHEDULE_TAB);
    }
  }, []);

  const handleTabClick = tab => {
    const url = new URL(window.location);

    if (tab === tabs.EDITOR_TAB) url.searchParams.delete('tab');
    if (tab === tabs.SCHEDULE_TAB) url.searchParams.set('tab', SCHEDULE_TAB);

    window.history.replaceState({}, '', url);
    setActiveTab(tab);
  };

  const questionPrefix = 'question-';

  const initialFormValues = survey => ({
    name: survey.name,
    title: survey.title,
    description: survey.description,
    scheduled_at: formatTimeInEventTimeZone(survey.scheduled_at, event),
    scheduled_kind: survey.scheduled_kind,
    ...(!!survey.questions?.length && {
      questions: survey.questions
        .filter(question => !isHopinNpsQuestion(question))
        .reduce((acc, current) => {
          return {
            ...acc,
            [`${questionPrefix}${current.id}`]: {
              question: current.question,
              enabled: current.enabled,
            },
          };
        }, {}),
    }),
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { isDirty, isSubmitting, isSubmitted, isValid },
    reset,
    errors,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: initialFormValues(survey),
    mode: 'onChange',
    shouldUnregister: false,
  });

  useUnloadPrompt({ when: isDirty });

  const onSubmit = async formData => {
    const formattedData = {
      ...(formData.scheduled_kind === SCHEDULED_KIND.SPECIFIC
        ? mergeRight(formData, {
            scheduled_at: utcScheduledAt(formData.scheduled_at, event),
          })
        : omit(['scheduled_at'], formData)),
      questions: Object.keys(formData.questions).map(key => ({
        id: key.replace(questionPrefix, ''),
        question: formData.questions[key].question,
        enabled: formData.questions[key].enabled,
      })),
    };

    try {
      const { token } = await hopinApi.getUserToken();

      const response = await hopinApi.updateSurvey(
        token,
        event.organiser_id,
        event.id,
        survey.id,
        formattedData,
      );

      reset(initialFormValues(response));
      addAlert({
        active: true,
        text: t('survey-builder.edit.all-changes-saved'),
        pattern: 'success',
      });
    } catch (e) {
      if (e.name === 'HTTPError') {
        const errorJson = await e.response.json();

        if (errorJson?.errors) {
          errorJson.errors.forEach(error => {
            if (error?.code === 'validation_error.invalid') {
              setError(error.source.field, {
                message: getErrorMessage(error.detail, t),
              });
            }
          });
        }
      }
      addAlert({
        active: true,
        text: t('survey-builder.list.something-went-wrong'),
        pattern: 'error',
      });
    }
  };

  return (
    <StyledContainer background="grey-200" pb={8}>
      <Tabs>
        <TopBar
          leading={
            <Button
              leadingIcon="arrow-left"
              pattern="inverse"
              size="medium"
              onClick={goToSurveyList}
            >
              {t('survey-builder.edit.back-to-venue')}
            </Button>
          }
          nav={
            <Box role="tablist">
              <StyledButton
                onClick={() => handleTabClick(tabs.EDITOR_TAB)}
                activeTab={isEditorTabActive}
                aria-selected={isEditorTabActive}
                pattern="inverse"
                aria-label={t('survey-builder.edit.editor')}
                role="tab"
                pt={0.125}
              >
                {t('survey-builder.edit.editor')}
              </StyledButton>
              <StyledButton
                onClick={() => handleTabClick(tabs.SCHEDULE_TAB)}
                activeTab={isScheduleTabActive}
                aria-selected={isScheduleTabActive}
                pattern="inverse"
                aria-label={t('survey-builder.list.schedule')}
                role="tab"
                pt={0.125}
              >
                {t('survey-builder.list.schedule')}
              </StyledButton>
            </Box>
          }
          align="center"
          trailing={[
            <Button
              size="medium"
              key="save"
              disabled={!isDirty || isSubmitting || !isValid}
              type="submit"
              form={formId}
              isLoading={isSubmitting}
            >
              {!isDirty && isSubmitted
                ? t('survey-builder.edit.saved')
                : t('survey-builder.edit.save')}
            </Button>,
          ]}
        />
        <TabPanels>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            {isEditorTabActive && (
              <TabPanel>
                <EditorTab
                  survey={survey}
                  event={event}
                  register={register}
                  watch={watch}
                  questionPrefix={questionPrefix}
                  control={control}
                  canEditSurvey={canEditSurvey}
                  errors={errors}
                />
              </TabPanel>
            )}
            {isScheduleTabActive && (
              <TabPanel>
                <ScheduleTab
                  survey={survey}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  reset={reset}
                  getValues={getValues}
                  event={event}
                  errors={errors}
                  control={control}
                  canEditSurvey={canEditSurvey}
                />
              </TabPanel>
            )}
          </form>
        </TabPanels>
      </Tabs>
      <Alerts />
    </StyledContainer>
  );
};

export default compose(
  withErrorHandling({ loggerName: LOGGER_NAMES.SURVEY_BUILDER }),
  withAlertsProvider,
  withLocalizationProvider,
)(EditSurvey);

EditSurvey.propTypes = {
  survey: shape({
    id: number.isRequired,
    name: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    questions: arrayOf(
      shape({
        id: number.isRequired,
        question: string.isRequired,
      }),
    ),
  }),
  event: shape({
    id: number.isRequired,
    organiser_id: number.isRequired,
  }),
};

const StyledContainer = styled(Box)`
  width: 100%;
  min-height: 100vh;
`;

const StyledButton = styled(Button)`
  border-radius: 0;
  height: 100%;
  border-bottom: solid 0.1875rem;
  border-color: ${({ activeTab }) =>
    activeTab ? 'var(--color-primary-400)' : 'transparent'};

  &:hover {
    background-color: var(--color-gray-200);
  }
`;
