import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Modal } from '@hopin-team/ui-modal';
import { Text } from '@hopin-team/ui-text';
import { bool, func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Header = styled(Flex)`
  width: 100%;
`;

const modalTitleId = 'duplicate-event-dialog-title';

export const DuplicateModal = ({ handleConfirm, isModalDisplayed }) => {
  const { t } = useLocalization(
    'onboarding.organization-dashboard.event-duplication-modal',
  );

  return (
    <Modal
      isShowing={isModalDisplayed}
      onClose={handleConfirm}
      describedById={modalTitleId}
      header={
        <Header justifyContent="flex-start">
          <Text id={modalTitleId} element="h1" pattern="headingTwo">
            {t('title')}
          </Text>
        </Header>
      }
      footer={
        <Flex justifyContent="flex-end">
          <Button
            px={2}
            size="medium"
            onClick={handleConfirm}
            data-testid="duplicate-confirm"
            isInline
          >
            {t('ok-button')}
          </Button>
        </Flex>
      }
      withSeparator
      isDismissible
      withCloseButton
    >
      <Box px={3} pb={2.5}>
        <Text element="p" pattern="emphasis" color="grey-600" mb={1}>
          {t('content')}
        </Text>
      </Box>
    </Modal>
  );
};

DuplicateModal.propTypes = {
  handleConfirm: func.isRequired,
  isModalDisplayed: bool.isRequired,
};
