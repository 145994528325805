const getNodeTextLineCount = (sentence, width, letterWidth) => {
  let sum = 0;
  let lineCount = 0;
  const words = sentence.split(' ');

  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    // Additional letterWidth is to account for the space after each word
    const length = word.length * letterWidth + letterWidth;

    sum += length;
    if (sum > width) {
      lineCount += 1;
      sum = length;
    }
  }

  return lineCount + 1;
};

export default getNodeTextLineCount;
