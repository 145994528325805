import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.span`
  font-weight: var(--font-weight-medium);
  margin-left: var(--spacing-8);
  color: var(--color-gray-800);
`;

export const Dot = styled.div`
  width: var(--spacing-8);
  height: var(--spacing-8);
  background-color: ${props => `var(${props.color || '--color-gray-500'})`};
  border-radius: 50%;
`;
