import getNodeTextWidth from './getNodeTextWidth';

const textFitsNode = (
  node,
  nodeLines,
  lineHeight,
  nodeHeight,
  verticalPadding,
  letterWidth,
  nodeWidth,
  textWidthOffset,
) => {
  // prevent description text from occupying more than 2/3
  // of node height to avoid visual crowding
  const nodeDescTextHeight = nodeLines * lineHeight + verticalPadding;
  const valueTextHeight = verticalPadding + 20;
  const isDescTextHeightValid = nodeHeight * 0.66 > nodeDescTextHeight;

  // prevent description text from overflowing vertically
  const isNodeTextHeightValid =
    nodeHeight > nodeDescTextHeight + valueTextHeight + verticalPadding;
  const isTextHeightValid = isDescTextHeightValid && isNodeTextHeightValid;

  // prevent node text from overflowing horizontally
  const nodeDescTextWidth = getNodeTextWidth(node, letterWidth);
  const isTextWidthValid = nodeWidth > nodeDescTextWidth + textWidthOffset;

  return isTextHeightValid && isTextWidthValid;
};

export default textFitsNode;
