export const webinarTemplates = {
  lite_meetup: {
    venue: {
      reception: { selected: false },
      stage: { selected: false },
      sessions: { selected: true },
      expo: { selected: false },
      networking: { selected: false },
      people: { selected: false },
      replay: { selected: false },
    },
    addons: {
      sponsors: { selected: false },
      speakers: { selected: false },
      q_and_a: { selected: true },
      polls: { selected: false },
      chat: { selected: true },
    },
  },
  lite_webcast: {
    venue: {
      reception: { selected: false },
      stage: { selected: true },
      sessions: { selected: false },
      expo: { selected: false },
      networking: { selected: false },
      people: { selected: false },
      replay: { selected: false },
    },
    addons: {
      sponsors: { selected: false },
      speakers: { selected: false },
      q_and_a: { selected: true },
      polls: { selected: false },
      chat: { selected: true },
    },
  },
};
