import { Grid } from '@hopin-team/ui-grid';
import { calcSpace } from '@hopin-team/ui-symbols';
import styled from 'styled-components';

const ChecklistTabs = styled(Grid)`
  grid-gap: ${calcSpace(1.5)};
  list-style-type: none;
  counter-reset: overview-checklist;
`;

export default ChecklistTabs;
