import { getReportTypeForJobType } from './report-type';

export const Status = {
  ENQUEUED: 'enqueued',
  STARTED: 'started',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  DOWNLOADED: 'downloaded',
  UNKNOWN: 'unknown',
};

export default class ReportHistoryItem {
  constructor(historyDTO) {
    this.id = historyDTO.id;
    this.title = historyDTO.title;
    this.createdAt = historyDTO.created_at;
    this.updatedAt = historyDTO.updated_at;
    this.status = Status[historyDTO.status.toUpperCase()] || Status.UNKNOWN;
    this.user = {
      id: historyDTO.user_id,
      userName: historyDTO.user_name,
    };
    this.downloadLink = historyDTO.download_link;
    this.type = getReportTypeForJobType(historyDTO.report_type);
    this.jobName = historyDTO.report_type;
  }

  get isDownloadable() {
    return [Status.SUCCEEDED, Status.DOWNLOADED].includes(this.status);
  }

  get isProcessing() {
    return [Status.ENQUEUED, Status.STARTED].includes(this.status);
  }
}
