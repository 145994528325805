import { useLocalization } from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

export const Section = styled.div`
  padding: ${rem(24)} ${rem(16)};

  & + & {
    box-shadow: 0 -1px 2px rgba(44, 50, 66, 0.08);
  }
`;

export const Heading = styled.h3`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: 1;
  color: var(--color-gray-800);
  text-align: center;
  margin-top: ${rem(24)};
  margin-bottom: 0;
`;

export const Body = styled.p`
  font-family: var(--font-family);
  font-size: ${rem(14)};
  line-height: 1.71;
  color: var(--color-gray-700);
  margin-top: ${rem(16)};
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: auto;
  margin: auto;
`;

const SuccessModal = () => {
  const { t } = useLocalization('magic-links.tabs.upload-csv');
  const handleButtonClick = () => {
    if (typeof window !== 'undefined') {
      window.location.hash = 'manage-invites';
      window.location.reload();
    }
  };
  return (
    <>
      <Section>
        <Heading>{t('processing')}</Heading>
        <Body
          dangerouslySetInnerHTML={{
            __html: t('processing-body'),
          }}
        />
      </Section>
      <Section>
        <StyledButton onClick={handleButtonClick}>{t('continue')}</StyledButton>
      </Section>
    </>
  );
};

export default SuccessModal;
