import { useEffect } from 'react';

export const useUnloadPrompt = ({ when: shouldPrompt }) => {
  useEffect(() => {
    const handleBeforeUnload = e => {
      if (shouldPrompt) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldPrompt]);
};
