import { eventAreas } from '@features/event-creation-wizard/constants/event-areas';
import { templates } from '@features/event-creation-wizard/constants/templates';
import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React from 'react';

const EventAreas = ({ templateId }) => {
  const { t } = useLocalization();

  const templateData = templates[templateId];
  const areas = [
    ...Object.entries(templateData.defaultVenue),
    ['reception', { selected: true }],
  ];

  return (
    <Flex flexDirection="column">
      <Text element="h2" pattern="bodyLargeMedium">
        {t('event-creation-wizard.event-areas.title')}
      </Text>
      {areas.map(area => {
        const areaName = area[0];
        const active = area[1].selected;
        if (!active || areaName === 'replay') return;
        return (
          <Flex
            pt={1}
            pb={1}
            key={areaName}
            flexDirection="row"
            alignItems="center"
          >
            <Flex
              p={1.5}
              background={`${eventAreas[areaName].color}-100`}
              cornerRadius="round"
              alignItems="center"
              justifyContent="start"
              mr={1.5}
            >
              <Icon
                name={eventAreas[areaName].iconKey}
                color={`${eventAreas[areaName].color}-500`}
              />
            </Flex>
            <Flex flexDirection="column">
              <Text element="h3" pattern="strong">
                {t(`event-creation-wizard.event-areas.areas.${areaName}.title`)}
              </Text>
              <Text color="grey-medium" element="span" pattern="caption">
                {t(
                  `event-creation-wizard.event-areas.areas.${areaName}.description`,
                )}
              </Text>
            </Flex>
          </Flex>
        );
      })}
      <Text color="grey-medium" element="span" pattern="caption" my={1}>
        {t('event-creation-wizard.event-areas.footline')}
      </Text>
    </Flex>
  );
};

EventAreas.propTypes = {
  templateId: string.isRequired,
};

export default EventAreas;
