import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Select } from '@hopin-team/ui-select';
import P from 'prop-types';
import React, { useMemo } from 'react';

const IconsByI18nKey = {
  default: 'grid-view',
  'speech-bubble': 'chat',
  headphones: 'support',
  'coffee-cup': 'break',
  heart: 'heart-filled',
  star: 'star',
  'thumb-up': 'thumbs-up',
  smiley: 'smile',
  gallery: 'image-two-tone',
  block: 'block',
  pin: 'pin',
};

const MenuIconInput = ({ className, onChange, value = 'default' }) => {
  const { t } = useLocalization('app-area.icon');

  const options = useMemo(
    () =>
      Object.entries(IconsByI18nKey).map(([i18nKey, icon]) => ({
        leadingIcon: icon,
        label: t(`options.${i18nKey}`),
        value: i18nKey,
      })),
    [t],
  );

  const selectedValue = useMemo(
    () => options.find(option => option.value === value),
    [options, value],
  );

  return (
    <div className={className}>
      <input type="hidden" name={`app_area[config][icon]`} value={value} />
      <Select
        showOptionsLabel={t('dropdown.title')}
        size="small"
        label="Icon"
        options={options}
        leadingIcon={selectedValue?.leadingIcon}
        value={value}
        setValue={onChange}
      />
    </div>
  );
};

MenuIconInput.propTypes = {
  className: P.string,
  onChange: P.func,
  value: P.string,
};

export default withLocalizationProvider(MenuIconInput);
