import Card from '@components/card-with-loading';
import Network from '@components/icons/network';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { useLocalization } from '@features/localization';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import InfoCircleIcon from 'icons/metrics/info-circle-big.svg';
import { bool, number, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AttendeeListComponent from '../components/attendee-list-component';
import { useEngagementPagination } from './hooks';

const TooltipWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -8px;
`;
const MostEngagedAttendees = ({ eventId, theme }) => {
  const { t } = useLocalization('connections-dashboard.most-engaged-attendees');
  const {
    error,
    items,
    loading,
    totalRecordsRef,
    showMore,
  } = useEngagementPagination(eventId);

  return (
    <div
      className="row mb-3 no-page-break-inside"
      data-testid="most-engaged-attendees"
    >
      <div className="col-12">
        <Card isLoading={loading}>
          <CardContentWrapper>
            <CardTitle>
              {t('title')}
              <TooltipWrapper data-testid="most-engaged-attendees-tooltip">
                <ToolTip
                  align="right"
                  tip={t('most-engaged-attendees-tooltip')}
                >
                  <InfoCircleIcon width={24} height={24} />
                </ToolTip>
              </TooltipWrapper>
            </CardTitle>
            {error ? (
              <EmptyState text={t('disabled')} icon={<Network />} />
            ) : items.length > 0 ? (
              <AttendeeListComponent
                people={items}
                theme={theme}
                hideShowMore={totalRecordsRef.current <= items.length}
                onShowMore={showMore}
              />
            ) : (
              <EmptyState text={t('empty')} icon={<Network />} />
            )}
          </CardContentWrapper>
        </Card>
      </div>
    </div>
  );
};

MostEngagedAttendees.propTypes = {
  eventId: number.isRequired,
  theme: string,
  isDisabled: bool,
};

export default MostEngagedAttendees;
