import { useLocalization } from '@features/localization';
import { Butticon } from '@hopin-team/ui-butticon';
import { Icon } from '@hopin-team/ui-icon';
import PropTypes from 'prop-types';
import React from 'react';

import { ErrorMessageWrapper, SuccessMessageWrapper } from './message.styled';

export const Message = ({ type, hidden, onClose }) => {
  const { t } = useLocalization('recording-library');

  return (
    <>
      {type === 'error' && (
        <ErrorMessageWrapper data-testid="status-message">
          <Icon
            name="report"
            title={t('messages.error-icon-title')}
            color="grey-white"
            mr={2.5}
          />
          {t('messages.failed')}
        </ErrorMessageWrapper>
      )}
      {type === 'success' && (
        <SuccessMessageWrapper hidden={hidden} data-testid="status-message">
          <Icon
            name="success"
            title={t('messages.success-icon-title')}
            color="green-600"
            mr={1.75}
          />
          {t('messages.success')}
          <Butticon
            icon="close"
            title={t('messages.close')}
            onClick={onClose}
          />
        </SuccessMessageWrapper>
      )}
    </>
  );
};

Message.propTypes = {
  hidden: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
};
