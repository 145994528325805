import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Grid } from '@hopin-team/ui-grid';
import kebabCase from 'lodash/kebabCase';
import { array, bool, func, number, object, string } from 'prop-types';
import React from 'react';

import { STEPS } from '../constants/steps';
import ToggleChecklistButton from '../styled/toggle-checklist-button';
import { isTabApplicable } from '../utils/tabs';

const ChecklistTabpanels = ({
  canPublish,
  checklistIsVisible,
  checklistStatuses,
  eventSlug,
  isPublishing,
  onPreview,
  onPublish,
  onSkipLandingPage,
  onSkipSpeakerProfiles,
  onToggleVisibility,
  selectedTabIndex,
  tabs,
}) => {
  const { t } = useLocalization('overview.event-setup-checklist');

  return (
    <Grid
      css={`
        width: 100%;
      `}
      gridTemplateRows={checklistIsVisible ? 'auto 1fr' : 'auto'}
      p={3}
    >
      <Flex justifyContent="flex-end">
        <ToggleChecklistButton
          data-testid="event-progress-show-hide-button"
          leadingIcon={checklistIsVisible ? 'minus' : 'watching'}
          onClick={onToggleVisibility}
        >
          {checklistIsVisible ? t('hide-checklist') : t('view-checklist')}
        </ToggleChecklistButton>
      </Flex>
      {checklistIsVisible &&
        tabs.map((tab, ix) =>
          isTabApplicable(tab, checklistStatuses) ? (
            <div
              aria-expanded={selectedTabIndex === ix}
              data-testid="event-progress-tabpanel"
              hidden={selectedTabIndex !== ix}
              id={`${kebabCase(tab.title)}-tab`}
              key={tab.title}
            >
              <tab.tabPanelComponent
                checklistStatuses={checklistStatuses}
                eventSlug={eventSlug}
                {...(tab.requiredSteps.includes(STEPS.PUBLISHED) && {
                  canPublish,
                  isPublishing,
                  onPublish,
                })}
                {...(tab.requiredSteps.includes(STEPS.LANDING_PAGE) && {
                  onSkipLandingPage,
                })}
                {...(tab.requiredSteps.includes(STEPS.SPEAKER_PROFILES) && {
                  onSkipSpeakerProfiles,
                })}
                {...(tab.requiredSteps.includes(STEPS.PREVIEWED) && {
                  onPreview,
                })}
              />
            </div>
          ) : null,
        )}
    </Grid>
  );
};

ChecklistTabpanels.propTypes = {
  canPublish: bool,
  checklistIsVisible: bool,
  checklistStatuses: object.isRequired,
  eventSlug: string.isRequired,
  isPublishing: bool,
  onPreview: func.isRequired,
  onPublish: func.isRequired,
  onSkipLandingPage: func.isRequired,
  onSkipSpeakerProfiles: func.isRequired,
  onToggleVisibility: func.isRequired,
  selectedTabIndex: number,
  tabs: array,
};

export default ChecklistTabpanels;
