import React from 'react';

const Stage = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5024 4.69922C16.607 4.69922 17.5024 5.59465 17.5024 6.69922V9.50922L21.9013 5.90378C22.3426 5.57176 22.9695 5.66038 23.3016 6.10172C23.4319 6.27503 23.5024 6.48603 23.5024 6.7029V18.7029C23.5024 19.2552 23.0547 19.7029 22.5024 19.7029C22.2866 19.7029 22.0765 19.633 21.9036 19.5038L17.5024 15.9102V18.6992C17.5024 19.8038 16.607 20.6992 15.5024 20.6992H3.50244C2.39787 20.6992 1.50244 19.8038 1.50244 18.6992V6.69922C1.50244 5.59465 2.39787 4.69922 3.50244 4.69922H15.5024ZM6.00244 7.69922C5.17401 7.69922 4.50244 8.37079 4.50244 9.19922C4.50244 10.0276 5.17401 10.6992 6.00244 10.6992C6.83087 10.6992 7.50244 10.0276 7.50244 9.19922C7.50244 8.37079 6.83087 7.69922 6.00244 7.69922Z"
      fill="currentColor"
    />
  </svg>
);

export default Stage;
