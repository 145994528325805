import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Select } from '@hopin-team/ui-select';
import { Text } from '@hopin-team/ui-text';
import { object } from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  max-width: 530px;
`;

const AttendeeVisibility = ({ control }) => {
  const { t } = useLocalization('venue-controls.attendee-visibility');

  const options = [
    {
      value: 'show_all',
      label: t('show-all'),
    },
    {
      value: 'show_list',
      label: t('show-list'),
    },
    {
      value: 'show_none',
      label: t('show-none'),
    },
  ];

  const {
    field: { onChange, value },
  } = useController({
    name: 'venue_settings[attendees_visiblity]',
    control,
  });

  return (
    <Box mb={3}>
      <Text
        element="h3"
        pattern="headingFour"
        color="grey-800"
        weight="medium"
        scale={4}
      >
        {t('title-redesign')}
      </Text>
      <Text element="p" mt={1} color="grey-600" pattern="body">
        {t('heading-redesign')}
      </Text>
      <StyledSelect
        options={options}
        setValue={onChange}
        size="small"
        hideLabel={true}
        label={t('label')}
        mt={1}
        value={value}
      />
    </Box>
  );
};

AttendeeVisibility.propTypes = {
  control: object.isRequired,
};

export default withLocalizationProvider(AttendeeVisibility);
