import PropTypes from 'prop-types';
import React from 'react';

const Check = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5279 9.30292C4.57279 9.35075 4.62701 9.38887 4.6872 9.41493C4.7474 9.44099 4.8123 9.45443 4.8779 9.45443C4.9435 9.45443 5.0084 9.44099 5.06859 9.41493C5.12879 9.38887 5.18301 9.35075 5.2279 9.30292L10.8479 3.68292C10.8948 3.63643 10.932 3.58113 10.9573 3.5202C10.9827 3.45927 10.9958 3.39392 10.9958 3.32792C10.9958 3.26191 10.9827 3.19656 10.9573 3.13563C10.932 3.0747 10.8948 3.0194 10.8479 2.97292L10.3179 2.44292C10.2244 2.3513 10.0988 2.29999 9.9679 2.29999C9.83702 2.29999 9.71136 2.3513 9.6179 2.44292L4.8779 7.18292L3.3779 5.69292C3.33301 5.64509 3.27879 5.60696 3.21859 5.5809C3.1584 5.55484 3.0935 5.5414 3.0279 5.5414C2.9623 5.5414 2.8974 5.55484 2.8372 5.5809C2.77701 5.60696 2.72279 5.64509 2.6779 5.69292L2.1479 6.22292C2.10103 6.2694 2.06384 6.3247 2.03845 6.38563C2.01307 6.44656 2 6.51191 2 6.57792C2 6.64392 2.01307 6.70927 2.03845 6.7702C2.06384 6.83113 2.10103 6.88643 2.1479 6.93292L4.5279 9.30292Z"
      fill="currentColor"
    />
  </svg>
);

Check.propTypes = {
  className: PropTypes.string,
};

export default Check;
