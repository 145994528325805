import analyticsApi from '@api/analytics';
import { usePromise } from '@features/dashboard/util/hooks';
import closestTo from 'date-fns/closestTo';
import isAfter from 'date-fns/isAfter';
import compose from 'lodash/fp/compose';
import { bool } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generatedStatus, generatedTimes } from '@/redux/reducers/reports';
import withReduxProvider from '@/redux/with-provider';

import ReportsNotificationContainer from './reports-notification-container';

const LOCAL_STORAGE_KEY = 'reports-page-last-viewed';

const getReportsPageLastViewed = eventId => {
  return localStorage.getItem(`${eventId}-${LOCAL_STORAGE_KEY}`);
};

/**
 * Find the status with the most recent time stamp
 * @param {object} statuses
 * @returns string as 'failed' | 'started' | 'succeeded'
 */
function getNewestStatus(statuses) {
  let timesArr = [];
  // Creates a 2D array from the key/value pairs from statuses that are not null
  for (const [status, timestamp] of Object.entries(statuses)) {
    if (statuses[status]) {
      timesArr.push(new Date(timestamp));
    }
  }
  if (timesArr.length) {
    const currentTimeStamp = new Date();
    const latestTimeStamp = new Date(
      closestTo(currentTimeStamp, timesArr),
    ).toISOString();
    for (const [status] of Object.entries(statuses)) {
      if (statuses[status] === latestTimeStamp) {
        return status;
      }
    }
  }
  return null;
}

/**
 * Displays the status of report generation AND fetches status from backend on mount
 * @param {boolean} hideStatus - useful to hide status icon when subnav is expanded
 * @returns {ReportsNotificationContainer}
 */
function ReportsNotificationWithFetchContainer({ hideStatus }) {
  const dispatch = useDispatch();
  const eventId = useSelector(({ event }) => event.id);

  usePromise(
    signal => analyticsApi.getOrganisersEventReportsStatus(eventId, signal),
    response => {
      dispatch(generatedTimes(response));
      const isOnReportsPage = window.location.pathname.endsWith('/reports');
      const newestStatus = getNewestStatus(response);
      const reportsLastViewedTime = getReportsPageLastViewed(eventId);

      /**
       * We want to show the Report notification status if it wasn't dismissed, but
       * if the status is 'started' (loading) we only show it on the Reports page.
       */
      if (
        (!reportsLastViewedTime ||
          isAfter(
            new Date(response[newestStatus]),
            new Date(reportsLastViewedTime),
          )) &&
        ((isOnReportsPage && newestStatus === 'started') ||
          ['failed', 'succeeded'].includes(newestStatus))
      ) {
        dispatch(generatedStatus({ status: newestStatus }));
      }
    },
    [eventId, dispatch],
  );

  if (hideStatus) {
    return null;
  }

  return <ReportsNotificationContainer theme="secondary" />;
}

ReportsNotificationWithFetchContainer.propTypes = {
  hideStatus: bool,
};

export default compose(withReduxProvider)(
  ReportsNotificationWithFetchContainer,
);
