import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Button } from '@hopin-team/ui-button';
import { Icon } from '@hopin-team/ui-icon';
import { Text } from '@hopin-team/ui-text';
import { ToolTip } from '@hopin-team/ui-tool-tip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { iconStyles } from '../category-block/styles';
import { CATEGORY_BLOCK, TYPE } from '../category-types';
import SelectPreview from './selection-preview/select-preview';

const CategoryDropdownComponent = ({
  onDropdownSelection,
  isAtCategoryLimit,
  lockProfileMatching,
}) => {
  const { t } = useLocalization();

  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [dropdownPreview, setDropdownPreview] = useState();

  const handleAddCategory = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  const handleOptionClick = option => {
    onDropdownSelection(option);
    setIsCategoryDropdownOpen(false);
  };

  return (
    <>
      <AddCategoryButton
        isInline
        onClick={handleAddCategory}
        size="small"
        disabled={isAtCategoryLimit || lockProfileMatching}
        trailingIcon={isAtCategoryLimit && 'alert'}
      >
        <ToolTip
          tip={t('networking.profile-setup.max-categories-reached')}
          hideTip={!isAtCategoryLimit}
          align="bottom"
        >
          {t('networking.profile-setup.cta')}{' '}
        </ToolTip>
      </AddCategoryButton>

      {isCategoryDropdownOpen && (
        <CategoryDropdown onMouseLeave={() => setDropdownPreview(null)}>
          <DropdownOptions>
            <DropdownOption
              px={0}
              ml={1}
              isInline
              pattern="ghost"
              onMouseOver={() => setDropdownPreview(TYPE.MULTI)}
              onClick={() => handleOptionClick(TYPE.MULTI)}
            >
              <StyledIcon
                name={CATEGORY_BLOCK[TYPE.MULTI].iconName}
                color="grey-white"
                m={0.75}
                scale={2}
                backgroundColor={CATEGORY_BLOCK[TYPE.MULTI].iconBackground}
              />
              <Text pattern="emphasis">
                {t('networking.profile-setup.multi-select')}
              </Text>
            </DropdownOption>
            <DropdownOption
              px={0}
              ml={1}
              pattern="ghost"
              isInline
              onMouseOver={() => setDropdownPreview(TYPE.SINGLE)}
              onClick={() => handleOptionClick(TYPE.SINGLE)}
            >
              <StyledIcon
                name={CATEGORY_BLOCK[TYPE.SINGLE].iconName}
                color="grey-white"
                m={0.75}
                scale={2}
                backgroundColor={CATEGORY_BLOCK[TYPE.SINGLE].iconBackground}
              />
              <Text pattern="emphasis">
                {t('networking.profile-setup.single-select')}
              </Text>
            </DropdownOption>
          </DropdownOptions>
          <DropdownPreview>
            {dropdownPreview && (
              <SelectPreview dropdownPreview={dropdownPreview} />
            )}
          </DropdownPreview>
        </CategoryDropdown>
      )}
    </>
  );
};

export default withLocalizationProvider(CategoryDropdownComponent);

CategoryDropdownComponent.propTypes = {
  onDropdownSelection: PropTypes.func,
  isAtCategoryLimit: PropTypes.bool,
  lockProfileMatching: PropTypes.bool,
};

const CategoryDropdown = styled.div`
  position: absolute;
  display: flex;
  width: 23.125rem;
  background: var(--color-white);
  height: 11.125rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(44, 50, 66, 0.08),
    0 0.063rem 0.188rem rgba(44, 50, 66, 0.15);
  z-index: 1;
`;

const DropdownOptions = styled.div`
  width: 45%;
  padding: 1rem 0;
  border-radius: 0.25rem;
`;

const DropdownOption = styled(Button)`
  justify-content: flex-start;
  width: calc(100% - 0.5rem);
  border-radius: 0;
  color: var(--color-gray-700);

  &:hover {
    background: var(--color-gray-100);
    color: var(--color-gray-900);
  }
`;

const DropdownPreview = styled.div`
  background: var(--color-gray-100);
  border-radius: 0 0.25rem 0.25rem 0;
  width: 55%;
`;

const StyledIcon = styled(Icon)`
  ${iconStyles}
  background: ${({ backgroundColor }) => backgroundColor};
`;

const AddCategoryButton = styled(Button)`
  width: 10rem;
`;
