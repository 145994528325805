import Loading from '@components/loading';
import { Box } from '@hopin-team/ui-box';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  height: 100%;
`;

const CardWithLoadingComponent = ({
  className,
  isLoading,
  children,
  cornerRadius = 'lg',
  px = 3,
  py = 2.5,
  testId,
}) => (
  <StyledBox
    data-testid={testId}
    className={className}
    px={px}
    py={py}
    background="grey-white"
    border="grey-200"
    cornerRadius={cornerRadius}
  >
    <Loading isLoading={isLoading}>{children}</Loading>
  </StyledBox>
);

CardWithLoadingComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cornerRadius: PropTypes.string,
  px: PropTypes.number,
  py: PropTypes.number,
  testId: PropTypes.string,
};

export default CardWithLoadingComponent;
