import PropTypes from 'prop-types';
import React from 'react';

const EyeSlash = ({ className, color = 'currentColor' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.1583 8.76679C16.9275 8.37762 16.629 7.93353 16.2615 7.4702C16.169 7.35353 15.9908 7.34261 15.8849 7.44761L8.13571 15.196C7.99987 15.3319 8.05831 15.5693 8.24415 15.6159C8.78915 15.7534 9.37578 15.8326 9.99911 15.8326C13.8408 15.8326 16.1325 12.9409 17.1575 11.2326C17.6167 10.4751 17.6167 9.52512 17.1583 8.76679Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M14.3749 5.62502C13.2249 4.79169 11.7832 4.16669 9.99991 4.16669C6.15825 4.16669 3.86651 7.05837 2.84151 8.7667C2.38317 9.52504 2.38317 10.475 2.84151 11.2333C3.39151 12.15 4.29991 13.4 5.62491 14.375L14.3749 5.62502Z"
        fill={color}
      />
      <path
        d="M17.9417 2.05831C17.6975 1.81414 17.3017 1.81414 17.0575 2.05831L11.5658 7.55005C11.1124 7.25671 10.5742 7.08415 9.99921 7.08415C9.69088 7.08415 9.40751 7.14242 9.13251 7.23409C9.29085 7.45909 9.39089 7.73426 9.39089 8.03426C9.39089 8.78426 8.78245 9.3925 8.03245 9.3925C7.73245 9.3925 7.45749 9.29245 7.23249 9.13411C7.14082 9.40911 7.08254 9.69248 7.08254 10.0008C7.08254 10.5758 7.25595 11.1143 7.54845 11.5676L2.05671 17.0591C1.81254 17.3033 1.81254 17.6991 2.05671 17.9433C2.17837 18.065 2.3384 18.1266 2.4984 18.1266C2.6584 18.1266 2.81842 18.0658 2.94009 17.9433L17.9401 2.94332C18.1859 2.69748 18.1859 2.30247 17.9417 2.05831Z"
        fill={color}
      />
    </svg>
  );
};

EyeSlash.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default EyeSlash;
