import getLogger, { LOGGER_NAMES } from '@util/logger';
import {
  pusherBindReportsStatus,
  pusherInitialize,
} from '@util/pusher/reports';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { generatedStatus, generatedTimes } from '@/redux/reducers/reports';

import { useAlertingReportNotification } from './use-alerting-report-notification';

const logger = getLogger(LOGGER_NAMES.ANALYTICS);

function useReportsNotificationPusherSubscription() {
  const dispatch = useDispatch();
  const eventId = useSelector(({ event }) => event.id);
  const userId = useSelector(({ user }) => user.id);
  const addStatusAlert = useAlertingReportNotification(eventId);

  useEffect(() => {
    if (eventId) {
      pusherInitialize({ eventId });
    }
  }, [eventId]);

  useEffect(() => {
    let unbind;
    if (eventId && userId) {
      try {
        unbind = pusherBindReportsStatus({
          eventId,
          handler: payload => {
            /**
             * Avoid showing 2 status alerts for quick report generation by showing
             * the new status at least 5 seconds after the generation was started.
             * If greater than 5 seconds, show the alert immediately [AP-1696]
             */
            const timeDifference = differenceInMilliseconds(
              new Date(payload.updated_at),
              new Date(payload.created_at),
            );
            setTimeout(
              () => {
                addStatusAlert(payload);
                dispatch(generatedStatus(payload));
                dispatch(
                  generatedTimes({
                    [payload.status]: payload.updated_at,
                  }),
                );
              },
              payload.status !== 'started' && timeDifference < 5000
                ? 5000 - timeDifference
                : 0,
            );
          },
          userId,
        });
      } catch (err) {
        logger.error(err);
      }
    }
    return () => {
      if (unbind) {
        unbind();
        dispatch(generatedStatus(null));
      }
    };
  }, [addStatusAlert, dispatch, eventId, userId]);
}

export { useReportsNotificationPusherSubscription };
