import { useLocalization } from '@features/localization';
import withErrorHandling from '@util/with-error-handling';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import {
  FormDateField,
  FormMultiSelectField,
  FormSelectField,
  FormTextField,
} from './attendee-details-fields';

// PRIVATE / UTILS

const QuestionShape = {
  id: number.isRequired,
  external_id: string.isRequired,
  name: string.isRequired,
  field_type: string,
  required: bool,
  default_answer: string,
};

const isArrayField = field_type => 'multi-select' === field_type;

const questionFieldPrefix = `question_`;
const fieldNameForQuestion = questionId =>
  `${questionFieldPrefix}${questionId}`;
const questionIdFromFieldName = fieldName =>
  parseInt(fieldName.replace(questionFieldPrefix, ''));

const questionsIncludingConditionals = (questions = []) =>
  questions
    .map(q => [q, q.conditionals])
    .flat(2)
    .filter(q => !!q);

/**
 * Creates a map of answers indexed by question id
 * @param answers
 * @returns
 */
const answersByQuestionId = (answers = []) =>
  answers.reduce((map, answer) => {
    const key = answer.question_id;
    if (isArrayField(answer.field_type)) {
      const answers = map.get(key) ?? [];
      answers.push(answer);
      map.set(key, answers);
    } else {
      map.set(key, answer);
    }

    return map;
  }, new Map());

/**
 * Given a form submission, reshapes it to an array of answers.
 * @param {Array} questions - The questions present in the form
 * @param {object} formData - The submitted form values
 */
export const mapToAnswers = (questions, formData) => {
  const allQuestions = questionsIncludingConditionals(questions);

  return Object.entries(formData).reduce((answers, [field, value]) => {
    const question_id = questionIdFromFieldName(field);
    const question = allQuestions.find(q => q.id === question_id);

    // Simplifies working with multi-select values.
    const iterableValue = Array.isArray(value)
      ? value
          .filter(checkbox => checkbox.checked)
          .map(checkbox => checkbox.label)
      : [value];

    for (const answer of iterableValue) {
      answers.push({
        answer,
        field_type: question.field_type,
        question: question.name,
        question_id,
      });
    }

    return answers;
  }, []);
};

/**
 * Given an array of answers, reshapes them into an object whose
 * keys are field names and whose values are form field values.
 * @param {*} questions
 * @param {*} answers
 */
export const mapToAnswerFormData = (questions = [], answers = []) => {
  const answersByQuestion = answersByQuestionId(answers);
  const allQuestions = questionsIncludingConditionals(questions);

  return Object.fromEntries(
    allQuestions.map(({ field_type, id, options }) => {
      const key = fieldNameForQuestion(id);
      const answer = answersByQuestion.get(id);

      if (isArrayField(field_type)) {
        const selectedLabels = answer?.map(({ answer }) => answer) ?? [];
        const values = options.map(({ name, external_id }) => ({
          checked: selectedLabels.includes(name),
          label: name,
          value: external_id,
        }));
        return [key, values];
      }

      return [key, answer?.answer];
    }),
  );
};

// PUBLIC / FORMS

export const useAttendeeRegistrationForm = (attendee, availableTickets) => {
  const selectedTicketType = availableTickets?.find(
    option => option.name === attendee?.ticket_type.label,
  );
  const defaultValues = useMemo(
    () => ({
      event_track: attendee?.event_track?.label,
      ticket: selectedTicketType?.id,
      promo_code: attendee?.promo_code,
      price_paid: attendee?.price_paid,
      created_at: attendee?.created_at_time,
    }),
    [attendee, selectedTicketType],
  );

  const form = useForm({ defaultValues, shouldUnregister: false });
  const { reset } = form;
  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  return form;
};

export const AttendeeRegistrationForm = ({
  className,
  editable,
  form,
  onSave,
  availableTickets = [],
}) => {
  const { t } = useLocalization(
    'people.attendees.attendees-tab.details.fields',
  );
  const ticketTypeOptions = useMemo(
    () =>
      availableTickets?.map(
        ({ name, id, remainingCapacity, availability }) => ({
          label: name,
          value: id,
          leadingIcon: 'ticket',
          disabled: remainingCapacity === 0 || availability === 'CLOSED',
        }),
      ),
    [availableTickets],
  );

  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form
        className={className}
        onSubmit={handleSubmit(onSave)}
        data-testid="attendee-details-registration-form"
      >
        <FormSelectField
          size="small"
          disabled={!editable}
          name="ticket"
          options={ticketTypeOptions}
          label={t('ticket')}
          leadingIcon="ticket"
        />
        <FormTextField
          size="small"
          disabled
          name="event_track"
          label={t('event-track')}
        />
        <FormTextField
          size="small"
          disabled
          name="price_paid"
          label={t('price-paid')}
        />
        <FormTextField
          size="small"
          disabled
          name="promo_code"
          label={t('promo-code')}
        />
        <FormTextField
          size="small"
          disabled
          name="created_at"
          label={t('registration-date')}
        />
      </form>
    </FormProvider>
  );
};

AttendeeRegistrationForm.propTypes = {
  className: string,
  editable: bool,
  form: object.isRequired,
  onSave: func,
  availableTickets: arrayOf(
    shape({
      remainingCapacity: number,
      label: string,
      value: number,
    }),
  ),
};

const Question = props => {
  const { depth = 0, editable, parentQuestion, question } = props;

  if (depth > 1) {
    throw new RangeError('Conditional questions are limited to one layer');
  }

  const { t } = useLocalization(
    'people.attendees.attendees-tab.details.custom-answers',
  );

  const precondition = question.option_selected;
  const parentValue = useWatch({
    name: fieldNameForQuestion(parentQuestion?.id),
  });
  const shouldRender =
    precondition === undefined || precondition.name === parentValue;

  if (!shouldRender) {
    return null;
  }

  const { field_type, id } = question;
  const options = question.options?.map(({ name }) => ({
    label: name,
    value: name,
  }));
  const commonProps = {
    disabled: !editable,
    label: question.name,
    key: fieldNameForQuestion(id),
    name: fieldNameForQuestion(id),
    rules: {
      required:
        question.required &&
        question.all_ticket_types &&
        t('validation.required', { field: question.name }),
    },
    size: 'small',
  };

  switch (field_type) {
    case 'date':
      return <FormDateField {...commonProps} />;
    case 'multi-select':
      return (
        <FormMultiSelectField
          {...commonProps}
          isDisabled={!editable}
          id={fieldNameForQuestion(id)}
        />
      );
    case 'single-select':
      return <FormSelectField {...commonProps} options={options} />;
    case 'paragraph-text':
      return <FormTextField {...commonProps} multiLine={true} />;
    case 'conditionals':
      return (
        <>
          <FormSelectField {...commonProps} options={options} />
          {question.conditionals.map(conditionalQuestion => (
            <Question
              depth={depth + 1}
              editable={editable}
              key={conditionalQuestion.external_id}
              parentQuestion={question}
              question={conditionalQuestion}
            />
          ))}
        </>
      );
    default:
      return <FormTextField {...commonProps} />;
  }
};

Question.propTypes = {
  depth: number,
  editable: bool,
  parentQuestion: shape(QuestionShape),
  question: shape(QuestionShape).isRequired,
};

export const useAttendeeCustomAnswerForm = (answers, questions) => {
  const defaultValues = useMemo(() => mapToAnswerFormData(questions, answers), [
    answers,
    questions,
  ]);

  const form = useForm({ defaultValues, shouldUnregister: true });
  const { reset } = form;
  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  return form;
};

export const AttendeeCustomAnswerForm = withErrorHandling()(
  function AttendeeCustomAnswerForm({
    className,
    editable,
    form,
    onSubmit,
    questions,
  }) {
    const { handleSubmit, reset } = form;

    useEffect(() => {
      if (!editable) {
        reset();
      }
    }, [editable, reset]);

    return (
      <FormProvider {...form}>
        <form
          className={className}
          onSubmit={handleSubmit(onSubmit)}
          data-testid="attendee-details-custom-question-form"
        >
          {questions?.map(question => {
            return (
              <Question
                key={question.external_id}
                question={question}
                editable={editable}
              />
            );
          })}
        </form>
      </FormProvider>
    );
  },
);

AttendeeCustomAnswerForm.propTypes = {
  className: string,
  editable: bool,
  form: object.isRequired,
  onSubmit: func,
  questions: arrayOf(
    shape({
      ...QuestionShape,
      options: arrayOf(
        shape({
          ...QuestionShape,
          default_answer: false,
          hidden: false,
        }),
      ),
    }),
  ),
};
