import { Button } from '@hopin-team/ui-button';
import { Modal } from '@hopin-team/ui-modal';
import { LAYOUT } from '@hopin-team/ui-symbols';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import styled from 'styled-components';

const image = require('marketo-modal/Left.png');

export const Form = styled.div`
  padding: var(--spacing-48) var(--spacing-24) var(--spacing-32);
  display: flex;
  flex-direction: column;
`;

export const RightHalf = styled.div`
  flex: 1;
  display: flex;
  min-width: 400px;
  min-height: 600px;
`;

export const LeftHalf = styled.div`
  flex: 1;
  display: flex;
  min-width: 400px;
  min-height: 600px;
  background-image: url('${image}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;

  @media (max-width: ${LAYOUT.TABLET_MIN}) {
    display: none;
  }
`;

export const StyledModal = styled(Modal)`
  & div[role='dialog'] {
    width: min-content;
  }
`;

export const StyledButton = styled(Button)`
  max-width: fit-content;

  @media (max-width: ${LAYOUT.TABLET_MIN}) {
    max-width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: ${rem(16)};
`;

export const StyledText = styled(Text)`
  font-size: ${rem(14)};
  font-weight: 600;
  line-height: ${rem(24)};
`;

export const LeftStyledText = styled(Text)`
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(16)};
  text-align: center;
`;
