export const trackSegmentPageView = (category, pageName, properties = {}) => {
  if (!isSegmentClientReady()) {
    return;
  }

  if (properties?.user_id) {
    // TODO: Remove and only perform once to reduce the number of
    //       events sent to Segment
    window.analytics.identify(properties.user_id);
  }

  window.analytics.page(category, pageName, properties);
};

const trackSegmentEvent = (eventName, properties = {}) => {
  if (!isSegmentClientReady()) {
    return;
  }

  if (properties?.user_id) {
    // TODO: Remove and only perform once to reduce the number of
    //       events sent to Segment
    window.analytics.identify(properties.user_id);
  }

  window.analytics.track(eventName, properties);
};

export const trackSegmentEventPromise = async (
  eventName,
  properties = {},
  options = {},
) => {
  if (!isSegmentClientReady()) {
    return;
  }

  if (properties?.user_id) {
    await window.analytics.identify(properties.user_id);
  }

  await window.analytics.track(eventName, properties, options);
};

export const isSegmentClientReady = () => {
  return window.analytics !== undefined;
};

export default trackSegmentEvent;
