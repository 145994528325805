import { useLocalization } from '@features/localization';
import { Icon } from '@hopin-team/ui-icon';
import { array, number, string } from 'prop-types';
import React from 'react';

import { numberFormatter } from '../shared';
import {
  ScheduleInfoItem,
  ScheduleInfoItemContent,
  ScheduleInfoItemTitle,
  ScheduleInfoValue,
} from './styles';

export const Audience = ({
  selectedTicketTypes,
  recipientsCount,
  eventAudienceCount,
  status,
  registrationCount,
}) => {
  const { t } = useLocalization('email-dashboard');

  const statusLabels = {
    registered: t('edit.all_registered'),
    attended: t('edit.all_attended'),
    unattended: t('edit.all_unattended'),
    ml_invited: t('edit.all_pending_registration'),
  };

  return (
    <ScheduleInfoItem my={1}>
      <Icon name="people" />
      <ScheduleInfoItemContent>
        <ScheduleInfoItemTitle>
          {t(`schedule-email.audience`)}
        </ScheduleInfoItemTitle>
        {registrationCount > 0 && (
          <div data-testid="recipients">
            <ScheduleInfoValue>
              {numberFormatter(recipientsCount)}
            </ScheduleInfoValue>{' '}
            {t('previewAndReviewPage.of')}{' '}
            <ScheduleInfoValue>
              {numberFormatter(eventAudienceCount)}
            </ScheduleInfoValue>{' '}
            {t('previewAndReviewPage.attendees')}
          </div>
        )}
        <div data-testid="audience-selection-targeting">
          {statusLabels[status]}
          {!!selectedTicketTypes.length && (
            <>
              {' '}
              {t('schedule-email.with')}{' '}
              {selectedTicketTypes.map(({ label }) => label).join(', ')}
            </>
          )}
          .
        </div>
      </ScheduleInfoItemContent>
    </ScheduleInfoItem>
  );
};

Audience.propTypes = {
  status: string,
  selectedTicketTypes: array,
  recipientsCount: number,
  eventAudienceCount: number,
  registrationCount: number,
};
