import compose from 'lodash/fp/compose';
import React from 'react';

import withReduxProvider from '@/redux/with-provider';

import ReportsNotificationContainer from './reports-notification-container';
import { useReportsNotificationPusherSubscription } from './use-reports-notification-pusher-subscription';

/**
 * Displays the status of report generation AND subscribes to Pusher updates
 * @returns {ReportsNotificationContainer}
 */
function ReportsNotificationWithPusherContainer() {
  useReportsNotificationPusherSubscription();

  return <ReportsNotificationContainer theme="primary" />;
}

export default compose(withReduxProvider)(
  ReportsNotificationWithPusherContainer,
);
