const updateMarketingSettings = {
  data: {
    id: 'abc-123',
    type: 'marketing_settings',
    attributes: {
      short_description: 'lorem ipsum',
      analytics_code: 'XYZ',
    },
  },
};

export default updateMarketingSettings;
