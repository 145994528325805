import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const Header = styled(Flex)`
  white-space: pre-line;

  & [role='tooltip'] {
    max-width: 240px;
  }
`;
