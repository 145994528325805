import { v1 as uuid } from 'uuid';

import { applyMapping } from './serialization';

describe('Serialization', () => {
  describe('applyMapping', () => {
    const COL_FIRST_NAME = 'First Name';
    const COL_LAST_NAME = 'Surname';
    const COL_EMAIL = 'Digital Mailbox';
    const COL_QUESTION = 'Describe your talent';

    const mapping = {
      first_name: COL_FIRST_NAME,
      last_name: COL_LAST_NAME,
      email: COL_EMAIL,
    };

    const sampleRecord = {
      [COL_FIRST_NAME]: 'Isaac',
      [COL_LAST_NAME]: 'Brock',
      [COL_EMAIL]: 'isaac@modest.mouse',
      [COL_QUESTION]: 'I ruined the band...',
    };

    it('returns a function which applies the mapping to its first argument', () => {
      const result = applyMapping(mapping)(sampleRecord);

      expect(result).toEqual({
        first_name: 'Isaac',
        last_name: 'Brock',
        email: 'isaac@modest.mouse',
      });
    });

    it('omits columns which are not present in the mapping', () => {
      const result = applyMapping(mapping)(sampleRecord);
      expect(result).not.toHaveProperty(COL_QUESTION);
    });

    describe('when the upload contains custom form questions', () => {
      const external_id = uuid();
      const questions = new Set([external_id]);

      it('populates an answers object keyed by question id', () => {
        const result = applyMapping(
          {
            ...mapping,
            [external_id]: COL_QUESTION,
          },
          questions,
        )({
          ...sampleRecord,
          [COL_QUESTION]: 'answer text',
        });

        expect(result).toEqual({
          first_name: 'Isaac',
          last_name: 'Brock',
          email: 'isaac@modest.mouse',
          answers: { [external_id]: 'answer text' },
        });
      });
    });
  });
});
