import AddSpeakersTabPabel from '../partials/tab-panels/add-speakers-tab-panel';
import BuildVenueTabPabel from '../partials/tab-panels/build-venue-tab-panel';
import PreviewAndPublishTabPabel from '../partials/tab-panels/preview-and-publish-tab-panel';
import SetupEventTabPanel from '../partials/tab-panels/setup-event-tab-panel';
import { STEPS } from './steps';

export const getTabs = t => [
  {
    title: t('overview.event-setup-checklist.tabs.setup-event'),
    requiredSteps: [STEPS.DETAILS, STEPS.TICKETS, STEPS.LANDING_PAGE],
    tabPanelComponent: SetupEventTabPanel,
  },
  {
    title: t('overview.event-setup-checklist.tabs.build-venue'),
    requiredSteps: [STEPS.RECEPTION, STEPS.SESSIONS, STEPS.STAGE],
    tabPanelComponent: BuildVenueTabPabel,
  },
  {
    title: t('overview.event-setup-checklist.tabs.add-speakers'),
    requiredSteps: [STEPS.SPEAKER_PROFILES],
    tabPanelComponent: AddSpeakersTabPabel,
  },
  {
    title: t('overview.event-setup-checklist.tabs.preview-and-publish'),
    requiredSteps: [STEPS.PREVIEWED, STEPS.PUBLISHED],
    tabPanelComponent: PreviewAndPublishTabPabel,
  },
];
