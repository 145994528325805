import { createSlice } from '@reduxjs/toolkit';

export const userInitialState = {
  accepted_terms: true,
  bio: null,
  city: null,
  country: null,
  email: '',
  event_purpose: [],
  external_id: '',
  first_name: '',
  headline: '',
  id: '',
  institution: '',
  intent: 'organizer',
  interests: [''],
  last_name: '',
  linkedin: '',
  picture: '',
  saved_events_slugs: [],
  suggested_profile: null,
  twitter: '',
  website: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
});

const { reducer } = userSlice;
export default reducer;
