import { EXPERIENCE_TEMPLATE } from '@features/event-creation-wizard/constants/event-experience';
import React from 'react';
import { useSelector } from 'react-redux';

import HybridPreviewImage from './assets/hybrid-preview.png';
import OnsitePreviewImage from './assets/onsite-preview.png';
import VirtualPreviewImage from './assets/virtual-preview.png';
import WebinarPreviewImage from './assets/webinar-preview.jpg';
import { PreviewContainer } from './styles';

const previews = {
  [EXPERIENCE_TEMPLATE.WEBINAR]: WebinarPreviewImage,
  [EXPERIENCE_TEMPLATE.VIRTUAL]: VirtualPreviewImage,
  [EXPERIENCE_TEMPLATE.HYBRID]: HybridPreviewImage,
  [EXPERIENCE_TEMPLATE.ONSITE]: OnsitePreviewImage,
};

export const ExperiencePreview = () => {
  const { id: templateID } = useSelector(state => state.template);

  return (
    <PreviewContainer>
      <img src={previews[templateID]} draggable="false" alt="" />
    </PreviewContainer>
  );
};
