import hopinApi from '@api/hopin';
import peopleApi from '@api/people';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import HidePeoplePrompt from '@features/people/components/hide-people-prompt';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { Toggle } from '@hopin-team/ui-toggle';
import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export const ATTENDEE_VISIBILITY = {
  SHOW_ALL: 'show_all',
  SHOW_LIST: 'show_list',
  SHOW_NONE: 'show_none',
};

const AttendeeVisibilityToggles = ({ control }) => {
  const eventExternalId = useSelector(({ event }) => event.externalId);
  const { t } = useLocalization();
  const [speedNetworkingEnabled, setSpeedNetworkingEnabled] = useState(true);
  const [showPeoplePrompt, setShowPeoplePrompt] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const { token } = await hopinApi.getUserToken();
      const response = await peopleApi.getPeopleSettings(
        eventExternalId,
        token,
      );
      setSpeedNetworkingEnabled(
        response?.event_setting?.enable_speed_networking,
      );
    }
    fetchData();
  }, [eventExternalId]);

  const {
    field: { onChange, value },
  } = useController({
    name: 'venue_settings[attendees_visiblity]',
    control,
  });

  const handleListToggle = e => {
    if (speedNetworkingEnabled) {
      e.target.checked
        ? onChange(ATTENDEE_VISIBILITY.SHOW_LIST)
        : onChange(ATTENDEE_VISIBILITY.SHOW_NONE);
    } else {
      e.target.checked
        ? onChange(ATTENDEE_VISIBILITY.SHOW_LIST)
        : setShowPeoplePrompt(true);
    }
  };

  const handleCountToggle = e =>
    e.target.checked
      ? onChange(ATTENDEE_VISIBILITY.SHOW_ALL)
      : onChange(ATTENDEE_VISIBILITY.SHOW_LIST);

  return (
    <Box mb={3}>
      <Text
        element="h3"
        pattern="headingFour"
        color="grey-800"
        weight="medium"
        scale={4}
        mb={1}
      >
        {t('venue-controls.attendee-visibility.title')}
      </Text>

      <Toggle
        label={t('venue-controls.attendee-visibility.show-list-label')}
        sizeOption="large"
        onChange={handleListToggle}
        isToggled={
          value === ATTENDEE_VISIBILITY.SHOW_ALL ||
          value === ATTENDEE_VISIBILITY.SHOW_LIST
        }
      />
      <StyledBox ml={8}>
        <Text scale={4} pattern="body" element="p" color="grey-600" mb={3}>
          {t('venue-controls.attendee-visibility.description')}
        </Text>
        {(value === ATTENDEE_VISIBILITY.SHOW_ALL ||
          value === ATTENDEE_VISIBILITY.SHOW_LIST) && (
          <>
            <Toggle
              label={t('venue-controls.attendee-visibility.show-count-label')}
              sizeOption="large"
              onChange={handleCountToggle}
              isToggled={value === 'show_all'}
            />
            <Text scale={4} pattern="body" element="p" color="grey-600" ml={8}>
              {t('venue-controls.attendee-visibility.count-description')}
            </Text>
          </>
        )}
      </StyledBox>

      <HidePeoplePrompt
        showPeoplePrompt={showPeoplePrompt}
        setShowPeoplePrompt={setShowPeoplePrompt}
        onConfirm={() => onChange(ATTENDEE_VISIBILITY.SHOW_NONE)}
        source={t('people.people-area.speed-networking')}
      />
    </Box>
  );
};

AttendeeVisibilityToggles.propTypes = {
  control: object.isRequired,
};

export default withLocalizationProvider(AttendeeVisibilityToggles);

const StyledBox = styled(Box)`
  max-width: 37.875rem;
`;
