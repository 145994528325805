import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import styled from 'styled-components';

export const LearnMoreLink = styled(Text).attrs({
  color: 'blue-500',
  scale: 4,
})`
  cursor: pointer;
`;

export const ModalHeader = styled(Flex)`
  width: 23.25rem;
  height: 9.5rem;
`;

export const ModalBody = styled(Flex).attrs({
  p: 2,
  flexDirection: 'column',
})``;

export const ModalFooter = styled(Flex).attrs({
  p: 2,
  flexDirection: 'column',
})``;

export const ModalTitle = styled(Text).attrs({
  pattern: 'headingOne',
})`
  position: absolute;
  top: 2.5rem;
  left: 2rem;
  font-weight: 500;
`;

export const ReadArticleButton = styled(Button)`
  height: 2rem;
  margin: 0 0.25rem;
`;

export const FeatureList = styled.ul`
  padding: 0 0 0 1.5rem;
`;

export const FeatureItem = styled.li`
  margin-bottom: 1rem;
`;

export const FeatureLink = styled.a`
  display: block;
`;
