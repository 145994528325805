import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import { rem } from 'polished';
import { func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const Anchor = styled.a`
  color: var(--color-blue-400);
  text-decoration: underline;
  font-weight: var(--font-weight-medium);
`;

const Container = styled.div`
  max-width: ${rem(392)};
  margin: auto;
  text-align: center;
`;

const SuccessModal = ({ t, image, ctaPath }) => (
  <Container data-testid="success-modal">
    <Flex alignItems="center" flexDirection="column">
      {image}
      <Text mt={1} pattern="headingTwo" element="p">
        {t('.title')}
      </Text>
      <Text mb={2} element="p">
        {t('.subtitle')}
      </Text>
      <Flex alignItems="center">
        <Anchor data-testid="continue-button" href={ctaPath}>
          {t('.cta')}
        </Anchor>
      </Flex>
    </Flex>
  </Container>
);

SuccessModal.propTypes = {
  t: func.isRequired,
  image: node.isRequired,
  ctaPath: string.isRequired,
};

export default SuccessModal;
