import { useEffect, useState } from 'react';

import { parseFile } from './utils/csv';

export const useParseCsvFile = (file, onError) => {
  const [parsedFile, setParsedFile] = useState();

  useEffect(() => {
    (async () => {
      try {
        setParsedFile(file ? await parseFile(file) : undefined);
      } catch (e) {
        onError?.(e);
      }
    })();
  }, [file, onError]);

  return parsedFile;
};
