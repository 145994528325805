import styled from 'styled-components';

export const Wrapper = styled.header`
  height: 4rem;
  border-bottom: 1px solid var(--color-gray-300);
  background-color: var(--color-white);
  padding: var(--page-padding-small) var(--page-padding);

  @media (max-width: 768px) {
    padding-left: var(--page-padding-small);
    padding-right: var(--page-padding-small);
  }
`;

export const Container = styled.div`
  ${({ isFluid }) => !isFluid && `max-width: 72rem;`}
  margin: 0 auto;
`;
