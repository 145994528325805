import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LayoutWrapper = styled.div`
  position: relative;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);

  @media (max-width: 768px) {
    padding-left: var(--page-padding-small);
    padding-right: var(--page-padding-small);
  }
`;

const LayoutContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${rem(1152)};
`;

export const Layout = ({ children, withLayout }) =>
  withLayout ? (
    <LayoutWrapper>
      <LayoutContainer>{children}</LayoutContainer>
    </LayoutWrapper>
  ) : (
    <>{children}</>
  );

Layout.propTypes = {
  children: PropTypes.node,
  withLayout: PropTypes.bool,
};
