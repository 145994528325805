import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authToken: null,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

export const { setAuthToken } = actions;

export default reducer;
