import PropTypes from 'prop-types';
import React from 'react';

const ChevronLeft = ({ size, color }) => {
  size = size || 12;
  color = color || 'currentColor';

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon color"
        d="M0.266387 7.41907C0.125694 7.27855 0.0465618 7.08792 0.0463867 6.88907V6.50907C0.0486907 6.31066 0.127503 6.12079 0.266387 5.97907L5.40639 0.849071C5.50027 0.754415 5.62807 0.701172 5.76139 0.701172C5.89471 0.701172 6.0225 0.754415 6.11639 0.849071L6.82639 1.55907C6.92045 1.65123 6.97346 1.77738 6.97346 1.90907C6.97346 2.04076 6.92045 2.16691 6.82639 2.25907L2.37639 6.69907L6.82639 11.1391C6.92104 11.233 6.97429 11.3608 6.97429 11.4941C6.97429 11.6274 6.92104 11.7552 6.82639 11.8491L6.11639 12.5491C6.0225 12.6437 5.89471 12.697 5.76139 12.697C5.62807 12.697 5.50027 12.6437 5.40639 12.5491L0.266387 7.41907Z"
        fill={color}
      />
    </svg>
  );
};

ChevronLeft.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ChevronLeft;
