import { isFunction } from 'lodash';
import { arrayOf, func, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { StyledSelect } from './styles';

const PreviewSelect = ({
  onChange,
  defaultValue,
  options: initialOptions,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (defaultValue) {
      isFunction(onChange) && onChange(defaultValue);
    }
  }, []);

  const handleOnSelectChange = newValue => {
    isFunction(onChange) && onChange(newValue);
    setValue(newValue);
  };

  return (
    <StyledSelect
      options={initialOptions}
      classNamePrefix="preview-select"
      onChange={handleOnSelectChange}
      value={value}
      {...rest}
    />
  );
};

PreviewSelect.propTypes = {
  onChange: func,
  defaultValue: shape({
    label: string,
    value: number,
  }),
  options: arrayOf(
    shape({
      label: string,
      value: number,
    }).isRequired,
  ),
};

PreviewSelect.defaultProps = {
  options: [],
};

export default PreviewSelect;
