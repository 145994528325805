import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Button } from '@hopin-team/ui-button';
import { Flex } from '@hopin-team/ui-flex';
import { Text } from '@hopin-team/ui-text';
import * as Routes from '@routes';
import { bool, number, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

import CSVUpload from '../csv/csv-upload';
import { CustomToast } from '../csv/upload-modal/modal-views/error-report.style';
import { useStorageErrors } from '../csv/use-storage-errors';
import InviteVendors from './invite-vendors';
import SearchBar from './search-bar';
import { CustomText } from './vendor-list-header.style';

const VendorListHeader = ({
  eventSlug,
  authenticityToken,
  guestOnlyConfigured,
  canInvite,
  showLeadRetrievalLicenses,
  leadRetrievalLicenses,
  searchText,
}) => {
  const { t } = useLocalization('vendor-list-header');
  const { storagedErrors } = useStorageErrors();

  const [isShowing, setIsShowing] = useState(false);
  const toggleModal = useCallback(
    () => setIsShowing(previous => !previous),
    [],
  );

  return (
    <Box mb={4}>
      <Box my={3}>{t('description')}</Box>
      <Flex className="avatar-group">
        <Button
          isInline
          href={Routes.newOrganisersEventVendorPath(eventSlug)}
          size="medium"
          mr={2}
          leadingIcon="plus"
        >
          {t('add-booth')}
        </Button>

        <CSVUpload
          eventSlug={eventSlug}
          authenticityToken={authenticityToken}
          mr={2}
          isShowing={isShowing}
          toggleModal={toggleModal}
          canInvite={canInvite}
        />

        <Button
          isOutlined
          isInline
          href={Routes.exportCsvOrganisersEventVendorsPath(eventSlug)}
          size="medium"
          mr={2}
          leadingIcon="download"
        >
          {t('download')}
        </Button>

        {!guestOnlyConfigured && (
          <InviteVendors mr={2} canInvite={canInvite} eventSlug={eventSlug} />
        )}

        <SearchBar searchText={searchText} />

        {showLeadRetrievalLicenses && (
          <span className="buttonish -small -light ml-auto">
            <b>{t('lead-retrieval-licenses')}</b>&nbsp;
            {t('available')}:&nbsp;
            <b>{leadRetrievalLicenses}</b>
          </span>
        )}
      </Flex>

      {!!storagedErrors?.length && (
        <CustomToast
          icon="alert"
          colorPattern="danger"
          role="status"
          iconColor="red-600"
          isInverse
          mt={4}
          py={2}
        >
          <Box>
            <Text pattern="strong" color="grey-800">
              {t('report-message')}.{' '}
            </Text>

            <CustomText color="blue-500" onClick={toggleModal}>
              {t('report-link')}
            </CustomText>
          </Box>
        </CustomToast>
      )}
    </Box>
  );
};

VendorListHeader.propTypes = {
  eventSlug: string.isRequired,
  authenticityToken: string.isRequired,
  guestOnlyConfigured: bool.isRequired,
  canInvite: bool.isRequired,
  showLeadRetrievalLicenses: bool.isRequired,
  leadRetrievalLicenses: number.isRequired,
  searchText: string.isRequired,
};

export default withLocalizationProvider(VendorListHeader);
