import { Tab, TabPanel, Tabs } from '@hopin-team/ui-tabs';
import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

Styles.Tabs = styled(Tabs)`
  [data-reach-tab-list] {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    & > div {
      bottom: 0;
    }
  }
  [data-reach-tab-list]::-webkit-scrollbar {
    display: none;
  }
`;

Styles.Tab = styled(Tab)`
  &[data-reach-tab] {
    background: white;
    width: ${rem(140)};
  }
`;

Styles.TabPanel = styled(TabPanel)`
  &[data-reach-tab-panel] {
    margin-top: 24px;
  }
`;

Styles.Grid = styled.div`
  display: grid;
  row-gap: 24px;
`;
