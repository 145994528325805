import { useLocalization } from '@features/localization';
import { DeprecatedSelect as Select } from '@hopin-team/ui-select';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { iconAccessibilityProps } from './icon-accessibility-props';

export const StatusSelect = ({ value, onChange, isDisabled, ...props }) => {
  const { t } = useLocalization('email-dashboard.edit');
  const statuses = useMemo(
    () =>
      !isDisabled
        ? [
            {
              label: t('all_registered'),
              value: 'registered',
            },
            {
              label: t('all_attended'),
              value: 'attended',
            },
            {
              label: t('all_unattended'),
              value: 'unattended',
            },
            {
              label: t('all_pending_registration'),
              value: 'ml_invited',
            },
          ]
        : [],
    [isDisabled, t],
  );

  const selected = useMemo(
    () => statuses?.find(option => option.value === value),
    [value, statuses],
  );

  return (
    <div data-testid="audience-status-select">
      <Select
        classNamePrefix="audience-status"
        name="status"
        id="status"
        isDisabled={isDisabled}
        placeholder={isDisabled ? t('no_audience') : t('select_status')}
        iconAccessibilityProps={iconAccessibilityProps(t)}
        onChange={({ value }) => onChange(value)}
        value={selected}
        options={statuses}
        {...props}
      ></Select>
    </div>
  );
};

StatusSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};
