import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const template = createSlice({
  name: 'purpose',
  initialState,
  reducers: {
    setPurpose: (state, action) => action.payload,
  },
});

const {
  reducer,
  actions: { setPurpose },
} = template;

export { setPurpose };

export default reducer;
