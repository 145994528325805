import { rem } from 'polished';
import styled from 'styled-components';

export const EventAccessContainer = styled.div`
  width: ${rem(529)};
`;

export const RadioGroupContainer = styled.div`
  .radio-buttons__radio-group__buttons-wrapper {
    width: 100%;
    margin-bottom: ${rem(24)};
  }
`;

export const RadioButtonContainer = styled.label`
  cursor: pointer;
  border-radius: 4px;
  padding: ${rem(16)};
  max-width: ${rem(392)};
  background: ${props =>
    props.isSelected ? 'var(--color-blue-100)' : 'var(--color-gray-100)'};

  .radio-buttons__radio-button__label {
    font-weight: var(--font-weight-normal);
  }
`;
