import { Flex } from '@hopin-team/ui-flex';
import { Pill } from '@hopin-team/ui-pill';
import { Select } from '@hopin-team/ui-select';
import styled from 'styled-components';

// Hide the label in an accessible way
// TODO: add this to the planetary component
const SelectWrapper = styled(Select)`
  min-width: 260px;
  max-width: 400px;
`;

const PillWrapper = styled(Flex)`
  cursor: pointer;

  ${Pill} {
    border-color: ${props =>
      props.selected ? 'var(--color-blue-400)' : 'var(--color-gray-400)'};
    background-color: ${props =>
      props.selected ? 'var(--color-blue-400);' : 'var(--color-white)'};
    color: ${props =>
      props.selected ? 'var(--color-white);' : 'var(--color-gray-700)'};
    font-weight: var(--font-weight-medium);

    &:hover {
      background-color: ${props =>
        props.selected ? 'var(--color-blue-400);' : 'var(--color-gray-100)'};
    }
  }
`;

const FilterContainer = styled(Flex)`
  width: calc(100% - 4rem);
`;

const Divider = styled(Flex).attrs({ mx: 1 })`
  width: 1px;
  background-color: var(--color-gray-400);
  height: 16px;
`;

export { Divider, FilterContainer, PillWrapper, SelectWrapper };
