import { createSlice } from '@reduxjs/toolkit';

export const eventSlice = createSlice({
  name: 'event',
  initialState: {
    slug: '',
    status: '',
    days: '',
    timeStart: '',
    timeEnd: '',
    areas: [],
    eventId: '',
  },
  reducers: {
    setEvent: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setEvent } = eventSlice.actions;

export default eventSlice.reducer;
