import { useLocalization } from '@features/localization';
import { ToastMessage } from '@features/registrations/components';
import { Link } from '@hopin-team/ui-link';
import { Text } from '@hopin-team/ui-text';
import PropTypes from 'prop-types';
import React from 'react';

export const EmailMarketing = ({ eventSlug, registrationEmailsDisabled }) => {
  const { t } = useLocalization(
    'people.attendees.registrant-bulk-upload.email-marketing',
  );

  const customizeEmailsHref = `/organisers/events/${eventSlug}/communications`;
  const learnMoreHref =
    'https://events-support.ringcentral.com/hc/en-us/articles/360058323611-Automated-Emails-to-Attendees';

  const learnMore = (
    <Link
      label={t('learn-more')}
      href={learnMoreHref}
      target="_blank"
      scale={4}
      rel="noreferrer noopener"
      trailingIcon="open-in-new"
    />
  );

  return registrationEmailsDisabled ? (
    <>
      <Text
        pattern="body"
        element="p"
        mb={2.5}
        dangerouslySetInnerHTML={{ __html: t('disabled.content') }}
      />
      <ToastMessage
        icon="disable"
        colorPattern="primary"
        content={t('disabled.toast', {
          customizeEmailsHref,
        })}
        learnMore={learnMore}
      ></ToastMessage>
    </>
  ) : (
    <>
      <Text
        pattern="body"
        element="p"
        mb={2.5}
        dangerouslySetInnerHTML={{
          __html: t('enabled.content-without-youre-in-emails'),
        }}
      />
      <ToastMessage
        icon="warning"
        colorPattern="warning"
        iconColor="yellow-600"
        content={t('enabled.toast', {
          customizeEmailsHref,
        })}
        learnMore={learnMore}
      ></ToastMessage>
    </>
  );
};

EmailMarketing.propTypes = {
  registrationEmailsDisabled: PropTypes.bool,
  eventSlug: PropTypes.string.isRequired,
};
