import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    flow: '',
  },
  reducers: {
    setFlow: (state, action) => {
      state.flow = action.payload;
      return state;
    },
  },
});

const {
  reducer,
  actions: { setFlow },
} = appSlice;

export { setFlow };

export default reducer;
