import analyticsApi from '@api/analytics';
import Chart from '@components/icons/chart';
import Loading from '@components/loading';
import CardTitle from '@features/dashboard/components/card-title';
import EmptyState from '@features/dashboard/components/empty-state/empty-state';
import { CardContentWrapper } from '@features/dashboard/styles/card-styles';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import EventAreaIcon from '@features/overview/analytics/common/event-area-icon';
import floor from 'lodash/floor';
import sumBy from 'lodash/sumBy';
import { string } from 'prop-types';
import React, { useState } from 'react';

import Card from '../common/overview-card';
import TopItemsListComponent from '../common/top-items-list-component';

const transformResults = areas => {
  const totalCount = sumBy(areas, 'count');

  if (totalCount === 0) return [];

  return areas.map(({ count, segment, name }) => {
    const formattedCount = count.toLocaleString();
    const percentage = `${floor((count / totalCount) * 100, 2)}%`;

    return {
      icon: <EventAreaIcon eventArea={segment} />,
      text: name,
      value: `${formattedCount} (${percentage})`,
      testId: `top-items-list-${segment.toLowerCase()}`,
    };
  });
};

const ChatMessagesBySegment = ({ eventSlug }) => {
  const { t } = useLocalization('overview.chat-messages-by-segment');
  const [segments, setSegments] = useState([]);

  const loading = usePromise(
    signal => analyticsApi.getChatMessagesBySegment(eventSlug, signal),
    results => setSegments(transformResults(results)),
  );

  return (
    <Card testId="chat-messages-by-segment">
      <CardContentWrapper>
        <CardTitle>{t('title')}</CardTitle>
        <Loading isLoading={loading}>
          {segments.length > 0 ? (
            <TopItemsListComponent items={segments} />
          ) : (
            <EmptyState text={t('empty')} icon={<Chart />} />
          )}
        </Loading>
      </CardContentWrapper>
    </Card>
  );
};

ChatMessagesBySegment.propTypes = {
  eventSlug: string.isRequired,
};

export default ChatMessagesBySegment;
