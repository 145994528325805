import camelizeKeys from '@util/camelize-keys';
import { addDays } from 'date-fns';

export const RECORDING_DETAIL_TEST_ID = {
  // Detail page elements
  DESCRIPTION_TEXT: 'description-text',
  EDIT_MODAL: 'edit-recording-modal',
  LAST_TRIMMED: 'last-trimmed',
  PUBLISH_CONTROLS: 'publish-controls',
  RECORDING_CONTROLS: 'recording-controls',
  RECORDING_BUTTONS: 'recording-buttons',
  RELATED_RECORDINGS: 'related-recordings',
  STATUS_MSG: 'status-message',
  SUBTITLE: 'recording-subtitle',
  PROCESSING_OVERLAY: 'processing-overlay',

  // Detail page action elements
  BACKUP_LINK: 'backup-link',
  DELETE_BTN: 'delete-button',
  DESCRIPTION_BTN: 'description-icon-btn',
  DESCRIPTION_LINK: 'description-link-btn',
  DOWNLOAD_BTN: 'download-icon-btn',
  OPTIONS_BTN: 'options-icon-btn',
  EDIT_BTN: 'edit-icon-btn',
  REVERT_BTN: 'revert-button',
  TRIM_BTN: 'trim-icon-btn',
  CHANGE_THUMBNAIL_BTN: 'change-thumbnail-icon-btn',
  VIEW_TRANSCRIPT_BTN: 'view-transcript-icon-btn',
};

export const featureFlags = {
  customThumbnailsEnabled: false,
};

export const daysFromNow = days => addDays(new Date(), days).toISOString();

export const slug = 'slug';

export const event = {
  slug,
  status: 'published',
  timeStart: '2021-03-07T19:09:58.000Z',
  timeEnd: '2029-03-07T19:09:58.000Z',
  areas: { stage: true, sessions: true },
  eventId: 'eventId',
};

export const title = 'test title 222ee';

export const generatedTitle = 'January 27, 2022 at 5:36 PM';

export const rawRelatedRecordings = [
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1Mg==',
    playableUrl:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    eventPart: {
      name: 'Main Stage',
      type: 'stage',
    },
    startedAt: '2021-03-07T19:09:58.000Z',
    endedAt: '2021-03-07T19:18:01.259Z',
    createdAt: '2021-03-07T19:24:06.138Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M2==',
    playableUrl:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    eventPart: {
      name: 'Main Stage',
      type: 'stage',
    },
    startedAt: '2021-03-07T19:09:58.000Z',
    endedAt: '2021-03-07T19:18:01.259Z',
    createdAt: '2021-03-07T19:24:06.138Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M3==',
    playableUrl:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    eventPart: {
      name: 'Main Stage',
      type: 'stage',
    },
    startedAt: '2021-03-07T19:09:58.000Z',
    endedAt: '2021-03-07T19:18:01.259Z',
    createdAt: '2021-03-07T19:24:06.138Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M4==',
    playableUrl:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    eventPart: {
      name: 'Main Stage',
      type: 'stage',
    },
    startedAt: '2021-03-07T19:09:58.000Z',
    endedAt: '2021-03-07T19:18:01.259Z',
    createdAt: '2021-03-07T19:24:06.138Z',
    duration: 483,
  },
  {
    id: 'TGl2ZXN0cmVhbVJlY29yZGluZzo1M5==',
    playableUrl:
      'https://stream.mux.com/o4VszgQ5CUPP2vYZ8Qbbzf41YvW2dLud/high.mp4',
    eventPart: {
      name: 'Main Stage',
      type: 'stage',
    },
    startedAt: '2021-03-07T19:09:58.000Z',
    endedAt: '2021-03-07T19:18:01.259Z',
    createdAt: '2021-03-07T19:24:06.138Z',
    duration: 483,
  },
];

export const rawRecording = {
  id: 'TGl2ZXN0cmVhbVJlY29yZGluZzoxMDE=',
  started_at: '2022-01-27T17:36:06.094Z',
  ended_at: '2022-01-27T17:39:06.094Z',
  created_at: '2022-01-27T18:10:06.095Z',
  playable_url: 'https://test-playable-url',
  download_url:
    '/organisers/events/emw-live-event-with-primary-and-backup/recordings/TGl2ZXN0cmVhbVJlY29yZGluZzoxMDE=/download',
  thumbnail: 'https://image.mux.com/xyz/thumbnail.jpg?width=640',
  captions_url: null,
  duration: 180,
  event_part: {
    external_id: 'kjmaW30627XV1eELORKjLfWYx',
    name: 'Default Stage',
    type: 'stage',
  },
  published: false,
  title: title,
  last_edit: null,
  rank: 'main',
  when_in_time: 'in_event',
  original_recording: {
    playable_url: 'https://test-playable-url',
    started_at: '2022-01-27T17:36:06.094Z',
    ended_at: '2022-01-27T17:39:06.094Z',
  },
  related_recordings: rawRelatedRecordings,
  recording_id_to_fetch_assets: 'TGl2ZXN0cmVhbVJlY29yZGluZzoxQWY=',
};

export const rawRecordingWithTrimSuccess = {
  ...rawRecording,
  last_edit: {
    status: 'success',
    completedGenerationAt: '2022-01-27T19:39:06.094Z',
  },
};

export const rawRecordingWithTrimProcessing = {
  ...rawRecording,
  last_edit: {
    status: 'processing',
  },
  related_recordings: [],
  id: 'test1',
};

export const rawRecordingWithTrimFailed = {
  ...rawRecording,
  last_edit: {
    status: 'failure',
  },
  related_recordings: [],
  id: 'test1',
};

export const relatedRecordings = camelizeKeys(rawRelatedRecordings);
export const recording = camelizeKeys(rawRecording);
export const trimmedRecording = camelizeKeys(rawRecordingWithTrimSuccess);

/**
 * recording/selector adds derived values to the recording object
 * when testing without redux (recording is passed in as prop) it is necessary to add these
 */
export const enhancedRecording = {
  ...recording,
  lastEdit: {
    status: 'success',
    completedGenerationAt: '2022-01-27T19:39:06.094Z',
  },
  isProcessing: false,
  hasRecordingEdits: true,
  lastTrimmedTime: '2022-01-27T19:39:06.094Z',
};

export const defaultUser = {
  userId: 'userId',
};

export const defaultRegistration = {
  external_id: 'registrationExternalId',
};
