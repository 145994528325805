import { useLocalization } from '@features/localization';
import { arrayOf, number, shape, string } from 'prop-types';
import React from 'react';

import { iconAccessibilityProps } from '../../email/announcements-edit/audience-select/icon-accessibility-props';
import PreviewSelect from './preview-select';

const PreviewOrganisersSelect = props => {
  const { t } = useLocalization();

  return (
    <PreviewSelect
      iconAccessibilityProps={iconAccessibilityProps(t)}
      placeholder={t(
        `email-dashboard.send-test-email.select-organiser.placeholder`,
      )}
      {...props}
    />
  );
};

PreviewOrganisersSelect.propTypes = {
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: number.isRequired,
    }).isRequired,
  ),
};

PreviewOrganisersSelect.defaultProps = {
  options: [],
};

export default PreviewOrganisersSelect;
