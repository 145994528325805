/* eslint-disable jsx-a11y/media-has-caption */
import {
  LocalizationContext,
  withLocalizationProvider,
} from '@features/localization';
import { SECONDS_IN_ONE_HOUR } from '@features/recordings/constants';
import { toTimestampString } from '@features/recordings/utils/utils';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import videojs from 'video.js';

import { mapModeToStyles } from './styled';
import { getModeProps, usePlayerMode } from './usePlayerMode';

const VideoPlayer = ({
  forcePause,
  mode = 'default',
  src,
  captionsSrc,
  trimStartTime,
  trimEndTime,
  children,
  onDurationChange,
  reloadSource,
}) => {
  const videoRef = useRef();
  const [player, setPlayer] = useState();
  const [currentSrc, setCurrentSrc] = useState(src);

  const { t, language } = useContext(LocalizationContext);

  useEffect(() => {
    if (!player) {
      setPlayer(
        videojs(videoRef.current, {
          sources: [{ src }],
          languages: {
            [language]: t('videojs'),
          },
          language,
          ...getModeProps(mode),
        }),
      );
    }

    return () => player?.dispose();
    // The src change is handled separately
    // We don't currently support changing the language on the fly,
    // which is not supported in Hopin as a whole on the Event pages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, player]);

  useEffect(() => {
    if (player) {
      const rootUrl = src.split('?')[0];
      const currentRootUrl = currentSrc.split('?')[0];

      if (rootUrl !== currentRootUrl) {
        setCurrentSrc(src);
        player.src({ src });
      }
    }
  }, [src, currentSrc, setCurrentSrc, player]);

  useEffect(() => {
    if (forcePause && player) {
      player.pause();
    }
  }, [forcePause, player]);

  useEffect(() => {
    if (player && reloadSource) {
      player.reloadSourceOnError({
        getSource: async reload => {
          try {
            const reloadedSrc = await reloadSource();
            reload({
              src: reloadedSrc,
            });
          } catch (error) {
            console.error(error);
          }
        },
        errorInterval: 5,
      });
    }
  }, [player, reloadSource]);

  const { duration } = usePlayerMode({
    mode,
    player,
    trimStartTime,
    trimEndTime,
    forcePause,
  });

  useEffect(() => {
    if (duration && onDurationChange) {
      onDurationChange(duration);
    }
  }, [duration, onDurationChange]);

  const ModeStyles = mapModeToStyles[mode];

  const durationStr = useMemo(() => {
    if (mode === 'edit' && duration) {
      const isHourNeededInTimeStamp = duration >= SECONDS_IN_ONE_HOUR;
      const timestampFormat = isHourNeededInTimeStamp ? 'H:mm:ss' : 'm:ss';

      return toTimestampString(trimEndTime, timestampFormat);
    }
  }, [trimEndTime, mode, duration]);

  return (
    <ModeStyles duration={durationStr}>
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-big-play-centered">
          {captionsSrc && (
            <track
              data-testid="captions-track"
              kind="captions"
              src={captionsSrc}
              srcLang="en"
              label="English"
            />
          )}
        </video>
      </div>
      {children}
    </ModeStyles>
  );
};

VideoPlayer.propTypes = {
  forcePause: PropTypes.bool,
  mode: PropTypes.string,
  src: PropTypes.string,
  captionsSrc: PropTypes.string,
  trimStartTime: PropTypes.number,
  trimEndTime: PropTypes.number,
  children: PropTypes.node,
  onDurationChange: PropTypes.func,
  reloadSource: PropTypes.func,
};

export default withLocalizationProvider(VideoPlayer);
/* eslint-enable jsx-a11y/media-has-caption */
