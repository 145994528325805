import { rem } from 'polished';
import styled from 'styled-components';

export const Styles = {};

Styles.Grid = styled.div`
  [class*='SpeakerCard'] {
    margin-top: ${rem(46)};
    max-width: inherit;
    min-width: inherit;
  }

  & > div {
    grid-template-columns: 1fr;
  }

  @media (min-width: 481px) {
    & > div {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;
