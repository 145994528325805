import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  externalId: null,
};

const { actions, reducer } = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegistration: (state, action) => action.payload,
  },
});

const getRegistrationExternalId = ({ registration }) =>
  registration?.externalId;

export const { setRegistration } = actions;

export { getRegistrationExternalId };

export default reducer;
