import Alerts from '@features/alerts/alerts';
import { withAlertsProvider } from '@features/alerts/alerts-provider';
import { useLocalization } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import { Text } from '@hopin-team/ui-text';
import { string } from 'prop-types';
import React, { useCallback, useState } from 'react';

import EventLinksModal from './event-links-modal';
import { ViewLinksButton } from './event-links-styles';

const EventLinksComponent = ({ eventId }) => {
  const [isModalDisplayed, setModalDisplayed] = useState(false);
  const handleOpenModal = useCallback(() => setModalDisplayed(true), []);
  const handleCloseModal = useCallback(() => setModalDisplayed(false), []);
  const { t } = useLocalization('overview.event-links');

  return (
    <>
      <Alerts />
      <Box p={2} border="grey-200" cornerRadius="lg">
        <Text
          element="h5"
          pattern="headingFive"
          color="grey-800"
          weight="medium"
        >
          {t('heading')}
        </Text>

        <Text element="p" pattern="body" color="grey-650" my={1}>
          {t('description')}
        </Text>
        <ViewLinksButton leadingIcon="link" onClick={handleOpenModal}>
          {t('view-links')}
        </ViewLinksButton>
        <EventLinksModal
          eventId={eventId}
          isModalDisplayed={isModalDisplayed}
          handleClose={handleCloseModal}
        />
      </Box>
    </>
  );
};

EventLinksComponent.propTypes = {
  eventId: string,
};

export default withAlertsProvider(EventLinksComponent);
