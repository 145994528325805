import { parse, unparse } from 'papaparse';

export const triggerCsvDownload = (data, filename, linkParentRef) => {
  if (!linkParentRef.current) {
    return;
  }

  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  /**
   * When "isDismissible" prop is set to false,
   * ui-modal prevents any click(s) outside the modal area.
   * To trigger the download, link is appended to
   * the modal's content, in order for the click event to work.
   */
  linkParentRef.current.appendChild(link);
  link.click();
  link.remove();
};

export const parseFile = file =>
  new Promise((resolve, reject) => {
    parse(file, {
      complete: ({ data, errors, meta }) => {
        if (errors?.length > 0 || meta.aborted) {
          // One or more lines are invalid; could be that
          // the whole file is garbage.
          reject(errors?.[0] || new Error('Parsing was aborted'));
        }

        resolve(data);
      },
      error: err => reject(err),
      header: true,
      skipEmptyLines: 'greedy',
    });
  });

/**
 * @see https://www.papaparse.com/docs#json-to-csv
 * @param input Object conforming to the structure in the linked docs
 * @returns {string} serialized output
 */
export const serializeCsv = input => unparse(input);

export const getFormattedErrors = errors => {
  return Object.values(errors).map(value => ({
    // Data from the back-end starts at 0. If considering the table headers and the first line in *.csv file, we need to add +2 here
    lineIndex: value.index + 2,
    messages: value.errors.join(', '),
  }));
};
