import { useLocalization } from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Halon } from '@hopin-team/ui-halon';
import { Text } from '@hopin-team/ui-text';
import { differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

const CountDown = ({ startDate }) => {
  const { t } = useLocalization('overview.onsite-banner');
  return (
    <Flex alignItems="center" pr={2} mr={8}>
      <Halon
        mr={2}
        icon="calendar"
        color="grey-200"
        iconColor="grey-900"
        scale={4}
        iconScale={2}
      />
      <Flex flexDirection="column">
        <Text pattern="bodyLargeBold">
          {differenceInDays(startDate, new Date())} {t('days')}
        </Text>
        <Text color="grey-600" pattern="body">
          {t('until-event-starts')}
        </Text>
      </Flex>
    </Flex>
  );
};

CountDown.propTypes = {
  startDate: PropTypes.instanceOf(Date),
};

export default CountDown;
