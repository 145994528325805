import Alerts from '@features/alerts/alerts';
import { LocalizationContext } from '@features/localization';
import { Box } from '@hopin-team/ui-box';
import {
  Tab,
  TabHeader,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@hopin-team/ui-tabs';
import useIsFreeTrial from '@util/hooks/use-is-free-trial';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import AttendeesListComponent from './attendees-list-component';
import UpgradeBanner from './upgrade-banner';
import WaitlistListComponent from './waitlist-list-component';

const Container = styled(Box)`
  width: 100%;
`;

const AttendeesWaitlistComponent = ({
  waitlistListUrl,
  attendeesListUrl,
  ...props
}) => {
  const { t } = useContext(LocalizationContext);
  const [tabIndex, setTabIndex] = useState(0);
  const { isFreeTrial, isDraft } = useIsFreeTrial();

  return (
    <Container px={2}>
      <Alerts />
      <Tabs onChange={setTabIndex}>
        <TabHeader>
          <TabList>
            <Tab>
              <Box px={4}>{t('people.attendees.tabs.registrants')}</Box>
            </Tab>
            <Tab>
              <Box px={4}>{t('people.attendees.tabs.waitlist')}</Box>
            </Tab>
          </TabList>
        </TabHeader>
        {isFreeTrial && isDraft ? (
          <UpgradeBanner />
        ) : (
          <TabPanels>
            <TabPanel>
              {tabIndex === 0 && (
                <AttendeesListComponent listUrl={attendeesListUrl} {...props} />
              )}
            </TabPanel>
            <TabPanel>
              {tabIndex === 1 && (
                <WaitlistListComponent listUrl={waitlistListUrl} {...props} />
              )}
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </Container>
  );
};

AttendeesWaitlistComponent.propTypes = {
  waitlistListUrl: PropTypes.string.isRequired,
  attendeesListUrl: PropTypes.string.isRequired,
};

export default AttendeesWaitlistComponent;
