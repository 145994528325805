import { Input } from '@components/inputs';
import Close from 'icons/close.svg';
import SearchIcon from 'icons/search.svg';
import { bool, func, string } from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';

const SearchForm = styled.form`
  position: relative;
`;

const StyledInput = styled(Input)`
  padding-left: var(--spacing-40) !important;
  padding-right: var(--spacing-40) !important;
`;

const FormButton = styled.button`
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
`;

const SearchButton = styled(FormButton)`
  &:focus:not([data-focus-visible-added]) {
    outline: 0;
  }
  left: calc(var(--spacing-16));

  svg {
    fill: var(--color-gray-700);
  }
`;

const ClearButton = styled(FormButton)`
  right: calc(var(--spacing-16));

  svg {
    fill: var(--color-gray-600);
  }
`;

const SearchInput = ({
  inputValue,
  onChange,
  name,
  placeholder,
  testId,
  ariaLabel,
  clearable,
}) => {
  const formRef = useRef();
  const showClearButton = Boolean(clearable && inputValue);

  function handleClearClick() {
    onChange({
      target: {
        value: '',
      },
    });

    if (formRef.current) {
      // This ensures Form is submitted after execution of onChange
      setTimeout(() => {
        formRef.current.submit();
      }, 0);
    }
  }

  return (
    <SearchForm ref={formRef}>
      <SearchButton aria-label={ariaLabel} type="submit">
        <SearchIcon />
      </SearchButton>
      <StyledInput
        onChange={onChange}
        data-testid={testId}
        name={name}
        placeholder={placeholder}
        value={inputValue}
      />
      {showClearButton && (
        <ClearButton aria-label="Clear search" onClick={handleClearClick}>
          <Close />
        </ClearButton>
      )}
    </SearchForm>
  );
};

SearchInput.propTypes = {
  inputValue: string.isRequired,
  onChange: func.isRequired,
  onClearClick: func,
  name: string.isRequired,
  placeholder: string.isRequired,
  clearable: bool,
  ariaLabel: string.isRequired,
  testId: string,
};

SearchInput.defaultProps = {
  clearable: false,
};

export default SearchInput;
