import { func, number, shape } from 'prop-types';
import React from 'react';

import {
  NavLink,
  Pagination,
  PaginationPageNumber,
} from './pagy-pagination-styles';

const PagyPagination = ({ pagyData, setCurrentPage }) => {
  const { page, last, next, prev } = pagyData;
  const isFirstPage = page === 1;
  const isLastPage = page === last;

  const generatePagination = () => {
    let pageLinks = [];
    for (let i = 1; i <= last; i++) {
      const isCurrentPage = page === i;
      pageLinks.push(
        <PaginationPageNumber
          data-testid="page-link"
          key={`page-number-${i}`}
          onClick={() => setCurrentPage(i)}
          disabled={isCurrentPage}
        >
          {i}
        </PaginationPageNumber>,
      );
    }
    return pageLinks;
  };

  const paginationFooter = generatePagination();
  return (
    <Pagination data-testid="pagination" role="navigation" aria-label="pager">
      <NavLink
        data-testid="previous-button"
        onClick={() => setCurrentPage(prev)}
        disabled={isFirstPage}
      >
        Prev
      </NavLink>
      {paginationFooter}
      <NavLink
        data-testid="next-button"
        disabled={isLastPage}
        onClick={() => setCurrentPage(next)}
      >
        Next
      </NavLink>
    </Pagination>
  );
};

PagyPagination.propTypes = {
  setCurrentPage: func.isRequired,
  pagyData: shape({
    page: number.isRequired,
    last: number.isRequired,
    next: number,
    prev: number,
  }),
};

export default PagyPagination;
