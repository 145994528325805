import { Flex } from '@hopin-team/ui-flex';
import styled from 'styled-components';

export const CutsSliceLoaderContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  background-color: var(--color-gray-900);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
`;
