import { rem } from 'polished';
import styled from 'styled-components';

import { LinkIcon } from '../styles';

const Header = styled.div`
  height: ${rem(68)};
  background: var(--color-gray-900);
  display: grid;
  grid-template-columns: ${rem(56)} repeat(2, auto);
  align-items: center;
  justify-items: left;
`;

const CloseLink = styled(LinkIcon)`
  justify-self: center;
`;

const ImageWrapper = styled.div`
  height: var(--spacing-32);
  width: ${rem(56)};
  box-shadow: inset 0 calc(-1 * ${rem(1)}) 0 var(--color-gray-700);
  border: ${rem(1)} solid transparent;
  border-radius: var(--border-radius-small);
  background-image: url(${props => props.picture});
  background-size: cover;
  background-position: center;
`;

const RightActionsWrapper = styled.div`
  justify-self: right;
`;

export { Header, ImageWrapper, RightActionsWrapper, CloseLink };
