import React from 'react';

const Ellipse = ({ fillColor = '#1d6eaa', size = 2 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.48" cx="1" cy="1" r="1" fill={fillColor} />
    </svg>
  );
};

export default Ellipse;
