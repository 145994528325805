import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { Flex } from '@hopin-team/ui-flex';
import { Icon } from '@hopin-team/ui-icon';
import * as Routes from '@routes';
import { func, object } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const IconWraper = styled(Flex)`
  margin: 0 0.375rem;
`;

const NonApplicable = ({ t }) => (
  <span ariaTitle={t('non-applicable')}>&#8212;</span>
);

NonApplicable.propTypes = {
  t: func.isRequired,
};

const StageRow = ({ event, stage }) => {
  const {
    captioned,
    description,
    is_primary: isPrimary,
    venue_type: venueType,
    name,
    stream_provider: streamProvider,
    backstage_url: backstageUrl,
  } = stage;
  const isOnsiteOnly = venueType === 'onsite';

  const capitalizedStreamProvider = streamProvider.replace(/^\w/, c =>
    c.toUpperCase(),
  );

  const { t } = useLocalization('stages');

  const hasBackstage =
    !isOnsiteOnly && streamProvider === 'hopin' && backstageUrl !== null;

  return (
    <tr>
      <td className="backstage-name">{name}</td>
      <td>{description}</td>
      <td>
        <Flex>
          {isOnsiteOnly ? (
            <NonApplicable t={t} />
          ) : (
            <>
              {capitalizedStreamProvider}
              {streamProvider === 'hopin' && (
                <IconWraper
                  data-testid={
                    captioned
                      ? 'stage-row-captioned'
                      : 'stage-row-not-captioned'
                  }
                >
                  <Icon
                    name={
                      captioned
                        ? 'closed-captions'
                        : 'closed-captions-deactivated'
                    }
                    title={captioned ? t('cc-activated') : t('cc-deactivated')}
                    scale={3}
                  />
                </IconWraper>
              )}
            </>
          )}
        </Flex>
      </td>
      {hasBackstage ? (
        <td className="backstage-link">
          <a href={backstageUrl} className="link">
            {t('backstage')}
          </a>
        </td>
      ) : (
        <td>
          <NonApplicable t={t} />
        </td>
      )}
      <td>
        <a
          href={Routes.editOrganisersEventBackstagePath(event, stage)}
          className="link"
          data-testid="edit-stage-action"
        >
          {t('edit')}
        </a>
      </td>
      {isPrimary ? (
        <td></td>
      ) : (
        <td>
          <a
            href={Routes.organisersEventBackstagePath(event, stage)}
            className="text-danger"
            data-testid="remove-stage-action"
            data-confirm={t('are-you-sure')}
            rel="nofollow"
            data-method="delete"
          >
            {t('remove')}
          </a>
        </td>
      )}
    </tr>
  );
};

StageRow.propTypes = {
  event: object.isRequired,
  stage: object.isRequired,
};

export default withLocalizationProvider(StageRow);
