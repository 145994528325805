import analyticsApi from '@api/analytics';
import { usePromise } from '@features/dashboard/util/hooks';
import { useLocalization } from '@features/localization';
import MetricCard from '@features/overview/analytics/common/metric-card/metric-card';
import { string } from 'prop-types';
import React, { useState } from 'react';

const defaultState = {
  expo_booth_visits: 0,
  expo_booth_interactions: 0,
};

const ExpoBoothKeyMetrics = ({ eventSlug }) => {
  const { t } = useLocalization('overview.expo-booth-key-metrics');
  const [{ expo_booth_visits, expo_booth_interactions }, setData] = useState(
    defaultState,
  );

  const isLoading = usePromise(
    signal => analyticsApi.getExpoBoothKeyMetrics(eventSlug, signal),
    ({ data }) => setData(data),
  );

  return (
    <>
      <MetricCard
        testId="expo-booth-visits"
        description={t('visits')}
        iconName="booth"
        displayValue={expo_booth_visits}
        isLoading={isLoading}
      />

      <MetricCard
        testId="expo-booth-interactions"
        description={t('interactions')}
        iconName="mouse-cursor"
        displayValue={expo_booth_interactions}
        isLoading={isLoading}
      />
    </>
  );
};

ExpoBoothKeyMetrics.propTypes = {
  eventSlug: string.isRequired,
};

export default ExpoBoothKeyMetrics;
