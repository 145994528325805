import { isEmpty as _isEmpty } from 'lodash';
import { arrayOf, number, shape, string } from 'prop-types';
import React from 'react';

import PreviewOrganisersSelect from './preview-organisers-select';

const PreviewUsersSelect = ({ options, ...rest }) => {
  const isOptionsPresent = !_isEmpty(options);
  const selectProps = {
    options,
    isDisabled: !isOptionsPresent,
    defaultMenuIsOpen: isOptionsPresent,
    autoFocus: isOptionsPresent,
  };

  return <PreviewOrganisersSelect {...selectProps} {...rest} />;
};

PreviewUsersSelect.propTypes = {
  eventId: number.isRequired,
  registrationsCount: number.isRequired,
  options: arrayOf(
    shape({
      label: string,
      value: number,
    }).isRequired,
  ),
};

export default PreviewUsersSelect;
