import { Checkbox } from '@hopin-team/ui-checkbox';
import styled from 'styled-components';

const Item = styled(Checkbox)`
  width: 50%;
  padding: var(--spacing-16);
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius-small);
  position: relative;

  ${({ checked }) =>
    checked &&
    `
    border-color: var(--blue-300);
    background-color: var(--gray-100);
  `}

  &:not(:first-child) {
    margin-left: var(--spacing-24);
  }

  /* Extend the clickable area of the label */
  label:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export { Item };
