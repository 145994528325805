import React from 'react';

export const ClockIcon = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0169 5.37L17.1481 4.24C17.3388 4.04558 17.3388 3.73442 17.1481 3.54L16.4373 2.83C16.3433 2.73534 16.2154 2.6821 16.0819 2.6821C15.9485 2.6821 15.8205 2.73534 15.7266 2.83L14.6154 4C13.5627 3.14667 12.3309 2.54164 11.0116 2.23V1C11.0116 0.447715 10.5634 0 10.0105 0H8.00843C7.45556 0 7.00738 0.447715 7.00738 1V2.23C2.90988 3.16292 0.00231828 6.80184 0 11C0.0546071 15.8476 3.90015 19.8034 8.74921 20C12.2574 20.0939 15.5009 18.1442 17.0609 15.0039C18.6209 11.8636 18.2135 8.1041 16.0169 5.37ZM9.00948 18C5.13942 18 2.00211 14.866 2.00211 11C2.00211 7.13401 5.13942 4 9.00948 4C12.8796 4 16.0169 7.13401 16.0169 11C16.0169 14.866 12.8796 18 9.00948 18ZM8.12856 6.5C8.12856 6.22386 8.35265 6 8.62908 6H9.38988C9.66632 6 9.89041 6.22386 9.89041 6.5V11.5C9.89041 11.7761 9.66632 12 9.38988 12H8.62908C8.35265 12 8.12856 11.7761 8.12856 11.5V6.5Z"
      fill="#1d6eaa"
    />
  </svg>
);
