import { Button } from '@hopin-team/ui-button';
import { bool, func, string } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Container } from './styles';

const Footer = ({
  handleCta,
  handlePreviewCta,
  handleSkip,
  canSkip = false,
  canPreview = false,
  disabled = false,
  transitioning = false,
  ctaLabel,
  ctaPreviewLabel,
  skipLabel,
  rounded = false,
}) => (
  <Container>
    {canSkip && (
      <SkipButton
        size="small"
        isInline
        isOutlined
        px={2}
        onClick={handleSkip}
        data-testid="skip-button"
        disabled={transitioning}
        $rounded={rounded}
      >
        {skipLabel}
      </SkipButton>
    )}
    <SaveButton
      size="small"
      isInline
      px={2}
      isOutlined={canPreview}
      onClick={handleCta}
      data-testid="save-and-continue"
      disabled={disabled || transitioning}
      $rounded={rounded}
    >
      {ctaLabel}
    </SaveButton>
    {canPreview && (
      <Button
        size="small"
        isInline
        leadingIcon="open-in-new"
        px={2}
        onClick={handlePreviewCta}
        data-testid="create-and-preview"
        disabled={disabled || transitioning}
      >
        {ctaPreviewLabel}
      </Button>
    )}
  </Container>
);

Footer.propTypes = {
  handleCta: func,
  handlePreviewCta: func,
  handleSkip: func,
  canSkip: bool,
  canPreview: bool,
  disabled: bool,
  transitioning: bool,
  ctaLabel: string,
  ctaPreviewLabel: string,
  skipLabel: string,
  rounded: bool,
};

export default Footer;

const SkipButton = styled(Button)`
  ${props =>
    props['$rounded'] &&
    css`
      border-radius: 0.625rem;
      border: 1px solid var(--color-blue-400);
    `}
`;

const SaveButton = styled(Button)`
  ${props =>
    props['$rounded'] &&
    css`
      border-radius: 0.625rem;
    `}
`;
