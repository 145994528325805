import timezones, {
  genericTimezonesMap,
  utcTimezone,
} from '@features/constants/timezones';
import { getBrowserTimezone } from '@util/date-helpers';
import getLogger, { LOGGER_NAMES } from '@util/logger';

const getTimezone = () => {
  try {
    const [region, browserTimezone] = getBrowserTimezone().split('/');
    return browserTimezone || region;
  } catch {
    return undefined;
  }
};

const getTzFromValue = value =>
  timezones().find(timezone => timezone.value === value);

const getSelectedTimezone = () => {
  const browserTimezone = getTimezone();

  const selectedTimezone =
    getTzFromValue(browserTimezone) ||
    getTzFromValue(genericTimezonesMap[browserTimezone]);

  if (!selectedTimezone) {
    const logger = getLogger(LOGGER_NAMES.EVENT_CREATION_WIZARD);
    logger.warn(`Couldn't resolve timezone: ${browserTimezone}`);

    return utcTimezone;
  }

  return selectedTimezone;
};

export { getTzFromValue, getSelectedTimezone };
